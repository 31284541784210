<!-- 数据查询视图-带分页，可用于表格数据管理查询页面 -->
<template>
  <div style="height: 100%;">
    <div
      v-if="showToolbar"
      class="form-headButton"
    >
      <!-- 授权操作按钮 -->
      <e-toolbar
        :buttons="buttons"
        :size="$config.defaultControlSize"
        :operationDisabled="operationDisabled"
        @click="onButtonClick"
      ></e-toolbar>
    </div>
    <div class="tab-view-context">
      <!-- 表title -->
      <e-head-title
        :title="title"
        :size="$config.defaultControlSize"
      ></e-head-title>
      <div class="view-context">
        <!-- 视图查询条件 -->
        <search-condition
          v-if="showSearchCondition"
          :conditions="searchCondition"
          :buttonName="searchConditionName"
          :changeValueOutoSearch="autoSearch"
          :initOneSearch="searchOne"
          @onSearch="doSearch"
        ></search-condition>
        <!-- 视图表格                     row-style="e-table-row_style"-->
        <div
          class="view-table"
          :class="getTableBoxClass"
        >
          <vxe-grid
            ref="tableRef"
            :stripe="$config.table_stripe"
            show-header-overflow 
            :scroll-x="{ gt: -1 }"
            :scroll-y="{ gt: 100 }"
            border
            resizable
            highlight-hover-row
            highlight-current-row
            :checkbox-config="{
              checkAll: false,
              highlight: true
            }"
            :empty-render="vxeTableEmptyRender"
            :show-footer-overflow="thisFooterTableColumn"
            :show-footer="thisFooterTableColumn"
            :tooltip-config="vxeTabletooltipConfig"
            :size="tableSize_MiXin"
            header-row-class-name="e-table-header_style"
            header-cell-class-name="e-table-header-cell_style"
            row-class-name="e-table-row_style"
            maxHeight="100%"
            height="auto"
            :loading="tableSpin"
            :data="tableDatas"
            @cell-click="onSelectChange"
            @checkbox-all="onSelectAll"
            @sort-change="onSortChange"
            :footer-method="footerMethod"
          >
            <template v-for="col in thisColumns">
              <vxe-table-column
                v-if="col.scopedSlots"
                :key="col.field"
                v-bind="col"
              >
                <!--如果定义了字段格式，则按照格式进行渲染-->
                <template slot-scope="slotProps">
                  <e-table-colum
                    v-if="col.scopedSlots && col.scopedSlots.clickMethod"
                    :column="col"
                    :slotScopeData="slotProps.row"
                    @onClick="columClick"
                  ></e-table-colum>
                  <e-table-colum
                    v-else
                    :column="col"
                    :slotScopeData="slotProps.row"
                  ></e-table-colum>
                </template>
              </vxe-table-column>
              <vxe-table-column
                v-else
                :key="col.field"
                v-bind="col"
              ></vxe-table-column>
            </template>
          </vxe-grid>
          <vxe-pager
            ref="vxePagerRef"
            class="e-table-page"
            background
            :size="tableSize_MiXin"
            :loading="tableSpin"
            :current-page="pagination.current"
            :page-size="pagination.pageSize"
            :page-sizes="pagination.pageSizes"
            :total="pagination.total"
            :layouts="pagination.layouts"
            @page-change="tableChange"
          >
          </vxe-pager>
        </div>
      </div>

      <!-- 筛选条件弹窗 -->
      <e-form-modal
        v-if="searchtemplate"
        v-model="sxModalShow"
        :tempId="searchtemplate"
        :initFormData="defaultData"
        :showOk="true"
        @okClicked="doListSx"
      >
        <template slot="title">
          <div class="e-model-head">
            <span class="e-model-title">筛选条件</span>
          </div>
        </template>
      </e-form-modal>

      <!-- 查询条件编辑弹窗 <a-icon type="zoom-in"></a-icon>-->
      <e-build-condition-modal
        v-model="searchModalShow"
        :selectCols="selectCols"
        @okClicked="doListCx"
        @changeCondition="onChangeCondition"
      >
        <template slot="title">
          <div class="e-model-head">
            <span class="e-model-title">查询条件定义</span>
          </div>
        </template>
      </e-build-condition-modal>

      <!--高级检索条件选择窗 -->
      <e-form-modal
        v-model="advancedSearchModalShow"
        :formConfig="advancedSearchFormConfig"
        :buttons="advancedSearchModalButtons"
        :initFormData="advancedSearchFormData"
        buttonAlign="space-between"
        width="50%"
        @buttonClicked="onAdvancedSearch"
      >
        <template slot="title">
          <div class="e-model-head">
            <span class="e-model-title">高级检索</span>
          </div>
        </template>
      </e-form-modal>
      <systemPrintSet ref="systemPrintSet"></systemPrintSet>
    </div>
  </div>
</template>

<script>
import ETableColum from "@/components/ETableColum";
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { e1ExpendColum } from "@/views/listView/mixins/e1ExpendColum.js"; // 提供了扩展列表功能的公共方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法
import { e1AdvancedSearch } from "@/views/listView/mixins/e1AdvancedSearch.js"; // 提供了列表高级检索功能的公共方法
export default {
  mixins: [
    analyticalMenuAuth,
    pageControls,
    requestControls,
    e1ExpendColum,
    e1AdvancedSearch
  ],
  name: "SearchView",
  components: {
    ETableColum
  },
  data () {
    return {
      cacheData: null,
      rowKey: "ID",
      tableDatas: [],
      thisColumns: [],
      statisticalData: [], // 合计行统计数据
      thisFooterTableColumn: false, // 合计行定义，如果是对本表的指定列进行合计，则应该和thisColumns保持一致
      showFooterTableHead: false, // 合计行是否显示表头，注：默认是对表列数据进行合计，如果只是显示指定的的其他的统计结果则需要显示出表头
      selectedRowKeys: [], // Check here to configure the default column
      buttons: [], // 当前用户可用的操作按钮
      searchCondition: [], // 用户可用的查询获执行条件
      searchConditionName: null, // 用户可用的查询获执行条件后面的按钮名称

      dmbh: null,
      thisConfig: null,
      tableConfig: null,
      tableSpin: false,
      showTableColums: true,
      otherCondition: {}, // 筛选条件表单参数
      nowSearchCondition: null, // 菜单配置的查询条件参数

      searchtemplate: null, // 筛选条件的表单模板Id
      //formType: null, // 筛选条件的表单name
      sxModalShow: false, // 是否弹出筛选条件表单弹窗
      defaultData: {}, // 筛选条件表单默认值
      searchModalShow: false, // 是否弹出查询条件编辑窗
      selectCols: [], // 查询条件可选表格字段,
      checkedAll: false, // 是否全选了列表
      popoverPlacement: "right",
      searchOne: false, // 是否第一次初始化表单即触发查询
      autoSearch: false // 是否数据变化即触发查询,注：由于数字选择控件有问题，本功能暂时有异常
    };
  },
  props: {
    pageConfig: {
      //包含视图结构、视图数据来源、操作权限配置
      type: Object,
      default: function () {
        return null;
      }
    },
    title: {
      type: String,
      default: ""
    }
  },
  computed: {
    tableMaxWidth: function () {
      if (this.thisColumns.length > 0) {
        let tempWidth = 0;
        for (let i = 0; i < this.thisColumns.length; i++) {
          tempWidth += this.thisColumns[i].width;
        }
        return tempWidth;
      }
      return 0;
    },
    showToolbar: function () {
      return this.checkToobleIsShow(this.buttons);
    },
    showSearchCondition: function () {
      return this.searchCondition && this.searchCondition.length > 0;
    },
    getTableBoxClass: function () {
      let classes = [];
      if (this.showSearchCondition) {
        if (this.thisFooterTableColumn) {
          classes.push("view-table__showSearchConditionAndFooter");
        } else classes.push("view-table__showSearchCondition");
      } else {
        if (this.thisFooterTableColumn) {
          classes.push("view-table__showFooter");
        } else classes.push("view-table__noSearchConditionAndFooter");
      }
      if (this.$config.defaultControlSize) {
        if (this.$config.defaultControlSize === "small") {
          classes.push("view-table__small");
        } else if (this.$config.defaultControlSize === "default") {
          classes.push("view-table__default");
        } else if (this.$config.defaultControlSize === "large") {
          classes.push("view-table__large");
        }
      } else {
        classes.push("view-table__default");
      }
      return classes;
    }
  },
  watch: {
    pageConfig: function (nDatas) {
      this.thisConfig = nDatas;
    },
    tableSpin: function (v) {
      if (!v) {
        // 数据加载完成后，将滚动条拉到顶部
        this.$refs.tableRef.scrollTo(0, 0);
      }
    }
  },
  activated() {
    this.$refs.tableRef.recalculate();
  },
  created () {
    this.thisConfig = this.pageConfig;
    //分析出用户可做操作按钮
    this.buttons = this.getUserJurisdictionButtons(this.thisConfig);
    if (this.thisConfig && this.thisConfig.viewConfig) {
      this.dmbh = this.thisConfig.DHBM;
      // 表格配置
      this.tableConfig = this.thisConfig.viewConfig;
      this.thisColumns = this.createTableColums(
        this.tableConfig.cols,
        this.tableConfig.templateId
      ); // 表头配置
      // 表尾合计行
      if (this.tableConfig.sumRow) this.createFooterTableData();
      console.log("表格定义", this.thisColumns);
    } else {
      this.alertError("表格配置错误");
    }
  },
  mounted () {
    if (this.tableConfig) {
      this.searchOne = this.tableConfig.autoLoad;
      this.searchConditionName = this.tableConfig.searchConditionName; // 条件后的名称
      let searchConditionTemp = this.tableConfig.searchCondition; // 查询或执行条件
      this.searchtemplate = this.tableConfig.searchtemplate; // 筛选条件模板
      /*
      2020-04-23新加功能代码，配合最新版searchCondition，需要经过searchTableList测试后方可使用
      */
      if (this.searchtemplate && this.searchtemplate != "") {
        if (searchConditionTemp) {
          searchConditionTemp.push({
            type: "temp",
            tempId: this.searchtemplate
          });
        } else {
          searchConditionTemp = [{ type: "temp", tempId: this.searchtemplate }];
        }
      }
      this.searchCondition = searchConditionTemp;
      let autoLoad = this.tableConfig.autoLoad; // 是否自动查询
      if (
        autoLoad &&
        !(this.searchCondition && this.searchCondition.length > 0)
      ) {
        // 有查询条件，则首次查询需要等待查询条件默认值完成加载并返回 !(this.searchCondition && this.searchCondition.length > 0)
        this.getTableData(null, {
          pageNumber: this.pagination.current,
          pageSize: this.pagination.pageSize
        });
      } else this.operationDisabled = false;
    } else {
      this.alertError("表格配置错误");
    }
    console.log("对应的查询条件", this.searchCondition);
  },
  methods: {
    /**
     * 加载表头
     * @param cols 视图配置的列头
     * @param tempid 视图记录详情对应的模板id
     */
    createTableColums (cols, tempid) {
      let colums = [];
      /*if (
        tempid === undefined ||
        tempid === null ||
        tempid == 0 ||
        (tempid && tempid != 79)
      ) {
        // 特殊处理“记账凭证查询”视图，有问题！！！！
        colums = this.analyticalColsArray(cols, null, null, "showByRule"); // 表头配置
        // 非标准方法,是否包含单号列
      } else if (tempid == 79) {
        colums = this.analyticalColsArray(cols);
      }*/
      colums = this.analyticalColsArray(cols, null, null, "showByRule");
      if (colums.length > 0) {
        let sumFilelds = [];
        for (let i = 0; i < colums.length; i++) {
          if (colums[i].isSum) {
            sumFilelds.push(colums[i].key);
          }
        }
        if (sumFilelds.length > 0 && this.tableConfig) {
          // 设置合计行字段
          this.tableConfig.sumRow = sumFilelds;
        }
      }
      return colums;
    },
    /**
     * 合计行渲染方法
     */
    footerMethod ({ columns, data }) {
      let that = this;
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "小计";
          }
          if (this.tableConfig.sumRow.includes(column.property)) {
            return that.$util.formatNumberRgx(that.statisticalData[0][column.property], true);
          }
          return null;
        }),
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (this.tableConfig.sumRow.includes(column.property)) {
            return that.$util.formatNumberRgx(that.statisticalData[1][column.property], true);
          }
          return null;
        })
      ];
    },
    /**
     * 创建展示合计行的footer表格数据对象
     */
    createFooterTableData () {
      this.thisFooterTableColumn = true;
      let hjdata = {
        "sum-column": "合计"
      };
      this.tableConfig.sumRow.forEach(function (row) {
        hjdata[row] = 0;
      });
      //小计数据行
      let xjdata = Object.assign({}, hjdata, { "sum-column": "小计" });
      this.statisticalData.push(xjdata);
      this.statisticalData.push(hjdata);
      console.log("合计数据", this.statisticalData);
    },
    /**
     * 加载表格的数据
     * @param searchCondition 菜单配置的查询条件字段对象
     * @param otherCondition 其他查询条件，包括筛选按钮打开的表单，和查询按钮组织的字段条件
     * @param button 对应的操作按钮对象
     * @param getAll 获得所有数据
     * @param callBack 拿到所有数据后回调
     */
    getTableData (searchCondition, otherCondition, button, getAll, callBack) {
      if (this.tableSpin) {
        return;
      }
      this.tableSpin = true;
      this.operationDisabled = true;
      let that = this;
      let params = {
        action: "GetSearchTableList",
        tb_name: this.tableConfig.tableName, //主表名
        cols: JSON.stringify(this.tableConfig.cols), // 字段配置
        initFilter: this.tableConfig.initfilter, // 查询条件
        t_id: this.tableConfig.templateId,
        bm: this.tableConfig.businessMode,
        searchCondition: JSON.stringify(searchCondition),
        et_name: this.tableConfig.et_name,
        randomKey: this.$store.getters.getRandomKey
      };
      if (otherCondition) {
        params = Object.assign(params, otherCondition);
        if (params.pageSize == Infinity) {
          // 查询所有数据
          delete params.pageNumber;
          delete params.pageSize;
        }
      }
      if (getAll) {
        if (params.pageNumber) {
          delete params.pageNumber;
        }
        if (params.pageSize) {
          delete params.pageSize;
        }
      }
      this.post("Form.ashx", params, {
        success: function (req) {
          //console.log(req);
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? req.data:req;
          if (getAll) {
            var dataL = reqData.data ? reqData.data.dataList : null;
            var dataC =
              reqData.data && reqData.data.columns
                ? reqData.data.columns
                : that.thisColumns;
            that.tableSpin = false;
            that.operationDisabled = false;
            callBack(dataL, dataC);
            return;
          }
          if (reqData.data && reqData.data.columns) {
            that.showTableColums = false;
            that.thisColumns = that.createTableColums(
              reqData.data.columns,
              that.tableConfig.templateId
            );
          }
          let dataList = reqData.data && reqData.data.dataList ? reqData.data.dataList : [];
          that.pagination.total = reqData.data.TotalCount
            ? reqData.data.TotalCount
            : dataList.length;
          if (dataList) {
            if (dataList.length > 0) {
              let tmppid = dataList[0][that.rowKey];
              if (tmppid === undefined) {
                // 如果不存在默认ID作为主键
                that.rowKey = that.defaultTableKeyName;
                // 则设置一个默认主键字段
                dataList.map(function (data, index) {
                  // 给数组对象设置一个主键字段用于表格选择
                  return (data[that.rowKey] = index);
                });
              }
            }
            that.tableDatas = dataList;
            if (reqData.data.sum) {
              // 如果包含合计数据
              // 本页小计
              let xjData = that.sumRowDatas_minxin(
                dataList,
                that.tableConfig.sumRow
              );
              that.statisticalData[0] = Object.assign(
                that.statisticalData[0],
                xjData
              );
              that.statisticalData[1] = Object.assign(
                that.statisticalData[1],
                reqData.data.sum
              );
            }
          } else {
            that.tableDatas = [];
          }
          if (!that.showTableColums) {
            setTimeout(function () {
              that.showTableColums = true;
              that.tableSpin = false;
              that.finishedProcessing(button);
            }, 500);
            return;
          }
          that.tableSpin = false;
          that.finishedProcessing(button);
        },
        error: function (err) {
          if (getAll) {
            that.tableSpin = false;
            that.operationDisabled = false;
            callBack(false);
            return;
          }
          console.log(err);
          that.pagination.current = 1;
          that.pagination.total = 0;
          that.tableSpin = false;
          that.finishedProcessing(button);
          that.alertError("请求失败，获取表数据失败");
        }
      });
    },
    /**
     * 查看记录对应详情
     * @param button
     */
    tsbView (button) {
      this.startProcessing(button);
      if (this.selectedRowKeys.length == 1) {
        if (button.param && button.param.viewConfig) {
          // 弹出的视图配置
          let selectedId = this.selectedRowKeys[0];
          let that = this;
          let selectedData = this.tableDatas.find(function (data) {
            return data[that.rowKey] === selectedId;
          });
          let titleFiedName = this.thisColumns[1].key;
          let title = "查看" + this.title + "-" + selectedData[titleFiedName];
          if (button.param.viewConfig.viewType === "View") {
            // 跳转到指定明细表格视图
            title =
              "查看" +
              button.param.viewConfig.title +
              "-" +
              selectedData[titleFiedName];
            let viewConfig = Object.assign({}, button.param.viewConfig);
            if (viewConfig.dbclicktablegetlink) {
              // 设置了关联数据参数字段
              let dbclicktableValue =
                selectedData[viewConfig.dbclicktablegetlink] + "";
              if (dbclicktableValue) {
                let searchTableName = viewConfig.tableName;
                let fiexFieldName = "{" + viewConfig.dbclicktablegetlink + "}";
                if (searchTableName.indexOf(fiexFieldName) >= 0) {
                  searchTableName = searchTableName.replace(
                    fiexFieldName,
                    dbclicktableValue
                  );
                  viewConfig.tableName = searchTableName;
                }
              }
            }
            let pageConfig = {
              DHBM: this.dmbh,
              operationConfig: viewConfig.operationConfig,
              viewConfig: viewConfig
            };
            this.$store.commit("setCacheData", pageConfig);
            this.openTab({
              title: title,
              path: "/UserForms",
              query: {
                dhbm: this.thisConfig.DHBM,
                title: title,
                type: "dataViewCache"
              }
            });
          } else {
            // 跳转到表单展示页面
            this.openTab({
              title: title,
              path: "/NewForm",
              query: {
                dhbm: this.dhbm,
                templetId: title,
                dataId: selectedId
              }
            });
          }
        }
        this.finishedProcessing(button);
      } else {
        this.alert("请选择一条需要查看的记录");
        this.finishedProcessing(button);
      }
    },
    /**
     *  打开编辑表单数据页面,?注意ID字段
     */
    tsbEdit () {
      if (this.selectedRowKeys.length == 1) {
        let selectedId = this.selectedRowKeys[0];
        let that = this;
        let selectedData = this.tableDatas.find(function (data) {
          return data[that.rowKey] === selectedId;
        });
        let titleFiedName = this.thisColumns[0].key;
        this.openTab({
          title: "编辑" + this.title + "-" + selectedData[titleFiedName],
          path: "/NewForm",
          query: {
            dhbm: this.dmbh,
            templetId: selectedData._MBID,
            dataId: selectedData.ID,
            type: "dateSearchView"
          }
        });
      } else {
        this.alert("请选择一条需要编辑的记录");
      }
    },
    /**
     * 点击“筛选”，打开筛选条件表单
     * 注：已经弃用，打开的筛选条件已经合并到查询工具条内
     */
    tsbSelect () {
      console.log("打开筛选表单");
      //this.formType = button.formType; button
      this.sxModalShow = true;
    },
    /**
     * 点击"高级检索"按钮，打开查询条件编辑表单
     */
    tsbSearch (button) {
      //console.log("打开查询表单", button);
      if (this.selectCols.length == 0) {
        let colums = this.analyticalColsArray(this.tableConfig.cols);
        this.selectCols = colums.map(function (col) {
          return {
            label: col.title,
            value: col.dataIndex
          };
        });
      }
      this.getAdvancedSearchModalFormConfig(
        this.tableConfig.tableName,
        this.tableConfig.cols,
        button
      );
    },
    /**
     * 点击筛选条件表单下的确认按钮触发查询
     * @param tjData
     */
    doListSx (tjData) {
      console.log("筛选表单条件", tjData);
      this.otherCondition = tjData.data;
      this.getTableData(this.nowSearchCondition, this.injectionPageSet(1));
      tjData.resolve();
    },
    /**
     * 点击“查询”按钮后，在弹出的查询条件组织页面，点击“确定”按钮，触发查询
     */
    doListCx (tjData) {
      console.log("字段搜索条件", tjData);
      this.otherCondition = Object.assign(this.otherCondition, tjData.data);
      this.getTableData(this.nowSearchCondition, this.injectionPageSet(1));
      tjData.resolve();
    },
    /**
     * 响应“查询”高级查询选项弹出窗的回退按钮事件，重新组织查询条件
     */
    onChangeCondition (tjData) {
      this.otherCondition = Object.assign(this.otherCondition, tjData.data);
    },
    /**
     * 点击查询按钮触发查询
     * @param values
     */
    doSearch (values) {
      console.log("查询数据", values);
      this.nowSearchCondition = values.data;
      this.getTableData(
        this.nowSearchCondition,
        this.injectionPageSet(1),
        values
      );
    },
            /**
     * 导出文件
     */
    SysExportFile(button) { 
       this.startProcessing(button);
        let that = this;
      let params = {
        action: 'ExportFWToExcel',
        dmbh: this.dmbh,
        randomKey: this.$store.getters.getRandomKey
      }; 
      this.get("Form.ashx", params, {
        success: function (res) {
          that.finishedProcessing(button);
          if (!res) {
            that.$message.error("获取导出的数据失败");
            return;
          }else{
            window.open(window.SERVER_URL + res.data)
          }
        },
        error: function (err) {
          that.finishedProcessing(button);
          that.alertError("导出失败，" + err.message);
        }
      });
    },
/**
     * 导出数据
     */
    tsbExport() { 
        this.getTableData(
          this.nowSearchCondition,
          this.injectionPageSet(1),
          null,
          true,
          res => {
            if (!res) {
              this.$message.error("获取导出的数据失败"); 
              return;
            }
            res &&
            this.thisColumns &&
            this.$util.excelExport(res, this.thisColumns, this.title);
          }
        );
       
    },
    /**
     * 响应列表的全选事件
     */
    onSelectAll (event) {
      this.checkedAll = !event.checked;
      this.tsbAll();
    },
    /**
     * 点击"全选"按钮
     */
    tsbAll () {
      let that = this;
      if (this.checkedAll) {
        this.selectedRowKeys = [];
        this.$refs.tableRef.setAllCheckboxRow(false);
      } else {
        this.selectedRowKeys = this.tableDatas.map(function (data) {
          return data[that.rowKey];
        });
        this.$refs.tableRef.setAllCheckboxRow(true);
      }
      this.checkedAll = !this.checkedAll;
    },
    /**
     * 点击任意单元格,选中或取消选中行
     * @param currentRow
     */
    onSelectChange (currentRow) {
      //console.log("onCellClick",currentRow);
      if (currentRow) {
        let that = this;
        let index = this.selectedRowKeys.findIndex(function (d) {
          return d === currentRow.row[that.rowKey];
        });
        if (index >= 0) {
          // 已经选中
          this.$refs.tableRef.setCheckboxRow(currentRow.row, false);
          this.selectedRowKeys.splice(index, 1);
        } else {
          this.$refs.tableRef.setCheckboxRow(currentRow.row, true);
          this.selectedRowKeys.push(currentRow.row[that.rowKey]);
        }
      }
      //console.log("selectedRowKeys changed: ", this.selectedRowKeys);
    },
    /**
     * 查看行数据 this.tableConfig.templateId != 79
     * @param row
     */
    show (row) {
      let rowID = row[this.rowKey];
      console.log("查看行", rowID);
      let titleFiedName = this.thisColumns[2].key;
      this.openTab({
        title: "查看-" + row[titleFiedName],
        path:
            !this.KeepAccountsFormTempIds.includes(this.tableConfig.templateId)
            ? "/DetailForm"
            : "/KeepAccountsForm",
        query: {
          dhbm: this.dmbh,
          templetId: this.tableConfig.templateId, // 表单模板ID
          dataId: rowID, // 表单数据id
          detailFiledName: titleFiedName, // 表单用于tab的title的表单字段名
          bm: this.tableConfig.businessMode // 表单对应BM表
        }
      });
    },

    /**
     * 打印按钮
     */
    tsbPrint () {
      let json = {
        formConfig: {
          position: { x: 0, y: 0 },
          size: { w: 0, h: 0 }
        },
        title: this.title,
        formRows: [],
        mxForm: {
          columns: this.thisColumns,
          data: this.tableDatas,
          position: { x: 0, y: 0 },
          size: { w: 0, h: 0 },
          type: "mxForm"
        }
      };
      this.$refs.systemPrintSet.show(json);
    },
    /**
     * 响应表格分页事件
     * @param pagination
     */
    tableChange (pagination) {
      this.selectedRowKeys = [];
      let that = this;
      this.onTableChangeMiXin(pagination, function () {
        that.getTableData(that.nowSearchCondition, that.injectionPageSet());
      });
    },
    /**
     * 触发排序事件
     * @param event
     */
    onSortChange (event) {
      //console.log("触发排序事件",event);
      if (event && event.field && event.order) {
        //event.field; 参与排序的字段;event.order;排序方式 "desc"
        let orderBy = event.field + " " + event.order;
        let otherParams = this.injectionPageSet(1);
        otherParams.orderBy = orderBy;
        this.selectedRowKeys = [];
        this.getTableData(this.nowSearchCondition, otherParams);
      } else {
        // 取消排序
        this.selectedRowKeys = [];
        this.getTableData(this.nowSearchCondition, this.injectionPageSet(1));
      }
    },
    /**
     * 刷新列表
     * @param button
     */
    tsbRefresh (button) {
      this.startProcessing(button);
      this.selectedRowKeys = [];
      this.getTableData(
        this.nowSearchCondition,
        this.injectionPageSet(1),
        button
      );
    },
    /**
     * 向otherCondition查询参数中注入分页查询设置
     * @param pageCurrent
     * @returns {({} & {pageNumber: *, pageSize: *}) | (otherCondition & {pageNumber: *, pageSize: *})}
     */
    injectionPageSet (pageCurrent) {
      if (pageCurrent) {
        this.pagination.current = pageCurrent;
      }
      let page = {
        pageNumber: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      return Object.assign(this.otherCondition, page);
    },
    /**
     * 推送数据给第三方接口,蓝能定制功能
     *  @param button
     */
    tsbSendToTPSystem (button) {
      this.startProcessing(button);
      if (this.selectedRowKeys.length > 0) {
        if (button.param && button.param.action) {
          let params = {
            action: button.param.action,
            templateID: this.tableConfig.templateId,
            dataID: this.selectedRowKeys.toString(),
            randomKey: this.$store.getters.getRandomKey
          };
          let that = this;
          this.get("Form.ashx", params, {
            success: function (req) {
              if (req.success) {
                that.tipSuccess(
                  "发送成功" + (req.message ? "," + req.message : "")
                );
              } else {
                that.alertError("发送失败，" + req.message);
              }
              that.finishedProcessing(button);
            },
            error: function (err) {
              that.finishedProcessing(button);
              that.alertError("发送失败，" + err.message);
            }
          });
        }
      } else {
        this.alert("请至少选择一条需要" + button.name + "的数据");
        this.finishedProcessing(button);
      }
    },
    /**
     * 点击执行按钮
     * @param button
     */
    tsbImplement (button) {
      this.startProcessing(button);
      if (this.selectedRowKeys.length > 0) {
        if (button.param) {
          let params = {
            action: "Implement",
            tableName: this.tableConfig.tableName,
            dataID: this.selectedRowKeys.toString(),
            randomKey: this.$store.getters.getRandomKey
          };
          params = Object.assign(params, button.param);
          let that = this;
          this.get("Form.ashx", params, {
            success: function (req) {
              if (req.success) {
                that.tipSuccess(
                  "发送成功" + (req.message ? "," + req.message : "")
                );
              } else {
                that.alertError("发送失败，" + req.message);
              }
              that.finishedProcessing(button);
            },
            error: function (err) {
              that.finishedProcessing(button);
              that.alertError("发送失败，" + err.message);
            }
          });
        }
      } else {
        this.alert("请至少选择一条需要" + button.name + "的数据");
        this.finishedProcessing(button);
      }
    },
    /**
     * 库存下限告警-创建材料采购申请单专用
     * @param button
     */
    tsbCreateNewForm (button) {
      this.startProcessing(button);
      if (this.selectedRowKeys.length > 0) {
        //构造需要带入到新表的数据
        let childrenDatas = [];
        for (var i = 0; i < this.tableDatas.length; i++) {
          for (var n = 0; n < this.selectedRowKeys.length; n++) {
            if (this.tableDatas[i].ID == this.selectedRowKeys[n]) {
              childrenDatas.push(this.tableDatas[i]);
              break;
            }
          }
        }

        let data = {
          childrenDatas: childrenDatas,
          mainTableData: {}
        };
        this.$store.commit("setCacheData", data);
        //设置材料采购申请单表单ID
        let query = {
          templetId: 164,
          type: "dataFromCache"
        };

        this.openTab({
          title: "新增-材料采购申请",
          path: "/NewForm",
          query: query
        });

        this.finishedProcessing(button);
      } else {
        this.alert("请至少选择一条需要" + button.name + "的数据");
        this.finishedProcessing(button);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/search-view.less";
</style>
