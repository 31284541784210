<template>
  <div class="eForm_content">
    <!-- 表单标题-->
    <e-head-title
      v-model="showMainFormTitle"
      :title="title"
      :id="1"
      :size="$config.defaultControlSize"
      :tip-info="showState"
      rightIcon="true"
      @clickRightIcon="closeFormTable"
      v-if="!zoomDetailForm && haveForm"
    >
      <template slot="leftTitleExpend">
        <slot name="formHeadTitleExpend"></slot>
      </template>
    </e-head-title>
    <!-- 主表单区域 formRows.length >0 && -->
    <div
      class="form-table form-table__inline-table"
      v-if="showForm && formRows && formRows.length > 0"
      v-show="foldFormTable1 && haveForm"
    >
      <form-template
        v-model="nowTableData"
        :colSpan="colSpan"
        :formulaConfig="formulaConfigs.mainFormulaConfigs"
        :formRows="formRows"
        :mainTableData="mainTableData"
        :tempId="tempId"
        :form="form"
        :templateName="templateName"
        :formDisable="formDisable"
        :formEditOver="formEditOver"
        :fieldsAllowedEdit="thisFieldsAllowedEdit"
        :modifiedData="modifiedData"
        @formExtendEvent="formExtendEvent"
        ref="formTemplate"
        @publicUploadChange="publicUploadChange"
      ></form-template>
    </div>
    <!-- 明细视图表格区域 -->
    <!-- 明细标题 -->
    <e-head-title
      v-model="showMxFormTitle"
      class="form-block-title_marginTop"
      title="明细数据"
      :id="2"
      :size="$config.defaultControlSize"
      rightIcon="true"
      @clickRightIcon="closeFormTable"
      :zoom="true"
      @zoom="zoom"
    >
    </e-head-title>
    <!-- 如果没有主表单，则只显示表单标题 -->
    <e-head-title
      v-model="showMxFormChangeTitle"
      :title="title"
      :id="2"
      :size="$config.defaultControlSize"
      :tip-info="showState"
      rightIcon="true"
      @clickRightIcon="closeFormTable"
      :zoom="true"
      @zoom="zoom"
    >
    </e-head-title>
    <div
      v-if="showForm && (tabOptions || option)"
      class="form-table"
      style="overflow: hidden;"
      v-show="foldFormTable2"
    >
      <!-- 单明细表 -->
      <e-table
        v-if="option && option.columns && option.columns.length > 0"
        :option="option"
        :mainTableData="nowTableData"
        :dataSource="mxTableDatas[option.tableName]"
        :changeValues="mxTableChangeDatas[option.tableName]"
        :buttonShow="buttonShow"
        :buttonConfig="mxOprButtons"
        :tempId="tempId"
        :ref="'mxform_' + option.tableName"
        @change="tableChange"
        :disabled="mxDisable"
        :editOver="formEditOver"
        :allowAddRows="option.allowAddRows"
        :allowDeleteRows="option.allowDeleteRows"
        :newFormAddOneRow="option.newFormAddOneRow"
        :fieldsAllowedEdit="thisMxAllowedEditField"
        :requiredField="thisMxRequiredField"
        :hideFields="mxHideFields[option.tableName]"
        :cannotFormula="option.disableRowEditCondition"
        :fieldsNotAllowedEdit="thisMxReadOnlyFields[option.tableName]"
        :changeHh="thisMxChangeHh"
        :scrollTop="scrollTop"
      ></e-table>
      <!-- 多明细表tab v-decorator="[tabObj.controlDefine.text]"-->
      <a-tabs
        v-else
        @change="tabCallback"
        type="card"
        class="form_tabs"
        v-model="activeKey"
      >
        <a-tab-pane
          class="form-tab-pane"
          v-for="tabObj in tabOptions.childObject"
          :tab="tabObj.controlDefine.text"
          :key="tabObj.controlDefine.tabIndex"
          :forceRender="true"
        >
          <template v-if="!tabObj.controlDefine.colSpan">
            <!-- 包含colSpan属性的说明是子表单类型，否则是明细表 -->
            <e-table
              v-for="gridObj in tabObj.childObject"
              :option="gridObj.controlDefine"
              :key="gridObj.controlName"
              :ref="'mxform_' + gridObj.controlDefine.tableName"
              :disabled="mxDisable"
              :editOver="formEditOver"
              :allowAddRows="gridObj.controlDefine.allowAddRows"
              :allowDeleteRows="gridObj.controlDefine.allowDeleteRows"
              :fieldsAllowedEdit="thisMxAllowedEditField"
              :requiredField="thisMxRequiredField"
              :fieldsNotAllowedEdit="
                thisMxReadOnlyFields[gridObj.controlDefine.tableName]
              "
              :newFormAddOneRow="gridObj.newFormAddOneRow"
              :changeHh="thisMxChangeHh"
              :buttonShow="buttonShow"
              :buttonConfig="mxOprButtons"
              :tempId="tempId"
              @change="tableChange"
              style="margin-bottom: 15px;margin-top: 10px;"
              :mainTableData="nowTableData"
              :hideFields="mxHideFields[gridObj.controlDefine.tableName]"
              :cannotFormula="gridObj.controlDefine.disableRowEditCondition"
              :dataSource="mxTableDatas[gridObj.controlDefine.tableName]"
              :changeValues="
                mxTableChangeDatas[gridObj.controlDefine.tableName]
              "
            ></e-table>
          </template>
          <form-template
            v-if="
              tabObj.controlDefine.colSpan &&
                tabObj.controlDefine.formRows &&
                tabObj.controlDefine.formRows.length > 0
            "
            v-model="nowTableData"
            tabForm
            :ref="'mxformTemplate_' + tabObj.controlDefine.tabIndex"
            :colSpan="tabObj.controlDefine.colSpan"
            :formulaConfig="formulaConfigs.mainFormulaConfigs"
            :formRows="tabObj.controlDefine.formRows"
            :mainTableData="mainTableData"
            :templateName="templateName"
            :tempId="tempId"
            :form="form"
            @formExtendEvent="formExtendEvent"
            :formDisable="formDisable"
            :formEditOver="formEditOver"
            :fieldsAllowedEdit="thisFieldsAllowedEdit"
            @publicUploadChange="publicUploadChange"
          ></form-template>
          <template
            v-if="
              tabObj.controlDefine.childGrid &&
                tabObj.controlDefine.childGrid.length > 0
            "
          >
            <!-- 既包含表单也包含明细表 -->
            <e-table
              v-for="gridObj in tabObj.controlDefine.childGrid"
              :option="gridObj.controlDefine"
              :key="gridObj.controlName"
              :ref="'mxform_' + gridObj.controlDefine.tableName"
              :disabled="mxDisable"
              :editOver="formEditOver"
              :allowAddRows="gridObj.controlDefine.allowAddRows"
              :allowDeleteRows="gridObj.controlDefine.allowDeleteRows"
              :fieldsAllowedEdit="thisMxAllowedEditField"
              :requiredField="thisMxRequiredField"
              :fieldsNotAllowedEdit="
                thisMxReadOnlyFields[gridObj.controlDefine.tableName]
              "
              :newFormAddOneRow="gridObj.newFormAddOneRow"
              :changeHh="thisMxChangeHh"
              :buttonShow="buttonShow"
              :buttonConfig="mxOprButtons"
              :tempId="tempId"
              @change="tableChange"
              style="margin-bottom: 15px;"
              :cannotFormula="gridObj.controlDefine.disableRowEditCondition"
              :mainTableData="nowTableData"
              :dataSource="mxTableDatas[gridObj.controlDefine.tableName]"
              :changeValues="
                mxTableChangeDatas[gridObj.controlDefine.tableName]
              "
              :hideFields="mxHideFields[gridObj.controlDefine.tableName]"
            ></e-table>
          </template>
        </a-tab-pane>
      </a-tabs>
    </div>
    <a-spin
      :spinning="showFormSpin"
      tip="表单加载中..."
      size="large"
      class="form-spin"
    >
    </a-spin>
    <div v-if="showFormError" class="e-form-formErrorInfo">
      <!-- 错误视图 -->
      <a-empty
        image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
      >
        <span slot="description"> 表单加载失败 </span>
        <a-button type="primary" @click="closeView">关闭</a-button>
      </a-empty>
    </div>

    <!-- excel导入组件 -->
    <ImportExcelDataModal
      @ExcelImportOk="ExcelImportOk"
      ref="importExcel"
    ></ImportExcelDataModal>
  </div>
</template>

<script>
import { analyticalTemplate } from "@/mixins/analyticalTemplate.js"; // 解析xml模板的方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法
import { mathAlgorithms } from "@/mixins/mathAlgorithms.js"; // 表单数据计算方法
import { analyticalPrint } from "@/mixins/analyticalPrint.js"; // 打印数据计算方法
import { systemService } from "@/mixins/systemService.js";
export default {
  mixins: [
    analyticalTemplate,
    pageControls,
    requestControls,
    mathAlgorithms,
    analyticalPrint,
    systemService
  ],
  name: "EForm",
  data() {
    return {
      showForm: false,
      showFormError: false,
      showFormSpin: false,

      foldFormTable1: true, // 是否折叠主表单
      foldFormTable2: true, // 是否折叠明细表单
      headLeftIconUp1: true,
      headLeftIconUp2: true,
      templateName: null, //对应GY_BDMB表的MBCBT字段值
      mainTableName: "", // 对应表单的主表表名
      mxTableName: [],
      option: null, //只有一张明细表
      tabOptions: null, // tab包含多张明细表
      activeKey: null, //
      formDisable: false, // 表单是否可以编辑，false表示是
      formEditOver: false,

      buttonShow: false,
      showState: "",
      formRows: [], // 表单的解析结果
      colSpan: 6,
      nowTableData: null, // 目前主表单的数据
      mainTableData: null, // 主表单默认数据
      modifiedData: {}, // 主表单中修改的数据
      mxTableDatas: {}, // 所有明细表格的初始化数据
      mxTableTempData: null, // 明细数据缓存
      formulaConfigs: {}, // 计算公式配置
      thisFieldsAllowedEdit: null,
      thisMxAllowedEditField: {},
      thisMxRequiredField: {},
      thisMxReadOnlyFields: {}, //明细表修改为只读的字段
      thisMxChangeHh: true, //明细表行号是否自动变化
      zoomDetailForm: false,
      mxTableChangeDatas: {}, // 明细表修改的内容
      thisMxCanbeEdited: true, // 明细表是否可编辑
      disableRowEditFormula: null, // 行禁用编辑的条件公式，满足该条件的行，将被禁用
      tabMxTableName: null, //多标签模式下当前显示明细表格名称
      mxHideFields:{},// 明细表需要动态隐藏的列
    };
  },
  props: {
    tempId: {
      type: [String, Number],
      default: null
    },
    form: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    spState: {
      type: String,
      default: ""
    },
    formConfigs: {
      type: Object,
      default: function() {
        return null;
      }
    },
    tableData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    subformDatas: {
      type: Array,
      default: function() {
        return null;
      }
    },
    mxOprButtons: {
      type: Array,
      default: function() {
        return [];
      }
    },
    disable: {
      type: Boolean,
      default: false
    },
    mxCanbeEdited: {
      type: Boolean,
      default: false
    },
    editOver: {
      // 表单编辑完毕状态，默认编辑中
      type: Boolean,
      default: false
    },
    fieldsAllowedEdit: {
      type: Array,
      default: function() {
        return null;
      }
    },
    mxAllowedEditField: {
      type: Object,
      default: function() {
        return {};
      }
    },
    mxReadOnlyFields: {
      type: Object,
      default: function() {
        return {};
      }
    },
    requiredField: {
      type: Object,
      default: function() {
        return null;
      }
    },
    hideFields: {
      type: Object,
      default: function() {
        return null;
      }
    },
    dataId: {
      type: [String, Number],
      default: null
    },
    mxHhAuto: {
      type: Boolean,
      default: true
    },
    mxDataChanges: {
      type: Object,
      default: function() {
        return {};
      }
    },
    mainDataChanged: {
      type: Object,
      default: function() {
        return {};
      }
    },
    mxButtonShow: {
      type: Boolean,
      default: false
    },
    scrollTop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mxDisable() {
      return !this.thisMxCanbeEdited;
    },
    showMainFormTitle() {
      return this.showForm && this.formRows.length > 0;
    },
    haveForm() {
      if (this.formRows && this.formRows.length > 0) {
        let showIndex = this.formRows.findIndex(function(frow) {
          if (frow.columns && frow.columns.length > 0) {
            let showField = frow.columns.findIndex(function(c) {
              return c.isShow != false && c.configs.fieldName;
            });
            return showField >= 0;
          }
          return false;
        });
        return showIndex >= 0;
      }
      return false;
    },
    showMxFormTitle() {
      return (
        this.showForm &&
        this.haveForm &&
        (this.tabOptions != null || this.option != null)
      );
    },
    showMxFormChangeTitle() {
      return (
        this.showForm &&
        !this.haveForm &&
        (this.tabOptions != null || this.option != null)
      );
    },
    nowShowMxTableName() {
        if(this.option && this.option.columns) {
          return this.option.tableName;
        } else {
         return this.tabMxTableName;
        }
    }
  },
  watch: {
    mxButtonShow: function(v) {
      this.buttonShow = v;
    },
    mxDataChanges: function(v) {
      if (v) {
        this.mxTableChangeDatas = v;
      }
    },
    mainDataChanged: function(newData) {
      if (newData) {
        this.modifiedData = newData;
      }
    },
    showForm: function(v) {
      if (!v && this.mxTableTempData) {
        // 隐藏起来的时候，先将缓存起来明细数据赋给真实明细保存对象
        this.mxTableDatas = Object.assign({}, this.mxTableTempData);
        this.mxTableTempData = null;
        //console.log("缓存了明细表数据",this.mxTableDatas);
      }
    },
    mxHhAuto: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(n) {
        //console.log("明细表行号可变化：", n);
        this.thisMxChangeHh = n;
      }
    },
    disable: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(n) {
        console.log("表单可编辑状态变化", n);
        this.formDisable = n;
        if (n) {
          this.thisMxCanbeEdited = this.mxCanbeEdited;
        }
      }
    },
    fieldsAllowedEdit: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(n) {
        if (this.formDisable && n && n.length > 0) {
          this.thisFieldsAllowedEdit = n;
        }
      }
    },
    mxAllowedEditField: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(n) {
        console.log(
          "监听到明细可编辑字段",
          this.thisMxCanbeEdited,
          this.formDisable,
          n
        );
        if ((this.formDisable || !this.thisMxCanbeEdited) && n) {
          this.thisMxAllowedEditField = n;
        }
      }
    },
    mxReadOnlyFields: function(newValue) {
      // 监听明细设置为只读的字段配置
      console.log("监听到明细设置为只读的字段配置", newValue);
      if (newValue) {
        this.thisMxReadOnlyFields = newValue;
      }
    },
    requiredField: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(n) {
        //console.log("追加设置必填项", n);
        if ((this.formDisable || !this.thisMxCanbeEdited) && n) {
          this.loading();
          if (
            this.formDisable &&
            n.mainTableField &&
            n.mainTableField.length > 0
          ) {
            // 主表单必填字段
            this.setFormFieldRequired(
              this.form,
              this.formRows,
              n.mainTableField
            );
          }
          if (this.thisMxCanbeEdited && n.chrenTableField) {
            this.thisMxRequiredField = n.chrenTableField;
          }
          this.loadingOk();
        }
      }
    },
    hideFields: {
      handler: function(n) {
        console.log("追加设置隐藏项", n);
        if (n) {
          this.loading();
          if (n.mainTableField && n.mainTableField.length > 0) {
            // 主表单隐藏字段
            this.setFormFieldHide(this.form, this.formRows, n.mainTableField);
          }
          if (n.chrenTableField) {
            /*this.setMxFieldHide(
              this.option,
              this.tabOptions,
              n.chrenTableField
            );*/
            this.mxHideFields = n.chrenTableField;
          }
          this.loadingOk();
        }
      }
    },
    editOver: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(n) {
        console.log("设置整个表单不可编辑状态", n);
        this.formEditOver = n;
      }
    },
    subformDatas: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(n) {
        //console.log("eform监听到明细数据变化",n);
        let that = this;
        this.$nextTick(function() {
          //console.log("到明细数据变化",n);
          that.setMxTableData(n);
        });
      }
    },
    spState: function(newState) {
      this.showState = newState;
    },
    tableData: function(newData) {
      if (newData) {
        this.mainTableData = newData;
        this.nowTableData = Object.assign({}, newData);
      }
    },
    formConfigs: function(n) {
      if (n) {
        this.colSpan = this.formConfigs.colSpan;
        this.mainTableName = this.formConfigs.mainTableName;
        this.templateName = this.formConfigs.formDefine.MBCBT;
        this.mxTableName = this.formConfigs.formDefine.mxTableName;
        this.formRows = this.formConfigs.formRows;
        this.formulaConfigs = this.formConfigs.formulaConfigs;
        //this.title = this.formConfigs.title;
        let mxForm = this.formConfigs.mxForm;
        //debugger
        if (mxForm) {
          //如果只有一张明细表格
          this.option = mxForm[0].controlDefine;
        } else {
          mxForm = this.formConfigs.mxForms;
          if (mxForm) {
            //是多张明细表的tab ?
            this.tabOptions = mxForm;
            //this.activeKey = this.tabOptions.controlDefine.selectTabIndex;
            console.log("this.activeKey=", this.tabOptions.controlDefine.selectTabIndex);
            let that = this;
            this.$nextTick(function() {
              console.log("初始化tabs：" , that.tabOptions);
              if (that.tabOptions) {
                for (let i = 0; i < that.tabOptions.childObject.length; i++) {
                  let tabObj = that.tabOptions.childObject[i];
                  let tabKey = tabObj.controlDefine.tabIndex;
                  if (!tabObj.controlDefine.colSpan && tabKey === this.activeKey) {
                    //that.activeKey = tabKey;
                    that.tabMxTableName = tabObj.childObject[0].controlDefine.tableName;
                    console.log("设置表格为第一默认标签页", that.activeKey);
                  } else {

                  }
                }
                that.activeKey = that.tabOptions.controlDefine.selectTabIndex;
              }
            });
          }
        }
      }
    },
    mxCanbeEdited: function(newState) {
      if (this.formDisable) {
        // 如果表单被完全禁用
        console.log("mxCanbeEdited变化成了", newState);
        this.thisMxCanbeEdited = newState; // 可以根据mxCanbeEdited设置明细表是否可以编辑
      }
    }
  },
  created() {
    //console.log("路由属性：" + JSON.stringify(this.$route.params)); // 从菜单直接带过来
    //console.log("eForm创建---------------------->");
  },
  mounted() {
    //console.log("请求参数：" + JSON.stringify(this.$route.query));
    //console.log("创建表单：" + this.subformDatas, this.tableData);
    /*console.log(
      "eForm mounted---------------------->",
      this.formConfigs,
      this.mxOprButtons
    );*/

    this.formDisable = this.disable;
    this.buttonShow = this.mxButtonShow;
    if (this.formDisable) {
      // 如果表单被完全禁用
      this.thisMxCanbeEdited = this.mxCanbeEdited; // 可以根据mxCanbeEdited设置明细表是否可以编辑
    } else {
      this.thisMxCanbeEdited = true;
    }
    this.mainTableData = this.tableData ? this.tableData : {};
    this.nowTableData = Object.assign({}, this.mainTableData);
    this.showState = this.spState;
    console.log("EForm获取到变化对象", this.mainDataChanged);
    if (this.mainDataChanged) {
      this.modifiedData = this.mainDataChanged;
    }
    if (this.mxDataChanges) {
      this.mxTableChangeDatas = this.mxDataChanges;
    }
    if (this.hideFields) {
      if (this.hideFields.mainTableField && this.hideFields.mainTableField.length > 0) {
        // 主表单隐藏字段
        this.setFormFieldHide(this.form, this.formRows, this.hideFields.mainTableField);
      }
      if (this.hideFields.chrenTableField)
        this.setMxFieldHide(
          this.option,
          this.tabOptions,
          this.hideFields.chrenTableField
        );
    }
    this.initMxTableData(this.subformDatas);
  },
  methods: {
    /**
     * 设置当前显示的明细表中的筛选条件下拉框失去焦点
     */
    filterSelectBlur: function() {
      if(this.nowShowMxTableName) {
        let mxRef = this.getThisRef("mxform_" + this.nowShowMxTableName);
        mxRef.contentFilterSelectBlur();
      }
    },
   /**
    * 将表单主表单的指定字段值重置，如果没有指定字段，则认为是全部重置
    * @param fields
    */
    resetFields: function(fields) {
        if(this.$util.isType(fields)==="Array" && fields.length>0) {
          this.form.resetFields(fields);
        } else {
            this.form.resetFields();
        }
    },
    /**
     * 设置当前表单的明细表满足条件的行不能编辑
     * @param newFormula
     */
    setDisableRowEditFormula: function(newFormula) {
      this.disableRowEditFormula = newFormula;
    },
    /**
     * 获取对应明细表名的数据
     * @param  tableName
     */
    getMxTableDataByTableName(tableName) {
      if (tableName) {
        let mxRef = this.getThisRef("mxform_" + tableName);
        if (mxRef) {
          return mxRef.getData();
        }
      }
      return [];
    },
    /**
     * 获取表单中的明细表的数据
     * 注，次方法会将非明细定义列的字段过滤掉，如果想获取源数据，请调用getMxSourceData方法
     */
    getMxTableData(chinese) {
      let mxData = null;
      if (this.mxTableName && this.mxTableName.length > 0) {
        mxData = [];
        for (let i = 0; i < this.mxTableName.length; i++) {
          let mxName = this.mxTableName[i];
          let mxRef = this.getThisRef("mxform_" + mxName);
          if (mxRef) {
            // 如果存在对应明细表的映射对象
            let data = [];
            if (chinese == true) {
              data = mxRef.getChineseValue();
            } else {
              data = mxRef.getData();
            }
            mxData.push({
              tableName: mxName,
              data: data
            });
          }
        }
      }
      return mxData;
    },
    /**
     * 获取明细表中的源数据
     */
    getMxSourceData() {
      let mxData = null;
      if (this.mxTableName && this.mxTableName.length > 0) {
        mxData = [];
        for (let i = 0; i < this.mxTableName.length; i++) {
          let mxName = this.mxTableName[i];
          let mxRef = this.getThisRef("mxform_" + mxName);
          if (mxRef) {
            // 如果存在对应明细表的映射对象
            let data = [];
            data = [].concat(mxRef.data);
            mxData.push({
              tableName: mxName,
              data: data
            });
          }
        }
      }
      return mxData;
    },
    /**
     * 获取对应明细表名的源数据，注本方法不会将源数据中的非表单字段过滤
     * @param  tableName
     */
    getMxSourceDataByTableName(tableName) {
      if (tableName) {
        let mxRef = this.getThisRef("mxform_" + tableName);
        if (mxRef) {
          let data = [];
          data = this.$util.deepCopy(mxRef.data);
          return data;
        }
      }
      return [];
    },
    /**
     * 获取表单中的明细表的列数据
     */
    getMxTableCol() {
      let mxData = null;
      if (this.mxTableName && this.mxTableName.length > 0) {
        mxData = [];
        for (let i = 0; i < this.mxTableName.length; i++) {
          let mxName = this.mxTableName[i];
          let mxRef = this.getThisRef("mxform_" + mxName);
          if (mxRef) {
            // 如果存在对应明细表的映射对象
            let data = mxRef.getCol();
            mxData.push({
              tableName: mxName,
              data: data
            });
          }
        }
        return mxData;
      }
      return this.option.columns;
    },
    /**
     * 明细表已加载后，可调用本方法重新设置表单明细数据
     * @param allChildrenDatas
     */
    setMxTableData(allChildrenDatas) {
      if (allChildrenDatas && allChildrenDatas.length > 0) {
        if (this.mxTableName && this.mxTableName.length > 0) {
          for (let i = 0; i < this.mxTableName.length; i++) {
            let mxName = this.mxTableName[i];
            let mxRef = this.getThisRef("mxform_" + mxName);
            if (mxRef) {
              // 如果存在对应明细表的映射对象
              let mxData = allChildrenDatas.find(function(mx) {
                return mx.tableName === mxName;
              });
              if (mxData) {
                let datas = mxData.datas ? mxData.datas : mxData.data;
                mxRef.setData(datas);
              }
            }
          }
        }
      }
    },
    /**
     * 修改指定表的行数据
     * @param tableName
     * @param rowData
     * @param keyField
     */
    updateRowData(tableName, rowData, keyField) {
      this.getThisRef("mxform_" + tableName).updateRowData(rowData, keyField);
    },
    /**
     * 明细表已加载后，可调用本方法给表单明细数据追加数据
     * @param allChildrenDatas
     * @param type true:传入的行号会删除重新排，false:引用原有行号
     */
    addMxTableData(allChildrenDatas, type) {
      if (allChildrenDatas && allChildrenDatas.length > 0) {
        if (this.mxTableName && this.mxTableName.length > 0) {
          for (let i = 0; i < this.mxTableName.length; i++) {
            let mxName = this.mxTableName[i];
            let mxRef = this.getThisRef("mxform_" + mxName);
            if (mxRef) {
              // 如果存在对应明细表的映射对象
              let mxData = allChildrenDatas.find(function(mx) {
                return mx.tableName === mxName;
              });
              if (mxData) {
                let datas = mxData.datas ? mxData.datas : mxData.data;
                mxRef.insertData(datas, type);
              }
            }
          }
        }
      }
    },
    /**
     * 明细表未加载前，可调用本方法初始化表单明细数据
     * @param allChildrenDatas
     */
    initMxTableData(allChildrenDatas) {
      if (allChildrenDatas && allChildrenDatas.length > 0) {
        if (this.mxTableName && this.mxTableName.length > 0) {
          for (let i = 0; i < this.mxTableName.length; i++) {
            let mxName = this.mxTableName[i];
            // 如果存在对应明细表的映射对象
            let mxData = allChildrenDatas.find(function(mx) {
              return mx.tableName === mxName;
            });
            if (mxData) {
              let datas = mxData.datas ? mxData.datas : mxData.data;
              this.mxTableDatas[mxName] = datas;
            }
          }
        }
      }
    },
    /**
     *  获取选中的明细记录
     *  注：目前只支持单一明细表
     */
    getSelectedDetails() {
      if (this.mxTableName && this.mxTableName.length == 1) {
        let mxName = this.mxTableName[0];
        let mxRef = this.getThisRef("mxform_" + mxName);
        if (mxRef) {
          return mxRef.$data.selectedRows;
        }
      }
      return null;
    },
    /**
     * 可调用本方法初始化表单数据
     * @param data
     */
    setMainTableData(data) {
      if (data) this.mainTableData = data;
    },
    /**
     * 显示加载中
     */
    loading() {
      this.showFormSpin = true;
      this.showForm = false;
    },
    /**
     * 加载成功
     */
    loadingOk() {
      this.showForm = true;
      this.showFormSpin = false;
    },
    /**
     * 加载失败
     */
    loadingError() {
      this.showFormSpin = false;
      this.showFormError = true;
    },
    /**
     * 隐藏表单
     */
    hideForm() {
      this.showForm = false;
      this.showFormSpin = false;
      this.showFormError = false;
    },
    /**
     * 将获得到的明细数据转换为JSON格式字符串，用于表单保存方法
     * @param subTableData
     */
    getMxDataJSON(subTableData) {
      let mxArray = [];
      let mxdata = this.getMxTableData();
      //debugger;
      if (mxdata && mxdata.length > 0) {
        for (let i = 0; i < this.mxTableName.length; i++) {
          let tname = this.mxTableName[i];
          let dataObj = mxdata.find(function(d) {
            return d.tableName === tname;
          });
          if (dataObj && dataObj.data && dataObj.data.length > 0)
            mxArray.push({
              tableName: tname,
              data: dataObj.data
            });
        }
      }
      if (this.$util.isType(subTableData) === "Array") {
        mxArray = [].concat(mxArray, subTableData);
      }
      //console.log("明细数据：", mxJSONString);
      return JSON.stringify(mxArray);
    },
    /**
     * 导出按钮
     */
    tsbExport() {
      // 如果存在对应明细表的映射对象
      // 单明细表
      if (this.option) {
        if (this.option.columns) {
          //debugger;
          var title = this.title;
          if (this.dataId) {
            if (this.mainTableData && this.mainTableData._BDBH) {
              title = title + this.mainTableData._BDBH;
            }
          }

          this.$util.excelExport(
            this.getMxTableData(true)[0].data,
            this.getMxTableCol()[0].data,
            title
          );
        }
      } else {
        this.$message.warning("此表单没有明细表,无法导出");
        return;
      }
      // 多明细表
      if (this.mxTableName && this.mxTableName.length > 1) {
        var mxData = [];
        // 获得明细表数据和name
        for (let i = 0; i < this.mxTableName.length; i++) {
          let mxName = this.mxTableName[i];
          let mxRef = this.getThisRef("mxform_" + mxName);
          if (mxRef) {
            // 如果存在对应明细表的映射对象
            let data = mxRef.getData();
            mxData.push({
              tableName: mxName,
              data: data
            });
          }
        }
        // 通过name找到行定义
        for (let j = 0; j < mxData.length; j++) {
          var ekey = "";
          var ii = j;
          var index = this.tabOptions.childObject.findIndex(item => {
            for (var key in item["childObject"]) {
              if (item["childObject"][key]["controlDefine"]) {
                if (
                  item["childObject"][key]["controlDefine"]["tableName"] ===
                  mxData[ii]["tableName"]
                ) {
                  ekey = key;
                  return true;
                }
              } else {
                break;
              }
            }
          });
          if (index != -1) {
            mxData[j].columns = this.tabOptions.childObject[index].childObject[
              ekey
            ]["controlDefine"]["columns"];
          }
        }
        // 打印
        for (let o = 0; o < mxData.length; o++) {
          this.$util.excelExport(
            mxData[o].data,
            mxData[o].columns,
            mxData[o].tableName
          );
        }
      }
    },
    /**
     * 点击数据导入进入导入视图
     */
    tsbExcelImport() {
      this.$refs.importExcel.show(this.option.columns, this.title);
    },
    ExcelImportOk(tableData) {
      for (let i = 0; i < this.mxTableName.length; i++) {
        let mxName = this.mxTableName[i];
        let mxRef = this.getThisRef("mxform_" + mxName);
        if (mxRef) {
          // 如果存在对应明细表的映射对象
          mxRef.setData(tableData);
        }
      }
    },
    /**
     * 标签页切换
     */
    tabCallback(tabTag) {
      let tabObj = null,have = false;
      for(let i=0; i<this.tabOptions.childObject.length; i++) {
        tabObj = this.tabOptions.childObject[i];
        if(!tabObj.controlDefine.colSpan) {
          if(tabObj.controlDefine.tabIndex == tabTag) {
            have = true;
            break;
          }
        }
      }
      if(have) { //是明细表格
        this.tabMxTableName = tabObj.childObject[0].controlDefine.tableName;
      } else { //子表单
        this.tabMxTableName = null;
      }
      console.log(this.tabOptions.childObject[tabTag],this.tabMxTableName);
    },
    /**
     * 监听明细表数据变化
     * @param tableData
     */
    tableChange(tableData) {
      //console.log("监听到明细表数据变化：", tableData);
      // 把通过新增和删除方式添加的数据缓存起来
      let tempData = {};
      if (this.mxTableTempData) {
        tempData[tableData.tableName] = tableData.data;
        this.mxTableTempData = Object.assign(this.mxTableTempData, tempData);
      } else {
        tempData[tableData.tableName] = tableData.data;
        this.mxTableTempData = tempData;
      }
      if (
        this.formulaConfigs.mainFromRowFormulaConfigs &&
        this.formulaConfigs.mainFromRowFormulaConfigs.length > 0
      ) {
        // 主表字段需要通过明细数据进行计算
        let newObject = {};
        for (
          let i = 0;
          i < this.formulaConfigs.mainFromRowFormulaConfigs.length;
          i++
        ) {
          let fieldFormula = this.formulaConfigs.mainFromRowFormulaConfigs[i];
          if (tableData.tableName === fieldFormula.mxTableName) {
            let jsResult = this.doCalculation1(fieldFormula, this.form, [
              tableData
            ]);
            console.log(
              fieldFormula.resultField +
                "=公式" +
                fieldFormula.formula +
                "的计算结果" +
                jsResult
            );
            newObject[fieldFormula.resultField] = jsResult;
          }
        }
        if (this.$util.isValidObject(newObject))
          this.form.setFieldsValue(newObject);
      }
    },
    /**
     * 折叠或展开指定表单块
     * @param n
     */
    closeFormTable(n) {
      if (n == 1) {
        // 如果是要收起主表单，则将明细表单置顶
        if (this.foldFormTable1) {
          this.scrollToBottom(null, "tab-form-context"); //
        }
        this.foldFormTable1 = !this.foldFormTable1;
      } else if (n == 2) {
        this.foldFormTable2 = !this.foldFormTable2;
      }
      this.readyDetailScroll();
    },
    /**
     * 最大化
     * @param n
     */
    zoom(n, zoomState) {
      if (n == 2) {
        if (zoomState) {
          this.foldFormTable1 = false;
          this.foldFormTable2 = true;
          this.zoomDetailForm = true;
        } else {
          this.foldFormTable1 = true;
          this.foldFormTable2 = true;
          this.zoomDetailForm = false;
        }

        if (this.$util.isType(this.showMainFormTitle) === "Boolean") {
          if (this.showMainFormTitle == true) {
            this.$emit("zoom", zoomState);
          }
        } else {
          if (this.$util.strIsTrue(this.showMainFormTitle)) {
            this.$emit("zoom", zoomState);
          }
        }
      }
      this.readyDetailScroll();
    },
    /**
     * 重置X轴滚动条状态
     */
    readyDetailScroll() {
      if (this.mxTableName && this.mxTableName.length > 0) {
        for (let i = 0; i < this.mxTableName.length; i++) {
          let mxName = this.mxTableName[i];
          let mxRef = this.getThisRef("mxform_" + mxName);
          if (mxRef) {
            // 如果存在对应明细表的映射对象
            mxRef.haveX(true);
          }
        }
      } else {
        let mxRef1 = this.getThisRef("mxform_" + this.option.tableName);
        mxRef1.haveX(true);
      }
    },
    /**
     * 表单校验方法
     * @param callBack
     */
    validateFields(callBack) {
      if (callBack && this.$util.isType(callBack) === "Function") {
        // 获取明细表校验结果
        let errors = [];
        //debugger
        if (this.mxTableName && this.mxTableName.length > 1) {
          // 多张明细
          for (let i = 0; i < this.tabOptions.childObject.length; i++) {
            //debugger
            let tabObj = this.tabOptions.childObject[i];
            let tabText = tabObj.controlDefine.text;
            let tabKey = tabObj.controlDefine.tabIndex;
            if (!tabObj.controlDefine.colSpan) {
              for (let j = 0; j < tabObj.childObject.length; j++) {
                let gridObj = tabObj.childObject[j];
                //调用全局的表单保存前的检查方法
                if(window.SYSTEM_EVENTS_HOOK &&
                  window.SYSTEM_EVENTS_HOOK.FORM_GLOBLE_HOOK &&
                  window.SYSTEM_EVENTS_HOOK.FORM_GLOBLE_HOOK.formSaveBeforeCheckDetailDatas) {
                  if(!window.SYSTEM_EVENTS_HOOK.FORM_GLOBLE_HOOK.formSaveBeforeCheckDetailDatas(this, this.getMxSourceDataByTableName(gridObj.controlDefine.tableName))){
                    return;
                  }
                }
                // 如果存在对应明细表的映射对象
                let error = this.getMxTableErr(gridObj.controlDefine.tableName);
                if (error && error.errors.length > 0) {
                  error.text = tabText;
                  error.tabIndex = tabKey;
                  errors.push(error);
                }
              }
            }
          }
        } else {
          //一张明细表
          //调用全局的表单保存前的检查方法
          if(window.SYSTEM_EVENTS_HOOK &&
            window.SYSTEM_EVENTS_HOOK.FORM_GLOBLE_HOOK &&
            window.SYSTEM_EVENTS_HOOK.FORM_GLOBLE_HOOK.formSaveBeforeCheckDetailDatas) {
            if(!window.SYSTEM_EVENTS_HOOK.FORM_GLOBLE_HOOK.formSaveBeforeCheckDetailDatas(this, this.getMxSourceDataByTableName(this.mxTableName[0]))){
              return;
            }
          }
          let error = this.getMxTableErr(this.mxTableName[0]);
          if (error && error.errors.length > 0) {
            errors.push(error);
          }
        }
        if (errors.length > 0) {
          this.activeKey = errors[0].tabIndex;
          callBack(errors);
          return;
        }
        this.form.validateFields(callBack);
      }
    },
    /**
     * 从指定名称的明细表中获取校验信息
     * @param mxName
     * @returns {*}
     */
    getMxTableErr(mxName) {
      let error = undefined;
      let mxRef = this.getThisRef("mxform_" + mxName);
      if (mxRef) {
        error = mxRef.getValidateResult();
      }
      return error;
    },
    /**
     * 系统打印多明细表获取表内容
     * @param data
     * @returns {*}
     */
    getFromsConfig() {
      var mxData = [];
      // 获得明细表数据和name
      for (let i = 0; i < this.mxTableName.length; i++) {
        let mxName = this.mxTableName[i];
        let mxRef = this.getThisRef("mxform_" + mxName);
        if (mxRef) {
          // 如果存在对应明细表的映射对象
          let data = mxRef.getData();
          mxData.push({
            tableName: mxName,
            data: data
          });
        }
      }
      // 通过name找到行定义
      for (let j = 0; j < mxData.length; j++) {
        var ekey = "";
        var ii = j;
        var index = this.tabOptions.childObject.findIndex(item => {
          for (var key in item["childObject"]) {
            if (item["childObject"][key]["controlDefine"]) {
              if (
                item["childObject"][key]["controlDefine"]["tableName"] ===
                mxData[ii]["tableName"]
              ) {
                ekey = key;
                return true;
              }
            } else {
              break;
            }
          }
        });
        if (index != -1) {
          mxData[j].columns = this.tabOptions.childObject[index].childObject[
            ekey
          ]["controlDefine"]["columns"];
        }
      }
      return mxData;
    },
    /**
     * 获取表单中的控件，对应的中文title
     */
    getChineseName() {
      try {
        let props1 = Object.getOwnPropertyNames(this.$refs);
        let result = this.getThisRef("formTemplate").chineseName;
        for (let i = 0, max = props1.length; i < max; i++) {
          if (props1[i].indexOf("mxformTemplate_") === 0) {
            result = Object.assign(
              {},
              result,
              this.getThisRef(props1[i]).chineseName
            );
          }
        }
        return result;
      } catch (err) {
        console.log("!!!没有获得中文定义", err);
        return {};
      }
    },
    /**
     * 获取表单明细表中的控件，对应的中文title
     */
    getMxChineseValue() {
      let chineseValues = [];
      for (let i = 0; i < this.mxTableName.length; i++) {
        let mxName = this.mxTableName[i];
        let mxRef = this.getThisRef("mxform_" + mxName);
        if (mxRef) {
          // 如果存在对应明细表的映射对象
          chineseValues.push({
            tableName: mxName,
            chineseNames: mxRef.getChineseValue()
          });
        }
      }
      return chineseValues;
    },
    getColDefinition() {
      var RowDefinition = [];
      for (let i = 0; i < this.mxTableName.length; i++) {
        let mxName = this.mxTableName[i];
        let mxRef = this.getThisRef("mxform_" + mxName);
        if (mxRef) {
          // 如果存在对应明细表的映射对象
          RowDefinition.push({
            tableName: mxName,
            data: mxRef.columns
          });
        }
      }
      return RowDefinition;
    },
    /**
     * 响应扩展表单功能，定义的组件事件
     * @param e
     */
    formExtendEvent(e) {
      this.$emit("formExtendEvent", e);
    },
    /**
     * 关闭当前的tab
     */
    closeView() {
      this.closeNowTab();
    },
    /**
     * 设置对应的表单字段禁用
     * @param field
     * @param disable
     */
    setFiledDisabled(field, disable) {
      let fieldObj = this.$util.getObjByParamAndValue(this.formRows, "fieldName", field);
      if(fieldObj) {
        this.getThisRef("formTemplate").setFiledDisabled(field, disable);
      } else if(this.tabOptions){
        for (let i = 0; i < this.tabOptions.childObject.length; i++) {
          let tabObj = this.tabOptions.childObject[i];
          if (tabObj.controlDefine.colSpan) {
            fieldObj = this.$util.getObjByParamAndValue(tabObj, "fieldName", field);
            if(fieldObj){
                let mxFormRef =this.getThisRef("mxformTemplate_" + tabObj.controlDefine.tabIndex);
                if(mxFormRef) {
                  mxFormRef.setFiledDisabled(field, disable);
                }
            }
          }
        }
      }
    },
    /**
     * 设置切换对应的表单字段禁用状态
     * @param field
     */
    setFieldDisabledSwitch(field) {
      let fieldObj = this.$util.getObjByParamAndValue(this.formRows, "fieldName", field);
      if(fieldObj) {
        this.getThisRef("formTemplate").setFieldDisabledSwitch(field);
      } else if(this.tabOptions){
        for (let i = 0; i < this.tabOptions.childObject.length; i++) {
          let tabObj = this.tabOptions.childObject[i];
          if (tabObj.controlDefine.colSpan) {
            fieldObj = this.$util.getObjByParamAndValue(tabObj, "fieldName", field);
            if(fieldObj){
              let mxFormRef =this.getThisRef("mxformTemplate_" + tabObj.controlDefine.tabIndex);
              if(mxFormRef) {
                mxFormRef.setFieldDisabledSwitch(field);
              }
            }
          }
        }
      }
    },
    /**
     * 抛出公共上传附件的值
     */
    publicUploadChange(v) {
      this.$emit("publicUploadChange", v);
    },
    /**
     * 强制渲染整个表单
     */
    formForceUpdate() {
      if(this.formRows) {
        this.getThisRef("formTemplate").formForceUpdate();
      }
      if(this.tabOptions){
        for (let i = 0; i < this.tabOptions.childObject.length; i++) {
          let tabObj = this.tabOptions.childObject[i];
          if (tabObj.controlDefine.colSpan) {
              let mxFormRef =this.getThisRef("mxformTemplate_" + tabObj.controlDefine.tabIndex);
              if(mxFormRef) {
                mxFormRef.formForceUpdate();
              }
          }
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../assets/style/base/components/e-form.less";
</style>
