/**
 * e1表单自定义校验方法
 * @type {{data(): *, created(), methods: {}}}
 */
export const e1FormValidator = {
  data() {
    return {};
  },
  created() {
    //console.log("混入analyticalTemplate的通用方法");
  },
  methods: {
    /**
     * e1表单控件输入值唯一性约束校验
     * @param rule
     * @param value
     * @param callback
     */
    e1_uniqueCheck: function(rule, value, callback) {
      if (rule && rule.uniqueCheck && value && value != "") {
        let checkField = rule.uniqueCheck.FieldName + "=";
        if (this.$util.isType(value) === "String") {
          checkField = checkField + "'" + value + "'";
        } else if (this.$util.isType(value) === "val") {
          checkField = checkField + value;
        } else if (this.$util.isType(value) === "Boolean") {
          checkField = checkField + value;
        } else {
          callback();
          return;
        }
        let params = {
          action: "UniquenessCheck",
          t_name: rule.uniqueCheck.UniqueCheckTable,
          check_field: checkField,
          check_condition: rule.uniqueCheck.UniqueCheckCondition
        };
        this.get("Form.ashx", params, {
          success: function(req) {
            if (req && req.data) {
              // 校验成功
              if (callback) {
                callback();
              }
            } else {
              if (callback) {
                callback(rule.message);
              }
            }
          },
          error: function() {
            callback();
          }
        });
      } else {
        if (callback) {
          callback();
        }
      }
    }
  }
};
