/** 一些系统级接口 */
export const systemService = {
  data() {
    return {};
  },
  created() {},
  methods: {
    /**
     * 获取系统菜单
     * @param callback
     */
    loadMenuList(callback) {
      let that = this;
      let params = {
        action: "GetLeftMenu",
        randomKey: this.$store.getters.getRandomKey
      };
      try {
        that.info.destroy();
      } catch {}
      this.getFormSNGenerateRules();
      this.get("System.ashx", params, {
        notTimeOut: true,
        success: res => {
          //console.log("登录获取到的菜单：",res);
          let tempList = res.data;
          for (let i = 0; i < tempList.length; i++) {
            if (!tempList[i].iconCls || tempList[i].iconCls === "") {
              //设置一个默认图标
              tempList[i].iconCls = "icon-zhbb";
            }
          }
          that.$util.setMenuKey(tempList);
          //console.log(tempList);
          that.$store.commit("setMenu", tempList);
          if (callback) callback();
        },
        error: function(error) {
          console.error("获取菜单失败：", error);
          that.info = that.$error({
            title: "提示",
            content: "获取菜单失败，服务异常"
          });
          if (callback) callback();
        }
      });
    },
    /**
     * 获取后台警告信息
     * @param {Function} callback
     */
    getNoticeMessage: function(callback) {
      let params = {
        action: "RemindInfo",
        randomKey: this.$store.getters.getRandomKey
      };
      let that = this;
      // 获取配料单，草稿状态的
      this.get("System.ashx", params, {
        success: res => {
          //console.log("登录获取到的菜单：",res);
          let params1 = {
            action: "GetNotices",
            DHBM: 256,
            o_Event: 0,
            randomKey: this.$store.getters.getRandomKey,
            searchStr: 139,
            searchField: "BDMBID"
          };
          that.get("WorkFlow.ashx", params1, {
            success: function(req) {
              let allNotices = res.data || [];
              if (req.data && req.data.length > 0) {
                console.log("获取到了配料单草稿数据", req.data);
                allNotices.push({
                  type: "openApprovalForm",
                  message:
                    "收到" + req.data.length + "条生产配料出库单，请进行配料",
                  value: req.data
                });
              }
              if (callback) callback(allNotices);
            },
            error: function(error) {
              console.error("获取弹窗消息失败：", error);
              if (callback) callback();
            }
          });
        },
        error: function(error) {
          console.error("获取弹窗消息失败：", error);
          if (callback) callback();
        }
      });
    },
    /**
     * 从后台获取一些有关表单相关编号的配置信息
     * @param {Function} callback
     */
    getFormSNGenerateRules: function() {
      let params = {
        action: "GetFormSNGenerateRules",
        randomKey: this.$store.getters.getRandomKey
      };
      let that = this;
      this.get("Form.ashx", params, {
        success: res => {
          if (res.data) {
            let lenght = res.data.length;
            let linkRule = null;
            let otherConfig = [];
            let lastRuleIndex = 0;
            if (!res.data[lenght - 1].rule) {
              // 说明有非链接规则配置
              for (let i = lenght - 1; i >= 0; i--) {
                if (res.data[i].rule) {
                  lastRuleIndex = i;
                  break;
                }
                otherConfig.push(res.data[i]);
              }
              if (lastRuleIndex > 0)
                linkRule = res.data.slice(0, lastRuleIndex);
              else linkRule = res.data;
            } else {
              linkRule = res.data;
            }
            that.$store.commit("setSystemConfigs", {
              FormBHRules: linkRule,
              FormDataConfig: otherConfig
            });
          } else console.error("获取表单相关编号配置失败：未返回配置");
        },
        error: function(error) {
          console.error("获取表单相关编号配置失败：", error);
        }
      });
    },
    /**
     * 保存日志到后台接口
     * @param logDatas
     */
    logToBackgroundSystem: function(logDatas) {
      let params = {
        action: "RecordSaveLog",
        data: JSON.stringify(logDatas),
        randomKey: this.$store.getters.getRandomKey
      };
      this.post("System.ashx", params, {
        success: res => {
          console.warn("已记录错误日志到后台",res)
        },
        error: function(error) {
          console.error("记录日志失败：", error);
        }
      });
    },
    /**
     * 从数据库指定表中获取数据的接口方法
     * @param listFilter
     * @param defaultValue
     * @param callBack
     */
    getDBTableDatas_MiXin: function(listFilter, callBack) {
      let params = {
        action: "GetXLdata",
        SourceTableName: listFilter.sourceTable ? listFilter.sourceTable : "",
        SourceFieldName: listFilter.sourceFieldName
          ? listFilter.sourceFieldName
          : "",
        filter: listFilter.filter
      };

      //console.log("select通过查询条件",listFilter, eTableIsSave);
      this.post("Form.ashx", params, {
        success: function(res) {
          if (callBack) {
            callBack(res);
          }
        },
        error: function(err) {
          console.error("获取指定表数据失败", err);
          if (callBack) callBack();
        }
      });
    }
  }
};
