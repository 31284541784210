var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body",staticStyle:{"margin":"0px !important"}},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":'/img/login3/' + _vm.config.logoUrl}})]),_c('div',{staticClass:"box"},[_c('div',{staticStyle:{"height":"90%","position":"relative"}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.config.systemName))]),_c('a-form-item',{attrs:{"prop":"account"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.userInputRules),expression:"userInputRules"}],ref:"userInput",style:({ width: '420px', padding: '10px 0' }),attrs:{"allowClear":true,"placeholder":"请输入账号","size":"large"},on:{"change":_vm.trimInput,"pressEnter":_vm.handleSubmit}},[_c('template',{slot:"prefix"},[_c('a-icon',{style:({ fontSize: '24px' }),attrs:{"type":"user"}})],1)],2)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.passInputRules),expression:"passInputRules"}],staticClass:"login-password-input",style:({ width: '420px', padding: '10px 0' }),attrs:{"type":"password","placeholder":"请输入密码"},on:{"change":_vm.trimInput,"pressEnter":_vm.handleSubmit}},[_c('template',{slot:"prefix"},[_c('a-icon',{style:({ fontSize: '24px' }),attrs:{"type":"lock"}})],1)],2)],1),_c('a-form-item',[_c('a-button',{style:({
              width: '420px',
              margin: '10px 0',
              height: '50px',
              'font-size': '20px',
              'background-image':
                'linear-gradient(to right, #569be8 , #351bc6)'
            }),attrs:{"type":"primary","loading":_vm.loading},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_vm._v("登录 ")])],1)],1)],1),_c('div',{staticClass:"bottom-text"},[_vm._v(_vm._s(_vm.config.companyName)),_c('a',{attrs:{"href":_vm.config.archivalInformationUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.config.archivalInformation))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }