/**
 * E1版-模板解析控件
 */
import _ from "lodash";
import moment from "moment";
import util from "../utils/util.js";

/**
 * E1端日期时间控件对应的显示格式配置
 * @type {{Long: {mode: string, format: string}, Short: {mode: string, format: string}, Time: {mode: string, format: string}}}
 */
const CXS_DATETIME_FORMATS = {
  Long: {
    mode: "date",
    format: "YYYY年MM月DD日"
  },
  Short: {
    mode: "date",
    format: "YYYY-MM-DD"
  },
  Time: {
    mode: "time",
    format: "hh:mm:ss"
  }
};
/**
 * 将控件的checkType值转换为可用的CXS_TO_ECONTROLS元素的type类型
 * @type {{密码框: string}}
 */
const CXS_CHECKTYPE_TO_TYPE = {
  密码框: "password",
};
/**
 * E1端控件和本系统内置控件的对应配置,
 * 注：如控件需要调取的接口非默认的控件数据请求接口，可以使用serverName: "System.ashx"参数进行设定
 * @type Object
 */
const CXS_TO_ECONTROLS = {
  CxsLabel: {
    name: "Text"
  },
  CxsTextBox: {
    name: "EInput" // 对应的本地控件
  },
  CxsRichTextBox: {
    name: "EInput",
    type: "textarea"
  },
  CxsDateTimePicker: {
    name: "EDatetimePicker",
    mode: "datetime",
    format: "YYYY年M月D日 H时m分"
  },
  CxsComboBox: {
    name: "ESelect",
    action: "GetXLdata"
  },
  CxsDepartment: {
    name: "EDepartmentSelect",
    treeAction: "GetDepartments",
    requiredPattern: /^\+?[1-9][0-9]*$/ //和必填校验共用，值必须是大于0的整数
  },
  CxsStaffer: {
    name: "EEmployeeSelect",
    treeAction: "getUsers",
    requiredPattern: /^\+?[1-9][0-9]*$/ //和必填校验共用，值必须是大于0的整数
  },
  CxsCheckBox: {
    name: "ECheckbox"
  },
  CxsRadioButton: {
    name: "ERadio"
  },
  CxsProvider: {
    name: "ESupplierSelect",
    treeAction: "GetGYSLX",
    nodeAction: "GetSuppliers"
  },
  CxsNumericUpDown: {
    name: "ENumericUpDown"
  },
  CxsGrid: {
    name: "ETable"
  },
  CxsMaterials: {
    name: "EMaterials",
    treeAction: "GetWZFLList",
    nodeAction: "GetWZInfo"
  },
  CxsWZFL: {
    // 物资分类控件
    name: "EMaterialsClassify"
  },
  CxsBooleanCheckbox: {
    name: "EBooleanCheckbox"
  },
  CxsGY_BDMB: {
    name: "EGyBdmb",
    treeAction: "GetTemplate"
  },
  CxsCustomer: {
    name: "ECustomer",
    treeAction: "GetCustomerList",
    nodeAction: "GetCustomerChilren"
  },
  CxsRS_GJDY: {
    name: "ERsGjdy",
    treeAction: "GetZWDYList"
  },
  CxsUploadFileList: {
    name: "EUpload",
    serverName: "System.ashx",
    action: "Upload"
  },
  CxsGroupBox: {
    name: "EGroupBox"
  },
  CxsCW_KJKM: {
    name: "EAccountingSubject"
  },
  CxsZJGSTextArea: {
    // 折旧公式控件
    name: "EZjgs"
  },
  CxsXZXMGSTextArea: {
    // 薪资公式控件
    name: "EXzjs"
  },
  CxsCKXZ: {
    // 仓库选择控件
    name: "EInventoryLocation"
  },
  CxsHWXX: {
    // 仓库货位选择控件
    name: "ESTWarehouse"
  }
};

let templateParser;
templateParser = {
  showLog: false,
  controlMapping: CXS_TO_ECONTROLS,
  controlCheckTypes: CXS_CHECKTYPE_TO_TYPE,
  containerControls: [
    "CxsForm",
    "CxsPanel",
    "CxsGroupBox",
    "CxsTabControl",
    "TabPage",
    "CxsGrid"
  ], // 所有的容器控件
  inputControls: [
    "CxsTextBox",
    "CxsRichTextBox",
    "CxsNumericUpDown",
    "ENumberHorizon"
  ], // 输入型控件
  needLayOutControls: [
    "CxsBooleanCheckbox",
    "CxsCheckBox",
    "CxsRadioButton",
    "CxsComboBox",
    "CxsCustomer",
    "CxsCW_KJKM",
    "CxsCWJCGSTextArea",
    "CxsDateTimePicker",
    "CxsDepartment",
    "CxsGroupBox",
    "CxsGY_BDMB",
    "CxsMaterials",
    "CxsCKXZ",
    "CxsHWXX",
    "CxsWZFL",
    "CxsNumericUpDown",
    "CxsProvider",
    "CxsRichTextBox",
    "CxsRS_GJDY",
    "CxsStaffer",
    "CxsTabControl",
    "CxsUploadFileList",
    "CxsXZXMGSTextArea",
    "CxsZJGSTextArea",
    "CxsTextBox",
    "CxsGrid",
    "CxsLabel",
    "ETimeHorizon",
    "ENumberHorizon"
  ], //需要参与页面布局的控件
  thisMainTableName: null,
  thisBiuniqueSubTable: null, // 非一对多明细表，区别于使用CxsGrid管理的明细表
  allFormulaConfigsMiXin: [], // 缓存解析过程获取到的公式配置，以及动态过滤条件等包含动态参数的配置
  /**
   * 控制整个解析器的打印日志输出的方法
   * @param vars
   */
  renderConsoleLog: function(...vars) {
    if (this.showLog) {
      //console.log("是否打印" + this.showLog + "\n");
      let len = vars.length;
      for (let i = 0; i < len; i++) {
        if (
          util.isType(vars[i]) === "String" ||
          util.isType(vars[i]) === "Boolean" ||
          util.isType(vars[i]) === "val"
        )
          console.log(vars[i] + "\n");
        else console.log(JSON.stringify(vars[i]) + "\n");
      }
    }
  },
  /**
   * IE浏览器dom解析兼容写法,获取节点属性
   * @param element
   * @param name
   * @returns {ASTNode|string|*}
   */
  getElementAttribuie: function(element, name) {
    let attr = element.attributes[name];
    if (attr && attr.value) {
      return attr.value;
    } else {
      return element.getAttribute(name);
    }
  },
  /**
   * IE浏览器dom解析兼容写法，获取指定节点名称的节点对象数组
   * @param doc
   * @param tagName
   * @returns {HTMLCollectionOf<SVGElementTagNameMap[*]>|ActiveX.IXMLDOMNodeList|HTMLCollectionOf<HTMLElementTagNameMap[*]>|HTMLCollectionOf<Element>|undefined|NodeListOf<HTMLElementTagNameMap[*]>|NodeListOf<Element>|NodeListOf<SVGElementTagNameMap[*]>}
   */
  querySelectorAll: function(doc, tagName) {
    try {
      let allElement = doc.querySelectorAll(tagName);
      if (allElement) {
        return allElement;
      }
    } catch (e) {
      return doc.getElementsByTagName(tagName);
    }
  },
  /**
   * IE浏览器dom解析兼容写法，检查节点为非文本节点
   * @param obj
   * @returns Boolean
   */
  checkNoTextNode: function(obj) {
    if (obj instanceof Element || obj.nodeType == 1 || obj.nodeType == 9) {
      return true;
    }
    return false;
  },
  /**
   * IE浏览器dom解析兼容写法，检查节点的文本内容
   * @param obj
   * @returns {*}
   */
  getNodeInnerHtml: function(obj) {
    return obj.innerHTML
      ? obj.innerHTML
      : obj.childNodes[0]
      ? obj.childNodes[0].nodeValue
      : "";
  },
  /**
   * 将xml模板解析为表单
   * @param formTemplate 模板xml字符串
   * @param thisMainTableData 主表单数据绑定对象
   * @param getValOfFixedValueType 从配置中根据控件类型和固定值类型获取固定值的方法
   * @param layout 将控件按layout方法进行排版布局
   * @param mainTableName 表单绑定的主表表名
   */
  renderFormTemplate: function(
    formTemplate,
    thisMainTableData,
    getValOfFixedValueType,
    layout,
    mainTableName
  ) {
    this.thisMainTableName = mainTableName;
    // 替换默认布局方法
    if (layout && util.isType(layout) === "Function") {
      this.layoutGrid = layout;
    }
    // 原始控件名与目标控件名的映射
    let parser = new DOMParser();
    let xmlDocument = parser.parseFromString(formTemplate, "text/xml");

    // 第一步：从文档中把容器控件解析出来
    // 文档根节点，默认是CxsForm节点
    // 获取form、panel、tab、grid控件节点对象的结构,用于内嵌标签页面和表格的解析
    let tempObjXml = this.renderContainer(
      xmlDocument.documentElement,
      thisMainTableData,
      getValOfFixedValueType
    );
    this.renderConsoleLog("获取到的容器", tempObjXml);
    // 获取所有groupbox容器的配置
    let groupBoxObj = [];
    this.getAllObjByControlType(tempObjXml, "CxsGroupBox", groupBoxObj);
    let formGroup = []; // 外层表单中的group
    // 获取TabPage容器的配置
    let tabPages = [];
    this.getAllObjByControlType(tempObjXml, "TabPage", tabPages);
    this.renderConsoleLog("tabPages-------------------》", tabPages);
    // 从tabPages中找出包含的groupBox,排除
    for (let x = 0; x < groupBoxObj.length; x++) {
      if (
        !this.getObjByParamValue(
          tabPages,
          "controlName",
          groupBoxObj[x].controlName
        )
      ) {
        formGroup.push(groupBoxObj[x]);
      }
    }
    this.renderConsoleLog("解析到了在主表单中的gropuBox容器：", groupBoxObj);
    // 第二步：把文档中的主表单包含的控件解析出来，（如果包含groupbox控件，整个groupbox当做一个表单控件来解析排序：groupbox会被嵌入到主表单中）
    //获取所有Object标签的元素节点
    //debugger
    let controlElements = this.querySelectorAll(xmlDocument, "Object"); //querySelectorAll
    // 从所有Object标签的控件元素中过滤掉容器组件以及容器组件包含的其他控件，并解析这些剩余控件的所有属性配置
    let controlDefines = this.renderControlObject(
      controlElements,
      thisMainTableData,
      formGroup,
      tabPages
    );
    // 将以上解析出来的控件按y、x轴坐标升序排列
    controlDefines = this.sortDefineByLocation(controlDefines);
    // 处理复选框组
    this.getCheckBoxDefines(controlDefines);
    // 处理单选框组
    this.getRadioDefines(controlDefines);
    //设置对应表单控件的绑定数据对象
    if (thisMainTableData)
      this.setControlDefaultValue(
        controlDefines,
        thisMainTableData,
        getValOfFixedValueType
      );

    this.renderConsoleLog(
      "排序后controlDefines",
      JSON.stringify(controlDefines)
    );

    // 第三步：把解析出来的控件进行排版，定位
    // 把所有控件按行排列
    let rows = this.getRowsControls(controlDefines);
    // 解析原始控件元素 -end
    this.renderConsoleLog("排列处理过的定义：", JSON.stringify(rows));
    //采用栅格控件，计算最多的一行，控件数量是多少
    let layoutGrid = this.layoutGrid(rows);
    this.renderConsoleLog("布局处理过的定义：", JSON.stringify(layoutGrid));
    this.renderConsoleLog("数据绑定对象", thisMainTableData);
    // 第四步：把除groupbox控件外的容器控件配置加入返回数据
    // 查询是否使用了其他容器
    let grids = [];
    tempObjXml.controlDefine.mxTableName = []; // 获取明细表名称
    tempObjXml.controlDefine.mxTableRequiredConfig = []; // 获取明细表对应的必填状态
    let tabControlObj = this.getObjByControlType(tempObjXml, "CxsTabControl"); // 查询是否使用了tab容器
    let formulaConfigs = [].concat(this.allFormulaConfigsMiXin);
    this.allFormulaConfigsMiXin = [];
    let formConfigs = {
      formulaConfigs: formulaConfigs
    };
    formConfigs = Object.assign({}, formConfigs, layoutGrid);
    if (this.thisBiuniqueSubTable) {
      formConfigs.biuniqueSubTable = [].concat(this.thisBiuniqueSubTable);
      this.thisBiuniqueSubTable = null;
    }
    if (!tabControlObj) {
      // 是否有tab容器
      this.getAllObjByControlType(tempObjXml, "CxsGrid", grids);
      //let grid  = this.getObjByControlType(tempObjXml, "CxsGrid");
      //detailRequiredConfig
      if (grids && grids.length > 0) {
        // 是否有grid容器
        this.renderConsoleLog("有非tab切换的明细表：", grids);
        for (let y = 0; y < grids.length; y++) {
          tempObjXml.controlDefine.mxTableName.push(
            util.trimStr(grids[y].controlDefine.tableName)
          );
          tempObjXml.controlDefine.mxTableRequiredConfig.push({
            tableName: util.trimStr(grids[y].controlDefine.tableName),
            required: grids[y].controlDefine.required
          });
        }
        formConfigs.formDefine = tempObjXml.controlDefine;
        formConfigs.mxForm = grids;
        return formConfigs;
      } else {
        formConfigs.formDefine = tempObjXml.controlDefine;
        // 无明细表
        return formConfigs;
      }
    } else {
      this.renderConsoleLog("有多Tab：", tabControlObj);
      this.getAllObjByControlType(tabControlObj, "CxsGrid", grids);
      for (let z = 0; z < grids.length; z++) {
        let nowTableName = util.trimStr(grids[z].controlDefine.tableName);
        tempObjXml.controlDefine.mxTableName.push(nowTableName);
        let thisTabPage = tabControlObj.childObject.find(function(tabPage) {
          let obj = util.getObjByParamAndValue(
            tabPage,
            "tableName",
            nowTableName
          );
          return obj != null;
        });
        this.renderConsoleLog(
          "对应表：",
          nowTableName,
          "所在的tab",
          thisTabPage
        );
        let tabName = thisTabPage
          ? util.trimStr(thisTabPage.controlDefine.text)
          : null;
        tempObjXml.controlDefine.mxTableRequiredConfig.push({
          tableName: nowTableName,
          required: grids[z].controlDefine.required,
          name: tabName
        });
      }
      this.renderConsoleLog("Tab下有明细表：", grids);
      formConfigs.formDefine = tempObjXml.controlDefine;
      formConfigs.mxForms = tabControlObj;
      return formConfigs;
    }
  },
  /**
   * 解析容器节点的结构
   * @param thisControlElement
   * @param thisMainTableData
   * @returns {*}
   */
  renderContainer: function(
    thisControlElement,
    thisMainTableData,
    getValOfFixedValueType
  ) {
    // 获取当前节点的type和Name
    let controls = this.getElementControlNameAndType(thisControlElement);
    let controlElements = [];
    let controlDefine = {
      name: "",
      location: { x: 0, y: 0 }, // 控件位置
      size: { w: 0, h: 0 }, // 尺寸
      text: "", // 内部文本，对于不同的控件来说有不同的含义（例如label中表示文本，textbox表示默认值）
      // columns: [] // 如果控件类型是CxsGrid，则会有columns属性
      configs: {} //其他控件属性
    };
    // 设置一些组件专有属性的必要默认值
    if (this["set_" + controls.controlType + "_DefaultDefine"]) {
      this["set_" + controls.controlType + "_DefaultDefine"](controlDefine);
    }
    // 当前节点的子节点
    let thisChildControlElements = thisControlElement.childNodes;
    for (let y = 0; y < thisChildControlElements.length; y++) {
      let element = thisChildControlElements[y];
      if (element.nodeName === "Object") {
        // 包含的子Object对象节点
        let elementNameAndType = this.getElementControlNameAndType(element);
        if (
          this.containerControls.indexOf(elementNameAndType.controlType) >= 0
        ) {
          // 只解析容器控件节点
          if (elementNameAndType.controlType === "CxsGroupBox")
            // 单独解析CxsGroupBox
            controlElements.push(
              this.renderGroupBox(
                element,
                thisMainTableData,
                getValOfFixedValueType
              )
            );
          else if (elementNameAndType.controlType === "TabPage")
            // 单独解析TabPage
            controlElements.push(
              this.renderTabPage(
                element,
                thisMainTableData,
                getValOfFixedValueType
              )
            );
          else
            controlElements.push(
              this.renderContainer(
                element,
                thisMainTableData,
                getValOfFixedValueType
              )
            );
        }
      } else if (this.checkNoTextNode(element) && element.hasChildNodes()) {
        // 包含的节点的Property属性节点
        let elementName = this.getElementAttribuie(element, "name"); // element.attributes["name"].value;
        controlDefine.name = elementName;
        // 对象的通用属性
        this.getControlsUniversalDefine(
          controlDefine,
          elementName,
          this.getNodeInnerHtml(element)
        );
        // 对象的专用属性
        if (this["render_" + controls.controlType + "_ProprietaryDefine"]) {
          this["render_" + controls.controlType + "_ProprietaryDefine"](
            controlDefine,
            element
          );
        }
      }
    }
    controls.controlDefine = controlDefine;
    controls.childObject = controlElements;
    return controls;
  },
  /**
   * 解析TabPage容器节点的结构
   * @param thisControlElement
   * @param thisMainTableData
   */
  renderTabPage: function(
    thisControlElement,
    thisMainTableData,
    getValOfFixedValueType
  ) {
    let controls = this.getElementControlNameAndType(thisControlElement);
    if (
      controls.controlType === "TabPage" ||
      this.containerControls.indexOf(controls.controlType) == -1
    ) {
      // 当前节点的子节点
      let thisChildControlElements = thisControlElement.childNodes;
      let controlElements = []; // 包含的控件对象
      let childObject = []; // 包含的容器对象
      let childGroupBox = [];
      let childGrid = []; // tab包含表
      let controlDefine = {
        name: "",
        location: { x: 0, y: 0 }, // 控件位置
        size: { w: 0, h: 0 }, // 尺寸
        text: "", // 内部文本，对于不同的控件来说有不同的含义（例如label中表示文本，textbox表示默认值）
        // columns: [] // 如果控件类型是CxsGrid，则会有columns属性
        configs: {} //其他控件属性
      };
      controlDefine = Object.assign(controlDefine, controls);
      // 设置一些组件专有属性的必要默认值
      if (this["set_" + controls.controlType + "_DefaultDefine"]) {
        this["set_" + controls.controlType + "_DefaultDefine"](controlDefine);
      }
      // 遍历当前节点的子节点进行解析
      for (let y = 0; y < thisChildControlElements.length; y++) {
        let element = thisChildControlElements[y];
        if (element.nodeName === "Object") {
          // 包含的子控件Object对象节点
          let objInfo = this.getElementControlNameAndType(element);
          if (this.containerControls.indexOf(objInfo.controlType) >= 0) {
            // 如果tab内还包含有容器控件，则需要递归去解析该容器
            if (objInfo.controlType === "CxsGroupBox")
              childGroupBox.push(
                this.renderGroupBox(
                  element,
                  thisMainTableData,
                  getValOfFixedValueType
                )
              );
            else if (objInfo.controlType === "CxsGrid") {
              // 如果tab标签页内嵌表格
              childGrid.push(
                this.renderContainer(
                  element,
                  thisMainTableData,
                  getValOfFixedValueType
                )
              );
            } else {
              childObject.push(
                this.renderContainer(
                  element,
                  thisMainTableData,
                  getValOfFixedValueType
                )
              );
            }
          } else {
            // 其他非容器控件，在下面进行排序解析
            controlElements.push(element);
          }
        } else if (this.checkNoTextNode(element) && element.hasChildNodes()) {
          // 包含的节点的Property属性节点
          let elementName = this.getElementAttribuie(element, "name"); //element.attributes["name"].value;
          controlDefine.name = elementName;
          // 对象的通用属性
          this.getControlsUniversalDefine(
            controlDefine,
            elementName,
            this.getNodeInnerHtml(element)
          );

          // 对象的专用属性
          if (this["render_" + controls.controlType + "_ProprietaryDefine"]) {
            this["render_" + controls.controlType + "_ProprietaryDefine"](
              controlDefine,
              element
            );
          }
        }
      }
      if (controlElements.length > 0 || childGroupBox.length > 0) {
        controlElements = this.renderControlObject(
          controlElements,
          thisMainTableData,
          childGroupBox
        );
        // 按y、x轴坐标升序排列
        controlElements = this.sortDefineByLocation(controlElements);
        this.renderConsoleLog("TabPage内部组件排序后", controlElements);
        // 处理复选框组
        this.getCheckBoxDefines(controlElements);
        // 处理单选框组
        this.getRadioDefines(controlElements);
        //设置对应表单控件的绑定数据对象
        if (thisMainTableData)
          this.setControlDefaultValue(
            controlElements,
            thisMainTableData,
            getValOfFixedValueType
          );
        this.renderConsoleLog(
          "TabPage-分行前处理的定义：",
          JSON.stringify(controlElements)
        );
        // 把所有控件按行排列
        let rows = this.getRowsControls(controlElements);
        // 解析原始控件元素 -end
        this.renderConsoleLog(
          "TabPage-layout前处理的定义：",
          JSON.stringify(rows)
        );
        //采用栅格控件，计算最多的一行，控件数量是多少
        let layoutGrid = this.layoutGrid(rows);
        controlDefine = Object.assign(controlDefine, layoutGrid);

        this.renderConsoleLog(
          "TabPage处理过的定义：",
          JSON.stringify(layoutGrid)
        );
        if (childGrid.length > 0) controlDefine.childGrid = childGrid; // tab页表单中包含表格
      }
      childObject = childObject.concat(controlElements);
      childObject = childObject.concat(childGroupBox);
      childObject = childObject.concat(childGrid);
      controls.controlDefine = controlDefine;
      controls.childObject = childObject;
      return controls;
    }
  },
  /**
   * 解析CxsGroupBox容器节点的结构
   * @param thisControlElement
   * @param thisMainTableData
   * @returns {*}
   */
  renderGroupBox: function(
    thisControlElement,
    thisMainTableData,
    getValOfFixedValueType
  ) {
    this.renderConsoleLog("解析CxsGroupBox");
    // 获取当前节点的type和Name
    let controls = this.getElementControlNameAndType(thisControlElement);
    if (
      controls.controlType === "CxsGroupBox" ||
      this.containerControls.indexOf(controls.controlType) == -1
    ) {
      let controlElements = [];
      let controlDefine = {
        name: "",
        location: { x: 0, y: 0 }, // 控件位置
        size: { w: 0, h: 0 }, // 尺寸
        text: "", // 内部文本，对于不同的控件来说有不同的含义（例如label中表示文本，textbox表示默认值）
        // columns: [] // 如果控件类型是CxsGrid，则会有columns属性
        configs: {} //其他控件属性
      };
      controlDefine = Object.assign(controlDefine, controls);
      // 设置一些组件专有属性的必要默认值
      if (this["set_" + controls.controlType + "_DefaultDefine"]) {
        this["set_" + controls.controlType + "_DefaultDefine"](controlDefine);
      }
      // 当前节点的子节点
      let thisChildControlElements = thisControlElement.childNodes;
      for (let y = 0; y < thisChildControlElements.length; y++) {
        let element = thisChildControlElements[y];
        if (element.nodeName === "Object") {
          // 包含的子控件Object对象节点
          controlElements.push(element);
        } else if (this.checkNoTextNode(element) && element.hasChildNodes()) {
          // 包含的节点的Property属性节点
          let elementName = this.getElementAttribuie(element, "name"); //element.attributes["name"].value;
          controlDefine.name = elementName;
          // 对象的通用属性
          this.getControlsUniversalDefine(
            controlDefine,
            elementName,
            this.getNodeInnerHtml(element)
          );
          // 对象的专用属性
          if (this["render_" + controls.controlType + "_ProprietaryDefine"]) {
            this["render_" + controls.controlType + "_ProprietaryDefine"](
              controlDefine,
              element
            );
          }
        }
      }
      if (controlElements.length > 0) {
        controlElements = this.renderControlObject(
          controlElements,
          thisMainTableData
        );
        // 按y、x轴坐标升序排列
        controlElements = this.sortDefineByLocation(controlElements);
        // 处理复选框组
        this.getCheckBoxDefines(controlElements);
        // 处理单选框组
        this.getRadioDefines(controlElements);
        //设置对应表单控件的绑定数据对象
        if (thisMainTableData)
          this.setControlDefaultValue(
            controlElements,
            thisMainTableData,
            getValOfFixedValueType
          );
        // 把所有控件按行排列
        let rows = this.getRowsControls(controlElements);

        // 解析原始控件元素 -end
        this.renderConsoleLog("groupoBox处理过的定义：", JSON.stringify(rows));

        //采用栅格控件，计算最多的一行，控件数量是多少
        let layoutGrid = this.layoutGrid(rows);
        controlDefine = Object.assign(controlDefine, layoutGrid);
      }
      controls.controlDefine = controlDefine;
      controls.childObject = controlElements;
      return controls;
    }
    return null;
  },
  /**
   * 把所有控件按行排列
   * @param controlDefines
   * @returns {Array}
   */
  getRowsControls: function(controlDefines) {
    let rows = [];
    // y轴偏离误差（在误差范围内的都算在同一行）
    let rowYAxisError = 12; // 对表单的编辑有严格要求，否则解析显示会出错！！！
    //let rowGroupBoxYAxisError = 50;
    controlDefines.forEach(controlDefine => {
      if (this.needLayOutControls.indexOf(controlDefine.controlType) >= 0) {
        // 如果是需要被布局到页面的控件
        if (controlDefine.text !== "*") {
          // 过滤掉无效label内容
          // 标题控件
          let row = rows.find(
            tRow => controlDefine.location.y - tRow.rowYAxis < rowYAxisError
          );
          if (row) {
            row.columns.push(controlDefine);
          } else {
            row = {
              rowYAxis: controlDefine.location.y,
              columns: []
            };
            row.columns.push(controlDefine);
            rows.push(row);
          }
        }
      }
    });
    this.renderConsoleLog("分行列后", rows);
    let that = this;
    // 删除多个连在一起的label控件的除最后一个的其他控件
    rows.forEach(row => {
      row.columns = _.sortBy(row.columns, column => column.location.x);
      for (let r = row.columns.length - 1; r >= 0; r--) {
        if (
          row.columns[r].controlType !== "CxsLabel" &&
          that.containerControls.indexOf(row.columns[r].controlType) < 0 &&
          row.columns[r].configs.fieldName === ""
        ) {
          // 移除掉fieldName未配置的控件
          console.log(
            "%c 移除未设置fieldName的控件：",
            "background-color: red; color: white; font-weight: bold;",
            Object.assign({}, row.columns[r].configs)
          );
          row.columns.splice(r, 1);
        }
      }
    });

    return rows;
  },
  /**
   * 把数组controlDefines根据location的坐标系进行排序
   * @param controlDefines
   */
  sortDefineByLocation: function(controlDefines) {
    // 按y、x轴坐标升序排列
    // 先按y轴排序
    let tempControls = _.sortBy(controlDefines, cd => {
      return cd.location.y;
    });
    // 修正y轴
    let tempY = 0;
    let wcY = 15;
    for (let i = 0; i < tempControls.length; i++) {
      let thisY = tempControls[i].location.y + tempControls[i].size.h / 2; // 取控件的高度中间点作为校验y轴
      if (tempY == 0) tempY = thisY;
      if (i == tempControls.length - 1) {
        // 到最后一个了
        if (thisY == tempY || Math.abs(thisY - tempY) <= wcY) {
          //认为是同一行
          tempControls[i].location.y = tempY;
        }
      } else {
        let nextY =
          tempControls[i + 1].location.y + tempControls[i + 1].size.h / 2;
        if (nextY == tempY || Math.abs(nextY - tempY) <= wcY) {
          //认为是同一行
          tempControls[i].location.y = tempY;
          tempControls[i + 1].location.y = tempY;
        } else {
          // 新的一行
          tempY = nextY;
        }
      }
    }
    // 最后先y再x轴排序
    return _.sortBy(
      tempControls,
      cd => {
        return cd.location.y;
      },
      cd => {
        return cd.location.x;
      }
    );
  },
  /**
   * 解析所有的非容器控件
   * @param controlElements
   * @param thisMainTableData
   * @param groupBoxObj // 包含需要controlElements中排除的控件
   * @param tabPages //  包含需要controlElements中排除的控件
   * @returns {Array}
   */
  renderControlObject: function(
    controlElements,
    thisMainTableData,
    groupBoxObj,
    tabPages
  ) {
    let controlDefines = [];
    let haveGroupBoxObj = false;
    let haveTabPage = false;
    // 是否存在需要从controlElements 中排除的控件
    if (groupBoxObj && groupBoxObj.length > 0) {
      haveGroupBoxObj = true;
      //groupBoxObj.controlDefine.formRows = groupBoxObj.formRows; // 把其子节点对象赋予控件
      for (let z = 0; z < groupBoxObj.length; z++) {
        controlDefines.push(groupBoxObj[z].controlDefine); // groupBox的位置定义信息用于排版排序
      }
    }
    if (tabPages && tabPages.length > 0) {
      haveTabPage = true;
    }
    // 解析原始控件元素 -begin
    for (let i = 0; i < controlElements.length; i++) {
      let controlElement = controlElements[i];
      let controlTypeAndName = this.getElementControlNameAndType(
        controlElement
      );
      if (this.containerControls.indexOf(controlTypeAndName.controlType) >= 0) {
        continue; // 跳过容器节点，只解析控件对象节点
      }
      // 跳过包含在 groupBox 容器中的控件
      if (haveGroupBoxObj) {
        if (
          this.getObjByParamValue(
            groupBoxObj,
            "controlName",
            controlTypeAndName.controlName
          )
        )
          // groupBoxObj-z是否是包含在主表单中
          continue;
      }
      // 跳过包含在 TabPage 容器中的控件
      if (haveTabPage) {
        if (
          this.getObjByParamValue(
            tabPages,
            "controlName",
            controlTypeAndName.controlName
          )
        ) {
          continue;
        }
      }
      let controlDefine = {
        name: "",
        controlType: "", // 控件类型
        location: { x: 0, y: 0 }, // 控件位置
        size: { w: 0, h: 0 }, // 尺寸
        text: "", // 内部文本，对于不同的控件来说有不同的含义（例如label中表示文本，textbox表示默认值）
        configs: {} //其他控件属性
      };
      //把属性name和type合并到controlDefine
      controlDefine = Object.assign(controlDefine, controlTypeAndName);
      //获取控件配置的属性
      let controlElementProperties = controlElement.childNodes;

      // 解析控件的通用属性
      for (let j = 0; j < controlElementProperties.length; j++) {
        let controlElementProperty = controlElementProperties[j];
        if (
          this.checkNoTextNode(controlElementProperty) &&
          controlElementProperty.hasChildNodes()
        ) {
          //是节点对象，并且有子节点 controlElementProperty.attributes["name"].value,
          this.getControlsUniversalDefine(
            controlDefine,
            this.getElementAttribuie(controlElementProperty, "name"),
            this.getNodeInnerHtml(controlElementProperty)
          );
        }
      }
      // 修改控件的校验条件
      this.updateCheckPatternRule(controlDefine);
      //设置一些组件专有属性的必要默认值
      if (this["set_" + controlDefine.controlType + "_DefaultDefine"])
        this["set_" + controlDefine.controlType + "_DefaultDefine"](
          controlDefine
        );

      // 根据控件类型，解析控件的特殊组件专有属性:使用对应方法如：render_CxsGrid_ProprietaryDefine
      if (this["render_" + controlDefine.controlType + "_ProprietaryDefine"]) {
        for (let j = 0; j < controlElementProperties.length; j++) {
          if (this.checkNoTextNode(controlElementProperties[j])) {
            this["render_" + controlDefine.controlType + "_ProprietaryDefine"](
              controlDefine,
              controlElementProperties[j]
            );
          }
        }
      }
      // 跳过CxsDateTimePicker等控件嵌套在CxsLabel中的这种情况（例如销售专用发票表单）
      if (controlDefine.location.y == -1 || controlDefine.location.x == -1) {
        continue;
      }
      //把E1端的控件名称，找到对应的vue控件名
      let formControlType;
      if (this.controlMapping[controlDefine.controlType]) {
        formControlType = this.controlMapping[controlDefine.controlType].name;
        controlDefine.setting = this.controlMapping[controlDefine.controlType];
      }
      controlDefine.thisControlType = formControlType; // 本地控件类型

      controlDefines.push(controlDefine);
    }
    return controlDefines;
  },

  /**
   * 计算依据栅格布局，应该如果排版控件,可以被外部方法覆盖
   * @param rows
   * @returns {{maxColumnCount: number, formRows: Array}}
   */
  layoutGrid: function(rows) {
    let that = this;
    let formRows = [];
    let maxColumnCount = 0;
    // 存储未被布局的控件列
    let noLayoutColumns = [];

    // 组织每行显示的控件
    rows.forEach(row => {
      let formControls = [];
      let thisRowHaveGroupBox = false; // 本行是否有gourpbox组件
      let moreControls = 0; // 多个控件公用一个标签
      let rowGroups = 0;
      for (let p = 0; p < row.columns.length; ) {
        // 把标签控件和对应的表单字段对应出来
        //that.renderConsoleLog("标签对象：",JSON.stringify(row.columns[i]));
        //that.renderConsoleLog("控件对象："+JSON.stringify(row.columns[i + 1]));
        if (
          row.columns[p].controlType === "CxsLabel" &&
          row.columns[p + 1] &&
          row.columns[p + 1].controlType !== "CxsLabel"
        ) {
          // 通常情况一个lalel后跟一个控件
          // 获取控件配置对应的本系统定义的vue控件名
          let controlLabel = row.columns[p].text;
          let controlProps = row.columns[p + 1];
          controlProps.size.labelw = row.columns[p].size.w;
          that.setOneLableControl(controlProps, controlLabel);
          formControls.push(controlProps);

          for (let q = p + 2; q < row.columns.length; q++) {
            if (
              row.columns[q].controlType !== "CxsLabel" &&
              row.columns[q].controlType !== "CxsGroupBox"
            ) {
              // 如果后续还有非标签组件
              let nextControlProps = row.columns[q];
              that.setOneLableControl(nextControlProps, "");
              formControls.push(nextControlProps);
              p++;
              moreControls++;
            } else if (
              row.columns[q].controlType === "CxsLabel" &&
              row.columns[q + 1] &&
              row.columns[q + 1].controlType !== "CxsLabel" &&
              row.columns[q].fontSize === "9pt"
            ) {
              // 如果后续跟着有意义标签组件,（除去表单标题控件）
              let nextControlProps = row.columns[q];
              controlProps.nextTip = nextControlProps.text; //组件注释内容
              p++;
            } else {
              break;
            }
          }
        } else if (row.columns[p].controlType === "CxsGroupBox") {
          // 如果是单独的一个groupBox控件
          rowGroups++;
          thisRowHaveGroupBox = true;
          let controlProps = row.columns[p];
          that.setOneLableControl(controlProps, controlProps.text);
          formControls.push(controlProps);
          p = p + 1;
          continue;
        } else if (
          row.columns[p].controlType === "CxsCheckBox" ||
          row.columns[p].controlType === "CxsRadioButton"
        ) {
          // 如果是单独的一个CxsCheckBox和CxsRadioButton,没有标签
          let controlProps = row.columns[p];
          that.setOneLableControl(controlProps, "");
          formControls.push(controlProps);
          p = p + 1;
          continue;
        } else if (row.columns[p].controlType === "CxsBooleanCheckbox") {
          // 如果是单独的一个CxsBooleanCheckbox,没有标签
          let controlProps = row.columns[p];
          //that.setOneLableControl(controlProps, controlProps.label);
          formControls.push(controlProps);
          p = p + 1;
          continue;
        } else if (
          row.columns[p].controlType === "CxsLabel" &&
          row.columns.length == 1 &&
          row.columns[p].fontSize === "9pt"
        ) {
          // 一行只有一个label，应该是一个注释
          console.log("label单独占一行", row.columns[p]);
          formControls.push(row.columns[p]);
          break;
        } else {
          noLayoutColumns.push(row.columns[p]);
          if (row.columns[p + 1]) {
            noLayoutColumns.push(row.columns[p + 1]);
          }
        }
        p = p + 2;
      }
      //debugger
      that.renderConsoleLog("处理过的Lable：", formControls);
      // 过滤掉不显示控件
      let noShowControls = formControls.filter(function(obj) {
        return obj.isShow === false;
      });
      let tempLength =
        formControls.length - noShowControls.length - moreControls; // 如果一行中无groupBox
      if (thisRowHaveGroupBox && formControls.length == rowGroups) {
        // 一个或多个groupBox独占一行
        tempLength = rowGroups;
      } else if (thisRowHaveGroupBox) {
        // 一行当中有groupBox和其他非容器控件
        tempLength = formControls.length - moreControls + rowGroups;
      } // 一行中无groupBox
      maxColumnCount = Math.max(maxColumnCount, tempLength);
      //组织每行显示的表单控件
      let thisColspan = tempLength > 0 ? Math.floor(24 / tempLength) : 24;
      formRows.push({
        key: Math.random(),
        columns: formControls,
        colSpan: thisColspan
      });
    });

    // todo 调试用代码
    if (noLayoutColumns.length) {
      console.log(
        "%c 表单中未被布局的元素：",
        "background-color: red; color: white; font-weight: bold;",
        noLayoutColumns
      );
    }

    // 处理未被布局的控件列
    for (let i = 0; i < noLayoutColumns.length; i += 2) {
      let formControls = [];

      // 处理二行元素中，上行元素是label控件而下行元素不是label控件的情况
      if (
        noLayoutColumns[i].controlType === "CxsLabel" &&
        noLayoutColumns[i + 1] &&
        noLayoutColumns[i + 1].controlType !== "CxsLabel"
      ) {
        let controlLabel = noLayoutColumns[i].text;
        let controlProps = noLayoutColumns[i + 1];

        controlProps.size.labelw = noLayoutColumns[i].size.w;
        that.setOneLableControl(controlProps, controlLabel);
        formControls.push(controlProps);

        formRows.push({
          key: Math.random(),
          columns: formControls,
          colSpan: 12
        });
      }
    }

    return {
      maxColumnCount: maxColumnCount,
      colSpan: Math.floor(24 / maxColumnCount),
      formRows: formRows
    };
  },
  /**
   * 把lable控件设置为后续控件的一个lable属性
   * @param controlProps
   * @param label
   */
  setOneLableControl: function(controlProps, label) {
    let formControlType;
    if (this.controlMapping[controlProps.controlType]) {
      formControlType = this.controlMapping[controlProps.controlType].name;
      if(this.controlMapping[controlProps.controlType]) {
        controlProps.setting = util.deepCopy(this.controlMapping[controlProps.controlType]);
        //如果设置了checkType，则需要根据配置转换为对应的type类型
        if (controlProps.configs.checkType) {
          let type = CXS_CHECKTYPE_TO_TYPE[controlProps.configs.checkType];
          if (type) {
            controlProps.setting.type = type;
          }
        }
      }
    }
    controlProps.label = label;
    if (label && label !== "") {
      // 根据label重新设置用户的message提示信息
      if (controlProps.configs.rules && controlProps.configs.rules.length > 0) {
        let that = this;
        // 是否有校验规则配置
        controlProps.configs.rules.forEach(function(cObj) {
          if (cObj.required) {
            if (that.inputControls.indexOf(controlProps.controlType) >= 0)
              cObj.message = "请输入" + label;
            else cObj.message = "请选择" + label;

            console.log(label, "=", cObj.message);
          }
        });
      }
      if (controlProps.configs.multiSelect) {
        controlProps.multiSelect = "multi";
      }
    }
    controlProps.thisControlType = formControlType; // 本地控件类型
  },
  /**
   * 从节点中解析出节点属性type和name的值
   * @param controlElement
   */
  getElementControlNameAndType: function(controlElement) {
    let controlDefine = {};
    //debugger
    controlDefine.controlName = this.getElementAttribuie(
      controlElement,
      "name"
    ); //controlElement.attributes["name"].value;
    let tempType = this.getElementAttribuie(controlElement, "type");
    let tempTypeArray = tempType.match(/UserComponents\.([^,]+)/);
    if (tempTypeArray && tempTypeArray.length > 1)
      controlDefine.controlType = tempTypeArray[1];
    else {
      tempTypeArray = tempType.match(/System.Windows.Forms\.([^,]+)/);
      controlDefine.controlType = tempTypeArray[1];
    }
    return controlDefine;
  },
  /**
   * 通用属性解析，从组件xml配置中解析出对应name属性的每个propertyValue包含的组件属性值，并赋给controlDefine对象
   * @param controlDefine
   * @param name
   * @param propertyValue
   */
  getControlsUniversalDefine: function(controlDefine, name, propertyValue) {
    switch (name) {
      case "Name": {
        // 控件名
        controlDefine.name = propertyValue;
        break;
      }
      case "Location": {
        // 控件位置
        if (propertyValue) {
          let x = propertyValue.split(",")[0];
          let y = propertyValue.split(",")[1];

          controlDefine.location = { x: parseInt(x), y: parseInt(y) };
        }
        break;
      }
      case "Size": {
        // 控件的大小
        if (propertyValue) {
          let w = propertyValue.split(",")[0];
          let h = propertyValue.split(",")[1];

          controlDefine.size = { w: parseInt(w), h: parseInt(h) };
        }
        break;
      }
      case "Text": {
        // 内部文本
        controlDefine.text = propertyValue;
        break;
      }
      case "ForeColor": {
        // 文本颜色
        controlDefine.foreColor = util.trimStr(propertyValue);
        break;
      }
      case "Font": {
        // 文字样式设置
        if (propertyValue) {
          let commProperty = propertyValue.split(",");
          if (commProperty) {
            //debugger
            controlDefine.fontSize = util.trimStr(commProperty[1]); // 字体大小
          }
        }
        break;
      }
      case "CommProperty": {
        // 解析通用属性，属性格式：制单人,,_ZDRID ,,,当前登录人,,Developer,True,0,
        //let commProperty = propertyValue.match(/^(.*),(.*),(.*),(.*),(.*),(.*),(.*),(.*),(.*),(.*),(.*)$/);
        if (propertyValue) {
          let commProperty = propertyValue.split(",");
          //this.renderConsoleLog("通用属性：", commProperty);
          if (commProperty) {
            //debugger
            controlDefine.configs.cnName = commProperty[0]; // 字段中文名
            controlDefine.configs.checkType = commProperty[1]; // 校验类型
            controlDefine.configs.tableName = util.trimStr(commProperty[3]); // 绑定表名
            if (
              controlDefine.configs.tableName !== "" &&
              this.thisMainTableName !== controlDefine.configs.tableName
            ) {
              // 在主表单中有特定绑定一个子表的字段
              controlDefine.configs.fieldName =
                controlDefine.configs.tableName +
                "." +
                util.trimStr(commProperty[2]); // 绑定字段名
              if (!this.thisBiuniqueSubTable) {
                this.thisBiuniqueSubTable = [];
                this.thisBiuniqueSubTable.push({
                  tableName: controlDefine.configs.tableName,
                  columns: [util.trimStr(commProperty[2])]
                });
              } else {
                let subTable = this.thisBiuniqueSubTable.find(function(
                  tbConfig
                ) {
                  return tbConfig.tableName === controlDefine.configs.tableName;
                });
                if (subTable) {
                  subTable.columns.push(util.trimStr(commProperty[2]));
                }
              }
            } else {
              controlDefine.configs.fieldName = util.trimStr(commProperty[2]); // 绑定字段名
            }
            controlDefine.configs.required =
              commProperty[4] && commProperty[4] === "必填"; // 是否必填
            controlDefine.configs.fixedValueType = util.trimStr(
              commProperty[5]
            ); // 固定值类型
            if (util.trimStr(commProperty[6]) !== "") {
              controlDefine.configs.formula = commProperty[6]; // 公式
              this.allFormulaConfigsMiXin.push({
                fieldName: commProperty[2],
                formula: commProperty[6]
              });
            }
            controlDefine.configs.developer = commProperty[7]; // ？开发者
            controlDefine.configs.excelImp = util.strIsTrue(commProperty[8]); // 可否excel导入
            controlDefine.configs.impSortNumber = commProperty[9]; // 导出字段排序号
            if (commProperty[10] && commProperty[10] !== "") {
              // 联动字段
              controlDefine.configs.linkageField = this.getLinkageFieldsConfig(
                commProperty[10]
              );
            }
            if (commProperty[11] && commProperty[11] !== "")
              controlDefine.configs.multiSelect = util.strIsTrue(
                util.trimStr(commProperty[11])
              ); // 是否支持多选
            if (commProperty[12] && commProperty[12] !== "") {
              // 是否配置了可见范围
              controlDefine.configs.visScope = this.getAllRegMatched(
                util.trimStr(commProperty[12])
              );
              console.log(
                commProperty[0],
                ",用户",
                controlDefine.configs.visScope,
                "可见"
              );
            }
            if (controlDefine.configs.required) {
              let requiredRule = {
                required: true,
                message: "请输入" + commProperty[0] + ""
              };
              // 如果在必填的基础上还配置了校验正则条件
              if (CXS_TO_ECONTROLS[controlDefine.controlType].requiredPattern) {
                requiredRule.validator = (rule, value) => {
                  if (value) {
                    let checkValue = value + "";
                    return (
                      !!checkValue.match(
                        CXS_TO_ECONTROLS[controlDefine.controlType]
                          .requiredPattern
                      ) && checkValue.length < 255
                    );
                  }
                  return false;
                };
              }
              this.controlAddRules(controlDefine.configs, requiredRule); //必填项校验规则
            }
          }
        }
        break;
      }
      //下列应是专有属性 -text控件使用的是该值作为默认值
      case "ComponentValue": {
        if (
          controlDefine.configs.defaultValue == undefined ||
          controlDefine.configs.defaultValue == null
        ) {
          // 默认显示内容
          controlDefine.configs.defaultValue = util.trimStr(propertyValue);
        }
        break;
      }
      case "Value": {
        if (
          !controlDefine.configs.defaultValue &&
          controlDefine.controlType !== "CxsDateTimePicker"
        ) {
          // 默认显示内容getAllRegMatched
          controlDefine.configs.defaultValue = util.trimStr(propertyValue);
        }
        break;
      }
      case "DefaultValue": {
        if (controlDefine.controlType === "CxsDateTimePicker") {
          controlDefine.configs.defaultValue = util.dateUtils.getDateTimePickerDefaultValue(
            util.trimStr(propertyValue)
          );
        } else {
          // 默认显示内容
          controlDefine.configs.defaultValue = util.trimStr(propertyValue);
        }
        break;
      }
      case "ReadOnly": {
        // 控件是否只读 ,默认是false
        let isReadOnly = util.trimStr(propertyValue);
        if (isReadOnly === "dynamicReadOnly")
          controlDefine.configs.readOnly = "dynamicReadOnly";
        // 根据dynamicReadOnly参数配置的条件计算结果决定是否只读
        else controlDefine.configs.readOnly = util.strIsTrue(propertyValue);
        break;
      }
      case "dynamicReadOnly": {
        // 判断控件是否只读的条件
        controlDefine.configs.dynamicReadOnly = util.trimStr(propertyValue);
        break;
      }
      case "ComponentEditable": {
        // 是否可编辑? 0,1,2；1为可编辑
        controlDefine.disable = propertyValue !== "1"; //propertyValue;
        break;
      }
      case "Visible": {
        // 是否可见，默认true
        controlDefine.isShow = !util.strIsFalse(propertyValue);
        break;
      }
      case "Enabled": {
        // 是否可用，
        controlDefine.enabled = !util.strIsFalse(propertyValue);
        break;
      }
      case "Checked": {
        // 复选选中，默认false,只有值为true才会有属性
        controlDefine.checked = util.strIsTrue(propertyValue);
        break;
      }
      case "Filter": {
        // 过滤条件，有些控件如：CxsMaterials、CxsGY_BDMB有该属性
        controlDefine.filter = propertyValue;
        break;
      }
      case "KeyType": {
        // 关键字段，控件CxsMaterials有
        controlDefine.keyType = propertyValue;
        break;
      }
      case "Tag": {
        // 其他的配置属性，如：emptyOption下拉列表用，是否默认加一个空选项
        propertyValue = util.trimStr(propertyValue);
        if (propertyValue != "") {
          let configObj = JSON.parse(propertyValue);
          //console.log("包含tag属性值:",configObj);
          if (configObj && configObj.config) {
            if (configObj.config.emptyOption) {
              // 适用于ESelect控件参数，是否需要设置空选项
              controlDefine.emptyOption = true;
            }
            if (configObj.config.autoGenerateConfig) {
              // 适用于模板的顶层Form控件,“系统自动生成表单有效”
              controlDefine.nonEditableFields =
                configObj.config.autoGenerateConfig.nonEditableFields; // 不能编辑的字段
              controlDefine.hiddenButtons =
                configObj.config.autoGenerateConfig.hiddenButtons; // 需要隐藏的功能按钮
            }
          }
        }
        break;
      }
      case "DefaultDataType": {
        propertyValue = util.trimStr(propertyValue);
        controlDefine.defaultDataType = parseInt(propertyValue);
        break;
      }
      case "DefaultDataValue": {
        propertyValue = util.trimStr(propertyValue);
        controlDefine.defaultDataValue = propertyValue;
        break;
      }
      case "CheckPattern": {
        // 文本格式校验的正则表达式
        propertyValue = util.trimStr(propertyValue);
        try {
          let reg = new RegExp(propertyValue);
          this.controlAddRules(controlDefine.configs, {
            type: "string",
            pattern: reg,
            transform(value) {
              if (value) {
                return value.trim();
              }
              return value;
            },
            message: "错误的文本格式"
          });
        } catch (e) {
          console.error("无效正则表达式", e);
        }
        break;
      }
      case "CheckErrorInfo": {
        // 配合checkPattern,使用在Rule中
        propertyValue = util.trimStr(propertyValue);
        controlDefine.checkErrorInfo = util.message_decode(propertyValue);
        break;
      }
      case "UniqueCheck": {
        // 字段值唯一性检测
        propertyValue = util.trimStr(propertyValue);
        if (propertyValue != "") {
          try {
            let uniqueCheck = JSON.parse(propertyValue);
            if (
              uniqueCheck &&
              uniqueCheck.UniqueCheckTable &&
              uniqueCheck.UniqueCheckTable != ""
            ) {
              this.controlAddRules(controlDefine.configs, {
                transform(value) {
                  return value.trim();
                },
                uniqueCheck: uniqueCheck
              });
            }
          } catch (e) {
            console.error("错误的唯一性配置参数", e);
          }
        }
        break;
      }
      case "Format": {
        propertyValue = util.trimStr(propertyValue);
        if(propertyValue!=="") {
          if(util.dateUtils.getEDatetimePickerMode(propertyValue)!=null) { //如果是某种时间日期格式，则不需要使用设定格式化方法
            controlDefine.format = propertyValue;
          } else {
            controlDefine.format = "format" + util.upperInitials(propertyValue);
          }
        }
        break;
      }
    }
  },
  /**
   * 给对应的控件对象增加rules配置
   * @param control
   * @param rule
   */
  controlAddRules(control, rule) {
    if (control && rule) {
      if (!control.rules) {
        control.rules = [];
      }
      control.rules.push(rule);
    }
  },
  /**
   * 解析给控件配置的正则表达式校验条件
   * 支持格式如：pattern╩^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$╪message╩提醒内容
   * @param control
   * @param checkPattern
   */
  analysisCheckPatternRule(control, checkPattern) {
    if (util.isType(checkPattern) === "String" && checkPattern !== "") {
      try {
        let checkPatternObj;
        if (checkPattern.includes("╬")) {
          // 多个规则
          checkPatternObj = [];
          let rules = util.stringSplitArray(checkPattern, "╬");
          for (let r = 0; r < rules.length; r++) {
            let values = util.stringSplitArray(rules[r], "╪");
            let rObject = {};
            for (let j = 0; j < values.length; j++) {
              let kv1 = util.stringSplitArray(values[j], "╩");
              if (kv1.length == 2) {
                rObject[kv1[0]] = kv1[1];
              }
            }
            if (util.isValidObject(rObject)) {
              checkPatternObj.push(rObject);
            }
          }
        } else {
          // 单个规则
          checkPatternObj = {};
          let allValue = util.stringSplitArray(checkPattern, "╪");
          for (let i = 0; i < allValue.length; i++) {
            let kv = util.stringSplitArray(allValue[i], "╩");
            if (kv.length == 2) {
              checkPatternObj[kv[0]] = kv[1];
            }
          }
        }
        if (util.isType(checkPatternObj) === "Object") {
          // 单个校验条件
          if (checkPatternObj.pattern) {
            let reg = new RegExp(checkPatternObj.pattern);
            let rule = {
              type: "string",
              pattern: reg,
              transform(value) {
                if (value) {
                  return value.trim();
                }
                return value;
              },
              message: !!checkPatternObj.message
                ? checkPatternObj.message
                : "错误的内容格式"
            };
            this.controlAddRules(control, rule);
          }
        } else if (util.isType(checkPatternObj) === "Array") {
          // 多个校验条件
          for (let i = 0; i < checkPatternObj.length; i++) {
            let temPatternObj = checkPatternObj[i];
            if (temPatternObj.pattern) {
              let reg1 = new RegExp(temPatternObj.pattern);
              let rule1 = {
                type: "string",
                pattern: reg1,
                transform(value) {
                  if (value) {
                    return value.trim();
                  }
                  return value;
                },
                message: !!temPatternObj.message
                  ? temPatternObj.message
                  : "错误的内容格式"
              };
              this.controlAddRules(control, rule1);
            }
          }
        }
      } catch (e) {
        console.error("错误的正则表达式校验条件配置");
      }
    }
  },
  /**
   * 将定义对象中的checkErrorInfo属性合并到checkPattern对应的rule规则中
   * @param controlDefine
   */
  updateCheckPatternRule: function(controlDefine) {
    if (
      controlDefine &&
      controlDefine.configs.rules &&
      controlDefine.configs.rules.length > 0
    ) {
      let rule = controlDefine.configs.rules.find(function(r) {
        return r.type === "string" && r.pattern !== undefined;
      });
      if (rule) {
        if (controlDefine.checkErrorInfo) {
          rule.message = controlDefine.checkErrorInfo;
          delete controlDefine.checkErrorInfo;
        }
      }
    }
  },
  /**
   * 根据固定值类型，获取对应的值
   * @param fixedValueType
   * @param controlProp
   */
  getValOfFixedValueType: function(fixedValueType, controlProp) {
    if (fixedValueType && fixedValueType !== "无") {
      let nowMonth = moment().month();
      switch (fixedValueType) {
        case "当前部门":
          return {
            label: this.$store.getters.getUserDept,
            value: this.$store.getters.getDepartId
          };
        case "当前登录人":
          return {
            label: this.$store.getters.getEmployee,
            value: this.$store.getters.getEmployeeId
          };
        case "登录日期": //this.$store.getters.getLoginTime ?默认当前时间
          return moment().format("YYYY-MM-DD HH:mm:ss");
        case "最大日期": // 日期控件暂时不可用
          return null;
        //if (controlProp.maxDate) return controlProp.maxDate;
        //break;
        case "最小日期": // 日期控件暂时不可用
          //if (controlProp.minDate) return controlProp.minDate;
          // break;
          return null;
        case "登录月份":
          if (controlProp.controlType === "CxsDateTimePicker") {
            // 日期控件暂时不可用
            return null;
            //return moment(this.$store.getters.getLoginTime).format("YYYY-MM-DD");
          } else {
            nowMonth = moment(this.$store.getters.getLoginTime).month();
            return nowMonth + 1;
          }
        case "登录月份增1":
          if (controlProp.controlType === "CxsDateTimePicker") {
            // 日期控件暂时不可用
            return null;
            //return moment(this.$store.getters.getLoginTime).add(1, "months").format("YYYY-MM-DD");
          } else {
            nowMonth = moment(this.$store.getters.getLoginTime).month();
            if (nowMonth < 11) return nowMonth + 1;
            else return 1; // 下一年
          }
        case "登录月份减1":
          if (controlProp.controlType === "CxsDateTimePicker") {
            // 日期控件暂时不可用
            return null;
            //return moment(this.$store.getters.getLoginTime).subtract(1, "months").format("YYYY-MM-DD");
          } else {
            nowMonth = moment(this.$store.getters.getLoginTime).month();
            if (nowMonth > 1) return nowMonth - 1;
            else return 12; // 前一年
          }
        case "列表空行值":
        case "登录公司ID":
          return this.$store.getters.getCompanyId;
        case "登录年份":
          if (controlProp.controlType === "CxsDateTimePicker") {
            // 日期控件暂时不可用
            return null;
            //return moment(this.$store.getters.getLoginTime).subtract(1, "months").format("YYYY-MM-DD");
          } else return moment(this.$store.getters.getLoginTime).format("YYYY");
        case "固定登录日期":
      }
    }
    return null;
  },
  /**
   * 分解出原CxsCheckBox对象，合并成一个CheckBox组
   * @param controlDefines
   */
  getCheckBoxDefines: function(controlDefines) {
    //分析出复选框的组
    let checkBoxObj = [];
    let checkBoxIndexs = []; //原始的checkbox配置
    let checkBoxnewIndex = []; //要被替换的元素
    controlDefines.forEach((controlDefine, index) => {
      if (controlDefine.controlType === "CxsCheckBox") {
        let checkBoxIndex = checkBoxObj.findIndex(function(cobj) {
          return cobj.configs.fieldName === controlDefine.configs.fieldName;
        });
        if (checkBoxIndex >= 0) {
          // 已经遍历到了选项
          checkBoxIndexs.push(index);
          checkBoxObj[checkBoxIndex].options.push({
            label: controlDefine.text,
            value: controlDefine.text,
            disabled: false
          });
          if (controlDefine.checked) {
            // 该项是否被默认选中
            checkBoxObj[checkBoxIndex].checked.push(controlDefine.text);
          }
          checkBoxObj[checkBoxIndex].controlDefines.push(controlDefine);
          checkBoxObj[checkBoxIndex].controlName.push(
            controlDefine.controlName
          );
          checkBoxObj[checkBoxIndex].isShow =
            checkBoxObj[checkBoxIndex].isShow || controlDefine.isShow;
          checkBoxObj[checkBoxIndex].size.w =
            checkBoxObj[checkBoxIndex].size.w + controlDefine.size.w + 20; // 宽度追加
        } else {
          //第一个被遍历到的选项
          checkBoxnewIndex.push(index);
          let checkedObj = [];
          if (controlDefine.checked) {
            // 该项是否被默认选中
            checkedObj.push(controlDefine.text);
          }

          let checkBox = {
            controlType: controlDefine.controlType,
            controlName: [controlDefine.controlName],
            configs: {
              fieldName: controlDefine.configs.fieldName
            },
            options: [
              {
                label: controlDefine.text,
                value: controlDefine.text,
                visible: controlDefine.isShow,
                disabled: false
              }
            ],
            isShow: controlDefine.isShow,
            checked: checkedObj,
            controlDefines: [controlDefine],
            location: controlDefine.location, //默认排序后第一个遍历到的CxsCheckBox，为最终的位置
            size: controlDefine.size
          };
          if (this.controlMapping[controlDefine.controlType]) {
            checkBox.thisControlType = this.controlMapping[
              controlDefine.controlType
            ].name;
            checkBox.setting = this.controlMapping[controlDefine.controlType];
          }
          checkBoxObj.push(checkBox);
        }
      }
    });
    this.renderConsoleLog(
      "复选框对象：",
      checkBoxObj,
      checkBoxIndexs,
      checkBoxnewIndex
    );

    //是否找到复选框组件
    if (checkBoxObj.length > 0) {
      let alenght = checkBoxObj.length;
      for (let i = 0; i < alenght; i++) {
        controlDefines[checkBoxnewIndex[i]] = checkBoxObj[i]; //添加新组件配置
      }
      let dlenght = checkBoxIndexs.length;
      for (let j = dlenght - 1; j >= 0; j--) {
        controlDefines.splice(checkBoxIndexs[j], 1); //移除老组件配置
      }
    }
  },
  /**
   * 分解出原CxsRadioButton对象，合并成一个Radio组
   * @param controlDefines
   */
  getRadioDefines: function(controlDefines) {
    //分析出复选框的组
    let checkRadioObj = [];
    let radioIndexs = []; //原始的checkbox配置
    let radioNewIndex = []; //要被替换的元素
    controlDefines.forEach((controlDefine, index) => {
      if (controlDefine.controlType === "CxsRadioButton") {
        let checkBoxIndex = checkRadioObj.findIndex(function(cobj) {
          return cobj.configs.fieldName === controlDefine.configs.fieldName;
        });
        if (checkBoxIndex >= 0) {
          // 已经遍历到了选项
          radioIndexs.push(index);
          checkRadioObj[checkBoxIndex].options.push({
            label: controlDefine.text,
            value: controlDefine.text,
            disabled: false
          });
          checkRadioObj[checkBoxIndex].controlDefines.push(controlDefine);
          checkRadioObj[checkBoxIndex].size.w =
            checkRadioObj[checkBoxIndex].size.w + controlDefine.size.w + 20; // 宽度追加
        } else {
          //第一个被遍历到的选项
          radioNewIndex.push(index);
          let checkBox = {
            controlType: controlDefine.controlType,
            configs: {
              fieldName: controlDefine.configs.fieldName
            },
            options: [
              {
                label: controlDefine.text,
                value: controlDefine.text,
                disabled: false
              }
            ],
            controlDefines: [controlDefine],
            location: controlDefine.location, //默认排序后第一个遍历到的CxsCheckBox，为最终的位置
            size: controlDefine.size
          };
          if (this.controlMapping[controlDefine.controlType]) {
            checkBox.thisControlType = this.controlMapping[
              controlDefine.controlType
            ].name;
            checkBox.setting = this.controlMapping[controlDefine.controlType];
          }
          checkRadioObj.push(checkBox);
        }
      }
    });
    this.renderConsoleLog(
      "单选对象：",
      checkRadioObj,
      radioIndexs,
      radioNewIndex
    );

    //是否找到复选框组件
    if (checkRadioObj.length > 0) {
      let alenght = checkRadioObj.length;
      for (let i = 0; i < alenght; i++) {
        controlDefines[radioNewIndex[i]] = checkRadioObj[i]; //添加新组件配置
      }
      let dlenght = radioIndexs.length;
      for (let j = dlenght - 1; j >= 0; j--) {
        controlDefines.splice(radioIndexs[j], 1); //移除老组件配置
      }
    }
  },

  /**
   * 分解出原CxsDateTimePicker对象中包含多个控件共同使用一个字段名的对象，合并成一个DateTime组
   * @param controlDefines
   */
  getDateTimeDefines: function(controlDefines) {
    //分析出复选框的组
    let checkDateTimeObj = [];
    let dataTimeIndexs = []; //原始的DateTime配置
    let dateTimeNewIndex = []; //要被替换的元素
    controlDefines.forEach((controlDefine, index) => {
      if (controlDefine.controlType === "CxsDateTimePicker") {
        let checkBoxIndex = checkDateTimeObj.findIndex(function(cobj) {
          return cobj.configs.fieldName === controlDefine.configs.fieldName;
        });
        if (checkBoxIndex >= 0) {
          // 已经遍历到了选项
          dataTimeIndexs.push(index);
          checkDateTimeObj[checkBoxIndex].options.push({
            label: controlDefine.text,
            value: controlDefine.text,
            disabled: false
          });
          checkDateTimeObj[checkBoxIndex].controlDefines.push(controlDefine);
          checkDateTimeObj[checkBoxIndex].size.w =
            checkDateTimeObj[checkBoxIndex].size.w + controlDefine.size.w + 20; // 宽度追加
        } else {
          //第一个被遍历到的选项
          dateTimeNewIndex.push(index);
          let checkBox = {
            controlType: controlDefine.controlType,
            configs: {
              fieldName: controlDefine.configs.fieldName
            },
            options: [
              {
                label: controlDefine.text,
                value: controlDefine.text,
                disabled: false
              }
            ],
            controlDefines: [controlDefine],
            location: controlDefine.location, //默认排序后第一个遍历到的CxsCheckBox，为最终的位置
            size: controlDefine.size
          };
          if (this.controlMapping[controlDefine.controlType]) {
            checkBox.thisControlType = this.controlMapping[
              controlDefine.controlType
            ].name;
            checkBox.setting = this.controlMapping[controlDefine.controlType];
          }
          checkDateTimeObj.push(checkBox);
        }
      }
    });
    this.renderConsoleLog(
      "单选对象：",
      checkDateTimeObj,
      dataTimeIndexs,
      dateTimeNewIndex
    );

    //是否找到复选框组件
    if (checkDateTimeObj.length > 0) {
      let alenght = checkDateTimeObj.length;
      for (let i = 0; i < alenght; i++) {
        controlDefines[dateTimeNewIndex[i]] = checkDateTimeObj[i]; //添加新组件配置
      }
      let dlenght = dataTimeIndexs.length;
      for (let j = dlenght - 1; j >= 0; j--) {
        controlDefines.splice(dataTimeIndexs[j], 1); //移除老组件配置
      }
    }
  },

  /**
   * 设置一些表格的默认属性，
   * 注意：此方法必须在render_CxsGrid_ProprietaryDefine之前调用
   * @param controlDefine
   */
  set_CxsGrid_DefaultDefine: function(controlDefine) {
    controlDefine.readOnly = false;
    controlDefine.allowAddRows = true;
    controlDefine.allowDeleteRows = true;
    controlDefine.multiSelect = true;
    controlDefine.newFormAddOneRow = true;
    controlDefine.required = true; // 默认必须有行
  },
  /**
   * 从Form组件xml配置中解析出json格式的组件专有属性（Form表单整体）
   * @param controlDefine
   * @param controlElementProperty
   */
  render_CxsForm_ProprietaryDefine: function(
    controlDefine,
    controlElementProperty
  ) {
    if (controlElementProperty.hasChildNodes()) {
      let tempText = this.getNodeInnerHtml(controlElementProperty);
      switch (this.getElementAttribuie(controlElementProperty, "name")) {
        case "ShortTitle": {
          // 短标题
          controlDefine.shortTitle = tempText;
          break;
        }
        case "Title": {
          //标题
          controlDefine.title = tempText;
          break;
        }
        case "RdlName": {
          // 绑定的综合报表
          controlDefine.rdlName = tempText;
          break;
        }
        case "Kind": {
          // 模板类型（打印模板、维护模板）
          controlDefine.kind = tempText;
          break;
        }
        case "Flow": {
          // 绑定工作流 ??
          controlDefine.flow = tempText;
          break;
        }
        case "SaveCondition": {
          // 表单保存校验条件 提交前的数据校验
          controlDefine.saveCondition = tempText;
          break;
        }
        case "HintCotent": {
          // 提示内容 提交前的数据校验如果失败报错
          controlDefine.hintCotent = util.message_decode(
            util.trimStr(tempText)
          );
          break;
        }
        case "HintType": {
          // 提示方式 提交前的数据校验提示方式
          controlDefine.hintType = tempText;
          break;
        }
        case "TableName": {
          // 绑定主表
          controlDefine.tableName = util.trimStr(tempText);
          break;
        }
        case "CompanyCode": {
          // 公司代码
          controlDefine.companyCode = tempText;
          break;
        }
        case "DataBindings": {
          // 数据绑定 ？？
          let childProperty = controlElementProperty.childNodes;
          controlDefine.dataBindings = {};
          for (let i = 0; i < childProperty.length; i++) {
            if (childProperty[i].hasChildNodes()) {
              switch (this.getElementAttribuie(childProperty[i], "name")) {
                case "DefaultDataSourceUpdateMode": {
                  controlDefine.dataBindings.defaultDataSourceUpdateMode = this.getNodeInnerHtml(
                    childProperty[i]
                  );
                }
              }
            }
          }
          break;
        }
      }
    }
  },
  /**
   * 从CxsTabControl组件xml配置中解析出json格式的组件专有属性（标签页容器）
   * @param controlDefine
   * @param controlElementProperty
   */
  render_CxsTabControl_ProprietaryDefine: function(
    controlDefine,
    controlElementProperty
  ) {
    if (controlElementProperty.hasChildNodes()) {
      switch (this.getElementAttribuie(controlElementProperty, "name")) {
        case "SelectedIndex": {
          // 默认选中的标签index
          controlDefine.selectTabIndex = this.getNodeInnerHtml(
            controlElementProperty
          );
          break;
        }
      }
    }
  },
  /**
   * 从TabPage组件xml配置中解析出json格式的组件专有属性（标签页）
   * @param controlDefine
   * @param controlElementProperty
   */
  render_TabPage_ProprietaryDefine: function(
    controlDefine,
    controlElementProperty
  ) {
    if (controlElementProperty.hasChildNodes()) {
      switch (this.getElementAttribuie(controlElementProperty, "name")) {
        case "TabIndex": {
          // 标签index
          controlDefine.tabIndex = this.getNodeInnerHtml(
            controlElementProperty
          );
          break;
        }
      }
    }
  },
  /**
   * 获取E1支持的特定字符串表示的时间
   * @param str
   * @return {*}
   */
  getE1_DataTime: function(str) {
    let dataFormat = "YYYY-MM-DD hh:mm:ss";
    if (str === "今天") {
      // 今日
      return moment(new Date()).format(dataFormat);
    } else if (str === "上个月") {
      // 上个月
      return moment(new Date())
        .subtract(1, "months")
        .format(dataFormat);
    } else if (str === "去年") {
      // 去年
      return moment(new Date())
        .subtract(1, "years")
        .format(dataFormat);
    } else if (str === "明天") {
      // 明天
      return moment(new Date())
        .add(1, "days")
        .format(dataFormat);
    } else if (str === "昨天") {
      // 昨天
      return moment(new Date())
        .subtract(1, "days")
        .format(dataFormat);
    } else if (str === "后天") {
      return moment(new Date())
        .add(2, "days")
        .format(dataFormat);
    }
    return str;
  },
  /**
   * 从CxsDateTimePicker组件xml配置中解析出json格式的组件专有属性（标签页）
   * @param controlDefine
   * @param controlElementProperty
   */
  render_CxsDateTimePicker_ProprietaryDefine: function(
    controlDefine,
    controlElementProperty
  ) {
    if (controlElementProperty.hasChildNodes()) {
      let tempText = this.getNodeInnerHtml(controlElementProperty);
      switch (this.getElementAttribuie(controlElementProperty, "name")) {
        case "MinDate": {
          // 最小日期
          controlDefine.minDate = this.getE1_DataTime(util.trimStr(tempText));
          console.log("minDate," + tempText);
          break;
        }
        case "MaxDate": {
          // 最大日期
          controlDefine.maxDate = this.getE1_DataTime(util.trimStr(tempText));
          console.log("maxDate," + tempText);
          break;
        }
        case "Format": {
          // 时间格式
          if (CXS_DATETIME_FORMATS[tempText]) {
            controlDefine.mode = CXS_DATETIME_FORMATS[tempText].mode;
            controlDefine.format = CXS_DATETIME_FORMATS[tempText].format;
          }
          break;
        }
      }
    }
  },
  /**
   * 数字选择器CxsNumericUpDown组件的专有属性
   * @param controlDefine
   * @param controlElementProperty
   */
  render_CxsNumericUpDown_ProprietaryDefine: function(
    controlDefine,
    controlElementProperty
  ) {
    if (controlElementProperty.hasChildNodes()) {
      let tempChar = util.trimStr(
        this.getNodeInnerHtml(controlElementProperty)
      );
      switch (this.getElementAttribuie(controlElementProperty, "name")) {
        case "Minimum": {
          // 最小值
          if (util.isNumberString(tempChar))
            controlDefine.minimum = Number(tempChar);
          break;
        }
        case "Maximum": {
          // 最大值
          if (util.isNumberString(tempChar))
            controlDefine.maximum = Number(tempChar);
          break;
        }
        case "Increment": {
          // 增量单位
          if (util.isNumberString(tempChar))
            controlDefine.increment = Number(tempChar);
          break;
        }
        case "DecimalPlaces": {
          // 小数位数
          if (util.isNumberString(tempChar))
            controlDefine.decimalPlaces = parseInt(tempChar);
          break;
        }
        case "ThousandsSeparator": {
          // 千分位自动分割
          controlDefine.thousandsSeparator = util.strIsTrue(tempChar);
          break;
        }
      }
    }
  },
  /**
   * 从CxsBooleanCheckbox的xml中解析出其专有属性
   * @param controlDefine
   * @param controlElementProperty
   */
  render_CxsBooleanCheckbox_ProprietaryDefine: function(
    controlDefine,
    controlElementProperty
  ) {
    if (controlElementProperty.hasChildNodes()) {
      let tempChar = util.trimStr(
        this.getNodeInnerHtml(controlElementProperty)
      );
      switch (this.getElementAttribuie(controlElementProperty, "name")) {
        case "Title": {
          // 标题
          controlDefine.label = tempChar;
          break;
        }
        case "Text1": {
          // 第一个选项
          controlDefine.text1 = tempChar;
          break;
        }
        case "Text2": {
          //  第二个选项
          controlDefine.text2 = tempChar;
          break;
        }
        case "Value": {
          // 默认选中
          controlDefine.value = util.strIsTrue(tempChar);
          break;
        }
      }
    }
  },
  /**
   * 解析会计科目组件CxsCW_KJKM的专用属性
   * @param controlDefine
   * @param controlElementProperty
   */
  render_CxsCW_KJKM_ProprietaryDefine: function(
    controlDefine,
    controlElementProperty
  ) {
    if (controlElementProperty.hasChildNodes()) {
      let tempChar = util.trimStr(
        this.getNodeInnerHtml(controlElementProperty)
      );
      switch (this.getElementAttribuie(controlElementProperty, "name")) {
        case "Filter": {
          // 过滤条件
          controlDefine.filter = tempChar;
          break;
        }
        case "KeyType": {
          // 关键字段
          controlDefine.keyType = tempChar;
          break;
        }
        case "CompanyId": {
          // 公司ID
          controlDefine.companyId = tempChar;
          break;
        }
      }
    }
  },
  /**
   * 解析薪资公式控件组件CxsXZXMGSTextArea的专用属性
   * @param controlDefine
   * @param controlElementProperty
   */
  render_CxsXZXMGSTextArea_ProprietaryDefine: function(
    controlDefine,
    controlElementProperty
  ) {
    if (controlElementProperty.hasChildNodes()) {
      let tempChar = util.trimStr(
        this.getNodeInnerHtml(controlElementProperty)
      );
      switch (this.getElementAttribuie(controlElementProperty, "name")) {
        case "FormulaType": {
          // 过滤条件
          controlDefine.formulaType = tempChar;
          break;
        }
      }
    }
  },
  /**
   * 从Grid组件xml配置中解析出json格式的组件专有属性（表格）
   * @param controlDefine
   * @param controlElementProperty
   */
  render_CxsGrid_ProprietaryDefine: function(
    controlDefine,
    controlElementProperty
  ) {
    if (controlElementProperty.hasChildNodes()) {
      let tempText = this.getNodeInnerHtml(controlElementProperty);
      switch (this.getElementAttribuie(controlElementProperty, "name")) {
        case "SumRow": {
          //是否显示合计 ，默认是true
          controlDefine.sumRow = !util.strIsFalse(tempText);
          break;
        }
        case "TableName": {
          // 绑定的表名
          controlDefine.tableName = util.trimStr(tempText);
          break;
        }
        case "ReadOnly": {
          // 是否只读，默认false
          let readOnlyStr = util.trimStr(tempText);
          if (readOnlyStr === "dynamicReadOnly") {
            controlDefine.readOnly = "dynamicReadOnly";
          } else controlDefine.readOnly = util.strIsTrue(readOnlyStr);
          break;
        }
        case "dynamicReadOnly": {
          controlDefine.dynamicReadOnly = util.trimStr(tempText);
          break;
        }
        case "AllowUserToAddRows": {
          // 是否允许增加行,默认true
          controlDefine.allowAddRows = !util.strIsFalse(tempText);
          //this.renderConsoleLog("allowAddRows" + controlDefine.allowAddRows);
          break;
        }
        case "AllowUserToDeleteRows": {
          // 是否允许删行,默认true
          controlDefine.allowDeleteRows = !util.strIsFalse(tempText);
          //this.renderConsoleLog("allowAddRows" + controlDefine.allowAddRows);
          break;
        }
        case "AllowEmptyData": {
          // 是否允许空行,默认false(类似于必填)
          controlDefine.required = util.strIsFalse(tempText);
          break;
        }
        case "MultiSelect": {
          //是否允许多选，默认true
          controlDefine.multiSelect = !util.strIsFalse(tempText);
          break;
        }
        case "NewFormAddOneRow": {
          //新建表单模式下，自动初始化一行是否，默认true
          controlDefine.newFormAddOneRow = !util.strIsFalse(tempText);
          break;
        }
        case "EditMode": {
          // 编辑模式 "EditOnEnter","EditOnKeystroke","EditOnF2","EditProgrammatically"默认"EditOnKeystrokeOrF2"
          controlDefine.editMode = tempText;
          break;
        }
        case "SelectionMode": {
          // 选择模式，"CellSelect","FullRowSelect",默认"RowHeaderSelect"
          controlDefine.selectionMode = tempText;
          break;
        }
        case "DisableRowEditCondition": {
          //行禁用编辑条件
          controlDefine.disableRowEditCondition = util.trimStr(tempText);
          break;
        }
        case "Columns": {
          // 表格头定义
          let items = controlElementProperty.childNodes; // querySelectorAll("Item");(会把孙节点都一起查询出来)
          let columns = [];

          for (let k = 0; k < items.length; k++) {
            let item = items[k];
            if (this.checkNoTextNode(item)) {
              //判断是否属于属性节点
              let column = {};
              //使用的编辑控件
              let tempControlType = this.getElementAttribuie(item, "type");
              column.controlType = tempControlType.match(
                /UserComponents\.([^,]+)/
              )[1];
              if (
                column.controlType === "CxsGridComboBoxColumn" ||
                column.controlType === "CxsGridComboEditBoxColumn" ||
                column.controlType === "CxsGridTexBoxButtonColumn"
              ) {
                column.listFilter = {}; // 此属性只有列表选择类型有
              }
              let columnProperties = this.querySelectorAll(item, "Property");
              column.editable = true;
              for (
                let columnPIndex = 0;
                columnPIndex < columnProperties.length;
                columnPIndex++
              ) {
                let columnProperty = columnProperties[columnPIndex];
                if (columnProperty.hasChildNodes()) {
                  let columTempText = this.getNodeInnerHtml(columnProperty);
                  switch (this.getElementAttribuie(columnProperty, "name")) {
                    case "FieldName": {
                      // 字段名
                      column.fieldName = util.trimStr(columTempText);
                      break;
                    }
                    case "HeaderText": {
                      // 字段标题
                      column.headerText = columTempText;
                      break;
                    }
                    case "Width": {
                      // 宽度
                      column.width = columTempText;
                      break;
                    }
                    case "ComponentEditable": {
                      // 是否可编辑 0，1,2
                      column.editable = columTempText === "1";
                      break;
                    }
                    case "IsMust": {
                      // 是否必填，默认true
                      column.isMust = util.strIsTrue(columTempText);
                      if (column.isMust) {
                        this.controlAddRules(column, {
                          required: true,
                          message: "不能为空"
                        });
                      }
                      break;
                    }
                    case "ReadOnly": {
                      // 是否只读，默认false
                      let readOnlyStr = util.trimStr(columTempText);
                      if (readOnlyStr === "dynamicReadOnly") {
                        column.readOnly = "dynamicReadOnly";
                      } else column.readOnly = util.strIsTrue(readOnlyStr);
                      break;
                    }
                    case "dynamicReadOnly": {
                      column.dynamicReadOnly = util.trimStr(columTempText);
                      break;
                    }
                    case "IsAutoColNo": {
                      // 是否是自增行号，默认false
                      column.isAutoColNo = !util.strIsFalse(columTempText);
                      break;
                    }
                    case "Frozen": {
                      // 是否是冻结该列，默认false
                      column.isAutoColNo = util.strIsTrue(columTempText);
                      break;
                    }
                    case "Formula": {
                      // 列数据计算公式
                      if (util.trimStr(columTempText) !== "") {
                        column.cFormula = columTempText;
                      }
                      break;
                    }
                    case "VisScope": {
                      // 可见范围、定义了该字段在那些用户登录时可见
                      column.visScope = this.getAllRegMatched(
                        util.trimStr(columTempText)
                      );
                      break;
                    }
                    case "IsSum": {
                      // 是否可以合计，默认true
                      column.isSum = util.strIsTrue(columTempText);
                      break;
                    }
                    case "Items": {
                      // 默认的选择项，只有CxsGridComboBoxColumn类型的控件才有
                      let selectItems = columnProperty.childNodes;
                      let options = [];
                      for (let k1 = 0; k1 < selectItems.length; k1++) {
                        let selectItem = selectItems[k1];
                        if (this.checkNoTextNode(selectItem)) {
                          let tempValue = util.trimStr(
                            this.getNodeInnerHtml(selectItem)
                          );
                          if (tempValue && tempValue !== "")
                            options.push({
                              label: tempValue,
                              value: tempValue,
                              disabled: false
                            });
                        }
                      }
                      if (options.length > 0) {
                        column.options = options;
                      }
                      break;
                    }
                    case "ListType": {
                      // 列表类型
                      column.listType = columTempText;
                      column.listFilter.listType = util.trimStr(columTempText);
                      break;
                    }
                    case "SourceTableName": {
                      // 指定的数据来源表
                      column.listFilter.sourceTable = util.trimStr(
                        columTempText
                      );
                      break;
                    }
                    case "SourceFieldName": {
                      // 指定的数据来源表的对应字段
                      column.listFilter.sourceFieldName = util.trimStr(
                        columTempText
                      );
                      break;
                    }
                    case "ListFilter": {
                      // 根据条件从数据库获取数据
                      column.listFilter.filter = columTempText;
                      column.listFilter[this.checkFilter(column.listFilter)] = true;
                      break;
                    }
                    case "Filter": {
                      // CxsGridTexBoxButtonColumn特有属性-过滤条件
                      column.listFilter.filter = columTempText;
                      column.listFilter[this.checkFilter(column.listFilter)] = true;
                      break;
                    }
                    case "FilterReferColumns": {
                      //  CxsGridTexBoxButtonColumn特有属性-过滤参照列
                      column.listFilter.filterReferColumns = columTempText;
                      break;
                    }
                    case "LinkColumns": {
                      // 联动字段
                      if (util.trimStr(columTempText) !== "") {
                        column.linkColumns = this.getLinkageFieldsConfig(
                          columTempText
                        );
                      }
                      break;
                    }
                    case "DisplayMember": {
                      // 数据库获取数据使用DisplayMember设置的字段做label
                      column.listFilter.label = util.trimStr(columTempText);
                      break;
                    }
                    case "ValueMember": {
                      // 数据库获取数据使用DisplayMember设置的字段做value
                      column.listFilter.value = util.trimStr(columTempText);
                      break;
                    }
                    case "EditorFormType": {
                      // 编辑类型
                      column.editorFormType = columTempText;
                      break;
                    }
                    case "Visible": {
                      // 是否可见
                      column.visible = !util.strIsFalse(columTempText);
                      break;
                    }
                    case "DefaultSelectedIndex": {
                      //默认选中项目，一般只有下列选项有此属性
                      let select = -1;
                      if (util.trimStr(columTempText) !== "") {
                        try {
                          select = parseInt(columTempText);
                        } catch (e) {
                          select = -1;
                        }
                      }
                      column.defaultSelectedIndex = select;
                      break;
                    }
                    case "CheckPattern": {
                      // 文本格式校验规则
                      columTempText = util.trimStr(columTempText);
                      this.analysisCheckPatternRule(column, columTempText);
                      break;
                    }
                    case "DefaultCellStyle": {
                      // 其他的列属性配置，包含数据格式化参数
                      if (columnProperty.hasChildNodes()) {
                        let columnChildProperty = columnProperty.childNodes;
                        for (
                          let ii = 0;
                          ii < columnChildProperty.length;
                          ii++
                        ) {
                          if (this.checkNoTextNode(columnChildProperty[ii])) {
                            let instanceDescriptor = columnChildProperty[ii];
                            if (
                              instanceDescriptor &&
                              instanceDescriptor.hasChildNodes()
                            ) {
                              let allInstanceDescriptor =
                                instanceDescriptor.childNodes;
                              for (
                                let jj = 0;
                                jj < allInstanceDescriptor.length;
                                jj++
                              ) {
                                if (
                                  this.checkNoTextNode(
                                    allInstanceDescriptor[jj]
                                  )
                                ) {
                                  let textInstanceDescriptor = this.getNodeInnerHtml(
                                    allInstanceDescriptor[jj]
                                  );
                                  switch (
                                    this.getElementAttribuie(
                                      allInstanceDescriptor[jj],
                                      "name"
                                    )
                                  ) {
                                    case "Format": {
                                      column.format = util.trimStr(
                                        textInstanceDescriptor
                                      );
                                      if (column.format !== "") {
                                        let mode = util.dateUtils.getEDatetimePickerMode(
                                          column.format
                                        );
                                        if (mode) {
                                          column.mode = mode;
                                        } else {
                                          let regN = /^[N,C]\d+$/;
                                          if(!regN.test(column.format)) {
                                            // 定义的预制格式化方法-对应components/vxe-table/table.js文件内定义的VXETable.formats
                                            column.format = "format" + util.upperInitials(column.format);
                                          }
                                        }
                                      }
                                      break;
                                    }
                                    case "NullValue": {
                                      column.nullValue = textInstanceDescriptor;
                                      break;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      break;
                    }
                    case "DefaultValue": {
                      if (column.controlType === "CxsGridDateTimeColumn") {
                        column.defaultValue = util.dateUtils.getDateTimePickerDefaultValue(
                          util.trimStr(columTempText)
                        );
                      } else {
                        //默认值
                        column.defaultValue = util.trimStr(columTempText);
                      }
                      break;
                    }
                    case "MinDate": {
                      // 最小日期
                      column.minDate = this.getE1_DataTime(
                        util.trimStr(columTempText)
                      );
                      console.log("minDate," + columTempText);
                      break;
                    }
                    case "MaxDate": {
                      // 最大日期
                      column.maxDate = this.getE1_DataTime(
                        util.trimStr(columTempText)
                      );
                      console.log("maxDate," + columTempText);
                      break;
                    }
                    case "Align": {
                      let align = util.trimStr(columTempText);
                      // 宽度
                      column.align = align !== "" ? align : "center";
                      break;
                    }
                  }
                }
              }
              if (column.cFormula) {
                // 把包含公式的字段单独保存为一个数组
                if (controlDefine.allFormulaConfigs) {
                  controlDefine.allFormulaConfigs.push(
                    this.getCFormulaConfig({
                      fieldName: column.fieldName,
                      cFormula: column.cFormula,
                      rowFormula: true
                    })
                  );
                } else {
                  controlDefine.allFormulaConfigs = [];
                  controlDefine.allFormulaConfigs.push(
                    this.getCFormulaConfig({
                      fieldName: column.fieldName,
                      cFormula: column.cFormula,
                      rowFormula: true
                    })
                  );
                }
              }
              // 处理非空字段的校验提示内容
              if (column.rules && column.rules.length > 0) {
                column.rules.forEach(function(rule) {
                  if (rule.required) {
                    rule.message = column.headerText + rule.message;
                  }
                });
              }
              columns.push(column);
            }
          }
          controlDefine.columns = columns;
          break;
        }
      }
    }
  },
  /**
   * 将checkType字段进行解析 ？
   * @param controlDefine
   * @param checkType
   */
  analysisCheckType: function(controlDefine, checkType) {
    if (controlDefine && checkType && checkType !== "" && checkType !== "无") {
      // 如果是日期时间控件，则需要根据实际值去理解控制值
      if (controlDefine.controlType === "CxsDateTimePicker") {
        let reg = new RegExp("\\[([^\\]]+)\\]", "ig"); //从字符串中匹配出所有用[]括起来的字段
        let allFields = checkType.match(reg);
        if (allFields && allFields.length > 0) {
          // 说明里面配置了字段名
        }
      }
    }
  },
  /**
   * 解析联动字段配置
   * @param linkageField
   * @returns {*}
   */
  getLinkageFieldsConfig: function(linkageField) {
    //_LXDH|_GYSLXDH;_LXR|_GYSLXR 或_WZBM|_WZBM,_WZMC|_WZMC,_GGXH|_GGXH,_JLDWID|_JLDWID,_CZ|_CZ
    if (
      linkageField != null &&
      util.isType(linkageField) === "String" &&
      linkageField !== ""
    ) {
      let temp = [];
      if (linkageField.includes(";", 1)) {
        temp = linkageField.split(";");
      } else if (linkageField.includes(",", 1)) {
        temp = linkageField.split(",");
      } else {
        temp.push(linkageField);
      }
      temp = temp.map(function(f) {
        let t = f.split("|");
        return {
          field: util.trimStr(t[0]),
          mbField: util.trimStr(t[1])
        };
      });
      return temp;
    }
    return null;
  },
  /**
   * 下拉列表选择控件
   * 从ComboBox组件xml配置中解析出json格式的组件专有属性
   * @param controlDefine
   * @param controlElementProperty
   */
  render_CxsComboBox_ProprietaryDefine: function(
    controlDefine,
    controlElementProperty
  ) {
    if (!controlDefine.listFilter) controlDefine.listFilter = {};
    if (controlElementProperty.hasChildNodes()) {
      let tempControlText = this.getNodeInnerHtml(controlElementProperty);
      switch (this.getElementAttribuie(controlElementProperty, "name")) {
        case "Items": {
          // 下拉列表项
          let items = this.querySelectorAll(controlElementProperty, "Item");
          let options = [];
          for (let k = 0; k < items.length; k++) {
            let tempLabel = util.trimStr(this.getNodeInnerHtml(items[k]));
            if (tempLabel && tempLabel !== "")
              options.push({
                label: tempLabel,
                value: util.trimStr(tempLabel),
                disabled: false
              });
          }
          if (options.length > 0) controlDefine.options = options;
          break;
        }
        case "SourceTableName": {
          // 指定的数据来源表
          controlDefine.listFilter.sourceTable = util.trimStr(tempControlText);
          break;
        }
        case "SourceFieldName": {
          // 指定的数据来源表的对应字段
          controlDefine.listFilter.sourceFieldName = util.trimStr(
            tempControlText
          );
          break;
        }
        case "DataSource": {
          // 指定默认选项的数据来源
          let tempData = util.trimStr(tempControlText);
          if (tempData && tempData !== "")
            controlDefine.listFilter.dataSource = tempData
              .slice(8)
              .slice(0, -9);
          break;
        }
        case "ListFilter": {
          // 根据条件从数据库获取数据
          controlDefine.listFilter.filter = util.trimStr(tempControlText);
          let reg = new RegExp("\\{([^\\}]+)\\}", "ig"); //从字符串中匹配出所有用{}括起来的字段
          let dynamicVariables = controlDefine.listFilter.filter.match(reg);
          if (dynamicVariables && dynamicVariables.length > 0) {
            // 包含动态变量参数
            controlDefine.listFilter[
              this.checkFilter(controlDefine.listFilter)
            ] = true;
            this.allFormulaConfigsMiXin.push({
              fieldName: controlDefine.configs.fieldName,
              filter: controlDefine.listFilter.filter,
              dynamicVariables: dynamicVariables
            });
          }
          break;
        }
        case "DisplayMember": {
          // 数据库获取数据使用DisplayMember设置的字段做label
          let tempData = util.trimStr(tempControlText);
          if (tempData && tempData !== "")
            controlDefine.listFilter.label = tempData;
          break;
        }
        case "ValueMember": {
          // 数据库获取数据使用DisplayMember设置的字段做value
          let tempData = util.trimStr(tempControlText);
          if (tempData !== "") controlDefine.listFilter.value = tempData;
          break;
        }
        case "DefaultSelectedIndex": {
          //默认选中项目
          let select = -1;
          if (tempControlText) {
            try {
              select = parseInt(tempControlText);
            } catch (e) {
              select = -1;
            }
          }
          controlDefine.defaultSelectedIndex = select;
          break;
        }
      }
    }
  },
  /**
   * 给组件设置默认值
   * @param controlProps
   * @param thisMainTableData
   */
  setControlDefaultValue: function(
    controlProps,
    thisMainTableData,
    getValOfFixedValueType
  ) {
    for (let i = 0; i < controlProps.length; i++) {
      let controlProp = controlProps[i];
      let fieldName = controlProp.fieldName
        ? controlProp.fieldName
        : controlProp.configs.fieldName;
      let isDesable = false;//controlProp.disable;暂时不需要
      //默认是禁用的表单控件不给它设置默认值
      if (fieldName && !isDesable) {
        let configs = controlProp.configs;
        let fixedValue = getValOfFixedValueType(
          configs.fixedValueType,
          controlProp
        );
        switch (controlProp.thisControlType) {
          case "EInput": {
            // 文本域默认值
            if (fixedValue)
              // 如果设置了固定值，使用固定值
              thisMainTableData[fieldName] = fixedValue;
            else if (configs.defaultValue)
              // 否则使用默认值
              thisMainTableData[fieldName] = configs.defaultValue;
            else thisMainTableData[fieldName] = "";
            break;
          }
          case "ENumericUpDown": {
            // 数字选择控件
            if (fixedValue)
              // 如果设置了固定值，使用固定值
              thisMainTableData[fieldName] = util.getNumber(fixedValue);
            else if (fixedValue === "") thisMainTableData[fieldName] = null;
            else if (configs.defaultValue)
              // 否则使用默认值
              thisMainTableData[fieldName] = util.getNumber(
                configs.defaultValue
              );
            else thisMainTableData[fieldName] = null;
            break;
          }
          case "EDatetimePicker": {
            //日期控件
            if (fixedValue)
              // 如果设置了固定值，使用固定值
              thisMainTableData[fieldName] = fixedValue;
            else if (fixedValue === "") thisMainTableData[fieldName] = null;
            else if (configs.defaultValue)
              thisMainTableData[fieldName] = configs.defaultValue;
            else thisMainTableData[fieldName] = null;
            break;
          }
          case "ESelect": {
            // 下拉列表默认选项
            if (fixedValue)
              // 如果设置了固定值，使用固定值
              thisMainTableData[fieldName] = fixedValue;
            else if (
              controlProp.options &&
              controlProp.options.length > 0 &&
              controlProp.defaultSelectedIndex >= 0
            )
              thisMainTableData[fieldName] = controlProp.options[
                controlProp.defaultSelectedIndex
              ]
                ? controlProp.options[controlProp.defaultSelectedIndex].value
                : controlProp.options[0].value;
            else if (configs.defaultValue && configs.defaultValue !== "") {
              // 如果设置了默认文本，则使用默认文本值
              thisMainTableData[fieldName] = configs.defaultValue;
            } else thisMainTableData[fieldName] = null;
            break;
          }
          case "EDepartmentSelect": // 弹出选择框，无默认值参数
          case "EEmployeeSelect":
          case "ESupplierSelect":
          case "ECustomer": {
            if (fixedValue)
              // 如果设置了固定值，使用固定值
              thisMainTableData[fieldName] = fixedValue;
            else thisMainTableData[fieldName] = null;
            break;
          }
          case "ECheckbox": {
            //复选项
            if (controlProp.checked && controlProp.checked.length > 0) {
              this.renderConsoleLog("复选默认选中", controlProp.checked);
              thisMainTableData[fieldName] = controlProp.checked;
            }
            break;
          }
          case "EBooleanCheckbox": {
            // 是否选择框
            thisMainTableData[fieldName] = controlProp.value;
            this.renderConsoleLog("是否选择框默认选中项", controlProp.value);
            break;
          }
          default: {
            thisMainTableData[configs.fieldName] = null;
          }
        }
      }
    }
  },
  /**
   * 解析表格内的公式配置
   * @param obj
   */
  getCFormulaConfig: function(obj) {
    let reg = new RegExp("\\[([^\\]]+)\\]", "ig"); //从字符串中匹配出所有用[]括起来的字段
    let formula = obj.cFormula;
    let newObj = {};
    if (formula) {
      let allFields = formula.match(reg);
      if (allFields && allFields.length > 0) {
        // 公式中所有的变量字段
        let fields = allFields.map(function(tObj) {
          return tObj.slice(0, -1).slice(1);
        });
        for (let j = 0; j < fields.length; j++) {
          if (fields[j].includes(".", 1)) {
            newObj.mxTableName = fields[j].split(".")[0];
          }
          formula = formula.replace(allFields[j], fields[j]);
        }

        newObj.rowFormula = obj.rowFormula ? true : false; // 明细行计算公式，true是
        newObj.resultField = obj.fieldName;
        newObj.variableField = fields;
        newObj.formula = formula;
      }
    }
    return newObj;
  },
  /**
   * 将字符串中所有用,分割出来的字段解析成字符串数组
   * @param str
   * @returns {*}
   */
  getAllRegMatched: function(str) {
    if (util.isType(str) === "String" && str != "") {
      //let reg = new RegExp("\\[([^\\]]+)\\]", "ig"); //从字符串中匹配出所有用[]括起来的字段
      //console.log("有可见范围配置：",str);
      if (str.indexOf(",") > 1) return str.split(",");
      else if (str.indexOf(";") > 1) return str.split(";");
      else return [util.trimStr(str)];
    }
    return null;
  },
  /**
   * 从obj中获取包含属性paramName的值为paramValue的对象或子对象
   * @param obj
   * @param paramName
   * @param paramValue
   * @returns {*}
   */
  getObjByParamValue: function(obj, paramName, paramValue) {
    if (util.isType(obj) === "Array") {
      for (let i = 0; i < obj.length; i++) {
        let tempObj = this.getObjByParamValue(obj[i], paramName, paramValue);
        if (tempObj) return tempObj;
      }
    } else if (
      util.isObjEqual(obj[paramName], paramValue) ||
      (util.isType(obj[paramName]) === "Array" &&
        obj[paramName].indexOf(paramValue) >= 0)
    ) {
      return obj;
    } else if (obj.childObject && obj.childObject.length > 0) {
      return this.getObjByParamValue(obj.childObject, paramName, paramValue);
    }
    return null;
  },
  /**
   * 从obj中获取第一个controlType对象
   * @param obj
   * @param controlType
   * @returns {*}
   */
  getObjByControlType: function(obj, controlType) {
    if (util.isType(obj) === "Array") {
      for (let i = 0; i < obj.length; i++) {
        let tempObj = this.getObjByControlType(obj[i], controlType);
        if (tempObj) return tempObj;
      }
    } else if (obj.controlType === controlType) {
      return obj;
    } else if (obj.childObject && obj.childObject.length > 0) {
      return this.getObjByControlType(obj.childObject, controlType);
    }
    return null;
  },

  /**
   * 从obj中获取所有controlType对象并返回为数组
   * @param obj
   * @param controlType
   * @param shuZu
   * @returns {*}
   */
  getAllObjByControlType: function(obj, controlType, shuZu) {
    if (util.isType(obj) === "Array") {
      for (let i = 0; i < obj.length; i++) {
        let tempObj = this.getAllObjByControlType(obj[i], controlType, shuZu);
        if (tempObj) shuZu.push(tempObj);
      }
    } else if (obj.controlType === controlType) {
      shuZu.push(obj);
    } else if (obj.childObject && obj.childObject.length > 0) {
      return this.getAllObjByControlType(obj.childObject, controlType, shuZu);
    }
    return null;
  },
  /**
   * 检测对应的字符串中是否包含由{}括起来的元素
   * @param listFilter
   * @return {string}
   */
  checkFilter(listFilter) {
    let reg = new RegExp("\\{([^\\}]+)\\}", "ig"); //从字符串中匹配出所有用{}括起来的字段
    let filter = listFilter.filter
    if (reg.test(filter)) {
      // 表示有变量
      reg.lastIndex = 0;
      let all = filter.match(reg);
      let index = all.findIndex(function(f) {
        return f.includes(".");
      });
      let tempMain = all.filter(function(d){
        return !d.includes(".");
      });
      if(tempMain.length> 0)
        listFilter.mainFields = tempMain.map(function(f1){
          return f1.substring(1, f1.length-1);
        });
      let tempDetail = all.filter(function(d1){
        return d1.includes(".");
      });
      if(tempDetail.length> 0)
        listFilter.detailFields = tempDetail.map(function(f2){
          return f2.substring(1, f2.length-1);
        });
      if (index >= 0) {
        return "haveDetailVariable";
      }
      return "haveVariable";
    }
    return "noVariable";
  },
  /**
   * 替换filter配置中的未知值,{}括号内是初始化参数，控件初始化时更新该参数值即可；
   * []括号内是变量参数，所以需要监听[]指定参数的值变化
   * @param filter 过滤条件表达式
   * @param mainTableData 当前主表单数据
   * @param detailData 对应行的明细数据
   * @param rowIndex 对应的行
   * @param allDetails 所有明细数据
   * @return {*}
   */
  changeFilter(filter, mainTableData, detailData, rowIndex, allDetails) {
    let reg = new RegExp("\\{([^\\}]+)\\}", "ig"); //从字符串中匹配出所有用{}括起来的字段
    let allFields = filter.match(reg);
    if (allFields && allFields.length > 0) {
      // 包含了未知字段值
      let thisFields = allFields.map(function(obj) {
        return obj.slice(0, -1).slice(1);
      });
      for (let fi = 0; fi < thisFields.length; fi++) {
        let fName = thisFields[fi];
        if (fName.includes(".")) {
          // 包含其他数据源的填充条件值
          let ftNames = fName.split(".");
          if (ftNames.length == 2) {
            if (ftNames[0] === "this") {
              // 需要从当前明细数据源获取填充值
              if (
                detailData &&
                detailData[ftNames[1]] != undefined &&
                detailData[ftNames[1]] != null
              ) {
                let value = util.trimStr(detailData[ftNames[1]]);
                filter = filter.replace(allFields[fi], value);
              }
            } else {
              //需要从其他同级明细表中获取填充数据，目前不支持
              if (
                allDetails &&
                allDetails.length > 0 &&
                util.isType(rowIndex) === "val" &&
                rowIndex >= 0
              ) {
                let tbObject = allDetails.find(function(tb) {
                  return tb.tableName === ftNames[0];
                });
                if (
                  tbObject &&
                  tbObject.data &&
                  tbObject.data.length > rowIndex
                ) {
                  let detail = tbObject.data[rowIndex];
                  let value = util.trimStr(detail[ftNames[1]]);
                  filter = filter.replace(allFields[fi], value);
                }
              }
            }
          }
        } else {
          // 需要从主表单中获取填充数据
          if (
            mainTableData &&
            mainTableData[fName] != undefined &&
            mainTableData[fName] != null
          ) {
            let value = "";
            if(util.isType(mainTableData[fName]) === "Object") {
              if(mainTableData[fName].value) {
                value = mainTableData[fName].value;
              }
            } else {
              value = util.trimStr(mainTableData[fName]);
            }
            filter = filter.replace(allFields[fi], value);
          }
        }
      }
      return filter;
    }
    return filter;
  }
};
export { templateParser, CXS_TO_ECONTROLS };
