/**
 * 支持e1平台的文件上传导入功能
 */
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求

export const e1ExcelImport = {
  mixins: [requestControls, pageControls],
  data() {
    return {
      excelImportAction_mixin: null // 当前的导入anction
    };
  },
  created() {
    //console.log("混入e1ExcelImport的通用方法");
  },
  methods: {
    /**
     * 导入功能
     */
    tsbExcelImport(button) {
      console.log("导入数据", button);
      let importActions = ["ImportBOMData", "ImportGylx"];
      if (
        button &&
        button.param &&
        button.param.bm &&
        importActions.includes(button.param.bm)
      ) {
        this.excelImportAction_mixin = button.param.bm;
        this.$refs.uploadFile.click();
      } else {
        this.tipWarning("此表单不可以使用此功能");
      }
    },
    /**
     * 导入功能传入文件
     */
    uploadChange(e) {
      let _this = this;
      let file = e.target.files;
      const formData = new FormData();
      let name = "";
      for (let i = 0; i < file.length; i++) {
        //    上传类型判断
        name = file[i].name;
        let idx = name.lastIndexOf(".");
        if (idx != -1) {
          let ext = name.substr(idx + 1).toUpperCase();
          ext = ext.toLowerCase();
          if (ext != "xls" && ext != "xlsx") {
            this.tipWarning("只能上传xls类型的文件");
            e.target.value = "";
            return;
          } else {
            file.forEach(fileObj => {
              formData.append("files[]", fileObj);
            });
          }
        } else {
          this.tipError("错误,无法确认文件类型");
          e.target.value = "";
          return;
        }
      }
      this.$confirm({
        title: "是否上传该文件?",
        content: "文件名:" + name,
        onOk() {
          let params = {
            action: _this.excelImportAction_mixin
          };
          _this.tableSpin = true;
          _this.upLoad("System.ashx", formData, params, {
            success: function(res) {
              if (res.success) {
                _this.tipSuccess(res.message);
                _this.tableSpin = false;
                _this.tsbRefresh();
                e.target.value = "";
              } else {
                _this.tipError("上传失败." + res.message);
                _this.tableSpin = false;
                e.target.value = "";
              }
            },
            error: function(res) {
              _this.tipError("上传失败." + res.message);
              _this.tableSpin = false;
              e.target.value = "";
            }
          });
        },
        onCancel() {
          _this.excelImportAction_mixin = null;
          e.target.value = "";
        }
      });
    }
  }
};
