<!-- 用于扩展表格的列样式 -->
<template>
  <div
    style="display: contents"
    v-if="
      column.scopedSlots &&
        column.scopedSlots.type &&
        column.scopedSlots.type === 'hyperlink'
    "
  >
    <!-- 给内容一个超链接 -->
    <!-- 第一种，如果配置了内容测试条件函数，则需要检验条件满足；如果没有配置内容测试条件函数并且内容不会分割为多个链接的情况下，只要配置了点击事件响应函数 -->
    <a
      v-if="
        column.scopedSlots &&
          ((column.scopedSlots.textCheckMethod &&
            column.scopedSlots.textCheckMethod(thisData[field])) ||
            (!column.scopedSlots.textCheckMethod &&
              !column.scopedSlots.splitSign &&
              column.scopedSlots.clickMethod))
      "
      :style="getColumStyle(column.width, column.align)"
      href="javascript:void(0);"
      :data-record="getJONS(thisData)"
      @click="onClick"
    >
      <a-tooltip
        v-if="column.scopedSlots.showTitle"
        :key="field + '_popover'"
        :placement="popoverPlacement"
        :title="defaultFormatText(thisData[field], column.title)"
      >
        <span class="etable-colum_a">
          {{
          defaultFormatText(thisData[field], column.title)
          }}
        </span>
      </a-tooltip>
      <span v-else class="etable-colum_a">
        {{
        defaultFormatText(thisData[field], column.title)
        }}
      </span>
    </a>
    <!-- 内容有需要渲染成多个超级链接, 需要将数据按分割符分割成数组后组织成多个链接-->
    <template
      v-else-if="
        column.scopedSlots && column.scopedSlots.splitSign && thisData[field]
      "
    >
      <template
        v-for="(item, index) in getStringSplitArray(
          thisData[field],
          column.scopedSlots.splitSign
        )"
      >
        {{ index > 0 ? "," : "" }}
        <a
          :key="index"
          href="javascript:void(0);"
          :data-record="getJONS(thisData)"
          @click="onClick($event, index)"
        >{{ item }}</a>
      </template>
    </template>
    <!-- 其他不满足条件的，不提供点击链接 -->
    <template v-else>
      {{
      defaultFormatText(thisData[field], column.title)
      }}
    </template>
  </div>
  <!-- 是一个会计金额输入控件-->
  <e-money
    v-else-if="
      column.scopedSlots &&
        column.scopedSlots.type &&
        column.scopedSlots.type === 'amountInput'
    "
    v-model="thisData[field]"
    :disabled="thisData.readOnly"
    :readOnly="thisData.readOnly"
    ref="money"
    :id="id"
    @enter="enter"
    @constraintEnter="constraintEnter"
    @clearOther="clearOther"
    @moneyEqual="moneyEqual"
  ></e-money>
  <!-- 会计科目选择控件 -->
  <table-button-controls
    v-else-if="
      column.scopedSlots &&
        column.scopedSlots.type &&
        column.scopedSlots.type === 'kjKmTextSelectButton' &&
        thisData[field] != undefined
    "
    v-model="thisData[field]"
    type="CW_KJKMJBSZ"
    :linkage="column.scopedSlots.linkColumns"
    @linkedChange="(linkedChange) => linked(linkedChange, thisData)"
    @change="
      (value, name) => {
        onChange({ value: value, name: name }, thisData);
      }
    "
    @constraintEnter="constraintEnter"
  ></table-button-controls>
  <!-- 会计摘要选择控件 -->
  <table-button-controls
    v-else-if="
      column.scopedSlots &&
        column.scopedSlots.type &&
        column.scopedSlots.type === 'kjZyTextSelectButton' &&
        thisData[field] != undefined
    "
    v-model="thisData[field]"
    type="CW_CYZY"
    @change="
      (value, name) => {
        onChange({ value: value, name: name }, thisData);
      }
    "
    @enter="enter"
    @constraintEnter="constraintEnter"
  ></table-button-controls>
  <keepAccountZY
    v-else-if="
      column.scopedSlots &&
        column.scopedSlots.type &&
        column.scopedSlots.type === 'keepAccountZY'
    "
    :size="$config.defaultControlSize"
    v-model="thisData[field]"
    :height="column.height"
    ref="zy"
    @nextRow="nextRow"
    @lastRow="lastRow"
    @constraintEnter="constraintEnter"
    :waiting="waiting"
    :dataOptionSave="dataOptionSave"
    @postDataOptionSave="postDataOptionSave"
  />
  <keepAccountKM
    v-else-if="
      column.scopedSlots &&
        column.scopedSlots.type &&
        column.scopedSlots.type === 'keepAccountZYKM'
    "
    :size="$config.defaultControlSize"
    v-model="thisData[field]"
    ref="km"
    :linkage="column.scopedSlots.linkColumns"
    @linkedChange="(linkedChange) => linked(linkedChange, thisData)"
    @change="
      (value, name) => {
        onChange({ value: value }, thisData);
      }
    "
    @showHs="
      (value, name) => {
        onChange({ value: value }, thisData);
      }
    "
    @tableBlur="tableBlur"
    @kminput="kminput"
    @constraintEnter="constraintEnter"
    :waiting="waiting"
    :dataOptionSave="dataOptionSave"
    @postDataOptionSave="postDataOptionSave"
    :kmNext="kmNext"
    @kmNext="kmNextNum"
  />
  <!-- 其他控件 -->
  <a-input
    v-else-if="
      column.scopedSlots &&
      column.scopedSlots.type &&
      column.scopedSlots.type !== '' &&
      thisData[field] !== undefined
    "
    :size="$config.defaultControlSize"
    :type="column.scopedSlots.dataType"
    v-model="thisData[field]"
  ></a-input>
  <!-- 未定义内容样式，默认直接显示内容 <span class="etable-colum_span" :style="{width: column.width+'px' }"> -->
  <a-tooltip
    v-else-if="column.scopedSlots && column.scopedSlots.showTitle"
    :key="field + '_popover'"
    :placement="popoverPlacement"
    :title="defaultFormatText(thisData[field], column.title)"
  >
    <span
      class="etable-colum_a"
      :style="{
        color:
          column.scopedSlots && column.scopedSlots.color
            ? column.scopedSlots.color(thisData[field])
            : ''
      }"
    >{{ defaultFormatText(thisData[field], column.title) }}</span>
  </a-tooltip>
  <!-- 其他文本列 -->
  <span
    v-else
    :class="
      column.scopedSlots && column.scopedSlots.leftIcon
        ? 'etable-colum_icon'
        : 'etable-colum_a'
    "
    :style="{
      color:
        column.scopedSlots && column.scopedSlots.color
          ? column.scopedSlots.color(thisData[field])
          : ''
    }"
  >
    <e-icon
      v-if="
        column.scopedSlots &&
          column.scopedSlots.leftIcon &&
          column.scopedSlots.leftIcon(thisData[field])
      "
      :style="{
        fontSize: '18px',
        height: '21px',
        cursor: column.scopedSlots.clickMethod ? 'pointer' : 'default'
      }"
      :type="column.scopedSlots.leftIcon(thisData[field])"
      @click="onClick"
    ></e-icon>
    <!--图标上的徽标 -->
    <div
      v-if="
        column.scopedSlots &&
          column.scopedSlots.iconBadge &&
          column.scopedSlots.iconBadge(thisData[field]) > 0
      "
      class="icon-badge"
      @click="onClick"
    >
      <span style="padding: 2px;">
        {{
        column.scopedSlots.iconBadge(thisData[field])
        }}
      </span>
    </div>
    <span
      v-if="columnText && columnText !== ''"
      :style="{
        marginLeft:
          column.scopedSlots &&
          column.scopedSlots.leftIcon &&
          column.scopedSlots.leftIcon(thisData[field])
            ? '10px'
            : '0'
      }"
    >{{ columnText }}</span>
  </span>
</template>

<script>
export default {
  name: "ETableColum",
  data() {
    return {
      popoverPlacement: "right",
      thisData: {},
      field: null,
      invalidString: ["null", "NULL", "Null", "undefined", "Undefined"]
    };
  },
  props: {
    column: {
      type: Object,
      default: function() {
        return {};
      }
    },
    slotScopeData: {
      type: [String, Number, Boolean, Object, Array],
      default: null
    },
    id: {
      type: String,
      default: ""
    },
    dataOptionSave: {
      type: null,
      default: function() {
        return null;
      }
    },
    waiting: {
      type: Boolean,
      default: false
    },
    kmNext: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    slotScopeData: function(v) {
      //console.log("响应数据变化",v);
      if (v) {
        this.thisData = v;
      }
    }
  },
  computed: {
    columnText: function() {
      return this.column.scopedSlots && this.column.scopedSlots.customRenderText
        ? this.column.scopedSlots.customRenderText(this.thisData[this.field])
        : this.defaultFormatText(this.thisData[this.field], this.column.title);
    }
  },
  mounted() {
    this.thisData = this.slotScopeData;
    this.field = this.column.dataIndex || this.column.field;
  },
  methods: {
    /**
     * 将字符串按照指定分割符进行分割返回为字符串数组
     * @param str
     * @param split 分割字符
     */
    getStringSplitArray(str, split) {
      return this.$util.stringSplitArray(str, split);
    },
    /**
     * 将对象转换为json字符串
     */
    getJONS: function(data) {
      return JSON.stringify(data);
    },
    /**
     * 根据宽度和对齐方式，调整行内容样式
     *
     width: (width-20)+'px',
     paddingLeft: ((width-20)/2)+'px'
     * @param width
     * @param align
     * @returns {*}
     */
    getColumStyle(width, align) {
      if (width) {
        if (align === "center") return {};
        else
          return {
            width: width + "px"
          };
      }
      return {};
    },
    /**
     * 响应控件值变化事件
     * @param event
     * @param record
     */
    onChange(event, record) {
      this.$emit("onChange", {
        event: event,
        record: record
      });
    },
    /**
     * 响应列数据点击事件
     * @param event
     * @param index
     */
    onClick(event, index) {
      if (this.column.scopedSlots && this.column.scopedSlots.clickMethod) {
        this.$emit("onClick", {
          event: event,
          record: this.thisData,
          text: this.thisData[this.field],
          column: this.column,
          linkMethod: this.column.scopedSlots.clickMethod,
          index: index,
          callBack: this.column.scopedSlots.clickCallback
        });
      }
    },
    /**
     * 响应联动事件
     * @param linkedChange
     * @param record
     */
    linked(linkedChange, record) {
      this.$emit("onLinked", {
        linkedChange: linkedChange,
        record: record
      });
    },
    /**
     * 默认对显示内容数据进行格式化
     * @param str
     * @param title
     */
    defaultFormatText(str, title) {
      return this.$util.defaultFormatText(str, title);
    },
    // 获得焦点
    tableFocus(e) {
      var type = this.column.scopedSlots.type;
      if (type === "amountInput") {
        // 金额
        this.$refs.money.tableFocus(e);
      } else if (type == "keepAccountZY") {
        // 摘要
        this.$refs.zy.tableFocus(e);
      } else if (type == "keepAccountZYKM") {
        // 科目
        this.$refs.km.tableFocus(e);
      }
    },
    // 取消焦点
    tableBlur() {
      var type = this.column.scopedSlots.type;
      if (type === "amountInput") {
        // 金额
        this.$refs.money.tableBlur();
      } else if (type == "keepAccountZY") {
        // 摘要
        this.$refs.zy.tableBlur();
      } else if (type == "keepAccountZYKM") {
        // 科目
        this.$refs.km.tableBlur();
        this.$emit("setIndex");
      }
    },
    nextRow(e) {
      this.$emit("nextRow", e);
    },
    lastRow(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit("lastRow", e);
    },
    enter(e) {
      this.$emit("enter", e);
    },
    constraintEnter(e) {
      this.$emit("constraintEnter", e);
    },
    kminput() {
      this.$emit("kminput");
    },
    clearOther(v) {
      this.$emit("clearOther", v);
    },
    clearMoney() {
      var type = this.column.scopedSlots.type;
      if (type === "amountInput") {
        // 金额
        this.$refs.money.clearMoney();
      }
    },
    setMoney(v) {
      var type = this.column.scopedSlots.type;
      if (type === "amountInput") {
        // 金额
        this.$refs.money.setMoney(v);
      }
    },
    moneyEqual(e) {
      this.$emit("moneyEqual", e);
    },
    postDataOptionSave(v) {
      this.$emit("postDataOptionSave", v);
    },
    isScan(v) {
      this.$emit("isScan", v);
    },
    kmNextNum() {
      this.$emit("kmNextNum");
    }
  }
};
</script>

<style lang="less" scoped>
@import "../assets/style/base/components/e-table-colum.less";
</style>
