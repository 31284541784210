/**
 * 支持蓝能ERP生产管理相关功能扩展
 * wanxing -2020-07-19
 */
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求

export const e1FormToolbarSCGL = {
  mixins: [pageControls, requestControls],
  data() {
    return {
      tempPreviousWorkflowFormNos_MiXin: null, // 多个上张业务表
      tempNextWorkflowFormNos_MiXin: null // 多个下张业务表
    };
  },
  created() {
    //console.log("混入e1FormToolbarToAssociated的通用方法");
  },
  methods: {
    /**
     * 打开编辑BOM副本的tab
     */
    tsbBOM: function() {
      // bom副本对应的jdbm = 85004020001-SCDD20200713005
      let BDBH = this.bdbh; //"SCDD20200720002"; // 生产订单编号
      let BOM = this.form.getFieldValue("_BOMBM"); // 原bom的JDBM
      let gylxBM = this.form.getFieldValue("_GYLXBM"); // 原工艺路线编码
      let that = this;
      this.getOrCreateGYLX_FB(gylxBM, BDBH, function() {
        let title = "编辑" + BDBH + "-BOM-" + BOM;
        let pageConfig = that.getBOMCopyManageViewConfig(
          BOM,
          BDBH,
          that.dataFromType,
          that.spState
        );
        that.$store.commit("setCacheData", pageConfig);
        that.openTab({
          title: title,
          path: "/UserForms",
          query: {
            title: "生产订单-" + BDBH + "BOM管理",
            type: "dataViewCache" // 表示视图配置来源于缓存
          }
        });
      });
    },
    /**
     * 获取或创建后返回对应生产订单的工艺路线副本ID
     * @param gylxBM
     * @param BDBH
     * @param callback
     */
    getOrCreateGYLX_FB: function(gylxBM, BDBH, callback) {
      let that = this;
      let params = {
        action: "GetGyLxIdByGylxbm",
        gylxBm: gylxBM,
        scddbh: BDBH
      };
      //console.log("select通过查询条件",listFilter, eTableIsSave);7uy
      this.get("Form.ashx", params, {
        success: function(res) {
          console.log("查询到对应的数据", res);
          if (res && res.data) {
            if (callback) callback(res.data);
          } else {
            that.alertWarning("未找到对应生产订单的工艺路线副本记录");
          }
        },
        error: function(err) {
          console.error("获取指定表数据失败", err);
          that.alertWarning("未找到对应生产订单的工艺路线副本记录");
        }
      });
    },
    /**
     * 打开编辑工艺路线副本的tab
     * @param button
     * @param other
     */
    tsbProduceRouting: function(button, other) {
      console.log("工艺路线", button, other);
      let that = this;
      let optType = 0;
      if (this.dataFromType === "dateManageView") {
        // 进行数据管理
        if (this.spState !== "未下达") {
          this.tipWarning("已下达的生产订单不能再编辑工艺路线");
          optType = 1;
        }
      }
      let BDBH = this.bdbh; //// 生产订单编号
      if (button.param.et_name === "901") {
        // 是工单确认单查看工艺路线
        optType = 1;
        BDBH = this.form.getFieldValue("_SCDDH");
      }
      let gylxBM = this.form.getFieldValue("_GYLXBM"); // 原工艺路线编码
      this.getOrCreateGYLX_FB(gylxBM, BDBH, function(data) {
        console.log("查询到对应的数据", data);
        let gylx = data;
        let title =
          (optType == 0 ? "编辑" : "查看") +
          "生产订单-" +
          BDBH +
          "-工艺路线管理";
        that.openTab({
          title: title,
          path: optType == 0 ? "/NewForm" : "/DetailForm",
          query: {
            dhbm: 983,
            templetId: 409,
            dataId: gylx,
            bm: "SYSTEM",
            type: "dateManageView"
          }
        });
      });
    }
  }
};
