/**
 * 支持e1平台的物料价格结转功能
 */
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求

export const e1CarryOver = {
  mixins: [requestControls, pageControls],
  data() {
    return {
      spModalShow: false // 审批意见窗口显示状态
    };
  },
  created() {
    //console.log("混入e1FormToolbar的通用方法");
  },
  methods: {
    /**
     * 重新结转功能
     * @param button
     */
    tsbAgain: function(button) {
      this.startProcessing(button);
      let params = {
        action: "Wljqpjjgjz",
        JZLX: 0,
        randomKey: this.$store.getters.getRandomKey
      };
      let that = this;
      this.post("System.ashx", params, {
        timeout: 600000,
        success: function(req) {
          if (req) {
            that.pagination.current = 1;
            that.getTableData(button);
          } else {
            that.finishedProcessing(button);
            that.alertError("重新结转失败,返回数据异常");
          }
        },
        error: function(err) {
          that.finishedProcessing(button);
          that.alertError("重新结转失败," + err.message);
        }
      });
    },
    /**
     * 回退结转功能
     * @param button
     */
    tsbRegression: function(button) {
      this.startProcessing(button);
      let params = {
        action: "Wljqpjjgjz",
        JZLX: 1,
        randomKey: this.$store.getters.getRandomKey
      };
      let that = this;
      this.post("System.ashx", params, {
        success: function(req) {
          if (req) {
            that.pagination.current = 1;
            that.getTableData(button);
          } else {
            that.finishedProcessing(button);
            that.alertError("回退结转失败,返回数据异常");
          }
        },
        error: function(err) {
          that.finishedProcessing(button);
          that.alertError("回退结转失败," + err.message);
        }
      });
    },
    /**
     * 前进结转功能
     * @param button
     */
    tsbForward: function(button) {
      this.startProcessing(button);
      let params = {
        action: "Wljqpjjgjz",
        JZLX: 2,
        randomKey: this.$store.getters.getRandomKey
      };
      let that = this;
      this.post("System.ashx", params, {
        timeout: 600000,
        success: function(req) {
          if (req) {
            that.pagination.current = 1;
            that.getTableData(button);
          } else {
            that.finishedProcessing(button);
            that.alertError("前进结转失败,返回数据异常");
          }
        },
        error: function(err) {
          that.finishedProcessing(button);
          that.alertError("前进结转失败," + err.message);
        }
      });
    },
    /**
     * 暂估结转功能
     * @param button
     */
    tsbEstimate: function(button) {
      this.startProcessing(button);
      let params = {
        action: "Wljqpjjgjz",
        JZLX: 3,
        randomKey: this.$store.getters.getRandomKey
      };
      let that = this;
      this.post("System.ashx", params, {
        timeout: 600000,
        success: function(req) {
          if (req) {
            that.pagination.current = 1;
            that.getTableData(button);
          } else {
            that.finishedProcessing(button);
            that.alertError("前进结转失败,返回数据异常");
          }
        },
        error: function(err) {
          that.finishedProcessing(button);
          that.alertError("前进结转失败," + err.message);
        }
      });
    }
  }
};
