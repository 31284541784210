const setObj = {
  CW_CBYS: {
    //
    type: "table",
    action: "GetControlData",
    controlName: "CW_CBYS",
    serverName: "Form.ashx"
  },
  CW_CBZX: {
    //
    type: "table",
    action: "GetControlData",
    controlName: "CW_CBZX",
    serverName: "Form.ashx"
  },
  CW_CYZY: {
    //
    type: "table",
    action: "GetControlData",
    controlName: "CW_CYZY",
    serverName: "Form.ashx"
  },
  CW_FSJEFormula: {
    //不管
    type: "table",
    action: "GetControlData",
    controlName: "CW_FSJEFormula",
    serverName: "Form.ashx"
  },
  CW_KJKMJBSZ: {
    //
    type: "tree",
    action: "GetCW_KJKMJBSZData",
    // action: "GetWZFLList",
    nodeAction: "GetCW_KJKMJBSZChildrenData",
    controlName: "CW_KJKMJBSZ",
    serverName: "Form.ashx",
    nodeActionParam: {
      // 异步加载时的参数  参数名:数据字段
      BM: "key",
      JC: "JC"
    }
  },
  RS_KQLXDY: {
    //
    type: "tree",
    action: "GetControlData",
    controlName: "RS_KQLXDY",
    serverName: "Form.ashx"
  },
  SC_CPJGDYMXB: {
    type: "table",
    action: "GetControlData",
    controlName: "SC_CPJGDYMXB",
    serverName: "Form.ashx"
  },
  SC_CPJGDYMXB_NOTREE: {
    type: "table",
    action: "GetControlData",
    controlName: "SC_CPJGDYMXB_NOTREE",
    serverName: "Form.ashx"
  },
  SC_CPJGJDDY: {
    //没有表
    type: "table",
    action: "GetControlData",
    controlName: "SC_CPJGJDDY",
    serverName: "Form.ashx"
  },
  XM_WBSDY: {
    //
    type: "tree",
    action: "GetControlData",
    controlName: "XM_WBSDY",
    serverName: "Form.ashx"
  },
  CW_LSCGJG: {
    type: "table",
    action: "GetControlData",
    controlName: "CW_LSCGJG",
    serverName: "Form.ashx"
  }
};

function getOption() {
  return setObj;
}

export { getOption };
