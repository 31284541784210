/** * 关于为何使用v-if mode=“xx”形式 *
开发过程中发现,标签mode属性和showtime属性不能共用，否则会无法选择数值 *
该问题在ant上就存在，也没有报错信息，无法解决 */

<template>
  <div :style="setWidth" v-show="visible" class="e-datetime-picker__box">
    <a-range-picker
      v-if="mode == 'date'"
      @change="change"
      :onPanelChange="change"
      :disabledDate="disabledDate"
      :defaultValue="defaultValue"
      :size="controlSize"
      :format="thisFormat"
      :value="setValue"
      :readOnly="readOnly"
      :disabled="disabled"
      :ranges="defaultRange"
      :open="nowOpen"
      @openChange="handleOpenChange"
      ref="dataPicker"
    ></a-range-picker>
    <a-range-picker
      v-else-if="mode == 'datetime'"
      @change="change"
      :onPanelChange="change"
      :disabledDate="disabledDate"
      :defaultValue="defaultValue"
      :size="controlSize"
      :format="thisFormat"
      showTime
      :value="setValue"
      :readOnly="readOnly"
      :disabled="disabled"
      :open="nowOpen"
      :ranges="defaultRange"
      @openChange="handleOpenChange"
      ref="dataPicker"
    ></a-range-picker>
    <!-- 控件后附带的注释提醒 :disabledDate="disabledDate"-->
    <li v-if="thisOldValue" class="form_right_icon">
      <a-tooltip
        placement="topRight"
        :title="'原值：' + thisOldTime"
        arrow-point-at-center
      >
        <a style="height: auto;"><e-icon type="exclamation-circle"></e-icon></a>
      </a-tooltip>
    </li>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "EDatetimePicker",
  data() {
    return {
      setValue: null,
      thisValue: null,
      controlSize: this.$config.defaultControlSize,
      thisFormat: null,
      nowReturnFormat: "YYYY-MM-DD hh:mm:ss",
      thisOldValue: null,
      showValue: null,
      startValue: null,
      endValue: null,
      nowOpen: false,
      defaultRange: {
        今天: [moment(), moment()],
        本月: [moment().startOf("month"), moment().endOf("month")]
      }
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    defaultValue: {
      type: [Object, String]
    },
    value: {
      type: [Object, String]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: "datetime"
    },
    format: {
      type: [String, Array],
      default: "YYYY-MM-DD hh:mm:ss"
    },
    returnFormat: {
      type: [String],
      default: "YYYY-MM-DD hh:mm:ss"
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number],
      default: ""
    },
    visible: {
      type: Boolean,
      default: true
    },
    raw: {
      type: Boolean,
      default: false
    },
    modifiedValue: {
      type: [Object, String],
      default: null
    },
    isMx: {
      type: Boolean,
      default: false
    },
    open: {
      // 当前是否弹出日历
      type: Boolean,
      default: false
    },
    maxTime: {
      type: [Object, String],
      default: function() {
        return null;
      }
    },
    minTime: {
      type: [Object, String],
      default: function() {
        return null;
      }
    }
  },
  created() {
    if (this.mode === "date") {
      if (this.format === "YYYY-MM-DD hh:mm:ss") this.thisFormat = "YYYY-MM-DD";
      this.nowReturnFormat = "YYYY-MM-DD";
    } else if (this.mode === "time") {
      if (this.format === "YYYY-MM-DD hh:mm:ss") this.thisFormat = "hh:mm:ss";
      this.nowReturnFormat = "hh:mm:ss";
    } else {
      this.thisFormat = this.format;
      this.nowReturnFormat = this.returnFormat;
    }
    this.nowOpen = this.open;
    this.thisValue = this.value;
    this.startValue = this.getDataMoment(this.minTime);
    this.endValue = this.getDataMoment(this.maxTime);
    //console.log("日期控件类型：", this.thisFormat);
  },
  mounted() {
    this.initializationValue();
  },
  methods: {
    change(value) {
      this.setValue = value;
    },
    emitChange(value) {
      if (value == null || value == "") {
        this.$emit("change", null);
        return;
      }
      var emitValue =
        value[0].format(this.nowReturnFormat) +
        "," +
        value[1].format(this.nowReturnFormat);
      this.$emit("change", emitValue);
    },
    /**
     * 将传入的value视情况转换为moment类型
     */
    getDataMoment(value) {
      if (
        this.$util.isType(value) === "String" &&
        this.$util.dateUtils.checkStringIsTime(value)
      ) {
        return new moment(value);
      } else if (
        this.$util.isType(value) === "Object" &&
        value instanceof moment
      ) {
        return value;
      }
      return null;
    },
    setStartValue(value) {
      this.startValue = this.getDataMoment(value);
    },
    setEndValue(value) {
      this.endValue = this.getDataMoment(value);
    },
    initializationValue() {
      if (this.thisValue == "" || this.thisValue == null) {
        this.setValue = null;
        return null;
      }
      if (typeof this.thisValue == "string") {
        if (this.thisValue === "today") {
          // 今日
          this.setValue = [new moment(), new moment()];
        } else if (this.thisValue === "lastMonth") {
          // 上个月
          this.setValue = [
            moment(new Date())
              .subtract(1, "months")
              .format(this.nowReturnFormat),
            new moment()
          ];
        } else if (this.thisValue === "lastYear") {
          // 去年
          this.setValue = [
            moment(new Date())
              .subtract(1, "years")
              .format(this.nowReturnFormat),
            new moment()
          ];
        } else {
          var value = this.thisValue.split(",");
          var start = value[0] ? new moment(value[0]) : new moment();
          var end = value[1] ? new moment(value[1]) : new moment();
          if (start <= end) {
            this.setValue = [start, end];
          } else {
            this.setValue = [end, start];
          }
        }
        return this.setValue;
      }
      if (typeof this.setValue === "object" && !this.raw) {
        this.emitChange(this.setValue);
      } else {
        this.emitChange(this.setValue);
      }
      // this.setValue = this.value;
      // this.change(this.setValue);
      return this.setValue;
    },
    tableFocus() {
      this.$refs.dataPicker.focus();
    },
    tableBlur() {
      this.$refs.dataPicker.blur();
    },
    /**
     * 检测是否设置了可选时间，如何设置了，需要判断范围，否则，直接返回true
     * @param currentDate
     * @return Boolean 返回true表示禁用
     */
    disabledDate(currentDate) {
      const endValue = this.endValue;
      const startValue = this.startValue;
      /*console.log("检测选中的时间",currentDate.format(this.nowReturnFormat),
      "\nstartValue=",startValue ? startValue.format(this.nowReturnFormat): "null",
      "\nendValue=",endValue ? endValue.format(this.nowReturnFormat): "null");*/
      if (!endValue && !startValue) {
        // 未设置日期范围
        return false;
      }
      let isOK = false,
        isOk1 = false,
        isOk2 = false;
      if (currentDate && endValue) {
        // 设置了maxTime
        isOk1 = currentDate.valueOf() > endValue.valueOf();
      }
      if (currentDate && startValue) {
        // 设置了minTime
        isOk2 = currentDate.valueOf() <= startValue.valueOf();
      }
      //console.log("isok1=",isOk1,",isok2=",isOk2);
      isOK = isOk1 || isOk2;
      return isOK;
    },
    /**
     * 监听弹出日历选择框
     * @param open
     */
    handleOpenChange(open) {
      this.nowOpen = open;
      this.$emit("openChange", open);
    }
  },
  computed: {
    thisOldTime: function() {
      if (this.thisOldValue) {
        try {
          return new moment(this.thisOldValue).format(this.nowReturnFormat);
        } catch (e) {
          console.log(e);
          return this.thisOldValue;
        }
      }
      return "";
    },
    setWidth: function() {
      let widthObj = { minWidth: "170px" };
      if (this.mode === "date") {
        widthObj.minWidth = "125px";
      } else if (this.mode === "time") {
        widthObj.minWidth = "110px";
      }
      if (this.width !== "") {
        if (
          typeof this.width == "number" ||
          this.$util.isNumberString(this.width)
        ) {
          widthObj.width = this.width + "px";
        } else if (
          this.$util.isPercentageString(this.width) ||
          this.$util.isPixelString(this.width)
        ) {
          widthObj.width = this.width;
        }
      }
      return widthObj;
    }
  },
  watch: {
    open(value) {
      this.nowOpen = value;
    },
    setValue(value) {
      // value
      //console.log("显示的时间",value);
      if (this.isMx) {
        this.tableFocus();
      }
      if (value == null || value == undefined) {
        this.emitChange(null);
        return;
      }
      if (typeof value === "object" && !this.raw) {
        if (value == null || value == "") {
          this.emitChange(null);
        } else {
          this.emitChange(value);
        }
      } else {
        this.emitChange(value);
      }
    },
    value(v) {
      this.thisValue = v;
      this.initializationValue();
    },
    modifiedValue: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(val) {
        //console.log("监听EDatetimePicker值", val);
        if (val) {
          // 新值和老值互换显示
          this.thisValue = val;
          this.thisOldValue = this.value;
        }
      }
    },
    disabled() {}
  }
};
</script>
<style scoped lang="less">
/deep/ .ant-input {
  font-size: 13px !important;
  padding-left: 5px !important;
}
.e-datetime-picker__box {
  position: relative;
}
.form_right_icon {
  position: absolute;
  width: 20px;
  height: calc(100%);
  top: 0;
  right: -25px;
  z-index: 1200;
}
</style>
