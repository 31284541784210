<!-- 权限设置功能-树状表单编辑视图 -->
<template>
  <div>
    <div v-if="showToolbar" class="form-headButton">
      <!-- 授权操作按钮 -->
      <e-toolbar
        :buttons="buttons"
        :operationDisabled="operationDisabled"
        :size="$config.defaultControlSize"
        @click="onButtonClick"
      ></e-toolbar>
    </div>
    <a-layout class="treeView-layout" :class="expandTreeViewClass">
      <a-layout-header v-if="!isNewTheme && showToolbar" class="view-header">
        <!--表title -->
        <e-head-title
          title="权限设定"
          :size="$config.defaultControlSize"
        ></e-head-title>
      </a-layout-header>
      <a-layout class="view-content">
        <!-- 左侧树 -->
        <a-layout-sider class="view-sider" :class="expandViewSilderClass">
          <div v-if="isNewTheme" class="view-sider-leftHead">
            <!--表title -->
            <e-head-title
                :title="title"
                :size="$config.defaultControlSize"
            ></e-head-title>
          </div>
          <div :class="isNewTheme ? expandViewSilderTreeClass : ''">
            <a-spin
              :spinning="showTreeSpin"
              tip="树加载中..."
              size="large"
              class="tree-spin"
            ></a-spin>
            <a-tree
              v-if="!showTreeSpin"
              showLine
              defaultExpandAll
              :treeData="treeData"
              @select="onTreeSelect"
            >
              <!--:loadData="onLoadTreeData" 异步加载数据  -->
            </a-tree>
          </div>
        </a-layout-sider>
        <a-layout-sider
          class="view-sider  view-sider__leftTable"
          :class="expandViewSilderClass"
        >
          <div v-if="isNewTheme" class="view-sider-leftHead">
            <!--表title -->
            <e-head-title
                :title="tableTitle"
                :size="$config.defaultControlSize"
            ></e-head-title>
          </div>
          <div :class="isNewTheme ? expandViewTableBoxClass : ''">
            <a-table
              ref="table1"
              rowKey="RYID"
              bordered
              :class="$config.table_stripe ? 'ant-table-stripe' : ''"
              :loading="showTableSpin1"
              :dataSource="table1Data"
              :columns="table1Col"
              :scroll="{ x: 200, y: 400 }"
              :size="aTableSize_MiXin"
              :pagination="pagination2"
              @change="tableChange2"
              :customRow="table1CustomRow"
            ></a-table>
          </div>
        </a-layout-sider>
        <a-layout-sider
          class="view-sider view-sider__rightTable"
          :class="expandViewSilderClass"
        >
          <div v-if="isNewTheme" class="view-sider-leftHead">
            <!--表title -->
            <e-head-title
                :title="table2Title"
                :size="$config.defaultControlSize"
            ></e-head-title>
          </div>
          <div :class="isNewTheme ? expandViewTableBoxClass : ''">
            <a-table
              ref="table2"
              bordered
              :class="$config.table_stripe ? 'ant-table-stripe' : ''"
              rowKey="id"
              :loading="showTableSpin2"
              :dataSource="table2Data"
              :columns="table2Col"
              :components="thComponents"
              :scroll="{ x: 200, y: 400 }"
              :size="aTableSize_MiXin"
              :pagination="pagination"
              @change="tableChange"
            >
              <template v-for="col in table2Col" :slot="col.dataIndex">
                <div :key="col.dataIndex">
                  {{ col }}
                  <template>
                    <a-popover>
                      <template slot="content">
                        保存
                      </template>
                    </a-popover>
                  </template>
                </div>
              </template>
            </a-table>
          </div>
        </a-layout-sider>
      </a-layout>
    </a-layout>
    <AuthorityChange
      ref="AuthorityChange"
      @cancel="changeAuthCancel"
    ></AuthorityChange>
    <!-- 复制权限给指定用户的用户选择弹出窗-->
    <e-form-modal
        v-model="copyPermissionModalShow"
        :showOk="true"
        :title="copyPermissionModalTitle"
        @okClicked="onCopyPermission"
    >
        <template slot="content">
            <div class="copy-permission-form">
                <a-form :form="copyPermissionForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
                    <a-form-item label="被复制权限的用户：">
                        {{copiedUser}}
                    </a-form-item>
                    <a-form-item label="被赋予权限的用户：">
                        <e-employee-select
                                width="300"
                                mode="multi"
                                v-decorator="[
                                    'beGiverUser',
                                    {
                                      rules: [{ required: true, message: '请选择需要被赋权的用户' }]
                                    }
                                  ]"
                        ></e-employee-select>
                    </a-form-item>
                </a-form>
            </div>
        </template>
    </e-form-modal>
  </div>
</template>

<script>
import { tableThResizeable } from "@/controls/Table/TabelThResizeable.js"; // 表格头可变列宽控件
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { analyticalTemplate } from "@/mixins/analyticalTemplate.js"; // 解析xml模板的方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法

export default {
  mixins: [
    requestControls,
    pageControls,
    analyticalMenuAuth,
    analyticalTemplate
  ],
  name: "AuthorityView",
  data() {
    return {
      title: "权限设定",
      showTreeSpin: false,
      showTableSpin1: false,
      showTableSpin2: false,
      treeData: [],
      thisConfig: null,
      viewConfig: null,
      tempId: null,
      selectedNodeKeys: [], // 选中的树节点
      selectRowInfo: [], // 选中树的数据
      table1Col: [], // 表1行定义
      table2Col: [], // 表2行定义
      thComponents: null,
      table1Data: [], // 表1数据
      table2Data: [], // 表2数据
      buttons: [], // 当前用户可用的操作按钮
      operationDisabled: false, // 操作按钮可用
      selectedUserRowKeys: [],
      selectedUserRow: null,

      cacheRecord: "",
      cacheIndex: "",
      treeTitle: "",
      tableSelectedTitle: "",
      pagination2: {
        current: 1,
        pageSize: 20,
        showLessItems: true,
        showSizeChanger: true,
        operationDisabled: true, // 默认加载成功前工具栏不能操作
        pageSizeOptions: ["20", "25", "30", "40"]
      }, //Object.assign({}, this.pagination)
      copyPermissionModalShow: false,
      copyPermissionModalTitle: "",
      copyPermissionForm: null,
    };
  },
  components: {},
  props: {
    pageConfig: {
      type: Object
    }
  },
  computed: {
    copiedUser: function() {
        if(this.selectedUserRow) {
            return this.selectedUserRow["RYXM"];
        }
        return "";
    },
    rowSelectionUser: function() {
      return {
        selectedRowKeys: this.selectedUserRowKeys,
        onChange: this.onSelectUserChange,
        hideDefaultSelections: true,
        type: "radio"
      };
    },
    // 根据当前设置的全局尺寸，改变界面样式
    expandTreeViewClass: function() {
      return this.getClassNameBySize("treeView-layout", this.showToolbar);
    },
    expandViewSilderClass: function() {
      return this.getClassNameBySize("view-sider", this.showToolbar);
    },
    expandViewSilderTreeClass: function() {
      let classes = [];
      classes.push("view-sider-tree");
      classes.push(this.getClassNameBySize("view-sider-tree", true));
      return classes;
    },
    expandViewTableBoxClass: function() {
      let classes = [];
      classes.push("view-sider-table-box");
      classes.push(this.getClassNameBySize("view-sider-table-box", true));
      return classes;
    },
    showToolbar: function() {
      return this.checkToobleIsShow(this.buttons);
    },
    tableTitle: function() {
      return this.treeTitle && this.treeTitle!=="" ? this.treeTitle: "所有员工";
    },
    table2Title:function() {
      return this.tableSelectedTitle && this.tableSelectedTitle!=="" ? this.tableSelectedTitle: "授权列表";
    }
  },
  watch: {},
  created() {
    this.thisConfig = this.pageConfig;
    //分析出用户可做操作按钮
    this.buttons = this.getUserJurisdictionButtons(this.thisConfig);
    this.viewConfig = this.thisConfig.viewConfig;
    if (this.viewConfig) {
      // 取得树配置
      if (this.viewConfig.rootName) {
        // 设置了根节点名称
        this.treeData.push({
          title: this.viewConfig.rootName,
          key: "ROOT",
          children: []
        });
      }
      this.pagination.pageSize = 20;
      this.tableStart(); // 初始化表
    } else {
      this.alertError("树形表单编辑视图配置错误");
    }
  },
  mounted() {
    if (this.viewConfig) {
      this.showTreeSpin = true;
      this.showTableSpin1 = true;
      this.showTableSpin2 = true;
      this.tempId = this.viewConfig.templateId;
      this.getTreeData(); // 加载树
    }
  },
  methods: {
    getTreeData() {
      let that = this;
      let mx = this.viewConfig.treeFields;
      let params = {
        action: "GetTreeViewList",
        tb_name: this.viewConfig.treeTable, //主表名
        fieldNames: JSON.stringify(mx), //明细字段名
        bm: this.viewConfig.treeBm,
        t_id: this.viewConfig.treeTemplateId,
        randomKey: this.$store.getters.getRandomKey
      };
      this.get("Form.ashx", params, {
        success: function(req) {
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? req.data:req;
          let mData = reqData.data;
          for (let i = 0; i < mData.length; i++) {
            that.treeData[0].children.push(mData[i]);
          }
          that.showTreeSpin = false;
          that.showTableSpin1 = false;
          that.showTableSpin2 = false;
        },
        error: function(err) {
          console.log(err);
          that.showTreeSpin = false;
          that.showTableSpin1 = false;
          that.showTableSpin2 = false;
          that.alertError("请求失败，获取树数据失败");
        }
      });
    },
    tableStart() {
      this.table1Col = this.viewConfig.tableCols;
      this.table2Col = this.viewConfig.tableCols2;
      this.thComponents = {
        header: {
          cell: tableThResizeable.getResizeableTitleFunction(this.table2Col)
        }
      };
      this.showTableSpin1 = false;
      this.showTableSpin2 = false;
    },
    onTreeSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys;
      if (this.selectedKeys == "") {
        this.selectRowInfo = [];
        return;
      }
      this.selectRowInfo = info.node.dataRef;
      this.treeTitle = info.node.dataRef.title;
      this.tableSelectedTitle = "";
      this.clickTree(info.node.dataRef.key);
    },
    clickTree(id) {
      let searchId = id;
      if (id === "ROOT") {
        let selectedIds = [];
        this.$util.getObjParamValueArrayByParam(
          this.treeData,
          "Id",
          selectedIds
        );
        searchId = selectedIds.toString();
        this.treeTitle = "";
        this.tableSelectedTitle = "";
      }
      let that = this;
      this.showTableSpin1 = true;
      let tableSearch = this.viewConfig.treeLink;

      this.table1Data = [];
      this.table2Data = [];
      let params = {
        action: "GetTableList",
        tb_name: this.viewConfig.tableName, //主表名
        bm: this.viewConfig.tableBm,
        t_id: this.viewConfig.tableTemplateId,
        d_id: searchId,
        search: tableSearch + " in (" + searchId + ")",
        randomKey: this.$store.getters.getRandomKey
      };
      this.post("Form.ashx", params, {
        success: function(req) {
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? req.data:req;
          that.table1Data = reqData.data;
          that.showTableSpin1 = false;
        },
        error: function(err) {
          console.log(err);
          that.showTableSpin1 = false;
          that.alertError("请求失败，获取表格数据失败");
        }
      });
    },
    clickTable(record, index) {
      this.cacheRecord = record;
      this.cacheIndex = index;
      var id = record.YHID;
      this.selectedUserRowKeys = [index];
      this.tableSelectedTitle = record.RYXM ? record.RYXM+"-授权列表" : "？授权列表";
      this.selectedUserRow = record;
      console.log("被选中的用户",this.selectedUserRow);
      let that = this;
      this.showTableSpin2 = true;
      var tableSearch = this.viewConfig.tableLink[
        this.viewConfig.tableLink.length - 1
      ];
      let params = {
        action: "GetTableList",
        tb_name: this.viewConfig.table2Name, //主表名
        bm: this.viewConfig.table2Bm,
        t_id: 0,
        d_id: id,
        search: tableSearch + " = " + id,
        randomKey: this.$store.getters.getRandomKey
      };
      this.post("Form.ashx", params, {
        success: function(req) {
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? req.data:req;
          that.table2Data = reqData.data;
          for (var i = 0; i < that.table2Data.length; i++) {
            if (that.table2Data[i]["_qxnr"]) {
              that.table2Data[i]["_QXNR"] = that.table2Data[i]["_qxnr"];
            }
            if (that.table2Data[i]["_sfyx"]) {
              that.table2Data[i]["_SFYX"] = that.table2Data[i]["_sfyx"];
            }
          }
          that.showTableSpin2 = false;
        },
        error: function(err) {
          console.log(err);
          that.showTableSpin2 = false;
          that.alertError("请求失败，获取表格数据失败");
        }
      });
    },
    table1CustomRow(record, key) {
      return {
        on: {
          click: object => {
            console.log(object);
            this.table2Data = [];
            let oldSelectEd = object.path[2].getElementsByClassName("a-table-selected");
            if(oldSelectEd && oldSelectEd.length>0) {
              oldSelectEd[0].classList.remove("a-table-selected");
            }
            object.path[1].classList.add("a-table-selected");
            this.clickTable(record, object.currentTarget.sectionRowIndex);
          }
        }
      };
    },
    tsbEdit() {
      // var dataRef = this.selectRowInfo;
      var dataRef = this.selectedUserRow;
      if (this.$util.objectIsNull(dataRef)) {
        this.$message.error("请选中至少一条数据.");
        return;
      }
      this.$refs.AuthorityChange.show(dataRef);
    },
    onSelectUserChange(selectedRowKeys, selectedRows) {
      this.selectedUserRowKeys = selectedRowKeys;
      this.selectedUserRow = selectedRows;
      console.log("选项变化", selectedRowKeys);
    },
    changeAuthCancel() {
      this.clickTable(this.cacheRecord, this.cacheIndex);
    },
    /**
     * 响应表格分页、排序、筛选事件
     * @param pagination
     */
    tableChange(pagination) {
      this.updatePageData("pagination", {
        current: pagination.current,
        pageSize: pagination.pageSize
      });
    },
    /**
     * 响应表格分页、排序、筛选事件
     * @param pagination
     */
    tableChange2(pagination) {
      this.updatePageData("pagination2", {
        current: pagination.current,
        pageSize: pagination.pageSize
      });
    },
    /**
     * 打开权限复制对象选择窗口
     * @param button
     */
    tsbCopyPermission(button) {
        if(this.selectedUserRow && this.selectedUserRow["RYXM"]) {
          this.copyPermissionModalTitle = "复制用户权限赋予指定用户";
          this.copyPermissionForm = this.$form.createForm(this, {
            name: this.copyPermissionModalTitle + "_" + new Date().getTime()
          });
          this.copyPermissionModalShow = true;

        }else{
            this.tipWarning("请选择被复制权限的员工用户");
        }
    },
    /**
     * 确定进行用户复制赋权
     * @param event
     */
    onCopyPermission(event) {
      let that = this;
      this.copyPermissionForm.validateFields((err, values) => {
        if (!err) {
          console.log("确定",values);
          let selectedUser = values.beGiverUser;
          let params = {
            action: "CopyUserPermission",
            UserId: this.selectedUserRow["RYID"],
            CopyUserId: selectedUser,
          };
          this.get("System.ashx", params, {
            success: function(res) {
              if (res.success) {
                that.tipSuccess("复制赋权成功");
                event.resolve();
              } else {
                that.tipError("复制赋权失败，"+ res.message);
                event.resolve(1);
              }
            },
            error: function(error) {
              console.error("复制赋权失败:",error.message);
              that.tipError("复制赋权失败，服务异常");
              event.resolve();
            }
          });
        } else {
          event.resolve(1);
        }
      });
    }

  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/authority-view.less";
</style>
