<!-- 查询条件配置转换为控件 :ref="'form_' + key" -->
<!--suppress ALL -->
<template>
  <div style="position:relative;margin-bottom: 5px;" :id="key">
    <a-form
      v-if="isShow"
      :form="form"
      layout="horizontal"
      class="searchCondition-form"
    >
      <a-row :gutter="24" justify="start" class="searchCondition-row">
        <a-col
          v-for="column in thisFormRow"
          class="searchCondition-item"
          :span="24 / oneRowControlsNumber"
          :key="column.fieldName"
        >
          <a-form-item :label="column.title">
            <!-- 文本域  -->
            <e-input
              v-if="column.type === 'EInput'"
              :width="column.width"
              :disabled="searchDisable"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-input>
            <!-- 日期时间选择器 thisMainTableData[column.fieldName]-->
            <e-datetime-picker
              v-else-if="column.type === 'EDatetimePicker'"
              :ref="'EDatetimePicker_' + column.fieldName"
              :width="column.width"
              :disabled="searchDisable"
              :format="column.format"
              :maxTime="maxDate[column.fieldName]"
              :minTime="minDate[column.fieldName]"
              :mode="column.mode"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-datetime-picker>
            <!--:defaultValue="column.defaultValue"-->
            <!-- 部门选择器 -->
            <e-department-select
              v-else-if="column.type === 'EDepartmentSelect'"
              :disabled="searchDisable"
              :width="column.width"
              :mode="column.multiSelect"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-department-select>
            <!-- 用户选择器 -->
            <e-employee-select
              v-else-if="column.type === 'EEmployeeSelect'"
              :disabled="searchDisable"
              :width="column.width"
              :mode="column.multiSelect"
              :keyType="column.keyType"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-employee-select>
            <!-- 供应商商户选择器 -->
            <e-supplier-select
              v-else-if="column.type === 'ESupplierSelect'"
              :disabled="searchDisable"
              :width="column.width"
              :bdmbId="tempId"
              :mode="column.multiSelect"
              :keyType="column.keyType"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-supplier-select>
            <!-- 通用下拉列表 -->
            <e-select
              v-else-if="column.type === 'ESelect'"
              :filter="column.listFilter"
              :options="column.options"
              :disabled="searchDisable"
              :setting="column.setting"
              :mode="column.multiSelect"
              :maxTagCount="1"
              :width="column.width"
              :emptyOption="
                column.emptyOption != undefined ? column.emptyOption : true
              "
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-select>
            <!-- 复选框-->
            <e-checkbox
              v-else-if="column.type === 'ECheckbox'"
              :options="column.options"
              :disabled="searchDisable"
              :filter="column.listFilter"
              :setting="column.setting"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-checkbox>
            <!-- 单选框 -->
            <e-radio
              v-else-if="column.type === 'ERadio'"
              :options="column.options"
              :disabled="searchDisable"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-radio>
            <!-- 数字选择器 -->
            <e-numeric-up-down
              v-else-if="column.type === 'ENumericUpDown'"
              :width="column.width"
              :disabled="searchDisable"
              :max="column.max"
              :min="column.min"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-numeric-up-down>
            <!-- 物资控件 -->
            <e-materials
              v-else-if="column.type === 'EMaterials'"
              :keyType="column.keyType"
              :disabled="searchDisable"
              :bdmbId="tempId"
              :mode="column.multiSelect"
              :setting="column.setting"
              :width="column.width"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-materials>

            <!-- 物资分类选择控件 -->
            <e-materials-classify
              v-else-if="column.type === 'EMaterialsClassify'"
              :keyType="column.keyType"
              :disabled="searchDisable"
              :bdmbId="tempId"
              :mode="column.multiSelect"
              :setting="column.setting"
              :width="column.width"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-materials-classify>

            <!-- 仓库选择器 -->
            <e-inventory-location
              v-else-if="column.type === 'EInventoryLocation'"
              :keyType="column.keyType"
              :disabled="searchDisable"
              :bdmbId="tempId"
              :defaultDataType="column.defaultDataType"
              :defaultDataValue="column.defaultDataValue"
              :mode="column.multiSelect"
              :setting="column.setting"
              :width="column.width"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-inventory-location>
            <!-- 仓库货位选择器 -->
            <e-s-t-warehouse
              v-else-if="column.type === 'ESTWarehouse'"
              :keyType="column.keyType"
              :disabled="searchDisable"
              :bdmbId="tempId"
              :mode="column.multiSelect"
              :setting="column.setting"
              :width="column.width"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-s-t-warehouse>
            <!-- 是否选择器 -->
            <e-boolean-checkbox
              v-else-if="column.type === 'EBooleanCheckbox'"
              :width="column.width"
              :disabled="searchDisable"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-boolean-checkbox>
            <!-- 客户选择器-->
            <e-customer
              v-else-if="column.type === 'ECustomer'"
              :setting="column.setting"
              :width="column.width"
              :mode="column.multiSelect"
              :disabled="searchDisable"
              :keyType="column.keyType"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-customer>
            <!-- 表单模板选择器 -->
            <e-gy-bdmb
              v-else-if="column.type === 'EGyBdmb'"
              :setting="column.setting"
              :width="column.width"
              :disabled="searchDisable"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-gy-bdmb>
            <!-- 岗级定义 -->
            <e-rs-gjdy
              v-else-if="column.type === 'ERsGjdy'"
              :setting="column.setting"
              :width="column.width"
              :disabled="searchDisable"
              v-decorator="[
                column.fieldName,
                {
                  initialValue: thisMainTableData[column.fieldName],
                  rules: column.rules
                }
              ]"
            ></e-rs-gjdy>
            <!-- configs -->
          </a-form-item>
        </a-col>
      </a-row>
      <div
        class="searchCondition-button"
        :class="
          'searchCondition-button__buttonSize-' + $config.defaultControlSize
        "
      >
        <!--:icon="thisIcon"  -->
        <a-button
          :icon="thisIcon"
          @click="onSearch"
          :loading="loading"
          :type="buttonType"
          :size="$config.defaultControlSize"
          >{{ buttonName }}
        </a-button>
        <div class="divider-block-min"></div>
        <a-button
          icon="close"
          @click="onCloseButton"
          :loading="loading"
          :type="buttonType"
          :ghost="buttonGhost"
          :size="$config.defaultControlSize"
          >重置</a-button
        >
        <div class="divider-block-min"></div>
        <a-button
          v-show="haveMoreConditons"
          :icon="visibleMoreCondition ? 'caret-up' : 'caret-down'"
          @click="onShowMoreCondition"
          :type="buttonType"
          :ghost="buttonGhost"
          :size="$config.defaultControlSize"
          >更多
        </a-button>
      </div>
      <!-- 更多检索条件 -->
      <transition name="moreConditons">
        <div
          v-if="haveMoreConditons"
          v-show="visibleMoreCondition"
          class="searchCondition-form-moreCondition"
        >
          <div>
            <a-row
              v-for="row in moreFormRows"
              :gutter="24"
              :key="row.key"
              justify="start"
              class="searchCondition-row"
            >
              <template v-for="column in row.columns">
                <a-col
                  class="searchCondition-item"
                  :key="column.fieldName"
                  :span="24 / oneRowControlsNumber"
                >
                  <a-form-item :label="column.title">
                    <!-- 文本域  -->
                    <e-input
                      v-if="column.type === 'EInput'"
                      :width="column.width"
                      :disabled="searchDisable"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-input>
                    <!-- 日期时间选择器-->
                    <e-datetime-picker
                      v-else-if="column.type === 'EDatetimePicker'"
                      :ref="'EDatetimePicker_' + column.fieldName"
                      :width="column.width"
                      :disabled="searchDisable"
                      :format="column.format"
                      :maxTime="maxDate[column.fieldName]"
                      :minTime="minDate[column.fieldName]"
                      :mode="column.mode"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-datetime-picker>
                    <!--:defaultValue="column.defaultValue"-->
                    <!-- 部门选择器 -->
                    <e-department-select
                      v-else-if="column.type === 'EDepartmentSelect'"
                      :disabled="searchDisable"
                      :width="column.width"
                      :mode="column.multiSelect"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-department-select>
                    <!-- 用户选择器 -->
                    <e-employee-select
                      v-else-if="column.type === 'EEmployeeSelect'"
                      :disabled="searchDisable"
                      :keyType="column.keyType"
                      :width="column.width"
                      :mode="column.multiSelect"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-employee-select>
                    <!-- 供应商商户选择器 -->
                    <e-supplier-select
                      v-else-if="column.type === 'ESupplierSelect'"
                      :disabled="searchDisable"
                      :width="column.width"
                      :bdmbId="tempId"
                      :mode="column.multiSelect"
                      :keyType="column.keyType"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-supplier-select>
                    <!-- 通用下拉列表 -->
                    <e-select
                      v-else-if="column.type === 'ESelect'"
                      :filter="column.listFilter"
                      :options="column.options"
                      :disabled="searchDisable"
                      :setting="column.setting"
                      :mode="column.multiSelect"
                      :maxTagCount="1"
                      :emptyOption="
                        column.emptyOption != undefined
                          ? column.emptyOption
                          : true
                      "
                      :width="column.width"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-select>
                    <!-- 复选框-->
                    <e-checkbox
                      v-else-if="column.type === 'ECheckbox'"
                      :options="column.options"
                      :disabled="searchDisable"
                      :filter="column.listFilter"
                      :setting="column.setting"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-checkbox>
                    <!-- 单选框 -->
                    <e-radio
                      v-else-if="column.type === 'ERadio'"
                      :options="column.options"
                      :disabled="searchDisable"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-radio>
                    <!-- 数字选择器 -->
                    <e-numeric-up-down
                      v-else-if="column.type === 'ENumericUpDown'"
                      :width="column.width"
                      :disabled="searchDisable"
                      :max="column.max"
                      :min="column.min"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-numeric-up-down>
                    <!-- 物资控件 -->
                    <e-materials
                      v-else-if="column.type === 'EMaterials'"
                      :bdmbId="tempId"
                      :disabled="searchDisable"
                      :setting="column.setting"
                      :mode="column.multiSelect"
                      :width="column.width"
                      :keyType="column.keyType"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-materials>
                    <!-- 物资分类选择控件 -->
                    <e-materials-classify
                      v-else-if="column.type === 'EMaterialsClassify'"
                      :keyType="column.keyType"
                      :disabled="searchDisable"
                      :bdmbId="tempId"
                      :mode="column.multiSelect"
                      :setting="column.setting"
                      :width="column.width"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-materials-classify>
                    <!-- 仓库选择器 -->
                    <e-inventory-location
                      v-else-if="column.type === 'EInventoryLocation'"
                      :keyType="column.keyType"
                      :disabled="searchDisable"
                      :bdmbId="tempId"
                      :defaultDataType="column.defaultDataType"
                      :defaultDataValue="column.defaultDataValue"
                      :mode="column.multiSelect"
                      :setting="column.setting"
                      :width="column.width"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-inventory-location>
                    <!-- 仓库货位选择器 -->
                    <e-s-t-warehouse
                      v-else-if="column.type === 'ESTWarehouse'"
                      :keyType="column.keyType"
                      :disabled="searchDisable"
                      :bdmbId="tempId"
                      :mode="column.multiSelect"
                      :setting="column.setting"
                      :width="column.width"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-s-t-warehouse>
                    <!-- 是否选择器 -->
                    <e-boolean-checkbox
                      v-else-if="column.type === 'EBooleanCheckbox'"
                      :width="column.width"
                      :disabled="searchDisable"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-boolean-checkbox>
                    <!-- 客户选择器-->
                    <e-customer
                      v-else-if="column.type === 'ECustomer'"
                      :setting="column.setting"
                      :disabled="searchDisable"
                      :mode="column.multiSelect"
                      :keyType="column.keyType"
                      :width="column.width"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-customer>
                    <!-- 表单模板选择器 -->
                    <e-gy-bdmb
                      v-else-if="column.type === 'EGyBdmb'"
                      :setting="column.setting"
                      :width="column.width"
                      :disabled="searchDisable"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-gy-bdmb>
                    <!-- 岗级定义 -->
                    <e-rs-gjdy
                      v-else-if="column.type === 'ERsGjdy'"
                      :setting="column.setting"
                      :width="column.width"
                      :disabled="searchDisable"
                      v-decorator="[
                        column.fieldName,
                        {
                          initialValue: thisMainTableData[column.fieldName],
                          rules: column.rules
                        }
                      ]"
                    ></e-rs-gjdy>
                    <!-- configs -->
                  </a-form-item>
                </a-col>
              </template>
            </a-row>
          </div>
          <!--<div style="display: flex;align-items: center;justify-content: flex-end;">
                        <a-button type="primary" @click="onOk" :loading="loading" :size="$config.defaultControlSize">确定
                        </a-button>
                    </div>-->
        </div>
      </transition>
    </a-form>
    <a-spin
      :spinning="showSpin"
      tip="筛选条件加载中..."
      class="searchCondition-spin"
    ></a-spin>
    <div v-show="showError"><p>筛选条件加载失败</p></div>
  </div>
</template>

<script>
//import ACol from "ant-design-vue/es/grid/Col";
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { analyticalTemplate } from "@/mixins/analyticalTemplate.js"; // 解析xml模板的方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法
import { e1DateTimeSelectRangeControls } from "@/mixins/e1DateTimeSelectRangeControls.js"; // 时间控件选择范围控制支持方法
export default {
  name: "SearchCondition",
  mixins: [
    pageControls,
    analyticalTemplate,
    requestControls,
    e1DateTimeSelectRangeControls
  ],
  props: {
    conditions: {
      // 定义查询条件表单项
      type: Array,
      default: function() {
        return [];
      }
    },
    tempId: {
      // 当前的模板id
      type: [Number, String],
      default: function() {
        return null;
      }
    },
    buttonName: {
      type: String,
      default: function() {
        return "搜索";
      },
      validator: function(value) {
        // 这个值必须匹配下列字符串中的一个
        return ["搜索", "执行"].indexOf(value) !== -1;
      }
    },
    conditionData: {
      // 表单数据
      type: Object,
      default: function() {
        return {};
      }
    },
    changeValueOutoSearch: {
      // 修改条件后自动触发查询事件
      type: Boolean,
      default: false
    },
    initOneSearch: {
      // 初始化表单后即触发查询事件
      type: Boolean,
      default: true
    },
    resetOrClear: {
      // 重置按钮触发事件是重置查询条件，还是清空查询条件，默认是清空查询条件，true表示是重置查询条件
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 表单
      form: null,
      oldMainTableObject: {},
      thisConditions: [],
      thisFormRow: [], // 第一行搜索条件
      moreFormRows: [],
      colSpan: 4,
      isShow: false,
      showSpin: false,
      showError: false,

      loading: false,
      searchDisable: false, // 是否允许修改条件，触发查询
      searchTempId: null, // 更多检索条件
      key:
        "searchCondition_" +
        new Date().getTime() +
        "_" +
        this.$util.getRandomString(3),
      visibleMoreCondition: false,
      oneRowControlsNumber: 3,
      haveInitValueControlsNumber: 0, // 首次初始化表单时，表单控件设置了有效值的控件数据量
      haveInitValueControls: [], // 首次初始化表单时，表单控件设置了有效值的控件
      oneChange: [],
      oneChangeSearch: false
    };
  },
  watch: {
    conditionData: function(newTable) {
      console.log("表数据变了", newTable);
    },
    visibleMoreCondition: function(v) {
      // 监听更多条件伸缩框，如果显示出来，则需要给本控件增加检查是否点击了控件外事件，来判断是否需要隐藏更多条件
      this.setElementOnBlurListener(v, this.key, "CheckAndcancel");
    },
    isShow: function(v) {
      // 如果首次初始化表单，不会触发表单onChange，则在表单显示的时候，直接触发查询
      if (v && this.haveInitValueControls.length == 0 && this.oneChangeSearch) {
        let allValue = Object.assign({}, this.thisMainTableData);
        this.emitOnSearch(allValue);
        this.oneChangeSearch = false;
      }
    }
  },
  computed: {
    thisIcon: function() {
      if (this.buttonName === "执行") {
        return "calculator";
      }
      return "search";
    },
    thisMainTableData: {
      get: function() {
        return this.oldMainTableObject;
      },
      set: function(v) {
        this.oldMainTableObject = v;
      }
    },
    haveMoreConditons: function() {
      return this.moreFormRows.length > 0;
    },
    buttonType: function() {
      return this.isNewTheme
        ? "primary"
        : this.visibleMoreCondition
        ? ""
        : "primary";
    },
    buttonGhost: function() {
      return this.isNewTheme;
    }
  },
  created() {
    this.form = this.$form.createForm(this, {
      name: "searchCondition_" + this.key,
      onValuesChange: this.onValuesChange
    });
    this.oldMainTableObject = this.conditionData;
    this.oneChangeSearch = this.initOneSearch;
    console.log("加载条件", this.buttonName);
  },
  mounted() {
    this.thisConditions = [].concat(this.conditions);
    if (this.thisConditions.length > 0) {
      this.thisFormRow = [];
      // 是否配置了筛选模板，如果有则需要解析出模板
      let tempIndex = this.thisConditions.findIndex(function(c) {
        return c.type === "temp";
      });
      if (tempIndex >= 0) {
        this.searchTempId = this.thisConditions[tempIndex].tempId;
        this.thisConditions.splice(tempIndex, 1);
      }
      if (this.searchTempId) {
        //如果配置了筛选条件模板，则需要将筛选条件模板一同查询出来
        this.showSpin = true;
        let that = this;
        this.getTemplate(function(controls) {
          if (controls) {
            that.thisConditions = controls.concat(that.thisConditions);
            that.lineFeed();
          }
          that.showSpin = false;
          if (that.thisFormRow.length > 0) that.isShow = true;
          else that.showError = true;
        });
      } else {
        this.showSpin = true;
        let that = this;
        setTimeout(function() {
          that.lineFeed();
          that.showSpin = false;
          that.isShow = true;
        }, 500);
      }
    }
  },
  methods: {
    /**
     * 监听表单控制值变跟事件
     */
    onValuesChange: function(props, values) {
      console.log("触发了表单数据变跟", props, values);
      // 首次表单控件的数据变化不触发查询事件，这是数据初始化导致的
      let names = Object.getOwnPropertyNames(values);
      // 处理监听时间日期选择的数据变化，用于控制选择范围
      this.handleDateChange(names[0], values[names[0]]);
      //第一次不触发后续onChange
      let allValue = props.form.getFieldsValue();
      this.$emit("tableDataChange", allValue);
      let ix = this.haveInitValueControls.indexOf(names[0]);
      if (this.haveInitValueControlsNumber > 1 && ix >= 0) {
        this.haveInitValueControlsNumber = this.haveInitValueControlsNumber - 1;
        return;
      } else if (ix >= 0) {
        this.haveInitValueControlsNumber = this.haveInitValueControlsNumber - 1;
      }
      if (this.oneChangeSearch) {
        //首次初始化后，自动触发
        if (values[names[0]]) {
          allValue = Object.assign(allValue, values);
          this.haveInitValueControlsNumber = 0;
          this.oneChangeSearch = false;
          this.emitOnSearch(allValue);
        }
      } else {
        // 是否表单数据变化、自动触发查询
        if (this.changeValueOutoSearch) {
          this.emitOnSearch(allValue);
        }
      }
    },
    /**
     * 触发onSearch事件
     * @param values
     */
    emitOnSearch: function(values) {
      let that = this;
      let promise = new Promise(resolve => {
        that.loading = true;
        if (that.changeValueOutoSearch) that.searchDisable = true;
        that.$emit("onSearch", {
          resolve: resolve,
          data: values
        });
      }).catch(() => {
        console.error("自动查询异常");
        that.searchDisable = false;
      });
      promise.then(function() {
        that.loading = false;
        that.searchDisable = false;
        that.visibleMoreCondition = false;
      });
    },
    /**
     * 提供给App.vue页面中的全局方法进行检测，如果点击的是当前div之外的元素则隐藏更多选项
     */
    CheckAndcancel: function() {
      this.visibleMoreCondition = false;
    },
    /**
     * 点击查询按钮，触发查询事件
     */
    onSearch: function() {
      let that = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          that.emitOnSearch(values);
        }
      });
    },
    /**
     * 显示更多检索条件 3
     */
    onShowMoreCondition: function() {
      this.visibleMoreCondition = !this.visibleMoreCondition;
    },
    /**
     * 关闭更多检索条件
     */
    onOk: function() {
      this.onSearch();
    },
    /**
     * 将thisConditions数组，进行控件换行
     */
    lineFeed: function() {
      let tempData = {};
      let columns = [];
      if(this.thisConditions) {
        for (let i = 0; i < this.thisConditions.length; i++) {
          //设置默认值
          if (this.thisConditions[i].fieldName ||
            (this.thisConditions[i].configs && this.thisConditions[i].configs.fieldName)) {
            let fieldName =
              this.thisConditions[i].fieldName ||
              this.thisConditions[i].configs.fieldName;
            if (
              !(
                this.thisMainTableData &&
                this.thisMainTableData[fieldName] != undefined
              )
            ) {
              tempData[fieldName] = this.thisConditions[i].value
                ? this.thisConditions[i].value
                : this.thisConditions[i].defaultValue;
              if (tempData[fieldName]) {
                this.haveInitValueControls.push(fieldName);
              }
            } else if (this.thisMainTableData[fieldName]) {
              this.haveInitValueControls.push(fieldName);
            }

            this.thisConditions[i].fieldName = fieldName;
            if (this.thisConditions[i].thisControlType)
              this.thisConditions[i].type = this.thisConditions[i].thisControlType;
            if (this.thisConditions[i].label)
              this.thisConditions[i].title = this.thisConditions[i].label;
            // 给日期选择控件增加范围控制属性的监听字段赋值
            if (this.thisConditions[i].type === "EDatetimePicker") {
              this.initDateTimeMaxAndMin(
                fieldName,
                this.thisMainTableData,
                this.thisConditions[i].maxDate,
                this.thisConditions[i].minDate
              );
            }

            if (i < this.oneRowControlsNumber) {
              this.thisFormRow.push(this.thisConditions[i]);
            } else {
              if (i % this.oneRowControlsNumber != 0) {
                columns.push(this.thisConditions[i]);
              } else {
                if (columns.length > 0) {
                  this.moreFormRows.push({
                    key: Math.random(),
                    columns: columns
                  });
                }
                columns = [this.thisConditions[i]];
              }
            }
          }
        }
      }
      if (columns.length > 0) {
        this.moreFormRows.push({
          key: Math.random(),
          columns: columns
        });
      }
      //console.log("moreFormRows",this.moreFormRows);
      this.thisMainTableData = Object.assign(tempData, this.thisMainTableData);
      this.haveInitValueControlsNumber = this.haveInitValueControls.length;
      console.log(
        "thisMainTableData=->",
        this.thisMainTableData,
        this.haveInitValueControls
      );
    },
    /**
     *  获取指定模板
     */
    getTemplate: function(success) {
      let that = this;
      if (this.searchTempId || this.thisCwpzId) {
        // 根据模板id，从服务器获取模板
        let params = {
          action: "GetFormTemplate",
          t_id: this.searchTempId,
          cwpzid: this.thisCwpzId,
          randomKey: this.$store.getters.getRandomKey
        };
        this.get("Form.ashx", params, {
          success: function(req) {
            let tempXML = req.data.template[0].MBNR;
            that.mainTableName = req.data.template[0].MBZBMC;
            if (tempXML !== "") {
              let res = that.renderSearchConditionTemplate(
                tempXML,
                that.oldMainTableObject
              ); // 解析表单模板
              console.log("formRows", res.formRows, that.oldMainTableObject);
              if (success) success(res.formRows);
            } else {
              that.alertError("请求失败，获取筛选条件模板失败");
              if (success) success(null);
            }
          },
          error: function(err) {
            console.error(err);
            if (success) success(null);
            that.alertError("请求失败，获取筛选条件模板失败");
          }
        });
      }
    },
    /**
     * 清空检索条件
     */
    clearSearchConditon: function() {
      this.form.resetFields();
      this.haveInitValueControls.forEach(fname => {
        let valObj = {};
        valObj[fname] = null;
        this.form.setFieldsValue(valObj);
      });
    },
    /**
     * 重置检索条件
     */
    resetSearchConditon: function() {
      this.form.resetFields();
    },
    /**
     * 监听点击重置按钮事件
     */
    onCloseButton: function() {
      if (this.resetOrClear) {
        this.resetSearchConditon();
      } else {
        this.clearSearchConditon();
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "../assets/style/base/components/search-condition.less";
</style>
