export const e1DateTimeSelectRangeControls = {
  data() {
    return {
      // 用于支持datetime控件的时间选择范围控制
      maxDate: {},
      minDate: {},
      changeDateMiXin: {},
      DatePickerRefNamePrefix: "EDatetimePicker_"
    };
  },
  created() {
    // console.log("混入requestControls的通用方法");
  },
  watch: {
    changeDateMiXin: function(v) {
      //console.log("changeDateMiXin响应变化:",v);
      let paramKeys = Object.keys(v);
      for (let key = 0; key < paramKeys.length; key++) {
        let maxFiled = this.maxDate[paramKeys[key]];
        let minFiled = this.minDate[paramKeys[key]];
        let dateRef = this.getThisRef(
          this.DatePickerRefNamePrefix + paramKeys[key]
        );
        if (dateRef) {
          if (paramKeys.includes(maxFiled)) {
            // 如果maxDate中保存的是一个字段名
            // console.log(paramKeys[key]+"修改endValue=",v[maxFiled]);
            dateRef.setEndValue(v[maxFiled]);
          }
          if (paramKeys.includes(minFiled)) {
            // 如果minDate中保存的是一个字段名
            //console.log(paramKeys[key]+"修改startValue=",v[minFiled]);
            dateRef.setStartValue(v[minFiled]);
          }
        }
      }
    }
  },
  methods: {
    /**
     * 从tableData中将fieldName的值初始化到控制参数中
     * @param fieldName
     * @param tableData
     * @param max
     * @param min
     */
    initDateTimeMaxAndMin(fieldName, tableData, max, min) {
      this.changeDateMiXin[fieldName] = tableData[fieldName]
        ? tableData[fieldName]
        : "";
      this.maxDate[fieldName] = max;
      this.minDate[fieldName] = min;
    },
    /**
     * 在onchange事件中处理对应的时间选择控件保存值
     * @param fieldName
     * @param value
     */
    handleDateChange(fieldName, value) {
      if (
        this.changeDateMiXin[fieldName] != undefined &&
        value &&
        value !== this.changeDateMiXin[fieldName]
      ) {
        //console.log("将changeDateMiXin."+fieldName+"设置为"+value);
        this.updatePageData("changeDateMiXin", fieldName, value);
      } else if (
        this.changeDateMiXin[fieldName] != undefined &&
        value == null &&
        this.changeDateMiXin[fieldName] !== ""
      ) {
        //console.log("将changeDateMiXin."+fieldName+"设置为null");
        this.updatePageData("changeDateMiXin", fieldName, "");
      }
    }
  }
};
