import _lodash from "lodash";
// 明细表键盘操作相关
export const Keydown = {
  data() {
    return {
      focusDataIndex: null, // 编辑行焦点字段
      editDataIndexSort: [], // 编辑行排序
      editIndex: null,
      ctrl: false,
      key: null
    };
  },
  inject: [], // 引用主页定义的页面操作方法
  computed: {},
  created() { },
  methods: {
    keyDownCtrl(e) {
      this.ctrl = true;
      e.preventDefault();
    },
    keyUpCtrl(e) {
      this.ctrl = false;
      e.preventDefault();
    },
    setFocusDataIndex(field, index, e) {
      if (e) {
        e.stopPropagation();
      }
      var flag = true;
      if (field == this.focusDataIndex || index == this.editIndex) {
        flag = false;
      }
      this.editIndex = index;
      this.focusDataIndex = field;
      if (flag) {
        this.editFoucs(field, e);
      }
    },
    addLocation(e) {
      var index = this.jzDetailDataList[this.jzDetailDataList.length - 1][this.rowKey];
      this.editIndex = index;
      this.focusDataIndex = "ZY";
      this.editFoucs("ZY", e);
    },
    // 右
    keyDownRight(e, enter) {
      if (e && e.notEnter == true) {
        return;
      }
      if (this.editIndex == null) {
        this.editIndex = this.jzDetailDataList[0][this.rowKey];
      }
      if (e) {
        e.stopPropagation();
      }
      if (!this.focusDataIndex) {
        this.focusDataIndex = this.editDataIndexSort[0];
        this.editFoucs(this.editDataIndexSort[0], e, "right");
        return true;
      } else {
        this.editBlur(this.focusDataIndex, e);
        var index = this.editDataIndexSort.findIndex(
          item => this.focusDataIndex == item
        );
        if (index != -1) {
          if (this.editDataIndexSort[index + 1]) {
            this.focusDataIndex = this.editDataIndexSort[index + 1];
            this.editFoucs(this.editDataIndexSort[index + 1], e, "right");
            // this.$refs[this.domId].scrollToColumn(this.focusDataIndex);
          } else {
            if (enter) {
              return false;
            } else {
              this.focusDataIndex = this.editDataIndexSort[0];
              this.editFoucs(this.editDataIndexSort[0], e, "right");
              // this.$refs[this.domId].scrollToColumn(this.focusDataIndex);
              return false;
            }
          }
          // this.setEditRowStyle();
          return true;
        }
      }
      return false;
    },
    // 左
    keyDownLeft(e) {
      if (e && e.notEnter == true) {
        return;
      }
      if (this.editIndex == null) {
        this.editIndex = this.jzDetailDataList[0][this.rowKey];
      }
      if (e) {
        e.stopPropagation();
      }
      if (!this.focusDataIndex) {
        this.focusDataIndex = this.editDataIndexSort[0];
        this.editFoucs(this.editDataIndexSort[0], e, "left");
      } else {
        this.editBlur(this.focusDataIndex, e);
        var index = this.editDataIndexSort.findIndex(
          item => this.focusDataIndex == item
        );
        if (index != -1) {
          if (this.editDataIndexSort[index - 1]) {
            this.focusDataIndex = this.editDataIndexSort[index - 1];
            this.editFoucs(this.editDataIndexSort[index - 1], e, "left");
            // this.$refs[this.domId].scrollToColumn(this.focusDataIndex);
          } else {
            this.focusDataIndex = this.editDataIndexSort[
              this.editDataIndexSort.length - 1
            ];
            this.editFoucs(
              this.editDataIndexSort[this.editDataIndexSort.length - 1],
              e,
              "left"
            );
            // this.$refs[this.domId].scrollToColumn(this.focusDataIndex);
          }
          // this.setEditRowStyle();
        }
      }
    },
    // 获得焦点
    editFoucs(field, e) {
      console.log("editfoucs")
      if (field) {
        var dom = this.$refs[this.domId + this.focusDataIndex + this.editIndex];
        if (dom && dom[0]) {
          try {
            dom[0].tableFocus(e);
          } catch {
            try {
              dom[0].focus();
            } catch { }
          }
        }
        // var foucsDom = this.$refs[this.domId + "div"].querySelectorAll(
        //   ".edit-cell-" + field
        // )[0];
        // var editDom = this.$refs[this.domId + "div"].querySelectorAll(
        //   ".editRowTable"
        // )[0];
        // if (foucsDom && editDom) {
        //   // if (foucsDom.offsetLeft > editDom)
        //   this.$refs[this.domId].scrollTo(
        //     foucsDom.offsetLeft
        //     // editDom.offsetTop
        //   );
        // }
      }
    },
    enter(e) {
      if (e.notEnter && e.notEnter == true) {
        if (e.notKmLast) {
          this.$message.error("该科目不是末级,不能选择")
        }
        e.preventDefault();
      } else {
        // 获得当前行
        var allData = _lodash.cloneDeep(this.jzDetailDataList);

        // 最后一个数
        var lastFieldIndex = false;
        // 最后一行
        var lastRow = false;

        var nowRow = allData.findIndex(
          item => item[this.rowKey].toString() == this.editIndex.toString()
        );
        if ((this.focusDataIndex == "ZY" || this.focusDataIndex == null) && allData[nowRow]["ZY"] == "") {
          this.$message.warning("摘要不能为空");
          return;
        }
        if (this.focusDataIndex == "KMBM" && (allData[nowRow]["KMBM"] == "" || allData[nowRow]["KMBM"] == null)) {
          this.$message.warning("会计科目不能为空");
          return;
        }
        if (this.focusDataIndex == "DFJE") {
          if (allData[nowRow]["DFJE"] == "" && allData[nowRow]["JFJE"] == "") {
            this.$message.warning("贷方金额或者借方金额不能为空");
            return;
          }
        }

        var nowData = allData.find(
          item => item[this.rowKey].toString() == this.editIndex.toString()
        );

        if (nowData["JFJE"] != "" && nowData["JFJE"] != null) {
          if (this.focusDataIndex == "JFJE") {
            e["haveJFJE"] = true;
          }
        }

        if (nowRow != -1 && nowRow == allData.length - 1) {
          lastRow = true;
        }

        if (this.editDataIndexSort.indexOf(this.focusDataIndex) == this.editDataIndexSort.length - 1) {
          lastFieldIndex = true;
        }

        // 借方金额有值，跳过贷方金额
        if (e["haveJFJE"]) {
          if (lastRow) {
            this.handleAdd(nowData);
            return;
          } else {
            this.keyEdit(allData[nowRow + 1][this.rowKey], "ZY", e);
            return;
          }
        }

        // 如果是最后一行，增加
        if (lastRow && lastFieldIndex) {
          this.handleAdd(nowData);
          return;
        }

        e.preventDefault();
        var flag = this.keyDownRight(e);
        if (flag == false) {
          flag = this.nextRow(e);
          // if (flag)
        }
      }
    },
    constraintEnter(e) {
      var flag = this.keyDownRight(e);
      if (flag == false) {
        flag = this.nextRow(e);
        // if (flag)
      }
    },
    // 取消焦点
    editBlur(field, e) {
      if (field) {
        var dom = this.$refs[this.domId + field + this.editIndex];
        if (dom && dom[0]) {
          try {
            dom[0].tableBlur(e);
          } catch {
            try {
              dom[0].blur();
            } catch { }
          }
        }
      }
    },
    keyDownBottom() {
      this.nextRow();
    },
    keyDownTop() {
      this.lastRow();
    },
    // 下一行
    nextRow(e, enter) {
      if (e.notEnter == true) {
        return;
      }
      if (this.editIndex == null) {
        this.editIndex = this.jzDetailDataList[0][this.rowKey];
      }
      if (this.focusDataIndex == null) {
        this.focusDataIndex = this.editDataIndexSort[0];
      }
      if (e) {
        try {
          e.preventDefault();
          e.stopPropagation();
        } catch (err) {
          err;
        }
      }
      if (this.editIndex) {
        const newData = _lodash.cloneDeep(this.jzDetailDataList);
        var index = newData.findIndex(
          item => item[this.rowKey].toString() == this.editIndex.toString()
        );
        if (index != -1) {
          if (newData[index + 1]) {
            this.editBlur(this.focusDataIndex, e);
            // this.editFoucs(this.focusDataIndex);
            this.keyEdit(newData[index + 1][this.rowKey], this.focusDataIndex, e);
            // this.$refs[this.domId].scrollToRow(newData[index + 1][this.rowKey]);
            return true;
          } else {
            this.keyEdit(newData[index][this.rowKey], this.focusDataIndex, e);
            return false;
          }
        }
      }
    },
    // 上一行
    lastRow(e) {
      if (e.notEnter == true) {
        return;
      }
      if (this.editIndex == null) {
        this.editIndex = this.jzDetailDataList[0][this.rowKey];
      }
      if (this.focusDataIndex == null) {
        this.focusDataIndex = this.editDataIndexSort[0];
      }
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (this.editIndex) {
        const newData = _lodash.cloneDeep(this.jzDetailDataList);
        var index = newData.findIndex(
          item => item[this.rowKey].toString() == this.editIndex.toString()
        );
        if (index != -1) {
          if (newData[index - 1]) {
            this.editBlur(this.focusDataIndex, e);
            // this.editFoucs(this.focusDataIndex);
            this.keyEdit(newData[index - 1][this.rowKey], this.focusDataIndex, e);
            // this.$refs[this.domId].scrollToRow(newData[index - 1][this.rowKey]);
          }
        }
      }
    },
    keydownDel(e) {
      e.preventDefault();
      var delIndex = this.jzDetailDataList.findIndex((item) => { return item[this.rowKey] == this.editIndex });
      if (this.jzDetailDataList.length <= 1) {
        this.$message.warning("不能删除最后一条明细");
        return;
      }
      if (delIndex != -1) {
        this.jzDetailDataList.splice(delIndex, 1);
        this.$nextTick(() => {
          this.keyEdit(this.jzDetailDataList[delIndex - 1][this.rowKey], this.focusDataIndex, e);
        })
      }
    },
    outAppKeyDownFunction(e, key) {
      if (e.code == "F6") {
        this.keydownF6SaveForm(e);
      } else if (e.code == "F5") {
        this.keydownF5AddForm(e);
      }
    },
    keydownF5AddForm(e) {
      e.preventDefault();
      let saveButton = this.buttons.find((b) => {
        return b.tsbName === "tsbOpenNew";
      });
      if (saveButton && saveButton.isShow) {
        this.tsbOpenNew(saveButton, true);
      } else {
        this.$message.warning("您没有新建权限");
      }
    },
    keydownF6SaveForm(e) {
      e.preventDefault();
      let saveButton = this.buttons.find((b) => {
        return b.name === "保存";
      });
      if (saveButton) {
        this.buttonExtend(saveButton);
      } else {
        this.$message.warning("您没有保存权限");
      }
    },
    keyEdit(index, field, e) {
      this.editIndex = index;
      this.focusDataIndex = field;
      this.editFoucs(field, e);
      // var flag = true;
      // console.log(field, this.focusDataIndex)
      // if (this.focusDataIndex == field) {
      //   flag = false;
      // }
      // this.editIndex = index;
      // this.focusDataIndex = field;
      // if (flag) {
      //   this.editFoucs(field);
      // }
    },
    //
    fzhsSelectOk(e) {
      e.preventDefault();
      e.notEnter = true;
      this.$nextTick(() => {
        this.keyEdit(this.editIndex, "JFJE", e);
      })

    },
    // 设置排序
    setEditDataIndexSort(col) {
      var editDataIndexSort = col.filter(item => item.keyMove);
      var arr = [];
      for (var i = 0; i < editDataIndexSort.length; i++) {
        arr.push(editDataIndexSort[i]["dataIndex"]);
      }
      this.editDataIndexSort = arr;
    },
    // 按下键盘触发
    keydown(e) {
      console.log(e);
      if (e.$event) {
        e.$event.stopPropagation();
        if (e.$event.code == "Numpad4" && e.$event.key == "ArrowLeft" && this.ctrl) {
          console.log("left");
          this.keyDownLeft(e);
          event.preventDefault();
        }
        if (e.$event.code == "Numpad6" && e.$event.key == "ArrowRight" && this.ctrl) {
          console.log("right");
          this.keyDownRight(e);
          event.preventDefault();
        }
        if (e.$event.code == "Numpad8" && e.$event.key == "ArrowUp" && this.ctrl) {
          console.log("top");
          this.keyDownTop(e);
          event.preventDefault();
        }
        if (e.$event.code == "Numpad2" && e.$event.key == "ArrowDown" && this.ctrl) {
          console.log("bottom");
          this.keyDownBottom(e);
          event.preventDefault();
        }
      } else {
        e.stopPropagation();
        if (e.code == "Numpad4" && e.key == "ArrowLeft" && this.ctrl) {
          console.log("left");
          this.keyDownLeft(e);
          event.preventDefault();
        }
        if (e.code == "Numpad6" && e.key == "ArrowRight" && this.ctrl) {
          console.log("right");
          this.keyDownRight(e);
          event.preventDefault();
        }
        if (e.code == "Numpad8" && e.key == "ArrowUp" && this.ctrl) {
          console.log("top");
          this.keyDownTop(e);
          event.preventDefault();
        }
        if (e.code == "Numpad2" && e.key == "ArrowDown" && this.ctrl) {
          console.log("bottom");
          this.keyDownBottom(e);
          event.preventDefault();
        }
      }
    }
  }
};
