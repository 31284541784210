/**
 * 给表单内的计算公式，提供一些数学算法
 * @type {{data(): *, created(), methods: {}}}
 */
export const mathAlgorithms = {
  data() {
    return {};
  },
  created() {
    // console.log("混入mathAlgorithms的通用方法");
  },
  methods: {
    /**
     * 根据公式，使用对应的字段值替换公式对应参数，并进行计算，！！！目前只支持简单SUM公式计算
     * @param formulaObj
     * @param thisForm 主表单对象
     * @param mxDataObj Array或Object,分别表示所有明细数据，和明细表mxTableName的一条数据
     * @param value 更改的数据,注：change的更改优先级比v-decorator高，会导致change了 form内容还没更新，导致计算延误
     * @returns {number}
     */
    doCalculation1(formulaObj, thisForm, mxDataObj) {
      if (!formulaObj) {
        return null;
      }
      let rowFormula = formulaObj.rowFormula; // true表示明细表内字段计算
      let formula = formulaObj.formula; // 计算公式
      let variableField = formulaObj.variableField; //计算变量字段
      let mxTableName = formulaObj.mxTableName; // 使用对应的明细表名称
      let exp = null;
      let dataTempObj = undefined;
      //debugger
      if (
        formula &&
        this.$util.isType(formula) === "String" &&
        variableField &&
        variableField.length > 0
      ) {
        formula = this.$util.trimStr(formula); // 去除前后端空格
        let sumReg = new RegExp("(SUM\\()(.*?)(\\))", "ig");
        let roundAReg = new RegExp("(ROUNDA2\\()(.*?)(\\))", "ig"); // 保留两位小数，财务式舍入
        let roundReg = new RegExp("(ROUND2\\()(.*?)(\\))", "ig"); // 保留两位小数，数据四舍五入
        let ceilReg = new RegExp("(CEIL\\()(.*?)(\\))", "ig"); //Math.ceil( // 向上舍入取整
        let ceil0Reg = new RegExp("(CEIL0\\()(.*?)(\\))", "ig"); //Math.ceil( // 向上舍入取整，如果小于0则取0
        let condReg = new RegExp("(COND\\()(.*?)(\\))", "ig"); // 条件判断，计算公式，例如：COND('_FX'=='清帐',_FSJE*_BCQZSL/_FSSL,0)
        let absReg = new RegExp("(ABSCEIL\\()(.*?)(\\))", "ig"); //取向上舍入取整的绝对值
        let isround = roundReg.test(formula);
        let isroundA = roundAReg.test(formula);
        let isCeil = ceilReg.test(formula);
        let isCeil0 = ceil0Reg.test(formula);
        let isAbs = absReg.test(formula);
        let value = 0;
        // ---------------------------------------------------------------------------------------->
        if (sumReg.test(formula)) {
          // 是SUM函数
          exp = formula.slice(4).slice(0, -1); // 获取SUM函数内的表达式
          if (exp.includes(",", 1)) {
            // 表示SUM应该是计算多个给定字段值的合计如：SUM(a,b,c) = a+b+c，只有单表内部字段间的合计可用(明细表内部计算或主表单内部字段计算)
            return NaN; // 暂不支持
          } else if (
            exp != variableField[0] &&
            this.$util.mathUtil.isMathExpression(exp)
          ) {
            // 内部是一个算数表达式,z这种情况下，变量字段中必须有一个明细表字段
            return NaN; // 暂不支持
          } else {
            // 单明细字段合计
            if (
              !rowFormula &&
              mxTableName &&
              variableField.length == 1 &&
              mxDataObj &&
              this.$util.isType(mxDataObj) === "Array" &&
              mxDataObj.length > 0
            ) {
              let sumValues = [];
              let field = variableField[0];
              let fieldName = field;
              let mxObj = mxDataObj.find(function(obj) {
                // 找到对应的明细表数据对象
                return obj.tableName === mxTableName;
              });
              if (mxObj) {
                if (field.includes(".", 1)) {
                  let tempArray = field.split(".");
                  fieldName = tempArray[1];
                }
                let tableDatas = mxObj.data;
                if (tableDatas.length > 0) {
                  // 是否有明细数据
                  for (let z = 0; z < tableDatas.length; z++) {
                    let hData = tableDatas[z];
                    let fValue = this.getEffectiveNumberValue(hData[fieldName]);
                    sumValues.push(fValue); // 将每一行计算公式计算出的结果放入数组
                  }
                  return this.$util.mathUtil.sum(sumValues); // 将数组数据进行合计
                } else {
                  return 0;
                }
              }
            }
            return NaN;
          }
          // ---------------------------------------------------------------------------------------->
        } else if (isround || isroundA) {
          // 精确到两位小数的四舍五入函数
          if (isroundA) {
            exp = formula.slice(8).slice(0, -1); // 获取ROUNDA2函数内的表达式
            // console.log("计算公式1", exp);
          } else {
            exp = formula.slice(7).slice(0, -1); // 获取ROUND2函数内的表达式
            // console.log("计算公式2", exp);
          }

          if (
            exp != variableField[0] &&
            this.$util.mathUtil.isMathExpression(exp)
          ) {
            // 非函数式表达式
            if (
              rowFormula &&
              mxDataObj &&
              this.$util.isType(mxDataObj) === "Object"
            ) {
              // 明细表内部计算
              for (let i = 0; i < variableField.length; i++) {
                exp = exp.replace(
                  variableField[i],
                  this.getEffectiveNumberValue(mxDataObj[variableField[i]])
                );
              }
              // console.log(formula+"明细表内部数据计算公式：",exp);
            } else if (!rowFormula) {
              dataTempObj = thisForm.getFieldsValue(variableField); // 从主表单中获取数据
              for (let j = 0; j < variableField.length; j++) {
                exp = exp.replace(
                  variableField[j],
                  this.getEffectiveNumberValue(dataTempObj[variableField[j]])
                );
              }
              //console.log(formula + "主表单字段数据计算公式：", exp);
            } else {
              return NaN;
            }
            console.log("计算公式=",exp);
            value = this.$util.mathUtil.binaryOperations(exp);
          } else if (variableField.length == 1 && exp === variableField[0]) {
            // 对字段值直接做ROUND2
            if (
              rowFormula &&
              mxDataObj &&
              this.$util.isType(mxDataObj) === "Object"
            ) {
              // 明细表内部计算
              value = this.getEffectiveNumberValue(mxDataObj[variableField[0]]);
            } else {
              // 主表单内部字段计算
              dataTempObj = thisForm.getFieldsValue(variableField); // 从主表单中获取数据
              value = this.getEffectiveNumberValue(
                dataTempObj[variableField[0]]
              );
            }
          }
          if (isroundA) return this.$util.mathUtil.accountingRound2(value);
          else return this.$util.mathUtil.round2(value);
          // ---------------------------------------------------------------------------------------->
        } else if (isAbs) {
          // 函数内部值先向上舍入取整然后取绝对值
          exp = formula.slice(8).slice(0, -1); // 获取ABS函数内的表达式
          if (
            exp != variableField[0] &&
            this.$util.mathUtil.isMathExpression(exp)
          ) {
            // 非函数式表达式
            if (
              rowFormula &&
              mxDataObj &&
              this.$util.isType(mxDataObj) === "Object"
            ) {
              // 明细表内部计算
              for (let i = 0; i < variableField.length; i++) {
                exp = exp.replace(
                  variableField[i],
                  this.getEffectiveNumberValue(mxDataObj[variableField[i]])
                );
              }
              // console.log(formula+"明细表内部数据计算公式：",exp);
            } else if (!rowFormula) {
              dataTempObj = thisForm.getFieldsValue(variableField); // 从主表单中获取数据
              for (let j = 0; j < variableField.length; j++) {
                exp = exp.replace(
                  variableField[j],
                  this.getEffectiveNumberValue(dataTempObj[variableField[j]])
                );
              }
              //console.log(formula + "主表单字段数据计算公式：", exp);
            } else {
              return NaN;
            }
            value = this.$util.mathUtil.binaryOperations(exp);
          } else if (variableField.length == 1 && exp === variableField[0]) {
            // 对字段值直接做ROUND2
            if (
              rowFormula &&
              mxDataObj &&
              this.$util.isType(mxDataObj) === "Object"
            ) {
              // 明细表内部计算
              value = this.getEffectiveNumberValue(mxDataObj[variableField[0]]);
            } else {
              // 主表单内部字段计算
              dataTempObj = thisForm.getFieldsValue(variableField); // 从主表单中获取数据
              value = this.getEffectiveNumberValue(
                dataTempObj[variableField[0]]
              );
            }
          }
          let ceilValue = Math.abs(Math.ceil(value));
          return ceilValue<0 ? 0:ceilValue;
          // ---------------------------------------------------------------------------------------->
        } else if (isCeil || isCeil0) {
          if(isCeil)
            // 向上舍入取整
            exp = formula.slice(5).slice(0, -1); // 获取ceil函数内的表达式
          else
            exp = formula.slice(6).slice(0, -1); // 获取ceil函数内的表达式
          if (
            exp != variableField[0] &&
            this.$util.mathUtil.isMathExpression(exp)
          ) {
            // 非函数式表达式
            if (
              rowFormula &&
              mxDataObj &&
              this.$util.isType(mxDataObj) === "Object"
            ) {
              // 明细表内部计算
              for (let i = 0; i < variableField.length; i++) {
                exp = exp.replace(
                  variableField[i],
                  this.getEffectiveNumberValue(mxDataObj[variableField[i]])
                );
              }
              // console.log(formula+"明细表内部数据计算公式：",exp);
            } else if (!rowFormula) {
              dataTempObj = thisForm.getFieldsValue(variableField); // 从主表单中获取数据
              for (let j = 0; j < variableField.length; j++) {
                exp = exp.replace(
                  variableField[j],
                  this.getEffectiveNumberValue(dataTempObj[variableField[j]])
                );
              }
              //console.log(formula + "主表单字段数据计算公式：", exp);
            } else {
              return NaN;
            }
            value = this.$util.mathUtil.binaryOperations(exp);
          } else if (variableField.length == 1 && exp === variableField[0]) {
            // 对字段值直接做ROUND2
            if (
              rowFormula &&
              mxDataObj &&
              this.$util.isType(mxDataObj) === "Object"
            ) {
              // 明细表内部计算
              value = this.getEffectiveNumberValue(mxDataObj[variableField[0]]);
            } else {
              // 主表单内部字段计算
              dataTempObj = thisForm.getFieldsValue(variableField); // 从主表单中获取数据
              value = this.getEffectiveNumberValue(
                dataTempObj[variableField[0]]
              );
            }
          }
          let resultValue = Math.ceil(value);
          return isCeil ? resultValue : (resultValue < 0 ? 0 : resultValue);
          // ---------------------------------------------------------------------------------------->
        } else if (condReg.test(formula)) {
          // 条件判断三元计算表达式，例如：COND('_FX'=='清帐',_FSJE*_BCQZSL/_FSSL,0)
          exp = formula.slice(5).slice(0, -1); // 获取ceil函数内的表达式
          if (
            rowFormula &&
            mxDataObj &&
            this.$util.isType(mxDataObj) === "Object"
          ) {
            // 明细表内部计算
            for (let i = 0; i < variableField.length; i++) {
              exp = exp.replace(
                variableField[i],
                this.getEffectiveValue(mxDataObj[variableField[i]])
              );
            }
          } else if (!rowFormula) {
            dataTempObj = thisForm.getFieldsValue(variableField); // 从主表单中获取数据
            for (let j = 0; j < variableField.length; j++) {
              exp = exp.replace(
                variableField[j],
                this.getEffectiveValue(dataTempObj[variableField[j]])
              );
            }
            //console.log(formula + "主表单字段数据计算公式：", exp);
          } else {
            return NaN;
          }
          let expArrays = exp.split(",");
          if (expArrays.length == 3) {
            let tempConditions = expArrays[0];
            tempConditions = tempConditions.replace(/or/g, "||");
            tempConditions = tempConditions.replace(/and/g, "&&");
            console.log("比较公式", tempConditions);
            let result = eval(tempConditions);
            if (this.$util.isType(result) === "Boolean") {
              if (result) {
                // 第一个参数条件满足，则去计算第一个参数值用于返回，否则返回第二个参数
                console.log("计算公式=", expArrays[1]);
                return this.$util.mathUtil.binaryOperations(expArrays[1]);
              } else {
                console.log("计算公式=", expArrays[2]);
                return this.$util.mathUtil.binaryOperations(expArrays[2]);
              }
            } else {
              return 0;
            }
          }
        } else if (
          formula != variableField[0] &&
          this.$util.mathUtil.isMathExpression(formula)
        ) {
          // 非函数式计算表达式

          if (rowFormula || (!rowFormula && !mxTableName)) {
            //只有明细表内部计算或主表单内部字段计算支持
            exp = formula;
            if (
              rowFormula &&
              mxDataObj &&
              this.$util.isType(mxDataObj) === "Object"
            ) {
              // 明细表内部计算
              for (let r = 0; r < variableField.length; r++) {
                exp = exp.replace(
                  variableField[r],
                  this.getEffectiveNumberValue(mxDataObj[variableField[r]])
                );
              }
              // console.log(formula+"明细表内部数据计算公式：",exp);
            } else {
              // 主表单内部字段计算
              dataTempObj = thisForm.getFieldsValue(variableField); // 从主表单中获取数据
              for (let y = 0; y < variableField.length; y++) {
                exp = exp.replace(
                  variableField[y],
                  this.getEffectiveNumberValue(dataTempObj[variableField[y]])
                );
              }
              //console.log(formula + "主表单字段数据计算公式：", exp);
            }
            console.log("计算公式=",exp);
            return this.$util.mathUtil.binaryOperations(exp);
          }
          // ---------------------------------------------------------------------------------------->
        } else if (
          formula.length > 0 &&
          variableField.length == 1 &&
          formula === variableField[0]
        ) {
          // 直接就是字段名
          if (
            rowFormula &&
            mxDataObj &&
            this.$util.isType(mxDataObj) === "Object"
          ) {
            // 明细表内部计算
            value = mxDataObj[variableField[0]];
          } else if (!rowFormula) {
            // 主表单内部字段计算
            dataTempObj = thisForm.getFieldsValue(variableField); // 从主表单中获取数据
            value = dataTempObj[variableField[0]]; //this.getEffectiveNumberValue(
          }
          return value;
        }
      }
      return NaN;
    },
    /**
     * 获取有效的数值
     * @param n
     * @returns {number}
     */
    getEffectiveNumberValue(n) {
      if (n) {
        if (this.$util.isType(n) === "val") return n;
        else if (this.$util.isNumberString(n)) {
          return Number(n);
        }
      }
      return 0;
    },
    /**
     * 获取有效的数值
     * @param n
     * @returns {number}
     */
    getEffectiveValue(n) {
      if (n) {
        if (this.$util.isType(n) === "val") return n;
        else if (this.$util.isNumberString(n)) {
          return Number(n);
        } else if (this.$util.isType(n) === "String") {
          return n;
        }
      }
      return 0;
    },
    /**
     * 条件计算公式
     * @param oneArg
     * @param towArg
     * @param threeArg
     */
    conditionCalculation(oneArg, towArg, threeArg) {}
  }
};
