<!-- 表格数据筛选组件 -->
<template>
  <div class="e-table-content-filter">
    <a-input-group compact>
      <a-select
        ref="filterFieldSelect"
        mode="multiple"
        style="min-width:200px;"
        placeholder="点击选择筛选列"
        :options="screenSelectOption"
        v-model="screenSelect"
        :disabled="disabled"
        @focus="onFocus"
        @blur="onFilterBlur"
        :open="filterOpen"
        :size="controlSize"
      >
        <a-icon slot="suffixIcon" type="smile" />
      </a-select>
      <a-input
        placeholder="请输入筛选内容"
        style="width:200px"
        v-model="screenText"
        :disabled="disabled"
        :size="controlSize"
        @keyup.enter="screen"
      />
      <a-button
        :class="
          screenMode
            ? ['editable-add-btn', 'no-radius']
            : ['editable-add-btn', 'screen-radius']
        "
        @click="screen"
        :disabled="disabled"
        :size="controlSize"
        :type="screenButtonType"
        ><a-icon type="search" />{{ btnName }}</a-button
      >
      <a-button
        class="editable-add-btn radius"
        @click="quit"
        v-show="screenMode"
        :disabled="disabled"
        :size="controlSize"
        ><a-icon type="zoom-out" />退出筛选</a-button
      >
    </a-input-group>
  </div>
</template>

<script>
export default {
  name: "ETableContentFilter",
  data() {
    return {
      screenSelect: [], // 筛选的选中项
      screenSelectOption: this.columnsSet(this.columns), // 筛选的下拉框数据
      screenMode: false, // 是否为筛选状态
      screenText: "", // 筛选内容
      screenData: [], // 筛选出的数据
      cacheData: [], // 筛选的数据
      saveData: [], // 缓存数据
      filterOpen: false,
      controlSize: this.$config.defaultControlSize
    };
  },
  props: {
    columns: {
      type: Array,
      default: () => {
        return [];
      }
    },
    dataSource: {
      type: Array,
      default: () => {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 代理模式，仅仅作为一个输入查询条件的控件
    proxy: {
      type: Boolean,
      default: false
    },
    // 是否可以退出筛选，true 为可以 false 为不可以
    quitScreenMode: {
      type: Boolean,
      default: true
    },
    searchBtnName: {
      type: String,
      default: null
    }
  },
  computed: {
    screenButtonType: function() {
      return window.SYS_THEME_NAME && window.SYS_THEME_NAME === "ruifu"
        ? "primary"
        : "";
    },
    btnName: function() {
      if (this.searchBtnName) {
        return this.searchBtnName;
      }
      return this.proxy ? "查询" : "筛选";
    }
  },
  watch: {
    columns: function(value) {
      this.screenSelectOption = this.columnsSet(value);
    },
    dataSource: {
      deep: true,
      immediate: true,
      handler: function(value) {
        this.cacheData = value;
        if (this.saveData == "") {
          this.saveData = value;
        }
      }
    },
    disabled() {}
  },
  mounted() {},
  methods: {
    onFocus() {
      this.filterOpen = true;
    },
    onFilterBlur() {
      this.filterOpen = false;
    },
    /**
     * 下拉选择框失去焦点
     */
    selectBlur() {
      if(this.filterOpen)
        this.$refs.filterFieldSelect.blur();
    },
    /**
     * 筛选方法
     */
    screen() {
      const screenText = this.screenText.trim();
      if (screenText === "") {
        this.$message.warn("请输入筛选内容");
        return;
      }
      if (this.proxy) {
        this.$emit("proxy", this.screenText, this.screenSelect.toString());
        this.screenMode = true;
        return;
      }
      this.screenMode = true;
      const newData = this.$util.objDeepCopy(this.cacheData);
      const screenSelect = [...this.screenSelect];
      let screenData = this.dataListFilter(newData, screenSelect, screenText);
      if (screenData) {
        this.screenData = screenData;
      }
      this.$emit("screen", this.screenData);
    },
    /**
     * 从数据中筛选出包含某个内容的数据,支持对树状表格数据的搜索
     * @param data
     * @param screenSelect
     * @param screenText
     */
    dataListFilter(data, screenSelect, screenText) {
      let screenData = [];
      if (screenSelect.length == 0) {
        // 如果未指定筛选字段
        screenData = data.filter(item => {
          for (let keyField in item) {
            if ((item[keyField] + "").includes(screenText)) return true;
          }
          if (item.children && this.$util.isType(item.children) === "Array") {
            let r = this.dataListFilter(
              item.children,
              screenSelect,
              screenText
            );
            if (r.length > 0) {
              item.children = r;
              return true;
            }
          }
          return false;
        });
      } else {
        // 指定了筛选字段
        screenData = data.filter(item => {
          for (let i = 0; i < screenSelect.length; i++) {
            if ((item[screenSelect[i]] + "").includes(screenText)) return true;
          }
          if (item.children && this.$util.isType(item.children) === "Array") {
            let r1 = this.dataListFilter(
              item.children,
              screenSelect,
              screenText
            );
            if (r1.length > 0) {
              item.children = r1;
              return true;
            }
          }
          return false;
        });
      }
      return screenData;
    },
    /**
     * 退出筛选
     */
    quit() {
      this.screenMode = false;
      this.screenSelect = [];
      this.screenText = "";
      if (this.proxy) {
        this.$emit("proxy", "", "", true);
        return;
      }
      this.$emit("screen", this.saveData);
    },
    /**
     * 获取可筛选的列
     * @param columns
     * @return {Array}
     */
    columnsSet(columns) {
      let screenSelectOption = [];
      columns.forEach(element => {
        if (element.type && element.type == "checkbox") {
        } else {
          screenSelectOption.push({
            label: element.title,
            value: element.dataIndex ? element.dataIndex : element.field,
            type: element.type
          });
        }
      });
      return screenSelectOption;
    },
    /**
     * 数据重新加载
     * @param data
     */
    isNewData(data) {
      if (this.screenMode == true && this.proxy != true) {
        this.$message.warning("数据变动,退出筛选模式");
        this.quit();
        this.cacheData = data;
        this.saveData = data;
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "../assets/style/base/components/e-table-content-filter.less";
</style>
