<!-- 小票打印 -->
<template>
  <a-modal
    title="二维码打印"
    v-model="visible"
    @ok="doPrint"
    @cancel="handleCancel"
    cancelText="关闭"
    :okText="printButtonName"
    destroyOnClose
    :width="getModalWidth"
  >
    <template slot="footer">
      <a-button key="submit" type="primary" @click="doPrint">
        打印
      </a-button>
    </template>
    <div
      :style="{
        width: getBoxWidth,
        maxWidth: getBoxWidth,
        maxHeight: getMaxHeight
      }"
      class="np58-print__box"
    >
      <div
        v-show="showContent"
        id="printContent_np58"
        :style="{ width: getPageWidth }"
        class="np58-print__content"
      >
        <template v-for="(page, index) in thisDataSources">
          <!-- 采购订单物料二维码 -->
          <div
            v-if="page.pritnFormat && page.pritnFormat == 'format_2DCodeWL'"
            :key="index"
            class="np58-print__page"
            :style="{ height: getPageHeight }"
            :id="'printContent_page_' + index"
          >
            <div
              style="display: flex;flex-direction: row-reverse;"
              :id="'canvas_' + index"
            >
              <div
                style="display: flex;flex-direction: column;padding-left: 2px;width: 100%;"
              >
                <div style="padding: 1mm 0;">
                  <span>编码:</span><span>{{ page._WZBM }}</span>
                </div>
                <div style="padding: 1mm 0;">
                  <span>规格:</span><span>{{ page._GGXH }}</span>
                </div>
              </div>
            </div>
            <div style="display: flex;padding: 0 2mm;flex-direction: column;">
              <div>
                <span>名称:</span><span>{{ page._WZMC }}</span>
              </div>
              <div>
                <span>批次:</span><span>{{ page._MainDataPCH }}</span>
              </div>
            </div>
          </div>
          <a-divider
            :key="'dev_' + index"
            type="horizontal"
            style="height: 1mm;margin: 15px 0;"
          />
        </template>
      </div>
      <div v-show="!showContent">
        <a-spin
          :spinning="!showContent"
          tip="小票加载中..."
          size="large"
          class="form-spin"
        >
        </a-spin>
      </div>
    </div>
  </a-modal>
</template>

<script>
import QRCode from "qrcode";
import print from "print-js";
/*const printData = {
    format: 'format_2DCodeWL',
    labels: [
    {
      type: 'qCode',
      value: "www.baidu.com"
    },
    [{
      name: '编码:',
      value: "LJ3488203412-12"
    }, {
      name: '规格:',
      value: "15寸"
    }, {
      name: '名称:',
      value: "钢板"
    }],
    [{
      name: '批次:',
      value: "PCH2134234223"
    }]]
  };*/

export default {
  name: "NP58Print",
  data() {
    return {
      showContent: false,
      qCodeImages: [],
      printButtonName: "",
      dataSourcesTemp: []
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    dataSource: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    getModalWidth: function() {
      return "73mm";
    },
    getBoxWidth: function() {
      return "61mm";
    },
    getPageWidth: function() {
      return "60mm";
    },
    getPageHeight: function() {
      return "40mm";
    },
    getMaxHeight: function() {
      return "600px";
    },
    visible: {
      get: function() {
        //console.log("弹窗显示",this.value );
        return this.value;
      },
      set: function(v) {
        this.$emit("input", v);
      }
    },
    thisDataSources: function() {
      return this.dataSourcesTemp;
    }
  },
  watch: {
    value: function(v) {
      if (v) {
        this.printButtonName = "打印（共" + this.dataSourcesTemp.length + "张)";
        this.initPages();
      }
    },
    dataSource: function(v) {
      if (v) {
        console.log("需要打印的数据", v);
        this.printButtonName = "打印（共" + v.length + "张)";
        this.dataSourcesTemp = v;
      } else {
        this.printButtonName = "打印（共0张)";
        this.dataSourcesTemp = [];
      }
    }
  },
  created() {
    this.dataSourcesTemp = this.dataSource;
  },
  mounted() {},
  methods: {
    /**
     * 生成二维码
     */
    initPages: function() {
      if (this.dataSource && this.dataSource.length > 0) {
        this.showContent = false;
        let that = this;
        this.$nextTick(function() {
          that.showContent = true;
          for (let i = 0; i < this.dataSource.length; i++) {
            let data = this.dataSource[i];
            if (data.printCodeURL) {
              setTimeout(function() {
                that.makeQRCode(data.printCodeURL, i);
              }, 200);
            }
          }
        });
      }
    },
    /**
     * 生成二维码
     */
    makeQRCode: function(text, index) {
      try {
        QRCode.toCanvas(
          text,
          {
            errorCorrectionLevel: "H",
            margin: 0,
            width: 118,
            scale: 2
          },
          function(err, canvas) {
            if (err) throw err;
            let container = document.getElementById("canvas_" + index);
            container.appendChild(canvas);
          }
        );
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * 打印小票
     */
    doPrint: function() {
      print({
        printable: "printContent_np58",
        type: "html",
        targetStyles: ["*"],
        header: null,
        footer: null
      });
    },
    /**
     * 关闭预览窗口
     */
    handleCancel: function() {
      this.visible = false;
    }
  }
};
</script>

<style scoped lang="less">
.np58-print__box {
  overflow-x: hidden;
  overflow-y: auto;

  .np58-print__content {
    margin-right: 1mm;
    margin-left: 0;
    margin-top: 10.2mm;
    margin-bottom: 0;
    size: auto;
    padding: 0;
    border: 0;
    color: #000;
    background-color: white;

    .np58-print__page {
      margin: 0;
      size: auto;
      padding: 1px 1px;
      width: inherit;
      overflow: hidden;
      font-size: 12px;
      font-family: SimSun, serif;
      font-weight: bold;
    }

    .np58-print__page:first-of-type {
      margin: -10mm 0 0;
    }
  }
}
</style>
