import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求
/**
 * 支持和ESelect控件相关操作的方法
 * @type {{mixins: *[], data(): *, methods: {}}}
 */
export const ESelectControl = {
  mixins: [pageControls, requestControls],
  data() {
    return {};
  },
  methods: {
    /**
     * 获取下拉列表数据的接口方法
     * @param listFilter
     * @param defaultValue
     * @param callBack
     */
    getSelectOptions_MiXin: function(listFilter, defaultValue, callBack) {
      let params = {
        action: "GetXLdata",
        SourceTableName: listFilter.sourceTable ? listFilter.sourceTable : "",
        SourceFieldName: listFilter.sourceFieldName
          ? listFilter.sourceFieldName
          : "",
        label: listFilter.label ? listFilter.label : "",
        value: listFilter.value ? listFilter.value : "",
        filter: listFilter.filter,
        listType: listFilter.listType,
        dataSource: listFilter.dataSource,
        defaultValue:
          this.$util.isType(defaultValue) === "Object"
            ? defaultValue.value
            : defaultValue
      };
      let eTableIsSave = false;
      if (listFilter.haveDetailVariable) {
        //listFilter.haveVariable ||
        eTableIsSave = true;
      }
      //console.log("select通过查询条件",listFilter, eTableIsSave);
      this.post("Form.ashx", params, {
        success: function(res) {
          if (callBack) {
            let obj = { haveVariable: eTableIsSave };
            res = Object.assign(res, obj);
            callBack(res);
          }
        },
        error: function(err) {
          console.error("获取下拉列表选项失败", err);
          if (callBack) callBack();
        }
      });
    }
  }
};
