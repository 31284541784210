<template>
  <div v-show="visible" :style="setwidth">
    <a-tooltip :title="departNames" arrow-point-at-center>
      <a-input
        @click="openModal"
        placeholder="请点击选择模板"
        v-model="departNames"
        :size="controlSize"
        readOnly
        :disabled="thisDisabled"
        style="100%"
      >
        <e-icon
          slot="prefix"
          type="plus"
          title="模板选择控件"
          :disabled="thisDisabled"
        />
        <a-icon
          v-if="departIds != '' && !thisDisabled && !readOnly"
          slot="suffix"
          type="close-circle"
          theme="filled"
          @click.stop="handleEmpty"
          title="清空"
          :style="{ color: 'rgba(0, 0, 0, 0.25)' }"
        />
      </a-input>
    </a-tooltip>
    <EGyBdmbTree
      ref="selectModal"
      :mode="mode"
      @ok="handleOK"
      :value="value"
      :defaultValue="defaultValue"
      :setting="thisSetting"
      :filter="filter"
    ></EGyBdmbTree>
  </div>
</template>

<script>
import EGyBdmbTree from "./EGyBdmbTree.vue";
export default {
  name: "EGyBdmb",
  data() {
    return {
      setvalue: this.value,
      controlSize: this.$config.defaultControlSize,
      departIds: "",
      departNames: "",
      setwidth: this.widthSet(),
      thisSetting: {
        serverName: "Form.ashx"
      },
      thisDisabled: false
    };
  },
  components: {
    EGyBdmbTree
  },
  model: {
    prop: "value",
    event: "change",
    type: Array
  },
  props: {
    defaultValue: {
      type: [String, Array]
    },
    value: {
      type: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array
    },
    mode: {
      type: String,
      default: "single"
    },

    readOnly: {
      type: Boolean,
      default: false
    },
    formula: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: ""
    },
    setting: {
      type: [Object],
      default: () => ({
        treeAction: "GetTemplate"
      })
    },
    filter: {
      type: [String],
      default: ""
    }
  },
  created() {
    this.thisDisabled = this.disabled;
    if (this.setting && !this.setting.serverName) {
      this.thisSetting = Object.assign(this.thisSetting, this.setting);
    } else {
      this.thisSetting = this.setting;
    }
  },
  computed: {},
  methods: {
    /**
     * 设置禁用状态
     * @param v
     */
    setDisabled(v) {
      if(this.$util.isType(v) === "Boolean") {
        this.thisDisabled = v;
      }else {
        this.thisDisabled = true;
      }
    },
    /**
     * 切换当前控件的禁用状态
     */
    setDisabledSwitch() {
      this.thisDisabled = !this.thisDisabled;
    },
    openModal() {
      if (!this.readOnly) {
        this.$refs.selectModal.show();
      }
    },
    handleOK(rows, idstr) {
      this.departNames = "";
      this.departIds = "";
      this.setvalue = "";
      if (!rows) {
        this.departNames = "";
        this.departIds = "";
        this.setvalue = "";
      } else if (rows.length == 1) {
        let temp = "";
        for (let item of rows) {
          temp = item.title;
          this.setvalue = item.key;
        }
        this.departNames = temp;
        this.departNames == "" || this.departNames == null
          ? (this.departIds = "")
          : (this.departIds = idstr);
      } else if (rows.length > 1) {
        let temp = "";
        let values = "";
        for (let item of rows) {
          temp += "," + item.title;
          values += "," + item.key;
        }
        this.departNames = temp.substring(1);
        this.setvalue = values.substring(1);
        this.departNames == "" || this.departNames == null
          ? (this.departIds = "")
          : (this.departIds = idstr);
      }
      this.$emit("change", this.setvalue, this.departNames);
    },
    handleEmpty() {
      this.$refs.selectModal.Empty();
      this.handleOK(null);
    },
    widthSet() {
      if (this.width == "") {
        this.setwidth = "";
        return "";
      }
      if (typeof this.width == "number") {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      if (this.width.indexOf("%") == -1 && this.width.indexOf("px") == -1) {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      this.setwidth = "width:" + this.width;
      return "width:" + this.width;
    }
  },
  watch: {
    width() {
      this.widthSet();
    },
    disabled(v) {
      this.thisDisabled = v;
    },
  }
};
</script>

<style scoped lang="less">
@import "../../../assets/style/base/controls/e-pop-up-select-input.less";
</style>
