<!--
 * 登录组件
 * @create wangxin 2018/07/08
-->
<template>
  <div class="body" style="margin: 0px !important;">
    <div class="box">
      <div class="box-left">
        <div class="logo">
          <img :src="'/img/login2/' + config.logoUrl" />
          <!-- <img src="~@/assets/login2/logo.png" /> -->
        </div>
        <div>
          <a-form :form="form">
            <a-form-item style="width:100%;text-align:center;">
              <div class="title">{{ config.systemName }}</div>
            </a-form-item>
            <a-form-item prop="account">
              <a-input
                v-decorator="userInputRules"
                :allowClear="true"
                placeholder="请输入账号"
                @change="trimInput"
                size="large"
                :style="{ width: '80%', padding: '10px 0' }"
                ref="userInput"
                @pressEnter="handleSubmit"
              >
                <template slot="prefix">
                  <a-icon type="user" :style="{ fontSize: '24px' }" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                type="password"
                class="login-password-input"
                v-decorator="passInputRules"
                placeholder="请输入密码"
                @change="trimInput"
                :style="{ width: '80%', padding: '10px 0' }"
                @blur.native="blurChange"
                @focus.native="blurChange"
                ref="passInput"
                @pressEnter="handleSubmit"
              >
                <template slot="prefix"
                  ><a-icon type="lock" :style="{ fontSize: '24px' }"
                /></template>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-button
                type="primary"
                :loading="loading"
                :style="{
                  width: '80%',
                  margin: '10px 0',
                  height: '50px',
                  'font-size': '20px',
                  'background-image':
                    'linear-gradient(to right, #569be8 , #351bc6)'
                }"
                @click.native.prevent="handleSubmit"
                >登录
              </a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="bottom-text">{{ config.companyName }}<a :href="config.archivalInformationUrl" target="_blank">{{config.archivalInformation}}</a></div>
      </div>
      <div class="box-right"></div>
    </div>
  </div>
</template>

<script>
import { LOGIN_CONFIG } from "../loginSet.js";
import { loginMixins } from "../mixins/loginMixins.js";

//页面初始化时执行方法
export default {
  mixins: [LOGIN_CONFIG, loginMixins],
  name: "Login",
  data() {
    return {
      loading: false,
      config: window.LOGIN_CONFIG,
      blur: true,
      enterOk: true,
      userInputRules: [
        "username",
        {
          initialValue: "",
          rules: [{ required: true, message: "请输入账号" }]
        }
      ],
      passInputRules: [
        "password",
        {
          initialValue: "",
          rules: [{ required: true, message: "请输入密码" }]
        }
      ],
      form: this.$form.createForm(this, { name: "login校验" }),
      checked: true
    };
  },
  created() {
    // let _self = this;
    // document.onkeydown = function() {
    //   //用户按Enter，提交登录
    //   console.log("_self.blur", _self.blur);
    //   if (window.event.keyCode === 13 && _self.blur) {
    //     // _self.blur = false;
    //     // _self.$refs.userInput.blur();
    //     // _self.$refs.passInput.blur();
    //     _self.handleSubmit();
    //   }
    // };
  },
  mounted() {
    this.$refs.userInput.focus();
  },
  methods: {
    /**
     * 设置清空用户名输入的空格
     * @param e
     */
    trimInput(e) {
      let val = e.target.value;
      if ("" == this.$util.trimStr(val)) {
        e.target.value = "";
      }
    },
    handleSubmit() {
      this.login(this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.body {
  height: 100%;
  width: 100%;
  background: url(/img/login2/backgroud.jpg) center center no-repeat;
  background-size: 100% 100%;
  // padding: 5% 12%;
  min-width: 1400px;
  position: relative;
  min-height: 950px;
}
.box {
  border-radius: 30px;
  background: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-box-shadow: 0px 0px 5px blue;
  box-shadow: 0px 0px 5px blue;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 750px;
  width: 1200px;
}

.box-left {
  float: left;
  width: 30%;
  height: 100%;
  position: relative;
}

.box-right {
  float: left;
  width: 70%;
  height: 100%;
  background: url(/img/login2/backgroud2.jpg) no-repeat;
  background-size: 100% 100%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.login-container {
  width: 100%;
}

.logo {
  margin-top: 10%;
  padding: 5%;
}

.title {
  color: #000;
  font-size: 30px;
}

.bottom-text {
  color: #a1a1a1;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 20px;
}

::v-deep .ant-input {
  background-color: #f3f8fe !important;
  padding: 0 60px !important;
  height: 50px;
  line-height: 50px;
  background-color: #f3f8fe;
  text-align: left;
  border: 0px;
  font-size: 18px;
}

::v-deep .ant-form-explain {
  text-align: left;
  margin-left: 100px;
}
</style>
