<template>
  <div class="e_xzjs" v-show="visible" :style="setwidth">
    <a-tooltip :title="setvalue" arrow-point-at-center>
      <a-input
        @click="openModal"
        placeholder="请点击输入公式"
        :size="controlSize"
        v-model="setvalue"
        readOnly
        :disabled="disabled"
      >
        <e-icon slot="prefix" type="plus" title="请点击输入公式" :disabled="disabled"/>
        <a-icon
          v-if="setvalue != ''"
          slot="suffix"
          type="close-circle"
          @click.stop="handleEmpty"
          title="清空"
        />
      </a-input>
    </a-tooltip>
    <EXzjsInput
      :ref="'selectModal' + random"
      @ok="handleOK"
      :type="type"
      v-model="setvalue"
    ></EXzjsInput>
  </div>
</template>

<script>
import EXzjsInput from "./EXzjsInput.vue";
export default {
  name: "EXzjs",
  data() {
    return {
      setvalue: this.value,
      controlSize: this.$config.defaultControlSize,
      setwidth: this.widthSet(),
      buttonData: [],
      random: new Date().getTime()
    };
  },
  components: {
    EXzjsInput
  },
  model: {
    prop: "value",
    event: "change",
    type: Array
  },
  props: {
    value: {
      type: [String, Array]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: ""
    },
    type: {
      type: String,
      default: "薪资项目计算公式项目"
    }
  },
  created() {},
  computed: {},
  methods: {
    openModal() {
      if (!this.readOnly && !this.disabled) {
        this.$refs["selectModal" + this.random].postData(this.setvalue);
        this.$refs["selectModal" + this.random].show();
      }
    },
    handleOK(rows) {
      this.setvalue = rows;
      this.$emit("change", rows);
    },
    handleEmpty() {
      this.handleOK(null);
    },
    widthSet() {
      if (this.width == "") {
        this.setwidth = "";
        return "";
      }
      if (typeof this.width == "number") {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      if (this.width.indexOf("%") == -1 && this.width.indexOf("px") == -1) {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      this.setwidth = "width:" + this.width;
      return "width:" + this.width;
    }
  },
  watch: {
    width() {
      this.widthSet();
    },
    disabled() {}
  }
};
</script>

<style scoped lang="less">
@import "../../../assets/style/base/controls/e-pop-up-select-input.less";
</style>
