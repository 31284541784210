<template>
  <a-radio-group
    v-model="setvalue"
    :defaultValue="defaultValue"
    :value="setvalue"
    :size="controlSize"
    :disabled="thisDisabled"
    :options="options"
  ></a-radio-group>
</template>

<script>
export default {
  name: "ERadio",
  data() {
    return {
      setvalue: this.value,
      controlSize: this.$config.defaultControlSize,
      option: this.options,
      thisDisabled: false
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    defaultValue: {
      type: String
    },
    value: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array
    },
    decorator: {
      type: [String, Object, Array]
    },
    formula: {
      type: String,
      default: ""
    }
  },
  created() {
    this.thisDisabled = this.disabled;
  },
  computed: {},
  methods: {
    /**
     * ���ý���״̬
     * @param v
     */
    setDisabled(v) {
      if(this.$util.isType(v) === "Boolean") {
        this.thisDisabled = v;
      }else {
        this.thisDisabled = true;
      }
    },
    /**
     * �л���ǰ�ؼ��Ľ���״̬
     */
    setDisabledSwitch() {
      this.thisDisabled = !this.thisDisabled;
    },
  },
  watch: {
    setvalue(value) {
      this.$emit("change", value);
    },
    disabled(v) {
      this.thisDisabled = v;
    },
  }
};
</script>
