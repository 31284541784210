var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.visible)?_c('a-modal',{attrs:{"title":_vm.title,"visible":_vm.visible,"cancelText":"关闭"},on:{"cancel":_vm.handleCancel}},[_c('template',{slot:"footer"},[_c('a-button',{key:"submit",attrs:{"type":"primary"},on:{"click":_vm.ok}},[_vm._v(" 确定 ")])],1),_c('div',[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 10 },"wrapper-col":{ span: 12 }}},[(_vm.type != 'table2')?[_c('a-form-item',{attrs:{"label":_vm.label1}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'label1',
                {
                  rules: [{ required: true, message: '此项为必填项' }],
                  initialValue: _vm.value.label1 ? _vm.value.label1 : ''
                }
              ]),expression:"[\n                'label1',\n                {\n                  rules: [{ required: true, message: '此项为必填项' }],\n                  initialValue: value.label1 ? value.label1 : ''\n                }\n              ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.label2}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'label2',
                {
                  rules: [{ required: true, message: '此项为必填项' }],
                  initialValue: _vm.value.label2 ? _vm.value.label2 : ''
                }
              ]),expression:"[\n                'label2',\n                {\n                  rules: [{ required: true, message: '此项为必填项' }],\n                  initialValue: value.label2 ? value.label2 : ''\n                }\n              ]"}]})],1)]:[_c('a-form-item',{attrs:{"label":_vm.label1}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'select1',
                {
                  rules: [{ required: true, message: '此项为必填项' }],
                  initialValue: _vm.value.select1 ? _vm.value.select1 : ''
                }
              ]),expression:"[\n                'select1',\n                {\n                  rules: [{ required: true, message: '此项为必填项' }],\n                  initialValue: value.select1 ? value.select1 : ''\n                }\n              ]"}],attrs:{"options":_vm.ywzdOption}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'label1',
                {
                  rules: [{ required: true, message: '此项为必填项' }],
                  initialValue: _vm.value.label1 ? _vm.value.label1 : ''
                }
              ]),expression:"[\n                'label1',\n                {\n                  rules: [{ required: true, message: '此项为必填项' }],\n                  initialValue: value.label1 ? value.label1 : ''\n                }\n              ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.label2}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'select2',
                {
                  rules: [{ required: true, message: '此项为必填项' }],
                  initialValue: _vm.value.select2 ? _vm.value.select2 : ''
                }
              ]),expression:"[\n                'select2',\n                {\n                  rules: [{ required: true, message: '此项为必填项' }],\n                  initialValue: value.select2 ? value.select2 : ''\n                }\n              ]"}],attrs:{"options":_vm.jczdOption}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'label2',
                {
                  rules: [{ required: true, message: '此项为必填项' }],
                  initialValue: _vm.value.label2 ? _vm.value.label2 : ''
                }
              ]),expression:"[\n                'label2',\n                {\n                  rules: [{ required: true, message: '此项为必填项' }],\n                  initialValue: value.label2 ? value.label2 : ''\n                }\n              ]"}]})],1)],[_c('a-form-item',{attrs:{"label":"是否生效"}},[_c('EBooleanCheckbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'isOk',
                {
                  rules: [{ required: true, message: '此项为必填项' }],
                  initialValue: _vm.value.isOk ? _vm.value.isOk : ''
                }
              ]),expression:"[\n                'isOk',\n                {\n                  rules: [{ required: true, message: '此项为必填项' }],\n                  initialValue: value.isOk ? value.isOk : ''\n                }\n              ]"}]})],1)]],2)],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }