<!--
 * 登录组件
 * @create wangxin 2018/07/08
-->
<template>
  <div class="body" style="margin: 0px !important;">
    <div class="box">
      <div class="box-left">
        <div class="logo">
          <!-- <img src="~@/assets/login1/logo.png" style="float:left" /> -->
          <img :src="'/img/login1/' + config.logoUrl" style="float:left" />
          <div style="float:left;" class="title">
            {{ config.systemName }}
          </div>
        </div>
      </div>
      <div class="box-right">
        <div style="height:90%;">
          <div style="padding-top:25%;">
            <a-form
              :form="form"
              :style="{ 'padding-left': '10%', 'padding-right': '10%' }"
            >
              <a-form-item style="width:100%;text-align:left;">
                <div class="title-next">{{ config.welcome }}</div>
              </a-form-item>
              <a-form-item prop="account">
                <a-input
                  v-decorator="userInputRules"
                  :allowClear="true"
                  placeholder="请输入账号"
                  @change="trimInput"
                  size="large"
                  :style="{ padding: '10px 0' }"
                  ref="userInput"
                  @pressEnter="handleSubmit"
                >
                  <template slot="prefix">
                    <a-icon type="user" :style="{ fontSize: '24px' }" />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-input
                  type="password"
                  class="login-password-input"
                  v-decorator="passInputRules"
                  placeholder="请输入密码"
                  @change="trimInput"
                  :style="{ padding: '10px 0' }"
                  @pressEnter="handleSubmit"
                >
                  <template slot="prefix"
                    ><a-icon type="lock" :style="{ fontSize: '24px' }"
                  /></template>
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-button
                  type="primary"
                  :loading="loading"
                  :style="{
                    width: '100%',
                    margin: '10px 0',
                    height: '50px',
                    'font-size': '20px',
                    'background-image':
                      'linear-gradient(to right, #569be8 , #351bc6)'
                  }"
                  @click.native.prevent="handleSubmit"
                  >登录
                </a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <div class="bottom-text">{{ config.companyName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGIN_CONFIG } from "../loginSet.js";
import { loginMixins } from "../mixins/loginMixins.js";
//页面初始化时执行方法
export default {
  mixins: [LOGIN_CONFIG, loginMixins],
  name: "Login",
  data() {
    return {
      loading: false,
      config: window.LOGIN_CONFIG,
      userInputRules: [
        "username",
        {
          initialValue: "",
          rules: [{ required: true, message: "请输入账号" }]
        }
      ],
      passInputRules: [
        "password",
        {
          initialValue: "",
          rules: [{ required: true, message: "请输入密码" }]
        }
      ],
      form: this.$form.createForm(this, { name: "login校验" }),
      checked: true
    };
  },
  created() {
    // let _self = this;
    // document.onkeydown = function() {
    //   //用户按Enter，提交登录
    //   if (window.event.keyCode === 13) {
    //     _self.handleSubmit();
    //   }
    // };
  },
  mounted() {
    this.$refs.userInput.focus();
  },
  methods: {
    /**
     * 设置清空用户名输入的空格
     * @param e
     */
    trimInput(e) {
      let val = e.target.value;
      if ("" == this.$util.trimStr(val)) {
        e.target.value = "";
      }
    },
    handleSubmit() {
      this.login(this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.body {
  height: 100%;
  width: 100%;
  background: url(/img/login1/backgroud.jpg) center center no-repeat;
  background-size: 100% 100%;
  min-width: 1200px;
  position: relative;
  min-height: 800px;
}
.box {
  border-radius: 30px;
  background: #fff;
  width: 1000px;
  height: 600px;
  position: relative;
  -webkit-box-shadow: 0px 0px 5px blue;
  box-shadow: 0px 0px 5px blue;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.box-left {
  float: left;
  width: 60%;
  height: 100%;
  background: url(/img/login1/backgroud2.jpg) no-repeat;
  background-size: 100% 100%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  position: relative;
}

.box-right {
  float: left;
  width: 40%;
  height: 100%;
  position: relative;
}

.login-container {
  width: 100%;
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
}

.logo span {
  color: #3c92ff;
  font-size: 32px;
}

.title {
  color: #3c91fe;
  font-size: 30px;
  letter-spacing: 5px;
  height: 60px;
  line-height: 60px;
  letter-spacing: 2px;
  padding-left: 10px;
}

.title-next {
  color: #000;
  font-size: 24px;
  text-align: left;
  letter-spacing: 5px;
  font-weight: bold;
}

.bottom-text {
  color: #a1a1a1;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 20px;
  font-size: 16px;
  letter-spacing: 3px;
}

::v-deep .ant-input {
  background-color: #f3f8fe !important;
  padding: 0 60px !important;
  height: 50px;
  line-height: 50px;
  background-color: #f3f8fe;
  text-align: left;
  border: 0px;
  font-size: 18px;
}

::v-deep .ant-form-explain {
  text-align: left;
  margin-left: 60px;
}
</style>
