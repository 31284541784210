<template>
  <!-- 错误视图 -->
  <a-empty
    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
  >
    <span slot="description"> 视图加载失败 </span>
    <a-button type="primary" @click="closeView">关闭</a-button>
  </a-empty>
</template>

<script>
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
export default {
  mixins: [pageControls],
  name: "NotFound",
  data() {
    return {};
  },
  methods: {
    /**
     * 关闭当前的tab
     */
    closeView() {
      this.closeNowTab();
    }
  }
};
</script>
