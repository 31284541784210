<template>
  <div>
    <a-modal
      title="导入数据"
      :visible="visible"
      @ok="handleCancel"
      @cancel="handleCancel"
      cancelText="关闭"
    >
      <div class="">
        <a-upload
          :fileList="fileList"
          :remove="handleRemove"
          :beforeUpload="beforeUpload"
          :disabled="disabled"
          :accept="accept"
        >
          <a-button> <a-icon type="upload" />选择文件 </a-button>
        </a-upload>
        <a-button
          type="primary"
          @click="handleUpload"
          :disabled="fileList.length === 0"
          :loading="uploading"
          style="margin-left:8px"
          >{{ uploading ? "上传中" : "开始上传" }}</a-button
        >
      </div>
    </a-modal>
  </div>
</template>
<script>
import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法
import Excel from "exceljs";
export default {
  name: "ImportExcelDataModal",
  mixins: [requestControls],
  data() {
    return {
      fileList: [],
      visible: false,
      uploading: false,
      setValue: this.value,
      thisSetting: {
        serverName: "Form.ashx"
      },
      rowDefinition: [],
      data: []
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: [String, Array, null]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String
    },
    setting: {
      type: Object,
      default: () => ({
        action: "upload"
      })
    }
  },
  created() {
    if (this.setting && !this.setting.serverName) {
      this.thisSetting = Object.assign(this.thisSetting, this.setting);
    } else {
      this.thisSetting = this.setting;
    }
  },
  computed: {},
  methods: {
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    show(row) {
      this.visible = true;
      this.rowDefinition = [];
      if (row) {
        row.forEach(element => {
          var obj = {
            title: element.headerText,
            field: element.fieldName,
            width: element.width
          };
          this.rowDefinition.push(obj);
        });
      }
    },
    handleCancel() {
      this.visible = false;
    },
    handleUpload() {
      // var data = this.thisSetting;
      const { fileList } = this;
      var _this = this;
      let reads = new FileReader();
      var file = fileList[0];
      reads.onload = function() {
        var workbook = new Excel.Workbook();

        workbook.xlsx.load(reads.result).then(function(filedata) {
          // use workbook
          var excelData = filedata.getWorksheet();
          var data = excelData.getSheetValues();
          _this.rowDefinition;
          var fieldArray = [];
          var postData = [];
          _this.rowDefinition.forEach(element => {
            for (var i = 0; i <= data[1].length - 1; i++) {
              if (element.title == data[1][i]) {
                fieldArray[i] = element.field;
                break;
              }
            }
          });
          for (var j = 2; j <= data.length - 1; j++) {
            var json = {};
            data[j].forEach((row, index) => {
              var fi = fieldArray[index];
              json[fi] = row;
            });
            json[_this.defaultTableKeyName] = j;
            postData.push(json);
          }

          _this.$emit("ExcelImportOk", postData);
          _this.handleCancel();
        });
      };
      reads.readAsArrayBuffer(file);
    }
  },
  watch: {
    setvalue(value) {
      this.$emit("change", value);
    },
    width() {
      this.widthSet();
    },
    disabled() {}
  }
};
</script>
<style module></style>
