var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('a-modal',{attrs:{"title":"系统打印","visible":_vm.visible,"cancelText":"关闭","maskClosable":false,"destroyOnClose":true,"width":1330,"mask":false},on:{"cancel":function () {
      _vm.visible = false;
      _vm.editors = null;
    }}},[_c('template',{slot:"footer"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.print}},[_c('a-icon',{attrs:{"type":"check"}}),_vm._v("打印预览 ")],1)],1),[_c('div',[_c('textarea',{staticStyle:{"min-height":"600px","width":"1280px"},attrs:{"id":_vm.id}})])],_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],style:({
      'overflow-x': 'scroll',
      width: _vm.getWidth,
      maxwidth: '1800px',
      'font-size': _vm.fontSize
    })},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',{style:({ width: '100%' })},[_c('div',{staticClass:"relative"},[(_vm.header != '')?_c('div',{attrs:{"id":"systemPrintTitle"}},[_c('h1',{style:({ 'text-align': 'center' })},[_vm._v(_vm._s(_vm.header))])]):_vm._e(),(_vm.printConfig && _vm.printConfig.formRows)?_c('div',{staticStyle:{"width":"100%"},attrs:{"id":"systemPrintMainForm"}},[_c('table',{staticStyle:{"width":"100%","margin-bottom":"20px"},attrs:{"border":"0"}},[_vm._l((_vm.printConfig.formRows),function(row,index){return [_c('tr',{key:("row" + index)},[_vm._l((row),function(col,colIndex){return [(col && col.controlType &&
                        col.controlType != 'Text' &&
                          col.controlType != 'EUpload'
                      )?[_c('td',{key:("row" + index + "col" + colIndex + "label"),staticClass:"mceNonEditable",style:({
                          'font-size': _vm.fontSize,
                          'text-align': 'right'
                        })},[_vm._v(" "+_vm._s(col.label)+": ")]),_c('td',{key:("row" + index + "col" + colIndex + "value"),staticClass:"mceNonEditable",style:({ 'font-size': _vm.fontSize }),attrs:{"colspan":colIndex == row.length - 1
                            ? (_vm.maxCol - (colIndex + 1)) * 2 + 1
                            : ''}},[(col && col.controlType && col.controlType != 'EDatetimePicker')?_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v(_vm._s(col.value))]):(col)?_c('span',[_vm._v(_vm._s(_vm.timeFormat(col.value)))]):_vm._e()])]:(col && col.controlType != 'EUpload')?[_c('td',{key:("row" + index + "col" + colIndex + "text"),staticClass:"mceNonEditable",style:({
                          'font-size': _vm.fontSize
                        }),attrs:{"colspan":colIndex == row.length - 1
                            ? (_vm.maxCol - (colIndex + 1)) * 2 + 2
                            : ''}},[_vm._v(" "+_vm._s(col.label)+" ")])]:_vm._e()]})],2)]})],2)]):_vm._e(),(_vm.printConfig && (_vm.printConfig.mxForm || _vm.printConfig.flow))?_c('div',{staticStyle:{"width":"100%"},attrs:{"id":"systemPrintMxForm"}},[(_vm.printConfig.mxForm && _vm.printConfig.mxForm.columns)?_c('a-table',{style:({ 'font-size': _vm.fontSize, width: '100%' }),attrs:{"bordered":true,"columns":_vm.printConfig.mxForm.columns,"dataSource":_vm.printConfig.mxForm.data.length == 0
                  ? _vm.returnNullMxData
                  : _vm.printConfig.mxForm.data,"pagination":false},scopedSlots:_vm._u([_vm._l((_vm.printConfig.mxForm.columns),function(col,index){return {key:col.dataIndex,fn:function(text){return [(col &&
                    col.controlType &&
                      col.controlType == 'CxsGridDateTimeColumn'
                  )?_c('span',{key:col.dataIndex + index,style:({ 'font-size': _vm.fontSize })},[_vm._v(_vm._s(_vm.timeFormat(text)))]):(col)?_c('span',{key:col.dataIndex + index,style:({ 'font-size': _vm.fontSize })},[_vm._v(_vm._s(_vm.checkTime(text, col)))]):_vm._e()]}}})],null,true)}):_vm._e(),(_vm.printConfig.flow)?_c('table',{staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"border":"0"}},[_vm._l((_vm.printConfig.flow),function(row,index){return [_c('tr',{key:("row" + index)},[_vm._l((row),function(col,colIndex){return [(col && col.controlType &&
                        col.controlType != 'Text' &&
                          col.controlType != 'EUpload'
                      )?[_c('td',{key:("row" + index + "col" + colIndex + "label"),staticClass:"mceNonEditable",style:({
                          'font-size': _vm.fontSize,
                          'text-align': 'right'
                        })},[_vm._v(" "+_vm._s(col.label)+": ")]),_c('td',{key:("row" + index + "col" + colIndex + "value"),staticClass:"mceNonEditable",style:({ 'font-size': _vm.fontSize }),attrs:{"colspan":colIndex == row.length - 1
                            ? (_vm.maxCol - (colIndex + 1)) * 2 + 1
                            : ''}},[(col && col.controlType && col.controlType != 'EDatetimePicker')?_c('span',{staticStyle:{"text-decoration":"underline"},domProps:{"innerHTML":_vm._s(col.value)}},[_vm._v(_vm._s(col.value))]):_c('span',[_vm._v(_vm._s(_vm.timeFormat(col.value)))])])]:(col && col.controlType != 'EUpload')?[_c('td',{key:("row" + index + "col" + colIndex + "text"),staticClass:"mceNonEditable",style:({
                          'font-size': _vm.fontSize
                        }),attrs:{"colspan":colIndex == row.length - 1
                            ? (_vm.maxCol - (colIndex + 1)) * 2 + 2
                            : ''}},[_vm._v(" "+_vm._s(col.label)+" ")])]:_vm._e()]})],2)]})],2):_vm._e()],1):_vm._e()])])])])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }