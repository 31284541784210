//公共打印小票方法, ！已经废弃了
export const npPrint = {
  data() {
    return {
      showNpPrint: false, // 二维码小票打印预览
      printDetailData: [], // 需要打印的明细数据
      PrintE1HandlerURL: "/AppWeb/Handler/E1Handler.ashx" //this.$config.BASE_URL +
    };
  },
  watch: {},
  created() {
    //console.log("混入bluePrint的通用方法");
  },
  methods: {
    /**
     * 打印二维码
     */
    tsbQRCode(button) {
      let selectRows = this.$refs.formTemplate.getSelectedDetails();
      if (selectRows) {
        if (selectRows.length == 0) {
          this.tipWarning("请选中需要打印的明细记录");
          return;
        }
        let that = this;
        selectRows.map(function(d) {
          d.pritnFormat = "format_2DCodeWL";
          d._MainDataPCH = that.mainTableData._PCH;
          d.printCodeURL = that.getWl2DCodeContent_mixin(d, d._MainDataPCH);
        });
        this.startProcessing(button);
        this.printDetailData = [].concat(selectRows);
        this.showNpPrint = true;
        this.finishedProcessing(button);
      }
    },
    /**
     * 获取物料二维码内容
     * @param {Object} data
     * @param {Object} pch
     */
    getWl2DCodeContent_mixin: function(data, pch) {
      let content = "";
      content += this.PrintE1HandlerURL + "?action=Qrcode&type=wlxx";
      content += "&bm=" + data._WZBM;
      content += "&pch=" + pch; //使用同一个批次号
      content += "&mc=" + data._WZMC;
      content += "&id=" + data.ID;
      return content;
    },
    /**
     * 打印指定物料的二维码
     * @param {Object} data 物料明细数据
     * @param {Object} pch 表单批次号
     */
    bluePrintWLIndex: function(data, pch) {
      let content = this.getWl2DCodeContent_mixin(data, pch);
      let jsonData = this.getPrintWLFormatData(content, data, pch);
      console.log("打印:", jsonData);
    },
    /**
     * 返回物料二维码小票打印数据
     * @param {Object} content
     * @param {Object} data
     * @param {Object} pch
     */
    getPrintWLFormatData: function(content, data, pch) {
      return {
        format: "format_2DCodeWL",
        labels: [
          {
            name: "",
            value: content
          },
          {
            name: "编码:",
            value: data._WZBM
          },
          {
            name: "规格:",
            value: data._GGXH
          },
          {
            name: "名称:",
            value: data._WZMC
          },
          {
            name: "批次:",
            value: pch
          }
        ]
      };
    }
  }
};
