<template>
  <div v-show="visible" :style="setwidth">
    <a-tooltip :title="departNames" arrow-point-at-center>
      <a-input
        @click="openModal"
        placeholder="请点击选择"
        v-model="departNames"
        readOnly
        :disabled="disabled"
        :size="controlSize"
        ref="tableButton"
      >
        <e-icon slot="prefix" type="plus" title="选择控件" />
        <a-icon
          v-if="departIds != '' && !disabled && !readOnly"
          slot="suffix"
          type="close-circle"
          theme="filled"
          @click.stop="handleEmpty"
          title="清空"
          :style="{ color: 'rgba(0, 0, 0, 0.25)' }"
        />
      </a-input>
    </a-tooltip>
    <TableButtonControlsTree
      v-if="set[type].type == 'tree'"
      ref="selectModal"
      :mode="mode"
      @ok="handleOK"
      :value="setvalue"
      :defaultValue="defaultValue"
      :bdmbId="bdmbId"
      :setting="set[type]"
      :filterSave="filterSave"
      @filterSave="filterSaveOk"
      :search="search"
      :notSave="notSave"
    ></TableButtonControlsTree>
    <TableButtonControlsTabel
      :value="setvalue"
      ref="selectTable"
      v-if="set[type].type == 'table'"
      :setting="set[type]"
      @ok="handleOK"
      :multiSelect="multiSelect"
      :filterSave="filterSave"
      @filterSave="filterSaveOk"
      :search="search"
      :notSave="notSave"
    ></TableButtonControlsTabel>
  </div>
</template>

<script>
import TableButtonControlsTree from "./TableButtonControlsTree.vue";
import TableButtonControlsTabel from "./TableButtonControlsTabel.vue";
import { getOption } from "./TableButtonControlsSetting.js";
export default {
  name: "TableButtonControls",
  data() {
    return {
      setvalue: this.value,
      departIds: "",
      departNames: "",
      setwidth: this.widthSet(),
      thisSetting: {
        serverName: "Form.ashx"
      },
      set: {},
      controlSize: this.$config.defaultControlSize
    };
  },
  components: {
    TableButtonControlsTree,
    TableButtonControlsTabel
  },
  model: {
    prop: "value",
    event: "change",
    type: Array
  },
  props: {
    defaultValue: {
      type: [String, Array, Number]
    },
    value: {
      type: null
    },
    type: {
      type: [String]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: [Array, null],
      default: null
    },
    mode: {
      type: String,
      default: "single"
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    formula: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: ""
    },
    bdmbId: {
      type: [String, Number]
    },
    setting: {
      type: [Object],
      default: () => ({
        treeAction: "GetWZFLList",
        nodeAction: "GetWZInfo"
      })
    },
    linkage: {
      type: Array,
      default: () => []
    },
    multiSelect: {
      type: String,
      default: "radio"
    },
    filterSave: {
      type: null
    },
    search: {
      type: String,
      default: ""
    },
    notSave: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.search;
    console.log("摘要或者会计科目value :", this.value);
    this.set = getOption();
    if (this.setting && !this.setting.serverName) {
      this.thisSetting = Object.assign(this.thisSetting, this.setting);
    } else {
      this.thisSetting = this.setting;
    }
  },
  computed: {},
  methods: {
    openModal() {
      if (!this.readOnly) {
        this.set[this.type].type == "tree" ? this.$refs.selectModal.show() : "";
        this.set[this.type].type == "table"
          ? this.$refs.selectTable.show()
          : "";
      }
    },
    handleOK(rows, idstr, option) {
      if (this.set[this.type].type == "tree") {
        this.departNames = "";
        this.departIds = "";
        this.setvalue = "";
        if (!rows) {
          this.departNames = "";
          this.departIds = "";
          this.setvalue = "";
        } else if (rows.length == 1) {
          let temp = "";
          for (let item of rows) {
            temp = item.title;
            this.setvalue = item.key;
          }
          this.departNames = temp;
          this.departNames == "" || this.departNames == null
            ? (this.departIds = "")
            : (this.departIds = idstr);
        } else if (rows.length > 1) {
          let temp = "";
          let values = "";
          for (let item of rows) {
            temp += "," + item.title;
            values += "," + item.key;
          }
          this.departNames = temp.substring(1);
          this.setvalue = values.substring(1);
          this.departNames == "" || this.departNames == null
            ? (this.departIds = "")
            : (this.departIds = idstr);
        }
        if (rows) {
          var emitLinkedChange = this.linkedChange(rows);
          this.$emit("linkedChange", emitLinkedChange);
        }

        this.$emit("change", this.setvalue, this.departNames);
        console.log("departIds:", this.departIds);
        return;
      }
      this.departNames = "";
      this.departIds = "";
      this.setvalue = "";
      if (!rows) {
        this.departNames = "";
        this.departIds = "";
        this.setvalue = "";
        this.$emit("change", this.setvalue, this.departNames);
        return;
      }
      if (rows.length == 1) {
        var temp = "";

        if (option.type == "table") {
          temp = rows[0][option.titleIndex];
          this.setvalue = rows[0][option.keyIndex];
        }

        this.departNames = temp;
        this.departNames == "" || this.departNames == null
          ? (this.departIds = "")
          : (this.departIds = idstr);
      }
      if (rows.length > 1) {
        var temp = "";
        var values = "";
        for (let item of rows) {
          if (option.type == "table") {
            temp += "," + item[option.titleIndex];
            values += "," + item[option.keyIndex];
          }
          if (option.type == "tree") {
            temp += "," + item.title;
            values += "," + item.key;
          }
        }
        this.departNames = temp.substring(1);
        this.setvalue = values.substring(1);
        this.departNames == "" || this.departNames == null
          ? (this.departIds = "")
          : (this.departIds = idstr);
      }
      if (rows) {
        this.$emit("linkedChange", this.linkedChange(rows));
      }
      this.$emit("change", this.setvalue, this.departNames);
    },
    linkedChange(data) {
      // {mbField:field}
      var json = {};
      let that = this;
      this.linkage.forEach(element => {
        if(that.$util.isType(data[0][element.field]) !== "Undefined"){
          json[element.mbField] = data[0][element.field];
        }
      });
      //console.log("控件linkedChange=", json);
      return json;
    },
    handleEmpty() {
      if (this.set[this.type].type == "tree") {
        this.$refs.selectModal.Empty();
      }
      if (this.set[this.type].type == "table") {
        this.$refs.selectTable.Empty();
      }
      this.handleOK(null);
    },
    widthSet() {
      if (this.width == "") {
        this.setwidth = "";
        return "";
      }
      if (typeof this.width == "number") {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      if (this.width.indexOf("%") == -1 && this.width.indexOf("px") == -1) {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      this.setwidth = "width:" + this.width;
      return "width:" + this.width;
    },
    filterSaveOk(data) {
      if (!this.notSave) {
        this.$emit("filterSave", data);
      }
    },
    tableFocus() {
      this.$refs.tableButton.focus();
    },
    tableBlur() {
      this.$refs.tableButton.blur();
    }
  },
  watch: {
    width() {
      this.widthSet();
    },
    value(v, oldv) {
      if (v != oldv && this.set[this.type].type == "tree") {
        this.$refs.selectModal.loadDepartValue(v);
      }
    },
    disabled() {},
    search() {},
    notSave() {}
  }
};
</script>

<style module></style>
