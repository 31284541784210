<template>
  <div v-show="visible" :style="setwidth" class="e-from-ematerials">
    <a-tooltip :title="departNames" arrow-point-at-center>
      <a-input
        @click="openModal"
        placeholder="请点击选择物资"
        :size="controlSize"
        v-model="departNames"
        readOnly
        :disabled="thisDisabled"
        ref="materials"
      >
        <e-icon slot="prefix" type="plus" title="物资选择控件" :disabled="thisDisabled"/>
        <e-icon
          v-if="departIds != '' && !thisDisabled && !readOnly"
          slot="suffix"
          type="close-circle"
          theme="filled"
          @click.stop="handleEmpty"
          title="清空"
          :style="{ color: 'rgba(0, 0, 0, 0.25)' }"
          class="e-pop-up-select-input-right"
        />
      </a-input>
    </a-tooltip>
    <EMaterialsTree
      v-if="show"
      ref="selectModal"
      :mode="mode"
      @ok="handleOK"
      :value="setvalue"
      :defaultValue="defaultValue"
      :bdmbId="bdmbId"
      :setting="thisSetting"
      :search="search"
      :filter="filter"
      :keyType="keyType"
      :filterSave="filterSave"
      @filterSave="filterSaveOk"
      :okValue="okValue"
      @okValue="okValueOk"
      @getChinese="
        (chinese, value) => {
          thisOldValue = chinese;
        }
      "
    ></EMaterialsTree>
    <li v-if="thisOldValue" class="form_right_icon">
      <a-tooltip
        placement="topRight"
        :title="'原值：' + thisOldValue"
        arrow-point-at-center
      >
        <a style="height: auto;">
          <e-icon type="exclamation-circle"></e-icon>
        </a>
      </a-tooltip>
    </li>
  </div>
</template>

<script>
import EMaterialsTree from "./EMaterialsTree.vue";

export default {
  name: "EMaterials",
  data() {
    return {
      setvalue: this.value,
      controlSize: this.$config.defaultControlSize,
      departIds: "",
      departNames: "",
      setwidth: this.widthSet(),
      thisSetting: {
        serverName: "Form.ashx"
      },
      show: true,
      thisOldValue: null,
      thisDisabled: false
    };
  },
  components: {
    EMaterialsTree
  },
  model: {
    prop: "value",
    event: "change",
    type: Array
  },
  props: {
    defaultValue: {
      type: [String, Array, Number]
    },
    value: {
      type: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: [Array, null],
      default: null
    },
    mode: {
      type: String,
      default: "single"
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    formula: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: ""
    },
    bdmbId: {
      type: [String, Number]
    },
    setting: {
      type: [Object],
      default: () => ({
        treeAction: "GetWZFLList",
        nodeAction: "GetWZInfo"
      })
    },
    linkage: {
      type: Array,
      default: () => []
    },
    search: {
      type: String,
      default: ""
    },
    filter: {
      type: String,
      default: ""
    },
    keyType: {
      type: String,
      default: ""
    },
    filterSave: {
      type: null
    },
    okValue: {
      type: Array,
      default: function() {
        return [];
      }
    },

    modifiedValue: {
      type: [String, Number],
      default: null
    }
  },
  created() {
    console.log("linkage:", this.linkage);
    console.log("options:", this.options);
    console.log("value:", this.value);
    this.thisDisabled = this.disabled;
    if (this.setting && !this.setting.serverName) {
      this.thisSetting = Object.assign(this.thisSetting, this.setting);
    } else {
      this.thisSetting = this.setting;
    }
  },
  mounted() {
    if (this.thisOldValue) {
      this.$refs.selectModal.loadDepartValue(this.thisOldValue, true);
    }
  },
  computed: {},
  methods: {
    /**
     * 设置禁用状态
     * @param v
     */
    setDisabled(v) {
      if(this.$util.isType(v) === "Boolean") {
        this.thisDisabled = v;
      }else {
        this.thisDisabled = true;
      }
    },
    /**
     * 切换当前控件的禁用状态
     */
    setDisabledSwitch() {
      this.thisDisabled = !this.thisDisabled;
    },
    openModal() {
      if (!this.readOnly) {
        // this.show = true;
        // this.$nextTick(() => {
        this.$refs.selectModal.show();
        // });
      }
    },
    handleOK(rows, idstr) {
      this.departNames = "";
      this.departIds = "";
      this.setvalue = "";
      if (!rows) {
        this.departNames = "";
        this.departIds = "";
        this.setvalue = "";
      } else if (rows.length == 1) {
        let temp = "";
        for (let item of rows) {
          temp = item.title;
          this.setvalue = item.key;
        }
        this.departNames = temp;
        this.departNames == "" || this.departNames == null
          ? (this.departIds = "")
          : (this.departIds = idstr);
      } else if (rows.length > 1) {
        let temp = "";
        let values = "";
        for (let item of rows) {
          temp += "," + item.title;
          values += "," + item.key;
        }
        this.departNames = temp.substring(1);
        this.setvalue = values.substring(1);
        this.departNames == "" || this.departNames == null
          ? (this.departIds = "")
          : (this.departIds = idstr);
      }
      if (rows) {
        let linkColumnsValue = this.linkedChange(rows);
        this.$emit("linkedChange", linkColumnsValue);
        this.$emit("change", this.setvalue, this.departNames, linkColumnsValue);
      } else {
        this.$emit("change", this.setvalue, this.departNames);
        console.log("departIds:", this.departIds);
      }
    },
    /**
     * 从接口数据中获取需要联动带出的数据
     * @param data
     * @return {{}|Array|*}
     */
    linkedChange(data) {
      let json = [];
      for (let i = 0; i < data.length; i++) {
        let rowJson = {};
        this.linkage.forEach(element => {
          if (this.$util.isType(data[i][element.field])!== "Undefined") {
            rowJson[element.mbField] = data[i][element.field];
          }
        });
        if (data.length == 1) {
          return rowJson;
        }
        json.push(rowJson);
      }
      return json;
    },
    handleEmpty() {
      this.$refs.selectModal.Empty();
      this.handleOK(null);
    },
    widthSet() {
      if (this.width == "") {
        this.setwidth = { "min-width": "350px", width: "350px" };
        return { "min-width": "350px", width: "350px" };
      }
      if (typeof this.width == "number") {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      if (this.width.indexOf("%") == -1 && this.width.indexOf("px") == -1) {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      this.setwidth = "width:" + this.width;
      return "width:" + this.width;
    },
    filterSaveOk(data) {
      this.$emit("filterSave", data);
    },
    okValueOk(data) {
      this.$emit("okValue", data);
    },
    tableFocus() {
      this.$refs.materials.focus();
    },
    tableBlur() {
      this.$refs.materials.blur();
    }
  },
  watch: {
    width() {
      this.widthSet();
    },
    value(v, oldv) {
      if (!v || v === "" || v == 0) {
        this.departNames = "";
        this.departIds = "";
      }
      if (v != oldv) {
        this.$refs.selectModal.loadDepartValue(v);
      }
    },
    modifiedValue: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(val) {
        //console.log("监听ESupplierSelect值", val);
        if (val) {
          // 新值和老值互换显示
          this.thisOldValue = this.value;
          this.value = val;
          if (this.$refs.selectModal) {
            this.$refs.selectModal.loadDepartValue(this.thisOldValue, true);
          }
        }
      }
    },
    disabled(v) {
      this.thisDisabled = v;
    },
  }
};
</script>
<style scoped lang="less">
@import "../../../assets/style/base/controls/e-pop-up-select-input.less";
</style>
