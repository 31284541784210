<!-- 审批表单 -->
<template>
  <div>
    <!-- 授权操作按钮 -->
    <div v-if="showToolbar" class="form-headButton">
      <e-toolbar
        :buttons="buttons"
        :operationDisabled="operationDisabled"
        :size="$config.defaultControlSize"
        @click="onButtonClick"
        ref="eToolbar"
      ></e-toolbar>
    </div>
    <!-- 表单区域 -->
    <div
      :class="getTabFormContextClass"
      @scroll="formScroll"
      :style="isZoom ? { 'padding-top': '0' } : {}"
    >
      <a-form
        :form="form"
        :class="showToolbar ? 'form-data' : 'form-data-noHead'"
      >
        <e-form
          :dataId="dataId"
          :form="form"
          :tempId="tempId"
          :formConfigs="formConfigs"
          :title="title"
          :spState="nowShowContent"
          :tableData="mainTableData"
          :mxOprButtons="mxOprButtons"
          :fieldsAllowedEdit="fieldsAllowedEdit"
          :mxAllowedEditField="mxAllowedEditField"
          :mxReadOnlyFields="mxReadOnlyFields"
          :requiredField="requiredField"
          :hideFields="hideField"
          :editOver="formEditState"
          :disable="formDisable"
          :mxHhAuto="false"
          ref="formTemplate"
          @zoom="zoomState => (isZoom = zoomState)"
          @publicUploadChange="publicUploadChange"
        >
          <template v-slot:formHeadTitleExpend>
            <!-- 扩展插槽 -->
            <e-icon
              v-if="formHeadTitleExpend"
              style="color: red;font-size: 18px;cursor: unset;line-height: 15px;"
              type="icon-tishi"
            ></e-icon>
            <span style="color: red;">{{ formHeadTitleExpend }}</span>
          </template>
        </e-form>
      </a-form>
    </div>
    <!-- 其他功能区域 -->
    <!-- 审批意见确认窗 -->
    <e-form-modal
      v-model="spModalShow"
      :buttons="spButtons"
      formName="审批意见表"
      @buttonClicked="doFormSp"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">表单审批</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 提交审批确认窗 -->
    <e-form-modal
      v-model="tjModalShow"
      formName="提交审批"
      :initFormData="tjDefaultData"
      :showOk="true"
      @okClicked="doFormTj"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">提交审批</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 审批记录弹出框 -->
    <e-table-modal
      v-model="spjlModalShow"
      :tableConfig="spTableConfig"
      title="审批记录"
      width="900"
      :tableDatas="spTableDatas"
      :loading="spTableSpin"
    ></e-table-modal>
    <!-- 查看审批流程图 -->
    <view-image-modal
      v-model="showWorkFlowImage"
      :src="workflowImageSrc"
      title="流程图"
    ></view-image-modal>
    <!-- 明细数据选人弹出框 -->
    <e-table-modal
      v-if="
          gridImportTableConfig &&
          gridImportTableConfig.columns
      "
      redraw
      v-model="gridImportModalShow"
      :tableConfig="gridImportTableConfig"
      title="明细选入搜索"
      width="900"
      :tableDatas="gridImportTableDatas"
      :buttons="gridImportModalButtons"
      :loading="gridImportModalLading"
      :selectedKeys="gridImportTableSelectedKeys"
      @buttonClicked="onClickTableModal"
    ></e-table-modal>

    <!-- 明细数据选人弹出框"筛选"条件选择窗  v-if="gridImportSelectModalShow"-->
    <e-form-modal
      v-model="gridImportSelectModalShow"
      :tempId="gridImportSelectTempId"
      :showOk="true"
      @okClicked="onGridImportSelected"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">筛选条件</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 小票打印预览 -->
    <n-p58-print
      v-model="showNpPrint"
      :dataSource="printDetailData"
    ></n-p58-print>
    <!-- 系统打印控件 -->
    <systemPrintSet ref="systemPrintSet"></systemPrintSet>
    <!-- 公共上传控件 -->
    <EUpload
      ref="publicUpload"
      @change="publicUploadChangeValue"
      @fileListNumChange="publicUploadNumChange"
      @CanSealFile="CanSealFile"
      v-show="false"
      :auth="
        operaterFile
          ? operaterFile
          : { CanEditFile: true, CanRemoveFile: true, CanSealFile: true }
      "
    />
    <!-- 入库单-物资序列号配置管理弹窗 -->
    <e-configure-product-serial-modal
      v-model="serialConfigModal"
      redraw
      :products="serialConfigProducts"
      @okClicked="onSerialConfig"
    ></e-configure-product-serial-modal>
  </div>
</template>

<script>
import EForm from "@/components/EForm.vue";

import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; // 解析菜单配置的权限字符串方法
import { e1Form } from "@/views/form/mixins/e1Form.js"; // 表单公共方法
import { e1FormToolbarWorkFlow } from "@/views/form/mixins/e1FormToolbarWorkFlow.js"; // e1的审批操作方法
import { e1FormToolbarGridImport } from "@/views/form/mixins/e1FormToolbarGridImport.js"; // e1的明细选人操作方法
import { e1FormToolbarToAssociated } from "@/views/form/mixins/e1FormToolbarToAssociated.js"; // e1的业务关联表查询操作功能
import { e1FormToolbarSCGL } from "@/views/form/mixins/e1FormToolbarSCGL.js"; // e1的业务-生产管理相关操作功能
import { npPrint } from "@/views/form/mixins/NpPrint.js"; // 下票打印方法
import { CustomPrintMixins } from "@/views/form/mixins/CustomPrintMixins.js"; // 按钮方法
import { e1ConfigureProductSerial } from "@/views/form/mixins/e1ConfigureProductSerial.js"; // e1的业务-入库单序列号管理配置功能
import { Upload } from "@/views/form/mixins/Upload.js"; // 公共上传mixins

export default {
  mixins: [
    e1Form,
    analyticalMenuAuth,
    e1FormToolbarWorkFlow,
    e1FormToolbarGridImport,
    npPrint,
    CustomPrintMixins,
    e1FormToolbarToAssociated,
    e1FormToolbarSCGL,
    e1ConfigureProductSerial,
    Upload
  ],
  name: "ApprovalForm",
  components: {
    EForm
  },
  data() {
    return {
      formName: null, // 对应的表单名称

      dhbm: null, // 当前开页面关联权限参数的id
      bm: null,
      clzt: null, // 当前表单的处理状态
      bdbh: null, // 保存后的表单编号
      tempName: null, //表单模板名
      random: null,
      defaultFiled: "", // 默认从其他页面带人的传参字段
      defaultMainTableFiled: null, // 默认从其他页面带人的传参字段
      dataId: 0, //对应的表单id
      currentWorkflowName: null, // 当前表单的流程名称
      //data: data,
      buttons: [], // 当前用户可用的操作按钮
      operationDisabled: true, // 当前用户可用的操作按钮默认不可用
      fieldsAllowedEdit: null, // 可编辑的表单项，在formEditState =false 并且 formDisable=true时可用
      mxAllowedEditField: null, // 可编辑的明细表单项，在formEditState =false 并且 formDisable=true时可用
      mxReadOnlyFields: null, // 明细表只读字段
      requiredField: null, // 可编辑的表单项中必填项，包含主表单和明细表
      hideField: null, // 需要隐藏的字段，包含主表单和明细表的设置

      saveTempID: null, // 特殊表单，有时会指定保存使用的模板id
      is_temp_changed: false,// 特殊表单用于表示该表单模板变跟的状态

      mxOprButtons: [],
      isDelete: false,
      thisChildrenTablesDatas: null,
      formHeadTitleExpend: null,

      isZoom: false // 明细表进入最大化状态
    };
  },
  computed: {
    nowShowContent: function() {
      let title = this.checkIsSpecialMarkBD(this.bdbh);
      if (this.bdbh && title) return "（" + title + "）" + this.spState;
      else return this.spState;
    }
  },
  created() {
    //console.log("路由属性：" + JSON.stringify(this.$route.query)); // 从菜单直接带过来
    //console.log("ApprovalForm创建---------------------->");
    this.tempId = this.$route.query.templetId;
    this.dataId = this.$route.query.dataId;
    this.bm = this.$route.query.bm;
    this.clzt = this.$route.query.clzt;
    this.random = this.$route.query.random;
    if (this.tempId && this.dataId && this.bm) {
      //通过query传参
      this.formName = "approval-" + this.dataId + "-" + this.random;
      this.dhbm = this.$route.query.dhbm;
      this.tempName = this.$route.query.tempName;
      // 附加主表单默认数据的默认字段名称，有对应的this.defaultFiled+"_Name"参数将其值传入
      this.defaultFiled = this.$route.query.defaultFiled;
      // 如果参数中携带了默认字段值，则给主表赋予对应字段对应默认值
      this.defaultMainTableFiled = this.getDefaultFiledForQuery(
        this.defaultFiled
      );
    } else {
      // 页面内按钮点开、是通过query传参
      this.alertError("错误的用户配置");
      this.loadingError();
      return;
    }
    if (!this.dataId) this.formName = "add_" + this.formName;
    this.form = this.$form.createForm(this, { name: this.formName });
  },
  mounted() {
    //console.log("请求参数：" + JSON.stringify(this.$route.query));
    //console.log("创建表单："+this.$route.params.dhbm);
    /*console.log(
      "ApprovalForm mounted---------------------->",
      this.tempId,
      this.dataId
    );*/
    this.loading();
    if (this.tempId) {
      this.getFormTemplate();
    } else {
      this.alertError("错误的用户配置");
      this.loadingError();
    }
  },
  methods: {
    /**
     * 获取表单模板
     */
    getFormTemplate() {
      let that = this;
      this.getForm(
        "GetFormTemplateToWorkFlow",
        this.tempId,
        { id: this.dataId, dmbh: this.dhbm, clzt: this.clzt, tempName: this.tempName },
        function(data) {
          that.workflowTemplateInstance =
            data.template[0].WorkflowTemplateInstance; // 流程图id
          that.buttons = data.buttons;
          that.mxOprButtons = data.rowButtons;
          that.isDelete = data.IsDelete ? true : false;
          if (that.isDelete) {
            // 已作废
            that.fromState = 0;
            that.spState = that.getStateName(that.fromState);
          }
        },
        function() {
          if (that.thisFromEvents && that.thisFromEvents.afterInit) {
            that.thisFromEvents.afterInit(that);
          }
          // 模板获取成功后,需要去获取数据
          if (that.dataId)
            that.getFormData(that.tempId, that.dataId, that.bm, 900, function(
              data
            ) {
              let workFlows = data.workFlow;
              //console.log("获取到表单操作状态：" , workFlows);
              // 缓存明细数据
              that.allChildrenTablesDatas = data.childrenDatas;
              if (!that.isDelete && workFlows) {
                that.currentWorkflowName = workFlows.CurrentWorkflowName;
                that.spButtons = that.getSpButtons(workFlows.operation);
                that.bdbh = that.mainTableData._BDBH;
                // 根据返回的表单处理状态获取fromState、formEditState、formDisable属性
                that.setFormState(workFlows.workFlowState);
                // 根据表单流程状态-控制某些按钮状态
                if (that.fromState > 1) {
                  // 非草稿状态,进入流程中
                  if (that.fromState == 2 || that.fromState == 4) {
                    // 流转中，待提交或待处理
                    // 需要审批操作
                    that.updateEToolbarButtonDisplay(
                      ["tsbDoWorkFlow", "tsbWorkFlowRecord"],
                      true
                    );
                    //待处理状态下，根据流程配置的可编辑项，设置属性
                    if (
                      that.fromState == 4 &&
                      (workFlows.EditableField || workFlows.MustInputField)
                    ) {
                      // 有可编辑项
                      if (workFlows.EditableField) {
                        console.log(
                          "有可以编辑的字段：",
                          workFlows.EditableField
                        );
                        if (
                          workFlows.EditableField.mainTableField &&
                          workFlows.EditableField.mainTableField.length > 0
                        ) {
                          that.fieldsAllowedEdit =
                            workFlows.EditableField.mainTableField;
                        }
                        if (
                          workFlows.EditableField.chrenTableField &&
                          that.$util.isValidObject(
                            workFlows.EditableField.chrenTableField
                          )
                        ) {
                          that.mxAllowedEditField =
                            workFlows.EditableField.chrenTableField;
                        }
                      }
                      // 如果有设置必填字段
                      if (workFlows.MustInputField)
                        that.requiredField = workFlows.MustInputField;
                    }
                  } else if (that.fromState == 3 || that.fromState == 5) {
                    // 已提交或已处理
                    // 可以查看审批记录
                    that.updateEToolbarButtonDisplay("tsbWorkFlowRecord", true);
                  }

                  // 如果表单状态是编辑完毕，或者非草稿和待提交状态下
                  if (that.formEditState && that.fromState > 2) {
                    // 不能编辑或无可操作项 隐藏保存,编辑，明细选人按钮
                    that.updateEToolbarButtonDisplay(
                      ["tsbEdit", "tsbSave", "tsbGridImport"],
                      false
                    );
                    that.mxOprButtons = [];
                  }
                  // 如果明细表的操作按钮没有，则应该将选人明细按钮也隐藏
                  if (!(!!that.mxOprButtons && that.mxOprButtons.length > 0)) {
                    that.updateEToolbarButtonDisplay("tsbGridImport", false);
                  }
                } else if (that.fromState == 1) {
                  // 草稿状态，则显示提交流程按钮
                  let mc = that.mainTableData._BDMC
                    ? that.mainTableData._BDMC
                    : that.title;
                  let zt = mc + "_" + that.bdbh;
                  that.updatePageData("tjDefaultData", "_zt", zt);
                  that.updateEToolbarButtonDisplay("tsbWorkFlowCreater", true);
                }
                // 如果配置了针对“系统自动生成”表单全局的设置项,或者返回流程设值了不可编辑项-workFlows.nonEditableFields
                // 在可编辑状态下
                if (
                  !that.formEditState &&
                  (that.mainTableData._ZDSC ||
                    (workFlows.nonEditableFields &&
                      workFlows.nonEditableFields.length > 0))
                ) {
                  let nonEditableFields = that.formConfig.nonEditableFields;
                  if (
                    workFlows.nonEditableFields &&
                    workFlows.nonEditableFields.length > 0
                  ) {
                    if (nonEditableFields) {
                      nonEditableFields = that.$util.mergeArray(
                        nonEditableFields,
                        workFlows.nonEditableFields
                      );
                    } else {
                      nonEditableFields = workFlows.nonEditableFields;
                    }
                  }

                  let formTagConfig = that.getFormTagConfig(
                    nonEditableFields,
                    that.formConfig.hiddenButtons
                  );
                  // 明细表配置
                  if (that.mxTableName && that.mxTableName.length > 0) {
                    if (
                      that.mxOprButtons &&
                      that.mxOprButtons.length > 0 &&
                      formTagConfig.detailHiddenButtons
                    ) {
                      // 如果配置了明细表隐藏按钮 ??? 这个按钮隐藏配置在了模板里？？
                      // 从明细表对应操作按钮中移除同名的按钮
                      for (let xx = 0; xx < that.mxTableName.length; xx++) {
                        if (
                          formTagConfig.detailHiddenButtons[
                            that.mxTableName[xx]
                          ]
                        ) {
                          let hiddenButtons =
                            formTagConfig.detailHiddenButtons[
                              that.mxTableName[xx]
                            ];
                          hiddenButtons.forEach(function(bName) {
                            let oprButtonIndex = that.mxOprButtons.findIndex(
                              function(b) {
                                return b.name === bName;
                              }
                            );
                            if (oprButtonIndex >= 0)
                              that.mxOprButtons.splice(oprButtonIndex, 1);
                          });
                        }
                      }
                    }
                    if (formTagConfig.detailReadOnlyFields) {
                      // 如果配置了明细表不可编辑字段
                      that.mxReadOnlyFields =
                        formTagConfig.detailReadOnlyFields;
                    }
                  }
                  // 主表单设置
                  if (
                    formTagConfig.mainReadOnlyFields &&
                    formTagConfig.mainReadOnlyFields.length > 0
                  ) {
                    // 如果设置主表单只读字段
                    for (
                      let yy = 0;
                      yy < formTagConfig.mainReadOnlyFields.length;
                      yy++
                    ) {
                      let controlObject = that.$util.getObjByParamAndValue(
                        that.formConfigs.formRows,
                        "fieldName",
                        formTagConfig.mainReadOnlyFields[yy]
                      );
                      if (controlObject) controlObject.disabled = true;
                      //console.log("需要设置为不可编辑的字段",controlObject);
                    }
                  }
                }
                // 检测是否设置了可见范围,
                that.checkMainVisScopeTakeEffect(that.formConfigs.formRows);
                that.checkMxVisScopeTakeEffect(that.formConfigs);
              }
              //通知列表中的表单打开，是否默认显示流程处理记录
              if(that.clzt === "通知" && that.$config.NOTICE_FORM_SHOW_WORK_FLOWRECORD) {
                that.updateEToolbarButtonDisplay("tsbWorkFlowRecord", true);
              }

              // 调用扩展功能，数据加载前调用的回调函数。
              if (that.thisFromEvents && that.thisFromEvents.beforeRenderData) {
                that.thisFromEvents.beforeRenderData(that); //, that.mainTableData, that.allChildrenTablesDatas
              }
              that.loadingOk();
            });
          else {
            // 调用扩展功能，数据加载前调用的回调函数。
            if (that.thisFromEvents && that.thisFromEvents.beforeRenderData) {
              that.thisFromEvents.beforeRenderData(that);
            }
            that.loadingOk();
          }
        }
      );
    },
    /**
     * 点击“新建”按钮，在tab打开一个新的本表单
     */
    tsbOpenNew() {
      let query = {
        dhbm: this.dhbm,
        templetId: this.tempId,
        type: "approvalOpenNew"
      };
      // 附带信息
      if (this.defaultFiled) {
        query.defaultFiled = this.defaultFiled;
        let defaultFiledValue = this.defaultMainTableFiled[this.defaultFiled];
        if (this.$util.isType(defaultFiledValue) === "Object") {
          query[this.defaultFiled] = defaultFiledValue.value;
          query[this.defaultFiled + "_Name"] = defaultFiledValue.label;
        } else {
          query[this.defaultFiled] = defaultFiledValue;
        }
      }
      this.openTab({
        title: "新增-" + this.title,
        path: "/NewForm",
        query: query
      });
    },
    /**
     * 点击“删除”按钮，删除当前表单
     * @param button
     * @param other
     */
    tsbDelete(button, other) {
      //console.log("删除", button);
      if (this.dataId) {
        if (other && other.prioriCondition) {
          // 满足前置条件还需要确认是否操作
          this.$refs.eToolbar.showConfirm(button.tsbName);
        } else {
          let that = this;
          let params = {
            action: "DeleteData",
            tb_name: that.mainTableName, //主表名
            mxb_name: that.saveMxTableNames.toString(), // 明细表名称
            bm: button.param.bm,
            t_id: this.tempId, // 模板id  从DHCS中获取
            d_id: this.dataId, // 插入之后返回的主键id
            randomKey: this.$store.getters.getRandomKey
          };
          this.startProcessing(button);
          this.post("Form.ashx", params, {
            success: function() {
              //console.log("请求结果", req);
              // 把对应删除的数据从数组中移除
              that.finishedProcessing(button);
              that.closeTab(that.random);
              that.alertSuccess("删除成功");
            },
            error: function(err) {
              that.finishedProcessing(button);
              that.alertError(err.message);
            }
          });
        }
      }
    },
    /**
     * 点击保存按钮
     * @param button
     */
    tsbSave(button) {
      if (!this.dataId) {
        this.alertWarning("表单ID不存在");
        return;
      }
      let that = this;
      this.$refs.formTemplate.validateFields((err, values) => {
        if (!err) {
          //console.log("数据信息：" + JSON.stringify(values));
          let saveParam = that.checkOperationAuth(
            that.buttons,
            "tsbSave",
            () => {
              that.alertError("您没有保存表单的权限");
            }
          );
          if (saveParam) {
            if (
              !that.saveConditionCheck(
                that.formConfig.saveCondition,
                values,
                that.getMxTableData()
              )
            ) {
              that.alertError(that.formConfig.hintCotent);
              return;
            }
            // 缓存明细数据
            that.allChildrenTablesDatas = that.getMxTableData();
            // 保存前检测，明细是否满足非空
            let errMessage = that.checkDetailRequired(
              that.formConfig.mxTableRequiredConfig,
              that.allChildrenTablesDatas
            );
            if (errMessage) {
              that.alertError(errMessage);
              return;
            }
            that.startProcessing(button);
            let params = {
              bm: saveParam.param.bm, // 类库名称（SYSTEM）、从GJLCS中获取 {tsbNew,SYSTEM,1,980}
              et_name: saveParam.param.et_name //  表示操作类型  添加为0，修改为1，删除为2 等，从GJLCS中获取 {tsbNew,SYSTEM,1,980}
            };

            that.formSaveService(values, params, button, function(data) {
              if (data.dataId) {
                that.mainTableData = values;
                let mc = that.mainTableData._BDMC
                  ? that.mainTableData._BDMC
                  : that.title;

                //that.tjDefaultData._zt = mc + "_" + that.bdbh;
                let zt = mc + "_" + that.bdbh;
                that.updatePageData("tjDefaultData", "_zt", zt);
                // 草稿状态
                if (
                  that.spState === "（草稿）" &&
                  data.workFlow &&
                  data.workFlow.isWorkFlow
                ) {
                  that.updateEToolbarButtonDisplay("tsbWorkFlowCreater", true);
                } else if(data.workFlow){ //非草稿
                  //获取下一步流程信息
                  that.spButtons = that.getSpButtons(data.workFlow.operation);
                }
                // 调用扩展功能，表单提交成功后调用的函数。
                if (that.thisFromEvents && that.thisFromEvents.afterSave) {
                  that.thisFromEvents.afterSave(that, values);
                }
              }
            });
          }
        } else {
          //console.log( JSON.stringify(err));
          let errMsg = that.getDecoratorErrInfo(err);
          that.tipWarning(errMsg);
        }
      });
    },
    /**
     * 通用的保存数据接口调用方法
     */
    formSaveService(values, data, button, callBack) {
      let params = {
        action: "SaveFormData",
        data: JSON.stringify(values), // 主表数据
        children: this.getMxDataJSON(), // 子表数据
        tb_name: this.mainTableName, // 主表名称 从DHCS中获取
        mxb_name: this.saveMxTableNames.toString(), // 明细表名称
        t_id: this.tempId, // 模板id  从DHCS中获取
        d_id: this.dataId, // 插入之后返回的主键id
        m_id: 0, // 保存时标识关联字段名
        randomKey: this.$store.getters.getRandomKey
      };
      if (data) {
        params = Object.assign(params, data);
      }
      let that = this;
      // 保存数据的方法
      let doSave = function(params1, button1) {
        that.post("Form.ashx", params1, {
          success: function(req) {
            //console.log("请求结果", req.data.isWorkFlow);
            callBack(req.data);
            that.tipSuccess("保存成功");
            that.finishedProcessing(button1);
          },
          error: function(e) {
            console.log("请求失败", e);
            that.alertError(e.message);
            that.finishedProcessing(button1);
          }
        });
      };
      // 判断是否配置了提交前的扩展功能
      this.exeSaveFromEvents(values, params, button, doSave);
    },
    /**
     * 打开一张新的审批表单
     * @param data
     */
    openOtherApprovalForm(data) {
      this.openTab({
        title: "编辑-" + (data.SJZT ? data.SJZT : data.SJID),
        path: "/ApprovalForm",
        query: {
          dhbm: this.dhbm,
          templetId: data.BDMBID, // 表单模板ID
          dataId: data.SJID, // 表单数据id
          bm: data.SJJZFS, // 表单对应BM表
          clzt: data.CLZT // 表单状态
        }
      });
    },
    /**
     * 跳转到表单修改申请表
     * @param button
     */
    tsbEditApplay(button) {
      let mc = this.mainTableData._BDMC ? this.mainTableData._BDMC : this.title;
      this.openTab({
        title: "表单修改申请-" + mc,
        path: "/DataModifyApprovalForm",
        query: {
          dhbm: this.dhbm,
          spFromTempId: this.tempId, // 表单模板ID
          templetId: button.param.approvalTempId,
          dataId: this.dataId, // 表单数据id
          type: "modifyApprovalForm",
          bm: this.bm // 表单对应BM表
        }
      });
      this.closeTab(this.random);
    },
    /**
     * 弹窗查看关联单据
     */
    tabRelated(button) {
      console.log("查看关联单据", button);
      if (button.param && button.param.tempId && button.param.dataId) {
        if(this.KeepAccountsFormTempIds.includes(button.param.tempId)) {
          this.openTab({
            title: "查看-" + button.param.tempCode,
            path: "/KeepAccountsForm",
            query: {
              dhbm: this.dmbh,
              templetId: button.param.tempId, // 表单模板ID
              dataId: button.param.dataId, // 表单数据id
              detailFiledName: "_BDBH", // 表单用于tab的title的表单字段名
              type: "dateSearchView",
              bm: button.param.bm // 表单对应BM表
            }
          });
        } else { //其他普通表单查看
          let rule = this.checkTextMeetASNGenerateRule(button.param.tempCode);
          if(rule) {
            let title = "查看-" + button.param.tempCode;
            this.openTab({
              title: title,
              path: "/DetailForm",
              query: {
                dataCode: button.param.tempCode, // 表单数据编号
                tableName: rule.tableName // 表单用于tab的title的表单字段名
              }
            });
          } else {
            this.alertError("关联数据表单编码未加入编码格式配置");
          }
        }
      } else {
        this.alertError("未获取到表单关联数据");
      }
    },
    /**
     * 监听表单滚动条，如果滚动做一些操作
     */
    formScroll() {
      this.$refs.formTemplate.filterSelectBlur();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/approval-form.less";
</style>
