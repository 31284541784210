/**
 * 支持E1系统的notice数据管理的配置，及操作方法
 * @type {{data(): *, created(), methods: {doLink(*=): void, doLinkZt(*=): void, doAction(*): void}}}
 */
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求

export const e1Notice = {
  mixins: [requestControls, pageControls],
  inject: ["updateLeftMenuAllCount"],
  data() {
    let that = this;
    return {
      haveSFCKType: ["251", "255"],
      thisType: null,
      tableConfig: {
        // 表格样式定义
        rowKey: this.defaultTableKeyName,
        columns: [
          {
            title: "主题",
            field: "SJZT",
            minWidth: 250,
            sortable: true, // 列是否可以排序
            remoteSort: true, // 去后端接口排序
            scopedSlots: {
              type: "hyperlink",
              clickMethod: this.doLinkZt
            }
          },
          {
            title: "表单类型",
            field: "MBDBT",
            minWidth: 150,
            sortable: true, // 列是否可以排序
            remoteSort: true, // 去后端接口排序
            align: "center"
          },
          {
            title: "类型",
            field: "GSLB",
            minWidth: 150,
            sortable: true, // 列是否可以排序
            remoteSort: true, // 去后端接口排序
            align: "center",
            scopedSlots: {
              type: "hyperlink",
              clickMethod: this.doLinkZt
            }
          },
          {
            title: "发送人",
            className: "column-money",
            field: "FSRMC",
            minWidth: 170,
            sortable: true, // 列是否可以排序
            remoteSort: true // 去后端接口排序
          },
          {
            title: "发送时间",
            minWidth: 170,
            field: "FSSJ",
            sortable: true, // 列是否可以排序
            remoteSort: true, // 去后端接口排序
            formatter: function(text) {
              if (text) return that.$util.changeTimeFormat(text.cellValue);
              else return "";
            }
          },
          {
            title: "制单人",
            field: "ZDRMC",
            sortable: true, // 列是否可以排序
            remoteSort: true, // 去后端接口排序
            minWidth: 170
          },
          {
            title: "制单时间",
            minWidth: 170,
            field: "ZDRQ",
            sortable: true, // 列是否可以排序
            remoteSort: true, // 去后端接口排序
            formatter: function(text) {
              if (text) return that.$util.changeTimeFormat(text.cellValue);
              else return "";
            }
          }
        ]
      },
      tableDatas: [], // 表格数据
      tableSpin: false, //表格加载状态
      orderBy: null
    };
  },
  created() {
    //console.log("混入e1Notice的通用方法");
  },
  methods: {
    /**
     * 守护路由跳转后，返回
     */
    afterEach() {
      console.log("守护路由afterEach", this.thisType);
    },
    /**
     * 获取 表格数据的接口方法
     * @param type
     * @param button 由操作按钮触发查询
     * @param oEvent
     * @param pageNumber
     * @param pageSize
     */
    getTableDatas(
      type,
      button,
      oEvent,
      pageNumber,
      pageSize,
      searchStr,
      searchField,
      orderBy
    ) {
      //console.log("获取notice类型数据：", type);
      let that = this;
      this.tableSpin = true;
      this.operationDisabled = true;
      if (button) button.loading = true;
      let params = {
        action: "GetNotices",
        DHBM: type,
        o_Event: oEvent,
        pageNumber: pageNumber,
        pageSize: pageSize,
        randomKey: this.$store.getters.getRandomKey,
        searchStr: searchStr,
        searchField: searchField,
        orderBy: orderBy
      };
      this.get("WorkFlow.ashx", params, {
        success: function(req) {
          //console.log(req);
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? req.data:req;
          let dataList = reqData.data;
          let totalCount = reqData.TotalCount;
          dataList.map(function(data, index) {
            // 给数组对象设置一个主键字段用于表格选择
            return (data[that.tableConfig.rowKey] = index);
          });
          that.tableDatas = dataList;
          if (reqData.Unread) {
            // 修改未处理数量
            that.updateLeftMenuAllCount(type, reqData.Unread);
          } else {
            that.updateLeftMenuAllCount(type, totalCount);
          }
          //console.log("获取的数据：", that.tableDatas);
          let total = totalCount;
          if (!pageNumber) {
            pageNumber = that.pagination.current;
          }
          if (!pageSize) {
            pageSize = that.pagination.pageSize;
          }
          if (pageNumber && pageSize && pageNumber != 1) {
            let pages = total / pageSize;
            let totalPage = 0;
            if (pages > parseInt(pages)) {
              totalPage = parseInt(pages) + 1;
            } else {
              totalPage = parseInt(pages);
            }
            if (pageNumber > totalPage) {
              that.pagination.current = totalPage;
            }
          }
          that.pagination.total = total;
          that.tableSpin = false;
          that.operationDisabled = false;
          that.thisType = type;
          if (button) button.loading = false;
        },
        error: function(err) {
          console.error(err);
          that.pagination.current = 1;
          that.pagination.total = 0;
          that.tableSpin = false;
          that.operationDisabled = false;
          if (button) button.loading = false;
          that.alertError("请求失败，获取表数据失败");
        }
      });
    },
    /**
     * 点击了主题链接
     * @param e
     */
    doLinkZt(e) {
      console.log("点击了主题链接", e);
      let linkExtendMath = this.getViewLinkExtends_Mixin("doLinkZt");
      if(linkExtendMath) {
        if(!linkExtendMath(e, this)) {
          return;
        }
      }
      let data = e.record;
      //console.log("获取到的数据", shuj);
      if (data) {
        let approvalFormConfig = null;
        let formDataConfig = this.$store.state.systemConfigs.FormDataConfig;
        if (formDataConfig && formDataConfig.length > 0) {
          approvalFormConfig = formDataConfig.find(function(d) {
            return (
              d.approvalFormTemp != undefined && d.approvalFormTemp != null
            );
          });
        }
        //this.$refs.tableRef.closeTooltip();
        if (
          approvalFormConfig != null &&
          approvalFormConfig.approvalFormTemp == data.BDMBID
        ) {
          // 表单修改审批申请单
          this.openTab({
            title:
              "处理" + this.title + "-" + (data.SJZT ? data.SJZT : data.SJID),
            path: "/DataModifyApprovalForm",
            query: {
              dhbm: this.thisType,
              templetId: data.BDMBID,
              spFormDataId: data.SJID, // 表单数据id
              type: "modifyApprovalForm",
              clzt: data.GSLB ? data.GSLB : this.title, // 表单状态
              bm: data.SJJZFS // 表单对应BM表
            }
          });
        } else {
          // 其他普通表单
          this.openTab({
            title:
              "处理" + this.title + "-" + (data.SJZT ? data.SJZT : data.SJID),
            path: !this.KeepAccountsFormTempIds.includes(data.BDMBID) ? "/ApprovalForm" : "/KeepAccountsForm", //特别定制，填制凭证对应的表单采用KeepAccountsForm页面
            query: {
              dhbm: this.thisType,
              templetId: data.BDMBID, // 表单模板ID
              dataId: data.SJID, // 表单数据id
              bm: data.SJJZFS, // 表单对应BM表
              clzt: this.title, // 表单状态
              tempName: this.title === "草稿" ? data.GSLB : data.MBDBT
            }
          });
        }
        if (data.SFCK == null) {
          let that = this;
          setTimeout(function() {
            that.getTableDatas(
              that.noticeType,
              null,
              that.oEvent,
              that.pagination.current,
              that.pagination.pageSize,
              that.searchStr,
              that.searchField
            );
          }, 600);
        }
      }
    }
  }
};
