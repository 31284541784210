const setObj = [
  {
    title: "备选变量",
    data: []
  },
  {
    title: "备选操作符",
    data: [
      {
        title: "(",
        key: "("
      },
      {
        title: ")",
        key: ")"
      },
      {
        title: "+",
        key: "+"
      },
      {
        title: "-",
        key: "-"
      },
      {
        title: "*",
        key: "*"
      },
      {
        title: "/",
        key: "/"
      },
      {
        title: "%",
        key: "%"
      }
    ]
  }
];

function getOption() {
  return [...setObj];
}

export { getOption };
