//菜单对应打开的操作授权信息解析方法
// 日期时间控件
var EDatetimePicker = {
  size: {
    w: 162,
    h: 21,
    labelw: 53
  },
  configs: {
    fieldName: "",
    required: false,
    defaultValue: ""
  },
  mode: "date",
  format: "YYYY年M月D日",
  thisControlType: "EDatetimePicker",
  label: ""
};
// 文本输入控件
var EIput = {
  size: {
    w: 162,
    h: 21,
    labelw: 53
  },
  setting: {},
  configs: {
    fieldName: "",
    required: false,
    defaultValue: ""
  },
  thisControlType: "EInput",
  label: ""
};
//下拉选项控件
var ESelect = {
  size: {
    w: 185,
    h: 20,
    labelw: 53
  },
  configs: {
    fieldName: "",
    multiSelect: false,
  },
  disable: false,
  enabled: true,
  listFilter: null,
  defaultSelectedIndex: 0,
  setting: {
    name: "ESelect",
    action: "GetXLdata"
  },
  thisControlType: "ESelect",
  label: "",
};
//物资选择器
var EMaterials = {
  size: {
    w: 187,
    h: 22,
    labelw: 53
  },
  configs: {
    fieldName: "",
    multiSelect: false
  },
  filter: "ID not in (14,16,17,18)",
  keyType: "BM",
  disable: false,
  setting: {
    name: "EMaterials",
    treeAction: "GetWZFLList",
    nodeAction: "GetWZInfo"
  },
  thisControlType: "EMaterials",
  label: "",
};
export const analyticalMenuAuth = {
  data() {
    return {
      colTypeConfigs_mixin: [
        "T", //文本查询
        "IN", //整型数字查询
        "FN", //浮点型数字查询
        "INR", //整型数字范围查询
        "FNR", //浮点型数字范围查询
        "D", //日期查询
        "DR", //日期范围查询
        "DT", //日期时间查询
        "DTR", //日期时间范围查询
        "B" //布尔型查询
      ],
      colAlignTypes_mixin: ["left","center","right"],
      colTypeToInputType: {
        "T": "text",
        "IN": "number",
        "FN": "number",
      },
      //明细表定义的编辑类型控件对应的表单控件
      columnControlTypeToFormControlType: {
        "CxsGridDateTimeColumn":"EDatetimePicker",
        "CxsGridTextBoxColumn":"EInput",
        "CxsGridTexBoxButtonColumn": {
          "Materials":"EMaterials",
        },
        "CxsGridComboBoxColumn":"ESelect",
      }
    };
  },
  created() {
    //console.log("混入analyticalMenuAuth的通用方法");
  },
  methods: {
    /**
     * 从data对象中找到值为编码规则字符串的值，将满足编码规则内包含由{}括起来的字段名的编码找出，
     * 并将对应的字段值从表单中找出并替换到改编码规则字符串中。
     * 例如：包含值如：[[{this._GXZH}]+[{this._GXXH}]+SN:000000]，则可以使用本方法进行变量注入
     * @param data
     * @param mainData
     * @param detailData
     */
    objectFieldValueCodeInjectionData(data, mainData, detailData) {
      let regex = /\[(\[\{\S+\}\]\+)?([Y,M,D]\+)*(SN:(0+)([0-9]*))\]/;
      let regexField = /\{[^\{]+\}/g;
      for (let key in data) {
        let value = data[key];
        if (this.$util.isType(value) === "String") {
          regex.lastIndex = 0;
          if (regex.test(value)) {
            // 有需要注入数据的编码字段
            let fileds = value.match(regexField);
            if (fileds) {
              // 找到了是注入数据的字符串
              fileds.forEach(function(fc) {
                let detailV = null;
                let fd = fc.slice(1, -1);
                if (fd.includes("this.")) {
                  // 是明细数据字段
                  if (detailData) {
                    detailV = detailData[fd.split(".")[1]];
                  }
                } else {
                  // 是主表数据字段
                  if (mainData) {
                    detailV = mainData[fd];
                  }
                }
                if (detailV) {
                  value = value.replace(fc, detailV);
                } else {
                  value = value.replace(fc, "");
                }
              });
              data[key] = value;
            }
          }
        }
      }
    },
    /**
     * 将明细表格的列定义包含的可编辑控件转换为表单可用控件
     * @param column
     * @returns {*}
     */
    columnControllChangeToFormControll(column) {
      let control = null;
      if(column && column.controlType) {
        let controltype = this.columnControlTypeToFormControlType[column.controlType];
        if(controltype) {
          if(this.$util.isType(controltype)==="String") {
            if(controltype === "EInput") {
              control = this.$util.deepCopy(EIput);
            } else if(controltype === "ESelect") {
              control = this.$util.deepCopy(ESelect);
              control.listFilter = this.$util.deepCopy(column.listFilter);
            } else if(controltype === "EDatetimePicker") {
              control = this.$util.deepCopy(EDatetimePicker);
              control.mode = "date";
              control.format = "YYYY年M月D日";
            } else {
              control = this.$util.deepCopy(EIput);
            }
          } else if(this.$util.isType(controltype)==="Object" && column.editorFormType){
            let buttonSelectType = controltype[column.editorFormType];
            if(buttonSelectType) {
              if(buttonSelectType === "EMaterials") {
                control = this.$util.deepCopy(EMaterials);
              } else {
                return null;
              }
            } else {
              return null;
            }
          }
          control.configs.fieldName = column.fieldName;
          control.label = column.title;
        }
      }
      return control;
    },
    /**
     * 获取对应操作名称的权限数据，有则返回对象，否则返回null
     * @param thisConfig
     * @param tsbName
     * @param callback
     * @returns {*}
     */
    checkOperationAuth(thisConfig, tsbName, callback) {
      let buttons = [];
      if (
        thisConfig &&
        thisConfig.operationConfig &&
        thisConfig.operationConfig.operation &&
        thisConfig.operationConfig.operation.length > 0
      ) {
        buttons = thisConfig.operationConfig.operation;
      } else if (thisConfig && this.$util.isType(thisConfig) === "Array") {
        buttons = thisConfig;
      }
      if (buttons && buttons.length > 0) {
        let that = this;
        let saveParam = buttons.find(function(v) {
          if (that.$util.isType(v.tsbName) === "String")
            return v.tsbName === tsbName;
          else if (that.$util.isType(v.tsbName) === "Array") {
            return v.tsbName.indexOf(tsbName) >= 0;
          } else return false;
        });
        if (!saveParam) {
          if (callback && this.$util.isType(callback) === "Function")
            callback();
          else this.$error("您没有操作权限");
          return null;
        }
        if (that.$util.isType(saveParam.tsbName) === "String") return saveParam;
        else {
          let index = saveParam.tsbName.indexOf(tsbName);
          let newParam = saveParam.param[index];
          return {
            tsbName: tsbName,
            param: newParam
          };
        }
      } else {
        if (callback && this.$util.isType(callback) === "Function") callback();
        else this.$error("您没有操作权限");
        return null;
      }
    },
    /**
     * 从cols配置中解析出表格字段定义
     * @param cols
     * @returns {Array}
     */
    analyticalCols(cols) {
      if (cols && this.$util.isType(cols) === "String" && cols !== "") {
        cols = cols.slice(1);
        cols = cols.slice(0, -1);
        let allCol = cols.split("|");
        let colsObjs = this.analyticalColsArray(allCol);
        return colsObjs;
      }
      return [];
    },
    /**
     * 根据配置是否需要显示测试功能列
     */
    insertTestFunctionColum_Minxin(colums) {
      // 是否需要显示测试功能列
      if (window.SHOW_TEST_FUNCTION) {
        colums.splice(0, 0, {
          title: "调试功能",
          dataIndex: "testOperation",
          key: "testOperation",
          scopedSlots: {
            customRender: "testOperation",
            type: "button",
            button: {
              icon: "export",
              title: "发给用友",
              loading: false
            }
          },
          align: "center",
          width: 160
        });
      }
    },
    /**
     * 用于atable的列表字段解析,
     * 注：只支持表格模板中使用vxe-table-column组件进行渲染的方式
     * @param allCol
     * @returns {*|Array}
     */
    analyticalATableColsArray(allCol, isTreeList, groupBy, linkMethod) {
      return this.analyticalColsArray(
        allCol,
        isTreeList,
        groupBy,
        linkMethod,
        true
      );
    },
    /**
     * 把表格定义数组进行解析
     * 注：只支持表格模板中使用vxe-table-column组件进行渲染的方式
     * @param allCol
     * @param isTreeList
     * @param groupBy
     * @param linkMethod
     * @param isATable 是否是ATable插件如果是，则会使用showTitle属性
     * @returns {Array}
     */
    analyticalColsArray(allCol, isTreeList, groupBy, linkMethod, isATable) {
      let colsObjs = [];
      // 默认第一列增加选择复选框列
      colsObjs.push({
        type: "checkbox",
        dataIndex: "operation",
        field: "operation",
        align: "center",
        resizable: false,
        width: 60
      });
      if (allCol && this.$util.isType(allCol) === "Array") {
        for (let i = 0; i < allCol.length; i++) {
          let colArray = allCol[i].split(":");
          let width = colArray[2] ? parseInt(colArray[2]) + 48 : undefined;
          if (width && isTreeList) {
            // 如果是树状列表，则树状列宽需要调整
            if (colArray[1] === groupBy) {
              width += 72;
            }
          }
          let cObj = {
            title: colArray[0],
            dataIndex: colArray[1],
            field: colArray[1],
            minWidth: width,
            key: colArray[1],
            showOverflow: true,
            sortable: true, // 列是否可以排序
            remoteSort: true, // 去后端接口排序
            scopedSlots: {
              customRender: colArray[1],
              showTitle: isATable ? true : false
            }
          };
          if (isATable) {
            cObj.width = width;
          }
          if (
            colArray[0].indexOf("数量") >= 0 ||
            colArray[0].indexOf("金额") >= 0 ||
            colArray[0].indexOf("日期") >= 0 ||
            colArray[0].indexOf("时间") >= 0
          ) {
            cObj.scopedSlots.showTitle = false;
            cObj.showOverflow = "ellipsis";
          } else if (colArray[0].indexOf("单号") >= 0) {
            cObj.showOverflow = "ellipsis";
          }

          // 如果指定了超过3个以上的参数，则使用如下方法进行解析
          let setClickMath = false; //是否给列指定了点击链接方法
          if (colArray.length > 3) {
            for (let i = 3; i < colArray.length; i++) {
              if (
                this.analyticalColsAfterTheThirdIndex(
                  colArray[i],
                  cObj,
                  isATable
                )
              ) {
                setClickMath = true;
              }
            }
          }
          // 检测列标题是否是全局定义的点击“单号”类型
          if (!setClickMath && linkMethod && this.checkTitleRule(colArray[0])) {
            cObj.scopedSlots.type = "hyperlink";
            cObj.scopedSlots.clickMethod = linkMethod;
            cObj.scopedSlots.textCheckMethod = this.checkTextMeetASNGenerateRule; // 内容检验函数
          }
          colsObjs.push(cObj);
        }
      }
      return colsObjs;
    },
    /**
     * 获取对应的分组字段
     * @param groupBy
     * @return {*}
     */
    getGroupFileds(groupBy) {
      if (groupBy) {
        if (this.$util.isType(groupBy) === "String") {
          // 只是在allCol中指定一个字段作为treeNode
          return groupBy;
        } else if (this.$util.isType(groupBy) === "Array") {
          // 指定了用于分组的多个字段,则用最后一个字段作为treeNode
          let fileds = "";
          groupBy.forEach(function(f) {
            if (fileds === "") {
              fileds += f.split(":")[1];
            } else {
              fileds += "," + f.split(":")[1];
            }
          });
          return fileds;
        }
      }
    },

    /**
     * 把表格定义数组进行解析
     * 注：支持表格模板中通过columns属性设置表格列定义的方式
     * @param allCol 其他主要显示字段
     * @param {String, Array} groupBy 树形分组字段
     * @param nextFloorGroupBy
     * @param nextFloorKeyField
     * @returns {Array}
     */
    analyticalColsArrayVxeTable(
      allCol,
      groupBy,
      nextFloorGroupBy,
      nextFloorKeyField
    ) {
      let colsObjs = [];
      // 默认第一列增加选择复选框列
      colsObjs.push({
        type: "checkbox",
        dataIndex: "operation",
        field: this.rowKey,
        align: "center",
        resizable: false,
        width: 60,
        fixed: "left"
      });
      let groupFiled = null;
      let nowCol = null;
      let fixedFileds = [];
      if (groupBy) {
        if (this.$util.isType(groupBy) === "String") {
          // 只是在allCol中指定一个字段作为treeNode
          groupFiled = groupBy;
          fixedFileds.push(groupFiled);
        } else if (this.$util.isType(groupBy) === "Array") {
          // 指定了用于分组的多个字段,则用最后一个字段作为treeNode
          let lastField = groupBy[groupBy.length - 1];
          // 将分组字段和子字段合并用于显示
          if (allCol && this.$util.isType(allCol) === "Array") {
            nowCol = [].concat(groupBy, allCol);
          } else {
            nowCol = [].concat(groupBy);
          }
          groupFiled = lastField.split(":")[1];
          groupBy.forEach(function(f) {
            fixedFileds.push(f.split(":")[1]);
          });
        }
      }
      if (nextFloorGroupBy && nextFloorKeyField) {
        if (fixedFileds.includes(nextFloorKeyField)) {
          this.groupToNextFloor = true; // 需要分组内字段再分组
        } else {
          this.groupToNextFloor = false;
        }
      }
      if (!nowCol && allCol && this.$util.isType(allCol) === "Array") {
        nowCol = [].concat(allCol);
      }
      if (nowCol && nowCol.length > 0) {
        for (let i = 0; i < nowCol.length; i++) {
          let colArray = nowCol[i].split(":");
          let cObj = {
            title: colArray[0],
            dataIndex: colArray[1],
            field: colArray[1],
            minWidth: width,
            key: colArray[1],
            showOverflow: true,
            sortable: true, // 列是否可以排序
            sortBy: "asc",
            remoteSort: true, // 去后端接口排序
            scopedSlots: {
              customRender: colArray[1],
              showTitle: false
            }
          };
          let width = colArray[2] ? parseInt(colArray[2]) + 48 : undefined;
          if (colArray[1] === groupFiled) {
            // 如果是树状列表，
            if (width) {
              //则树状列宽需要调整
              width += 72;
              if (nextFloorGroupBy && nextFloorKeyField) {
                width += 72;
              }
            }
            cObj.treeNode = true;
          }
          /*if(nextFloorGroupBy && nextFloorKeyField && this.groupToNextFloor && colArray[1] === nextFloorKeyField) { // 需要再次分层
              if (width) { //则树状列宽需要调整
                width += 72;
              }
              cObj.treeNode = true;
            }
          }*/
          if (fixedFileds.includes(colArray[1])) {
            cObj.fixed = "left"; // 固定列
          }
          cObj.minWidth = width;
          if (
            colArray[0].includes("数量") ||
            colArray[0].includes("金额") ||
            colArray[0].includes("日期") ||
            colArray[0].includes("时间") ||
            colArray[0].includes("单号")
          ) {
            cObj.showOverflow = "ellipsis";
            cObj.cellRender = {
              name: "e1ColumnDefault"
            };
          }
          // 如果指定了超过3个以上的参数，则使用如下方法进行解析
          let setClickMath = false; //是否给列指定了点击链接方法
          if (colArray.length > 3) {
            for (let i = 3; i < colArray.length; i++) {
              if (this.analyticalColsAfterTheThirdIndex(colArray[i], cObj)) {
                setClickMath = true;
              }
            }
          }
          // 检测列标题是否是全局定义的点击“单号”类型
          if (!setClickMath && this.checkTitleRule(colArray[0])) {
            cObj.cellRender = {
              name: "e1ColumnLink",
              events: {
                click: this.columClick
              }
            };
            cObj.cellRender.clickMethod = "showByRule";
            cObj.cellRender.textCheckMethod = this.checkTextMeetASNGenerateRule; // 内容检验函数
            // scopedSlots只用于自定义vxe-table-column表格列
            cObj.scopedSlots.type = "hyperlink";
            cObj.scopedSlots.clickMethod = "showByRule";
            cObj.scopedSlots.textCheckMethod = this.checkTextMeetASNGenerateRule; // 内容检验函数
          }
          colsObjs.push(cObj);
        }
      }
      return colsObjs;
    },
    /**
     * 根据列定义，第4位开始的参数，进行分别解析
     * @param colConfig
     * @param cObj
     */
    analyticalColsAfterTheThirdIndex: function(colConfig, cObj, isAtable) {
      colConfig = this.$util.trimStr(colConfig);
      if (colConfig && colConfig !== "") {
        // 如果包含3位以上的配置
        if (this.colTypeConfigs_mixin.includes(colConfig)) {
          // 该定义是定义检索类型
          cObj.searchType = colConfig;
          cObj.scopedSlots.dataType = this.colTypeToInputType[colConfig];
        } else if ("IsSum" === colConfig) {
          // 该列是合计列
          cObj.isSum = true;
        } else if ("Files" === colConfig) {
          // 该列是文件列
          cObj.scopedSlots = {
            leftIcon: text => {
              if (text && text !== "") return "folder";
              //<a-icon type="folder" />
              else return null;
            },
            iconBadge: text => {
              if (text && text !== "") {
                try {
                  let files = JSON.parse(text);
                  return files.length;
                } catch (e) {
                  console.error("错误的文件数组", e);
                }
              }
              return 0;
            },
            customRenderText: text => {
              if (text && text !== "") return null;
              else return "无";
            },
            clickMethod: "showFiles"
          };
          cObj.slots = { default: cObj.field };
        } else if(this.colAlignTypes_mixin.includes(colConfig.toLowerCase())) {
          // 该定义是排版方式
          cObj.align = colConfig.toLowerCase();
        } else if("editable" === colConfig.toLowerCase()) {
          // 该列可编辑
          cObj.scopedSlots.type = "input";
        } else {
          let rule = this.checkOpenRules(colConfig); // 检测参数是否是指定点击方法
          if (rule) {
            if (isAtable) {
              cObj.cellRender = {
                name: "e1ColumnLink",
                events: {
                  click: this.columClick
                }
              };
              cObj.cellRender = Object.assign(rule, cObj.cellRender);
            }
            // scopedSlots只用于自定义vxe-table-column表格列
            cObj.scopedSlots.type = "hyperlink";
            cObj.scopedSlots = Object.assign(rule, cObj.scopedSlots);
            return true;
          }
        }
      }
      return false;
    },
    /**
     * title字符串是否包含“单号”
     * @param title
     * @returns {boolean}
     */
    checkTitleRule: function(title) {
      return (
        title &&
        (title.includes("单号") ||
          (title.includes("编号") && title.includes("单")))
      );
    },
    /**
     * 检测字符串配置，是否满足校验条件，如果满足返回解析结果
     * @param str
     * @returns {null}
     */
    checkOpenRules(str) {
      if (str && this.$util.isType(str) === "String") {
        let methodStr = this.$util.trimStr(str);
        let ruleObj = this.checkTextMeetASNGenerateRule(methodStr);
        //console.log("检测结果，",ruleObj);
        if (ruleObj) {
          let index1 = methodStr.indexOf("(");
          if (index1 > 0) {
            let tempStr = methodStr.substring(index1 + 1, methodStr.length - 1);
            if (tempStr.includes(",", 1)) {
              let variableField = tempStr.split(",");
              if (variableField.length >= 2) {
                let rule = {};
                rule.selectField = this.$util.trimStr(variableField[0]);
                rule.valueField = this.$util.trimStr(variableField[1]);
                rule.tableName = ruleObj.tableName;
                rule.title = ruleObj.tableChineseName;
                rule.dhbm = ruleObj.dhbm;
                rule.bm = ruleObj.bm;
                rule.clickMethod = methodStr.substring(0, index1);
                if (variableField.length > 2) {
                  if (
                    variableField.length > 3 &&
                    (variableField[2] === "" || variableField[2] === " ")
                  ) {
                    rule.splitSign = ",";
                  } else rule.splitSign = variableField[2]; // 分割符标记，内容需要分割显示
                }
                return rule;
              }
            }
          }
        }
      }
      return null;
    },
    /**
     * 根据dhbm获取对应页面的页面配置
     * @param dhbm
     * @returns {null}
     */
    getPageConfig(dhbm) {
      return this.$util.getObjByParamAndValue(
        this.$store.state.menuList,
        "DHBM",
        dhbm
      );
    },
    /**
     * 分析Jurisdiction字段，获取操作按钮配置
     * @param thisConfig
     * @returns {Array}
     */
    getUserJurisdictionButtons(thisConfig) {
      if (
        thisConfig &&
        thisConfig.operationConfig &&
        thisConfig.operationConfig.operation
      ) {
        console.log("解析按钮", thisConfig.operationConfig.operation);
        if (
          thisConfig.operationConfig.operation &&
          thisConfig.operationConfig.operation.length > 1
        ) {
          let tsbTemplatePrint = thisConfig.operationConfig.operation.find(
            function(b) {
              return b.tsbName === "tsbTemplatePrint";
            }
          );
          if (tsbTemplatePrint)
            tsbTemplatePrint.menuList = ["直接打印", "打印预览"];
        }
        return thisConfig.operationConfig.operation;
      }
      return [];
    },
    /**
     * 从操作按钮中过滤出明细操作按钮
     * @param operations
     * @returns {Array}
     */
    getMxOprButtons(operations) {
      let mxOprs = [];
      if (operations && this.$util.isType(operations) === "Array") {
        for (let j = 0; j < operations.length; j++) {
          let param = operations[j];
          if (param.type && param.type === "mxOpr") {
            mxOprs.push(param);
          }
        }
        console.log("明细操作按钮", JSON.stringify(mxOprs));
        return mxOprs;
      }
      return mxOprs;
    },
    /**
     * 分析operation数组，获取操作按钮配置
     * @param thisConfig
     * @returns {Array}
     */
    getUserJurisdictionButtonsFromArray(operation) {
      if (operation && this.$util.isType(operation) === "Array") {
        console.log("解析按钮", operation);
        return operation;
      }
      return [];
    },
    /**
     * 获取审批操作按钮的配置
     * @param operation
     * @returns {*}
     */
    getSpButtons(operation) {
      if (operation && this.$util.isType(operation) === "Array") {
        let tempButton = operation;
        for (let i = 0; i < tempButton.length; i++) {
          tempButton[i].loading = false;
          tempButton[i].returnData = true;
        }
        /*tempButton.push({
          title: "取消",
          key: "cancel",
          ghost: true
        });*/
        return tempButton;
      } else {
        let tempButton = [
          {
            title: "取消",
            key: "cancel"
          }
        ];
        return tempButton;
      }
    },
    /**
     * 从“明细导入”按钮配置中获取弹出表格样式
     * @param button
     * @returns {*}
     */
    getGridImportTableConfig(button) {
      let visableTsb = ["tsbGridImport", "tsbTopTable", "tsbBottomTable"];
      if (button && visableTsb.indexOf(button.tsbName) >= 0) {
        if (button.tempConfig) {
          let cols = button.tempConfig.cols;
          if (cols && cols.length > 0) {
            let isTreeList = false;
            let groupBy = button.tempConfig.groupBy
              ? button.tempConfig.groupBy
              : button.tempConfig.groupby;
            if (groupBy) {
              // 查询出的数据是树形结构
              isTreeList = true;
            }
            // 是表格
            cols = this.analyticalATableColsArray(cols, isTreeList, groupBy);
            if (button.tempConfig.selectCheckCondition) {
              if (button.tempConfig.selectCheckCondition.checkError) {
                button.tempConfig.selectCheckCondition.checkError = this.$util.xml_decode(
                  button.tempConfig.selectCheckCondition.checkError
                );
              }
            }
            return {
              tableName: button.tempConfig.table, // 非标准附加属性,表名
              templateId: button.tempConfig.template, // 非标准附加属性，对应的数据模板id
              businessMode: button.tempConfig.bm, //非标准附加属性，对应的数据bm
              checked: true, // 显示选择框
              rowKey: this.defaultTableKeyName,
              columns: cols,
              type: button.tempConfig.type,
              groupBy: button.tempConfig.groupBy,
              groupby: button.tempConfig.groupby,
              configType: button.tempConfig.configType,
              detailForeignKey: button.tempConfig.detailForeignKey,
              selectCheckCondition: button.tempConfig.selectCheckCondition,
              selectUniqueConstraints:
                button.tempConfig.selectUniqueConstraints,
              coveragePattern: this.$util.strIsTrue(
                button.tempConfig.coveragePattern
              ),
              mxTable: button.tempConfig.newTable
                ? button.tempConfig.newTable
                : button.tempConfig.tableName
            };
          } else {
            // 非表格
            return {
              tableName: button.tempConfig.table, // 非标准附加属性,表名
              businessMode: button.tempConfig.bm, //非标准附加属性，对应的数据bm
              templateId: button.tempConfig.templateId, // 非标准附加属性，对应的数据模板id
              type: button.tempConfig.type,
              mxTable: button.tempConfig.newTable,
              coveragePattern: this.$util.strIsTrue(
                button.tempConfig.coveragePattern
              )
            };
          }
        }
      }
      return null;
    }
  }
};
