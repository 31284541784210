<template>
  <div class="rdl-report-view__box">
    <a-spin
      :spinning="!iframLoad"
      tip="页面加载中..."
      size="large"
      class="report-spin"
    >
    </a-spin>
    <!-- 报表视图 -->
    <iframe
      v-show="iframLoad"
      :id="'iframe' + redom"
      class="rdl-view"
      :src="src"
      :style="{ width: thisWidth }"
      onerror="alert('报表加载失败')"
    ></iframe>
  </div>
</template>

<script>
import { pageControls } from "@/mixins/pageControls.js"; // 引入一些界面操作方法
export default {
  mixins: [pageControls],
  name: "RdlReportView",
  data() {
    return {
      src: "",
      config: null,
      iframLoad: false,
      redom: new Date().getTime(),
      dataId: null
      // buttons: []
    };
  },
  props: {
    pageConfig: {
      type: Object
    }
  },
  computed: {
    size: function() {
      return this.getWindowSize();
    },
    thisWidth: function() {
      return this.size.width + "px";
    },
    thisHeight: function() {
      return this.size.height + "px";
    }
  },
  created() {
    this.config = this.pageConfig;
    // this.dataId = this.$route.query.dataId;
    if (!this.config) {
      this.config = {
        viewConfig: {
          name: this.$route.params.rdlName.replace(/!@!/g, "/"),
          dataId: this.$route.params.dataId
        }
      };
    }
    console.log("this.config.viewConfig.name", this.config.viewConfig.name);
    let src =
      window.SERVER_REPORT +
      "?rdlName=" +
      this.config.viewConfig.name +
      "&dataId=" +
      this.config.viewConfig.dataId;
    //this.src = encodeURI(src);
    this.src = src;
    if (window.REPORTVIEW_OPEN_NEW_TAG) {
      window.open(this.src);
      this.closeTab(this.config.DHBM);
    }
  },
  mounted() {
    let that = this;
    // 监听ifram加载状态，加载成功，则显示iframe并隐藏加载状态
    setTimeout(function() {
      that.$nextTick(function() {
        let iframe = document.getElementById("iframe" + that.redom);
        if (iframe) {
          if (iframe.attachEvent) {
            iframe.attachEvent("onload", function() {
              // IE
              that.iframLoad = true;
            });
          } else {
            iframe.onload = function() {
              // 非IE
              that.iframLoad = true;
            };
          }
        }
      });
    }, 200);
  },
  watch: {},
  methods: {}
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/rdl-report-view.less";
</style>
