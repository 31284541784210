<template>
  <div class="clearfix">
    <a-button
      shape="circle"
      icon="upload"
      @click="visible = true"
      :size="$config.defaultControlSize"
      ref="uploadButton"
      :disabled="fileList.length === 0 && (readOnly || disabled)"
    />
    <span>
      <!-- {{
        uploading
          ? "上传中..."
          : fileList.length > 0
          ? fileList[0]["name"] + "等" + fileList.length.toString() + "个文件"
          : "请选择文件"
      }}</span-->
      {{ showContent }}
    </span>
    <template>
      <a-modal
        title="文件上传"
        :visible="visible"
        wrapClassName="upload-modal"
        @cancel="visible = false"
      >
        <template slot="footer">
          <!-- <a-button key="back" @click="visible = false">关闭</a-button> -->
          <a-button
            @click="handleUpload"
            :disabled="fileList.length === 0 || readOnly || !auth.CanEditFile"
            v-show="!disabled"
            :loading="uploading"
            :style="{ 'margin-top': '0px' }"
          >
            <a-icon type="upload" />
            {{ uploading ? "上传中" : "开始上传" }}
          </a-button>
        </template>
        <!-- {{ fileList }},
        {{ setValue }}-->
        <div class="clearfix">
          <a-upload
            :fileList="fileList"
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
            :disabled="readOnly"
            :accept="accept"
            @change="change"
            @preview="download"
          >
            <a-button v-show="!disabled" :disabled="!auth.CanEditFile">
              <a-icon type="plus" />选择文件
            </a-button>
          </a-upload>
        </div>
        <a-modal
          :visible="previewVisible"
          :footer="null"
          @cancel="() => (previewVisible = false)"
        >
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
      </a-modal>
    </template>
  </div>
</template>
<script>
import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法

// import _ from "lodash";
export default {
  name: "EInput",
  mixins: [requestControls],
  data() {
    return {
      fileList: [],
      uploading: false,
      setValue: [],
      thisSetting: {
        serverName: "Form.ashx"
      },
      visible: false,
      previewVisible: false,
      previewImage: null
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: [String, Array, null]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String
    },
    setting: {
      type: Object,
      default: () => ({
        action: "Upload"
      })
    },
    returnArray: {
      type: Boolean,
      default: false
    },
    isMx: {
      type: null,
      default: null
    },
    auth: {
      type: Object,
      default: function() {
        return {
          CanEditFile: true,
          CanRemoveFile: true,
          CanSealFile: true
        };
      }
    }
  },
  created() {
    if (this.setting && !this.setting.serverName) {
      this.thisSetting = Object.assign(this.thisSetting, this.setting);
    } else {
      this.thisSetting = this.setting;
    }
    this.defaultList();
  },
  computed: {
    showContent() {
      var _this = this;
      if (this.disabled || this.readOnly) {
        if (okFileNum()[0] > 0) {
          return "已上传" + okFileNum()[1] + "等" + okFileNum()[0] + "个文件";
        }
        return "暂无文件";
      }
      if (this.uploading) {
        return "上传中...";
      }
      if (okFileNum()[0] > 0) {
        return "已上传" + okFileNum()[1] + "等" + okFileNum()[0] + "个文件";
      } else {
        return "请选择文件上传";
      }

      function okFileNum() {
        var num = 0;
        var firstName = "";
        _this.fileList.forEach(element => {
          if (element.status && element.status === "done") {
            if (firstName == "") {
              firstName = element.name;
            }
            num += 1;
          }
        });
        return [num, firstName];
      }
    }
  },
  methods: {
    // 一开始根据路劲值生成文件树
    defaultList(v) {
      var imgExt = new Array("png", "jpg", "jpeg", "bmp", "gif");
      var data;
      if (v) {
        data = v;
      } else {
        data = this.value;
      }

      var nameArray = [];
      var urlArray = [];
      var isJson = true;
      if (data == null || data == "" || data.length == 0) {
        this.fileList = [];
        return;
      } else {
        try {
          // 检查是否是json
          data = JSON.parse(data);
        } catch (err) {
          err;
          isJson = false;
        }
      }
      if (isJson) {
        data.forEach(element => {
          var name = element.n;
          nameArray.push(name);
          urlArray.push(element.p);
        });
      } else {
        data = data.split(",");
        data.forEach(element => {
          var name = element.split("/");
          name = name[name.length - 1];
          nameArray.push(name);
          urlArray.push(element);
        });
      }
      // data = data.split(",");
      var newList = [];
      for (var i = 0; i < nameArray.length; i++) {
        var json = {
          name: nameArray[i],
          url: urlArray[i],
          status: "done",
          uid: i
        };
        var fileExtension = nameArray[i].substring(
          nameArray[i].lastIndexOf(".") + 1
        );
        if (imgExt.indexOf(fileExtension) != -1) {
          json["type"] = "image";
        } else {
          json["type"] = "other";
        }
        newList.push(json);
      }
      this.fileList = newList;
    },
    // 下载，预览
    download(fileObj) {
      if (fileObj.type.indexOf("image") != -1) {
        if (fileObj.url != "" && fileObj.url != null) {
          this.previewImage = window.UPLOAD_URL + fileObj.url;
          this.previewVisible = true;
          return;
        }
      }
      if (fileObj.url != "" && fileObj.url != null) {
        window.open(window.UPLOAD_URL + fileObj.url);
      }
    },

    // 移除文件，会从树状态删除，不会删除服务器上的文件
    handleRemove(file) {
      if (!this.auth.CanRemoveFile) {
        this.$message.error("此流程不允许删除附件");
        return;
      }
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.valueSubmit();
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    // 新加文件时，给文件新加wait标识用来上传,因为不是自动上传，在控件逻辑之外，除了新加不会触发change事件
    change(file) {
      var index = this.fileList.findIndex(function(item) {
        return item.uid === file.file.uid;
      });
      if (index != -1) {
        var data = file.file;
        data["status"] = "wait";
        // data["nameStr"] = file.file["name"];
        // data["sizeStr"] = file.file["size"];
        this.fileList[index] = data;
      }
      // var fileObj
    },
    // 文件上传逻辑
    handleUpload() {
      // var data = this.thisSetting;
      var _this = this;
      var uid = [];
      const { fileList } = this;
      const formData = new FormData();
      // 只有wait 的状态  和 error 状态才能再上传，并记录uid
      fileList.forEach(file => {
        if (file.status == "wait" || file.status == "error") {
          formData.append("files[]", file);
          uid.push(file.uid);
        }
      });
      // uid 为空，则表示没有需要上传的文件
      if (uid == "") {
        _this.$message.error("没有文件需要上传");
        return;
      }
      this.uploading = true;
      let params = {
        action: "UploadFile"
      };
      this.upLoad("Form.ashx", formData, params, {
        success: function(res) {
          if (res.success) {
            // _this.fileList = [];
            _this.uploading = false;
            _this.$message.success("上传成功");
            // 上传成功，更改文件的状态
            uid.forEach((element, index) => {
              var item = _this.fileList.find(function(data) {
                return data.uid === element;
              });
              if (item) {
                item.status = "done";
                item.url = res.data[index].p;
              }
            });

            // 生成成功值
            _this.valueSubmit();
          } else {
            _this.uploading = false;
            _this.$message.error("上传失败." + res.message);
            uid.forEach(element => {
              var item = _this.fileList.find(function(data) {
                return data.uid === element;
              });
              if (item) {
                item.status = "error";
              }
            });
          }
        },
        error: function(res) {
          _this.uploading = false;
          _this.$message.error("上传失败." + res);
          uid.forEach(element => {
            var item = _this.fileList.find(function(data) {
              return data.uid === element;
            });
            if (item) {
              item.status = "error";
            }
          });
        }
      });
    },
    // 将上传成功的文件生成为路径数组，传出
    valueSubmit() {
      var values = [];
      this.fileList.forEach(file => {
        if (file.status != "wait" && file.status != "error") {
          values.push({ n: file.name, p: file.url, s: file.size });
        }
      });
      this.setValue = JSON.stringify(values);
      this.$emit("change", this.returnArray ? values : this.setValue);
    },
    tableFocus() {
      this.$refs.uploadButton.focus();
    },
    tableBlur() {
      this.$refs.uploadButton.blur();
    }
  },
  watch: {
    setValue(value) {
      this.$emit("change", value);
    },
    width() {
      this.widthSet();
    },
    disabled() {},
    isMx(v, oldv) {
      if (v !== oldv) {
        this.defaultList();
      }
    },
    fileList: {
      deep: true,
      handler: function(value) {
        this.$emit("fileListNumChange", value.length);
      }
    },
    auth: {
      deep: true,
      handler: function(value, oldv) {
        // 处理是否可见
        if (value.CanSealFile || value.CanSealFile == false) {
          if (value != oldv) {
            this.$emit("CanSealFile", value.CanSealFile);
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .upload-modal .ant-btn-primary {
  // display: none;
}
</style>
