<template>
  <div v-show="visible" :style="setwidth" class="e-from-eSupplierSelect__box">
    <a-tooltip :title="departNames" arrow-point-at-center>
      <a-input
        @click="openModal"
        placeholder="请点击选择供应商"
        v-model="departNames"
        :size="controlSize"
        readonly
        :disabled="thisDisabled"
      >
        <e-icon slot="prefix" type="plus" title="供应商选择控件" :disabled="thisDisabled"/>
        <a-icon
          v-if="departIds != '' && !thisDisabled && !readOnly"
          slot="suffix"
          type="close-circle"
          theme="filled"
          @click.stop="handleEmpty"
          title="清空"
          :style="{ color: 'rgba(0, 0, 0, 0.25)' }"
        ></a-icon>
      </a-input>
    </a-tooltip>
    <ESupplierSelectTree
      v-if="show"
      ref="selectModal"
      :mode="mode"
      @ok="handleOK"
      :value="value"
      :defaultValue="defaultValue"
      :bdmbId="bdmbId"
      :setting="thisSetting"
      :keyType="keyType"
      :filterSave="filterSave"
      @filterSave="filterSaveOk"
      :okValue="okValue"
      @okValue="okValueOk"
      :filter="filter"
      @getChinese="
        (chinese, value) => {
          debugger;
          thisOldValue = chinese;
        }
      "
    ></ESupplierSelectTree>
    <!-- 控件后附带的注释提醒 -->
    <li v-if="thisOldValue" class="form_right_icon">
      <a-tooltip
        placement="topRight"
        :title="'原值：' + thisOldValue"
        arrow-point-at-center
      >
        <a style="height: auto;">
          <e-icon type="exclamation-circle"></e-icon>
        </a>
      </a-tooltip>
    </li>
  </div>
</template>

<script>
import ESupplierSelectTree from "./ESupplierSelectTree.vue";
export default {
  name: "ESupplierSelect",
  data() {
    return {
      setvalue: this.value,
      controlSize: this.$config.defaultControlSize,
      departIds: "",
      departNames: "",
      setwidth: this.widthSet(),
      thisSetting: {
        serverName: "Form.ashx"
      },
      show: true,
      thisOldValue: null,
      thisDisabled: false
    };
  },
  components: {
    ESupplierSelectTree
  },
  model: {
    prop: "value",
    event: "change",
    type: Array
  },
  props: {
    defaultValue: {
      type: [String, Array]
    },
    value: {
      type: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: [Array, null],
      default: null
    },
    mode: {
      type: String,
      default: "single"
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    formula: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: ""
    },
    bdmbId: {
      type: [String, Number]
    },
    setting: {
      type: [Object],
      default: () => ({
        treeAction: "GetGYSLX",
        nodeAction: "GetSuppliers"
      })
    },
    linkage: {
      type: Array,
      default: () => []
    },
    keyType: {
      type: String,
      default: ""
    },
    filter: {
      type: String,
      default: ""
    },
    filterSave: {
      type: null
    },
    okValue: {
      type: Array,
      default: function() {
        return [];
      }
    },
    modifiedValue: {
      type: [String, Number],
      default: null
    }
  },
  created() {
    console.log("linkage:", this.linkage);
    this.thisDisabled = this.disabled;
    if (this.setting && !this.setting.serverName) {
      this.thisSetting = Object.assign(this.thisSetting, this.setting);
    } else {
      this.thisSetting = this.setting;
    }
  },
  mounted() {
    if (this.thisOldValue) {
      this.$refs.selectModal.loadDepartValue(this.thisOldValue, true);
    }
  },
  computed: {},
  methods: {
    /**
     * 设置禁用状态
     * @param v
     */
    setDisabled(v) {
      if(this.$util.isType(v) === "Boolean") {
        this.thisDisabled = v;
      }else {
        this.thisDisabled = true;
      }
    },
    /**
     * 切换当前控件的禁用状态
     */
    setDisabledSwitch() {
      this.thisDisabled = !this.thisDisabled;
    },
    openModal() {
      if (!this.readOnly) {
        this.$refs.selectModal.show();
      }
    },
    handleOK(rows, idstr) {
      this.departNames = "";
      this.departIds = "";
      this.setvalue = "";
      if (!rows) {
        this.departNames = "";
        this.departIds = "";
        this.setvalue = "";
      } else if (rows.length == 1) {
        let temp = "";
        for (let item of rows) {
          temp = item.title;
          this.setvalue = item.key;
        }
        this.departNames = temp;
        this.departNames == "" || this.departNames == null
          ? (this.departIds = "")
          : (this.departIds = idstr);
      } else if (rows.length > 1) {
        let temp = "";
        let values = "";
        for (let item of rows) {
          temp += "," + item.title;
          values += "," + item.key;
        }
        this.departNames = temp.substring(1);
        this.setvalue = values.substring(1);
        this.departNames == "" || this.departNames == null
          ? (this.departIds = "")
          : (this.departIds = idstr);
      }
      if (rows) {
        this.$emit("linkedChange", this.linkedChange(rows));
      }
      this.$emit("change", this.setvalue, this.departNames);
    },
    linkedChange(data) {
      // {mbField:field}
      var json = {};
      this.linkage.forEach(element => {
        if (this.$util.isType(data[0][element.field])!== "Undefined") {
          json[element.mbField] = data[0][element.field];
        }
      });
      //console.log("控件linkedChange=", json);
      return json;
    },
    handleEmpty() {
      this.$refs.selectModal.Empty();
      this.handleOK(null);
    },
    widthSet() {
      if (this.width == "") {
        this.setwidth = "";
        return "";
      }
      if (typeof this.width == "number") {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      if (this.width.indexOf("%") == -1 && this.width.indexOf("px") == -1) {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      this.setwidth = "width:" + this.width;
      return "width:" + this.width;
    },
    filterSaveOk(data) {
      this.$emit("filterSave", data);
    },
    okValueOk(data) {
      this.$emit("okValue", data);
    }
  },
  watch: {
    width() {
      this.widthSet();
    },
    value(v, oldv) {
      if (v != oldv) {
        this.$refs.selectModal.loadDepartValue(v);
      }
    },
    modifiedValue: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(val) {
        //console.log("监听ESupplierSelect值", val);
        if (val) {
          // 新值和老值互换显示
          this.thisOldValue = this.value;
          this.value = val;
          if (this.$refs.selectModal) {
            this.$refs.selectModal.loadDepartValue(this.thisOldValue, true);
          }
        }
      }
    },
    disabled(v) {
      this.thisDisabled = v;
    },
  }
};
</script>

<style scoped lang="less">
@import "../../../assets/style/base/controls/e-pop-up-select-input.less";
</style>
