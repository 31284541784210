<template>
  <a-checkbox-group
    v-if="option.length > 1"
    v-model="setValue"
    :defaultValue="defaultValue"
    :value="setValue"
    :size="controlSize"
    :disabled="thisDisabled"
    :options="options"
    v-show="visible"
  ></a-checkbox-group>
  <a-checkbox
    :checked="setValue2"
    :defaultValue="defaultValue"
    :disabled="thisDisabled"
    v-show="visible || option[0].visible"
    v-else
    v-model="setValue2"
    ><span>{{ option[0].label }}</span>
  </a-checkbox>
</template>

<script>
export default {
  name: "ECheckbox",
  data() {
    return {
      controlSize: this.$config.defaultControlSize,
      setValue: this.value,
      setValue2: null,
      option: this.options,
      thisDisabled: false
    };
  },
  model: {
    prop: "value",
    event: "change",
    type: Array
  },
  props: {
    defaultValue: {
      type: [String, null]
    },
    value: {
      type: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.thisDisabled = this.disabled;
    if (this.option.length <= 1) {
      if (
        this.value === "true" ||
        this.value === "True" ||
        this.value === "TRUE" ||
        this.value == 1
      ) {
        this.setValue2 = true;
      } else if (
        this.value === "false" ||
        this.value === "False" ||
        this.value === "FALSE" ||
        this.value === "" ||
        this.value == 0 ||
        this.value == undefined
      ) {
        this.setValue2 = false;
      } else {
        if (this.option.findIndex(item => item.value === this.value[0]) != -1) {
          this.setValue2 = true;
        } else {
          this.setValue2 = false;
        }
        // this.setValue2 = this.value;
      }
    }
  },
  computed: {},
  methods: {
    /**
     * ���ý���״̬
     * @param v
     */
    setDisabled(v) {
      if(this.$util.isType(v) === "Boolean") {
        this.thisDisabled = v;
      }else {
        this.thisDisabled = true;
      }
    },
    /**
     * �л���ǰ�ؼ��Ľ���״̬
     */
    setDisabledSwitch() {
      this.thisDisabled = !this.thisDisabled;
    },
  },
  watch: {
    setValue(value) {
      this.$emit("change", value);
    },
    setValue2(value) {
      this.$emit("change", value);
    },
    value(value) {
      if (
        this.value === "true" ||
        this.value === "True" ||
        this.value === "TRUE" ||
        this.value == 1
      ) {
        this.setValue = true;
      } else if (
        this.value === "false" ||
        this.value === "False" ||
        this.value === "FALSE" ||
        this.value === "" ||
        this.value == 0 ||
        this.value == undefined ||
        (value === "" && this.option.length <= 1)
      ) {
        this.setValue = false;
      } else {
        this.setValue = value;
      }
    },
    disabled(v) {
      this.thisDisabled = v;
    },
  }
};
</script>
