<!--主框架 -->
<template>
  <a-locale-provider :locale="zhCN">
    <a-layout id="components-layout-custom">
      <!-- 全局的消息内容 -->
      <a-alert
        v-if="menuList && isShowAlert"
        class="index-content-alert__position"
        :message="alertMessage"
        :type="alertType"
        closable
        @close="onAlertClose"
        show-icon
      >
        <template slot="description">
          <div
            v-html="alertDescription"
            class="index-content-alert__alertDescription"
          ></div>
        </template>
      </a-alert>
      <a-layout-header v-if="!newTheme" class="index-layout-header"
        ><!-- 头部 -->
        <index-head @clickToMenu="clickMenu"></index-head>
      </a-layout-header>
      <a-layout>
        <a-layout-sider
          :trigger="null"
          collapsible
          v-model="collapsed"
          class="layout-sider_leftMenu"
          :style="{ width: showMeunWidth + 'px' }"
        >
          <div class="logo" v-if="newTheme">
            <img
              :class="
                collapsed ? 'index-head-small_logoImg' : 'index-head_logoImg'
              "
              :src="'/img/' + systemLogo + '.png'"
              alt="嵌嵌信息"
            />
          </div>
          <div class="layout_leftMenu">
            <!-- 侧边菜单导航-->
            <a-spin
              :spinning="!menuList"
              tip="菜单加载中..."
              size="large"
              class="menu-spin"
            >
            </a-spin>
            <index-left-sidebar
                                v-show="!!menuList"
              @clickMenu="clickMenu"
              :menuList="menuList"
              :openKey="openKeys"
              :selected="showTagkey"
              v-model="collapsed"
            ></index-left-sidebar>
          </div>
        </a-layout-sider>
        <a-layout
          class="index-content-layout"
          :style="{ width: tabSlipWidth, display: 'block' }"
        >
          <!-- 左侧菜单宽度拖拽变宽控件 -->
          <vue-draggable-resizable
            v-if="canDrag"
            class="menu-draggable-handle"
            :style="{ left: showMeunWidth + 'px' }"
            :parent="true"
            :prevent-deactivation="true"
            :active="true"
            :draggable="true"
            :resizable="false"
            :disable-user-select="true"
            :x="showMeunWidth"
            :y="64"
            :z="1000"
            :grid="[5, 5]"
            axis="x"
            :onDragStart="checkDrag"
            @dragging="onDrag"
            @dragstop="onDragstop"
          ></vue-draggable-resizable>
          <!-- 页面内容  -->
          <a-layout-content class="index-content">
            <!-- 内容 @tabClick="onTabClick"-->
            <div class="tabs-content">
              <a-tabs
                hideAdd
                v-model="showTagkey"
                type="editable-card"
                :size="$config.defaultControlSize"
                class="tab-slip"
                :style="{
                  width: tabSlipWidth,
                  left: showMeunWidth + 'px'
                }"
                @edit="onTabEdit"
              >
                <!-- tabs更多操作项 -->
                <template slot="tabBarExtraContent">
                  <div>
                    <!-- 刷新当前tab -->
                    <div v-if="newTheme" style="display: inline-block;">
                      <span
                        class="ant-tabs-tab-extraContent ant-tabs-tab-extraContent__borderLeft"
                        @click="
                          () => {
                            onTabsMoreClick({ key: 'refresh' });
                          }
                        "
                      >
                        <e-icon
                          type="icon-shuaxin"
                          iClass="trigger"
                          :iStyle="{
                            color: 'inherit'
                          }"
                        />
                      </span>
                    </div>
                    <!-- 更多操作 -->
                    <div style="display: inline-block;">
                      <a-dropdown
                        :trigger="['click']"
                        overlayClassName="tabs-content__extra"
                      >
                        <span
                          class="ant-tabs-tab-arrow-show ant-tabs-tab-extraContent"
                        >
                          <e-icon
                            type="icon-more"
                            iClass="trigger"
                            :iStyle="{
                              color: 'inherit'
                            }"
                          />
                        </span>
                        <a-menu slot="overlay" @click="onTabsMoreClick">
                          <a-menu-item
                            v-if="!newTheme"
                            key="refresh"
                            :disabled="showTagkey === '_0'"
                          >
                            <e-icon type="icon-refresh" />
                            刷新当前标签页
                          </a-menu-item>
                          <a-menu-item
                            key="closeLeft"
                            :disabled="tabsMoreMenuState.closeLeft"
                          >
                            <e-icon type="icon-closeLeft" />
                            关闭当前左侧所有标签页
                          </a-menu-item>
                          <a-menu-item
                            key="closeRight"
                            :disabled="tabsMoreMenuState.closeRight"
                          >
                            <e-icon type="icon-closeRight" />
                            关闭当前右侧所有标签页
                          </a-menu-item>
                          <a-menu-item
                            key="closeOther"
                            :disabled="tabsMoreMenuState.closeOther"
                          >
                            <e-icon type="close" />
                            关闭其他标签页
                          </a-menu-item>
                          <a-menu-item
                            key="closeAll"
                            :disabled="tabsMoreMenuState.closeAll"
                          >
                            <e-icon type="close-square" />
                            关闭所有标签页
                          </a-menu-item>
                          <a-menu-divider />
                          <a-menu-item-group>
                            <span
                              slot="title"
                              class="ant-tabs-tab__openedMenuGroup"
                            >
                              <e-icon type="icon-qiehuan" />切换当前标签页
                            </span>
                            <a-menu-item v-if="mainTag" key="_0">
                              <span
                                :class="
                                  '_0' === showTagkey
                                    ? 'tabsMoreMenus-activateTab'
                                    : 'tabsMoreMenus'
                                "
                                >{{ mainTagTitle }}</span
                              >
                            </a-menu-item>
                            <a-menu-item v-for="pane in panes" :key="pane.key">
                              <span
                                :class="
                                  pane.key === showTagkey
                                    ? 'tabsMoreMenus-activateTab'
                                    : 'tabsMoreMenus'
                                "
                                :title="pane.title"
                                >{{ pane.title }}</span
                              >
                            </a-menu-item>
                          </a-menu-item-group>
                        </a-menu>
                      </a-dropdown>
                    </div>
                    <div v-if="newTheme" style="display: inline-block;">
                      <index-head @clickToMenu="clickMenu"></index-head>
                    </div>
                  </div>
                </template>
                <!-- 标签页和对应内容 $util.formatvChar(x,12)-->
                <a-tab-pane
                  v-if="mainTag"
                  :key="mainTag"
                  :closable="false"
                  class="tabs_pane--local"
                >
                  <div slot="tab">
                    <a href="javaScript: void(0);" :title="mainTagTitle">{{
                      mainTagTitle
                    }}</a>
                  </div>
                  <div
                    v-if="showTagkey === '_0'"
                    class="vueContent"
                    :style="{
                      width: showWidth,
                      marginTop: newTheme ? '' : '7px'
                    }"
                  >
                    <Main
                      v-if="menuList && mainIsAlive"
                      :windowsSize="$root.NowWindowsSize"
                      :collapsed="collapsed"
                    ></Main>
                    <a-spin
                      :spinning="!menuList && !mainIsAlive"
                      tip="数据加载中..."
                      size="large"
                      class="main-spinning"
                    >
                    </a-spin>
                    <!--首页组件 -->
                  </div>
                </a-tab-pane>
                <a-tab-pane
                  v-for="pane in panes"
                  :key="pane.key"
                  :closable="pane.closable"
                  class="tabs_pane--local"
                >
                  <div slot="tab">
                    <a href="javaScript: void(0);" :title="pane.title">{{
                      pane.title
                    }}</a>
                    <!-- 现在停用此菜单
                     覆盖掉原来的关闭按钮，tab标签页鼠标移动到关闭按钮上，会显示下拉菜单
                    <a-dropdown :disabled="pane.key !== showTagkey" overlayClassName="tabs-content-dropdown__tabMenu">
                      <a href="javaScript: void(0);" class="tabs-content-a__tabMenu" @click="remove(pane.key)">&nbsp;</a>
                      <a-menu slot="overlay" @click="tabMenuClick($event, pane.key)">
                            <a-menu-item key="refresh">
                                <e-icon type="icon-refresh"></e-icon>
                            </a-menu-item>
                       </a-menu>
                    </a-dropdown> -->
                  </div>
                </a-tab-pane>
              </a-tabs>
              <!-- tabs内容  -->
              <div
                v-if="isAlive"
                v-show="showTagkey !== '_0'&& (!$config.iframeTabsNeedCache || !isShowIframe)"
                class="vueContent vueContent-view"
                :id="key"
                :class="getContentMarginClass"
                :style="{ width: showWidth, left: showMeunWidth + 4 + 'px' }"
              >
                <keep-alive>
                  <router-view :key="key"></router-view>
                </keep-alive>
              </div>
              <!-- 用于缓存iframe显示页面 -->
              <div
                  v-if="$config.iframeTabsNeedCache && isAlive"
                  v-show="showTagkey !== '_0'&& isShowIframe"
                  class="vueContent vueContent-view"
                  :id="key"
                  :class="getContentMarginClass"
                  :style="{ width: showWidth, left: showMeunWidth + 4 + 'px' }"
              >
                <template v-for="fv in nowIframesCache" >
                  <e-i-frame v-show="fv.key === showTagkey" :key="fv.key" :src="fv.path"></e-i-frame>
                </template>
              </div>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
  </a-locale-provider>
</template>
<script>
import IndexHead from "@/views/index/IndexHead"; // 页面头部
import IndexLeftSidebar from "@/views/index/IndexLeftSidebar"; // 页面左侧菜单
import Main from "@/views/Main"; // 页面首页
import { systemMethods } from "@/mixins/systemMethods.js"; // 系统级方法
import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法
import { systemService } from "@/mixins/systemService.js";
import { cacheControls } from "@/mixins/cacheControls.js"; // tab缓存数据处理方法
import { lodopPrint } from "@/mixins/lodopPrint.js"; // lodop打印插件方法
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import ALayoutSider from "ant-design-vue/es/layout/Sider";
import EIFrame from "./EIFrame";

export default {
  mixins: [
    systemMethods,
    requestControls,
    systemService,
    cacheControls,
    lodopPrint
  ],
  name: "Index",
  components: {
    EIFrame,
    ALayoutSider,
    IndexHead: IndexHead,
    IndexLeftSidebar: IndexLeftSidebar,
    Main: Main
  },
  data: function() {
    return {
      zhCN,
      mainIsPushToPanes: false, // 默认打开的标签是放入panes列表，不是Main.vue页面
      collapsed: false,
      isShowIframe: false,
      nowIframesCache: [],
      isAlive: true,
      mainIsAlive: true,
      menuList: undefined,
      index: 0,
      panes: [], //当前默认打开的tag标签页，一般是首页{ key: "0", title: "首页", path: "/main",closable:false}
      showTagkey: this.MainTagDisplay, // 当前显示的tag标签key
      mainTag: this.MainTagDisplay, //"_0"
      mainTagTitle: "我的E1",
      openKeys: [], // 当前展开的菜单
      closedKey: null,
      defaultOpenMenu: window.DEFAULT_OPEN_MENUDHBM
        ? window.DEFAULT_OPEN_MENUDHBM
        : null, // 默认展开的一级菜单
      // 标签栏更多操作配置参数
      menuMouseOutColor: "rgba(0, 0, 0, 0.25)",
      menuMouseOverColor: "rgba(0, 0, 0, 0.65)",
      tabsMoreMenuState: {
        // 标签栏更多操作菜单项可用状态
        closeLeft: true,
        closeRight: true,
        closeAll: true,
        closeOther: true
      },
      defaultVueContentMarginTop: 161, // vueContent--view的默认MarginTop
      showVueContentMarginTop: true, // 是否显示了工具栏
      hideToolTab: [],
      isopenTab: false,
      showMeunWidth: 200,

      canDrag: true,
      scrollSet: {},

      isShowAlert: false, // 是否显示提示框
      alertMessage: null, // 提示框主题
      alertType: "error",
      alertDescription: "", // 提示内容_small
      systemLogo: window.HEAD_LOGO_IMAGE,
      newTheme: window.SYS_THEME_NAME && window.SYS_THEME_NAME === "ruifu"
    };
  },
  watch: {
    $route() {
      if (this.hideToolTab.includes(this.key)) {
        this.showVueContentMarginTop = false;
      } else {
        this.showVueContentMarginTop = true;
      }
    },
      isShowIframe: function(v){
        if (this.hideToolTab.includes(this.showTagkey)) {
          this.showVueContentMarginTop = true;
        } else {
          this.showVueContentMarginTop = false;
        }
      },
    showTagkey: function(v) {
      //监听到tab切换
      if (!this.isopenTab) this.onTabClick(v);
      else this.isopenTab = false;
    },
    collapsed: function(v) {
      if (v) {
        this.showMeunWidth = 80;
        this.canDrag = false;
        this.systemLogo = window.HEAD_LOGO_IMAGE + "_small";
      } else {
        this.showMeunWidth = 200;
        this.canDrag = true;
        this.systemLogo = window.HEAD_LOGO_IMAGE;
      }
    },
    closedKey: function(v) {
      //console.log("监听到tab关闭",v);
      this.$store.state.closedKey = v;
    }
  },
  computed: {
    // 根据当前设置的全局尺寸，改变界面样式
    getContentMarginClass: function() {
      if (this.$config.defaultControlSize && this.showVueContentMarginTop) {
        if (this.$config.defaultControlSize === "small") {
          return "vueContent__smallMargin";
        } else if (this.$config.defaultControlSize === "large") {
          return "vueContent__largeMargin";
        } else {
          return "vueContent__defaultMargin";
        }
      } else if (this.showVueContentMarginTop) {
        //
        return "vueContent__defaultMargin";
      } else {
        return "vueContent__noneToolbarMargin";
      }
    },
    key: function() {
      return this.$route.query.random
        ? this.$route.query.random
        : this.$route.path;
    },
    tabSlipWidth: function() {
      // 动态计算内容界面宽度
      let w = this.showMeunWidth;
      if (this.collapsed) {
        w = 80;
      }
      return (
        this.$util.mathUtil.round2(
          this.$util.mathUtil.accDiv(
            this.$root.NowWindowsSize.width - w + 1,
            this.$root.NowWindowsSize.width
          ) * 100
        ) + "%"
      );
    },
    showWidth: function() {
      // 动态计算内容界面宽度
      //console.log("重新计算窗口大小",this.$root.NowWindowsSize.width);
      let w = this.showMeunWidth + 2;
      if (this.collapsed) {
        w = 82;
      }
      return (
        this.$util.mathUtil.round2(
          this.$util.mathUtil.accDiv(
            this.$root.NowWindowsSize.width - w + 1,
            this.$root.NowWindowsSize.width
          ) * 100
        ) + "%"
      );
    },
    showHeight: function() {
      // 动态计算内容界面高度
      //console.log("屏幕高度：", this.windowsSize.height);
      let h = 180;
      return (
        this.$util.mathUtil.round2(
          this.$util.mathUtil.accDiv(
            this.$root.NowWindowsSize.height - h + 1,
            this.$root.NowWindowsSize.height
          ) * 100
        ) + "%"
      );
    },
    resizeTranslate: function() {
      // 拖拽变宽控件的left位移
      return this.showMeunWidth - 200;
    },
  },
  created() {
    let that = this;
    console.log("新建index",this.$util.upperInitials("axx0.456663"));
    if (!this.$store.state.hasLogin) {
      this.$router.replace("/login");
      return;
    }
    //console.log(
    //  "跳转index请求携带的路由属性：" + JSON.stringify(this.$route.query)
    //);
    let redirectUri = this.$route.query.redirectUri; // 如果有跳转请求，则需要打开请求的页面
    if (!redirectUri && this.$route.fullPath !== "/") {
      this.$router.replace("/");
    }
    if (this.$store.state.menuList) {
      this.menuList = this.$store.state.menuList;
      this.openDefaultPage();
      this.openUrlPage(redirectUri);
    } else {
      //用于刷新页面时重新获取菜单
      this.loadMenuList(function() {
        that.menuList = that.$store.state.menuList;
        that.openDefaultPage();
        that.openUrlPage(redirectUri);
      });
    }
  },
  mounted() {
    if (this.$store.state.hasLogin) {
      let that = this;
      let key = null;
      this.getNoticeMessage(function(data) {
        //console.log("获取到消息：", data);
        if (data) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].type) {
              // 有弹出需求的消息
              let value = data[i].value;
              if (data[i].type === "openApprovalForm") {
                // 打开处理表单
                setTimeout(function() {
                  if (window.AUTO_OPEN_PLD_NOTICE === true) {
                    that.openApprovalForm(value);
                  } else {
                    key = "d_" + new Date().getTime();
                    that.openNotification(
                      key,
                      "系统通知",
                      data[i].message,
                      function() {
                        that.openApprovalForm(value);
                        that.$notification.close(key);
                      },
                      "配料"
                    );
                  }
                }, 500);
              }
            } else {
              // 普通警告消息
              setTimeout(function() {
                key = "d_" + new Date().getTime();
                that.openNotification(key, "警告", data[i], function() {
                  that.$notification.close(key);
                });
              }, 500);
            }
          }
        }
      });

      window.vm = this;
    }
  },
  provide: function() {
    //用于支持其中子页面打开新的tab，子页面可以通过@/mixins/pageControls.js引入本方法
    return {
      redirect: this.redirect,
      reload: this.reload,
      openTab: this.openTab,
      closeTab: this.remove,
      closeNowTab: this.closeNowTab,
      getNowTabKey: this.getNowTabKey,
      updateLeftMenuAllCount: this.updateLeftMenuAllCount,
      hideToolBar: this.hideToolBar,
      getWindowSize: this.getWindowSize,
      hiddenScrollY: this.hiddenScrollY,
      showScrollY: this.showScrollY,
      scrollToBottom: this.scrollToBottom,
      lodopInit: this.lodopInit,
      lodopRenderingPrint: this.lodopRenderingPrint,
      lodopPrint: this.lodopPrint,
      lodopPrintTemplateDesign: this.lodopPrintTemplateDesign,
      showAlert: this.showAlert
    };
  },
  methods: {
    /**
     * 获取当前打印的tab的key
     */
    getNowTabKey: function() {
      return this.showTagkey;
    },
    /**
     * 打开对应记录的处理表单页面
     * @param datas
     */
    openApprovalForm: function(datas) {
      if (datas) {
        if (this.$util.isType(datas) === "Array") {
          for (let i = 0; i < datas.length; i++) {
            let data = datas[i];
            this.openTab({
              title: "处理草稿-" + (data.SJZT ? data.SJZT : data.SJID),
                path: !this.KeepAccountsFormTempIds.includes(data.BDMBID) ? "/ApprovalForm" : "/KeepAccountsForm",
              query: {
                dhbm: this.thisType,
                templetId: data.BDMBID, // 表单模板ID
                dataId: data.SJID, // 表单数据id
                bm: data.SJJZFS, // 表单对应BM表
                clzt: data.GSLB ? data.GSLB : this.title // 表单状态
              }
            });
          }
        }
      }
    },
    /**
     *  显示提示框内容
     *  @param type
     *  @param title
     *  @param description
     */
    showAlert: function(type, title, description) {
      this.alertType = type ? type : "info";
      this.alertMessage = title ? title : "提示";
      this.alertDescription = description ? description : "";
      this.isShowAlert = true;
    },
    /**
     * 关闭提示框
     */
    onAlertClose: function() {
      this.isShowAlert = false;
    },
    /**
     * 在左侧上角，弹出提示消息
     * @param key
     * @param title
     * @param description
     * @param onClickFunction
     * @param buttonTitle
     */
    openNotification: function(
      key,
      title,
      description,
      onClickFunction,
      buttonTitle
    ) {
      if (buttonTitle) {
        this.$notification.warn({
          message: title,
          description: description,
          duration: 0,
          key: key,
          btn: h => {
            return h(
              "a-button",
              {
                props: {
                  type: "primary",
                  size: "small"
                },
                on: {
                  click: () => onClickFunction()
                }
              },
              buttonTitle
            );
          }
        });
      } else {
        this.$notification.warn({
          message: title,
          description: description,
          duration: 0,
          key: key,
          onClick: onClickFunction
        });
      }
    },

    /**
     * 获取操作界面尺寸单位px
     */
    getWindowSize: function() {
      return {
        width:
          (parseFloat(this.showWidth) * this.$root.NowWindowsSize.width) / 100 -
          20,
        height:
          (parseFloat(this.showHeight) * this.$root.NowWindowsSize.height) / 100
      };
    },
    /**
     * 隐藏工具栏
     */
    hideToolBar: function() {
      this.showVueContentMarginTop = false;
      if (!this.hideToolTab.includes(this.key)) {
        this.hideToolTab.push(this.key);
      }
    },
    /**
     * 被弃用方法
     * 定义用于页面刷新的方法,此方法会清除其他tab标签的缓存
     */
    reloadAllPage: function() {
      let that = this;
      this.isAlive = false;
      setTimeout(function() {
        that.isAlive = true;
      }, 60);
    },
    /**
     * 定义用于页面刷新的方法
     */
    reload: function() {
      if (window.SHOW_TEST_FUNCTION) {
        if (this.showTagkey === "_0") {
          let that = this;
          this.mainIsAlive = false;
          setTimeout(function() {
            that.mainIsAlive = true;
          }, 60);
        } else {
          this.reloadAllPage();
        }
      } else {
        let that = this;
        if (this.showTagkey === "_0") {
          let that = this;
          this.mainIsAlive = false;
          setTimeout(function() {
            that.mainIsAlive = true;
          }, 60);
        } else {
          let opened = this.panes.find(function(p) {
            return p.key === that.showTagkey;
          });
          if (opened && opened.key !== this.MainTagDisplay) {
            let nowViewCache = this.getViewCache(opened.key);
            if (nowViewCache) {
              this.clearViewCache(opened.key);
            }
            let randomKey = new Date().getTime() + "_" + Math.random();
            opened.key = opened.title + "_" + randomKey;
            opened.query.random = randomKey;
            this.showTagkey = opened.key;
            if (nowViewCache) {
              this.pushViewCache(this.showTagkey, nowViewCache);
            }
            this.roterReplace(opened);
          }
        }
      }
    },
    /**
     * tab页面转换为指定url页面
     */
    redirect: function(path, query, title) {
      let that = this;
      let opened = this.panes.find(function(p) {
        return p.key === that.showTagkey;
      });
      if (opened && opened.key !== this.MainTagDisplay) {
        opened.path = path;
        if (query) {
          opened.query = opened.query ? Object.assign({},opened.query, query) : query;
        }
        if (title) {
          opened.title = title;
        }
        let randomKey = new Date().getTime() + "_" + Math.random();
        opened.key = opened.title + "_" + randomKey;
        opened.query.random = randomKey;
        this.showTagkey = opened.key;
        this.roterReplace(opened);
      }
    },
    /**
     * 根据标签打开情况，改变标签更多操作菜单项的可用状态
     */
    onTabsMoreClick(tag) {
      //console.log("点击更多操作", tag);
      let nowLength = this.panes.length;
      if (tag.key === "closeAll") {
        // 关闭所有可关闭的标签
        if (this.panes.length > 0) {
          for (let r = nowLength - 1; r >= 0; r--) {
            if (
              this.panes[r].closable === undefined ||
              false !== this.panes[r].closable
            ) {
              this.remove(this.panes[r].key);
            }
          }
        }
      } else {
        // 找到当前打开的标签所在位置
        let that = this;
        let nowPanIndex = this.panes.findIndex(function(openTag) {
          return that.compareFunction(openTag.key, that.showTagkey);
        });
        if (tag.key === "closeOther") {
          this.onTabsMoreClick({ key: "closeLeft" });
          this.onTabsMoreClick({ key: "closeRight" });
          return;
        } else if (tag.key === "closeLeft") {
          // 关闭左侧所有标签
          let tempLast = 0; // 可关闭的第一个标签
          if (this.mainIsPushToPanes) {
            // 第一个不可关闭
            tempLast = 1;
          }
          for (let i = nowPanIndex - 1; i >= tempLast; i--) {
            if (
              this.panes[i].closable === undefined ||
              false !== this.panes[i].closable
            ) {
              this.remove(this.panes[i].key);
            }
          }
        } else if (tag.key === "closeRight") {
          // 关闭右侧所有标签
          for (let j = nowLength - 1; j > nowPanIndex; j--) {
            if (
              this.panes[j].closable === undefined ||
              false !== this.panes[j].closable
            ) {
              this.remove(this.panes[j].key);
            }
          }
        } else if (tag.key === "refresh") {
          //刷新当前标签
          this.reload();
        } else {
          //切换到指定标签,改为监听showTagkey进行切换
          this.showTagkey = tag.key;
          //this.onTabClick(tag.key);
        }
      }
    },
    /**
     * 根据标签打开情况，改变标签更多操作菜单项的可用状态
     */
    changeTabsMoreMenuState() {
      if (this.panes.length <= 1) {
        // 这种情况是默认第一个打开的标签页不能关闭
        this.tabsMoreMenuState.closeLeft = true;
        this.tabsMoreMenuState.closeRight = true;
        this.tabsMoreMenuState.closeAll = false;
        this.tabsMoreMenuState.closeOther = true;
      } else {
        let that = this;
        // 找到当前打开的标签所在位置
        let nowPanIndex = this.panes.findIndex(function(openTag) {
          return that.compareFunction(openTag.key, that.showTagkey);
        });
        if (nowPanIndex == 0 || (this.mainIsPushToPanes && nowPanIndex == 1)) {
          // 如果是顺位第一个，除默认打开的标签
          this.tabsMoreMenuState.closeLeft = true;
          this.tabsMoreMenuState.closeRight = false;
          this.tabsMoreMenuState.closeOther = true;
        } else if (
          (!this.mainIsPushToPanes && this.panes.length - 1 == nowPanIndex) ||
          (this.panes.length > 2 && this.panes.length - 1 == nowPanIndex)
        ) {
          // 是当前是最后一个
          this.tabsMoreMenuState.closeLeft = false;
          this.tabsMoreMenuState.closeRight = true;
          this.tabsMoreMenuState.closeOther = true;
        } else if (nowPanIndex > 0 && nowPanIndex < this.panes.length - 1) {
          this.tabsMoreMenuState.closeLeft = false;
          this.tabsMoreMenuState.closeRight = false;
          this.tabsMoreMenuState.closeOther = false;
        } else {
          this.tabsMoreMenuState.closeLeft = true;
          this.tabsMoreMenuState.closeRight = true;
          this.tabsMoreMenuState.closeOther = true;
        }
        this.tabsMoreMenuState.closeAll = false;
        this.tabsMoreMenuState.closeOther = false;
      }
    },
    /**
     * 在系统中打开一个指定URL地址的页面,
     * 注：url只能是本系统内的地址，不能是第三方网站地址
     * @param url
     */
    openUrlPage(url) {
      if (url && url.indexOf("?") > 0) {
        let query = this.$util.getQueryParams(url);
        let path = url.substring(0, url.indexOf("?"));
        let obj = this.$util.getObjByParamAndValue(this.menuList, "path", path);
        if (!obj) {
          if (query.DEFAULT_DHBM_TITLE) {
            obj = {
              path: path,
              name: query.DEFAULT_DHBM_TITLE,
              menuName: query.DEFAULT_DHBM_TITLE,
              closable: this.mainTag !== null
            };
          }
        }
        if (this.$util.isValidObject(query)) {
          obj.query = query;
        }
        if (obj) {
          this.mainIsPushToPanes = true;
          //console.log("打开一个默认页面：", this.mainIsPushToPanes);
          this.openTab({
            key: obj.DHBM,
            title: obj.menuName,
            path: obj.path,
            name: obj.name,
            query: obj.query,
            closable: this.mainTag !== null // 默认页面为不可关闭tab
          });
          this.openKeys = this.$util.getMenuParents(obj.key);
        }
      }
    },
    /**
     * 定义用于打开一个默认页面,针对配置了Default_DHBM参数的情况
     */
    openDefaultPage() {
      if (this.Default_DHBM) {
        let obj = this.$util.getObjByParamAndValue(
          this.menuList,
          "DHBM",
          this.Default_DHBM
        ); // 可根据不同的实施对象进行定制
        if (!obj) {
          // 如果不是配置的"DHBM"，监测是否是使用URL配置
          let query = null;
          let path = this.Default_DHBM;
          if (this.Default_DHBM && this.Default_DHBM.indexOf("?") > 0) {
            query = this.$util.getQueryParams(path);
            path = path.substring(0, this.Default_DHBM.indexOf("?"));
          }
          obj = this.$util.getObjByParamAndValue(this.menuList, "path", path);
          if (!obj && query) {
            if (query.DEFAULT_DHBM_TITLE) {
              obj = {
                path: path,
                name: query.DEFAULT_DHBM_TITLE,
                menuName: query.DEFAULT_DHBM_TITLE,
                closable: this.mainTag !== null
              };
            }
          }
          if (this.$util.isValidObject(query)) {
            obj.query = query;
          }
        }
        //console.log("打开一个默认页面：", obj);
        if (obj) {
          this.mainIsPushToPanes = true;
          //console.log("打开一个默认页面：", this.mainIsPushToPanes);
          this.openTab({
            key: obj.DHBM,
            title: obj.menuName,
            path: obj.path,
            name: obj.name,
            query: obj.query,
            closable: this.mainTag !== null // 默认页面为不可关闭tab
          });
          this.openKeys = this.$util.getMenuParents(obj.key);
        }
      } else {
        if (this.defaultOpenMenu) {
          // 如果设置了默认展开菜单
          this.openKeys = [this.defaultOpenMenu];
        }
      }
    },
    /**
     * 点击菜单，打开对应的tab页面，如果已经打开则切换到显示的tab
     * @param menuObj
     */
    clickMenu(menuObj) {
      this.openTab(menuObj);
    },
    /**
     * 打开一个新的tab标签页面，如果已经打开则切换到显示的tab
     * @param TabObj
     */
    openTab(TabObj) {
      if (!TabObj.title) {
        this.$error({
          title: "错误",
          content: "打开页面失败，参数错误"
        });
      }
      let randomKey = new Date().getTime() + "_" + Math.random();
      if (!TabObj.key) {
        // 如果没有key,不是菜单点击打开tab,需要追加一个随机数
        TabObj.key = TabObj.title + "_" + randomKey;
        TabObj.query.random = randomKey;
      } else {
        TabObj.query = {};
          TabObj.query.random = TabObj.key;
        }
        if (TabObj.key === this.showTagkey) return;

        if (TabObj.key === this.mainTag) {
          if (this.$route.fullPath !== "/") this.$router.replace("/");
          this.showTagkey = TabObj.key;
          this.isShowIframe = false;
          this.changeTabsMoreMenuState();
          return;
        }
        this.isopenTab = true;
        let oldTab;
        //如果允许在不同标签打开重复页面
        if(this.$config.notAllowRepeatedOpening) {
          oldTab = this.panes.find(function(openTag) {
            return openTag.title === TabObj.title;
          });
        }
        if (oldTab) {
          //切换到已打开的标签页
          this.switchTap(oldTab);
      } else {
        //打开新标签页
        // 如果有需要存入缓存的视图定义配置信息
        if (TabObj.query && TabObj.query.type === "dataViewCache") {
          let pageConfig = this.$store.state.cacheData;
          if (pageConfig) {
            this.pushViewCache(TabObj.key, pageConfig);
            this.$store.commit("clearCacheData");
          }
        }
        this.panes.push(TabObj);
        this.showTagkey = TabObj.key;
        this.roterPush(TabObj);
        if (this.isHttpURL(TabObj.path)) {
          //打开iframe
          this.hideToolBar();
        }
        // 展开当前打开菜单项所在顶级菜单和父级菜单
        let obj = this.$util.getObjByParamAndValue(
          this.menuList,
          "DHBM",
          TabObj.key
        );
        if (obj) this.openKeys = this.$util.getMenuParents(obj.key);
      }
      this.changeTabsMoreMenuState();
    },
    /**
     * 点击切换标签页
     */
    onTabClick(targetKey) {
      //console.log(targetKey);
      let oldTab = this.panes.find(function(openTag) {
        return openTag.key === targetKey;
      });
      if (oldTab) {
        //切换到已打开的标签页
        this.switchTap(oldTab);
        // 展开当前打开菜单项所在顶级菜单和父级菜单
        let obj = this.$util.getObjByParamAndValue(
          this.menuList,
          "DHBM",
          oldTab.key
        );
        if (obj) this.openKeys = this.$util.getMenuParents(obj.key);
      } else {
        //切换到首页
        this.showTagkey = this.mainTag;
        if (this.$route.fullPath !== "/") this.$router.replace("/");
        else this.isShowIframe = false;
      }
      this.changeTabsMoreMenuState();
    },
    /**
     * 切换显示tabs标签
     */
    switchTap(oldTab) {
      this.roterReplace(oldTab);
      this.showTagkey = oldTab.key;
    },
    /**
     * 当前roter-view跳转到指定页面，根据标签配置，自动选择采用path导航或name导航
     */
    roterPush(roteObj) {
      if (roteObj) {
        if (roteObj.path) {
          let showRote = this.$util.deepCopy(roteObj);
          if(this.isHttpURL(showRote.path)) { // 如果是直接跳转到外部网站
            let iframeUrl = showRote.path;
            this.isShowIframe = true;
            //如果需要缓存,则进入缓存列表
            if(this.$config.iframeTabsNeedCache) {
              this.nowIframesCache.push({
                key: showRote.key,
                path: window.encodeURIComponent(iframeUrl)
              });
              return;
            } else {
              showRote.query.src = window.encodeURIComponent(iframeUrl);
              showRote.path = "/EIFrame";
            }
          } else {
            this.isShowIframe = false;
          }
          //path导航
          this.$router.push({
            path: showRote.path,
            query: showRote.query
          });
        } else if (roteObj.name) {
          //name导航
          this.$router.push({
            name: roteObj.name,
            params: roteObj.params,
            query: roteObj.query
          });
        }
      }
    },
    /**
     * 刷新当前roter-view:根据标签配置，自动选择采用path导航或name导航，注：方法有问题，无法实现刷新
     */
    roterReplace(roteObj) {
      if (roteObj) {
        if (roteObj.path) {
          let showRote = this.$util.deepCopy(roteObj);
          if(this.isHttpURL(showRote.path)) { // 如果是直接跳转到外部网站
            if(this.$config.iframeTabsNeedCache) {
              this.isShowIframe = true;
              return;
            } else {
              let iframeUrl = showRote.path;
              showRote.query.src = window.encodeURIComponent(iframeUrl);
              showRote.path = "/EIFrame";
              this.isShowIframe = true;
            }
          } else {
            this.isShowIframe = false;
          }
          //path导航
          this.$router.replace({
            path: showRote.path,
            query: showRote.query
          });
        } else if (roteObj.name) {
          //name导航
          this.$router.replace({
            name: roteObj.name,
            params: roteObj.params
          });
        }
      }
    },
    /**
     * 标签页面点击关闭
     * @param targetKey
     * @param action
     */
    onTabEdit(targetKey, action) {
      this[action](targetKey);
    },
    /**
     * 关闭当前打开的Tab
     */
    closeNowTab() {
      if (this.showTagkey !== this.mainTag) {
        this.remove(this.showTagkey);
      }
    },
    /**
     * 关闭对应的标签
     * @param targetKey
     */
    remove(targetKey) {
      let lastIndex;
      let that = this;
      let panesLenth = this.panes.length - 1;
      //找到最后一个tab
      this.panes.forEach((pane, i) => {
        if (that.compareFunction(pane.key, targetKey)) {
          lastIndex = i;
          if (panesLenth <= lastIndex) {
            lastIndex = i - 1;
          }
        }
      });
      this.closedKey = targetKey;
      let nextShowTab = undefined;
      // 把要关闭的tab过滤掉后剩余的tab页
      const panes = this.panes.filter(
        pane => !that.compareFunction(pane.key, targetKey)
      );
      if (panes.length && that.compareFunction(this.showTagkey, targetKey)) {
        if (lastIndex >= 0) {
          nextShowTab = panes[lastIndex];
        }
        this.panes = panes;
        if (nextShowTab) {
          this.isopenTab = true;
          this.switchTap(nextShowTab);
        } else {
          if (this.$route.fullPath !== "/") this.$router.replace("/");
          this.showTagkey = this.mainTag;
          this.isShowIframe = false;
        }
      } else {
        this.panes = panes;
        if (panes.length == 0) {
          if (this.$route.fullPath !== "/") this.$router.replace("/");
          this.showTagkey = this.mainTag;
          this.isShowIframe = false;
        } else {
          if (lastIndex >= 0) {
            nextShowTab = panes[lastIndex];
            //如果接下来显示的不是iframe页面的情况
            this.isShowIframe = this.isHttpURL(nextShowTab.path);
          }
        }
      }
      this.changeTabsMoreMenuState();
      this.clearViewCache(this.closedKey);
      let closeIndex = this.hideToolTab.indexOf(this.closedKey);
      if(closeIndex>=0)
        this.hideToolTab.splice(closeIndex, 1);

      closeIndex = this.nowIframesCache.findIndex(function(ifv){
        return ifv.key === that.closedKey
      });
      if(closeIndex>=0) {
        this.nowIframesCache.splice(closeIndex, 1);
      }
    },
    /**
     * 监听tab附带的下拉菜单点击事件
     */
    tabMenuClick(e, key) {
      console.log("点击了tab菜单", e, key);
      if (e.key === "refresh") {
        // 点击了刷新菜单项，刷新当前key对应的页面内容
        this.reload();
      }
    },
    /**
     * 如果需要打开的页面是网页
     * @param url
     * @returns {boolean}
     */
    isHttpURL(url) {
      try {
        return url && (0 == url.indexOf("http:") || 0 == url.indexOf("https:"));
      } catch (e) {
        return false;
      }
    },
    /**
     * targetKey比较方法，a表示保存的key,b表示rodom或bdmh，用于打开的tab的key确认
     * @param a
     * @param b
     * @returns {boolean}
     */
    compareFunction(a, b) {
      //debugger
      if (a === b) {
        return true;
      } else if (b.indexOf("_") > 0 && a.indexOf(b) > 0) {
        return true;
      }
      return false;
    },
    /**
     * 修改菜单对应数字角标数字
     * @param bmdh
     * @param count
     */
    updateLeftMenuAllCount(bmdh, count) {
      let obj = this.$util.getObjByParamAndValue(this.menuList, "DHBM", bmdh);
      if (obj && obj.allCount && count != undefined && count != null) {
        obj.allCount = count;
      }
    },
    /**
     * 如果拖拽条的位置超过200则可以触发拖拽事件，否则会被禁止
     * @param ev
     * @returns {boolean}
     */
    checkDrag(ev) {
      console.log(ev.x);
      if (ev.x >= 200) {
        this.canDrag = true;
        return true;
      }
      return false;
    },
    /**
     * 响应拖拽条拖拽事件
     * @param x
     */
    onDrag(x) {
      if (x >= 200) {
        this.showMeunWidth = x + 5;
      }
    },
    /**
     * 响应拖拽条拖拽结束事件
     * @param x
     */
    onDragstop(x) {
      console.log("x=", x);
      if (x >= 200) this.showMeunWidth = x;
      else {
        this.canDrag = false;
        this.showMeunWidth = 200;
        let that = this;
        setTimeout(function() {
          // 五百毫秒后重新渲染出拖拽控件
          that.canDrag = true;
        }, 500);
      }
    },
    /**
     * 让指定类名或id 的对象滚动条置底
     * @param {String} id
     * @param {String} className
     */
    scrollToBottom(id, className) {
      let dObj;
      if (id) {
        dObj = document.getElementById(id);
        if (dObj) {
          dObj[0].scrollTop = dObj[0].scrollHeight;
        }
      } else if (className) {
        dObj = document.getElementsByClassName(className);
        if (dObj && dObj.length > 0) {
          dObj[0].scrollTop = dObj[0].scrollHeight;
        }
      }
    },
    /**
     * 隐藏对应的对象的滚动条，有不合理的地方
     * @param className
     * @param id
     */
    hiddenScrollY(className, id) {
      console.log("隐藏滚动条");
      let dObj, key;
      let number = 0;
      if (id) {
        dObj = document.getElementById(id);
        if (dObj) {
          number = document.styleSheets[0].insertRule(
            "#" + id + "::-webkit-scrollbar {width:0px}",
            0
          );
          key = id + "_" + this.key;
          this.scrollSet[key] = number;
          dObj.style.overflow = "-moz-scrollbars-none";
        }
      } else if (className) {
        dObj = document.getElementsByClassName(className);
        if (dObj && dObj.length > 0) {
          key = "c_" + className + "_" + this.key;
          number = this.scrollSet[key];
          if (!number) {
            number = Object.keys(this.scrollSet).length + 1;
          }
          number = document.styleSheets[0].insertRule(
            "." + className + "::-webkit-scrollbar {width:0px}",
            number
          );
          console.log("返回的对象：", number);
          this.scrollSet[key] = number;
          dObj[0].style.overflow = "-moz-scrollbars-none";
          // 滚动条置底
          dObj[0].scrollTop = dObj[0].scrollHeight;
        }
      }
      this.scrollToBottom(id, className);
    },
    /**
     * 显示对应的对象的滚动条，有不合理的地方
     * @param className
     * @param id
     */
    showScrollY(className, id) {
      console.log("显示滚动条");
      let dObj, key;
      let number = 0;
      if (id) {
        key = id + "_" + this.key;
        number = this.scrollSet[key];
        dObj = document.getElementById(id);
        document.styleSheets[0].removeRule(number);
        this.scrollSet[key] = undefined;
        dObj.style.overflow = "-moz-scrollbars-none";
      } else if (className) {
        dObj = document.getElementsByClassName(className);
        if (dObj && dObj.length > 0) {
          key = "c_" + className + "_" + this.key;
          number = this.scrollSet[key];
          document.styleSheets[0].removeRule(number);
          this.scrollSet[key] = undefined;
          dObj[0].style.overflowY = "-moz-scrollbars-none";
        }
      }
    },

    // 将路径中的占位符替换为相应值
    replacePathPlaceholder(path) {
      return path.replace("{{token}}", this.$store.state.userInfo.randomKey);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../assets/style/base/index.less";
</style>
