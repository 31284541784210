import { requestControls } from "@/mixins/requestControls.js"; // 网络请求
/**
 * 支持入库单-相关表单的明细物资序列号管理
 * @type {{mixins: *[], data()}}
 */
export const e1ConfigureProductSerial = {
  mixins: [requestControls],
  data() {
    return {
      serialConfigModal: false,
      serialConfigProducts: null,
      editSerialProductIndex: "1",
      haveRepeatSerial: false,
      haveRepeatSerialMessage: "",
      needRemoveDuplicationSerial: true // 需要去除同一物质的重复序列号
    };
  },
  created() {
    //console.log("混入e1ConfigureProductSerial的通用方法");
  },
  methods: {
    /**
     * 点击“序列号配置”按钮，打开序列号配置窗口
     * @param button
     */
    tsbSerialConfig: function(button) {
      console.log("tsbSerialConfig=", button);
      let tableName = this.mxTableName[0];
      let selectedDetailDatas = this.getMxTableDataByTableName(tableName);
      if (selectedDetailDatas.length > 0) {
        let serialConfigProducts = this.getSerialConfigWzList(
          selectedDetailDatas
        );
        if (serialConfigProducts.length > 0) {
          this.serialConfigProducts = serialConfigProducts;
          this.serialConfigModal = true;
        } else {
          this.alertWarning("没有需要配置序列号的物资");
        }
      } else {
        this.alertWarning("请先选入入库物资");
      }
    },
    /**
     * 分析选人的物资明细，从中将需要进行序列号输入管理的物资组织成数组
     * @param details
     */
    getSerialConfigWzList: function(details) {
      let wzList = [];
      if (details && details.length > 0) {
        for (let i = 0; i < details.length; i++) {
          let detail = details[i];
          let SFQYXLH = detail._SFQYXLH;
          let WZMC = detail._WZMC;
          let xlhsclx = detail._XLHSCLX;
          let xlhlx = detail._XLHLX;
          let hh = detail._HH;
          let rksl = this.$util.getNumber(detail._BCRKSL); // 入库数量
          // 物资是否启用序列号
          if (
            (this.$util.isType(SFQYXLH) === "Boolean" && SFQYXLH) ||
            this.$util.strIsTrue(SFQYXLH)
          ) {
            if (
              xlhlx === "厂家" ||
              (xlhlx === "自制" && xlhsclx === "手工输入")
            ) {
              if (rksl > 0) {
                // 只有入库数量大于0的需要检测序列号
                let xlhs = detail._XLH;
                let xlhsArray = [];
                if (xlhs) {
                  try {
                    xlhsArray = xlhs.split(","); //JSON.parse(xlhs);
                  } catch (e) {
                    xlhsArray = [];
                  }
                }
                let selectXLH = {};
                if (xlhsArray.length == rksl) {
                  xlhsArray.forEach(function(v, index) {
                    selectXLH[hh + "_" + (index + 1)] = v;
                  });
                }
                wzList.push({
                  name: WZMC,
                  HH: hh,
                  num: rksl,
                  serials: selectXLH,
                  rules: [
                    {
                      required: true,
                      message:
                        "请输入" + WZMC + "行号为" + hh + "的序列号" + (i + 1)
                    }
                  ]
                });
              }
            }
          }
        }
      }
      return wzList;
    },
    /**
     * 响应“序列号配置”窗口点击确定事件
     * @param event
     */
    onSerialConfig: function(event) {
      console.log("获取到的序列信息", event);
      let serial = event.data;
      let tableName = this.mxTableName[0];
      let selectedDetailDatas = this.getMxTableDataByTableName(tableName);
      let hhxlh = {};
      if (this.$util.isValidObject(serial)) {
        for (let key in serial) {
          let keys = key.split("_");
          if (keys[0]) {
            // 行号
            if (!hhxlh[keys[0]]) {
              hhxlh[keys[0]] = [];
            }
            hhxlh[keys[0]].push(serial[key]);
          }
        }
      }
      let haveRepeatSerial = false;
      for (let hh in hhxlh) {
        let hhint = this.$util.getNumber(hh);
        let rowData = selectedDetailDatas.find(function(row) {
          return row._HH == hhint;
        });
        if (rowData) {
          let newData = Object.assign({}, rowData, {
            _XLH: hhxlh[hh].toString() //JSON.stringify(hhxlh[hh])
          });
          this.updateRowData(tableName, newData, "_HH");
          if (
            this.needRemoveDuplicationSerial &&
            !haveRepeatSerial &&
            this.$util.arrayIsRepeat(hhxlh[hh])
          ) {
            haveRepeatSerial = true;
            this.haveRepeatSerialMessage =
              "（行号" +
              hh +
              "）" +
              rowData._WZMC +
              "的有重复序列号，请重新配置";
          }
        }
      }
      this.haveRepeatSerial = haveRepeatSerial;
      if (haveRepeatSerial) {
        this.alertWarning(this.haveRepeatSerialMessage);
        event.resolve(1);
      } else {
        this.haveRepeatSerialMessage = null;
        event.resolve();
      }
    },
    /**
     * 检测是否需要继续配置序列号
     * @return {boolean}
     */
    checkProductSerial: function() {
      let tableName = this.mxTableName[0];
      let selectedDetailDatas = this.getMxTableDataByTableName(tableName);
      if (selectedDetailDatas.length > 0) {
        let serialConfigProducts = this.getSerialConfigWzList(
          selectedDetailDatas
        );
        if (serialConfigProducts.length > 0) {
          let needSerialObj = serialConfigProducts.find(p => {
            return !this.$util.isValidObject(p.serials);
          });
          if (needSerialObj || this.haveRepeatSerial) {
            this.serialConfigModal = true;
            this.serialConfigProducts = serialConfigProducts;
            let message = this.haveRepeatSerial
              ? this.haveRepeatSerialMessage
              : "（行号" +
                needSerialObj.HH +
                "）" +
                needSerialObj.name +
                "的序列号未配置完成，请继续配置";
            setTimeout(() => {
              this.alertWarning(message);
            }, 60);
            return false;
          }
        }
      }
      return true;
    }
  }
};
