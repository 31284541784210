<!--
 * 登录组件
 * @create wangxin 2018/07/08
-->
<!--suppress ALL -->
<template>
  <div class="container-login">
    <div>
      <img src="../assets/loginBg.jpg" class="bgmImg" />
    </div>
    <div class="container">
      <a-form :form="form" class="login-container">
        <a-form-item style="width:100%;text-align:center;">
          <a class="loginTiittle">{{ sysName }}</a>
        </a-form-item>

        <a-form-item
          prop="account"
          class="account-login"
          :validate-status="userNameError() ? 'error' : ''"
          :help="userNameError() || ''"
        >
          <a-input
            v-decorator="userInputRules"
            :allowClear="true"
            class="login-user-input"
            placeholder="请输入账号"
            @change="trimInput"
          >
            <template slot="prefix"><i class="icon-user-input"></i></template>
          </a-input>
        </a-form-item>
        <a-form-item
          class="password-login"
          :validate-status="passwordError() ? 'error' : ''"
          :help="passwordError() || ''"
        >
          <a-input
            type="password"
            class="login-password-input"
            v-decorator="passInputRules"
            placeholder="请输入密码"
            @change="trimInput"
          >
            <template slot="prefix"
              ><i class="icon-password-input"></i
            ></template>
          </a-input>
        </a-form-item>
        <a-form-item style="width:100%;">
          <a-button
            type="primary"
            :loading="loading"
            style="width:100%;height:32px;font-size:20px;"
            @click.native.prevent="handleSubmit"
            >登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法
import { systemService } from "@/mixins/systemService.js"; // 主体框架方法
//页面初始化时执行方法
export default {
  mixins: [analyticalMenuAuth, requestControls, systemService],
  name: "Login",
  data() {
    return {
      sysName: "",
      loading: false,
      userInputRules: [
        "username",
        {
          initialValue: "user1",
          rules: [{ required: true, message: "请输入账号" }]
        }
      ],
      passInputRules: [
        "password",
        {
          initialValue: "yd2013",
          rules: [{ required: true, message: "请输入密码" }]
        }
      ],
      form: this.$form.createForm(this, { name: "login校验" }),
      checked: true
    };
  },
  created() {
    let _self = this;
    document.onkeydown = function() {
      //用户按Enter，提交登录
      if (window.event.keyCode === 13) {
        _self.handleSubmit();
      }
    };
  },
  mounted: function() {
    this.sysName = "ERP系统";
  },
  methods: {
    //表单提交方法
    handleSubmit() {
      let that = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.loading) {
            that.loading = true;
            let loginParams = {
              action: "UserLogin",
              username: values.username,
              userpsw: values.password
            };
            that.post("System.ashx", loginParams, {
              headers: {
                "Content-Type":
                  "application/x-www-form-urlencoded;charset=utf-8"
              },
              success: function(req) {
                //console.log("请求结果",req);
                //保存全局状态
                that.$store.commit("login", {
                  userName: req.data.userName,
                  userDept: req.data.depart,
                  userID: req.data.userId,
                  userQrimg: "/static/qrtest.png",
                  randomKey: req.data.randomKey,
                  employee: req.data.employee,
                  departId: req.data.departId,
                  employeeId: req.data.employeeId,
                  userHeadLogo:
                    "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png", //测试头像，需要根据需求设置
                  companyId: req.data.companyId
                });
                //加载左侧菜单
                that.loadMenuList(function() {
                  that.loading = false;
                  that.$router.push({
                    path: "/",
                    replace: true
                  }); //跳转主页
                  document.onkeydown = undefined;
                });
              },
              error: function(err) {
                console.error("请求失败", err);
                that.loading = false;
                that.$error({
                  title: "提示",
                  content: "登录失败，" + err.message + ""
                });
              }
            });
          }
        } else {
          this.$info({
            title: "提示",
            content: "请输入账号和密码"
          });
          return false;
        }
      });
    },
    /**
     * 用户名输入错误提示
     */
    userNameError() {
      const { getFieldError, isFieldTouched } = this.form;
      return isFieldTouched("username") && getFieldError("username");
    },
    /**
     * 密码输入错误提示
     */
    passwordError() {
      const { getFieldError, isFieldTouched } = this.form;
      return isFieldTouched("password") && getFieldError("password");
    },
    /**
     * 设置清空用户名输入的空格
     * @param e
     */
    trimInput(e) {
      let val = e.target.value;
      if ("" == this.$util.trimStr(val)) {
        e.target.value = "";
      }
    }
  }
};
</script>

<style lang="scss">
.container-login .container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  //background-image: url(../assets/loginBg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.login-container {
  margin-left: 70% !important;
  margin-top: 16% !important;
  width: 20% !important;
  padding: 35px 35px 15px 35px !important;
  background: transparent;
  .title {
    margin: 0 auto 40px auto;
    text-align: center;
    color: #505458;
  }
  .remember {
    margin: 0 0 35px 0;
  }
}

.icon-user-input {
  background-image: url(../assets/userIcon.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 18px;
  height: 22px;
  margin-left: -5px;
}

.icon-clean-account {
  float: right;
  position: absolute;
  right: 4%;
  top: 80%;
  background-image: url(../assets/loginCleanIcon.png);
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-position: 5px 3px;
  width: 31px;
  height: 30px;
  z-index: 888;
  visibility: hidden;
}

.icon-password-input {
  background-image: url(../assets/passWordIcon.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 18px;
  height: 22px;
  margin-left: -5px;
}

.login-user-input .el-input__inner {
  padding-left: 50px;
  position: absolute;
  font-size: 18px;
  border: 0px;
  z-index: 333;
  height: 50px;
  width: 100%;
  margin-top: -5px;
  background: url(../assets/loginInputBg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.login-password-input .el-input__inner {
  padding-left: 51px;
  position: absolute;
  font-size: 18px;
  border: 0;
  height: 50px;
  z-index: 333;
  margin-top: 28px;
  width: 100%;
  background: url(../assets/loginInputBg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.account-login .el-form-item__error {
  display: none;
  font-size: 15px;
  top: 65px;
}

.password-login .el-form-item__error {
  font-size: 15px;
  display: none;
  top: 98px;
}

.bgmImg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.loginTiittle {
  font-weight: 600;
  font-size: 25px;
  font-family: Helvetica;
  color: #303030;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
</style>
