/**
 * 表头动态改变列宽的渲染组件
 * 注：依赖组件vue-draggable-resizable，使用了JSX语法
 */
import Vue from "vue";

let tableThResizeable;
const draggingMap = {};
tableThResizeable = {
  oneCnCharPX: 20, // 单汉字占用的px宽度
  /**
   * 将字符串进行统计，汉字按一个字符长度，英文和数字按半个字符长度进行计算，得出该字符串最多可占用字符长度
   * @param text
   * @returns {number}
   */
  getTextMaximumLength: function(text) {
    let length = 0;
    if (text && Object.prototype.toString.call(text) === "[object String]") {
      let cnReg = new RegExp("[\\u4E00-\\u9FFF]+", "g"); // 匹配汉字
      for (let ii = 0; ii < text.length; ii++) {
        if (cnReg.test(text.charAt(ii))) {
          length++;
        } else {
          length = length + 0.5;
        }
      }
    }
    return length;
  },
  getStyle: function(obj, attr) {
    return obj.currentStyle
      ? obj.currentStyle[attr]
      : getComputedStyle(obj, false)[attr];
  },
  /**
   * 根据表头，创建一个动态列宽表头渲染方法
   * @param columns
   * @returns {ResizeableTitle}
   */
  getResizeableTitleFunction: function(columns) {
    columns.forEach(col => {
      let tempK = col.dataIndex || col.key;
      let minLength = col
        ? col.title
          ? col.title.length
          : col.ctitle
          ? col.ctitle.length
          : 1
        : 1;
      let setWidth = 60;
      if (col.width) {
        setWidth = parseFloat(col.width);
      }
      //重新计算列的最小宽度
      col.width = Math.max(
        setWidth,
        minLength * tableThResizeable.oneCnCharPX + 20
      );
      draggingMap[tempK] = col.width;
    });
    let lastColumn;
    if (columns.length > 0) {
      // 找到列定义的最后一个非固定的列
      for (let i = columns.length - 1; i >= 0; i--) {
        if (
          columns[i].key !== "operation-column" &&
          columns[i].fixed !== "right"
        ) {
          lastColumn = columns[i];
          break;
        }
      }
    }
    /*const lastKey = Vue.observable(
      lastColumn
        ? lastColumn.dataIndex
          ? lastColumn.dataIndex
          : lastColumn.key
        : ""
    );*/
    const draggingState = Vue.observable(draggingMap);
    const ResizeableTitle = createElement => {
      let thDom = null;
      let children = createElement.children;
      const { key, ...restProps } = createElement.props;
      //console.log("tableThResizeable=", createElement, key);
      const col = columns.find(col1 => {
        const k = col1.dataIndex || col1.key;
        return k === key;
      });
      const minLength = col
        ? col.title
          ? col.title.length
          : col.ctitle
          ? col.ctitle.length
          : 1
        : 1;
      //console.log("children",children,col);
      if (!col) {
        if (key === "selection-column")
          // 全选列
          return (
            <th {...restProps} align="center" style={{ textAlign: "center" }}>
              {children}
            </th>
          );
        else return <th {...restProps}>{children}</th>;
      } else if (!col.width) {
        return (
          <th {...restProps} align={col.align} style={{ textAlign: col.align }}>
            {children}
          </th>
        );
      } else if (
        Object.prototype.toString.call(col.width) === "[object String]"
      ) {
        let reg = new RegExp("^((\\d+\\.?\\d*)|(\\d*\\.\\d+))$", "ig");
        let reg1 = new RegExp("^((\\d+\\.?\\d*)|(\\d*\\.\\d+))px$", "ig");
        let reg2 = new RegExp("^((\\d+\\.?\\d*)|(\\d*\\.\\d+))\\%$", "ig");
        let tempValue = col.width;
        if (reg.test(tempValue)) {
          // 数值字符串
          col.width = parseFloat(tempValue);
        } else if (reg1.test(tempValue)) {
          //px值
          tempValue = tempValue.substring(0, tempValue.length - 2);
          col.width = parseFloat(tempValue);
        } else if (reg2.test(tempValue)) {
          // 百分比值,暂不支持
          col.width = 100;
        }
      } else if (key === "operation-column") {
        // 操作列
        return (
          <th
            {...restProps}
            align={col.align}
            width={col.width}
            style={{ textAlign: col.align }}
          >
            {children}
          </th>
        );
      }
      /* else if (key === lastKey) { // 最后一列，并且是非操作列
        return <th {...restProps} align={col.align} width={Math.max(col.width, minLength*25)} style={{textAlign: col.align}} >{children}</th>;
      }*/

      /**
       * 拖动中
       * @param x
       * @param y
       */
      const onDrag = (x, y) => {
        draggingState[key] = 0;
        //console.log(children[0].children[0].text,"的最大长度",leng*20+16);
        col.width = Math.max(x, minLength * tableThResizeable.oneCnCharPX + 20);
      };
      /**
       * 拖动结束
       * parent={true} onDragStart={checkDrag}
       */
      const onDragstop = x => {
        //console.log("拖动，",x, y);
        draggingState[key] = thDom.getBoundingClientRect().width;
      };
      /**
       * 双击表头，根据内容宽度自适应列宽
       * @param e
       */
      const onDBLClick = e => {
        //console.log("双击列，",e);
        if (e && e.path && e.path.length > 5) {
          let nowTh = e.path.find(function(e1) {
            return e1.tagName === "TH" && e1.className === "resize-table-th";
          });
          let nowFontsize = tableThResizeable.getStyle(nowTh, "fontSize");
          if (nowFontsize) {
            nowFontsize = nowFontsize.substring(0, nowFontsize.length - 2);
            tableThResizeable.oneCnCharPX = parseFloat(nowFontsize) + 4;
          }
          let index = nowTh ? nowTh.cellIndex : -1;
          let maxLength = minLength;
          if (index >= 0) {
            let tableScroll = e.path.find(function(e1) {
              return (
                e1.className === "ant-table-scroll" && e1.tagName === "DIV"
              );
            });
            let table;
            if (tableScroll) {
              // 是table-scroll类型
              for (let yy = 0; yy < tableScroll.childNodes.length; yy++) {
                if (tableScroll.childNodes[yy].className === "ant-table-body") {
                  table = tableScroll.childNodes[yy].querySelector("table");
                  break;
                }
              }
            } else {
              // 普通table
              table = e.path.find(function(e1) {
                return e1.tagName === "TABLE";
              });
            }
            //console.log("双击列，", table);
            if (table)
              //
              for (let ii = 0; ii < table.rows.length; ii++) {
                //遍历index列中的每一行的文本内容
                //console.log("双击列数据，", table.rows[ii].cells[index].innerText);
                let textLength = tableThResizeable.getTextMaximumLength(
                  table.rows[ii].cells[index].innerText
                );
                maxLength = Math.max(textLength, maxLength);
              }
          }
          //console.log("双击列数据最大长度，", maxLength);
          draggingState[key] = maxLength * tableThResizeable.oneCnCharPX + 20;
          col.width = draggingState[key];
          //console.log("双击列数据最大长度，", draggingState[key]);
        }
      };

      return (
        <th
          {...restProps}
          v-ant-ref={r => (thDom = r)}
          width={col.width}
          class="resize-table-th"
          style={{ textAlign: col.align }}
          align={col.align}
          ondblclick={onDBLClick}
        >
          {children}
          <vue-draggable-resizable
            key={col.key}
            class="table-draggable-handle"
            w={10}
            x={draggingState[key] || col.width}
            z={1}
            axis="x"
            grid={[25, 25]}
            active={true}
            prevent-deactivation={true}
            disable-user-select={true}
            draggable={true}
            resizable={false}
            onDragging={onDrag}
            onDragstop={onDragstop}
          ></vue-draggable-resizable>
        </th>
      );
    };
    return ResizeableTitle;
  }
};
export { tableThResizeable };
