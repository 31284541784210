<!-- 产品入库配置序列号窗口 -->
<template>
  <a-modal
    class="e-config-serial__modal"
    v-model="visible"
    v-if="isRedraw ? visible : true"
    title="序列号管理"
    :bodyStyle="{ width: 'auto', maxHeight: maxHeight, maxWidth: allWidth }"
    :width="allWidth"
    :centered="true"
  >
    <div>
      <div class="e-config-serial__title" style="display: none;">
        <div><a-icon type="caret-down" />明细物资</div>
      </div>
      <a-form
        :form="form"
        layout="horizontal"
        :class="'e_form_' + $config.defaultControlSize"
      >
        <a-tabs
          :activeKey="nowTab"
          tab-position="left"
          :style="{ height: '350px', width: '100%' }"
          @change="callback"
          @prevClick="onPrevClick"
          @nextClick="onNextClick"
        >
          <a-tab-pane
            v-for="(product, index) in thisProducts"
            :key="index + 1"
            forceRender
          >
            <div slot="tab" class="e-config-serial__tabTitle">
              <a
                href="javaScript: void(0);"
                :title="product.name"
                :class="getNowSelectTabClass(index + 1)"
                >(行号{{ product.HH }}){{ product.name }}</a
              >
            </div>
            <div class="e-config-serial__form">
              <a-form-item
                v-for="i in product.num"
                :label="'序列号' + i"
                :key="product.name + i"
                :labelCol="{ span: 4 }"
                :wrapperCol="{ span: 19 }"
                colon
                selfUpdate
              >
                <e-input
                  :readOnly="readOnly"
                  :disabled="disabled"
                  v-decorator="[
                    product.HH + '_' + i,
                    {
                      initialValue: product.serials[product.HH + '_' + i]
                        ? product.serials[product.HH + '_' + i]
                        : '',
                      rules: product.rules ? product.rules : []
                    }
                  ]"
                ></e-input>
              </a-form-item>
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-form>
    </div>
    <div slot="footer" class="e-form-modal-footer" :class="buttonAlignClass">
      <!-- 默认确定按钮 -->
      <a-button
        :disabled="buttonDisabled"
        class="headButton"
        type="primary"
        :ghost="okButton.ghost"
        :loading="okButton.loading"
        :data-config="okButton"
        @click="onButtonClick(okButton)"
        >{{ okButton.title }}</a-button
      ><!--确定按钮图标和按钮尺寸 :icon="button.icon" :size="$config.defaultControlSize"-->
      <div v-if="showCancel" class="divider-block-min"></div>
      <!-- 默认取消按钮 -->
      <a-button
        v-if="showCancel"
        :disabled="buttonDisabled"
        class="headButton"
        type="primary"
        :ghost="cancelButton.ghost"
        :loading="cancelButton.loading"
        :data-config="cancelButton"
        @click="onButtonClick(cancelButton)"
        >{{ cancelButton.title }}</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
export default {
  mixins: [pageControls],
  name: "EConfigureProductSerialModal",
  data() {
    return {
      form: null,
      thisProducts: [],
      maxHeight: "1000px",
      imageLoaded: false,
      buttonDisabled: false,
      readOnly: false,
      disabled: false,
      nowTab: 1,
      okButton: {
        title: "确定",
        icon: "check",
        key: "ok",
        loading: false,
        system: true,
        returnData: true
      },
      cancelButton: {
        title: "取消",
        icon: "close",
        key: "cancel",
        system: true,
        loading: false
      }
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    buttonAlign: {
      type: String,
      default: "right"
    },
    showCancel: {
      type: Boolean,
      default: false
    },
    products: {
      type: Array,
      default: function() {
        return [];
      }
    },
    redraw: {
      // 显示时默认只初始化一次
      type: Boolean,
      default: false
    },
    editTab: {
      type: String,
      default: null
    }
  },
  computed: {
    isRedraw: function() {
      return this.redraw;
    },
    visible: {
      get: function() {
        //console.log("弹窗显示",this.value );
        return this.value;
      },
      set: function(v) {
        this.$emit("input", v);
      }
    },
    allWidth: function() {
      let maxWidth = this.$root.NowWindowsSize.width * 0.6;
      if (maxWidth < 700) return maxWidth + "px";
      else return 700 + "px";
    },
    buttonAlignClass: function() {
      if (this.buttonAlign) {
        if (this.buttonAlign === "right") {
          return "e-form-modal-footer__right";
        } else if (this.buttonAlign === "center") {
          return "e-form-modal-footer__center";
        } else if (this.buttonAlign === "space-between") {
          return "e-form-modal-footer__space-between";
        }
      }
      return "";
    }
  },
  watch: {
    products: {
      deep: true,
      handler: function(v) {
        if (v) this.thisProducts = v;
        else this.thisProducts = [];
        this.getNeedEditTab();
      }
    },
    editTab: {
      immediate: true,
      handler: function(v) {
        console.log("监听到标签页切换", v);
        if (v) {
          this.nowTab = this.$util.getNumber(v);
        } else {
          this.nowTab = 1;
        }
      }
    }
  },
  created() {
    this.thisProducts = this.products;
    try {
      this.getNeedEditTab();
      this.form = this.$form.createForm(this, {
        name: "ConfigureProductSerialModal_" + Math.random()
      });
      let windowSize = this.$util.findDimensions();
      if (windowSize) {
        let mHeight = windowSize.height - 55;
        this.maxHeight = mHeight + "px"; // 减去底部按钮区域高度，获得内容区域最大高度
      }
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    /**
     * 从当前可编辑项中找到最近一个需要编辑的tab标签
     */
    getNeedEditTab: function() {
      if (this.thisProducts) {
        let index = this.thisProducts.findIndex((p, index) => {
          if (!this.$util.isValidObject(p.serials)) {
            this.nowTab = index + 1;
            return true;
          }
          return false;
        });
        if (index == -1) {
          this.nowTab = 1;
        }
      }
    },
    /**
     * 获取当前选中的tab样式
     * @param val
     * @return {string}
     */
    getNowSelectTabClass: function(val) {
      if (val == this.nowTab) {
        return "e-config-serial__tabSelected";
      }
      return "";
    },
    /**
     * 按钮事件 提交
     * @param button
     */
    onButtonClick(button) {
      if (button.system && button.key === "cancel") {
        this.visible = false;
      }
      if (button.returnData) {
        let that = this;
        if (this.form) {
          // 是模板表单
          this.form.validateFields((err, values) => {
            if (!err) {
              let data = values;
              this.buttonDisabled = true;
              //console.log("点击了提交数据按钮");
              button.loading = true;
              let promise1 = new Promise(resolve => {
                that.$emit(button.key + "Clicked", {
                  resolve: resolve,
                  key: button.key,
                  data: data
                });
                that.$emit("buttonClicked", {
                  resolve: resolve,
                  key: button.key,
                  data: data
                });
              }).catch(() => console.error("弹出框按钮异常"));
              // 异步响应上方的Promise类提供的resolve的，执行结果
              promise1.then(function(v) {
                //console.log("确认按钮操作完成",value);
                if (v) {
                  // 如果带返回值，则不关闭页面
                  button.loading = false;
                  that.buttonDisabled = false;
                } else {
                  button.loading = false;
                  that.visible = false;
                  that.buttonDisabled = false;
                }
              });
            } else {
              let errMsg = that.getDecoratorErrInfo(err);
              that.alertError(errMsg);
            }
          });
        } else {
          // 非模板表单
          this.buttonDisabled = true;
          //console.log("点击了提交数据按钮");
          button.loading = true;
          let promise2 = new Promise(resolve => {
            that.$emit(button.key + "Clicked", {
              resolve: resolve,
              key: button.key
            });
            that.$emit("buttonClicked", {
              resolve: resolve,
              key: button.key
            });
          }).catch(() => console.error("弹出框按钮异常"));
          // 异步响应上方的Promise类提供的resolve的，执行结果
          promise2.then(function(v) {
            //console.log("确认按钮操作完成",value);
            if (v) {
              // 如果带返回值，则不关闭页面
              button.loading = false;
              that.buttonDisabled = false;
            } else {
              button.loading = false;
              that.visible = false;
              that.buttonDisabled = false;
            }
          });
        }
      } else {
        this.$emit(button.key + "Clicked");
        this.$emit("buttonClicked", {
          key: button.key
        });
      }
    },
    callback(val) {
      console.log(val);
      this.nowTab = val;
    },
    onPrevClick(val) {
      console.log("onPrevClick", val);
    },
    onNextClick(val) {
      console.log("onPrevClick", val);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../assets/style/base/components/eConfigure-product-serial-modal.less";
</style>
