<template>
  <home class="home" :notice="0" :oEvent="3" showName="我的E1"></home>
</template>

<script>
import { pageControls } from "@/mixins/pageControls.js"; // 引入一些界面操作方法
import Home from "@/views/notice/Home.vue";

export default {
  name: "Main",
  mixins: [pageControls],
  components: {
    Home
  },
  data: function() {
    return {
      menuCollapsed: this.collapsed
    };
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  created() {},
  watch: {
    collapsed: function(n) {
      this.menuCollapsed = n;
    }
  },
  computed: {
    windowsSize: function() {
      return this.getWindowSize();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../assets/style/base/main.less";
</style>
