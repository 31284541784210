var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body",staticStyle:{"margin":"0px !important"}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"box-left"},[_c('div',{staticClass:"logo"},[_c('img',{staticStyle:{"float":"left"},attrs:{"src":'/img/login1/' + _vm.config.logoUrl}}),_c('div',{staticClass:"title",staticStyle:{"float":"left"}},[_vm._v(" "+_vm._s(_vm.config.systemName)+" ")])])]),_c('div',{staticClass:"box-right"},[_c('div',{staticStyle:{"height":"90%"}},[_c('div',{staticStyle:{"padding-top":"25%"}},[_c('a-form',{style:({ 'padding-left': '10%', 'padding-right': '10%' }),attrs:{"form":_vm.form}},[_c('a-form-item',{staticStyle:{"width":"100%","text-align":"left"}},[_c('div',{staticClass:"title-next"},[_vm._v(_vm._s(_vm.config.welcome))])]),_c('a-form-item',{attrs:{"prop":"account"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.userInputRules),expression:"userInputRules"}],ref:"userInput",style:({ padding: '10px 0' }),attrs:{"allowClear":true,"placeholder":"请输入账号","size":"large"},on:{"change":_vm.trimInput,"pressEnter":_vm.handleSubmit}},[_c('template',{slot:"prefix"},[_c('a-icon',{style:({ fontSize: '24px' }),attrs:{"type":"user"}})],1)],2)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.passInputRules),expression:"passInputRules"}],staticClass:"login-password-input",style:({ padding: '10px 0' }),attrs:{"type":"password","placeholder":"请输入密码"},on:{"change":_vm.trimInput,"pressEnter":_vm.handleSubmit}},[_c('template',{slot:"prefix"},[_c('a-icon',{style:({ fontSize: '24px' }),attrs:{"type":"lock"}})],1)],2)],1),_c('a-form-item',[_c('a-button',{style:({
                  width: '100%',
                  margin: '10px 0',
                  height: '50px',
                  'font-size': '20px',
                  'background-image':
                    'linear-gradient(to right, #569be8 , #351bc6)'
                }),attrs:{"type":"primary","loading":_vm.loading},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_vm._v("登录 ")])],1)],1)],1)]),_c('div',{staticClass:"bottom-text"},[_vm._v(_vm._s(_vm.config.companyName))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }