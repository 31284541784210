<!-- 表单模板解析转换
v-model="thisMainTableData[column.configs.fieldName]"
initialValue: thisMainTableData[column.configs.fieldName] ,
initialValue: column.configs.defaultValue,
 :width="column.size.w" -->
<template>
  <a-form
    :form="form"
    layout="horizontal"
    :class="'e_form_' + $config.defaultControlSize"
    :style="{ minWidth: formMinWidth, marginRight: '5px' }"
  >
    <a-row
      :gutter="24"
      v-for="(row, rowIndex) in thisFormRows"
      :key="row.key"
      justify="start"
      align="middle"
      style="margin-right:unset;width:100%;"
      :class="formRowItemClass"
      v-show="row.columns.length > 0"
    >
      <template v-for="(column, colIndex) in row.columns">
        <!-- 如果这个控件被隐藏，则渲染为隐藏域 :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }" -->
        <a-col
          v-if="
            column.isShow != false &&
              (column.thisControlType === 'EGroupBox' ||
                column.thisControlType === 'Text' ||
                column.configs.fieldName)
          "
          :span="calculationColspan(row, column)"
          :key="column.configs.fieldName+'_'+randomKey+'_'+formElementsDisabled[column.configs.fieldName]"
          style="padding: 0;"
        >
          <div v-if="column.thisControlType === 'Text'" class="e_form_text">
            <!-- 普通文本显示 -->
            <span :style="{ color: column.foreColor }">{{ column.text }}</span>
          </div>
          <a-form-item
            v-if="column.thisControlType !== 'EGroupBox'"
            :label="column.label"
            :colon="column.label ? true : false"
            :wrapperCol="
              !column.label &&
              column.thisControlType !== 'ECheckbox' &&
              column.thisControlType !== 'EBooleanCheckbox'
                ? { push: 8, span: 24 }
                : {}
            "
            :key="'form_item_'+column.configs.fieldName"
            :class="column.label ? '' : 'e-form-item__noLabel'"
            :selfUpdate="true"
            :ref="rowIndex == 0 && colIndex == 0 ? 'firstInput' : null"
          >
            <!-- 文本域-不可编辑  -->
            <!-- 文本框如果给定了指定值，则可以采取这种显示名字，隐藏域保存值  {{ formElementsDisabled[column.configs.fieldName]+''}}-->
            <template
              v-if="
                column.thisControlType === 'EInput' &&
                  $util.isType(thisMainTableData[column.configs.fieldName]) ==
                    'Object'
              "
            >
              {{ thisMainTableData[column.configs.fieldName].label }}
              <input
                type="hidden"
                v-decorator="[
                  column.configs.fieldName,
                  {
                    initialValue:
                      thisMainTableData[column.configs.fieldName].value
                  }
                ]"
                @change="
                  v => {
                    doChange(column.configs, v);
                  }
                "
              />
            </template>
            <!-- 普通文本域  readOnly属性由“是否禁用disabled”和“是否只读”两个参数共同决定，disabled=true表示禁用，readOnly=true表示只读-->
            <e-input
              v-if="
                column.thisControlType === 'EInput' &&
                  $util.isType(thisMainTableData[column.configs.fieldName]) !=
                    'Object'
              "
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :width="column.size.w"
              :type="column.setting.type"
              :tip="column.nextTip"
              :readOnly="
                formElementsDisabled[column.configs.fieldName] &&
                  column.configs.readOnly
              "
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              :linkage="column.configs.linkageField"
              :format="column.format"
              @focus="doFocus(column.configs.fieldName)"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName]
                    ? thisMainTableData[column.configs.fieldName]
                    : column.configs.defaultValue,
                  rules: column.configs.rules
                }
              ]"
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              @change="
                v => {
                  doChange(column.configs, v);
                }
              "
            ></e-input>

            <!-- 日期时间选择器 :ref="'EDatetimePicker_' + column.configs.fieldName"-->
            <e-datetime-picker
              v-if="column.thisControlType === 'EDatetimePicker'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :width="column.size.w"
              :mode="column.mode ? column.mode : column.setting.mode"
              :format="column.format ? column.format : column.setting.format"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              :linkage="column.configs.linkageField"
              :readOnly="column.configs.readOnly"
              :maxTime="maxDate[column.configs.fieldName]"
              :minTime="minDate[column.configs.fieldName]"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                values => {
                  doChange(column.configs, values);
                }
              "
            ></e-datetime-picker>

            <!-- 时间日期范围选择器 -->
            <e-time-horizon
              v-if="column.thisControlType === 'ETimeHorizon'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :width="column.size.w"
              :mode="column.mode ? column.mode : column.setting.mode"
              :format="column.format ? column.format : column.setting.format"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              :linkage="column.configs.linkageField"
              :readOnly="column.configs.readOnly"
              :maxTime="maxDate[column.configs.fieldName]"
              :minTime="minDate[column.configs.fieldName]"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                values => {
                  doChange(column.configs, values);
                }
              "
            ></e-time-horizon>

            <!--:defaultValue="column.configs.defaultValue" -->
            <!-- 部门选择器 -->
            <e-department-select
              v-if="column.thisControlType === 'EDepartmentSelect'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :width="column.size.w"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              :mode="column.multiSelect"
              :filter="column.filter"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              @linkedChange="doLinkedChange"
              :linkage="column.configs.linkageField"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                (values, names) => {
                  doChange(column.configs, values, names);
                }
              "
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
            ></e-department-select>

            <!-- 用户选择器 -->
            <e-employee-select
              v-if="column.thisControlType === 'EEmployeeSelect'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :width="column.size.w"
              :filter="column.filter"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              :mode="column.multiSelect"
              :keyType="column.keyType"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              @linkedChange="doLinkedChange"
              :linkage="column.configs.linkageField"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                (values, names) => {
                  doChange(column.configs, values, names);
                }
              "
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
            ></e-employee-select>

            <!-- 供应商商户选择器 -->
            <e-supplier-select
              v-if="column.thisControlType === 'ESupplierSelect'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :width="column.size.w"
              @linkedChange="doLinkedChange"
              :filter="column.filter"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              :mode="column.multiSelect"
              :keyType="column.keyType"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              :linkage="column.configs.linkageField"
              @focus="doFocus(column.configs.fieldName)"
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              @change="
                (values, names) => {
                  doChange(column.configs, values, names);
                }
              "
              :bdmbId="tempId"
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-supplier-select>

            <!-- 通用下拉列表 ? variableFilter[column.configs.fieldName] : column.listFilter  :ref="'CONTROL_' + column.configs.fieldName + '_' + randomKey"-->
            <e-select
              v-if="column.thisControlType === 'ESelect'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :emptyOption="column.emptyOption"
              :filter="column.listFilter"
              :options="column.options"
              :setting="column.setting"
              :mode="column.multiSelect"
              :defaultSelectedIndex="
                column.defaultSelectedIndex ? column.defaultSelectedIndex : 0
              "
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              :width="column.size.w"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              :linkage="column.configs.linkageField"
              @linkedChange="doLinkedChange"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                (values, names) => {
                  doChange(column.configs, values, names);
                }
              "
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-select>

            <!-- 复选框 -->
            <e-checkbox
              v-if="column.thisControlType === 'ECheckbox'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :options="column.options"
              :filter="column.listFilter"
              :setting="column.setting"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              :linkage="column.configs.linkageField"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                (values, names) => {
                  doChange(column.configs, values, names);
                }
              "
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-checkbox>

            <!-- 单选框 -->
            <e-radio
              v-if="column.thisControlType === 'ERadio'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :options="column.options"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              :linkage="column.configs.linkageField"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                (values, names) => {
                  doChange(column.configs, values, names);
                }
              "
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-radio>

            <!-- 是否选择器 -->
            <e-boolean-checkbox
              v-if="column.thisControlType === 'EBooleanCheckbox'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :width="column.size.w"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              :trueLabel="column.text1"
              :falseLabel="column.text2"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                v => {
                  doChange(column.configs, v);
                }
              "
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-boolean-checkbox>

            <!-- 数字选择器 -->
            <e-numeric-up-down
              v-if="column.thisControlType === 'ENumericUpDown'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :width="column.size.w"
              :max="column.maximum"
              :min="column.minimum"
              :step="column.increment"
              :tip="column.nextTip"
              :defaultValue="column.configs.defaultValue"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              :linkage="column.configs.linkageField"
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              :precision="column.decimalPlaces"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              :separator="column.thousandsSeparator"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                v => {
                  doChange(column.configs, v);
                }
              "
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-numeric-up-down>

            <!-- 数字范围选择器 -->
            <e-number-horizon
              v-if="column.thisControlType === 'ENumberHorizon'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :width="column.size.w"
              :max="column.maximum"
              :min="column.minimum"
              :step="column.increment"
              :tip="column.nextTip"
              :defaultValue="column.configs.defaultValue"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              :linkage="column.configs.linkageField"
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              :precision="column.decimalPlaces"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              :separator="column.thousandsSeparator"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                v => {
                  doChange(column.configs, v);
                }
              "
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-number-horizon>

            <!-- 物资控件 -->
            <e-materials
              v-if="column.thisControlType === 'EMaterials'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :bdmbId="tempId"
              :setting="column.setting"
              :width="column.size.w"
              :mode="column.multiSelect"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              :keyType="column.keyType"
              :filter="column.filter"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              @linkedChange="doLinkedChange"
              :linkage="column.configs.linkageField"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                (values, names) => {
                  doChange(column.configs, values, names);
                }
              "
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
            ></e-materials>

            <!-- 物资分类选择控件 -->
            <e-materials-classify
              v-if="column.thisControlType === 'EMaterialsClassify'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :keyType="column.keyType"
              :readOnly="column.configs.readOnly"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :bdmbId="tempId"
              :mode="column.multiSelect"
              :setting="column.setting"
              :width="column.width"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              @linkedChange="doLinkedChange"
              :linkage="column.configs.linkageField"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                (values, names) => {
                  doChange(column.configs, values, names);
                }
              "
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-materials-classify>
            <!-- 仓库选择器 -->
            <e-inventory-location
              v-if="column.thisControlType === 'EInventoryLocation'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :keyType="column.keyType"
              :readOnly="column.configs.readOnly"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :bdmbId="tempId"
              :mode="column.multiSelect"
              :defaultDataType="column.defaultDataType"
              :defaultDataValue="column.defaultDataValue"
              :setting="column.setting"
              :width="column.width"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              @linkedChange="doLinkedChange"
              :linkage="column.configs.linkageField"
              @focus="doFocus(column.configs.fieldName)"
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              @change="
                (values, names) => {
                  doChange(column.configs, values, names);
                }
              "
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-inventory-location>
            <!-- 仓库货位选择器 -->
            <e-s-t-warehouse
              v-if="column.thisControlType === 'ESTWarehouse'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :keyType="column.keyType"
              :readOnly="column.configs.readOnly"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :bdmbId="tempId"
              :mode="column.multiSelect"
              :setting="column.setting"
              :width="column.width"
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              @linkedChange="doLinkedChange"
              :linkage="column.configs.linkageField"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                (values, names) => {
                  doChange(column.configs, values, names);
                }
              "
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-s-t-warehouse>
            <!-- 客户选择器-->
            <e-customer
              v-if="column.thisControlType === 'ECustomer'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :setting="column.setting"
              :width="column.size.w"
              :mode="column.multiSelect"
              :filter="column.filter"
              :keyType="column.keyType"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              @linkedChange="doLinkedChange"
              :linkage="column.configs.linkageField"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                (values, names) => {
                  doChange(column.configs, values, names);
                }
              "
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-customer>

            <!-- 表单模板选择器 -->
            <e-gy-bdmb
              v-if="column.thisControlType === 'EGyBdmb'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :filter="column.filter"
              :setting="column.setting"
              :mode="column.multiSelect"
              :width="column.size.w"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              @linkedChange="doLinkedChange"
              :linkage="column.configs.linkageField"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                (values, names) => {
                  doChange(column.configs, values, names);
                }
              "
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-gy-bdmb>

            <!-- 岗级定义 -->
            <e-rs-gjdy
              v-if="column.thisControlType === 'ERsGjdy'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :setting="column.setting"
              :width="column.size.w"
              :mode="column.multiSelect"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              @click="doClick(column.configs.fieldName)"
              @blur="doBlur(column.configs.fieldName)"
              @linkedChange="doLinkedChange"
              :linkage="column.configs.linkageField"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                (values, names) => {
                  doChange(column.configs, values, names);
                }
              "
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-rs-gjdy>

            <template v-if="column.thisControlType === 'EUpload'">
              <e-upload
                v-if="
                  !formElementsDisabled[column.configs.fieldName] &&
                    !column.configs.disabled
                "
                :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
                :key="column.key"
                :visible="column.isShow"
                :setting="column.setting"
                :width="column.size.w"
                :disabled="
                  formElementsDisabled[column.configs.fieldName] ||
                    column.configs.disabled
                "
                :readOnly="column.configs.readOnly"
                @click="doClick(column.configs.fieldName)"
                @linkedChange="doLinkedChange"
                @blur="doBlur(column.configs.fieldName)"
                @focus="doFocus(column.configs.fieldName)"
                @change="
                  v => {
                    doChange(column.configs, v);
                  }
                "
                :modifiedValue="thisModifiedData[column.configs.fieldName]"
                v-decorator="[
                  column.configs.fieldName,
                  {
                    initialValue: thisMainTableData[column.configs.fieldName],
                    rules: column.configs.rules
                  }
                ]"
              ></e-upload>

              <!-- 文件查看器 -->
              <EFilePreview
                v-else
                :key="column.configs.fieldName + '_control' + randomKey"
                :visible="column.isShow"
                :setting="column.setting"
                :width="column.size.w"
                :modifiedValue="thisModifiedData[column.configs.fieldName]"
                v-decorator="[
                  column.configs.fieldName,
                  {
                    initialValue: thisMainTableData[column.configs.fieldName],
                    rules: column.configs.rules
                  }
                ]"
              ></EFilePreview>
            </template>

            <!-- 会计科目 -->
            <e-accounting-subject
              v-if="column.thisControlType === 'EAccountingSubject'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :filter="column.filter"
              :companyId="column.companyId"
              :keyType="column.keyType"
              :setting="column.setting"
              :width="column.size.w"
              :mode="column.multiSelect"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              @click="doClick(column.configs.fieldName)"
              @linkedChange="doLinkedChange"
              @blur="doBlur(column.configs.fieldName)"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                (values, names) => {
                  doChange(column.configs, values, names);
                }
              "
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-accounting-subject>

            <!-- 折旧计算公式控件 -->
            <e-zjgs
              v-if="column.thisControlType === 'EZjgs'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :setting="column.setting"
              :width="column.size.w"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              @click="doClick(column.configs.fieldName)"
              @linkedChange="doLinkedChange"
              @blur="doBlur(column.configs.fieldName)"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                v => {
                  doChange(column.configs, v);
                }
              "
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-zjgs>

            <!-- 薪资计算公式控件 -->
            <e-xzjs
              v-if="column.thisControlType === 'EXzjs'"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.configs.fieldName"
              :key="column.key"
              :visible="column.isShow"
              :type="column.formulaType"
              :setting="column.setting"
              :width="column.size.w"
              :disabled="
                formElementsDisabled[column.configs.fieldName] ||
                  column.configs.disabled
              "
              :readOnly="column.configs.readOnly"
              @click="doClick(column.configs.fieldName)"
              @linkedChange="doLinkedChange"
              @blur="doBlur(column.configs.fieldName)"
              @focus="doFocus(column.configs.fieldName)"
              @change="
                v => {
                  doChange(column.configs, v);
                }
              "
              :modifiedValue="thisModifiedData[column.configs.fieldName]"
              v-decorator="[
                column.configs.fieldName,
                {
                  initialValue: thisMainTableData[column.configs.fieldName],
                  rules: column.configs.rules
                }
              ]"
            ></e-xzjs>
            <!-- 其他控件？ -->
            <!-- configs -->
          </a-form-item>
          <!-- GroupBox 容器控件 -->
          <e-group-box
            v-if="!isNewTheme && column.thisControlType === 'EGroupBox'"
            :ref="randomKey+'_'+column.thisControlType+'_' + column.text"
            :childForm="column.formRows"
            :bdmbId="tempId"
            :key="column.key"
            :form="form"
            :width="column.size.w"
            :formName="column.text"
            :colSpan="column.colSpan"
            :mainTableData="thisMainTableData"
            :formEditOver="thisFormEditOver"
            :modifiedValues="thisModifiedData"
            @change="doChange"
            :disabled="
              thisFormDisable || column.disable || column.enabled == false
            "
            :fieldsAllowedEdit="thisFieldsAllowedEdit"
          ></e-group-box>
          <!-- 新主题框架下的分组控件 -->
          <a-form-item
            v-if="isNewTheme && column.thisControlType === 'EGroupBox'"
            :label="column.text"
            :colon="column.text ? true : false"
            :wrapperCol="!column.text ? { push: 8, span: 24 } : {}"
            :class="column.text ? '' : 'e-form-item__noLabel'"
            :key="'form_item_'+column.configs.text"
            :selfUpdate="true"
          >
            <e-group-box
              :childForm="column.formRows"
              :ref="randomKey+'_'+column.thisControlType+'_' + column.text"
              :bdmbId="tempId"
              :key="column.key"
              :form="form"
              :width="column.size.w"
              :colSpan="column.colSpan"
              :mainTableData="thisMainTableData"
              :formEditOver="thisFormEditOver"
              :modifiedValues="thisModifiedData"
              @change="doChange"
              :disabled="
                thisFormDisable || column.disable || column.enabled == false
              "
              :fieldsAllowedEdit="thisFieldsAllowedEdit"
            ></e-group-box>
          </a-form-item>
        </a-col>
        <!-- 隐藏域  -->
        <a-form-item
          v-else-if="column.configs.fieldName"
          v-show="false"
          :key="'form_item_'+column.configs.fieldName"
          :selfUpdate="true"
          style="height: 0;margin: 0;padding: 0;"
        >
          <!-- {{column.label}}：{{thisMainTableData[column.configs.fieldName]}}-->
          <input
            type="hidden"
            :key="column.key"
            v-decorator="[
              column.configs.fieldName,
              { initialValue: thisMainTableData[column.configs.fieldName] }
            ]"
          />
        </a-form-item>
      </template>
    </a-row>
  </a-form>
</template>

<script>
import { mathAlgorithms } from "@/mixins/mathAlgorithms.js"; // 表单数据计算方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { e1DateTimeSelectRangeControls } from "@/mixins/e1DateTimeSelectRangeControls.js"; // 时间控件选择范围控制支持方法
export default {
  mixins: [mathAlgorithms, pageControls, e1DateTimeSelectRangeControls],
  name: "FormTemplate",
  props: {
    value: {
      type: Object,
      default: function() {
        return {};
      }
    },
    colSpan: {
      // 定义表单项分布的参数
      type: Number,
      default: 6
    },
    formRows: {
      // 定义表格表单项
      type: Array,
      default: function() {
        return [];
      }
    },
    tempId: {
      // 当前的模板id
      type: [Number, String],
      default: function() {
        return null;
      }
    },
    templateName: {
      // 当前模板的名称
      type: String,
      default: function() {
        return null;
      }
    },
    mainTableData: {
      //主表数据
      type: Object,
      default: function() {
        return {};
      }
    },
    modifiedData: {
      // 主表单中修改的内容
      type: Object,
      default: function() {
        return {};
      }
    },
    formDisable: {
      // 表单可编辑状态，但是排除明确标识了可编辑字段
      type: Boolean,
      default: false
    },
    formEditOver: {
      // 表单可编辑状态,整体概念的不可编辑
      type: Boolean,
      default: false
    },
    form: {
      // 用来接收父表单的form对象，用于表单嵌套时的数据获取和校验
      type: Object,
      default: function() {
        return this.$form.createForm(this, { name: "coordinated" });
      }
    },
    formulaConfig: {
      // 计算公式配置
      type: Array,
      default: function() {
        return [];
      }
    },
    width: {
      type: Number,
      default: function() {
        return 0;
      }
    },
    fieldsAllowedEdit: {
      // 允许编辑的字段列表，和formDisable字段配合使用，formDisable为true时本字段配置有效
      type: Array,
      default: function() {
        return null;
      }
    },
    showModalType: {
      type: Boolean,
      default: false
    },
    tabForm: { //是否是tab
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      // 表单
      oldMainTableObject: {},
      nowData: {},
      thisFormRows: [],
      thisFormExtends: null,
      thisFormDisable: false,
      thisWidth: 0,
      defaultWidth: 1480,
      thisFieldsAllowedEdit: null,
      formElementsDisabled: {},
      oldFormElementsDisabled: {},
      thisFormEditOver: false,
      haveEditFields: false, // 是否设置了可编辑项
      chineseName: {}, // 所有选择控件，对应的中文
      labelCnName: {}, //所有控件对应的控件名
      thisModifiedData: {},
      variableFilter: {},
      randomKey: new Date().getTime() + "_" + Math.random()
    };
  },
  watch: {
    nowData: function(v) {
      this.$emit("input", v);
    },
    fieldsAllowedEdit: function(newFields) {
      if (!this.thisFormEditOver && this.thisFormDisable) {
        // 如果是表单不可编辑状态，监听设置可编辑字段
        //console.log("设置可编辑状态的字段",newFields);
        this.thisFieldsAllowedEdit = newFields;
        if (newFields != null && newFields.length > 0) {
          //设置对应字段可编辑
          for (let i = 0; i < newFields.length; i++) {
            this.$set(this.formElementsDisabled, newFields[i], false);
          }
          this.haveEditFields = true;
        } else {
          // 否则全面禁用
          let paramKeys = Object.keys(this.formElementsDisabled);
          for (let key = 0; key < paramKeys.length; key++) {
            this.$set(this.formElementsDisabled, paramKeys[key], true);
          }
          this.haveEditFields = false;
        }
        //this.$forceUpdate();
      }
    },
    formEditOver: function(newState) {
      let paramKeys = Object.keys(this.formElementsDisabled);
      if (newState === true) {
        // 表单全面禁用
        for (let key = 0; key < paramKeys.length; key++) {
          this.$set(this.formElementsDisabled, paramKeys[key], newState);
        }
      } else {
        // 表单编辑放开
        for (let key1 = 0; key1 < paramKeys.length; key1++) {
          this.$set(
            this.formElementsDisabled,
            paramKeys[key1],
            this.oldFormElementsDisabled[paramKeys[key1]]
          );
        }
      }
      this.thisFormEditOver = newState;
      console.log("表单编辑状态变更，", this.formElementsDisabled);
    },
    mainTableData: function(newTable) {
      //console.log("表数据变了", newTable);
      let newObject = {};
      let paramKeys = Object.keys(this.oldMainTableObject);
      for (let key = 0; key < paramKeys.length; key++) {
        // 只设置字段值发生变化的字段
        if (
          newTable[paramKeys[key]] &&
          !this.$util.isObjEqual(
            this.oldMainTableObject[paramKeys[key]],
            newTable[paramKeys[key]]
          )
        ) {
          newObject[paramKeys[key]] = newTable[paramKeys[key]]
            ? newTable[paramKeys[key]]
            : null;
        }
      }
      console.log("表数据变了", newObject);
      //this.form.resetFields();
      this.form.setFieldsValue(newObject);
    },
    modifiedData: function(newData) {
      console.log("申请修改表单内容：", newData);
      if (newData) {
        this.thisModifiedData = newData;
      } else {
        this.thisModifiedData = {};
      }
    },
    thisFormRows: function(v) {
      this.$emit("tableChange", v);
    }
  },
  created() {
    this.thisFormDisable = this.formDisable;
    this.thisFormEditOver = this.formEditOver;
    this.thisFormRows = this.$util.deepCopy(this.formRows);
    this.DatePickerRefNamePrefix = this.randomKey+"_EDatetimePicker_";
    if (this.thisFormRows.length > 0) {
      for (let i = 0; i < this.thisFormRows.length; i++) {
        let column = this.thisFormRows[i].columns;
        if (column.length > 0) {
          for (let j = 0; j < column.length; j++) {
            if (column[j].configs.cnName && column[j].isShow != false) {
              this.$set(this.labelCnName, column[j].configs.fieldName, column[j].configs.cnName);
            }
            this.initFormElementsDisabled(column[j]);
            this.initFormElementsFilter(column[j]);
            // 给日期选择控件增加范围控制属性的监听字段赋值
            if (column[j].thisControlType === "EDatetimePicker") {
              this.initDateTimeMaxAndMin(
                column[j].configs.fieldName,
                this.oldMainTableObject,
                column[j].maxDate,
                column[j].minDate
              );
            }
          }
        }
      }
      this.oldFormElementsDisabled = Object.assign(
        {},
        this.oldFormElementsDisabled
      );
    }

    this.oldMainTableObject = this.mainTableData;
    this.nowData = Object.assign({}, this.oldMainTableObject);
    if (this.modifiedData) this.thisModifiedData = this.modifiedData;
    this.thisWidth = this.width > 0 ? this.width : this.defaultWidth;
    console.log("表单设置的默认宽度：" + this.thisWidth, this.changedDate);
    this.thisFormChanged = true;
  },
  mounted() {
    // 获取表单的扩展属性
    this.getFormExtends_Mixin(
      "thisFormExtends",
      this.tempId,
      this.templateName,
      1
    );
    console.log("FormTemplate=mounted");

    // 如果有公共附件，抛出
    if (this.form.getFieldsValue()._Attachments) {
      this.$emit("publicUploadChange", this.form.getFieldsValue()._Attachments);
    }
  },
  computed: {
    formRowItemClass: function() {
      let classTemp= [];
      classTemp.push(this.showModalType
        ? 'e-form-modal-span__' + this.colSpan
        : 'e-form-span__' + this.colSpan);
      if(this.tabForm) {
        classTemp.push("e-form-span__tab-form");
      }
      return classTemp;
    },
    thisMainTableData: {
      get: function() {
        return this.oldMainTableObject;
      },
      set: function(v) {
        this.$emit("tableDataChange", v);
      }
    },
    formMinWidth: function() {
      let cols = 24 / this.colSpan;
      if (cols > 4) {
        return this.thisWidth + "px";
      } else {
        return "unset";
      }
    }
  },
  errorCaptured(err, vm, info) {
    console.error("表单视图:", err, vm, info);
    return false;
  },
  methods: {
    /**
     * 初始化表单字段的禁用状态
     * @param column
     */
    initFormElementsDisabled: function(column) {
      let fieldName = column.configs.fieldName;
      //console.log("表单Disabled状态:",this.thisFormDisable);
      //console.log("表单设置可编辑的字段:",this.fieldsAllowedEdit);
      if (this.formElementsDisabled[fieldName] === undefined) {
        if (this.formEditOver) {
          // 表单全部不可编辑
          this.formElementsDisabled[fieldName] = true;
        } else {
          if (
            this.fieldsAllowedEdit &&
            this.fieldsAllowedEdit.indexOf(fieldName) >= 0
          ) {
            // 在可编辑字段内
            this.formElementsDisabled[fieldName] = false;
            this.haveEditFields = true;
            //this.$set(this.formElementsDisabled, fieldName, false);
            //this.$set(this.formElementsDisabled, fieldName, disabled);
          } else {
            this.formElementsDisabled[fieldName] =
              this.thisFormDisable || column.disable || column.enabled == false;
          }
        }
      }
      /*console.log(
        "表单字段：",
        fieldName,
        "Disabled状态:",
        this.formElementsDisabled[fieldName]
      );*/
    },
    /**
     * 设置对应的表单字段禁用
     * @param field
     */
    setFiledDisabled: function(field, disable) {
      let tempDisable = this.$util.isType(disable)==="Boolean" ? disable : true;
      this.$set(this.formElementsDisabled, field, tempDisable);
      let config = this.getFormItemConfig(field);
      if(config) {
        console.log("改变diable的控件", config);
        let ref=null,refName =null;
        if(config.isShow !== false) {
          if (config.thisControlType === 'EGroupBox') {
            refName = this.randomKey+ "_"+ config.thisControlType+ "_" + config.text;
          } else {
            refName = this.randomKey+ "_"+ config.thisControlType+ "_" + config.configs.fieldName;
          }
          ref = this.getThisRef(refName);
          if (ref && ref.setDisabled) {
            ref.setDisabled(tempDisable);
          }
        }
      }
    },
    /**
     * 设置切换对应的表单字段禁用状态
     * @param field
     */
    setFieldDisabledSwitch: function(field) {
      let olde = this.formElementsDisabled[field];
      if(olde)
        this.formElementsDisabled[field] = false;
      else
        this.formElementsDisabled[field] = true;
      let config = this.getFormItemConfig(field);
      if(config) {
        console.log("改变diable的控件", config);
        let ref=null,refName =null;
        if(config.isShow !== false) {
          if (config.thisControlType === 'EGroupBox') {
            refName = this.randomKey+ "_"+ config.thisControlType+ "_" + config.text;
          } else {
            refName = this.randomKey+ "_"+ config.thisControlType+ "_" + config.configs.fieldName;
          }
          ref = this.getThisRef(refName);
          if (ref && ref.setDisabledSwitch) {
            ref.setDisabledSwitch();
          }
        }
      }
    },
    /**
     * 获取指定字段的定义对象
     */
    getFormItemConfig: function(field){
      if (this.thisFormRows.length > 0) {
        for (let i = 0; i < this.thisFormRows.length; i++) {
          let column = this.thisFormRows[i].columns;
          if (column.length > 0) {
            for (let j = 0; j < column.length; j++) {
              if(column[j].configs.fieldName === field) {
                return column[j];
              }
            }
          }
        }
      }
      return null;
    },
    /**
     * 修改对应的字段定义配置
     * 注：会导致页面直接卡死
     * @param fieldConfig
     */
    updateFormItemConfig: function(fieldConfig){
      if (this.thisFormRows.length > 0) {
        let frowIndex = -1, rowIndex=-1;
        for (let i = 0; i < this.thisFormRows.length; i++) {
          frowIndex = i;
          let column = this.thisFormRows[i].columns;
          if (column.length > 0) {
            for (let j = 0; j < column.length; j++) {
              if(column[j].configs.fieldName === fieldConfig.configs.fieldName) {
                rowIndex = j;
                break;
              }
            }
          }
        }
        if(rowIndex>=0) {
          let columnObj = this.$util.deepCopy(this.thisFormRows[frowIndex]);
          columnObj.columns[rowIndex] = fieldConfig;
          this.$set(this.thisFormRows, frowIndex , columnObj)
        }
      }
    },
    /**
     * 强制重新渲染表单.有问题
     */
    formForceUpdate: function() {
        this.randomKey =  new Date().getTime() + "_" + Math.random();
    },
    /**
     * 初始化表单字段带有数据过滤条件的配置
     * @param column
     */
    initFormElementsFilter: function(column) {
      if (column.listFilter) {
        if (column.listFilter.filter) {
          // 控件有配置过滤条件
          column.listFilter.filter = this.TemplateParser.changeFilter(
            column.listFilter.filter,
            this.mainTableData
          );
        }
        this.variableFilter[column.configs.fieldName] = Object.assign(
          {},
          column.listFilter
        );
      }
    },

    /**
     * 根据控件所在行的控件数量来计算当前控件可用最多占用多少格
     * @param row
     * @param column
     */
    calculationColspan(row, column) {
      if (column.isShow != false) {
        if (row.colSpan < 24 && row.colSpan >= this.colSpan) {
          // 表示本行的项比较少
          if (column.size) {
            if (column.controlType === "CxsCheckBox") {
              //console.log(column.controlType, row.colSpan, "->", this.colSpan);
              return this.colSpan;
            } else if (
              column.controlType === "CxsGroupBox" ||
              column.controlType === "CxsRichTextBox"
            ) {
              return row.colSpan;
            }
            // 标准控件，带标签的，默认分成了多少份
            let cols = 24 / this.colSpan;
            // 获取当前工作表单的最大可用宽度
            let minWidth = this.thisWidth - 140 - 40;
            let minOneColWidth = minWidth / cols;
            //console.log(column.label + ":单列最小宽度：",this.thisWidth, minOneColWidth);
            let controlw = column.size.w + 140 + 40;
            //console.log(column.label,"占宽度："+controlw);
            if (controlw >= minOneColWidth) {
              // 该控件可能需要占用多个位置
              let tempNum =
                Math.round(
                  this.$util.mathUtil.accDiv(controlw, minOneColWidth)
                ) * this.colSpan;
              console.log(column.label, "占份：" + tempNum);
              return tempNum;
            } else {
              return this.colSpan;
            }
          } else {
            // 不带标签的控件包括（CxsGroupBox）
          }
        } else if (row.colSpan == 24 && row.columns.length > 1) {
          // 一个标准控件后续跟了一个无标签控件
          if (column.size) {
            // 标准控件，带标签
            let cols1 = row.columns.length;
            // 获取当前工作表单的最大可用宽度
            let minWidth1 = this.thisWidth - 200 - (cols1 * 24 + 40);
            let minOneColWidth1 = minWidth1 / cols1;
            //console.log(column.label + ":单列最小宽度：", minOneColWidth);
            let controlw1 = column.size.w + 140 + 60;
            //console.log(column.label,"占宽度："+controlw);
            if (controlw1 >= minOneColWidth1) {
              // 该控件可能需要占用多个位置
              let tempNum1 = Math.ceil(
                this.$util.mathUtil.accDiv(controlw1, minOneColWidth1)
              );
              //console.log(column.label,"占宽度："+controlw,this.colSpan * tempNum);
              return 24 / (tempNum1 * cols1);
            } else {
              let oldCol = Math.ceil(
                this.$util.mathUtil.accDiv(24, row.columns.length)
              );
              return this.colSpan > oldCol ? oldCol : this.colSpan;
            }
          }
          return row.colSpan;
        } else if (row.columns.length == 1) {
          /*else if (row.colSpan == 24 && this.colSpan != 24) {
          return row.colSpan;
        } else if (row.colSpan == 24 && this.colSpan == 24) {
          // 如果一行只有一个普通控件，则可以控件只占3/4
          if (column.thisControlType === "EGroupBox") {
            return 24;
          }
          return 24;
        } */
          // 一行只有一个控件
          if (column.controlType === "CxsCheckBox") {
            // 如果这是一个checkBox则为了和上方表单对齐，则使用默认
            return this.colSpan;
          } else {
            return 24;
          }
        } else {
          return row.colSpan;
        }
      }
      return 24;
    },
    /**
     * 用于使用本组件的对象获取数据
     * @param successCallback
     * @param failCallback
     */
    getFormData(successCallback, failCallback) {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (successCallback) {
            successCallback({
              form: values
            });
          }
        } else {
          if (failCallback) {
            failCallback(err);
          }
        }
      });
    },
    /**
     * 响应对应字段名的表单控件的的单击事件
     * @param fildName
     */
    doClick(fieldName) {
      if (
        this.thisFormExtends &&
        this.thisFormExtends[fieldName] &&
        this.thisFormExtends[fieldName].onClick
      ) {
        let data = this.form.getFieldsValue([fieldName]);
        this.$emit("formExtendEvent", {
          fieldName: fieldName,
          value: data,
          onClick: this.thisFormExtends[fieldName].onClick
        });
      }
    },
    /**
     * 响应对应字段名的表单控件的的失去焦点事件
     * @param fildName
     */
    doBlur(fieldName) {
      if (
        this.thisFormExtends &&
        this.thisFormExtends[fieldName] &&
        this.thisFormExtends[fieldName].onBlur
      ) {
        let data = this.form.getFieldsValue([fieldName]);
        this.$emit("formExtendEvent", {
          fieldName: fieldName,
          value: data,
          onBlur: this.thisFormExtends[fieldName].onBlur
        });
      }
    },
    /**
     * 响应对应字段名的表单控件的的获得焦点事件
     * @param fieldName
     */
    doFocus(fieldName) {
      if (
        this.thisFormExtends &&
        this.thisFormExtends[fieldName] &&
        this.thisFormExtends[fieldName].onFocus
      ) {
        //console.log("焦点字段：", fildName);
        let data = this.form.getFieldsValue([fieldName]);
        this.$emit("formExtendEvent", {
          fieldName: fieldName,
          value: data,
          onFocus: this.thisFormExtends[fieldName].onFocus
        });
      }
    },
    /**
     * 响应对应字段名的表单控件的的联动改变事件
     * @param obj
     */
    doLinkedChange(obj) {
      //console.log("联动对象变更",obj);
      this.form.setFieldsValue(obj);
    },
    /**
     * 响应对应字段名的表单控件的的内容改变事件
     * @param configs
     * @param value
     * @param name
     */
    doChange(configs, value, name) {
      console.log("dochange:" + value);
      var setFieldName = configs["fieldName"];
      var jsonField = {};
      jsonField[setFieldName] = value;
      this.form.setFieldsValue(jsonField);
      let fieldName = configs.fieldName;

      // 公共上传附件额外处理
      if (fieldName == "_Attachments") {
        this.$emit("publicUploadChange", value);
      }

      // 处理监听时间日期选择的数据变化，用于控制选择范围
      this.handleDateChange(fieldName, value);
      if (value !== this.nowData[fieldName]) {
        this.updatePageData("nowData", fieldName, value);
      }
      /*if (configs.cnName)
        this.$set(this.labelCnName, fieldName, configs.cnName);*/
      if (name != null && name != "") {
        this.$set(this.chineseName, fieldName, name);
      }
      //if(this.form)
      //console.log("触发change事件字段数据：", fieldName+"="+value);
      if (this.form && this.formulaConfig.length > 0) {
        // 如果有配置字段计算公式，则先判断字段是否关联了计算公式
        let formulaObjs = this.formulaConfig.filter(function(obj) {
          return obj.variableField.indexOf(fieldName) >= 0;
        });
        if (formulaObjs && formulaObjs.length > 0) {
          for (let x = 0; x < formulaObjs.length; x++) {
            let formulaObj = formulaObjs[x];
            if (formulaObj.formula) {
              // 是动态计算公式数据联动
              //console.log("动态条件对象", formulaObj);
              let jsResult = this.doCalculation1(formulaObj, this.form);
              /*console.log(
                formulaObj.resultField +
                "=公式" +
                formulaObj.formula +
                "的计算结果" +
                jsResult
              );*/
              let newObject = {};
              newObject[formulaObj.resultField] = jsResult;
              this.form.setFieldsValue(newObject);
            }

            if (formulaObj.filter) {
              // 是联动过滤条件，需要改thisFormRows
              let nowFilter = formulaObj.filter;
              nowFilter = nowFilter.replace(fieldName, value);
              if (this.variableFilter[formulaObj.fieldName]) {
                if (
                  this.variableFilter[formulaObj.fieldName].filter !== nowFilter
                ) {
                  //console.log("动态条件对象", formulaObj);
                  let newFilter = this.variableFilter[formulaObj.fieldName];
                  newFilter.filter = nowFilter;
                  let controlRefName =
                    this.randomKey + "_ESelect_" + formulaObj.fieldName;
                  let controlRef = this.getThisRef(controlRefName);
                  if (controlRef) {
                    controlRef.thisOldFilter = Object.assign({}, newFilter);
                  }
                  //console.log("变更了过滤条件",this.variableFilter);
                }
              }
            }
          }
        }
      }
      // 表单外部扩展事件响应方法
      if (
        this.form &&
        this.thisFormExtends &&
        this.thisFormExtends[fieldName] &&
        this.thisFormExtends[fieldName].onChange
      ) {
        this.$emit("formExtendEvent", {
          fieldName: fieldName,
          value: value,
          onChange: this.thisFormExtends[fieldName].onChange
        });
      }
    },
    /**
     * 表单设置焦点
     */
    tableFocus() {
      this.$nextTick(() => {
        if (this.$refs) {
          for (var key in this.$refs) {
            if (this.$refs[key][0].tableSelectFocus) {
              console.log("tableSelectFocus");
              this.$refs[key][0].tableSelectFocus();
            }
          }
        }
      });
    },
    // 表单第一个获取焦点
    firstFocus() {
      try {
        var firstField = this.thisFormRows[0].columns[0].configs.fieldName;
        var refName = this.randomKey + "_ESelect_" + firstField;
        if (this.$refs[refName][0].tableSelectFocus) {
          this.$refs[refName][0].tableSelectFocus();
        } else {
          this.$refs[refName][0].tableFocus();
        }
      } catch (err) {
        err;
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "../assets/style/base/components/form-template.less";
</style>
