import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求
import { e1ApprovalRecord } from "@/views/form/mixins/e1ApprovalRecord.js"; // e1的审批记录-ApprovalRecord配置和操作
// 包含了支持表单流程功能的方法
export const e1FormToolbarWorkFlow = {
  mixins: [requestControls, pageControls, e1ApprovalRecord],
  data() {
    return {
      spModalShow: false, // 审批意见窗口显示状态
      tjModalShow: false, // 提交到审批流程窗口的显示状态
      spjlModalShow: false, // 审批记录列表弹出窗
      tsbNewData: null,
      tjDefaultData: { _zt: "" }, // 提交主题数据对象
      spButtons: null, // 当前用户可做审批操作
      beforeTjOptNeedSave: false, // 提交前需要保存
      beforeSpOptNeedSave: false, // 审批前需要保存
      showWorkFlowImage: false, // 弹出流程图查看弹窗
      workflowTemplateInstance: null, // 流程图对应的唯一性编码
      workflowImageSrc: "", // 流程图地址
      // 对应状态名称和index值
      showStateNameFrom: [
        "已作废",
        "草稿",
        "未提交",
        "已提交",
        "待处理",
        "已处理",
        ""
      ],
      fromState: -1, // 表单状态对应->0:"已作废",1:"草稿", 2:"未提交", 3:"已提交", 4:"待处理", 5:"已处理"
      spState: "", // 显示到表单上的状态名
      spMainTableNameTemp: null,
      spMainTableDataIDTemp: null
    };
  },
  computed: {
    spMainTableName: {
      get: function() {
        if (this.spMainTableNameTemp) {
          return this.spMainTableNameTemp;
        }
        return this.mainTableName;
      },
      set: function(v) {
        this.spMainTableNameTemp = v;
      }
    },
    spMainTableDataID: {
      get: function() {
        if (this.spMainTableDataIDTemp) {
          return this.spMainTableDataIDTemp;
        }
        return this.dataId;
      },
      set: function(v) {
        this.spMainTableDataIDTemp = v;
      }
    }
  },
  created() {
    //console.log("混入e1FormToolbar的通用方法");
  },
  methods: {
    /**
     * 根据流程处理状态，获得页面需要的标识字段
     * @param workFlowState
     */
    setFormState(workFlowState) {
      if (workFlowState !== "" && this.fromState == -1) {
        this.fromState = this.showStateNameFrom.indexOf(workFlowState);
        if (this.fromState >= 0)
          this.spState = this.getStateName(this.fromState);
        if (this.fromState > 0) {
          if (this.fromState == 1) {
            // 草稿状态
            this.formEditState = false; // 表单可编辑
            this.formDisable = false; // 表单非禁用
          } else if (this.fromState == 2 || this.fromState == 4) {
            // 流转中，待提交或待处理
            this.formEditState = false; //表单可编辑
            if (this.fromState == 2) {
              //待提交
              this.formDisable = false; //未提交 表单非禁用
              this.beforeSpOptNeedSave = true; // 如果直接审批，需要先保存
            } else {
              // 待处理 有限可编辑
              this.formDisable = true; // 表单禁用，由流程配置的可编辑项控制表单内容是否可编辑
            }
          } else if (this.fromState == 3 || this.fromState == 5) {
            // 已提交或已处理
            this.formEditState = true; //表单不可编辑
            this.formDisable = true;
          } else {
            // 其他情况
            this.formEditState = true; //表单不可编辑
          }
        }
      }
    },

    /**
     * 获取统一显示格式的状态名称
     * @param index
     * @returns {string}
     */
    getStateName(index) {
      if (this.$util.isType(index) === "val") {
        if (index >= 0) {
          return "（" + this.showStateNameFrom[index] + "）";
        }
        return "";
      }
      return "（" + index + "）";
    },
    /**
     * 点击“提交”按钮，打开表单提交审核确认框,确定创建流程
     */
    tsbWorkFlowCreater(button, formType) {
      if (!this.spMainTableDataID) {
        this.alertWarning("表单ID不存在");
        return;
      }
      let that = this;
      let templateForm = this.$refs.formTemplate;
      if (formType) {
        templateForm = this.form;
      }
      templateForm.validateFields((err, values) => {
        if (!err) {
          // 填制凭证特殊处理
          if(that.KeepAccountsFormTempIds.includes(that.tempId)) {
            that.oldMainTableObject = values;
          }
          that.beforeTjOptNeedSave = true;
          if (
            that.$config.fromApprovalMode !== "merge_approve" && (that.beforeTjOptNeedSave ||
            that.fieldsAllowedEdit ||
            that.mxAllowedEditField)
          ) {
            // 前一次校验未通过，说明需要先保存修改的内容
            //console.log("数据信息：" + JSON.stringify(values));
            let saveParam = that.checkOperationAuth(
              that.buttons,
              "tsbWorkFlowCreater",
              () => {
                that.alertError("您没有提交表单的权限");
              }
            );
            if (saveParam) {
              that.startProcessing(button);
              // 缓存明细数据
              //that.allChildrenTablesDatas = that.getMxTableData();
              // 检测明细选人数据
              for (let r = 0; r < that.mxTableName.length; r++) {
                if (
                  that.judgeCheckCondition &&
                  that.judgeCheckCondition(that.mxTableName[r], [])
                ) {
                  that.alertError(
                    "明细选入错误，" +
                      that.gridImportTableConfig.selectCheckCondition.checkError
                  );
                  that.finishedProcessing(button);
                  return;
                }
              }
              let params = {
                action: "SaveFormData",
                data: JSON.stringify(values), // 主表数据
                children: that.getMxDataJSON(), // 子表数据
                tb_name: that.mainTableName, // 主表名称 从DHCS中获取
                mxb_name: that.saveMxTableNames.toString(), // 明细表名称
                bm: saveParam.param.bm, // 类库名称（SYSTEM）、从GJLCS中获取 {tsbNew,SYSTEM,1,980}
                t_id: that.saveTempID ? that.saveTempID: that.tempId, // 模板id  从DHCS中获取
                et_name: saveParam.param.et_name, //  表示操作类型  添加为0，修改为1，删除为2 等，从GJLCS中获取 {tsbNew,SYSTEM,1,980}
                m_id: 0, // 保存时没用到 传入0
                d_id: that.dataId, // 插入之后返回的主键id
                randomKey: that.$store.getters.getRandomKey
              };
              let doSave = function(params1, button1) {
                that.post("Form.ashx", params1, {
                  success: function(req) {
                    console.log("提交保存请求结果", req);
                    //that.alertSuccess("保存成功");
                    if (req.data.dataId) {
                      that.mainTableData = values;
                      let mc = that.mainTableData._BDMC
                        ? that.mainTableData._BDMC
                        : that.title;
                      let zt = mc + "_" + that.mainTableData._BDBH;
                      //获取下一步流程信息
                      let workFlows = req.data.workFlow;
                      if(workFlows) that.spButtons = that.getSpButtons(workFlows.operation);
                      that.updatePageData("tjDefaultData", "_zt", zt);
                      // 调用扩展功能，表单提交成功后调用的函数。
                      if (
                        that.thisFromEvents &&
                        that.thisFromEvents.afterSave
                      ) {
                        that.thisFromEvents.afterSave(that, values);
                      }
                      that.tjModalShow = true; // 弹出提交审批框
                    }
                    that.fromState = 3;
                    that.finishedProcessing(button1);
                  },
                  error: function(er) {
                    console.error("请求失败", er);
                    that.alertError(er.message);
                    that.finishedProcessing(button1);
                  }
                });
              };
              // 判断是否配置了提交前的扩展功能
              that.exeSaveFromEvents(values, params, button, doSave);
            }
          } else {
            // 说明需要校验的字段都通过，不需要保存
            that.tjModalShow = true; // 弹出提交审批框
            that.finishedProcessing(button);
          }
        } else {
          // 有校验未通过的字段
          that.beforeTjOptNeedSave = true;
          //console.log( JSON.stringify(err));
          let errMsg = that.getDecoratorErrInfo(err);
          that.tipWarning(errMsg);
        }
      });
    },
    /**
     * 表单提交到审批流程
     * @param tjData
     * @param tsbNewData
     */
    doFormTj(tjData, tsbNewData) {
      console.log("提交主题", tjData, tsbNewData);
      debugger
      //如果是提交和保存合并操作
      if(this.$config.fromApprovalMode === "merge_submit") {
        if(tsbNewData) {
          if(tsbNewData.next && this[tsbNewData.next]) {
            let param = tsbNewData.param ? Object.assign({},tsbNewData.param) : {};
            param.f_Subject = tjData.data._zt;
            this[tsbNewData.next](tsbNewData.button, param);
            tjData.resolve();
          }
        }
      } else {
        let params = {
          action: "CreateWorkflow",
          m_tbl: this.spMainTableName, // 主表名称 从DHCS中获取
          f_No: this.bdbh, // 表单编号
          t_id: this.tempId, // 模板id  从DHCS中获取
          f_Subject: tjData.data._zt, //  表示操作类型  添加为0，修改为1，删除为2 等，从GJLCS中获取 {tsbNew,SYSTEM,1,980}
          d_id: this.spMainTableDataID, // 插入之后返回的主键id
          merge: this.$config.fromApprovalMode, // 是否自动审批
          randomKey: this.$store.getters.getRandomKey
        };
        let that = this;
        this.post("WorkFlow.ashx", params, {
          success: function(req) {
            //console.log("请求结果",req);
            //let needChangeField = req.data.field;
            //console.log("需要修改的字段",needChangeField);
            try {
              // 隐藏提交按钮
              that.updateEToolbarButtonDisplay("tsbWorkFlowCreater");
              tjData.resolve();
              that.tipSuccess("提交成功");
              that.beforeTjOptNeedSave = false;
              that.mxChangeHh = false;
              if (req.data.operation) {
                let tempButton = req.data.operation;
                for (let i = 0; i < tempButton.length; i++) {
                  tempButton[i].loading = false;
                  tempButton[i].returnData = true;
                }
                /*tempButton.push({
                  title: "取消",
                  key: "cancel"
                });*/
                that.spButtons = tempButton;
                //that.formEditState = true;
                // 显示审批和审批记录按钮
                if (that.$config.fromApprovalMode !== "merge_approve") { // 提交审批不是合并操作
                  that.updateEToolbarButtonDisplay("tsbDoWorkFlow", true);
                  that.fromState = that.fromState + 1;
                  that.spState = that.getStateName(that.fromState);
                }
                that.updateEToolbarButtonDisplay("tsbWorkFlowRecord");
              }
              if (that.$config.fromApprovalMode === "merge_approve") { // 提交审批合并了
                that.updateEToolbarButtonDisplay([
                  "tsbDoWorkFlow",
                  "tsbEdit",
                  "tsbSave",
                  "tsbGridImport",
                  "tsbForceSave",
                  "tsbUpdateDetailColumn"
                ], false);
                that.fromState = 3;
                that.spState = that.getStateName(that.fromState);
                that.mxOprButtons = [];
              }
            }catch (e) {
              console.warn(e);
              // 隐藏提交按钮
              that.updateEToolbarButtonDisplay("tsbWorkFlowCreater");
              tjData.resolve();
              that.tipSuccess("提交成功");
            }
          },
          error: function(err) {
            console.log("请求失败", err);
            that.beforeTjOptNeedSave = false;
            tjData.resolve(1);
            that.alertError(err.message);
          }
        });
      }
    },
    /**
     * 打开表单审核弹窗
     */
    tsbDoWorkFlow(button, formType) {
      if (!this.spMainTableDataID) {
        this.alertWarning("表单ID不存在");
        return;
      }
      let that = this,
        isFormEdited = false;
      // 查指定可编辑项，是否进行过修改
      if (this.fieldsAllowedEdit)
        isFormEdited = this.form.isFieldsTouched(this.fieldsAllowedEdit);
      console.log("表单操作状态", isFormEdited);
      //return;
      let templateForm = this.$refs.formTemplate;
      if (formType) {
        templateForm = this.form;
      }
      templateForm.validateFields((err, values) => {
        if (!err) {
          // 填制凭证特殊处理
          if(that.KeepAccountsFormTempIds.includes(that.tempId)) {
            that.oldMainTableObject = values;
          }
          if (
            that.beforeSpOptNeedSave ||
            that.fieldsAllowedEdit ||
            that.mxAllowedEditField
          ) {
            // 上一次校验有异常，需要先保存修改内容
            //console.log("数据信息：" + JSON.stringify(values));
            let saveParam = that.checkOperationAuth(
              that.buttons,
              "tsbDoWorkFlow",
              () => {
                that.alertError("您没有审批表单的权限");
              }
            );

            if (saveParam) {
              that.startProcessing(button);
              // 缓存明细数据
              //that.allChildrenTablesDatas = that.getMxTableData();
              for (let r = 0; r < that.mxTableName.length; r++) {
                if (
                  that.judgeCheckCondition &&
                  that.judgeCheckCondition(that.mxTableName[r], [])
                ) {
                  that.alertError(
                    "明细选入错误，" +
                      that.gridImportTableConfig.selectCheckCondition.checkError
                  );
                  return;
                }
              }
              let params = {
                action: "SaveFormData",
                data: JSON.stringify(values), // 主表数据
                children: that.getMxDataJSON(), // 子表数据
                tb_name: that.mainTableName, // 主表名称 从DHCS中获取
                mxb_name: that.saveMxTableNames.toString(), // 明细表名称
                bm: saveParam.param.bm, // 类库名称（SYSTEM）、从GJLCS中获取 {tsbNew,SYSTEM,1,980}
                t_id: that.saveTempID ? that.saveTempID: that.tempId, // 模板id  从DHCS中获取
                et_name: saveParam.param.et_name, //  表示操作类型  添加为0，修改为1，删除为2 等，从GJLCS中获取 {tsbNew,SYSTEM,1,980}
                m_id: 0, // 保存时没用到 传入0
                d_id: that.spMainTableDataID, // 插入之后返回的主键id
                randomKey: that.$store.getters.getRandomKey
              };
              let doSave = function(params1, button1) {
                that.post("Form.ashx", params1, {
                  success: function(req) {
                    console.log("审批保存请求结果", req);
                    //that.alertSuccess("保存成功");
                    that.beforeSpOptNeedSave = false;
                    if (req.data.dataId) {
                      //获取下一步流程信息
                      let workFlows = req.data.workFlow;
                      if(workFlows) that.spButtons = that.getSpButtons(workFlows.operation);
                      that.mainTableData = values;
                      // 调用扩展功能，表单提交成功后调用的函数。
                      if (
                        that.thisFromEvents &&
                        that.thisFromEvents.afterSave
                      ) {
                        that.thisFromEvents.afterSave(that, values);
                      }
                      that.spModalShow = true; // 弹出提交审批框
                    }
                    that.finishedProcessing(button1);
                  },
                  error: function(er) {
                    console.error("请求失败", er);
                    that.beforeSpOptNeedSave = false;
                    that.alertError(er.message);
                    that.finishedProcessing(button1);
                  }
                });
              };
              // 判断是否配置了提交前的扩展功能
              that.exeSaveFromEvents(values, params, button, doSave);
            }
          } else {
            // 没有校验错误的表单项
            that.spModalShow = true; // 弹出提交审批框
            that.finishedProcessing(button);
          }
        } else {
          that.beforeSpOptNeedSave = true;
          //console.log( JSON.stringify(err));
          let errMsg = that.getDecoratorErrInfo(err);
          that.tipWarning(errMsg);
        }
      });
    },
    /**
     * 进行审批操作保存
     * @param tagData
     * @param otherData
     */
    doFormSp(tagData) {
      //console.log("data",tagData);
      if (tagData.key === "cancel") {
        this.beforeSpOptNeedSave = false;
        this.spModalShow = false;
        return;
      }
      let params = {
        action: "WorkflowProcess",
        m_tbl: this.spMainTableName, // 主表名称 从DHCS中获取
        e_Name: tagData.key, // 表单编号
        t_id: this.tempId, // 模板id  从DHCS中获取
        fContent: tagData.data._yj, //  表示操作类型  添加为0，修改为1，删除为2 等，从GJLCS中获取 {tsbNew,SYSTEM,1,980}
        d_id: this.spMainTableDataID, // 插入之后返回的主键id
        randomKey: this.$store.getters.getRandomKey
      };
      let that = this;
      this.loading();
      if (this.approvalFormLoading) {
        this.approvalFormLoading();
      }
      this.post("WorkFlow.ashx", params, {
        success: function(req) {
          //console.log("请求结果",req);
          //let needChangeField = req.data.field;
          // console.log("可以修改的字段",needChangeField);
          try {
            if (req.data && req.data.operation) {
              // 如果还需要本人做下一步流程
              that.spButtons = that.getSpButtons(req.data.operation);
            } else {
              /// 隐藏审批, 保存, 编辑，明细选人按钮
              that.updateEToolbarButtonDisplay(
                [
                  "tsbDoWorkFlow",
                  "tsbEdit",
                  "tsbSave",
                  "tsbGridImport",
                  "tsbForceSave",
                  "tsbUpdateDetailColumn"
                ],
                false
              );
              tagData.resolve();
              that.tipSuccess("审批成功");
              that.beforeSpOptNeedSave = false;
              that.spModalShow = false;
              // 修改表单状态
              that.fromState = that.fromState + 1;
              that.spState = that.getStateName(that.fromState);
            }
            if (req.turnParm) {
              // 如果需要弹出到下一个处理表单去
              that.tipInfoMessage("有后续表单需要处理");
              that.openNextForm(req.turnParm);
            }
            // 重新回填明细表数据
            //let nowChilrenTablesData = that.allChildrenTablesDatas.concat();
            //console.log("保存的明细数据",that.allChildrenTablesDatas,nowChilrenTablesData);
            //that.initMxTableData(nowChilrenTablesData);
            if (that.approvalFormLoadingOk) {
              that.approvalFormLoadingOk();
            }
            that.loadingOk();
            // 设置表单为不可编辑状态
            that.formEditState = true;
          } catch (e) {
            console.warn(e);
            tagData.resolve();
            that.tipSuccess("审批成功");
          }
        },
        error: function(err) {
          console.log("请求失败", err);
          that.beforeSpOptNeedSave = false;
          tagData.resolve(1);
          that.loadingOk();
          that.alertError(err.message);
        }
      });
    },
    /**
     * 打开下一个表单
     * @param data
     */
    openNextForm(data) {
      if (data) {
        this.openTab({
          title: "处理草稿" + "-" + (data.BDBH ? data.BDBH : data.d_id),
          path: "/ApprovalForm",
          query: {
            dhbm: 256,
            templetId: data.t_id, // 表单模板ID
            dataId: data.d_id, // 表单数据id
            bm: data.bm, // 表单对应BM表
            clzt: "草稿" // 表单状态
          }
        });
      }
    },
    /**
     * 打开表单审核记录查询弹窗
     */
    tsbWorkFlowRecord() {
      this.spjlModalShow = true;
      this.getSpTableDatas(this.tempId, this.spMainTableDataID);
    },
    /**
     * 查看表单审批流程图
     */
    tsbWorkFlowImage(button) {
      this.startProcessing(button);
      let url =
        this.BaseUrl +
        this.ServerPath +
        window.E1_WORKFLOW_IMAGEPATH +
        "&templateId=" +
        this.tempId;
      if (this.spMainTableDataID)
        url += "&formDataId=" + this.spMainTableDataID;
      if (this.workflowTemplateInstance)
        url += "&InstanceID=" + this.workflowTemplateInstance;
      this.workflowImageSrc = url;
      this.showWorkFlowImage = true;
      this.finishedProcessing(button);
    },
    /**
     * 撤销表单审批意见
     * ApprovalForm?dhbm=274&templetId=44&dataId=1584&bm=SYSTEM&clzt=收件&random=1598862679649_0.5835201921933644
     * @param button
     */
    tsbCheXiao(button) {
      if (!this.spMainTableDataID) {
        this.alertWarning("表单ID不存在");
        return;
      }
      let that = this;
      let params = {
        action: "CancelWorkflow",
        templateID: this.tempId, // 模板id  从DHCS中获取
        dataID: this.spMainTableDataID, // 插入之后返回的主键id
        randomKey: this.$store.getters.getRandomKey
      };
      this.startProcessing(button);
      this.post("WorkFlow.ashx", params, {
        success: function(req) {
          console.log("请求结果", req);
          that.finishedProcessing(button);
          that.alertSuccess("撤销成功", function() {
            that.loading();
            //that.getFormExtends(); // 获取当前表单的扩展属性
            //that.getFormTemplate();
            //带人数据id重载页面，跳转到审批页面
            that.redirect(
              "/ApprovalForm",
              {
                dhbm: 274,
                templetId: that.tempId, // 表单模板ID
                dataId: that.dataId, // 表单数据id
                bm: that.bm, // 表单对应BM表
                clzt: "收件" // 表单状态
              },
              "处理待处理-" + that.bdbh
            );
          });
        },
        error: function(err) {
          that.finishedProcessing(button);
          that.alertError(err.message);
        }
      });
    },
    /**
     * 执行dowork前判断是否有扩展功能，如果有则需要先执行扩展功能，否则直接执行dowork
     * @param values
     * @param params
     * @param button
     * @param doWork
     */
    exeSaveFromEvents(values, params, button, doWork) {
      let that = this;
      //保存前去检测是否需要产品序列号配置，如果序列号需要配置则会弹出配置窗口
      if (this.fromState > 1 && this["checkProductSerial"]) {
        if (!this.checkProductSerial()) {
          that.finishedProcessing(button);
          return;
        }
      }
      if (this.thisFromEvents) {
        // 是否配置有扩展功能
        // 调用扩展功能，表单提交前调用的同步数据校验函数，如果返回false，则停止提交。
        if (this.thisFromEvents.checkData) {
          if (this.thisFromEvents.checkData(this, values)) {
            this.runBeforeSaveFromEvents(values, params, button, doWork);
            return;
          }
          that.finishedProcessing(button);
        } else if (this.thisFromEvents.checkDataAsyn) {
          // 如果配置了异步数据校验函数
          new Promise((resolve, reject) => {
            that.thisFromEvents.checkDataAsyn(that, values, {
              ok: resolve,
              cancel: reject
            });
          })
            .then(function(value) {
              console.log("checkDataAsyn---------->提交前异步校验成功", value);
              // 继续
              that.runBeforeSaveFromEvents(values, params, button, doWork);
            })
            .catch(function(value) {
              console.log("checkDataAsyn---------->提交前异步校验失败", value);
              if (value) {
                that.alertError(value + "");
              }
              // 取消
              that.finishedProcessing(button);
            });
        } else {
          this.runBeforeSaveFromEvents(values, params, button, doWork);
        }
      } else {
        doWork(params, button);
      }
    },
    /**
     * 调用扩展功能，表单提交前调用的函数。调用params.ok()以指示继续表单保存操作。调用params.cancel()指示停止表单保存操作
     * @param value
     * @param params
     * @param button
     * @param doWork
     */
    runBeforeSaveFromEvents(values, params, button, doWork) {
      // 调用扩展功能，表单提交前调用的函数。调用params.ok()以指示继续表单保存操作。调用params.cancel()指示停止表单保存操作
      if (this.thisFromEvents && this.thisFromEvents.beforeSave) {
        let that = this;
        new Promise((resolve, reject) => {
          that.thisFromEvents.beforeSave(that, values, {
            ok: resolve,
            cancel: reject
          });
        })
          .then(function(value) {
            console.log("beforeSave---------->提交前异步返回数据", value);
            // 继续
            doWork(params, button);
          })
          .catch(function(value) {
            console.log("beforeSave---------->取消提交时返回的数据", value);
            // 取消
            that.finishedProcessing(button);
          });
      } else {
        doWork(params, button);
      }
    }
  }
};
