import _lodash from "lodash";
// 明细表键盘操作相关
export const ETableKeydown = {
  data() {
    return {
      focusDataIndex: null, // 编辑行焦点字段
      editDataIndexSort: [] // 编辑行排序
    };
  },
  inject: [], // 引用主页定义的页面操作方法
  computed: {},
  created() { },
  methods: {
    setFocusDataIndex(field, e) {
      if (e) {
        e.stopPropagation();
      }
      this.focusDataIndex = field;
      // this.editFoucs(field);
    },
    // 右
    keyDownRight(e) {
      if (!this.focusDataIndex) {
        this.focusDataIndex = this.editDataIndexSort[0];
        this.editFoucs(this.editDataIndexSort[0], e, "right");
      } else {
        this.editBlur(this.focusDataIndex);
        var index = this.editDataIndexSort.findIndex(
          item => this.focusDataIndex == item
        );
        if (index != -1) {
          if (this.editDataIndexSort[index + 1]) {
            this.focusDataIndex = this.editDataIndexSort[index + 1];
            this.editFoucs(this.editDataIndexSort[index + 1], e, "right");
            this.$refs[this.domId].scrollToColumn(this.focusDataIndex);
          } else {
            this.focusDataIndex = this.editDataIndexSort[0];
            this.editFoucs(this.editDataIndexSort[0], e, "right");
            this.$refs[this.domId].scrollToColumn(this.focusDataIndex);
          }
          // this.setEditRowStyle();
        }
      }
    },
    // 左
    keyDownLeft(e) {
      if (!this.focusDataIndex) {
        this.focusDataIndex = this.editDataIndexSort[0];
        this.editFoucs(this.editDataIndexSort[0], e, "left");
      } else {
        this.editBlur(this.focusDataIndex);
        var index = this.editDataIndexSort.findIndex(
          item => this.focusDataIndex == item
        );
        if (index != -1) {
          if (this.editDataIndexSort[index - 1]) {
            this.focusDataIndex = this.editDataIndexSort[index - 1];
            this.editFoucs(this.editDataIndexSort[index - 1], e, "left");
            this.$refs[this.domId].scrollToColumn(this.focusDataIndex);
          } else {
            this.focusDataIndex = this.editDataIndexSort[
              this.editDataIndexSort.length - 1
            ];
            this.editFoucs(
              this.editDataIndexSort[this.editDataIndexSort.length - 1],
              e,
              "left"
            );
            this.$refs[this.domId].scrollToColumn(this.focusDataIndex);
          }
          // this.setEditRowStyle();
        }
      }
    },
    // 下一行
    nextRow() {
      if (this.editIndex) {
        this.editBlur(this.focusDataIndex);
        const newData = _lodash.cloneDeep(this.data);
        var index = newData.findIndex(
          item => item[this.rowKey].toString() == this.editIndex.toString()
        );
        if (index != -1) {
          if (newData[index + 1]) {
            // this.editFoucs(this.focusDataIndex);
            this.edit(newData[index + 1][this.rowKey], this.focusDataIndex);
            this.$refs[this.domId].scrollToRow(newData[index + 1][this.rowKey]);
          }
        }
      }
    },
    // 上一行
    lastRow() {
      if (this.editIndex) {
        this.editBlur(this.focusDataIndex);
        const newData = _lodash.cloneDeep(this.data);
        var index = newData.findIndex(
          item => item[this.rowKey].toString() == this.editIndex.toString()
        );
        if (index != -1) {
          if (newData[index - 1]) {
            // this.editFoucs(this.focusDataIndex);
            this.edit(newData[index - 1][this.rowKey], this.focusDataIndex);
            this.$refs[this.domId].scrollToRow(newData[index - 1][this.rowKey]);
          }
        }
      }
    },
    // 获得焦点
    editFoucs(field, e) {
      if (field) {
        var dom = this.$refs[this.domId + "Edit" + field];
        if (dom && dom[0]) {
          try {
            dom[0].tableFocus(e);
          } catch {
            try {
              dom[0].focus();
            } catch { }
          }
        }
        // var foucsDom = this.$refs[this.domId + "div"].querySelectorAll(
        //   ".edit-cell-" + field
        // )[0];
        // var editDom = this.$refs[this.domId + "div"].querySelectorAll(
        //   ".editRowTable"
        // )[0];
        // if (foucsDom && editDom) {
        //   // if (foucsDom.offsetLeft > editDom)
        //   this.$refs[this.domId].scrollTo(
        //     foucsDom.offsetLeft
        //     // editDom.offsetTop
        //   );
        // }
      }
    },
    // 取消焦点
    editBlur(field) {
      if (field) {
        var dom = this.$refs[this.domId + "Edit" + field];
        if (dom && dom[0]) {
          try {
            dom[0].tableBlur();
          } catch {
            try {
              dom[0].blur();
            } catch { }
          }
        }
      }
    },
    keyDownBottom() {
      this.nextRow();
    },
    keyDownTop() {
      this.lastRow();
    },
    // 设置排序
    setEditDataIndexSort(col) {
      var editDataIndexSort = col.filter(item => !item.hideThis);
      var arr = [];
      for (var i = 0; i < editDataIndexSort.length; i++) {
        arr.push(editDataIndexSort[i]["dataIndex"]);
      }
      this.editDataIndexSort = arr;
    },
    // 按下键盘触发
    keydown(e) {
      if (e.$event) {
        e.$event.stopPropagation();
        if (e.$event.code == "Numpad4" && e.$event.key == "ArrowLeft") {
          console.log("left");
          this.keyDownLeft(e);
          event.preventDefault();
        }
        if (e.$event.code == "Numpad6" && e.$event.key == "ArrowRight") {
          console.log("right");
          this.keyDownRight(e);
          event.preventDefault();
        }
        if (e.$event.code == "Numpad8" && e.$event.key == "ArrowUp") {
          console.log("top");
          this.keyDownTop(e);
          event.preventDefault();
        }
        if (e.$event.code == "Numpad2" && e.$event.key == "ArrowDown") {
          console.log("bottom");
          this.keyDownBottom(e);
          event.preventDefault();
        }
      } else {
        e.stopPropagation();
        if (e.code == "Numpad4" && e.key == "ArrowLeft") {
          console.log("left");
          this.keyDownLeft(e);
          event.preventDefault();
        }
        if (e.code == "Numpad6" && e.key == "ArrowRight") {
          console.log("right");
          this.keyDownRight(e);
          event.preventDefault();
        }
        if (e.code == "Numpad8" && e.key == "ArrowUp") {
          console.log("top");
          this.keyDownTop(e);
          event.preventDefault();
        }
        if (e.code == "Numpad2" && e.key == "ArrowDown") {
          console.log("bottom");
          this.keyDownBottom(e);
          event.preventDefault();
        }
      }
    }
  }
};
