/**
 * 支持e1平台的列表高级检索功能
 */
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求
// 文本输入控件
var EIput = {
  size: {
    w: 162,
    h: 21,
    labelw: 53
  },
  setting: {},
  configs: {
    fieldName: "",
    required: false,
    defaultValue: ""
  },
  thisControlType: "EInput",
  label: ""
};
// 数值输入控件
var NumericUpDown = {
  size: {
    w: 162,
    h: 21,
    labelw: 53
  },
  configs: {
    fieldName: "",
    required: false,
    defaultValue: ""
  },
  increment: 1,
  thousandsSeparator: true,
  thisControlType: "ENumericUpDown",
  label: ""
};
// 数值范围输入控件
var ENumberHorizon = {
  size: {
    w: 162,
    h: 21,
    labelw: 53
  },
  configs: {
    fieldName: "",
    required: false,
    defaultValue: ""
  },
  increment: 1,
  thousandsSeparator: true,
  thisControlType: "ENumberHorizon",
  label: ""
};
// 日期时间控件
var EDatetimePicker = {
  size: {
    w: 162,
    h: 21,
    labelw: 53
  },
  configs: {
    fieldName: "",
    required: false,
    defaultValue: ""
  },
  mode: "datetime",
  format: "YYYY年M月D日 H时m分",
  thisControlType: "EDatetimePicker",
  label: ""
};
// 日期时间范围选择控件
var ETimeHorizon = {
  size: {
    w: 324,
    h: 21,
    labelw: 53
  },
  configs: {
    fieldName: "",
    required: false,
    defaultValue: ""
  },
  mode: "datetime",
  format: "YYYY年M月D日 H时m分",
  thisControlType: "ETimeHorizon",
  label: ""
};
// 是否选择器
var EBooleanCheckbox = {
  size: {
    w: 147,
    h: 20
  },
  configs: {
    fieldName: ""
  },
  defaultSelectedIndex: 0,
  options: [
    {
      label: "空",
      value: "NULL",
      disabled: false
    },
    {
      label: "是",
      value: "1",
      disabled: false
    },
    {
      label: "否",
      value: "0",
      disabled: false
    }
  ],
  setting: {
    name: "ESelect",
    action: "GetXLdata"
  },
  thisControlType: "ESelect",
  label: ""
};
export const e1AdvancedSearch = {
  mixins: [requestControls, pageControls],
  data() {
    return {
      rangeSelectControls: ["INR", "FNR", "DR", "DTR"],
      advancedSearchModalShow: false, // 定义的列表视图高级检索窗口显示状态
      advancedSearchFormConfig: null, // 定义的列表视图高级检索条件表单
      advancedSearchColumns: null, // 参与高级检索的字段定义
      advancedSearchFormData: {}, // 默认的查询条件
      advancedSearchModalButtons: [
        {
          title: "高级检索",
          icon: "check",
          key: "build-condition",
          ghost: true,
          loading: false
        },
        {
          title: "搜索",
          icon: "search",
          returnData: true,
          key: "search",
          loading: false
        }
      ]
    };
  },
  created() {
    //console.log("混入e1AdvancedSearch的通用方法");
  },
  methods: {
    /**
     * 获取表格对应的高级查询表单条件定义
     * @param tb_name
     * @param cols
     */
    getAdvancedSearchModalFormConfig: function(tb_name, cols, button) {
      if (this.advancedSearchFormConfig) {
        this.advancedSearchModalShow = true;
        return;
      }
      let that = this;
      let params = {
        action: "GetSearchDefaultQueryFields",
        tb_name: tb_name,
        cols: JSON.stringify(cols)
      };
      this.startProcessing(button);
      //console.log("select通过查询条件",listFilter, eTableIsSave);7uy
      this.post("Form.ashx", params, {
        success: function(res) {
          console.log("查询到对应的数据", res);
          that.finishedProcessing(button);
          if (res && res.data) {
            that.advancedSearchModalShow = true;
            let tempColumns = [].concat([], res.data);
            if (tempColumns.length > 0) {
              that.advancedSearchColumns = tempColumns;
              that.advancedSearchFormConfig = that.lineFeed();
            }
          } else {
            that.alertWarning("获取高级检索表单配置失败");
            that.finishedProcessing(button);
          }
        },
        error: function(err) {
          console.error("获取指定表数据失败", err);
          that.finishedProcessing(button);
          that.alertWarning("获取高级检索表单配置失败");
        }
      });
    },
    /**
     * 将advancedSearchColumns数组，进行控件换行
     */
    lineFeed() {
      let columns = [];
      let moreFormRows = [];
      let j = 0;
      for (let i = 0; i < this.advancedSearchColumns.length; i++) {
        let tempData = {};
        //设置默认值
        let fieldName = this.advancedSearchColumns[i].fieldName;
        let searchType = this.advancedSearchColumns[i].type;
        let label = this.advancedSearchColumns[i].label;
        let nowColumn = this.thisColumns.find(function(c) {
          return c.field === fieldName;
        });
        if (nowColumn) {
          // 如果在列定义中已经自定义了列查询类型
          if (nowColumn.searchType) {
            searchType = nowColumn.searchType;
          }
          if (searchType) {
            tempData = this.getSearchControl(searchType, fieldName, label);
          }
        }
        if (tempData) {
          // 范围类选择器
          if (searchType && this.rangeSelectControls.includes(searchType)) {
            moreFormRows.push({
              key: Math.random(),
              columns: [tempData],
              colSpan: 24
            });
          } else {
            if (j < 2) {
              columns.push(tempData);
            } else {
              if (j % 2 != 0) {
                columns.push(tempData);
              } else {
                if (columns.length > 0) {
                  moreFormRows.push({
                    key: Math.random(),
                    columns: columns,
                    colSpan: 12
                  });
                }
                columns = [tempData];
              }
            }
            j++;
          }
        }
      }
      if (columns.length > 0) {
        moreFormRows.push({
          key: Math.random(),
          columns: columns,
          colSpan: 24 / columns.length
        });
      }
      return moreFormRows;
      //console.log("moreFormRows",this.moreFormRows);
    },
    /**
     * 根据指定的查询类型，获取对应的配置
     * @param type
     * @param fieldName
     * @param label
     */
    getSearchControl: function(type, fieldName, label) {
      let control;
      switch (type) {
        case "T": {
          // 文本输入框
          control = this.$util.deepCopy(EIput);
          control.configs.fieldName = fieldName + "-LIKE";
          break;
        }
        case "IN": {
          // 整型数字查询
          control = this.$util.deepCopy(NumericUpDown);
          //this.advancedSearchFormData =
          break;
        }
        case "INR": {
          // 整型数字范围查询
          control = this.$util.deepCopy(ENumberHorizon);
          control.configs.fieldName = fieldName + "-N-FW";
          //this.advancedSearchFormData =
          break;
        }
        case "FN": {
          // 浮点型数字
          control = this.$util.deepCopy(NumericUpDown);
          control.increment = 0.1;
          control.decimalPlaces = 2; // 小数两位
          break;
        }
        case "FNR": {
          // 浮点型数字范围查询
          control = this.$util.deepCopy(ENumberHorizon);
          control.configs.fieldName = fieldName + "-N-FW";
          control.increment = 0.1;
          control.decimalPlaces = 2; // 小数两位
          break;
        }
        case "B": {
          // 是否
          control = this.$util.deepCopy(EBooleanCheckbox);
          control.configs.fieldName = fieldName + "-B";
          break;
        }
        case "D": {
          // 日期RQ
          control = this.$util.deepCopy(EDatetimePicker);
          control.configs.fieldName = fieldName + "-RQ";
          control.mode = "date";
          control.format = "YYYY年M月D日";
          break;
        }
        case "DR": {
          // 日期范围
          control = this.$util.deepCopy(ETimeHorizon);
          control.configs.fieldName = fieldName + "-RQ-FW";
          control.mode = "date";
          control.format = "YYYY年M月D日";
          break;
        }
        case "DT": {
          // 日期时间
          control = this.$util.deepCopy(EDatetimePicker);
          control.configs.fieldName = fieldName + "-DT";
          break;
        }
        case "DTR": {
          // 日期时间范围
          control = this.$util.deepCopy(ETimeHorizon);
          control.configs.fieldName = fieldName + "-DT-FW";
          break;
        }
      }
      if (control) {
        control.label = label;
        if (control.configs.fieldName === "") {
          control.configs.fieldName = fieldName;
        }
      }
      return control;
    },
    /**
     * 接收高级查询事件
     */
    onAdvancedSearch: function(event) {
      console.log("进行查询", event);
      if (event.key === "search") {
        // 触发查询
        this.doSearch(event);
      } else if (event.key === "build-condition") {
        // 打开高级检索界面
        this.advancedSearchModalShow = false;
        this.searchModalShow = true;
      }
    }
  }
};
