import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: JSON.parse(sessionStorage.getItem("userInfo")) || {},
    hasLogin: !!sessionStorage.getItem("userInfo"), //是否登录
    isShowLoginConfirm: false, // 是否已经显示登陆确认框
    menuList: null, // 当前登录人的菜单权限
    systemConfigs: null, // 从后台获取的一些系统配置参数
    nowShowNoticePageCurrent: 1,
    cacheData: null,
    canBackSpace: false,
    closedKey: null,
    searchMenuHistory:
      JSON.parse(sessionStorage.getItem("searchMenuHistory")) || [] // 菜单搜索历史记录
  },
  mutations: {
    login(state, uinfo) {
      uinfo.loginTime = moment().format("YYYY-MM-DD HH:mm:ss"); // 设置用户登录时间
      sessionStorage.setItem("userInfo", JSON.stringify(uinfo));
      state.userInfo = uinfo;
      state.hasLogin = true;
      state.isShowLoginConfirm = false;
    },
    logout(state) {
      sessionStorage.removeItem("userInfo");
      state.userInfo = null;
      state.menuList = null;
      state.hasLogin = false;
      state.searchMenuHistory = [];
      sessionStorage.removeItem("searchMenuHistory");
    },
    setMenu(state, menuList) {
      state.menuList = menuList;
    },
    setSystemConfigs(state, config) {
      if (state.systemConfigs) {
        state.systemConfigs = Object.assign({}, state.systemConfigs, config);
      } else {
        state.systemConfigs = config;
      }
    },
    setSearchMenuHistory(state, searchMenuHistory) {
      state.searchMenuHistory = searchMenuHistory;
      sessionStorage.setItem(
        "searchMenuHistory",
        JSON.stringify(searchMenuHistory)
      );
    },
    setShowLoginConfirm(state) {
      // 设置已显示登录确认框
      state.isShowLoginConfirm = true;
    },
    setHideLoginConfirm(state) {
      // 设置已经关闭登录确认框
      state.isShowLoginConfirm = false;
    },
    setNowShowNoticePageCurrent(state, current) {
      // 设置已经关闭登录确认框
      state.nowShowNoticePageCurrent = current;
    },
    setCacheData(state, current) {
      // 设置本地缓存数据
      state.cacheData = current;
      // 两秒之后字段清空缓存
      /*window.setTimeout(function() {
        if (state.cacheData) {
          state.cacheData = null;
        }
      }, 3000);*/
    },
    clearSearchMenuHistory(state) { // 清除查询历史
      state.searchMenuHistory = [];
      sessionStorage.removeItem("searchMenuHistory");
    },
    clearCacheData(state) {
      // 清楚本地数据缓存
      state.cacheData = null;
    }
  },
  getters: {
    getSearchMenuHistory(state) {
      return state.searchMenuHistory;
    },
    getLoginTime: function(state) {
      // 登录时间
      return moment(state.userInfo.loginTime).toDate();
    },
    getRandomKey: function(state) {
      return state.userInfo.randomKey;
    },
    getUserName: function(state) {
      // 登录用户名
      return state.userInfo.userName;
    },
    getUserDept: function(state) {
      // 用户部门名
      return state.userInfo.userDept;
    },
    getDepartId: function(state) {
      // 部门ID
      return state.userInfo.departId;
    },
    getUserID: function(state) {
      // 登录用户id
      return state.userInfo.userID;
    },
    getUserQrimg: function(state) {
      // 用户二维码
      return state.userInfo.userQrimg;
    },
    getEmployee: function(state) {
      // 登录人对应员工姓名
      return state.userInfo.employee;
    },
    getEmployeeId: function(state) {
      // 登录人对应员工id
      return state.userInfo.employeeId;
    },
    getCompanyId: function(state) {
      // 登录人公司id
      return state.userInfo.companyId;
    }
  },
  actions: {},
  modules: {}
});
