<template>
  <a-dropdown>
    <a-button
      :class="buttonClass"
      :disabled="disabled"
      @click="onButtonClick"
      :title="tip"
    >
      <a-icon slot="icon" type="user" />
      <e-icon
        v-show="!thisLoading"
        :type="icon"
        :iClass="iconClass"
        :iStyle="iStyle"
      />
      <e-icon v-show="thisLoading" type="loading" :iStyle="iStyle" />
      <span v-if="title" :class="iconButtonTitle">{{ title }}</span>
    </a-button>
    <a-menu slot="overlay">
      <a-menu-item key="1"><a-icon type="user" />1st menu item</a-menu-item>
      <a-menu-item key="2"><a-icon type="user" />2nd menu item</a-menu-item>
      <a-menu-item key="3"><a-icon type="user" />3rd item</a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
export default {
  name: "EIconSlect",
  data() {
    return {
      iStyle: {},
      loadingIcon: "",
      thisLoading: false,
      iconClass: ["iconButton_icon"]
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: null
    },
    iconSize: {
      type: Number,
      default: 18
    },
    size: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: function() {
        return "defalut";
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: function() {
        return {};
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    keyID: {
      type: [Number, String],
      required: true
    },
    tip: {
      type: String,
      default: function() {
        return null;
      }
    }
  },
  watch: {
    loading: function(v) {
      //console.log("按钮状态变化",v);
      this.thisLoading = v;
    }
  },
  computed: {
    buttonClass: function() {
      let defaultClass = ["iconButton", "ant-btn"];
      if (this.size) {
        if (this.size === "default") {
          return defaultClass;
        } else if (this.size === "small") {
          return ["iconButton__small", "ant-btn"];
        } else if (this.size === "large") {
          return ["iconButton__large", "ant-btn"];
        }
      } else {
        return defaultClass;
      }
    },
    iconButtonTitle: function() {
      if (this.size) {
        if (this.size === "default") {
          return ["iconButton-title"];
        } else if (this.size === "small") {
          return ["iconButton-title__small"];
        } else if (this.size === "large") {
          return ["iconButton-title__large"];
        }
      } else {
        return ["iconButton-title"];
      }
    }
  },
  created() {
    this.setIStyle();
    this.thisLoading = this.loading;
    if (this.type === "primary") {
      this.iconClass.push("iconButton_icon--primaryColor");
      this.buttonClass.push("ant-btn-primary");
    } else {
      this.iconClass.push("iconButton_icon--color");
    }
  },
  methods: {
    onButtonClick() {
      if (!this.thisLoading) this.$emit("click", this.keyID);
    },
    setIStyle: function() {
      if (this.size) {
        if (this.size === "default") {
          this.iStyle.fontSize = "18px";
        } else if (this.size === "small") {
          this.iStyle.fontSize = "16px";
        } else if (this.size === "large") {
          this.iStyle.fontSize = "20px";
        }
      } else {
        this.iStyle.fontSize = this.iconSize + "px";
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../assets/style/base/components/e-icon-slect.less";
</style>
