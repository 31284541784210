<template>
  <div class="e-form-numberic">
    <!-- val:{{setvalue}}
    type: {{typeof setvalue}} -->
    <a-input-number
      :defaultValue="defaultValue"
      v-model="setvalue"
      :disabled="thisDisabled"
      :size="controlSize"
      :readOnly="readOnly"
      v-show="visible"
      :style="setwidth"
      :max="max"
      :min="min"
      :precision="precision"
      :step="step"
      :formatter="
        value =>
          separator && value >= 1000
            ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : `${value}`
      "
      :class="notNumberHandlerWrap ? 'not-number-handler-wrap' : ''"
    ></a-input-number>
    <!-- 控件后附带的注释提醒 -->
    <li v-if="thisOldValue" class="form_right_icon">
      <a-tooltip
        placement="topRight"
        :title="'原值：' + thisOldValue"
        arrow-point-at-center
      >
        <a style="height: auto;"><e-icon type="exclamation-circle"></e-icon></a>
      </a-tooltip>
    </li>
    <div v-if="tip" class="e-form-nextTip">{{ tip }}</div>
  </div>
</template>

<script>
export default {
  name: "ENumericUpDown",
  data() {
    return {
      setvalue: null,
      controlSize: this.$config.defaultControlSize,
      thisOldValue: null,
      showValue: null,
      setwidth: this.widthSet(),
      thisDisabled: false
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    defaultValue: {
      type: [Number, String, null],
      default: null
    },
    value: {
      type: [Number, String, null],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: ""
    },
    max: {
      type: [String, Number]
    },
    min: {
      type: [String, Number]
    },
    precision: {
      type: [String, Number],
      default: 0
    },
    step: {
      type: [String, Number],
      default: 1
    },
    separator: {
      type: Boolean,
      default: false
    },
    tip: {
      type: String,
      default: null
    },
    notNumberHandlerWrap: {
      type: Boolean,
      default: false
    },
    modifiedValue: {
      type: [String, Number],
      default: null
    }
  },
  created() {
    this.thisDisabled = this.disabled;
  },
  mounted() {
    let defaultV = this.$util.getNumber(this.defaultValue);
    let nV = this.$util.getNumber(this.value);
    this.setvalue = nV != null || defaultV != nV ? nV : defaultV;
    this.showValue = this.modifiedValue;
    if (this.showValue != null) {
      this.thisOldValue = this.setvalue;
      this.setvalue = this.showValue;
    }
    // this.init();
    console.log("初始化ENumericUpDown的Value=", this.setvalue, this.showValue);
  },
  computed: {
    toInt(num) {
      return Number(num) == NaN || num == "NaN" ? 0 : Number(num);
    }
  },
  methods: {
    /**
     * 设置禁用状态
     * @param v
     */
    setDisabled(v) {
      if(this.$util.isType(v) === "Boolean") {
        this.thisDisabled = v;
      }else {
        this.thisDisabled = true;
      }
    },
    /**
     * 切换当前控件的禁用状态
     */
    setDisabledSwitch() {
      this.thisDisabled = !this.thisDisabled;
    },
    init() {
      if (this.setvalue != null && this.setvalue != "") {
        this.$emit("change", this.setvalue);
      }
    },
    blur() {
      this.$emit("blur", this.setvalue);
    },
    focus() {
      this.$emit("focus", this.setvalue);
    },
    defaultValueSet() {
      this.$emit("change", this.defaultValue);
      return this.defaultValue;
    },
    widthSet() {
      if (this.width == "") {
        this.setwidth = "";
        return "";
      }
      if (typeof this.width == "number") {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      if (this.width.indexOf("%") == -1 && this.width.indexOf("px") == -1) {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      this.setwidth = "width:" + this.width;
      return "width:" + this.width;
    }
  },
  watch: {
    setvalue(value, oldValue) {
      if (value !== oldValue) {
        if (value == "NaN") {
          value = 0;
        }
        this.$emit("change", value);
      }
    },
    value(value) {
      this.setvalue = value;
    },
    width() {
      this.widthSet();
    },
    disabled(v) {
      this.thisDisabled = v;
    },
    modifiedValue: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(val) {
        //console.log("监听ENumericUpDown值", val,this.setvalue);
        if (val) {
          // 新值和老值互换显示
          //this.thisOldValue = this.setvalue;
          this.showValue = val;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.e-form-numberic {
  position: relative;
}
.form_right_icon {
  position: absolute;
  width: 20px;
  height: calc(100%);
  top: 0;
  right: -25px;
  z-index: 1200;
}
::v-deep .ant-input-number-handler-wrap {
  width: 30px !important;
}
::v-deep .not-number-handler-wrap .ant-input-number-handler-wrap {
  display: none !important;
}
</style>
