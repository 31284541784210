/**
 * 生产工单确认单-工单排产工作台功能扩展
 * @type {{data(): *, methods: {}}}
 */
export const e1SC_SCGDQR_WORKBENCH = {
  data() {
    return {};
  },
  methods: {
    /**
     * 工单-操作
     * 确认排产操作
     */
    tsbStart(button) {
      this.updateWorkOrderStatus(button);
    },
    /**
     *工单-操作
     * 恢复生产操作
     */
    tsbResume(button) {
      this.updateWorkOrderStatus(button);
    },
    /**
     *工单-操作
     * 暂停生产操作
     */
    tsbSuspension(button) {
      this.updateWorkOrderStatus(button);
    },
    /**
     *工单-操作
     * 终止生产操作
     */
    tsbShutDown(button) {
      this.updateWorkOrderStatus(button);
    },
    /**
     * 修改工单确认单状态
     */
    updateWorkOrderStatus(button) {
      let rowKeys = null;
      if (this.selectedRowKeys.length >= 1) {
        rowKeys = this.getSelectedScDd(this.tableDatas, this.selectedRowKeys);
      }
      if (rowKeys && rowKeys.length > 0 && button && button.param) {
        let that = this;
        let bhAndIds = this.getSelectDatasFiled(this.tableDatas, rowKeys, [
          "_BDBH",
          "ID"
        ]);
        let params = {
          action: "UpdateWorkOrderStatus",
          orderSN: JSON.stringify(bhAndIds["_BDBH"]), //主表名
          updateStatus: button.param.et_name, // 模板id  从DHCS中获取
          dataID: JSON.stringify(bhAndIds["ID"]), // 插入之后返回的主键id
          t_id: this.tempId,
          randomKey: this.$store.getters.getRandomKey
        };
        this.startProcessing(button);
        this.post("Modules/Scgl.ashx", params, {
          success: function() {
            console.log("请求结果");
            that.finishedProcessing(button);
            that.getTableData();
            that.alertSuccess("操作成功");
          },
          error: function(err) {
            that.finishedProcessing(button);
            try {
              let jsonMessage = JSON.parse(err.message);
              let message = "";
              if (that.$util.isType(jsonMessage) === "Array") {
                // 多条错误信息
                jsonMessage.forEach(function(e, index) {
                  if (index == 0) message += e.message;
                  else message = message + "\n" + e.message;
                });
                that.alertError(message);
              } else {
                that.alertError(err.message);
              }
            } catch (e) {
              console.error(e);
              that.alertError(err.message);
            }
          }
        });
      } else {
        this.alert("请至少选择一条需要操作的生产订单");
      }
    },
    /**
     * 打开工单确认单的管理页面
     * @param button
     */
    tsbWorkOrder(button) {
      let rowKeys = null;
      if (this.selectedRowKeys.length >= 1) {
        rowKeys = this.getSelectedScDd(this.tableDatas, this.selectedRowKeys);
      }
      if (rowKeys && rowKeys.length == 1) {
        let bhAndIds = this.getSelectDatasFiled(this.tableDatas, rowKeys, [
          "_BDBH",
          "ID",
          "GDQRZT"
        ]);
        this.openTab({
          title: "管理工单-" + bhAndIds["_BDBH"][0],
          path: "/NewForm",
          query: {
            templetId: this.tempId,
            dataId: bhAndIds["ID"][0],
            type: "dateManageView",
            state: bhAndIds["GDQRZT"][0]
          }
        });
      } else {
        this.alert("请选择一条需要管理的工单");
      }
    }
  }
};
