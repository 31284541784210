<template>
  <div v-show="visible" :style="setwidth">
    <a-tooltip :title="departNames" arrow-point-at-center>
      <a-input
        @click="openModal"
        placeholder="请点击选择仓库"
        :size="controlSize"
        v-model="departNames"
        readOnly
        :disabled="thisDisabled"
      >
        <e-icon slot="prefix" type="plus" title="仓库选择控件" :disabled="thisDisabled"/>
        <a-icon
          v-if="showClear"
          slot="suffix"
          type="close-circle"
          theme="filled"
          @click.stop="handleEmpty"
          title="清空"
          :style="{ color: 'rgba(0, 0, 0, 0.25)' }"
        />
      </a-input>
    </a-tooltip>
    <EInventoryLocationTree
      v-if="show"
      ref="selectModal"
      :mode="mode"
      @ok="handleOK"
      :value="setvalue"
      :defaultValue="defaultValue"
      :bdmbId="bdmbId"
      :setting="thisSetting"
      :search="search"
      :keyType="keyType"
      :filterSave="filterSave"
      @filterSave="filterSaveOk"
      :okValue="okValue"
      @okValue="okValueOk"
      :defaultDataType="defaultDataType"
      :defaultDataValue="defaultDataValue"
    ></EInventoryLocationTree>
  </div>
</template>

<script>
import EInventoryLocationTree from "./EInventoryLocationTree.vue";
export default {
  name: "EInventoryLocation",
  data() {
    return {
      setvalue: this.value,
      controlSize: this.$config.defaultControlSize,
      departIds: "",
      departNames: "",
      setwidth: this.widthSet(),
      thisSetting: {
        serverName: "Form.ashx"
      },
      show: true,
      thisDisabled: false
    };
  },
  components: {
    EInventoryLocationTree
  },
  model: {
    prop: "value",
    event: "change",
    type: Array
  },
  props: {
    defaultValue: {
      type: [String, Array, Number]
    },
    value: {
      type: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: [Array, null],
      default: null
    },
    mode: {
      type: String,
      default: "single"
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    formula: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: ""
    },
    bdmbId: {
      type: [String, Number]
    },
    setting: {
      type: [Object],
      default: () => ({
        treeAction: "GetWZFLList",
        nodeAction: "GetWZInfo"
      })
    },
    linkage: {
      type: Array,
      default: () => []
    },
    search: {
      type: String,
      default: ""
    },
    keyType: {
      type: String,
      default: ""
    },
    filterSave: {
      type: null
    },
    okValue: {
      type: Array,
      default: function() {
        return [];
      }
    },
    defaultDataType: {
      type: Number,
      default: 0
    },
    defaultDataValue: {
      type: String,
      default: null
    }
  },
  created() {
    console.log("linkage:", this.linkage);
    console.log("options:", this.options);
    console.log("value:", this.value);
    this.thisDisabled = this.disabled;
    if (this.setting && !this.setting.serverName) {
      this.thisSetting = Object.assign(this.thisSetting, this.setting);
    } else {
      this.thisSetting = this.setting;
    }
  },
  computed: {
    showClear: function() {
      return (
        this.departIds &&
        this.departIds != "" &&
        !this.thisDisabled &&
        !this.readOnly
      );
    }
  },
  methods: {
    /**
     * 设置禁用状态
     * @param v
     */
    setDisabled(v) {
      if(this.$util.isType(v) === "Boolean") {
        this.thisDisabled = v;
      }else {
        this.thisDisabled = true;
      }
    },
    /**
     * 切换当前控件的禁用状态
     */
    setDisabledSwitch() {
      this.thisDisabled = !this.thisDisabled;
    },
    openModal() {
      if (!this.readOnly) {
        // this.show = true;
        // this.$nextTick(() => {
        this.$refs.selectModal.show();
        // });
      }
    },
    handleOK(rows, idstr) {
      this.departNames = "";
      this.departIds = "";
      this.setvalue = "";
      if (!rows) {
        this.departNames = "";
        this.departIds = "";
        this.setvalue = "";
      } else if (rows.length == 1) {
        let temp = "";
        for (let item of rows) {
          temp = item.title;
          this.setvalue = item.key;
        }
        this.departNames = temp;
        this.departNames == "" || this.departNames == null
          ? (this.departIds = "")
          : (this.departIds = idstr);
      } else if (rows.length > 1) {
        let temp = "";
        let values = "";
        for (let item of rows) {
          temp += "," + item.title;
          values += "," + item.key;
        }
        this.departNames = temp.substring(1);
        this.setvalue = values.substring(1);
        this.departNames == "" || this.departNames == null
          ? (this.departIds = "")
          : (this.departIds = idstr);
      }
      if (rows) {
        this.$emit("linkedChange", this.linkedChange(rows));
      }
      this.$emit("change", this.setvalue, this.departNames);
      console.log("departIds:", this.departIds);
    },
    linkedChange(data) {
      // {mbField:field}
      var json = {};
      this.linkage.forEach(element => {
        if (this.$util.isType(data[0][element.field])!=="Undefined") {
          json[element.mbField] = data[0][element.field];
        }
      });
      //console.log("控件linkedChange=", json);
      return json;
    },
    handleEmpty() {
      this.$refs.selectModal.Empty();
      this.handleOK(null);
    },
    widthSet() {
      if (this.width == "") {
        this.setwidth = { "min-width": "350px", width: "350px" };
        return { "min-width": "350px", width: "350px" };
      }
      if (typeof this.width == "number") {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      if (this.width.indexOf("%") == -1 && this.width.indexOf("px") == -1) {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      this.setwidth = "width:" + this.width;
      return "width:" + this.width;
    },
    filterSaveOk(data) {
      this.$emit("filterSave", data);
    },
    okValueOk(data) {
      this.$emit("okValue", data);
    }
  },
  watch: {
    width() {
      this.widthSet();
    },
    value(v) {
      if (!v || v === "" || v == 0) {
        this.departNames = "";
        this.departIds = "";
      }
    },
    disabled(v) {
      this.thisDisabled = v;
    },
  }
};
</script>
<style scoped lang="less">
@import "../../../assets/style/base/controls/e-pop-up-select-input.less";
</style>
