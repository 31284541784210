<template>
  <div ref="select" tabindex="1" class="eselect-div">
    <!-- {{filter}} -->
    <a-select
      v-if="option.length < maxOption"
      v-model="setVal"
      :defaultValue="defaultValue"
      :value="setVal"
      :disabled="thisDisabled"
      :mode="mode == 'multi' ? 'multiple' : mode"
      :readOnly="readOnly"
      v-show="visible"
      :maxTagTextLength="MaxLength"
      :size="controlSize"
      :style="setWidth"
      :loading="dataLoading"
      :filterOption="filterOption"
      showSearch
      :dropdownMenuStyle="{ 'min-height': '30px' }"
      :dropdownMatchSelectWidth="false"
      :maxTagCount="maxTagCount"
      @inputKeydown="inputKeydown"
      ref="selectE"
    >
      <template v-if="option.length > 0">
        <a-select-option
          v-for="(row, index) in option"
          :key="'search' + index"
          :value="row.value"
          class="option-height"
        >{{ row.label }}</a-select-option>
        <!-- {{row.label}} -->
      </template>
    </a-select>
    <!-- :options="option.length > 300 || option == '' ? [] : option" -->
    <a-select
      showSearch
      v-else
      v-model="setVal"
      :defaultActiveFirstOption="false"
      :filterOption="false"
      @search="handleSearch"
      :size="controlSize"
      :notFoundContent="null"
      :style="setWidth"
      :readOnly="readOnly"
      :disabled="thisDisabled"
      :dropdownMatchSelectWidth="false"
      :loading="dataLoading"
      :mode="mode == 'multi' ? 'multiple' : mode"
      :dropdownMenuStyle="{ 'min-height': '30px' }"
      :maxTagTextLength="MaxLength"
      :maxTagCount="maxTagCount"
      placeholder="请输入文字筛选数据"
      @inputKeydown="inputKeydown"
      ref="selectE"
    >
      <template v-if="cacheData.length > 0">
        <a-select-option
          v-for="(row, index) in cacheData"
          :key="'search' + index"
          :value="row.value"
          class="option-height"
        >{{ row.label }}</a-select-option>
        <!-- {{row.label}} -->
      </template>
      <div slot="dropdownRender" slot-scope="more">
        <v-nodes :vnodes="more" />
        <a-divider style="margin: 2px 0;" />
        <div style="padding: 1px; cursor: pointer;text-align:center;">
          <span :style="{ 'font-size': '12px' }" v-html="getMore"></span>
        </div>
      </div>
    </a-select>
    <!-- {{cacheData}} -->
    <!-- <div>{{ cacheData }}</div> -->
    <!-- 控件后附带的注释提醒 -->
    <li v-if="thisOldValue" class="form_right_icon">
      <a-tooltip placement="topRight" :title="'原值：' + thisOldLabel" arrow-point-at-center>
        <a style="height: auto;">
          <e-icon type="exclamation-circle"></e-icon>
        </a>
      </a-tooltip>
    </li>
  </div>
</template>

<script>
import { ESelectControl } from "./mixin/ESelectControl.js"; // 页面控制方法
export default {
  mixins: [ESelectControl],
  name: "ESelect",
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  data() {
    return {
      // option: this.options,
      option: [{ label: "", value: "" }],
      controlSize: this.$config.defaultControlSize,
      dataLoading: false,
      thisSetting: {
        serverName: "Form.ashx"
      },
      first: true, //
      firstNotNull: true, // 另一个是否一次加载，比上一个first稍晚
      // maxOption: 15000, // 查询select触发数
      pushCacheNum: 100, // 初始插入查询下拉的数量
      cacheData: [],
      chineseName: "",
      moreSelectNum: 0,
      selectNum: 0, // 当前显示条数
      maxCacheNum: 100, // 查询最多显示数量
      showValue: null,
      thisOldValue: null,
      thisOldFilter: null,
      thisDisabled: false
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    maxOption: {
      type: Number,
      default: 300
    },
    defaultValue: {
      type: String,
      default: null
    },
    value: {
      type: [Number, String, Array, Object],
      default: function() {
        return null;
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    mode: {
      type: String,
      default: "default"
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    MaxDropDownItems: {
      type: Number,
      default: null
    },
    MaxLength: {
      type: Number,
      default: 50
    },
    maxTagCount: {
      type: Number,
      default: 5
    },
    width: {
      type: [String, Number],
      default: ""
    },
    filter: {
      type: Object,
      default: function() {
        return null;
      }
    },
    setting: {
      type: Object,
      default: () => ({})
    },
    // 默认选中项
    defaultSelectedIndex: {
      type: Number,
      default: -1
    },
    isInTable: {
      type: Boolean,
      default: false
    },
    emptyOption: {
      type: Boolean,
      default: false
    },
    modifiedValue: {
      type: [String, Number],
      default: null
    },
    linkage: {
      type: Array,
      default: () => []
    },
    debugerField: {
      type: String,
      default: ""
    }
  },
  created() {
    console.log("获取值", this.value);
    this.thisDisabled = this.disabled;
    if (this.setting && !this.setting.serverName) {
      this.thisSetting = Object.assign(this.thisSetting, this.setting);
    } else {
      this.thisSetting = this.setting;
    }
    this.thisOldFilter = this.filter;
    if (this.options && this.options.length > 0) {
      if (this.emptyOption) {
        let tempOptions = [...this.options];
        if (!this.options[0]) {
          tempOptions.push({ label: "", value: "" });
        } else if (
          this.options[0].value !== "" &&
          this.options[0].label !== ""
        ) {
          tempOptions.unshift({ label: "", value: "" });
        }
        this.option = tempOptions;
        /*for (var i = 0; i < this.options.length; i++) {
          this.option.push(this.options[i]);
        }*/
      } else {
        this.option = this.options;
      }
      this.$emit("filterSave", this.option, false);
    } else {
      this.filterGet();
    }
  },
  computed: {
    // 显示老选项的计算属性
    thisOldLabel: function() {
      if (this.option && this.option.length > 0 && this.thisOldValue != null) {
        let selected = null;
        if (this.thisOldValue) {
          if (typeof this.thisOldValue === "number") {
            selected = this.thisOldValue + "";
          } else {
            if (this.mode == "multi") {
              if (this.thisOldValue == null || this.thisOldValue == "") {
                return "";
              } else {
                selected = this.thisOldValue.split(",");
              }
            } else selected = this.thisOldValue;
          }
        }
        if (selected && typeof selected === "string") {
          let selectObject = this.option.find(function(d) {
            return d.value + "" === selected;
          });
          return selectObject ? selectObject.label : "";
        } else if (selected) {
          // 可能是多选
          let result = "";
          for (let i = 0; i < selected.length; i++) {
            let selectObject1 = this.option.find(function(d1) {
              return d1.value + "" === selected[i];
            });
            if (selectObject1 && i == 0) result = selectObject1.label;
            else if (selectObject1) result = result + "," + selectObject1.label;
          }
          return result;
        }
      }
      return "";
    },
    setWidth: function() {
      let widthObj = { minWidth: "50px" };
      if (this.width !== "") {
        if (typeof this.width === "number") {
          widthObj.width = this.width + 22 + "px";
        } else if (this.$util.isNumberString(this.width)) {
          widthObj.width = Number(this.width) + 22 + "px";
        } else if (this.$util.isPixelString(this.width)) {
          let tempW = this.width.slice(0, -2);
          widthObj.width = Number(tempW) + 22 + "px";
        } else if (this.$util.isPercentageString(this.width)) {
          widthObj.width = this.width;
        }
      }
      if (this.width == "" && this.isInTable) {
        widthObj.width = "100%";
        delete widthObj.maxWidth;
      }
      return widthObj;
    },
    setVal: {
      get: function() {
        if (this.showValue) {
          if (typeof this.showValue === "number") {
            return this.showValue + "";
          }
          if (this.mode == "multi") {
            if (this.showValue == null || this.showValue == "") {
              return [];
            } else {
              return this.showValue.split(",");
            }
          }
          return this.showValue;
        } else {
          if (typeof this.value === "number") {
            return this.value + "";
          }
          if (this.mode == "multi") {
            if (this.value == null || this.value == "") {
              return [];
            } else {
              return this.value.split(",");
            }
          }
          return this.value;
        }
        // return []
      },
      set: function(nValue) {
        if (this.debugerField != "") {
          debugger;
        }
        if (this.firstNotNull && (nValue == null || nValue == "")) {
          return;
        }
        var value = nValue;
        var chineseName = "";
        let selectIndex = -1;
        if (
          (nValue == "" || nValue == null) &&
          this.defaultSelectedIndex != -1
        ) {
          if (this.option[this.defaultSelectedIndex]) {
            value = this.option[this.defaultSelectedIndex].value;
            chineseName = this.option[this.defaultSelectedIndex].label;
            selectIndex = this.defaultSelectedIndex;
            // 抛出明细表默认选择项
            this.$emit("defaultValueSave", value);
          }
        }
        if (value != "") {
          for (var i = 0; i < this.option.length; i++) {
            if (this.option[i].value === value) {
              chineseName = this.option[i].label;
              selectIndex = i;
              break;
            }
          }
        }
        if (this.$util.isType(value) == "Array") {
          if (value == "") {
            value = "";
          } else {
            var valueStr = "";
            for (let item of value) {
              valueStr += "," + item;
            }
            value = valueStr.substring(1);
          }
        } else {
          // 单值选人可能触发联动操作
          if (this.thisOldFilter && this.thisOldFilter.sourceTable) {
            // 目前只有从接口获取到的数据可以进行联动
            let linkData = this.linkedChange(this.option[selectIndex]);
            if (linkData) this.$emit("linkedChange", linkData);
          }
        }
        this.$emit("change", value, chineseName);
      }
    },
    getMore: function() {
      return `共${this.moreSelectNum}条数据,当前显示${this.selectNum}`;
    }
  },
  methods: {
    /**
     * 根据filter配置从数据库获取下拉列表数据
     */
    filterGet() {
      if (this.debugerField != "") {
        debugger;
      }
      if (this.debugerField == null) {
        return;
      }
      this.removeDuplicates();
      this.fiterLabel();
      this.first = false;
      if (this.thisOldFilter && this.thisOldFilter.sourceTable) {
        this.dataLoading = true;
        //是否需要表自己缓存每一行的选项
        let _this = this;
        this.getSelectOptions_MiXin(this.thisOldFilter, this.value, function(
          res
        ) {
          if (res) {
            _this.option = res.data;
            if (_this.emptyOption) {
              if (!_this.option[0]) {
                _this.option.unshift({ label: "", value: "" });
              } else if (
                _this.option[0].value != "" &&
                _this.option[0].label != ""
              ) {
                _this.option.unshift({ label: "", value: "" });
              }
            }
            let result;
            if (res.result) {
              result = res.result;
            }
            _this.removeDuplicates();
            _this.fiterLabel(result);
            // _this.fiterLabel();
            _this.firstNotNull = false;
            _this.dataLoading = false;
            // 提供一个缓存给父组件，做到同一类别的select组件不重复下载数据
            _this.$emit("filterSave", _this.option, res.haveVariable);
            if (_this.option.length > _this.maxOption) {
              //let cache = [];
              let o = _this.pushCacheNum;
              for (let i = _this.cacheData.length; i < o; i++) {
                if (
                  _this.cacheData.findIndex(
                    item => item.value === _this.option[i].value
                  ) != -1
                ) {
                  o += 1;
                  continue;
                }
                // cache.push(_this.option[i]);
                _this.cacheData.push(_this.option[i]);
                _this.moreSelectNum =
                  _this.option.length - _this.cacheData.length;
              }
              // 这里控制为最大筛选数
              // if (cache.length > _this.maxCacheNum) {
              // for (var o = 0; o < _this.maxCacheNum; o++) {
              //   _this.cacheData.push(cache[o]);
              // }
              // _this.moreSelectNum = _this.option.length - _this.cacheData.length;
              // }
              _this.selectNum = _this.cacheData.length;
              //console.log("-1", _this.selectNum);
            } else {
              _this.dataLoading = false;
            }
          }
        });
      } else {
        this.firstNotNull = false;
      }
      if (this.option.length > this.maxOption) {
        for (let j = 0; j < this.pushCacheNum; j++) {
          if (
            this.cacheData.findIndex(
              item => item.value === this.option[j].value
            ) != -1
          ) {
            continue;
          }
          this.cacheData.push(this.option[j]);
          this.moreSelectNum = this.option.length - this.pushCacheNum;
        }
        this.selectNum = this.cacheData.length;
        //console.log("-2", this.selectNum);
      }
    },
    /**
     * 查询label获得值
     */
    fiterLabel(res) {
      if (this.debugerField != "") {
        debugger;
      }
      var thisValue = this.value;
      var selectValue = "";
      if (thisValue != null || thisValue != "") {
        try {
          thisValue = thisValue.trim();
        } catch (err) {
          err;
        }
      }
      if (res) {
        if (res.value == null || res.value == "") {
          // this.setVal = "";
          this.setVal = this.defaultSelected();
          //console.log("1");
          return;
        }
      }
      var valueType = this.$util.isType(thisValue);
      if (thisValue == "" || thisValue == null) {
        this.setVal = this.defaultSelected();
        //console.log("2");
        return;
      }
      if (this.option.length != 0) {
        for (var i = 0; i < this.option.length; i++) {
          if (valueType === "Object") {
            this.chineseName = thisValue.label;
            this.setVal = thisValue.value;
            //console.log("3");
            break;
          } else {
            if (
              this.option[i].label + "" === thisValue + "" ||
              this.option[i].value + "" === thisValue + ""
            ) {
              this.chineseName = this.option[i].label;
              selectValue = this.option[i].value;
              if (
                this.option.length > this.maxOption
                // &&
                // this.setData.findIndex(item => {debugger;item.value === selectValue}) == -1
              ) {
                this.fetch(
                  this.option[i].label,
                  data => {
                    this.setCacheData(data);
                  }
                  // true
                );
              }
              break;
              // return;
            } else if (!this.first) {
              // debugger
              //console.log(thisValue + "查询次数");
              selectValue = "";
            }
          }
        }
        this.setVal = selectValue;
        //console.log("4");
        return;
      }
      if (valueType === "Object") {
        this.setVal = thisValue.value;
        //console.log("5");
      } else {
        this.setVal = thisValue;
        //console.log("6");
      }
    },
    /**
     * 没有初始值时默认选择第几列
     * @param
     * @return
     */
    defaultSelected() {
      var index = this.defaultSelectedIndex;
      var returnData;
      if (index == -1) {
        if (this.mode != "defined") {
          return null;
        }
        return "";
      }
      try {
        returnData = this.option[index].value;
        // 抛出明细表默认选择项
        this.$emit("defaultValueSave", returnData);
      } catch (err) {
        try {
          returnData = this.option[0].value;
          err;
        } catch (errr) {
          returnData = "";
          err;
        }
      }
      return returnData;
    },
    handleSearch(value) {
      this.fetch(value, data => {
        this.setCacheData(data);
      });
    },
    handleChange(value) {
      this.fetch(value, data => {
        this.setCacheData(data);
      });
    },
    setCacheData(data) {
      var setData = [];
      for (var i = 0; i < this.maxCacheNum; i++) {
        if (data[i]) {
          setData.push(data[i]);
        } else {
          break;
        }
      }
      if (setData.length > this.maxCacheNum) {
        var setDataCache = setData;
        for (var j = 0; j < this.maxCacheNum; j++) {
          setData[j] = setDataCache[j];
        }
      }
      this.moreSelectNum = data.length - setData.length <= -1 ? 0 : data.length;
      this.selectNum = setData.length;
      //console.log("-3", this.selectNum);
      this.cacheData = setData;
    },
    fetch(value, callback, fill) {
      const data = [];
      var fillArray = [];
      for (var i = 0; i < this.option.length; i++) {
        if (this.option[i].label.indexOf(value) != -1) {
          data.push({
            label: this.option[i].label,
            value: this.option[i].value
          });
        } else if (fill === true) {
          fillArray.push({
            label: this.option[i].label,
            value: this.option[i].value
          });
        }
      }
      if (fill === true) {
        if (data.length < this.maxCacheNum) {
          // var num = this.maxCacheNum - data.length;
          var num = fillArray.length;
          for (var j = 0; j < num; j++) {
            try {
              data.push(fillArray[j]);
            } catch (err) {
              break;
            }
          }
        }
      }
      callback(data);
    },
    fiterLabelAgain() {
      if (this.debugerField != "") {
        debugger;
      }
      var value = this.setVal;
      if (value != null || value != "") {
        try {
          value = value.trim();
        } catch (err) {
          value = this.setVal;
        }
      }
      var valueType = this.$util.isType(value);
      this.option.forEach(row => {
        if (valueType === "Object") {
          this.setVal = value.value;
          //console.log("7");
        } else {
          if (row.label === value || row.value === value) {
            if (this.option.length > this.maxOption) {
              this.fetch(
                row.label,
                data => {
                  this.setCacheData(data);
                },
                true
              );
            }
            this.setVal = row.value;
            //console.log("8");
            return;
          }
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    removeDuplicates() {
      if (this.option.length > 5000) {
        return;
      }
      var newArray = this.option[0] ? [this.option[0]] : [];
      var thisOption = this.option;
      for (var i = 0; i < thisOption.length; i++) {
        try {
          if (
            newArray.findIndex(item => {
              return item.value === thisOption[i].value;
            }) == -1
          ) {
            newArray.push(thisOption[i]);
          }
        } catch (err) {
          err;
        }
      }
      this.option = [...newArray];
    },
    inputKeydown(e) {
      //debugger;
    },
    tableFocus() {
      this.$refs.select.focus();
    },
    tableSelectFocus() {
      this.$refs.selectE.focus();
    },
    tableBlur() {
      this.$refs.select.blur();
    },
    /**
     * 获取联动数据
     * @param data
     */
    linkedChange(option) {
      if (option) {
        let json = null;
        let that = this;
        this.linkage.forEach(element => {
          if (that.$util.isType(option.data[element.field])!== "Undefined") {
            if (json == null) {
              json = {};
            }
            json[element.mbField] = option.data[element.field];
          }
        });
        //console.log("控件linkedChange=", json);
        return json;
      }
      return null;
    },
    /**
     * 设置禁用状态
     * @param v
     */
    setDisabled(v) {
      if(this.$util.isType(v) === "Boolean") {
        this.thisDisabled = v;
      }else {
        this.thisDisabled = true;
      }
    },
    /**
     * 切换当前控件的禁用状态
     */
    setDisabledSwitch() {
      this.thisDisabled = !this.thisDisabled;
    },
  },
  watch: {
    value(getValue) {
      if (getValue != null || getValue != "") {
        try {
          this.value = getValue.trim();
        } catch (err) {
          err;
          this.value = getValue;
        }
      }
      if (!this.first) {
        this.fiterLabelAgain();
      }
    },

    modifiedValue: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(val) {
        //console.log("监听eSelect值", val);
        if (val) {
          // 新值和老值互换显示
          this.thisOldValue = this.value;
          this.showValue = val;
        }
      }
    },
    filter: {
      handler: function(newV) {
        // 清除缓存
        this.thisOldFilter = newV;
      },
      deep: true
    },
    thisOldFilter: {
      handler: function(newV, old) {
        if (newV && (newV.haveDetailVariable || newV.haveVariable))
          console.log("eSelect监听到过滤thisOldFilter变化", newV, old);
        // 清除缓存
        this.filterGet();
      },
      deep: true
    },
    disabled(v) {
      this.thisDisabled = v;
    },
    options: {
      handler: function(newV) {
        this.option = newV;
      },
      deep: true
    }
  },
  errorCaptured(err, vm, info) {
    console.error("e-select组件渲染异常:", err, vm, info);
    return false;
  }
};
</script>

<style scoped lang="less">
.eselect-div {
  width: 100%;
  position: relative;
}
.form_right_icon {
  position: absolute;
  width: 20px;
  height: calc(100%);
  top: 0;
  right: -25px;
  z-index: 1200;
}
::v-deep .ant-select div:first-child {
  width: 100%;
}
::v-deep .ant-select-selection--multiple .ant-select-selection__placeholder {
  margin: 0;
}
.eselect-div:focus {
  outline: 0px;
}
</style>
