import { render, staticRenderFns } from "./CriticalDataProtectionView.vue?vue&type=template&id=2ef95072&scoped=true&"
import script from "./CriticalDataProtectionView.vue?vue&type=script&lang=js&"
export * from "./CriticalDataProtectionView.vue?vue&type=script&lang=js&"
import style0 from "./CriticalDataProtectionView.vue?vue&type=style&index=0&id=2ef95072&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ef95072",
  null
  
)

export default component.exports