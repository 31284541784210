<template>
  <div>
    <a-modal
      title="权限维护"
      :visible="visible"
      @ok="ok"
      @cancel="handleCancel"
      cancelText="关闭"
      :width="800"
    >
      <template slot="footer">
        <a-button key="submit" type="primary" @click="ok">
          确定
        </a-button>
      </template>
      <div>
        <div class="bodyBox">
          <div class="bodyBoxLayout width50B">
            <div class="leftBox">
              <template>
                <a-tree
                  :treeData="menuTreeData"
                  defaultExpandAll
                  showLine
                  @select="onTreeSelect"
                />
              </template>
            </div>
          </div>
          <div class="bodyBoxLayout rightBox">
            <div class="rightBoxBody">
              <a-row>
                <a-col :span="6">权限范围</a-col>
                <a-col :span="18">
                  <a-input v-model="userName" readOnly></a-input>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="6">权限对象</a-col>
                <a-col :span="18">
                  <a-input v-model="authObj" readOnly></a-input>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="6">是否有效</a-col>
                <a-col :span="18">
                  <a-checkbox v-model="authEnable"></a-checkbox>
                </a-col>
              </a-row>
              <div>权限内容</div>
              <div class="auth__box">
                <a-checkbox-group v-model="slectLine">
                  <div>
                    <template v-for="row in auth">
                      <a-row :key="row.name">
                        <!-- <a-col :span="6"> </a-col>
							<a-col :span="18"> -->
                        <a-checkbox :value="row.name">
                          {{ row.name }}
                        </a-checkbox>
                        <!-- </a-col> -->
                      </a-row>
                    </template>
                  </div>
                </a-checkbox-group>
              </div>
            </div>
            <div
              style="width:100%;text-align:center;height"
              @click="changeAuth"
            >
              <a-button>应用</a-button>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法
export default {
  name: "AuthorityChange",
  mixins: [requestControls],
  components: {},
  data() {
    return {
      visible: false,
      auth: [],
      slectLine: [],
      menuTreeData: [],
      userName: "",
      userId: "",
      authObj: "",
      objectId: "",
      authEnable: false,
      selectedKeys: [],
      selectRowInfo: []
    };
  },
  props: {},
  created() {
    this.init();
  },
  computed: {},
  methods: {
    show(dataRef) {
      this.visible = true;
      this.userName = "(" + dataRef.YHMC + ")" + dataRef.RYXM;
      this.userId = dataRef.YHID;
      // this.printConfig = printConfig;
      // this.checkAll();
    },
    handleCancel() {
      this.visible = false;
      this.$emit("cancel");
    },
    ok() {
      this.handleCancel();
    },
    getAuth(id) {
      this.objectId = id;
      var _this = this;
      _this.slectLine = [];
      var gsbm = this.$store.getters.getCompanyId;
      let params = {
        action: "GetPermissionContent",
        randomKey: this.$store.getters.getRandomKey,
        prgId: id,
        userId: this.userId,
        companyCode: gsbm
      };
      this.get("System.ashx", params, {
        success: function(res) {
          if (res.success) {
            let data = res.data;
            let arr = data._QXNR;
            _this.auth = arr;
            for (var i = 0; i < arr.length; i++) {
              if (arr[i]["check"]) {
                _this.slectLine.push(arr[i]["name"]);
              }
            }
            if (data.SFYX) {
              _this.authEnable = data.SFYX;
            } else {
              _this.authEnable = false;
            }
          }
        },
        error: function() {}
      });
    },
    changeAuth() {
      if (this.objectId == "") {
        this.$message.error("请选择需要编辑权限的页面");
        return;
      }
      var _this = this;
      var limitContent = [...this.slectLine];
      var limitContentStr = "";
      for (var i = 0; i < limitContent.length; i++) {
        limitContentStr = limitContentStr + limitContent[i];
        if (i != limitContent.length) {
          limitContentStr = limitContentStr + ",";
        }
      }
      var gsbm = this.$store.getters.getCompanyId;
      let params = {
        action: "ChangePermission",
        randomKey: this.$store.getters.getRandomKey,
        prgId: this.objectId,
        userId: this.userId,
        limitContent: limitContentStr,
        enable: this.authEnable
      };
      this.get("System.ashx", params, {
        success: function(res) {
          if (res.success) {
            _this.$message.success(res.message);
          } else {
            _this.$message.error(res.message);
          }
        },
        error: function() {
          _this.$message.error("操作失败");
        }
      });
    },
    init() {
      // menuTreeData;
      var _this = this;
      let params = {
        action: "GetLeftMenuToPermission",
        randomKey: this.$store.getters.getRandomKey
      };
      this.get("System.ashx", params, {
        success: function(res) {
          var data = res.data;
          if (res.success) {
            let arr = data;
            _this.menuTreeData = arr;
          }
        },
        error: function() {}
      });
    },
    onTreeSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys;
      this.authObj = info.node.dataRef.title;
      if (this.selectedKeys == "") {
        this.selectRowInfo = [];
        return;
      }
      this.selectRowInfo = info.node.dataRef;
      this.getAuth(info.node.dataRef.key);
    }
  },
  watch: {}
};
</script>
<style lang="less" scoped>
  @import "../../assets/style/base/authority-change.less";
</style>
