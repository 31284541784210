<!-- 普通数据视图-带分页，可用于表格数据管理 -->
<template>
  <div style="height: 100%;">
    <div v-if="showToolbar" class="form-headButton">
      <!-- 授权操作按钮 -->
      <e-toolbar
        :buttons="buttons"
        :operationDisabled="operationDisabled"
        :size="$config.defaultControlSize"
        ref="eToolbar"
        @click="onButtonClick"
      ></e-toolbar>
    </div>
    <div class="tab-view-context">
      <!-- 视图表格 -->
      <div style="height: 100%;">
        <!-- 明细表title -->
        <e-head-title
          :title="title"
          :size="$config.defaultControlSize"
        ></e-head-title>
        <div class="view-context">
          <div class="view-table-filter">
            <e-table-content-filter
              ref="screenViewTable"
              @screen="
                data => {
                  tableDatas = data;
                }
              "
              :columns="thisColumns"
              :dataSource="tableDatas"
              :disabled="tableSpin"
              @proxy="searchByKey"
              :proxy="true"
            ></e-table-content-filter>
          </div>
          <!-- 视图表格 :footer-method="footerMethod"-->
          <div class="view-table" :class="getTableBoxClass">
            <vxe-grid
              ref="tableRef"
              :stripe="$config.table_stripe"
              show-header-overflow
              show-footer-overflow
              :scroll-x="{ gt: -1 }"
              :scroll-y="{ gt: 100 }"
              border
              resizable
              highlight-hover-row
              highlight-current-row
              :checkbox-config="{
                checkAll: false,
                highlight: true
              }"
              :empty-render="vxeTableEmptyRender"
              :tooltip-config="vxeTabletooltipConfig"
              :show-footer-overflow="thisFooterTableColumn"
              :show-footer="thisFooterTableColumn"
              :size="tableSize_MiXin"
              header-row-class-name="e-table-header_style"
              header-cell-class-name="e-table-header-cell_style"
              row-class-name="e-table-row_style"
              maxHeight="100%"
              height="auto"
              :loading="tableSpin"
              :data="tableDatas"
              @checkbox-all="onSelectAll"
              @cell-click="onSelectChange"
              @sort-change="onSortChange"
            >
              <template v-for="col in thisColumns">
                <vxe-table-column
                  v-if="col.scopedSlots"
                  :key="col.field"
                  v-bind="col"
                >
                  <!--如果定义了字段格式，则按照格式进行渲染-->
                  <template slot-scope="slotProps">
                    <e-table-colum
                      v-if="col.scopedSlots && col.scopedSlots.clickMethod"
                      :column="col"
                      :slotScopeData="slotProps.row"
                      @onClick="columClick"
                    ></e-table-colum>
                    <e-table-colum
                      v-else
                      :column="col"
                      :slotScopeData="slotProps.row"
                    ></e-table-colum>
                  </template>
                </vxe-table-column>
                <vxe-table-column
                  v-else
                  :key="col.field"
                  v-bind="col"
                ></vxe-table-column>
              </template>
            </vxe-grid>
            <vxe-pager
              ref="vxePagerRef"
              class="e-table-page"
              background
              :size="tableSize_MiXin"
              :loading="tableSpin"
              :current-page="pagination.current"
              :page-size="pagination.pageSize"
              :page-sizes="pagination.pageSizes"
              :total="pagination.total"
              :layouts="pagination.layouts"
              @page-change="tableChange"
            >
            </vxe-pager>
          </div>
        </div>
      </div>
      <systemPrintSet ref="systemPrintSet"></systemPrintSet>
      <!-- 文件选择上传控件 -->
      <input
        type="file"
        style="display:none"
        ref="uploadFile"
        @change="uploadChange"
      />
    </div>
    <!-- 全新建立条件选择框，注目前只用于会计科目选择 <a-icon type="form"></a-icon>-->
    <e-form-modal
      v-model="selectNewKjkmModalShow"
      formName="会计科目选择"
      showOk
      showCancel
      @buttonClicked="toOpenNew"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">请选择</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 明细数据选人弹出框 -->
    <e-table-modal
      v-if="
        gridImportModalShow &&
          gridImportTableConfig &&
          gridImportTableConfig.columns
      "
      v-model="gridImportModalShow"
      :tableConfig="gridImportTableConfig"
      title="明细选入搜索"
      width="950"
      :tableDatas="gridImportTableDatas"
      :buttons="gridImportModalButtons"
      :loading="gridImportModalLading"
      :selectedKeys="gridImportTableSelectedKeys"
      @buttonClicked="onClickTableModal"
    ></e-table-modal>

    <!-- 明细数据选人弹出框"筛选"条件选择窗 -->
    <e-form-modal
      v-if="gridImportSelectModalShow"
      v-model="gridImportSelectModalShow"
      :tempId="gridImportSelectTempId"
      :showOk="true"
      @okClicked="onGridImportSelected"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">筛选条件</span>
        </div>
      </template>
    </e-form-modal>
  </div>
</template>

<script>
import ETableColum from "@/components/ETableColum";
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求
import { e1CarryOver } from "@/views/listView/mixins/e1CarryOver.js"; // 支持e1平台的物料价格结转功能的方法
import { e1ExpendColum } from "@/views/listView/mixins/e1ExpendColum.js"; // 提供了扩展列表功能的公共方法
import { e1ExcelImport } from "@/views/listView/mixins/e1ExcelImport.js"; // 提供了文件上传导入数据的公共方法
import { e1FormToolbarGridImport } from "@/views/form/mixins/e1FormToolbarGridImport.js"; // e1的明细选人操作方法
export default {
  mixins: [
    analyticalMenuAuth,
    pageControls,
    requestControls,
    e1CarryOver,
    e1ExpendColum,
    e1ExcelImport,
    e1FormToolbarGridImport
  ],
  name: "DataView",
  components: {
    ETableColum
  },
  data () {
    return {
      cacheData: null,
      tableDatas: [],
      thisColumns: [],
      thisFooterTableColumn: false, // 是否显示合计行，默认不显示
      selectedRowKeys: [], // 表格选中的行
      thisConfig: null,
      buttons: [], // 用户可做的操作按钮
      tableConfig: [],
      tableSpin: false,
      tempId: null, // 有可能是从对应表中的字段名
      rowKey: this.defaultTableKeyName,
      defaultDataIdField: "ID",
      defaultDataId: null, // 新建时用来向新建表单传递一个默认值字段
      dhbm: null,
      operationDisabled: false, // 操作按钮是否可用
      popoverPlacement: "right", // 表单内容提示框
      searchTj: {},
      selectNewKjkmModalShow: false // 是否显示会计科目选择弹窗
    };
  },
  props: {
    pageConfig: {
      //包含视图结构、视图数据来源、操作权限配置
      type: Object,
      default: function () {
        return null;
      }
    },
    title: {
      type: String,
      default: ""
    }
  },
  watch: {
    pageConfig: function (nDatas) {
      this.thisConfig = nDatas;
    },
    tableSpin: function (v) {
      if (!v) {
        // 数据加载完成后，将滚动条拉到顶部
        this.$refs.tableRef.scrollTo(0, 0);
      }
    }
  },
  computed: {
    tableMaxWidth: function () {
      if (this.thisColumns.length > 0) {
        let tempWidth = 0;
        for (let i = 0; i < this.thisColumns.length; i++) {
          tempWidth += this.thisColumns[i].width;
        }
        return tempWidth;
      }
      return 0;
    },
    showToolbar: function () {
      return this.checkToobleIsShow(this.buttons);
    },
    getTableBoxClass: function () {
      let classes = [];
      if (this.thisFooterTableColumn) {
        classes.push("view-table__showFooter");
      } else classes.push("view-table__noFooter");
      if (this.$config.defaultControlSize) {
        if (this.$config.defaultControlSize === "small") {
          classes.push("view-table__small");
        } else if (this.$config.defaultControlSize === "default") {
          classes.push("view-table__default");
        } else if (this.$config.defaultControlSize === "large") {
          classes.push("view-table__large");
        }
      } else {
        classes.push("view-table__default");
      }
      return classes;
    }
  },
  activated () {
    this.tsbRefresh();
  },
  created () {
    this.thisConfig = this.pageConfig;
    console.log("pageConfig:", this.thisConfig);
    //分析出用户可做操作按钮
    this.buttons = this.getUserJurisdictionButtons(this.thisConfig);
    if (
      this.thisConfig &&
      this.thisConfig.viewConfig &&
      this.thisConfig.viewConfig.cols
    ) {
      // 表格配置
      this.tableConfig = this.thisConfig.viewConfig;
      this.thisColumns = this.analyticalColsArray(
        this.tableConfig.cols,
        null,
        null,
        "showByRule"
      ); // 表头配置
    } else {
      this.alertError("表格配置错误");
    }
  },
  mounted () {
    if (this.tableConfig) {
      if (this.tableConfig.defaultDataId)
        // 如果视图指定了默认的数据标记字段
        this.defaultDataId = this.tableConfig.defaultDataId;
      this.tempId = this.tableConfig.templatId;
      if (!this.tempId) this.tempId = this.tableConfig.templateField;
      this.getTableData();
      this.dhbm = this.thisConfig.DHBM;
    } else {
      this.alertError("表格配置错误");
    }
  },
  methods: {
    /**
     * 加载表格的数据
     */
    getTableData (button, getAll, callBack) {
      this.tableSpin = true;
      this.operationDisabled = true;
      let that = this;
      let params = {
        action: "GetSearchTableList",
        tb_name: this.tableConfig.tableName, //主表名
        search: "", // 无查询条件
        t_id: this.tableConfig.templatId,
        bm: this.tableConfig.bm
          ? this.tableConfig.bm
          : this.tableConfig.defaultBm,
        randomKey: this.$store.getters.getRandomKey,
        searchCols: this.searchTj.searchCols,
        keyword: this.searchTj.keyword,
        orderBy: this.searchTj.orderBy
      };
      let pageSize = this.pagination.pageSize;
      let pageNumber = this.pagination.current;
      if (this.pagination.pageSize < Infinity) {
        params.pageSize = pageSize;
        params.pageNumber = pageNumber;
      }
      if (getAll) {
        if (params.pageNumber) {
          delete params.pageNumber;
        }
        if (params.pageSize) {
          delete params.pageSize;
        }
      }
      if (button) button.loading = true;
      this.post("Form.ashx", params, {
        success: function(req) {
          //console.log(req);
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? req.data:req;
          let dataList = reqData.data ? reqData.data.dataList : [];
          if (getAll) {
            that.tableSpin = false;
            that.operationDisabled = false;
            if (button) button.loading = false;
            callBack(dataList);
            return;
          }
          that.pagination.total = reqData.data.TotalCount
            ? reqData.data.TotalCount
            : dataList.length;
          dataList.map(function (data, index) {
            if (data.children && data.children.length > 0) {
              data.children.map(function (data1, index2) {
                return (data1[that.rowKey] = index + "." + index2);
              });
            }
            if (that.tableConfig.groupby) {
              // 表示是树状列表
              data.SYSTEM_TREE_ROOT = "null"; // 关联字段标识为无效数据
            }
            // 给数组对象设置一个主键字段用于表格选择
            return (data[that.rowKey] = index + "");
          });
          that.tableDatas = dataList;
          that.tableSpin = false;
          that.operationDisabled = false;
          that.selectedRowKeys = []; // 清空选中项
          if (button) button.loading = false;
        },
        error: function (err) {
          if (getAll) {
            that.tableSpin = false;
            that.operationDisabled = false;
            if (button) button.loading = false;
            callBack(false);
            return;
          }
          console.log(err);
          that.pagination.current = 1;
          that.pagination.total = 0;
          that.tableSpin = false;
          that.operationDisabled = false;
          that.selectedRowKeys = []; // 清空选中项
          if (button) button.loading = false;
          that.alertError("请求失败，获取表数据失败");
        }
      });
    },
    /**
     * 全新建立，先弹出一个指定模板进行选择
     */
    tsbAllNew (button) {
      this.selectNewKjkmModalShow = true;
    },
    /**
     * 确定打开选择的数据对应的模板表单新建页面
     */
    toOpenNew (event) {
      console.log("结果", event);
      if (event.key === "ok") {
        let query = {
          dhbm: this.dhbm
        };
        let title = event.data.KMZMC;
        query.templetId = event.data.JTJBDMBID;
        query.defaultFiled = "KMZID";
        query.KMZID = event.data.KMZID;
        // 打开模板的一些默认选项值获取接口的必要传参
        query.businessModel = this.tableConfig.businessModel;
        this.openTab({
          title: "新增-" + title,
          path: "/NewForm",
          query: query
        });
      }
      if (event.resolve) event.resolve();
    },
    /**
     *  打开新建表单数据页面
     */
    tsbNew () {
      if (this.tempId) {
        let query = {
          dhbm: this.dhbm
        };
        let title = this.title;
        // 如果配置的tempId是数字则认为指定了模板id,否则需要根据this.tempId配置的名称从选中的一条数据中获取模板id
        if (
          this.$util.isType(this.tempId) === "val" ||
          this.$util.isNumberString(this.tempId)
        ) {
          query.templetId = this.tempId;
          query.defaultDataId = this.getDefaultId();
        } else if (this.selectedRowKeys.length == 1) {
          let selectedId = this.selectedRowKeys[0];
          let that = this;
          let selectedData = this.tableDatas.find(function (data) {
            return data[that.rowKey] === selectedId;
          });
          let titleFiedName = this.thisColumns[1].key;
          title = selectedData[titleFiedName];
          query.templetId = selectedData[this.tempId];
          //query.dataId = this.getDataId(selectedData);
          query.defaultDataId = this.getDefaultId(selectedData);
        } else {
          this.alertWarning("请从下表选择一条新建类型");
          return;
        }
        // 打开模板的一些默认选项值获取接口的必要传参
        query.businessModel = this.tableConfig.businessModel;
        this.openTab({
          title: "新增-" + title,
          path: "/NewForm",
          query: query
        });
      } else {
        this.alertError("不存在的模板ID");
      }
    },
    /**
     *  打开编辑表单数据页面
     */
    tsbEdit () {
      if (this.selectedRowKeys.length == 1) {
        let selectedId = this.selectedRowKeys[0];
        let that = this;
        let selectedData = this.tableDatas.find(function (data) {
          return data[that.rowKey] === selectedId;
        });
        let titleFiedName = this.thisColumns[1].key;
        this.openTab({
          title: "编辑" + this.title + "-" + selectedData[titleFiedName],
          path: "/NewForm",
          query: {
            dhbm: this.dhbm,
            templetId: this.getEditTempId(selectedData),
            dataId: this.getDataId(selectedData)
          }
        });
      } else {
        this.alert("请选择一条需要编辑的记录");
      }
    },
    /**
     * 获取视图配置的数据模板ID
     * @param row
     */
    getEditTempId(row) {
      if (row) {
        //特殊处理-会计凭证接口定义？？？？
        if(row._MBID) {
          if(row._MBID+""==="78" || row._MBID+""==="336") {
            return 510;
          }
          return row._MBID;
        }
      }
      return this.tempId;
    },
    /**
     * 获取视图配置的数据模板ID
     * @param row
     */
    getTempId(row) {
      if (row) {
        if(row._MBID) {
          if(row._MBID+""==="78" || row._MBID+""==="336") {
            return 510;
          }
          return row._MBID;
        }
      }
      return this.tempId;
    },
    /**
     * 从数据行中获取标记数据ID
     * @param row
     */
    getDataId (row) {
      if (this.defaultDataIdField && row) {
        return row[this.defaultDataIdField];
      }
      return null;
    },
    /**
     * 获取需要传送给新建或编辑表单的默认字段对应的值
     */
    getDefaultId (row) {
      if (this.defaultDataId && this.$util.isNumberString(this.defaultDataId)) {
        return this.defaultDataId;
      } else if (this.defaultDataId && row) {
        return row[this.defaultDataId];
      }
      return null;
    },
    /**
     * 获取行数据对象内的第一列的值
     */
    getOneColumnValue (row) {
      if (row) {
        let titleFiedName = this.thisColumns[1].key;
        return row[titleFiedName];
      }
      return "";
    },
    /**
     * 删除选中的
     * @param button
     * @param nowIndex
     */
    tsbDelete (button, nowIndex) {
      if (this.selectedRowKeys.length >= 1) {
        if (nowIndex && nowIndex.prioriCondition) {
          // 满足前置条件还需要确认是否操作
          this.$refs.eToolbar.showConfirm(button.tsbName);
        } else {
          this.doDelete(button, nowIndex ? nowIndex : 0);
        }
      } else {
        this.alert("请至少选择一条需要删除的记录");
      }
    },
    /**
     * 执行删除操作,支持批量删除
     */
    doDelete (button, index) {
      if (button && this.$util.isType(index) === "val") {
        let that = this;
        let selectedData;
        let promise = new Promise(resolve => {
          let selectedId = this.selectedRowKeys[index];
          selectedData = this.tableDatas.find(function (data) {
            return data[that.rowKey] === selectedId;
          });
          let params = {
            action: "DeleteData",
            tb_name: this.tableConfig.tableName, //主表名
            bm: button.param.bm,
            t_id: this.getTempId(selectedData), // 模板id  从DHCS中获取
            d_id: this.getDataId(selectedData), // 插入之后返回的主键id
            randomKey: this.$store.getters.getRandomKey
          };
          button.loading = true;
          that.operationDisabled = true;
          this.post("Form.ashx", params, {
            success: function () {
              resolve();
            },
            error: function (err) {
              resolve(err.message);
            }
          });
        }).catch(() => {
          console.error("删除操作异常");
          if (selectedData) {
            let selectTitle = that.getOneColumnValue(selectedData);
            that.alertError("删除‘" + selectTitle + "’失败");
          }
          that.operationDisabled = false;
          button.loading = false;
        });

        // 异步响应上方的Promise类提供的resolve的，执行结果
        promise.then(function (value) {
          console.log("删除操作完成", value);
          if (value) {
            if (selectedData) {
              let selectTitle = that.getOneColumnValue(selectedData);
              that.alertError("删除‘" + selectTitle + "’失败,<br />" + value);
            } else {
              that.alertError("删除失败," + value);
            }
            if (value > 0) {
              that.getTableData();
            }
            button.loading = false;
            that.operationDisabled = false;
          } else {
            if (index == that.selectedRowKeys.length - 1) {
              that.getTableData();
              that.alertSuccess("删除成功");
              button.loading = false;
              that.operationDisabled = false;
            } else {
              that.tsbDelete(button, index + 1);
            }
          }
        });
      }
    },
    /**
     * 查看选中行
     */
    tsbView (button) {
      if (this.selectedRowKeys.length == 1) {
        let selectedId = this.selectedRowKeys[0];
        let that = this;
        let selectedData = this.tableDatas.find(function (data) {
          return data[that.rowKey] === selectedId;
        });
        let titleFiedName = this.thisColumns[1].key;
        let id = this.getDataId(selectedData);
        let tempId = this.getTempId(selectedData);
        this.$refs.tableRef.closeTooltip();
        if (button.param.dbclicktype === "form") {
          // 根据配置参数获取表单模板id
          //referdataid: "_LYZBID";
          //refertemplate: "_LYMBID"
          if (button.param.referdataid) {
            id = selectedData[button.param.referdataid];
          }
          if (button.param.refertemplate) {
            tempId = selectedData[button.param.refertemplate];
          }
          this.openTab({
            title: this.title + "-" + selectedData[titleFiedName],
            path: "/DetailForm",
            query: {
              dhbm: this.dhbm,
              templetId: tempId,
              detailFiledName: titleFiedName, // 表单用于tab的title的表单字段名
              dataId: id,
              bm: button.param.bm // 表单对应BM表
            }
          });
        } else {
          // 普通弹出查看视图
          this.openTab({
            title: "查看-" + this.title + "-" + selectedData[titleFiedName],
            path: "/DetailForm",
            query: {
              dhbm: this.dhbm,
              templetId: tempId,
              detailFiledName: titleFiedName, // 表单用于tab的title的表单字段名
              dataId: id,
              bm: this.tableConfig.bm // 表单对应BM表
            }
          });
        }
      } else {
        this.alert("请选择一条需要查看的记录");
      }
    },
    /**
     * 响应行的选中情况变化
     * @param currentRow
     */
    onSelectChange (currentRow) {
      //console.log("onCellClick",currentRow);
      if (currentRow) {
        let that = this;
        let index = this.selectedRowKeys.findIndex(function (d) {
          return d === currentRow.row[that.rowKey];
        });
        if (index >= 0) {
          // 已经选中
          this.$refs.tableRef.setCheckboxRow(currentRow.row, false);
          this.selectedRowKeys.splice(index, 1);
        } else {
          this.$refs.tableRef.setCheckboxRow(currentRow.row, true);
          this.selectedRowKeys.push(currentRow.row[that.rowKey]);
        }
      }
      //console.log("selectedRowKeys changed: ", this.selectedRowKeys);
    },
    /**
     * 响应列表的全选事件
     */
    onSelectAll (event) {
      let that = this;
      if (!event.checked) {
        this.selectedRowKeys = [];
        this.$refs.tableRef.setAllCheckboxRow(false);
      } else {
        this.selectedRowKeys = this.tableDatas.map(function (data) {
          return data[that.rowKey];
        });
        this.$refs.tableRef.setAllCheckboxRow(true);
      }
    },
    /**
     * 响应表格分页事件
     * @param pagination
     */
    tableChange (pagination) {
      let that = this;
      this.onTableChangeMiXin(pagination, function () {
        that.getTableData();
      });
    },
    /**
     * 触发排序事件
     * @param event
     */
    onSortChange (event) {
      console.log("触发排序事件", event);
      if (event && event.field && event.order) {
        //event.field; 参与排序的字段;event.order;排序方式 "desc"
        let orderBy = event.field + " " + event.order;
        this.searchTj.orderBy = orderBy;
        this.selectedRowKeys = [];
        this.getTableData();
      } else {
        // 取消排序
        delete this.searchTj.orderBy;
        this.selectedRowKeys = [];
        this.getTableData();
      }
    },
    /**
     * 刷新列表
     * @param button
     */
    tsbRefresh (button) {
      this.selectedRowKeys = [];
      this.getTableData(button);
    },
    tsbExport () {
      this.getTableData(null, true, res => {
        if (!res) {
          this.$message.error("获取导出的数据失败");
          return;
        }
        res &&
          this.thisColumns &&
          this.$util.excelExport(res, this.thisColumns, this.title);
      });
    },
    tsbPrint () {
      var json = {
        formConfig: {
          position: { x: 0, y: 0 },
          size: { w: 0, h: 0 },
          title: this.title
        },
        formRows: [],
        title: this.title,
        mxForm: {
          columns: this.thisColumns,
          data: this.tableDatas,
          position: { x: 0, y: 0 },
          size: { w: 0, h: 0 },
          type: "mxForm"
        }
      };
      this.$refs.systemPrintSet.show(json);
    },

    /**
     * 根据条件进行查询
     */
    searchByKey (value, filed) {
      this.searchTj.searchCols = filed;
      this.searchTj.keyword = value;
      this.pagination.current = 1;
      this.getTableData();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/data-view.less";
</style>
