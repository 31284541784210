import Excel from "exceljs";
import FileSaver from "file-saver";
import moment from "moment";
let util;
util = {
  //定义了一些非法字符串
  invalidString: ["null", "NULL", "Null", "undefined", "Undefined"],
  /**
   * 把包含‘T’字符的字符串中的‘T’都替换为‘ ’
   * @param {Object} data
   */
  changeTimeFormat: function(data) {
    if (data && data.includes("T", 10)) {
      return data.replace("T", " ");
    }
    return data;
  },
  /**
   * 算数公式方法
   */
  mathUtil: {
    /**
     * 乘法函数，用来得到精确的乘法结果
     * @param arg1
     * @param arg2
     * @returns {Number}
     */
    accMul: function(arg1, arg2) {
      let m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        if (s1.split(".")[1] != undefined) m += s1.split(".")[1].length;
      } catch (e) {
        console.error(e);
      }
      try {
        if (s2.split(".")[1] != undefined) m += s2.split(".")[1].length;
      } catch (e) {
        console.error(e);
      }
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    /**
     * 浮点数除法运算
     * @param arg1
     * @param arg2
     * @returns {Number}
     */
    accDiv: function(arg1, arg2) {
      let r1 = 0,
        r2 = 0,
        m,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        if (s1.split(".")[1] != undefined) r1 = s1.split(".")[1].length;
      } catch (e) {
        console.error(e);
      }
      try {
        if (s2.split(".")[1] != undefined) r2 = s2.split(".")[1].length;
      } catch (e) {
        console.error(e);
      }
      m = Math.pow(10, Math.max(r1, r2));
      return this.accMul(arg1, m) / this.accMul(arg2, m);
    },

    /**
     * 浮点数加法
     * @param arg1
     * @param arg2
     * @returns {Number}
     */
    accAdd: function(arg1, arg2) {
      let r1 = 0,
        r2 = 0,
        m,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        if (s1.split(".")[1] != undefined) r1 = s1.split(".")[1].length;
      } catch (e) {
        console.error(e);
      }
      try {
        if (s2.split(".")[1] != undefined) r2 = s2.split(".")[1].length;
      } catch (e) {
        console.error(e);
      }
      m = Math.pow(10, Math.max(r1, r2));
      return (this.accMul(arg1, m) + this.accMul(arg2, m)) / m;
    },

    /**
     * 浮点数减法
     * @param arg1
     * @param arg2
     * @returns {Number}
     */
    accSub: function(arg1, arg2) {
      let r1 = 0,
        r2 = 0,
        m,
        n,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        if (s1.split(".")[1] != undefined) r1 = s1.split(".")[1].length;
      } catch (e) {
        console.error(e);
      }
      try {
        if (s2.split(".")[1] != undefined) r2 = s2.split(".")[1].length;
      } catch (e) {
        console.error(e);
      }
      m = Math.pow(10, Math.max(r1, r2));
      //last modify by deeka
      //动态控制精度长度
      //n = r1 >= r2 ? r1 : r2;
      //console.log(n);
      return (this.accMul(arg1, m) - this.accMul(arg2, m)) / m;
    },
    /**
     * 将参数数组中的对象进行合计
     * @param args
     * @returns {number}
     */
    sum: function(...args) {
      if (args) {
        let len = args.length;
        let sumValue = 0;
        for (let i = 0; i < len; i++) {
          if (
            (util.isType(args[i]) === "String" &&
              util.isNumberString(args[i])) ||
            util.isType(args[i]) === "val"
          ) {
              sumValue = this.accAdd(sumValue, args[i]);
          } else if (
            args[i] == null ||
            args[i] == undefined ||
            args[i] === ""
          ) {
            continue;
          } else if (util.isType(args[i]) === "Array") {
            sumValue = this.sumArray(args[i]);
          } else return NaN;
        }
        return sumValue;
      }
      return NaN;
    },
    /**
     * 将参数中的数据进行合计
     * @param args
     * @returns {number}
     */
    sumArray: function(args) {
      if (args && args.length > 0) {
        let len = args.length;
        let sumValue = 0;
        for (let i = 0; i < len; i++) {
          if (
            (util.isType(args[i]) === "String" &&
              util.isNumberString(args[i])) ||
            util.isType(args[i]) === "val"
          ) {
            sumValue = this.accAdd(sumValue, args[i]);
          } else if (args[i] == "-") {
            sumValue = this.accAdd(sumValue, 0);
          } else if (
            args[i] == null ||
            args[i] == undefined ||
            args[i] === ""
          ) {
            continue;
          } else {
            return NaN;
          }
        }
        return sumValue;
      }
      return 0;
    },
    /**
     * 数学计算表达式运算
     * @param expression
     */
    binaryOperations: function(expression) {
      try {
        return Expression.calcExpression(expression);
      } catch (e) {
        console.error(e);
        return 0;
      }
    },
    /**
     * 将js的条件表达式字符串，分解成多段计算表达式或数值，然后分别进行计算后，再进行判断
     * @param expression
     */
    conditionalExpressionjJudge: function(expression) {
      try {
        if (this.isType(expression) === "String" && expression !== "") {
          expression = expression.replace(/ or /g, " || ");
          expression = expression.replace(/ and /g, " && ");
        }
        return -1;
      } catch (e) {
        console.error(e);
        return -1;
      }
    },

    /**
     * 字符串是否是合法的数学计算表达式
     * @param string
     */
    isMathExpression(string) {
      // 剔除空白符
      string = string.replace(/\s/g, "");

      // 错误情况，空字符串
      if ("" === string) return false;
      let reg = new RegExp("[\\+\\-\\*\\/]{2,}", "ig");
      // 错误情况，运算符连续
      if (reg.test(string)) return false;
      // 空括号
      if (/\(\)/.test(string)) return false;
      // 错误情况，括号不配对
      let stack = [];
      for (let i = 0, item; i < string.length; i++) {
        item = string.charAt(i);
        if ("(" === item) {
          stack.push("(");
        } else if (")" === item) {
          if (stack.length > 0) {
            stack.pop();
          } else {
            return false;
          }
        }
      }
      if (0 !== stack.length) return false;
      reg = new RegExp("\\([\\+\\-\\*\\/]", "ig");
      // 错误情况，(后面是运算符
      if (reg.test(string)) return false;
      reg = new RegExp("[\\+\\-\\*\\/]\\)", "ig");
      // 错误情况，)前面是运算符
      if (reg.test(string)) return false;
      reg = new RegExp("[^\\+\\-\\*\\/]\\(", "ig");
      // 错误情况，(前面不是运算符
      if (reg.test(string)) return false;
      reg = new RegExp("\\)[^\\+\\-\\*\\/]", "ig");
      // 错误情况，)后面不是运算符
      if (reg.test(string)) return false;

      // 错误情况，变量没有来自“待选公式变量”
      /*var tmpStr = string.replace(/[\(\)\+\-\*\/]{1,}/g, '`');
      var array = tmpStr.split('`');
      for(var i = 0, item; i < array.length; i++){
        item = array[i];
        if( /[A-Z]/i.test(item) && 'undefined' === typeof(obj[item]) ){
          return false;
        }
      }*/
      return true;
    },
    /**
     * 获取数据的保存两位小数点，最后一位四舍五入
     * @param data
     */
    round2(data) {
      if (util.isType(data) === "val") {
        return this.accDiv(Math.round(this.accMul(data, 100)), 100);
      } else {
        return NaN;
      }
    },
    /**
     * 获取数据的保存3位小数点，最后一位四舍五入
     * @param data
     */
    round3(data) {
      if (util.isType(data) === "val") {
        return this.accDiv(Math.round(this.accMul(data, 1000)), 1000);
      } else {
        return NaN;
      }
    },
    /**
     * 会计式，保留两位小数点，第三位有值 就进1
     * @param data
     * @returns {*}
     */
    accountingRound2(data) {
      if (util.isType(data) === "val") {
        let je = this.round3(data);
        console.log("财务舍入法", je);
        return (parseFloat(je.toString()) + 0.004).toFixed(2);
      } else {
        return NaN;
      }
    }
  },
  dateUtils: {
    UNITS: {
      年: 31557600000,
      月: 2629800000,
      天: 86400000,
      小时: 3600000,
      分钟: 60000,
      秒: 1000,
      DEFAULT_FORMAT: "yyyy-mm-dd HH:MM:ss",
      DEFAULT_DATE: "YYYY-MM-DD"
    },
    DefaultFormats: [
      "YYYY/MM/DD",
      "YYYY-MM-DD",
      "YYYY/MM/DDTHH:mm:ss",
      "YYYY/M/D H:mm:ss",
      "YYYY-MM-DDTHH:mm:ss",
      "YYYY-MM-DD HH:mm:ss",
      "YYYY-MM-DD h:mm:ss A",
      "YYYY-MM-DD HH:mm",
      "YYYY年MM月DD日",
      "YYYY年MM月DD日 HH时mm分ss秒",
    ],
    DateTimeMode: {
      month: [
        "YYYY/MM",
        "YYYY-MM",
        "YYYY年MM月",
        "YYYY/M",
        "YYYY-M",
        "YYYY年M月"
      ],
      date: [
        "YYYY/MM/DD",
        "YYYY-MM-DD",
        "YYYY年MM月DD日",
        "YYYY/M/D",
        "YYYY-M-D",
        "YYYY年M月D日"
      ],
      time: [
        "YYYY/MM/DDTHH:mm:ss",
        "YYYY/M/D H:mm:ss",
        "YYYY-MM-DDTHH:mm:ss",
        "YYYY-MM-DD HH:mm:ss",
        "YYYY-MM-DD h:mm:ss A",
        "YYYY-MM-DD HH:mm",
        "YYYY年MM月DD日 HH:mm:ss"
      ]
    },
    humanize: function(milliseconds) {
      let humanize = "";
      for (let key in this.UNITS) {
        if (milliseconds >= this.UNITS[key]) {
          humanize = Math.floor(milliseconds / this.UNITS[key]) + key + "前";
          break;
        }
      }
      return humanize || "刚刚";
    },
    /**
     * 根据format返回对应的mode类型
     * @param format
     */
    getEDatetimePickerMode: function(format) {
      for (let key in this.DateTimeMode) {
        if (this.DateTimeMode[key].includes(format)) {
          return key;
        }
      }
      return null;
    },
    parse: function(str) {
      return moment(str).toDate();
    },
    /**
     * 对Date的扩展，将 Date 转化为指定格式的String
     * 月(M)、日(d)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q) 可以用 1-2 个占位符 * 年(y)可以用 1-4 个占位符，
     * 毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
     * @param {Date} date
     * @param {String} fmt
     */
    pattern: function(date, fmt) {
      return moment(date).format(fmt);
    },
    /**
     * 把时间格式的字符串，格式化成指定格式
     * @param dateString
     * @param fmt
     * @returns {*}
     */
    formatDateString: function(dateString, fmt) {
      let date = this.parse(dateString);
      if (date) {
        return this.pattern(date, fmt);
      } else {
        return dateString;
      }
    },
    /**
     * 检测字符串是否满足某种时间日期字符串格式
     * @param str
     * @returns {boolean}
     */
    checkStringIsTime: function(str) {
      return moment(str, this.DefaultFormats, true).isValid();
    },
    /**
     * 将时间选择器控件解析到的DefaultValue 控件属性，翻译为对应的值
     *
     */
    getDateTimePickerDefaultValue: function(defaultValue) {
      if (defaultValue) {
        let weekOfDay = 0;
        switch (defaultValue) {
          case "空":
            return "";
          case "默认日期":
            return null;
          case "当前时间":
            return moment();
          case "当天所属本年周":
            return this.getWeekOfYear();
          case "当前时间下一天":
            return moment().add(1, "day");
          case "本周第一日":
            return moment().startOf("week");
          case "本周最后一日":
            return moment().endOf("week");
          case "下周第一日":
            weekOfDay = parseInt(moment().format("E")); //计算本周的今天是该周第几天
            return moment().add(8 - weekOfDay, "days");
          case "下周最后一日":
            weekOfDay = parseInt(moment().format("E")); //计算本周的今天是该周第几天
            return moment().add(14 - weekOfDay, "days");
          case "下个月第一日":
            return moment()
              .add(1, "months")
              .startOf("month");
          case "上个月第一日":
            return moment()
              .subtract(1, "months")
              .startOf("month");
          case "本月第一天":
            return moment().startOf("month");
          case "本月最后一天":
            return moment().endOf("month");
          case "去年第一日":
            return moment()
              .subtract(1, "year")
              .startOf("year");
          case "今年第一日":
            return moment().startOf("year");
          case "明年第一日":
            return moment()
              .add(1, "year")
              .startOf("year");
          case "明年本月第一日":
            return moment()
              .add(1, "year")
              .startOf("month");
        }
        return null;
      }
      return defaultValue;
    },
    /**
     * 获取该日是本年第几周
     * @param date
     * @return {number}
     */
    getWeekOfYear: function (date){
      let today = date;
      if(!date)
        today = new Date();
      let firstDay = new Date(today.getFullYear(),0, 1);
      let dayOfWeek = firstDay.getDay();
      let spendDay= 1;
      if (dayOfWeek !=0) {
        spendDay=7-dayOfWeek+1;
      }
      firstDay = new Date(today.getFullYear(),0, 1+spendDay);
      let d =Math.ceil((today.valueOf()- firstDay.valueOf())/ 86400000);
      let result = Math.ceil(d/7);
      return result+1;
      //return moment().weeksInYear();
    },
  },
  /**
   * 获取指定位数的字符串
   * @param len
   * @returns {*}
   */
  getRandomString: function(len) {
    if (len && util.isType(len) !== "val" && len < 1) {
      len = 3;
    } else {
      len = Math.floor(len);
    }
    let rand = "";
    for (let i = 0; i < len; i++) {
      rand += Math.floor(Math.random() * 10);
    }
    return len;
  },
  /**
   * 两个数组合并去重
   * @param arg1
   * @param arg2
   * @returns {*}
   */
  mergeArray: function(arg1, arg2) {
    let arr1 = [].concat(arg1);
    let arr2 = [].concat(arg2);
    for (let i = 0; i < arr1.length; i++) {
      for (let j = 0; j < arr2.length; j++) {
        if (arr1[i] === arr2[j]) {
          arr1.splice(i, 1); //利用splice函数删除元素，从第i个位置，截取长度为1的元素
        }
      }
    }
    //alert(arr1.length)
    for (let i = 0; i < arr2.length; i++) {
      arr1.push(arr2[i]);
    }
    return arr1;
  },
  /**
   * 数组内去重
   * @param array
   * @return {Array}
   */
  uniqArray: function(array) {
    let temp = [];
    //let index = [];
    let l = array.length;
    for (let i = 0; i < l; i++) {
      for (let j = i + 1; j < l; j++) {
        if (array[i] === array[j]) {
          i++;
          j = i;
        }
      }
      temp.push(array[i]);
      //index.push(i);
    }
    //console.log(index);
    return temp;
  },
  /**
   * 获取两个对象中相同属性，但是值不相同的部分组成一个新的对象
   * @param newObj
   * @param oldObj
   */
  getObjectChangedFields: function(newObj, oldObj) {
    let newObject = {};
    if(newObj && oldObj) {
      let filedNames=[];
      // 老对象里面所有的属性
      for (let f1 in oldObj) {
        if(this.isType(newObj[f1])!=="Object" && this.isType(newObj[f1])!=="Array" && this.isType(newObj[f1])!=="Function") {
          filedNames.push(f1);
        }
      }
      for (let f in newObj) {
        if(this.isType(newObj[f])!=="Object" && this.isType(newObj[f])!=="Array" && this.isType(newObj[f])!=="Function") {
          if(filedNames.includes(f)){
            this.arrayRemoveValue(filedNames, f);
          }
          if(oldObj[f] !== undefined && !this.isObjEqual(oldObj[f], newObj[f])) { // 老属性，值变了
            newObject[f] = newObj[f];
          } else if(oldObj[f] === undefined){ // 新加的属性
            newObject[f] = newObj[f];
          }
        }
      }
      if(filedNames.length>0) { // 有被删除的属性
        for(let nf in filedNames) {
          newObject[nf] = null;
        }
      }
    }
    if(this.isValidObject(newObject))
      return newObject;
    else
      return null;
  },
  /**
   * 字符串首字母大写
   * @param str
   * @return {*}
   */
  upperInitials: function(str) {
    if(this.isType(str) === "String") {
      let ret = str.replace(str[0],str[0].toUpperCase());
      return ret;
    }
    return str;
  },
  /**
   * 截取字符串val的前height位然后其后的显示为省略号
   * @param val
   * @param maxLength
   * @returns
   */
  formatvStringMaxLength: function(val, maxLength) {
    if (
      val == null ||
      val == undefined ||
      val == "" ||
      val == "NULL" ||
      val == "null"
    ) {
      return "";
    } else {
      if (val.length > maxLength) {
        let val1 = val.substr(0, maxLength) + "...";
        return val1;
      } else {
        return val;
      }
    }
  },

  /**
   * 把一些空对象转换为空字符串显示
   * @param {Object} data
   */
  changeNullToEmptyString: function(data) {
    //console.log("changeNull=" + data);
    if (!data) {
      return this.changeNullToEmpty(data);
    }
    return data;
  },
  /**
   * 检测data是否是百分比字符串格式
   * @param data
   * @returns {boolean}
   */
  isPercentageString: function(data) {
    if (this.isType(data) === "String") {
      let reg = new RegExp("^((\\d+\\.?\\d*)|(\\d*\\.\\d+))\\%$", "ig");
      return reg.test(data);
    }
    return false;
  },
  /**
   * 是否是像素px字符串格式
   * @param data
   * @returns {boolean}
   */
  isPixelString: function(data) {
    if (this.isType(data) === "String") {
      let reg = new RegExp("^((\\d+\\.?\\d*)|(\\d*\\.\\d+))px$", "ig");
      return reg.test(data);
    }
    return false;
  },
  /**
   * 如果是数值字符
   * @param data
   * @returns {boolean}
   */
  isNumberString: function(data) {
    if (this.isType(data) === "String") {
      let reg = new RegExp(
        "^[-+]?(([0-9]+)([.]([0-9]+))?|([.]([0-9]+))?)$",
        "ig"
      );
      return reg.test(data);
    }
    return false;
  },
  /**
   * 是否是整数
   * @param obj
   * @returns {boolean}
   */
  isInteger: function(obj) {
    return typeof obj === "number" && obj % 1 === 0;
  },
  /**
   * 将对象转换为数值，如果不能转换成功则返回null
   * @param obj
   */
  getNumber: function(obj) {
    if (this.isType(obj) === "val") {
      return obj;
    }
    if (this.isNumberString(obj)) return parseFloat(obj);
    return null;
  },
  /**
   * 将百分比字符串转换为数值
   * @param percent
   * @return {*}
   */
  percentToPoint: function(percent) {
    if (this.isPercentageString(percent)) {
      let str = percent.replace("%", "");
      str = this.mathUtil.accDiv(str, 100);
      return str;
    }
    return 0;
  },
  /**
   * 数字转换为千分位逗号
   * @param obj
   * @param zeroFilling 是否补齐小数位的两位
   * @returns {String}
   */
  formatNumberRgx: function(num, zeroFilling) {
    if (num && num !== "" && num != null) {
      let parts = num.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if(zeroFilling) {
        if(parts.length >1 && parts[1].length==1)
          parts[1] = parts[1] + "0";
        else if(parts.length ==1)
          parts[1] = "00";
      }
      return parts.join(".");
    }
    if(zeroFilling)
      return "0.00";
    else
      return "0";
  },
  /**
   * 数值转换为金额
   * @param num
   * @param unit
   * @param showUnit
   */
  formatMomenyRgx: function(num, unit, showUnit) {
    let isUnit = showUnit!==undefined && showUnit === false ? false: true;
    if(unit==="万") {
      if(num && num !== "")
        return this.formatNumberRgx((Number(num) / 10000).toFixed(2) == 0.0 ? 0 : this.MathUtil.round2(Number(num) / 10000)) + (isUnit ? "万": "");
      else
        return "0" + (isUnit ? "万": "");
    }
    return this.formatNumberRgx(num);
  },
  /**
   * 将数值转为为百分比格式字符串
   * @param v
   * @param exactDigits
   */
  valToPercentStr: function(v, exactDigits) {
    let value = this.getNumber(v);
    let vs = 2;
    if(this.isInteger(exactDigits)) {
      vs = exactDigits;
    }
    if(value) {
      let reg =new RegExp("^\\d+(?:\\.\\d{0,"+vs+"})?");///^\d+(?:\.\d{0,2})?/
      return this.mathUtil.accMul(value, 100).toFixed(vs).match(reg)+"%";
    }
    return "0%";
  },
  /**
   * 把换行，制表符，左右两侧无效空格都转换为空字符
   * @param {Object} str
   */
  trimStr: function(str) {
    if (str) {
      str = str.replace(/\r\n*/g, "");
      return str.replace(/(^\s*)|(\s*$)/g, "");
    }
    return "";
  },
  /**
   * 将字符串按照指定字符串进行分割，并将非空字符串按顺序放入数组返回
   * @param str
   * @param split
   * @returns {*}
   */
  stringSplitArray: function(str, split) {
    let result = [];
    if (str && split) {
      let text = this.trimStr(str);
      if (text.includes(split)) {
        let array = text.split(split);
        return array.filter(function(item) {
          return item !== " " && item !== "";
        });
      }
    }
    result.push(str);
    return result;
  },
  /**
   * 把无效字符串进行转换为空字符串
   * @param str
   */
  changeNullToEmpty(str) {
    if (str && this.invalidString.indexOf(str) < 0) {
      return str;
    }
    return "";
  },
  /**
   * 把对象进行深度复制
   * @param obj
   * @return {*}
   */
  deepCopy(obj) {
    if (obj) {
      return JSON.parse(JSON.stringify(obj));
    }
    return obj;
  },
  /**
   * 对象或数组的深度拷贝
   * @param source
   * @return {*}
   */
  objDeepCopy(source) {
    if (this.isType(source) === "Object" || this.isType(source) === "Array") {
      let sourceCopy = source instanceof Array ? [] : {};
      for (let item in source) {
        sourceCopy[item] =
          typeof source[item] === "object"
            ? this.objDeepCopy(source[item])
            : source[item];
      }
      return sourceCopy;
    }
    return source;
  },
  /**
   * 默认对显示内容数据进行格式化
   * @param str
   * @param title
   */
  defaultFormatText(str, title) {
    let text = "";
    if (title) {
      if (this.isType(str) === "Boolean") {
        return str ? "是" : "否";
      } else {
        text = this.changeNullToEmpty(str);
        if (
          text !== "" &&
          (title.indexOf("时间") >= 0 || title.indexOf("日期") >= 0)
        ) {
          // 先进行时间类型的格式化
          if (this.dateUtils.checkStringIsTime(text)) {
            if (title.indexOf("时间") >= 0) {
              // 是时间字符串
              return this.dateUtils.formatDateString(text, "YYYY-MM-DD HH:mm");
            } else if (text.length == 10 || title.indexOf("日期") >= 0) {
              // 是日期格式
              return this.dateUtils.formatDateString(text, "YYYY-MM-DD");
            }
          }
        } else if (this.strIsTrue(text) || this.strIsFalse(text)) {
          return this.strIsTrue(text) ? "是" : "否";
        } else if (
          (title.indexOf("数量") >= 0 || title.indexOf("金额") >= 0) &&
          text === ""
        ) {
          return "0";
        }
      }
    }
    return text;
  },
  /**
   * 字符串是否是true
   * @param str
   * @returns {*|boolean}
   */
  strIsTrue: function(str) {
    return (
      this.isType(str) === "String" &&
      (str === "true" || str === "True" || str === "TRUE")
    );
  },
  /**
   * 字符串是否是false
   * @param str
   * @returns {*|boolean}
   */
  strIsFalse: function(str) {
    return (
      this.isType(str) === "String" &&
      (str === "false" || str === "False" || str === "FALSE")
    );
  },
  /**
   * 判断传入参数的类型
   * @param obj
   * @returns {String}
   */
  isType: function(obj) {
    if (obj === null) {
      return "Null";
    }
    if (obj === undefined) {
      return "Undefined";
    }
    let type = Object.prototype.toString.call(obj);
    if (type === "[object Array]") {
      return "Array";
    } else if (type === "[object Object]") {
      return "Object";
    } else if (type === "[object Boolean]") {
      return "Boolean";
    } else if (type === "[object Function]") {
      return "Function";
    } else if (type === "[object Date]") {
      return "Date";
    } else if (type === "[object RegExp]") {
      return "RegExp";
    } else if (type === "[object String]") {
      return "String";
    } else return "val";
  },
  /**
   * 将array中指定的值元素移除
   * @param {Object} array
   * @param {Object} value
   * @param {Function} tjf
   */
  arrayRemoveValue: function(array, value, tjf) {
    let i = array.length;
    while (i--) {
      //console.log(i + "=" + array[i]);
      if ((tjf && tjf(array[i], value)) || this.isObjEqual(array[i], value)) {
        array.splice(i, 1);
      }
    }
  },
  /**
   * 比较两个对象是否一样
   * @param {Object} o1
   * @param {Object} o2
   */
  isObjEqual: function(o1, o2) {
    if (this.isType(o1) === this.isType(o2)) {
      if (this.isType(o1) === "Object") {
        //如果是对象，按照下列方法对比
        let props1 = Reflect.ownKeys(o1);
        this.arrayRemoveValue(props1, "__ob__");
        let props2 = Reflect.ownKeys(o2);
        this.arrayRemoveValue(props2, "__ob__");
        if (props1.length != props2.length) {
          return false;
        }
        for (let i = 0, max = props1.length; i < max; i++) {
          if (!this.isObjEqual(o1[props1[i]], o2[props1[i]])) {
            return false;
          }
        }
        return true;
      } else if (
        this.isType(o1) === "Function" ||
        this.isType(o1) === "RegExp"
      ) {
        return false;
      } else if (this.isType(o1) === "Date") {
        //比较时间
        return o1.getTime() == o2.getTime();
      } else if (this.isType(o1) === "Array") {
        //比较数组
        if (o1.length != o2.length) {
          //两个数组长度不一
          return false;
        }
        if (o1.length == 0) {
          //两个空数组
          return true;
        }
        let someIndex = [];
        let that = this;
        for (let i = 0; i < o1.length; i++) {
          let tempObj = o1[i];
          //从o2中找到和o1中的tempObj一样的对象的位置
          let index2 = o2.findIndex(function(yobj) {
            return that.isObjEqual(yobj, tempObj);
          });
          if (index2 >= 0) {
            someIndex.push(i);
          }
        }
        return o1.length == someIndex.length;
      } else if (
        this.isType(o1) === "String" &&
        this.dateUtils.checkStringIsTime(o1) &&
        this.dateUtils.checkStringIsTime(o2)
      ) {
        let date1 = this.dateUtils.parse(o1);
        let date2 = this.dateUtils.parse(o2);
        return date1.getTime() == date2.getTime();
      } else {
        //其他类型可以直接比较
        return o1 === o2;
      }
    }
    return false;
  },
  /**
   * 将指定颜色变暗或变亮
   * @param {Object} col
   * @param {Object} amt
   */
  LightenDarkenColor: function(col, amt) {
    let usePound = false;
    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }
    let num = parseInt(col, 16);
    let r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    let b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    let g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    //console.log(r+"\n"+b+'\n'+g);
    let temp = ((r << 16) | (b << 8) | g).toString(16);
    let zore = "";
    if (temp.length < 6) {
      for (let i = 0; i < 6 - temp.length; i++) {
        zore += "0";
      }
    }
    return (usePound ? "#" : "") + zore + temp;
  },

  /**
   * 根据背景色，推荐最佳对比度字体颜色
   * @param {Object} hexcolor
   */
  getContrastYIQ: function(hexcolor) {
    let r = parseInt(hexcolor.substr(0, 2), 16);
    let g = parseInt(hexcolor.substr(2, 2), 16);
    let b = parseInt(hexcolor.substr(4, 2), 16);
    let yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
  },
  /**
   * 从URL地址中解析出所有请求参数，并返回成一个对象，无参数则返回空对象
   * @param {Object} url
   * @return {Object}
   */
  getQueryParams: function(url) {
    if (url && url.length > 0) {
      if (url.includes("?")) {
        url = url.substring(url.indexOf("?"));
      }
      //console.log("需要解析的url:"+url);
      try {
        let reg = new RegExp("(?!\\?|&)([^&|\\?]+)={0,1}([^&]+)", "ig");
        let allParams = url.match(reg);
        if (allParams) {
          let len = allParams.length;
          let temp = {};
          for (let i = 0; i < len; i++) {
            let t = allParams[i].split("=");
            temp[t[0]] = t[1];
          }
          //console.log(temp);
          return temp;
        }
      } catch (e) {
        console.log(e);
      }
      return {};
    }
    return {};
  },
  /**
   * 从请求地址中解析出对应名称的参数
   * @param {Object} url
   * @param {Object} name 参数名
   */
  getQueryString: function(url, name) {
    let reg = new RegExp("(^|&?)" + name + "=([^&]*)(&|$)", "i");
    let r = url.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },
  /**
   * 数组中是否有重复值，只能用于字符串或数字数组
   * @param arr
   * @returns {boolean}
   */
  arrayIsRepeat: function(arr) {
    let hash = {};
    for (let i in arr) {
      if (hash[arr[i]]) {
        return true;
      }
      // 不存在该元素，则赋值为true，可以赋任意值，相应的修改if判断条件即可
      hash[arr[i]] = true;
    }
    return false;
  },
  /**
   * 判断传入的对象是否是有效对象，如果有效返回true
   * null,undefined,{}和所有属性都无效的都是无效对象
   * @param obj
   * @returns {boolean}
   */
  isValidObject: function(obj) {
    if (obj) {
      let arr = Object.getOwnPropertyNames(obj);
      if (arr.length > 0) {
        let json = JSON.stringify(obj);
        if (json !== "{}") {
          for (let field in arr) {
            if (
              this.isType(obj[arr[field]]) === "Boolean" ||
              obj[arr[field]] ||
              obj[arr[field]] == 0 ||
              obj[arr[field]] === ""
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  },
  /**
   * 把对象拼接成 i4=4&i1=1&i2=2&i3=3格式
   * @param param
   * @param key
   * @returns {string}
   */
  parseParam: function(param, key) {
    let paramStr = "";
    let paramType = this.isType(param);
    if (paramType === "String") {
      param = param.replace(/\+/g, "$ADD$"); // 特殊字符转义
      //console.log("请求参数~！",param);
      paramStr += "&" + key + "=" + encodeURIComponent(param);
    } else if (paramType === "val" || paramType === "Boolean") {
      paramStr += "&" + key + "=" + encodeURIComponent(param);
    } else if (
      paramType === "Undefined" ||
      paramType === "Null" ||
      paramType === "Function" ||
      paramType === "RegExp"
    ) {
      return "";
    } else if (paramType === "Date") {
      paramStr +=
        "&" +
        key +
        "=" +
        this.dateUtils.pattern(param, this.dateUtils.UNITS.DEFAULT_FORMAT);
    } else {
      let paramKeys = Object.keys(param);
      let that = this;
      paramKeys.forEach(function(i) {
        let k =
          key == null
            ? i
            : key + (paramType === "Array" ? "[" + i + "]" : "." + i);
        paramStr += "&" + that.parseParam(param[k], k);
      });
    }
    return paramStr.substr(1);
  },
  /**
   * xml字符串字符反转义
   * @param str
   * @returns {string}
   */
  xml_decode(str) {
    let s = "";
    if (!str || str.length == 0) return "";
    s = str.replace(/&amp;/g, "&");
    s = s.replace(/&lt;/g, "<");
    s = s.replace(/&gt;/g, ">");
    s = s.replace(/&nbsp;/g, " ");
    s = s.replace(/&#39;/g, "'");
    s = s.replace(/&quot;/g, '"');
    s = s.replace(/<br\/>/g, "\n");
    return s;
  },
  /**
   * xml字符串字符反转义
   * @param str
   * @returns {string}
   */
  message_decode(str) {
    let s = "";
    if (!str || str.length == 0) return "";
    s = str.replace(/&amp;/g, "&");
    s = s.replace(/&lt;/g, "<");
    s = s.replace(/&gt;/g, ">");
    s = s.replace(/&nbsp;/g, " ");
    s = s.replace(/&#39;/g, "'");
    s = s.replace(/&quot;/g, '"');
    return s;
  },
  /**
   * 从obj中获取本身和子属性对象都包含的属性paramName的值，返回为数组
   * @param obj
   * @param paramName
   * @param valueArray
   * @returns {*}
   */
  getObjParamValueArrayByParam(obj, paramName, valueArray) {
    if (this.isType(obj) === "Array") {
      for (let i = 0; i < obj.length; i++) {
        this.getObjParamValueArrayByParam(obj[i], paramName, valueArray);
      }
    } else if (obj[paramName]) {
      valueArray.push(obj[paramName]);
      if (obj.children && obj.children.length > 0) {
        this.getObjParamValueArrayByParam(obj.children, paramName, valueArray);
      }
    } else if (obj.children && obj.children.length > 0) {
      this.getObjParamValueArrayByParam(obj.children, paramName, valueArray);
    }
  },
  /**
   * 从对象中找到 包含属性paramName值为paramValue的子对象
   * @param obj
   * @param paramName
   * @param paramValue
   * @returns {*}
   */
  getObjByParamAndValue(obj, paramName, paramValue) {
    if (obj && paramName && paramValue !== undefined && paramValue != null) {
      if (this.isType(obj) === "Object") {
        let paramKeys = Object.keys(obj);
        for (let key = 0; key < paramKeys.length; key++) {
          let tempType = this.isType(obj[paramKeys[key]]);
          if (tempType === "Object" || tempType === "Array") {
            let tempValue = this.getObjByParamAndValue(
              obj[paramKeys[key]],
              paramName,
              paramValue
            );
            if (tempValue) return tempValue;
          } else if (
            tempType === "val" ||
            tempType === "Boolean" ||
            tempType === "String"
          ) {
            if (
              paramName === paramKeys[key] &&
              obj[paramKeys[key]] === paramValue
            ) {
              return obj;
            }
          }
        }
      } else if (this.isType(obj) === "Array") {
        for (let index = 0; index < obj.length; index++) {
          if (this.isType(obj[index]) === "Object") {
            let tempValue = this.getObjByParamAndValue(
              obj[index],
              paramName,
              paramValue
            );
            if (tempValue) return tempValue;
          }
        }
      }
    }
    return null;
  },
  /**
   * 从对象中找到 包含属性paramName值为paramValue的子对象
   * @param obj
   * @param paramName
   * @param paramValue
   * @param type 类型 0：全文匹配，1：模糊匹配
   * @param sz 结果数组
   * @returns {*}
   */
  getObjArrayByParamAndValue(obj, paramName, paramValue, type, sz) {
    //debugger
    if (type != 0 && type != 1) {
      type = 0;
    }
    if (this.isType(obj) === "Object") {
      let paramKeys = Object.keys(obj);
      for (let key = 0; key < paramKeys.length; key++) {
        let tempType = this.isType(obj[paramKeys[key]]);
        if (tempType === "Object" || tempType === "Array") {
          this.getObjArrayByParamAndValue(
            obj[paramKeys[key]],
            paramName,
            paramValue,
            type,
            sz
          );
        } else if (
          tempType === "val" ||
          tempType === "Boolean" ||
          tempType === "String"
        ) {
          if (type == 0) {
            if (
              paramName === paramKeys[key] &&
              obj[paramKeys[key]].toUpperCase() === paramValue.toUpperCase()
            ) {
              sz.push(obj);
            }
          } else if (type == 1) {
            if (
              paramName === paramKeys[key] &&
              obj[paramKeys[key]]
                .toUpperCase()
                .includes(paramValue.toUpperCase())
            ) {
              sz.push(obj);
            }
          }
        }
      }
    } else if (this.isType(obj) === "Array") {
      for (let index = 0; index < obj.length; index++) {
        if (this.isType(obj[index]) === "Object") {
          this.getObjArrayByParamAndValue(
            obj[index],
            paramName,
            paramValue,
            type,
            sz
          );
        }
      }
    }
  },
  /**
   * 获取对应key的父级菜单key
   * @param key
   */
  getMenuParents(key) {
    if (key && this.isType(key) === "String") {
      if (key.includes(".", 1)) {
        // 是子菜单
        let parents = key.split(".");
        if (parents.length > 1) {
          let allParents = [];
          let tempKey = "";
          for (let i = 0; i < parents.length - 1; i++) {
            if (i == 0) tempKey += parents[i];
            else tempKey += "." + parents[i];
            allParents.push(tempKey);
          }
          return allParents;
        }
      }
    }
    return [];
  },
  getMenuParentsName(menuList, key) {
    let menuObj = this.getObjByParamAndValue(menuList, "key", key);
    if (menuObj.parent) return menuObj.menuName + " « " + menuObj.parent;
    else return menuObj.menuName;
  },
  /**
   * 给菜单设置默认的key
   * @param menuList
   * @param parentKey
   */
  setMenuKey(menuList, parentKey, parentName) {
    parentKey = parentKey ? parentKey : "";
    parentName = parentName ? parentName : "";
    let deleteMenu = [];
    for (let i = 0; i < menuList.length; i++) {
      let menu = menuList[i];
      if (menu.menuName) {
        if (parentKey !== "") {
          menu.key = parentKey + "." + i;
          menu.parent = parentName;
          menu.parentKey = parentKey;
        } else {
          menu.key = i + "";
        }
      } else {
        deleteMenu.push(i);
      }
      if (menu.submenu && menu.submenu.length > 0) {
        this.setMenuKey(menu.submenu, menu.key, menu.menuName);
      }
    }
    //移除无效菜单
    if (deleteMenu.length > 0) {
      for (let index in deleteMenu) {
        menuList.splice(index, 1);
      }
    }
  },
  /**
   * 从对象中找到 包含属性paramName的对象
   * @param obj
   * @param paramName
   * @param paramValue
   * @returns {*}
   */
  getObjArrayByParam(obj, paramName, sz) {
    if (!sz) {
      sz = [];
    }
    //debugger
    if (this.isType(obj) === "Object") {
      let paramKeys = Object.keys(obj);
      for (let key = 0; key < paramKeys.length; key++) {
        let tempType = this.isType(obj[paramKeys[key]]);
        if (tempType === "Object" || tempType === "Array") {
          this.getObjArrayByParam(obj[paramKeys[key]], paramName, sz);
        } else if (
          tempType === "val" ||
          tempType === "Boolean" ||
          tempType === "String"
        ) {
          if (paramName === paramKeys[key] && obj[paramName]) {
            let newObj = Object.assign({}, obj); // 只是把对象的拷贝返回给sz
            sz.push(newObj);
            return;
          }
        }
      }
    } else if (this.isType(obj) === "Array") {
      for (let index = 0; index < obj.length; index++) {
        if (this.isType(obj[index]) === "Object") {
          this.getObjArrayByParam(obj[index], paramName, sz);
        }
      }
    }
  },
  /**
   * 设置树形结构的title 与 key,并将空格去掉
   * @data 树形结构数据 object
   * @title 标题
   * @key 值
   * @returns object
   */
  setTreeKey: function(data, title, key) {
    data = JSON.stringify(data);
    title = "/" + title + "/g";
    key = "/" + key + "/g";
    data = data.replace(/ /g, "");
    data = data.replace(title, "key");
    data = data.replace(key, "title");
    return JSON.parse(data);
  },
  /**
   * 将数字数组进行升序排序
   * @param ary
   * @return {*}
   */
  sortAseNumberArray: function(ary) {
    if (ary && this.isType(ary) === "Array") {
      return ary.sort(function(a, b) {
        return a - b;
      });
    }
    return ary;
  },
  /**
   * 函数：获取窗口尺寸
   */
  findDimensions: function() {
    let winWidth, winHeight;
    //获取窗口宽度
    if (window.innerWidth) winWidth = window.innerWidth;
    else if (document.body && document.body.clientWidth)
      winWidth = document.body.clientWidth;
    //获取窗口高度
    if (window.innerHeight) winHeight = window.innerHeight;
    else if (document.body && document.body.clientHeight)
      winHeight = document.body.clientHeight;
    //通过深入Document内部对body进行检测，获取窗口大小
    if (
      document.documentElement &&
      document.documentElement.clientHeight &&
      document.documentElement.clientWidth
    ) {
      winHeight = document.documentElement.clientHeight;
      winWidth = document.documentElement.clientWidth;
    }
    return {
      height: winHeight,
      width: winWidth
    };
  },
  /**
   * 获取对象node节点相对于父节点的相对偏移
   * @param node
   * @param offset
   * @returns {*}
   */
  getOffset: function(node, offset) {
    if (!offset) {
      offset = {};
      offset.top = 0;
      offset.left = 0;
    }
    if (node == document.body) {
      //当该节点为body节点时，结束递归
      return offset;
    }
    offset.top += node.offsetTop;
    offset.left += node.offsetLeft;
    return this.getOffset(node.parentNode, offset); //向上累加offset里的值
  },
  /**
   * 导出方法
   * @data table结构的数据
   * @row 列头结构数据
   * @returns 导出文件
   */
  excelExport: function(data, row, title) {
    if (!data || !row) {
      return;
    }

    var workbook = new Excel.Workbook();
    workbook.creator = "tableData";
    workbook.lastModifiedBy = "tableData";
    workbook.created = new Date();
    workbook.modified = new Date();
    let sheet = workbook.addWorksheet(title);

    var columns = [];
    row.forEach(item => {
      // 如果没有title 则不导出
      if (item.visible != false && item.title) {
        var dataIndex = item["fieldName"]
          ? item["fieldName"]
          : item["dataIndex"];
        var title1 = item["title"] ? item["title"] : item["headerText"];
        var json = { header: title1, key: dataIndex };
        columns.push(json);
      }
    });

    sheet.columns = columns;

    const exceldata = [];

    data.forEach(element => {
      var rows = {};
      for (var key in element) {
        rows[key] = element[key];
      }
      exceldata.push(rows);
    });

    sheet.addRows(exceldata);
    workbook.xlsx.writeBuffer().then(function(buffer) {
      let blob = new Blob([buffer.buffer], {
        type: "text/plain;charset=utf-8"
      });
      FileSaver.saveAs(blob, title + ".xlsx");
    });
  },
  /**
   * 创建一个iframe打开文件
   * @param file
   */
  createIframeOpenFile: function(file){
    let el = document.createElement('iframe');
    el.src = file;
    el.style.display = 'none';
    document.body.appendChild(el);
    el.onload = function() { 
      setTimeout(function(){
        document.body.removeChild(el);
      },5000);
    };
  },
  /**
   * object是否为空
   * @object 判断的object
   */
  objectIsNull: function(object) {
    for (let key in object) {
      return key == undefined;
    }
    return true;
  },
  /**
   * 判断2个数字类型或者字符串数字是否一致
   * @object 判断的object
   */
   numberStingBoolean: function(num1, num2) {
    if (num1.toString() === num2.toString()) {
      return true;
    }
    return false;
  }
};

/**
 * 二元运算表达式的运算
 */
class Expression {
  static throwError(index) {
    throw `bad character position in expression:@${index}`;
  }

  static compItem(value, index) {
    return [value, index];
  }

  static isNumber(item) {
    return typeof item[0] === "number";
  }

  static calcExpression(exp) {
    let comps = [];

    let signs = new RegExp("[\\(\\)\\-\\+\\*\\/\\%]", "y"),
      space = new RegExp("\\s+", "y"),
      number = new RegExp("(\\d+\\.*\\d*)|(\\.\\d+)", "y");

    let lastIndex = 0,
      match;
    do {
      //debugger
      if (((space.lastIndex = lastIndex), true) && (match = space.exec(exp))) {
        //spaces
        //rawcomps.push(match[0]);
        lastIndex = space.lastIndex;
      } else if (
        ((signs.lastIndex = lastIndex), true) &&
        (match = signs.exec(exp))
      ) {
        //signs
        //rawcomps.push(match[0]);
        comps.push(Expression.compItem(match[0], match.index));
        if (match[0] === "(") {
          if (comps.length > 1) {
            let last = comps[comps.length - 2][0];
            if (last === ")" || Expression.isNumber(comps[comps.length - 2])) {
              comps.splice(comps.length - 1, 0, Expression.compItem("*", -1));
            }
          }
        }
        lastIndex = signs.lastIndex;
      } else if (
        ((number.lastIndex = lastIndex), true) &&
        (match = number.exec(exp))
      ) {
        //number
        //rawcomps.push(match[0]);
        if (match[0][0] === ".") match[0] = "0" + match[0]; //添加0
        comps.push(Expression.compItem(Number(match[0]), match.index));
        lastIndex = number.lastIndex;
      } else {
        Expression.throwError(lastIndex);
      }
    } while (lastIndex !== exp.length);
    //console.log("comps,",comps);
    return Expression.expressionParser(comps)[0];
  }

  /**
   * 数学运算表达式，支持括号
   * @param comps
   * @returns {*}
   */
  static expressionParser(comps) {
    //return a compItem
    let groupStack = [],
      lastWasNumber = false;
    for (let i = 0; i < comps.length; i++) {
      //(),先运算括号内的算数表达式
      if (Expression.isNumber(comps[i])) {
        if (lastWasNumber) Expression.throwError(comps[i][1]);
        lastWasNumber = true;
        continue;
      }
      lastWasNumber = false;
      if (comps[i][0] === "(") {
        groupStack.push(i);
      } else if (comps[i][0] === ")") {
        if (groupStack.length === 0) Expression.throwError(comps[i][1]);
        else if (groupStack.length === 1) {
          //use a new parser
          let newgroup = comps.splice(groupStack[0], i - groupStack[0] + 1);
          newgroup.pop();
          newgroup.shift();
          comps.splice(groupStack[0], 0, Expression.expressionParser(newgroup));
          i = groupStack[0];
        }
        groupStack.pop();
      }
    }
    if (groupStack.length)
      Expression.throwError(comps[groupStack[groupStack.length - 1]][1]);

    for (let i = 0; i < comps.length; i++) {
      // n%
      if (Expression.isNumber(comps[i])) {
        continue;
      }
      let exp = comps[i][0];
      if (exp === "%") {
        if (!comps[i - 1] || !Expression.isNumber(comps[i - 1]))
          Expression.throwError(comps[i][1]);
        comps.splice(
          i - 1,
          2,
          Expression.compItem(
            util.mathUtil.accDiv(comps[i - 1][0], 100),
            comps[i - 1][1]
          )
        );
        i--;
      }
    }
    for (let i = 0; i < comps.length; i++) {
      // * /
      if (Expression.isNumber(comps[i])) {
        continue;
      }
      let exp = comps[i][0];
      if (exp === "*" || exp === "/") {
        if (
          i === comps.length ||
          !comps[i - 1] ||
          !Expression.isNumber(comps[i - 1]) ||
          !Expression.isNumber(comps[i + 1])
        )
          Expression.throwError(comps[i][1]);
        //calc
        let result;
        if (exp === "*") {
          result = util.mathUtil.accMul(comps[i - 1][0], comps[i + 1][0]);
        } else {
          if (comps[i + 1][0] == 0) {
            throw "被除数不能为0";
          }
          result = util.mathUtil.accDiv(comps[i - 1][0], comps[i + 1][0]);
        }
        comps.splice(i - 1, 3, Expression.compItem(result, comps[i - 1][1]));
        i--;
      }
    }
    for (let i = 0; i < comps.length; i++) {
      // + -
      if (Expression.isNumber(comps[i])) {
        continue;
      }
      let exp = comps[i][0];
      if (exp === "+" || exp === "-") {
        if (i === comps.length || !Expression.isNumber(comps[i + 1]))
          Expression.throwError(comps[i][1]);
        if (i === 0 || !Expression.isNumber(comps[i - 1])) {
          //当做正负符号
          if (exp === "-") comps[i + 1][0] = -comps[i + 1][0];
          comps.splice(i, 1);
          i--;
        } else {
          //计算表达式
          let result;
          if (exp === "+") {
            result = util.mathUtil.accAdd(comps[i - 1][0], comps[i + 1][0]);
          } else {
            result = util.mathUtil.accSub(comps[i - 1][0], comps[i + 1][0]);
          }
          comps.splice(i - 1, 3, Expression.compItem(result, comps[i - 1][1]));
          i--;
        }
      }
    }
    if (comps.length !== 1) {
      console.error([...comps]);
      throw "something wrong";
    }
    return comps[0]; //return the value
  }
}
export default util;
