/**
 * 缓存每个tab对应的缓存数据处理方法
 * @type {{data(): *, created(), methods: {pushViewCache(*=, *): void, getViewCache(*=): *, clearViewCache(*=): void}}}
 */
export const cacheControls = {
  data() {
    return {}
  },
  created() {
    // console.log("混入cacheControls的通用方法");
  },
  methods: {
    /**
     * 向tab视图缓存中添加数据
     * @param key
     * @param cache
     */
    pushViewCache(key, cache)
    {
      if (key && cache) {
        let oldViewCache = JSON.parse(sessionStorage.getItem("ViewCache")) || {};
        oldViewCache[key] = cache;
        sessionStorage.setItem(
          "ViewCache",
          JSON.stringify(oldViewCache)
        );
      }
    },
    /**
     * 获取对应key的视图缓存
     */
    getViewCache(key)
    {
      if (key) {
        let oldViewCache = JSON.parse(sessionStorage.getItem("ViewCache")) || {};
        return oldViewCache[key];
      }
    },
    /**
     * 清除对应key的视图缓存
     */
    clearViewCache(key)
    {
      if (key) {
        let oldViewCache = JSON.parse(sessionStorage.getItem("ViewCache")) || {};
        if (oldViewCache[key]) {
          delete oldViewCache[key];
        }
        sessionStorage.setItem(
          "ViewCache",
          JSON.stringify(oldViewCache)
        );
      }
    },
    /**
     * 销毁所有的ViewCache缓存
     */
    destroyedViewCache() {
      sessionStorage.removeItem("ViewCache");
    }
  }
};