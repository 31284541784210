/**
 * 监听tab标签页的变化，将关闭的标签页进行销毁
 * @type {{data(): *, computed: {state_OpenTabs_MiXin: (function(): (Array|*))}, watch: {state_OpenTabs_MiXin: pageDestroy.watch.state_OpenTabs_MiXin}, created()}}
 */
export const pageDestroy = {
  data() {
    return {
      thisTabKey: this.getNowTabKey()
    };
  },
  computed: {
    thisClosedKey: function() {
      return this.$store.state.closedKey;
    }
  },
  watch: {
    thisClosedKey: function(v) {
      let that = this;
      if (v && v === that.thisTabKey) {
        setTimeout(function() {
          //console.log("监听到组件导航路由变化",from,that.$store.state.closedKey);
          //此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
          that.$store.state.closedKey = null;
          //console.log("监听到组件关闭",v,that);
          if (that.$vnode && that.$vnode.data.keepAlive) {
            if (
              that.$vnode.parent &&
              that.$vnode.parent.componentInstance &&
              that.$vnode.parent.componentInstance.cache
            ) {
              if (that.$vnode.componentOptions) {
                let key =
                  that.$vnode.key == null
                    ? that.$vnode.componentOptions.Ctor.cid +
                      (that.$vnode.componentOptions.tag
                        ? `::${that.$vnode.componentOptions.tag}`
                        : "")
                    : that.$vnode.key;
                let cache = that.$vnode.parent.componentInstance.cache;
                let keys = that.$vnode.parent.componentInstance.keys;
                if (cache[key]) {
                  if (keys.length) {
                    let index = keys.indexOf(key);
                    if (index > -1) {
                      keys.splice(index, 1);
                    }
                  }
                  console.log("清除缓存", v);
                  delete cache[key];
                }
              }
            }
          }
          that.$destroy();
        }, 1000);
      }
    }
  },
  /*beforeRouteUpdate:function(to, from, next){
    let that = this;
    setTimeout(function(){
      //console.log("监听到组件导航路由变化",from,that.$store.state.closedKey);
      if (from && that.$store.state.closedKey)
        console.log("监听到组件关闭",from,that.$store.state.closedKey);
      {//此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
        that.$store.state.closedKey = null;
        if (that.$vnode && that.$vnode.data.keepAlive)
        {
          if (that.$vnode.parent && that.$vnode.parent.componentInstance && that.$vnode.parent.componentInstance.cache)
          {
            if (that.$vnode.componentOptions)
            {
              var key = that.$vnode.key == null
                ? that.$vnode.componentOptions.Ctor.cid + (that.$vnode.componentOptions.tag ? `::${that.$vnode.componentOptions.tag}` : '')
                : that.$vnode.key;
              var cache = that.$vnode.parent.componentInstance.cache;
              var keys  = that.$vnode.parent.componentInstance.keys;
              if (cache[key])
              {
                if (keys.length) {
                  var index = keys.indexOf(key);
                  if (index > -1) {
                    keys.splice(index, 1);
                  }
                }
                delete cache[key];
              }
            }
          }
        }
        that.$destroy();
      }
    },1000);
    next();
  },*/
  created() {
    // console.log("混入pageDestroy的通用方法");
  }
};
