//从xml模板中解析出表单所有表单项的配置JSON数组
import moment from "moment";
import { e1FormValidator } from "@/mixins/e1FormValidator.js";
import { CXS_TO_ECONTROLS } from "../utils/e1_TemplateParser"; // e1表单的自定义校验方法
export const analyticalTemplate = {
  mixins: [e1FormValidator],
  data() {
    return {
      inputControls: this.TemplateParser.inputControls, // 输入型控件
      controlMapping: this.$config.CXS_TO_ECONTROLS, // 控件对应配置
      controlCheckTypes: this.TemplateParser.controlCheckTypes,
      showLog: true,
      allFormulaConfigsMiXin: [], // 所有配置了计算公式的字段配置
      visScopeTakeEffectMiXin: true // 可以设置可见范围在引用页面内是否生效，默认是生效的
    };
  },
  created() {
    //console.log("混入analyticalTemplate的通用方法");
  },
  methods: {
    /**
     * 默认的表单自定义校验方法
     * @param rule
     * @param value
     * @param callback
     */
    e1FormValidatorSystem: function(rule, value, callback) {
      //console.log("进入默认的表单校验方法，",rule, value);
      if (rule.uniqueCheck) {
        // 字段值唯一性约束校验，异步方法
        if (this.e1_uniqueCheck) {
          if (this.dataId) {
            // 如果表单已经保存到数据库，则需要排除该id
            let pcId = "ID<>" + this.dataId;
            if (rule.uniqueCheck.UniqueCheckCondition) {
              // 如果设置了前置条件
              if (!rule.uniqueCheck.UniqueCheckCondition.includes(pcId)) {
                rule.uniqueCheck.UniqueCheckCondition =
                  rule.uniqueCheck.UniqueCheckCondition + " AND " + pcId;
              }
            } else {
              rule.uniqueCheck.UniqueCheckCondition = pcId;
            }
          }
          this.e1_uniqueCheck(rule, value, callback);
          return;
        }
      }
      callback();
    },
    /**
     * 将xml模板解析为表单
     * @param formTemplate 模板xml字符串
     * @param thisMainTableData 主表单数据绑定对象
     * @param mainTableName 主表表名
     */
    renderFormTemplate(formTemplate, thisMainTableData, mainTableName) {
      let temObj = this.TemplateParser.renderFormTemplate(
        formTemplate,
        thisMainTableData,
        this.getValOfFixedValueType,
        this.layoutGrid,
        mainTableName
      );
      this.allFormulaConfigsMiXin = temObj.formulaConfigs;
      return temObj;
    },
    /**
     * 修改明细表字段的可见范围是否生效
     * @param temObj
     */
    checkMxVisScopeTakeEffect: function(temObj) {
      if (this.visScopeTakeEffectMiXin) {
        console.log("检测明细表配置的可见访问是否有效");
        let mxForm = temObj.mxForm;
        //debugger
        if (mxForm) {
          //如果只有一张明细表格
          mxForm[0].controlDefine.visScopeTakeEffect = true;
        } else {
          mxForm = temObj.mxForms;
          if (mxForm) {
            //是多张明细表的tab ?
            for (let i = 0; i < mxForm.childObject.length; i++) {
              let tabObj = mxForm.childObject[i];
              if (!tabObj.controlDefine.colSpan) { // 是子表格
                for (let j = 0; j < tabObj.childObject.length; j++) {
                  tabObj.childObject[j].controlDefine.visScopeTakeEffect = true;
                }
              } else { // 是tab表单
                this.checkMainVisScopeTakeEffect(tabObj.controlDefine.formRows);
              }
            }
          }
        }
      }
    },
    /**
     * 修改表单主表单控件字段的可见范围是否生效
     * @param rows
     */
    checkMainVisScopeTakeEffect: function(rows) {
      if (this.visScopeTakeEffectMiXin) {
        console.log("检测主表配置的可见访问是否有效");
        let that = this;
        rows.forEach(row => {
          for (let j = 0; j < row.columns.length; j++) {
            let tempControl = row.columns[j];
            if (
              tempControl.configs.visScope &&
              tempControl.configs.visScope.length > 0
            ) {
              //!tempControl.isShow &&
              let uIndex = tempControl.configs.visScope.findIndex(function(u) {
                return that.$util.trimStr(u) === that.$store.getters.getUserName;
              });
              if (uIndex == -1) {
                tempControl.isShow = false;
              }
            }
          }
        });
      }
    },
    changeFormPropertyValue(rows, property, value) {
      if (
        rows &&
        this.$util.isType(property) === "String" &&
        value != undefined
      ) {
        rows.forEach(row => {
          for (let j = 0; j < row.columns.length; j++) {
            let tempControl = row.columns[j];
            if (
              tempControl.configs.fieldName &&
              tempControl.configs.fieldName === property
            ) {
              tempControl.property = value;
            }
          }
        });
      }
    },
    /**
     * 计算依据栅格布局，应该如果排版控件
     * @param rows
     * @returns {{maxColumnCount: number, formRows: Array}}
     */
    layoutGrid(rows) {
      let that = this;
      let formRows = [];
      let maxColumnCount = 0;
      // 存储未被布局的控件列
      let noLayoutColumns = [];

      // 如果设置了可见范围，需要将可见范围外的用户，对该控件不可见
      // 组织每行显示的控件
      rows.forEach(row => {
        let formControls = [];
        let thisRowHaveGroupBox = false; // 本行是否有gourpbox组件
        let moreControls = 0; // 多个控件公用一个标签
        let rowGroups = 0;
        for (let p = 0; p < row.columns.length; ) {
          // 把标签控件和对应的表单字段对应出来
          if (
            row.columns[p].controlType === "CxsLabel" &&
            row.columns[p + 1] &&
            row.columns[p + 1].controlType !== "CxsLabel"
          ) {
            // 通常情况一个lalel后跟一个控件
            // 获取控件配置对应的本系统定义的vue控件名
            let controlLabel = row.columns[p].text;
            let controlProps = row.columns[p + 1];
            controlProps.size.labelw = row.columns[p].size.w;
            that.setOneLableControl(controlProps, controlLabel);
            formControls.push(controlProps);

            for (let q = p + 2; q < row.columns.length; q++) {
              if (
                row.columns[q].controlType !== "CxsLabel" &&
                row.columns[q].controlType !== "CxsGroupBox"
              ) {
                // 如果后续还有非标签组件
                let nextControlProps = row.columns[q];
                that.setOneLableControl(nextControlProps, "");
                formControls.push(nextControlProps);
                p++;
                moreControls++;
              } else if (
                row.columns[q] &&
                row.columns[q].controlType === "CxsLabel" &&
                row.columns[q].fontSize == undefined &&
                (row.columns.length == q + 1 ||
                  row.columns[q + 1].controlType === "CxsLabel")
                //&& (row.columns[q+1].fontSize == undefined || row.columns[q+2].fontSize === "9pt")
                //&& (row.columns.length == q+2 || row.columns[q+2].controlType !== "CxsLabel")
              ) {
                // 如果后续跟着有意义标签组件,（除去表单标题控件）
                console.log("有意义标签内容:", row.columns[q].text);
                controlProps.nextTip = row.columns[q].text; //组件注释内容
                p++;
              } else {
                break;
              }
            }
          } else if (row.columns[p].controlType === "CxsGroupBox") {
            // 如果是单独的一个groupBox控件
            rowGroups++;
            thisRowHaveGroupBox = true;
            let controlProps = row.columns[p];
            that.setOneLableControl(controlProps, controlProps.text);
            formControls.push(controlProps);
            p = p + 1;
            continue;
          } else if (
            row.columns[p].controlType === "CxsCheckBox" ||
            row.columns[p].controlType === "CxsRadioButton"
          ) {
            // 如果是单独的一个CxsCheckBox和CxsRadioButton,没有标签
            let controlProps = row.columns[p];
            that.setOneLableControl(controlProps, "");
            formControls.push(controlProps);
            p = p + 1;
            continue;
          } else if (row.columns[p].controlType === "CxsBooleanCheckbox") {
            // 如果是单独的一个CxsBooleanCheckbox,没有标签
            let controlProps = row.columns[p];
            //that.setOneLableControl(controlProps, controlProps.label);
            formControls.push(controlProps);
            p = p + 1;
            continue;
          } else if (
            row.columns[p].controlType === "CxsLabel" &&
            row.columns.length == 1 &&
            row.columns[p].fontSize === "9pt"
          ) {
            // 一行只有一个label，应该是一个注释
            formControls.push(row.columns[p]);
            break;
          } else {
            noLayoutColumns.push(row.columns[p]);
            p++;
            continue;
          }
          p = p + 2;
        }
        //debugger
        // 过滤掉不显示控件
        let noShowControls = formControls.filter(function(obj) {
          return obj.isShow === false;
        });
        let tempLength =
          formControls.length - noShowControls.length - moreControls; // 如果一行中无groupBox
        if (thisRowHaveGroupBox && formControls.length == rowGroups) {
          // 一个或多个groupBox独占一行
          tempLength = rowGroups;
        } else if (thisRowHaveGroupBox) {
          //debugger
          // 一行当中有groupBox和其他非容器控件
          tempLength = formControls.length; //- moreControls + rowGroups;
        } // 一行中无groupBox
        maxColumnCount = Math.max(maxColumnCount, tempLength);
        //组织每行显示的表单控件
        let thisColspan = tempLength > 0 ? Math.floor(24 / tempLength) : 24;
        formRows.push({
          key: Math.random(),
          columns: formControls,
          colSpan: thisColspan
        });
      });

      // todo 调试用代码
      if (noLayoutColumns.length) {
        console.log(
          "%c 表单中未被布局的元素：",
          "background-color: red; color: white; font-weight: bold;",
          noLayoutColumns
        );
      }

      // 处理未被布局的控件列
      for (let i = 0; i < noLayoutColumns.length; i += 2) {
        let formControls = [];

        // 处理二行元素中，上行元素是label控件而下行元素不是label控件的情况
        if (
          noLayoutColumns[i].controlType === "CxsLabel" &&
          noLayoutColumns[i + 1] &&
          noLayoutColumns[i + 1].controlType !== "CxsLabel"
        ) {
          let controlLabel = noLayoutColumns[i].text;
          let controlProps = noLayoutColumns[i + 1];

          controlProps.size.labelw = noLayoutColumns[i].size.w;
          that.setOneLableControl(controlProps, controlLabel);
          formControls.push(controlProps);

          formRows.push({
            key: Math.random(),
            columns: formControls,
            colSpan: 12
          });
        }
      }
      return {
        maxColumnCount: maxColumnCount,
        colSpan: Math.floor(24 / maxColumnCount), // 计算每个控件占用的列宽，每个控件可占用的col宽（最大不超过24）
        formRows: formRows
      };
    },
    /**
     * 把lable控件设置为后续控件的一个lable属性
     * @param controlProps
     * @param label
     */
    setOneLableControl: function(controlProps, label) {
      let formControlType;
      if (this.controlMapping[controlProps.controlType]) {
        formControlType = this.controlMapping[controlProps.controlType].name;
        if(this.controlMapping[controlProps.controlType]) {
          controlProps.setting = this.$util.deepCopy(this.controlMapping[controlProps.controlType]);
          //如果设置了checkType，则需要根据配置转换为对应的type类型
          if (controlProps.configs.checkType) {
            let type = this.controlCheckTypes[controlProps.configs.checkType];
            if (type) {
              controlProps.setting.type = type;
            }
          }
        }
      }
      controlProps.label = label;
      if (label && label !== "") {
        // 特殊处理方法
        // 根据label重新设置用户的message提示信息
        if (
          controlProps.configs.rules &&
          controlProps.configs.rules.length > 0
        ) {
          let that = this;
          // 是否有校验规则配置
          let deleteIndex = [];
          controlProps.configs.rules.forEach(function(cObj, index) {
            if (cObj.required) {
              // 必填项
              if (that.inputControls.indexOf(controlProps.controlType) >= 0)
                cObj.message = "请输入" + label;
              else cObj.message = "请选择" + label;
              //console.log(label, "=", cObj.message);
            }
            if (cObj.uniqueCheck) {
              // 唯一性约束  e1FormValidatorSystem
              if (that.inputControls.indexOf(controlProps.controlType) >= 0) {
                // 目前只有输入控件支持自定义校验
                cObj.validator = that.e1FormValidatorSystem;
                cObj.uniqueCheck.FieldName = controlProps.configs.fieldName;
                cObj.message = "输入的" + label + "已经存在";
              } else {
                deleteIndex.push(index);
              }
            }
          });
          for (let ix in deleteIndex) {
            controlProps.configs.rules.splice(ix, 1);
          }
        }

        //console.log(label, "multiSelect=", controlProps.configs.multiSelect);
        if (controlProps.configs.multiSelect) {
          controlProps.multiSelect = "multi";
        }
      }
      controlProps.key =  new Date().getTime() + "_" + Math.random();
      controlProps.thisControlType = formControlType; // 本地控件类型
    },
    /**
     * 根据固定值类型，获取对应的值
     * @param fixedValueType 固定值类型
     * @param controlProp 控制配置
     */
    getValOfFixedValueType(fixedValueType, controlProp) {
      if (fixedValueType && fixedValueType !== "无") {
        let nowMonth = moment().month();
        switch (fixedValueType) {
          case "当前部门":
            return {
              label: this.$store.getters.getUserDept,
              value: this.$store.getters.getDepartId
            };
          case "当前登录人":
            return {
              label: this.$store.getters.getEmployee,
              value: this.$store.getters.getEmployeeId
            };
          case "登录日期": //this.$store.getters.getLoginTime ?默认当前时间
            return moment().format("YYYY-MM-DD HH:mm:ss");
          case "最大日期": // 日期控件暂时不可用
            if (controlProp.maxDate) return controlProp.maxDate;
            break;
          case "最小日期": // 日期控件暂时不可用
            if (controlProp.minDate) return controlProp.minDate;
            break;
          case "登录月份":
            if (controlProp.controlType === "CxsDateTimePicker") {
              // 日期控件暂时不可用
              return null;
              //return moment(this.$store.getters.getLoginTime).format("YYYY-MM-DD");
            } else {
              nowMonth = moment(this.$store.getters.getLoginTime).month();
              return nowMonth + 1;
            }
          case "登录月份增1":
            if (controlProp.controlType === "CxsDateTimePicker") {
              // 日期控件暂时不可用
              //return null;
              return moment(this.$store.getters.getLoginTime)
                .add(1, "months")
                .format("YYYY-MM-DD");
            } else {
              nowMonth = moment(this.$store.getters.getLoginTime).month();
              if (nowMonth < 11) return nowMonth + 1;
              else return 1; // 下一年
            }
          case "登录月份减1":
            if (controlProp.controlType === "CxsDateTimePicker") {
              // 日期控件暂时不可用
              //return null;
              return moment(this.$store.getters.getLoginTime)
                .subtract(1, "months")
                .format("YYYY-MM-DD");
            } else {
              nowMonth = moment(this.$store.getters.getLoginTime).month();
              if (nowMonth > 1) return nowMonth - 1;
              else return 12; // 前一年
            }
          case "列表空行值":
            return "";
          case "登录公司ID":
            return this.$store.getters.getCompanyId;
          case "登录年份":
            if (controlProp.controlType === "CxsDateTimePicker") {
              // 日期控件暂时不可用
              //return null;
              return moment(this.$store.getters.getLoginTime)
                .subtract(1, "months")
                .format("YYYY-MM-DD");
            } else {
              return moment(this.$store.getters.getLoginTime).format("YYYY");
            }
          case "固定登录日期":
            break;
          case "本月1号":
            return this.$util.dateUtils.getDateTimePickerDefaultValue(
              "本月第一天"
            );
          case "下一年度本月1号":
            return this.$util.dateUtils.getDateTimePickerDefaultValue(
              "明年本月第一日"
            );
          case "当天所属本年周":
            return this.$util.dateUtils.getDateTimePickerDefaultValue("当天所属本年周")
        }
      }
      return null;
    },

    /**
     * 从主表单中遍历出所有计算公式的配置
     * @returns {Object}
     */
    getFormulaConfigs() {
      let formulaConfig = {
        haveMxField: false // 是否有明细表字段参与计算
      };
      let formulaConfigs = [];
      // 主表内的计算字段
      let allControls = this.allFormulaConfigsMiXin;
      if (allControls.length > 0) {
        // 表单内有需要计算的公式
        let reg = new RegExp("\\[([^\\]]+)\\]", "ig"); //从字符串中匹配出所有用[]括起来的字段,计算公式
        let reg2 = new RegExp("\\{([^\\}]+)\\}", "ig"); //从字符串中匹配出所有用{}括起来的字段，联动条件过滤表达式

        for (let i = 0; i < allControls.length; i++) {
          let control = allControls[i];
          if (control.formula || control.cFormula) {
            // 是计算公式
            let formula = control.formula ? control.formula : control.cFormula;
            let allFields = formula.match(reg);
            if (formula && allFields && allFields.length > 0) {
              // 公式中所有的变量字段
              let fields = allFields.map(function(obj) {
                return obj.slice(0, -1).slice(1);
              });
              for (let j = 0; j < fields.length; j++) {
                if (fields[j].includes(".", 1)) {
                  formulaConfig.haveMxField = true;
                  control.tableName = fields[j].split(".")[0];
                }
                formula = formula.replace(allFields[j], fields[j]);
              }
              formulaConfigs.push({
                rowFormula: control.rowFormula == true ? true : false, // 明细行计算公式，true是
                mxTableName: control.tableName,
                resultField: control.fieldName,
                variableField: fields,
                formula: formula
              });
              //console.log(formula+"="+this.$util.mathUtil.isMathExpression(formula));
            }
          } else if (control.filter) {
            // 是联动条件
            let filter = control.filter;
            let allFields1 = filter.match(reg2);
            if (allFields1 && allFields1.length > 0) {
              // 条件中所有的变量字段
              let fields = allFields1.map(function(obj) {
                return obj.slice(0, -1).slice(1);
              });
              for (let j = 0; j < fields.length; j++) {
                if (fields[j].includes(".", 1)) {
                  formulaConfig.haveMxField = true;
                  control.tableName = fields[j].split(".")[0];
                }
                filter = filter.replace(allFields1[j], fields[j]);
              }
              formulaConfigs.push({
                fieldName: control.fieldName,
                rowFilter: control.rowFormula == true ? true : false, // 明细行计算公式，true是
                mxTableName: control.tableName,
                variableField: fields,
                filter: filter
              });
            }
          }
        }
        formulaConfigs.sort(function(a, b) {
          // 把和明细表关联的计算公式排到前面
          if (!a.mxTableName && b.mxTableName) {
            return 1;
          } else {
            return -1;
          }
        });
        formulaConfig.formulaConfigs = formulaConfigs;
        return this.classifiedDisassembly(formulaConfig);
      } else {
        return {};
      }
    },

    /**
     * 将公式配置数组，根据是否需要明细数据参与计算进行分类
     * @param formulaConfig
     * @returns {*}
     */
    classifiedDisassembly(formulaConfig) {
      if (
        formulaConfig &&
        formulaConfig.formulaConfigs &&
        formulaConfig.formulaConfigs.length > 0
      ) {
        if (formulaConfig.haveMxField) {
          // 过滤出需要明细数据才能进行的计算公式
          let mainFromRowFormulaConfigs = formulaConfig.formulaConfigs.filter(
            function(d) {
              // 单纯的明细数据表字段间的计算公式
              return !d.rowFormula && d.mxTableName;
            }
          );
          formulaConfig.mainFromRowFormulaConfigs = mainFromRowFormulaConfigs;
        }
        // 主表单数据关联计算公式
        let mainFormulaConfigs = formulaConfig.formulaConfigs.filter(function(
          d
        ) {
          return !d.rowFormula && !d.mxTableName;
        });
        formulaConfig.mainFormulaConfigs = mainFormulaConfigs;
        formulaConfig.formulaConfigs = undefined;
      }
      return formulaConfig;
    },
    /**
     * 分解校验条件，必须是逻辑表达式
     * @param saveCondition
     */
    getSaveCondition(saveCondition) {
      if (
        this.$util.isType(saveCondition) === "String" &&
        saveCondition !== ""
      ) {
        let formulaConfig = {
          haveMxField: false // 是否有明细表字段参与计算
        };
        let reg = new RegExp("\\[([^\\]]+)\\]", "ig"); //从字符串中匹配出所有用[]括起来的字段
        let allFields = saveCondition.match(reg);
        if (allFields && allFields.length > 0) {
          // 公式中所有的变量字段
          let fields = allFields.map(function(obj) {
            return obj.slice(0, -1).slice(1);
          });
          formulaConfig.variableFields = [];
          for (let j = 0; j < fields.length; j++) {
            if (fields[j].includes(".", 1)) {
              formulaConfig.haveMxField = true;
              formulaConfig.variableFields.push({
                oldArgName: fields[j],
                tableName: fields[j].split(".")[0],
                fieldName: fields[j].split(".")[1]
              });
            } else {
              formulaConfig.variableFields.push({
                oldArgName: fields[j]
              });
            }
            saveCondition = saveCondition.replace(allFields[j], fields[j]);
          }
          saveCondition = this.$util.xml_decode(saveCondition); // 把一些已经转义的字符反转义
          formulaConfig.saveCondition = saveCondition;
          return formulaConfig;
        }
      }
      return null;
    },
    /**
     * 将表单指定字段设置为必填
     * @param fields
     */
    setFormFieldRequired(thisFrom, formRows, fields) {
      if (
        thisFrom &&
        formRows &&
        fields &&
        this.$util.isType(fields) === "Array"
      )
        for (let i = 0; i < fields.length; i++) {
          let field = fields[i];
          for (let r = 0; r < formRows.length; r++) {
            let comlumn = formRows[r].columns;
            for (let j = 0; j < comlumn.length; j++) {
              if (comlumn[j].configs.fieldName === field) {
                let rules = [];
                if (comlumn[j].configs.rules) {
                  let objTemp = this.$util.getObjByParamAndValue(
                    comlumn[j].configs.rules,
                    "required",
                    true
                  );
                  if (objTemp) {
                    break;
                  }
                  rules = comlumn[j].configs.rules.concat();
                } else {
                  rules = [];
                }
                let message = "请输入" + comlumn[j].label;
                if (this.inputControls.indexOf(comlumn[j].controlType) < 0)
                  message = "请选择" + comlumn[j].label;

                let requiredRule = {
                  required: true,
                  message: message
                };
                // 如果在必填的基础上还配置了校验正则条件
                if (
                  this.controlMapping[comlumn[j].controlType].requiredPattern
                ) {
                  requiredRule.validator = (rule, value) => {
                    if (value) {
                      let checkValue = value + "";
                      return (
                        !!checkValue.match(
                          this.controlMapping[comlumn[j].controlType]
                            .requiredPattern
                        ) && checkValue.length < 255
                      );
                    }
                    return false;
                  };
                }
                rules.push(requiredRule); //必填项校验规则
                console.log("追加了必填规则", rules);
                comlumn[j].configs.rules = rules;
                break;
              }
            }
          }
        }
    },
    /**
     * 设置表单字段表单为隐藏
     * @param thisFrom
     * @param formRows
     * @param fields
     */
    setFormFieldHide(thisFrom, formRows, fields) {
      if (
        thisFrom &&
        formRows &&
        fields &&
        this.$util.isType(fields) === "Array"
      )
        for (let i = 0; i < fields.length; i++) {
          let field = fields[i];
          for (let r = 0; r < formRows.length; r++) {
            let comlumn = formRows[r].columns;
            for (let j = 0; j < comlumn.length; j++) {
              if (comlumn[j].configs.fieldName === field) {
                comlumn[j].isShow = false;
                break;
              }
            }
          }
        }
    },
    /**
     * 修改明细表指定字段的隐藏状态
     * @param option
     * @param tabOptions
     * @param fields
     */
    setMxFieldHide(option, tabOptions, fields) {
      console.log("明细表配置隐藏字段", fields);
      if (
        (option || tabOptions) &&
        fields &&
        this.$util.isValidObject(fields)
      ) {
        if (option && fields[option.tableName]) {
          // 只有一张明细表定义
          if (option.columns && option.columns.length > 0) {
            for (let r = 0; r < option.columns.length; r++) {
              if (
                fields[option.tableName].indexOf(option.columns[r].fieldName) >=
                0
              ) {
                // 如果有对应字段
                option.columns[r].visible = false;
              }
            }
          }
        } else if (tabOptions) {
          // 明细表在tab里
          for (let i = 0; i < tabOptions.length; i++) {
            let tabObj = tabOptions[i];
            if (!tabObj.controlDefine.colSpan) {
              for (let j = 0; j < tabObj.childObject.length; j++) {
                let gridObj = tabObj.childObject[j];
                if (fields[gridObj.controlDefine.tableName]) {
                  // 如果给表配置了隐藏设置
                  for (let f = 0; f < gridObj.controlDefine.columns; f++) {
                    if (
                      fields[gridObj.controlDefine.tableName].indexOf(
                        gridObj.controlDefine.columns[f].fieldName
                      ) >= 0
                    ) {
                      // 如果有对应字段
                      gridObj.controlDefine.columns[f].visible = false;
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    /**
     * 从obj中获取包含属性paramName的值为paramValue的对象或子对象
     * @param obj
     * @param paramName
     * @param paramValue
     * @returns {*}
     */
    getObjByParamValue(obj, paramName, paramValue) {
      return this.TemplateParser.getObjByParamValue(obj, paramName, paramValue);
    },
    /**
     * 从obj中获取第一个controlType对象
     * @param obj
     * @param controlType
     * @returns {*}
     */
    getObjByControlType(obj, controlType) {
      return this.TemplateParser.getObjByControlType(obj, controlType);
    },

    /**
     * 从obj中获取所有controlType对象并返回为数组
     * @param obj
     * @param controlType
     * @param shuZu
     * @returns {*}
     */
    getAllObjByControlType(obj, controlType, shuZu) {
      return this.TemplateParser.getAllObjByControlType(
        obj,
        controlType,
        shuZu
      );
    },

    /**
     * 将xml模板解析为检索条件表单
     * @param formTemplate 模板xml字符串
     * @param thisMainTableData 主表单数据绑定对象
     */
    renderSearchConditionTemplate(formTemplate, thisMainTableData) {
      let temObj = this.TemplateParser.renderFormTemplate(
        formTemplate,
        thisMainTableData,
        this.getValOfFixedValueType,
        this.layoutLinear
      );
      this.allFormulaConfigsMiXin = temObj.formulaConfigs;
      return temObj;
    },

    /**
     * 单纯的线性布局
     * @param rows
     * @returns {{formRows: Array}}
     */
    layoutLinear: function(rows) {
      let that = this;
      let formRows = [];
      // 存储未被布局的控件列
      let noLayoutColumns = [];

      // 如果设置了可见范围，需要将可见范围外的用户，对该控件不可见
      if (this.visScopeTakeEffectMiXin) {
        rows.forEach(row => {
          for (let j = 0; j < row.columns.length; j++) {
            let tempControl = row.columns[j];
            if (
              !tempControl.isShow &&
              tempControl.configs.visScope &&
              tempControl.configs.visScope.length > 0
            ) {
              let uIndex = tempControl.configs.visScope.findIndex(function(u) {
                return u === that.$store.getters.getUserName;
              });
              if (uIndex == -1) {
                tempControl.isShow = false;
              }
            }
          }
        });
      }
      // 组织每行显示的控件
      rows.forEach(row => {
        let formControls = [];
        for (let p = 0; p < row.columns.length; ) {
          // 把标签控件和对应的表单字段对应出来
          if (
            row.columns[p].controlType === "CxsLabel" &&
            row.columns[p + 1] &&
            row.columns[p + 1].controlType !== "CxsLabel"
          ) {
            // 通常情况一个lalel后跟一个控件
            // 获取控件配置对应的本系统定义的vue控件名
            let controlLabel = row.columns[p].text;
            let controlProps = row.columns[p + 1];
            that.setOneLableControl(controlProps, controlLabel);
            formControls.push(controlProps);

            for (let q = p + 2; q < row.columns.length; q++) {
              if (
                row.columns[q].controlType !== "CxsLabel" &&
                row.columns[q].controlType !== "CxsGroupBox"
              ) {
                // 如果后续还有非标签组件
                let nextControlProps = row.columns[q];
                that.setOneLableControl(nextControlProps, "");
                formControls.push(nextControlProps);
                p++;
              } else {
                break;
              }
            }
          } else if (row.columns[p].controlType === "CxsGroupBox") {
            // 如果是单独的一个groupBox控件
            formControls = formControls.concat(row.columns[p].formRows);
            p = p + 1;
            continue;
          } else if (
            row.columns[p].controlType === "CxsCheckBox" ||
            row.columns[p].controlType === "CxsRadioButton"
          ) {
            // 如果是单独的一个CxsCheckBox和CxsRadioButton,没有标签
            let controlProps = row.columns[p];
            that.setOneLableControl(controlProps, "");
            formControls.push(controlProps);
            p = p + 1;
            continue;
          } else if (row.columns[p].controlType === "CxsBooleanCheckbox") {
            // 如果是单独的一个CxsBooleanCheckbox,没有标签
            let controlProps = row.columns[p];
            //that.setOneLableControl(controlProps, controlProps.label);
            formControls.push(controlProps);
            p = p + 1;
            continue;
          } else {
            noLayoutColumns.push(row.columns[p]);
            p = p + 1;
            continue;
          }
          p = p + 2;
        }
        formRows = formRows.concat(formControls);
      });

      // todo 调试用代码
      if (noLayoutColumns.length) {
        console.log(
          "%c 表单中未被布局的元素：",
          "background-color: red; color: white; font-weight: bold;",
          noLayoutColumns
        );
      }

      // 处理未被布局的控件列
      for (let i = 0; i < noLayoutColumns.length; i += 2) {
        let formControls = [];

        // 处理二行元素中，上行元素是label控件而下行元素不是label控件的情况
        if (
          noLayoutColumns[i].controlType === "CxsLabel" &&
          noLayoutColumns[i + 1] &&
          noLayoutColumns[i + 1].controlType !== "CxsLabel"
        ) {
          let controlLabel = noLayoutColumns[i].text;
          let controlProps = noLayoutColumns[i + 1];
          that.setOneLableControl(controlProps, controlLabel);
          formControls.push(controlProps);
          formRows = formRows.concat(formControls);
        }
      }
      return {
        formRows: formRows
      };
    }
  }
};
