<template>
  <div>
    <div v-if="buttons && buttons.length > 0" class="form-headButton">
      <!-- 授权操作按钮 -->
      <e-toolbar
        :buttons="buttons"
        :operationDisabled="false"
        @click="onButtonClick"
      ></e-toolbar>
    </div>
    <div class="body">
      <div class="tablebody">
        <a-table
          bordered
          :dataSource="tableDatas"
          :columns="columns"
          :rowSelection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange
          }"
        >
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法
import { getOption } from "./columnsSet.js";
export default {
  mixins: [analyticalMenuAuth, pageControls, requestControls],
  name: "mobileList",
  data() {
    return {
      tableDatas: [],
      tableSpin: true,
      columns: [],
      selectedRowKeys: [],
      selectNode: [],
      buttons: [],
      thisConfig: []
    };
  },
  props: {
    pageConfig: {
      type: Object
    }
  },
  watch: {
    // pageConfig: function(nDatas) {
    //   this.thisConfig = nDatas;
    // }
  },
  created() {
    this.columns = getOption();
    this.init();
  },
  mounted() {
    // this.thisConfig = this.pageConfig;
    // this.buttons = this.getUserJurisdictionButtons(this.thisConfig);
  },
  methods: {
    init() {
      this.getTableData(1);
      var _this = this;
      let params = {
        action: "GetBtnByMobile",
        randomKey: this.$store.getters.getRandomKey
      };
      this.get("System.ashx", params, {
        success: function(res) {
          if (res.success) {
            var data = res.data;
            _this.buttons = data;
          }
        },
        error: function() {}
      });
    },
    getTableData(index) {
      var _this = this;
      let params = {
        action: "GetFormTemplateList",
        // randomKey: this.$store.getters.getRandomKey,
        page: index,
        pageSize: 10
      };
      this.get("System.ashx", params, {
        success: function(res) {
          if (res.success) {
            var data = res.data;
            _this.tableDatas = data;
          }
        },
        error: function() {}
      });
    },
    /**
     * 选中行
     * @param selectedRowKeys
     */
    onSelectChange(selectedRowKeys, node) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
      this.selectNode = node;
    },
    tsbNew() {
      this.openTab({
        title: "新增-移动端表单配置",
        path: "/mobileBuilder/new",
        query: {}
      });
    },
    tsbEdit() {
      if (this.selectNode.length != 1) {
        this.$message.error("请选择一条数据");
        return;
      }
      this.openTab({
        title: "编辑-" + this.selectNode[0].FormTemplateName,
        path: "/mobileBuilder/" + this.selectNode[0].ID,
        query: {}
      });
    },
    tsbDelete() {
      if (this.selectNode.length != 1) {
        this.$message.error("请选择一条数据");
        return;
      }
      var _this = this;
      let params = {
        action: "DeleteFormTemplate",
        // randomKey: this.$store.getters.getRandomKey,
        formTemplateId: this.selectNode[0].ID
      };
      this.get("System.ashx", params, {
        success: function(res) {
          if (res.success) {
            _this.$message.success("删除成功");
            _this.init();
          }
        },
        error: function() {}
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/base/mobile-list.less";
</style>
