//公共上传附件
export const Upload = {
  data() {
    return {
      fileList: [],
      uploading: false,
      setValue: "",
      thisSetting: {
        serverName: "Form.ashx"
      },
      visible: false,
      previewVisible: false,
      previewImage: null
    };
  },
  watch: {},
  created() {},
  methods: {
    // 控制打开显示上传控件
    tsbUploadFile() {
      if (this.$refs.publicUpload) {
        this.$refs.publicUpload.visible = true;
      }
    },
    // 控件值改变时方法
    publicUploadChangeValue(v) {
      this.form.setFieldsValue({ _Attachments: v });
    },
    // 接受到值方法
    publicUploadChange(v) {
      if (this.$refs.publicUpload) {
        this.$refs.publicUpload.defaultList(v);
      }
    },
    // 接受到文件数变化时的方法
    publicUploadNumChange(v) {
      if (this.$refs.eToolbar) {
        this.$refs.eToolbar.remindSet("tsbUploadFile", v);
      }
    },
    CanSealFile(v) {
      if (this.$refs.eToolbar) {
        this.$refs.eToolbar.disabledButtonMapSet("tsbUploadFile", v);
      }
    }
  }
};
