const setObj = [
  {
    title: "表单定义编码",
    dataIndex: "FormTemplateCode"
  },
  {
    title: "表单定义名称",
    dataIndex: "FormTemplateName"
  },
  {
    title: "表单定义内容",
    dataIndex: "FormTemplateContent"
  },
  {
    title: "绑定表单",
    dataIndex: "RelativeFormTemplateID"
  },
  {
    title: "创建者",
    dataIndex: "Creator"
  },
  {
    title: "创建时间",
    dataIndex: "CreateTime"
  },
  {
    title: "最后修改人",
    dataIndex: "Reviser"
  },
  {
    title: "最后修改时间",
    dataIndex: "ReviseTime"
  }
];

const addData = [
  {
    controlType: "input",
    field: "",
    defaultValue: "",
    label: "",
    readonly: false,
    disabled: false,
    validate: ""
  }
];

function getOption() {
  return [...setObj];
}

function getAddData() {
  return [...addData];
}

export { getOption, getAddData };
