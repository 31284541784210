<!-- 表单冲销 -->
<template>
  <div>
    <!-- 授权操作按钮 -->
    <div v-if="showToolbar" class="form-headButton">
      <e-toolbar
        :buttons="buttons"
        :operationDisabled="operationDisabled"
        :size="$config.defaultControlSize"
        @click="onButtonClick"
        ref="eToolbar"
      ></e-toolbar>
    </div>
    <!-- 表单区域 -->
    <div
      :class="getTabFormContextClass"
      @scroll="formScroll"
      :style="isZoom ? { 'padding-top': '0' } : {}"
    >
      <a-form
        :form="form"
        :class="showToolbar ? 'form-data' : 'form-data-noHead'"
      >
        <e-form
          :form="form"
          :dataId="dataId"
          :tempId="tempId"
          :spState="nowShowContent"
          :disable="formDisable"
          :mxCanbeEdited="false"
          :formConfigs="formConfigs"
          mxButtonShow
          :title="title"
          :tableData="mainTableData"
          :mxOprButtons="mxOprButtons"
          :fieldsAllowedEdit="fieldsAllowedEdit"
          :mxAllowedEditField="mxAllowedEditField"
          :mxReadOnlyFields="mxReadOnlyFields"
          :requiredField="requiredField"
          :hideFields="hideField"
          :mxHhAuto="mxChangeHh"
          ref="formTemplate"
          @zoom="zoomState => (isZoom = zoomState)"
          @publicUploadChange="publicUploadChange"
        ></e-form>
      </a-form>
    </div>
    <!-- 其他功能区域 -->
    <!-- 审批意见确认窗 <a-icon type="form"></a-icon>-->
    <e-form-modal
      v-model="spModalShow"
      :buttons="spButtons"
      formName="审批意见表"
      @buttonClicked="doFormSp"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">表单审批</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 提交审批确认窗 <a-icon type="form"></a-icon>-->
    <e-form-modal
      v-model="tjModalShow"
      formName="提交审批"
      :initFormData="tjDefaultData"
      :showOk="true"
      @okClicked="doFormTj"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">提交审批</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 审批记录弹出框 -->
    <e-table-modal
      v-model="spjlModalShow"
      :tableConfig="spTableConfig"
      title="审批记录"
      width="900"
      :tableDatas="spTableDatas"
      :loading="spTableSpin"
    ></e-table-modal>
    <!-- 查看审批流程图 -->
    <view-image-modal
      v-model="showWorkFlowImage"
      :src="workflowImageSrc"
      title="流程图"
    ></view-image-modal>
    <!-- 小票打印预览 -->
    <n-p58-print
      v-model="showNpPrint"
      :dataSource="printDetailData"
    ></n-p58-print>
    <!-- 公共上传控件 -->
    <EUpload
      ref="publicUpload"
      @change="publicUploadChangeValue"
      @fileListNumChange="publicUploadNumChange"
      @CanSealFile="CanSealFile"
      v-show="false"
      :auth="
        operaterFile
          ? operaterFile
          : { CanEditFile: true, CanRemoveFile: true, CanSealFile: true }
      "
    />
  </div>
</template>

<script>
import EForm from "@/components/EForm.vue";

import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; // 解析菜单配置的权限字符串方法
import { e1Form } from "@/views/form/mixins/e1Form.js"; // 表单公共方法
import { e1FormToolbarWorkFlow } from "@/views/form/mixins/e1FormToolbarWorkFlow.js"; // e1的审批操作方法
import { npPrint } from "@/views/form/mixins/NpPrint.js"; // 下票打印方法
import { CustomPrintMixins } from "@/views/form/mixins/CustomPrintMixins.js"; // 按钮方法
import { Upload } from "@/views/form/mixins/Upload.js"; // 公共上传mixins

export default {
  mixins: [
    e1Form,
    analyticalMenuAuth,
    e1FormToolbarWorkFlow,
    npPrint,
    CustomPrintMixins,
    Upload
  ],
  name: "ChargeAgainstForm",
  components: {
    EForm
  },
  data() {
    return {
      formName: null, // 对应的表单名称

      random: null, // 当前显示的表单tab的key
      dhbm: null, // 当前开页面关联权限参数的id
      bm: null,
      etName: null, // 当前表单的etName参数
      bdbh: null, // 保存后的表单编号
      dataId: 0, //对应的表单id

      buttons: [], // 当前用户可用的操作按钮
      operationDisabled: true, // 默认加载成功前不能操作
      formDisable: true, // 表单有限可编辑
      fieldsAllowedEdit: null, // 可编辑的表单项，在formEditState =false 并且 formDisable=true时可用
      mxAllowedEditField: null, // 可编辑的明细表单项，在formEditState =false 并且 formDisable=true时可用
      mxReadOnlyFields: null, // 明细表只读字段
      requiredField: null, // 可编辑的表单项中必填项，包含主表单和明细表
      hideField: null, // 需要隐藏的字段，包含主表单和明细表的设置

      saveTempID: null, // 特殊表单，有时会指定保存使用的模板id
      is_temp_changed: false,// 特殊表单用于表示该表单模板变跟的状态

      mxOprButtons: [],
      isDelete: false,
      isZoom: false // 明细表进入最大化状态
    };
  },
  computed: {
    nowShowContent: function() {
      return "（冲销）";
    }
  },
  created() {
    console.log("路由属性：" + JSON.stringify(this.$route.query)); // 从菜单直接带过来
    console.log("ChargeAgainstForm创建---------------------->");
    this.tempId = this.$route.query.templetId;
    this.dataId = this.$route.query.dataId;
    this.bm = this.$route.query.bm;
    this.etName = this.$route.query.etName;
    this.random = this.$route.query.random;
    if (this.tempId && this.dataId && this.bm) {
      //通过query传参
      this.formName = "chargeAgainst-" + this.dataId;
      this.dhbm = this.$route.query.dhbm;
    } else {
      // 页面内按钮点开、是通过query传参
      this.alertError("错误的用户配置");
      this.loadingError();
      return;
    }
    this.fromState = 1; // 默认进来是草稿状态
    this.form = this.$form.createForm(this, { name: this.formName });
  },
  mounted() {
    //console.log("请求参数：" + JSON.stringify(this.$route.query));
    //console.log("创建表单："+this.$route.params.dhbm);
    console.log(
      "ChargeAgainstForm mounted---------------------->",
      this.tempId,
      this.dataId
    );
    this.loading();
    if (this.tempId && this.dataId) {
      this.mxChangeHh = false;
      this.getFormTemplate();
    } else {
      this.alertError("错误的用户配置");
      this.loadingError();
    }
  },
  methods: {
    /**
     * 获取表单模板
     */
    getFormTemplate() {
      let that = this;
      this.getForm(
        "GetTemplateToChongXiao",
        this.tempId,
        this.dataId,
        function(data) {
          that.workflowTemplateInstance =
            data.template[0].WorkflowTemplateInstance; // 流程图id
          that.buttons = data.buttons;
          that.mxOprButtons = data.rowButtons;
        },
        function() {
          if (that.thisFromEvents && that.thisFromEvents.afterInit) {
            that.thisFromEvents.afterInit(that);
          }
          // 模板获取成功后,需要去获取数据
          if (that.dataId)
            that.getFormData(
              that.tempId,
              that.dataId,
              that.bm,
              that.etName,
              function(data) {
                that.bdbh = that.mainTableData._BDBH;
                let workFlows = data.workFlow;
                if (workFlows) {
                  that.formEditState = false; //表单可编辑
                  that.formDisable = true; //未提交 表单可编辑
                  that.beforeSpOptNeedSave = true; // 如果直接审批，需要先保存

                  //如果设置了隐藏字段,并且流程未结束
                  if (workFlows.HideField && that.isFormWorkflowEnd == false) {
                    that.hideField = workFlows.HideField;
                  }

                  // 判断是否配置可编辑项
                  if (
                    !that.formEditState &&
                    (workFlows.EditableField || workFlows.MustInputField)
                  ) {
                    // 有可操作项
                    if (workFlows.EditableField) {
                      console.log(
                        "有可以编辑的字段：",
                        workFlows.EditableField
                      );
                      if (
                        workFlows.EditableField.mainTableField &&
                        workFlows.EditableField.mainTableField.length > 0
                      ) {
                        that.fieldsAllowedEdit =
                          workFlows.EditableField.mainTableField;
                      }
                      if (
                        workFlows.EditableField.chrenTableField &&
                        that.$util.isValidObject(
                          workFlows.EditableField.chrenTableField
                        )
                      ) {
                        that.mxAllowedEditField =
                          workFlows.EditableField.chrenTableField;
                      }
                    }
                    // 如果有设置必填字段
                    if (workFlows.MustInputField)
                      that.requiredField = workFlows.MustInputField;
                  }
                }
                that.loadingOk();
              },
              function() {
                that.closeTab(that.random);
              }
            );
          else that.loadingOk();
        }
      );
    },
    /**
     * 点击保存按钮
     * @param button
     */
    tsbSave(button) {
      if (!this.dataId) {
        this.alertWarning("表单ID不存在");
        return;
      }
      let that = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          //console.log("数据信息：" + JSON.stringify(values));
          let saveParam = that.checkOperationAuth(
            that.buttons,
            "tsbSave",
            () => {
              that.alertError("您没有保存表单的权限");
            }
          );
          if (saveParam) {
            that.startProcessing(button);
            that.allChildrenTablesDatas = that.getMxTableData(); // 保存明细缓存
            // 保存前检测，明细是否满足非空
            let errMessage = that.checkDetailRequired(
              that.formConfig.mxTableRequiredConfig,
              that.allChildrenTablesDatas
            );
            if (errMessage) {
              that.alertError(errMessage);
              return;
            }
            let params = {
              action: "SaveFormData",
              data: JSON.stringify(values), // 主表数据
              children: that.getMxDataJSON(), // 子表数据
              tb_name: that.mainTableName, // 主表名称 从DHCS中获取
              mxb_name: that.mxTableName.toString(), // 明细表名称
              bm: saveParam.param.bm, // 类库名称（SYSTEM）、从GJLCS中获取 {tsbNew,SYSTEM,1,980}
              t_id: that.tempId, // 模板id  从DHCS中获取
              et_name: saveParam.param.et_name, //  表示操作类型  添加为0，修改为1，删除为2 等，从GJLCS中获取 {tsbNew,SYSTEM,1,980}
              m_id: "冲销", // 保存时没用到 传入0
              d_id: that.dataId, // 插入之后返回的主键id
              randomKey: that.$store.getters.getRandomKey
            };
            that.post("Form.ashx", params, {
              success: function(req) {
                //console.log("请求结果", req);
                if (req.data.dataId) {
                  that.dataId = req.data.dataId;
                  if (
                    req.data.mainTableData &&
                    that.$util.isType(req.data.mainTableData) === "Object"
                  ) {
                    values = Object.assign({}, values, req.data.mainTableData);
                  }
                  that.mainTableData = values;
                  let mc = that.mainTableData._BDMC
                    ? that.mainTableData._BDMC
                    : that.title;
                  let zt = mc + "_" + that.mainTableData._BDBH;
                  that.updatePageData("tjDefaultData", "_zt", zt);
                }
                // 返回了明细数据,需要回填
                if (
                  req.data.childrenDatas &&
                  req.data.childrenDatas.length > 0
                ) {
                  that.setMxTableData(req.data.childrenDatas);
                  that.allChildrenTablesDatas = req.data.childrenDatas;
                }
                if (
                  req.data.workFlow &&
                  req.data.workFlow.isWorkFlow &&
                  that.fromState == 1
                ) {
                  that.updateEToolbarButtonDisplay("tsbWorkFlowCreater");
                }
                that.alertSuccess("保存成功");
                that.finishedProcessing(button);
              },
              error: function(er) {
                console.error("请求失败", er);
                that.alertError(er.message);
                that.finishedProcessing(button);
              }
            });
          }
        } else {
          //console.log( JSON.stringify(err));
          let errMsg = that.getDecoratorErrInfo(err);
          that.tipWarning(errMsg);
        }
      });
    },
    /**
     * 点击"取消"或“放弃”按钮
     */
    tsbCancel() {
      this.closeTab(this.random);
    },
    /**
     * 点击“删除”按钮，删除当前表单
     * @param button
     * @param other
     */
    tsbDelete(button, other) {
      //console.log("删除", button);
      if (this.dataId) {
        if (other && other.prioriCondition) {
          // 满足前置条件还需要确认是否操作
          this.$refs.eToolbar.showConfirm(button.tsbName);
        } else {
          let that = this;
          let params = {
            action: "DeleteData",
            tb_name: that.mainTableName, //主表名
            mxb_name: that.mxTableName.toString(), // 明细表名称
            bm: button.param.bm,
            t_id: this.tempId, // 模板id  从DHCS中获取
            d_id: this.dataId, // 插入之后返回的主键id
            randomKey: this.$store.getters.getRandomKey
          };
          this.startProcessing(button);
          this.post("Form.ashx", params, {
            success: function() {
              //console.log("请求结果", req);
              // 把对应删除的数据从数组中移除
              that.closeTab(that.random);
              that.tipSuccess("删除成功");
              that.finishedProcessing(button);
            },
            error: function(err) {
              that.alertError(err.message);
              that.finishedProcessing(button);
            }
          });
        }
      }
    },
    /**
     * 监听表单滚动条，如果滚动做一些操作
     */
    formScroll() {
      this.$refs.formTemplate.filterSelectBlur();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/charge-against-form.less";
</style>
