<!-- 用户本身发出的表单、以及他人发出需要本人审批、或查看的表单记录列表 -->
<template>
  <div style="height: 100%;">
    <div v-if="showToolbar" class="form-headButton">
      <!-- 授权操作按钮 -->
      <e-toolbar
        :buttons="buttons"
        :size="$config.defaultControlSize"
        :operationDisabled="operationDisabled"
        @click="onButtonClick"
      ></e-toolbar>
    </div>
    <div class="tab-view-context">
      <!-- 表单头 -->
      <e-head-title
        :title="title"
        :size="$config.defaultControlSize"
      ></e-head-title>
      <div class="view-context">
        <div class="view-table-filter">
          <e-table-content-filter
            ref="screenViewTable"
            @screen="
              data => {
                tableDatas = data;
              }
            "
            :proxy="true"
            :columns="thisColumns"
            :dataSource="tableDatas"
            :disabled="tableSpin"
            @proxy="searchByKey"
          ></e-table-content-filter>
        </div>
        <div class="view-table" :class="getSizeClass">
          <vxe-grid
            ref="tableRef"
            show-overflow
            :stripe="$config.table_stripe"
            :tooltip-config="vxeTabletooltipConfig"
            border
            resizable
            highlight-hover-row
            highlight-current-row
            :empty-render="vxeTableEmptyRender"
            :size="tableSize_MiXin"
            header-row-class-name="e-table-header_style"
            header-cell-class-name="e-table-header-cell_style"
            row-class-name="e-table-row_style"
            maxHeight="100%"
            height="auto"
            :loading="tableSpin"
            :data="tableDatas"
            @sort-change="onSortChange"
          >
            <template v-for="col in thisColumns">
              <vxe-table-column
                v-if="col.scopedSlots"
                :key="col.field"
                v-bind="col"
              >
                <!--如果定义了字段格式，则按照格式进行渲染-->
                <template slot-scope="slotProps">
                  <e-table-colum
                    v-if="col.scopedSlots && col.scopedSlots.clickMethod"
                    :column="col"
                    :slotScopeData="slotProps.row"
                    @onClick="
                      col.scopedSlots.clickMethod({ record: slotProps.row })
                    "
                  ></e-table-colum>
                  <e-table-colum
                    v-else
                    :column="col"
                    :slotScopeData="slotProps.row"
                  ></e-table-colum>
                </template>
              </vxe-table-column>
              <vxe-table-column
                v-else
                :key="col.field"
                v-bind="col"
              ></vxe-table-column>
            </template>
          </vxe-grid>
          <vxe-pager
            class="e-table-page"
            ref="vxePagerRef"
            background
            :size="tableSize_MiXin"
            :loading="tableSpin"
            :current-page="pagination.current"
            :page-size="pagination.pageSize"
            :page-sizes="pagination.pageSizes"
            :total="pagination.total"
            :layouts="pagination.layouts"
            @page-change="onTableChange"
          >
          </vxe-pager>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ETableColum from "@/components/ETableColum";
import { pageControls } from "@/mixins/pageControls.js"; // 引入一些界面操作方法
import { pageDestroy } from "@/mixins/pageDestroy.js"; // 页面关闭清除缓存的方法
import { e1Notice } from "@/views/notice/mixins/e1Notice.js"; // e1的notice配置和操作
//页面初始化时执行方法
export default {
  mixins: [e1Notice, pageDestroy, pageControls],
  components: {
    ETableColum
  },
  name: "Notice",
  data() {
    return {
      noticeType: null,
      title: "",
      thisColumns: [],
      selectedRowKeys: [],
      rowKey: "ID", // 主键列
      operationDisabled: false,
      buttons: [
        {
          // 可用操作按钮
          name: "刷新",
          icon: "icon-refresh",
          tsbName: "tsbRefresh",
          type: "primary",
          loading: false
        }
      ],
      searchField: "",
      searchStr: ""
    };
  },
  props: {
    notice: {
      type: [String, Number],
      default: null
    },
    oEvent: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: null
    }
  },
  watch: {
    tableSpin: function(v) {
      if (!v) {
        // 数据加载完成后，将滚动条拉到顶部
        //console.log("数据加载条变化", v, this.$refs.tableRef);
        this.$refs.tableRef.scrollTo(0, 0);
      }
    }
  },
  computed: {
    getSizeClass: function() {
      let classes = [];
      if (this.$config.defaultControlSize) {
        if (this.$config.defaultControlSize === "small") {
          classes.push("view-table__small");
        } else if (this.$config.defaultControlSize === "default") {
          classes.push("view-table__default");
        } else if (this.$config.defaultControlSize === "large") {
          classes.push("view-table__large");
        }
      } else {
        classes.push("view-table__default");
      }
      if (this.showToolbar) {
        classes.push("view-table__toolbar");
      }
      return classes;
    },
    showToolbar: function() {
      return !this.isNewTheme && this.checkToobleIsShow(this.buttons);
    }
  },
  created() {
    //console.log("请求参数：" + JSON.stringify(this.$route.query));
    //console.log("路由属性：" + JSON.stringify(this.$route.params));
    if (!this.showToolbar) {
      this.hideToolBar();
    }
    this.title = this.$route.params.title;
    let _dhbm = this.$route.params.dhbm;
    if (_dhbm) {
      //console.log("菜单编码", _dhbm);
      this.noticeType = _dhbm;
    } else {
      this.noticeType = this.notice;
      this.title = this.name;
    }
    if (this.haveSFCKType.indexOf(this.noticeType) >= 0) {
      // 包含状态字段的表单消息列表
      //console.log("菜单编码", this.noticeType);
      this.tableConfig.columns.splice(0, 0, {
        title: "状态",
        resizable: false,
        fixed: "left",
        field: "SFCK",
        width: 100,
        align: "center",
        sortable: true, // 列是否可以排序
        remoteSort: true, // 去后端接口排序
        scopedSlots: {
          color: text => {
            if (text && text === "已阅") return "";
            else {
              return "red";
            }
          },
          leftIcon: text => {
            if (text && text === "已阅") return "icon-yiduxinxi";
            else {
              return "icon-weiduxinxi";
            }
          },
          customRenderText: text => {
            if (text && text === "已阅") return text;
            else {
              return "未读";
            }
          }
        },
        slots: { default: "SFCK" }
      });
    }
    //原稿-已处理增加新的列
    if (this.noticeType === "277") {
      this.tableConfig.columns.push(
        {
          title: "流转关卡审批人",
          resizable: false,
          field: "_NOWJSRXM",
          width: 150,
          align: "center",
          sortable: true, // 列是否可以排序
          remoteSort: true, // 去后端接口排序
          slots: { default: "_NOWJSRXM" }
        },
        {
          title: "流转状态",
          resizable: false,
          field: "_NOWZT",
          width: 120,
          align: "center",
          sortable: true, // 列是否可以排序
          remoteSort: true, // 去后端接口排序
          scopedSlots: {
            color: text => {
              if (text && text === "审批完成") return "";
              else {
                return "red";
              }
            }
          },
          slots: { default: "_NOWZT" }
        }
      );
    }
    this.thisColumns = this.tableConfig.columns;
  },
  mounted() {},
  activated() {
    // 显示本页的时候，重新加载数据
    //console.log("激活notice",this.noticeType);
    if (this.noticeType != null) {
      if (this.tableConfig) {
        this.rowKey = this.tableConfig.rowKey;
        this.getTableDatas(
          this.noticeType,
          null,
          this.oEvent,
          this.pagination.current,
          this.pagination.pageSize,
          this.searchStr,
          this.searchField
        );
      } else {
        this.alertError("请先配置表格项");
      }
    }
  },
  methods: {
    /**
     * 触发排序事件
     * @param event
     */
    onSortChange(event) {
      console.log("触发排序事件", event);
      this.pagination.current = 1;
      if (event && event.field && event.order) {
        //event.field; 参与排序的字段;event.order;排序方式 "desc"
        this.orderBy = event.field + " " + event.order;
        this.getTableDatas(
          this.noticeType,
          null,
          this.oEvent,
          this.pagination.current,
          this.pagination.pageSize,
          this.searchStr,
          this.searchField,
          this.orderBy
        );
      } else {
        // 取消排序
        this.orderBy = null;
        this.getTableDatas(
          this.noticeType,
          null,
          this.oEvent,
          this.pagination.current,
          this.pagination.pageSize,
          this.searchStr,
          this.searchField
        );
      }
    },
    /**
     * 刷新列表
     * @param button
     */
    tsbRefresh(button) {
      this.orderBy = null;
      this.getTableDatas(
        this.noticeType,
        button,
        this.oEvent,
        this.pagination.current,
        this.pagination.pageSize,
        this.searchStr,
        this.searchField,
        this.orderBy
      );
      //this.reload();
    },
    /**
     * 响应表格变化事件，包括翻页，排序等
     * @param pagination
     */
    onTableChange(pagination) {
      //console.log(pagination);
      let that = this;
      this.onTableChangeMiXin(pagination, function() {
        that.selectedRowKeys = [];
        if (pagination.pageSize) {
          that.getTableDatas(
            that.noticeType,
            null,
            that.oEvent,
            that.pagination.current,
            that.pagination.pageSize,
            that.searchStr,
            that.searchField,
            that.orderBy
          );
        } else {
          that.getTableDatas(
            that.noticeType,
            null,
            that.oEvent,
            null,
            null,
            that.searchStr,
            that.searchField,
            that.orderBy
          );
        }
      });
    },
    /**
     * 根据条件进行查询
     */
    searchByKey(value, filed, edit) {
      if ((filed && filed !== "") || edit) {
        this.searchStr = value;
        this.searchField = filed;
        this.pagination.current = 1;
        this.getTableDatas(
          this.noticeType,
          null,
          this.oEvent,
          this.pagination.current,
          this.pagination.pageSize,
          this.searchStr,
          this.searchField,
          this.orderBy
        );
      } else if (!edit) {
        this.tipWarning("请选择筛选项");
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/notice.less";
</style>
