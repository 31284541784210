<!--  -->
<template>
  <div>
    <div class="clearfix">
      <a-button
        @click="visible = true"
        :size="$config.defaultControlSize"
        ref="uploadButton"
        :disabled="value.length === 0"
        >查看文件</a-button
      >
      <template>
        <a-modal
          title="文件预览"
          :visible="visible"
          wrapClassName="upload-modal"
          @cancel="visible = false"
          width="80%"
        >
          <template slot="footer">
            <a-button key="back" @click="visible = false">关闭</a-button>
          </template>

          <div class="clearfix">
            <div class="title"><a-icon type="picture" />图片</div>
            <div class="img-class">
              <!-- :images="getUploadUrl(fileList['img'])"  -->
              <viewer v-if="fileList['img'] != ''">
                <img
                  v-for="(src, index) in getUploadUrl(fileList['img'])"
                  :src="src.url"
                  :key="'img' + index"
                  class="upload-img"
                />
              </viewer>
            </div>
            <div class="title"><a-icon type="folder-open" />其他文件</div>
            <div>
              <div
                v-for="(a, index) in getUploadUrl(fileList['other'])"
                :key="'other' + index"
              >
                <a target="_blank" :href="a.url">{{ a.name }}</a>
              </div>
            </div>
          </div>
        </a-modal>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: {},
      visible: false,
      imgExt: ["png", "jpg", "jpeg", "bmp", "gif"],
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {},
  computed: {},
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.setData(this.value);
  },
  methods: {
    setData(data) {
      if (data == "" || data == null) {
        return;
      }

      var imgFile = [];
      var otherFile = [];

      var isJson = true;
      try {
        // 检查是否是json
        data = JSON.parse(data);
      } catch (err) {
        err;
        isJson = false;
      }

      if (isJson) {
        for (var i = 0; i < data.length; i++) {
          var fileExtension = data[i]["n"].substring(
            data[i]["n"].lastIndexOf(".") + 1
          );
          if (this.imgExt.indexOf(fileExtension) != -1) {
            imgFile.push(data[i]);
          } else {
            otherFile.push(data[i]);
          }
        }
      } else {
        data = data.split(",");
        data.forEach((element) => {
          var name = element.split("/");
          name = element.substring(element.lastIndexOf(".") + 1);
          if (this.imgExt.indexOf(name) != -1) {
            imgFile.push(element);
          } else {
            otherFile.push(element);
          }
        });
      }

      this.$set(this.fileList, "img", imgFile);
      this.$set(this.fileList, "other", otherFile);
    },
    // 获得上传文件的url
    getUploadUrl(data) {
      // var html = "";
      if (data == null || data == "") {
        return [];
      }

      var returnData = [];

      var isJson = true;
      if (typeof data != "object") {
        try {
          data = JSON.parse(data);
        } catch (err) {
          err;
          isJson = false;
        }
      }

      if (isJson) {
        for (var j = 0; j < data.length; j++) {
          returnData.push(checkUrl(data[j]["p"] ? data[j]["p"] : data[j]));
        }
      } else {
        data = data.split(",");
        for (var i = 0; i < data.length; i++) {
          returnData.push(checkUrl(data[i]));
        }
      }
      return returnData;

      function checkUrl(url) {
        try {
          var file = url.split("/");
          file = file[file.length - 1];
        } catch {
          var file = url
        }

        if (url.indexOf("http://") == -1 || url.indexOf("https://") == -1) {
          url = window.UPLOAD_URL + url;
        }

        var json = {
          url: url,
          name: file,
        };

        return json;
      }
    },
  },
  watch: {
    value(v) {
      this.setData(v);
    },
  },
};
</script>
<style scoped lang="less">
@import "../assets/style/base/components/e-file-preview.less";
</style>
