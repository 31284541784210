// 页面打开弹窗，跳转打开tab的控制公共方法,只有在index中打开的vue页面可以引入本文件
import { cacheControls } from "@/mixins/cacheControls.js"; // tab缓存数据处理方法
import { systemMethods } from "@/mixins/systemMethods.js"; // tab缓存数据处理方法
import Vue from "vue/dist/vue.esm.js";

export const pageControls = {
  mixins: [cacheControls, systemMethods],
  data() {
    return {
      operationDisabled: true, // 默认加载成功前工具栏不能操作
      pagination: {
        current: 1,
        pageSize: this.$config.defaultPageSize,
        pageSizes: this.$config.vxeDefaultPageSizeOptions, // ?
        layouts: [
          "Total",
          "PrevJump",
          "PrevPage",
          "Number",
          "NextPage",
          "NextJump",
          "FullJump",
          "Sizes"
        ], // ?
        showSizeChanger: true,
        /*showTotal: function(total) {
          return "总共" + total + "条";
        },*/
        total: 0,
        showLessItems: true,
        pageSizeOptions: this.$config.defaultPageSizeOptions,
        onShowSizeChange: this.onShowSizeChangeMiXin //(current, pageSize) => (this.current = 1)
      },
      setPageCurrentToOne_Mixin: false, // 设置显示当前第1页
      vxeTabletooltipConfig: {
        enterable: true,
        leaveDelay: 3000
      },
      vxeTableEmptyRender: { name: "NotData" }
    };
  },
  inject: [
    "openTab",
    "redirect",
    "reload",
    "closeTab",
    "closeNowTab",
    "getNowTabKey",
    "hideToolBar",
    "getWindowSize",
    "hiddenScrollY",
    "showScrollY",
    "scrollToBottom",
    "lodopInit",
    "lodopPrint",
    "lodopRenderingPrint",
    "lodopPrintTemplateDesign",
    "showAlert"
  ], // 引用主页定义的页面操作方法
  computed: {
    tableSize_MiXin: function() {
      if (this.$config.defaultControlSize) return this.getTableSize_Mixin();
      else return "small";
    },
    aTableSize_MiXin: function() {
      if (this.$config.defaultControlSize) return this.getATableSize_Mixin();
      else return "middle";
    },
    /**
     * 是否是新主题
     * @return {string|boolean}
     */
    isNewTheme: function() {
      return window.SYS_THEME_NAME && window.SYS_THEME_NAME === "ruifu";
    }
  },
  created() {
    // console.log("混入pageControls的通用方法");e-table-page
  },
  mounted() {
    let that = this;
    this.$nextTick(function() {
      if (that.$refs.vxePagerRef) {
        let pageSizes = that.$refs.vxePagerRef.$el.querySelectorAll(
          ".vxe-pager--sizes"
        )[0];
        let sizeOption = pageSizes.querySelectorAll(".vxe-dropdown--panel")[0];
        if (sizeOption) {
          let options = sizeOption.querySelectorAll(".vxe-select-option");
          if (options.length > 0) {
            let lastOption = options[options.length - 1];
            let innerHtml = lastOption.innerHTML;
            if (innerHtml === "Infinity条/页") {
              lastOption.innerHTML = "全部";
            }
            //console.log("pageInput=", lastOption);
          }
        }
      }
    });
  },
  methods: {
    /**
     * 给操作工具栏指定按钮增加下拉菜单选项
     * @param button
     * @param menuList
     * @param clickAutoOne
     */
    toolbarButtonAddMenu: function(button, menuList, clickAutoOne) {
      button.menuList = menuList;
      button.menuVisible = true;
      button.oldIcon = button.icon;
      button.clickAutoOne = clickAutoOne;
    },
    /**
     * 根据配置的全局尺寸参数，转换为a-table的尺寸
     */
    getATableSize_Mixin: function() {
      if (this.$config.defaultControlSize) {
        if (this.$config.defaultControlSize === "small") {
          return "small";
        } else if (this.$config.defaultControlSize === "default") {
          return "middle";
        } else if (this.$config.defaultControlSize === "large") {
          return "default";
        }
      }
      return "middle";
    },
    /**
     * 根据配置的全局尺寸参数，转换为vxe-table的尺寸
     */
    getTableSize_Mixin: function() {
      if (this.$config.defaultControlSize) {
        if (this.$config.defaultControlSize === "small") {
          return "mini";
        } else if (this.$config.defaultControlSize === "default") {
          return "small";
        } else if (this.$config.defaultControlSize === "large") {
          return "medium";
        }
      }
      return "small";
    },
    /**
     * 获取对应size的class的扩展类名
     * @param name
     * @param showToolber
     * @returns {string}
     */
    getClassNameBySize: function(name, showToolber) {
      if (this.$config.defaultControlSize && showToolber) {
        if (this.$config.defaultControlSize === "small") {
          return name + "__small";
        } else if (this.$config.defaultControlSize === "large") {
          return name + "__large";
        } else {
          return name + "__default";
        }
      } else if (showToolber) {
        //
        return name + "__default";
      } else {
        return name + "__noneToolbar";
      }
    },
    /**
     * 响应分页控件的当前每页记录数量变化事件
     * @param current
     * @param pageSize
     */
    onShowSizeChangeMiXin: function(current, pageSize) {
      console.log("表格每页数量改变", current, pageSize);
      this.pagination.current = 1; // 设置当前查询第一页
      this.setPageCurrentToOne_Mixin = true;
    },
    /**
     * 处理表格变化事件，包括翻页，排序等变化的公共处理方法
     * @param pagination
     * @param callBack
     */
    onTableChangeMiXin: function(pagination, callBack) {
      if (pagination.pageSize) {
        if (!this.setPageCurrentToOne_Mixin) {
          // 是否改变了每页数据条数，不是则需要响应当前页变化
          this.pagination.current = pagination.current
            ? pagination.current
            : pagination.currentPage;
        } else {
          this.setPageCurrentToOne_Mixin = false;
        }
        this.pagination.pageSize = pagination.pageSize;
      } else {
        // 如果pagination.pageSize没有则表示查询的是所有数据，不分页
        this.pagination.current = 1;
        this.pagination.pageSize = Infinity;
      }
      callBack();
    },
    /**
     * 对应操作按钮操作结束，需要改变按钮状态，和工具条的全局状态
     * @param button
     */
    finishedProcessing: function(button) {
      if (button) button.loading = false;
      if (
        button &&
        button.resolve &&
        this.$util.isType(button.resolve) === "Function"
      )
        button.resolve();
      this.operationDisabled = false;
    },
    /**
     * 对应操作按钮操作开始，需要改变按钮状态，和工具条的全局状态
     * @param button
     */
    startProcessing: function(button) {
      if (button) button.loading = true;
      this.operationDisabled = true;
    },
    /**
     * 普通信息提示框
     * @param msg
     */
    alert: function(msg) {
      const h = this.$createElement;
      msg = "<div>" + msg + "</div>";
      const com = Vue.extend({
        template: msg
      });
      this.$info({
        title: "提示",
        content: h(com, {}),
        onOk() {}
      });
    },
    getDom: function(html) {
      if (html) {
        const h = this.$createElement;
        const com = Vue.extend({
          template: html
        });
        return h(com, {});
      }
    },
    /**
     * 警告提示
     * @param msg
     */
    alertWarning: function(msg) {
      const h = this.$createElement;
      msg = "<div>" + msg + "</div>";
      const com = Vue.extend({
        template: msg
      });
      this.$warning({
        title: "提醒",
        content: h(com, {})
      });
    },
    /**
     * 错误提示
     * @param msg
     * @param callBack 点击确定后的回调方法
     */
    alertError: function(msg, callBack) {
      const h = this.$createElement;
      msg = "<div>" + msg + "</div>";
      const com = Vue.extend({
        template: msg
      });
      if (callBack)
        this.$error({
          title: "错误",
          content: h(com, {}),
          onOk: callBack
        });
      else
        this.$error({
          title: "错误",
          content: h(com, {})
        });
    },
    /**
     * 成功提示
     * @param msg
     * @param onOk
     */
    alertSuccess: function(msg, onOk) {
      const h = this.$createElement;
      msg = "<div>" + msg + "</div>";
      const com = Vue.extend({
        template: msg
      });
      this.$success({
        title: "提示",
        content: h(com, {}),
        onOk: onOk
      });
    },
    /**
     * 错误message
     * @param msg
     */
    tipSuccess: function(msg) {
      this.$message.success(msg);
    },
    /**
     * 错误message
     * @param msg
     */
    tipError: function(msg) {
      this.$message.error(msg);
    },
    /**
     * 警告message
     * @param msg
     */
    tipWarning: function(msg) {
      this.$message.warning(msg);
    },
    /**
     * 普通message
     * @param msg
     */
    tipInfoMessage: function(msg) {
      this.$message.info(msg);
    },
    /**
     * 修改指定按钮的显示状态
     * @param button
     * @param display 不传该参数则对改按钮进行显示状态切换
     */
    updateEToolbarButtonDisplay(button, display) {
      if (this.$refs.eToolbar && button) {
        if (this.$util.isType(display) === "Boolean") {
          this.$refs.eToolbar.eToolbarButtonToggleDisplay(button, display);
        } else {
          this.$refs.eToolbar.eToolbarButtonToggleDisplay(button);
        }
      }
    },
    /**
     * 从v-decorator校验返回的错误信息中获取第一个报错信息
     * @param err
     * @returns {*}
     */
    getDecoratorErrInfo: function(err) {
      //console.log(JSON.stringify(err));
      if (err && this.$util.isType(err) === "Object") {
        // 主表单错误信息
        let paramKeys = Object.keys(err);
        let filedName = paramKeys[0];
        let thisError = err[filedName].errors.find(function(errobj) {
          return errobj.field === filedName;
        });
        if (thisError) return thisError.message;
      } else if (err && this.$util.isType(err) === "Array") {
        // 明细表错误信息
        let errorObject = err[0];
        if (errorObject.errors.length > 0) {
          // 取第一个错误信息
          let errObject = errorObject.errors[0].error;
          let paramKeys = Object.keys(errObject);
          let filedName = paramKeys[0];
          return errObject[filedName].message;
        }
        return "明细表有错误数据";
      }
    },
    /**
     * 获取表单的名称标识
     * @param bdObj
     * @param thisColumns
     * @returns {*}
     */
    getBDObjectMcInfo: function(bdObj, thisColumns) {
      let titleFiedName =
        thisColumns != undefined && thisColumns != null
          ? thisColumns[1].key
          : "_BDMC";
      if (bdObj[titleFiedName]) {
        return bdObj[titleFiedName];
      } else if (bdObj._BDMC) {
        return bdObj._BDMC;
      } else if (bdObj.BDMC) {
        return bdObj.BDMC;
      } else if (bdObj._BDBH) {
        return bdObj._BDBH;
      } else if (bdObj.BDBH) {
        return bdObj.BDBH;
      } else if (bdObj.RYXM) {
        return bdObj.RYXM;
      } else if (bdObj._WZMC) {
        return bdObj._WZMC;
      } else if (bdObj._GYSMC) {
        return bdObj._GYSMC;
      }
      return null;
    },
    /**
     * 监测工具条是否可见
     * @param buttons
     * @returns {boolean}
     */
    checkToobleIsShow: function(buttons) {
      if (buttons) {
        let index = buttons.findIndex(function(d) {
          return d.tsbName != undefined && d.tsbName !== "getData";
        });
        return index >= 0;
      }
      return false;
    },
    /**
     * 获取视图中的链接扩展方法
     * @param methedName
     */
    getViewLinkExtends_Mixin: function(methedName) {
      if(this.ViewLinkExtends &&  methedName) {
        if(this.ViewLinkExtends[methedName]) {
          return this.ViewLinkExtends[methedName];
        }
      }
      return null;
    },
    /**
     * 获取表单的扩展属性
     * @param thisFromEventName 保存表单扩展属性的本地对象名称
     * @param tempId 模板id
     * @param templateName 模板名称
     * @param type 大于0:或true获取表单控件扩展属性，否则就是获取表单扩展功能
     */
    getFormExtends_Mixin: function(
      thisFromEventName,
      tempId,
      templateName,
      type
    ) {
      if (this.FormExtends) {
        let getType = type ? 1 : 0;
        // 如果有扩展属性
        let thisFormExtends = tempId
          ? this.FormExtends["form_" + tempId]
          : null;
        if (!thisFormExtends && templateName) {
          thisFormExtends = this.FormExtends["form_" + templateName];
        }
        if (thisFormExtends && getType == 0 && thisFormExtends.formEvents) {
          // 获取表单扩展设置
          this[thisFromEventName] =Object.assign({}, thisFormExtends.formEvents);
          console.log("本表单有扩展功能", this[thisFromEventName]);
        } else if (
          thisFormExtends &&
          getType == 1 &&
          thisFormExtends.controlEvents
        ) {
          // 获取表单控件详细的扩展属性
          this[thisFromEventName] = Object.assign({}, thisFormExtends.controlEvents);
          console.log("本表单具体控件有扩展功能", this[thisFromEventName]);
        }
      }
    },
    /**
     * 将数据中的设置的主键字段合并为一个字符串，并增加_PrimaryKey作为主键，然后会返回对应所有的PrimaryKey值数组
     * @param dataList
     * @param checkPrimaryKey
     * @returns {Array}
     */
    setCheckPrimaryKey: function(dataList, checkPrimaryKey) {
      let allKey = [];
      for (let i = 0; i < dataList.length; i++) {
        let data = dataList[i];
        if (!data._PrimaryKey) {
          let key = "";
          let that = this;
          checkPrimaryKey.forEach(function(field, index) {
            if (index == 0 && data[field]) {
              key += that.getStringTrim(data[field]);
            } else if (data[field]) {
              key += "_" + that.getStringTrim(data[field]);
            } else {
              key += "_";
            }
          });
          data._PrimaryKey = key;
          allKey.push(key);
        } else {
          allKey.push(data._PrimaryKey);
        }
      }
      return allKey;
    },
    /**
     * 将是字符串格式的数据进行trim
     * @param data
     * @return {*}
     */
    getStringTrim: function(data) {
      if(this.$util.isType(data) === "String") {
        return this.$util.trimStr(data);
      }
      return data;
    },
    /**
     * 获取当前项目根路径
     * @return {String}
     */
    getRootPath : function(){
      //获取当前网址，如： http://localhost:8083/uimcardprj/share/meun.jsp
      let curWwwPath=window.document.location.href;
      //获取主机地址之后的目录，如： uimcardprj/share/meun.jsp
      let pathName=window.document.location.pathname;
      let pos=curWwwPath.indexOf(pathName);
      //获取主机地址，如： http://localhost:8083
      let localhostPaht=curWwwPath.substring(0,pos);
      //获取带"/"的项目名，如：/uimcardprj
      let projectName=pathName.substring(0,pathName.substr(1).indexOf('/')+1);
      return localhostPaht + projectName;
    },
    /**
     * 工具栏操作按钮点击响应
     * @param param
     */
    onButtonClick: function(param) {
      let index = -1,
        other;
      if (this.$util.isType(param) === "Object") {
        index = param.index;
        other = param;
      } else {
        index = param;
      }
      //console.log("点击了按钮：", this.buttons[index], param);
      if (this.buttons[index]) {
        if (this[this.buttons[index].tsbName]) {
          this[this.buttons[index].tsbName](this.buttons[index], other);
        } else {
          this.tipWarning("尚没有" + this.buttons[index].name + "的功能");
        }
      }
    },
    /**
     * 表数据格式化
     * @param title 视图配置的列头
     * @param text 视图配置的内容
     */
    columsCustomRender: function(title, text) {
      if (
        this.$util.isType(text) === "String" &&
        !this.$util.isNumberString(text) &&
        text !== ""
      ) {
        if (title.includes("日期"))
          return this.$util.dateUtils.formatDateString(text, "YYYY-MM-DD");
        else if (title.includes("时间"))
          return this.$util.dateUtils.formatDateString(
            text,
            "YYYY-MM-DD HH:mm"
          );
        else if (text === "NULL" || text === "null" || text === "undefined") {
          return "";
        }
      }
      return text;
    },
    /**
     * 将字符串按字符是否是数字，来进行转换
     * @param v
     * @returns {*}
     */
    getValidTypesValueString(v) {
      if (v) {
        if (this.$util.isType(v) === "val" || this.$util.isNumberString(v)) {
          return v;
        } else {
          return "'" + v + "'";
        }
      }
      return "";
    },
    /**
     * 检测编码是否满足某个表达式规则
     * @param code
     */
    checkTextMeetASNGenerateRule: function(code) {
      let rule;
      if (
        this.$store.state.systemConfigs &&
        this.$store.state.systemConfigs.FormBHRules
      ) {
        let allRules = this.$store.state.systemConfigs.FormBHRules;
        for (let i = 0; i < allRules.length; i++) {
          let ruleObject = allRules[i];
          if (
            ruleObject &&
            ruleObject.rule &&
            this.$util.isType(ruleObject.rule) === "String"
          ) {
            rule = new RegExp(ruleObject.rule);
            if (rule.test(code)) {
              return ruleObject;
            }
          }
        }
      }
      return null;
    },
    /**
     * 给表格对象tableDocument内的所有class是ant-table-body的div做横向滚动同步
     * @param tableDocument
     */
    createTableScrollSynchro: function(tableDocument) {
      let dom = tableDocument.querySelectorAll(".ant-table-body")[0];
      dom.addEventListener(
        "scroll",
        () => {
          tableDocument.querySelectorAll(".ant-table-body")[1].scrollLeft =
            dom.scrollLeft;
        },
        true
      );
    },
    /**
     * 获取BOM副本的管理页面视图定义配置
     * @param BOM
     * @param SDDBH
     * @param dataFromType
     * @param spState
     * @return {{operationConfig: {operation: *[]}, viewConfig: {treeTableName: string, treeFields: string[], treeInitFilter: string, viewBM: string, treeTemplateId: string, rootText: string, formEditNoReloadTree: boolean, Node: string, tableName: string, fields: string[], initFilter: string, viewType: string, gridViewBM: string, templateId: string, treeOneDefaultFormLoad: boolean, operationConfig: {operation: *[], rowButtons: *[]}}}}
     */
    getBOMCopyManageViewConfig: function(BOM, SDDBH, dataFromType, spState) {
      let pageConfig = {
        operationConfig: {
          operation: [
            {
              tsbName: "tsbSave",
              loading: false,
              isShow: true,
              icon: "icon-save",
              name: "保存",
              param: {
                et_name: "0",
                bm: "SYSTEM",
                event: "1"
              }
            },
            {
              tsbName: "tsbEdit",
              loading: false,
              isShow: false,
              icon: "icon-save",
              name: "编辑",
              param: {
                et_name: "981",
                bm: "SYSTEM",
                event: "1"
              }
            },
            {
              tsbName: "tsbPrint",
              loading: false,
              isShow: true,
              icon: "icon-print",
              name: "系统打印",
              param: {
                et_name: "0",
                bm: "SYSTEM",
                event: "1"
              }
            },
            {
              tsbName: "tsbExport",
              loading: false,
              isShow: true,
              icon: "icon-export",
              name: "导出",
              param: {
                et_name: "0",
                bm: "SYSTEM",
                event: "1"
              }
            },
            {
              bm: "SYSTEM",
              tsbName: "getData",
              et_name: 989
            }
          ]
        },
        viewConfig: {
          treeTableName: "V_SC_CPJGJDDY_FB order by _WZBM asc",
          treeFields: ["_WZMC", "_WZBM"],
          treeInitFilter: "_JDBM='" + BOM + "-" + SDDBH + "'",
          viewBM: "SYSTEM",
          treeTemplateId: "245",
          rootText: "生产订单对应产品包",
          formEditNoReloadTree: true,
          Node: "[SelectedTreeNode]",
          tableName: "V_SC_CPJGGXDY_ISBOM_FB order by ID asc",
          fields: ["_WZMC", "_WZBM"],
          initFilter: "_ZBID = [Id] and _JDBM like '%-" + SDDBH + "'",
          viewType: "TreeViewSelect",
          gridViewBM: "SYSTEM",
          templateId: "245",
          treeOneDefaultFormLoad: true, // 是否默认展示第一个节点表单
          operationConfig: {
            operation: [
              {
                tsbName: "tsbSave",
                loading: false,
                isShow: true,
                icon: "icon-save",
                name: "保存",
                param: {
                  et_name: "0",
                  bm: "SYSTEM",
                  event: "1"
                }
              },
              {
                tsbName: "tsbPrint",
                loading: false,
                isShow: true,
                icon: "icon-print",
                name: "系统打印",
                param: {
                  et_name: "0",
                  bm: "SYSTEM",
                  event: "1"
                }
              },
              {
                tsbName: "tsbExport",
                loading: false,
                isShow: true,
                icon: "icon-export",
                name: "导出",
                param: {
                  et_name: "0",
                  bm: "SYSTEM",
                  event: "1"
                }
              },
              {
                loading: false,
                searchBring: false,
                tsbName: "tsbGridImport",
                icon: "icon-select",
                tempConfig: {
                  multiselect: "true",
                  configType: "DATAVIEWGRID",
                  table: "SC_CPJGGXDY",
                  search: "true",
                  //"groupBy": "SEARCHRESULTFORM",
                  searchtemplate: "266",
                  customcalc: "false",
                  bm: "CGGL",
                  newTable: "SC_CPJGGXDY",
                  cols: ["编码:_ZJBM:100", "组件名称:_ZJMC:100"],
                  type: "SEARCHLIST",
                  template: "245"
                },
                isShow: true,
                name: "选入明细",
                mxb_name: "SC_CPJGGXDY",
                param: {
                  et_name: "0",
                  bm: "CGGL",
                  event: "1"
                }
              }
            ],
            rowButtons: [
              {
                tsbName: "tsbDelRow",
                loading: false,
                isShow: true,
                icon: "",
                name: "删行",
                type: "mxOpr",
                param: {
                  et_name: "0",
                  bm: "SYSTEM",
                  event: "1"
                }
              }
            ]
          }
        }
      };
      if (dataFromType === "dateManageView") {
        // 进行数据管理
        if (spState !== "未下达") {
          this.tipWarning("已下达的生产订单不能再编辑BOM");
          let newButtons = [
            {
              tsbName: "tsbPrint",
              loading: false,
              isShow: true,
              icon: "icon-print",
              name: "系统打印",
              param: {
                et_name: "0",
                bm: "SYSTEM",
                event: "1"
              }
            },
            {
              tsbName: "tsbExport",
              loading: false,
              isShow: true,
              icon: "icon-export",
              name: "导出",
              param: {
                et_name: "0",
                bm: "SYSTEM",
                event: "1"
              }
            },
            {
              bm: "SYSTEM",
              tsbName: "getData",
              et_name: 989
            }
          ];
          pageConfig.operationConfig.operation = newButtons;
          pageConfig.viewConfig.operationConfig.operation = [].concat(
            newButtons
          );
          // 内容不能编辑
          pageConfig.viewConfig.fromCannotEdit = true;
          delete pageConfig.operationConfig.rowButtons;
          delete pageConfig.viewConfig.operationConfig.rowButtons;
        }
      }
      return pageConfig;
    }
  }
};
