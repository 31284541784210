<!-- 包装了icon-font和a-icon两种图标，根据type来区分两种图标，如果是带前缀“icon-”则使用icon-font，否则使用ant默认图标 -->
<template>
  <icon-font
    v-if="isIconFont"
    class="e-icon-font-default"
    :class="thisClass"
    :type="thisType"
    :style="thisStyle"
    @click="onClick"
    @mouseover.stop="onMouseover"
    @mouseout.stop="onMouseout"
  ></icon-font>
  <a-icon
    v-else
    class="e-icon-font-default"
    :class="thisClass"
    :type="thisType"
    :theme="theme"
    :style="thisStyle"
    @click="onClick"
    @mouseover.stop="onMouseover"
    @mouseout.stop="onMouseout"
  ></a-icon>
</template>

<script>
const colorStrReg = new RegExp("^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$"); //校验颜色字符串
const rgbgStrReg = new RegExp(
  "^[rR][gG][Bb][Aa]?[\\(]([\\s]*(2[0-4][0-9]|25[0-5]|[01]?[0-9][0-9]?),){2}[\\s]*(2[0-4][0-9]|25[0-5]|[01]?[0-9][0-9]?),?[\\s]*(0\\.\\d{1,2}|1|0)?[\\)]{1}$"
);
export default {
  name: "EIcon",
  data() {
    return {
      thisStyle: {}
    };
  },
  props: {
    type: {
      type: String,
      default: ""
    },
    iClass: {
      type: [String, Array],
      default: function() {
        return null;
      }
    },
    iStyle: {
      type: Object,
      default: function() {
        return {};
      }
    },
    theme: {
      type: String,
      default: function() {
        return this.iconThemeGlobal;
      }
    },
    mouseOverColor: {
      type: String,
      default: function() {
        return null;
      },
      validator: function(value) {
        return !value || colorStrReg.test(value) || rgbgStrReg.test(value);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    mouseOutColor: {
      type: String,
      default: function() {
        return null;
      },
      validator: function(value) {
        return !value || colorStrReg.test(value) || rgbgStrReg.test(value);
      }
    }
  },
  created() {
    this.thisStyle = this.iStyle;
  },
  watch: {
    iStyle: function(n) {
      //console.log("修改了incon的style：" + n);
      this.thisStyle = n;
    }
  },
  computed: {
    iconThemeGlobal: function() {
      return window.ICON_THEME_NAME ? window.ICON_THEME_NAME : "outlined";
    },
    thisType: function() {
      return this.type;
    },
    thisClass: function() {
      let classes = [];
      if(this.iClass) {
        if(this.$util.isType(this.iClass) === "String") {
          classes.push(this.iClass);
        } else {
          classes = classes.concat([],this.iClass);
        }
      }
      if(this.disabled) {
        classes.push("e_icon_disabled");
      }
      return classes;
    },
    isIconFont: function() {
      return this.type.indexOf("icon-") == 0;
    }
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
    /**
     * 鼠标移入图标
     * @param tg
     */
    onMouseover(tg) {
      if (this.mouseOverColor && tg) {
        //console.log("移入",tg,tg.target.tagName,tg.relatedTarget);
        let oneTest =
          tg.target &&
          tg.target.tagName &&
          tg.target.tagName === "I" &&
          (tg.relatedTarget == null || tg.relatedTarget.tagName !== "svg");
        let twoTest =
          tg.target && tg.target.tagName && tg.target.tagName === "svg";
        if (oneTest || twoTest) {
          if (tg.target.style.color !== this.mouseOverColor) {
            this.thisStyle.color = this.mouseOverColor;
            this.$forceUpdate();
          }
        }
      }
    },
    /**
     * 图标移出图标
     * @param tg
     */
    onMouseout(tg) {
      if (this.mouseOutColor && tg && tg.relatedTarget) {
        //console.log("移出",tg,tg.target.tagName,tg.relatedTarget);
        let oneTest =
          tg.target &&
          tg.target.tagName &&
          tg.target.tagName === "I" &&
          (tg.relatedTarget == null || tg.relatedTarget.tagName !== "svg");
        let twoTest =
          tg.target &&
          tg.target.tagName &&
          tg.target.tagName === "svg" &&
          (tg.relatedTarget.tagName === "SPAN" ||
            tg.relatedTarget.tagName === "DIV");
        let threeTest =
          tg.target &&
          tg.target.tagName &&
          tg.target.tagName === "path" &&
          tg.relatedTarget.tagName === "DIV";
        if (oneTest || twoTest || threeTest) {
          if (tg.target.style.color !== this.mouseOutColor) {
            //console.log("从非path移出切换颜色");
            this.thisStyle.color = this.mouseOutColor;
            this.$forceUpdate();
          }
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../assets/style/base/components/e-icon.less";
</style>
