import { requestControls } from "@/mixins/requestControls.js"; // 网络请求
/**
 * 扩展e1表单，NewFrom页面的明细表单元格操作功能
 * @type {{mixins: *[], data(): *, created(), methods: {formMxColumClick: e1DetailColumnExtend.methods.formMxColumClick, onClickDetailColumnExtButton: e1DetailColumnExtend.methods.onClickDetailColumnExtButton, e1TableModalButtonClick: e1DetailColumnExtend.methods.e1TableModalButtonClick, ZJJCTableModalDataLoad: e1DetailColumnExtend.methods.ZJJCTableModalDataLoad}}}
 */
export const e1DetailColumnExtend = {
  mixins: [requestControls],
  data() {
    return {
      detailColumnExt_tableLastID: 0,
      detailColumnExt_tableModalInit: false, // 控制列表渲染
      detailColumnExt_tableModalShow: false,
      detailColumnExt_tableConfig: null,
      detailColumnExt_title: null,
      detailColumnExt_tableDatas: null,
      detailColumnExt_buttons: null,
      detailColumnExt_loading: false,
      detailColumnExt_ColumnKey: null, // 当前操作的数据的关键字值
      detailColumnExt_selectedKeys: null, // 未使用
      detailColumnExt_tableSearchInitFilter: null,
      detailColumnExt_tableModal_LoadParamValue: null,
      detailColumnExt_tableModal_checkedRowKeys: [], // 表格选中的项

      detailColumnExt_formModalInit: true, // 控制表单渲染
      detailColumnExt_formModalShow: false,
      detailColumnExt_formTempId: 0, // 弹出的表单模板ID
      detailColumnExt_fromTitle: "", // 弹出的表单标题
      detailColumnExt_formData: null, // 弹出的表单中的初始数据

      ZJJCTableData: {} // 保存所有对应行号的质检项
    };
  },
  created() {
    //console.log("混入e1Notice的通用方法");
  },
  watch: {
    detailColumnExt_formModalShow: function(v) {
      // 如果关闭则重新渲染
      //console.log("模板打状态",v);
      if (!v) {
        this.detailColumnExt_formModalInit = false;
        this.detailColumnExt_formData = null;
      }
    },
    detailColumnExt_tableModalShow: function(v) {
      // 如果关闭则重新渲染
      console.log("模板打状态", v);
      if (!v) {
        this.detailColumnExt_tableDatas = null;
        this.detailColumnExt_ColumnKey = null;
        this.detailColumnExt_tableModal_checkedRowKeys = [];
        this.detailColumnExt_tableSearchInitFilter = null;
        this.detailColumnExt_tableModalInit = false;
      }
    }
  },
  methods: {
    /**
     * 表单明细表列单元格内容的链接点击事件响应
     * @param event
     */
    formMxColumClick: function(event) {
      //console.log("点击了列",event);
      let { row, clickConfig } = event;
      if (clickConfig) {
        if (clickConfig.type) {
          if (clickConfig.type === "openModal" && clickConfig.modalType) {
            if (
              clickConfig.modalType === "TableModal" &&
              clickConfig.keyFiled
            ) {
              // 打开列表弹窗
              let fkey = row[clickConfig.keyFiled];
              if (fkey) {
                this.detailColumnExt_ColumnKey = "_" + fkey;
                this.detailColumnExt_tableConfig = clickConfig.tableConfig;
                this.detailColumnExt_tableConfig.linkedRowData = row;
                this.detailColumnExt_title = clickConfig.title;
                this.detailColumnExt_buttons = clickConfig.buttons;
                if (this.ZJJCTableData[this.detailColumnExt_ColumnKey]) {
                  // 已经有数据了
                  this.detailColumnExt_tableDatas = [].concat(
                    this.ZJJCTableData[this.detailColumnExt_ColumnKey]
                  );
                  this.detailColumnExt_tableLastID =
                    this.detailColumnExt_tableDatas[
                      this.detailColumnExt_tableDatas.length - 1
                    ][this.detailColumnExt_tableConfig.rowKey] + 1;
                  this.detailColumnExt_tableModalInit = true;
                  this.detailColumnExt_tableModalShow = true;
                  return;
                }
                this.detailColumnExt_tableModalInit = true;
                this.detailColumnExt_tableModalShow = true;
                if (clickConfig.tableConfig.onLoad) {
                  // 定义了初始化数据方法
                  if (this.getInitLoadParamValue()) {
                    clickConfig.tableConfig.onLoad();
                  } else {
                    // 不用去加载数据
                    console.log("没有满足初始化参数");
                    this.detailColumnExt_loading = false;
                    this.detailColumnExt_tableModal_LoadParamValue = null;
                  }
                }
              } else {
                this.tipInfoMessage(
                  "弹窗管理子表数据必须指定关联当前行数据的外键字段有值"
                );
              }
            } else {
              this.tipInfoMessage(
                "弹窗管理子表数据必须指定关联当前行数据的外键"
              );
            }
          }
        }
      }
    },
    /**
     * 点击了弹出的列表窗口中的功能按钮
     * @param event
     */
    onClickDetailColumnExtButton: function(event) {
      //console.log("点击了弹出窗口列表的按钮",event);
      if (event && event.events) {
        if (event.events.click) {
          event.events.click({
            key: event.key,
            clickConfig: event.events.clickConfig,
            resolve: event.resolve
          });
        }
      }
    },
    /**
     * e1表单弹出表格中功能按钮-点击后的操作方法
     * @param event
     */
    e1TableModalButtonClick: function(event) {
      console.log("进到了指定方法做处理", event);
      let key = event.key;
      if (key) {
        let that = this;
        if (key === "delete") {
          // 删除表格选中项
          if (
            this.detailColumnExt_tableModal_checkedRowKeys &&
            this.detailColumnExt_tableModal_checkedRowKeys.length > 0
          ) {
            this.$confirm({
              title: "提示",
              content: "确定需要删除选中项",
              okText: "确定",
              cancelText: "取消",
              onOk() {
                return new Promise(resolve => {
                  const nowDatas = [].concat(that.detailColumnExt_tableDatas);
                  let newDatas = nowDatas.filter(function(data) {
                    return !that.detailColumnExt_tableModal_checkedRowKeys.includes(
                      data[that.detailColumnExt_tableConfig.rowKey]
                    );
                  });
                  that.detailColumnExt_tableDatas = newDatas;
                  resolve();
                }).catch(() => {
                  resolve();
                });
              },
              onCancel() {}
            });
          } else {
            this.tipInfoMessage("请选择需要删除的项");
          }
          return;
        } else if (key === "ok") {
          // 确定
          const nowDatas1 = [].concat(that.detailColumnExt_tableDatas);
          this.ZJJCTableData[this.detailColumnExt_ColumnKey] = nowDatas1;
          if (event.resolve) event.resolve();
          return;
        }
        //新增和编辑
        let clickConfig = event.clickConfig;
        if (clickConfig.type) {
          if (clickConfig.type === "openModal" && clickConfig.modalType) {
            if (clickConfig.modalType === "FormModal") {
              // 打开表单弹窗
              if (clickConfig.params) {
                this.detailColumnExt_formTempId = this.detailColumnExt_tableConfig.templateId;
                this.detailColumnExt_fromTitle = clickConfig.title;
                // 配置了需要注入数据
                let injectionValue = clickConfig.params.injectionValue;
                if (this.$util.isType(injectionValue) === "Array") {
                  // 需要带人初始化数据
                  this.detailColumnExt_formData = {};
                  for (let i = 0; i < injectionValue.length; i++) {
                    let f = injectionValue[i];
                    if (f && f.formFiled && f.valueFiled) {
                      if (f.valueFiled.includes("this.")) {
                        // 是从当前明细数据中取值
                        let mxFiled = f.valueFiled.split(".")[1];
                        this.detailColumnExt_formData[
                          f.formFiled
                        ] = this.detailColumnExt_tableConfig.linkedRowData[
                          mxFiled
                        ];
                      } else {
                        // 从当前表单主表中取值
                        this.detailColumnExt_formData[
                          f.formFiled
                        ] = this.mainTableData[f.valueFiled];
                      }
                    }
                  }
                  //console.log("获取到需要注入的初始化数据", this.detailColumnExt_formData);
                } else if (
                  this.$util.isType(injectionValue) === "String" &&
                  injectionValue === "selectedOne"
                ) {
                  if (
                    this.detailColumnExt_tableModal_checkedRowKeys &&
                    this.detailColumnExt_tableModal_checkedRowKeys.length == 1
                  ) {
                    let selected = that.detailColumnExt_tableDatas.find(
                      function(d) {
                        return (
                          d[that.detailColumnExt_tableConfig.rowKey] ===
                          that.detailColumnExt_tableModal_checkedRowKeys[0]
                        );
                      }
                    );
                    if (selected) {
                      this.detailColumnExt_formData = Object.assign(
                        {},
                        selected
                      );
                    } else {
                      this.tipInfoMessage("未找到选择到的数据");
                      return;
                    }
                  } else {
                    this.tipInfoMessage("请选择一条需要编辑的项");
                    return;
                  }
                }
                if (key === "add" || key === "edit") {
                  // 打开新增表单
                  this.detailColumnExt_formModalInit = true;
                  this.detailColumnExt_formModalShow = true;
                }
              }
            }
          }
        }
      }
    },
    /**
     * 弹出的表格如果设置了选择项，则会监听行选中事件
     * @param event
     */
    onSelectedChangeDetailColumnExtButton: function(event) {
      this.detailColumnExt_tableModal_checkedRowKeys = event;
      //console.log("监听到明细选中变化",event, this.detailColumnExt_tableModal_checkedRowKeys);
    },
    /**
     * 点击了弹出表单窗口的OK按钮
     * @param event
     */
    onClickDetailColumnExtOk: function(event) {
      //console.log("弹出窗点OK",event);
      if (event) {
        let data = event.data;
        if (
          event.initFormData &&
          event.initFormData[this.detailColumnExt_tableConfig.rowKey] !=
            undefined
        ) {
          // 这是编辑该数据后回传
          let that = this;
          let key = event.initFormData[this.detailColumnExt_tableConfig.rowKey];
          let selectedIndex = this.detailColumnExt_tableDatas.findIndex(
            function(d) {
              return d[that.detailColumnExt_tableConfig.rowKey] === key;
            }
          );
          data[this.detailColumnExt_tableConfig.rowKey] = key;
          this.$set(this.detailColumnExt_tableDatas, selectedIndex, data);
        } else {
          // 这是新增数据
          data[
            this.detailColumnExt_tableConfig.rowKey
          ] = this.detailColumnExt_tableLastID;
          if (this.detailColumnExt_tableDatas) {
            this.detailColumnExt_tableDatas.push(data);
          } else {
            this.detailColumnExt_tableDatas = [data];
          }
          this.detailColumnExt_tableLastID =
            this.detailColumnExt_tableLastID + 1;
        }
        if (event.resolve) event.resolve();
      }
    },
    /**
     * 根据配置的onLoadParams，如果满足查询参数，
     * 则会解析出对应的detailColumnExt_tableSearchInitFilter，并且返回true,否则返回false
     * @return boolean 如果不需要去查询数据，则返回false
     */
    getInitLoadParamValue: function() {
      let initFilter = null;
      let autoLoading = true;
      let that = this;
      if (
        this.detailColumnExt_tableConfig.onLoadParams &&
        this.$util.isType(this.detailColumnExt_tableConfig.onLoadParams) ===
          "Array" &&
        this.detailColumnExt_tableConfig.onLoadParams.length > 0
      ) {
        // 配置了加载条件

        this.detailColumnExt_tableConfig.onLoadParams.forEach(function(
          fConfig,
          index
        ) {
          if (index == 0) {
            initFilter = that.getSearchFilter(fConfig);
            if (fConfig.required && initFilter == null) {
              // 是触发从后台查询数据的必要条件
              autoLoading = false;
            }
          } else {
            let tempFiler = that.getSearchFilter(fConfig);
            if (tempFiler) {
              if (initFilter) initFilter = initFilter + " AND " + tempFiler;
              else initFilter = tempFiler;
            } else {
              autoLoading = false;
            }
          }
        });
        this.detailColumnExt_tableSearchInitFilter = initFilter; //initFilter = "_ZBID="+1+" AND GX_HH="+1;
        return autoLoading;
      }
    },
    /**
     * 根据配置{searchFiled:"GX_HH",valueFiled: "this._HH",}获取对应的查询条件语句
     * @param fConfig
     * @return {*}
     */
    getSearchFilter: function(fConfig) {
      let initFilter = null;
      if (fConfig.valueFiled) {
        if (!this.detailColumnExt_tableModal_LoadParamValue) {
          this.detailColumnExt_tableModal_LoadParamValue = {};
        }
        initFilter = fConfig.searchFiled + "=";
        // 如果要的数据是Id,则默认使用当前的this.dataId
        if (
          fConfig.valueFiled === "ID" ||
          fConfig.valueFiled === "id" ||
          fConfig.valueFiled === "Id"
        ) {
          if (this.dataId) {
            initFilter = initFilter + this.dataId;
            this.detailColumnExt_tableModal_LoadParamValue.ID = this.dataId;
          } else {
            return null;
          }
        } else if (fConfig.valueFiled.includes("this.")) {
          // 表示使用对应明细行中的数据字段值
          let detailFiled = fConfig.valueFiled.split(".")[1];
          let value = this.detailColumnExt_tableConfig.linkedRowData[
            detailFiled
          ];
          this.detailColumnExt_tableModal_LoadParamValue[detailFiled] = value;
          if (this.$util.isType(value) === "String") {
            initFilter = initFilter + "'" + value + "'";
          } else if (this.$util.isType(value) === "val") {
            initFilter = initFilter + value;
          } else {
            this.detailColumnExt_tableModal_LoadParamValue[detailFiled] = null;
            return null;
          }
        } else {
          // 其他主表单中的字段值
          let value1 = this.mainTableData[fConfig.valueFiled];
          this.detailColumnExt_tableModal_LoadParamValue[
            fConfig.valueFiled
          ] = value1;
          if (this.$util.isType(value1) === "String") {
            initFilter = initFilter + "'" + value1 + "'";
          } else if (this.$util.isType(value1) === "val") {
            initFilter = initFilter + value1;
          } else {
            this.detailColumnExt_tableModal_LoadParamValue[
              fConfig.valueFiled
            ] = null;
            return null;
          }
        }
      }
      return initFilter;
    },

    /**
     * 定制方法
     * 工艺路线-明细选择管理-质检检测项数据加载方法
     * @constructor
     */
    ZJJCTableModalDataLoad: function() {
      //console.log("质检检测项数据：");
      let that = this;
      this.detailColumnExt_loading = true;
      let params = {
        action: "GetSearchTableList",
        tb_name: this.detailColumnExt_tableConfig.tableName, //主表名
        initFilter: this.detailColumnExt_tableSearchInitFilter, // 查询条件
        t_id: this.detailColumnExt_tableConfig.templateId,
        bm: "SYSTEM",
        randomKey: this.$store.getters.getRandomKey
        //orderBy: this.searchTj.orderBy,
      };
      this.get("Form.ashx", params, {
        success: function(req) {
          if (req.data && req.data.dataList) {
            let dataList = req.data.dataList;
            dataList.map(function(data, index) {
              // 给数组对象设置一个主键字段用于表格选择
              return (data[that.detailColumnExt_tableConfig.rowKey] = index);
            });
            that.detailColumnExt_tableDatas = dataList;
            that.detailColumnExt_tableLastID = dataList.length + 1;
          }
          console.log("获取的数据：", that.detailColumnExt_tableDatas);
          that.detailColumnExt_loading = false;
        },
        error: function(err) {
          console.log(err);
          that.detailColumnExt_loading = false;
          that.alertError("请求失败，获取表数据失败");
        }
      });
    }
  }
};
