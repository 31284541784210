export const analyticalPrint = {
  data() {
    return {};
  },
  created() {},
  methods: {
    /**
     * 解析为print所需的数据
     * @param xml 根据analyticalTemplate的方法解析出来的数据
     * @return 需要的数据object
     */
    analyticalPrintData(xml) {
      var analyticalData = {
        formConfig: this.analyticalFormConfig(xml),
        formRows: this.analyticalFormRows(xml),
        mxForm: this.analyticalMxForm(xml),
        title: xml.formDefine.title,
        flow: this.analyticalFlowInput(xml)
      };
      try {
        this.consoleFormRows(analyticalData.formRows);
        this.consoleMxRows(analyticalData.mxForm.columns);
      } catch (err) {
        err;
      }

      return analyticalData;
    },
    consoleFormRows(obj) {
      console.log("body form");
      var text1 = "";
      var text2 = "";
      for (var i = 0; i < obj.length; i++) {
        for (var j = 0; j < obj[i].length; j++) {
          text1 = text1 + obj[i][j].label + "\n";
          text2 = text2 + obj[i][j].fieldName + "\n";
        }
      }
      console.log(text1);
      console.log(text2);
    },
    consoleMxRows(obj) {
      console.log("mx form");
      var text1 = "";
      var text2 = "";
      for (var i = 0; i < obj.length; i++) {
        text1 = text1 + obj[i].headerText + "\n";
        text2 = text2 + obj[i].fieldName + "\n";
      }
      console.log(text1);
      console.log(text2);
    },
    /**
     * 解析formConfig
     * @param
     * @return
     */
    analyticalFormConfig(xml) {
      var key = xml["formDefine"];

      var Rdata = {
        tableName: key.tableName,
        title: key.shortTitle,
        size: key.size,
        position: key.location
      };

      return Rdata;
    },
    /**
     * 解析formRows
     * @param
     * @return
     */
    analyticalFormRows(xml) {
      //debugger;
      var formRows = xml["formRows"];
      var returnData = [];

      formRows.forEach(row => {
        var RowsArray = [];

        row["columns"].forEach(columns => {
          // 如果不显示，则不处理
          if (columns.isShow == false) {
            return;
          }
          var columnsJson = {};
          if (columns.controlType == "CxsLabel") {
            columnsJson = {
              fieldName: columns.configs.fieldName,
              label: columns.text,
              position: columns.location,
              size: columns.size,
              controlType: columns.thisControlType
            };
          } else if (columns.controlType == "CxsGroupBox") {
            columnsJson = {
              // fieldName: columns.configs.fieldName,
              label: columns.text,
              position: { x: 0, y: 0 },
              size: { w: 0, h: 0 },
              controlType: columns.thisControlType
            };
            var fieldName = [];
            var checkboxValue = {};
            for (var i = 0; i < columns.formRows[0]["columns"].length; i++) {
              var boxObj = columns.formRows[0]["columns"][i];
              if (boxObj.thisControlType == "ECheckbox") {
                fieldName.push(boxObj.configs.fieldName);
                checkboxValue[boxObj.configs.fieldName] = boxObj.checked[0];
              }
            }
            columnsJson.fieldName = fieldName;
            columnsJson.checkboxValue = checkboxValue;
          } else if (columns.controlType == "CxsCheckBox") {
            columnsJson = {
              fieldName: columns.configs.fieldName,
              label: columns.options[0].label
                ? columns.options[0].label
                : columns.label,
              position: { x: 0, y: 0 },
              size: { w: 0, h: 0 },
              controlType: columns.thisControlType
            };
          } else {
            columnsJson = {
              fieldName: columns.configs.fieldName,
              label: columns.label,
              position: columns.location,
              size: columns.size,
              controlType: columns.thisControlType
            };
          }

          RowsArray.push(columnsJson);
        });

        RowsArray != "" ? returnData.push(RowsArray) : "";
      });

      return returnData;
    },
    /**
     * 解析流程
     * @param
     * @return
     */
    analyticalFlowInput(xml) {
      // 对流程赋值
      var returnData = [];
      if (xml.flow) {
        var length = xml.flow.length % 2;
        var rowFlow = [];
        for (var i = 0; i < xml.flow.length; i++) {
          if (xml.flow[i].GKMC === "开始") {
            length = (xml.flow.length - 1) % 2;
            continue;
          }
          if (length == 0) {
            if (rowFlow.length == 2) {
              returnData.push(rowFlow);
              rowFlow = [];
            } else {
              rowFlow.push(this.analyticalFlow(xml.flow[i], i));
              if (rowFlow.length == 2) {
                returnData.push(rowFlow);
                rowFlow = [];
              }
            }
          } else {
            returnData.push([this.analyticalFlow(xml.flow[i], i)]);
          }
        }
        return returnData;
      }
      return [];
    },
    /**
     * 解析MxForm
     * @param
     * @return
     */
    analyticalMxForm(xml) {
      var multi = false;
      var mxForm = null;
      // 多张明细表和单明细表结构完全不一样
      if (xml["mxForm"] != null) {
        mxForm = xml["mxForm"];
      }
      if (xml["mxForms"] != null) {
        mxForm = xml["mxForms"];
        multi = true;
      }
      if (mxForm == null) {
        return null;
      }
      if (!multi) {
        return this.analysisSingleMxForm(mxForm);
      } else {
        return null;
        // return this.analysisMultiMxForm(mxForm);
      }
    },
    /**
     * 解析单个明细表
     * @param
     * @return
     */
    analysisSingleMxForm(form) {
      var mxForm = form[0]["controlDefine"];
      mxForm.columns.forEach((row, index) => {
        if (row.visible == false) {
          mxForm.columns.splice(index, 1);
        }
      });

      var returnData = {
        type: "mxForm",
        columns: mxForm.columns,
        size: mxForm.size,
        position: mxForm.location
      };

      return returnData;
    },
    /**
     * 往 analyticalPrintData 方法返回的已经组装好了的对象字段赋值
     * @param obj
     * @return
     */
    setPrintDataValue(printDataObj, valueData) {
      // 设置主表数据
      var formRows = printDataObj["formRows"],
        tableName = printDataObj.formConfig.tableName || "";

      formRows.forEach((rows, index) => {
        for (let i = 0; i < rows.length; i++) {
          // 特殊处理box控件
          if (rows[i].controlType === "EGroupBox") {
            for (let key in valueData) {
              if (
                rows[i].fieldName.indexOf(key) != -1 &&
                valueData[key] == true
              ) {
                if (formRows[index][i].value == null) {
                  formRows[index][i].value = "";
                }
                formRows[index][i].value =
                  formRows[index][i].value +
                  formRows[index][i].checkboxValue[key] +
                  " ";
              }
            }
          } else if (rows[i].controlType === "ECheckbox") {
            for (let keys2 in valueData) {
              if (rows[i].fieldName == keys2) {
                if (valueData[keys2] == true) {
                  formRows[index][i].value = "是";
                }
                if (valueData[keys2] == false) {
                  formRows[index][i].value = "否";
                }
              }
            }
          } else {
            for (let fieldName in valueData) {
              if (rows[i].fieldName === fieldName || rows[i].fieldName === tableName+"."+fieldName) {
                formRows[index][i].value = valueData[fieldName];
              }
            }
          }
        }
      });
      printDataObj.formRows = formRows;
      return printDataObj;
    },
    /**
     * 解析流程
     * @param obj
     * @return
     */
    analyticalFlow(data, index) {
      var value =
        data.CLRMC && data.CLSJ
          ? (data.FKYJ || '无') +
            "<br>" +
            data.CLRMC +
            "   " +
            data.CLJG +
            "   " +
            this.$util.changeTimeFormat(data.CLSJ)
          : "";
      var returnData = {
        controlType: "EInput",
        fieldName: "flow" + index,
        label: (
          data.GKMC.substr(data.GKMC.length - 2) === "确认" ||
          data.GKMC.substr(data.GKMC.length - 2) === "审批"
            ? data.GKMC
            : data.GKMC + "审批"),
        value: value,
        position: { x: 1, y: 1 },
        size: { h: 1, labelw: 1, w: 1 }
      };
      return returnData;
    }
  }
};
