<template>
  <a-tooltip placement="topRight" :title="title" arrow-point-at-center>
    <a style="height: auto;"><e-icon type="exclamation-circle"></e-icon></a>
  </a-tooltip>
</template>

<script>
export default {
  name: "ChangeIcon",
  data() {
    return {};
  },
  components: {},
  model: {
    prop: "value",
    event: "change",
    type: Array
  },
  props: {
    title: {
      type: null,
      default: ""
    },
    type: {
      type: [Number, String],
      default: 0
    }
  },
  created() {},
  computed: {},
  methods: {},
  watch: {}
};
</script>

<style module></style>
