<template>
  <button
    :class="buttonClass"
    :disabled="disabled"
    @click="onButtonClick"
    @mouseover="onButtonMouseover"
    @mouseout="onButtonMouseout"
    :title="tip"
  >
    <div class="remind" v-if="remind">{{ remind }}</div>
    <e-icon
      v-show="!thisLoading"
      :type="icon"
      :iClass="iconClass"
      :disabled="disabled"
      :iStyle="iStyle"
    />
    <e-icon v-show="thisLoading" type="loading" :iStyle="iStyle" />
    <span v-if="title" :class="iconButtonTitle">{{ title }}</span>
  </button>
</template>

<script>
export default {
  name: "EIconButton",
  data() {
    return {
      iStyle: {},
      loadingIcon: "",
      thisLoading: false,
      iconClass: ["iconButton_icon"]
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: null
    },
    iconSize: {
      type: Number,
      default: 18
    },
    size: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: function() {
        return "defalut";
      }
    },
    haveMenu: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: function() {
        return {};
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    keyID: {
      type: [Number, String],
      required: true
    },
    tip: {
      type: String,
      default: function() {
        return null;
      }
    },
    remind: {
      type: [Number, String],
      default: null
    }
  },
  watch: {
    loading: function(v) {
      //console.log("按钮状态变化",v);
      this.thisLoading = v;
    }
  },
  computed: {
    buttonClass: function() {
      let defaultClass = ["iconButton", "ant-btn"];
      if (this.size) {
        if (this.size === "default") {
          return defaultClass;
        } else if (this.size === "small") {
          return ["iconButton__small", "ant-btn"];
        } else if (this.size === "large") {
          return ["iconButton__large", "ant-btn"];
        }
      } else {
        return defaultClass;
      }
    },
    iconButtonTitle: function() {
      if (this.size) {
        if (this.size === "default") {
          return ["iconButton-title"];
        } else if (this.size === "small") {
          return ["iconButton-title__small"];
        } else if (this.size === "large") {
          return ["iconButton-title__large"];
        }
      } else {
        return ["iconButton-title"];
      }
    }
  },
  created() {
    this.setIStyle();
    this.thisLoading = this.loading;
    if (this.type === "primary") {
      this.iconClass.push("iconButton_icon--primaryColor");
      this.buttonClass.push("ant-btn-primary");
    } else {
      this.iconClass.push("iconButton_icon--color");
    }
  },
  methods: {
    /**
     * 响应按钮点击事件
     */
    onButtonClick() {
      if (!this.thisLoading) this.$emit("click", this.keyID);
    },
    /**
     * 响应移入按钮事件
     * @param e
     */
    onButtonMouseover(e) {
      this.$emit("mouseover", {
        event: e,
        keyId: this.keyID,
        haveMenu: this.haveMenu
      });
    },
    /**
     * 响应移出按钮事件
     * @param e
     */
    onButtonMouseout(e) {
      this.$emit("mouseout", {
        event: e,
        keyId: this.keyID,
        haveMenu: this.haveMenu
      });
    },
    /**
     * 根据size,切换字体尺寸
     */
    setIStyle: function() {
      if (this.size) {
        if (this.size === "default") {
          this.iStyle.fontSize = "18px";
        } else if (this.size === "small") {
          this.iStyle.fontSize = "16px";
        } else if (this.size === "large") {
          this.iStyle.fontSize = "20px";
        }
      } else {
        this.iStyle.fontSize = this.iconSize + "px";
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../assets/style/base/components/e-icon-button.less";
</style>
