<template>
  <div class="index-head">
    <div class="logo" v-if="!isNewTheme">
      <img
        class="index-head_logoImg"
        :src="'/img/' + systemLogo + '.png'"
        alt="嵌嵌信息"
      />
    </div>
    <div v-if="showSystemTitle" class="index-head-title">
      <span>{{ indexHeadName }}</span>
    </div>
    <div class="user-info">
      <!-- 菜单快捷搜索功能 optionLabelProp="value"-->
      <div class="certain-category-search-wrapper">
        <a-auto-complete
          class="certain-category-search"
          dropdownClassName="certain-category-search-dropdown"
          :dropdownMatchSelectWidth="false"
          :allowClear="true"
          :dropdownStyle="{ width: '300px' }"
          @select="onSelect"
          @search="doSearch"
          @focus="searchFocus"
          style="width: 100%"
          placeholder="输入搜索菜单"
          optionLabelProp="searchKey"
        >
          <template slot="dataSource">
            <a-select-option disabled value="all">
              <span class="show-all"
                >总共有{{ dataSource.length }}条{{
                  showCache ? "历史" : "搜索"
                }}结果</span
              >
            </a-select-option><!-group.title -->
            <a-select-option
              v-for="group in dataSource"
              :key="group.DHBM + '_' + new Date().getTime()"
              :value="group.DHBM"
              :searchKey="group.searchKey"
            >
              <a
                href="JavaScript:void(0);"
                :title="group.title"
                rel="noopener noreferrer"
                v-html="group.html"
              ></a>
            </a-select-option>
          </template>
          <a-input>
            <a-icon
              v-show="!nowSearchKey"
              slot="suffix"
              type="search"
              class="certain-category-icon"
            />
          </a-input>
        </a-auto-complete>
      </div>
      <div v-if="userLog" class="user-logo">
        <a-avatar :src="userLog" />
      </div>
      <div class="user-handle">
        <a-dropdown>
          <a class="ant-dropdown-link" href="#"
            >{{ nowUser }}
            <e-icon type="down" style="color: inherit;" />
          </a>
          <a-menu slot="overlay" @click="onMenuClick">
            <a-menu-item key="1">
              <e-icon type="edit" style="color: inherit;" />修改密码
            </a-menu-item>
            <a-menu-item key="2">
              <e-icon type="info-circle" style="color: inherit;" />关于
            </a-menu-item>
            <a-menu-item key="4" v-if="issuesManagementUrl != ''">
              <e-icon type="question-circle" style="color: inherit;" />问题管理
            </a-menu-item>
            <a-menu-item key="5" v-if="nowUser === '管理员'">
              <e-icon
                type="icon-printTemplateDesign"
                style="color: inherit;"
              />打印模板设计
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item key="3">
              <e-icon type="logout" style="color: inherit;" />退出登录
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <!-- 密码修改弹窗 :validate-status="passwordError() ? 'error' : ''" :help="passwordError() || ''"-->
    <a-modal
      :visible="updatePassVisible"
      title="修改密码"
      :width="540"
      @cancel="updatePassVisible = false"
    >
      <template>
        <a-form :form="updatePassForm">
          <a-form-item
            label="请输入原密码"
            :labelCol="{ xs: { span: 20 }, sm: { span: 7 } }"
            :wrapperCol="{ xs: { span: 24 }, sm: { span: 16 } }"
          >
            <a-input
              type="password"
              v-decorator="[
                'password',
                { rules: [{ required: true, message: '请输入密码' }] }
              ]"
              placeholder="原密码"
              @change="trimInput"
            >
            </a-input>
          </a-form-item>
          <a-form-item
            label="请输入新密码"
            :labelCol="{ xs: { span: 20 }, sm: { span: 7 } }"
            :wrapperCol="{ xs: { span: 24 }, sm: { span: 16 } }"
          >
            <a-input
              type="password"
              v-decorator="[
                'password1',
                {
                  rules: [
                    { required: true, message: '请输入新密码' },
                    { validator: validateToNextPassword }
                  ]
                }
              ]"
              placeholder="新密码"
              @change="trimInput"
            >
            </a-input>
          </a-form-item>
          <a-form-item
            label="再次输入新密码"
            :labelCol="{ xs: { span: 20 }, sm: { span: 7 } }"
            :wrapperCol="{ xs: { span: 24 }, sm: { span: 16 } }"
          >
            <a-input
              type="password"
              v-decorator="[
                'password2',
                {
                  rules: [
                    { required: true, message: '请再次输入密码' },
                    { validator: compareToFirstPassword }
                  ]
                }
              ]"
              placeholder="新密码"
              @change="trimInput"
              @blur="handleConfirmBlur"
            >
            </a-input>
          </a-form-item>
        </a-form>
      </template>
      <template v-if="!isNewTheme" slot="title">
        <div class="e-model-head">
          <a-icon type="form"></a-icon
          ><span class="e-model-title">修改密码</span>
        </div>
      </template>
      <template slot="footer">
        <a-button key="back" @click="doUpdatePass" :type="buttonType"
          >确定</a-button
        >
      </template>
    </a-modal>
    <!-- 关于我们弹窗控件 -->
    <a-modal
      title="关于"
      :visible="aboutWeVisible"
      cancelText="关闭"
      :width="340"
      @ok="aboutWeVisible = false"
      @cancel="aboutWeVisible = false"
      wrapClassName="about__us"
    >
      <template slot="footer">
        <a-button
          key="back"
          @click="aboutWeVisible = false"
          :type="buttonType"
          :ghost="buttonGhost"
          >关闭</a-button
        >
      </template>
      <!-- <p>版本号</p>
    <p>技术支持</p> -->
      <a-row>
        <a-col span="6" class="about__label">版本号:</a-col>
        <a-col>{{ aboutConfig.version }}</a-col>
      </a-row>
      <a-row>
        <a-col span="6" class="about__label">技术支持:</a-col>
        <a-col>{{ aboutConfig.technicalSupport }}</a-col>
      </a-row>
    </a-modal>

    <!-- 关于我们退出确认 -->
    <a-modal
      title="提示"
      :visible="logOutVisible"
      :maskClosable="false"
      @cancel="logOutVisible = false"
      :width="340"
    >
      <template slot="footer">
        <div style="display: flex;align-items: center;justify-content: center;">
          <a-button @click="logOutWait" :loading="logOutOK" :type="buttonType"
            >确定</a-button
          >
          <div class="divider-block-min"></div>
          <a-button
            @click="logOutVisible = false"
            :disabled="logOutOK"
            :type="buttonType"
            :ghost="buttonGhost"
            >取消</a-button
          >
        </div>
      </template>
      <div style="text-align: center;">
        <div>
          <e-icon type="icon-icon-question" style="font-size: 48px;"></e-icon>
        </div>
        <p style="font-size: 16px;margin: 0">你确定需要退出系统？</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { requestControls } from "@/mixins/requestControls.js"; // 网络访问方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法

export default {
  mixins: [requestControls, pageControls],
  name: "IndexHead",
  data() {
    return {
      updatePassForm: this.$form.createForm(this, { name: "updatePassForm" }),
      indexHeadName: window.APP_NAME,
      nowUser: this.$store.getters.getEmployee,
      userLog: this.$store.getters.getUserHeadLogo,
      dataSource: [],
      cacheDataSource: [],
      showSearch: "",
      showCache: false,
      updatePassVisible: false, // 显示密码修改弹窗
      confirmDirty: false,
      selectMenuTitle: "",
      nowSearchKey: null,
      aboutWeVisible: false,
      logOutVisible: false,
      logOutOK: false,
      aboutConfig: window.ABOUT_US_CONFIG,
      systemLogo: window.HEAD_LOGO_IMAGE,
      showSystemTitle: window.HEAD_SHOW_APPNAME,
      issuesManagementUrl: window.ISSUES_MANAGEMENT__URL
    };
  },
  computed: {
    buttonType: function() {
      return this.isNewTheme
        ? "primary"
        : this.visibleMoreCondition
        ? ""
        : "primary";
    },
    buttonGhost: function() {
      return this.isNewTheme;
    }
  },
  created() {
    this.cacheDataSource = this.$store.getters.getSearchMenuHistory; // 将搜索历史记录从缓存中取得
    this.dataSource = this.cacheDataSource;
  },
  destroyed() {},
  methods: {
    /**
     * 根据当前菜单获取，对应菜单的名称，包含其上级菜单名称(上级菜单颜色变淡)
     * @param group
     */
    getSearchTitleHTMl(group) {
      if (group.parentKey) {
        // 是否存在上级
        if (group.parentKey.indexOf(".") == -1) {
          // 只有一层上级
          return {
            title: group.menuName + " « " + group.parent,
            html:
              group.menuName +
              " <span style='color:#b1afaf;'>« " +
              group.parent +
              "</span>"
          };
        } else {
          let parentKeys = this.$util.getMenuParents(group.parentKey);
          let parentName = " « " + group.parent;
          let menuList = this.$store.state.menuList;
          for (let i = parentKeys.length - 1; i >= 0; i--) {
            let menuObj = this.$util.getObjByParamAndValue(
              menuList,
              "key",
              parentKeys[i]
            );
            if (menuObj) parentName += " « " + menuObj.menuName;
          }
          return {
            title: group.menuName + parentName,
            html:
              group.menuName +
              "<span style='color:#b1afaf;'>" +
              parentName +
              "</span>"
          };
        }
      }
      return { title: group.menuName, html: group.menuName };
    },
    /**
     * 点击下拉菜单选项
     * @param tagKey
     */
    onMenuClick(tagKey) {
      //console.log("tagKey",tagKey);
      if (tagKey.key === "1") {
        //修改密码
        this.updatePassVisible = true;
      } else if (tagKey.key === "3") {
        //退出登录
        this.toLogOut();
      } else if (tagKey.key === "2") {
        // 关于我们
        this.aboutWeVisible = true;
      } else if (tagKey.key === "4") {
        window.open(window.ISSUES_MANAGEMENT__URL);
      } else if (tagKey.key === "5") {
        // 打开打印模板设计器
        this.lodopPrintTemplateDesign();
      }
    },
    /**
     * 确认登录方法
     */
    toLogOut() {
      if (this.isNewTheme) {
        this.logOutVisible = true;
      } else {
        let that = this;
        this.$confirm({
          title: "提示",
          content: "你确定需要退出系统？",
          okText: "确定",
          cancelText: "取消",
          onOk() {
            return new Promise(resolve => {
              that.logOut(resolve);
            }).catch(() => console.log("Oops errors!"));
          },
          onCancel() {}
        });
      }
    },
    /**
     * 登出前方法
     */
    logOutWait() {
      let promise = new Promise(resolve => {
        this.logOutOK = true;
        this.logOut(resolve);
      }).catch(() => {
        this.logOutOK = false;
        this.logOutVisible = false;
        console.error("登出弹出框按钮异常");
      });
      // 异步响应上方的Promise类提供的resolve的，执行结果
      promise.then(() => {
        let that = this;
        setTimeout(function() {
          that.logOutOK = false;
          that.logOutVisible = false;
        }, 60);
      });
    },
    /**
     * 退出登录方法
     */
    logOut(resolve) {
      let that = this;
      let params = {
        action: "UserLogout",
        randomKey: this.$store.getters.getRandomKey
      };
      this.get(
        "System.ashx",
        params,
        {
          success: () => {
            that.$store.commit("logout");
            that.destroyedViewCache();
            that.$router.replace("/login");
          },
          error: function() {
            //console.log(err);
            that.$error({
              title: "提示",
              content: "登出失败"
            });
          }
        },
        resolve
      );
    },
    /**
     * 点击选中的菜单检索项
     * @param key
     * @param option
     */
    onSelect(key, option) {
      if (key !== "all") {
        console.log("选中的option", option, key);
        let menuList = this.$store.state.menuList;
        let selectObj = this.$util.getObjByParamAndValue(
          menuList,
          "DHBM",
          key
        );
        //console.log("选中的菜单",selectObj);
        if (selectObj) {
          let randomKey = new Date().getTime() + "_" + Math.random();
          this.$emit("clickToMenu", {
            key: selectObj.DHBM + "_" + randomKey,
            title: selectObj.menuName,
            path: selectObj.path,
            name: selectObj.name
          });
          selectObj = Object.assign(
            selectObj,
            this.getSearchTitleHTMl(selectObj)
          );
          let index = this.cacheDataSource.findIndex(function(d) {
            return d.DHBM === key;
          });
          // 把当前选中的菜单排到第一位
          if (index == -1) {
            this.cacheDataSource.splice(0, 0, selectObj);
          } else {
            this.cacheDataSource.splice(index, 1);
            this.cacheDataSource.splice(0, 0, selectObj);
          }
          this.$store.commit("setSearchMenuHistory", this.cacheDataSource); // 将搜索历史记录更新到缓存
        }
      } else {
        this.dataSource = this.cacheDataSource;
      }
    },
    /**
     * 根据输入关键字去检索菜单
     * @param key
     */
    doSearch(key) {
      if (key !== "") {
        key = this.$util.trimStr(key);
        this.dataSource = [];
        this.nowSearchKey = key;
        this.showCache = false;
        let searchResult = [];
        let menuList = this.$store.state.menuList;
        this.$util.getObjArrayByParamAndValue(
          menuList,
          "menuName",
          key,
          1,
          searchResult
        );
        searchResult = searchResult.filter(function(menu) {
          return menu.path;
        });
        let that = this;
        searchResult.map(function(menu) {
          let titleInfo = that.getSearchTitleHTMl(menu);
          titleInfo.searchKey = key;
          return Object.assign(menu, titleInfo);
        });
        console.log("搜索结果", searchResult);
        this.dataSource = searchResult;
      } else {
        this.dataSource = [];
        this.nowSearchKey = null;
      }
    },
    /**
     * 搜索输入框获取焦点时，默认显示历史搜索记录
     */
    searchFocus() {
      if (this.nowSearchKey == null) {
        this.showCache = true;
        this.dataSource = this.cacheDataSource;
      }
    },
    /**
     * 输入新密码前要求先输入第一的密码
     * @param e
     */
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    /**
     * 比较两次输入新密码是否一致
     * @param rule
     * @param value
     * @param callback
     */
    validateToNextPassword(rule, value, callback) {
      const form = this.updatePassForm;
      if (value && this.confirmDirty) {
        form.validateFields(["password2"], { force: true });
      }
      callback();
    },
    /**
     * 比较两次输入新密码是否一致
     * @param rule
     * @param value
     * @param callback
     */
    compareToFirstPassword(rule, value, callback) {
      const form = this.updatePassForm;
      if (value && value !== form.getFieldValue("password1")) {
        callback("再次输入的新密码与第一次输入不一致");
      } else {
        callback();
      }
    },
    /**
     * 设置清空用户名输入的空格
     * @param e
     */
    trimInput(e) {
      let val = e.target.value;
      if ("" === this.$util.trimStr(val)) {
        e.target.value = "";
      } else {
        e.target.value = this.$util.trimStr(val);
      }
    },
    /**
     * 点击修改密码确定按钮，提交修改密码
     * @param tag
     */
    doUpdatePass(tag) {
      let that = this;
      this.updatePassForm.validateFields((err, values) => {
        if (!err) {
          console.log("去提交", values);
          let params = {
            action: "ChangePassWord",
            oldPassword: that.paramEncrypt(values.password),
            newPassword: that.paramEncrypt(values.password2),
            randomKey: this.$store.getters.getRandomKey
          };
          this.post(
            "System.ashx",
            params,
            {
              success: () => {
                that.alertSuccess("密码修改成功,请重新登录");
                that.$store.commit("logout");
                that.$router.replace("/login");
              },
              error: function(er) {
                console.log(er);
                that.alertError("修改密码失败:" + er.message);
              }
            },
            tag.resolve
          );
        } else {
          tag.resolve(1);
          //console.log("错误不去提交",values);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/index-head.less";
</style>
