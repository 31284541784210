<!-- 树形表格视图 -->
<template>
  <div style="height: 100%;" class="tree-table-view">
    <div v-if="showToolbar" class="form-headButton">
      <!-- 授权操作按钮 -->
      <e-toolbar
        :buttons="buttons"
        :operationDisabled="operationDisabled"
        :size="$config.defaultControlSize"
        @click="onButtonClick"
      ></e-toolbar>
    </div>
    <div class="tab-view-context">
      <!-- 视图表格 -->
      <div style="height: 100%;">
        <!-- 明细表title -->
        <e-head-title
          :title="title"
          :size="$config.defaultControlSize"
        ></e-head-title>
        <div class="view-context">
          <!-- 视图查询条件 -->
          <search-condition
            v-if="showSearchCondition"
            :conditions="searchCondition"
            :buttonName="searchConditionName"
            :changeValueOutoSearch="autoSearch"
            :initOneSearch="searchOne"
            @tableDataChange="onSearchConditionChange"
            @onSearch="doSearch"
          ></search-condition>
          <!-- 视图表格 :footer-method="footerMethod" @cell-click="onSelectChange" :span-method="rowspanMethod"-->
          <div class="view-table" :class="getTableBoxClass">
            <vxe-toolbar
              v-if="showGroupToolbar"
              :loading="tableSpin"
              :size="tableSize_MiXin"
              class="e-table-vxe-toolbar"
            >
              <template slot="buttons">
                &nbsp;&nbsp;展示分组：
                <vxe-button @dropdown-click="onGroupChanged">
                  <template>{{ groupType }}</template>
                  <template slot="dropdowns">
                    <vxe-button
                      v-for="(name, index) in groupTypeOptions"
                      :key="index"
                      :name="name"
                      >{{ name }}</vxe-button
                    >
                  </template>
                </vxe-button>
              </template>
            </vxe-toolbar>
            <vxe-virtual-tree
              resizable
              :stripe="$config.table_stripe"
              show-overflow
              show-header-overflow
              ref="tableRef"
              maxHeight="100%"
              height="auto"
              border
              highlight-hover-row
              highlight-current-row
              :loading="tableSpin"
              :data="tableDatas"
              :row-id="rowKey"
              :empty-render="vxeTableEmptyRender"
              :tooltip-config="vxeTabletooltipConfig"
              :size="tableSize_MiXin"
              header-row-class-name="e-table-header_style"
              header-cell-class-name="e-table-header-cell_style"
              row-class-name="e-table-row_style"
              :checkbox-config="checkboxConfig"
              :tree-config="{ children: 'children' }"
              :columns="thisColumns"
              @checkbox-all="onSelectAll"
              @checkbox-change="onSelectChange"
              @sort-change="onSortChange"
            >
            </vxe-virtual-tree>
            <vxe-pager
              ref="vxePagerRef"
              class="e-table-page"
              background
              :size="tableSize_MiXin"
              :loading="tableSpin"
              :current-page="pagination.current"
              :page-size="pagination.pageSize"
              :page-sizes="pagination.pageSizes"
              :total="pagination.total"
              :layouts="pagination.layouts"
              @page-change="tableChange"
            >
            </vxe-pager>
          </div>
        </div>
      </div>
      <systemPrintSet ref="systemPrintSet"></systemPrintSet>
    </div>
  </div>
</template>

<script>
import ETableColum from "@/components/ETableColum";
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求
import { e1CarryOver } from "@/views/listView/mixins/e1CarryOver.js"; // 支持e1平台的物料价格结转功能的方法
import { e1ExpendColum } from "@/views/listView/mixins/e1ExpendColum.js"; // 提供了扩展列表功能的公共方法
import { e1SC_SCDD_WORKBENCH } from "@/views/listView/mixins/e1SC_SCDD_WORKBENCH.js"; // e1生产订单工作台功能扩展方法
import { e1SC_SCGDQR_WORKBENCH } from "@/views/listView/mixins/e1SC_SCGDQR_WORKBENCH.js"; // e1工单排产工作台功能扩展方法
export default {
  mixins: [
    analyticalMenuAuth,
    pageControls,
    requestControls,
    e1CarryOver,
    e1ExpendColum,
    e1SC_SCDD_WORKBENCH,
    e1SC_SCGDQR_WORKBENCH
  ],
  name: "TreeTableView",
  components: {
    ETableColum
  },
  data() {
    return {
      cacheData: null,
      tableDatas: [],
      thisColumns: [],
      thisFooterTableColumn: false, // 是否显示合计行，默认不显示
      selectedRowKeys: [], // 表格选中的行
      thisConfig: null,
      buttons: [], // 用户可做的操作按钮
      searchCondition: [], // 用户可用的查询获执行条件
      searchConditionName: null, // 用户可用的查询获执行条件后面的按钮名称
      switchTableColums: false, // 用于标记是否需要切换表格
      tableConfig: [],
      otherCondition: {}, // 筛选条件表单参数
      nowSearchCondition: {}, // 菜单配置的查询条件参数
      searchtemplate: null, // 筛选条件的表单模板Id
      searchOne: false, // 是否第一次初始化表单即触发查询
      autoSearch: false, // 是否数据变化即触发查询,注：由于数字选择控件有问题，本功能暂时有异常

      oldGroupConfig: null,
      nowGroupFields: null, //当前分组使用的分组字段
      group: null, // 当前的分组字段，
      groupOrder: null, // 在分组语句中的排序配置
      orderBy: null, // 其他字段联合排序
      groupTypeOptions: [],
      groupType: "无", // 分组类型名称
      checkboxConfig: {
        checkAll: false,
        highlight: true
        //checkStrictly: true,
        //checkField: this.defaultTableKeyName
      },
      tableSpin: false,
      tempId: null, // 有可能是从对应表中的字段名
      rowKey: this.defaultTableKeyName,
      nextFloorGroupBy: null,
      nextFloorKeyField: null,
      nextFloorKeyFieldChanged: null,
      groupToNextFloor: false, // 是否是分组字段内再分层
      defaultDataIdField: "ID",
      defaultDataId: null, // 新建时用来向新建表单传递一个默认值字段
      dhbm: null,
      operationDisabled: false, // 操作按钮是否可用
      popoverPlacement: "right", // 表单内容提示框
      searchTjOrderBy: null,
      nowExpand: []
    };
  },
  props: {
    pageConfig: {
      //包含视图结构、视图数据来源、操作权限配置
      type: Object,
      default: function() {
        return null;
      }
    },
    title: {
      type: String,
      default: ""
    }
  },
  watch: {
    pageConfig: function(nDatas) {
      this.thisConfig = nDatas;
    },
    tableSpin: function(v) {
      if (!v) {
        // 数据加载完成后，将滚动条拉到顶部
        this.$refs.tableRef.scrollTo(0, 0);
      }
    }
  },
  computed: {
    tableMaxWidth: function() {
      if (this.thisColumns.length > 0) {
        let tempWidth = 0;
        for (let i = 0; i < this.thisColumns.length; i++) {
          tempWidth += this.thisColumns[i].width;
        }
        return tempWidth;
      }
      return 0;
    },
    showToolbar: function() {
      return this.checkToobleIsShow(this.buttons);
    },
    showGroupToolbar: function() {
      return this.groupTypeOptions && this.groupTypeOptions.length > 1;
    },
    showSearchCondition: function() {
      return this.searchCondition && this.searchCondition.length > 0;
    },
    getTableBoxClass: function() {
      let classes = [];
      // view-table__search_toolbar_footer
      let extName = "view-table_";
      if (this.showSearchCondition) {
        extName += "_search";
      }
      if (this.showGroupToolbar) {
        extName += "_toolbar";
      }
      if (this.thisFooterTableColumn) {
        extName += "_footer";
      }
      if (extName !== "view-table_") {
        classes.push(extName);
      }
      if (this.$config.defaultControlSize) {
        if (this.$config.defaultControlSize === "small") {
          classes.push("view-table__small");
        } else if (this.$config.defaultControlSize === "default") {
          classes.push("view-table__default");
        } else if (this.$config.defaultControlSize === "large") {
          classes.push("view-table__large");
        }
      } else {
        classes.push("view-table__default");
      }
      return classes;
    }
  },
  created() {
    this.thisConfig = this.pageConfig;
    //分析出用户可做操作按钮
    this.buttons = this.getUserJurisdictionButtons(this.thisConfig);
    if (this.thisConfig && this.thisConfig.viewConfig) {
      this.dmbh = this.thisConfig.DHBM;
      // 表格配置
      this.tableConfig = this.thisConfig.viewConfig;
      // 表尾合计行
      if (this.tableConfig.sumRow) this.createFooterTableData();

      if (this.tableConfig.groupCols && this.tableConfig.childCols) {
        // 单一分组模式
        this.nowGroupFields = this.tableConfig.groupCols;
        this.nextFloorKeyField = this.tableConfig.nextFloorKeyField;
        this.nextFloorKeyFieldChanged = this.tableConfig.nextFloorKeyFieldChanged;
        this.nextFloorGroupBy = this.tableConfig.nextFloorGroupBy;
        this.searchTjOrderBy = this.tableConfig.Order;
        this.thisColumns = this.analyticalColsArrayVxeTable(
          this.tableConfig.childCols,
          this.tableConfig.groupCols,
          this.tableConfig.nextFloorGroupBy,
          this.tableConfig.nextFloorKeyField
        );
        this.group = this.tableConfig.groupBy;
        this.groupOrder = this.tableConfig.groupOrder;
        this.oldGroupConfig = Object.assign({}, this.tableConfig);
      } else if (this.tableConfig.groupOptions) {
        // 可变化分组字段
        try {
          if (
            this.$util.isType(this.tableConfig.groupOptions) === "Array" &&
            this.tableConfig.groupOptions.length > 0
          ) {
            let that = this;
            let selectedOption = this.tableConfig.groupOptions[0]; // 默认
            this.groupType = selectedOption.groupName;
            this.tableConfig.groupOptions.forEach(function(opt) {
              if (opt.groupName) {
                that.groupTypeOptions.push(opt.groupName);
              }
              if (opt.selected && !that.groupType) {
                that.groupType = opt.groupName;
                selectedOption = opt;
              }
            });
            this.oldGroupConfig = Object.assign({}, selectedOption);
            this.nowGroupFields = selectedOption.groupCols;
            this.group = selectedOption.groupBy;
            this.groupOrder = selectedOption.groupOrder;
            this.nextFloorKeyField = selectedOption.nextFloorKeyField;
            this.nextFloorKeyFieldChanged =
              selectedOption.nextFloorKeyFieldChanged;
            this.nextFloorGroupBy = selectedOption.nextFloorGroupBy;
            this.searchTjOrderBy = selectedOption.Order;
            this.thisColumns = this.analyticalColsArrayVxeTable(
              selectedOption.childCols,
              this.nowGroupFields,
              selectedOption.nextFloorGroupBy,
              selectedOption.nextFloorKeyField
            );
          } else {
            this.alertError("表格配置错误");
          }
        } catch (e) {
          this.alertError("表格配置错误");
        }
      } else {
        this.alertError("表格配置错误");
      }
      console.log("表格定义", this.thisColumns);
    } else {
      this.alertError("表格配置错误");
    }
  },
  mounted() {
    if (this.tableConfig) {
      this.searchOne = this.tableConfig.autoLoad;
      this.searchConditionName = this.tableConfig.searchConditionName; // 条件后的名称
      let searchConditionTemp = this.tableConfig.searchCondition; // 查询或执行条件
      this.tempId = this.tableConfig.templateId; // 操作数据的表单模板
      this.searchtemplate = this.tableConfig.searchTemplate;
      /*
        2020-04-23新加功能代码，配合最新版searchCondition，需要经过searchTableList测试后方可使用
        */
      if (this.searchtemplate && this.searchtemplate != "") {
        if (searchConditionTemp) {
          searchConditionTemp.push({
            type: "temp",
            tempId: this.searchtemplate
          });
        } else {
          searchConditionTemp = [{ type: "temp", tempId: this.searchtemplate }];
        }
      }
      this.searchCondition = searchConditionTemp;
      let autoLoad = this.tableConfig.autoLoad; // 是否自动查询
      if (
        autoLoad &&
        !(this.searchCondition && this.searchCondition.length > 0)
      ) {
        // 有查询条件，则首次查询需要等待查询条件默认值完成加载并返回 !(this.searchCondition && this.searchCondition.length > 0)
        this.getTableData();
      } else this.operationDisabled = false;
    } else {
      this.alertError("表格配置错误");
    }
  },
  methods: {
    /**
     * 点击查询按钮触发查询
     * @param values
     */
    doSearch(values) {
      console.log("查询数据", values);
      this.nowSearchCondition = values.data;
      this.getTableData(null, function() {
        if (values && values.resolve) values.resolve();
      });
    },
    /**
     * 监听查询控件中的选项值变化
     */
    onSearchConditionChange(values) {
      if (values) {
        // 只监听第一次查询条件初始化
        this.nowSearchCondition = values;
      }
    },
    /**
     * 向otherCondition查询参数中注入分页查询设置
     * @param pageCurrent
     * @returns {({} & {pageNumber: *, pageSize: *}) | (otherCondition & {pageNumber: *, pageSize: *})}
     */
    injectionPageSet(pageCurrent) {
      if (pageCurrent) {
        this.pagination.current = pageCurrent;
      }
      let page = {
        pageNumber: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      return Object.assign(this.otherCondition, page);
    },
    /**
     * 加载表格的数据
     */
    getTableData(button, callBack) {
      this.tableSpin = true;
      this.operationDisabled = true;
      let that = this;
      let params = {
        action: "GetTreeTableDatas",
        tb_name: this.tableConfig.tableName, //主表名
        search: JSON.stringify(this.nowSearchCondition), // 无查询条件
        group: this.group, //this.searchTj.searchCols, // 分组字段
        groupOrder: this.groupOrder, // 分组内的排序字段，必填
        orderBy: this.searchTjOrderBy, // 子数据排序字段，不能再加groupOrder内包含的字段名
        randomKey: this.$store.getters.getRandomKey
      };
      let pageSize = this.pagination.pageSize;
      let pageNumber = this.pagination.current;
      if (this.pagination.pageSize < Infinity) {
        params.pageSize = pageSize;
        params.pageNumber = pageNumber;
      }
      if (button) button.loading = true;
      this.post("Form.ashx", params, {
        success: function(req) {
          console.log(req);
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? req.data:req;
          let dataList = reqData.data ? [].concat([], reqData.data) : [];
          that.pagination.total = reqData.TotalCount
            ? reqData.TotalCount
            : dataList.length;
          if (!that.groupToNextFloor) {
            dataList = dataList.map(function(data, index) {
              data[that.rowKey] = index + "";
              if (data.children && data.children.length > 0) {
                let nullNotes = that.getNullNotes(
                  that.getChildrenNotes(data.children, that.nextFloorKeyField),
                  that.getChildrenNotes(data.children, that.nextFloorGroupBy)
                );
                //console.log(data[that.nextFloorKeyFieldChanged],"的不存在分层节点",nullNotes);
                let indexX = -1;
                let children = [];
                let parentKey = data[that.nextFloorKeyField]
                  ? data[that.nextFloorKeyField]
                  : data[that.nextFloorKeyFieldChanged];
                if (nullNotes.length > 0) {
                  nullNotes.forEach(function(v) {
                    let newNote = {};
                    newNote[that.nextFloorKeyFieldChanged] =
                      data[that.nextFloorKeyFieldChanged];
                    newNote[that.nextFloorGroupBy] = v.substring(
                      0,
                      v.lastIndexOf("_")
                    ); //有问题，只能兼容使用“_”做为分层符的表述字符串
                    newNote[that.nextFloorKeyField] = v;
                    //console.log("新插入节点",newNote);
                    data.children.push(newNote);
                  });
                }
                /**  需要重新整理层级结构 */
                data.children.map(function(data1) {
                  if (that.nextFloorGroupBy && that.nextFloorKeyField) {
                    if (
                      data1[that.nextFloorGroupBy] === parentKey ||
                      data1[that.nextFloorGroupBy] === null
                    ) {
                      indexX++;
                      data1[that.rowKey] = index + "." + indexX;
                      children.push(data1);
                    }
                  } else {
                    indexX++;
                    data1[that.rowKey] = index + "." + indexX;
                    children.push(data1);
                  }
                  return data1;
                });
                if (that.nextFloorGroupBy && that.nextFloorKeyField) {
                  children = children.map(function(data2) {
                    let childData = that.changeDataNextChildren(
                      data.children,
                      data2[that.nextFloorKeyField],
                      that.nextFloorKeyField,
                      that.nextFloorGroupBy,
                      data2[that.rowKey],
                      that.nextFloorKeyFieldChanged
                    );
                    if (childData) {
                      data2.children = childData;
                    }
                    if (that.nextFloorKeyFieldChanged) {
                      // 将nextFloorKeyFieldChanged指定的字段的值修改为parentKeyField字段设置的值
                      data2[that.nextFloorKeyFieldChanged] =
                        data2[that.nextFloorKeyField];
                    }
                    return data2;
                  });
                }
                if (children && children.length > 0) data.children = children;
              }
              if (that.tableConfig.groupCols) {
                // 表示是树状列表
                data.SYSTEM_TREE_ROOT = "null"; // 关联字段标识为无效数据
              }

              // 给数组对象设置一个主键字段用于表格选择
              return data;
            });
          } else {
            dataList = dataList.map(function(data, index) {
              if (data.children && data.children.length > 0) {
                data.children.map(function(data1, indexX2) {
                  data1[that.rowKey] = index + "." + indexX2;
                });
              }
              data[that.rowKey] = index + "";
              // 给数组对象设置一个主键字段用于表格选择
              return data;
            });
          }
          //console.log("重新解析：",dataList);
          that.tableDatas = dataList;
          that.tableSpin = false;
          that.operationDisabled = false;
          that.selectedRowKeys = []; // 清空选中项
          //默认展开
          that.$nextTick(function() {
            that.$refs.tableRef.setAllTreeExpand(true);
          });
          if (button) button.loading = false;
          if (callBack) callBack(dataList);
        },
        error: function(err) {
          console.log(err);
          that.pagination.current = 1;
          that.pagination.total = 0;
          that.tableSpin = false;
          that.operationDisabled = false;
          that.selectedRowKeys = []; // 清空选中项
          if (button) button.loading = false;
          if (callBack) callBack();
          that.alertError("请求失败，获取表数据失败");
        }
      });
    },
    /**
     * 获取列表里指定的下级节点的节点关键字的节点值按层级排序的列表
     * @param datas
     * @param nextFloorKeyField
     */
    getChildrenNotes(datas, nextFloorKeyField) {
      let childrenNotes = [];
      if (datas && datas.length > 0 && nextFloorKeyField) {
        datas.forEach(function(jlObject) {
          if (!childrenNotes.includes(jlObject[nextFloorKeyField]))
            childrenNotes.push(jlObject[nextFloorKeyField]);
        });
      }
      //排序
      if (childrenNotes.length > 0) {
        childrenNotes.sort();
        if (childrenNotes[childrenNotes.length - 1] === null) {
          childrenNotes.splice(childrenNotes.length - 1, 1);
        }
      }
      return childrenNotes;
    },
    /**
     * 获取parentKeys中存在，但是不在keys中存在的节点
     * @param keys
     * @param parentKeys
     */
    getNullNotes(keys, parentKeys) {
      let nullNotes = [];
      parentKeys.forEach(function(k) {
        if (!keys.includes(k)) {
          nullNotes.push(k);
        }
      });
      return nullNotes;
    },
    /**
     * @description 将列表中的数据按照指定方式进行分层
     * @param data 数据列表
     * @param parentKeys 节点层每级的节点值
     * @param level 检索的层级
     * @param parentKeyField 父级的关联的字段名
     * @param thisKeyField 本层数据中用来和上层关联值进行关联的字段名
     * @param parent 上级的分层rowkey
     */
    getDataNotes(
      data,
      parentNode,
      parentKeys,
      level,
      parentKeyField,
      thisKeyField,
      parent,
      nextFloorKeyFieldChanged
    ) {
      if (
        data &&
        data.length > 1 &&
        parentKeys &&
        parentKeys.length > 0 &&
        thisKeyField
      ) {
        let parentKey = parentKeys.filter(function(k) {
          if (level == 0) {
            return k === parentNode;
          } else if (k) {
            let tempRa = k.split("_");
            return (
              tempRa.length == level &&
              (parentNode === null || k.indexOf(parentNode) == 0)
            );
          }
        });
        let nextChildren = [];
        if (level < parentKeys.length && parentKey.length > 0) {
          //console.log("第"+level+ "层的节点有："+parentKey);
          let newLevel = ++level;
          for (let key in parentKey) {
            this.getDataNotes(
              data,
              parentKey[key],
              parentKeys,
              newLevel,
              parentKeyField,
              thisKeyField,
              parent,
              nextFloorKeyFieldChanged
            );
          }
        }
      }
      return null;
    },
    /**
     * @description 将列表中的数据按照指定方式进行分层
     * @param data 数据列表
     * @param parentKey 关联上层的值
     * @param parentKeyField 父级的关联的字段名
     * @param thisKeyField 本层数据中用来和上层关联值进行关联的字段名
     * @param parent 上级的分层rowkey
     */
    changeDataNextChildren(
      data,
      parentKey,
      parentKeyField,
      thisKeyField,
      parent,
      nextFloorKeyFieldChanged
    ) {
      if (data && data.length > 1 && parentKey && thisKeyField) {
        let deleteIndex = [];
        let that = this;
        let nextChildren = [];
        data.forEach(function(data1, index1) {
          if (data1[thisKeyField] && !data1[that.rowKey]) {
            if (parentKey === data1[thisKeyField]) {
              //console.log(parentKeyField+'='+parentKey,'=',thisKeyField+'='+data1[thisKeyField]);
              //console.log(parentKey+"的下级",data1);
              if (nextFloorKeyFieldChanged) {
                // 将nextFloorKeyFieldChanged指定的字段的值修改为parentKeyField字段设置的值
                data1[nextFloorKeyFieldChanged] = data1[parentKeyField];
              }
              nextChildren.push(data1);
              deleteIndex.push(index1);
            }
          }
        });
        for (let i = deleteIndex.length - 1; i >= 0; i--) {
          data.splice(deleteIndex[i], 1);
        }
        if (nextChildren.length > 0) {
          nextChildren = nextChildren.map(function(data2, index2) {
            data2[that.rowKey] = parent + "." + index2;
            let childData = that.changeDataNextChildren(
              data,
              data2[parentKeyField],
              parentKeyField,
              thisKeyField,
              data2[that.rowKey],
              nextFloorKeyFieldChanged
            );
            if (childData) {
              data2.children = childData;
            }
            return data2;
          });
        }
        return nextChildren.length > 0 ? nextChildren : null;
      }
      return null;
    },
    /**
     * 获取视图配置的数据模板ID
     * @param row
     */
    getTempId(row) {
      if (row) {
        return row._MBID ? row._MBID : this.tempId;
      }
      return this.tempId;
    },
    /**
     * 从数据行中获取标记数据ID
     * @param row
     */
    getDataId(row) {
      if (this.defaultDataIdField && row) {
        return row[this.defaultDataIdField];
      }
      return null;
    },
    /**
     * 获取需要传送给新建或编辑表单的默认字段对应的值
     */
    getDefaultId(row) {
      if (this.defaultDataId && this.$util.isNumberString(this.defaultDataId)) {
        return this.defaultDataId;
      } else if (this.defaultDataId && row) {
        return row[this.defaultDataId];
      }
      return null;
    },
    /**
     * 查看选中行
     */
    tsbView(button) {
      if (this.selectedRowKeys.length == 1) {
        let selectedId = this.selectedRowKeys[0];
        let that = this;
        let selectedData = this.tableDatas.find(function(data) {
          return data[that.rowKey] === selectedId;
        });
        let titleFiedName = this.thisColumns[1].key;
        let id = this.getDataId(selectedData);
        let tempId = this.getTempId(selectedData);
        this.$refs.tableRef.closeTooltip();
        if (button.param.dbclicktype === "form") {
          // 根据配置参数获取表单模板id
          //referdataid: "_LYZBID";
          //refertemplate: "_LYMBID"
          if (button.param.referdataid) {
            id = selectedData[button.param.referdataid];
          }
          if (button.param.refertemplate) {
            tempId = selectedData[button.param.refertemplate];
          }
          this.openTab({
            title: this.title + "-" + selectedData[titleFiedName],
            path: "/DetailForm",
            query: {
              dhbm: this.dhbm,
              templetId: tempId,
              detailFiledName: titleFiedName, // 表单用于tab的title的表单字段名
              dataId: id,
              bm: button.param.bm // 表单对应BM表
            }
          });
        } else {
          // 普通弹出查看视图
          this.openTab({
            title: "查看-" + this.title + "-" + selectedData[titleFiedName],
            path: "/DetailForm",
            query: {
              dhbm: this.dhbm,
              templetId: tempId,
              detailFiledName: titleFiedName, // 表单用于tab的title的表单字段名
              dataId: id,
              bm: this.tableConfig.bm // 表单对应BM表
            }
          });
        }
      } else {
        this.alert("请选择一条需要查看的记录");
      }
    },
    /**
     * 响应行的选中情况变化
     * @param currentRow
     */
    onSelectChange(currentRow) {
      //console.log("onCellClick",currentRow);
      if (currentRow) {
        let that = this;
        let index = this.selectedRowKeys.findIndex(function(d) {
          return d === currentRow.row[that.rowKey];
        });
        if (index >= 0) {
          // 已经选中,取消选中
          this.$refs.tableRef.setCheckboxRow(currentRow.row, false);
          this.selectedRowKeys.splice(index, 1);
          if (currentRow.row.children && currentRow.row.children.length > 0) {
            // 如果其下有子节点，全部取消选中
            //this.$refs.tableRef.setTreeExpand(currentRow.row, false);
            this.$refs.tableRef.setCheckboxRow(currentRow.row.children, false);
            let indexArray = [];
            currentRow.row.children.forEach(function(row) {
              if (that.selectedRowKeys.includes(row[that.rowKey])) {
                indexArray.push(that.selectedRowKeys.indexOf(row[that.rowKey]));
              }
            });
            indexArray = this.$util.sortAseNumberArray(indexArray);
            for (let i = indexArray.length - 1; i >= 0; i--) {
              this.selectedRowKeys.splice(i, 1);
            }
          }
          if (currentRow.rowid.includes(".")) {
            // 当前取消选中的是，本身是一个子节点
            let parentKey = currentRow.rowid.substring(
              0,
              currentRow.rowid.lastIndexOf(".")
            );
            // 查看同级的节点，如果全部被取消选中，则将父节点页取消选中，否则将父节点设置为半选中状态
            let parentItem = currentRow.items.find(function(item) {
              return item[that.rowKey] === parentKey;
            });
            if (parentItem && parentItem.children) {
              let index = parentItem.children.findIndex(function(cItem) {
                return that.$refs.tableRef.isCheckedByCheckboxRow(cItem);
              });
              if (
                index >= 0 &&
                !that.$refs.tableRef.isCheckedByCheckboxRow(parentItem)
              )
                // 还有被选中的本级节点
                that.$refs.tableRef.setCheckboxRow(parentItem, true);
              else if (
                index < 0 &&
                that.$refs.tableRef.isCheckedByCheckboxRow(parentItem)
              )
                that.$refs.tableRef.setCheckboxRow(parentItem, false);
            }
          }
          //console.log("剩余选中项目",this.selectedRowKeys);
        } else {
          // 进行选中
          this.$refs.tableRef.setCheckboxRow(currentRow.row, true);
          this.selectedRowKeys.push(currentRow.row[that.rowKey]);
          if (currentRow.row.children && currentRow.row.children.length > 0) {
            // 如果其下有子节点，则将其展开，并且选中
            this.$refs.tableRef.setTreeExpand(currentRow.row, true);
            this.$refs.tableRef.setCheckboxRow(currentRow.row.children, true);
            currentRow.row.children.forEach(function(row) {
              if (!that.selectedRowKeys.includes(row[that.rowKey]))
                that.selectedRowKeys.push(row[that.rowKey]);
            });
          }
          if (currentRow.rowid.includes(".")) {
            // 当前选中的节点，本身是一个子节点
            let parentKey = currentRow.rowid.substring(
              0,
              currentRow.rowid.lastIndexOf(".")
            );
            // 查看同级的节点，如果全部被取消选中，则将父节点页取消选中，否则将父节点设置为半选中状态
            let parentItem = currentRow.items.find(function(item) {
              return item[that.rowKey] === parentKey;
            });
            if (parentItem && parentItem.children) {
              // 其有同级节点
              that.$refs.tableRef.setCheckboxRow(parentItem, true);
            }
          }
          //console.log("选中项目",this.selectedRowKeys);
        }
      }
      //console.log("selectedRowKeys changed: ", this.selectedRowKeys);
    },
    /**
     * 响应列表的全选事件
     */
    onSelectAll(event) {
      //console.log("点击全选", event);
      let that = this;
      if (!event.checked) {
        this.selectedRowKeys = [];
        this.$refs.tableRef.setAllCheckboxRow(false);
      } else {
        this.tableDatas.forEach(function(data) {
          that.selectedRowKeys.push(data[that.rowKey]);
          if (data.children && data.children.length > 0) {
            // 如果其下有子节点，则将其展开，并且选中
            data.children.forEach(function(row) {
              that.selectedRowKeys.push(row[that.rowKey]);
            });
            that.selectedRowKeys = that.selectedRowKeys.concat(
              that.getNowDataAllRowKeys(data.children, that.rowKey)
            );
          }
        });
        this.$refs.tableRef.setAllTreeExpand(true);
        setTimeout(function() {
          that.$refs.tableRef.setAllCheckboxRow(true);
        }, 100);
      }
      console.log("选择的", this.selectedRowKeys);
    },
    /**
     * 获取指定field在data中的所有值
     */
    getNowDataAllRowKeys(data, field) {
      let keys = [];
      if (data && data.length > 0) {
        // 如果其下有子节点，则将其展开，并且选中
        let that = this;
        data.forEach(function(row) {
          keys.push(row[field]);
          if (row.children && row.children.length > 0) {
            keys = keys.concat(
              [],
              that.getNowDataAllRowKeys(row.children, field)
            );
          }
        });
      }
      return keys;
    },
    /**
     * 响应分组类型切换
     */
    onGroupChanged(event) {
      console.log("分组变化", event);
      let name = event.name;
      let selectedOption = this.tableConfig.groupOptions.find(function(opt) {
        return opt.groupName === name;
      });
      if (selectedOption) {
        // 重新组织查询条件
        this.oldGroupConfig = Object.assign({}, selectedOption);
        this.tableDatas = [];
        this.groupType = name;
        this.nowGroupFields = selectedOption.groupCols;
        this.group = selectedOption.groupBy;
        this.groupOrder = selectedOption.groupOrder;
        this.nextFloorKeyField = selectedOption.nextFloorKeyField;
        this.nextFloorKeyFieldChanged = selectedOption.nextFloorKeyFieldChanged;
        this.nextFloorGroupBy = selectedOption.nextFloorGroupBy;
        this.searchTjOrderBy = selectedOption.Order;
        this.thisColumns = this.analyticalColsArrayVxeTable(
          selectedOption.childCols,
          this.nowGroupFields,
          selectedOption.nextFloorGroupBy,
          selectedOption.nextFloorKeyField
        );
        if (!this.showTableColums) {
          this.getTableData();
        }
      }
    },
    /**
     * 插件不支持事件
     */
    onToggleTreeExpand(event) {
      console.log("触发展开树事件", event);
    },
    /**
     * 从选中的选项中，过滤出生产订单的节点选项
     * @param rowKey
     */
    getSelectedScDd(datas, rowKey) {
      if (datas && datas.length > 0 && rowKey && rowKey.length > 0) {
        let selectedScDds = [];
        for (let i = 0; i < datas.length; i++) {
          let data = datas[i];
          if (rowKey.includes(data[this.rowKey])) {
            if (data["ID"]) {
              selectedScDds.push(data[this.rowKey]);
            }
          }
          if (data.children && data.children.length > 0) {
            let tempSelecteds = this.getSelectedScDd(data.children, rowKey);
            selectedScDds = [].concat(selectedScDds, tempSelecteds);
          }
        }
        return selectedScDds;
      }
      return null;
    },
    /**
     * 从表数据列表中获取指定选中项的对应的字段值，组成的数组
     * @param dataList
     * @param rowKeys
     * @param fields
     */
    getSelectDatasFiled(dataList, rowKeys, fields) {
      let result = {};
      if (
        dataList &&
        dataList.length > 0 &&
        rowKeys &&
        rowKeys.length > 0 &&
        fields
      ) {
        let selectFields = [];
        if (this.$util.isType(fields) === "String") {
          // 单个字符值
          selectFields.push(fields);
        } else if (this.$util.isType(fields) === "Array") {
          //多个字符的值
          selectFields = fields;
        }
        for (let i = 0; i < dataList.length; i++) {
          let data = dataList[i];
          if (rowKeys.includes(data[this.rowKey])) {
            for (let j = 0; j < selectFields.length; j++) {
              let fieldName = selectFields[j];
              if (result[fieldName]) {
                result[fieldName].push(data[fieldName]);
              } else {
                result[fieldName] = [data[fieldName]];
              }
            }
          }
          if (data.children && data.children.length > 0) {
            let tempObj = this.getSelectDatasFiled(
              data.children,
              rowKeys,
              selectFields
            );
            if (this.$util.isValidObject(tempObj)) {
              for (let t = 0; t < selectFields.length; t++) {
                let ftemp = selectFields[t];
                if (tempObj[ftemp]) {
                  if (result[ftemp]) {
                    result[ftemp] = [].concat(result[ftemp], tempObj[ftemp]);
                  } else {
                    result[ftemp] = tempObj[ftemp];
                  }
                }
              }
            }
          }
        }
      }
      return result;
    },
    /**
     * 响应表格分页事件
     * @param pagination
     */
    tableChange(pagination) {
      let that = this;
      this.onTableChangeMiXin(pagination, function() {
        that.getTableData();
      });
    },
    /**
     * 触发排序事件
     * @param event
     */
    onSortChange(event) {
      console.log("触发排序事件", event);
      if (event && event.field && event.order) {
        this.tableSpin = true;
        let that = this;
        //event.field; 参与排序的字段;event.order;排序方式 "desc"
        let orderField = event.field;
        let orderBy = orderField + " " + event.order;
        let isGroupOrderFiled = false;
        if (this.nowGroupFields && this.nowGroupFields.length > 0) {
          // 设置了分组字段
          let nowGroupOrders = this.$util.trimStr(this.groupOrder).split(",");
          let groupIndex = this.nowGroupFields.findIndex(function(f) {
            let groupFiled = f.split(":")[1];
            return groupFiled === orderField;
          });
          if (groupIndex >= 0) {
            // 当前排序的字段是分组字段
            isGroupOrderFiled = true;
            if (nowGroupOrders.length > 1) {
              //多个排序字段
              this.groupOrder = orderBy;
            } else {
              // 只有一个排序字段
              let fOrder = nowGroupOrders[0].split(" ");
              if (fOrder.includes(orderField)) {
                // 当前排序中包含对应的字段
                if (
                  fOrder.includes("desc") ||
                  fOrder.includes("DESC") ||
                  fOrder.includes("Desc")
                ) {
                  // 目前该字段是降序
                  if (event.order === "desc") {
                    // 是变成降序
                    setTimeout(function() {
                      that.tableSpin = false;
                    }, 200);
                    return;
                  } else {
                    //降序变升序
                    this.groupOrder = orderBy;
                  }
                } else {
                  //是升序
                  if (event.order === "asc") {
                    setTimeout(function() {
                      that.tableSpin = false;
                    }, 200);
                    return;
                  } else {
                    //升序变降序
                    this.groupOrder = orderBy;
                  }
                }
              } else {
                this.groupOrder = orderBy;
              }
            }
          }
        }
        if (!isGroupOrderFiled) {
          // 不是改变分组字段的排序
          this.searchTjOrderBy = orderBy;
        } else if (
          this.nextFloorKeyFieldChanged &&
          orderField === this.nextFloorKeyFieldChanged
        ) {
          // 如果是配置了分组字段对应显示值字段
          this.searchTjOrderBy = this.nextFloorKeyField + " " + event.order;
        }
        this.selectedRowKeys = [];
        this.getTableData();
      } else {
        // 取消排序,还原到原始排序状态
        this.searchTjOrderBy = this.oldGroupConfig.Order;
        this.groupOrder = this.oldGroupConfig.groupOrder;
        this.selectedRowKeys = [];
        this.getTableData();
      }
    },
    /**
     * 刷新列表
     * @param button
     */
    tsbRefresh(button) {
      this.getTableData(button);
    },
    /**
     * 通用行合并函数（将相同多列数据合并为一行）
     * 有显示问题
     */
    rowspanMethod({ row, $rowIndex, column, data }) {
      if (this.nowGroupFields && this.nowGroupFields.length > 0) {
        let index = this.nowGroupFields.findIndex(function(fv) {
          return fv.split(":").includes(column.property);
        });
        if (index >= 0) {
          let cellValue = this.getGroupKeyValue(row);
          let prevRow = data[$rowIndex - 1]; //上一行
          let nextRow = data[$rowIndex + 1]; // 下一行
          if (prevRow) {
            let prevValue = this.getGroupKeyValue(prevRow);
            if (cellValue === prevValue) return { rowspan: 0, colspan: 0 };
          } else {
            let countRowspan = 1;
            let nextValue = this.getGroupKeyValue(nextRow);
            while (nextValue && nextValue === cellValue) {
              nextRow = data[++countRowspan + $rowIndex];
              nextValue = this.getGroupKeyValue(nextRow);
            }
            if (countRowspan > 1) {
              return { rowspan: countRowspan, colspan: 1 };
            }
          }
        }
      }
    },
    /**
     * 根据分组定义获取该组唯一标志的的key值
     * @param row
     * @return {string}
     */
    getGroupKeyValue(row) {
      if (row) {
        let fieldValue = "";
        let that = this;
        this.nowGroupFields.forEach(function(f) {
          let fieldName = f.split(":")[1];
          fieldValue =
            fieldValue + "_" + that.$util.changeNullToEmpty(row[fieldName]);
        });
        return fieldValue;
      }
      return null;
    },
    tsbExport() {
      this.getTableData(null, res => {
        if (!res) {
          this.$message.error("获取导出的数据失败");
          return;
        }
        res &&
          this.thisColumns &&
          this.$util.excelExport(res, this.thisColumns, this.title);
      });
    },
    tsbPrint() {
      var json = {
        formConfig: {
          position: { x: 0, y: 0 },
          size: { w: 0, h: 0 },
          title: this.title
        },
        formRows: [],
        title: this.title,
        mxForm: {
          columns: this.thisColumns,
          data: this.tableDatas,
          position: { x: 0, y: 0 },
          size: { w: 0, h: 0 },
          type: "mxForm"
        }
      };
      this.$refs.systemPrintSet.show(json);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/tree-table-view.less";
</style>
