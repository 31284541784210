/**
 * 支持明细选人功能
 * @type {{data(): *, created(), methods: {}}}
 */
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求

export const e1FormToolbarGridImport = {
  mixins: [pageControls, requestControls],
  data() {
    return {
      gridImportTitle: "",
      gridImportCoveragePattern: false, // 明细导入数据覆盖模式，默认是追加模式
      gridImportModalShow: false, // 是否显示明细数据选入弹出框
      gridImportTableConfig: undefined, // 明细数据选人弹出表格定义
      gridImportTableDatas: [], // 选人明细数据
      gridImportModalButtons: [], // 明细数据选人弹出窗，底部按钮设置
      gridImportTableSelectedKeys: null, // 默认选中的明细数据
      gridImportModalLading: false, // 明细选人数据加载中
      gridImportSelectModalShow: false, // 明细数据选人条件弹出框
      gridImportSelectTempId: null, // 明细数据选人条件表单id
      gridImportModalType: null, // 弹出明细选人框的类型
      gridImportSearchFileds: null, // 弹出筛选表单，点击查询时提交的条件
      gridImportSearchCustomcalc: false, // 标识GetConditionView接口查询结果需要扩充数据
      gridImportsearchBring: false, // 标识是否需要将筛选条件数据合并到主表单
      gridImportSelectCheckCondition: null, // 保存了每个表单设置的每条明细的校验规则
      gridImportCheckConditionConfig: null, // 保存配置的明细校验
      gridImportSearchMainResultCache: null, // 缓存当前表单明细选人的数据中附加的对应主表数据
      gridDefaultLinkedMainFiled: ["_BDMC"], // 默认会从明细选人数据中联动带人到主表单的数据，多条明细，联动带人第一条记录的改字段
      gridImportLinkMainSameForeignKey: [] // 明细选人主表与子表外键关联字段相同的表配置
    };
  },
  created() {
    //console.log("混入e1FormToolbar的通用方法");
  },
  methods: {
    /**
     * 上表选人明细- 针对上下表视图的数据选人方法
     * @param button
     */
    tsbTopTable(button) {
      this.gridImportTableConfig = null;
      this.gridImportTableDatas = [];
      this.gridImportTitle = button.title;
      console.log("上表选人明细", button);
      this.tsbGridImport(button);
    },
    /**
     * 下表选人明细- 针对上下表视图的数据选人方法
     * @param button
     */
    tsbBottomTable(button) {
      this.gridImportTableConfig = null;
      this.gridImportTableDatas = [];
      this.gridImportTitle = button.title;
      console.log("下表选人明细", button);
      this.tsbGridImport(button);
    },
    /**
     * 点击“选人明细”按钮根据弹出明细数据选择功能
     * @param button
     */
    tsbGridImport(button) {
      console.log("选人明细", button);
      this.gridImportTableConfig = this.getGridImportTableConfig(button);
      if (this.gridImportTableConfig) {
        if (button.searchBring) {
          this.gridImportsearchBring = true; //标识需要将筛选条件数据合并到主表单
        }
        if (this.gridImportTableConfig.coveragePattern) {
          //是否设置了明细选人覆盖模式
          this.gridImportCoveragePattern = true;
        }
        if (button.tempConfig && button.tempConfig.type) {
          this.gridImportModalType = button.tempConfig.type;
          this.gridImportSearchCustomcalc = this.$util.strIsTrue(
            button.tempConfig.customcalc
          );
          console.log("弹出表格定义", this.gridImportTableConfig);
          if (this.gridImportTableConfig.columns) {
            // 弹窗选择表格模式
            if (
              button.tempConfig.searchtemplate &&
              button.tempConfig.searchtemplate !== "0"
            ) {
              // 查询筛选列表视图
              this.gridImportModalButtons = [
                {
                  title: "筛选",
                  key: "search",
                  icon: "search"
                },
                {
                  title: "全选",
                  key: "selectAll",
                  icon: "check-square"
                },
                {
                  returnData: true,
                  title: "确定",
                  icon: "check",
                  key: "ok"
                }
              ];
              this.gridImportSelectTempId = button.tempConfig.searchtemplate;
              this.gridImportModalShow = true;
            } else {
              this.gridImportTableDatas = [];
              // 列表视图
              this.gridImportModalButtons = [
                {
                  title: "全选",
                  key: "selectAll",
                  icon: "check-square"
                },
                {
                  returnData: true,
                  title: "确定",
                  icon: "check",
                  key: "ok"
                }
              ];
              if (button.tempConfig.searchtemplate === "0") {
                this.onGridImportSelected();
              } else {
                this.getGridImportDataList(this.gridImportModalType); // 直接加载列表数据
              }
              this.gridImportModalShow = true;
            }
            return;
          } else if (button.tempConfig) {
            // 其他弹窗选人模式
            if (this.gridImportModalType === "CONDITIONSSELECT") {
              this.gridImportTableDatas = [];
              // 直接弹出筛选表单
              this.gridImportSelectTempId = button.tempConfig.templateId;
              this.gridImportSelectModalShow = true;
              return;
            }
          }
        }
        this.tipWarning("错误的“选人明细表”弹窗配置");
      }
    },
    /**
     * 导入明细表数据
     * @param type
     */
    getGridImportDataList(type) {
      this.gridImportModalLading = true;
      let that = this;
      let params = {
        action: "GetSearchTableList",
        tb_name: this.gridImportTableConfig.tableName, //主表名
        t_id: this.tempId,
        bm: this.gridImportTableConfig.businessMode,
        type: type ? type : "treeList",
        groupBy: this.gridImportTableConfig.groupBy, // 树形结构进行分组的字段
        randomKey: this.$store.getters.getRandomKey
      };
      this.post("Form.ashx", params, {
        success: function(req) {
          //console.log(req);
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? req.data:req;
          let dataList = reqData.data ? reqData.data.dataList : [];
          dataList.map(function(data, index) {
            // 给数组对象设置一个主键字段用于表格选择
            if (data.children && data.children.length > 0) {
              data.children.map(function(data1, index2) {
                return (data1[that.defaultTableKeyName] = index + "." + index2);
              });
            }
            if (that.gridImportTableConfig.groupby) {
              // 表示是树状列表
              data.SYSTEM_TREE_ROOT = "null"; // 关联字段标识为无效数据
            }
            return (data[that.defaultTableKeyName] = index + "");
          });
          // 是否包含了主表单附加备选数据
          if (
            that.$util.isType(req.mainData) === "Array" &&
            req.mainData.length > 0
          ) {
            that.gridImportSearchMainResultCache = req.mainData;
          }
          that.gridImportTableDatas = dataList;
          that.gridImportModalLading = false;
        },
        error: function(err) {
          console.log(err);
          that.gridImportModalLading = false;
          that.alertError("请求失败," + err.message);
        }
      });
    },
    /**
     * 调用GetConditionView，接口
     * @param selectData
     * @param callBack
     */
    getConditionView(selectData, callBack) {
      console.log("选择了条件data:", selectData);
      // 选中了类型
      this.gridImportModalLading = true;
      let that = this;
      let params = {
        action: "GetConditionView",
        tb_name: this.gridImportTableConfig.tableName, //主表名
        t_id: this.gridImportTableConfig.templateId,
        bm: this.gridImportTableConfig.businessMode,
        customcalc: this.gridImportSearchCustomcalc,
        type: this.gridImportModalType,
        groupBy: this.gridImportTableConfig.groupby, // 树形结构进行分组的字段
        configType: this.gridImportTableConfig.configType,
        randomKey: this.$store.getters.getRandomKey
      };
      if (selectData && selectData.data) {
        params.search = JSON.stringify(selectData.data); // 查询条件
      }
      if (
        this.gridImportSearchCustomcalc &&
        selectData &&
        selectData.dataSource
      ) {
        // 需要附加数据的选择行
        params.dataSource = JSON.stringify(selectData.dataSource);
      }
      this.request("Form.ashx", params, {
        type: selectData ? selectData.requestType : null,
        success: function(req) {
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? req.data:req;
          if (callBack && that.$util.isType(callBack) === "Function")
            callBack(reqData);
          that.gridImportModalLading = false;
          if (
            selectData &&
            selectData.resolve &&
            that.$util.isType(selectData.resolve) === "Function"
          )
            selectData.resolve();
        },
        error: function(err) {
          console.log(err);
          if (
            selectData &&
            selectData.resolve &&
            that.$util.isType(selectData.resolve) === "Function"
          )
            selectData.resolve(1);
          that.gridImportModalLading = false;
          that.alertError("请求失败," + err.message);
        }
      });
    },

    /**
     * 点击“明细表导入”弹出窗内的"筛选"按钮
     * 打开的选择弹窗后，点窗口的“确定”按钮的事件响应方法
     * @param selectData
     */
    onGridImportSelected(selectData) {
      console.log("选择了条件data:", selectData);
      let that = this;
      this.getConditionView(selectData, function(req) {
        if (req) {
          // 是否包含了主表单附加备选数据
          if (
            that.$util.isType(req.mainData) === "Array" &&
            req.mainData.length > 0
          ) {
            that.gridImportSearchMainResultCache = req.mainData;
          }
          if (
            that.gridImportModalType === "SEARCHLIST" ||
            that.gridImportModalType === "TREELISTVIEW"
          ) {
            // 查询筛选视图
            let dataList = req.data;
            dataList.map(function(data, index) {
              if (data.children && data.children.length > 0) {
                data.children.map(function(data1, index2) {
                  return (data1[that.defaultTableKeyName] =
                    index + "." + index2);
                });
              }
              if (that.gridImportTableConfig.groupby) {
                // 表示是树状列表
                data.SYSTEM_TREE_ROOT = "null"; // 关联字段标识为无效数据
              }
              // 给数组对象设置一个主键字段用于表格选择
              return (data[that.defaultTableKeyName] = index + "");
            });
            if (selectData && selectData.data)
              // 把筛选表单的查询条件，附加给主表单响应的字段
              that.gridImportSearchFileds = selectData.data;
            that.gridImportTableDatas = dataList;
          } else if (that.gridImportModalType === "CONDITIONSSELECT") {
            // 直接筛选表单视图
            // 需要从备选主表单数据中获取主表单注入数据
            let injectionData = that.handleSelectedDetailDatasRelated(
              req.data.chrenildTable
            );
            if (req.data.mainTable)
              injectionData = Object.assign(injectionData, req.data.mainTable);
            // 将查询结果直接赋给表单
            if (
              selectData &&
              selectData.resolve &&
              that.$util.isType(selectData.resolve) === "Function"
            ) {
              that.gridImportSetToFormDatas(
                req.data.chrenildTable,
                injectionData,
                selectData.resolve
              );
            } else {
              that.gridImportSetToFormDatas(
                req.data.chrenildTable,
                injectionData,
                function(v) {
                  if (v) {
                    that.alertWarning("未查询到明细数据，请选择其他查询条件");
                  }
                }
              );
            }
          }
        }
      });
    },
    /**
     * 从返回的请求数据中分离出需要注入到主表的数据,
     * 只取选中的明细中第一个可以找到对应的主表数据
     * @param req
     * @return {*}
     */
    handleSelectedDetailDatasRelated(selected) {
      //如果返回了明细相关主表单数据，则需要去匹配需要注入到主表单的数据
      if (
        this.$util.isType(this.gridImportSearchMainResultCache) === "Array" &&
        this.gridImportSearchMainResultCache.length > 0
      ) {
        if (this.$util.isType(selected) === "Array" && selected.length > 0) {
          let mainData = {};
          let isok = false;
          let that = this;
          selected.forEach(function(detailData) {
            let zbid = detailData["_ZBID"]
              ? detailData["_ZBID"].toString()
              : "";
            if (zbid) {
              that.gridImportSearchMainResultCache.forEach(function(main) {
                let id = main["ID"] ? main["ID"].toString() : "0";
                if (id === zbid) {
                  mainData = Object.assign({}, main);
                  delete mainData.ID;
                  isok = true;
                  return;
                }
              });
            }
            if (isok) {
              return;
            }
          });
          if (isok) {
            return mainData;
          }
        }
      } else {
        return {};
      }
    },
    /**
     * 点击“明细表导入”弹出窗内的按钮的事件响应方法
     * @param rdata
     */
    onClickTableModal(rdata) {
      console.log("点击了按钮：", rdata);
      //debugger
      if (rdata.key === "search") {
        // 点击筛选，弹出查询条件选择框
        rdata.resolve();
        this.gridImportSelectModalShow = true;
      } else if (rdata.key === "selectAll") {
        // 点击全选按钮
        if (this.gridImportTableSelectedKeys === "SELECTALL")
          this.gridImportTableSelectedKeys = [];
        else this.gridImportTableSelectedKeys = "SELECTALL";
        rdata.resolve();
      } else if (rdata.key === "ok") {
        // 点击确定按钮
        if (rdata.data && rdata.data.length > 0) {
          if (this.gridImportSearchCustomcalc) {
            // 还要再次附加数据
            let that = this;
            this.getConditionView(
              {
                requestType: "POST",
                dataSource: rdata.data,
                data: this.gridImportSearchFileds,
                resolve: rdata.resolve
              },
              function(req) {
                //debugger
                if (req && req.data) {
                  let mainFJdata = req.data.mainTable;
                  if (that.gridImportsearchBring) {
                    if (mainFJdata)
                      mainFJdata = Object.assign(
                        mainFJdata,
                        that.gridImportSearchFileds
                      );
                    else mainFJdata = that.gridImportSearchFileds;
                  }
                  that.gridImportSetToFormDatas(
                    req.data.chrenildTable,
                    mainFJdata,
                    rdata.resolve
                  );
                }
              }
            );
          } else {
            // 列表中直接将选中数据注入表单
            let result = false;
            let mainDefaultLinked = {};
            //是否配置了默认主表单联动带人字段
            if (
              this.gridDefaultLinkedMainFiled &&
              this.$util.isType(this.gridDefaultLinkedMainFiled) === "Array" &&
              this.gridDefaultLinkedMainFiled.length > 0
            ) {
              let dataOne = rdata.data[0];
              for (let n = 0; n < this.gridDefaultLinkedMainFiled.length; n++) {
                if (dataOne[this.gridDefaultLinkedMainFiled[n]])
                  mainDefaultLinked[this.gridDefaultLinkedMainFiled[n]] =
                    dataOne[this.gridDefaultLinkedMainFiled[n]];
              }
            }
            // 需要从备选主表单数据中获取主表单注入数据
            let injectionData = this.handleSelectedDetailDatasRelated(
              rdata.data
            );

            if (this.gridImportsearchBring) {
              mainDefaultLinked = Object.assign(
                mainDefaultLinked,
                this.gridImportSearchFileds,
                injectionData
              );
              this.gridImportSetToFormDatas(
                rdata.data,
                mainDefaultLinked,
                rdata.resolve
              );
            } else {
              mainDefaultLinked = Object.assign(
                mainDefaultLinked,
                injectionData
              );
              result = this.gridImportSetToFormDatas(
                rdata.data,
                mainDefaultLinked,
                rdata.resolve
              );
            }
          }
        } else {
          this.tipWarning("未选择明细数据");
          rdata.resolve();
        }
      }
    },
    /**
     * 检查明细表选人是否满足唯一性选择条件
     * @param mxdataList 明细数据数组
     * @param mainData 需要注入到主表的数据
     * @param tableName 对应明细表
     * @returns {boolean}
     */
    checkDetailDatasSelectedUnique(mxdataList, mainData, tableName) {
      if (this.gridImportTableConfig.detailForeignKey && tableName) {
        // 如果配置了筛选唯一性约束，
        // 目前已经选人的明细；有个问题，detailForeignKey，则无法进行比较
        let selectUniqueValueField = this.gridImportTableConfig.detailForeignKey
          .mainField; // 明细表与主表的外键关联字段
        let foreignKey = this.gridImportTableConfig.detailForeignKey
          .detailField; // 明细表与主表的外键关联字段
        let title = this.gridImportTableConfig.detailForeignKey.FieldTitle;
        let foreignKeyValue = this.form.getFieldValue(selectUniqueValueField); // 已经选择的外键值
        //检测是否是多个明细表选人时，都是控制关联同一个主表字段作为外键作为唯一性选人条件
        let needConnectCheckTables = [];
        if(this.gridImportLinkMainSameForeignKey.length>0) {
          let ishave = this.gridImportLinkMainSameForeignKey.findIndex(function(ddtemp){
            return ddtemp.tableName!==tableName && ddtemp.detailForeignKey.mainField === selectUniqueValueField;
          });
          if(ishave>=0) {
            this.gridImportLinkMainSameForeignKey.push(this.$util.deepCopy(this.gridImportTableConfig));
            this.gridImportLinkMainSameForeignKey.forEach(function(dtemp){
              needConnectCheckTables.push({
                tableName: dtemp.tableName,
                foreignKey: dtemp.detailForeignKey.detailField
              });
            });
          }
        } else {
          this.gridImportLinkMainSameForeignKey.push(this.$util.deepCopy(this.gridImportTableConfig));
        }

        let selectedDetialDatas = this.getMxTableData();
        let tempList = this.$util.deepCopy(mxdataList);
        let newSelected;
        let selected = null;
        // 有多个选人明细表数据需要校验唯一性控制字段
        if(needConnectCheckTables.length>1) {
          needConnectCheckTables.forEach(function(tempData){
            let selecedTemp = selectedDetialDatas.find(function(ds) {
              return ds.tableName === tempData.tableName;
            });
            if(selecedTemp) {
              if(selected) {
                selected = selected.concat(selecedTemp.data);
              } else {
                selected = selecedTemp.data;
              }
            }
          });
        } else {
          let tempTableData = selectedDetialDatas.find(function(ds) {
            return ds.tableName === tableName;
          });
          if(tempTableData) {
            selected = tempTableData.data;
          }
        }
        if (tempList && this.$util.isType(tempList) === "Array") {
          if (selected) {
            // 有已选
            newSelected = selected.map(function(v) {
              v[foreignKey] = foreignKeyValue;
              return v;
            });
            if(newSelected)
              tempList = tempList.concat(newSelected);
          }
        } else {
          if (selected) {
            // 有已选
            newSelected = selected.map(function(v) {
              v[foreignKey] = foreignKeyValue;
              return v;
            });
            tempList = newSelected;
          }
        }
        //let that = this;
        if (tempList && tempList.length > 0) {
          let oneValue = null;
          for (let i = 0; i < tempList.length; i++) {
            if (oneValue) {
              let tempOneValue = oneValue + "";
              let tempK = tempList[i][foreignKey] + "";
              if (tempOneValue !== tempK) {
                this.alertWarning("只能选择包含相同" + title + "的明细记录");
                return false;
              }
            } else {
              oneValue = tempList[i][foreignKey];
            }
          }
          foreignKeyValue = oneValue;
          // 把外键字段值关联到主表单注入对象
          if (
            mainData &&
            (newSelected == undefined ||
              newSelected == null ||
              newSelected.length == 0 ||
              mainData[selectUniqueValueField] == undefined) &&
            foreignKeyValue
          ) {
            // 如果原本未选人
            mainData[selectUniqueValueField] = foreignKeyValue;
          }
        }
      }
      // 如果设置了明细选入，唯一性控制配置
      if (this.gridImportTableConfig.selectUniqueConstraints) {
        let checkPrimaryKey = this.gridImportTableConfig.selectUniqueConstraints
          .checkPrimaryKey;
        let checkTableName = this.gridImportTableConfig.selectUniqueConstraints
          .checkSelectTable;
        let errorMessage = this.gridImportTableConfig.selectUniqueConstraints
          .checkError;
        if (checkPrimaryKey && checkPrimaryKey.length > 0 && checkTableName) {
          let selectedDetialDatas1 = this.getMxTableData();
          let selected1 = selectedDetialDatas1.find(function(ds) {
            return ds.tableName === checkTableName;
          });
          let tempList1 = this.$util.deepCopy(mxdataList);
          if (selected1 && selected1.data.length > 0 && tempList1.length > 0) {
            let oldSelect = [].concat([], selected1.data);
            let selectKeys = this.setCheckPrimaryKey(
              oldSelect,
              checkPrimaryKey
            );
            this.setCheckPrimaryKey(tempList1, checkPrimaryKey);
            let isCannot = false;
            tempList1.forEach(sdata => {
              if (selectKeys.includes(sdata._PrimaryKey)) {
                this.alertWarning(
                  errorMessage
                    ? errorMessage
                    : "不能重复选入已经在明细表中的记录"
                );
                isCannot = true;
                return false;
              }
            });
            if (isCannot) {
              return false;
            }
          }
        }
      }
      this.organizeCheckCondition(tableName, mxdataList);
      return true;
    },

    /**
     * 检验条件,功能待扩展，目前只支持SUM函数和普通计算表达式
     * @param tableName
     * @param mxdataList
     */
    judgeCheckCondition(tableName, mxdataList) {
      if (
        this.gridImportSelectCheckCondition &&
        this.gridImportSelectCheckCondition[tableName]
      ) {
        let oldKeys = Object.keys(
          this.gridImportSelectCheckCondition[tableName]
        );
        if (oldKeys.length > 0) {
          let selectedDetialDatas = this.getMxSourceData(); // 将明细表中已选人数据获得
          let selectedList = [];
          if (selectedDetialDatas) {
            // 已选人一些数据了,给已选人数据设置PrimaryKey
            let selected = selectedDetialDatas.find(function(ds) {
              return ds.tableName === tableName;
            });
            selectedList = selected.data;
            if (selectedList && selectedList.length > 0) {
              this.setCheckPrimaryKey(
                selectedList,
                this.gridImportTableConfig.selectCheckCondition.checkPrimaryKey
              );
            }
          }
          let tempSelectedList = selectedList.concat(mxdataList);
          for (let r = 0; r < oldKeys.length; r++) {
            //console.log(oldKeys[r] + "...." + this.gridImportSelectCheckCondition[oldKeys[r]]);
            let condition = this.gridImportSelectCheckCondition[tableName][
              oldKeys[r]
            ];
            let checkKey = oldKeys[r];
            let selecteds = tempSelectedList.filter(function(d) {
              return d._PrimaryKey === checkKey;
            });
            if (selecteds.length > 0) {
              let reg = new RegExp("[&{2},|{2},>,<,!=,={2},<=,>=,=>,=<]", "ig");
              let calculations = condition.split(reg); // 将逻辑表达式进行分解
              calculations = calculations.filter(function(d) {
                return d !== "";
              });
              if (calculations.length > 0) {
                // 是逻辑表达式
                let that = this;
                calculations.forEach(function(culation) {
                  let sumReg = new RegExp("(SUM\\()(.*?)(\\))", "ig");
                  if (sumReg.test(culation)) {
                    // 是合计公式
                    let exp = culation.slice(4).slice(0, -1); // 获取SUM函数内的表达式
                    let sumValues = [];
                    if (selecteds.length > 0) {
                      selecteds.forEach(function(d) {
                        let fValue1 = d[exp]
                          ? that.getValidTypesValueString(d[exp])
                          : "0";
                        sumValues.push(fValue1);
                      });
                      let sum = that.$util.mathUtil.sumArray(sumValues) + "";
                      condition = condition.replace(culation, sum);
                    }
                  }
                  /*if (selecteds.length > 0 && culation) {
                    let tempConditions = [];
                    for (let i = 0; i < that.gridImportCheckConditionConfig.variableFields.length; i++) {
                      let variable = that.gridImportCheckConditionConfig.variableFields[i];
                      if (!variable.tableName && culation.indexOf(variable.oldArgName)>=0) {
                        for (let j = 0; j < selecteds.length; j++) {
                          // 每行数据都需要校验
                          let fValue = selecteds[variable.oldArgName]
                            ? that.getValidTypesValueString(
                              selecteds[variable.oldArgName]
                            )
                            : "0";
                          let thisCondition = culation;
                          if (tempConditions[j]) {
                            thisCondition = tempConditions[j];
                          }
                          thisCondition = thisCondition.replace(
                            variable.oldArgName,
                            fValue
                          );
                          culation = thisCondition;
                        }
                      }
                    }
                  }*/
                });
                console.log("明细选人后的结果", condition);
                let result = eval(condition);
                if (this.$util.isType(result) === "Boolean") {
                  return result;
                }
              }
            }
          }
          return false;
        }
      }
      return false;
    },
    /**
     * 组织选入明细校验条件
     * checkSelectTable
     * checkPrimaryKey
     * checkFormula
     * checkError
     */
    organizeCheckCondition(tableName, mxdataList) {
      if (
        this.gridImportTableConfig &&
        this.gridImportTableConfig.selectCheckCondition &&
        tableName &&
        this.$util.isType(mxdataList) === "Array" &&
        mxdataList.length > 0
      ) {
        let tempList = this.$util.deepCopy(mxdataList);
        // 配置了选人校验条件
        if (!this.gridImportSelectCheckCondition) {
          this.gridImportSelectCheckCondition = {};
          this.gridImportSelectCheckCondition[tableName] = {};
        } else if (!this.gridImportSelectCheckCondition[tableName]) {
          this.gridImportSelectCheckCondition[tableName] = {};
        }
        if (
          this.gridImportTableConfig.selectCheckCondition.checkSelectTable ===
            tableName &&
          this.gridImportTableConfig.selectCheckCondition.checkPrimaryKey &&
          this.gridImportTableConfig.selectCheckCondition.checkFormula
        ) {
          // 如果操作的是对应的明细表
          let reg = new RegExp("[&{2},|{2},>,<,!=,={2},<=,>=,=>,=<]", "ig");
          let pp = this.gridImportTableConfig.selectCheckCondition.checkFormula.split(
            reg
          );
          console.log("解析公式", pp);
          if (pp.length > 0) {
            // 涉及到的数据主键
            let allKey = this.setCheckPrimaryKey(
              tempList,
              this.gridImportTableConfig.selectCheckCondition.checkPrimaryKey
            );
            if (this.$util.arrayIsRepeat(allKey)) {
              this.alertWarning("配置的判重主键无法标识唯一一条明细记录");
              return false;
            }
            // 将对应主键的数据的检验公式中的需要源数据介入的公式进行数据替换
            let tempSelectCheckCondition = {};
            let checkCondition = this.getSaveCondition(
              this.gridImportTableConfig.selectCheckCondition.checkFormula
            );
            this.gridImportCheckConditionConfig = checkCondition;
            let condition = checkCondition.saveCondition;

            let sourceFields = [];
            // 从公式中过滤出需要到选人源中去找的对应字段
            for (let n = 0; n < checkCondition.variableFields.length; n++) {
              if (
                checkCondition.variableFields[n].tableName &&
                checkCondition.variableFields[n].tableName === "this"
              ) {
                sourceFields.push(checkCondition.variableFields[n]);
              }
            }
            // 根据key去组织校验条件
            for (let j = 0; j < allKey.length; j++) {
              if (sourceFields.length > 0) {
                // 需要去源选人列表中找字段进行替换
                let sourceObj = tempList.find(function(d) {
                  return d._PrimaryKey === allKey[j];
                });
                for (let i = 0; i < sourceFields.length; i++) {
                  let variable = sourceFields[i];
                  let sourceValue = sourceObj[variable.fieldName]
                    ? this.getValidTypesValueString(
                        sourceObj[variable.fieldName]
                      )
                    : "0";
                  let temp = condition + "";
                  if (tempSelectCheckCondition[allKey[j]]) {
                    temp = tempSelectCheckCondition[allKey[j]];
                    tempSelectCheckCondition[allKey[j]] = temp.replace(
                      variable.oldArgName,
                      sourceValue
                    );
                  } else {
                    tempSelectCheckCondition[allKey[j]] = temp.replace(
                      variable.oldArgName,
                      sourceValue
                    );
                  }
                }
              } else {
                tempSelectCheckCondition[allKey[j]] = condition + "";
              }
            }
            let oldKeys = Object.keys(
              this.gridImportSelectCheckCondition[tableName]
            );
            if (oldKeys.length > 1) {
              for (let r = 0; r < oldKeys.length; r++) {
                //console.log(oldKeys[r] + "...." + this.gridImportSelectCheckCondition[oldKeys[r]]);
                if (!tempSelectCheckCondition[oldKeys[r]]) {
                  tempSelectCheckCondition[
                    oldKeys[r]
                  ] = this.gridImportSelectCheckCondition[tableName][
                    oldKeys[r]
                  ];
                }
              }
            }
            this.gridImportSelectCheckCondition[
              tableName
            ] = tempSelectCheckCondition;
            console.log(
              "每条满足条件的数据需要校验的公式：",
              this.gridImportSelectCheckCondition
            );
          } else {
            console.log("配置的公式为非逻辑运算表达式");
          }
        }
      }
    },
    /**
     * 将明细选择结果赋值给表单
     * @param mxdataList
     * @param mainData
     * @param importResolve
     */
    gridImportSetToFormDatas(mxdataList, mainData, importResolve) {
      // 检测选入限制条件
      let checkMainImportUnique = this.checkDetailDatasSelectedUnique(
        mxdataList,
        mainData,
        this.gridImportTableConfig.mxTable
      );
      if (checkMainImportUnique && mxdataList && mxdataList.length > 0) {
        // 有明细数据导入
        // 如果配置了筛选唯一性约束，
        try {
          // 将数据注入明细表之前做操作
          if (
            this.thisFromEvents &&
            this.thisFromEvents.detailDataImportBefore
          ) {
            this.thisFromEvents.detailDataImportBefore(this, mainData);
          }

          if (
            this.thisFromEvents &&
            this.thisFromEvents.beforeDetailDataImport
          ) {
            let that = this;
            new Promise((resolve, reject) => {
              that.thisFromEvents.beforeDetailDataImport(
                that,
                mainData,
                mxdataList,
                {
                  ok: resolve,
                  cancel: reject
                }
              );
            })
              .then(function(value) {
                console.log(
                  "beforeDetailDataImport---------->明细插入前异步处理成功",
                  value
                );
                // 继续
                that.gridImportSetDetailList(value);
                if (mainData && that.mainTableData) {
                  // 将搜索条件并入主表单响应字段
                  let tempObj = mainData;
                  tempObj = Object.assign({}, that.mainTableData, tempObj);
                  that.mainTableData = tempObj;
                }
                importResolve();
              })
              .catch(function(value) {
                console.log(
                  "beforeDetailDataImport---------->明细插入前异步处理失败",
                  value
                );
                if (value) {
                  that.alertError(value + "");
                }
                importResolve(1);
              });
          } else {
            this.gridImportSetDetailList(mxdataList);
            if (mainData && this.mainTableData) {
              // 将搜索条件并入主表单响应字段
              let tempObj = mainData;
              tempObj = Object.assign({}, this.mainTableData, tempObj);
              this.mainTableData = tempObj;
            }
            importResolve();
          }
        } catch (e) {
          console.error(e);
          importResolve(1);
        }
      } else if (
        checkMainImportUnique &&
        mainData &&
        this.$util.isValidObject(mainData)
      ) {
        // 只需要导入主表单数据
        try {
          if (
            this.thisFromEvents &&
            this.thisFromEvents.detailDataImportBefore
          ) {
            this.thisFromEvents.detailDataImportBefore(this, mainData);
          }
          if (
            this.gridImportCoveragePattern &&
            this["setMxTableData"] &&
            (!mxdataList || mxdataList.length == 0)
          ) {
            // 覆盖模式下需要清空数据
            this.clearMxDatas(this.gridImportTableConfig.mxTable); // 重新设置数据清空
          }
          if (mainData && this.mainTableData) {
            // 将搜索条件并入主表单响应字段
            let tempObj = mainData;
            tempObj = Object.assign({}, this.mainTableData, tempObj);
            this.mainTableData = tempObj;
          }
          importResolve();
        } catch (e) {
          console.error(e);
          importResolve(1);
        }
      } else {
        // 没有选人或者不满足条件
        if (
          this.gridImportCoveragePattern &&
          this["setMxTableData"] &&
          (!mxdataList || mxdataList.length == 0)
        ) {
          //覆盖模式下需要清空数据
          this.clearMxDatas(this.gridImportTableConfig.mxTable); // 重新设置数据清空
        }
        importResolve(1);
      }
    },
    /**
     * 将数组注入对应明细表
     * @param mxdataList
     */
    gridImportSetDetailList(mxdataList) {
      let mxdata = [
        {
          tableName: this.gridImportTableConfig.mxTable,
          datas: mxdataList
        }
      ];
      if (this["addMxTableData"] && !this.gridImportCoveragePattern) {
        this.addMxTableData(mxdata, true); // 追加数据
      } else if (this.gridImportCoveragePattern && this["setMxTableData"]) {
        this.setMxTableData(mxdata); // 重新设置数据
      }
      if (this["setUpOrDowmTableDataByName"]) {
        // 针对上下表视图的数据选人方法
        this.setUpOrDowmTableDataByName(mxdata);
      }
    },
    /**
     * 根据规矩将对应的明细数据进行拆分
     * @param rSourceList 明细数据列表
     * @param checkField 对应关联一条需要拆分的数据的关键字
     * @param splitField 拆分分数
     * @param allSplitCheckFieldValues 所有需要拆分的数据的关键字数组
     * @param {String/Array} otherSplitFileds 其他需要拆分的数据字段名
     */
    detailDataQuantitySplit(
      rSourceList,
      checkField,
      splitField,
      allSplitCheckFieldValues,
      otherSplitFileds
    ) {
      if (
        rSourceList &&
        rSourceList.length > 0 &&
        checkField &&
        splitField &&
        allSplitCheckFieldValues &&
        allSplitCheckFieldValues.length > 0
      ) {
        const tempList = this.$util.deepCopy(rSourceList);
        let tempOtherFileds;
        let tempType = this.$util.isType(otherSplitFileds);
        let otherSplitFieldNames;
        if (tempType === "String" || tempType === "Array") {
          tempOtherFileds = {};
          otherSplitFieldNames = [];
          if (tempType === "String") {
            tempOtherFileds[otherSplitFileds] = [];
            otherSplitFieldNames.push(otherSplitFileds);
          } else {
            for (let r = 0; r < otherSplitFileds.length; r++) {
              tempOtherFileds[otherSplitFileds[r]] = [];
              otherSplitFieldNames.push(otherSplitFileds[r]);
            }
          }
        }
        let result = [];
        for (let i = 0; i < tempList.length; i++) {
          let cFieldV = this.$util.trimStr(tempList[i][checkField]);
          let sFieldV = this.$util.getNumber(tempList[i][splitField]);
          if (otherSplitFieldNames) {
            otherSplitFieldNames.forEach(f => {
              let yjje = this.$util.getNumber(tempList[i][f]);
              let tempOtherObj = {
                key: cFieldV,
                yje: yjje ? yjje : 0,
                yfje: 0
              };
              tempOtherFileds[f].push(tempOtherObj);
            });
          }
          if (
            allSplitCheckFieldValues.includes(cFieldV) &&
            sFieldV &&
            sFieldV > 1
          ) {
            // 需要拆分的行
            let fs = Math.floor(sFieldV); // 需要拆分成多少个
            for (let j = 0; j < fs; j++) {
              let newObj = this.$util.deepCopy(tempList[i]);
              newObj["_SFAYCFCRK"] = 1;
              if (j < fs - 1) {
                newObj[splitField] = 1;
              } else {
                // 最后一份
                newObj[splitField] = sFieldV - fs + 1;
              }
              //其他需要拆分的字段值
              if (otherSplitFieldNames) {
                if (j < fs - 1) {
                  otherSplitFieldNames.forEach(f => {
                    let otherObj = tempOtherFileds[f].find(function(ofObject){
                      return ofObject.key === cFieldV;
                    });
                    if (otherObj) {
                      let zs = otherObj.yje;
                      newObj[f] = this.$util.mathUtil.accDiv(zs, fs).toFixed(3);
                      otherObj.yfje = this.$util.mathUtil.accAdd(
                        otherObj.yfje,
                        newObj[f]
                      );
                    }
                  });
                } else {
                  otherSplitFieldNames.forEach(f1 => {
                    let otherObj1 = tempOtherFileds[f1].find(function(ofObject1){
                      return ofObject1.key === cFieldV;
                    });
                    let zs1 = otherObj1.yje;
                    let ls1 = otherObj1.yfje;
                    if (zs1) {
                      newObj[f1] = this.$util.mathUtil
                        .accSub(zs1, ls1)
                        .toFixed(3);
                      otherObj1.yfje = zs1;
                    }
                  });
                }
              }
              result.push(newObj);
            }
          } else {
            result.push(tempList[i]);
          }
        }
        return result;
      }
      return rSourceList;
    },
    /**
     * 清空指定明细表数据
     * @param mxTable
     */
    clearMxDatas(mxTable) {
      this.setMxTableData([
        {
          tableName: mxTable,
          datas: []
        }
      ]);
    }
  }
};
