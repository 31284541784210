<!-- 用户本身发出的表单、以及他人发出需要本人审批、或查看的表单记录列表 -->
<template>
  <div>
    <div v-if="showToolbar" class="form-headButton">
      <!-- 授权操作按钮 -->
      <e-toolbar
        :buttons="buttons"
        :size="$config.defaultControlSize"
        :operationDisabled="operationDisabled"
        @click="onButtonClick"
      ></e-toolbar>
    </div>
    <div class="tab-view-context context-my-e1" :class="getContentMarginClass">
      <!-- 表单头 -->
      <e-head-title
        :title="title"
        :size="$config.defaultControlSize"
      ></e-head-title>
      <div class="view-context">
        <div class="view-table-filter">
          <e-table-content-filter
            ref="screenViewTable"
            @screen="
              data => {
                tableDatas = data;
              }
            "
            :proxy="true"
            :columns="thisColumns"
            :dataSource="tableDatas"
            :disabled="tableSpin"
            @proxy="searchByKey"
          ></e-table-content-filter>
        </div>
        <div class="view-table" :class="getSizeClass">
          <vxe-grid
            ref="tableRef"
            show-overflow
            :stripe="$config.table_stripe"
            :tooltip-config="vxeTabletooltipConfig"
            border
            resizable
            highlight-hover-row
            highlight-current-row
            :empty-render="vxeTableEmptyRender"
            header-row-class-name="e-table-header_style"
            header-cell-class-name="e-table-header-cell_style"
            row-class-name="e-table-row_style"
            :size="tableSize_MiXin"
            maxHeight="100%"
            height="auto"
            :loading="tableSpin"
            :data="tableDatas"
            @sort-change="onSortChange"
          >
            <template v-for="col in thisColumns">
              <vxe-table-column
                v-if="col.scopedSlots"
                :key="col.dataIndex"
                v-bind="col"
              >
                <!--如果定义了字段格式，则按照格式进行渲染-->
                <template slot-scope="slotProps">
                  <e-table-colum
                    v-if="col.scopedSlots && col.scopedSlots.clickMethod"
                    :column="col"
                    :slotScopeData="slotProps.row"
                    @onClick="
                      col.scopedSlots.clickMethod({ record: slotProps.row })
                    "
                  ></e-table-colum>
                  <e-table-colum
                    v-else
                    :column="col"
                    :slotScopeData="slotProps.row"
                  ></e-table-colum>
                </template>
              </vxe-table-column>
              <vxe-table-column
                v-else
                :key="col.dataIndex"
                v-bind="col"
              ></vxe-table-column>
            </template>
          </vxe-grid>
          <vxe-pager
            ref="vxePagerRef"
            class="e-table-page"
            background
            :size="tableSize_MiXin"
            :loading="tableSpin"
            :current-page="pagination.current"
            :page-size="pagination.pageSize"
            :page-sizes="pagination.pageSizes"
            :total="pagination.total"
            :layouts="pagination.layouts"
            @page-change="onTableChange"
          >
          </vxe-pager>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ETableColum from "@/components/ETableColum";
//import { tableThResizeable } from "@/controls/Table/TabelThResizeable.js"; // 表格头可变列宽控件
import { e1Notice } from "@/views/notice/mixins/e1Notice.js"; // e1的notice配置和操作
import { pageControls } from "@/mixins/pageControls.js"; // 引入一些界面操作方法
//页面初始化时执行方法
export default {
  mixins: [e1Notice, pageControls],
  components: {
    ETableColum
  },
  name: "Home",
  data() {
    return {
      noticeType: null,
      title: "",
      thisColumns: [],
      selectedRowKeys: [],
      rowKey: "ID", // 主键列
      operationDisabled: false,
      oldPageCurrent: this.$store.state.nowShowNoticePageCurrent,
      windowsSize: this.$util.findDimensions(),
      buttons: [
        {
          // 可用操作按钮
          name: "刷新",
          icon: "icon-refresh",
          tsbName: "tsbRefresh",
          type: "primary",
          loading: false
        }
      ]
    };
  },
  props: {
    notice: {
      type: [String, Number],
      default: null
    },
    oEvent: {
      type: Number,
      default: 0
    },
    showName: {
      type: String,
      default: null
    }
  },
  watch: {
    tableSpin: function(v) {
      if (!v) {
        // 数据加载完成后，将滚动条拉到顶部
        this.$refs.tableRef.scrollTo(0, 0);
      }
    }
  },
  computed: {
    getSizeClass: function() {
      if (this.$config.defaultControlSize) {
        if (this.$config.defaultControlSize === "small") {
          return "view-table__small";
        } else if (this.$config.defaultControlSize === "default") {
          return "view-table__default";
        } else if (this.$config.defaultControlSize === "large") {
          return "view-table__large";
        }
      }
      return "view-table__default";
    },
    // 根据当前设置的全局尺寸，改变界面样式
    getContentMarginClass: function() {
      return this.getClassNameBySize("homeContent", this.showToolbar);
    },
    showToolbar: function() {
      return !this.isNewTheme && this.checkToobleIsShow(this.buttons);
    }
  },
  created() {
    //console.log("请求参数：" + this.notice);
    //console.log("当前的页：" + this.oldPageCurrent);
    this.noticeType = this.notice;
    this.title = this.showName;
    if (this.oldPageCurrent != 1) {
      this.pagination.current = this.oldPageCurrent;
    }
    if (this.noticeType != null) {
      //console.log("菜单", this.title);
      if (this.tableConfig) {
        this.thisColumns = this.tableConfig.columns;
        this.rowKey = this.tableConfig.rowKey;
      } else {
        this.alertError("请先配置表格项");
      }
    }
  },
  mounted() {
    //console.log("表数据", this.oEvent);
    if (!this.$store.state.hasLogin) {
      return;
    }
    if (this.noticeType != null) {
      //console.log("菜单", this.title);
      if (this.tableConfig) {
        let that = this;
        that.$nextTick(function() {
          that.getTableDatas(
            that.noticeType,
            null,
            that.oEvent,
            that.pagination.current,
            that.pagination.pageSize
          );
        });
      } else {
        this.alertError("请先配置表格项");
      }
    }
  },
  methods: {
    /**
     * 触发排序事件
     * @param event
     */
    onSortChange(event) {
      console.log("触发排序事件", event);
      this.pagination.current = 1;
      if (event && event.field && event.order) {
        //event.field; 参与排序的字段;event.order;排序方式 "desc"
        this.orderBy = event.field + " " + event.order;
        this.getTableDatas(
          this.noticeType,
          null,
          this.oEvent,
          this.pagination.current,
          this.pagination.pageSize,
          this.searchStr,
          this.searchField,
          this.orderBy
        );
      } else {
        // 取消排序
        this.orderBy = null;
        this.getTableDatas(
          this.noticeType,
          null,
          this.oEvent,
          this.pagination.current,
          this.pagination.pageSize,
          this.searchStr,
          this.searchField
        );
      }
    },
    /**
     * 刷新列表
     * @param button
     */
    tsbRefresh(button) {
      this.orderBy = null;
      this.getTableDatas(
        this.noticeType,
        button,
        this.oEvent,
        this.pagination.current,
        this.pagination.pageSize
      );
      //this.reload();
    },
    /**
     * 响应表格变化事件，包括翻页，排序等
     * @param pagination
     */
    onTableChange(pagination) {
      //console.log("表变化事件", pagination);
      let that = this;
      this.onTableChangeMiXin(pagination, function() {
        that.selectedRowKeys = [];
        if (pagination.pageSize) {
          that.$store.commit("setNowShowNoticePageCurrent", pagination.current);
          that.getTableDatas(
            that.noticeType,
            null,
            that.oEvent,
            that.pagination.current,
            that.pagination.pageSize,
            that.searchStr,
            that.searchField,
            that.orderBy
          );
        } else {
          that.$store.commit("setNowShowNoticePageCurrent", 1);
          that.getTableDatas(that.noticeType, null, that.oEvent);
        }
      });
    },
    /**
     * 根据条件进行查询
     */
    searchByKey(value, filed, edit) {
      if ((filed && filed !== "") || edit) {
        this.searchStr = value;
        this.searchField = filed;
        this.pagination.current = 1;
        this.getTableDatas(
          this.noticeType,
          null,
          this.oEvent,
          this.pagination.current,
          this.pagination.pageSize,
          this.searchStr,
          this.searchField,
          this.orderBy
        );
      } else if (!edit) {
        this.tipWarning("请选择筛选项");
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/home.less";
</style>
