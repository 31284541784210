<!-- 用户打开视图的入口 -->
<template>
  <!-- 视图表格 -->
  <div style="height: 100%;">
    <!-- 会计记账凭证编辑表单 -->
    <keep-accounts-form
      v-if="viewType == 'keepAccountsView' && pageConfig"
      :pageConfig="pageConfig"
      :title="title"
    ></keep-accounts-form>
    <!-- 普通数据视图 -->
    <data-view
      v-else-if="viewType == 'View' && pageConfig"
      :pageConfig="pageConfig"
      :title="title"
    ></data-view>
    <!-- 数据查询视图 -->
    <search-view
      v-else-if="viewType == 'seachView' && pageConfig"
      :pageConfig="pageConfig"
      :title="title"
    ></search-view>
    <!-- 树状表格视图 -->
    <tree-table-view
      v-else-if="viewType == 'treeTableView' && pageConfig"
      :pageConfig="pageConfig"
      :title="title"
    ></tree-table-view>
    <!-- 树状列表视图 -->
    <tree-list-view
      v-else-if="viewType == 'treeView' && pageConfig"
      :pageConfig="pageConfig"
      :title="title"
    ></tree-list-view>
    <!-- 树状表单编辑视图 -->
    <tree-form-view
      v-else-if="
        (viewType == 'treeFormView' || viewType == 'TreeViewSelect') &&
          pageConfig
      "
      :pageConfig="pageConfig"
      :viewTitle="title"
    ></tree-form-view>
    <!-- 报表视图 -->
    <RdlReportView
      v-else-if="viewType == 'reportView' && pageConfig"
      :pageConfig="pageConfig"
    ></RdlReportView>
    <!-- 权限视图 -->
    <AuthorityView
      v-else-if="viewType == 'AuthorityView' && pageConfig"
      :pageConfig="pageConfig"
    ></AuthorityView>
    <!-- 被引用关键基础数据不可修改性保护视图 -->
    <CriticalDataProtectionView
      :pageConfig="pageConfig"
      v-else-if="viewType == 'CriticalDataProtectionView' && pageConfig"
    ></CriticalDataProtectionView>
    <!-- 业务流配置视图 -->
    <BusinessFlowConfigView
      :pageConfig="pageConfig"
      v-else-if="viewType == 'BusinessFlowConfigView' && pageConfig"
    ></BusinessFlowConfigView>
    <!-- 上下两个表单的视图 UpAndDownListView -->
    <up-and-down-list-view
      v-else-if="viewType == 'upAndDownListView' && pageConfig"
      :pageConfig="pageConfig"
      :title="title"
    ></up-and-down-list-view>
    <!-- 错误视图 -->
    <a-empty
      v-else
      image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
    >
      <span slot="description"> 错误的视图 </span>
      <a-button type="primary" @click="closeView">关闭</a-button>
    </a-empty>

    <e-file-preview-modal
      v-model="showFilePreview"
      title="文件预览"
      :Files="nowShowFiles"
    ></e-file-preview-modal>
  </div>
</template>

<script>
import DataView from "@/views/listView/DataView"; // 普通查询视图表格
import TreeTableView from "@/views/listView/TreeTableView"; // 树状表格视图表格
import TreeListView from "@/views/listView/TreeListView"; // 左侧树状列表视图表格
import SearchView from "@/views/listView/SearchView"; // 查询视图表格
import TreeFormView from "@/views/listView/TreeFormView"; // 树状表单编辑视图（左侧数据树，右侧为点中数据对应的编辑表单）
import RdlReportView from "@/views/listView/RdlReportView.vue";
import AuthorityView from "@/views/listView/AuthorityView.vue";
import UpAndDownListView from "@/views/listView/UpAndDownListView.vue";
import KeepAccountsForm from "@/views/form/KeepAccountsForm.vue"; // 记账凭证登记表单
import CriticalDataProtectionView from "@/views/listView/CriticalDataProtectionView.vue";
import BusinessFlowConfigView from "@/views/listView/BusinessFlowConfigView.vue";

import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { pageDestroy } from "@/mixins/pageDestroy.js"; // 页面关闭清除缓存的方法

//页面初始化时执行方法
export default {
  mixins: [analyticalMenuAuth, pageControls, pageDestroy],
  name: "UserForms",
  components: {
    TreeTableView: TreeTableView,
    TreeFormView: TreeFormView,
    DataView: DataView,
    TreeListView: TreeListView,
    SearchView: SearchView,
    RdlReportView: RdlReportView,
    AuthorityView: AuthorityView,
    UpAndDownListView: UpAndDownListView,
    KeepAccountsForm: KeepAccountsForm,
    CriticalDataProtectionView: CriticalDataProtectionView,
    BusinessFlowConfigView: BusinessFlowConfigView
  },
  data() {
    return {
      viewType: "", // 视图类型
      pageConfig: null, // 视图配置
      loading: false,
      title: "",
      configType: null, // 视图配置参数类型，默认是从菜单带人，使用dataFromCache，表示是自定义配置式，默认存储在cache中
      showFilePreview: false,
      nowShowFiles: [] // 文件
    };
  },
  created() {
    console.log("请求参数：" + JSON.stringify(this.$route.query));
    console.log("路由属性：" + JSON.stringify(this.$route.params));
    this.title = this.$route.query.title;
    if (!this.title) {
      // 通过路径配置直接获取参数
      this.title = this.$route.params.formName;
      let _dhbm = this.$route.params.dhbm;
      if (_dhbm != null) {
        let pageConfig = this.getPageConfig(_dhbm);
        console.log("视图定义", pageConfig);
        if (pageConfig) {
          if (pageConfig.viewConfig) {
            //返回了viewInfo，表示该页面是一个视图
            this.viewType = pageConfig.viewConfig.viewType;
          }
          this.pageConfig = pageConfig;
        }
        console.log("打开的视图为", this.viewType);
      }
    } else {
      // 通过query请求参数传参
      this.configType = this.$route.query.type;
      if (this.configType && this.configType === "dataViewCache") {
        let pageConfig = this.getViewCache(this.getNowTabKey());
        console.log("2视图定义", pageConfig);
        if (pageConfig.viewConfig) {
          this.viewType = pageConfig.viewConfig.viewType;
        }
        this.pageConfig = pageConfig;
      }
      console.log("2打开的视图为", this.viewType);
    }
    if (this.viewType === "reportView") {
      this.hideToolBar();
    }
  },
  methods: {
    closeView() {
      this.closeTab(this.pageConfig.DHBM);
    },
    /**
     * 显示文件预览弹出窗
     * @param files
     */
    showPreviewModal(files) {
      this.nowShowFiles = files;
      this.showFilePreview = true;
    }
  }
};
</script>

<style scoped lang="less">
@import "../../assets/style/base/user-forms.less";
</style>
