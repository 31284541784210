<!--
 * 登录组件
 * @create wangxin 2018/07/08
-->
<template>
  <div class="body" style="margin: 0px !important;padding: 0px !important;">
    <img
      :src="'/img/loginShenSe/' + config.logoUrl"
      style="position: absolute;left: 40px;top: 40px;"
    />
    <div class="box">
      <div class="box-right">
        <div class="box-right-body">
          <a-form :form="form" :style="{ 'padding-left': '10%', 'padding-right': '10%' }">
            <a-form-item style="width:100%;text-align:left;">
              <div class="title-next">{{ config.welcome }}</div>
            </a-form-item>
            <a-form-item prop="account">
              <a-input
                v-decorator="userInputRules"
                :allowClear="true"
                placeholder="请输入您的账号"
                @change="trimInput"
                size="large"
                :style="{ padding: '10px 0' }"
                ref="userInput"
                @pressEnter="handleSubmit"
              >
                <template slot="prefix">
                  <e-icon
                    type="icon-wo"
                    :style="{
                      fontSize: '24px',
                      color: '#5A93FE',
                      'margin-left': '16px'
                    }"
                  />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                type="password"
                class="login-password-input"
                v-decorator="passInputRules"
                placeholder="请输入您的密码"
                @change="trimInput"
                :style="{ padding: '10px 0' }"
                @pressEnter="handleSubmit"
              >
                <template slot="prefix">
                  <e-icon
                    type="icon-suo"
                    :style="{
                      fontSize: '24px',
                      color: '#5A93FE',
                      'margin-left': '16px'
                    }"
                  />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-button
                type="primary"
                :loading="loading"
                :style="{
                  width: '100%',
                  margin: '30px 0',
                  height: '60px',
                  'border-radius': '30px',
                  'font-size': '20px',
                  'background-image':
                    'linear-gradient(to right, #569be8 , #351bc6)'
                }"
                @click.native.prevent="handleSubmit"
              >登录</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
    <div class="bottom-text">{{ config.companyName }}<a :href="config.archivalInformationUrl" target="_blank">{{config.archivalInformation}}</a></div>
  </div>
</template>

<script>
import { LOGIN_CONFIG } from "../loginSet.js";
import { loginMixins } from "../mixins/loginMixins.js";
//页面初始化时执行方法
export default {
  mixins: [LOGIN_CONFIG, loginMixins],
  name: "Login",
  data() {
    return {
      loading: false,
      config: window.LOGIN_CONFIG,
      userInputRules: [
        "username",
        {
          initialValue: "",
          rules: [{ required: true, message: "请输入账号" }]
        }
      ],
      passInputRules: [
        "password",
        {
          initialValue: "",
          rules: [{ required: true, message: "请输入密码" }]
        }
      ],
      form: this.$form.createForm(this, { name: "login校验" }),
      checked: true
    };
  },
  created() {
    // let _self = this;
    // document.onkeydown = function() {
    //   //用户按Enter，提交登录
    //   if (window.event.keyCode === 13) {
    //     _self.handleSubmit();
    //   }
    // };
  },
  mounted() {
    this.$refs.userInput.focus();
  },
  methods: {
    /**
     * 设置清空用户名输入的空格
     * @param e
     */
    trimInput(e) {
      let val = e.target.value;
      if ("" == this.$util.trimStr(val)) {
        e.target.value = "";
      }
    },
    handleSubmit() {
      this.login(this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.body {
  height: 100%;
  width: 100%;
  background: url(/img/loginShenSe/backgroud.jpg) center center no-repeat;
  background-size: 100% 100%;
  min-width: 1600px;
  position: relative;
  min-height: 800px;
}
.box {
  background: transparent;
  width: 800px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.box-left {
  float: left;
  width: 60%;
  height: 100%;
  // background: url("~@/assets/loginQianSe/backgroud2.jpg") no-repeat;
  background-size: 100% 100%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  position: relative;
}

.box-right {
  width: 100%;

  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;

  justify-content: center;
  align-items: center;
}

.login-container {
  width: 100%;
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
}

.logo span {
  color: #3c92ff;
  font-size: 32px;
}

.title {
  color: #3c91fe;
  font-size: 30px;
  letter-spacing: 5px;
  height: 60px;
  line-height: 60px;
  letter-spacing: 2px;
  padding-left: 10px;
}

.title-next {
  color: #3a393a;
  font-size: 28px;
  text-align: left;
  letter-spacing: 0px;
}

.bottom-text {
  color: #fff;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 10px;
  font-size: 16px;
  letter-spacing: 3px;
}

::v-deep .ant-input {
  background-color: #f3f8fe !important;
  padding: 0 70px !important;
  height: 60px;
  line-height: 50px;
  background-color: #f3f8fe;
  text-align: left;
  border: 0px;
  font-size: 18px;
  border-radius: 30px;
}

::v-deep .ant-input:focus,
::v-deep .ant-input:active {
  box-shadow: 0px 0px 10px 5px #d0d0fb !important;
  border-radius: 30px;
}

::v-deep .ant-form-explain {
  text-align: left;
  margin-left: 60px;
}

::v-deep .ant-form-explain:before {
    left: 65px !important;
}

::v-deep .ant-form-explain:after{
    left: 65px !important;
}
.box-right-body {
  width: 70%;
  padding: 50px 0px 10px 0px;
  background: #fff;
  border-radius: 15px;
}
</style>
