/**
 * 扩展列表列功能
 */
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
export const e1ExpendColum = {
  mixins: [pageControls],
  data() {
    return {};
  },
  created() {
    //console.log("混入e1ExpendColum的通用方法");
  },
  methods: {
    /**
     * 响应列表中数据-链接点击事件
     */
    columClick(event) {
      if (event) {
        if (this.$refs.tableRef) {
          this.$refs.tableRef.closeTooltip();
        }
        let linkMethod = event.linkMethod,
          value = event.text,
          rule,
          index = event.index,
          callBackMethod = event.callBack;
        rule = this.checkTextMeetASNGenerateRule(value);
        console.log("点击了链接", linkMethod, value, rule, index);
        if (linkMethod && this[linkMethod]) {
          if (rule) {
            // 满足表单编码规则
            this[linkMethod](value, rule, callBackMethod);
          } else if (event.column.scopedSlots.selectField) {
            // 满足方法匹配规则
            this[linkMethod](event.record, event.column.scopedSlots, index, callBackMethod);
          } else {
            // 其他点击链接
            this[linkMethod](event, callBackMethod);
          }
        }
      }
    },
    /**
     * 执行回调函数
     * @param callBack
     */
    runCallBack(callBack) {
      if(callBack && this[callBack] && this.$util.isType(this[callBack])==="Function") {
        this[callBack]();
      }
    },
    /**
     * 打开对应的表单详情
     * @param values
     * @param rule
     * @param callback
     */
    openDetailMixin: function(values, rule, callback) {
      if (values && rule) {
        let selectField = rule.selectField;
        let valueField = rule.valueField;
        if (selectField && valueField) {
          let value = values[valueField];
          if (value) {
            let title = "查看-" + rule.title + "（" + value + "）";
            this.openTab({
              title: title,
              path: "/DetailForm",
              query: {
                dataCode: value, // 表单数据编号
                bm: rule.bm,
                dhbm: rule.dhbm,
                detailFiledName: selectField,
                tableName: rule.tableName // 表单用于tab的title的表单字段名
              }
            });
          }
        }
        this.runCallBack(callback);
      }
    },
    /**
     * 查看行数据,通过编码rule配置（保存在systemConfigs.FormBHRules中）带了参数
     * @param row
     * @param rule
     * @param callback
     */
    showByRule: function(row, rule, callback) {
      if (row && rule) {
        let title = "查看-" + rule.tableChineseName + "（" + row + "）";
        this.openTab({
          title: title,
          path: "/DetailForm",
          query: {
            dataCode: row, // 表单数据编号
            tableName: rule.tableName // 表单用于tab的title的表单字段名
          }
        });
        if (this.domId && this.$refs[this.domId]) {
          this.$refs[this.domId].closeTooltip();
        }
      }
      this.runCallBack(callback);
    },
    /**
     * 打开物资详情
     * @param values
     * @param rule
     * @param callback
     */
    openWZ: function(values, rule, callback) {
      console.log("打开物资详情", values, rule);
      this.openDetailMixin(values, rule, callback);
    },
    /**
     * 打开供应商详情
     * @param values
     * @param rule
     * @param callback
     */
    openGYS: function(values, rule, callback) {
      console.log("打开供应商详情", values, rule);
      this.openDetailMixin(values, rule, callback);
    },
    /**
     * 打开仓库详情
     * @param values
     * @param rule
     * @param callback
     */
    openCK: function(values, rule, callback) {
      console.log("打开仓库详情", values, rule);
      this.openDetailMixin(values, rule, callback);
    },
    /**
     * 打开客户详情
     * @param values
     * @param rule
     * @param callback
     */
    openKH: function(values, rule, callback) {
      console.log("打开客户详情", values, rule);
      this.openDetailMixin(values, rule, callback);
    },
    /**
     * 打开货位详情
     * @param values
     * @param rule
     * @param index
     * @param callback
     */
    openHW: function(values, rule, index, callback) {
      if (index != undefined) {
        let selectField = rule.selectField;
        let valueField = rule.valueField;
        if (selectField && valueField) {
          let value = values[valueField];
          if (value) {
            let allItems = this.$util.stringSplitArray(value, rule.splitSign);
            if (allItems[index] != undefined) {
              let values1 = {};
              values1[valueField] = allItems[index];
              this.openDetailMixin(values1, rule, callback);
            }
          }
        }
      }
    },
    /**
     * 查看填制凭证信息
     * @param values
     * @param rule
     * @param callback
     */
    openCWPZ: function(values, rule, callback) {
      console.log("查看行openCWPZ", values, rule);
      let rowID = values[rule.valueField];
      let titleFiedName = this.thisColumns[2].key;
      this.openTab({
        title: "查看-" + values[titleFiedName],
        path: "/KeepAccountsForm",
        query: {
          dhbm: this.dmbh,
          templetId: this.tableConfig.templateId, // 表单模板ID
          dataId: rowID, // 表单数据id
          detailFiledName: titleFiedName, // 表单用于tab的title的表单字段名
          type: "dateSearchView",
          bm: this.tableConfig.businessMode // 表单对应BM表
        }
      });
      this.runCallBack(callback);
    },
    /**
     * 查看图片
     * @param event
     * @param callback
     */
    showFiles: function(event, callback) {
      console.log("查看图片：", event);
      try {
        let files = JSON.parse(event.text);
        this.$parent.showPreviewModal(files);
        this.runCallBack(callback);
      } catch (e) {
        console.error("错误的文件数组", e);
      }
    }
  }
};
