<template>
  <div
    :class="type !== 'textarea' ? 'e-form-einput' : 'e-form-einput__textarea'"
    :title="setvalue"
  >
    <a-input-password
      v-model="setvalue"
      :defaultValue="defaultValue"
      :value="setvalue"
      :disabled="thisDisabled"
      :size="$config.defaultControlSize"
      :placeholder="placeholder"
      @blur.native="blur"
      @focus.native="focus"
      @click.native="click"
      :readOnly="readOnly"
      v-show="visible"
      :style="setWidth"
      ref="input"
      v-if="type === 'password'"
    >
    </a-input-password>
    <a-input
      v-else
      v-model="setvalue"
      :defaultValue="defaultValue"
      :value="setvalue"
      :disabled="thisDisabled"
      :type="type"
      :size="$config.defaultControlSize"
      :placeholder="placeholder"
      @blur.native="blur"
      @focus.native="focus"
      @click.native="click"
      :readOnly="readOnly"
      v-show="visible"
      :style="setWidth"
      ref="input"
    ></a-input>
    <span v-if="formatValueShow" :class="valueFormatClass">{{
      formatValue
    }}</span>
    <!-- 控件后附带的注释提醒 -->
    <li v-if="thisOldValue" class="form_right_icon">
      <a-tooltip
        placement="topRight"
        :title="'原值：' + thisOldValue"
        arrow-point-at-center
      >
        <a style="height: auto"><e-icon type="exclamation-circle"></e-icon></a>
      </a-tooltip>
    </li>
    <div v-if="tip" class="e-form-nextTip">{{ tip }}</div>
  </div>
</template>

<script>
import { dateFormat } from "@/mixins/dateFormat.js";
export default {
  name: "EInput",
  mixins: [dateFormat],
  data() {
    return {
      setvalue: this.defaultValue ? this.defaultValueSet() : this.value,
      thisOldValue: null,
      isFocus: false,
      thisDisabled: false,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    defaultValue: {
      type: [String, null],
      default: null,
    },
    value: {
      type: [String, Number, null],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [String, Number],
      default: "",
    },
    tip: {
      type: String,
      default: null,
    },
    modifiedValue: {
      type: [String, Number],
      default: null,
    },
    format: {
      type: String,
      default: null,
    },
  },
  created() {
    this.thisDisabled = this.disabled;
  },
  computed: {
    setWidth: function () {
      let widthObj = { minWidth: "50px" }; // ,maxWidth: "300px"
      /*if (this.width !== "") {
        if (typeof this.width == "number") {
          widthObj.width = this.width + 40 + "px";
        } else if (this.$util.isNumberString(this.width)) {
          widthObj.width = new Number(this.width) + 40 + "px";
        } else if (this.$util.isPixelString(this.width)) {
          let tempW = this.width.slice(0, -2);
          widthObj.width = new Number(tempW) + 40 + "px";
        } else if (this.$util.isPercentageString(this.width)) {
          widthObj.width = this.width;
        }
      }*/
      return widthObj;
    },
    formatValue: function () {
      if (this.format && this.setvalue) {
        if (this[this.format]) {
          return this[this.format](this.setvalue);
        }
      }
      return null;
    },
    formatValueShow: function () {
      if (this.format) {
        if (this[this.format]) {
          let formV = this[this.format](this.setvalue);
          if (formV != null && formV != undefined && formV !== "") {
            return !this.isFocus;
          }
        }
      }
      return false;
    },
    valueFormatClass: function () {
      let vClasses = ["e-form-formatShow"];
      if (this.disabled || this.readOnly) {
        vClasses.push("e-form-formatShow__disabled");
      }
      return vClasses;
    },
  },
  methods: {
    tableFocus() {
      this.$refs.input.focus();
    },
    tableBlur() {
      this.$refs.input.blur();
    },
    blur() {
      this.isFocus = false;
      this.$emit("blur", this.setvalue);
      console.log(this.decorator);
    },
    /**
     * 设置禁用状态
     * @param v
     */
    setDisabled(v) {
      if (this.$util.isType(v) === "Boolean") {
        this.thisDisabled = v;
      } else {
        this.thisDisabled = true;
      }
    },
    /**
     * 切换当前控件的禁用状态
     */
    setDisabledSwitch() {
      this.thisDisabled = !this.thisDisabled;
    },
    focus() {
      this.isFocus = true;
      this.$emit("focus", this.setvalue);
    },
    click() {
      this.$emit("click", this.setvalue);
      this.$store.state.canBackSpace = true;
      console.log("click");
    },
    defaultValueSet() {
      this.$emit("change", this.defaultValue);
      return this.defaultValue;
    },
  },
  watch: {
    setvalue(value) {
      // console.log(value);
      // this.$emit("change", value);
      this.$emit("change", value);
    },
    value(value) {
      this.setvalue = value;
    },
    disabled(v) {
      this.thisDisabled = v;
    },
    modifiedValue: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function (val) {
        //console.log("监听einput值", val);
        if (val) {
          // 新值和老值互换显示
          this.thisOldValue = this.setvalue;
          this.setvalue = val;
        }
      },
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/style/base/controls/e-input.less";
</style>
