<template>
  <div>
    <a-modal
      title="输入"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @ok="handleSubmit"
      @cancel="handleCancel"
      cancelText="关闭"
    >
      <template slot="footer">
        <a-button key="submit" type="primary" @click="handleSubmit">
          确定
        </a-button>
      </template>
      <a-textarea
        :placeholder="placeholder"
        :rows="4"
        id="text"
        v-model="setValue"
        class="formula-in-select"
      />
      <div style="margin-top:10px;height:300px">
        <template v-for="(row, index) in buttonData">
          <div :key="row.title" :class="'cl' + index">
            <div>{{ row.title }}</div>
            <select
              multiple="multiple"
              style="height:280px;width:100%"
              @dblclick="selectChange(selectKey)"
              v-model="selectKey"
              class="formula-in-select"
            >
              <template v-for="sum in row.data">
                <option :key="sum.key" :value="sum.key">{{ sum.title }}</option>
              </template>
            </select>
          </div>
        </template>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法
import { getOption } from "./DataSet.js";
export default {
  name: "EZjgsInput",
  props: {
    value: {
      type: [String, Array],
      default: ""
    }
  },
  mixins: [requestControls],
  data() {
    return {
      setValue: this.value,
      visible: false,
      confirmLoading: false,
      treeData: [],
      autoExpandParent: true,
      expandedKeys: [],
      checkedKeys: [],
      checkedRows: [],
      spinning: true,
      checkName: "",
      checkId: "",
      selectKey: [],
      placeholder: "请输入公式",
      serverName: "Form.ashx",
      action: "GetCxsZJGSTextArea",
      buttonData: []
    };
  },
  created() {
    this.buttonData = [...getOption()];
    this.getData();
  },
  watch: {},
  methods: {
    getData() {
      var params = {
        action: this.action
      };
      var _this = this;
      this.post(this.serverName, params, {
        success: function(res) {
          if (res.success) {
            _this.buttonData[0].data = res.data;
          }
        },
        error: function(e) {
          e;
        }
      });
    },
    selectChange(value) {
      console.log("value:", value);
      if (this.setValue == null) {
        this.setValue = "";
      }
      value.forEach(element => {
        this.setValue = this.setValue + element;
      });
    },
    show() {
      this.visible = true;
      this.checkedRows = [];
      this.checkedKeys = [];
    },
    postData(value) {
      this.setValue = value;
    },
    handleSubmit() {
      this.$emit("ok", this.setValue);
      this.handleClear();
    },
    handleCancel() {
      this.handleClear();
    },
    handleClear() {
      this.visible = false;
    }
  }
};
</script>
<style scoped lang="less">
@import "../../../assets/style/base/controls/e-formula-in.less";
</style>
