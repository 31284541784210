/**
 * 系统级方法
 * @type {{data(): *, methods: {getThisRef: systemMethods.methods.getThisRef, sumRowDatas_minxin: (function(*=, *=))}}}
 */
export const systemMethods = {
  data() {
    return {};
  },
  methods: {
    /**
     * 本方法可以在组件created函数中将对应的lsess文件加载到组件中
     * @param filename
     * @param filepath
     * @return {*}
     */
    getLess(filename, filepath) {
      console.log("加载----", (filepath || "") + "/" + filename + ".less");
      if (filepath && filepath !== "") {
        return require("../assets/style/theme/" +
          window.SYS_THEME_NAME +
          "/" +
          filepath +
          "/" +
          filename +
          ".less");
      }
      return require("../assets/style/theme/" +
        window.SYS_THEME_NAME +
        "/" +
        filename +
        ".less");
    },
    /**
     * 如果需要打开的页面是网页
     * @param url
     * @returns {boolean}
     */
    isHttpURL(url) {
      try {
        return url && (0 == url.indexOf("http:") || 0 == url.indexOf("https:"));
      } catch (e) {
        return false;
      }
    },
    /**
     * 给对应id的节点绑定一个方法，用于监听本对象节点失去焦点事件
     * @param state true:表示绑定监听，false:表示卸载监听
     * @param id
     * @param checkMethodName
     */
    setElementOnBlurListener: function(state, id, checkMethodName) {
      if (this.$util.isType(state) === "Boolean") {
        if (id) {
          if (state) {
            if (checkMethodName && this[checkMethodName])
              window.globalOnBlurListener[id] = this[checkMethodName];
            else console.warn("没有找到对应实现的响应失去焦点事件的方法");
          } else {
            delete window.globalOnBlurListener[id];
          }
        }
      }
    },
    /**
     * 移除数组中指定位置的对象
     * @param oldData
     * @param index
     * @returns {*}
     */
    removeObjFromVueArrayData: function(oldData, index) {
      if (
        this.$util.isType(oldData) === "Array" &&
        this.$util.isInteger(index)
      ) {
        oldData.splice(index, 1);
      }
      return oldData;
    },
    /**
     * 向数组中指定位置插入对象
     * @param oldData
     * @param newValue
     * @param index
     * @returns {*}
     */
    insertObjToVueArrayData: function(oldData, newValue, index) {
      if (
        this.$util.isType(oldData) === "Array" &&
        newValue &&
        this.$util.isInteger(index)
      ) {
        oldData.splice(index, 0, newValue);
      }
      return oldData;
    },
    /**
     * 注： 系统级公共方法
     * 修改作用域的data中指定名字的数据,!系统级方法，不能修改
     * @param {Object} dataName this.data中的属性名
     * @param {Object} key 可以是对象的字段名，也可以是只数组的index，也可以是用于进行替换一个对象
     * @param {Object} value
     */
    updatePageData: function(dataName, key, value) {
      if (this[dataName] && this.$util.isType(this[dataName]) !== "Function") {
        if (this.$util.isType(this[dataName]) === "Object") {
          // 如果为对象类型的数据，需要按照下列方法修改
          let copyObject = Object.assign({}, this[dataName]);
          if (this.$util.isType(key) === "String" && value != undefined)
            copyObject[key] = value;
          else if (this.$util.isType(key) === "Object") {
            copyObject = Object.assign(copyObject, key);
          }
          this[dataName] = copyObject;
        } else if (this.$util.isType(this[dataName]) === "Array") {
          if (this.$util.isType(key) === "val" && value != undefined) {
            if (this.$util.isType(value) === "Object") {
              let tempData = Object.assign({}, value);
              if (
                this[dataName][key] &&
                this.$util.isType(this[dataName][key]) === "Object"
              ) {
                tempData = Object.assign({}, this[dataName][key], tempData);
              }
              this.$set(this[dataName], key, tempData);
            } else this.$set(this[dataName], key, value);
          }
        } else {
          if (
            this.$util.isType(key) === "val" ||
            this.$util.isType(key) === "String" ||
            this.$util.isType(key) === "Boolean"
          )
            this[dataName] = key;
        }
      }
    },
    /**
     * 获取对应name的Ref对象
     * @param name
     * @return {*}
     */
    getThisRef: function(name) {
      if (name) {
        if (this.$refs[name]) {
          if (this.$util.isType(this.$refs[name]) === "Array") {
            return this.$refs[name][0];
          } else {
            return this.$refs[name];
          }
        }
      }
      return null;
    },
    /**
     * 根据合计列定义，从数据对象列表中合计该列的合计值
     * @param datas
     * @param sumRows
     */
    sumRowDatas_minxin: function(datas, sumRows) {
      let hjData = {};
      if (datas && datas.length > 0 && sumRows && sumRows.length > 0) {
        let that = this;
        sumRows.forEach(function(row) {
          let allList = [];
          for (let i = 0; i < datas.length; i++) {
            let value = datas[i][row];
            if (
              that.$util.isType(value) === "val" ||
              that.$util.isNumberString(value) === "String"
            ) {
              allList.push(value);
            }
          }
          hjData[row] = that.$util.mathUtil.sumArray(allList);
        });
      }
      return hjData;
    }
  }
};
