<!-- iframe组件 -->
<template>
  <div class="e_iframe">
    <a-spin
        v-show="loading"
        :spinning="loading"
        tip="菜单加载中..."
        size="large"
        class="iframe-spin"
    >
    </a-spin>
    <iframe @load="onLoad" v-show="!loading" style="margin-top: 0;"
            :src="replacePathPlaceholder(path)"
    ></iframe>
  </div>
</template>

<script>
  import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
  export default {
    mixins: [
      pageControls
    ],
    name: "EIFrame",
    data() {
      return {
        path: null,
        random: null,
        loading: true
      }
    },
    props: {
      src: {
        type: String,
        default: null
      },
      iClass: {
        type: [String, Array],
        default: null
      }
    },
    watch: {
      src: function(v) {
        this.path = v;
      }
    },
    created: function(){
      //console.log("请求参数：" + JSON.stringify(this.$route.query));
      //console.log("路由属性：" + JSON.stringify(this.$route.params));
      let path = this.$route.query.src;
      if (!path) {
        this.random = this.$route.query.random;
        this.path = decodeURIComponent(this.src);
      } else {
        this.path = decodeURIComponent(path);
      }
      console.log("打开页面：" + this.path);
    },
    methods: {
      // 将路径中的占位符替换为相应值
      replacePathPlaceholder(path) {
        return path.replace("{{token}}", this.$store.state.userInfo.randomKey);
      },
      onLoad(e){
        console.log("ifame:"+this.path+",加载事件",e);
        let that = this;
        setTimeout(function(){
          that.loading = false;
        },600)
      }
    }
  };
</script>

<style scoped>
.iframe-spin {
  width: 100%;
  height: 100%;
  margin-top: 100px;
}
.e_iframe {
  width: 100%;
  height: 100%;
}
</style>