<template>
  <div class="e-form-number-horizon">
    <!-- val:{{setvalue}}
    type: {{typeof setvalue}}-->
    <span>
      <a-input-number
        :defaultValue="defaultValue"
        v-model="setValue1"
        :disabled="thisDisabled"
        :size="controlSize"
        :readOnly="readOnly"
        v-show="visible"
        :style="setwidth"
        :max="max"
        :min="min"
        :precision="precision"
        :step="step"
        :formatter="
          value =>
            separator && value >= 1000
              ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : `${value}`
        "
        :class="notNumberHandlerWrap ? 'not-number-handler-wrap' : ''"
      />
      <!-- 控件后附带的注释提醒 -->
      <!-- <li v-if="thisOldValue" class="form_right_icon">
        <a-tooltip placement="topRight" :title="'原值：'+thisOldValue" arrow-point-at-center>
          <a style="height: auto;">
            <e-icon type="exclamation-circle"></e-icon>
          </a>
        </a-tooltip>
      </li>
      <div v-if="tip" class="e-form-nextTip">{{ tip }}</div> -->
    </span>
    ~
    <span>
      <a-input-number
        :defaultValue="defaultValue"
        v-model="setValue2"
        :disabled="thisDisabled"
        :size="controlSize"
        :readOnly="readOnly"
        v-show="visible"
        :style="setwidth"
        :max="max"
        :min="min"
        :precision="precision"
        :step="step"
        :formatter="
          value =>
            separator && value >= 1000
              ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : `${value}`
        "
        :class="notNumberHandlerWrap ? 'not-number-handler-wrap' : ''"
      />
      <!-- 控件后附带的注释提醒 -->
      <!-- <li v-if="thisOldValue" class="form_right_icon">
        <a-tooltip placement="topRight" :title="'原值：'+thisOldValue" arrow-point-at-center>
          <a style="height: auto;">
            <e-icon type="exclamation-circle"></e-icon>
          </a>
        </a-tooltip>
      </li>
      <div v-if="tip" class="e-form-nextTip">{{ tip }}</div> -->
    </span>
  </div>
</template>

<script>
export default {
  name: "ENumericUpDown",
  data() {
    return {
      setvalue: [null, null],
      setValue1: null,
      setValue2: null,
      controlSize: this.$config.defaultControlSize,
      thisOldValue: null,
      showValue: null,
      setwidth: this.widthSet(),
      thisDisabled: false
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    defaultValue: {
      type: [Number, String, null],
      default: null
    },
    value: {
      type: [Number, String, null],
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: ""
    },
    max: {
      type: [String, Number]
    },
    min: {
      type: [String, Number]
    },
    precision: {
      type: [String, Number],
      default: 0
    },
    step: {
      type: [String, Number],
      default: 1
    },
    separator: {
      type: Boolean,
      default: false
    },
    tip: {
      type: String,
      default: null
    },
    notNumberHandlerWrap: {
      type: Boolean,
      default: false
    },
    modifiedValue: {
      type: [String, Number],
      default: null
    }
  },
  created() {
    this.thisDisabled = this.disabled;
  },
  mounted() {
    // this.value = this.defaultValue ? this.defaultValueSet() : this.value;
    this.showValue = this.modifiedValue;
    if (this.showValue != null) {
      this.thisOldValue = this.setvalue;
      this.setvalue = this.showValue;
    }
    this.initializationValue();
    // this.init();
    // console.log("初始化ENumericUpDown的Value=", this.setvalue, this.showValue);
  },
  computed: {},
  methods: {
    /**
     * 设置禁用状态
     * @param v
     */
    setDisabled(v) {
      if(this.$util.isType(v) === "Boolean") {
        this.thisDisabled = v;
      }else {
        this.thisDisabled = true;
      }
    },
    /**
     * 切换当前控件的禁用状态
     */
    setDisabledSwitch() {
      this.thisDisabled = !this.thisDisabled;
    },
    init() {
      if (this.setvalue != null && this.setvalue != "") {
        this.$emit("change", this.setvalue);
      }
    },
    blur() {
      this.$emit("blur", this.setvalue);
    },
    focus() {
      this.$emit("focus", this.setvalue);
    },
    defaultValueSet() {
      this.$emit("change", this.defaultValue);
      return this.defaultValue;
    },
    widthSet() {
      if (this.width == "") {
        this.setwidth = "";
        return "";
      }
      if (typeof this.width == "number") {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      if (this.width.indexOf("%") == -1 && this.width.indexOf("px") == -1) {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      this.setwidth = "width:" + this.width;
      return "width:" + this.width;
    },
    initializationValue() {
      var value = this.value;
      if (value == "" || value == null) {
        this.setValue1 = null;
        this.setValue2 = null;
        return null;
      }
      if (typeof value == "string") {
        value = value.split(",");
      }
      if (typeof value == "object") {
        var start =
          value[0] && value[0] != null && value[0] != "null"
            ? Number(value[0])
            : null;
        var end =
          value[1] && value[1] != null && value[1] != "null"
            ? Number(value[1])
            : null;
        if (start == null || start == "null" || end == null || end == "null") {
          this.setValue1 = start;
          this.setValue2 = end;
        } else {
          var middle;
          if (start <= end) {
            this.setValue1 = start;
            this.setValue2 = end;
          } else {
            middle = start;
            this.setValue1 = end;
            this.setValue2 = middle;
          }
        }
      }
      return this.setValue;
    },
    emitChange(v) {
      if (v == null || v == "") {
        this.$emit("change", null);
        return;
      }
      var emitValue = v[0] + "," + v[1];
      this.$emit("change", emitValue);
    },
    test(v) {
      debugger;
    }
  },
  watch: {
    setValue1(value, oldValue) {
      // if (value !== oldValue) {
      //   if (value == "NaN") {
      //     value = 0;
      //   }
      //   var value2 = this.setValue2;
      //   if (value2 == "NaN") {
      //     value2 = 0;
      //   }
      //   this.emitChange([value, value2]);
      // }
      this.emitChange([value, this.setValue2]);
    },
    setValue2(value, oldValue) {
      // if (value !== oldValue) {
      //   if (value == "NaN") {
      //     value = 0;
      //   }
      //   var value2 = this.setValue2;
      //   if (value2 == "NaN") {
      //     value2 = 0;
      //   }
      //   this.emitChange([value2, value]);
      // }
      this.emitChange([this.setValue1, value]);
    },
    value() {
      this.initializationValue();
    },
    width() {
      this.widthSet();
    },
    disabled(v) {
      this.thisDisabled = v;
    },
    modifiedValue: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(val) {
        //console.log("监听ENumericUpDown值", val,this.setvalue);
        if (val) {
          // 新值和老值互换显示
          //this.thisOldValue = this.setvalue;
          this.showValue = val;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.e-form-numberic {
  position: relative;
}
.form_right_icon {
  position: absolute;
  width: 20px;
  height: calc(100%);
  top: 0;
  right: -25px;
  z-index: 1200;
}
::v-deep .ant-input-number-handler-wrap {
  width: 30px !important;
}
::v-deep .not-number-handler-wrap .ant-input-number-handler-wrap {
  display: none !important;
}
</style>
