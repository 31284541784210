// 表单功能需要的公共方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { pageDestroy } from "@/mixins/pageDestroy.js"; // 页面关闭清除缓存的方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求
import { analyticalTemplate } from "@/mixins/analyticalTemplate.js"; // 解析xml模板的方法
import { analyticalPrint } from "@/mixins/analyticalPrint.js"; // 打印数据计算方法

export const e1Form = {
  mixins: [
    requestControls,
    pageDestroy,
    pageControls,
    analyticalTemplate,
    analyticalPrint
  ],
  data() {
    return {
      form: null,
      title: "", // 表单名称
      tempId: null, // 对应表单模板id
      templateName_mixin: null, // 模板名称
      mainTableData: null,
      formConfigs: null, // 表单配置参数
      option: null, //只有一张明细表
      tabOptions: null, // tab包含多张明细表
      formDisable: false, // 表单是否可以编辑（如果是标识的可编辑字段不受影响），false表示是
      formEditState: false, // 整个表单可编辑状态，true表示不可编辑
      isFormWorkflowEnd: false, // 表单流程是否结束
      mainTableName: "", // 对应表单的主表表名
      mxTableName: null, // 对应的（1对多式）明细表名 ，多张明细表？
      biuniqueSubTable: null, // 一一对应的子表
      thisFromEvents: null, // 表单的额外扩展功能
      allChildrenTablesDatas: null, // 明细表数据,
      showNameFromMcFields: ["_BDMC", "_GYSMC", "_WZMC", "_KHMC"], // 用于标识可以用于匹配现在表单，可以用于中文标识的字段
      formSpecialMark: [
        // 需要进行特殊标记的表单编码
        {
          reg: "^_CX_[A-Z]+\\d+$",
          title: "冲销"
        }
      ],
      operaterFile: null,
      mxChangeHh: true, // 明细的行号是否自动变化
      uploadFieldConfig: {
        configs: {
          rules: [],
          tableName: "",
          required: false,
          multiSelect: false,
          fieldName: "_Attachments",
          checkType: "",
          cnName: "采购订单名称",
          defaultValue: ""
        },
        controlName: "upload",
        controlType: "CxsTextBox",
        disable: false,
        label: "upload",
        location: {
          x: 0,
          y: 0
        },
        name: "uploadfile",
        setting: {
          name: "EInput"
        },
        size: {
          h: 0,
          labelw: 0,
          w: 0
        },
        text: "",
        thisControlType: "EInput",
        isShow: false
      }
    };
  },
  created() {
    //console.log("混入e1Form的通用方法");
  },
  computed: {
    /**
     * 获取表单操作时对应的明细表列表，会将一一对应的子表并入到一对多的明细表中
     * @return {null|*|string[]|Array}
     */
    saveMxTableNames: function () {
      let mxTableNames = this.mxTableName;
      if (this.biuniqueSubTable) {
        let subTableName = this.biuniqueSubTable.map(function (tConfig) {
          return tConfig.tableName;
        });
        mxTableNames = [].concat(mxTableNames, subTableName);
      }
      return mxTableNames;
    },
    showToolbar: function () {
      if (this.checkToobleIsShow(this.buttons)) {
        return true;
      } else {
        return false;
      }
    },
    getTabFormContextClass: function () {
      if (this.checkToobleIsShow(this.buttons)) {
        return "tab-form-context";
      } else {
        return "tab-form-context_noHead";
      }
    }
  },
  methods: {
    /**
     * 检查编号是否满足特殊标记配置
     * @param bdbh
     * @returns {*}
     */
    checkIsSpecialMarkBD(bdbh) {
      if (this.$util.isType(bdbh) === "String" && this.formSpecialMark) {
        let mark = this.formSpecialMark.find(function (obj) {
          return new RegExp(obj.reg).test(bdbh);
        });
        if (mark) {
          return mark.title;
        }
      }
      return null;
    },
    /**
     * 设置表单的可编辑状态
     * @param disable
     */
    setFormDisable(disable) {
      if (disable === undefined || this.$util.isType(disable) !== "Boolean")
        this.formDisable = false;
      else this.formDisable = disable;
    },
    /**
     * 获取表单的扩展属性
     */
    getFormExtends() {
      this.getFormExtends_Mixin(
        "thisFromEvents",
        this.tempId,
        this.templateName_mixin
      );
    },
    /**
     * 从服务端获取表单模板数据，并进行解析
     * @param action
     * @param tempId
     * @param idObj 可以传入Object:格式如:{id:数据id,dmbh:菜单id},两个参数可以都传，但是优先使用数据id作为请求参数
     * @param renderBefore
     * @param renderOver
     */
    getForm(action, tempId, idObj, renderBefore, renderOver) {
      let that = this;
      let dataId = null,
        dmbh = null,
        type = null,
        businessModel = null,
        tableName = null,
        bdbh = null,
        dataID = null,
        keyField = null,
        clzt = null,
        spTempId = null,
        tempName = null;
      if (idObj && this.$util.isType(idObj) === "Object") {
        dataId = idObj.id;
        dmbh = idObj.dmbh;
        type = idObj.type;
        businessModel = idObj.businessModel;
        tableName = idObj.tableName;
        bdbh = idObj.bdbh;
        dataID = idObj.defalultDataId;
        keyField = idObj.keyField;
        spTempId = idObj.spTempId;
        clzt = idObj.clzt;
        tempName = idObj.tempName;
      } else {
        dataId = idObj;
      }
      let params = {
        action: action,
        t_id: tempId,
        d_id: dataId,
        randomKey: this.$store.getters.getRandomKey
      };
      if (tempId) params.t_id = tempId;
      if (dmbh) params.dmbh = dmbh;
      if (type) params.type = type;
      if (businessModel) params.businessModel = businessModel;
      if (dataID) params.dataID = dataID;
      if (tableName) params.tableName = tableName;
      if (bdbh) params.bdbh = bdbh;
      if (keyField) params.keyField = keyField;
      if (spTempId) params.spTempId = spTempId;
      if (clzt) params.clzt = clzt;
      if (tempName) params.tempName = tempName;

      this.get("Form.ashx", params, {
        success: function (req) {
          that.title = req.data.template[0].MBCBT; // 表单名称
          that.templateName_mixin = req.data.template[0].MBCBT; // 模板名称
          that.getFormExtends(); // 获取当前表单的扩展属性
          if (renderBefore && that.$util.isType(renderBefore) === "Function")
            renderBefore(req.data);
          that.mainTableName = req.data.template[0].MBZBMC; // 绑定的表名
          let tempXML = req.data.template[0].MBNR; // 表单模板
          that.printConfig = req.data.template[0].MBNR;
          if (tempXML !== "") {
            // 给表单解析组件设置参数
            that.formConfigs = that.getFormConfigs(
              tempXML,
              req.data.data,
              dataId,
              that.mainTableName,
              req.data.buttons
            );
            that.formConfigs.formDefine.MBCBT = that.templateName_mixin;
            that.formConfig = that.formConfigs.formDefine;
            that.mxTableName = that.formConfigs.formDefine.mxTableName;

            if (renderOver && that.$util.isType(renderOver) === "Function")
              renderOver();
            else if (that.thisFromEvents && that.thisFromEvents.afterInit) {  // 调用扩展功能，表单初始化后调用（表单模板加载完成、数据未加载完成）。
              that.thisFromEvents.afterInit(that, that.mainTableData);
            }
            else that.loadingOk();
          }
        },
        error: function (err) {
          console.log(err);
          that.loadingError();
          that.alertError("请求失败，获取模板失败");
        }
      });
    },
    /**
     * 根据模板解析出表单配置参数
     * @param tempXML
     * @param defaultData
     * @param d_id
     * @param mainTableName
     * @returns {*}
     */
    getFormConfigs(tempXML, defaultData, d_id, mainTableName, operation) {
      //debugger
      let res = {};
      this.mainTableData = {};
      if (d_id) {
        // 有初始数据
        res = this.renderFormTemplate(
          tempXML,
          this.mainTableData,
          mainTableName
        ); // 解析表单模板
      } else {
        res = this.renderFormTemplate(
          tempXML,
          this.mainTableData,
          mainTableName
        ); // 解析表单模板
        console.log("解析表单模板", JSON.stringify(res));
        // 如果包含其他默认字段值，则合并入初始对象
        if (this.defaultMainTableFiled) {
          this.mainTableData = Object.assign(
            this.mainTableData,
            this.defaultMainTableFiled
          );
        }
      }
      if (defaultData) {
        // 如果包含了默认表单数据返回，则要将改数据附加到默认表单数据中
        this.mainTableData = Object.assign({}, this.mainTableData, defaultData);
      }
      res.mainTableName = mainTableName;
      // 解析计算公式字段配置that.formRows,
      res.formulaConfigs = this.getFormulaConfigs();
      // 给定制打印按钮，在有多个选项的情况下追加菜单配置
      if (operation) {
        let buttonObj = operation.find(item => {
          return item.tsbName == "tsbDZPrint";
        });
        if (
          buttonObj &&
          res.formDefine &&
          res.formDefine.rdlName &&
          res.formDefine.rdlName.includes(";", 1)
        ) {
          buttonObj.menuList = res.formDefine.rdlName.split(";");
        }
      }

      // 加个附件控件追加字段
      // res.formRows[res.formRows.length - 1].columns.push(this.uploadFieldConfig);
      res.formRows.push({
        colSpan: 24,
        columns: [this.uploadFieldConfig],
        key: 9999
      });
      // 包含一一对应的子表
      this.biuniqueSubTable = res.biuniqueSubTable;
      console.log("res", res, this.mainTableData);
      return res;
    },
    /**
     * 把指定字段值重置，checkbox无效
     * @param fields
     */
    resetFields(fields){
      this.$refs.formTemplate.resetFields(fields);
    },
    /**
     * 把指定字段的控件设置为禁用
     * @param fields
     * @param disabled
     */
    setFiledDisabled(fields, disabled){
      if(fields) {
        if(this.$util.isType(fields)==="String") {
          this.$refs.formTemplate.setFiledDisabled(fields, disabled);
        } else if(this.$util.isType(fields)==="Array") {
          for(let i=0; i<fields.length;i++) {
            if(this.$util.isType(fields[i])==="String") {
              this.$refs.formTemplate.setFiledDisabled(fields[i], disabled);
            }
          }
        }
      }
    },
    /**
     * 设置切换对应的表单字段禁用状态
     * @param fields
     */
    setFieldDisabledSwitch(fields) {
      if(fields) {
        if(this.$util.isType(fields)==="String") {
          this.$refs.formTemplate.setFieldDisabledSwitch(fields);
        } else if(this.$util.isType(fields)==="Array") {
          for(let i=0; i<fields.length;i++) {
            if(this.$util.isType(fields[i])==="String") {
              this.$refs.formTemplate.setFieldDisabledSwitch(fields[i]);
            }
          }
        }
      }
    },
    /**
     * 获取表单的数据
     * @param tempId
     * @param dataId
     * @param bm
     * @param et_name
     * @param successOver
     * @param errorOver
     */
    getFormData(tempId, dataId, bm, et_name, successOver, errorOver) {
      let that = this;
      if (tempId && dataId && bm) {
        let params = {
          action: "GetTemplateData",
          d_id: dataId,
          tb_name: this.mainTableName, //主表名
          mxtbdata: this.saveMxTableNames.toString(), //明细表名
          bm: bm,
          t_id: tempId,
          et_name: et_name,
          m_id: 0,
          randomKey: this.$store.getters.getRandomKey
        };
        this.get("Form.ashx", params, {
          success: function (req) {
            //console.log("获取到表单数据：" , req);
            // 初始化化表单数据
            if (req.data.mainTableData) {
              that.mainTableData = Object.assign(
                {},
                that.mainTableData,
                req.data.mainTableData,
                that.defaultMainTableFiled ? that.defaultMainTableFiled : {}
              );
            }
            if (req.data.workFlow) {
              if (req.data.workFlow.IsFormWorkflowEnd) {
                // 表单流程是否结束
                that.isFormWorkflowEnd = true;
              }
              if (req.data.workFlow.operaterFile) {
                that.operaterFile = req.data.workFlow.operaterFile;
              }
            }
            let temChildrenDatas = req.data.childrenDatas;
            that.setBiuniqueSubTableDataToMainData(
              that.mainTableData,
              temChildrenDatas
            );
            console.log(
              "合并后的主表数据",
              that.mainTableData,
              temChildrenDatas
            );
            // 初始化化明细表数据
            that.initMxTableData(temChildrenDatas);
            if (successOver && that.$util.isType(successOver) === "Function") {
              successOver(req.data);
              if (!that.checkToobleIsShow(that.buttons)) {
                that.hideToolBar();
              }
            } else {
              if (!that.checkToobleIsShow(that.buttons)) {
                that.hideToolBar();
              }
              that.loadingOk();
            }
          },
          error: function (err) {
            console.log(err);
            that.loadingError();
            that.tipError(err.message ? err.message:"请求失败，获取表单数据失败");
            if(errorOver) {
              errorOver();
            }
          }
        });
      } else {
        this.loadingError();
      }
    },
    /**
     * 重新设置明细表数据
     * @param data
     */
    setMainTableData(data) {
      this.$refs.formTemplate.setMainTableData(data);
    },
    /**
     * 明细表已加载后，可调用本方法重新设置表单数据
     * @param allChildrenDatas
     */
    setMxTableData(allChildrenDatas) {
      this.$refs.formTemplate.setMxTableData(allChildrenDatas);
    },
    /**
     * 向明细表追加数据
     * @param allChildrenDatas
     * @param type true:传入的行号会删除重新排，false:引用原有行号
     */
    addMxTableData(allChildrenDatas, type) {
      this.$refs.formTemplate.addMxTableData(allChildrenDatas, type);
    },
    /**
     * 修改明细表对应的行数据
     * @param tableName
     * @param rowData
     * @param keyField
     */
    updateRowData(tableName, rowData, keyField) {
      this.$refs.formTemplate.updateRowData(tableName, rowData, keyField);
    },
    /**
     * 获取表单中主表单的选择控件选中项对应的中文内容
     */
    getMainFormChinese() {
      return this.$refs.formTemplate.getChineseName();
    },
    /**
     * 获取表单中明细表中的选择控件选中项对应的中文内容
     */
    getMxChineseValue() {
      return this.$refs.formTemplate.getMxChineseValue();
    },
    /**
     * 获取表单中的明细表的数据
     * 注：次方法会过滤掉非明细表列定义的字段
     * @param chinese true表示需要获取对应编码字段的中文
     */
    getMxTableData(chinese) {
      return this.$refs.formTemplate.getMxTableData(chinese);
    },
    /**
     * 获取对应明细表的数据
     * @param tableName
     * @return {*}
     */
    getMxTableDataByTableName(tableName) {
      return this.$refs.formTemplate.getMxTableDataByTableName(tableName);
    },
    /**
     * 获取表单中明细表包含的源数据
     * @returns {*|Object}
     */
    getMxSourceData() {
      return this.$refs.formTemplate.getMxSourceData();
    },
    /**
     * 获取表单中的明细表的行定义
     */
    getColDefinition() {
      return this.$refs.formTemplate.getColDefinition();
    },
    /**
     * 将获得到的明细数据转换为JSON格式字符串，用于表单保存方法
     */
    getMxDataJSON(mainData) {
      let subTableData = this.getBiuniqueSubTableData(mainData);
      return this.$refs.formTemplate.getMxDataJSON(subTableData);
    },
    /**
     * 从主表单数据中获取出子表单数据
     * @param mainData
     * @return {Array}
     */
    getBiuniqueSubTableData(mainData) {
      if (this.biuniqueSubTable && mainData) {
        // 包含一一对应的子表
        let subTableData = [];
        this.biuniqueSubTable.forEach(function (tbConfig) {
          let tName = tbConfig.tableName;
          if (mainData[tName]) {
            subTableData.push({
              tableName: tName,
              data: [mainData[tName]]
            });
          }
        });
        return subTableData;
      }
      return null;
    },
    /**
     * 如果包含有一一对应的子表，则需要从明细数据中将该对应的数据注入主表单
     * @param mainTableData
     * @param childData
     */
    setBiuniqueSubTableDataToMainData(mainTableData, childData) {
      if (this.biuniqueSubTable && mainTableData && childData) {
        // 包含一一对应的子表
        let sNames = [];
        this.biuniqueSubTable.forEach(function (tbConfig) {
          let tName = tbConfig.tableName;
          sNames.push(tName);
          let tabData = childData.find(function (cdata) {
            return cdata.tableName === tName;
          });
          if (tabData && tabData.datas && tabData.datas.length == 1) {
            // 因为是一一对应的子表，所以只能有一条明细数据
            for (let i = 0; i < tbConfig.columns.length; i++) {
              mainTableData[tName + "." + tbConfig.columns[i]] =
                tabData.datas[0][tbConfig.columns[i]];
            }
          }
        });
        if (sNames.length > 0) {
          let newChild = childData.filter(function (cdata) {
            return !sNames.includes(cdata.tableName);
          });
          childData = newChild;
        }
      }
    },
    /**
     * 明细表未加载前，可调用本方法初始化表单数据
     * @param allChildrenDatas
     */
    initMxTableData(allChildrenDatas) {
      this.$refs.formTemplate.initMxTableData(allChildrenDatas);
    },
    /**
     * 显示加载中
     */
    loading() {
      this.$refs.formTemplate.loading();
      this.operationDisabled = true;
    },
    /**
     * 加载成功
     */
    loadingOk() {
      this.$refs.formTemplate.loadingOk();
      this.operationDisabled = false;
    },
    /**
     * 加载失败
     */
    loadingError() {
      this.$refs.formTemplate.loadingError();
    },
    /**
     * 隐藏表单
     */
    hideForm() {
      this.$refs.formTemplate.hideForm();
    },
    /**
     * 检测明细表是否满足必填条件
     * @param formConfig 对应config中mxTableRequiredConfig
     * @param mxDatas
     * @returns {String}
     */
    checkDetailRequired(formConfig, mxDatas) {
      if (formConfig) {
        for (let i = 0; i < formConfig.length; i++) {
          let tableConfig = formConfig[i];
          if (tableConfig.required) {
            let mxTableObj = mxDatas.find(function (mxObj) {
              return (mxObj.tableName = tableConfig.tableName);
            });
            if (mxTableObj) {
              if (mxTableObj.data && mxTableObj.data.length > 0) {
                continue;
              }
            }
            return tableConfig.name
              ? tableConfig.name + "不能为空"
              : "明细数据不能为空";
          }
        }
      }
      return null;
    },
    /**
     * 保存表单前调用表单模板配置的全局校验条件进行检测mainTables,mxDatas
     * @param condition
     * @param mainTables
     * @param mxDatas
     * @constructor required
     */
    saveConditionCheck(condition, mainTables, mxDatas) {
      if (condition) {
        let obj = this.getSaveCondition(condition);
        console.log("解析出校验条件", obj);
        if (obj) {
          let saveCondition = obj.saveCondition;
          let tempConditions = null;
          if (obj.haveMxField) {
            // 需要明细数据参与校验
            tempConditions = [];
            for (let i = 0; i < obj.variableFields.length; i++) {
              let variable = obj.variableFields[i];
              if (variable.tableName) {
                // 是明细表字段
                let mxTableObj = mxDatas.find(function (mxObj) {
                  return (mxObj.tableName = variable.tableName);
                });
                if (mxTableObj) {
                  if (mxTableObj.data && mxTableObj.data.length > 0) {
                    for (let j = 0; j < mxTableObj.data.length; j++) {
                      // 每行数据都需要校验

                      let fValue = mxTableObj.data[j][variable.fieldName]
                        ? this.getValidTypesValueString(
                          mxTableObj.data[j][variable.fieldName]
                        )
                        : "0";
                      let thisCondition = saveCondition;
                      if (tempConditions[j]) {
                        thisCondition = tempConditions[j];
                      }
                      thisCondition = thisCondition.replace(
                        variable.oldArgName,
                        fValue
                      );
                      tempConditions[j] = thisCondition;
                    }
                  }
                }
              }
            }
          } else {
            tempConditions = saveCondition;
          }
          if (
            (this.$util.isType(tempConditions) === "Array" &&
              tempConditions.length > 0) ||
            this.$util.isType(tempConditions) === "String"
          ) {
            for (let n = 0; n < obj.variableFields.length; n++) {
              let variable = obj.variableFields[n];
              if (!variable.tableName) {
                // 表单主表字段
                let mfValue = mainTables[variable.oldArgName]
                  ? this.getValidTypesValueString(
                    mainTables[variable.oldArgName]
                  )
                  : "0";
                if (this.$util.isType(tempConditions) === "Array") {
                  tempConditions = tempConditions.map(function (temp) {
                    return temp.replace(variable.oldArgName, mfValue);
                  });
                } else {
                  tempConditions = tempConditions.replace(
                    variable.oldArgName,
                    mfValue
                  );
                }
              }
            }
            if (this.$util.isType(tempConditions) === "Array") {
              for (let y = 0; y < tempConditions.length; y++) {
                let tempCondition = tempConditions[y];
                tempCondition = tempCondition.replace(/or/g, "||");
                tempCondition = tempCondition.replace(/and/g, "&&");
                console.log("依次-校验公式替换结果", tempCondition);
                try {
                  let result = eval(tempCondition);
                  if (this.$util.isType(result) === "Boolean") {
                    if (result) return false;
                  }
                } catch (e) {
                  console.error("错误的逻辑表达式", e);
                  this.tipError("保存失败，表单配置了错误的逻辑表达式！");
                }
              }
            } else {
              tempConditions = tempConditions.replace(/or/g, "||");
              tempConditions = tempConditions.replace(/and/g, "&&");
              console.log("校验公式替换结果", tempConditions);
              try {
                let result = eval(tempConditions);
                if (this.$util.isType(result) === "Boolean") {
                  if (result) return false;
                }
              } catch (e) {
                console.error("错误的逻辑表达式", e);
                this.tipError("保存失败，表单配置了错误的逻辑表达式！");
              }
            }
          }
        }
      }
      return true;
    },
    tsbExport() {
      // 如果存在对应明细表的映射对象
      this.$refs.formTemplate.tsbExport();
    },
    /**
     * excel导入成功后将数据注入明细表
     * @param tableData
     * @constructor
     */
    ExcelImportOk(tableData) {
      for (let i = 0; i < this.mxTableName.length; i++) {
        let mxRef = this.getThisRef("mxform_" + this.mxTableName[i]);
        if (mxRef) {
          // 如果存在对应明细表的映射对象
          mxRef.setData(tableData);
        }
      }
    },
    /**
     * 系统打印
     */
    tsbPrint() {
      // 解析表数据
      var that = this;
      var flow = [];
      if (this.tempId && this.dataId) {
        let params = {
          action: "GetNoticesDetail",
          t_id: this.tempId,
          d_id: this.dataId,
          randomKey: this.$store.getters.getRandomKey
        };
        this.get("WorkFlow.ashx", params, {
          success: function (req) {
            //console.log(req);
            if (req.success) {
              flow = req.data;
            }
            setPrintData();
          },
          error: function (err) {
            setPrintData();
            console.error(err);
          }
        });
      } else {
        setPrintData();
      }

      function setPrintData() {
        let xmlData = that.renderFormTemplate(that.printConfig);
        let valueOkData;
        let printData, data, chineseName;
        // 多明细表数据
        if (xmlData.mxForms) {
          // 多明细表数据
          let table = that.$refs.formTemplate.getFromsConfig(xmlData.mxForms);
          // 解析成打印需要的数据
          xmlData.flow = flow;
          printData = that.analyticalPrintData(xmlData);
          // 获得主表数据
          data = that.form.getFieldsValue();
          chineseName = that.$refs.formTemplate.getChineseName();
          for (let key in chineseName) {
            data[key] = chineseName[key];
          }
          // 将主表数据放入打印数据
          valueOkData = that.setPrintDataValue(printData, data);
          // valueOkData.mxForm = {
          //   columns: table[0].columns,
          //   data: table[0].data,
          //   type: "mxForm"
          // };
          // 目前只能打印单明细
          if(table && table.length>0) {
            valueOkData.mxForm = {
              columns: table[0].columns,
              data: that.$refs.formTemplate.getMxTableData(
                true
              )[0].data,
              type: "mxForm"
            };
          }
        } else {
          // 单明细表数据
          // 解析成打印需要的数据
          xmlData.flow = flow;
          printData = that.analyticalPrintData(xmlData);
          // printData.formRows = that.formConfigs.formRows;
          // 那到etable那里的行定义
          var etableColConfig = that.getColDefinition();

          // 获得主表数据
          data = that.form.getFieldsValue();
          chineseName = that.$refs.formTemplate.getChineseName();
          for (let key1 in chineseName) {
            data[key1] = chineseName[key1];
          }
          // 将主表数据放入打印数据
          valueOkData = that.setPrintDataValue(printData, data);
          // 获取明细表数据
          if (valueOkData.mxForm) {
            valueOkData.mxForm.columns = etableColConfig[0].data;
            valueOkData.mxForm.data = that.$refs.formTemplate.getMxTableData(
              true
            )[0].data;
            // 隐藏字段
            valueOkData.hideField = that.hideField;
          }
        }

        if (that.$refs.systemPrintSet) {
          that.$refs.systemPrintSet.show(valueOkData);
        } else {
          that.$message.warning("此表单不支持系统打印");
        }
      }
      //console.log("valueOkData", valueOkData);
    },
    /**
     * 解析配置在模板Form对象中的tag配置
     * @param nonEditableFields
     * @param hiddenButtons
     */
    getFormTagConfig(nonEditableFields, hiddenButtons) {
      let formTagConfig = {};
      if (
        this.$util.isType(nonEditableFields) === "Array" &&
        nonEditableFields.length > 0
      ) {
        // 不能编辑的字段
        let mainReadOnlyFields = [];
        let detailReadOnly = null;
        for (let i = 0; i < nonEditableFields.length; i++) {
          if (nonEditableFields[i].includes(".", 1)) {
            let tempArray = nonEditableFields[i].split(".");
            if (detailReadOnly) {
              if (detailReadOnly[tempArray[0]]) {
                detailReadOnly[tempArray[0]].push(tempArray[1]);
                continue;
              }
            } else {
              detailReadOnly = {};
            }
            detailReadOnly[tempArray[0]] = [tempArray[1]];
          } else {
            mainReadOnlyFields.push(nonEditableFields[i]);
          }
        }
        console.log("主表不可编辑字段:", mainReadOnlyFields);
        formTagConfig.mainReadOnlyFields = mainReadOnlyFields;
        console.log("明细表不可编辑字段:", detailReadOnly);
        formTagConfig.detailReadOnlyFields = detailReadOnly;
      }
      if (
        this.$util.isType(hiddenButtons) === "Array" &&
        hiddenButtons.length > 0
      ) {
        // 需要隐藏的操作按钮
        let mainHiddenButton = [];
        let detailHiddenButton = null;
        for (let i = 0; i < hiddenButtons.length; i++) {
          if (hiddenButtons[i].includes(".", 1)) {
            let tempArray = hiddenButtons[i].split(".");
            if (detailHiddenButton) {
              if (detailHiddenButton[tempArray[0]]) {
                detailHiddenButton[tempArray[0]].push(tempArray[1]);
                continue;
              }
            } else {
              detailHiddenButton = {};
            }
            detailHiddenButton[tempArray[0]] = [tempArray[1]];
          } else {
            mainHiddenButton.push(nonEditableFields[i]);
          }
        }
        console.log("主表需要隐藏的操作按钮:", mainHiddenButton);
        formTagConfig.mainHiddenButtons = mainHiddenButton;
        console.log("明细表需要隐藏的操作按钮:", detailHiddenButton);
        formTagConfig.detailHiddenButtons = detailHiddenButton;
      }
      return formTagConfig;
    },
    /**
     * 比较两个对象，获取差异
     * @param newData
     * @param oldData
     * @return {*}
     */
    getDataChange(newData, oldData) {
      let data = null;
      if (newData || oldData) {
        if (newData && this.$util.isType(newData) === "Object" && !oldData) {
          // 全新加入
          data = {
            operateType: 1,
            //"dataID": "数据ID",
            data: newData
          };
        } else if (
          !newData &&
          oldData &&
          this.$util.isType(oldData) === "Object"
        ) {
          // 移除
          data = {
            operateType: 3,
            dataID: oldData.ID,
            data: oldData
          };
        } else if (
          this.$util.isType(newData) === "Object" &&
          this.$util.isType(oldData) === "Object"
        ) {
          let props = Reflect.ownKeys(newData);
          let changeData = {};
          for (let i = 0, max = props.length; i < max; i++) {
            if (!this.$util.isObjEqual(newData[props[i]], oldData[props[i]])) {
              if (
                this.$util.isType(newData[props[i]]) === "String" ||
                this.$util.isType(oldData[props[i]]) === "String"
              ) {
                let temp1 = this.$util.trimStr(
                  newData[props[i]] ? newData[props[i]] + "" : ""
                );
                let temp2 = this.$util.trimStr(
                  oldData[props[i]] ? oldData[props[i]] + "" : ""
                );
                if (temp1 !== temp2) {
                  if (
                    this.$util.isNumberString(temp1) &&
                    this.$util.isNumberString(temp2)
                  ) {
                    if (this.$util.mathUtil.accSub(temp1, temp2) != 0) {
                      changeData[props[i]] = newData[props[i]];
                    }
                  } else changeData[props[i]] = newData[props[i]];
                }
              } else {
                changeData[props[i]] = newData[props[i]];
              }
            }
          }
          if (this.$util.isValidObject(changeData)) {
            data = {
              operateType: 2,
              dataID: newData.ID,
              data: changeData
            };
          }
        }
      }
      return data;
    },
    /**
     *
     * "[明细表名]": [
     {
         "operateType": 2,
         "dataID": "数据ID",
         "data": {
             "字段名": "新值"
         }
     }]
     * @param newDatas
     * @param oldDatas
     */
    getDetialDatasChange(newDatas, oldDatas) {
      let resultData = null;
      if (
        (newDatas && this.$util.isType(newDatas) === "Array") ||
        (oldDatas && this.$util.isType(oldDatas) === "Array")
      ) {
        if (
          newDatas &&
          newDatas.length > 0 &&
          (!oldDatas || oldDatas.length == 0)
        ) {
          // 只有新数据
          resultData = {};
          for (let i1 = 0; i1 < newDatas.length; i1++) {
            let tableName = newDatas[i1].tableName;
            let dataList = newDatas[i1].data;
            let dataChangeList = [];
            if (
              dataList &&
              this.$util.isType(dataList) === "Array" &&
              dataList.length > 0
            ) {
              for (let j1 = 0; j1 < dataList.length; j1++) {
                let changeObj = this.getDataChange(dataList[j1], null);
                dataChangeList.push(changeObj);
              }
              resultData[tableName] = dataChangeList;
            }
          }
        } else if (
          oldDatas &&
          oldDatas.length > 0 &&
          (!newDatas || newDatas.length == 0)
        ) {
          // 只有老数据，说明被删除
          resultData = {};
          for (let i2 = 0; i2 < oldDatas.length; i2++) {
            let tableName1 = oldDatas[i2].tableName;
            let dataList1 = oldDatas[i2].datas;
            let dataChangeList1 = [];
            if (
              dataList1 &&
              this.$util.isType(dataList1) === "Array" &&
              dataList1.length > 0
            ) {
              for (let j2 = 0; j2 < dataList1.length; j2++) {
                let changeObj1 = this.getDataChange(null, dataList1[j2]);
                dataChangeList1.push(changeObj1);
              }
              resultData[tableName1] = dataChangeList1;
            }
          }
        } else if (
          oldDatas &&
          oldDatas.length > 0 &&
          newDatas &&
          newDatas.length > 0
        ) {
          // 新老数据都有
          let that = this;
          resultData = {};
          let newDatasHaveingTables = [];
          // 新提交的明细数据对应的表有数据需要保存
          newDatas.forEach(function (d) {
            if (d.data && d.data.length > 0)
              newDatasHaveingTables.push(d.tableName);
          });
          if (newDatasHaveingTables.length > 0) {
            for (let i = 0; i < this.mxTableName.length; i++) {
              let mxName = this.mxTableName[i];
              let newData = newDatas.find(function (d) {
                return d.tableName === mxName;
              });
              let oldData = oldDatas.find(function (d) {
                return d.tableName === mxName;
              });
              let changeDetialList = [];
              if (newData != null && newDatasHaveingTables.includes(mxName)) {
                // 新数据列表对应表名包含数据
                if (
                  oldData != null &&
                  oldData.datas &&
                  oldData.datas.length > 0
                ) {
                  // 对应表包含老的数据
                  let allNewHHList = []; // 所有新数据中包含的行号
                  newData.data.forEach(function (d1) {
                    let hh = d1["_HH"] ? d1["_HH"] : d1["HH"];
                    allNewHHList.push(hh);
                  });
                  let newHHlist = [].concat(allNewHHList);
                  oldData.datas.forEach(function (d2) {
                    let hh2 = d2["_HH"] ? d2["_HH"] : d2["HH"];
                    if (allNewHHList.includes(hh2)) {
                      // 新老数据都存在， 需要做数据对比
                      newHHlist.splice(newHHlist.indexOf(hh2), 1);
                      let change = that.getDataChange(
                        newData.data[allNewHHList.indexOf(hh2)],
                        d2
                      );
                      if (change) changeDetialList.push(change);
                    } else {
                      // 只有老数据，说明是被删除的行
                      changeDetialList.push(that.getDataChange(null, d2));
                    }
                  });
                  if (newHHlist.length > 0) {
                    // 剩余未匹配的是新加入的
                    newHHlist.forEach(function (hh) {
                      changeDetialList.push(
                        that.getDataChange(
                          newData.data[allNewHHList.indexOf(hh)],
                          null
                        )
                      );
                    });
                  }
                } else {
                  // 意味着这些都是是新加的数据
                  newData.data.forEach(function (d1) {
                    changeDetialList.push(that.getDataChange(d1, null));
                  });
                }
              } else if (
                oldData != null &&
                oldData.datas &&
                oldData.datas.length > 0
              ) {
                // 只有老的包含数据， 意味着该表的数据被清空？
                oldData.datas.forEach(function (d2) {
                  changeDetialList.push(that.getDataChange(null, d2));
                });
              }
              if (changeDetialList.length > 0) {
                resultData[mxName] = changeDetialList;
              }
            }
          } else {
            // 新明细表无数据
            resultData = this.getDetialDatasChange(null, oldDatas);
          }
        }
      }
      if (this.$util.isValidObject(resultData)) {
        return resultData;
      }
      return null;
    },
    /**
     * 获取表单的数据修改内容
     * @param values
     * @param newDetails
     */
    getFormChanged(values, newDetails) {
      let modifyData = {};
      console.log("原数据", this.tableData.mainTable);
      console.log("新数据", values);
      let mainDataChange = this.getDataChange(values, this.tableData.mainTable);
      if (mainDataChange != null) {
        let mainTable = {
          tableName: this.mainTableName,
          dataID: this.dataId
        };
        modifyData.mainTable = Object.assign(mainDataChange, mainTable);
      }
      console.log("原明细数据", this.tableData.detailTable);
      console.log("新明细数据", newDetails);
      let detailDataChange = this.getDetialDatasChange(
        newDetails,
        this.tableData.detailTable
      );
      if (detailDataChange) modifyData.detailTable = detailDataChange;
      return modifyData;
    },
    /**
     * 响应表单控件扩展onChange事件
     * @param data
     */
    onFormExtendEvent(data) {
      let mainData = this.form.getFieldsValue();
      if (data.onChange) data.onChange(this, mainData, data.value);
    }
  }
};
