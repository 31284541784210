import "core-js/stable";
import "regenerator-runtime/runtime";
import util from "./utils/util.js";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import { Icon } from "ant-design-vue";
import "./components/vxe-table/utils";
import "./components/vxe-table/table";
import "vxe-table/lib/index.css";
import "vxe-table-plugin-virtual-tree/dist/style.css";

import "ant-design-vue/lib/style/index.less";
import "ant-design-vue/lib/style/components.less";

import "./assets/style/themes.less";
//const themes = (themes) => () => import('./assets/style/themes_'+themes+'.less');
//themes(window.SYS_THEME_NAME);

// 调用系统初始化前的勾子
window.SYSTEM_EVENTS_HOOK.beforeSystemInit();

Vue.use(Antd);

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "/js/iconfont.js"
});
Vue.component("IconFont", IconFont);

//导入其他自定义组件
import EIcon from "./components/EIcon";
import FormTemplate from "./components/FormTemplate";
import EControlTemplate from "./components/EControlTemplate";
import SearchCondition from "./components/SearchCondition";
import EHeadTitle from "./components/EHeadTitle";
import EFormModal from "./components/EFormModal";
import ETableModal from "./components/ETableModal";
import EBuildConditionModal from "./components/EBuildConditionModal";
import ETableContentFilter from "@/components/ETableContentFilter.vue";
import AuthorityChange from "@/views/listView/AuthorityChange.vue";
import EIconButton from "./components/EIconButton";
import EIconSlect from "./components/EIconSlect";
import EToolbar from "./components/EToolbar.vue";
import ViewImageModal from "./components/ViewImageModal.vue";
import VueDraggableResizable from "vue-draggable-resizable";
import EFilePreviewModal from "./components/EFilePreviewModal";
import EConfigureProductSerialModal from "./components/EConfigureProductSerialModal";

Vue.component("vue-draggable-resizable", VueDraggableResizable);
Vue.component("EIcon", EIcon);
Vue.component("FormTemplate", FormTemplate);
Vue.component("EControlTemplate", EControlTemplate);
Vue.component("SearchCondition", SearchCondition);
Vue.component("EHeadTitle", EHeadTitle);
Vue.component("EFormModal", EFormModal);
Vue.component("ETableModal", ETableModal);
Vue.component("EBuildConditionModal", EBuildConditionModal);
Vue.component("ETableContentFilter", ETableContentFilter);
Vue.component("AuthorityChange", AuthorityChange);
Vue.component("EIconButton", EIconButton);
Vue.component("EIconSlect", EIconSlect);
Vue.component("EToolbar", EToolbar);
Vue.component("ViewImageModal", ViewImageModal);
Vue.component("EFilePreviewModal", EFilePreviewModal);
Vue.component("EConfigureProductSerialModal", EConfigureProductSerialModal);

import { templateParser, CXS_TO_ECONTROLS } from "./utils/e1_TemplateParser.js";
//导入公共控件
import {
  EInput,
  ERadio,
  ECheckbox,
  ESelect,
  EDatetimePicker,
  EDepartmentSelect,
  EEmployeeSelect,
  ESupplierSelect,
  ENumericUpDown,
  EUpload,
  ETable,
  ERsGjdy,
  EBooleanCheckbox,
  ECustomer,
  EGyBdmb,
  EMaterials,
  EGroupBox,
  EZjgs,
  EXzjs,
  EFsjeFormula,
  EKjpztjFormula,
  TableButtonControls,
  EAccountingSubject,
  EMoney,
  EMaterialsClassify,
  EInventoryLocation,
  ESTWarehouse,
  ChangeIcon,
  ETimeHorizon,
  ENumberHorizon,
  EFilePreview,
  keepAccountZY,
  keepAccountKM
} from "./controls/FormControls/index.js";

import { ImportExcelDataModal } from "./controls/Excel/index.js";

// 系统打印控件
import { systemPrintSet, NP58Print } from "./controls/Print/index.js";

Vue.component("systemPrintSet", systemPrintSet);
Vue.component("NP58Print", NP58Print);

Vue.component("ImportExcelDataModal", ImportExcelDataModal);
Vue.component("EInput", EInput);
Vue.component("ERadio", ERadio);
Vue.component("ECheckbox", ECheckbox);
Vue.component("ESelect", ESelect);
Vue.component("EDatetimePicker", EDatetimePicker);
Vue.component("EDepartmentSelect", EDepartmentSelect);
Vue.component("EEmployeeSelect", EEmployeeSelect);
Vue.component("ESupplierSelect", ESupplierSelect);
Vue.component("ENumericUpDown", ENumericUpDown);
Vue.component("EBooleanCheckbox", EBooleanCheckbox);
Vue.component("ETable", ETable);
Vue.component("ECustomer", ECustomer);
Vue.component("EGyBdmb", EGyBdmb);
Vue.component("EMaterials", EMaterials);
Vue.component("EUpload", EUpload);
Vue.component("ERsGjdy", ERsGjdy);
Vue.component("EGroupBox", EGroupBox);
Vue.component("TableButtonControls", TableButtonControls);
Vue.component("EZjgs", EZjgs);
Vue.component("EXzjs", EXzjs);
Vue.component("EFsjeFormula", EFsjeFormula);
Vue.component("EKjpztjFormula", EKjpztjFormula);
Vue.component("EAccountingSubject", EAccountingSubject);
Vue.component("EMoney", EMoney);
Vue.component("EMaterialsClassify", EMaterialsClassify);
Vue.component("EInventoryLocation", EInventoryLocation);
Vue.component("ESTWarehouse", ESTWarehouse);
Vue.component("ChangeIcon", ChangeIcon);
Vue.component("ETimeHorizon", ETimeHorizon);
Vue.component("ENumberHorizon", ENumberHorizon);
Vue.component("EFilePreview", EFilePreview);
Vue.component("keepAccountZY", keepAccountZY);
Vue.component("keepAccountKM", keepAccountKM);

// import VueDND from "awe-dnd";
// Vue.use(VueDND);

// 关于我们控件
import { aboutUsWindow } from "@/views/index/aboutUsWindow.vue";

import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";

Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
});

Vue.component("aboutUsWindow", aboutUsWindow);

let REPORTSERVICE_TYPES = ["RDL", "JAR"];
let FROM_APPROVAL_MODES = ["merge_approve", "merge_submit"];
let VXE_TABLE_PAGESIZE_OPTIONS = [];
if (window.PAGE_SIZE_OPTIONS) {
  for (let i = 0; i < window.PAGE_SIZE_OPTIONS.length; i++) {
    try {
      if (
        window.PAGE_SIZE_OPTIONS[i] !== "所有" &&
        window.PAGE_SIZE_OPTIONS[i] !== "全部"
      ) {
        let p = parseInt(window.PAGE_SIZE_OPTIONS[i]);
        VXE_TABLE_PAGESIZE_OPTIONS.push({label: window.PAGE_SIZE_OPTIONS[i], value: p});
      } else {
        VXE_TABLE_PAGESIZE_OPTIONS.push({label: window.PAGE_SIZE_OPTIONS[i], value: Infinity});
      }
    } catch (e) {
    }
  }
} else {
  VXE_TABLE_PAGESIZE_OPTIONS = [10, 20, 40, 80, 160];
}
let formDetailAddRowModes = [];
let DEFAULT_FROM_DETAIL_ADDROW_MODE = "custom";
if(util.isType(window.FROM_DETAIL_ADDROW_MODES) === "Array") {
  window.FROM_DETAIL_ADDROW_MODES.forEach(function(f){
    let fStr;
    if (util.isInteger(f)) {
      fStr = f + "";
      if (!formDetailAddRowModes.includes(fStr)) {
        formDetailAddRowModes.push(fStr);
      }
    }
    if (util.isNumberString(f) && util.isInteger(util.getNumber(f))) {
      if (!formDetailAddRowModes.includes(f)) {
        formDetailAddRowModes.push(f);
      }
    }
  });
}
formDetailAddRowModes.push(DEFAULT_FROM_DETAIL_ADDROW_MODE);

Vue.prototype.TemplateParser = templateParser;
Vue.prototype.$config = {
  CXS_TO_ECONTROLS,
  defaultControlSize: window.DEFALUT_CONTRLO_SIZE,
  notAllowRepeatedOpening: !!window.NOT_ALLOW_REPEATED_OPENING,
  defaultPageSizeOptions: window.PAGE_SIZE_OPTIONS
    ? window.PAGE_SIZE_OPTIONS
    : ["10", "20", "40", "80", "160"],
  defaultPageSize: window.PAGE_SIZE ? window.PAGE_SIZE : 30,
  vxeDefaultPageSizeOptions: VXE_TABLE_PAGESIZE_OPTIONS,
  TREE_DEFAULT_EXPANDALL: window.TREE_DEFAULT_EXPANDALL,
  table_stripe: window.TABLE_STRIPE ? window.TABLE_STRIPE : false,
  fromApprovalMode: window.FROM_APPROVAL_MODE && FROM_APPROVAL_MODES.includes(window.FROM_APPROVAL_MODE) ? window.FROM_APPROVAL_MODE : "",
  formDetailAddRowModes: formDetailAddRowModes,
  iframeTabsNeedCache: window.IFRAME_TABS_NEED_CACTHE ? window.IFRAME_TABS_NEED_CACTHE : false,
  formDerailDefaultAddRows: util.isInteger(util.getNumber(window.FORM_DETAIL_ADD_DEFAULT_MODE_ROW)) ? window.FORM_DETAIL_ADD_DEFAULT_MODE_ROW : 1,
  toolbarPopConfirm:
    window.TOOLBAR_CONFIRM_POPMODLE
      ? window.TOOLBAR_CONFIRM_POPMODLE
      : false,
  NOTICE_FORM_SHOW_WORK_FLOWRECORD: window.NOTICE_FORM_SHOW_WORK_FLOWRECORD || false,
};
Vue.prototype.BaseUrl = window.SERVER_URL;
Vue.prototype.ServerPath = window.SERVER_PATH;
Vue.prototype.ServerNames = window.SERVER_NAMES;
Vue.prototype.MainTagDisplay = window.MAIN_DISPLAY ? "_0" : null;
Vue.prototype.Default_DHBM = window.DEFAULT_DHBM ? window.DEFAULT_DHBM : null;
Vue.prototype.defaultTableKeyName = "table_key"; // 用于ant-table组件的自动主键字段
// 配置使用的报表服务类型
Vue.prototype.DefaultReportService =
  window.REPORTSERVICE_TYPE && REPORTSERVICE_TYPES.includes(window.REPORTSERVICE_TYPE)
    ? window.REPORTSERVICE_TYPE
    : "RDL";
// 通讯数据加密密钥，对应公钥
Vue.prototype.C_RSAPublicKey =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCsGPj1q4oCHPI7sV24QQ0bewy6IADrEj0MzPfnsW/0qcf34BkbhHRSXZXt+O2OfNDe6O6kM3W84QBkWw5CcMbe2d46VNiWTBCaOMH0ZGvYnwMyucP6+jRdR1NcoETp96p3vGi0Rk6bzThDKDw4Lge2zOVhTwHoTZuVArsCV4aSawIDAQAB";
//表单扩展属性
Vue.prototype.FormExtends = window.FORM_EXTENDS;
Vue.prototype.ViewLinkExtends = window.VIEW_LINK_EXTENDS;

Vue.prototype.JasperReportsUser = "jasperadmin";
Vue.prototype.JasperReportsPass = "jasperadmin";
//对应使用填制凭证表单的模板id
Vue.prototype.KeepAccountsFormTempIds = [79,512];
Vue.config.productionTip = false;



Vue.prototype.$util = util;

new Vue({
  data() {
    return {
      NowWindowsSize: util.findDimensions()
    };
  },
  router,
  store,
  render: h => h(App)
}).$mount("#app");
