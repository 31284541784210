import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法
import { systemService } from "@/mixins/systemService.js";
import Axios from "axios";

export const loginMixins = {
  mixins: [requestControls, systemService],
  data() {
    return {
      info: null
    };
  },
  created() {},
  methods: {
    login(form) {
      //console.log("login");
      let that = this;
      form.validateFields((err, values) => {
        if (!err) {
          if (!this.loading) {
            that.loading = true;
            let loginParams = {
              action: "UserLogin",
              username: values.username,
              userpsw: that.paramEncrypt(values.password)
            };
            try {
              that.info.destroy();
            } catch (error) {
              error;
            }
            that.post("System.ashx", loginParams, {
              headers: {
                "Content-Type":
                  "application/x-www-form-urlencoded;charset=utf-8"
              },
              notTimeOut: true,
              success: function(req) {
                //console.log("请求结果",req);
                //保存全局状态

                Axios.defaults.headers.common["Authorization"] =
                  req.data.randomKey;

                that.$store.commit("login", {
                  userName: req.data.userName,
                  userDept: req.data.depart,
                  userID: req.data.userId,
                  userQrimg: "/static/qrtest.png",
                  randomKey: req.data.randomKey,
                  employee: req.data.employee,
                  departId: req.data.departId,
                  employeeId: req.data.employeeId,
                  userHeadLogo:
                    "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png", //测试头像，需要根据需求设置
                  companyId: req.data.companyId
                });
                //加载左侧菜单
                that.loadMenuList(function() {
                  that.loading = false;
                  that.$router.push({
                    path: "/",
                    replace: true
                  }); //跳转主页
                  document.onkeydown = undefined;
                });
              },
              error: function(perr) {
                console.error("请求失败", perr);
                that.loading = false;
                that.info = that.$error({
                  title: "提示",
                  // content: "登录失败."
                  content: "登录失败." + perr.message ? perr.message : ""
                });
              }
            });
          }
        } else {
          try {
            this.info.destroy();
          } catch (errs) {
            errs;
          }
          // this.info = this.$info({
          //   title: "提示",
          //   content: "请输入账号和密码！"
          // });
          return false;
        }
      });
    }
  }
};
