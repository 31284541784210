<template>
  <a-modal
    v-if="visible"
    v-model="visible"
    :bodyStyle="{ width: 'auto', maxHeight: maxHeight, maxWidth: maxWidth }"
    :width="allWidth"
    :centered="true"
  >
    <slot name="title">
      <template slot="title">{{ title }}</template>
    </slot>
    <slot name="content">
      <div
        class="clearfix"
        v-if="fileList['img'] && fileList['img'].length > 0"
      >
        <div class="title"><a-icon type="picture" />图片</div>
        <div class="img-class">
          <!-- :images="getUploadUrl(fileList['img'])"  -->
          <viewer v-if="fileList['img'] != ''">
            <div
              class="upload-img"
              v-for="src in getUploadUrl(fileList['img'])"
              :key="src"
            >
              <img :src="src" />
            </div>
          </viewer>
        </div>
        <div
          class="title"
          v-if="fileList['other'] && fileList['other'].length > 0"
        >
          <a-icon type="folder-open" />其他文件
        </div>
        <div>
          <div v-for="a in getUploadUrl(fileList['other'])">
            <a target="_blank" :key="a" :href="a">{{ a }}</a>
          </div>
        </div>
      </div>
    </slot>
    <template slot="footer">
      <a-button
        class="headButton"
        type="primary"
        @click="() => (visible = false)"
        >关闭
      </a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: "EFilePreviewModal",
  data() {
    return {
      // 表单
      fileList: {},
      showForm: false,
      showFormError: false,
      showFormSpin: false,
      thisFiles: null,
      imgExt: ["png", "jpg", "jpeg", "bmp", "gif"]
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    Files: {
      // 操作按钮
      type: Array,
      default: function() {
        return [];
      }
    },
    width: {
      type: [Number, String],
      validator: function(value) {
        if (value) {
          return (
            this.$util.isType(value) === "val" ||
            this.$util.isNumberString(value) ||
            this.$util.isPixelString(value) ||
            this.$util.isPercentageString(value)
          );
        }
        return true;
      }
    }
  },
  computed: {
    visible: {
      get: function() {
        return this.value;
      },
      set: function(v) {
        this.$emit("input", v);
      }
    },
    maxHeight: function() {
      return this.$root.NowWindowsSize.height * 0.8 + "px";
    },
    maxWidth: function() {
      return this.$root.NowWindowsSize.width * 0.9 + "px";
    },
    allWidth: function() {
      // 如果设置了width默认宽度，则使用默认宽度计算，否则根据表单每行列数进行计算宽度
      if (this.$util.isType(this.width) === "String") {
        if (this.$util.isPixelString(this.width)) {
          let widthNumber = this.width.substring(0, this.width.indexOf("px"));
          return parseInt(widthNumber) + 48;
        } else if (this.$util.isNumberString(this.width)) {
          return parseInt(this.width) + 48;
        } else {
          return parseFloat(this.width) * this.$root.NowWindowsSize.width + 48;
        }
      } else if (this.$util.isType(this.width) === "val") {
        return this.width + 48;
      } else {
        return 650;
      }
    }
  },
  watch: {
    Files: function(v) {
      this.thisFiles = v;
      this.setFiles(this.thisFiles);
    }
  },
  created() {
    console.log("传入的附加列表", this.Files);
    this.thisFiles = this.Files;
  },
  mounted() {
    this.setFiles(this.thisFiles);
  },
  methods: {
    /**
     *
     */
    setFiles(data) {
      if (data == "" || data == null) {
        return;
      }
      let imgFile = [];
      let otherFile = [];

      for (let i = 0; i < data.length; i++) {
        let fileExtension = data[i]["n"].substring(
          data[i]["n"].lastIndexOf(".") + 1
        );
        if (this.imgExt.indexOf(fileExtension) != -1) {
          imgFile.push(data[i]);
        } else {
          otherFile.push(data[i]);
        }
      }

      this.$set(this.fileList, "img", imgFile);
      this.$set(this.fileList, "other", otherFile);
    },
    /**
     * 将对应的文件列表的文件路径进行转换
     * @param data
     * @return {Array}
     */
    getUploadUrl(data) {
      if (data == null || data == "") {
        return [];
      }
      let returnData = [];

      let isJson = true;
      if (typeof data != "object") {
        try {
          data = JSON.parse(data);
        } catch (err) {
          err;
          isJson = false;
        }
      }
      if (isJson) {
        for (let j = 0; j < data.length; j++) {
          returnData.push(checkUrl(data[j]["p"]));
        }
      } else {
        data = data.split(",");
        for (let i = 0; i < data.length; i++) {
          returnData.push(checkUrl(data[i]));
        }
      }
      return returnData;
      function checkUrl(url) {
        if (url.indexOf("http://") == -1 || url.indexOf("https://") == -1) {
          url = window.UPLOAD_URL + url;
        }
        return url;
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "../assets/style/base/components/eFile-preview-modal.less";
</style>
