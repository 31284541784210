/**
 * 生产订单工作台，相关功能扩展支持
 * @type {{}}
 */
export const e1SC_SCDD_WORKBENCH = {
  data() {
    return {};
  },
  methods: {
    /**
     * 生产订单操作
     * 下达订单操作
     */
    tsbBegin(button) {
      this.updateOrderStute(button);
    },
    /**
     * 生产订单操作
     * 暂停订单操作
     */
    tsbSuspend(button) {
      this.updateOrderStute(button);
    },
    /**
     * 生产订单操作
     * 终止订单操作
     */
    tsbRecovery(button) {
      this.updateOrderStute(button);
    },
    /**
     * 生产订单操作
     * 终止订单操作
     */
    tsbTermination(button) {
      this.updateOrderStute(button);
    },
    /**
     * 修改生产订单状态
     */
    updateOrderStute(button) {
      let rowKeys = null;
      if (this.selectedRowKeys.length >= 1) {
        rowKeys = this.getSelectedScDd(this.tableDatas, this.selectedRowKeys);
      }
      if (rowKeys && rowKeys.length > 0 && button && button.param) {
        let that = this;
        let dataFields = ["_BDBH", "ID"];
        let dataIDField = "ID";
        let SNField = "_BDBH";
        if (this.tableConfig.primaryKey && this.tableConfig.nextFloorKeyField) {
          dataFields = [
            this.tableConfig.primaryKey,
            this.tableConfig.nextFloorKeyField
          ];
          dataIDField = this.tableConfig.primaryKey;
          SNField = this.tableConfig.nextFloorKeyField;
        }
        let bhAndIds = this.getSelectDatasFiled(
          this.tableDatas,
          rowKeys,
          dataFields
        );
        let params = {
          action: "UpdateProductionOrderStatus",
          orderSN: JSON.stringify(bhAndIds[SNField]), //主表名
          updateStatus: button.param.et_name, // 模板id  从DHCS中获取
          dataID: JSON.stringify(bhAndIds[dataIDField]), // 插入之后返回的主键id
          t_id: this.tempId,
          randomKey: this.$store.getters.getRandomKey
        };
        this.startProcessing(button);
        this.post("Modules/Scgl.ashx", params, {
          success: function() {
            console.log("请求结果");
            that.finishedProcessing(button);
            that.getTableData();
            that.alertSuccess("操作成功");
          },
          error: function(err) {
            that.finishedProcessing(button);
            try {
              let jsonMessage = JSON.parse(err.message);
              let message = "";
              if (that.$util.isType(jsonMessage) === "Array") {
                // 多条错误信息
                jsonMessage.forEach(function(e, index) {
                  if (index == 0) message += e.message;
                  else message = message + "\n" + e.message;
                });
                that.alertError(message);
              } else {
                that.alertError(err.message);
              }
            } catch (e) {
              console.error(e);
              that.alertError(err.message);
            }
          }
        });
      } else {
        this.alert("请至少选择一条需要操作的生产订单");
      }
    },
    /**
     * 打开生产订单管理tab
     * @param button
     */
    tsbProductionOrder(button) {
      let rowKeys = null;
      if (this.selectedRowKeys.length >= 1) {
        rowKeys = this.getSelectedScDd(this.tableDatas, this.selectedRowKeys);
      }
      if (rowKeys && rowKeys.length == 1) {
        let bhAndIds = this.getSelectDatasFiled(this.tableDatas, rowKeys, [
          "_BDBH",
          "ID",
          "GDQRZT"
        ]);
        this.openTab({
          title: "管理生产订单-" + bhAndIds["_BDBH"][0],
          path: "/NewForm",
          query: {
            templetId: this.tempId,
            dataId: bhAndIds["ID"][0],
            type: "dateManageView",
            state: bhAndIds["GDQRZT"][0]
          }
        });
      } else {
        this.alert("请选择一条需要管理的生产订单");
      }
    },

    /**
     * 打开编辑BOM副本的tab
     */
    tsbBOM: function() {
      // bom副本对应的jdbm = 85004020001-SCDD20200713005
      let rowKeys = null;
      if (this.selectedRowKeys.length >= 1) {
        rowKeys = this.getSelectedScDd(this.tableDatas, this.selectedRowKeys);
      }
      if (rowKeys && rowKeys.length == 1) {
        let that = this;
        let dataFields = ["_BDBH", "_JDBM", "_GYLXBM", "GDQRZT"];
        let bhAndIds = this.getSelectDatasFiled(
          this.tableDatas,
          rowKeys,
          dataFields
        );
        let BDBH = bhAndIds["_BDBH"][0]; //"SCDD20200720002"; // 生产订单编号
        let gylxBM = bhAndIds["_GYLXBM"][0]; // 对应的工艺路线编码
        let BOM = bhAndIds["_JDBM"][0]; // 原bom的JDBM
        let spState = bhAndIds["GDQRZT"][0];
        this.getOrCreateGYLX_FB(gylxBM, BDBH, function() {
          let title = "编辑" + BDBH + "-BOM-" + BOM;
          let pageConfig = that.getBOMCopyManageViewConfig(
            BOM,
            BDBH,
            "dateManageView",
            spState
          );
          that.$store.commit("setCacheData", pageConfig);
          that.openTab({
            title: title,
            path: "/UserForms",
            query: {
              title: "生产订单-" + BDBH + "BOM管理",
              type: "dataViewCache" // 表示视图配置来源于缓存
            }
          });
        });
      } else {
        this.alert("请选择一条生产订单记录");
      }
    },
    /**
     * 获取或创建后返回对应生产订单的工艺路线副本ID
     * @param gylxBM
     * @param BDBH
     * @param callback
     */
    getOrCreateGYLX_FB: function(gylxBM, BDBH, callback) {
      let that = this;
      let params = {
        action: "GetGyLxIdByGylxbm",
        gylxBm: gylxBM,
        scddbh: BDBH
      };
      //console.log("select通过查询条件",listFilter, eTableIsSave);7uy
      this.get("Form.ashx", params, {
        success: function(res) {
          console.log("查询到对应的数据", res);
          if (res && res.data) {
            if (callback) callback(res.data);
          } else {
            that.alertWarning("未找到对应生产订单的工艺路线副本记录");
          }
        },
        error: function(err) {
          console.error("获取指定表数据失败", err);
          that.alertWarning("未找到对应生产订单的工艺路线副本记录");
        }
      });
    },
    /**
     * 打开编辑工艺路线副本的tab
     * @param button
     * @param other
     */
    tsbProduceRouting: function(button, other) {
      console.log("工艺路线", button, other);
      let rowKeys = null;
      if (this.selectedRowKeys.length >= 1) {
        rowKeys = this.getSelectedScDd(this.tableDatas, this.selectedRowKeys);
      }
      if (rowKeys && rowKeys.length == 1) {
        let that = this;
        let optType = 0;
        let dataFields = ["_BDBH", "_GYLXBM", "GDQRZT"];
        let bhAndIds = this.getSelectDatasFiled(
          this.tableDatas,
          rowKeys,
          dataFields
        );
        let BDBH = bhAndIds["_BDBH"][0]; //"SCDD20200720002"; // 生产订单编号
        let gylxBM = bhAndIds["_GYLXBM"][0]; // 对应的工艺路线编码
        let spState = bhAndIds["GDQRZT"][0];
        if (spState !== "未下达") {
          this.tipWarning("已下达的生产订单不能再编辑工艺路线");
          optType = 1;
        }
        this.getOrCreateGYLX_FB(gylxBM, BDBH, function(data) {
          let gylx = data;
          let title =
            (optType == 0 ? "编辑" : "查看") +
            "生产订单-" +
            BDBH +
            "-工艺路线管理";
          that.openTab({
            title: title,
            path: optType == 0 ? "/NewForm" : "/DetailForm",
            query: {
              dhbm: 983,
              templetId: 409,
              dataId: gylx,
              bm: "SYSTEM",
              type: "dateManageView"
            }
          });
        });
      } else {
        this.alert("请选择一条生产订单记录");
      }
    }
  }
};
