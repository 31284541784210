<template>
  <div
    :class="type !== 'textarea' ? 'e-form-einput' : 'e-form-einput__textarea'"
    :title="setvalue"
    :style="setWidth"
  >
    <!-- @keydown.down="openMenu"
    @keydown.enter="okMenu"
    @keydown.up.ctrl="lastRow"
    @keydown.down.ctrl="nextRow"-->
    <a-auto-complete
      v-model="setvalue"
      :defaultValue="defaultValue"
      :value="setvalue"
      :disabled="disabled"
      :type="type"
      :size="$config.defaultControlSize"
      :placeholder="placeholder"
      @blur.native="blur"
      @focus.native="focus"
      @click.native="click"
      :readOnly="readOnly"
      v-show="visible"
      :style="setWidth"
      ref="input"
      :data-source="!option ? [] : option.length == 0 ? allOption : option"
      @search="onSearch"
      :defaultOpen="false"
      :open="open"
    >
      <!-- :data-source="!option ? [] : option.length == 0 ? allOption : option" height: 60px-->
      <template slot="default">
        <a-textarea
          :rows="4"
          @keydown="keydown"
          :style="defaultStyle"
          @blur="blur"
        />
      </template>
    </a-auto-complete>

    <!-- 控件后附带的注释提醒 -->
    <li v-if="thisOldValue" class="form_right_icon">
      a
      <a-tooltip
        placement="topRight"
        :title="'原值：' + thisOldValue"
        arrow-point-at-center
      >
        <a style="height: auto;">
          <e-icon type="exclamation-circle"></e-icon>
        </a>
      </a-tooltip>
    </li>
    <div v-if="tip" class="e-form-nextTip">{{ tip }}</div>
  </div>
</template>

<script>
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法
import { isError } from "xe-utils/methods";
export default {
  mixins: [pageControls, requestControls],
  name: "keepAccountZY",
  data() {
    return {
      setvalue: this.defaultValue ? this.defaultValueSet() : this.value,
      thisOldValue: null,
      option: [],
      allOption: [],
      enterArray: [],
      open: false,
      ctrl: false
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    defaultValue: {
      type: [String, null],
      default: null
    },
    value: {
      type: [String, Number, null]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: ""
    },
    height: {
      type: [String, Number],
      default: null
    },
    tip: {
      type: String,
      default: null
    },
    modifiedValue: {
      type: [String, Number],
      default: null
    },
    dataOptionSave: {
      type: null,
      default: function() {
        return null;
      }
    },
    waiting: {
      type: Boolean,
      default: false
    }
  },
  created() {},
  mounted() {
    this.init();
  },
  computed: {
    setWidth: function() {
      let widthObj = { minWidth: "50px", width: "100%" }; // ,maxWidth: "300px"
      return widthObj;
    },
    defaultStyle: function() {
        let styleObject = {};
        if(this.$util.isType(this.height)==="val" || this.$util.isNumberString(this.height)) {
          styleObject.height = this.height+"px";
        } else if(this.$util.isPixelString(this.height)) {
          styleObject.height = this.height;
        } else {
          styleObject.height = "34px";
          styleObject.marginTop= "3px";
        }
        return styleObject;
    }
  },
  methods: {
    // 获取f2下拉菜单的数据源
    init() {
      var that = this;
      if (this.dataOptionSave != null) {
        this.allOption = this.dataOptionSave.allOption;
        this.enterArray = this.dataOptionSave.enterArray;
      }
      if (this.waiting) {
        return;
      }

      let params = {
        controlName: "CW_CYZY",
        action: "GetControlData",
        randomKey: this.$store.getters.getRandomKey
      };
      this.get("Form.ashx", params, {
        success: function(res) {
          if (res.success) {
            //兼容202102之前老版本接口
            let reqData = window.USE_NEW_API ? res.data:res;
            var titleIndex = reqData.data.option.titleIndex;
            var keyIndex = reqData.data.option.keyIndex;
            var array = [];
            var enterArray = [];
            for (var i = 0; i < reqData.data.ColsValue.length; i++) {
              array.push(reqData.data.ColsValue[i][titleIndex]);
              // array.push("i" + i);
              var enterObj;
              try {
                enterObj = {
                  label: reqData.data.ColsValue[i][titleIndex],
                  key: reqData.data.ColsValue[i][keyIndex].trim()
                };
              } catch (err) {
                enterObj = {
                  label: reqData.data.ColsValue[i][titleIndex],
                  key: reqData.data.ColsValue[i][keyIndex]
                };
              }
              enterArray.push(enterObj);
            }
            that.allOption = array;
            that.enterArray = enterArray;
            that.$emit("postDataOptionSave", {
              allOption: array,
              enterArray: enterArray
            });
          }
        },
        error: function() {
          //   _this.showTreeSpin = false;
        }
      });
    },
    // 响应填制凭证传来的键盘操作
    tableFocus(e) {
      console.log("zytableFocus");
      this.$refs.input.focus();
      this.$nextTick(() => {
        this.$refs.input.$el.querySelectorAll("textarea")[0].select();
        if (e) {
          e.preventDefault();
        }
      });
    },
    tableBlur(e) {
      this.$refs.input.blur();
      // e.preventDefault();
      // this.nextRow();
    },
    blur() {
      console.log("blur");
      this.open = false;
      this.$emit("blur", this.setvalue);
      console.log(this.decorator);
    },
    focus() {
      console.log("focus");
      this.$emit("focus", this.setvalue);
    },
    click() {
      this.$emit("click", this.setvalue);
      console.log("click");
    },
    defaultValueSet() {
      this.$emit("change", this.defaultValue);
      return this.defaultValue;
    },
    selectChange(v) {
      this.setvalue = this.setvalue + v;
    },
    onSearch(v) {
      if (!v || v == "") {
        this.option = this.allOption;
      } else {
        var rArray = [];
        for (var i = 0; i < this.allOption.length; i++) {
          var thisV = this.allOption[i];
          if (thisV.indexOf(v) != -1) {
            rArray.push(thisV);
          }
        }
        if (rArray.length == 0) {
          this.option = false;
        } else {
          this.option = rArray;
        }
      }
    },
    // 键盘操作
    keydown(e) {
      // F2打开收起菜单
      if (e.key == "F2") {
        e.preventDefault();
        if (!this.option) {
          this.open = false;
          this.$message.error("没有找到该摘要值的摘要菜单");
        } else {
          if (this.option.length == 0) {
            if (this.allOption.length == 0) {
              this.open = false;
              this.$message.error("没有找到对应值的摘要菜单");
            } else {
              this.open = !this.open;
            }
          } else {
            this.open = !this.open;
          }
        }
        // if (!this.option ? [] : option.length == 0 ? allOption : option)
        // this.open = !this.open;
      } else if (e.key == "Enter") {
        // enter键关闭菜单 并换行 并如果内容和数据源编码一样，则自动变成数据源
        if (this.open) {
          this.open = !this.open;
          e.notEnter = true;
          e.preventDefault();
          this.$nextTick(() => {
            this.$refs.input.$el.querySelectorAll("textarea")[0].select();
          });
        } else {
          // 如果回车时内容和编码一样，则自动填充
          for (var i = 0; i < this.enterArray.length; i++) {
            if (this.setvalue === this.enterArray[i].key) {
              this.setvalue = this.enterArray[i].label;
            }
          }
          e.preventDefault();
        }
      } else if (e.key == "ArrowUp") {
        // 上键换上一行，如果打开了open则阻止
        if (this.open) {
          e.notEnter = true;
        } else {
          this.lastRow(e);
        }
        e.preventDefault();
      } else if (e.key == "ArrowDown") {
        // 下键换下一行，如果打开了open则阻止
        e.preventDefault();
        if (this.open) {
          e.notEnter = true;
        } else {
          this.nextRow(e);
        }
      } else if (e.key == "ArrowLeft" || e.key == "ArrowRight") {
        // 左右键逻辑
        // 如果全选的时候左右键会移动下标，到最边缘才是切换列
        var valueLength = e.target.value.length;
        var selectionStart = e.target.selectionStart;
        var selectionEnd = e.target.selectionEnd;
        if (selectionStart == 0 && selectionEnd == 0) {
          e.preventDefault();
          return;
        }
        if (selectionStart == 0 && selectionEnd == valueLength) {
          // // 这是全选状态
          if (e.key == "ArrowLeft") {
            e.target.selectionStart = selectionEnd - 1;
            e.target.selectionEnd = selectionEnd - 1;
          } else if (e.key == "ArrowRight") {
            e.target.selectionStart = selectionEnd;
            e.target.selectionEnd = selectionEnd;
          }
          e.preventDefault();
          e.notEnter = true;
        } else {
          if (e.key == "ArrowLeft") {
            if (selectionEnd != 0) {
              e.target.selectionStart = selectionEnd - 1;
              e.target.selectionEnd = selectionEnd - 1;
              e.preventDefault();
              e.notEnter = true;
            }
          } else if (e.key == "ArrowRight") {
            if (selectionEnd != valueLength) {
              e.target.selectionStart = selectionEnd + 1;
              e.target.selectionEnd = selectionEnd + 1;
              e.preventDefault();
              e.notEnter = true;
            }
          }
        }
      } else if (e.key === "Backspace") {
        e.stopPropagation();
        e.notEnter = true;
      }
    },
    // enter键
    enter(e) {
      this.$emit("enter", e);
    },
    // 打开菜单
    openMenu(e) {
      console.log("down");
      e.stopPropagation();
      if (this.open == false) {
        this.open = true;
      }
    },
    okMenu() {
      if (this.open == true) {
        this.open = false;
      }
    },
    // 上一行
    lastRow(e) {
      e.preventDefault();
      e.stopPropagation();
      this.open = false;
      this.$emit("lastRow", e);
    },
    // 下一行
    nextRow(e) {
      try {
        e.preventDefault();
        e.stopPropagation();
      } catch (err) {
        err;
      }
      console.log("zy next");
      this.open = false;
      this.tableBlur();
      this.$nextTick(() => {
        this.$emit("nextRow", e);
      });
    }
  },
  watch: {
    setvalue(value) {
      // console.log(value);
      // this.$emit("change", value);
      this.onSearch(value);
      this.$emit("change", value);
    },
    value(value) {
      this.setvalue = value;
    },
    disabled() {},
    modifiedValue: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(val) {
        //console.log("监听einput值", val);
        if (val) {
          // 新值和老值互换显示
          this.thisOldValue = this.setvalue;
          this.setvalue = val;
        }
      }
    },
    dataOptionSave: {
      deep: true,
      handler: function(val) {
        //console.log("监听einput值", val);
        this.allOption = val.allOption;
        this.enterArray = val.enterArray;
      }
    }
  }
};
</script>
<style scoped lang="less">
.e-form-einput {
  position: relative;
}
.e-form-einput__textarea {
  padding-top: 6px;
  position: relative;
}
.form_right_icon {
  position: absolute;
  width: 20px;
  height: calc(100%);
  top: 0;
  right: -25px;
  z-index: 1200;
}
::v-deep .ant-table-body tr td {
  padding: 0px;
}
</style>
