<template>
  <div
    :class="click === 'detail' ? 'clickRowBody' : 'rowbody'"
    @click="clickBox('', 'detail')"
  >
    <h1>{{ data[0].label }}</h1>
    <h3>绑定表名: {{ data[0].tableName }}</h3>
    <!-- init{{initClick}}
    {{data}} -->
    <template>
      <a-form :form="form">
        <!-- <a-row :style="{ border: '0px dashed #000' }"> -->
        <template v-for="(item, index) in data[0].columns">
          <!-- {{index}}={{click}} -->
          <a-col :key="'row' + index">
            <div
              @click.stop="clickBox(index, 'detail')"
              class="clickbox"
              :style="
                click === index
                  ? { border: '1px dashed blue' }
                  : { border: '1px dashed #000' }
              "
            >
              <div>
                <div class="label">{{ item.label }}</div>
                <div class="button" @click.stop="">
                  <!-- {{ item.field }} -->
                  <a-input v-model="item.field"></a-input>
                  <!-- <a-button shape="circle" icon="plus" @click.stop="addCol(index)" v-if="index + 1 == data.length" class="add-button"></a-button> -->
                  <a-button
                    shape="circle"
                    icon="close"
                    @click.stop="delCol(index)"
                    class="del-button"
                  ></a-button>
                </div>
              </div>
              <div class="input">
                <a-input
                  v-if="item.controlType == 'input'"
                  :defaultValue="item.defaultValue"
                  :disabled="item.disabled"
                  :readOnly="item.readonly"
                  :style="{ width: '100%' }"
                  v-model="item.defaultValue"
                ></a-input>
                <a-select
                  v-if="item.controlType == 'select'"
                  :defaultValue="item.defaultValue"
                  :disabled="item.disabled"
                  :readOnly="item.readonly"
                  :style="{ width: '100%' }"
                  :options="item.options"
                  v-model="item.defaultValue"
                ></a-select>
                <a-input
                  v-if="item.controlType == 'supplier-select'"
                  placeholder="请点击选择供应商"
                  v-model="item.defaultValue"
                  :disabled="item.disabled"
                >
                  <a-icon
                    slot="prefix"
                    type="plus"
                    style="color:blue"
                    title="供应商选择控件"
                  />
                </a-input>
                <a-input
                  v-if="item.controlType == 'material-select'"
                  placeholder="请点击选择物料"
                  v-model="item.defaultValue"
                  :disabled="item.disabled"
                >
                  <a-icon
                    slot="prefix"
                    type="plus"
                    style="color:blue"
                    title="物料选择控件"
                  />
                </a-input>
                <a-time-picker
                  v-if="item.controlType == 'datetime'"
                  :style="{ width: '100%' }"
                  inputReadOnly
                />
                <a-input
                  placeholder="拍照控件"
                  v-model="item.defaultValue"
                  :disabled="item.disabled"
                  v-if="item.controlType == 'photo'"
                >
                  <a-icon slot="prefix" type="camera" style="color:blue" />
                </a-input>
                <a-input
                  placeholder="当前人控件"
                  v-model="item.defaultValue"
                  :disabled="item.disabled"
                  v-if="item.controlType == 'current-user'"
                >
                  <a-icon slot="prefix" type="user" style="color:blue" />
                </a-input>
                <a-input
                  placeholder="当前部门控件"
                  v-model="item.defaultValue"
                  :disabled="item.disabled"
                  v-if="item.controlType == 'current-department'"
                >
                  <a-icon slot="prefix" type="team" style="color:blue" />
                </a-input>
              </div>
            </div>
          </a-col>
        </template>
        <!-- </a-row> -->
      </a-form>
    </template>
    <a-button type="dashed" icon="plus" class="addbutton" @click="addCol"
      >添加一行明细列</a-button
    >

    <hidden
      :fieldsOption="fieldsOption"
      v-model="data[0].hiddenFields"
      :title="'明细表-' + data[0].tableName + '隐藏域'"
      :style="{ border: '0px' }"
    ></hidden>
  </div>
</template>

<script>
import moment from "moment";
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法
import { getOption, getAddData } from "../columnsSet.js";
import hidden from "./hidden.vue";
export default {
  mixins: [analyticalMenuAuth, pageControls, requestControls],
  name: "detailTable",
  data() {
    return {
      click: "detail",
      // data: this.pushArray(this.option[0].columns),
      data: this.pushArray(this.option),
      thisOptions: this.pushArray(this.option),
      form: this.$form.createForm(this),
      maxAdd: 2,
      addData: [],
      initClick: null
    };
  },
  computed: {
    // sum:function(){
    //     return this.Math+ this.English+this.chemistry;
    // },
    // average:function(){
    //     return Math.round(this.sum/3);
    // }
  },
  // props:[
  //   'option',
  //   'row'
  // ],
  props: {
    option: {
      type: null,
      default: null
    },
    row: {
      type: null,
      default: null
    },
    fieldsOption: {
      type: null,
      default: null
    }
  },
  watch: {
    // option: {
    //   deep: true,
    //   handler: function (newVal,oldVal) {
    //     alert("1");
    //   }
    // },
    option: function(value) {
      value;
    },
    initClick: function() {
      this.clickBox("", "detail");
    },
    // "option.0.columns": {
    //   deep: true,
    //   handler: function (newVal,oldVal) {
    //     debugger
    //   }
    // },
    // "option.0.columns" (value) {
    //   debugger
    // },
    row: function(value) {
      value;
    }
  },
  components: {
    hidden: hidden
  },
  created() {
    this.addData = getAddData();
    this.init();
  },
  mounted() {},
  methods: {
    submit() {
      // this.thisOptions[0].columns = this.data;
      // var op = [...this.option];
      // op[0].columns = this.data;
      // this.$emit("ok", this.thisOptions, this.row);
      this.$emit("ok", this.data, this.row);
    },
    pushArray(data) {
      // if (data.length == null) {
      //   return [data];
      // }
      return data;
    },
    init() {
      if (this.initClick != null) {
        this.clickBox("", "detail");
      }
    },
    addCol() {
      // 是否超出最大
      // if (index + 1 == this.data.length && index + 1 < this.maxAdd) {
      // var addData = [...this.addData][0];
      var addData = {
        controlType: "input",
        field: "",
        defaultValue: "",
        label: "",
        readonly: false,
        disabled: false,
        validate: ""
      };
      this.data[0].columns.push(addData);

      this.submit();
      // }
    },
    delCol(index) {
      if (this.data[0].columns[index]) {
        this.data[0].columns.splice(index, 1);
        this.$emit("del");
        this.click = null;
        this.submit();
      }
    },
    noClick() {
      this.click = null;
    },
    clickBox(index, type) {
      this.$emit("noClick");
      this.click = typeof index == "number" ? index : type;
      this.$emit("isClick", this.row, index, type);
    },
    clickMe(index) {
      // if (index === 0) {
      //   clickBox('','detail');
      //   return;
      // }
      this.clickBox(Number(index), "detail");
    }
  }
};
</script>

<style lang="less" scoped>
.rowbody {
  margin: 20px;
  border: 1px dashed red;
  padding: 20px;
}
.clickRowBody {
  margin: 20px;
  border: 1px dashed blue;
  padding: 20px;
}
.label {
  font-size: 20px;
  float: left;
}
.input {
  width: 100%;
}
.button {
  float: right;
  font-size: 20px;
  z-index: 10001;
}
.clickbox {
  cursor: pointer;
  z-index: 10000;
  padding: 5px;
  margin: 5px;
}
.add-button {
  color: rgb(0, 0, 0);
}
.del-button {
  color: #fff;
  background: red;
}
.border {
  border: 1px dashed #000;
  cursor: pointer;
  z-index: 10000;
  padding: 5px;
  margin: 5px;
}
.border-click {
  border: 1px dashed #f2f2f2;
  cursor: pointer;
  z-index: 10000;
  padding: 5px;
  margin: 5px;
}
.addbutton {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}
h1 {
  text-align: center;
}
</style>
