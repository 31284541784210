<template>
  <div>
    <div v-if="buttons && buttons.length > 0" class="form-headButton">
      <!-- 授权操作按钮 -->
      <e-toolbar
        :buttons="buttons"
        :operationDisabled="false"
        @click="onButtonClick"
      ></e-toolbar>
    </div>
    <div class="tabbody body" style="overflow:auto">
      <a-tabs defaultActiveKey="1" type="card">
        <a-tab-pane tab="基础属性" key="1">
          <a-form :form="form">
            <a-form-item
              label="表单定义编码"
              :label-col="labelOption.labelCol"
              :wrapper-col="labelOption.wrapperCol"
            >
              <a-input v-model="option.formTemplateCode" readOnly></a-input>
            </a-form-item>
            <a-form-item
              label="* 表单定义名称"
              :label-col="labelOption.labelCol"
              :wrapper-col="labelOption.wrapperCol"
            >
              <a-input v-model="option.formTemplateName"></a-input>
            </a-form-item>
            <a-form-item
              label="* 绑定表单"
              :label-col="labelOption.labelCol"
              :wrapper-col="labelOption.wrapperCol"
            >
              <!-- <ESelect v-model="option.relativeFormTemplateID" :options="bindingFormOption" v-if="bindingFormOption.length != 0"></ESelect> -->
              <a-select
                v-model="option.relativeFormTemplateID"
                :options="bindingFormOption"
                v-if="bindingFormOption.length != 0"
              ></a-select>
            </a-form-item>
            <a-form-item
              label="创建者"
              :label-col="labelOption.labelCol"
              :wrapper-col="labelOption.wrapperCol"
            >
              {{ option.creator }}
            </a-form-item>
            <a-form-item
              label="创建时间"
              :label-col="labelOption.labelCol"
              :wrapper-col="labelOption.wrapperCol"
            >
              {{ option.createTime }}
            </a-form-item>
            <a-form-item
              label="最后修改人"
              :label-col="labelOption.labelCol"
              :wrapper-col="labelOption.wrapperCol"
            >
              {{ option.reviser }}
            </a-form-item>
            <a-form-item
              label="最后修改时间"
              :label-col="labelOption.labelCol"
              :wrapper-col="labelOption.wrapperCol"
            >
              {{ option.reviseTime }}
            </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane tab="模板内容" key="2">
          <div class="boyd2">
            <div class="layout-left">
              <template v-if="load">
                <template v-for="(item, index) in data">
                  <template v-if="item.length != 0">
                    <!-- {{item}} -->
                    <!-- {{'row' + index}} -->
                    <a-row :key="index">
                      <!-- <div v-if="ifOk(item)" :style="{height: '80px'}"> -->
                      <builderRow
                        v-if="ifOk(item)"
                        :option="item"
                        :row="index"
                        :ref="'row' + index"
                        @ok="changeData"
                        @noClick="noClick"
                        @isClick="activeData"
                        @del="delCol"
                      ></builderRow>
                      <!-- </div> -->
                      <!-- <div v-if="item[0].controlType == 'detail-table' && a == true"> -->
                      <detailTable
                        v-if="
                          item[0].controlType == 'detail-table' && a == true
                        "
                        :fieldsOption="detailTableFieldOption"
                        :option="item"
                        :col="item[0].columns"
                        :row="index"
                        :ref="'row' + index"
                        @ok="changeData"
                        @noClick="noClick"
                        @isClick="activeData"
                        @del="delCol"
                      ></detailTable>
                      <!-- </div> -->
                    </a-row>
                    <!-- {{item[0]}} -->
                  </template>
                </template>
                <template>
                  <a-button
                    type="dashed"
                    icon="plus"
                    class="addbutton"
                    @click="add"
                    >添加一行主数据</a-button
                  >
                  <!-- <a-button type="dashed" icon="plus" class="addbutton" @click="addHidden">添加隐藏域</a-button> -->
                </template>
                <template>
                  <a-row :gutter="24">
                    <hidden
                      :fieldsOption="fieldOption"
                      v-model="hiddenData"
                    ></hidden>
                  </a-row>
                </template>
              </template>
            </div>
            <div class="layout-right">
              <a-form :form="form2">
                <a-form-item
                  label="关联字段"
                  v-if="
                    active.controlType != 'detail-table' && detailClick == false
                  "
                >
                  <a-select
                    v-model="active.field"
                    :options="fieldOption"
                  ></a-select>
                </a-form-item>
                <a-form-item
                  label="关联表"
                  v-if="active.controlType === 'detail-table'"
                >
                  <!-- <ESelect v-model="active.tableName" :options="bindingFormOption" v-if="bindingFormOption.length != 0"></ESelect> -->
                  <a-select
                    v-model="active.tableNameKey"
                    :options="bindingFormOption"
                    v-if="bindingFormOption.length != 0"
                  ></a-select>
                </a-form-item>
                <a-form-item
                  label="明细表列关联字段"
                  v-if="
                    active.controlType != 'detail-table' && detailClick == true
                  "
                >
                  <a-select
                    v-model="active.field"
                    :options="detailTableFieldOption"
                  ></a-select>
                </a-form-item>
                <a-form-item label="控件类型">
                  <a-select
                    v-model="active.controlType"
                    :options="controlsList"
                    @change="controlsChange"
                  ></a-select>
                </a-form-item>
                <a-form-item label="LABEL">
                  <a-input v-model="active.label"></a-input>
                </a-form-item>
                <a-form-item label="默认值">
                  <a-input v-model="active.defaultValue"></a-input>
                </a-form-item>
                <a-form-item
                  v-if="active.validate == '' || active.validate"
                  label="校验"
                >
                  <a-input v-model="active.validate"></a-input>
                </a-form-item>
                <a-form-item
                  v-if="active.options && active.controlType === 'select'"
                  label="option"
                >
                  <optionSet v-model="active.options"></optionSet>
                </a-form-item>
                <a-form-item
                  v-if="active.controlType === 'material-select'"
                  label="lableField"
                >
                  <a-input v-model="active.lableField"></a-input>
                </a-form-item>
                <a-form-item
                  v-if="
                    active.controlType === 'material-select' ||
                      active.controlType === 'supplier-select'
                  "
                  label="keyField"
                >
                  <a-input v-model="active.keyField"></a-input>
                </a-form-item>
                <a-form-item
                  v-if="active.controlType === 'material-select'"
                  label="联动字段"
                >
                  <linkageFields v-model="active.linkageFields"></linkageFields>
                </a-form-item>
                <a-form-item
                  v-if="active.controlType === 'select'"
                  label="filter"
                >
                  <!-- {{active}} -->
                  table:<a-input v-model="active['filter']['table']"></a-input>
                  fieldName:<a-input
                    v-model="active.filter.fieldName"
                  ></a-input>
                  listType:<a-input v-model="active.filter.listType"></a-input>
                </a-form-item>
                <a-form-item
                  v-if="active.controlType === 'detail-table'"
                  label="tableName"
                >
                  <a-input v-model="active.tableName"></a-input>
                </a-form-item>
                <a-form-item label="是否禁用">
                  <EBooleanCheckbox
                    v-model="active.disabled"
                  ></EBooleanCheckbox>
                </a-form-item>
                <a-form-item label="是否只读">
                  <EBooleanCheckbox
                    v-model="active.readonly"
                  ></EBooleanCheckbox>
                </a-form-item>
              </a-form>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane tab="源码内容" key="3">
          {{ option }}
          <!-- ----------
          {{option.formTemplateContent}} -->
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法
import { getOption, getAddData } from "./columnsSet.js";
import {
  getControlsList,
  getChangeControlData
} from "./controls/controlsList.js";
import builderRow from "./controls/builderRow.vue";
import detailTable from "./controls/detailTable.vue";
import hidden from "./controls/hidden.vue";
import linkageFields from "./controls/linkageFields.vue";
import optionSet from "./controls/optionSet.vue";
export default {
  mixins: [analyticalMenuAuth, pageControls, requestControls],
  name: "mobileBuilder",
  data() {
    return {
      tableName: null,
      option: {
        id: "", // 表单ID
        formTemplateCode: "", // 表单定义编码
        formTemplateName: "", // 表单定义名称
        formTemplateContent: {
          formTemplate: this.data,
          hiddenFields: this.hiddenData
        }, // 表单定义内容
        relativeFormTemplateID: "", // 绑定表单
        creator: "123", // 创建者
        createTime: "", // 创建时间
        reviser: "", // 最后修改人
        reviseTime: "" // 最后修改时间
      },
      builderId: "",
      load: true,
      activeRow: null,
      activeIndex: null,
      active: {},
      a: true,
      form: this.$form.createForm(this),
      form2: this.$form.createForm(this),
      labelOption: {
        labelCol: { span: 5 },
        wrapperCol: { span: 12 }
      },
      addData: [], // 新增一条时的默认数据,
      data: [],
      hiddenData: [],
      bindingFormOption: [], //绑定表单的配置
      fieldOption: [], // 关联字段配置
      controlsList: [], // 控件列表
      defaultControlsOption: [], // 控件的默认参数
      detailTableFieldOption: [], // 明细表关联字段设置
      detailClick: false,
      buttons: [
        {
          icon: "icon-save",
          isShow: true,
          loading: false,
          name: "保存",
          tsbName: "tsbSave",
          type: "primary"
        }
      ]
    };
  },
  computed: {
    getData: function() {
      return this.data;
    },
    getHiddenData: function() {
      return this.hiddenData;
    }
    // sum:function(){
    //     return this.Math+ this.English+this.chemistry;
    // },
    // average:function(){
    //     return Math.round(this.sum/3);
    // }
  },
  props: {},
  watch: {
    // pageConfig: function(nDatas) {
    //   this.thisConfig = nDatas;
    // }
    data: function(value) {
      this.option.formTemplateContent.formTemplate = value;
    },
    hiddenData: function(value) {
      this.option.formTemplateContent.hiddenFields = value;
    },
    active: {
      deep: true,
      handler: function(newVal, oldVal) {
        if (newVal == {}) {
          return;
        }
        console.log("newValue", newVal);
        console.log("oldValue", oldVal);
        if (this.activeIndex != null) {
          if (typeof this.activeIndex == "string") {
            if (this.activeIndex.indexOf("detail-") != -1) {
              var index = this.activeIndex.split("detail-")[1];
              this.data[this.activeRow][0]["columns"][index] = newVal;
            } else {
              this.data[this.activeRow][this.activeIndex] = newVal;
            }
          } else {
            this.data[this.activeRow][this.activeIndex] = newVal;
          }
        } else {
          if (this.activeRow == null || this.activeIndex) {
            return;
          } else {
            this.data[this.activeRow][this.activeIndex] = newVal;
          }
        }
      }
    },
    "option.relativeFormTemplateID"(value) {
      this.getFieldOption(value);
      this.getTableName(value);
    },
    "active.tableNameKey"(value) {
      if (this.active.controlType === "detail-table") {
        this.getDetailTableFieldOption(value);
        this.getTableName(value, "detail", this.activeRow, this.activeIndex);
      }
    }
  },
  created() {
    this.builderId = this.$route.params.id;
    this.columns = getOption();
    this.addData = getAddData();
    this.controlsList = getControlsList();
    this.defaultControlsOption = getChangeControlData();
    // this.active = {};
    this.init();
  },
  components: {
    builderRow: builderRow,
    detailTable: detailTable,
    hidden: hidden,
    linkageFields: linkageFields,
    optionSet: optionSet
  },
  mounted() {},
  methods: {
    // 初始化
    init() {
      var _this = this;
      if (this.builderId === "new") {
        this.data = [];
        this.hiddenData = [];
        this.option.formTemplateCode = "code_" + new Date().getTime();
      } else {
        let params = {
          action: "GetFormTemplate",
          formTemplateId: this.builderId
        };
        this.get("System.ashx", params, {
          success: function(res) {
            if (res.success) {
              var data = res.data[0];
              _this.option["id"] = data.ID;
              _this.option["formTemplateCode"] = data.FormTemplateCode;
              _this.option["formTemplateName"] = data.FormTemplateName;
              _this.option["relativeFormTemplateID"] =
                data.RelativeFormTemplateID;
              _this.option["creator"] = data.Creator;
              _this.option["createTime"] = new moment(data.CreateTime).format(
                "YYYY-MM-DD hh:mm:ss"
              );
              _this.option["reviser"] = data.Reviser;
              _this.option["reviseTime"] = new moment(data.ReviseTime).format(
                "YYYY-MM-DD hh:mm:ss"
              );
              _this.tableName = data.TableName;
              try {
                var formTemplateContent = JSON.parse(data.FormTemplateContent);
                _this.data = formTemplateContent.formTemplate;
                _this.hiddenData = formTemplateContent.hiddenFields;
                // _this.data = JSON.parse(res.data[0].FormTemplateContent.formTemplate);
                // _this.hiddenData = JSON.parse(res.data[0].FormTemplateContent.hiddenFields);
              } catch (err) {
                err;
                _this.data = res.data[0].FormTemplateContent;
              }
            }
          },
          error: function() {}
        });
      }
      // 获取绑定表单定义
      let params = {
        action: "GetTemplateNameList",
        randomKey: this.$store.getters.getRandomKey
      };
      this.get("System.ashx", params, {
        success: function(res) {
          if (res.success) {
            _this.bindingFormOption = res.data;
          }
        }
      });
    },
    // 新增一行
    add() {
      const addData = [
        {
          controlType: "input",
          field: "",
          defaultValue: "",
          label: "",
          readonly: false,
          disabled: false,
          validate: ""
        }
      ];
      this.data.push([...addData]);
    },
    // 添加隐藏域
    addHidden() {
      this.$set(this.option.formTemplateContent, "hiddenFields", []);
    },
    // 改变数据
    changeData(data, row) {
      // this.a = false;
      this.data[row] = data;
      // this.a = true;
      console.log(this.data);
    },
    // 点击的数据
    activeData(row, index, type) {
      if (type == "detail") {
        if (index != "" || typeof index == "number") {
          this.detailClick = true;
          this.active = this.copy(this.data[row][0]["columns"][index]);
          this.activeRow = row;
          this.activeIndex = "detail-" + index;
        } else {
          this.detailClick = true;
          this.active = this.copy(this.data[row][0]);
          this.activeRow = row;
          this.activeIndex = 0;
        }
      } else {
        this.detailClick = false;
        this.active = this.copy(this.data[row][index]);
        this.activeRow = row;
        this.activeIndex = index;
      }
    },
    // 取消其他的点击事件
    noClick() {
      for (var i = 0; i < this.data.length; i++) {
        if (this.data[i].length != 0) {
          this.getThisRef("row" + i).noClick();
        }
      }
    },
    // 删除了一条数据后的操作，如清除active
    delCol() {
      this.active = {};
      this.activeRow = null;
      this.activeIndex = null;
    },
    // 保存
    tsbSave() {
      var _this = this;
      // var data = [...this.data];
      // 清除空数组
      // data = this.delNullArray(data);
      var data = this.copy(this.option.formTemplateContent);
      data.formTemplate = this.delNullArray(data.formTemplate);
      var mxbTableName = this.getMxbTableName(data.formTemplate);
      let params = {
        action: "SaveFormTemplate",
        formTemplateCode: this.option.formTemplateCode,
        formTemplateName: this.option.formTemplateName,
        formTemplateContent: JSON.stringify(data),
        relativeFormTemplateID: this.option.relativeFormTemplateID,
        formTemplateId: this.builderId === "new" ? "" : this.builderId,
        randomKey: this.$store.getters.getRandomKey,
        tableName: this.tableName,
        mxbTableName: mxbTableName
      };
      this.post("System.ashx", params, {
        success: function(res) {
          if (res.success) {
            _this.$message.success("保存成功");
          }
        }
      });
    },
    // 情况空数组
    delNullArray(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].length == 0) {
          data.splice(i, 1);
          data = this.delNullArray(data);
          break;
        }
      }
      return data;
    },
    // 获得关联字段数据
    getFieldOption(id) {
      var _this = this;
      let params = {
        action: "GetTemplateColumnsList",
        randomKey: this.$store.getters.getRandomKey,
        templateId: id
      };
      this.get("System.ashx", params, {
        success: function(res) {
          if (res.success) {
            _this.fieldOption = res.data;
          }
        },
        error: function(res) {
          _this.$message.error(res.message);
        }
      });
    },
    getDetailTableFieldOption(id) {
      var _this = this;
      let params = {
        action: "GetTemplateColumnsList",
        randomKey: this.$store.getters.getRandomKey,
        templateId: id
      };
      this.get("System.ashx", params, {
        success: function(res) {
          if (res.success) {
            _this.detailTableFieldOption = res.data;
          }
        },
        error: function(res) {
          _this.$message.error(res.message);
        }
      });
    },
    // 获得主表名
    getTableName(id, type, row, index) {
      var _this = this;
      let params = {
        action: "GetTableName",
        randomKey: this.$store.getters.getRandomKey,
        templateId: id
      };
      this.get("System.ashx", params, {
        success: function(res) {
          if (res.success) {
            if (type === "detail") {
              _this.data[row][index]["tableName"] = res.data;
            } else {
              _this.tableName = res.data;
            }
          }
        }
      });
    },
    // 改变控件类型
    controlsChange(value) {
      if (this.activeRow != null && this.activeIndex != null) {
        if (this.defaultControlsOption[value]) {
          var first;
          // 判断是否为明细表数据
          if (this.activeIndex != null) {
            if (typeof this.activeIndex == "string") {
              if (this.activeIndex.indexOf("detail-") != -1) {
                var index = this.activeIndex.split("detail-")[1];
                first = this.copy(
                  this.data[this.activeRow][0]["columns"][index]
                );
              } else {
                first = this.copy(this.data[this.activeRow][this.activeIndex]);
              }
            } else {
              first = this.copy(this.data[this.activeRow][this.activeIndex]);
            }
          } else {
            if (this.activeRow == null || this.activeIndex) {
              return;
            } else {
              first = this.copy(this.data[this.activeRow][this.activeIndex]);
            }
          }
          var last = this.copy(this.defaultControlsOption[value]);
          var add = this.objectMerge(first, last);
          //this.data[this.activeRow][this.activeIndex] = add;
          // this.data[this.activeRow][this.activeIndex] = {};

          if (this.activeIndex != null) {
            if (typeof this.activeIndex == "string") {
              if (this.activeIndex.indexOf("detail-") != -1) {
                var index1 = this.activeIndex.split("detail-")[1];
                Object.assign(
                  this.data[this.activeRow][0]["columns"][index1],
                  add
                );
              } else {
                Object.assign(this.data[this.activeRow][this.activeIndex], add);
              }
            } else {
              Object.assign(this.data[this.activeRow][this.activeIndex], add);
            }
          } else {
            if (this.activeRow == null || this.activeIndex) {
              return;
            } else {
              Object.assign(this.data[this.activeRow][this.activeIndex], add);
            }
          }
          let activeRef = this.getThisRef("row" + this.activeRow);
          if (activeRef) {
            if (typeof this.activeIndex == "string") {
              if (this.activeIndex.indexOf("detail-") != -1) {
                activeRef.clickMe(this.activeIndex.split("detail-")[1]);
              } else {
                activeRef.clickMe(this.activeIndex);
              }
            } else {
              activeRef.clickMe(this.activeIndex);
            }
          }
          // this.active = this.data[this.activeRow][this.activeIndex];
          // for (var key in add) {
          //   this.$set(this.data[this.activeRow][this.activeIndex], key, add.key);
          // }
          // this.data[this.activeRow][this.activeIndex] = add;
        }
      }
    },
    // 2个对象合并方法,如果last有first的元素，则取first元素，不然去除first元素，得到的结构为last结构,controlType 则跳过
    objectMerge(first, last) {
      for (var key in last) {
        if (first[key] != null && key != "controlType") {
          last[key] = first[key];
        }
      }
      return last;
    },
    // 深度拷贝
    copy(obj) {
      if (obj == null) {
        return obj;
      }
      try {
        return JSON.parse(JSON.stringify(obj));
      } catch (err) {
        console.log("copy出错了", obj, err);
      }
    },
    // 检查是否是明细表
    ifOk(item) {
      if (item.length != null) {
        for (var i = 0; i < item.length; i++) {
          if (item[i]["controlType"]) {
            if (item[i]["controlType"] === "detail-table") {
              return false;
            }
          }
        }
        return true;
      } else {
        if (item["controlType"] === "detail-table") {
          return false;
        }
        return true;
      }
    },
    // 获取明细表tablename
    getMxbTableName(data) {
      var name = "";
      for (var i = 0; i < data.length; i++) {
        for (var key in data[i]) {
          if (data[i][key].controlType === "detail-table") {
            name = name + data[i][key].tableName + ",";
          }
        }
      }
      if (name[name.length - 1] === ",") {
        name = name.substr(0, name.length - 1);
      }
      return name;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/base/mobile-builder.less";
</style>
