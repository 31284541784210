<!-- 对应E1客户端，查询视图提供查询按钮弹出窗，用于查询条件编辑 -->
<template>
  <a-modal
    :title="title"
    v-model="visible"
    :bodyStyle="{ width: 'auto', maxHeight: maxHeight, maxWidth: maxWidth }"
    :width="allWidth"
  >
    <slot name="title">{{ title }}</slot>
    <div>
      <a-row type="flex" justify="space-between" style="padding: 10px;">
        <a-col :span="21">
          字段条件：
          <a-select
            style="min-width: 100px;max-width: 200px;"
            :size="controlSize"
            v-model="field"
            placeholder="请选择字段"
          >
            <a-select-option v-for="col in thisSelectCols" :key="col.value">
              {{ col.label }}
            </a-select-option>
          </a-select>
          <a-select
            v-model="comparison"
            :size="controlSize"
            defaultValue="[等于]"
            style="width: 100px;margin-left:10px;"
          >
            <a-select-option v-for="col in comparisons" :key="col.value">
              {{ col.label }}
            </a-select-option>
          </a-select>
          <a-input
            placeholder="请输入条件值"
            :size="controlSize"
            v-model="fieldValue"
            style="width: 150px;margin-left:10px;"
          />
        </a-col>
        <a-col :span="3" style="text-align: right;"
          ><a-button
            key="button1"
            type="primary"
            ghost
            @click="onFieldCondition"
            >添加</a-button
          ></a-col
        >
      </a-row>
      <a-row type="flex" justify="space-between" style="padding: 10px;">
        <a-col :span="21">
          逻辑条件：
          <a-select
            v-model="logical"
            :size="controlSize"
            style="width: 80px"
            placeholder="请选择"
          >
            <a-select-option v-for="col in logicals" :key="col.value">
              {{ col.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="3" style="text-align: right;"
          ><a-button
            key="button2"
            type="primary"
            ghost
            @click="onLogicalCondition"
            >添加</a-button
          ></a-col
        >
      </a-row>
    </div>
    <div style="padding: 10px;">
      条件语句内容：<span style="display: block;color: red;"
        >注：字段文本的比较，请使用“是”、“不是”和“包含”，布尔型数据请输入1或0表示true和false</span
      >
      <a-textarea
        :size="controlSize"
        :rows="4"
        v-model="searchCondition"
      ></a-textarea>
    </div>
    <template slot="footer">
      <template v-for="button in thisButtons">
        <a-button
          :key="button.key"
          :disabled="buttonDisabled"
          class="headButton"
          type="primary"
          :loading="button.loading"
          :data-config="button"
          @click="onButtonClick(button)"
          >{{ button.title }}</a-button
        ><!-- :icon="button.icon" -->
        <div :key="button.title" class="divider-block-min"></div>
      </template>
    </template>
  </a-modal>
</template>

<script>
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法

export default {
  mixins: [pageControls],
  name: "EBuildConditionModal",
  data() {
    return {
      controlSize: this.$config.defaultControlSize,
      // 表单
      showForm: false,
      thisFormName: null, // 对应的表单名称
      thisSelectCols: [],
      thisForm: null,
      buttonDisabled: false,
      field: null,
      comparison: "[等于]",
      fieldValue: "",
      searchCondition: "",
      trueSearchCondition: "",
      logical: null,
      maxHeight: "1000px",
      maxWidth: "1000px",
      comparisons: [
        {
          label: "等于",
          value: "[等于]"
        },
        {
          label: "大于",
          value: "[大于]"
        },
        {
          label: "小于",
          value: "[小于]"
        },
        {
          label: "大于等于",
          value: "[大于等于]"
        },
        {
          label: "小于等于",
          value: "[小于等于]"
        },
        {
          label: "不等于",
          value: "[不等于]"
        },
        {
          label: "是",
          value: "[是]"
        },
        {
          label: "不是",
          value: "[不是]"
        },
        {
          label: "包含",
          value: "[包含]"
        }
      ],
      logicals: [
        {
          label: "或者",
          value: "[或者]"
        },
        {
          label: "而且",
          value: "[而且]"
        }
      ],
      tjArray: [],
      tjShowArray: []
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    buttons: {
      // 操作按钮
      type: Array,
      default: function() {
        return [
          {
            title: "确定",
            icon: "check",
            key: "ok",
            loading: false,
            system: true,
            returnData: true
          },
          {
            title: "回退",
            icon: "left-square",
            key: "delete",
            system: true,
            loading: false
          },
          {
            title: "取消",
            icon: "close",
            key: "cancel",
            system: true,
            loading: false
          }
        ];
      }
    },
    width: {
      type: [Number, String],
      default: function() {
        return "600";
      }
    },
    selectCols: {
      // 表格项
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    visible: {
      get: function() {
        return this.value;
      },
      set: function(v) {
        this.$emit("input", v);
      }
    },
    thisButtons: {
      get: function() {
        return this.buttons;
      },
      set: function(nButton) {
        this.$emit("onButtonState", nButton);
      }
    },
    allWidth: function() {
      if (this.$util.isType(this.width) === "String") {
        if (this.width.indexOf("px") > 0) {
          let widthNumber = this.width.substring(0, this.width.indexOf("px"));
          return parseInt(widthNumber) + 46;
        } else {
          return parseInt(this.width) + 46;
        }
      } else if (this.$util.isType(this.width) === "val") {
        return this.width + 46;
      } else {
        return 520;
      }
    }
  },
  watch: {
    buttons: function(n) {
      //console.log("按钮改变了",n);
      this.thisButtons = n;
    },
    selectCols: function(newTable) {
      console.log("EBuildConditionModal初始化下列数据改变了", newTable);
      this.thisSelectCols = newTable;
    }
  },
  created() {
    try {
      let windowSize = this.$util.findDimensions();
      if (windowSize) {
        this.maxHeight = windowSize.height - 53 + "px"; // 减去底部按钮区域高度，获得内容区域最大高度
        this.maxWidth = windowSize.width + "px";
      }
    } catch (e) {
      console.error(e);
    }
  },
  mounted() {
    this.thisSelectCols = this.selectCols;
  },
  errorCaptured(err, vm, info) {
    console.error("弹出窗口表单异常:", err, vm, info);
    return false;
  },
  methods: {
    /**
     * 添加字段条件
     */
    onFieldCondition() {
      if (
        this.field &&
        this.comparison &&
        this.fieldValue &&
        this.$util.trimStr(this.fieldValue) !== ""
      ) {
        let that = this;
        let tempField = this.thisSelectCols.find(function(obj) {
          return obj.value === that.field;
        });
        let temp =
          tempField.value +
          this.comparison +
          "'" +
          this.$util.trimStr(this.fieldValue) +
          "'";
        let showTemp =
          "[" +
          tempField.label +
          "]" +
          this.comparison +
          this.$util.trimStr(this.fieldValue);
        if (this.tjArray.length > 0) {
          // 判断之前是否已经插入了本条件，而且中间没有逻辑语句
          let lastIndex = this.tjArray.lastIndexOf(temp);
          if (lastIndex == this.tjArray.length - 1) {
            this.tipWarning("两个字段条件之间请先插入逻辑条件");
            return;
          } else {
            let last = this.tjArray[this.tjArray.length - 1];
            let i = this.logicals.findIndex(function(obj) {
              return obj.value === last;
            });
            if (i == -1) {
              this.tipWarning("两个字段条件之间请先插入逻辑条件");
              return;
            }
          }
        }
        this.tjArray.push(temp);
        this.tjShowArray.push(showTemp);
        this.searchCondition = this.searchCondition + showTemp; //[表单名称][大于]ss
      }
    },
    /**
     * 添加逻辑条件
     */
    onLogicalCondition() {
      if (this.logical && this.logical !== "" && this.tjArray.length > 0) {
        let lastIndex = this.tjArray.lastIndexOf(this.logical);
        if (lastIndex == this.tjArray.length - 1) {
          this.tipWarning("之前条件语句已经包含逻辑条件");
          return;
        }
        this.tjArray.push(this.logical);
        this.tjShowArray.push(this.logical);
        this.searchCondition = this.searchCondition + this.logical;
      } else {
        this.tipWarning("条件语句开头不能是逻辑条件");
      }
    },
    /**
     * 删除上一步添加的条件
     */
    doBackOne() {
      if (this.tjArray.length > 0) {
        this.tjArray.pop();
        this.tjShowArray.pop();
        this.searchCondition = this.tjShowArray.join("");
      }
    },
    /**
     * 按钮事件 提交
     * @param button
     */
    onButtonClick(button) {
      if (button.system && button.key === "cancel") {
        this.visible = false;
        this.searchCondition = "";
        this.tjShowArray = [];
        this.tjArray = [];
        this.$emit("changeCondition", {
          data: { search: this.tjArray.join("") }
        });
        return;
      }
      if (button.system && button.key === "delete") {
        this.doBackOne();
        this.$emit("changeCondition", {
          data: { search: this.tjArray.join("") }
        });
        return;
      }
      if (button.returnData) {
        /*if (this.searchCondition === "") {
          // 如果没有条件，则直接跳过
          return;
        }*/
        let that = this;
        this.buttonDisabled = true;
        //console.log("点击了提交数据按钮");
        button.loading = true;
        let promise1 = new Promise(resolve => {
          that.$emit(button.key + "Clicked", {
            resolve: resolve,
            key: button.key,
            data: { search: this.tjArray.join("") }
          });
          that.$emit("buttonClicked", {
            resolve: resolve,
            key: button.key,
            data: { search: this.tjArray.join("") }
          });
        }).catch(() => console.error("弹出框按钮异常"));
        // 异步响应上方的Promise类提供的resolve的，执行结果
        promise1.then(function() {
          //console.log("确认按钮操作完成",value);
          button.loading = false;
          that.visible = false;
          that.buttonDisabled = false;
        });
      } else {
        this.$emit(button.key + "Clicked");
        this.$emit("buttonClicked", {
          key: button.key
        });
      }
    }
  }
};
</script>
