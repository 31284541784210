<!-- 左侧树形、右侧列表视图-带分页 -->
<template>
  <div>
    <div v-if="showToolbar" class="form-headButton">
      <!-- 授权操作按钮 -->
      <e-toolbar
        ref="eToolbar"
        :buttons="buttons"
        :operationDisabled="operationDisabled"
        :size="$config.defaultControlSize"
        @click="onButtonClick"
      ></e-toolbar>
    </div>
    <a-layout class="treeView-layout" :class="expandTreeViewClass">
      <!-- 上部 授权操作按钮 -->
      <a-layout-header v-if="!isNewTheme && showToolbar" class="view-header">
        <!-- 表title -->
        <e-head-title
          :title="title"
          :size="$config.defaultControlSize"
        ></e-head-title>
      </a-layout-header>
      <a-layout class="view-content">
        <!-- 左侧树focus下渲染出占位空div -->

        <!-- 左侧树 -->
        <a-layout-sider
          class="view-sider tree-blur"
          :class="expandViewSilderClass"
          @blur.native="e => drawer(e, 'blur')"
          @focus.native="e => drawer(e, 'focus')"
          tabIndex="1"
        >
          <div v-if="isNewTheme" class="view-sider-leftHead">
            <!--表title -->
            <e-head-title
              :title="title"
              :size="$config.defaultControlSize"
            ></e-head-title>
          </div>
          <div :class="isNewTheme ? expandViewSilderTreeClass : ''">
            <a-spin
              :spinning="showTreeSpin"
              tip="树加载中..."
              size="large"
              class="tree-spin"
            >
            </a-spin>

            <a-tree
              v-if="!showTreeSpin"
              showLine
              :defaultExpandAll="treeDefaultExpandAll"
              :selectedKeys="selectedNodeKeys"
              :treeData="treeData"
              :disabled="treeDisabled"
              @select="onTreeSelect"
            >
              <!--:loadData="onLoadTreeData" 异步加载数据 multiple -->
            </a-tree>
          </div>
        </a-layout-sider>
        <!-- 右侧列表 -->
        <a-layout-content class="view-main-content">
          <div v-if="isNewTheme" class="view-content-leftHead">
            <!--表title -->
            <e-head-title
              :title="tableTitle"
              :size="$config.defaultControlSize"
            ></e-head-title>
          </div>
          <div class="view-layout-content">
            <!-- <div class="mask" v-if="maskShow"></div> -->
            <!-- 筛选控件 -->
            <div class="view-table-screenBox">
              <e-table-content-filter
                ref="screenViewTable"
                @screen="
                  data => {
                    tableDatas = data;
                  }
                "
                :proxy="true"
                :columns="thisColumns"
                :dataSource="tableDatas"
                :disabled="tableSpin"
                @proxy="searchByKey"
                :quitScreenMode="
                  this.searchNodeKeys == '' || this.searchNodeKeys == null
                    ? false
                    : true
                "
              ></e-table-content-filter>
            </div>
            <div class="view-table" :class="expandViewTableClass">
              <vxe-grid
                ref="tableRef"
                :stripe="$config.table_stripe"
                show-overflow
                show-header-overflow
                :scroll-x="{ gt: -1 }"
                :scroll-y="{ gt: 100 }"
                border
                resizable
                highlight-hover-row
                highlight-current-row
                :checkbox-config="{
                  checkAll: false,
                  highlight: true
                }"
                :empty-render="vxeTableEmptyRender"
                :size="tableSize_MiXin"
                :tooltip-config="vxeTabletooltipConfig"
                header-row-class-name="e-table-header_style"
                header-cell-class-name="e-table-header-cell_style"
                row-class-name="e-table-row_style"
                maxHeight="100%"
                height="auto"
                :loading="tableSpin"
                :data="tableDatas"
                @checkbox-all="onSelectAll"
                @cell-click="onTableSelectChange"
                @sort-change="onSortChange"
              >
                <template v-for="col in thisColumns">
                  <vxe-table-column
                    v-if="col.scopedSlots"
                    :key="col.field"
                    v-bind="col"
                  >
                    <!--如果定义了字段格式，则按照格式进行渲染-->
                    <template slot-scope="slotProps">
                      <e-table-colum
                        v-if="col.scopedSlots && col.scopedSlots.clickMethod"
                        :column="col"
                        :slotScopeData="slotProps.row"
                        @onClick="columClick"
                      ></e-table-colum>
                      <e-table-colum
                        v-else
                        :column="col"
                        :slotScopeData="slotProps.row"
                      ></e-table-colum>
                    </template>
                  </vxe-table-column>
                  <vxe-table-column
                    v-else
                    :key="col.field"
                    v-bind="col"
                  ></vxe-table-column>
                </template>
              </vxe-grid>
              <vxe-pager
                ref="vxePagerRef"
                class="e-table-page"
                background
                :size="tableSize_MiXin"
                :loading="tableSpin"
                :current-page="pagination.current"
                :page-size="pagination.pageSize"
                :page-sizes="pagination.pageSizes"
                :total="pagination.total"
                :layouts="pagination.layouts"
                @page-change="tableChange"
              >
              </vxe-pager>
            </div>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <systemPrintSet ref="systemPrintSet"></systemPrintSet>
  </div>
</template>

<script>
import ETableColum from "@/components/ETableColum";
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法
import { e1ExpendColum } from "@/views/listView/mixins/e1ExpendColum.js"; // 提供了扩展列表功能的公共方法

export default {
  mixins: [analyticalMenuAuth, pageControls, requestControls, e1ExpendColum],
  name: "TreeListView",
  components: {
    ETableColum
  },
  data () {
    return {
      cacheData: null,
      tableDatas: [],
      thisColumns: [],
      rowKey: "ID",
      selectedRowKeys: [], // 选中的表数据行
      treeData: [], // 左侧树展示的数据
      treeCacheData: [], // 左侧所有数据
      tableMaxHeight: "460px", // 右侧表格最大高度
      treeDefaultExpandAll: this.$config.TREE_DEFAULT_EXPANDALL,
      selectedNodeKeys: [], // 选中的树节点，多选时需要
      searchNodeKeys: [], // 需要查询的节点id
      selectIsLeaf: false, // 目前选择的树节点是否是叶子节点
      tempIdFiled: null, // 对应模板id在树返回数据中的字段名 ？？？？？
      treeTitle: "", // 选中的节点title
      editSelectedName: "", // 需要编辑的数据名

      dhbm: null, // 当前开页面关联权限参数的id
      tempId: null, // 需要新建和编辑的模板ID
      formTempIdFieldName: null,
      thisConfig: null,
      treeConfig: null,
      tableConfig: null,

      buttons: [], // 当前用户可用的操作按钮
      operationDisabled: false, // 操作按钮是否可用
      treeDisabled: false, // 树是否禁用
      showTreeSpin: false, // 树加载状态
      tableSpin: false, // 表数据加载状态,
      searchTj: {},
      maskShow: false // 右树状图遮罩层是否显示
    };
  },
  props: {
    pageConfig: {
      //包含视图结构、视图数据来源、操作权限配置
      type: Object,
      default: function () {
        return null;
      }
    },
    title: {
      type: String,
      default: ""
    }
  },
  watch: {
    pageConfig: function (nDatas) {
      this.thisConfig = nDatas;
    },
    tableSpin: function (v) {
      if (!v) {
        // 数据加载完成后，将滚动条拉到顶部
        this.$refs.tableRef.scrollTo(0, 0);
      }
    }
  },
  computed: {
    tableMaxWidth: function () {
      if (this.thisColumns.length > 0) {
        let tempWidth = 0;
        for (let i = 0; i < this.thisColumns.length; i++) {
          tempWidth += this.thisColumns[i].width;
        }
        return tempWidth;
      }
      return 0;
    },
    // 根据当前设置的全局尺寸，改变界面样式
    expandTreeViewClass: function () {
      return this.getClassNameBySize("treeView-layout", this.showToolbar);
    },
    expandViewSilderClass: function () {
      return this.getClassNameBySize("view-sider", this.showToolbar);
    },
    expandViewTableClass: function () {
      return this.getClassNameBySize("view-table", this.showToolbar);
    },
    expandViewSilderTreeClass: function () {
      let classes = [];
      classes.push("view-sider-tree");
      classes.push(this.getClassNameBySize("view-sider-tree", true));
      return classes;
    },
    showToolbar: function () {
      return this.checkToobleIsShow(this.buttons);
    },
    tableTitle: function () {
      return this.treeTitle && this.treeTitle !== "" ? this.treeTitle : "列表";
    }
  },
  created () {
    let windowSize = this.$util.findDimensions();
    if (windowSize) {
      this.tableMaxHeight = windowSize.height - 3 * 105 - 84 + "px";
    }
    this.pagination.showQuickJumper = true; // 显示快捷调整输入框
    this.thisConfig = this.pageConfig;
    this.treeConfig = this.thisConfig.viewConfig.treeConfig;
    this.tableConfig = this.thisConfig.viewConfig.gridConfig;
    this.dhbm = this.pageConfig.DHBM;
    if (this.treeConfig) {
      // 取得树配置
      if (this.treeConfig.treeRootText) {
        // 设置了根节点名称
        this.treeData.push({
          title: this.treeConfig.treeRootText,
          key: "ROOT",
          children: []
        });
        if (this.treeConfig.needFields)
          // 是否需要从树中获取新建对应数据的模板id
          this.tempIdFiled = this.treeConfig.needFields[0];
      }
      if (this.tableConfig && this.tableConfig.gridCols) {
        // 表格配置
        this.thisColumns = this.analyticalColsArray(this.tableConfig.gridCols); // 表头配置
        this.tempId = this.tableConfig.templateId;
        this.formTempIdFieldName = this.thisConfig.viewConfig.formTempId;
      } else {
        this.alertError("表格配置错误");
      }
    } else {
      this.alertError("树形列表配置错误");
    }
  },
  mounted () {
    if (this.treeConfig) {
      this.showTreeSpin = true;
      this.operationDisabled = true;
      this.getTreeData();
    } else {
      this.alertError("树形列表配置错误");
    }
    //分析出用户可做操作按钮
    this.buttons = this.getUserJurisdictionButtons(this.thisConfig);
    if (!this.checkToobleIsShow(this.buttons)) {
      this.hideToolBar();
    }
    console.log("this.buttons", this.buttons);
  },
  methods: {
    /**
     * 加载树的一级数据
     */
    getTreeData () {
      let that = this;
      let mx = this.treeConfig.treeFields;
      if (mx) {
        if (this.treeConfig.needFields)
          mx = mx.concat(this.treeConfig.needFields);
      } else {
        mx = this.treeConfig.needFields;
      }
      let params = {
        action: "GetTreeViewList",
        tb_name: this.treeConfig.treeTableName, //主表名
        fieldNames: JSON.stringify(mx), //明细字段名
        bm: this.treeConfig.treeViewBM,
        m_id: "", // 其他过滤条件
        t_id: this.treeConfig.treeTemplateId,
        randomKey: this.$store.getters.getRandomKey
      };
      this.get("Form.ashx", params, {
        success: function(req) {
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? req.data:req;
          let mData = reqData.data;
          for (let i = 0; i < mData.length; i++) {
            that.treeData[0].children.push(mData[i]);
          }
          that.showTreeSpin = false;
          that.operationDisabled = false;
        },
        error: function (err) {
          console.log(err);
          that.showTreeSpin = false;
          that.operationDisabled = false;
          that.alertError("请求失败，获取树数据失败");
        }
      });
    },
    /**
     * 选中树事件响应
     * @param selectedKeys
     * @param info
     */
    onTreeSelect (selectedKeys, info) {
      console.log("selected", info);
      if (info.selected) {
        let selectedIds = [];
        this.selectedRowKeys = []; // 选择的行先清空
        this.selectedNodeKeys = [];
        this.selectedNodeKeys.push(selectedKeys[0]);
        if (info.node.isLeaf) {
          // 当前选中的节点是否是叶子节点
          this.selectIsLeaf = true;
          if (this.tempIdFiled)
            this.tempId = info.node.dataRef[this.tempIdFiled];
          this.treeTitle = info.node.dataRef.title;
        } else {
          this.selectIsLeaf = false;
          this.treeTitle = "";
        }
        // 设置当前选中的和其所有的子节点都为选中
        // this.$util.getObjParamValueArrayByParam(info.node.dataRef,"key" ,this.selectedNodeKeys);
        // 获取当前选中的和其所有的子节点的ID用于查询
        this.$util.getObjParamValueArrayByParam(
          info.node.dataRef,
          "Id",
          selectedIds
        );
        this.searchNodeKeys = selectedIds;
        // console.log('selectedNodeIDs', selectedIds);
        this.pagination.current = 1;
        this.searchTj.searchCols = null;
        this.searchTj.keyword = null;
        this.getTableData(selectedIds);
      }
    },
    /**
     * 根据条件进行查询
     */
    searchByKey (value, filed) {
      this.searchTj.searchCols = filed;
      this.searchTj.keyword = value;
      this.pagination.current = 1;
      this.getTableData(this.searchNodeKeys);
    },
    /**
     * 加载表格的数据
     */
    getTableData (nodeIDs, resolve, getAll, callBack) {
      if (this.tableSpin) {
        return;
      }
      this.tableSpin = true;
      this.operationDisabled = true;
      this.treeDisabled = true;
      let that = this;
      // 查询所有数据下要把查询条件设为空
      let mx =
        nodeIDs != null && nodeIDs != ""
          ? this.tableConfig.treeGridLinkField +
          " IN (" +
          nodeIDs.toString() +
          ")"
          : "";
      let params = {
        action: "GetTableList",
        tb_name: this.tableConfig.tableName, //主表名
        search: mx, // 查询条件
        bm: this.tableConfig.gridViewBM,
        randomKey: this.$store.getters.getRandomKey,
        searchCols: this.searchTj.searchCols,
        keyword: this.searchTj.keyword,
        orderBy: this.searchTj.orderBy
      };
      let pageSize = this.pagination.pageSize;
      let pageNumber = this.pagination.current;
      if (this.pagination.pageSize < Infinity) {
        params.pageSize = pageSize;
        params.pageNumber = pageNumber;
      }
      if (getAll) {
        if (params.pageNumber) {
          delete params.pageNumber;
        }
        if (params.pageSize) {
          delete params.pageSize;
        }
      }
      this.post("Form.ashx", params, {
        success: function(req) {
          //console.log(req);
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? req.data:req;
          if (getAll) {
            that.tableSpin = false;
            that.operationDisabled = false;
            that.treeDisabled = false;
            if (resolve) resolve();
            callBack(reqData.data);
            return;
          }
          that.tableDatas = reqData.data;
          that.pagination.total = reqData.TotalCount
            ? reqData.TotalCount
            : reqData.data
            ? reqData.data.length
            : 0;
          that.$refs.screenViewTable.isNewData(reqData.data);
          that.tableSpin = false;
          that.operationDisabled = false;
          that.treeDisabled = false;
          that.selectedRowKeys = []; // 清空选中项
          if (resolve) resolve();
        },
        error: function (err) {
          if (getAll) {
            that.tableSpin = false;
            that.operationDisabled = false;
            that.treeDisabled = false;
            if (resolve) resolve();
            callBack(false);
            return;
          }
          console.log(err);
          that.pagination.total = 0;
          that.pagination.current = 1;
          that.tableSpin = false;
          that.operationDisabled = false;
          that.treeDisabled = false;
          that.selectedRowKeys = []; // 清空选中项
          if (resolve) resolve();
          that.alertError("请求失败，获取表数据失败");
        }
      });
    },
    /**
     *  打开新建表单数据页面
     */
    tsbNew () {
      if (this.selectIsLeaf) {
        if (this.tempId) {
          //this.alert("已选择模板ID:"+this.tempId);
          let query = {
            dhbm: this.dhbm,
            templetId: this.tempId
          };
          if (this.tableConfig.treeGridLinkField) {
            query[
              this.tableConfig.treeGridLinkField
            ] = this.selectedNodeKeys[0];
            query.defaultFiled = this.tableConfig.treeGridLinkField;
            query[
              this.tableConfig.treeGridLinkField + "_Name"
            ] = this.treeTitle;
          }
          this.openTab({
            title: "新建-" + this.title + "-" + this.treeTitle,
            path: "/NewForm",
            query: query
          });
        } else {
          this.alertError("不存在的模板ID");
        }
      } else {
        this.alertWarning("请选择最底层的分类进行添加");
      }
    },
    /**
     *  打开编辑表单数据页面
     */
    tsbEdit () {
      if (this.selectedRowKeys.length == 1) {
        let that = this;
        let selectedId = this.selectedRowKeys[0];
        let selectedData = this.tableDatas.find(function (data) {
          return data[that.rowKey] === selectedId;
        });
        let temp = this.tempId;
        if (this.formTempIdFieldName) {
          // 如果设置了使用表格数据自带的TempeID
          temp = selectedData[this.formTempIdFieldName];
        }
        let mc = this.getBDObjectMcInfo(selectedData, this.thisColumns);
        let query = {
          dhbm: this.dhbm,
          templetId: temp,
          dataId: selectedId
        };
        if (this.tableConfig.treeGridLinkField) {
          query[this.tableConfig.treeGridLinkField] =
            selectedData[this.tableConfig.treeGridLinkField];
          query.defaultFiled = this.tableConfig.treeGridLinkField;
          query[this.tableConfig.treeGridLinkField + "_Name"] = this.treeTitle;
        }
        this.openTab({
          title: "编辑-" + this.title + "-" + mc,
          path: "/NewForm",
          query: query
        });
      } else {
        this.alertWarning("请选择一条需要编辑的记录");
      }
    },
    /**
     *  查看表单数据
     *  @param button
     */
    tsbView (button) {
      if (this.selectedRowKeys.length == 1) {
        let that = this;
        let selectedId = this.selectedRowKeys[0];
        let selectedData = this.tableDatas.find(function (data) {
          return data[that.rowKey] === selectedId;
        });
        let temp = this.tempId;
        if (this.formTempIdFieldName) {
          // 如果设置了使用表格数据自带的TempeID
          temp = selectedData[this.formTempIdFieldName];
        }
        let mc = this.getBDObjectMcInfo(selectedData, this.thisColumns);
        this.openTab({
          title: "查看" + (mc ? "-" + mc : selectedId),
          path: "/DetailForm",
          query: {
            dhbm: this.dhbm,
            templetId: temp, // 表单模板ID
            detailFiledName: this.thisColumns[1].key,
            dataId: selectedId, // 表单数据id
            bm: button.param.bm // 表单对应BM表
          }
        });
      } else {
        this.alertWarning("请选择一条需要查看的记录");
      }
    },
    /**
     * 点击“删除”按钮删除选中的
     */
    tsbDelete (button, other) {
      if (this.selectedRowKeys.length == 1) {
        if (other && other.prioriCondition) {
          // 满足前置条件还需要确认是否操作
          this.$refs.eToolbar.showConfirm(button.tsbName);
        } else {
          this.doDelete(button);
        }
      } else {
        this.alertWarning("请选择一条需要删除的记录");
      }
    },
    /**
     * 执行删除操作
     * @param button
     * @param resolve
     */
    doDelete (button, resolve) {
      let that = this;
      let selectedId = this.selectedRowKeys[0];
      let selectedData = this.tableDatas.find(function (data) {
        return data[that.rowKey] === selectedId;
      });
      let temp = this.tempId;
      if (this.formTempIdFieldName) {
        // 如果设置了使用表格数据自带的TempeID
        temp = selectedData[this.formTempIdFieldName];
      }
      let params = {
        action: "DeleteData",
        tb_name: that.tableConfig.tableName, //主表名
        bm: that.tableConfig.gridViewBM,
        t_id: temp, // 模板id  从DHCS中获取
        d_id: selectedId, // 插入之后返回的主键id
        randomKey: that.$store.getters.getRandomKey
      };
      that.startProcessing(button);
      this.tableSpin = true;
      this.treeDisabled = true;
      that.post("Form.ashx", params, {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8"
        },
        success: function () {
          //console.log("请求结果", req);
          // 把对应删除的数据从数组中移除
          that.finishedProcessing(button);
          /*const newData = that.tableDatas.filter(
            data => data.ID !== selectedId
          );
          that.tableDatas = newData;
          that.$refs.screenViewTable.isNewData(newData);*/
          that.tableSpin = false;
          that.getTableData(that.searchNodeKeys, resolve);
          if (resolve) resolve();
          that.alertSuccess("删除成功");
        },
        error: function (err) {
          that.finishedProcessing(button);
          that.tableSpin = false;
          that.treeDisabled = false;
          if (resolve) resolve();
          that.alertError(err.message);
        }
      });
    },

    tsbExport () {
      this.getTableData(this.searchNodeKeys, null, true, res => {
        if (!res) {
          this.$message.error("获取导出的数据失败");
          return;
        }
        res &&
          this.thisColumns &&
          this.$util.excelExport(res, this.thisColumns, this.title);
      });
    },
    /**
     * 响应表格行的选中情况变化
     * @param currentRow
     */
    onTableSelectChange (currentRow) {
      //console.log("onCellClick",currentRow);
      if (currentRow) {
        let that = this;
        let index = this.selectedRowKeys.findIndex(function (d) {
          return d === currentRow.row[that.rowKey];
        });
        if (index >= 0) {
          // 已经选中
          this.$refs.tableRef.setCheckboxRow(currentRow.row, false);
          this.selectedRowKeys.splice(index, 1);
        } else {
          this.$refs.tableRef.setCheckboxRow(currentRow.row, true);
          this.selectedRowKeys.push(currentRow.row[that.rowKey]);
        }
      }
      //console.log("selectedRowKeys changed: ", this.selectedRowKeys);
    },
    /**
     * 响应列表的全选事件
     */
    onSelectAll (event) {
      let that = this;
      if (!event.checked) {
        this.selectedRowKeys = [];
        this.$refs.tableRef.setAllCheckboxRow(false);
      } else {
        this.selectedRowKeys = this.tableDatas.map(function (data) {
          return data[that.rowKey];
        });
        this.$refs.tableRef.setAllCheckboxRow(true);
      }
    },
    /**
     * 响应表格分页事件
     * @param pagination
     */
    tableChange (pagination) {
      let that = this;
      this.onTableChangeMiXin(pagination, function () {
        that.getTableData(that.searchNodeKeys);
      });
    },
    /**
     * 响应表格排序事件
     * @param event
     */
    onSortChange (event) {
      console.log("触发排序事件", event);
      if (event && event.field && event.order) {
        //event.field; 参与排序的字段;event.order;排序方式 "desc"
        let orderBy = event.field + " " + event.order;
        this.searchTj.orderBy = orderBy;
        this.selectedRowKeys = [];
        this.getTableData(this.searchNodeKeys);
      } else {
        // 取消排序
        delete this.searchTj.orderBy;
        this.selectedRowKeys = [];
        this.getTableData(this.searchNodeKeys);
      }
    },
    /**
     * 异步加载树的数据, 未使用
     * @param treeNode
     */
    onLoadTreeData (treeNode) {
      console.log("异步再加载", treeNode);
      let that = this;
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          // 已有子菜单
          resolve();
          return;
        }
        //获取子节点下的数据
        that.getTreeChildren(treeNode.dataRef.key, resolve);
      });
    },
    /**
     * 加载树的下级数据, 未使用
     */
    getTreeChildren (id, resolve) {
      console.log("点击了id", id);
      resolve();
    },
    /**
     * 系统打印
     */
    tsbPrint () {
      var json = {
        formConfig: {
          position: { x: 0, y: 0 },
          size: { w: 0, h: 0 },
          title: this.title
        },
        title: this.title,
        formRows: [],
        mxForm: {
          columns: this.thisColumns,
          data: this.tableDatas,
          position: { x: 0, y: 0 },
          size: { w: 0, h: 0 },
          type: "mxForm"
        }
      };
      this.$refs.systemPrintSet.show(json);
    },
    /**
     * 模板打印
     * @param button
     * @param other
     */
    tsbTemplatePrint (button, other) {
      console.log("模板打印", button, other);
      if (this.lodopInit()) {
        if (this.selectedRowKeys.length >= 1) {
          if (button && button.param && button.param.printTemplateName) {
            let printType = other && other.menuValue ? other.menuValue : "打印";
            let that = this;
            let printDatas = [];
            for (let i = 0; i < this.selectedRowKeys.length; i++) {
              let selectedData = this.tableDatas.find(function (data) {
                return data[that.rowKey] === that.selectedRowKeys[i];
              });
              printDatas.push(selectedData);
              //this.lodopPrint(button.param.printTemplateName, selectedData);
            }
            this.lodopRenderingPrint(
              button.param.printTemplateName,
              printDatas,
              printType
            );
          } else {
            this.alertWarning("错误的打印配置");
          }
        } else {
          this.alertWarning("请选择至少一条需要打印的记录");
        }
      }
    },
    drawer (e, type) {
      if (type == "focus") {
        if (e.target.className.indexOf("tree-focus") == -1) {
          e.target.className += " tree-focus";
          this.maskShow = true;
        }
      }
      if (type == "blur") {
        e.target.classList.remove("tree-focus");
        this.maskShow = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/tree-list-view.less";
</style>
