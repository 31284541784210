<!-- 记账凭证 登记表单（定制表单） -->
<template>
  <div
    class="keep-accounts-view"
    :class="'keep-accounts-view_' + $config.defaultControlSize"
    style="overflow: scroll;"
    :id="domId"
    @keydown.right="keyDownRight"
    @keydown.left="keyDownLeft"
    @keydown.down="nextRow"
    @keydown.up="lastRow"
    @keydown.enter="enter"
    @keydown.ctrl.i="handleAdd"
    @keydown.ctrl.d="keydownDel"
  >
    <!-- {{editIndex}} -->
    <div style="overflow: visible;">
      <div class="form-headButton" v-if="showToolbar">
        <!-- 授权操作按钮 -->
        <e-toolbar
          :buttons="buttons"
          :size="$config.defaultControlSize"
          :operationDisabled="operationDisabled"
          ref="eToolbar"
          @click="onButtonClick"
        ></e-toolbar>
      </div>
      <div class="tab-view-context">
        <div class="eForm_content" v-if="showForm" v-show="!showFormSpin">
          <!-- 表单标题-->
          <e-head-title
            v-model="showMainFormTitle"
            :title="title"
            :id="1"
            :size="$config.defaultControlSize"
            rightIcon="true"
            :tip-info="spState"
            @clickRightIcon="closeFormTable"
          ></e-head-title>
          <div class="form-table" v-show="foldFormTable1">
            <a-form :form="form" class="form_modal_context">
              <form-template
                :colSpan="thisColSpan"
                :formRows="formRows"
                :mainTableData="oldMainTableObject"
                :tempId="tempId"
                :templateName="templateName"
                :formDisable="formDisable"
                :formEditOver="formEditState"
                :fieldsAllowedEdit="fieldsAllowedEdit"
                :form="form"
                @formExtendEvent="formDataChange"
                ref="tzpzMainFrom"
              ></form-template>
            </a-form>
          </div>
          <!-- 表title -->
          <e-head-title
            title="记账明细"
            :id="2"
            class="form-block-title_marginTop"
            :size="$config.defaultControlSize"
            rightIcon="true"
            @clickRightIcon="closeFormTable"
          ></e-head-title>
          <div class="form-table" v-show="foldFormTable2">
            <div
              class="form-table__mxButton"
              v-if="canEdited && !formEditState"
            >
              <a-button
                class="editable-add-btn"
                @click="handleAdd"
                :size="$config.defaultControlSize"
              >
                <a-icon type="plus-circle" />新增
              </a-button>
              <div class="divider-block-min" style="width: 10px;"></div>
              <a-popconfirm
                okText="是"
                cancelText="否"
                :visible="delButtonConfirmVisible"
                @confirm="handleDel"
                @cancel="delButtonConfirmVisible = false"
                @visibleChange="delButtonConfirmVisibleChange"
              >
                <template slot="title">
                  <p>是否确定删除?</p>
                </template>
                <a-button
                  class="editable-del-btn"
                  :size="$config.defaultControlSize"
                >
                  <a-icon type="delete" />删除
                </a-button>
              </a-popconfirm>
            </div>
            <!-- {{jzDetailDataList}} -->
            <!-- :scroll="{y: 400,  x: 1024}" -->
            <!-- {{focusDataIndex}},{{editIndex}},ctrl: {{ctrl}} -->
            <a-table
              :size="aTableSize_MiXin"
              bordered
              :class="$config.table_stripe ? 'ant-table-stripe' : ''"
              class="e-table__extendClassName"
              :rowKey="rowKey"
              :pagination="false"
              :columns="showKmbm ? thisColumns : thisColumns2"
              :dataSource="jzDetailDataList"
              tableLayout="fixed"
              :expandedRowKeys="expandedRowKeys"
              :customRow="customRow"
              ref="TableInfo"
              :scroll="{ y: 400 }"
              :rowSelection="rowSelectionOption"
              :rowClassName="
                () => {
                  return 'keepRow';
                }
              "
              @expandedRowsChange="onExpandedRowsChange"
              @expand="onRowExpand"
              size="small"
            >
              <!-- 合计行定义 -->
              <template
                v-if="thisColumnsHj.length"
                slot="footer"
                slot-scope="currentPageData"
              >
                <a-table
                  :size="aTableSize_MiXin"
                  bordered
                  :rowKey="rowKey"
                  :pagination="false"
                  :columns="showKmbm ? thisColumnsHj : thisColumnsHj2"
                  :showHeader="false"
                  :dataSource="getFooterDataSource(currentPageData)"
                  :rowClassName="
                    () => {
                      return 'keepRow';
                    }
                  "
                >
                  <!-- 合计行-序号列 -->
                  <!-- <div slot="totalIndex-column" slot-scope="text, record, index">

                    {{currentPageData.length}}
                  </div> -->
                  <!-- 合计列 -->
                  <template
                    v-for="col1 in showKmbm ? thisColumnsHj : thisColumnsHj2"
                    :slot="col1.key"
                    slot-scope="text, record, index"
                  >
                    <div
                      v-if="col1.sumRow"
                      :key="col1.key"
                      class="view-table__mainDataCol"
                      :class="getMainDataAlignClass(col1.align)"
                    >
                      <e-table-colum
                        v-if="record.editable && !formEditState"
                        :column="col1"
                        :slotScopeData="record"
                      ></e-table-colum>
                      <template v-else>{{ text }}</template>
                    </div>
                  </template>
                </a-table>
              </template>
              <!-- 序号列 -->
              <div slot="totalIndex-column" slot-scope="text, record, index">
                {{ ++index }}
              </div>
              <!-- 明细列 -->
              <template
                v-for="col in showKmbm ? thisColumns : thisColumns2"
                :slot="col.key"
                slot-scope="text, record, index"
              >
                <div
                  v-if="
                    col.dataIndex !== 'operation-column' &&
                      col.dataIndex !== 'totalIndex-column'
                  "
                  :key="col.key"
                  class="view-table__mainDataCol"
                  :class="getMainDataAlignClass(col.align)"
                  @click="
                    e => setFocusDataIndex(col.dataIndex, record[rowKey], e)
                  "
                  :style="{
                    background:
                      focusDataIndex == col.dataIndex &&
                      editIndex == record[rowKey]
                        ? '#fff'
                        : '#fff'
                  }"
                >
                  <!-- {{col.dataIndex + record[rowKey]}}, -->
                  <e-table-colum
                    v-if="col.editable && !formEditState && !formDisable"
                    :column="col"
                    :slotScopeData="record"
                    :id="col.dataIndex"
                    @onChange="
                      onColumColChange(col.scopedSlots.onChange, $event)
                    "
                    @onLinked="onLinkedColum"
                    :ref="domId + col.dataIndex + record[rowKey]"
                    @enter="enter"
                    @nextRow="nextRow"
                    @lastRow="lastRow"
                    @kminput="setFocusDataIndex(col.dataIndex, record[rowKey])"
                    @constraintEnter="constraintEnter"
                    @clearOther="
                      changeMoney(col.dataIndex, record, record[rowKey])
                    "
                    @moneyEqual="
                      moneyEqual(col.dataIndex, record, record[rowKey])
                    "
                    :waiting="index != 0 ? true : false"
                    :dataOptionSave="dataOptionSave[col.dataIndex]"
                    @postDataOptionSave="
                      postDataOptionSave($event, col.dataIndex)
                    "
                    :kmNext="kmNextGetIndex == index ? true : false"
                    @kmNextNum="kmNextNum"
                    @isScan="(v)=> isScan(v,col.dataIndex, record, record[rowKey])"
                    :disabled="formDisable"
                  ></e-table-colum>
                  <!-- Error in v-on handler: "RangeError: Maximum call stack size exceeded" -->
                  <template v-else>{{
                    col.textFieldName ? record[col.textFieldName] : text
                  }}</template>
                </div>
              </template>
              <!-- 操作按钮 -->
              <a
                slot="operation-column"
                slot-scope="text, record"
                @click="editRow($event, record[rowKey])"
                >{{ formEditState || fzhsEditOver  ? "查看辅助项" : "编辑辅助项" }}</a
              >
              <!-- 辅助核算项内嵌行表 -->
              <div
                slot="expandedRowRender"
                slot-scope="record, index, indent, expanded"
                class="eForm_expandedRowTable"
              >
                <!-- <div style="padding: 5px 0;">辅助核算</div> -->
                <a-table
                  :size="aTableSize_MiXin"
                  :columns="innerColumns"
                  :dataSource="
                    record.innerTableData && record.innerTableData.length != 0
                      ? record.innerTableData
                      : [{ c1: '暂无', c2: '暂无' }]
                  "
                  :pagination="false"
                ></a-table>
              </div>
            </a-table>
          </div>
        </div>
        <a-spin
          :spinning="showFormSpin"
          tip="表单加载中..."
          size="large"
          class="form-spin"
        ></a-spin>
        <div v-show="showFormError" class="e-form-formErrorInfo">
          <p>表单加载失败</p>
        </div>
      </div>
      <!-- 其他功能区域 -->

      <!-- 辅助核算项目选择编辑表单弹出框 -->
      <e-form-modal
        ref="fzhs"
        v-model="fzhsxSelectModalShow"
        :cwpzId="fzhsxSelectCwpzId"
        :initFormData="fzhsxSelectInitFormData"
        :showOk="true"
        :editOver ="fzhsEditOver"
        :redraw = "fzhsEditOver"
        @okClicked="onFzhsxSelected"
        @fzhsSelectOk="fzhsSelectOk"
      >
        <template slot="title">
          <div class="e-model-head">
            <span class="e-model-title">辅助核算项选择</span>
          </div>
        </template>
      </e-form-modal>

      <!-- 审批意见确认窗 <a-icon type="form"></a-icon>-->
      <e-form-modal
        v-model="spModalShow"
        :buttons="spButtons"
        formName="审批意见表"
        @buttonClicked="doFormSp"
      >
        <template slot="title">
          <div class="e-model-head">
            <span class="e-model-title">表单审批</span>
          </div>
        </template>
      </e-form-modal>

      <!-- 提交审批确认窗 -->
      <e-form-modal
        v-model="tjModalShow"
        formName="提交审批"
        :initFormData="tjDefaultData"
        :showOk="true"
        @okClicked="doFormTj"
        ref="tjModal"
      >
        <template slot="title">
          <div class="e-model-head">
            <span class="e-model-title">提交审批</span>
          </div>
        </template>
      </e-form-modal>

      <!-- 审批记录弹出框 -->
      <e-table-modal
        v-model="spjlModalShow"
        :tableConfig="spTableConfig"
        title="审批记录"
        width="900"
        :tableDatas="spTableDatas"
        :loading="spTableSpin"
      ></e-table-modal>
      <!-- 查看审批流程图 -->
      <view-image-modal
        v-model="showWorkFlowImage"
        :src="workflowImageSrc"
        title="流程图"
      ></view-image-modal>
      <!-- 关联单据查看
      <e-table-modal
        v-model="linkTableModalShow"
        :tableConfig="linkTableConfig"
        title="查看关联单据"
        :tableDatas="linkTableDatas"
        :loading="linkTableSpin"
        needExport
        needPrint
      ></e-table-modal> -->
    </div>
  </div>
</template>
<script>
import Axios from "axios";
import ETableColum from "@/components/ETableColum";
import { analyticalTemplate } from "@/mixins/analyticalTemplate.js"; // 解析xml模板的方法
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { pageDestroy } from "@/mixins/pageDestroy.js"; // 页面关闭清除缓存的方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求
import { e1FormToolbarWorkFlow } from "@/views/form/mixins/e1FormToolbarWorkFlow.js"; // e1的审批操作方法
import { Keydown } from "./mixins/keydown.js";

// 明细表定义
const columns = [
  // {
  //   title: "序号",
  //   width: 50,
  //   dataIndex: "totalIndex-column",
  //   align: "center",
  //   scopedSlots: { customRender: "totalIndex-column" }
  // },
  {
    title: "摘要",
    dataIndex: "ZY",
    key: "ZY",
    minWidth: 180,
    height: 60,
    editable: true,
    scopedSlots: { customRender: "ZY", type: "keepAccountZY" },
    keyMove: true
  },
  {
    title: "会计科目",
    dataIndex: "KMBM",
    key: "KMBM",
    minWidth: 180,
    editable: true,
    textFieldName: "KMMC",
    keyMove: true,
    scopedSlots: {
      customRender: "KMBM",
      type: "keepAccountZYKM",
      // type: "kjKmTextSelectButton",
      onChange: "onKMMCChange",
      linkColumns: [
        {
          field: "KMMC",
          mbField: "KMMC"
        },
        {
          field: "ZDZTZID",
          mbField: "ZDZTZID"
        }
      ]
    }
  },
  {
    title: "会计科目编码",
    dataIndex: "KMBM",
    align: "center",
    key: "KMBM1",
    width: 110,
    editable: false,
    textFieldName: "KMMC1",
    keyMove: false,
    visible: false
  },
  {
    title: "借方金额",
    dataIndex: "JFJE",
    key: "JFJE",
    minWidth: 158,
    align: "center",
    sumRow: true,
    editable: true,
    scopedSlots: { customRender: "JFJE", type: "amountInput", defaultValue: 0 },
    keyMove: true
  },
  {
    title: "贷方金额",
    dataIndex: "DFJE",
    key: "DFJE",
    minWidth: 158,
    align: "center",
    sumRow: true,
    editable: true,
    scopedSlots: { customRender: "DFJE", type: "amountInput", defaultValue: 0 },
    keyMove: true
  },
  { title: "KMMC", dataIndex: "KMMC", key: "KMMC", visible: false },
  { title: "ZDZTZID", dataIndex: "ZDZTZID", key: "ZDZTZID", visible: false },
  {
    title: "操作",
    width: 100,
    align: "center",
    dataIndex: "operation-column",
    scopedSlots: { customRender: "operation-column" }
  }
];
// 每行明细表的内嵌信息子表
const innerColumns = [
  { title: "辅助核算项名", dataIndex: "c1", key: "c1" },
  { title: "辅助核算项值", dataIndex: "c2", key: "c2" }
];

export default {
  mixins: [
    analyticalTemplate,
    analyticalMenuAuth,
    pageControls,
    pageDestroy,
    requestControls,
    e1FormToolbarWorkFlow,
    Keydown
  ],
  name: "KeepAccountsForm",
  components: {
    ETableColum
  },
  data() {
    return {
      domId: "keep" + Date.parse(new Date()).toString(),
      random: null,
      dataFromType: null,
      showForm: false,
      showFormError: false,
      showFormSpin: false,
      foldFormTable1: true, // 是否折叠主表单
      foldFormTable2: true, // 是否折叠明细表单
      updateState: false, //当前状态，用于确认凭证号联动状态，false为不联动
      canEdited: true, // 明细是否可编辑
      clzt: null, // 记账凭证的处理状态，新建情况下为null
      thisFormName: "记账凭证", // 对应的表单名称
      oldMainTableObject: {},
      formRows: [],
      requiredField: {},
      thisMxRequiredField: {},
      form: null,
      thisColSpan: 24,
      thisFormConfig: null,
      mxTableName: ["CW_KJPZMXB"], // 记账明细表名
      thisColumns: [], // 主表单列定义
      thisColumns2: [],
      thisColumnsHj: [], // 表尾合计行列定义
      thisColumnsHj2: [],
      sumColumns: null, // 合计列
      innerColumns: innerColumns, //内嵌表格
      jzDetailDateObject: null, //一行记账明细默认数据
      allChildrenTablesDatas: {}, //明细数据缓存
      jzDetailDataList: [], // 记账明细数据列表
      footerDataSource: [],
      rowKey: this.defaultTableKeyName,

      detailOtherColumns: ["ID","HH", "_ZBID"],
      selectedRowKeys: [], // 当前选中的行
      expandedRowKeys: [], // 当前展开的行
      buttons: [], // 当前用户可用的操作按钮
      tempId: 0,
      templateName: null, // 模板名称
      dataId: 0,
      bdbh: null, // 用于表单审批的编号
      dmbh: null,
      thisConfig: null,
      tableConfig: null,
      thisFromEvents: null, // 表单的额外扩展功能
      delButtonConfirmVisible: false,

      fzhsEditOver: false, //辅助核算是否可编辑
      fzhsxSelectModalShow: false, // 辅助核算项选择框是否显示
      fzhsxSelectRowKey: null, // 当前编辑辅助核算项行的rowkey
      fzhsxSelectCwpzId: null, // 辅助核算项选择表单对应的财务配置id
      fzhsxSelectInitFormData: null, // 辅助核算项选择表单的默认显示内容
      formEditState: false, // 表单编辑是否结束
      formDisable: false, // 表单是否禁用
      fieldsAllowedEdit: null, //在“未处理”状态下，当前用户可以编辑的字段

      dataOptionSave: {},
      kmNextGetIndex: 0,
      showKmbm: false,

      isF5NewFrom: false,

      //linkTableModalShow: false, //是否显示关联单据信息
      //linkTableDatas: null, //关联单据信息
      //linkTableSpin: false, //关联单据加载中
      /*linkTableConfig: {
        rowKey: "_BDBH",
        columns:[
            { title: "表单编号", dataIndex: "_BDBH", key: "_BDBH" , width: 180,
              scopedSlots: {
                customRender: '_BDBH',
                type :'hyperlink',
                clickMethod: "showByRule",
                textCheckMethod: this.checkTextMeetASNGenerateRule,
                clickCallback: "closeModal",
              }
            },
            { title: "表单名称", dataIndex: "_BDMC", key: "_BDMC", width: 300, }
          ]
      },*/
    };
  },
  computed: {
    //  单选多选Checkbox配置
    rowSelectionOption() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        type: "checkbox"
      };
    },
    showToolbar: function() {
      return this.checkToobleIsShow(this.buttons);
    },
    showMainFormTitle() {
      return this.showForm && this.formRows.length > 0;
    },
    saveMxTableNames() {
      return this.mxTableName;
    }
  },
  props: {
    pageConfig: {
      //包含视图结构、视图数据来源、操作权限配置
      type: Object,
      default: function() {
        return null;
      }
    },
    title: {
      type: String,
      default: "填制凭证"
    }
  },
  watch: {
    fzhsxSelectModalShow(v) {
      if (v) {
        let that = this;
        this.$nextTick(() => {
          that.$refs.fzhs.tableFocus();
          setTimeout(function(){
              let nowSelectRowIndex = that.jzDetailDataList.findIndex(function(d){
                return d[that.rowKey] === that.fzhsxSelectRowKey;
              });
              //如果已经包含值,并且innerTableData并未初始化，则需要从表单中获取相关参数
              if(nowSelectRowIndex>=0 && !that.jzDetailDataList[nowSelectRowIndex].innerTableData
                && that.jzDetailDataList[nowSelectRowIndex].innerData) {
                let newRow = Object.assign({}, that.jzDetailDataList[nowSelectRowIndex]);
                let data = {};
                data.chineseName = that.$refs.fzhs.getFormChineseName();
                data.labelCnName = that.$refs.fzhs.getFormLabelCnName();
                data.values = newRow.innerData;
                newRow.innerTableData = that.getFzhsxTableData(data);
                that.updatePageData("jzDetailDataList", nowSelectRowIndex, newRow);
              }
          }, 800);
        });
      }
    },
    showFormSpin(v) {
      if (!v && this.isF5NewFrom) {
        this.isF5NewFrom = false;
        this.$nextTick(()=>{
          this.$refs.tzpzMainFrom.firstFocus();
        })
      }
    }
  },
  created() {
    this.tempId = this.$route.query.templetId;
    this.dataId = this.$route.query.dataId;
    this.random = this.$route.query.random;
    this.clzt = this.$route.query.clzt;
    this.dmbh = this.$route.query.dmbh;
    this.dataFromType = this.$route.query.type;
    this.isF5NewFrom = true;
    // this.isF5NewFrom = this.$route.query.isF5NewFrom;
    let tampButtons = [];
    if (!this.tempId && !this.dataId) {
      // 通过地址传参跳转到本页面
      this.updateState = true;
      this.thisConfig = this.pageConfig;
      //分析出用户可做操作按钮
      tampButtons = this.getUserJurisdictionButtons(this.thisConfig);
      if (this.thisConfig && this.thisConfig.viewConfig) {
        this.dmbh = this.thisConfig.DHBM;
        // 表单配置
        this.tableConfig = this.thisConfig.viewConfig;
        this.tempId = this.tableConfig.templatId;
      } else {
        this.alertError("错误的用户配置");
      }
    } else {
      if (this.dmbh) {
        let pageConfig = this.getPageConfig(this.dmbh);
        console.log("视图定义", pageConfig);
        if (pageConfig) {
          this.thisConfig = pageConfig;
          tampButtons = this.getUserJurisdictionButtons(this.thisConfig);
          if (this.thisConfig && this.thisConfig.viewConfig) {
            // 表单配置
            this.tableConfig = this.thisConfig.viewConfig;
            this.tempId = this.tableConfig.templatId;
            this.updateState = true;
          } else {
            this.alertError("错误的用户配置");
          }
        } else {
          this.alertError("错误的用户配置");
        }
        console.log("打开的视图为", this.thisConfig);
      }
    }
    console.log("打开的视图为random", this.random);
    this.buttons = this.$util.deepCopy(tampButtons);
    if (!this.dataId) {
      this.buttons.forEach(function(b) {
        if (
          b.tsbName === "tsbOpenNew" ||
          b.tsbName === "tsbDelete" ||
          b.tsbName === "tsbChongXiao" ||
          b.tsbName === "tabRelated"
        ) {
          b.isShow = false;
        }
      });
    }
  },
  mounted() {
    if (window.keepKeydown) {
      window.keepKeydown[this.domId] = this.outAppKeyDownFunction;
    } else {
      window.keepKeydown = {};
      window.keepKeydown[this.domId] = this.outAppKeyDownFunction;
    }
    this.getFormTemplate();
    if (this.thisConfig && this.showToolbar)
      this.updateEToolbarButtonDisplay(
        ["tsbDoWorkFlow", "tsbWorkFlowRecord"],
        false
      );
  },
  // 从router 的 keepalive 再进入时触发
  activated() {
    if (window.keepKeydown) {
      window.keepKeydown[this.domId] = this.outAppKeyDownFunction;
    } else {
      window.keepKeydown = {};
      window.keepKeydown[this.domId] = this.outAppKeyDownFunction;
    }
  },
  destroyed() {
    // debugger;
  },
  beforeDestroy() {},
  methods: {
  /**
   * 数据校验
   */
    isScan(v, field, data, rowKey) {
      data[field+"scan"] = v;
    },
    /**
     * 初始化明细表
     */
    initDetailTable() {
      this.jzDetailDateObject = this.getDetailTableDefaultObj(
        columns,
        this.innerColumns
      );
      this.thisColumns = columns.filter(function(d) {
        return d.visible !== false || d.key === "KMBM1";
      });
      this.thisColumns2 = columns.filter(function(d) {
        return d.visible !== false;
      });
      this.setEditDataIndexSort(this.thisColumns);
      this.sumColumns = columns.filter(function(d) {
        return d.sumRow;
      });
      if (this.sumColumns.length > 0) {
        // 如果配置了合计列，则需要渲染出合计行
        this.thisColumnsHj = [].concat(this.thisColumns);
        this.thisColumnsHj2 = [].concat(this.thisColumns2);
        this.thisColumnsHj.splice(0, 0, {
          title: "合计",
          width: 102,
          dataIndex: "sum-column",
          align: "center"
        });
        this.thisColumnsHj2.splice(0, 0, {
          title: "合计",
          width: 102,
          dataIndex: "sum-column",
          align: "center"
        });
        this.footerDataSource.push(
          Object.assign({ "sum-column": "合计" }, this.jzDetailDateObject)
        );
      }
      if (!this.dataId) {
        //初始化第一条数据
        this.jzDetailDataList.push(Object.assign({}, this.jzDetailDateObject));
      }
    },
    /**
       * 将明细表的列设置为隐藏状态
      setDetailColumnsVisible(hideColums) {
        if(this.thisColumns && this.thisColumns.length && hideColums.length>0) {
            for(let i=0; i<hideColums.length; i++) {

            }
        }
      }, */
    /**
     * 从表格配置中获取对应字段组成的对象
     */
    getDetailTableDefaultObj(columns, innerColumns, row) {
      if (columns && columns.length > 0) {
        let obj = { editable: true };
        obj[this.rowKey] = row ? row : 1;
        for (let i = 0; i < columns.length; i++) {
          if (
            columns[i].key &&
            columns[i].key !== "operation-column" &&
            columns[i].key !== "totalIndex-column"
          ) {
            if (
              columns[i].scopedSlots &&
              columns[i].scopedSlots.defaultValue != undefined &&
              columns[i].scopedSlots.defaultValue != null
            ) {
              obj[columns[i].key] = columns[i].scopedSlots.defaultValue;
            } else {
              obj[columns[i].key] = "";
            }
          }
        }
        // 如果包含内嵌表格，则需要给内嵌表格设置保存对象数组
        if (innerColumns && innerColumns.length > 0) {
          obj.innerTableData = [];
        }
        return obj;
      }
    },
    /**
     * 获取表单的扩展属性
     */
    getFormExtends() {
      this.getFormExtends_Mixin(
        "thisFromEvents",
        this.tempId,
        this.templateName
      );
    },
    /**
     *  获取指定模板
     */
    getFormTemplate() {
      this.loading();
      //console.log("getFormTemplate->>>>>>>>",this.tempId);
      let that = this;
      if (this.thisFormName) {
        // 获取本地配置模板
        let url = "/xml/" + this.thisFormName + ".xml";
        Axios.get(url)
          .then(resp => {
            let tempXML = resp.data;
            if (tempXML !== "") {
              let res = that.renderFormTemplate(
                tempXML,
                that.oldMainTableObject
              );
              console.log("获取到本地模板", res, that.oldMainTableObject);
              that.thisColSpan = res.colSpan;
              that.formRows = res.formRows;
              that.thisFormConfig = res.formDefine;
              that.mainTableName = res.formDefine.tableName;
              //that.mxTableName = res.formDefine.mxTableName;
              that.templateName = that.thisFormConfig.title; // 模板名称
              that.form = that.$form.createForm(that, {
                name: that.templateName
              });
              that.initDetailTable(); // 初始化明细表格
              that.getFormExtends(); // 获取当前表单的扩展属性
              //获取表单数据
              if (that.dataId) {
                that.getFormData(that.tempId, that.dataId, function(data) {
                  //将获取的按钮显示出来
                  that.buttons = [].concat(data.operation);
                  setTimeout(function() {
                    if (that.dataFromType === "dateSearchView") {
                      that.formEditState = true; //表单不可编辑
                      that.fzhsEditOver = true;
                      that.canEdited = false;
                      that.updateEToolbarButtonDisplay(
                        "tsbWorkFlowRecord"
                      );
                    } else if (that.dataFromType === "chongxiao") {
                      // 冲销表单
                      that.fromState = 1;
                      that.spState = "（冲销）";
                      that.fzhsEditOver = true; //辅助核算项目
                      that.updateState = true;
                      that.formEditState = true;
                      that.formDisable = true; // 表单不可编辑
                    } else {
                      // 普通表单
                      let workFlows = data.workFlow;
                      console.log("获取到表单操作状态：", workFlows);
                      // 缓存明细数据
                      //that.allChildrenTablesDatas = data.ChildrenTableData;
                      if (workFlows) {
                        that.spButtons = that.getSpButtons(workFlows.operation);
                        //that.bdbh = that.mainTableData._BDBH;
                        if (workFlows.workFlowState !== "") {
                          that.fromState = that.showStateNameFrom.indexOf(
                            workFlows.workFlowState
                          );
                          if (that.fromState >= 1)
                            that.spState = that.getStateName(that.fromState);
                        }
                        if (that.fromState == 1) {
                          // 根据表单流程状态-切换表格编辑状态
                          // 草稿状态
                          that.formEditState = false;
                          that.formDisable = false; // 表单可编辑
                          //that.updateEToolbarButtonDisplay(["tsbEdit", "tsbSave", "tsbGridImport"]);
                        } else {
                          // 非草稿状态
                          if (that.fromState == 2 || that.fromState == 4) {
                            that.formEditState = false; //表单可编辑
                            if (that.fromState == 2) {
                              that.formDisable = false; //未提交 表单可编辑
                            } else {
                              that.fzhsEditOver = true;
                              that.formDisable = true;  // 待处理 有限可编辑
                            }
                            that.beforeSpOptNeedSave = true; // 如果直接审批，需要先保存
                            // 需要审批操作
                            that.updateEToolbarButtonDisplay([
                              "tsbDoWorkFlow",
                              "tsbWorkFlowRecord"
                            ]);
                          } else if (
                            that.fromState == 3 ||
                            that.fromState == 5
                          ) {
                            // 已提交和已处理
                            that.fzhsEditOver = true;
                            that.formEditState = true; //表单不可编辑
                            that.updateEToolbarButtonDisplay(
                              "tsbWorkFlowRecord"
                            );
                            that.canEdited = false; // 明细表不可编辑
                          } else {
                            that.formEditState = true; //表单不可编辑
                          }

                          //如果设置了隐藏字段
                          if (workFlows.HideField) {
                            that.hideField = workFlows.HideField;
                            if (
                              that.hideField.mainTableField &&
                              that.hideField.mainTableField.length > 0
                            ) {
                              // 主表单隐藏字段
                              that.setFormFieldHide(
                                that.form,
                                that.formRows,
                                that.hideField.mainTableField
                              );
                            }
                            //if (that.hideField.chrenTableField) { //明细表隐藏字段
                            //}
                          }

                          // 判断是否配置可编辑项
                          if (
                            !that.formEditState &&
                            that.fromState == 4 &&
                            (workFlows.EditableField ||
                              workFlows.MustInputField)
                          ) {
                            // 有可操作项
                            if (workFlows.EditableField) {

                              if (
                                workFlows.EditableField.mainTableField &&
                                workFlows.EditableField.mainTableField.length >
                                  0
                              ) {
                                // 把出纳，记账、审核人的可编辑取消
                                that.fieldsAllowedEdit = that.$util.arrayRemoveValue(workFlows.EditableField.mainTableField,
                                    ["KJZGSHRID","CNQZRID", "PZJZRID"], function(a, b){
                                  return b.includes(a);
                                });
                              }
                              console.log(
                                "有可以编辑的字段：",
                                that.fieldsAllowedEdit
                              );
                              if (
                                workFlows.EditableField.chrenTableField &&
                                that.$util.isValidObject(
                                  workFlows.EditableField.chrenTableField
                                )
                              ) {
                                that.mxAllowedEditField =
                                  workFlows.EditableField.chrenTableField;
                              }
                            }
                            // 如果有设置必填字段
                            if (workFlows.MustInputField) {
                              that.requiredField = workFlows.MustInputField;
                              // 主表单必填字段
                              that.setFormFieldRequired(
                                that.form,
                                that.formRows,
                                that.requiredField.mainTableField
                              );
                              // 有问题
                              if (that.requiredField.chrenTableField) {
                                that.thisMxRequiredField =
                                  that.requiredField.chrenTableField;
                              }
                            }
                          } else if (that.fromState != 2) {
                            // 不能编辑或无可操作项 隐藏保存,编辑，明细选人按钮
                            that.updateEToolbarButtonDisplay(
                              ["tsbEdit", "tsbSave", "tsbGridImport"],
                              false
                            );
                          }
                        }
                      }
                    }
                    if (that.thisFromEvents && that.thisFromEvents.afterInit) {
                      that.thisFromEvents.afterInit(
                        that,
                        that.oldMainTableObject
                      );
                    }
                    that.loadingOk();
                  }, 500);
                });
              } else {
                if (that.thisFromEvents && that.thisFromEvents.afterInit) {
                  that.thisFromEvents.afterInit(that);
                }
                that.updateEToolbarButtonDisplay(
                  ["tsbDoWorkFlow", "tsbWorkFlowRecord"],
                  false
                );
                that.loadingOk();
              }
              //console.log("明细表，传参", that.mxTableName, that.mainTableName);
            }
          })
          .catch(function(error) {
            console.error(error);
            that.loadingError();
          });
      }
    },
    /**
     * 获取表单的数据, 注：特殊方法，只用于记账凭证表单！
     * @param tempId
     * @param dataId
     * @param bm
     * @param et_name
     * @param successOver
     */
    getFormData(tempId, dataId, successOver) {
      let that = this;
      if (tempId && dataId) {
        let params = {
          action: "GetTemplateDataByFinance",
          d_id: dataId,
          tempId: tempId,
          type: this.dataFromType,
          randomKey: this.$store.getters.getRandomKey
        };
        this.get("Form.ashx", params, {
          success: function(req) {
            //console.log("获取到表单数据：" , req);
            // 初始化化表单数据
            if (req.data.MainTableData) {
              let newData = req.data.MainTableData[0];
              let tempData = Object.assign({}, newData);
              for (let keyf in tempData) {
                if (tempData[keyf] == null) {
                  delete newData[keyf];
                }
              }
              that.oldMainTableObject = Object.assign(
                {},
                that.oldMainTableObject,
                newData
              );
            }
            console.log("数据库获取到表单数据：", that.oldMainTableObject);
            let detaitlDataList = req.data.ChildrenTableData;
            if (detaitlDataList) {
              detaitlDataList.map(function(d, index) {
                //把明细数据中的辅助核算内容分离出来
                that.getFzhsxObject(d);
                if (!d[that.rowKey]) {
                  d[that.rowKey] = index + 1;
                }
              });
              // 初始化化明细表数据
              //console.log("明细数据:",req.data.ChildrenTableData);
              that.jzDetailDataList = detaitlDataList;
            }

            console.log("明细数据:", that.jzDetailDataList);
            // 调用扩展功能，数据加载前调用的回调函数。
            if (that.thisFromEvents && that.thisFromEvents.beforeRenderData) {
              that.thisFromEvents.beforeRenderData(
                that,
                that.oldMainTableObject
              );
            }
            if (successOver && that.$util.isType(successOver) === "Function")
              successOver(req.data);
            else that.loadingOk();
          },
          error: function(err) {
            console.log(err);
            that.loadingError();
            that.alertError("请求失败，获取表单数据失败");
          }
        });
      } else {
        this.loadingError();
      }
    },

    /**
     * 显示加载中
     */
    loading() {
      this.showFormSpin = true;
      this.showForm = false;
      this.showFormError = false;
      this.operationDisabled = true;
    },
    /**
     * 加载成功
     */
    loadingOk() {
      this.showForm = true;
      this.showFormSpin = false;
      this.operationDisabled = false;

      let that = this;
      this.$nextTick(function() {
        // 同步表与footer滚动
        let dom = that.$refs.TableInfo.$el.querySelectorAll(
          ".ant-table-body"
        )[0];
        dom.addEventListener(
          "scroll",
          () => {
            that.$refs.TableInfo.$el.querySelectorAll(
              ".ant-table-body"
            )[1].scrollLeft = dom.scrollLeft;
          },
          true
        );
      });
    },
    /**
     * 加载失败
     */
    loadingError() {
      this.showFormSpin = false;
      this.showFormError = true;
      this.operationDisabled = true;
    },
    /**
     * 响应行事件
     * @param record
     */
    customRow: function(record) {
      return {
        on: {
          click: event => {
            // 行点击事件
            this.updateState = true;
            if (this.expandedRowKeys.indexOf(record[this.rowKey]) >= 0) {
              //console.log("点击当前行已经展开", record.key, this.expandedRowKeys, event);
              if (event)
                event.stopPropagation
                  ? event.stopPropagation()
                  : (event.cancelBubble = true);
            } else {
              // 如果没有展开的行，则控制其展开
              this.expandedRowKeys.push(record[this.rowKey]);
            }
          }
        }
      };
    },
    /**
     * 响应展开行变化事件
     * @param expandedRows
     */
    onExpandedRowsChange: function(expandedRows) {
      console.log("响应展开行变化事件");
      this.expandedRowKeys = expandedRows;
    },
    /**
     * 响应展开行事件
     * @param expanded
     */
    onRowExpand: function(expanded, record) {
        console.log(expanded, record);
        //如果没有辅助核算选中内容则弹出辅助核算选中弹窗
        if(expanded && (!record.innerTableData || record.innerTableData.length<1)) {
            this.editRow(null, record[this.rowKey]);
        }
    },
    /**
     * 响应行选中事件
     * @param selectedRowKeys
     * @param selectedRows
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      //console.log("选中项:", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    /**
     * 从每行明细数据中提取出辅助核算项的对象
     */
    getFzhsxObject: function(data) {
        let newData = null;
        if(this.$util.isType(data) === "Object") {
         newData = {};
         let nowColumes = [];
         for(let i=0; i<columns.length; i++) {
           nowColumes.push(columns[i].key);
         }
          let arr = Object.getOwnPropertyNames(data);
          if (arr.length > 0) {
            let json = JSON.stringify(data);
            if (json !== "{}") {
              for (let field in arr) {
               if(!nowColumes.includes(arr[field]) && !this.detailOtherColumns.includes(arr[field])) {
                 newData[arr[field]] = data[arr[field]];
                 delete data[arr[field]];
               }
              }
              data.innerData = newData;
            }
          }
        }
    },
    /**
     * 编辑辅助项
     * @param event
     * @param key
     */
    editRow: function(event, key) {
      let that = this;
      let row = this.jzDetailDataList.find(function(r) {
        return r[that.rowKey] === key;
      });
      this.fzhsxSelectRowKey = key;
      this.fzhsxSelectCwpzId = row.ZDZTZID;
      if (
        this.fzhsxSelectCwpzId &&
        this.fzhsxSelectCwpzId !== "0" &&
        this.fzhsxSelectCwpzId !== ""
      ) {
        this.openFzhsSelectModal(); // 打开编辑辅助项事件
        this.fzhsxSelectInitFormData = row.innerData;
        if (this.expandedRowKeys.indexOf(key) >= 0) {
          // 如果本行已经展开，则不再触发行点击事件
          if (event)
            event.stopPropagation
              ? event.stopPropagation()
              : (event.cancelBubble = true);
        }
      } else if (!this.fzhsxSelectCwpzId || this.fzhsxSelectCwpzId === "0" || this.fzhsxSelectCwpzId === "") {
        this.alert("会计科目未配置辅助核算项");
      } else if (!this.formEditState) {
        this.alert("请选择会计科目");
      }
    },
    /**
     * 折叠或展开指定表单块
     * @param n
     */
    closeFormTable: function(n) {
      if (n == 1) {
        // 如果是要收起主表单，则将明细表单置顶
        if (this.foldFormTable1) {
          this.scrollToBottom(null, "keep-accounts-view"); //tab-form-context
        }
        this.foldFormTable1 = !this.foldFormTable1;
      } else if (n == 2) {
        this.foldFormTable2 = !this.foldFormTable2;
      }
    },
    /**
     * 新增一行明细数据
     */
    handleAdd: function(newData) {
      var nullData = {
        KMBM: null
      };

      let nextData = Object.assign(nullData, this.jzDetailDateObject);
      if (newData && newData.ZY) {
        nextData.ZY = newData.ZY;
      }
      let lastKey = 0;
      if (this.jzDetailDataList.length > 0) {
        var lastRow = this.jzDetailDataList[this.jzDetailDataList.length - 1];

        if (lastRow["ZY"] == "") {
          this.$message.warning("摘要不能为空");
          return;
        }
        if (lastRow["KMBM"] == "" || lastRow["KMBM"] == null) {
          this.$message.warning("会计科目不能为空");
          return;
        }

        if (lastRow["DFJE"] == "" && lastRow["JFJE"] == "") {
          this.$message.warning("贷方金额或者借方金额不能为空");
          return;
        }

        lastKey = this.jzDetailDataList[this.jzDetailDataList.length - 1][
          this.rowKey
        ];
      }
      nextData[this.rowKey] = lastKey + 1;
      this.jzDetailDataList.push(nextData);
      this.$nextTick(() => {
        this.addLocation();
      });
    },
    /**
     * 确认删除选中的明细
     */
    handleDel: function() {
      if (this.selectedRowKeys.length > 0) {
        for (let i = 0; i < this.selectedRowKeys.length; i++) {
          let key = this.selectedRowKeys[i];
          let that = this;
          let rowIndex = this.jzDetailDataList.findIndex(function(r) {
            return r[that.rowKey] === key;
          });
          if (rowIndex >= 0) this.jzDetailDataList.splice(rowIndex, 1);
        }
      }
      this.delButtonConfirmVisible = false;
      this.selectedRowKeys = [];
    },
    /**
     * 检查是否选中了需要删除的明细
     */
    delButtonConfirmVisibleChange: function() {
      if (this.selectedRowKeys.length > 0) {
        this.delButtonConfirmVisible = true;
      } else {
        this.delButtonConfirmVisible = false;
        this.$message.error("请选择需要删除的数据");
      }
    },
    /**
     * 点击“新建”按钮，在tab打开一个新的本表单
     */
    tsbOpenNew(e, isF5) {
      e;
      let query = {
        dmbh: this.dmbh,
        templetId: this.tempId,
        isF5NewFrom: isF5
      };
      this.openTab({
        title: this.title,
        path: "/KeepAccountsForm",
        query: query
      });
    },
    /**
     * 根据当前行的排列设置，返回对应的排列样式
     * @param align
     * @returns {*}
     */
    getMainDataAlignClass(align) {
      if (align) {
        if (align === "center") {
          return "view-table__mainDataColCenter";
        } else if (align === "right") {
          return "view-table__mainDataColRight";
        }
      }
      return null;
    },
    /**
     * 监听每行的控件的值变化，如果变化了则根据配置进行后续操作
     * @param changeName
     * @param data
     */
    onColumColChange(changeName, data) {
      if (changeName && this[changeName] && this.updateState) {
        this[changeName](data);
      }
    },
    /**
     * 金额联动
     */
    changeMoney(field, data, index) {
      if (field === "JFJE") {
        this.$refs[this.domId + "DFJE" + index][0].clearMoney();
      }
      if (field === "DFJE") {
        this.$refs[this.domId + "JFJE" + index][0].clearMoney();
      }
    },
    /**
     * 将借贷双方金额自动配平
     */
    moneyEqual(field, data, rowKey) {
      let allData = this.jzDetailDataList;
      let jfjeMoney = 0;
      let dfjeMoney = 0;
      let money = 0;
      let alldfMoneys = [],
        alljfMoneys = [];
      for (let i = 0; i < allData.length; i++) {
        if (allData[i][this.rowKey] == rowKey) {
          continue;
        }
        alldfMoneys.push(allData[i]["DFJE"]);
        alljfMoneys.push(allData[i]["JFJE"]);
      }
      dfjeMoney = this.$util.mathUtil.sumArray(alldfMoneys);
      jfjeMoney = this.$util.mathUtil.sumArray(alljfMoneys);
      if (field === "JFJE") {
        // 当为借方金额时，贷方金额总计 - 借方金额除了本行的总计
        money = this.$util.mathUtil.accSub(dfjeMoney ,jfjeMoney);
        this.$set(data, "JFJE", money);
        // this.$refs[this.domId + "JFJE" + rowKey][0].setMoney(money);
        this.$refs[this.domId + "DFJE" + rowKey][0].clearMoney();
        // this.$refs[this.domId + "JFJE" + rowKey][0].setMoney(money);
      } else if (field === "DFJE") {
        // 当为贷方金额时，借方金额总计 - 贷方金额除了本行的总计
        money = this.$util.mathUtil.accSub(jfjeMoney , dfjeMoney);
        this.$set(data, "DFJE", money);
        // this.$refs[this.domId + "DFJE" + rowKey][0].setMoney(money);
        this.$refs[this.domId + "JFJE" + rowKey][0].clearMoney();
        // this.$refs[this.domId + "DFJE" + rowKey][0].setMoney(money);
      }
    },
    /**
     * 响应每行“会计科目”选择变化事件
     * @param data
     */
    onKMMCChange(data) {
      console.log("会计科目内容选择变化：", data);
      let record = data.record;
      let value = data.event.value;
      if (record) {
        let that = this;
        let rowIndex = this.jzDetailDataList.findIndex(function(r) {
          return r[that.rowKey] === record[that.rowKey];
        });
        if (value && value !== "") {
          // 选择了会计科目值
          if (rowIndex >= 0) {
            this.fzhsxSelectRowKey = record[this.rowKey];
            let cwpzId = this.jzDetailDataList[rowIndex].ZDZTZID;
            console.log("会计科目：", cwpzId);
            if (this.fzhsxSelectCwpzId !== cwpzId)
              this.fzhsxSelectCwpzId = cwpzId;
            this.openFzhsSelectModal();
          }
        } else {
          // 清除了会计科目
          this.fzhsxSelectCwpzId = null;
          if (rowIndex >= 0) {
            let rowData = Object.assign({}, this.jzDetailDataList[rowIndex], {
              ZDZTZID: null,
              innerTableData: []
            });
            this.updatePageData("jzDetailDataList", rowIndex, rowData);
            //console.log("辅助核算项内容：", this.jzDetailDataList);
          }
        }
      }
    },
    /**
     * 根据财务配置id，打开“辅助核算选择”弹窗
     */
    openFzhsSelectModal() {
      if (
        this.fzhsxSelectCwpzId &&
        this.fzhsxSelectCwpzId !== "0" &&
        this.fzhsxSelectCwpzId !== ""
      ) {
        this.fzhsxSelectModalShow = true;
        // this.$nextTick(() => {
        //   this.$refs.fzhs.tableFocus();
        // });
      }
    },
    /**
     * 响应-明细表中的联动字段数据变化
     * @param data
     */
    onLinkedColum(data) {
      if (data && data.linkedChange) {
        let record = data.record;
        if (record && record[this.rowKey]) {
          let key = record[this.rowKey];
          let that = this;
          let rowIndex = this.jzDetailDataList.findIndex(function(r) {
            return r[that.rowKey] === key;
          });
          if (rowIndex >= 0) {
            let rowData = Object.assign(
              {},
              this.jzDetailDataList[rowIndex],
              data.linkedChange
            );
            this.updatePageData("jzDetailDataList", rowIndex, rowData);
          }
        }
      }
    },
    /**
     * 打开的辅助核算选择弹窗后，
     * 点窗口的“确定”按钮的事件响应方法
     * @param selectData
     */
    onFzhsxSelected(selectData) {
      if (selectData && selectData.key && selectData.key === "ok") {
        if (selectData.data) {
          // 选中数据
          let innerObject = {
            innerData: selectData.data.values,
            innerTableData: this.getFzhsxTableData(selectData.data)
          };
          // 将辅助核算项内容附加到明细数据中
          let that = this;
          let rowIndex = this.jzDetailDataList.findIndex(function(r) {
            return r[that.rowKey] === that.fzhsxSelectRowKey;
          });
          if (rowIndex >= 0) {
            let rowData = Object.assign(
              {},
              this.jzDetailDataList[rowIndex],
              innerObject
            );
            this.updatePageData("jzDetailDataList", rowIndex, rowData);
            //console.log("辅助核算项内容：", this.jzDetailDataList);
          }
        }
        if (selectData.resolve) selectData.resolve();
      }
    },
    /**
     * 从辅助核算项选择弹窗中获取明细表行对应的内嵌显示表格内容
     * @param data
     * @returns {Array}
     */
    getFzhsxTableData(data) {
      let array = [];
      if (data && data.labelCnName && data.chineseName) {
        // 辅助核算表单中包含的字段项
        let paramKeys = Object.keys(data.labelCnName);
        for (let i = 0; i < paramKeys.length; i++) {
          let fieldName = paramKeys[i];
          if (fieldName) {
            let tempObj = {};
            tempObj.c1 = data.labelCnName[fieldName];
            tempObj.c2 = data.chineseName[fieldName];
            array.push(tempObj);
          }
        }
      }
      return array;
    },
    /**
     * 根据footer数据合计行
     * @param data
     * @returns {Array}
     */
    getFooterDataSource(data) {
      if (data && data.length > 0 && this.sumColumns.length > 0) {
        let temp = Object.assign({}, this.footerDataSource[0]);

        let ifUpdate = false;
        for (let i = 0; i < this.sumColumns.length; i++) {
          let fieldName =
            this.sumColumns[i].key || this.sumColumns[i].dataIndex;
          let sumArray = [];
          for (let j = 0; j < data.length; j++) {
            sumArray.push(data[j][fieldName]);
          }
          let sumValue = this.$util.mathUtil.sum(sumArray);
          if (temp[fieldName] && temp[fieldName] == sumValue) {
            continue;
          }
          //console.log(fieldName + "明细数据合计：", sumValue);
          ifUpdate = true;
          temp[fieldName] = sumValue;
        }
        temp.readOnly = true;
        if (ifUpdate) {
          this.footerDataSource[0] = temp;
        }
        //console.log("获得表尾响应数据",this.footerDataSource);
      }
      //console.log("明细数据：", this.footerDataSource[0]);
      return this.footerDataSource;
    },
    /**
     * 点击“冲销”按钮
     */
    tsbChongXiao(button) {
      console.log("冲销表单", this.oldMainTableObject.PZBH);
      let bm = this.oldMainTableObject.PZBH;
      let pz = this.form.getFieldValue("PZLBZ");
      if (bm.indexOf("_CX") == 0) {
        this.alertWarning("冲销记录不能做冲销操作");
        return;
      }
      this.startProcessing(button);
      this.openTab({
        title: "冲销-" + pz + "(" + bm + ")",
        path: "/KeepAccountsForm",
        query: {
          dmbh: this.dmbh,
          templetId: this.tempId,
          dataId: this.dataId, // 表单数据id
          bm: button.param.bm, // 表单对应BM表
          etName: button.param.et_name,
          event: button.param.event,
          type: "chongxiao"
        }
      });
      this.finishedProcessing(button);
    },
    /**
     * 响应表单控件扩展onChange事件
     * @param data
     */
    formDataChange(data) {
      let mainData = this.form.getFieldsValue();
      if (data.onChange) data.onChange(this, mainData, data.value);
    },
    /**
     * 点击新建保存按钮
     * @param button
     */
    tsbNew(button, param) {
      this.tsbSave(button, param);
    },
    /**
     * 点击修改保存按钮
     * @param button
     */
    tsbEdit(button, param) {
      this.tsbSave(button, param);
    },
    /**
     * 点击保存按钮
     * @param button
     */
    tsbSave(button, param) {
      let that = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("数据信息：" + JSON.stringify(values));
          let saveParam = param;
          if (that.$util.isType(saveParam)!=="Object") {
            let tempButton = that.checkOperationAuth(
              that.buttons,
              button.tsbName,
              () => {
                that.alertError("您没有保存表单的权限");
              }
            );
            saveParam = tempButton.param;
          }
          if (saveParam) {
            //console.log("明细数据：",that.jzDetailDataList);
            // 保存前检测，明细是否满足非空
            if (!(that.jzDetailDataList && that.jzDetailDataList.length > 0)) {
              that.alertError("明细表数据不能为空");
              return;
            }
            let params = {
              action: "SaveFormData",
              data: JSON.stringify(values), // 主表数据
              children: that.getMxDataJSON(), // 子表数据
              tb_name: that.mainTableName, // 主表名称 从DHCS中获取
              mxb_name: that.mxTableName.toString(), // 明细表名称
              bm: saveParam.bm, // 类库名称（SYSTEM）、从GJLCS中获取 {tsbNew,SYSTEM,1,980}
              t_id: that.tempId, // 模板id  从DHCS中获取
              et_name: saveParam.et_name, //  表示操作类型  添加为0，修改为1，删除为2 等，从GJLCS中获取 {tsbNew,SYSTEM,1,980}
              m_id: 0, // 保存时没用到 传入0
              d_id: that.dataId, // 插入之后返回的主键id
              randomKey: that.$store.getters.getRandomKey
            };
            that.startProcessing(button);
            let doSave = function(params, button) {
              that.post("Form.ashx", params, {
                success: function(req) {
                  //console.log("请求结果", req);
                  if (req.data.dataId) {
                    that.dataId = req.data.dataId;
                    // 是否有流程需要走，如果有则显示提交按钮
                    if (req.data.workFlow && req.data.workFlow.isWorkFlow) {
                      // 有流程则需要控制流程相关显示
                      if (that.fromState <= 1) {
                        /*let mc =
                          values.PZLBZ +
                          "-" +
                          that.$util.dateUtils.pattern(
                            that.$util.dateUtils.parse(values.YWRQ),
                            "YYYYMM"
                          );*/
                        let zt = values.PZLBZ + "-" + values.PZBH;
                        that.updatePageData("tjDefaultData", "_zt", zt);
                        that.bdbh = zt;
                        that.updateEToolbarButtonDisplay("tsbWorkFlowCreater");
                        // 避免重复提交
                        //直接保存后进行提交
                        that.doFormTj({
                          resolve: function() {
                            that.oldMainTableObject.PZBH = values.PZBH;
                            that.fromState = 2;
                            that.spState = that.getStateName(that.fromState);
                            that.updateEToolbarButtonDisplay(
                              ["tsbOpenNew", "tsbDelete", "tsbChongXiao"],
                              true
                            );
                            // that.alertSuccess("保存成功");
                            that.finishedProcessing(button);
                          },
                          data: that.tjDefaultData
                        });
                      } else {
                        that.finishedProcessing(button);
                      }
                    } else {
                      that.finishedProcessing(button);
                    }
                  } else {
                    that.updateEToolbarButtonDisplay(
                      ["tsbOpenNew", "tsbDelete", "tsbChongXiao"],
                      true
                    );
                    // that.alertSuccess("保存成功");
                    that.finishedProcessing(button);
                  }
                },
                error: function(e) {
                  console.error("请求失败", e);
                  that.alertError(e.message);
                  that.finishedProcessing(button);
                }
              });
            };
            // 判断是否配置了提交前的扩展功能
            that.exeSaveFromEvents(values, params, button, doSave);
          }
        } else {
          //console.log( JSON.stringify(err));
          let errMsg = that.getDecoratorErrInfo(err);
          that.alertError(errMsg);
        }
      });
    },
    /**
     * 点击“删除”按钮，删除当前表单
     * @param button
     * @param other
     */
    tsbDelete(button, other) {
      //console.log("删除", button);
      if (this.dataId) {
        if (other && other.prioriCondition) {
          // 满足前置条件还需要确认是否操作
          this.$refs.eToolbar.showConfirm(button.tsbName);
        } else {
          let that = this;
          let params = {
            action: "DeleteData",
            tb_name: that.mainTableName, //主表名
            mxb_name: that.mxTableName.toString(), // 明细表名称
            bm: button.param.bm,
            t_id: this.tempId, // 模板id  从DHCS中获取
            d_id: this.dataId, // 插入之后返回的主键id
            logSource: this.logSource,
            randomKey: this.$store.getters.getRandomKey
          };
          this.startProcessing(button);
          this.post("Form.ashx", params, {
            success: function() {
              //console.log("请求结果", req);
              // 把对应删除的数据从数组中移除
              that.finishedProcessing(button);
              that.closeTab(that.random ? that.random : that.dhbm);
              that.tipSuccess("删除成功");
            },
            error: function(err) {
              that.alertError(err.message);
              that.finishedProcessing(button);
            }
          });
        }
      }
    },
    /**
     * 弹窗查看关联单据
     */
    tabRelated(button) {
      let params = {
        action: "GetRelatedList",
        dataID: this.dataId,
        randomKey: this.$store.getters.getRandomKey
      };
      this.startProcessing(button);
      //this.linkTableModalShow = true; //是否显示关联单据信息
      //this.linkTableSpin = true;
      let that = this;
      this.get("Form.ashx", params, {
        success: function(req) {
          that.finishedProcessing(button);
          if(req.data) {
            /*
            2021-04-07 old
            that.linkTableDatas = req.data;
            that.linkTableSpin = false;
            */
            //2021-04-07 new 改成直接打开
            if(that.$util.isType(req.data)==="Array" && req.data.length>=1) {
              let bh = req.data[0]["_BDBH"], mc = req.data[0]["_BDMC"];
              let rule = that.checkTextMeetASNGenerateRule(bh);
              if(rule) {
                let title = "查看-" + mc + "（" + bh + "）";
                that.openTab({
                  title: title,
                  path: "/DetailForm",
                  query: {
                    dataCode: bh, // 表单数据编号
                    tableName: rule.tableName // 表单用于tab的title的表单字段名
                  }
                });
              } else {
                that.alertError("关联数据表单编码未加入编码格式配置");
              }
            }
          } else {
            //that.linkTableSpin = false;
            that.alertError("未获取到表单关联数据");
          }
        },
        error: function(err) {
          console.log(err);
          that.finishedProcessing(button);
          //that.linkTableSpin = false;
          that.alertError("请求失败，获取关联数据失败");
        }
      });
    },
    /**
     * 将获得到的明细数据转换为JSON格式字符串，用于表单保存方法
     */
    getMxDataJSON() {
      let mxJSONString = "[]";
      let mxdata = [
        {
          tableName: "CW_KJPZMXB",
          data: this.jzDetailDataList
        }
      ];
      //debugger;
      if (mxdata && mxdata.length > 0) {
        let mxArray = [];
        for (let i = 0; i < this.mxTableName.length; i++) {
          let tname = this.mxTableName[i];
          let dataObj = mxdata.find(function(d) {
            return d.tableName === tname;
          });
          if (dataObj && dataObj.data && dataObj.data.length > 0) {
            let newDatas = [];
            //把辅助核算项字段数据合并到明细记录中
            for (let j = 0; j < dataObj.data.length; j++) {
              let newData = this.$util.deepCopy(dataObj.data[j]);
              if(dataObj.data[j].innerData) {
                newData = Object.assign({}, dataObj.data[j], dataObj.data[j].innerData);
              }
              delete newData.innerData;
              delete newData.innerTableData;
              newDatas.push(newData);
            }
            mxArray.push({
              tableName: tname,
              data: newDatas
            });
          }
        }
        mxJSONString = JSON.stringify(mxArray);
      }
      //console.log("明细数据：", mxJSONString);
      return mxJSONString;
    },
    postDataOptionSave(v, field) {
      this.$set(this.dataOptionSave, field, v);
    },
    kmNextNum() {
      this.kmNextGetIndex += 1;
    },
    /**
     * 操作按钮点击响应，目前只有编辑视图有“复合功能按钮” 主要是保存场景问题
     * 覆盖了pageControls提供的默认方法
     * @param param
     * @constructor
     */
    onButtonClick(param) {
      let index = -1,
        other;
      if (this.$util.isType(param) === "Object") {
        index = param.index;
        other = param;
      } else {
        index = param;
      }
      if (this.buttons[index]) {
        if (this.$util.isType(this.buttons[index].tsbName) === "String") {
          // 单一功能按钮
          if (this[this.buttons[index].tsbName]) {
            this[this.buttons[index].tsbName](this.buttons[index], 1, other);
          } else {
            this.tipWarning("尚没有" + this.buttons[index].name + "的功能");
          }
        } else if (
          this.$util.isType(this.buttons[index].tsbName) === "Array" &&
          this.$util.isType(this.buttons[index].param) === "Array"
        ) {
          // 复合功能按钮
          this.buttonExtend(this.buttons[index], other);
        } else {
          this.tipWarning("尚没有" + this.buttons[index].name + "的功能");
        }
      }
    },
    /**
     * 按钮功能扩展，特殊按钮
     */
    buttonExtend(button) {
      let tname = null;
      let param = null;
      let index = -1;
      if (
        button.name === "保存" &&
        this.$util.isType(button.tsbName) === "Array"
      ) {
        // 保存功能，复合按钮
        if (!this.dataId) {
          index = button.tsbName.findIndex(function(obj) {
            return obj === "tsbNew";
          });
        } else {
          index = button.tsbName.findIndex(function(obj) {
            return obj === "tsbEdit";
          });
        }
      }
      tname = button.tsbName[index];
      if (tname) {
        param = button.param[index];
        if (param) {
          if (this[tname]) {
            this[tname](button, param);
          } else {
            this.tipWarning("尚没有" + button.name + "的功能");
          }
        } else {
          this.tipWarning(button.name + "未配置相应的操作参数");
        }
      } else {
        this.tipWarning("尚没有" + button.name + "的功能配置");
      }
    },
    /**
     * 点击切换科目编码列显示
     */
    tsbKMBM() {
      this.showKmbm = !this.showKmbm;
      // var col = this.thisColumns.find(item => {
      //   return item.key == "KMBM1";
      // });
      // col.visible = this.showKmbm;
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/style/base/keep-accounts-form.less";
</style>