<template>
  <div
    v-show="visible"
    style="display: contents"
    @keydown.right="keyDownRight"
    @keydown.left="keyDownLeft"
    @keydown.enter="enter"
    @keydown.110="SwitchScore"
    @keydown.107="transformjia"
    @keydown.109="transformjian"
  >
    <!-- {{setvalue}} -->
    <div class="input">
      <a-input
        :min="0"
        :max="9"
        @click.native="
          (e) => {
            inputFocus(0);
            e.target.selectionStart = e.target.value.length;
            e.target.selectionEnd = e.target.value.length;
          }
        "
        :style="{
          'border-left': '1px solid #d9d9d9',
          width: '13px',
          'border-right': '0px',
          color: value < 0 ? 'red !important' : '#000 !important',
        }"
        :size="controlSize"
        v-model="values[0]"
        @keydown.native="
          (e) => {
            keydown(e, 0);
          }
        "
        @input.native="(e) => changeValue(e, 0)"
        :disabled="thisDisabled"
        :readOnly="true"
        ref="EMoney0"
        @focus="
          (e) => {
            inputFocus(0);
          }
        "
        @blur="inputFocus(null)"
      />
      <span v-if="!thisDisabled && thisFocus == 0"></span>
    </div>
    <!-- @focus="(e)=>{inputFocus(0),e.target.select();}" -->
    <div class="input">
      <a-input
        :min="0"
        :max="9"
        @click.native="
          (e) => {
            inputFocus(1);
            e.target.selectionStart = e.target.value.length;
            e.target.selectionEnd = e.target.value.length;
          }
        "
        :style="{
          'border-left': '1px solid blue',
          width: '13px',
          color: value < 0 ? 'red !important' : '#000 !important',
        }"
        :size="controlSize"
        v-model="values[1]"
        @keydown.native="
          (e) => {
            keydown(e, 1);
          }
        "
        @input.native="(e) => changeValue(e, 1)"
        :disabled="thisDisabled"
        :readOnly="true"
        ref="EMoney1"
        @focus="
          (e) => {
            inputFocus(1);
          }
        "
        @blur="inputFocus(null)"
      />
      <span v-if="!thisDisabled && thisFocus == 1"></span>
    </div>
    <div class="input">
      <a-input
        :min="0"
        :max="9"
        @click.native="
          (e) => {
            inputFocus(2);
            e.target.selectionStart = e.target.value.length;
            e.target.selectionEnd = e.target.value.length;
          }
        "
        :size="controlSize"
        v-model="values[2]"
        @keydown.native="
          (e) => {
            keydown(e, 2);
          }
        "
        @input.native="(e) => changeValue(e, 2)"
        :disabled="thisDisabled"
        :readOnly="true"
        ref="EMoney2"
        :style="{ color: value < 0 ? 'red !important' : '#000 !important' }"
        @focus="
          (e) => {
            inputFocus(2);
          }
        "
        @blur="inputFocus(null)"
      />
      <span v-if="!thisDisabled && thisFocus == 2"></span>
    </div>
    <div class="input">
      <a-input
        :min="0"
        :max="9"
        @click.native="
          (e) => {
            inputFocus(3);
            e.target.selectionStart = e.target.value.length;
            e.target.selectionEnd = e.target.value.length;
          }
        "
        :size="controlSize"
        v-model="values[3]"
        @keydown.native="
          (e) => {
            keydown(e, 3);
          }
        "
        @input.native="(e) => changeValue(e, 3)"
        :style="{
          'border-right': '0px',
          color: value < 0 ? 'red !important' : '#000 !important',
        }"
        :disabled="thisDisabled"
        :readOnly="true"
        ref="EMoney3"
        @focus="
          (e) => {
            inputFocus(3);
          }
        "
        @blur="inputFocus(null)"
      />
      <span v-if="!thisDisabled && thisFocus == 3"></span>
    </div>
    <div class="input">
      <a-input
        :min="0"
        :max="9"
        @click.native="
          (e) => {
            inputFocus(4);
            e.target.selectionStart = e.target.value.length;
            e.target.selectionEnd = e.target.value.length;
          }
        "
        :style="{
          'border-left': '1px solid blue',
          width: '13px',
          color: value < 0 ? 'red !important' : '#000 !important',
        }"
        :size="controlSize"
        v-model="values[4]"
        @keydown.native="
          (e) => {
            keydown(e, 4);
          }
        "
        @input.native="(e) => changeValue(e, 4)"
        :disabled="thisDisabled"
        :readOnly="true"
        ref="EMoney4"
        @focus="
          (e) => {
            inputFocus(4);
          }
        "
        @blur="inputFocus(null)"
      />
      <span v-if="!thisDisabled && thisFocus == 4"></span>
    </div>
    <div class="input">
      <a-input
        :min="0"
        :max="9"
        @click.native="
          (e) => {
            inputFocus(5);
            e.target.selectionStart = e.target.value.length;
            e.target.selectionEnd = e.target.value.length;
          }
        "
        :size="controlSize"
        v-model="values[5]"
        @keydown.native="
          (e) => {
            keydown(e, 5);
          }
        "
        @input.native="(e) => changeValue(e, 5)"
        :disabled="thisDisabled"
        :readOnly="true"
        ref="EMoney5"
        :style="{ color: value < 0 ? 'red !important' : '#000 !important' }"
        @focus="
          (e) => {
            inputFocus(5);
          }
        "
        @blur="inputFocus(null)"
      />
      <span v-if="!thisDisabled && thisFocus == 5"></span>
    </div>
    <div class="input">
      <a-input
        :min="0"
        :max="9"
        @click.native="
          (e) => {
            inputFocus(6);
            e.target.selectionStart = e.target.value.length;
            e.target.selectionEnd = e.target.value.length;
          }
        "
        :size="controlSize"
        v-model="values[6]"
        :style="{
          'border-right': '0px',
          color: value < 0 ? 'red !important' : '#000 !important',
        }"
        @keydown.native="
          (e) => {
            keydown(e, 6);
          }
        "
        @input.native="(e) => changeValue(e, 6)"
        :disabled="thisDisabled"
        :readOnly="true"
        ref="EMoney6"
        @focus="
          (e) => {
            inputFocus(6);
          }
        "
        @blur="inputFocus(null)"
      />
      <span v-if="!thisDisabled && thisFocus == 6"></span>
    </div>
    <div class="input">
      <a-input
        :min="0"
        :max="9"
        @click.native="
          (e) => {
            inputFocus(7);
            e.target.selectionStart = e.target.value.length;
            e.target.selectionEnd = e.target.value.length;
          }
        "
        :style="{
          'border-left': '1px solid blue',
          width: '13px',
          color: value < 0 ? 'red !important' : '#000 !important',
        }"
        :size="controlSize"
        v-model="values[7]"
        @keydown.native="
          (e) => {
            keydown(e, 7);
          }
        "
        @input.native="(e) => changeValue(e, 7)"
        :disabled="thisDisabled"
        :readOnly="true"
        ref="EMoney7"
        @focus="
          (e) => {
            inputFocus(7);
          }
        "
        @blur="inputFocus(null)"
      />
      <span v-if="!thisDisabled && thisFocus == 7"></span>
    </div>
    <div class="input">
      <a-input
        :min="0"
        :max="9"
        @click.native="
          (e) => {
            inputFocus(8);
            e.target.selectionStart = e.target.value.length;
            e.target.selectionEnd = e.target.value.length;
          }
        "
        :size="controlSize"
        v-model="values[8]"
        @keydown.native="
          (e) => {
            keydown(e, 8);
          }
        "
        @input.native="(e) => changeValue(e, 8)"
        :disabled="thisDisabled"
        :readOnly="true"
        ref="EMoney8"
        :style="{ color: value < 0 ? 'red !important' : '#000 !important' }"
        @focus="
          (e) => {
            inputFocus(8);
          }
        "
        @blur="inputFocus(null)"
      />
      <span v-if="!thisDisabled && thisFocus == 8"></span>
    </div>
    <div class="input">
      <a-input
        :min="0"
        :max="9"
        @click.native="
          (e) => {
            inputFocus(9);
            e.target.selectionStart = e.target.value.length;
            e.target.selectionEnd = e.target.value.length;
          }
        "
        :size="controlSize"
        v-model="values[9]"
        @keydown.native="
          (e) => {
            keydown(e, 9);
          }
        "
        @input.native="(e) => changeValue(e, 9)"
        :style="{
          'border-right': '0px',
          color: value < 0 ? 'red !important' : '#000 !important',
        }"
        :disabled="thisDisabled"
        :readOnly="true"
        ref="EMoney9"
        @focus="
          (e) => {
            inputFocus(9);
          }
        "
        @blur="inputFocus(null)"
      />
      <span v-if="!thisDisabled && thisFocus == 9"></span>
    </div>
    <div class="input">
      <a-input
        :min="0"
        :max="9"
        @click.native="
          (e) => {
            inputFocus(10);
            e.target.selectionStart = e.target.value.length;
            e.target.selectionEnd = e.target.value.length;
          }
        "
        :style="{
          'border-left': '1px solid red',
          width: '13px',
          color: value < 0 ? 'red !important' : '#000 !important',
        }"
        :size="controlSize"
        v-model="values[10]"
        @keydown.native="
          (e) => {
            keydown(e, 10);
          }
        "
        @input.native="(e) => changeValue(e, 10)"
        :disabled="thisDisabled"
        :readOnly="true"
        ref="EMoney10"
        @focus="
          (e) => {
            inputFocus(10);
          }
        "
        @blur="inputFocus(null)"
      />
      <span v-if="!thisDisabled && thisFocus == 10"></span>
    </div>
    <div class="input">
      <a-input
        :min="0"
        :max="9"
        @click.native="
          (e) => {
            inputFocus(11);
            e.target.selectionStart = e.target.value.length;
            e.target.selectionEnd = e.target.value.length;
          }
        "
        :size="controlSize"
        v-model="values[11]"
        @keydown.native="
          (e) => {
            keydown(e, 11);
          }
        "
        @input.native="(e) => changeValue(e, 11)"
        :disabled="thisDisabled"
        :readOnly="true"
        ref="EMoney11"
        :style="{ color: value < 0 ? 'red !important' : '#000 !important' }"
        @focus="
          (e) => {
            inputFocus(11);
          }
        "
        @blur="inputFocus(null)"
      />
      <span v-if="!thisDisabled && thisFocus == 11"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EMoney",
  data() {
    return {
      values: {
        0: "",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "0",
        10: "0",
        11: "0",
      },
      activeIndex: null,
      test: "",
      val: "",
      setvalue: this.defaultValue ? this.defaultValueSet() : this.value,
      controlSize: this.$config.defaultControlSize,
      setwidth: this.widthSet(),
      thisFocus: null,
      thisDisabled: false,
      valuesCache: {
        0: "",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
      },

      notFKey: [
        "F1",
        "F2",
        "F3",
        "F4",
        "F5",
        "F6",
        "F7",
        "F8",
        "F9",
        "F10",
        "F11",
        "F12",
      ],
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    defaultValue: {
      type: [Number, String, null],
      default: null,
    },
    value: {
      type: [Number, String, null],
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [String, Number],
      default: "",
    },
    max: {
      type: [String, Number],
    },
    min: {
      type: [String, Number],
    },
    precision: {
      type: [String, Number],
      default: 0,
    },
    step: {
      type: [String, Number],
      default: 1,
    },
    separator: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },
  created() {
    this.thisDisabled = this.disabled;
    this.format(this.value);
  },
  mounted() {
    this.$emit("isScan", false);
  },
  computed: {},
  methods: {
    /**
     * 设置禁用状态
     * @param v
     */
    setDisabled(v) {
      if (this.$util.isType(v) === "Boolean") {
        this.thisDisabled = v;
      } else {
        this.thisDisabled = true;
      }
    },
    /**
     * 切换当前控件的禁用状态
     */
    setDisabledSwitch() {
      this.thisDisabled = !this.thisDisabled;
    },
    // 控制按下的按键只能是数字键和退格
    keydown(e, index) {
      if (this.thisDisabled) {
        return;
      }
      console.log(e);
      if (e.code == "Equal") {
        e.preventDefault();
        e.stopPropagation();
        this.Equal(e);
        return;
      }
      let a;
      if (this.notFKey.indexOf(e.code) == -1) {
        a = e.key.replace(/[^\d]/g, "");
      } else {
        return;
      }
      if (!a && e.code != "Backspace" && e.code != "Delete") {
        e.preventDefault();
      }
      if (a) {
        this.valuesCache[index] = this.values[index];
      }
      if (a != "" || a == 0) {
        this.changeValue({ data: a }, index);
      }
      if (e.code == "Backspace") {
        e.preventDefault();
        e.stopPropagation();
        // 有这个键
        if (index == 11) {
          // 如果为最后的分钱，如果角钱还有值则把分钱给他，没有则清0
          if (
            this.values[11] != null &&
            this.values[11] != "" &&
            this.values[11] != 0
          ) {
            this.changeValue({ data: this.values[11] }, 10, true);
            this.changeValue({ data: "0" }, 11, true);
          } else {
            this.changeValue({ data: "0" }, 10, true);
          }
        } else {
          // 不然前面置空
          if (this.values.hasOwnProperty(index - 1)) {
            // this.values[index - 1] = "";
            this.changeValue("", index - 1, true);
          } else {
            this.changeValue("", index, true);
          }
        }
      }
      if (e.code == "Delete") {
        e.preventDefault();
        e.stopPropagation();
        // 有这个键
        if (index == 11) {
          this.changeValue({ data: "0" }, index, true);
        } else if (index == 10) {
          if (
            this.values[10] != null &&
            this.values[10] != "" &&
            this.values[10] != 0
          ) {
            this.changeValue({ data: this.values[11] }, 10, true);
            this.changeValue({ data: "0" }, 11, true);
          } else {
            this.changeValue({ data: "0" }, 10, true);
          }
        } else if (this.values.hasOwnProperty(index)) {
          // this.values[index - 1] = "";
          this.changeValue("", index, true);
        }
      }
    },
    // 数据改变时
    changeValue(e, index, del) {
      // 符合1-9的数字
      console.log(e.data, index, this.activeIndex);
      var reg = new RegExp("[0-9]");
      if (reg.test(e.data)) {
        // 如果是前几位数，会为后面空格的位置加上0
        for (var i = index; i < 12; i++) {
          if (this.values[i] == "" || this.values[i] == null) {
            this.values[i] = "0";
          }
        }
        // 获取最新输入的数字
        console.log(e.data, index);
        var num = e.data[e.data.length - 1];

        // // 获取当前焦点下标
        // if (this.activeIndex != index) {
        //   // 如果第一次为下标，则改变自身
        //   this.activeIndex = index;
        // } else {
        //   this.sort(index);
        // }

        if (
          (this.valuesCache[index] != "" && this.valuesCache[index] != null) ||
          this.valuesCache[index] == 0
        ) {
          // 不然执行向前挤的方法

          this.sort(index);
        }

        // 第一数无方法,特殊处理
        if (index == 0) {
          this.values[index] = num;
          this.setvalue = this.dataOk();
          return;
        }
        // 赋予自身是最新输入的数字
        this.values[index] = num;

        // 如果是毛，移动到分
        if (index == 10 && !del) {
          this.thisFocus = 11;
          this.$refs["EMoney11"].focus();
        }
      } else if (e.data == null) {
        console.log(index);
        // 如果为删除
        if (index < 10) {
          var value = this.getData();
          // 处理字符串为去除所选位置的字符，并最前面加一位
          value = value.slice(0, index) + value.slice(index + 1);
          value = " " + value;
          for (var o = 0; o < 10; o++) {
            if (value[o] == " ") {
              this.values[o] = "";
            } else {
              this.values[o] = value[o];
            }
          }
          this.activeIndex = null;
          // this.activeIndex = index;
        }
        if (index == 10 && !del) {
          this.thisFocus = 11;
          this.$refs["EMoney11"].focus();
        }
      } else {
        try {
          e.preventDefault();
        } catch (err) {
          err;
        }
      }
      this.setvalue = this.dataOk();
    },
    // 向前挤的方法
    sort(index) {
      var _this = this;
      // 只处理整数
      if (index < 10) {
        integer(index);
      }

      function integer(i) {
        // 第一位不执行
        if (i == 0) {
          return;
        }
        var value = _this.getData();
        // value会有延迟，也就是说input事件会带入之前的值,比如之前是3,后面输入4,这里获得就是34,所以往前减一位赋值
        value = value.slice(1);
        for (var o = 0; o < 10; o++) {
          _this.values[o] = value[o];
        }
      }
    },
    getData() {
      var valueStr = "";
      for (var i = 0; i < 10; i++) {
        if (this.values[i] == "" || this.values[i] == null) {
          if (i <= 9) {
            valueStr += " ";
          } else {
            valueStr += "0";
          }
        } else {
          valueStr += this.values[i].toString();
        }
      }
      return valueStr;
    },
    format(value) {
      if (value == "" || value == null) {
        if (value === 0) {
          this.values = {
            0: "",
            1: "",
            2: "",
            3: "",
            4: "",
            5: "",
            6: "",
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
          };
        }
        return;
      }
      value = value.toString();
      var str = value.split(".");
      if (str[1]) {
        this.values[10] = str[1][0];
        this.values[11] = str[1][1] ? str[1][1] : 0;
      } else {
        this.values[10] = 0;
        this.values[11] = 0;
      }
      if (str[0]) {
        var length = str[0].length - 1;
        for (var i = 9; i >= 0; i--) {
          if (str[0][length] != "-") {
            this.values[i] = str[0][length];
          }
          length = length - 1;
        }
      }
    },
    dataOk() {
      var value = this.values;
      var data = "";
      for (var i = 0; i < 10; i++) {
        if (value[i] == null || value[i] == "") {
          data += "0";
        } else {
          data += value[i];
        }
      }
      var num1 = value[10] == null || value[10] == "" ? "0" : value[10];
      var num2 = value[11] == null || value[11] == "" ? "0" : value[11];
      data = data + "." + num1 + num2;
      data = data.trim();
      data = Number(data);
      if (this.setvalue < 0) {
        data = -data;
      }
      return data;
    },
    blur() {
      this.$emit("blur", this.setvalue);
      console.log(this.decorator);
    },
    focus() {
      this.$emit("focus", this.setvalue);
    },
    defaultValueSet() {
      if (this.id != "") {
        this.$emit("clearOther", this.id);
      }
      this.$emit("change", this.defaultValue);
      return this.defaultValue;
    },
    widthSet() {
      if (this.width == "") {
        this.setwidth = "";
        return "";
      }
      if (typeof this.width == "number") {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      if (this.width.indexOf("%") == -1 && this.width.indexOf("px") == -1) {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      this.setwidth = "width:" + this.width;
      return "width:" + this.width;
    },
    tableFocus(e) {
      console.log("EMoney9");
      // if (this.thisFocus == null) {
      this.$refs.EMoney9.focus();
      e.stopPropagation();
      // }

      // if (this.thisFocus == null) {
      //   this.$refs.EMoney9.focus();
      //   this.thisFocus = 9;
      // } else {
      //   e.preventDefault();
      // }
    },
    tableBlur() {
      for (var key in this.$refs) {
        this.$refs[key].blur();
      }
    },
    inputFocus(v) {
      this.thisFocus = v;
    },
    keyDownRight(e) {
      var thisFocus = this.thisFocus + 1;
      if (thisFocus >= 10) {
        if (this.$refs["EMoney" + thisFocus]) {
          e.stopPropagation();
          this.thisFocus = thisFocus;
          this.$refs["EMoney" + thisFocus].focus();
        } else {
          this.tableBlur();
        }
        return;
      }
      // if (thisFocus >= 11) {
      //   if (this.$refs["EMoney" + thisFocus]) {
      //     e.stopPropagation();
      //     this.thisFocus = thisFocus;
      //     this.$refs["EMoney" + thisFocus].focus();
      //   }
      //   return;
      // }
      if (
        this.$refs["EMoney" + thisFocus] &&
        this.$refs["EMoney" + thisFocus].value != "" &&
        this.$refs["EMoney" + thisFocus].value != null
      ) {
        e.stopPropagation();
        this.thisFocus = thisFocus;
        this.$refs["EMoney" + thisFocus].focus();
      } else {
        if (
          this.$refs["EMoney" + thisFocus] &&
          this.$refs["EMoney" + thisFocus].value === 0
        ) {
          console.log("in");
          e.stopPropagation();
          this.thisFocus = thisFocus;
          this.$refs["EMoney" + thisFocus].focus();
        } else {
          console.log("out");
          this.tableBlur();
        }
      }
    },
    keyDownLeft(e) {
      var thisFocus = this.thisFocus - 1;
      console.log(this.$refs["EMoney" + thisFocus].value);
      if (thisFocus >= 9) {
        if (this.$refs["EMoney" + thisFocus]) {
          e.stopPropagation();
          this.thisFocus = thisFocus;
          this.$refs["EMoney" + thisFocus].focus();
        } else {
          this.tableBlur();
        }
        return;
      }
      if (
        this.$refs["EMoney" + thisFocus] &&
        this.$refs["EMoney" + thisFocus].value != "" &&
        this.$refs["EMoney" + thisFocus].value != null
      ) {
        // 查看向左有没有值
        e.stopPropagation();
        this.thisFocus = thisFocus;
        this.$refs["EMoney" + thisFocus].focus();
      } else {
        // 防范是否有0
        if (
          this.$refs["EMoney" + thisFocus] &&
          this.$refs["EMoney" + thisFocus].value === 0
        ) {
          e.stopPropagation();
          this.thisFocus = thisFocus;
          this.$refs["EMoney" + thisFocus].focus();
        } else {
          if (
            this.$refs["EMoney" + this.thisFocus] &&
            this.$refs["EMoney" + this.thisFocus].value != "" &&
            this.$refs["EMoney" + this.thisFocus].value != null
          ) {
            e.stopPropagation();
            this.thisFocus = thisFocus;
            this.$refs["EMoney" + thisFocus].focus();
          } else {
            if (
              this.$refs["EMoney" + this.thisFocus] &&
              this.$refs["EMoney" + this.thisFocus].value === 0
            ) {
              e.stopPropagation();
              this.thisFocus = thisFocus;
              this.$refs["EMoney" + thisFocus].focus();
            } else {
              this.tableBlur();
            }
          }
        }
      }
    },
    keyDownDown(e) {
      this.tableBlur();
    },
    keyDownUp(e) {
      this.tableBlur();
    },
    enter(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit("enter", e);
    },
    SwitchScore(e) {
      e.preventDefault();
      this.thisFocus = 10;
      this.$refs["EMoney10"].focus();
    },
    clearMoney() {
      this.setvalue = 0;
      this.activeIndex = null;
    },
    Equal(e) {
      // e.preventDefault();
      // e.stopPropagation();
      if (e.shiftKey == false) {
        this.$emit("moneyEqual", e);
      }
    },
    setMoney(v) {
      this.setvalue = v;
      this.format(v);
    },
    transformjia(e) {
      e.stopPropagation();
      e.preventDefault();
      this.setvalue = Math.abs(this.value);
      this.format(this.setvalue);
    },
    transformjian(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.value > 0) {
        this.setvalue = -this.value;
        this.format(this.setvalue);
      }
    },
  },
  watch: {
    setvalue(value) {
      this.$emit("change", value);
      if (this.id != "") {
        this.$emit("clearOther", this.id);
      }
      var noValue = true;
      for (var i = 0; i < this.values.length; i++) {
        if (this.values != "") {
          noValue = false;
        }
      }
      this.$emit("isScan", noValue);
    },
    width() {
      this.widthSet();
    },
    disabled(v) {
      this.thisDisabled = v;
    },
    value(values) {
      this.setvalue = values;
      this.format(values);
    },
    "values.0"(v, o) {
      this.valuesCache[0] = o;
    },
    "values.1"(v, o) {
      this.valuesCache[1] = o;
    },
    "values.2"(v, o) {
      this.valuesCache[2] = o;
    },
    "values.3"(v, o) {
      this.valuesCache[3] = o;
    },
    "values.4"(v, o) {
      this.valuesCache[4] = o;
    },
    "values.5"(v, o) {
      this.valuesCache[5] = o;
    },
    "values.6"(v, o) {
      this.valuesCache[6] = o;
    },
    "values.7"(v, o) {
      this.valuesCache[7] = o;
    },
    "values.8"(v, o) {
      this.valuesCache[8] = o;
    },
    "values.9"(v, o) {
      this.valuesCache[9] = o;
    },
    "values.10"(v, o) {
      this.valuesCache[10] = o;
    },
    "values.11"(v, o) {
      this.valuesCache[11] = o;
    }
    // values: {
    //   deep: true,
    //   handler: function(value, oldv) {
    //     debugger
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
::v-deep input[type="text"] {
  text-align: center !important;
  padding: 0px;
  border-radius: 0px;
  width: 12px;
  border-left: 0px;
  float: left;
  border-color: #008b8b;
}
.input {
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  position: relative;

  span {
    height: 1px;
    width: 7px;
    position: absolute;
    // text-decoration: blink;
    animation: blink 0.5s infinite steps(1, start);
    bottom: 3px;
  }
}

@keyframes blink {
  0%,
  100% {
    background: #000;
  }
  50% {
    background: transparent;
  }
}
</style>
