<template>
  <a-row
    v-if="title"
    v-show="thisValue"
    type="flex"
    justify="center"
    :class="fromSizeGetFormBlockTitle"
    class="form-block-title"
  >
    <a-col :span="24">
      <div class="form-head-title">
        <div class="form-head-title__center">
          <e-icon
            :type="thisLeftIconType"
            class="form-head-icon eIcon_defalut_color"
          />
          <span :title="title">{{ title }}</span
          ><span style="color: red;">{{ tipInfo }}</span
          ><span class="form-head-leftTitleExpend"
            ><slot name="leftTitleExpend"></slot
          ></span>
        </div>
      </div>
      <div
        v-show="showRightIcon"
        class="form-head-rightIcon"
        @click="clickRightIcon"
      >
        <e-icon
          :type="headLeftIconUp ? 'up' : 'down'"
          class="form-head-icon eIcon_defalut_color"
        />
      </div>
      <div v-if="zoom" class="form-head-rightIcon" @click="clickZoom">
        <e-icon
          :type="isZoom ? 'fullscreen-exit' : 'fullscreen'"
          class="form-head-icon eIcon_defalut_color"
        />
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
export default {
  mixins: [pageControls],
  name: "EHeadTitle",
  data() {
    return {
      headLeftIconUp: true,
      thisValue: this.value,
      isZoom: false,
      thisLeftIconType: null
    };
  },
  props: {
    value: {
      type: [String, Boolean],
      default: true,
      validator: function(value) {
        // 这个值必须匹配下列字符串中的一个
        if (Object.prototype.toString.call(value) === "[object Boolean]") {
          return true;
        }
        return (
          ["true", "false", "True", "False", "TRUE", "FALSE"].indexOf(value) !==
          -1
        );
      }
    },
    id: {
      type: [Number, String],
      default: 0
    },
    title: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    },
    tipInfo: {
      // 额外的提示信息
      type: String,
      default: ""
    },
    leftIconType: {
      // 左侧图标类型
      type: String,
      default: null
    },
    rightIcon: {
      type: [String, Boolean],
      default: false,
      validator: function(value) {
        // 这个值必须匹配下列字符串中的一个
        if (Object.prototype.toString.call(value) === "[object Boolean]") {
          return true;
        }
        return (
          ["true", "false", "True", "False", "TRUE", "FALSE"].indexOf(value) !==
          -1
        );
      }
    },
    zoom: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showRightIcon() {
      if (this.$util.isType(this.rightIcon) === "Boolean") {
        return this.rightIcon;
      } else {
        return this.$util.strIsTrue(this.rightIcon);
      }
    },
    fromSizeGetFormBlockTitle() {
      if (this.size && this.size === "small") {
        return ["form-block-title_small"];
      } else if (this.size && this.size === "large") {
        return ["form-block-title_large"];
      }
      return ["form-block-title_default"];
    }
  },
  watch: {
    value: function(n) {
      if (this.$util.isType(n) === "Boolean") {
        this.thisValue = n;
      } else {
        this.thisValue = this.$util.strIsTrue(n);
      }
    }
  },
  created: function() {
    if (this.leftIconType) {
      this.thisLeftIconType = this.leftIconType;
    } else {
      if (this.isNewTheme) {
        this.thisLeftIconType = "icon-ego-menu";
      } else {
        this.thisLeftIconType = "icon-zfx";
      }
    }
  },
  methods: {
    /**
     * 点击右侧
     */
    clickRightIcon() {
      this.headLeftIconUp = !this.headLeftIconUp;
      this.$emit("clickRightIcon", this.id);
    },
    /**
     * 点击最大化
     */
    clickZoom() {
      this.isZoom = !this.isZoom;
      this.$emit("zoom", this.id, this.isZoom);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../assets/style/base/components/e-head-title.less";
</style>
