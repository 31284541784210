<template>
  <div class="e_zjgs" v-show="visible" :style="setwidth">
    <a-tooltip :title="setvalue" arrow-point-at-center>
      <a-input
        @click="openModal"
        placeholder="请点击输入公式"
        v-model="setvalue"
        :size="controlSize"
        readOnly
        :disabled="thisDisabled"
      >
        <e-icon slot="prefix" type="plus" title="请点击输入公式" :disabled="thisDisabled"/>
        <a-icon
          v-if="setvalue != ''"
          slot="suffix"
          type="close-circle"
          @click.stop="handleEmpty"
          title="清空"
        />
      </a-input>
    </a-tooltip>
    <EFsjeFormulaInput
      :ref="'selectModal' + random"
      @ok="handleOK"
      v-model="setvalue"
      :buttonData="buttonData"
      :option="option"
      @filterSave="filterSaveOK"
    ></EFsjeFormulaInput>
  </div>
</template>

<script>
import EFsjeFormulaInput from "./EFsjeFormulaInput.vue";
import { getOption } from "./DataSet.js";
export default {
  name: "EFsjeFormula",
  data() {
    return {
      setvalue: this.value,
      controlSize: this.$config.defaultControlSize,
      setwidth: this.widthSet(),
      buttonData: [],
      random: new Date().getTime(),
      thisDisabled: false
    };
  },
  components: {
    EFsjeFormulaInput
  },
  model: {
    prop: "value",
    event: "change",
    type: Array
  },
  props: {
    value: {
      type: [String, Array],
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: ""
    },
    option: {
      type: null
    }
  },
  created() {
    this.thisDisabled = this.disabled;
    this.buttonData = getOption();
  },
  computed: {},
  methods: {
    /**
     * 设置禁用状态
     * @param v
     */
    setDisabled(v) {
      if(this.$util.isType(v) === "Boolean") {
        this.thisDisabled = v;
      }else {
        this.thisDisabled = true;
      }
    },
    /**
     * 切换当前控件的禁用状态
     */
    setDisabledSwitch() {
      this.thisDisabled = !this.thisDisabled;
    },
    openModal() {
      if (!this.readOnly && !this.thisDisabled) {
        this.$refs["selectModal" + this.random].postData(this.setvalue);
        this.$refs["selectModal" + this.random].show();
      }
    },
    handleOK(rows) {
      this.setvalue = rows;
      this.$emit("change", rows);
    },
    handleEmpty() {
      this.handleOK(null);
    },
    widthSet() {
      if (this.width == "") {
        this.setwidth = "";
        return "";
      }
      if (typeof this.width == "number") {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      if (this.width.indexOf("%") == -1 && this.width.indexOf("px") == -1) {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      this.setwidth = "width:" + this.width;
      return "width:" + this.width;
    },
    filterSaveOK(value) {
      this.$emit("filterSave", value);
    }
  },
  watch: {
    width() {
      this.widthSet();
    },
    disabled(v) {
      this.thisDisabled = v;
    },
  }
};
</script>
<style scoped lang="less">
@import "../../../assets/style/base/controls/e-pop-up-select-input.less";
</style>
