<!-- 查看表单 -->
<template>
  <div>
    <!-- 授权操作按钮 -->
    <div v-if="showToolbar" class="form-headButton">
      <e-toolbar
        :buttons="buttons"
        :operationDisabled="operationDisabled"
        :size="$config.defaultControlSize"
        @click="onButtonClick"
        ref="eToolbar"
      ></e-toolbar>
    </div>
    <!-- 表单区域 -->
    <div
      :class="getTabFormContextClass"
      @scroll="formScroll"
      :style="isZoom ? { 'padding-top': '0' } : {}"
    >
      <a-form
        :form="form"
        :class="showToolbar ? 'form-data' : 'form-data-noHead'"
      >
        <!--:spState="spState"-->
        <e-form
          :form="form"
          :tempId="tempId"
          :dataId="dataId"
          :disable="formDisable"
          :formConfigs="formConfigs"
          :title="title"
          :tableData="mainTableData"
          :editOver="true"
          :mxHhAuto="false"
          :mxOprButtons="mxOprButtons"
          ref="formTemplate"
          @zoom="zoomState => (isZoom = zoomState)"
          @publicUploadChange="publicUploadChange"
        ></e-form>
      </a-form>
    </div>
    <!-- 其他功能区域 -->

    <!-- 审批记录弹出框 -->
    <e-table-modal
      v-model="spjlModalShow"
      :tableConfig="spTableConfig"
      title="审批记录"
      width="900"
      :tableDatas="spTableDatas"
      :loading="spTableSpin"
    ></e-table-modal>
    <!-- 查看审批流程图 -->
    <view-image-modal
      v-model="showWorkFlowImage"
      :src="workflowImageSrc"
      title="流程图"
    ></view-image-modal>
    <!-- 小票打印预览 -->
    <n-p58-print
      v-model="showNpPrint"
      :dataSource="printDetailData"
    ></n-p58-print>
    <systemPrintSet ref="systemPrintSet"></systemPrintSet>
    <!-- 公共上传控件 -->
    <EUpload
      ref="publicUpload"
      @change="publicUploadChangeValue"
      @fileListNumChange="publicUploadNumChange"
      @CanSealFile="CanSealFile"
      v-show="false"
      :auth="
        operaterFile
          ? operaterFile
          : { CanEditFile: true, CanRemoveFile: true, CanSealFile: true }
      "
    />
  </div>
</template>

<script>
import EForm from "@/components/EForm.vue";

import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; // 解析菜单配置的权限字符串方法
import { e1Form } from "@/views/form/mixins/e1Form.js"; // 表单公共方法
import { e1FormToolbarWorkFlow } from "@/views/form/mixins/e1FormToolbarWorkFlow.js"; // e1的审批操作方法
import { e1FormToolbarToAssociated } from "@/views/form/mixins/e1FormToolbarToAssociated.js"; // e1的业务关联表查询操作功能
import { e1FormToolbarSCGL } from "@/views/form/mixins/e1FormToolbarSCGL.js"; // e1的业务-生产管理相关操作功能
import { analyticalPrint } from "@/mixins/analyticalPrint.js"; // 打印数据计算方法
import { npPrint } from "@/views/form/mixins/NpPrint.js"; // 下票打印方法
import { CustomPrintMixins } from "@/views/form/mixins/CustomPrintMixins.js"; // 按钮方法
import { Upload } from "@/views/form/mixins/Upload.js"; // 公共上传mixins

export default {
  mixins: [
    e1Form,
    analyticalMenuAuth,
    e1FormToolbarWorkFlow,
    analyticalPrint,
    npPrint,
    CustomPrintMixins,
    e1FormToolbarToAssociated,
    e1FormToolbarSCGL,
    Upload
  ],
  components: {
    EForm
  },
  name: "DetailForm",
  data() {
    return {
      showForm: false,
      showFormError: false,
      showFormSpin: false,
      formName: null, // 对应的表单名称
      spjlModalShow: false, // 审批记录列表弹出窗

      random: null, // 当前显示的表单tab的key
      dhbm: null, // 当前开页面关联权限参数的id
      dataId: 0, //对应的表单id
      bm: null,
      clzt: null, // 当前表单的处理状态
      bdbh: null, // 保存后的表单编号
      detailFiledName: null, // 如果有冲销的情况下用于指定显示title的字段，或者用于使用tableName和bdbh的获取表单模板的情况下指定bdbh值对应tableName中的字段名
      tableName: null, // 表单对应的表名

      buttons: [], // 当前用户可用的操作按钮
      operationDisabled: true, // 操作按钮默认不可用
      formDisable: true, // 表单不可编辑
      mxOprButtons: [], // 明细表可做操作

      saveTempID: null, // 特殊表单，有时会指定保存使用的模板id
      is_temp_changed: false,// 特殊表单用于表示该表单模板变跟的状态
      isZoom: false, // 明细表进入最大化状态
      printConfig: null
    };
  },
  created() {
    console.log("路由属性：" + JSON.stringify(this.$route.query)); // 从菜单直接带过来
    console.log("DetailForm创建---------------------->");
    this.tempId = this.$route.query.templetId;
    this.dataId = this.$route.query.dataId;
    this.dhbm = this.$route.query.dhbm;
    this.bm = this.$route.query.bm;
    this.clzt = this.$route.query.clzt;
    this.random = this.$route.query.random;
    this.detailFiledName = this.$route.query.detailFiledName;
    this.bdbh = this.$route.query.dataCode;
    this.tableName = this.$route.query.tableName;
  },
  mounted() {
    console.log("请求参数：" + JSON.stringify(this.$route.query));
    //console.log("创建表单："+this.$route.params.dhbm);
    this.loading();
    if (this.tempId && this.dataId && this.bm) {
      //通过query传参
      this.formName = "detail-" + this.dataId;
      this.form = this.$form.createForm(this, { name: this.formName });
      this.getFormTemplate();
    } else if (this.bdbh && this.tableName) {
      // 通过表单编号和表名查询表单
      this.formName = "detail-" + this.bdbh;
      this.form = this.$form.createForm(this, { name: this.formName });
      this.getFormTemplate();
    } else {
      // 页面内按钮点开、是通过query传参
      this.alertError("错误的用户配置");
      this.loadingError();
      return;
    }
  },
  methods: {
    /**
     * 获取表单模板
     */
    getFormTemplate() {
      let that = this;
      let param = {
        dmbh: this.dhbm,
        id: this.dataId,
        bdbh: this.bdbh,
        tableName: this.tableName
      };
      if (this.bdbh && this.tableName && this.detailFiledName) {
        param.keyField = this.detailFiledName;
      }
      this.getForm(
        "GetTemplateDetail",
        this.tempId,
        param,
        function(data) {
          that.workflowTemplateInstance =
            data.template[0].WorkflowTemplateInstance; // 流程图id
          that.buttons = data.buttons;
          that.printConfig = data.template[0].MBNR;
          that.tempId = data.template[0].ID;
          if (data.dataId) {
            that.dataId = data.dataId;
            if (data.bm) {
              that.bm = data.bm;
            }
          }
        },
        function() {
          if (that.dataId)
            /**
             * et_name:1000，表示不带用户名，查询表单的全局状态
             */
            that.getFormData(that.tempId, that.dataId, that.bm, 1000, function(
              data
            ) {
              if (data.workFlow && data.workFlow.isWorkFlow) {
                that.updateEToolbarButtonDisplay("tsbWorkFlowRecord", true);
                that.updateEToolbarButtonDisplay(
                  ["tsbProduceRouting", "tsbBOM"],
                  false
                );
                that.spState = that.getStateName(data.workFlow.workFlowState);
              }
              //if (!that.isFormWorkflowEnd) { // 不需要控制流程结束后显示可见范围内的字段
                that.checkMainVisScopeTakeEffect(that.formConfigs.formRows);
                that.checkMxVisScopeTakeEffect(that.formConfigs);
              //}
              // 调用扩展功能，数据加载前调用的回调函数。
              if (that.thisFromEvents && that.thisFromEvents.beforeRenderData) {
                that.thisFromEvents.beforeRenderData(that); //, that.mainTableData, that.allChildrenTablesDatas
              }
              that.loadingOk();
            });
          else that.loadingError();
        }
      );
    },
    /**
     * 点击保存按钮
     */
    tsbSave(button) {
      if (!this.dataId) {
        this.alertWarning("表单ID不存在");
        return;
      }
      let that = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          //console.log("数据信息：" + JSON.stringify(values));
          let saveParam = that.checkOperationAuth(
            that.buttons,
            "tsbSave",
            () => {
              that.alertWarning("您没有保存表单的权限");
            }
          );
          if (saveParam) {
            that.startProcessing(button);
            let params = {
              action: "SaveFormData",
              data: JSON.stringify(values), // 主表数据
              children: that.getMxDataJSON(), // 子表数据
              tb_name: that.mainTableName, // 主表名称 从DHCS中获取
              mxb_name: that.mxTableName.toString(), // 明细表名称
              bm: saveParam.param.bm, // 类库名称（SYSTEM）、从GJLCS中获取 {tsbNew,SYSTEM,1,980}
              t_id: that.tempId, // 模板id  从DHCS中获取
              et_name: saveParam.param.et_name, //  表示操作类型  添加为0，修改为1，删除为2 等，从GJLCS中获取 {tsbNew,SYSTEM,1,980}
              m_id: 0, // 保存时没用到 传入0
              d_id: that.dataId, // 插入之后返回的主键id
              randomKey: that.$store.getters.getRandomKey
            };
            that.post("Form.ashx", params, {
              success: function(req) {
                console.log("请求结果", req);
                that.alertSuccess("保存成功");
                that.finishedProcessing(button);
              },
              error: function(er) {
                console.error("请求失败", er);
                that.alertError(er.message);
                that.finishedProcessing(button);
              }
            });
          }
        } else {
          //console.log( JSON.stringify(err));
          let errMsg = that.getDecoratorErrInfo(err);
          that.tipWarning(errMsg);
        }
      });
    },
    /**
     * 点击“冲销”按钮
     */
    tsbChongXiao(button) {
      console.log("冲销表单", this.mainTableData._BDMC);
      let bm = this.mainTableData._BDBH;
      if (bm.indexOf("_CX") == 0) {
        this.alertWarning("冲销记录不能做冲销操作");
        return;
      }
      this.startProcessing(button);
      this.openTab({
        title: "冲销-" + this.getOpenTabName(this.mainTableData),
        path: "/ChargeAgainstForm",
        query: {
          dhbm: this.dhbm,
          templetId: this.tempId, // 表单模板ID
          dataId: this.dataId, // 表单数据id
          bm: button.param.bm, // 表单对应BM表
          etName: button.param.et_name,
          event: button.param.event
        }
      });
      this.finishedProcessing(button);
    },
    /**
     * 获取本条记录在所有查询结果中对应位置的数据（用于首张，上一张，下一张，末张）
     * @param button
     * @param callback
     */
    getNearForm(button, callback) {
      let that = this;
      let params = {
        action: "GetTemplateDataToOpr",
        tb_name: this.mainTableName, //主表名
        t_id: this.tempId,
        d_id: this.dataId,
        bm: button.param.bm,
        et_name: button.param.et_name,
        randomKey: this.$store.getters.getRandomKey
      };
      this.get("Form.ashx", params, {
        success: function(req) {
          if (req.data && req.data.mainTableData) {
            callback(req.data);
          } else {
            that.tipWarning("请求失败," + req.message);
            that.finishedProcessing(button);
          }
        },
        error: function(err) {
          that.finishedProcessing(button);
          that.alertError("请求失败," + err.message);
        }
      });
    },
    /**
     *从表单中获取一个字段值作为新打开的tab的标签标题
     *
     */
    getOpenTabName(mainTableData) {
      let title = "";
      if (mainTableData) {
        if (this.detailFiledName) {
          title = mainTableData[this.detailFiledName];
        } else {
          title = mainTableData._BDMC
            ? mainTableData._BDMC
            : mainTableData._BDBH
            ? mainTableData._BDBH
            : "";
        }
      }
      return title;
    },
    /**
     * 点击首张按钮 templetId=133&dataId=26845&bm=SYSTEM&clzt=未提交
     * @param button
     */
    tsbFirstPage(button) {
      this.startProcessing(button);
      let that = this;
      this.getNearForm(button, function(data) {
        that.openOtherApprovalForm(
          that.getOpenTabName(data.mainTableData),
          data.mainTableData.ID
        );
        that.finishedProcessing(button);
      });
    },
    /**
     * 点击上一张按钮
     * @param button
     */
    tsbLastPage(button) {
      this.startProcessing(button);
      let that = this;
      this.getNearForm(button, function(data) {
        that.openOtherApprovalForm(
          that.getOpenTabName(data.mainTableData),
          data.mainTableData.ID
        );
        that.finishedProcessing(button);
      });
    },
    /**
     * 点击下一张按钮
     * @param button
     */
    tsbNextPage(button) {
      this.startProcessing(button);
      let that = this;
      this.getNearForm(button, function(data) {
        that.openOtherApprovalForm(
          that.getOpenTabName(data.mainTableData),
          data.mainTableData.ID
        );
        that.finishedProcessing(button);
      });
    },
    /**
     * 点击最后一张按钮
     * @param button
     */
    tsbEndPage(button) {
      this.startProcessing(button);
      let that = this;
      this.getNearForm(button, function(data) {
        that.openOtherApprovalForm(
          that.getOpenTabName(data.mainTableData),
          data.mainTableData.ID
        );
        that.finishedProcessing(button);
      });
    },
    /**
     * 点击“删除”按钮，删除当前表单
     * @param button
     * @param other
     */
    tsbDelete(button, other) {
      //console.log("删除", button);
      if (this.dataId) {
        if (other && other.prioriCondition) {
          // 满足前置条件还需要确认是否操作
          this.$refs.eToolbar.showConfirm(button.tsbName);
        } else {
          let that = this;
          let params = {
            action: "DeleteData",
            tb_name: that.mainTableName, //主表名
            mxb_name: that.mxTableName.toString(), // 明细表名称
            bm: button.param.bm,
            t_id: this.tempId, // 模板id  从DHCS中获取
            d_id: this.dataId, // 插入之后返回的主键id
            randomKey: this.$store.getters.getRandomKey
          };
          this.startProcessing(button);
          this.post("Form.ashx", params, {
            success: function() {
              //console.log("请求结果", req);
              // 把对应删除的数据从数组中移除
              that.finishedProcessing(button);
              that.closeTab(that.random);
              that.alertSuccess("删除成功");
            },
            error: function(err) {
              that.finishedProcessing(button);
              that.alertError(err.message);
            }
          });
        }
      }
    },
    /**
     * 打开一张新的审批表单
     * @param title
     * @param dataid
     */
    openOtherApprovalForm(title, dataid) {
      this.openTab({
        title: "查看-" + title,
        path: "/DetailForm",
        query: {
          dhbm: this.dhbm,
          templetId: this.tempId, // 表单模板ID
          dataId: dataid, // 表单数据id
          bm: this.bm, // 表单对应BM表
          detailFiledName: this.detailFiledName
        }
      });
      this.closeTab(this.random);
    },
    /**
     * 弹窗查看关联单据
     */
    tabRelated(button) {
      console.log("查看关联单据",button);
      if (button.param && button.param.tempId && button.param.dataId) {
        if(this.KeepAccountsFormTempIds.includes(button.param.tempId)) {
          this.openTab({
            title: "查看-" + button.param.tempCode,
            path: "/KeepAccountsForm",
            query: {
              dhbm: this.dmbh,
              templetId: button.param.tempId, // 表单模板ID
              dataId: button.param.dataId, // 表单数据id
              detailFiledName: "_BDBH", // 表单用于tab的title的表单字段名
              type: "dateSearchView",
              bm: button.param.bm // 表单对应BM表
            }
          });
        } else { //其他普通表单查看
          let rule = this.checkTextMeetASNGenerateRule(button.param.tempCode);
          if(rule) {
            let title = "查看-" + button.param.tempCode;
            this.openTab({
              title: title,
              path: "/DetailForm",
              query: {
                dataCode: button.param.tempCode, // 表单数据编号
                tableName: rule.tableName // 表单用于tab的title的表单字段名
              }
            });
          } else {
            this.alertError("关联数据表单编码未加入编码格式配置");
          }
        }
      } else {
        this.alertError("未获取到表单关联数据");
      }
    },
    /**
     * 监听表单滚动条，如果滚动做一些操作
     */
    formScroll() {
      this.$refs.formTemplate.filterSelectBlur();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/detail-form.less";
</style>
