/**
 * 支持lodop打印插件使用的方法
 * @type {{data(): *, created(), methods: {lodopInit: lodopPrint.methods.lodopInit, lodopPrintTemplateDesign: lodopPrint.methods.lodopPrintTemplateDesign, lodopRenderingPrint: lodopPrint.methods.lodopRenderingPrint, lodopPrint: lodopPrint.methods.lodopPrint, getPrintFormatValue(*, *): *}}}
 */
export const lodopPrint = {
  data() {
    return {
      LODOPObject: null // 打印插件对象
    };
  },
  created() {},
  methods: {
    /**
     * 打印插件初始化
     */
    lodopInit: function() {
      if (this.LODOPObject) {
        return true;
      }
      // 打印插件对象
      let lodopResult = getLodop(
        document.getElementById("LODOP_OB"),
        document.getElementById("LODOP_EM")
      );
      //console.log("获取到的插件对象", lodopResult);
      if (lodopResult != null) {
        this.LODOPObject = lodopResult.LODOP;
        if (lodopResult.message) {
          // 有错误信息提示
          this.showAlert("error", "错误", lodopResult.message);
          return false;
        }
        // 设置打印控件可用通过打印JOB代码查看打印状态
        this.LODOPObject.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);
        return true;
      }
      return false;
    },
    /**
     * lodop打印模板设计
     */
    lodopPrintTemplateDesign: function() {
      if (this.LODOPObject) {
        this.LODOPObject.PRINT_INIT("新建打印模板");
        this.LODOPObject.PRINT_DESIGN();
      } else {
        this.showAlert(
          "error",
          "错误",
          "打印插件加载失败，请重新启动浏览器或者重新安装打印插件"
        );
      }
    },
    /**
     * 自定义渲染函数式打印
     * @param name 打印的模板名称
     * @param params 打印的数据
     * @param printType 打印方式
     */
    lodopRenderingPrint: function(name, params, printType) {
      if (
        this.LODOPObject &&
        name &&
        this.$util.isType(name) === "String" &&
        params &&
        this.$util.isType(params) === "Array"
      ) {
        if (window.LABEL_PRINT_TEMPLATES) {
          if (window.LABEL_PRINT_TEMPLATES["P_" + name]) {
            let config = window.LABEL_PRINT_TEMPLATES["P_" + name];
            if (config.loopPrintRenderingMethod) {
              let result = config.loopPrintRenderingMethod(
                this,
                this.LODOPObject,
                params
              );
              if (result) {
                let that = this;
                if (printType && printType === "打印预览") {
                  this.LODOPObject.PREVIEW();
                } else {
                  // 直接打印
                  let job = this.LODOPObject.PRINT();
                  setTimeout(function() {
                    let id = that.LODOPObject.GET_VALUE("PRINT_STATUS_ID", job);
                    let stateText = window.LodopPrintState["_" + id];
                    that.$message.info(
                      "打印状态：" + (stateText ? stateText : "正在打印")
                    );
                  }, 500);
                }
              } else {
                this.$message.warning("打印失败，错误的参数");
              }
            }
          }
        }
      }
    },
    /**
     * 调用LODOP插件将数据打印出来,一般用于单个标签打印
     * @param name 打印的模板名称
     * @param param 打印的数据
     */
    lodopPrint: function(name, param) {
      if (
        this.LODOPObject &&
        name &&
        param &&
        this.$util.isType(param) === "Object"
      ) {
        if (window.LABEL_PRINT_TEMPLATES) {
          if (
            name &&
            param &&
            this.$util.isType(name) === "String" &&
            window.LABEL_PRINT_TEMPLATES["P_" + name]
          ) {
            let config = window.LABEL_PRINT_TEMPLATES["P_" + name];
            if (config.printRenderingMethod) {
              // 如果配置了打印模板渲染函数，则直接执行该函数进行打印，否则检测是否配置了打印参数
              config.printRenderingMethod(this, this.LODOPObject, param);
              let job = this.LODOPObject.PRINT();
              let that = this;
              setTimeout(function() {
                let id = that.LODOPObject.GET_VALUE("PRINT_STATUS_ID", job);
                let stateText = window.LodopPrintState["_" + id];
                that.$message.info(
                  "打印状态：" + (stateText ? stateText : "正在打印")
                );
              }, 500);
            } else if (
              config.printInita &&
              config.printContents &&
              this.$util.isType(config.printContents) === "Array" &&
              config.printContents.length > 0
            ) {
              // 使用打印参数进行打印
              let printName = name + "_" + new Date().getTime();
              // 初始化打印界面
              this.LODOPObject.PRINT_INITA(
                config.printInita.x,
                config.printInita.y,
                config.printInita.width,
                config.printInita.height,
                printName
              );
              for (let i = 0; i < config.printContents.length; i++) {
                let contentObj = config.printContents[i];
                if (contentObj.type) {
                  let printContent;
                  if (contentObj.value) {
                    // 固定值
                    printContent = contentObj.value;
                  } else if (
                    contentObj.valueField &&
                    param[contentObj.valueField]
                  ) {
                    // 对应字段值
                    printContent = param[contentObj.valueField];
                  } else if (contentObj.valueFormat) {
                    // 对应字段值按格式组织的字符串
                    printContent = this.getPrintFormatValue(
                      contentObj.valueFormat,
                      param
                    );
                    if (!printContent) continue;
                  } else {
                    continue;
                  }

                  if (contentObj.type === "TEXT") {
                    // 打印文本内容
                    this.LODOPObject.ADD_PRINT_TEXT(
                      contentObj.x,
                      contentObj.y,
                      contentObj.width,
                      contentObj.height,
                      printContent
                    );
                  } else if (contentObj.type === "BARCODE") {
                    // 打印条码
                    let codeType = contentObj.codeType;
                    if (!codeType) {
                      codeType = "QRCode";
                    }
                    this.LODOPObject.ADD_PRINT_BARCODE(
                      contentObj.x,
                      contentObj.y,
                      contentObj.width,
                      contentObj.height,
                      codeType,
                      printContent
                    );
                  }
                }
              }
              if (config.style) {
                if (config.style.fontSize)
                  this.LODOPObject.SET_PRINT_STYLE(
                    "FontSize",
                    config.style.fontSize
                  );
                if (config.style.fontName)
                  this.LODOPObject.SET_PRINT_STYLE(
                    "FontName",
                    config.style.fontName
                  );
              }
              //this.LODOPObject.PREVIEW();
              if (config.printPageSize) {
                // 配置了打印纸张
                this.LODOPObject.SET_PRINT_PAGESIZE(
                  config.printPageSize.intOrient,
                  config.printPageSize.intPageWidth,
                  config.printPageSize.intPageHeight,
                  config.printPageSize.strPageName
                );
              }
              this.LODOPObject.PRINT();
            }
          }
        } else {
          this.$message.warning("未找到对应的打印模板");
        }
      } else if (this.LODOPObject) {
        this.$message.warning("请提供正确的打印参数");
      } else {
        this.$message.warning("打印控件异常，请刷新页面或重新安装打印控件");
      }
    },
    /**
     * 按打印模板中的字符串格式，进行组织
     */
    getPrintFormatValue(format, data) {
      let reg = new RegExp("\\[([^\\]]+)\\]", "ig"); //从字符串中匹配出所有用[]括起来的字段
      let allFields = format.match(reg);
      let value = format;
      if (allFields && allFields.length > 0) {
        // 公式中所有的变量字段
        let fields = allFields.map(function(obj) {
          return obj.slice(0, -1).slice(1);
        });
        for (let j = 0; j < fields.length; j++) {
          value = value.replace(
            allFields[j],
            data[fields[j]] ? data[fields[j]] : ""
          );
        }
      }
      return value;
    }
  }
};
