import { dateFormat } from "@/mixins/dateFormat.js";
/**
 * 支持etable数据显示格式化方法
 * @type {{methods: {formatSex(*): *, formatPercentage(*=): *, formatDate(*=): *}}}
 */
export const ETableDataFormats = {
  mixins: [dateFormat],
  methods: {
    formatSex(cellValue){
      return cellValue === '1' ? '男' : '女'
    }
  }
};