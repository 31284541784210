export const CustomPrintMixins = {
  data() {
    return {};
  },
  watch: {},
  created() {
    //console.log("混入bluePrint的通用方法");
  },
  methods: {
    /**
     * 定制打印按钮
     */
    tsbDZPrint(button, menuIndex) {
      if (
        !this.formConfig ||
        !this.formConfig.rdlName ||
        this.formConfig.rdlName == ""
      ) {
        this.alertError("该表单没有配置关联报表");
        return;
      }
      if (!this.dataId || this.dataId == 0 || this.dataId == "") {
        this.alertError("新建表单时不可进行定制打印");
        return;
      }
      let rdlName;
      if (menuIndex) {
        rdlName = menuIndex.menuValue;
      } else {
        rdlName = this.formConfig.rdlName;
      }
      if (window.REPORTVIEW_OPEN_NEW_TAG) {
        //原rdl服务
        /*var src =
          window.SERVER_REPORT +
          "?rdlName=" +
          rdlName +
          "&dataId=" +
          this.dataId;*/
        //新JasperReports报表服务打开
        /*let src = this.getRootPath() + "/reportView.html?tempId="+this.tempId+"&dataId="+this.dataId+"&reportName="+rdlName;*/
        let src = this.getReportUrl(this.tempId, this.dataId, rdlName);
        window.open(src);
        return;
      }
      // 转义一下斜杆传输
      rdlName = rdlName.replace(/\//g, "!@!");
      //原rdl服务
      //let url = window.SERVER_REPORT + "/RdlReportView/" + this.dataId + "/" + rdlName;
      //新JasperReports报表服务
      //通过reportView.html页面跳转打开，该一页面包含了一些扩展按钮功能
      //let url = this.getRootPath() + "/reportView.html?openType=tab&tempId="+this.tempId+"&dataId="+this.dataId+"&reportName="+rdlName;

      let url = this.getReportUrl(this.tempId, this.dataId, rdlName);
      this.openTab({
        title: "定制打印-" + this.title,
        path: url,
        query: {
          // key: Date.parse(new Date()).toString()
        }
      });
    },
    /**
     * 获取报表访问地址
     * @param tempId
     * @param dataId
     * @param rName
     * @return {string}
     */
    getReportUrl: function(tempId, dataId, rName) {
      //配置的是jasperReport
      if(this.DefaultReportService === "JAR") {
        return this.getRootPath() + "/reportView.html?openType=tab&tempId="+this.tempId+"&dataId="+this.dataId+"&reportName="+rName;
        // 下列代码可以支持点击定制打印，直接跳转到pdf文件打印预览
        /*
        url =url+"&output=pdf";
        let printWindow=window.open(printUrl,"_blank");
        printWindow.print();
        */
      } else { // 其他的都是"RDL"
        return window.SERVER_REPORT + "?rdlName=" + rName + "&dataId=" + this.dataId;
      }
    },
    /**
     * 获取当前项目根路径
     * @return {String}
     */
    getRootPath : function(){
      //获取当前网址，如： http://localhost:8083/uimcardprj/share/meun.jsp
      let curWwwPath=window.document.location.href;
      //获取主机地址之后的目录，如： uimcardprj/share/meun.jsp
      let pathName=window.document.location.pathname;
      let pos=curWwwPath.indexOf(pathName);
      //获取主机地址，如： http://localhost:8083
      let localhostPaht=curWwwPath.substring(0,pos);
      //获取带"/"的项目名，如：/uimcardprj
      let projectName=pathName.substring(0,pathName.substr(1).indexOf('/')+1);
      return localhostPaht + projectName;
    }
}
};
