<template>
  <div>
    <a-modal
      title="关于我们"
      :visible="visible"
      @ok="handleCancel"
      @cancel="handleCancel"
      cancelText="关闭"
      okText="明白"
    >
    </a-modal>
  </div>
</template>
<script>
import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法
export default {
  name: "aboutUsWindow",
  props: {},
  mixins: [requestControls],
  data() {
    return {
      visible: true
    };
  },
  created() {},
  watch: {},
  methods: {
    show() {
      this.visible = true;
    },
    handleCancel() {
      this.handleClear();
    },
    handleClear() {
      this.visible = false;
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/style/base/about-us-window.less";
</style>
