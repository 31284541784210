/**
 * 支持E1系统的审批记录数据列表的配置，及操作方法
 * @type {{data(): *, created(), methods: {doLink(*=): void, doLinkZt(*=): void, doAction(*): void}}}
 */
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求

export const e1ApprovalRecord = {
  mixins: [requestControls],
  data() {
    let that = this;
    return {
      spTableConfig: {
        // 表格样式定义
        rowKey: this.defaultTableKeyName,
        columns: [
          {
            title: "关卡",
            dataIndex: "GKMC",
            width: 150
          },
          {
            title: "审批人",
            dataIndex: "JSRMC",
            width: 100
          },
          {
            title: "处理人",
            dataIndex: "CLRMC",
            width: 100
          },
          {
            title: "结果",
            width: 150,
            dataIndex: "CLJG"
          },
          {
            title: "意见",
            dataIndex: "FKYJ",
            width: 250,
            scopedSlots: {
              customRender: "FKYJ",
              showTitle: true
            }
          },
          {
            title: "处理时间",
            width: 150,
            dataIndex: "CLSJ",
            customRender: function(text) {
              return that.$util.changeTimeFormat(text);
            }
          }
        ]
      },
      spTableDatas: [], // 表格数据
      spTableSpin: false //表格加载状态
    };
  },
  created() {
    //console.log("混入e1Notice的通用方法");
  },
  methods: {
    /**
     * 获取 表格数据的接口方法
     * @param tid
     * @param did
     */
    getSpTableDatas(tid, did) {
      console.log("获取审批记录数据：");
      let that = this;
      this.spTableSpin = true;
      let params = {
        action: "GetNoticesDetail",
        t_id: tid,
        d_id: did,
        randomKey: this.$store.getters.getRandomKey
      };
      this.get("WorkFlow.ashx", params, {
        success: function(req) {
          //console.log(req);
          let dataList = req.data;
          dataList.map(function(data, index) {
            // 给数组对象设置一个主键字段用于表格选择
            return (data[that.spTableConfig.rowKey] = index);
          });
          that.spTableDatas = dataList;
          console.log("获取的数据：", that.spTableDatas);
          that.spTableSpin = false;
        },
        error: function(err) {
          console.log(err);
          that.spTableSpin = false;
          that.alertError("请求失败，获取表数据失败");
        }
      });
    }
  }
};
