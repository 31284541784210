<!-- 列表弹出窗 maxHeight             :centered="true"-->
<template>
    <a-modal
            v-model="visible"
            :bodyStyle="{ maxHeight: maxHeight, maxWidth: maxWidth, paddingBottom: 0}"
            :width="allWidth"
            :wrapClassName="draggable ? 'e-table-modal__body e-table-modal__draggable':'e-table-modal__body'"
            :centered="!draggable"
            :id="thisKey"
    >
        <slot name="title">
            <!-- 表title <a-icon type="ordered-list" class="form-head-icon" /> -->
            <a-row justify="center" class="tableModal-head">
                <a-col :span="24"
                >
                    <vue-draggable-resizable
                            v-if="draggable"
                            :prevent-deactivation="true"
                            class="e-table-head__resizable"
                            :active="true"
                            :draggable="true"
                            :resizable="false"
                            :disable-user-select="true"
                            :z="1000"
                            :grid="[10, 10]"
                            :onDragStart="checkDrag"
                            @dragging="onDrag"
                            @dragstop="onDragstop"
                    ></vue-draggable-resizable>
                    <div class="form-head-title">
                        {{ title }}
                    </div>
                </a-col
                >
            </a-row>
        </slot>
        <div class="table-form-context" v-if="reRender">
            <e-table-content-filter
                ref="screenViewTable"
                @screen="
                  data => {
                    thisTableDatas = data;
                  }
                "
                searchBtnName="搜索"
                :columns="thisColumns"
                :dataSource="thisTableDatas"
                :disabled="tableSpin"
            ></e-table-content-filter>
            <div class="view-table-modal" :class="dPrint ? 'view-table-print':''" :id="'printJS-table-'+ title">
                <a-table
                        :key="key"
                        :size="aTableSize_MiXin"
                        :class="$config.table_stripe ? 'ant-table-stripe':''"
                        bordered
                        :defaultExpandAllRows="isExpandAllRows"
                        :rowKey="rowKey"
                        :dataSource="thisTableDatas"
                        :loading="tableSpin"
                        :columns="thisColumns"
                        :scroll="{ x: tableWidth + 'px', y: tableMaxHeight }"
                        :components="thComponents"
                        :pagination="thisTableDatas.length>10"
                        :rowSelection="thisRowSelection"
                >
                    <template v-for="col in thisColumns"
                              :slot="col.dataIndex"
                              slot-scope="text, record"
                              :rowKey="rowKey"
                    >   <!--如果定义了字段格式，则按照格式进行渲染-->
                        <template v-if="col.scopedSlots">
                            <e-table-colum :key="col.dataIndex" :column="col" :slotScopeData="record" @onClick="columClick"></e-table-colum>
                        </template>
                    </template>
                </a-table>
            </div>
        </div>
        <template slot="footer">
            <a-button v-if="needCreateFrom"
                        class="headButton"
                        type="primary"
                        :disabled="buttonDisabled"
                        :loading="createFromButton.loading"
                        :data-config="createFromButton"
                        @click="onButtonClick(createFromButton)"
            >{{ createFromButtonTitle }}
            </a-button>
            <div v-if="needCreateFrom" class="divider-block-min"></div>
            <!-- 导出按钮 -->
            <a-button v-if="needExport"
                        class="headButton"
                        type="primary"
                        :disabled="buttonDisabled"
                        :loading="exportButton.loading"
                        :data-config="exportButton"
                        @click="onButtonClick(exportButton)"
            >{{ exportButton.title }}
            </a-button>
            <div v-if="needExport" class="divider-block-min"></div>
            <!-- 打印按钮 :size="$config.defaultControlSize"
                      :icon="printButton.icon" :ghost="createFromButton.ghost"-->
            <a-button v-if="needPrint"
                        class="headButton"
                        type="primary"
                        :loading="printButton.loading"
                        :data-config="printButton"
                        @click="onButtonClick(printButton)"
            >{{ printButton.title }}
            </a-button>
            <div v-if="needPrint" class="divider-block-min"></div>
            <!-- 其他定制按钮，:icon="button.icon":size="$config.defaultControlSize" -->
            <template v-for="button in thisButtons">
                <a-button
                        :key="button.key"
                        :disabled="buttonDisabled"
                        class="headButton"
                        type="primary"
                        :ghost="button.ghost"
                        :loading="button.loading"
                        :data-config="button"
                        @click="onButtonClick(button)"
                >{{ button.title }}
                </a-button
                >
                <div :key="button.title" class="divider-block-min"></div>
            </template>
        </template>
    </a-modal>
</template>

<script>
  import ETableColum from "@/components/ETableColum";
  import { tableThResizeable } from "@/controls/Table/TabelThResizeable.js"; // 表格头可变列宽控件
  import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
  import { e1ExpendColum } from "@/views/listView/mixins/e1ExpendColum.js"; // 提供了扩展列表功能的公共方法
  import print from "print-js"; // 打印插件
  export default {
    mixins: [pageControls, e1ExpendColum],
    name: "ETableModal",
    components: {
      ETableColum
    },
    data() {
      return {
        thisKey: "tableModal_" + new Date().getTime() + "_" + this.$util.getRandomString(3),
        key: 0,
        controlSize: this.$config.defaultControlSize,
        noticeType: null,
        thisColumns: [],
        thComponents: null,
        thisTableDatas: [],
        expandedRowKeys: [],
        selectedRowKeys: [],
        tableSpin: false,
        rowKey: this.defaultTableKeyName,
        buttonDisabled: false,
        isExpandAllRows: false,
        rowSelection: null,
        multiSelect: "checkbox",
        showCheck: false,
        checkedAll: false,
        tableWidth: 600,
        tempButton: [],
        exportButton: {
          title: "导出到Excel",
          icon: "download",
          key: "exportExcel_sys",
          system: true,
          loading: false
        },
        printButton: {
          title: "打印",
          icon: "printer",
          key: "print_sys",
          system: true,
          loading: false
        },
        createFromButton: {
          icon: "plus",
          key: "createFrom_sys",
          system: true,
          loading: false
        },
        reRender: true,
        createFromButtonTitle: "创建表单",
        dPrint: false,
        x: 0, // 拖动时的鼠标初始x坐标
        y: 0, // 拖动时的鼠标初始y坐标
        absX: 0,
        absY: 0
      };
    },
    props: {
      // 传参
      value: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ""
      },
      multi: {
        type: Boolean,
        default: true
      },
      tableConfig: {
        // 表格定义
        type: Object,
        default: function() {
          return {};
        }
      },
      tableDatas: {
        // 表格数据
        type: Array,
        default: function() {
          return [];
        }
      },
      loading: {
        // 表格加载状态
        type: Boolean,
        default: false
      },
      buttons: {
        // 定义操作按钮
        type: Array,
        default: function() {
          return [
            {
              title: "确定",
              icon: "close",
              key: "cancel",
              system: true,
              loading: false
            }
          ];
        }
      },
      width: {
        type: [Number, String],
        default: function() {
          return "600";
        }
      },
      needExport: { // 需要导出功能
        type: Boolean,
        default: false
      },
      needPrint: {// 需要打印功能
        type: Boolean,
        default: false
      },
      needCreateFrom: { // 需要创建的表单id
        type: [String, Number],
        default: null
      },
      formTitle: { // 表单名称
        type: String,
        default: null
      },
      selectedKeys: {
        type: [Array, String],
        default: function() {
          return [];
        }
      },
      resizable: { // 列宽是否可变大小
        type: Boolean,
        default: true
      },
      draggable: {//是否可以移动窗口
        type: Boolean,
        default: false
      },
      redraw: { //关闭窗口是否销毁掉表格
        type: Boolean,
        default: false // 默认不销毁
      },
    },
    computed: {
      visible: {
        get: function() {
          //console.log("弹窗显示",this.value );
          return this.value;
        },
        set: function(v) {
          this.$emit("input", v);
        }
      },
      thisButtons: {
        get: function() {
          return this.tempButton;
        },
        set: function(nButton) {
          this.$emit("onButtonState", nButton);
        }
      },
      allWidth: function() {
        if (this.tableWidth) {
          return this.tableWidth + 48 + 20;
        } else {
          return 520;
        }
      },
      thisRowSelection() {
        if (this.showCheck)
          return {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onTableSelectChange,
            type: this.multiSelect,
            onSelect: this.onSelect
          };
        else return null;
      },
      maxHeight: function() {
        return (this.$root.NowWindowsSize.height * 0.8) + "px";
      },
      maxWidth: function() {
        return (this.$root.NowWindowsSize.width * 0.9) + "px";
      },
      tableMaxWidth: function() {
        return this.$root.NowWindowsSize.width * 0.9 - 48;
      },
      tableMaxHeight: function() {
        return (this.$root.NowWindowsSize.height * 0.8 - 220) + "px";
      }
    },
    watch: {
      value: function(v) {
        if(v) {
          this.reRender = true;
          if(this.draggable) {
            let that = this;
              that.$nextTick(function() {
                let thisModalDocument = document.getElementById(this.thisKey);
                let antModal = thisModalDocument.querySelector(".ant-modal");
                let middleY = (that.$root.NowWindowsSize.height - antModal.clientHeight + 26) / 2;
                let middleX = (that.$root.NowWindowsSize.width - that.allWidth) / 2;
                that.x = middleX;
                that.y = middleY;
                antModal.style.top = middleY+"px";
                antModal.style.left = middleX+"px";
                console.log(middleX, middleY);
              });
            }
        } else if(this.redraw) {
          let that = this;
          setTimeout(function(){
            that.reRender = false;
            that.thisTableDatas = [];
          },600)
        }
      },
      buttons: function(n) {
        //console.log("按钮改变了",n);
        this.tempButton = n;
      },
      tableDatas: function(n) {
        console.log("---------------------------------表格数据改变了", n);
        if(n)
          this.thisTableDatas = n;
        else
          this.thisTableDatas = [];
      },
      loading: function(n) {
        //console.log("表格数据加载状态改变了", n);
        this.tableSpin = n;
        if(!n) {
          this.isExpandAllRows = true; // 如果数据加载完成，则将树状表格展开
          this.key =1;
        } else {
          this.isExpandAllRows = false;
          this.key = 0;
        }
      },
      tableConfig: function(n) {
        //console.log("表格定义改变了", n);
        this.thisColumns = n.columns;
        this.rowKey = n.rowKey;
        this.showCheck = n.checked;
        this.selectedRowKeys = [];
        let tempWidth = this.computeWidth();
        if (tempWidth)
          this.tableWidth = tempWidth;
      },
      multi: function(n) {
        if (n === false) {
          this.multiSelect = "radio";
        } else {
          this.multiSelect = "checkbox";
        }
      },
      selectedKeys: function(n) {
        if (n && n === "SELECTALL") {
          let that = this;
          if (!this.checkedAll) {
            // 否则全选择
            //console.log("全部选择");
            this.checkedAll = true;
            let type = this.tableConfig.type; //this.tableConfig.groupBy ? this.tableConfig.groupBy :
            let type2 = this.tableConfig.configType;
            if (type === "TREELISTVIEW" || type2 === "TREELISTVIEW") { // 树状列表，全部选中
              let allKey = [];
              this.$util.getObjParamValueArrayByParam(this.thisTableDatas, this.rowKey, allKey);
              this.selectedRowKeys = allKey;
            } else
              this.selectedRowKeys = this.thisTableDatas.map(function(data) {
                return data[that.rowKey];
              });
          }
        } else if (n && n === "DESELECTALL") {
          if (this.checkedAll) {
            // 如果已经被全选，则清空选择
            //console.log("全部取消选择");
            this.checkedAll = false;
            this.selectedRowKeys = [];
          }
        } else if (this.$util.isType(n) === "Array") {
          if (n.length === 0) {
            this.checkedAll = false;
          }
          this.selectedRowKeys = n;
        }
      },
      formTitle: function(v) {
        if (v && v !== "") {
          this.createFromButtonTitle = "新建" + this.formTitle;
        }
      },
      selectedRowKeys: function(v) {
        this.$emit("onSelectedChange",v);
      }
    },
    created() {
      let tempWidth = 0;
      if (this.tableConfig) {
        this.thisColumns = this.tableConfig.columns;
        if(this.resizable) {
          this.thComponents = {
            header: {
              cell: tableThResizeable.getResizeableTitleFunction(this.thisColumns)
            }
          };
        }
        console.log("this.thisColumns=",this.thisColumns);
        this.rowKey = this.tableConfig.rowKey;
        this.showCheck = this.tableConfig.checked;
        tempWidth = this.computeWidth();
      }
      //console.log("获取到配置的表格宽度",tempWidth);
      if (tempWidth)
        this.tableWidth = tempWidth;
      else
        this.tableWidth = this.getWidth();
      this.tableSpin = this.loading;
      this.tempButton = this.buttons;
      this.selectedRowKeys = [];
      if (this.formTitle && this.formTitle !== "") {
        this.createFromButtonTitle = "新建" + this.formTitle;
      }
      //console.log("ETableModal-created---------------->", this.thisColumns);
    },
    mounted() {
      if(this.tableDatas)
        this.thisTableDatas = [].concat(this.tableDatas);
    },
    methods: {
      /**
       * 计算表格配置的总宽度
       */
      computeWidth() {
        if (this.thisColumns && this.thisColumns.length > 0) {
          let temWidth = 0;
          for (let i = 0; i < this.thisColumns.length; i++) {
            if (this.thisColumns[i].width) {
              temWidth += this.thisColumns[i].width + 5;
            } else {
              return 0;
            }
          }
          temWidth = temWidth + 67;
          //console.log("表格最大宽度",this.tableMaxWidth);
          if (temWidth > this.tableMaxWidth) {
            return this.tableMaxWidth - 4;
          }
          return temWidth;
        }
        return 0;
      },
      /**
       * 格式化width传入参数为int值
       */
      getWidth() {
        if(this.$util.isNumberString(this.width)){
            return parseFloat(this.width);
        } else if (this.$util.isType(this.width) === "val") {
          return this.width;
        } else if (this.$util.isPixelString(this.width)) {
          let widthNumber = this.width.substring(0, this.width.indexOf("px"));
          return parseFloat(widthNumber);
        } else {
          return 600;
        }
      },
      /**
       * 将数据导出到excel
       */
      doExportToExcel() {
        if (this.thisColumns && this.thisColumns.length > 0 && this.thisTableDatas.length > 0) {
          this.$util.excelExport(
            this.thisTableDatas,
            this.thisColumns,
            this.title
          );
        }
      },
      /**
       * 把表单内的数据打印出来
       */
      doPrint() {
        let that = this;
        this.dPrint = true;
        this.$nextTick(function() {
          print({
            printable: "printJS-table-" + that.title,
            type: "html",
            targetStyles: ["*"],
            header: that.title,
            style: "@media print{@page {size:landscape}}"
          });
          that.dPrint = false;
        });
      },
      /**
       * 按钮事件 提交
       * @param button
       */
      onButtonClick(button) {
        if (button.system && button.key === "cancel") {
          this.visible = false;
        }
        if (button.system && button.key === "exportExcel_sys") { // 导出数据到Excel
          this.doExportToExcel();
        }
        if (button.system && button.key === "print_sys") {
          this.doPrint();
        }
        if (button.system && button.key === "createFrom_sys" && this.needCreateFrom) { // 创建表单事件
          this.visible = false;
          this.$emit("buttonClicked", {
            key: button.key,
            tempId: this.needCreateFrom
          });
          let data = {
            childrenDatas: this.thisTableDatas,
            mainTableData: {}
          };
          this.$store.commit("setCacheData", data);
          let query = {
            templetId: this.needCreateFrom,
            type: "dataFromCache"
          };
          this.openTab({
            title: "新增-" + this.formTitle,
            path: "/NewForm",
            query: query
          });
          return;
        }
        //debugger
        if (button.returnData) {
          let that = this;
          this.buttonDisabled = true;
          //console.log("点击了提交数据按钮");
          let values = [];
          let type = this.tableConfig.type; //this.tableConfig.groupBy ? this.tableConfig.groupBy :
          let type2 = this.tableConfig.configType;
          let selectedKeys = [];
          if (this.checkedAll) {
            if (type === "TREELISTVIEW" || type2 === "TREELISTVIEW") { // 树状列表，需要过滤掉无效的根节点数据
              selectedKeys = this.selectedRowKeys.filter(function(key) {
                return key.includes(".");
              });
              selectedKeys.forEach(function(key) {
                let conn = that.$util.getObjByParamAndValue(that.thisTableDatas, that.rowKey, key);
                const newObj = Object.assign({}, conn);
                values.push(newObj);
              });
            } else {
              values = this.thisTableDatas.map(function(obj) {
                const newObj = Object.assign({}, obj);
                return newObj;
              });
              selectedKeys = this.selectedRowKeys;
            }
          } else {
            if (type === "TREELISTVIEW" || type2 === "TREELISTVIEW")
              selectedKeys = this.selectedRowKeys.filter(function(key) {
                return key.includes(".");
              });
            else
              selectedKeys = this.selectedRowKeys;
            selectedKeys.forEach(function(key) {
              let conn = that.$util.getObjByParamAndValue(that.thisTableDatas, that.rowKey, key);
              console.log("选择的", conn, key);
              const newObj = Object.assign({}, conn);
              values.push(newObj);
            });
          }
          button.loading = true;
          let promise1 = new Promise(resolve => {
            that.$emit(button.key + "Clicked", {
              events: button.events,
              resolve: resolve,
              key: button.key,
              data: values,
              dataKeys: selectedKeys
            });
            that.$emit("buttonClicked", {
              events: button.events,
              resolve: resolve,
              key: button.key,
              data: values,
              dataKeys: selectedKeys
            });
            if(button.selected && button.selected === "noClear") {
              return;
            }
            that.selectedRowKeys = [];
          }).catch(() => console.error("弹出框按钮异常"));
          // 异步响应上方的Promise类提供的resolve的，执行结果
          promise1.then(function(value) {
            console.log("列表弹窗-确认按钮操作完成",value);
            if(value) {
              button.loading = false;
              that.buttonDisabled = false;
            } else {
              button.loading = false;
              that.visible = false;
              that.buttonDisabled = false;
            }
          });
        } else {
          this.$emit(button.key + "Clicked");
          this.$emit("buttonClicked", {
            resolve: function() {},
            events: button.events,
            key: button.key
          });
          if(button.selected && button.selected === "noClear") {
            return;
          }
          this.selectedRowKeys = [];
        }
      },
      /**
       * 选中行数据事件
       * @param selectedRowKeys
       */
      onTableSelectChange(selectedRowKeys) {
        console.log("选中的行对象: ", selectedRowKeys);
        this.selectedRowKeys = selectedRowKeys;
      },
      /**
       * 行选择控制树形结构的父子选中情况
       * @param row
       * @param selected
       */
      onSelect(row, selected) {
        //console.log('onSelect行对象: ', row,selected);
        if (row.children) { // 如果选择的行对象包含子对象
          let that = this;
          if (selected) { // 选中父节点，则全部选中子对象
            let allChildren = [];
            // 合并去重
            for (let i = 0; i < row.children.length; i++) {
              if (this.selectedRowKeys.indexOf(row.children[i][that.rowKey]) == -1)
                allChildren.push(row.children[i][that.rowKey]);
            }
            this.selectedRowKeys = this.selectedRowKeys.concat(allChildren);
          } else { // 取消选中父节点，则全部取消选中子对象
            for (let i = 0; i < row.children.length; i++) {
              let key = row.children[i][that.rowKey];
              let index = this.selectedRowKeys.indexOf(key);
              if (index >= 0) {
                this.selectedRowKeys.splice(index, 1);
              }
            }
          }
        }
        //console.log('行对象子行数据: ', this.selectedRowKeys);
      },
      /**
       * 关闭本弹窗
       */
      closeModal() {
        this.visible = false;
      },
      /**
       * 拖动事件开始，获取当前鼠标位置与窗口目前位置的偏移量
       */
      checkDrag(ev) {
        console.log('拖动开始状态: ',ev, ev.x-this.x,ev.y-this.y);
        this.absX = ev.x-this.x;
        this.absY = ev.y-this.y;
        return true;
      },
      /**
       * 拖动窗口
       * @param x
       * @param y
       */
      onDrag(x, y) {
        let thisModalDocument = document.getElementById(this.thisKey);
        let antModal = thisModalDocument.querySelector(".ant-modal");
        let maxY = this.$root.NowWindowsSize.height - antModal.clientHeight +26;
        let maxX = this.$root.NowWindowsSize.width - this.allWidth;
        console.log("鼠标位置",x,y);
        this.x = x - this.absX;
        this.y = y - this.absY;
        console.log("相对位置",this.x,this.y);
        antModal.style.left = (this.x>=0 ? (this.x<maxX ? this.x:maxX):0)+ "px";
        antModal.style.top = (this.y>=0 ? (this.y<maxY ? this.y:maxY):0)+ "px";
        console.log(antModal.style.left,antModal.style.top);
      },
      /**
       * 停止拖动后，重新计算拖动偏移量
       * @param x
       * @param y
       */
      onDragstop(x,y) {
        console.log("重新计算鼠标位置",x,y);
        this.absX = x-this.x;
        this.absY = y-this.y;
      },
    }
  };
</script>

<style lang="less" scoped>
  @import "../assets/style/base/components/e-table-modal.less";
</style>
