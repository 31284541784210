<!-- groupBox容器控件    colSpan   v-decorator="[formName]" :style="{minWidth: minWidth}"-->
<template>
  <div class="e-group" :class="getGroupBoxClass">
    <div v-if="formName" class="e-group-box-title">{{ formName }}</div>
    <form-template
      style="margin-left:0;"
      v-model="nowTableData"
      v-if="formRows.length > 0"
      :ref="'GroupBoxTemplate_'+random"
      :colSpan="colSpan > 18 ? colSpan : 18"
      :formDisable="thisDisabled"
      :formEditOver="thisFormEditOver"
      :formRows="formRows"
      :mainTableData="mainTableData"
      :tempId="tempId"
      :form="form"
      :width="groupWidth"
      :modifiedData="modifiedData"
      :fieldsAllowedEdit="thisFieldsAllowedEdit"
    ></form-template>
  </div>
</template>

<script>
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
export default {
  mixins: [pageControls],
  name: "EGroupBox",
  data() {
    return {
      controlSize: this.$config.defaultControlSize,
      formRows: this.childForm,
      tempId: this.bdmbId,
      thisDisabled: false,
      thisFieldsAllowedEdit: null,
      thisFormEditOver: false,
      modifiedData: {},
      nowTableData: {},
      random: new Date().getTime() + "_" + Math.random()
    };
  },
  created() {
    console.log("EGroupBox--->", this.width);
    console.log("childForm", this.childForm);
    console.log("thisFormEditOver", this.formEditOver);
    this.thisDisabled = this.disabled;
    this.thisFormEditOver = this.formEditOver;
  },
  computed: {
    groupWidth: function() {
      return this.width - 50;
    },
    minWidth: function() {
      let max = 1;
      if (this.formRows) {
        for (let i = 0; i < this.formRows.length; i++) {
          let row = this.formRows[i];
          let len = row.columns.length;
          max = Math.max(max, len);
        }
        //console.log("一行最多控件数：",max);
      }
      return max * 400 + "px";
    },
    getGroupBoxClass: function() {
      let classes = [];
      if (this.controlSize === "small") {
        classes.push("e-group-box__small");
      } else if (this.controlSize === "large") {
        classes.push("e-group-box__large");
      } else {
        classes.push("e-group-box");
      }
      if (this.isNewTheme) {
        classes.push("e-group-box__noHeadText");
      }
      return classes;
    }
  },
  props: {
    childForm: {
      type: Array,
      default: function() {
        return [];
      }
    },
    fieldsAllowedEdit: {
      type: Array,
      default: function() {
        return null;
      }
    },
    formName: {
      type: String,
      default: ""
    },
    bdmbId: {
      type: [String, Number]
    },
    colSpan: {
      type: Number,
      default: 18
    },
    mainTableData: {
      //主表数据
      type: Object,
      default: function() {
        return {};
      }
    },
    form: {
      type: Object,
      default: function() {
        return {};
      }
    },
    disabled: {
      // 表单是否可编辑，但是排除明确标识了可编辑字段
      type: Boolean,
      default: false
    },
    formEditOver: {
      // 表单是否可编辑,表单可编辑状态,整体概念的不可编辑
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 900
    },
    modifiedValues: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  watch: {
    modifiedValues: function(newData) {
      if (newData) {
        this.modifiedData = newData;
      }
    },
    nowTableData: function(v) {
      console.log("group-box值发生变化", v);
      //this.$emit("change",v)
    },
    disabled: function(n) {
      this.thisDisabled = n;
    },
    fieldsAllowedEdit: function(n) {
      console.log(
        "监听到GroupBox的fieldsAllowedEdit变化",
        this.thisDisabled,
        n
      );
      if (this.thisDisabled) this.thisFieldsAllowedEdit = n;
    },
    formEditOver: function(n) {
      console.log(
        "监听到GroupBox的thisFormEditOver变化",
        this.thisFormEditOver,
        n
      );
      this.thisFormEditOver = n;
    }
  },
  methods: {
    /**
     * 设置切换对应的表单字段禁用状态
     * @param field
     */
    setDisabledSwitch() {
      let formRef = this.getThisRef("GroupBoxTemplate_"+this.random);
      if(formRef && this.formRows){
        for (let i = 0; i < this.formRows.length; i++) {
          let column = this.formRows[i].columns;
          if (column.length > 0) {
            for (let j = 0; j < column.length; j++) {
              if(column[j].configs.fieldName && column[j].isShow !== false) {
                formRef.setFieldDisabledSwitch(column[j].configs.fieldName);
              }
            }
          }
        }
      }
    },
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/base/controls/e-group-box.less";
</style>
