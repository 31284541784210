<template>
  <a-form v-if="column" :form="form">
    <a-form-item :label="column.title">
      <!-- 文本域  -->
      <e-input
        v-if="column.type === 'EInput'"
        :width="column.width"
        v-decorator="[
          column.fieldName,
          {
            initialValue: nowValue,
            rules: column.rules
          }
        ]"
      ></e-input>
      <!-- 日期时间选择器-->
      <e-datetime-picker
        v-else-if="column.type === 'EDatetimePicker'"
        :width="column.width"
        :format="column.format"
        :mode="column.mode"
        v-decorator="[
          column.fieldName,
          {
            initialValue: nowValue,
            rules: column.rules
          }
        ]"
      ></e-datetime-picker>
      <!--:defaultValue="column.defaultValue"-->
      <!-- 部门选择器 -->
      <e-department-select
        v-else-if="column.type === 'EDepartmentSelect'"
        :disabled="column.disable"
        :width="column.width"
        v-decorator="[
          column.fieldName,
          {
            initialValue: nowValue,
            rules: column.rules
          }
        ]"
      ></e-department-select>
      <!-- 用户选择器 -->
      <e-employee-select
        v-else-if="column.type === 'EEmployeeSelect'"
        :disabled="column.disable"
        :width="column.width"
        v-decorator="[
          column.fieldName,
          {
            initialValue: nowValue,
            rules: column.rules
          }
        ]"
      ></e-employee-select>
      <!-- 供应商商户选择器 -->
      <e-supplier-select
        v-else-if="column.type === 'ESupplierSelect'"
        :disabled="column.disable"
        :width="column.width"
        :bdmbId="tempId"
        v-decorator="[
          column.fieldName,
          {
            initialValue: nowValue,
            rules: column.rules
          }
        ]"
      ></e-supplier-select>
      <!-- 通用下拉列表 -->
      <e-select
        v-else-if="column.type === 'ESelect'"
        :filter="column.listFilter"
        :options="column.options"
        :disabled="column.disable"
        :setting="column.setting"
        :width="column.width"
        v-decorator="[
          column.fieldName,
          {
            initialValue: nowValue,
            rules: column.rules
          }
        ]"
      ></e-select>
      <!-- 复选框-->
      <e-checkbox
        v-else-if="column.type === 'ECheckbox'"
        :options="column.options"
        :filter="column.listFilter"
        :setting="column.setting"
        v-decorator="[
          column.fieldName,
          {
            initialValue: nowValue,
            rules: column.rules
          }
        ]"
      ></e-checkbox>
      <!-- 单选框 -->
      <e-radio
        v-else-if="column.type === 'ERadio'"
        :options="column.options"
        v-decorator="[
          column.fieldName,
          {
            initialValue: nowValue,
            rules: column.rules
          }
        ]"
      ></e-radio>
      <!-- 数字选择器 -->
      <e-numeric-up-down
        v-else-if="column.type === 'ENumericUpDown'"
        :width="column.width"
        :max="column.max"
        :min="column.min"
        v-decorator="[
          column.fieldName,
          {
            initialValue: nowValue,
            rules: column.rules
          }
        ]"
      ></e-numeric-up-down>
      <!-- 物资控件 -->
      <e-materials
        v-else-if="column.type === 'EMaterials'"
        :bdmbId="tempId"
        :setting="column.setting"
        :width="column.width"
        v-decorator="[
          column.fieldName,
          {
            initialValue: nowValue,
            rules: column.rules
          }
        ]"
      ></e-materials>
      <!-- 是否选择器 -->
      <e-boolean-checkbox
        v-else-if="column.type === 'EBooleanCheckbox'"
        :width="column.width"
        v-decorator="[
          column.fieldName,
          {
            initialValue: nowValue,
            rules: column.rules
          }
        ]"
      ></e-boolean-checkbox>
      <!-- 客户选择器-->
      <e-customer
        v-else-if="column.type === 'ECustomer'"
        :setting="column.setting"
        :width="column.width"
        v-decorator="[
          column.fieldName,
          {
            initialValue: nowValue,
            rules: column.rules
          }
        ]"
      ></e-customer>
      <!-- 表单模板选择器 -->
      <e-gy-bdmb
        v-else-if="column.type === 'EGyBdmb'"
        :setting="column.setting"
        :width="column.width"
        v-decorator="[
          column.fieldName,
          {
            initialValue: nowValue,
            rules: column.rules
          }
        ]"
      ></e-gy-bdmb>
      <!-- 岗级定义 -->
      <e-rs-gjdy
        v-else-if="column.type === 'ERsGjdy'"
        :setting="column.setting"
        :width="column.width"
        v-decorator="[
          column.fieldName,
          {
            initialValue: nowValue,
            rules: column.rules
          }
        ]"
      ></e-rs-gjdy>
    </a-form-item>
  </a-form>
</template>

<script>
export default {
  name: "EControlTemplate",
  data() {
    return {
      thisValue: null,
      thisControlConfig: null,
      thisCwpzId: null
    };
  },
  computed: {
    column: function() {
      return this.thisControlConfig;
    },
    nowValue: {
      get: function() {
        return this.thisValue ? this.thisValue : this.column.defaultValue;
      },
      set: function(v) {
        this.$emit("input", v);
      }
    }
  },
  props: {
    value: {
      // 绑定的数据对象
      type: [Number, String, Boolean, Object, Array],
      default: function() {
        return "";
      }
    },
    tempId: {
      // 当前的模板id
      type: [Number, String],
      default: function() {
        return null;
      }
    },
    controlConfig: {
      // 绑定的控件配置对象
      type: Object,
      default: function() {
        return null;
      }
    },
    form: {
      type: Object,
      default: null
    }
  },
  created() {
    this.thisValue = this.value;
    this.thisControlConfig = this.controlConfig;
  }
};
</script>
