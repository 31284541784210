<template>
  <div v-if="visible">
    <a-modal
      title="系统打印"
      :visible="visible"
      @ok="ok"
      @cancel="handleCancel"
      cancelText="关闭"
      :width="600"
      :mask="true"
    >
      <template slot="footer">
        <a-button key="submit" type="primary" @click="ok">
          确定
        </a-button>
      </template>
      <div v-if="visible">
        <div>选择列</div>
        <div class="bodyBox">
          <div class="bodyBoxLayout width50B">
            <div class="leftBox">
              <a-checkbox-group
                v-model="slectLine"
                :disabled="printAllMxFromFlag"
              >
                <div v-if="printConfig.mxForm">
                  <template
                    v-for="row in printConfig.mxForm.columns.filter(
                      item => !item.hideThis
                    )"
                  >
                    <a-row :key="row.fieldName ? row.fieldName : row.dataIndex">
                      <a-checkbox
                        :value="row.fieldName ? row.fieldName : row.dataIndex"
                        >{{
                          row.headerText ? row.headerText : row.title
                        }}</a-checkbox
                      >
                    </a-row>
                  </template>
                </div>
              </a-checkbox-group>
            </div>
            <!-- <div class="button-row">
              <a-button @click="checkAll">全选</a-button>
            </div>
            <div class="button-row">
              <a-button @click="notCheckAll">全不选</a-button>
            </div> -->
          </div>
          <div class="bodyBoxLayout rightBox" style="height:10%">
            <a-row align="middle" v-if="printConfig.mxForm">
              <div class="button-row">
                <a-button @click="checkAll" :disabled="printAllMxFromFlag"
                  >全选</a-button
                >
              </div>
              <div class="button-row">
                <a-button @click="notCheckAll" :disabled="printAllMxFromFlag"
                  >全不选</a-button
                >
              </div>
            </a-row>

            <a-row>
              列打印范围
            </a-row>
            <a-row>
              <a-radio-group v-model="printAllMxFromFlag" buttonStyle="solid">
                <a-radio-button :value="true">所有列</a-radio-button>
                <a-radio-button :value="false">选择明细表列</a-radio-button>
              </a-radio-group>
            </a-row>

            <a-row>
              字体大小
            </a-row>
            <a-row>
              <a-select
                :options="textFontSizeOptions"
                defaultValue="16px"
                v-model="fontSize"
                :style="{ width: '100%' }"
              />
            </a-row>

            <a-row>
              标题
            </a-row>
            <a-row>
              <a-textarea
                placeholder=""
                :autosize="{ minRows: 6, maxRows: 6 }"
                v-model="head"
              />
            </a-row>
          </div>
        </div>
      </div>
    </a-modal>

    <systemPrint ref="Print"></systemPrint>
  </div>
</template>

<script>
import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法
import systemPrint from "./systemPrint.vue";
import XEUtils from "xe-utils";
import _lodash from "lodash";
export default {
  name: "systemPrintSet",
  mixins: [requestControls],
  components: {
    systemPrint
  },
  data() {
    return {
      visible: false,
      printConfig: null, // 打印设置
      slectLine: [],
      printAllMxFromFlag: true,
      head: "",
      fontSize: "16px",
      textFontSizeOptions: [
        {
          label: "初号",
          value: "44px"
        },
        {
          label: "小初",
          value: "36px"
        },
        {
          label: "一号",
          value: "26px"
        },
        {
          label: "小一",
          value: "24px"
        },
        {
          label: "二号",
          value: "22px"
        },
        {
          label: "小二",
          value: "18px"
        },
        {
          label: "三号",
          value: "16px"
        },
        {
          label: "小三",
          value: "15px"
        },
        {
          label: "四号",
          value: "14px"
        },
        {
          label: "小四",
          value: "12px"
        },
        {
          label: "五号",
          value: "10.5px"
        },
        {
          label: "小五",
          value: "9px"
        },
        {
          label: "六号",
          value: "7.5px"
        },
        {
          label: "小六",
          value: "6.5px"
        },
        {
          label: "七号",
          value: "5.5px"
        },
        {
          label: "八号",
          value: "5px"
        }
      ]
    };
  },
  props: {},
  created() {},
  computed: {},
  methods: {
    show(printConfig) {
      this.visible = true;
      this.printConfig = removeInvalid(printConfig);
      this.head = printConfig.title;
      this.titleChange();
      this.checkAll();

      // 去除没用的列
      function removeInvalid(obj) {
        obj = _lodash.cloneDeep(obj);
        if (obj) {
          if (obj.mxForm && obj.mxForm.columns) {
            for (var i = 0; i < obj.mxForm.columns.length; i++) {
              if (
                obj.mxForm.columns[i].dataIndex == "operation" &&
                !obj.mxForm.columns[i]["key"]
              ) {
                obj.mxForm.columns.splice(i, 1);
              }
            }
          }
        }
        return obj;
      }
    },
    checkAll() {
      if (!this.printConfig.mxForm) {
        return;
      }
      var slectLine = [...this.printConfig.mxForm.columns];
      slectLine.forEach(element => {
        var fieldName = element.fieldName
          ? element.fieldName
          : element.dataIndex;
        this.slectLine.push(fieldName);
      });
    },
    titleChange() {
      if (this.printConfig.mxForm && this.printConfig.mxForm.columns) {
        for (var i = 0; i < this.printConfig.mxForm.columns.length; i++) {
          if (this.printConfig.mxForm.columns[i].title) {
            this.printConfig.mxForm.columns[
              i
            ].title = this.printConfig.mxForm.columns[i].title;
          }
        }
      }
    },
    notCheckAll() {
      this.slectLine = [];
    },
    handleCancel() {
      this.visible = false;
    },
    ok() {
      // 筛选选中明细表列
      var printConfigPost = JSON.parse(JSON.stringify(this.printConfig));
      if (this.printConfig.mxForm) {
        if (!this.printAllMxFromFlag) {
          var newColumns = [];
          this.printConfig.mxForm.columns.forEach(element => {
            var fieldName = element.fieldName
              ? element.fieldName
              : element.dataIndex;
            for (var i = 0; i < this.slectLine.length; i++) {
              if (fieldName == this.slectLine[i]) {
                newColumns.push(element);
                break;
              }
            }
          });
          printConfigPost.mxForm.columns = newColumns;
        }

        // for (var j = 0; j < printConfigPost.mxForm.columns.length; j++) {
        //   // printConfigPost.mxForm.columns[j].scopedSlots["thead"] = printConfigPost.mxForm.columns[j]["title"];
        //   //  printConfigPost.mxForm.columns[j].slots = { title: 'customTitle'};
        //   //  printConfigPost.mxForm.columns[j].dataIndex = printConfigPost.mxForm.columns[j]["title"];
        //   //  delete printConfigPost.mxForm.columns[j]["title"]
        // }
      }
      // 去除可见范围之外的
      if (printConfigPost.mxForm) {
        printConfigPost.mxForm.columns = printConfigPost.mxForm.columns.filter(
          item => !item.hideThis
        );

        var col = printConfigPost.mxForm.columns;
        var sumArray = null;
        for (var s = 0; s < col.length; s++) {
          if (col[s].isSum) {
            if (sumArray == null) {
              sumArray = {};
            }
            sumArray[col[s].fieldName] = XEUtils.sum(
              printConfigPost.mxForm.data,
              col[s].fieldName
            );
          }
        }
        // 插入合计行
        if (sumArray) {
          var HH = printConfigPost.mxForm.columns.find(item => {
            return item.title == "行号" || item.isAutoColNo;
          });
          if (HH) {
            sumArray[HH.fieldName] = "合计";
          }

          printConfigPost.mxForm.data.push(sumArray);
        }
      }
      this.$refs.Print.show(printConfigPost, this.head, this.fontSize);
    }
  },
  watch: {
    printAllMxFromFlag(v) {
      if (v == false) {
        this.notCheckAll();
      } else {
        this.checkAll();
      }
    }
  }
};
</script>
<style scoped>
/deep/ .ant-row {
  margin-bottom: 0 !important;
}
.bodyBox {
  height: 300px;
  width: 100%;
}
.bodyBoxLayout {
  height: 100%;
  float: left;
}
.leftBox {
  padding: 5px;
  border: 1px solid #cecece;
  overflow-y: scroll;
  height: 100%;
}
.rightBox {
  margin-left: 5%;
  width: 45%;
}
.width50B {
  width: 50%;
}
.button-row {
  float: left;
  padding-right: 20px;
}
</style>
