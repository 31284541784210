<!--
 * 登录组件
 * @create wangxin 2018/07/08
-->
<template>
  <div class="body" style="margin: 0px !important;">
    <div class="logo">
      <img :src="'/img/login3/' + config.logoUrl" />
      <!-- {{config.logoUrl}} -->
      <!-- <img src="~@/assets/login3/logo.png" /> -->
    </div>
    <div class="box">
      <div style="height:90%; position:relative">
        <a-form :form="form">
          <div class="title">{{ config.systemName }}</div>
          <a-form-item prop="account">
            <a-input
              v-decorator="userInputRules"
              :allowClear="true"
              placeholder="请输入账号"
              @change="trimInput"
              size="large"
              :style="{ width: '420px', padding: '10px 0' }"
              @pressEnter="handleSubmit"
              ref="userInput"
            >
              <template slot="prefix">
                <a-icon type="user" :style="{ fontSize: '24px' }" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              type="password"
              class="login-password-input"
              v-decorator="passInputRules"
              placeholder="请输入密码"
              @change="trimInput"
              :style="{ width: '420px', padding: '10px 0' }"
              @pressEnter="handleSubmit"
            >
              <template slot="prefix"
                ><a-icon type="lock" :style="{ fontSize: '24px' }"
              /></template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              :loading="loading"
              :style="{
                width: '420px',
                margin: '10px 0',
                height: '50px',
                'font-size': '20px',
                'background-image':
                  'linear-gradient(to right, #569be8 , #351bc6)'
              }"
              @click.native.prevent="handleSubmit"
              >登录
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="bottom-text">{{ config.companyName }}<a :href="config.archivalInformationUrl" target="_blank">{{config.archivalInformation}}</a></div>
    </div>
  </div>
</template>

<script>
import { LOGIN_CONFIG } from "../loginSet.js";
import { loginMixins } from "../mixins/loginMixins.js";
//页面初始化时执行方法
export default {
  mixins: [LOGIN_CONFIG, loginMixins],
  name: "loginCenter1",
  data() {
    return {
      loading: false,
      config: window.LOGIN_CONFIG,
      userInputRules: [
        "username",
        {
          initialValue: "",
          rules: [{ required: true, message: "请输入账号" }]
        }
      ],
      passInputRules: [
        "password",
        {
          initialValue: "",
          rules: [{ required: true, message: "请输入密码" }]
        }
      ],
      form: this.$form.createForm(this, { name: "login" }),
      checked: true
    };
  },
  created() {
    // let _self = this;
    // document.onkeydown = function() {
    //   //用户按Enter，提交登录
    //   console.log("_self.blur", _self.blur);
    //   if (window.event.keyCode === 13 && _self.blur) {
    //     // _self.blur = false;
    //     // _self.$refs.userInput.blur();
    //     // _self.$refs.passInput.blur();
    //     _self.handleSubmit();
    //   }
    // };
  },
  mounted() {
    this.$refs.userInput.focus();
  },
  methods: {
    /**
     * 设置清空用户名输入的空格
     * @param e
     */
    trimInput(e) {
      let val = e.target.value;
      if ("" == this.$util.trimStr(val)) {
        e.target.value = "";
      }
    },
    handleSubmit() {
      this.login(this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.body {
  height: 100%;
  width: 100%;
  background: url(/img/login3/backgroud.jpg) center center no-repeat;
  background-size: 100% 100%;
  // padding: 200px 500px;
  min-width: 1000px;
  min-height: 800px;
  position: relative;
}
.box {
  border-radius: 20px;
  background: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  // padding-left: 25%;
  // padding-right: 25%;
  -webkit-box-shadow: 0px 0px 5px blue;
  box-shadow: 0px 0px 5px blue;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 500px;
  width: 800px;
}
.login-container {
  width: 100%;
}

.logo {
  position: absolute;
  left: 50px;
  top: 50px;
}

.title {
  height: 120px;
  line-height: 140px;
  text-align: center;
  font-size: 30px;
  color: #000;
}

.bottom-text {
  color: #a1a1a1;
}

::v-deep .ant-input {
  background-color: #f3f8fe !important;
  padding: 0 60px !important;
  height: 50px;
  line-height: 50px;
  background-color: #f3f8fe;
  width: 420px;
  border: 0px;
  font-size: 18px;
}

::v-deep .ant-form-explain {
  text-align: left !important;
  margin-left: 250px;
}
</style>
