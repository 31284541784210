<template>
  <div
    :class="type !== 'textarea' ? 'e-form-einput' : 'e-form-einput__textarea'"
    :title="setvalue"
  >
    {{ kmNext }}
    <!-- <button @click="t">t</button> -->
    <Treeselect
      :multiple="false"
      :disabled="disabled"
      :readOnly="readOnly"
      :options="option"
      :appendToBody="true"
      ref="input"
      zIndex="9999"
      :load-options="loadOptions"
      :disable-branch-nodes="true"
      :flat="true"
      v-model="setvalue"
      :openOnClick="false"
      @select="select"
      @searchKm="searchKm"
      :disableFuzzyMatching="true"
      placeholder="请选择科目"
    ></Treeselect>
    <!-- <div v-show="!editMode"><a-input v-model="text" :disabled="true"></a-input></div> -->
  </div>
</template>

<script>
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法
// import the component
// import Treeselect from "@riophae/vue-treeselect";
// import the styles
// import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "@/controls/KeepAccountsForm/treeSelct/vue-treeselect.css";
import Treeselect from "@/controls/KeepAccountsForm/treeSelct/vue-treeselect.js";

export default {
  mixins: [pageControls, requestControls],
  name: "keepAccountKM",
  components: { Treeselect },
  data() {
    return {
      setvalue: this.value,
      thisOldValue: null,
      option: [],
      allOption: [],
      popupVisible: false,
      editMode: false,
      text: "",
      optionOk: false,
      firstCache: false
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    defaultValue: {
      type: [String, null],
      default: null
    },
    value: {
      type: [String, Number, null],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: ""
    },
    tip: {
      type: String,
      default: null
    },
    modifiedValue: {
      type: [String, Number],
      default: null,
    },
    linkage: {
      type: Array,
      default: () => []
    },
    dataOptionSave: {
      type: null,
      default: function() {
        return null;
      }
    },
    waiting: {
      type: Boolean,
      default: false
    },
    kmNext: {
      type: Boolean,
      default: false
    }
  },
  created() {},
  mounted() {
    this.init();
  },
  computed: {
    setWidth: function() {
      let widthObj = { minWidth: "50px" }; // ,maxWidth: "300px"
      return widthObj;
    }
  },
  methods: {
    t() {
      return;
    },
    init() {
      var that = this;
      if (this.dataOptionSave != null) {
        this.option = this.dataOptionSave;
      }
      if (this.waiting) {
        return;
      }
      let params = {
        controlName: "CW_KJKMJBSZ",
        action: "GetCW_KJKMJBSZData",
        randomKey: this.$store.getters.getRandomKey
      };
      this.get("Form.ashx", params, {
        success: function(res) {
          if (res.success) {
            //兼容202102之前老版本接口
            let reqData = window.USE_NEW_API ? res.data:res;
            that.option = that.formatData(reqData.data);
            that.optionOk = true;
          }
        },
        error: function() {
          //   _this.showTreeSpin = false;
        }
      });
    },
    searchKm(searchStr, e) {
      var that = this;
      if (searchStr == this.setvalue) {
        // 这里注释掉，值一样也会触发change
        that.$refs.input.trigger.searchQuery = "";
        that.$emit("showHs", this.setvalue);
        return;
        // var trimSearchStr = searchStr.trim();
      }
      if (searchStr != "" && searchStr != null) {
        var trimSearchStr = searchStr.trim();
      }
      var flag = this.checkValue(trimSearchStr);

      if (flag == null) {
        that.$message.error("输入的科目不是末级");
        if (e) {
          e.notEnter = true;
        }
        return;
      }
      if (flag != false) {
        // that.setvalue = flag.key.trim();
        that.$refs.input.selectValue(trimSearchStr);
        that.$refs.input.trigger.searchQuery = "";
        var emitLinkedChange = that.linkedChange(flag);
        that.$emit("linkedChange", emitLinkedChange);
        if (this.kmNext) {
          this.$emit("kmNext");
        }
        return;
      }
      let params = {
        controlName: "CW_KJKMJBSZ",
        action: "GetCW_KJKMJBSZData",
        searchStr: searchStr,
        randomKey: this.$store.getters.getRandomKey
      };
      if (e) {
        e.notEnter = true;
      }
      this.get("Form.ashx", params, {
        success: function(res) {
          // that.option = that.formatData(res.data);
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? res.data:res;
          if (reqData.data) {
            that.pushOption(reqData.data);
          }
          if (reqData.result) {
            that.setvalue = reqData.result[0].key.trim();
            var emitLinkedChange = that.linkedChange(reqData.result[0]);
            that.$emit("linkedChange", emitLinkedChange);
            that.text = reqData.result[0].title.trim();
            that.$refs.input.trigger.searchQuery = "";
            if (that.kmNext) {
              that.$emit("kmNext");
            }
          }
          if (e) {
            e.notEnter = false;
            that.constraintEnter(e);
          }
        },
        error: function(res) {
          //   _this.showTreeSpin = false;
          that.$message.error(res.message);
        }
      });
    },
    pushOption(data) {
      var option = this.option;
      var pushData = format(data);
      for (var i = 0; i < option.length; i++) {
        if (option[i].id == pushData[0].id) {
          this.$set(this.option, i, pushData[0]);
          // this.$set(this.option[i], "children", pushData[0].children);
          // this.$refs.input.loadOptions();
          break;
        }
      }

      function format(data) {
        for (var i = 0; i < data.length; i++) {
          data[i].label = data[i].title;
          data[i].id = data[i].key;
          try {
            if (data[i].isLeaf == true) {
              data[i].isDisabled = false;
              if (data[i].children) {
                delete data[i].children;
              }
              data[i].checkable = true;
            } else if (data[i].isLeaf == false) {
              data[i].isDisabled = true;
            }
          } catch (err) {
            err;
            data[i].isDisabled = false;
          }
          if (data[i].children && data[i].children != "") {
            data[i].children = format(data[i].children);
          }
        }
        return data;
      }
    },
    checkValue(str) {
      var flag = false;
      var data = this.option;
      check(data);
      return flag;

      function check(data) {
        if (!flag) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].id == str) {
              if (data[i].isLeaf) {
                flag = data[i];
                break;
              } else {
                flag = null;
                break;
              }
            }
            if (data[i].children && data[i].children != "") {
              check(data[i].children);
            }
          }
        }
      }
    },
    loadOptions(e) {
      var treeNode = e.parentNode;
      var _this = this;
      return new Promise((resolve) => {
        if (treeNode.children == "" || !treeNode.children) {
          var url = "Form.ashx";
          // let params = {
          //   action: _this.setting.nodeAction,
          //   ID: treeNode.dataRef.key
          // };
          let params = {
            action: "GetCW_KJKMJBSZChildrenData",
            ID: treeNode.Id,
            BM: treeNode.key,
            JC: treeNode.JC
          };
          this.get(url, params, {
            success: function(res) {
              if (res.success) {
                treeNode.children = _this.formatData(res.data);
                if (res.data === "") {
                  treeNode.isLeaf = true;
                }
                resolve();
              }
            },
            error: function() {}
          });
        } else {
          resolve();
          return;
        }
      });
    },
    select(e) {
      if (e) {
        var emitLinkedChange = this.linkedChange(e);
        this.$emit("linkedChange", emitLinkedChange);
      }
    },
    linkedChange(data) {
      // {mbField:field}
      var json = {};
      this.linkage.forEach((element) => {
        if (data[element.field]) {
          json[element.mbField] = data[element.field];
        }
      });
      //console.log("控件linkedChange=", json);
      return json;
    },
    formatData(data, haveChildren) {
      for (var i = 0; i < data.length; i++) {
        data[i].label = data[i].title;
        data[i].id = data[i].key;
        if (data[i].isLeaf != true) {
          if (!haveChildren) {
            data[i].children = null;
          }
        } else {
          delete data[i].children;
        }
        try {
          if (data[i].isLeaf == false) {
            data[i].isDisabled = true;
          } else if (data[i].isLeaf == true) {
            data[i].isDisabled = false;
          }
        } catch (err) {
          err;
          data[i].isDisabled = false;
        }
      }
      return data;
    },
    tableFocus() {
      // debugger
      this.$nextTick(() => {
        this.$refs.input.$el.querySelectorAll("input")[0].focus();
      });
    },
    tableBlur() {
      // this.popupVisible = false;
      this.$refs.input.$el.querySelectorAll("input")[0].blur();
      this.$emit("tableBlur");
    },
    blur() {
      this.$emit("blur", this.setvalue);
      console.log(this.decorator);
    },
    focus() {
      this.$emit("focus", this.setvalue);
      console.log("kmfocus");
    },
    click() {
      this.$emit("click", this.setvalue);
      console.log("click");
    },
    defaultValueSet() {
      this.$emit("change", this.defaultValue);
      return this.defaultValue;
    },
    selectChange(v) {
      this.setvalue = this.setvalue + v;
    },
    test(v) {
      console.log(v);
    },
    onSearch(v) {
      if (!v || v == "") {
        this.option = this.allOption;
      } else {
        var rArray = [];
        for (var i = 0; i < this.allOption.length; i++) {
          var thisV = this.allOption[i];
          if (thisV.indexOf(v) != -1) {
            rArray.push(thisV);
          }
        }
        if (rArray.length == 0) {
          this.option = false;
        } else {
          this.option = rArray;
        }
      }
    },
    input() {
      this.$emit("kminput");
    },
    enter(e) {
      // if (!e.defaultPrevented) {
      this.$emit("enter", e);
      // } else {
      //   e.preventDefault();
      // }
    },
    constraintEnter(e) {
      this.$emit("constraintEnter", e);
    }
  },
  watch: {
    setvalue(value) {
      // console.log(value);
      // this.$emit("change", value);
      // this.onSearch(value);
      this.$emit("change", value);
    },
    value(value) {
      if (value == 0 || value == "") {
        value = null;
      }
      if (this.optionOk) {
        this.searchKm(value);
        this.setvalue = value;
      } else {
        this.firstCache = value;
      }
    },
    optionOk(v) {
      if (v == true && this.firstCache != false) {
        this.searchKm(this.firstCache);
        this.setvalue = this.firstCache;
        this.firstCache = false;
      }
    },
    option: {
      deep: true,
      handler: function(v) {
        this.$emit("postDataOptionSave", v);
      }
    },
    dataOptionSave: {
      deep: true,
      handler: function(val) {
        //console.log("监听einput值", val);
        this.option = val;
        this.$nextTick(() => {
          if (!this.optionOk) {
            if (this.kmNext) {
              this.optionOk = true;
            }
          }
        });
      }
    },
    kmNext(v) {
      if (v == true) {
        if (!this.optionOk) {
          this.optionOk = true;
        }
      }
    },
    disabled() {},
    modifiedValue: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(val) {
        //console.log("监听einput值", val);
        if (val) {
          // 新值和老值互换显示
          this.thisOldValue = this.setvalue;
          this.setvalue = val;
        }
      }
    }
  }
};
</script>
<style scoped lang="less">
.e-form-einput {
  position: relative;
}
.e-form-einput__textarea {
  padding-top: 6px;
  position: relative;
}
.form_right_icon {
  position: absolute;
  width: 20px;
  height: calc(100%);
  top: 0;
  right: -25px;
  z-index: 1200;
}
// ::v-deep .vue-treeselect__menu {

//   z-index: 999999;

// }
</style>
