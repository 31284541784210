/**
 * 表单保存返回错误信息，如果包含其他需要特殊处理的数据，则需要特殊处理
 */
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求

export const e1FormSaveErrorHandle = {
  mixins: [pageControls, requestControls],
  data() {
    return {
      // 错误信息是弹出表格
      errTableModalShow: false,
      errTableConfig: {
        rowKey: this.defaultTableKeyName,
        columns: []
      },
      errTableDatas: [],
      errTableSpin: true,
      errTableInfoCreateFrom: null, // 错误弹出信息，直接生成表单id
      errToCreateFormName: "" //  错误弹出信息，直接生成表单的表单名称
    };
  },
  created() {
    //console.log("混入e1FormToolbarToAssociated的通用方法");
  },
  methods: {
    /**
     * 对请求错误信息做特殊处理，如果错误信息里包含，需要展示错误明细，则需要调取回调打开错误明细表
     * @param err
     */
    changeErrAlert(err) {
      if (err) {
        let that = this;
        if (err.errorData && err.cols) {
          // 包含错误信息表，在点击提示“知道”后需要弹窗查看
          that.alertError(err.message, function() {
            that.errTableModalShow = true;
            that.updatePageData(
              "errTableConfig",
              "columns",
              that.analyticalColsArray(err.cols, null, null, null, true)
            );
            console.log("错误表格配：", that.errTableConfig);
            setTimeout(function() {
              err.errorData.map(function(data, index) {
                // 给数组对象设置一个主键字段用于表格选择
                return (data[that.errTableConfig.rowKey] = index);
              });
              that.errTableDatas = err.errorData;
              that.errTableSpin = false;
            }, 500);
          });
        } else that.alertError(err.message);
      }
    }
  }
};
