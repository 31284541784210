const controlsList = [
  {
    label: "单行文本控件",
    value: "input"
  },
  {
    label: "下拉选择控件",
    value: "select"
  },
  {
    label: "供应商选择控件",
    value: "supplier-select"
  },
  {
    label: "物料选择控件",
    value: "material-select"
  },
  {
    label: "日期时间选择控件",
    value: "datetime"
  },
  {
    label: "明细表控件",
    value: "detail-table"
  },
  {
    label: "拍照控件",
    value: "photo"
  },
  {
    label: "当前人控件",
    value: "current-user"
  },
  {
    label: "当前部门控件",
    value: "current-department"
  }
];

const changeControlData = {
  input: {
    controlType: "input",
    field: "",
    defaultValue: "",
    label: "",
    readonly: false,
    disabled: false,
    validate: ""
  },
  select: {
    controlType: "select",
    field: "",
    defaultValue: "",
    options: [{ text: "", value: "" }],
    label: "",
    validate: "",
    filter: { table: "", fieldName: "", listType: "" }
  },
  "supplier-select": {
    controlType: "supplier-select",
    field: "",
    keyField: "ID",
    defaultValue: "",
    label: "",
    validate: ""
  },
  "material-select": {
    field: "",
    lableField: "",
    keyField: "ID",
    linkageFields: [{ thisField: "", toField: "" }],
    defaultValue: "",
    label: "",
    validate: ""
  },
  datetime: {
    controlType: "datetime",
    field: "",
    defaultValue: "",
    label: "",
    validate: ""
  },
  "detail-table": {
    controlType: "detail-table",
    tableName: "",
    columns: [],
    tableName: "",
    label: "",
    hiddenFields: []
  },
  photo: {
    controlType: "photo",
    field: "",
    defaultValue: "",
    label: "",
    validate: ""
  },
  "current-user": {
    controlType: "current-user",
    field: "",
    defaultValue: "",
    label: ""
  },
  "current-department": {
    controlType: "current-department",
    field: "",
    defaultValue: "",
    label: ""
  }
};

function getControlsList() {
  return [...controlsList];
}

function getChangeControlData() {
  return JSON.parse(JSON.stringify(changeControlData));
}

export { getControlsList, getChangeControlData };
