<template>
  <div style="width:100%;height:100%;overflow:auto">
    {{ sjfw }}
    <Treeselect
      :appendToBody="true"
      :alwaysOpen="false"
      :options="Treeselectop"
    ></Treeselect>
      <keepAccountKM/>
    <a-form :form="form" @submit="check" style="width:40%">
      <a-form-item label="文件预览 EFilePreview">
        <EFilePreview :data="filesc"></EFilePreview>
      </a-form-item>
      <a-form-item label="数字范围 ENumberHorizon">
        <ENumberHorizon v-model="szfw"></ENumberHorizon> {{ szfw }}
      </a-form-item>
      <a-form-item label="时间范围 ETimeHorizon">
        <ETimeHorizon v-model="sjfw" mode="datetime"></ETimeHorizon>
      </a-form-item>
      <a-form-item label="科目选择 EAccountingSubject">
        <EAccountingSubject v-model="kjkm"></EAccountingSubject>
      </a-form-item>
      <a-form-item label="公式1 薪资公式 EXzjs">
        <EXzjs v-model="testData"></EXzjs>
      </a-form-item>
      <a-form-item label="公式2 折旧公式 EZjgs">
        <EZjgs></EZjgs>
      </a-form-item>
      <a-form-item label="公式3 EFsjeFormula">
        <EFsjeFormula></EFsjeFormula>
      </a-form-item>
      <a-form-item label="容器 EGroupBox">
        <EGroupBox
          formName="家
        "
        >
        </EGroupBox>
      </a-form-item>
      <a-form-item label="文本框 EInput">
        <EInput
          :disabled="disabled"
          :type="type"
          :placeholder="placeholder"
          @blur="blur"
          @focus="focus"
          :readOnly="false"
          :visible="true"
          :width="wi"
          v-decorator="testdecorator"
          :value="null"
        />
        <a-input
          :disabled="disabled"
          :type="type"
          :placeholder="placeholder"
          @blur="blur"
          @focus="focus"
          :readOnly="false"
          :visible="true"
          :width="wi"
          v-decorator="testdecorator"
          :value="null"
        />
      </a-form-item>
      <a-form-item label="单选 ERadio">
        <ERadio v-model="radio" :options="radiooptions"></ERadio>
      </a-form-item>
      <a-form-item lebel="下拉选择 ESelect">
        下拉选择 ESelect
        <ESelect
          :options="radiooptions"
          :mode="mode"
          :readOnly="true"
          width="80%"
          v-model="select"
          ref="selecttest"
        ></ESelect
        >
        <a-button @click="()=>{$refs.selecttest.tableSelectFocus()}">test</a-button>
      </a-form-item>
      双选 ECheckbox：
      <a-form-item lebel="双选 ECheckbox">
        <ECheckbox v-model="radio2" :options="radiooptions"></ECheckbox>
      </a-form-item>
      <a-form-item lebel="时间 EDatetimePicker">
        时间 EDatetimePicker
        <EDatetimePicker
          v-model="date1"
          :mode="datemode"
          :readOnly="true"
          width="20%"
          test="123"
        ></EDatetimePicker>
      </a-form-item>
      <a-form-item labe="部门选择 EDepartmentSelect">
        部门选择 EDepartmentSelect
        <EDepartmentSelect
          v-model="bumen"
          mode="multi"
          @change="c"
          width="300px"
        ></EDepartmentSelect>
      </a-form-item>
      <a-form-item lebel="布尔选择 EBooleanCheckbox">
        布尔选择 EBooleanCheckbox：
        <EBooleanCheckbox
          v-model="bools"
          trueLabel="是"
          falseLabel="否"
        ></EBooleanCheckbox>
      </a-form-item>
      <a-form-item label="用户选择 EEmployeeSelect">
        <EEmployeeSelect
          v-model="user"
          mode="multi"
          @change="c"
          width="400px"
        ></EEmployeeSelect>
      </a-form-item>

      <a-form-item label="供应商选择 ESupplierSelect">
        <ESupplierSelect
          v-model="gys"
          mode="multi"
          @change="c"
          width="500px"
          bdmbId="28"
        ></ESupplierSelect>
      </a-form-item>
      <a-form-item label="数字选择 ENumericUpDown">
        <ENumericUpDown
          v-model="num"
          precision="4"
          :step="0.01"
          :separator="true"
        ></ENumericUpDown>
      </a-form-item>
      <a-form-item label="物资选择 EMaterials">
        <EMaterials
          v-model="EMaterials"
          mode="multi"
          @change="c"
          width="300px"
          bdmbId="27"
        ></EMaterials>
        {{ EMaterials }}
      </a-form-item>
      <a-form-item label="模板选择 EGyBdmb">
        <EGyBdmb
          v-model="EGyBdmb"
          mode="multi"
          @change="c"
          width="300px"
        ></EGyBdmb>
      </a-form-item>
      <a-form-item label="选择仓库 EInventoryLocation">
        <EInventoryLocation></EInventoryLocation>
      </a-form-item>
      <a-form-item label="选择物资分类 EMaterialsClassify">
        <EMaterialsClassify></EMaterialsClassify>
      </a-form-item>
      <a-form-item label="客户选择 ECustomer">
        <ECustomer
          v-model="ECustomer"
          mode="multi"
          @change="c"
          width="300px"
        ></ECustomer>
      </a-form-item>
      <a-form-item label="仓库货位选择 ESTWarehouse">
        <ESTWarehouse></ESTWarehouse>
      </a-form-item>
      <a-form-item label="岗级选择 ERsGjdy">
        <ERsGjdy
          v-model="ERsGjdy"
          mode="multi"
          @change="c"
          width="300px"
        ></ERsGjdy>
      </a-form-item>
      <a-form-item label="文件上传 EUpload">
        <EUpload v-model="files"></EUpload>
      </a-form-item>
      <ETable
        :option="tableoption"
        :dataSource="etabledata"
        :fieldsAllowedEdit="fieldsAllowedEdit"
        :editOver="false"
        ref="table1"
        :allowAddRows="true"
        :disabled="true"
      ></ETable>

      钱输入控件 EMoney
      <EMoney></EMoney>

      <!-- <a-form-item>
        <a-button type="primary" html-type="submit">Submit</a-button>
      </a-form-item>
      <input @click="trues = !trues" type="button" value="获取数据" />
      <a-form-item>
        <ETable
          :option="tableoption"
          :dataSource="etabledata"
          :fieldsAllowedEdit="fieldsAllowedEdit"
          :editOver="false"
          ref="table1"
          :allowAddRows="true"
          :disabled="true"
        ></ETable>
      </a-form-item>
      <EBooleanCheckbox
        v-model="bools"
        trueLabel="ttt"
        falseLabel="fff"
      ></EBooleanCheckbox>
      <div>bool={{ bools }}</div>
      <a-table
        :columns="columns"
        :dataSource="data"
        :scroll="{ x: true }"
      >
        <a slot="action" href="javascript:;">action</a>
      </a-table> -->
    </a-form>
    <!-- <input @click="check" type="button" value="提交" /> -->
    <!-- <div>value={{ value }}</div>
    <div>value2={{ value2 }}</div>
    <div>radio={{ radio }}</div>
    <div>radio2={{ radio2 }}</div>
    <div>select={{ select }}</div>
    <div>date={{ date1 }}</div>
    <div>bumen={{ bumen }}</div>
    <div>user={{ user }}</div>
    <div>num={{ num }}</div>
    <div>ECustomer={{ ECustomer }}</div>
    <div>ERsGjdy={{ ERsGjdy }}</div>
    <div>EMaterials={{ EMaterials }}</div>
    <div>files={{ files }}</div> -->
    <!-- <div>bumenvalue={{bumenvalue}}</div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import Treeselect from "@riophae/vue-treeselect";
const columns = [
  {
    title: "Full Name",
    width: 100,
    dataIndex: "name",
    key: "name",
    fixed: "left"
  },
  { title: "Age", width: 100, dataIndex: "age", key: "age", fixed: "left" },
  { title: "Column 1", dataIndex: "address", key: "1", width: 150 },
  { title: "Column 2", dataIndex: "address", key: "2", width: 150 },
  { title: "Column 3", dataIndex: "address", key: "3", width: 150 },
  { title: "Column 4", dataIndex: "address", key: "4", width: 150 },
  { title: "Column 5", dataIndex: "address", key: "5", width: 150 },
  { title: "Column 6", dataIndex: "address", key: "6", width: 150 },
  { title: "Column 7", dataIndex: "address", key: "7", width: 150 },
  { title: "Column 8", dataIndex: "address", key: "8", width: 150 },
  {
    title: "Action",
    key: "operation",
    fixed: "right",
    width: 100,
    scopedSlots: { customRender: "action" }
  }
];

const data = [];
for (let i = 0; i < 100; i++) {
  data.push({
    key: i,
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`
  });
}

export default {
  name: "test",
  components: {Treeselect},
  data() {
    return {
      Treeselectop: [{label:"1",id:1},{label:"2",id:2}],
      szfw: null,
      data,
      columns,
      kjkm: null,
      testdecorator: [
        "username",
        { rules: [{ required: true, message: "Please input your name" }] }
      ],
      sjfw: "2020-09-01 11:11:19, 2020-08-20 11:11:19",
      trues: true,
      kkk: "kkk",
      setting: { serverName: "System.ashx", treeAction: "GetZWDYList" },
      wi: 200,
      form: this.$form.createForm(this, { name: "coordinated" }),
      value: null,
      etabledata: [{ _HH: 1 }, { _HH: 2 }, { _HH: 3 }],
      value2: "23",
      radio: "1",
      radio2: "true",
      select: "2",
      files: [],
      testv: null,
      mode: "defined",
      //date1: new moment(),
      date2: new moment(),
      date1: "04/12/2010 00:55:38",
      datemode: "time",
      bumenvalue: ["100101", "100000", "100100", "质量部"],
      bumen: ["11"],
      user: ["王海云", "0191", "M003"],
      gys: "358",
      //bumen:"1233",
      num: null,
      fieldsAllowedEdit: ["_GGXH", "_SL", "_JLDWID"],
      // fieldsAllowedEdit: [],
      ECustomer: [],
      ERsGjdy: [],
      EGyBdmb: [],
      tableC: [],
      testData: "123213",
      EMaterials: "10101010101",
      filestr:
        "/uploadfiles/2020/c822fce4-5974-45d4-8bf2-73907c210f70/DNFCalculating_MainProcess_2020_08_20_15_25_57.log,/uploadfiles/2020/26060136-f3f5-4617-849b-fb42a800003e/table选择.png",
      filesc: [
        {
          n: "DNFCalculating_MainProcess_2020_08_20_15_25_57.log",
          s: 15334,
          p:
            "/uploadfiles/2020/c822fce4-5974-45d4-8bf2-73907c210f70/DNFCalculating_MainProcess_2020_08_20_15_25_57.log"
        },
        {
          n: "table选择.png",
          s: 34152,
          p:
            "/uploadfiles/2020/26060136-f3f5-4617-849b-fb42a800003e/table选择.png"
        },
        {
          n: "单选、多选控件.png",
          s: 3215,
          p:
            "/uploadfiles/2020/bd6d85bb-9c46-4b60-babd-84b53a6989ce/单选、多选控件.png"
        },
        {
          n: "公式组合控件.png",
          s: 43383,
          p:
            "/uploadfiles/2020/1f443418-9fa9-44d2-82e5-3d5b63bbab72/公式组合控件.png"
        },
        {
          n: "上传控件.png",
          s: 31772,
          p:
            "/uploadfiles/2020/028fa4da-0c68-4089-a053-70609c69fc9f/上传控件.png"
        },
        {
          n: "psb.jpg",
          s: 70157,
          p: "/uploadfiles/2020/09e96da9-224d-4952-bd94-e3d5400f955a/psb.jpg"
        },
        {
          n: "实验.docx",
          s: 13055,
          p: "/uploadfiles/2020/2992a223-0849-456c-b14b-2bf4fc80e915/实验.docx"
        }
      ],
      radiooptions: [
        {
          label: "我1",
          value: "1",
          disabled: false
        },
        {
          label: "2",
          value: "wo2",
          disabled: false
        }
      ],
      type: "text",
      bools: true,
      disabled: false,
      placeholder: "123",
      defaultValue: "defaultValue",
      tableoption: {
        name: "CxsGrid1",
        controlType: "CxsGrid",
        location: { x: 0, y: 363 },
        size: { w: 1029, h: 245 },
        text: "",
        configs: { disable: true },
        rules: [],
        controlName: "CxsGrid1",
        readOnly: false,
        allowAddRows: false,
        multiSelect: true,
        sumRow: false,
        columns: [
          {
            controlType: "CxsGridTextBoxColumn",
            isMust: false,
            isAutoColNo: true,
            isSum: false,
            fieldName: "_HH",
            width: "250",
            headerText: "行号",
            editable: true,
            visible: false
          },
          // {
          //   controlType: "CxsGridTexBoxButtonColumn",
          //   isMust: true,
          //   fieldName: "_WZBM",
          //   editable: true,
          //   headerText: "产品编码",
          //   width: "80"
          // },
          // {
          //   controlType: "CxsGridCurrencyColumn",
          //   isMust: false,
          //   isAutoColNo: false,
          //   isSum: false,
          //   fieldName: "_WZMC",
          //   width: "150",
          //   headerText: "钱控件",
          //   readOnly: false,
          //   editable: false,
          //   editorFormType: "RS_KQLXDY",
          // },
          {
            controlType: "CxsGridTextBoxColumn",
            isMust: false,
            isAutoColNo: false,
            isSum: false,
            fieldName: "_GGXH3",
            width: "150",
            headerText: "输入控件"
          },
          {
            controlType: "CxsGridDateTimeColumn",
            isMust: false,
            isAutoColNo: false,
            isSum: false,
            fieldName: "_GGXH2",
            width: "150",
            headerText: "日期控件"
          },
          {
            controlType: "CxsUploadFilesColumn",
            isMust: false,
            isAutoColNo: false,
            isSum: false,
            fieldName: "_GGXH1",
            width: "150",
            headerText: "上传控件"
          },

          {
            controlType: "CxsGridTexBoxButtonColumn",
            editorFormType: "CW_CYZY",
            isMust: false,
            isAutoColNo: false,
            isSum: false,
            fieldName: "_SL2",
            width: "160",
            headerText: "表选择"
          },
          {
            controlType: "CxsGridComboBoxColumn",
            listFilter: {},
            isMust: false,
            listType: "None",
            fieldName: "_SHUILV",
            options: [
              { label: "0.16", value: "0.16", disabled: false },
              { label: "0", value: "0", disabled: false },
              { label: "0.05", value: "0.05", disabled: false },
              { label: "0.06", value: "0.06", disabled: false },
              { label: "0.17", value: "0.17", disabled: false }
            ],
            headerText: "下拉控件",
            width: "160"
          }
        ],
        tableName: "GXC_XSFPMXB"
      }
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "customized_form_controls"
    });
  },
  methods: {
    test() {
      if (this.datemode == "year") {
        this.datemode = "datetime";
      } else if (this.datemode == "datetime") {
        this.datemode = "year";
      }
      console.log("datemode=" + this.datemode);
    },
    blur(data) {
      console.log("blur=" + data);
    },
    focus(data) {
      console.log("focus=" + data);
    },
    datec(value) {
      this.date1 = value;
    },
    c(date1, date2) {
      console.log("change!!");
      console.log(date1);
      console.log(date2);
    },
    check() {
      this.disabled = !this.disabled;
      // this.form.setFieldsValue({
      //   username: "change",
      //   username1: "chang2"
      // });
      // debugger;
      // this.form.validateFields((err, values) => {
      //   debugger;
      //   values;
      //   if (!err) {
      //     console.info("success");
      //   }
      // });
    },
    getdata() {
      var data = this.$refs.table1.getData();
      console.log("table data = ");
      console.log(data);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .ant-table td {
  white-space: nowrap;
}
::v-deep .ant-col {
  width: 50%;
  float: left;
}
</style>
