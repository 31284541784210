<!-- 左侧树状、右侧表单编辑视图 -->
<template>
  <div>
    <div class="form-headButton" v-if="showToolbar">
      <!-- 授权操作按钮 -->
      <e-toolbar
        :buttons="buttons"
        :operationDisabled="operationDisabled"
        :size="$config.defaultControlSize"
        @click="onButtonClick"
        ref="eToolbar"
      ></e-toolbar>
    </div>
    <a-layout class="treeView-layout" :class="expandTreeViewClass">
      <a-layout-header v-if="!isNewTheme" class="view-header">
        <!--表title -->
        <e-head-title
          :title="viewTitle"
          :size="$config.defaultControlSize"
        ></e-head-title>
      </a-layout-header>
      <a-layout class="view-content">
        <!-- 左侧树focus下渲染出占位空div -->
        <!-- 左侧树-->
        <a-layout-sider
          class="view-sider tree-blur"
          :class="expandViewSilderClass"
          @blur="e => drawer(e, 'blur')"
          @focus="e => drawer(e, 'focus')"
          tabIndex="1"
        >
          <div v-if="isNewTheme" class="view-sider-leftHead">
            <!--表title -->
            <e-head-title
              :title="viewTitle"
              :size="$config.defaultControlSize"
            ></e-head-title>
          </div>
          <div :class="isNewTheme ? expandViewSilderTreeClass : ''">
            <a-spin
              :spinning="showTreeSpin"
              tip="树加载中..."
              size="large"
              class="tree-spin"
            ></a-spin>
            <a-tree
              v-if="!showTreeSpin"
              showLine
              :defaultExpandedKeys="['ROOT']"
              :defaultExpandParent="treeExpand"
              :selectedKeys="selectedNodeKeys"
              :treeData="treeData"
              @select="onTreeSelect"
              :loadData="treeLoadData"
            >
              <!-- viewConfig.viewType == 'TreeViewSelect' ? onLoadData : false -->
            </a-tree>
          </div>
        </a-layout-sider>
        <!-- 右侧表单模板 -->
        <a-layout-content class="view-main-content">
          <!-- <div class="mask" v-if="maskShow"></div> -->
          <a-form :form="form">
            <e-form
              :form="form"
              :dataId="dataId"
              :tempId="tempId"
              :disable="formDisable"
              :editOver="formEditState"
              :formConfigs="formConfigs"
              :title="dataTitle"
              :tableData="mainTableData"
              :mxOprButtons="mxOprButtons"
              ref="formTemplate"
              :scrollTop="true"
              @publicUploadChange="publicUploadChange"
            ></e-form>
          </a-form>
          <a-empty v-show="showEmpty" :description="emptyTitle"></a-empty>
        </a-layout-content>
      </a-layout>
    </a-layout>

    <!-- 其他功能区域 -->

    <!-- 审批意见确认窗 <a-icon type="form"></a-icon>-->
    <e-form-modal
      v-model="spModalShow"
      :buttons="spButtons"
      formName="审批意见表"
      @buttonClicked="doFormSp"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">表单审批</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 提交审批确认窗 -->
    <e-form-modal
      v-model="tjModalShow"
      formName="提交审批"
      :initFormData="tjDefaultData"
      :showOk="true"
      @okClicked="doFormTj"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">提交审批</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 审批记录弹出框 -->
    <e-table-modal
      v-model="spjlModalShow"
      :tableConfig="spTableConfig"
      title="审批记录"
      width="900"
      :tableDatas="spTableDatas"
      :loading="spTableSpin"
    ></e-table-modal>

    <!-- 明细数据选人弹出框 -->
    <e-table-modal
      v-if="gridImportTableConfig && gridImportTableConfig.columns"
      v-model="gridImportModalShow"
      :tableConfig="gridImportTableConfig"
      title="明细选入搜索"
      width="950"
      :tableDatas="gridImportTableDatas"
      :buttons="gridImportModalButtons"
      :loading="gridImportModalLading"
      :selectedKeys="gridImportTableSelectedKeys"
      @buttonClicked="onClickTableModal"
    ></e-table-modal>

    <!-- 明细数据选人弹出框"筛选"条件选择窗 -->
    <e-form-modal
      v-model="gridImportSelectModalShow"
      :tempId="gridImportSelectTempId"
      :showOk="true"
      @okClicked="onGridImportSelected"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">筛选条件</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 公共上传控件 -->
    <EUpload
      ref="publicUpload"
      @change="publicUploadChangeValue"
      @fileListNumChange="publicUploadNumChange"
      @CanSealFile="CanSealFile"
      v-show="false"
      :auth="
        operaterFile
          ? operaterFile
          : { CanEditFile: true, CanRemoveFile: true, CanSealFile: true }
      "
    />
  </div>
</template>

<script>
import EForm from "@/components/EForm.vue";

import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { e1Form } from "@/views/form/mixins/e1Form.js"; // 表单公共方法
import { e1FormToolbarWorkFlow } from "@/views/form/mixins/e1FormToolbarWorkFlow.js"; // e1的审批操作方法
import { e1FormToolbarGridImport } from "@/views/form/mixins/e1FormToolbarGridImport.js"; // e1的明细选人操作方法
import { mathAlgorithms } from "@/mixins/mathAlgorithms.js"; // 表单数据计算方法
import { Upload } from "@/views/form/mixins/Upload.js"; // 公共上传mixins

export default {
  mixins: [
    analyticalMenuAuth,
    e1Form,
    e1FormToolbarWorkFlow,
    e1FormToolbarGridImport,
    mathAlgorithms,
    Upload
  ],
  name: "TreeFormView",
  components: {
    EForm
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: "TreeFormView" }),
      formName: "",
      formDisable: false,
      formEditState: false,

      dhbm: null,
      tempId: null, // 模板id
      dataId: null, // 表单数据id
      fartherDataId: null, // 新建表单时保存选中的树选项ID
      defautlFormData: {},
      mxOprButtons: [], // 明细表可做操作

      showTreeSpin: false, // 树加载状态
      treeData: [], // 左侧树展示的数据
      treeCacheData: [], // 左侧所有数据
      selectedNodeKeys: [], // 选中的树节点，多选时需要

      thisConfig: null, // 当前页面配置
      viewConfig: null, // 当前视图配置
      getTreeTempIdFieldName: null,
      treeOneDefaultFormLoad: false, // 是否默认选中第一个节点对象
      initFilter: null, // 查询子节点使用的条件语句
      buttons: [], // 当前用户可用的操作按钮
      operationDisabled: false, // 操作按钮可用

      treeLoadData: undefined,
      treeExpand: this.$config.TREE_DEFAULT_EXPANDALL,
      parentID: null,
      saveType: 0, // 0:新建，1:编辑保存
      dataTitle: "新建",
      showEmpty: true,
      emptyTitle: null,
      maskShow: false // 右树状图遮罩层是否显示
    };
  },
  props: {
    pageConfig: {
      //包含视图结构、视图数据来源、操作权限配置
      type: Object,
      default: function() {
        return {};
      }
    },
    viewTitle: {
      type: String,
      default: ""
    }
  },
  watch: {
    pageConfig: function(nDatas) {
      this.thisConfig = nDatas;
    }
  },
  computed: {
    // 根据当前设置的全局尺寸，改变界面样式
    expandTreeViewClass: function() {
      return this.getClassNameBySize("treeView-layout", this.showToolbar);
    },
    expandViewSilderClass: function() {
      return this.getClassNameBySize("view-sider", this.showToolbar);
    },
    showToolbar: function() {
      return this.checkToobleIsShow(this.buttons);
    },
    expandViewSilderTreeClass: function() {
      let classes = [];
      classes.push("view-sider-tree");
      classes.push(this.getClassNameBySize("view-sider-tree", true));
      return classes;
    }
  },
  created() {},
  mounted() {
    this.thisConfig = this.pageConfig;
    this.viewConfig = this.thisConfig.viewConfig;
    console.log("this.thisConfig", this.thisConfig);
    if (this.viewConfig) {
      this.getTreeTempIdFieldName = this.viewConfig.formFields;
      this.treeOneDefaultFormLoad = !!this.viewConfig.treeOneDefaultFormLoad;
      if(this.viewConfig.fromCannotEdit) { // 表单不能编辑了
        this.formEditState = true;
      }
      // 取得树配置
      if (this.viewConfig.rootText) {
        // 设置了根节点名称
        this.treeData.push({
          title: this.viewConfig.rootText,
          key: "ROOT",
          children: []
        });
        if (!this.getTreeTempIdFieldName && !this.treeOneDefaultFormLoad)
          // 设置了根节点名称,则默认选择根节点
          this.selectedNodeKeys = ["ROOT"];
      }

      this.getTreeData(); // 加载树
      this.tempId = this.viewConfig.templateId;
      this.dhbm = this.thisConfig.DHBM;
      if (this.tempId) {
        if (!this.treeOneDefaultFormLoad) {
          this.showEmpty = false;
          this.loading();
          this.getFormTemplate(); // 加载表单
        }
      } else if (this.getTreeTempIdFieldName) {
        this.emptyTitle = "请点击左侧树的末端节点，加载表单";
      } else {
        this.emptyTitle = "错误的视图配置";
        this.alertError("表单模板配置错误");
      }
    } else {
      this.emptyTitle = "错误的视图配置";
      this.alertError("树形表单编辑视图配置错误");
    }
    //分析出用户可做操作按钮
    //this.buttons = this.getUserJurisdictionButtons(this.thisConfig);
  },
  methods: {
    /**
     * 加载树的一级数据
     */
    getTreeData() {
      let that = this;
      let params = {};
      if (this.viewConfig.viewType === "treeFormView") {
        let mx = this.viewConfig.fields;
        if (this.initFilter) {
          this.treeExpand = true;
        }
        params = {
          action: "GetTreeViewList",
          tb_name: this.viewConfig.tableName, //主表名
          fieldNames: JSON.stringify(mx), //明细字段名
          bm: this.viewConfig.viewBM,
          t_id: this.viewConfig.templateId,
          m_id: this.initFilter ? this.initFilter : "", // 其他过滤条件
          randomKey: this.$store.getters.getRandomKey
        };
      }
      if (this.viewConfig.viewType === "TreeViewSelect") {
        let mx = this.viewConfig.treeFields;
        if (this.viewConfig.treeInitFilter) {
          // 如果设置了查询条件，则可以全部默认展开
          this.treeExpand = true;
        } else {
          this.treeExpand = false;
        }
        params = {
          action: "GetTreeViewList",
          tb_name: this.viewConfig.treeTableName, //主表名
          fieldNames: JSON.stringify(mx), //明细字段名
          bm: this.viewConfig.viewBM,
          t_id: this.viewConfig.treeTemplateId,
          m_id: this.viewConfig.treeInitFilter
            ? this.viewConfig.treeInitFilter
            : "", // 其他过滤条件
          randomKey: this.$store.getters.getRandomKey
        };
      }
      this.showTreeSpin = true;
      this.get("Form.ashx", params, {
        success: function(req) {
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? req.data:req;
          let mData = reqData.data;
          if (mData && mData.length > 0) {
            that.treeData[0].children = [];
            for (let i = 0; i < mData.length; i++) {
              // 接口有isleaf 传过来，这边不需要，删除
              if (that.viewConfig.viewType === "TreeViewSelect") {
                delete mData[i].isLeaf;
              }
              that.treeData[0].children.push(mData[i]);
            }
            if (that.dataId) {
              that.selectedNodeKeys = [that.dataId + ""];
              let selectedData = that.$util.getObjByParamAndValue(
                that.treeData,
                "key",
                that.dataId + ""
              );
              if (selectedData) that.dataTitle = selectedData.title;
              console.log(
                "选中的默认节点",
                that.selectedNodeKeys,
                selectedData
              );
              that.fartherDataId = that.dataId;
              that.saveType = 1;
              //that.selectedNodeKeys.push(that.dataId);
            } else if (that.treeOneDefaultFormLoad) {
              // 首次加载，默认显示第一个表单内容
              that.showEmpty = false; // 加载表单
              that.loading();
              that.getFormTemplate(function() {
                if (mData.length > 0)
                  that.onTreeSelect([mData[0].key], { node: mData[0] });
              });
            }
            that.showTreeSpin = false;
            if (that.viewConfig.viewType === "TreeViewSelect") {
              //viewConfig.viewType != 'TreeViewSelect'
              that.treeLoadData = that.onLoadData;
            } else {
              that.treeExpand = that.$config.TREE_DEFAULT_EXPANDALL;
            }
          } else {
            that.showTreeSpin = false;
            that.alertError(
              "未获取到" +
                (that.viewConfig.rootText
                  ? that.viewConfig.rootText
                  : "对应的根节点下") +
                "数据"
            );
          }
        },
        error: function(err) {
          console.log(err);
          that.showTreeSpin = false;
          that.alertError("请求失败，获取树数据失败");
        }
      });
    },
    /**
     * 选中树事件响应
     * @param selectedKeys
     */
    onTreeSelect(selectedKeys, e) {
      //console.log("设置了选择节点", selectedKeys, e);
      if (selectedKeys.length > 0 && "ROOT" !== selectedKeys.toString()) {
        if (this.getTreeTempIdFieldName) {
          // 通过树节点带人TempID,这种情况目前是新增表单操作
          if (e.node.isLeaf) {
            // 只有末端节点可以点击进入
            let that = this;
            this.saveType = 0;
            this.dataId = selectedKeys.toString();
            this.selectedNodeKeys = selectedKeys;
            this.tempId = e.node.dataRef[this.getTreeTempIdFieldName];
            this.showEmpty = false;
            this.loading();
            this.dataTitle = e.node.title;
            this.getFormTemplate(function() {
              that.getThisFormData();
            });
          } else {
            // 其他节点都清空表单
            this.dataId = null;
            this.dataTitle = "新建";
            this.tempId = null;
            this.showEmpty = true;
            this.operationDisabled = true;
            this.hideForm();
          }
        } else {
          // 其他普通树表单
          this.saveType = 1;
          this.dataId = selectedKeys.toString();
          this.fartherDataId = this.dataId;
          this.selectedNodeKeys = selectedKeys;
          //this.selectedNodeKeys.push(this.dataId);
          this.loading();
          this.dataTitle = e.node.title;
          this.getThisFormData();
        }
      } else if ("ROOT" == selectedKeys.toString()) {
        // 点击了根节点，直接清空页面内容
        this.selectedNodeKeys = selectedKeys;
        this.fartherDataId = null;
        this.setPageReset();
      }
    },
    /**
     * 设置重置页面
     */
    setPageReset() {
      if (this.getTreeTempIdFieldName) {
        this.setTreeReset();
        this.showEmpty = true;
        this.hideForm();
      } else this.tsbNew();
    },
    /**
     * 设置重置页面
     */
    setTreeReset() {
      this.dataId = null;
      this.dataTitle = "新建";
      this.operationDisabled = true;
      if (this.viewConfig.rootText && this.getTreeTempIdFieldName) {
        // 在不是通过节点获取表单模板的情况下
        // 设置了根节点名称，则设置根节点默认选中，并且当前是新建状态
        this.saveType = 0;
        this.selectedNodeKeys = ["ROOT"];
      }
    },
    /**
     * 获取表单模板
     * @param success
     */
    getFormTemplate(success) {
      let that = this;
      this.getForm(
        "GetFormTemplate",
        this.tempId,
        { id: this.dataId, dmbh: this.dhbm },
        function(data) {
          //如果配置了视图内按钮定义，则不从表单模板获取
          if (
            !!that.viewConfig.operationConfig &&
            that.$util.isType(that.viewConfig.operationConfig.operation) ===
              "Array" &&
            that.viewConfig.operationConfig.operation.length > 0
          ) {
            that.buttons = that.viewConfig.operationConfig.operation;
            that.mxOprButtons = that.viewConfig.operationConfig.rowButtons;
          } else {
            if (data.buttons) {
              // 获取表单对应的操作按钮
              that.buttons = data.buttons;
              that.mxOprButtons = data.rowButtons; // 明细表可做操作按钮
              if (!that.checkToobleIsShow(that.buttons)) {
                that.hideToolBar();
              }
            }
          }
          that.defautlFormData = Object.assign({}, that.mainTableData);
          console.log("表单加载时-默认的选中项", that.mainTableData);
        },
        success
      );
    },
    /**
     * 获取表单的数据
     */
    getThisFormData() {
      let that = this;
      let saveParam = this.checkOperationAuth(
        that.thisConfig,
        "getData",
        () => {
          that.alertError("您没有查询本表单数据的权限");
        }
      );
      if (saveParam) {
        that.getFormData(
          that.tempId,
          that.dataId,
          saveParam.bm,
          saveParam.et_name,
          function() {
            if (that.saveType === 1 && that.viewConfig.fieldKey) {
              // 包含主表单的关联上级的字段
              that.parentID = that.mainTableData[that.viewConfig.fieldKey];
            }
            that.loadingOk();
          }
        );
      } else {
        this.loadingError();
      }
    },
    /**
     * 点击“删除”按钮
     * @param button
     * @param other
     */
    tsbDelete(button, other) {
      if (this.dataId) {
        if (other && other.prioriCondition) {
          // 满足前置条件还需要确认是否操作
          this.$refs.eToolbar.showConfirm(button.tsbName);
        } else {
          this.doDelete(button);
        }
      } else {
        this.alertError("请选择一条需要删除的" + this.title + "");
      }
    },
    /**
     * 执行删除操作
     */
    doDelete(button) {
      if (this.dataId) {
        let that = this;
        let saveParam = that.checkOperationAuth(
          that.thisConfig,
          "tsbDelete",
          () => {
            that.alertError("您没有删除本表单的权限");
          }
        );
        if (saveParam) {
          let params = {
            action: "DeleteData",
            tb_name: this.mainTableName, //主表名
            mxb_name: this.mxTableName.toString(), // 明细表名称
            bm: saveParam.param.bm,
            t_id: this.tempId, // 模板id  从DHCS中获取
            d_id: this.dataId, // 插入之后返回的主键id
            randomKey: this.$store.getters.getRandomKey
          };
          button.loading = true;
          this.post("Form.ashx", params, {
            success: function() {
              //console.log("请求结果", req);
              button.loading = false;
              that.alertSuccess("删除成功");
              that.getTreeData();
              that.setPageReset();
            },
            error: function(err) {
              button.loading = false;
              that.alertError(err.message);
            }
          });
        }
      } else {
        this.alertError("请选择一条需要删除的" + this.title + "");
      }
    },
    /**
     *  新建表单数据页面,作用是清空右侧表单内容
     */
    tsbNew() {
      if (this.tempId) {
        this.setTreeReset();
        this.loading();
        this.saveType = 0;
        console.log("默认的选中项", this.defautlFormData);
        this.mainTableData = Object.assign({}, this.defautlFormData);
        this.setMxTableData([]);
        let that = this;
        setTimeout(function() {
          that.loadingOk();
        }, 300);
      } else {
        this.alertError("不存在的模板ID");
      }
    },
    /**
     * 点击“取消”按钮，将填入的数据清空或还原
     */
    tsbCancel() {
      this.form.resetFields();
    },
    /**
     * 点击“保存”按钮
     * @param button
     */
    tsbSave(button) {
      let that = this;
      this.form.validateFields((err, values) => {
        console.log("数据信息：" + JSON.stringify(values));
        if (!err) {
          let saveParam = null;
          let dataId = 0;
          if (that.saveType == 1) {
            // 编辑模式下的修改保存,tsbEdit是隐藏按钮
            saveParam = that.checkOperationAuth(
              that.thisConfig,
              "tsbEdit",
              () => {
                that.alertError("您没有保存本表单的权限");
              }
            );
            if (that.dataId) {
              dataId = that.dataId;
              if (that.viewConfig.fieldKey && that.parentID) {
                // 包含主表单的关联上级的字段
                values[that.viewConfig.fieldKey] = that.parentID;
              }
            }
          } else {
            // 新建默认保存
            saveParam = that.checkOperationAuth(
              that.thisConfig,
              "tsbSave",
              () => {
                that.alertError("您没有新建保存本表单的权限");
              }
            );
            if (that.fartherDataId) {
              dataId = that.fartherDataId;
              if (that.viewConfig.fieldKey) {
                // 包含主表单的关联上级的字段
                values[that.viewConfig.fieldKey] = that.fartherDataId;
              }
            }
          }
          if (saveParam) {
            button.loading = true;

            let params = {
              action: "SaveFormData",
              data: JSON.stringify(values), // 主表数据
              children: that.getMxDataJSON(), // 子表数据
              tb_name: that.mainTableName, // 主表名称 从DHCS中获取
              mxb_name: that.mxTableName.toString(), // 明细表名称
              bm: saveParam.param.bm, // 类库名称（SYSTEM）、从GJLCS中获取 {tsbNew,SYSTEM,1,980}
              t_id: that.tempId, // 模板id  从DHCS中获取
              et_name: saveParam.param.et_name, //  表示操作类型  添加为0，修改为1，删除为2 等，从GJLCS中获取 {tsbNew,SYSTEM,1,980}
              m_id: "",
              d_id: dataId, // 保存时如果有值，表示是该数据的父节点id
              randomKey: that.$store.getters.getRandomKey
            };
            that.post("Form.ashx", params, {
              success: function(req) {
                console.log("请求结果", req.data);
                if (req.data.dataId) {
                  that.dataId = req.data.dataId;
                  if (
                    req.data.mainTableData &&
                    that.$util.isType(req.data.mainTableData) === "Object" &&
                    that.$util.isValidObject(req.data.mainTableData)
                  ) {
                    values = Object.assign({}, values, req.data.mainTableData);
                    // 提交主题？？？？？？？？？？？？？？
                    let bdbh = req.data.mainTableData._BDBH;
                    if (bdbh) {
                      that.mainTableData = values;
                      let mc = that.mainTableData._BDMC
                        ? that.mainTableData._BDMC
                        : that.title;
                      that.tjDefaultData._zt = mc + "_" + bdbh;
                    }
                  }
                }
                // 返回了明细数据,需要回填
                if (
                  req.data.childrenDatas &&
                  req.data.childrenDatas.length > 0
                ) {
                  that.setMxTableData(req.data.childrenDatas);
                  that.allChildrenTablesDatas = req.data.childrenDatas;
                }
                // 是否有流程需要走，如果有则显示提交按钮
                if (req.data.workFlow && req.data.workFlow.isWorkFlow) {
                  that.$refs.eToolbar.eToolbarButtonToggleDisplay(
                    "tsbWorkFlowCreater"
                  );
                }
                that.alertSuccess("保存成功");
                if (that.getTreeTempIdFieldName && that.saveType == 0) {
                  // 在通过树数据获取模板ID是，新建保存
                } else {
                  // if(!that.viewConfig.formEditNoReloadTree)
                  that.getTreeData(); // 重新加载树
                }
                button.loading = false;
              },
              error: function(err) {
                console.log("请求失败", err);
                that.alertError(err.message);
                button.loading = false;
              }
            });
          }
        } else {
          //console.log( JSON.stringify(err));
          let errMsg = that.getDecoratorErrInfo(err);
          that.alertError(errMsg);
        }
      });
    },
    /**
     * 异步加载树的子节点数据
     * @param treeNode
     * @returns {Promise<any>}
     */
    onLoadData(treeNode) {
      let _this = this;
      let mx = this.viewConfig.fields;
      return new Promise(resolve => {
        if (treeNode.dataRef.children == "") {
          let m_id = "ID = " + treeNode.dataRef.Id;
          let did = treeNode.dataRef.Id;
          if (_this.viewConfig.initFilter) {
            // 如果配置了查询初始化条件，则需要根据条件拼接m_id数据
            if (did) {
              m_id = _this.viewConfig.initFilter.replace(
                "[Id]",
                treeNode.dataRef.Id
              );
              did = null;
            } else {
              resolve();
              return;
            }
          }
          let params = {
            action: "GetTreeViewList",
            tb_name: _this.viewConfig.tableName, //主表名
            fieldNames: JSON.stringify(mx), //明细字段名
            bm: _this.viewConfig.gridViewBM,
            t_id: this.viewConfig.templateId,
            d_id: did,
            // et_name: 989,
            m_id: m_id,
            randomKey: _this.$store.getters.getRandomKey
          };
          _this.get("Form.ashx", params, {
            success: function(res) {
              if (res.success) {
                //兼容202102之前老版本接口
                let reqData = window.USE_NEW_API ? res.data:res;
                let data = reqData.data;
                if (!data) {
                  // 如果没有下一项，设置为true
                  treeNode.dataRef["isLeaf"] = true;
                  resolve();
                }
                for (let i = 0; i < data.length; i++) {
                  // 接口有isleaf 传过来，这边不需要，删除
                  delete data[i].isLeaf;
                }
                treeNode.dataRef.children = data;
                resolve();
              }
            },
            error: function() {}
          });
        } else {
          resolve();
        }
      });
    },
    drawer(e, type) {
      if (type == "focus") {
        if (e.target.className.indexOf("tree-focus") == -1) {
          e.target.className += " tree-focus";
          this.maskShow = true;
        }
      }
      if (type == "blur") {
        e.target.classList.remove("tree-focus");
        this.maskShow = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/tree-form-view.less";
</style>
