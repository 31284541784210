<template>
  <div id="app" @click="globalOnClick">
    <router-view />
  </div>
</template>
<script>
import Axios from "axios";

export default {
  created() {
    Axios.defaults.headers.common[
      "Authorization"
    ] = this.$store.getters.getRandomKey;
    window.globalOnBlurListener = {};
    // 设置tip消息的样式，可将这些配置放入全局配置中
    this.$message.config({
      top: `53px`,
      duration: 2,
      maxCount: 3
    });
    let that = this;
    // 监听窗口大小变化
    window.onresize = function() {
      that.$root.NowWindowsSize = that.$util.findDimensions();
      for (let key in window.etable) {
        let etableObject = document.getElementById(key);
        if (etableObject && etableObject.length != 0) {
          //   //这句是说如果我们点击到了id为myPanel以外的区域
          window.etable[key]["scroll"](true, "app");
        } else {
          delete window.etable[key];
        }
      }
    };
    // 全局阻止一些定制快捷键
    window.onkeydown = this.stopKeyDown;
  },
  mounted() {
    // 监听滚动条事件，用于处理明细表编辑行跟随
    document.getElementById("app").addEventListener(
      "scroll",
      e => {
        if (e.target.className.indexOf("vxe-table") == -1) {
          for (let key in window.etable) {
            let etableObject = document.getElementById(key);
            if (etableObject && etableObject.length != 0) {
              //   //这句是说如果我们点击到了id为myPanel以外的区域
              window.etable[key]["scroll"]();
            } else {
              delete window.etable[key];
            }
          }
        }
      },
      true
    );
    // 添加关于填制凭证f5f6问题的事件
    document.body.addEventListener(
      "keydown",
      e => {
        this.keepKeydown(e);
      },
      true
    );
  },
  destroyed: function() {
    //清除查询缓存
    this.$store.commit("clearSearchMenuHistory");
    sessionStorage.removeItem("ViewCache");
  },
  methods: {
    /**
     * 如果是非内容编辑，则需要屏蔽退格删除键
     */
    stopKeyDown(){
      if(window.BROWSER_BLOCK_SHORTCUT_KEYS &&
        window.BROWSER_BLOCK_SHORTCUT_KEYS.includes(window.event.keyCode)) {
        if (window.event.keyCode == 8) {
          if (!this.$store.state.canBackSpace) {
            window.event.returnValue = false;
          }
        }
      }
    },
    /**
     * 检测点击获得焦点的对象是否是可编辑对象
     */
    checkCanBackSpace(event) {
      if(event && event.target) {
        if(event.target.tagName === "INPUT" && !event.target.disabled) {
          this.$store.state.canBackSpace = true;
          return;
        } else if(event.target.className && event.target.className.includes
            && event.target.className.includes("ant-select-selection-selected-value")
            && event.defaultPrevented) {
          this.$store.state.canBackSpace = true;
          return;
        } else if(event.target.tagName === "TEXTAREA" && !event.target.disabled) {
          this.$store.state.canBackSpace = true;
          return;
        }
      }
      this.$store.state.canBackSpace = false;
    },
    /**
     * 响应整个文档内触发的click事件
     * @param event
     */
    globalOnClick(event) {
      console.log("监听到点击事件",event);
      this.checkCanBackSpace(event);
      // 检查设置了对象全局监听失去焦点事件的对象，是否要触发该对象对应的失去焦点方法
      for (let key in window.globalOnBlurListener) {
        let documentElement = document.getElementById(key);
        console.log("app click", documentElement);
        if (documentElement && documentElement.length != 0) {
          //这句是说如果我们点击到了id为key的节点以外的区域
          this.$nextTick(() => {
            console.log(
              "event.target",
              event.target,
              documentElement.contains(event.target)
            );

            // console.log(
            //   "documentElement.outerHTML", documentElement.outerHTML,
            //   "event.target.outerHTML", event.target.outerHTML,
            //   "documentElement.contains(event.target)", documentElement.contains(event.target),
            //   "document.body.contains", document.body.contains(event.target));
            console.log(document.body.contains(event.target));

            if (!documentElement.contains(event.target)) {
              if (window.globalOnBlurListener[key])
                window.globalOnBlurListener[key](null, true);
            }
          });
        } else {
          // delete window.globalOnBlurListener[key];
        }
      }
    },
    // 填制凭证f5f6保存新建
    keepKeydown(e) {
      for (let key in window.keepKeydown) {
        let keepDom = document.getElementById(key);
        if (keepDom) {
          //   //这句是说如果我们点击到了id为myPanel以外的区域
          window.keepKeydown[key](e);
        } else {
          delete window.keepKeydown[key];
        }
      }
    }
  }
};
</script>
<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
