<template>
  <div
    v-show="visible"
    :id="domId"
    :ref="domId + 'div'"
    v-on:copy="copyHead"
    v-on:paste="pasteHead"
    @keydown="keydown"
  >
    <!-- 工具栏 -->
    <div class="view-table-filter">
      <!-- <a-button @click="debuggers">test</a-button> -->
      <!-- <a-button @click="handleAddNum">testadd</a-button> -->
      <div
        style="float:left;"
        v-if="(!editOver && !disabled) || showButton"
      >
        <a-popover
          title="新增明细表数据"
          v-model="addVisible"
          trigger="focus"
        >
          <template slot="content">
            <div>
              <a-input-group compact>
                <a-input-number
                  v-model="addNum"
                  :min="1"
                  :max="999"
                  size="small"
                />
                <a-button
                  @click.stop="
                    () => {
                      handleAdd(addNum);
                      addVisible = false;
                    }
                  "
                  size="small"
                >
                  <a-icon type="check" />
                </a-button>
                <a-button
                  @click.stop="
                    () => {
                      addVisible = false;
                    }
                  "
                  size="small"
                >
                  <a-icon type="close" />
                </a-button>
              </a-input-group>
            </div>
          </template>
          <!-- <a-button
            class="editable-add-btn"
            :size="controlSize"
            v-if="
              buttonConfig.findIndex((item) => item.tsbName === 'tsbAddRow') !=
                -1 || allowAddRows
            "
            ><a-icon type="plus-circle" />新增</a-button
          >-->
          <a-dropdown-button
            @click.stop="handleAdd()"
            size="small"
            :type="buttonType"
            v-if="addButton || allowAddRows"
            class="editable-add-btn"
          >
            <a-icon type="plus-circle" />新增
            <a-menu
              slot="overlay"
              @click="handleAddNum"
              placement="bottomLeft"
            >
              <a-menu-item
                v-for="key1 in $config.formDetailAddRowModes"
                :key="key1"
              >
                <a-icon type="plus" />新增{{
                  key1 === "custom" ? "任意" : key1
                }}行
              </a-menu-item>
              <a-menu-item
                v-if="
                  addButton && checkCanCustomBatchAdd(addButton.customBatchAdd)
                "
                :key="addButton.customBatchAdd.customBatchAddFiled"
              >
                <a-icon type="plus" />
                {{ addButton.customBatchAdd.customBatchAddFiledName }}
              </a-menu-item>
            </a-menu>
            <a-icon
              slot="icon"
              type="down"
            />
          </a-dropdown-button>
        </a-popover>

        <a-popconfirm
          okText="是"
          cancelText="否"
          :visible="delButtonConfirmVisible"
          @click.stop="() => {}"
          @confirm="handleDel"
          @cancel="delButtonConfirmVisible = false"
          v-if="
            buttonConfig.findIndex(item => item.tsbName === 'tsbDelRow') !=
              -1 || allowDeleteRows
          "
          @visibleChange="delButtonConfirmVisibleChange"
        >
          <template slot="title">
            <p>是否确定删除?</p>
          </template>
          <a-button
            class="editable-del-btn"
            :size="controlSize"
            @click.stop="() => {}"
            :type="buttonType"
            :ghost="deleteButtonGhost"
          >
            <a-icon type="delete" />删除
          </a-button>
        </a-popconfirm>
        <!-- 批量选择插入多条对应字段选择值的明细数据 -->
        <div
          style="display: none;"
          v-if="customBatchAddControl"
        >
          <EMaterials
            v-if="customBatchAddControl.editorFormType == 'Materials'"
            :value="customBatchAddControl.defaultVaule"
            @change="
              (value, chinese, linkedClumns) => {
                onSelectCustomBatchAdd(value, linkedClumns);
              }
            "
            :linkage="customBatchAddControl.linkColumns"
            :bdmbId="tempId"
            width="100%"
            mode="multi"
            :search="
              customBatchAddControl.listFilter
                ? customBatchAddControl.listFilter.filter
                : ''
            "
            :filterSave="
              filterSave[customBatchAddControl.editorFormType]
                ? filterSave[customBatchAddControl.editorFormType]
                : null
            "
            @filterSave="
              data => {
                filterSave[customBatchAddControl.editorFormType] = data;
              }
            "
            :ref="'CustomBatchAdd_' + customBatchAddControl.customBatchAddFiled"
          ></EMaterials>
        </div>
      </div>
      <div style="float:right;">
        <!--筛选-->
        <e-table-content-filter
          @screen="screen"
          :columns="columns"
          :dataSource="data"
        ></e-table-content-filter>
      </div>
    </div>
    <div
      style="position: relative;"
      v-if="false"
    >
      <a-button
        size="small"
        @click.stop="moveScroll('left')"
        @mousedown.stop="moveScrollStart('left')"
        @mouseup.stop="moveScrollEnd('left')"
        class="sc-left"
      >
        <a-icon type="left"></a-icon>
      </a-button>
      <a-button
        size="small"
        @click.stop="moveScroll('right')"
        @mousedown.stop="moveScrollStart('right')"
        @mouseup.stop="moveScrollEnd('right')"
        class="sc-right"
      >
        <a-icon type="right"></a-icon>
      </a-button>
    </div>

    <div
      :class="domId + 'bottom-scroll bottom-scroll'"
      :ref="domId + 'bottom'"
      :style="{ right: '30px', position: scrollTop ? 'initial' : 'absolute' }"
      @scroll="bottomScroll"
      v-show="!domScrollHide"
    >
      <div
        :style="{ height: '1px' }"
        :ref="domId + 'bottom-scroll'"
      ></div>
    </div>

    <div :class="getTableBoxClass">
      <div
        class="relative"
        :ref="domId + 'relative'"
      >
        <!-- 要设height，max-height 编辑会对不上，有问题 -->
        <!-- @cell-dblclick="clickTableCell" -->
        <vxe-grid
          border
          resizable
          :stripe="$config.table_stripe"
          show-header-overflow
          show-footer-overflow
          :ref="domId"
          :data="screenMode ? screenData : data"
          @checkbox-change="checkboxChangeEvent"
          :row-id="rowKey"
          :size="tableSize_MiXin"
          highlight-hover-row
          show-overflow
          height="auto"
          @cell-click="clickTableCell"
          @scroll="setTableScrollEvent"
          @resizable-change="setTableResizable"
          @header-cell-click="activeHeadTitle"
          :scroll-y="{
            gt: screenMode
              ? screenData.length > 100
                ? 0
                : -1
              : data.length > 100
              ? 0
              : -1
          }"
          :scroll-x="{ gt: -1 }"
          :footer-method="footerMethod"
          :show-footer="config.sumRow"
          :empty-render="vxeTableEmptyRender"
          header-row-class-name="e-table-header_style"
          header-cell-class-name="e-table-header-cell_style"
          :row-class-name="rowClassName"
          :auto-resize="true"
          @keydown="keydown"
          :checkbox-config="{ checkMethod: checkBoxDisable }"
        >
          <!-- e-table-row_style -->
          <!-- @cell-click="clickTableCell" -->
          <vxe-table-column
            type="checkbox"
            width="60"
            :resizable="false"
            header-class-name="head-color"
            align="center"
          ></vxe-table-column>
          <template v-for="(col, index) in columns.filter(item => !item.hideThis)">
            <!-- {{col.controlType}} -->
            <vxe-table-column
              :key="domId + col.dataIndex + '-' + index"
              :field="col.dataIndex"
              :min-width="col.width"
              :header-class-name="
                '#header#' + col.dataIndex + col.active == true
                  ? ' head-active head-color'
                  : ' head-color'
              "
              :align="col.align"
              :formatter="col.formatter"
              :class-name="col.active ? ' head-active' : ''"
              show-overflow="tooltip"
            >
              <template v-slot:header>
                <div style="display: flex;align-items: center; ">
                  <!-- <span v-if="col.isMust" style="color:red">* </span> -->
                  <span :class="col.isMust ? 'isMust' : ''">{{
                    col.title
                  }}</span>
                </div>
              </template>
              <template v-slot="{ row }">
                <!-- v-for="(checkOk, checkKey) in checkData(row[col.dataIndex], col.dataIndex)"
                :style="{'background-color': checkOk == true ? '' : 'rgb(249 0 0 / 26%)'}"-->
                <div
                  :class="domId + 'col' + row[rowKey]"
                  v-for="(checkOk, checkKey) in checkData(
                    row[col.dataIndex],
                    col.dataIndex
                  )"
                  :style="{
                    'background-color':
                      checkOk == true ? '' : 'rgb(249 0 0 / 26%)'
                  }"
                  :key="domId + checkKey"
                >
                  <template v-if="
                      col.controlType === 'CxsGridComboBoxColumn' ||
                        col.controlType === 'CxsGridComboEditBoxColumn' ||
                        col.controlType === 'CxsGridCheckBoxColumn'
                    ">
                    <div style="float:left;display: flex;width: 100%; justify-content: center;">
                      <template v-if="
                          col.controlType === 'CxsGridComboBoxColumn' ||
                            col.controlType === 'CxsGridComboEditBoxColumn'
                        ">
                        <!-- {{ getCacheOk[col.dataIndex] }} -->
                        <template v-if="getCacheOk[col.dataIndex]">
                          {{
                            selectCacheText(
                              row[col.dataIndex],
                              col.dataIndex,
                              row[rowKey],
                              null,
                              col.isMust
                            )
                          }}
                          <ChangeIcon
                            v-if="
                              row['etable_changeValues'] &&
                                row['etable_changeValues']['editFind'][
                                  col.dataIndex
                                ] &&
                                row['etable_changeValues'].operateType != 1
                            "
                            :title="
                              selectCacheText(
                                row['etable_changeValues']['editFind'][
                                  col.dataIndex
                                ],
                                col.dataIndex,
                                row[rowKey],
                                null
                              )
                            "
                            :type="row['etable_changeValues'].operateType"
                          ></ChangeIcon>
                        </template>
                      </template>
                      <template v-if="col.controlType === 'CxsGridCheckBoxColumn'">
                        {{
                          checkBoxCacheText(
                            row[col.dataIndex],
                            col.dataIndex,
                            row[rowKey]
                          )
                        }}
                        <ChangeIcon
                          v-if="
                            row['etable_changeValues'] &&
                              row['etable_changeValues']['editFind'][
                                col.dataIndex
                              ] &&
                              row['etable_changeValues'].operateType != 1
                          "
                          :title="
                            col.format
                              ? colFormat(
                                  row['etable_changeValues']['editFind'][
                                    col.dataIndex
                                  ],
                                  col.format,
                                  {
                                    index: row[rowKey],
                                    dataIndex: col.dataIndex,
                                    row: row
                                  }
                                )
                              : row['etable_changeValues']['editFind'][
                                  col.dataIndex
                                ]
                          "
                          :type="row['etable_changeValues'].operateType"
                        ></ChangeIcon>
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="col.controlType === 'CxsGridDateTimeColumn'">
                      <span
                        class="no-must"
                        v-if="
                          col.isMust &&
                            (row[col.dataIndex] == '' ||
                              row[col.dataIndex] == null) &&
                            !row.editable
                        "
                      >
                        <template v-if="
                            row['etable_changeValues'] &&
                              row['etable_changeValues']['editFind'][
                                col.dataIndex
                              ]
                          ">
                          *
                          <ChangeIcon
                            v-if="
                              row['etable_changeValues'] &&
                                row['etable_changeValues']['editFind'][
                                  col.dataIndex
                                ] &&
                                row['etable_changeValues'].operateType != 1
                            "
                            :title="
                              FormattingTime(
                                row['etable_changeValues']['editFind'][
                                  col.dataIndex
                                ],
                                col.format
                              )
                            "
                            :type="row['etable_changeValues'].operateType"
                          ></ChangeIcon>
                        </template>
                        <v-else>*</v-else>
                      </span>
                      <template v-else>
                        {{ FormattingTime(row[col.dataIndex], col.format) }}
                        <ChangeIcon
                          v-if="
                            row['etable_changeValues'] &&
                              row['etable_changeValues']['editFind'][
                                col.dataIndex
                              ] &&
                              row['etable_changeValues'].operateType != 1
                          "
                          :title="
                            FormattingTime(
                              row['etable_changeValues']['editFind'][
                                col.dataIndex
                              ],
                              col.format
                            )
                          "
                          :type="row['etable_changeValues'].operateType"
                        ></ChangeIcon>
                      </template>
                    </template>
                    <template v-else-if="col.controlType === 'CxsUploadFilesColumn'">
                      <span
                        class="no-must"
                        v-if="
                          col.isMust &&
                            (row[col.dataIndex] == '' ||
                              row[col.dataIndex] == null) &&
                            !row.editable
                        "
                      >*</span>
                      <div v-else>
                        <viewer
                          :images="getUploadUrl(row[col.dataIndex])"
                          v-if="row[col.dataIndex] != ''"
                        >
                          <img
                            v-for="src in getUploadUrl(row[col.dataIndex])"
                            :src="src"
                            :key="src"
                            style="height:30px;width:30px"
                            class="upload-img"
                          />
                        </viewer>
                      </div>
                    </template>
                    <!-- 列表内的链接按钮 -->
                    <template v-else-if="col.controlType === 'CxsGridLinkColumn'">
                      <span
                        class="no-must"
                        v-if="
                          col.isMust &&
                            (row[col.dataIndex] == '' ||
                              row[col.dataIndex] == null) &&
                            !row.editable
                        "
                      >*</span>
                      <div v-else>
                        <a
                          v-if="col.cellRender"
                          @click.stop="
                            event => {
                              if (
                                col.cellRender.events &&
                                col.cellRender.events.click
                              ) {
                                col.cellRender.events.click({
                                  event: event,
                                  row: row,
                                  clickConfig: col.cellRender.events.clickConfig
                                });
                              }
                            }
                          "
                        >{{ col.cellRender.text }}</a>
                      </div>
                    </template>
                    <template v-else-if="
                        col.controlType != 'CxsGridDateTimeColumn' &&
                          col.controlType != 'CxsUploadFilesColumn' &&
                          col.controlType != 'CxsGridLinkColumn'
                      ">
                      <span
                        class="no-must"
                        v-if="
                          col.isMust &&
                            (row[col.dataIndex] == '' ||
                              row[col.dataIndex] == null) &&
                            row[col.dataIndex] != 0 &&
                            !row.editable
                        "
                      >*</span>
                      <template v-else>
                        <a
                          v-if="
                            col.isForm &&
                              checkTextMeetASNGenerateRule(row[col.dataIndex])
                          "
                          @click.stop="
                            showByRule(
                              row[col.dataIndex],
                              checkTextMeetASNGenerateRule(row[col.dataIndex])
                            )
                          "
                        >{{ row[col.dataIndex] }}</a>
                        <template v-else>
                          {{
                            col.format
                              ? colFormat(row[col.dataIndex], col.format, {
                                  index: row[rowKey],
                                  dataIndex: col.dataIndex,
                                  row: row
                                })
                              : row[col.dataIndex]
                          }}
                          <ChangeIcon
                            v-if="
                              row['etable_changeValues'] &&
                                row['etable_changeValues']['editFind'][
                                  col.dataIndex
                                ] &&
                                row['etable_changeValues'].operateType != 1
                            "
                            :title="
                              col.format
                                ? colFormat(
                                    row['etable_changeValues']['editFind'][
                                      col.dataIndex
                                    ],
                                    col.format,
                                    {
                                      index: row[rowKey],
                                      dataIndex: col.dataIndex,
                                      row: row
                                    }
                                  )
                                : row['etable_changeValues']['editFind'][
                                    col.dataIndex
                                  ]
                            "
                            :type="row['etable_changeValues'].operateType"
                          ></ChangeIcon>
                        </template>
                      </template>
                    </template>
                  </template>
                  <a-tooltip
                    placement="topRight"
                    :title="checkOk"
                    arrow-point-at-center
                    v-if="checkOk != true"
                  >
                    <a style="height: auto;">
                      <e-icon type="exclamation-circle"></e-icon>
                    </a>
                  </a-tooltip>
                </div>
              </template>
            </vxe-table-column>
          </template>
        </vxe-grid>
        <!-- 编辑模式下的表单 本来是v-if = “editIndex != null” 但是冲洗渲染时如果滚动条不是最左，left:0的浮动会有问题 -->
        <div
          v-if="editIndex"
          v-show="editShow"
          class="vxe-table border--full editRowTable"
          :style="{ right: haveY() ? '10px' : '0' }"
          @keydown="keydown"
          @scroll="editScrollEvent"
        >
          <tr
            class="vxe-body--row edit-tr"
            :class="getEditRowIsEvenOrOdd"
            :key="'editRow'"
          >
            <!-- 选择列的checkBox -->
            <td
              class="vxe-body--column col--ellipsis"
              :style="{
                width: '60px !important'
              }"
            >
              <div
                class="vxe-cell c--tooltip"
                style="width:60px;"
              ></div>
            </td>
            <!-- 其他字段列 -->
            <template v-for="col in columns.filter(item => !item.hideThis)">
              <!-- {{col.controlType}}{{focusDataIndex}} -->
              <td
                :key="col.dataIndex + 'edit'"
                class="vxe-body--column col--ellipsis edit-cell"
                :class="getEditColClass(col)"
                :style="{
                  width: setEditColWidth(col.dataIndex),
                  backgroundColor: isNewTheme ? 'unset' : '#FFF',
                  backgroundImage: isNewTheme ? 'unset !important' : ''
                }"
                @click="e => setFocusDataIndex(col.dataIndex, e)"
                @keydown="keydown"
                tabindex="1"
              >
                <!-- 如果是超级链接 -->
                <template v-if="col.controlType === 'CxsGridLinkColumn'">
                  <div class="edit-link-column">
                    <a
                      v-if="col.cellRender"
                      @click.stop="
                        event => {
                          if (
                            col.cellRender.events &&
                            col.cellRender.events.click
                          ) {
                            col.cellRender.events.click({
                              event: event,
                              row: editFind,
                              clickConfig: col.cellRender.events.clickConfig
                            });
                          }
                        }
                      "
                    >{{ col.cellRender.text }}</a>
                  </div>
                </template>
                <div
                  class="vxe-cell c--tooltip edit-div"
                  :style="{
                    width: setEditColWidth(col.dataIndex),
                    'padding-left': col.readOnly ? '10px' : '1px',
                    'padding-right': col.readOnly ? '10px' : '1px'
                  }"
                  :class="getEditDivClasses(col, editFind)"
                  @keydown="keydown"
                >
                  <!-- :style="{ width: col.width + 'px' }" -->
                  <template v-if="
                      col.readOnly != true && !formulaCannotBeFilled(editFind)
                    ">
                    <template v-if="
                        col.controlType === 'CxsGridComboBoxColumn' ||
                          col.controlType === 'CxsGridComboEditBoxColumn' ||
                          col.controlType === 'CxsGridCheckBoxColumn'
                      ">
                      <!-- Select选择器 -->
                      <template v-if="
                          col.controlType === 'CxsGridComboBoxColumn' ||
                            col.controlType === 'CxsGridComboEditBoxColumn'
                        ">
                        <template v-if="editEselectRender">
                          <ESelect
                            v-if="
                              col.controlType === 'CxsGridComboBoxColumn' ||
                                col.controlType === 'CxsGridComboEditBoxColumn'
                            "
                            :key="col.dataIndex + '_' + editIndex"
                            :value="editFind[col.dataIndex]"
                            @change="
                              (e, chinese) =>
                                handleChange(
                                  e,
                                  editFind[rowKey],
                                  col.dataIndex,
                                  editFind,
                                  null,
                                  chinese,
                                  1
                                )
                            "
                            :defaultSelectedIndex="col.defaultSelectedIndex"
                            :options="
                              filterCatch['_' + editFind[rowKey]]
                                ? filterCatch['_' + editFind[rowKey]][
                                    col.dataIndex
                                  ]
                                  ? filterCatch['_' + editFind[rowKey]][
                                      col.dataIndex
                                    ]
                                  : filterSave[col.dataIndex]
                                  ? filterSave[col.dataIndex]
                                  : col.options
                                  ? col.options
                                  : []
                                : filterSave[col.dataIndex]
                                ? filterSave[col.dataIndex]
                                : col.options
                                ? col.options
                                : []
                            "
                            :filter="col.listFilter"
                            :linkage="col.linkColumns"
                            :disabled="
                              col.readOnly === 'dynamicReadOnly'
                                ? conditionDynamicallyEditable(
                                    col.dynamicReadOnly,
                                    editFind
                                  )
                                : col.readOnly
                            "
                            @filterSave="
                              (rdata, ok) => {
                                onGetFilterSave(
                                  {
                                    option: rdata,
                                    fieldName: col.dataIndex,
                                    rowKey: editFind[rowKey]
                                  },
                                  ok
                                );
                              }
                            "
                            @linkedChange="
                              linkedChange =>
                                linked(linkedChange, editFind[rowKey], 1)
                            "
                            :isInTable="true"
                            :emptyOption="false"
                            width="100%"
                            :ref="domId + 'Edit' + col.dataIndex"
                          ></ESelect>
                          <!-- </div> -->
                          <!-- :ref="domId + 'Edit' + col.dataIndex" -->
                        </template>
                      </template>
                      <!-- 是否选择器 -->
                      <template v-if="col.controlType === 'CxsGridCheckBoxColumn'">
                        <template>
                          <!-- handleChange(e, row[rowKey], col.dataIndex, row) -->
                          <EBooleanCheckbox
                            :value="editFind[col.dataIndex]"
                            :key="col.dataIndex + '_' + editIndex"
                            @change="
                              e =>
                                handleChange(
                                  e,
                                  editFind[rowKey],
                                  col.dataIndex,
                                  editFind,
                                  null,
                                  null,
                                  2
                                )
                            "
                            v-if="col.controlType === 'CxsGridCheckBoxColumn'"
                            :disabled="
                              !editFind.editable ||
                              col.readOnly === 'dynamicReadOnly'
                                ? conditionDynamicallyEditable(
                                    col.dynamicReadOnly,
                                    editFind
                                  )
                                : col.readOnly
                            "
                            width="100%"
                            :ref="domId + 'Edit' + col.dataIndex"
                          ></EBooleanCheckbox>
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      <template>
                        <div style="float:left;display: flex;width: 100%; justify-content: center;">
                          <!-- 文本编辑器 -->
                          <template v-if="col.controlType === 'CxsGridTextBoxColumn'">
                            <!-- handleChange( e, row[rowKey], col.dataIndex, row, null ); -->
                            <ENumericUpDown
                              v-if="isNumberText(col.format)"
                              :key="col.dataIndex + '_' + editIndex"
                              :value="editFind[col.dataIndex]"
                              :size="controlSize"
                              :isMx="true"
                              @change="
                                e => {
                                  handleChange(
                                    e,
                                    editFind[rowKey],
                                    col.dataIndex,
                                    editFind,
                                    null,
                                    null,
                                    3
                                  );
                                }
                              "
                              :disabled="
                                col.readOnly === 'dynamicReadOnly'
                                  ? conditionDynamicallyEditable(
                                      col.dynamicReadOnly,
                                      editFind
                                    )
                                  : col.readOnly
                              "
                              :precision="numberPrecision(col.format)"
                              width="100%"
                              :ref="domId + 'Edit' + col.dataIndex"
                            />
                            <template v-else>
                              <a-input
                                :key="col.dataIndex + '_' + editIndex"
                                :size="controlSize"
                                :value="editFind[col.dataIndex]"
                                v-decorator="[
                                  col.dataIndex,
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: 'Please input your note!'
                                      }
                                    ]
                                  }
                                ]"
                                @change="
                                  e => {
                                    handleChange(
                                      e.target.value,
                                      editFind[rowKey],
                                      col.dataIndex,
                                      editFind,
                                      null,
                                      null,
                                      4
                                    );
                                  }
                                "
                                :disabled="
                                  col.readOnly === 'dynamicReadOnly'
                                    ? conditionDynamicallyEditable(
                                        col.dynamicReadOnly,
                                        editFind
                                      )
                                    : col.readOnly
                                "
                                width="100%"
                                :ref="domId + 'Edit' + col.dataIndex"
                              ></a-input>
                            </template>
                          </template>
                          <!-- 其他定制控件 -->
                          <template v-else>
                            <!-- handleChange(e, row[rowKey], col.dataIndex, row, 'time') -->
                            <EDatetimePicker
                              v-if="col.controlType === 'CxsGridDateTimeColumn'"
                              :key="col.dataIndex + '_' + editIndex"
                              :value="editFind[col.dataIndex]"
                              :mode="col.mode ? col.mode : 'date'"
                              :format="col.format ? col.format : 'YYYY-MM-DD'"
                              @change="
                                e =>
                                  handleChange(
                                    e,
                                    editFind[rowKey],
                                    col.dataIndex,
                                    editFind,
                                    'time',
                                    null,
                                    5
                                  )
                              "
                              :disabled="
                                col.readOnly === 'dynamicReadOnly'
                                  ? conditionDynamicallyEditable(
                                      col.dynamicReadOnly,
                                      editFind
                                    )
                                  : col.readOnly
                              "
                              width="100%"
                              :isMx="col.dataIndex == focusDataIndex"
                              :ref="domId + 'Edit' + col.dataIndex"
                              :maxTime="maxDate[col.fieldName]"
                              :minTime="minDate[col.fieldName]"
                            ></EDatetimePicker>
                            <EUpload
                              v-if="col.controlType === 'CxsUploadFilesColumn'"
                              :key="col.dataIndex + '_' + editIndex"
                              :value="editFind[col.dataIndex]"
                              @change="
                                e =>
                                  handleChange(
                                    e,
                                    editFind[rowKey],
                                    col.dataIndex,
                                    editFind,
                                    null,
                                    null,
                                    6
                                  )
                              "
                              :disabled="
                                col.readOnly === 'dynamicReadOnly'
                                  ? conditionDynamicallyEditable(
                                      col.dynamicReadOnly,
                                      editFind
                                    )
                                  : col.readOnly
                              "
                              width="100%"
                              :ref="domId + 'Edit' + col.dataIndex"
                              :isMx="editIndex"
                            ></EUpload>
                            <EMoney
                              v-if="col.controlType === 'CxsGridCurrencyColumn'"
                              :key="col.dataIndex + '_' + editIndex"
                              :value="editFind[col.dataIndex]"
                              :separator="true"
                              :precision="controlsOption.precision"
                              @change="
                                e =>
                                  handleChange(
                                    e,
                                    editFind[rowKey],
                                    col.dataIndex,
                                    editFind,
                                    null,
                                    null,
                                    7
                                  )
                              "
                              :disabled="
                                col.readOnly === 'dynamicReadOnly'
                                  ? conditionDynamicallyEditable(
                                      col.dynamicReadOnly,
                                      editFind
                                    )
                                  : col.readOnly
                              "
                              width="100%"
                              :ref="domId + 'Edit' + col.dataIndex"
                            ></EMoney>
                            <EMaterials
                              v-if="
                                editIndex &&
                                  col.controlType ===
                                    'CxsGridTexBoxButtonColumn' &&
                                  col.editorFormType == 'Materials'
                              "
                              :value="editFind[col.dataIndex]"
                              :key="col.dataIndex + '_' + editIndex"
                              @change="
                                (e, chinese) => {
                                  handleChange(
                                    e,
                                    editFind[rowKey],
                                    col.dataIndex,
                                    editFind,
                                    null,
                                    chinese,
                                    8
                                  );
                                }
                              "
                              :bdmbId="tempId"
                              width="100%"
                              :linkage="col.linkColumns"
                              @linkedChange="
                                linkedValue =>
                                  linked(linkedValue, editFind[rowKey], 8)
                              "
                              :search="
                                col.listFilter ? col.listFilter.filter : ''
                              "
                              :disabled="
                                col.readOnly === 'dynamicReadOnly'
                                  ? conditionDynamicallyEditable(
                                      col.dynamicReadOnly,
                                      editFind
                                    )
                                  : col.readOnly
                              "
                              :filterSave="
                                filterSave[col.dataIndex]
                                  ? filterSave[col.dataIndex]
                                  : null
                              "
                              @filterSave="
                                data => {
                                  filterSave[col.dataIndex] = data;
                                }
                              "
                              :mxId="domId + 'Save'"
                              :okValue="
                                okValue[col.dataIndex]
                                  ? okValue[col.dataIndex]
                                  : null
                              "
                              @okValue="
                                data => {
                                  okValue[col.dataIndex] = data;
                                }
                              "
                              :ref="domId + 'Edit' + col.dataIndex"
                            ></EMaterials>
                            <EFsjeFormula
                              v-if="
                                col.controlType ===
                                  'CxsGridTexBoxButtonColumn' &&
                                  col.editorFormType == 'CW_FSJEFormula'
                              "
                              :value="editFind[col.dataIndex]"
                              :key="col.dataIndex + '_' + editIndex"
                              @change="
                                e =>
                                  handleChange(
                                    e,
                                    editFind[rowKey],
                                    col.dataIndex,
                                    editFind,
                                    null,
                                    null,
                                    9
                                  )
                              "
                              :option="
                                filterSave[col.dataIndex]
                                  ? filterSave[col.dataIndex]
                                  : null
                              "
                              :disabled="
                                col.readOnly === 'dynamicReadOnly'
                                  ? conditionDynamicallyEditable(
                                      col.dynamicReadOnly,
                                      editFind
                                    )
                                  : col.readOnly
                              "
                              @filterSave="
                                data => {
                                  filterSave[col.dataIndex] = data;
                                }
                              "
                              width="100%"
                              :ref="domId + 'Edit' + col.dataIndex"
                            ></EFsjeFormula>
                            <!-- 会计凭证条件公式定义 -->
                            <EKjpztjFormula
                              v-if="
                                col.controlType ===
                                  'CxsGridTexBoxButtonColumn' &&
                                  col.editorFormType === 'CW_KjpztjFormula'
                              "
                              :value="editFind[col.dataIndex]"
                              :key="col.dataIndex + '_' + editIndex"
                              :filter="col.listFilter"
                              @change="
                                e =>
                                  handleChange(
                                    e,
                                    editFind[rowKey],
                                    col.dataIndex,
                                    editFind,
                                    null,
                                    null,
                                    9
                                  )
                              "
                              :disabled="
                                col.readOnly === 'dynamicReadOnly'
                                  ? conditionDynamicallyEditable(
                                      col.dynamicReadOnly,
                                      editFind
                                    )
                                  : col.readOnly
                              "
                              @filterSave="
                                data => {
                                  filterSave[col.dataIndex] = data;
                                }
                              "
                              width="100%"
                              :ref="domId + 'Edit' + col.dataIndex"
                            ></EKjpztjFormula>
                            <!-- 会计摘要选择编辑器 -->
                            <keepAccountZY
                                v-if="col.controlType ==='CxsGridTexBoxButtonColumn' &&
                                  col.editorFormType === 'CW_CYZY'"
                                :size="$config.defaultControlSize"
                                v-model="editFind[col.dataIndex]"
                                :ref="domId + 'Edit' + col.dataIndex"
                                :key="col.dataIndex + '_' + editIndex"
                                :dataOptionSave="
                                    filterSave[col.dataIndex]
                                      ? filterSave[col.dataIndex]
                                      : null
                                  "
                                @postDataOptionSave="
                                    (data) => {
                                  filterSave[col.dataIndex] = data;
                                }"
                            ></keepAccountZY>
                            <TableButtonControls
                              v-if="
                                col.controlType ===
                                  'CxsGridTexBoxButtonColumn' &&
                                  col.editorFormType != 'Materials' &&
                                  col.editorFormType != 'CW_FSJEFormula' &&
                                  col.editorFormType != 'CW_CYZY' &&
                                  col.editorFormType != 'CW_KjpztjFormula'
                              "
                              :value="editFind[col.dataIndex]"
                              :key="col.dataIndex + '_' + editIndex"
                              @change="
                                (e, chinese) => {
                                  handleChange(
                                    e,
                                    editFind[rowKey],
                                    col.dataIndex,
                                    editFind,
                                    null,
                                    chinese,
                                    10
                                  );
                                }
                              "
                              :linkage="col.linkColumns"
                              @linkedChange="
                                linkedChange =>
                                  linked(linkedChange, editFind[rowKey])
                              "
                              :type="col.editorFormType"
                              :bdmbId="tempId"
                              :disabled="
                                col.readOnly === 'dynamicReadOnly'
                                  ? conditionDynamicallyEditable(
                                      col.dynamicReadOnly,
                                      editFind
                                    )
                                  : col.readOnly
                              "
                              :filterSave="
                                filterSave[col.dataIndex]
                                  ? filterSave[col.dataIndex]
                                  : null
                              "
                              :search="
                                col.listFilter
                                  ? filterValueChange(
                                      col.listFilter.filter,
                                      editFind
                                    )[0]
                                  : ''
                              "
                              :notSave="
                                col.listFilter
                                  ? filterValueChange(
                                      col.listFilter.filter,
                                      editFind
                                    )[1]
                                  : false
                              "
                              @filterSave="
                                data => {
                                  filterSave[col.dataIndex] = data;
                                }
                              "
                              :ref="domId + 'Edit' + col.dataIndex"
                              width="100%"
                            ></TableButtonControls>
                          </template>
                        </div>
                        <!-- </div> -->
                      </template>
                    </template>
                  </template>
                  <!-- 不可编辑项-->
                  <template v-else>
                    <div
                      style="z-index: 1; background: #fff;overflow: hidden"
                      tabindex="1"
                      @keydown="keydown"
                      :ref="domId + 'Edit' + col.dataIndex"
                      class="null-edit-cell"
                    >
                      <template v-if="
                          col.controlType === 'CxsGridComboBoxColumn' ||
                            col.controlType === 'CxsGridComboEditBoxColumn' ||
                            col.controlType === 'CxsGridCheckBoxColumn'
                        ">
                        <template v-if="
                            col.controlType === 'CxsGridComboBoxColumn' ||
                              col.controlType === 'CxsGridComboEditBoxColumn'
                          ">
                          <template v-if="getCacheOk[col.dataIndex]">
                            {{
                              selectCacheText(
                                editFind[col.dataIndex],
                                col.dataIndex,
                                editFind[rowKey],
                                null,
                                col.isMust
                              )
                            }}
                          </template>
                        </template>
                        <template v-if="col.controlType === 'CxsGridCheckBoxColumn'">
                          {{
                            checkBoxCacheText(
                              editFind[col.dataIndex],
                              col.dataIndex,
                              editFind[rowKey]
                            )
                          }}
                        </template>
                      </template>
                      <template v-else>
                        <template v-if="col.controlType === 'CxsGridDateTimeColumn'">
                          <span
                            class="no-must"
                            v-if="
                              col.isMust &&
                                (editFind[col.dataIndex] == '' ||
                                  editFind[col.dataIndex] == null) &&
                                !editFind.editable
                            "
                          >*</span>
                          <template v-else>{{
                            FormattingTime(editFind[col.dataIndex])
                          }}</template>
                        </template>
                        <template v-if="col.controlType === 'CxsUploadFilesColumn'">
                          <span
                            class="no-must"
                            v-if="
                              +col.isMust &&
                                (editFind[col.dataIndex] == '' ||
                                  editFind[col.dataIndex] == null) &&
                                !editFind.editable
                            "
                          >*</span>
                          <div v-else>
                            <viewer
                              :images="getUploadUrl(row[col.dataIndex])"
                              v-if="row[col.dataIndex] != ''"
                            >
                              <img
                                v-for="src in getUploadUrl(row[col.dataIndex])"
                                :src="src"
                                :key="src"
                                style="height:30px;width:30px"
                                class="upload-img"
                              />
                            </viewer>
                          </div>
                        </template>
                        <template v-if="
                            col.controlType != 'CxsGridDateTimeColumn' &&
                              col.controlType != 'CxsUploadFilesColumn'
                          ">
                          <span
                            class="no-must"
                            v-if="
                              col.isMust &&
                                (editFind[col.dataIndex] == '' ||
                                  editFind[col.dataIndex] == null) &&
                                !editFind.editable
                            "
                          >*</span>
                          <template v-else>
                            <!-- <div style="z-index: 1"> -->
                            <a
                              v-if="
                                col.isForm &&
                                  checkTextMeetASNGenerateRule(
                                    editFind[col.dataIndex]
                                  )
                              "
                              @click.stop="
                                showByRule(
                                  editFind[col.dataIndex],
                                  checkTextMeetASNGenerateRule(
                                    editFind[col.dataIndex]
                                  )
                                )
                              "
                            >{{ editFind[col.dataIndex] }}</a>
                            <!-- </div> -->
                            <template v-else>
                              {{
                                col.format
                                  ? colFormat(
                                      editFind[col.dataIndex],
                                      col.format,
                                      {
                                        index: editFind[rowKey],
                                        dataIndex: col.dataIndex,
                                        row: editFind
                                      }
                                    )
                                  : editFind[col.dataIndex]
                              }}
                            </template>
                          </template>
                        </template>
                      </template>
                    </div>
                  </template>
                </div>
              </td>
            </template>
          </tr>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _lodash from "lodash";
import moment from "moment";
import { ESelectControl } from "../ESelect/mixin/ESelectControl.js"; //
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { mathAlgorithms } from "@/mixins/mathAlgorithms.js"; // 表单数据计算方法
import { e1ExpendColum } from "@/views/listView/mixins/e1ExpendColum.js";
import { analyticalTemplate } from "@/mixins/analyticalTemplate.js"; // 解析xml模板的方法
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; // 通用的E1参数解析方法
import { e1DateTimeSelectRangeControls } from "@/mixins/e1DateTimeSelectRangeControls.js"; // 时间控件选择范围控制支持方法
import { ETableKeydown } from "@/controls/FormControls/ETable/mixins/ETableKeydown.js";
import { ETableDom } from "@/controls/FormControls/ETable/mixins/ETableDom.js";
import { ETableDataFormats } from "@/controls/FormControls/ETable/mixins/ETableDataFormats.js";
import XEUtils from "xe-utils";
export default {
  mixins: [
    pageControls,
    mathAlgorithms,
    ESelectControl,
    e1ExpendColum,
    analyticalTemplate,
    ETableKeydown,
    ETableDom,
    ETableDataFormats,
    analyticalMenuAuth,
    e1DateTimeSelectRangeControls
  ],
  name: "ETable",
  data () {
    return {
      tableName: null,
      columns: [],
      data:
        this.dataSource && this.dataSource.length > 0
          ? this.setDataSource()
          : [],
      controlSize: this.$config.defaultControlSize,
      addNullJson: {},
      editKey: null,
      selectedRowKeys: [],
      selectedRows: [],
      cacheData: {},
      //count: -1,
      multiSelect: "radio",
      controlsOption: {
        precision: 2
      },
      config: {},
      allFormulaConfigs: [],
      filterSave: {}, // 从接口拿取数据进行缓存,一个字段名对应一个缓存数据
      filterCatch: {}, // 如果对应的需要缓存的控件，包含了需要注入变量参数值的过滤条件，则需要每一行都缓存
      okValue: {}, // 确定正确的数缓存
      screenMode: false, // 是否为筛选状态
      screenData: null, // 筛选出的数据
      isMust: [], // 需要必填的字段
      sumWidth: 0, // 表格总长度
      maxWidthFloat: 1500, // 缩放宽度
      addWidth: 100, // 给每个单元格加上的宽度
      delButtonConfirmVisible: false,
      alloEditArray: [], // 可编辑字段暂存 优先级大于 》 disable
      alloNotEditArray: [], // 不可编辑字段暂存 优先级》可编辑字段配置
      alloMustArray: [], // 动态必填字段暂存
      hideFieldsArray: [], // 动态隐藏字段
      dataInMode: false, //
      firstLodingArray: [], // 用于预渲染控件设置的数组
      notShow: false,
      getCacheOk: {},
      // getCacheOk: false, // 是否已经获得所有select数据
      cacheKey: [], // 需要缓存的key
      thComponents: null,
      colHhKey: "_HH",
      domId:
        "ETable" + Date.parse(new Date()).toString() + this.option["tableName"],
      nullString: " ",
      firstLoadSum: true, // 第一次加载合计视图
      activeDataIndex: null, // 选取中的列
      pasteBody: [], // 粘贴板
      addNum: this.$config.formDerailDefaultAddRows, // 新增的行数数
      addVisible: false,

      editIndex: null, // 新版编辑，获得data下标
      editShow: false, // 编辑列是否显示
      tableBodyDomScrollLeft: 0, // 记住X轴滚动条位置
      tableBodyDomScrollTop: 0, // 记住轴滚动条位置
      bodyTableDom: null,
      maxHh: 0,

      moveScrollTimeOutEvent: null,
      editTimeOut: null,
      allFormulaArray: [], // 参与计算的参数
      onlyFormulaVariable: [], // 只是作为被取参数参与公式计算的字段
      domOffsetTop: null,
      defaultValueSave: {}, // select存取默认选中值的变量
      bottomScrollWidth: 0, // 最下级滚动条的宽度
      domScrollHide: true,
      thisButtonShow: false,
      isInit: true,

      editEselectRender: true,
      nowCannotFormula: "[_HH] == 10",
      rule: {},
      customBatchAddControl: null, // 自定义选入多条对应字段的明细数据
      mainChangeOne: false, // 监听到第一次主表数据变化
      needMonitorMainFields: [] // 需要监听变化的主表字段
    };
  },
  props: {
    defaultValue: {
      type: [String, null],
      default: null
    },
    value: {
      type: [String, null]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    option: {
      type: [Object, null],
      default: null
    },
    dataSource: {
      type: Array,
      default: function () {
        return [];
      }
    },
    mainTableData: {
      // 用来获取当前的主表单数据
      type: Object,
      default: function () {
        return {};
      }
    },
    tempId: {
      type: [String, Number]
    },
    rowKey: {
      type: [String, Function],
      default: function () {
        return this.defaultTableKeyName;
      }
    },
    buttonConfig: {
      type: Array,
      default: function () {
        //{ tsbName: "tsbAddRow" }, { tsbName: "tsbDelRow" }
        return [];
      }
    },
    allowDeleteRows: {
      type: Boolean,
      default: true
    },
    allowAddRows: {
      type: Boolean,
      default: true
    },
    newFormAddOneRow: {
      // 新建表单模式下，是否初始化一行
      type: Boolean,
      default: true
    },
    editOver: {
      type: Boolean,
      default: false
    },
    // 动态可编辑字段
    fieldsAllowedEdit: {
      type: Object,
      default: function () {
        return {};
      }
    },
    // 不可编辑字段优先级》fieldsAllowedEdit
    fieldsNotAllowedEdit: {
      type: Array,
      default: function () {
        return [];
      }
    },
    requiredField: {
      type: Object,
      default: function () {
        return {};
      }
    },
    //隐藏字段
    hideFields: {
      type: Array,
      default: function () {
        return [];
      }
    },
    tableClassName: {
      // 用于扩展table样式
      type: String,
      default: "e-table__extendClassName"
    },
    changeHh: {
      type: Boolean,
      default: false
    },
    // 新老值
    changeValues: {
      type: Array,
      default: function () {
        return [];
      }
    },
    buttonShow: {
      // 此参数只有在disabled=true的时候有效，可以控制操作按钮是否可用
      type: Boolean,
      default: false
    },
    cannotFormula: {
      type: String,
      default: ""
    },
    scrollTop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showButton: function () {
      if (this.disabled) {
        return this.thisButtonShow;
      } else {
        return false;
      }
    },
    addButton: function () {
      return this.buttonConfig.find(item => item.tsbName === "tsbAddRow");
    },
    buttonType: function () {
      return this.isNewTheme ? "primary" : "default";
    },
    deleteButtonGhost: function () {
      return this.isNewTheme;
    },
    getTableBoxClass: function () {
      let classes = [];
      classes.push(this.tableClassName);
      if (this.$config.defaultControlSize) {
        if (this.$config.defaultControlSize === "small") {
          classes.push("vxe-table__small");
        } else if (this.$config.defaultControlSize === "default") {
          classes.push("vxe-table__default");
        } else if (this.$config.defaultControlSize === "large") {
          classes.push("vxe-table__large");
        }
      } else {
        classes.push("vxe-table__default");
      }
      if (this.config.sumRow) {
        classes.push("vxe-table__footer");
      }
      return classes;
    },
    count: function () {
      if (this.data && this.data.length > 0) {
        let key = this.data[this.data.length - 1][this.rowKey];
        return this.$util.getNumber(key);
      }
      return 0;
    },
    // 查找编辑行
    editFind: function () {
      if (this.editIndex) {
        return this.data.find(
          item => item[this.rowKey] === this.editIndex.toString()
        );
      } else {
        return {};
      }
    },
    /**
     * 获取编辑行的奇偶样式名
     */
    getEditRowIsEvenOrOdd: function () {
      if (this.editIndex % 2 == 0) {
        //偶数
        return "edit-tr-rowIndex-even";
      }
      return "edit-tr-rowIndex-odd";
    }
  },
  watch: {
    cannotFormula: function (v) {
      this.nowCannotFormula = v;
    },
    mainTableData: {
      // 监听
      handler: function (newV, oldV) {
        let changed = this.$util.getObjectChangedFields(newV, oldV);
        let needFilterChange = false;
        if (changed) {
          for (let fieldName in changed) {
            if (this.needMonitorMainFields.includes(fieldName)) {
              needFilterChange = true;
            }
          }
        }
        if (
          this.columns &&
          this.columns.length > 0 &&
          this.mainChangeOne &&
          needFilterChange &&
          !this.isInit
        ) {
          let changeColumns = {};
          let isChange = false;
          this.columns.forEach((element, index) => {
            if (element.listFilter && element.listFilter.haveVariable) {
              //过滤数据字段
              if (element.listFilter.filter) {
                // 如果包含了过滤条件设置\\{[\\w]*\\}
                let listFilter = element.listFilter.filter;
                if (element.listFilter.oldFilter) {
                  listFilter = element.listFilter.oldFilter;
                } else {
                  element.listFilter.oldFilter = element.listFilter.filter;
                }
                let filter = this.changeColumnsFilter(listFilter);
                if (filter && filter !== element.listFilter.filter) {
                  isChange = true;
                  element.listFilter.filter = filter;
                  changeColumns[index + ""] = Object.assign({}, element);
                }
              }
            }
          });
          if (isChange) {
            for (let ft in changeColumns) {
              //console.log("filter变化", ft, changeColumns[ft]);
              //this.editFind[col.dataIndex]
              // 置空选中的数据
              this.data.forEach(cData => {
                if (
                  !cData[changeColumns[ft].dataIndex] ||
                  (cData[changeColumns[ft].dataIndex] &&
                    cData[changeColumns[ft].dataIndex] != "")
                ) {
                  this.handleChange(
                    "",
                    cData[this.rowKey],
                    changeColumns[ft].dataIndex,
                    cData,
                    null,
                    "",
                    1
                  );
                  if (changeColumns[ft].linkColumns) {
                    // 清除相关联动字段的值
                    changeColumns[ft].linkColumns.forEach(element => {
                      this.handleChange(
                        "",
                        cData[this.rowKey],
                        element.mbField,
                        cData,
                        null,
                        "",
                        1
                      );
                    });
                  }
                  this.updatePageData(
                    "filterSave",
                    changeColumns[ft].dataIndex,
                    null
                  );
                  //this.$set(this.getCacheOk, changeColumns[ft].dataIndex, true);
                }
              });
              console.log("修改明细表列表字段的过滤条件", changeColumns[ft]);
              this.updatePageData("columns", parseInt(ft), changeColumns[ft]);
            }
          }
        } else {
          this.mainChangeOne = true;
        }
      }
    },
    setvalue (value) {
      this.$emit("change", value);
    },
    allowDeleteRows () { },
    allowAddRows () { },
    dataSource (value) {
      this.data = value;
    },
    disabled () { },
    editOver (value) {
      if (value == true) {
        this.data.forEach(element => {
          if (element["editable"] == true) {
            this.save(element[this.rowKey], true);
          }
        });
      }
    },
    fieldsAllowedEdit: {
      deep: true,
      handler: function (value) {
        if (this.disabled) {
          if (value) {
            this.alloEditArray = value[this.tableName];
          } else {
            this.alloEditArray = [];
          }
          this.fieldsAllowedEditSet();
        }
      }
    },
    fieldsNotAllowedEdit: {
      deep: true,
      handler: function (value) {
        this.alloNotEditArray = value;
        this.fieldsAllowedEditSet();
      }
    },
    hideFields: {
      deep: true,
      handler: function (value) {
        this.hideFieldsArray = value;
        this.hideFieldsSet();
      }
    },
    requiredField: {
      deep: true,
      handler: function (value) {
        if (this.disabled) {
          this.changeIsMustArray(value, this.alloEditArray);
          this.requiredFieldSet();
        }
      }
    },
    columns: {
      deep: true,
      handler: function (v) {
        this.$nextTick(() => {
          var columns = this.columns.filter(item => !item.hideThis);
          var width = 0;
          for (var i = 0; i < columns.length; i++) {
            width += columns[i].width;
          }
          this.setBottomScrollWidth(width + 60);
        });
      }
    },
    data: {
      deep: true,
      handler: function (value) {
        this.scrollCache(true);
        this.$emit("change", {
          tableName: this.option.tableName,
          data: this.data
        });
      }
    },
    domScrollHide (v) {
      if (v == false) {
        var XScrollDom = this.$refs[this.domId].$el.getElementsByClassName(
          "vxe-table--body-wrapper"
        )[0];
        if (XScrollDom) {
          this.$nextTick(() => {
            this.$refs[this.domId + "bottom"].scrollLeft =
              XScrollDom.scrollLeft;
          });
        }
      }
    },
    getTableBoxClass (v) {
      setTimeout(() => {
        this.haveX(true);
      }, 50);
    },
    changeHh () { },
    editShow (v) {
      console.log("editshow ", v);
      if (v == true) {
        this.$nextTick(() => {
          this.setTableScroll();
        });
      }
    },
    editIndex (v) {
      if (v != null) {
        console.log("点击编辑第" + v + "行");
        let that = this;
        let changeColumns = {};
        let isChange = false;
        that.columns.forEach((element, index) => {
          if (
            element.listFilter &&
            (element.listFilter.haveDetailVariable ||
              element.listFilter.haveVariable)
          ) {
            // 该条件包含变量参数
            //过滤数据字段
            // 如果包含了过滤条件设置\\{[\\w]*\\}
            let listFilter = element.listFilter.filter;
            if (element.listFilter.oldFilter) {
              listFilter = element.listFilter.oldFilter;
            } else {
              element.listFilter.oldFilter = element.listFilter.filter;
            }
            let rowData = that.data[v - 1];
            let filter = that.changeColumnsFilter(listFilter, rowData);
            if (filter && filter !== element.listFilter.filter) {
              isChange = true;
              element.listFilter.filter = filter;
              changeColumns["" + index] = Object.assign({}, element);
            }
          }
        });
        if (isChange) {
          for (let ft in changeColumns) {
            console.log("重新渲染选择器", changeColumns[ft]);
            that.updatePageData("columns", parseInt(ft), changeColumns[ft]);
          }
        }
        this.$nextTick(() => {
          // this.setEditRowStyle(null, 'editIndexChange');
          this.setTableScroll();
        });
      } else {
        this.focusDataIndex = null;
      }
    }
  },
  created () {
    this.nowCannotFormula = this.cannotFormula;
    // this.nowCannotFormula = "[this._HH] == 10"
    if (!this.nowCannotFormula || this.nowCannotFormula === "") {
      this.nowCannotFormula = this.option.disableRowEditCondition
        ? this.option.disableRowEditCondition
        : "";
    }
    this.DatePickerRefNamePrefix = this.domId + "Edit";
    this.init();
  },
  mounted () {
    //debugger;
    this.bodyTableDom = this.$refs[this.domId].$el.getElementsByClassName(
      "vxe-table--body-wrapper"
    )[0];
    // 给表格中的数据列表table对象设置一个id
    let vxeTableBody = this.bodyTableDom.getElementsByClassName(
      "vxe-table--body"
    );
    vxeTableBody[0].setAttribute("id", this.domId + "_TABLE");
    this.thisButtonShow = this.buttonShow;
    console.log("etbale-disabled=", this.disabled);
    this.initWindowEvent();
  },
  // 从router 的 keepalive 再进入时触发
  activated () {
    this.setEditRowStyle(true, "activated");
    this.initWindowEvent();
  },
  methods: {
    /**
     * 获取当前编辑列Div样式
     */
    getEditDivClasses: function (col, data) {
      let classes = [];
      if (col.dataIndex == this.focusDataIndex) {
        classes.push("edit-cell-" + col.dataIndex + " focus");
      } else {
        classes.push("edit-cell-" + col.dataIndex);
      }
      if (col.readOnly == true || this.formulaCannotBeFilled(data)) {
        classes.push("edit-cell-unEdit");
      }
      return classes;
    },
    /**
     * 获取当前编辑列tr样式
     */
    getEditColClass: function (col) {
      let classes = [];
      if (col.active) {
        classes.push("head-active");
      }
      if (col.readOnly) {
        classes.push("col_readOnly");
      }
      return classes;
    },
    /**
     * 监听使用了配置接口请求获取后台数据的选择或者下拉选项的控件的数据加载成功方法
     * @param data 对应的选项缓存
     * @param eTableIsCatch true表示需要每行都保存一条缓存记录
     */
    onGetFilterSave: function (data, eTableIsCatch) {
      let { option, fieldName, rowKey } = data;
      let allColumns = this.option.columns;
      let column = allColumns.find(function (c) {
        return c.fieldName === fieldName;
      });
      if (eTableIsCatch) {
        //需要缓存到每一行
        let row = "_" + rowKey;
        if (this.filterCatch[row]) {
          let newFieldCatch = Object.assign({}, this.filterCatch[row]);
          newFieldCatch[fieldName] = option;
          this.$set(this.filterCatch, row, newFieldCatch);
        } else {
          let newFieldCatch = {};
          newFieldCatch[fieldName] = option;
          this.filterCatch[row] = newFieldCatch;
        }
      } else {
        this.$set(this.filterSave, fieldName, option);
      }
      //需要设置默认值
      if (
        column &&
        column.defaultSelectedIndex >= 0 &&
        option &&
        option.length > column.defaultSelectedIndex
      ) {
        this.$set(
          this.defaultValueSave,
          fieldName,
          option[column.defaultSelectedIndex].value
        );
      }
      this.$set(this.getCacheOk, fieldName, true);
      //console.log("注入缓存数据", this.filterSave, this.filterCatch);
    },
    /**
     * 表格初始化参数
     */
    init () {
      var _this = this;
      var userName = this.$store.getters.getUserName;
      this.config = this.option;
      let option = this.option;
      this.allFormulaConfigs = this.option.allFormulaConfigs;
      let allResultFields = [];
      if (this.allFormulaConfigs) {
        _this.allFormulaArray = [];
        this.allFormulaConfigs.forEach(element => {
          allResultFields.push(element.resultField);
          if (element.variableField) {
            element.variableField.forEach(formulaField => {
              if (!_this.allFormulaArray.includes(formulaField))
                _this.allFormulaArray.push(formulaField);
            });
          }
        });
      }
      this.allFormulaArray.forEach(function (field) {
        if (!allResultFields.includes(field)) {
          _this.onlyFormulaVariable.push(field);
        }
      });
      console.log("只是作为参数参与公式的字段", this.onlyFormulaVariable);
      this.sumWidth = 0;
      const columns = [];
      const addNullJson = {};
      console.log("明细表配置1：", option, this.buttonConfig, this.disabled);
      this.tableName = option.tableName;
      this.data = this.dataSource;
      //  解析行数据
      if (option.columns != "") {
        option["columns"].forEach(element => {
          let json = {};

          if (element.isAutoColNo) {
            this.colHhKey = element.fieldName;
          }

          for (let key in element) {
            if (!json["align"]) json["align"] = "center";
            if (key === "headerText") {
              //  行列显示字体绑定
              json["title"] = element[key];
              if (this.checkTitleRule(element[key])) {
                json["isForm"] = true;
              }
            } else if (key === "fieldName") {
              //  行列字段名绑定
              json["dataIndex"] = element[key];
              if (!addNullJson[element[key]]) addNullJson[element[key]] = null;
              json[key] = element[key];
              json["scopedSlots"] = {
                customRender: element[key],
                title: element["headerText"]
              };

              json["needTotal"] = true;
            } else if (key === "width") {
              if (
                element["fieldName"] == _this.colHhKey ||
                element["headerText"] == "行号"
              ) {
                json[key] = window.ETABLE_WIDTH.hhWidthNum;
              } else if (element["controlType"] == "CxsGridDateTimeColumn") {
                // json[key] = Number(element[key]) + 90;
                json[key] = window.ETABLE_WIDTH.timePickWidthNum;
                // 初始化日期时间控件的最大值和最小值设置
                let thisData = {};
                thisData[element.fieldName] = element.defaultValue;
                this.initDateTimeMaxAndMin(
                  element.fieldName,
                  thisData,
                  element.maxDate,
                  element.minDate
                );
              } else {
                json[key] =
                  Number(element[key]) +
                  Number(window.ETABLE_WIDTH.addWidthNum);
              }
              // 每行增加宽度
              // json[key] = Number(element[key]) + Number(this.addWidth);
            } else if (key === "visScope" && option.visScopeTakeEffect) {
              // 用户可见字段 可见范围,在新建时无效
              json[key] = element[key];
              try {
                var showUser = element[key];
                if (showUser != null && showUser != "") {
                  var show = showUser.findIndex(item => {
                    return item === userName;
                  });
                  if (show == -1) {
                    json["hideThis"] = true;
                  }
                }
              } catch (err) {
                err;
              }
            } else if (key === "listFilter") {
              //过滤数据字段
              if (element[key].filter) {
                if (!element[key].oldFilter) {
                  element[key].oldFilter = element[key].filter + "";
                }
                let filter = this.changeColumnsFilter(element[key].oldFilter);
                element[key].filter = filter;
              }
              if (element[key].haveVariable) {
                // 有主表字段变量注入
                console.log("需要注入主表变量：", element[key]);
                this.needMonitorMainFields = [].concat(
                  [],
                  this.needMonitorMainFields,
                  element[key].mainFields
                );
              }
              json[key] = element[key];
            } else if (key === "defaultValue") {
              //如果设置了默认值
              if (element[key]) {
                // 如果包含了过滤条件设置\\{[\\w]*\\}
                let defaultV = this.changeColumnsFilter(element[key]);
                if (defaultV) {
                  element[key] = defaultV;
                  addNullJson[element["fieldName"]] = defaultV;
                }
              }
              json[key] = element[key];
            } else if (key === "defaultSelectedIndex") {
              if (this.$util.isType(element[key]) === "val") {
                json.defaultSelectedIndex = element[key];
              }
            } else if (key === "rules") {
              this.$set(this.rule, element["fieldName"], element[key]);
            } else {
              json[key] = element[key];
            }
          }
          let width = json["width"];
          json["width"] = Number(width);
          this.sumWidth = this.sumWidth + json["width"];
          if (json["visible"] != false) {
            // 必填
            if (element.isMust == true) {
              this.isMust.push(element.fieldName);
            }
            columns.push(json);
          }
        });
        this.addNullJson = addNullJson;
        if (!this.editOver) {
          //  添加操作栏
          // columns.push({
          //   title: "操作",
          //   dataIndex: "operation-column",
          //   scopedSlots: { customRender: "operation-column" },
          //   width: 150,
          //   align: "center",
          //   fixed:
          //     Number(this.sumWidth) > Number(this.maxWidthFloat) ? "right" : ""
          // });
        }
        console.log("newFormAddOneRow=", this.newFormAddOneRow);
        if (
          this.allowAddRows &&
          this.data.length == 0 &&
          !this.editOver &&
          this.newFormAddOneRow
        ) {
          this.handleAdd();
        }
        this.setEditDataIndexSort(columns);
      }
      this.addNullJson = addNullJson;
      this.columns = columns;
      //  解析其他设置
      //  是否单、多选
      this.multiSelect = option.multiSelect ? "checkbox" : "radio";

      // 如果表单时禁用状态，获取流程配置的可编辑字段,以及必填字段(该必填配置只在可编辑字段中有效)
      if (
        this.disabled &&
        this.fieldsAllowedEdit &&
        this.$util.isValidObject(this.fieldsAllowedEdit)
      ) {
        this.alloEditArray = this.fieldsAllowedEdit[this.tableName];
        // 分析出有效的必填字段配置
        this.changeIsMustArray(this.requiredField, this.alloEditArray);
      }
      // 不可编辑字段
      this.alloNotEditArray = this.fieldsNotAllowedEdit;

      // 根据配置的可编辑项和不可编辑项，修改列可编辑状态
      this.fieldsAllowedEditSet();
      // 根据配置的必填项，修改列定义
      this.requiredFieldSet();
      this.getCacheOption();
      this.isInit = false;
    },

    /**
     * 组织表格的必填字段，
     * @param {Object} requiredField
     */
    changeIsMustArray (requiredField, alloEditArray) {
      if (
        this.disabled &&
        this.$util.isType(alloEditArray) === "Array" &&
        alloEditArray.length > 0 &&
        requiredField &&
        this.$util.isValidObject(requiredField)
      ) {
        // 必填字段配置
        let tempRequiredFields = requiredField[this.tableName];
        if (
          this.$util.isType(tempRequiredFields) === "Array" &&
          tempRequiredFields.length > 0
        ) {
          tempRequiredFields = tempRequiredFields.filter(function (f) {
            return alloEditArray.includes(f);
          });
          this.alloMustArray = tempRequiredFields;
        }
      }
    },
    /**
     * 替换filter配置中的未知值
     * @param filter
     * @param rowData
     * @return {*}
     */
    changeColumnsFilter (filter, rowData) {
      let reg = new RegExp("\\{([^\\}]+)\\}", "ig"); //从字符串中匹配出所有用{}括起来的字段
      if (this.$util.isType(filter) === "String") {
        let allFields = filter.match(reg);
        if (allFields && allFields.length > 0) {
          // 包含了未知字段值
          let thisFields = allFields.map(function (obj) {
            return obj.slice(0, -1).slice(1);
          });
          for (let fi = 0; fi < thisFields.length; fi++) {
            let fName = thisFields[fi];
            if (fName.includes(".")) {
              // 包含其他数据源的填充条件值
              let ftNames = fName.split(".");
              if (ftNames.length == 2) {
                if (ftNames[0] === "this") {
                  // 需要从当前数据源获取填充值
                  if (
                    !!rowData &&
                    rowData[ftNames[1]] != undefined &&
                    rowData[ftNames[1]] != null
                  ) {
                    let value = this.$util.trimStr(rowData[ftNames[1]]);
                    filter = filter.replace(allFields[fi], value);
                  }
                } else {
                  //需要从其他同级明细表中获取填充数据，目前不支持
                }
              }
            } else {
              // 需要从主表单中获取填充数据
              if (
                this.mainTableData &&
                this.mainTableData[fName] != undefined &&
                this.mainTableData[fName] != null
              ) {
                let value = null;
                if (this.$util.isType(this.mainTableData[fName]) === "Object") {
                  value = this.$util.trimStr(
                    this.mainTableData[fName].value + ""
                  );
                } else {
                  value = this.$util.trimStr(this.mainTableData[fName] + "");
                }
                filter = filter.replace(allFields[fi], value);
              }
            }
          }
          return filter;
        }
      }
      return filter;
    },
    // 更改值方法
    // value 值
    // key 标识符
    // column 字段名
    handleChange (value, key, column, record, type, chinese, index) {
      console.log("根据" + column + "的值" + value + "进行计算");
      let tableBodyDom = this.$refs[this.domId].$el.getElementsByClassName(
        "vxe-table--body-wrapper"
      )[0];
      this.tableBodyDomScrollLeft = tableBodyDom.scrollLeft;
      // chinese 为中文显示名，一般打印用，需要特殊的getChinese方法才能拿出去
      if (
        (this.dataInMode && type !== "dataIn") ||
        (this.editOver && type !== "coerce")
      ) {
        return;
      }
      if (this.screenMode) {
        this.screenModeHandleChange(value, key, column, record, type);
      }

      let _this = this;
      let formulaQueue = [];
      if (this.allFormulaConfigs) formulaQueue = [...this.allFormulaConfigs];
      let copyRecord = _lodash.cloneDeep(record);
      if (type === "time") {
        value = value ? new moment(value).format("YYYY-MM-DD") : "";
      }
      // 处理时间日期控件的最大值和最小值变化
      this.handleDateChange(column, value);
      copyRecord[column] = value;

      let dataIndex = -1;
      const nowData = _lodash.cloneDeep(this.data);
      const target = nowData.find((item, index1) => {
        if (key === item[this.rowKey]) {
          dataIndex = index1;
          return true;
        }
        return false;
      });
      if (target) {
        target[column] = value;
        let changed = null;
        //已经存在的数据
        // 联动在这里处理
        if (
          _this.allFormulaConfigs &&
          _this.allFormulaArray &&
          _this.allFormulaArray.includes(column)
        ) {
          changed = {};
          //将已经存入data中的对象拷贝一份，用于计算
          copyRecord = _lodash.cloneDeep(target);
          this.allFormulaConfigs.forEach(element => {
            if (element.variableField.includes(column)) {
              let tempValue = _this.doCalculation1(element, null, copyRecord);
              console.log(
                "公式计算1:",
                element.resultField + "=" + element.formula + "=" + tempValue
              );
              copyRecord[element.resultField] = tempValue;
              changed[element.resultField] = tempValue;
              //copyRecord = this.$util.deepCopy(target);
              // 防死循环
              let maxFunction = formulaQueue.length;
              let num = 0;
              // 这里是为了防止多级联动失效
              // 比如 B 联动 A , C 联动 B , 更改 A 为 1 , B 联动生效为 1 , C 会因为拿不到联动更改的数据无法更改
              let childChanged = _this.doFormulaField(
                copyRecord,
                element.resultField,
                formulaQueue,
                num,
                maxFunction
              );
              changed = Object.assign(changed, childChanged);
              for (let field in changed) {
                target[field] = changed[field];
              }
            } else target[column] = value; // ????
            if (chinese != null && chinese !== "") {
              // 特殊字符特殊命名
              if (!target["detail_table_chinese_value"]) {
                target["detail_table_chinese_value"] = {};
              }
              target["detail_table_chinese_value"][column] = chinese;
            }
          });
        } else {
          target[column] = value;
          if (chinese && chinese !== "") {
            // 特殊字符特殊命名
            if (!target["detail_table_chinese_value"]) {
              target["detail_table_chinese_value"] = {};
            }
            target["detail_table_chinese_value"][column] = chinese;
          }
        }
        console.log("row=" + dataIndex, target);
        this.$set(this.data, dataIndex, target);
        //this.scrollX(tableBodyDomScrollLeft, tableBodyDomScrollTop);
        return copyRecord;
      }
    },
    /**
     * 依次计算公式队列中的公式，并将公式计算结果对象返回
     */
    doFormulaField (copyRecord, resultField, formulaQueue, num, maxFunction) {
      num = num + 1;
      let changed = {};
      for (let key in formulaQueue) {
        let index = formulaQueue[key]["variableField"].findIndex(
          item => item === resultField
        );
        if (index > -1) {
          let element = formulaQueue[key];
          let value = this.doCalculation1(element, null, copyRecord);
          console.log(
            "公式计算2:",
            element.resultField + "=" + element.formula + "=" + value
          );
          if (copyRecord[element.resultField] === value) {
            return;
          }
          changed[element.resultField] = value;
          copyRecord[element.resultField] = value;
          let nextKey = element.resultField;
          // delete formulaQueue[key];
          if (num > maxFunction * 10) {
            this.$message.error(
              "明细表公式计算超出最大循环" +
              maxFunction * 1000 +
              ",公式为:" +
              element.resultField +
              "=" +
              element.formula
            );
          } else {
            let childChanged = this.doFormulaField(
              copyRecord,
              nextKey,
              formulaQueue,
              num,
              maxFunction
            );
            changed = Object.assign(changed, childChanged);
          }
        } else {
          // delete formulaQueue[key];
        }
      }
      return changed;
    },
    /**
     * 点击单元格触发行编辑
     * @param row
     * @param e
     */
    clickTableCell (row, e) {
      if (
        row.row.editable ||
        row.column.type == "checkbox" ||
        e.target.className.indexOf("upload-img") != -1
      ) {
        return;
      }
      if (!this.editOver && !this.readOnly) {
        // 表单没有设置编辑结束,并且表单可编辑
        //行是否禁用
        if (!this.formulaCannotBeFilled(this.data[row.rowid])) {
          this.edit(row.rowid, row.column.property);
          if (row.column.property) {
            this.focusDataIndex = row.column.property;
          }
        }
      }
    },

    // 编辑
    edit (key, foucs) {
      let _this = this;
      let rowKey = this.rowKey;
      const newData = _lodash.cloneDeep(this.data);

      let editIndexBackUp = null;
      let dataIndex = -1;

      const targetCommon = newData.filter((item, index) => {
        try {
          if (key.toString() === item[_this.rowKey].toString()) {
            editIndexBackUp = item[_this.rowKey];
            dataIndex = index;
            return true;
          }
        } catch (err) {
          try {
            if (Number(key) == Number(item[_this.rowKey])) {
              editIndexBackUp = item[_this.rowKey];
              dataIndex = index;
              return true;
            }
          } catch (errr) {
            if (key === item[_this.rowKey]) {
              editIndexBackUp = item[_this.rowKey];
              dataIndex = index;
              return true;
            }
          }
        }
      })[0];

      if (targetCommon) {
        // targetCommon.editable = true;
        this.cacheDataPush(targetCommon);
      }
      if (this.screenMode) {
        const screenModeData = [...this.screenData];
        const target = screenModeData.filter(items => {
          return key === items[rowKey];
        })[0];
        if (target) {
          // target.editable = true;
          this.screenData = [...screenModeData];
        }
      }
      // this.CheckAndcancel(key);
      if (targetCommon) {
        // 添加点击明细表外部事件退出编辑行的事件
        this.setElementOnBlurListener(
          true,
          this.domId + "_TABLE",
          "CheckAndcancel"
        );
      }
      //console.log("editIndexBackUp", editIndexBackUp);
      // 这里将编辑先清空，再渲染，可以解除一些缓存问题
      if (editIndexBackUp) {
        // 重新渲染editrow
        this.editIndex = null;
        this.editIndex = editIndexBackUp;
        editOk();
      }
      // this.haveX();
      function editOk () {
        _this.setEditRowStyle(null, "editok");
        if (foucs) {
          _this.editFoucs(foucs);
        }
      }
      if(this.$refs[this.domId]) {
        //记录点击编辑的时候的滚动条位置
        let tableBodyDom = this.$refs[this.domId].$el.getElementsByClassName(
          "vxe-table--body-wrapper"
        )[0];
        if (tableBodyDom) {
          this.tableBodyDomScrollLeft = tableBodyDom.scrollLeft;
          this.tableBodyDomScrollTop = tableBodyDom.scrollTop;
        }
        console.log("记录到的滚动条位置", this.tableBodyDomScrollTop);
      }
      // this.activeHeadTitle(null, false);
    },

    // 增几行
    handleAddNum (e) {
      console.log("e", e);
      if (e.key == "custom") {
        // 自定义行
        this.addVisible = true;
      } else if (this.$util.isNumberString(e.key)) {
        // 指定行
        this.addVisible = false;
        this.handleAdd(this.$util.getNumber(e.key));
      } else {
        // 指定字段的多行数据
        let addButtonObj = this.addButton;
        if (addButtonObj && addButtonObj.customBatchAdd) {
          // 点击了自定义增加多行字段对应的行数据
          if (addButtonObj.customBatchAdd.customBatchAddFiled === e.key) {
            if (this.$refs["CustomBatchAdd_" + e.key]) {
              this.$refs["CustomBatchAdd_" + e.key].openModal();
            }
          }
        }
      }
    },

    /**
     * 增加指定的行
     * @param rows 可以是空、数字或者是对象数值
     */
    handleAdd (rows) {
      let newData = _lodash.cloneDeep(this.addNullJson);
      let count = 0;
      this.setElementOnBlurListener(false, this.domId + "_TABLE");
      if (rows) {
        let num = 0;
        if (this.$util.isType(rows) === "Array") {
          //加入指定字段的值在num中的数据
          num = rows.length;
        } else if (this.$util.isType(rows) === "val") {
          num = rows;
        } else {
          return;
        }
        count = Number(this.count);
        let dataList = _lodash.cloneDeep(this.data);
        let screenModeAddNum = [];
        for (let i = 0; i < num; i++) {
          count = count + 1;
          let obj = JSON.parse(JSON.stringify(this.addNullJson));
          // 把传入的字段对应的值进行覆盖
          obj = Object.assign(obj, rows[i]);
          obj[this.rowKey] = count.toString();
          // 如果有下拉默认项
          if (this.defaultValueSave) {
            for (let keys in this.defaultValueSave) {
              obj[keys] = this.defaultValueSave[keys];
            }
          }
          dataList.push(obj);
          if (this.screenMode) {
            screenModeAddNum.push(obj);
          }
        }
        this.data = dataList;
        this.setLineNumber();
        if (this.screenMode) {
          this.screenData = [...this.screenData, ...screenModeAddNum];
        }
        this.$nextTick(() => {
          this.edit(this.data[this.data.length - 1][this.rowKey]);
        });
      } else {
        // 只追加一行
        count = this.count + 1;
        newData[this.rowKey] = count + "";
        if (this.defaultValueSave) {
          for (let key in this.defaultValueSave) {
            newData[key] = this.defaultValueSave[key];
          }
        }
        this.data = [...this.data, newData];
        this.setLineNumber();
        if (this.screenMode) {
          this.screenData = [...this.screenData, newData];
        }
        this.edit(newData[this.rowKey]);
      }
      let that = this;
      setTimeout(function(){
        that.$refs[that.domId].clearScroll().then(() => {
          that.scrollDown()
        });
      },20);

      console.log("明细数据插入后", this.data);
    },
    /**
     * 将滚动条拉到最底部
     */
    scrollDown() {
      //debugger
      const el = this.$refs[this.domId].$el.querySelector('.vxe-table--body-wrapper');
      if (el) {
        el.scrollTop = el.scrollHeight;
        this.tableBodyDomScrollTop = el.scrollHeight;
      }
    },
    // 删行
    handleDel () {
      var _this = this;
      var selectInfo = this.$refs[this.domId].getCheckboxRecords();
      // this.$refs[this.domId].removeCheckboxRow()
      var data = _lodash.cloneDeep(this.data);
      var key = [];
      for (var i = 0; i < selectInfo.length; i++) {
        key.push(selectInfo[i][this.rowKey]);
      }

      // return
      // const key = [...this.selectedRowKeys];
      if (key.length == 0) {
        this.$message.error("请选择需要删除的数据");
        this.delButtonConfirmVisible = false;
        return;
      }
      key.forEach(element => {
        // this.onDelete(element, this.screenMode);
        data = data.filter(item => item[_this.rowKey] !== element);
      });
      this.data = data;
      // 因为删除了清空正在编辑的列
      if (key.findIndex(item => item == this.editIndex) != -1) {
        this.editIndex = null;
      }

      if (this.screenMode) {
        var screenData = _lodash.cloneDeep(this.screenData);
        key.forEach(element => {
          // this.onDelete(element, this.screenMode);
          screenData = screenData.filter(
            item => item[_this.rowKey] !== element
          );
        });
        this.screenData = screenData;
      }

      this.setLineNumber();
      this.setEditRowStyle(null, "handleDel");
      this.delButtonConfirmVisible = false;
    },

    // 删除行操作
    onDelete (key, screenMode) {
      const data = _lodash.cloneDeep(this.data);
      this.data = data.filter(item => item[this.rowKey] !== key);
      if (screenMode) {
        const screenData = [...this.screenData];
        this.screenData = screenData.filter(item => item[this.rowKey] !== key);
      }
    },

    // 校验行退出编辑模式，就算没通过校验也会退出，但能添加自定义事件
    CheckAndcancel () {
      if (this.editIndex) {
        this.editIndex = null;
        console.log("editShowChange", 1);
        this.editShow = false;
        this.addVisible = false;
      }
    },
    /**
     * 响应筛选按钮，进行数据筛选
     */
    screen (result) {
      this.screenMode = true;
      // console.log("拥有筛选项，搜索结果:", result);
      if (result) {
        this.screenData = result;
        this.setEditRowStyle(null, "screen");
      }
    },
    // 筛选模式更变
    screenModeHandleChange (value, key, column, record, type) {
      // 以这个为拷贝对象为基准，使其输入使不会让值还输入值还是undefined
      var copyRecord = _lodash.cloneDeep(record);
      copyRecord[column] = value;
      if (type == "time") {
        value = value == null ? "" : new moment(value).format("YYYY-MM-DD");
      }
      const newData = [...this.screenData];
      const target = newData.filter(item => key === item[this.rowKey])[0];
      if (target) {
        // 联动在这里处理
        if (this.allFormulaConfigs) {
          this.allFormulaConfigs.forEach(element => {
            target[element.resultField] = this.doCalculation1(
              element,
              null,
              copyRecord
            );
          });
        }
        target[column] = value;
        this.screenData = newData;
      }
    },

    // 保存
    save (key, compulsive, notCheck) {
      const newData = [...this.data];
      const target = newData.filter(item => key === item[this.rowKey])[0];
      if (target) {
        if (notCheck) {
          notCheck;
        } else if (!compulsive) {
          for (var mustKey in this.isMust) {
            if (
              target[this.isMust[mustKey]] == "" ||
              target[this.isMust[mustKey]] == null
            ) {
              this.$message.error("请将必填项填写完后再保存");
              return;
            }
          }
        }
        // delete target.editable;
        target.editable = false;
        this.data = [...newData];
        // this.cacheData = newData.map(item => ({ ...item }));
      }
      if (this.screenMode) {
        const screenModeData = [this.screenData];
        const target = screenModeData.filter(
          item => key === item[this.rowKey]
        )[0];
        if (target) {
          // delete target.editable = false;
          target.editable = false;
          this.screenData = [...screenModeData];
          // this.cacheData = newData.map(item => ({ ...item }));
        }
      }
    },

    // 点击列事件
    activeHeadTitle (e) {
      if (this.readOnly || this.disabled) {
        // 不可编辑
        if (this.editOver) {
          return;
        }
      } else if (this.editOver) {
        return;
      }
      // var property = e.column.property;
      // var col = this.columns.filter((item) => !item.hideThis);
      var col = this.columns;
      var record = null;
      var index = null;
      var property = null;

      try {
        if (e) {
          property = e.column.property;
          record = col.find(item => item.fieldName == property);
        }
      } catch (err) {
        record = null;
        err;
      }

      for (var i = 0; i < col.length; i++) {
        if (record == null) {
          this.$set(this.columns[i], "active", false);
          this.pasteBody = [];
          this.activeDataIndex = null;
          // delete window.globalOnBlurListener[this.domId];
        } else {
          if (col[i].fieldName != property) {
            this.$set(this.columns[i], "active", false);
          } else {
            this.$set(this.columns[i], "active", true);
            this.activeDataIndex = col[i].fieldName;
          }
        }
      }

      this.CheckAndcancel(null);

      if (!record) {
        return;
      }
      this.setElementOnBlurListener(true, this.domId, "activeHeadTitle");
    },

    // 复制选中的到粘贴板
    copyHead () {
      var copyArray = [];
      if (this.readOnly || this.disabled) {
        // 不可编辑
        if (this.editOver) {
          return;
        }
      } else if (this.editOver) {
        return;
      }
      if (!this.activeDataIndex) {
        return;
      }
      for (var i = 0; i < this.data.length; i++) {
        if (this.data[i][this.activeDataIndex]) {
          copyArray.push(this.data[i][this.activeDataIndex]);
        } else {
          copyArray.push("");
        }
      }
      if (copyArray.length == 0) {
        return;
      }
      this.pasteBody = copyArray;
      this.$message.success("复制列成功,请选中其他列粘贴");
    },

    // 粘贴列
    pasteHead () {
      if (this.readOnly || this.disabled) {
        // 不可编辑
        if (this.editOver) {
          return;
        }
      } else if (this.editOver) {
        return;
      }
      if (this.pasteBody.length == 0) {
        // this.$message.warning("请Ctrl+C复制其他行后再粘贴");
        return;
      }
      var notPasteArray = [];
      for (var o = 0; o < this.columns.length; o++) {
        if (this.columns[o].readOnly) {
          notPasteArray.push(this.columns[o].dataIndex);
        }
      }

      if (notPasteArray.indexOf(this.activeDataIndex) == -1) {
        for (var i = 0; i < this.data.length; i++) {
          this.handleChange(
            this.pasteBody[i],
            this.data[i][this.rowKey],
            this.activeDataIndex,
            this.data[i]
          );
        }
        this.$message.success("粘贴成功");
      } else {
        this.$message.warning("不可编辑列不能执行粘贴操作");
      }
    },

    // 选择行
    checkboxChangeEvent (record) { },

    // 自动生成行号
    setLineNumber () {
      if (!this.changeHh) {
        for (var j = 0; j < this.data.length; j++) {
          if (this.data[j][this.colHhKey]) {
            if (this.maxHh < Number(this.data[j][this.colHhKey])) {
              this.maxHh = Number(this.data[j][this.colHhKey]);
            }
          }
        }
        for (var o = 0; o < this.data.length; o++) {
          if (!this.data[o][this.colHhKey]) {
            var hh = Number(this.maxHh) + 10;
            this.data[o][this.colHhKey] = hh;
            this.maxHh = hh;
          }
        }
      } else {
        if (this.data.length != 0) {
          for (var i = 0; i < this.data.length; i++) {
            this.data[i][this.colHhKey] = (Number(i) + 1) * 10;
          }
        }
      }
    },

    // 删除行时的泡泡确认框控制
    delButtonConfirmVisibleChange () {
      var selectInfo = this.$refs[this.domId].getCheckboxRecords();
      if (selectInfo.length == 0) {
        this.delButtonConfirmVisible = false;
        this.$message.error("请选择需要删除的数据");
        return;
      } else {
        this.delButtonConfirmVisible = true;
      }
    },

    // 缓存数据插入
    cacheDataPush (target) {
      var id = null;
      var data = {};
      for (var key in target) {
        if (key === this.rowKey) {
          id = target[key];
        }
        data[key] = target[key];
      }
      this.cacheData[id] = data;
    },

    // 格式化时间
    FormattingTime (date, format) {
      if (date == "" || date == null) {
        return date;
      }
      try {
        if (format) {
          date = new moment(date).format(format);
        } else date = new moment(date).format("YYYY-MM-DD");
      } catch {
        return "";
      }
      return date;
    },

    // 联动
    linked (jsonData, key, index) {
      console.log("linked", key, index, jsonData);
      const newData = _lodash.cloneDeep(this.data);
      const target = newData.findIndex(item => key === item[this.rowKey]);
      if (target != -1) {
        var data = null;
        var first = true;
        for (var jsonKey in jsonData) {
          if (first) {
            data = this.handleChange(
              jsonData[jsonKey],
              key,
              jsonKey,
              newData[target],
              "test"
            );
            first = false;
          } else {
            data = this.handleChange(
              jsonData[jsonKey],
              key,
              jsonKey,
              data,
              "test"
            );
          }
        }
      }
    },
    /**
     * 渲染获得Select控件的option数据
     */
    getCacheOption () {
      for (let i = 0; i < this.columns.length; i++) {
        let dataIndex = this.columns[i]["dataIndex"];
        if (
          (this.columns[i].controlType === "CxsGridComboBoxColumn" ||
            this.columns[i].controlType === "CxsGridComboEditBoxColumn") &&
          this.columns[i].listFilter &&
          this.columns[i].listFilter.sourceTable
        ) {
          if (
            this.columns[i].listFilter.haveVariable ||
            this.columns[i].listFilter.haveDetailVariable
          ) {
            this.getDataOneRowDynamicCache(
              dataIndex,
              this.columns[i].listFilter
            ); // 动态缓存
          } else {
            if (!this.filterSave[dataIndex]) {
              //静态缓存
              this.getDataStaticCache(dataIndex, this.columns[i].listFilter);
            }
          }
        } else {
          this.onGetFilterSave(
            {
              // 默认缓存
              option: this.columns[i].options,
              fieldName: dataIndex,
              rowKey: null
            },
            false
          );
        }
      }
      //console.log("firstLodingArray=====", this.firstLodingArray);
    },
    /**
     * 获取当前列表里最大的key
     */
    getLastRowKey () {
      return this.count;
    },
    /**
     * 获取 dataIndex字段对应Select的选项静态缓存
     * @param dataIndex
     * @param listFilter
     */
    getDataStaticCache (dataIndex, listFilter) {
      let newListFilter = Object.assign({}, listFilter);
      let that = this;
      this.getSelectOptions_MiXin(newListFilter, null, function (res) {
        if (res) {
          that.onGetFilterSave(
            {
              option: res.data ? res.data : [],
              fieldName: dataIndex,
              rowKey: null
            },
            false
          );
          that.cacheKey.push(dataIndex);
        }
      });
    },
    /**
     * 获取每条记录中需要缓存的下拉列表的列表选项
     * @param dataIndex 字段名
     * @param listFilter 字段配置的过滤条件
     */
    getDataOneRowDynamicCache (dataIndex, listFilter) {
      if (dataIndex && listFilter && this.data && this.data.length > 0) {
        let that = this;
        let promise1 = new Promise(resolve => {
          let oks = [];
          // 需要从明细中拉取多变量
          if (listFilter.haveDetailVariable) {
            for (let i = 0; i < that.data.length; i++) {
              let row = that.data[i];
              let rowKey = row[that.rowKey];
              let newListFilter = Object.assign({}, listFilter);
              let newFilter = newListFilter.oldFilter + "";
              newFilter = that.changeColumnsFilter(newFilter, row);
              newListFilter.filter = newFilter;
              that.getSelectOptions_MiXin(newListFilter, null, function (res) {
                if (res) {
                  that.onGetFilterSave(
                    {
                      option: res.data ? res.data : [],
                      fieldName: dataIndex,
                      rowKey: rowKey
                    },
                    res.haveVariable
                  );
                  oks.push(i);
                  if (that.data.length == oks.length) {
                    resolve(true);
                  }
                } else {
                  oks.push(i);
                }
              });
            }
          } else {
            // 不需要拉取明细变量
            let newListFilter1 = Object.assign({}, listFilter);
            let newFilter1 = newListFilter1.oldFilter + "";
            newFilter1 = that.changeColumnsFilter(newFilter1, null);
            newListFilter1.filter = newFilter1;
            that.getSelectOptions_MiXin(newListFilter1, null, function (res) {
              if (res) {
                that.onGetFilterSave(
                  {
                    option: res.data ? res.data : [],
                    fieldName: dataIndex,
                    rowKey: null
                  },
                  res.haveVariable
                );
              }
              resolve(true);
            });
          }
        }).catch(() => console.error("明细表下拉列表多行缓存异常"));
        promise1.then(function (value) {
          that.cacheKey.push(dataIndex);
          console.log("缓存到的多行缓存", that.filterCatch, value);
        });
      }
    },
    /**
     * 下拉自动获取值
     * @param text 下拉选项值
     * @param filed 控件字段名
     * @param key 对应的数据行id
     * @param again 再次搜索的次数
     * @param isMust 必填选项
     */
    selectCacheText (text, filed, key, again, isMust) {
      // console.log(text, "下拉自动获取值");
      if (text == null || text == "") {
        return "";
      }
      try {
        text = text + "";
        text = text.trim();
      } catch (err) {
        //console.log(text, "下拉自动获取值trim无效");
      }
      //  console.log("第"+key+"行："+filed+",value="+text+"的缓存：",this.filterCatch['_'+key][filed],"this.filterSave[filed]=",this.filterSave[filed]);
      if (
        this.filterSave[filed] ||
        (this.filterCatch["_" + key] && this.filterCatch["_" + key][filed])
      ) {
        let options =
          this.filterCatch["_" + key] && this.filterCatch["_" + key][filed]
            ? this.filterCatch["_" + key][filed]
            : this.filterSave[filed];
        for (let i = 0; i < options.length; i++) {
          let optionValue = options[i].value + "";
          optionValue = optionValue.trim();
          if (text === optionValue) {
            //  console.log("转换第"+key+"行："+filed+"=值为："+options[i].label);
            return options[i].label;
          }
        }
        return text;
      } else {
        if (this.getCacheOk == false) {
          if (!again) {
            this.selectCacheText(text, filed, key, 1);
          } else {
            if (again > 10) {
              return "没有获取到下拉列表数据";
            } else {
              this.selectCacheText(text, filed, key, again + 1);
            }
          }
        } else {
          //console.log(text, "返回空");
          if (isMust) {
            return "*";
          }
          return "";
        }
      }
    },

    // checkBox格式化文本
    checkBoxCacheText (text) {
      if (text == null || text == "") {
        return "否";
      }
      if (text == true || text === "true" || text === "True") {
        return "是";
      }
      if (text == false || text === "false" || text === "False") {
        return "否";
      }
    },

    // 根据配置的N几来精确小数点几位
    colFormat (value, format, changeOption) {
      if (value) {
        var oldValue = value;
        var reg = /^[N,C]\d+$/;
        if (reg.test(format)) {
          value = Number(value).toFixed(format.substr(1));
          if (this.$util.mathUtil.accSub(oldValue, Number(value)) != 0) {
            this.handleChange(
              value,
              changeOption.index,
              changeOption.dataIndex,
              changeOption.row,
              "coerce"
            );
          }
        } else if (this[format]) {
          return this[format](value);
        }
      }
      return value;
    },

    // 小数点精度
    numberPrecision (str) {
      var reg = /^[N,C]\d+$/;
      if (reg.test(str)) {
        return Number(str.substr(1));
      }
      return 0;
    },
    /**
     * 根据format判断该列是否是数据输入框
     */
    isNumberText (str) {
      let reg = /^[N,C]\d+$/;
      return reg.test(str);
    },

    editFindIndex (index) {
      var target;
      if (this.screenMode) {
        target = this.screenData.findIndex(
          item => item[this.rowKey] === index.toString()
        );
      } else {
        target = this.data.findIndex(
          item => item[this.rowKey] === index.toString()
        );
      }

      return target;
    },
    /**
     * 动态隐藏列
     */
    hideFieldsSet () {
      console.log("动态改变隐藏列:this.hideFieldsArray=", this.hideFieldsArray);
      let cols;
      if (
        this.$util.isType(this.hideFieldsArray) === "Array" &&
        this.hideFieldsArray.length > 0
      ) {
        cols = [...this.columns];
        if (cols.length > 0) {
          for (let i = 0; i < cols.length; i++) {
            let col = cols[i];
            //如果该列包含在需要隐藏的列中
            if (this.hideFieldsArray.includes(col["dataIndex"])) {
              col["hideThis"] = true;
            } else {
              if (col.visible !== false) {
                col["hideThis"] = false;
              } else {
                col["hideThis"] = true;
              }
            }
          }
        }
        this.columns = cols;
      }
    },
    /**
     * 修改列可编辑状态
     */
    fieldsAllowedEditSet () {
      console.log(
        "动态改变可编辑列:this.alloEditArray=",
        this.alloEditArray,
        "this.alloNotEditArray=",
        this.alloNotEditArray
      );
      let value;
      let cols;
      if (this.disabled) {
        // 表被禁用的情况下
        //如果未定义可编辑列，则所有列都不能编辑
        if (this.$util.isType(this.alloEditArray) !== "Array") {
          value = [];
        } else if (
          this.$util.isType(this.alloNotEditArray) === "Array" &&
          this.alloNotEditArray.length > 0
        ) {
          // 否则需要从可编辑列中，将定义为不可编辑列的过滤掉
          value = this.alloEditArray.filter(function (f) {
            return !this.alloNotEditArray.includes(f);
          });
        } else {
          value = this.alloEditArray;
        }
        cols = [...this.columns];
        if (cols.length > 0) {
          for (let i = 0; i < cols.length; i++) {
            let col = cols[i];
            //如果该列包含在可编辑列定义中
            if (value.includes(col["dataIndex"])) {
              col["readOnly"] = false;
              col["editable"] = true;
            } else {
              col["readOnly"] = true;
              col["editable"] = false;
            }
          }
        }
        this.columns = cols;
      } else {
        // 表未被禁用,this.alloEditArray配置无效
        // 根据alloNotEditArray将不可编辑项设置为readOnly
        if (
          this.$util.isType(this.alloNotEditArray) === "Array" &&
          this.alloNotEditArray.length > 0
        ) {
          cols = [...this.columns];
          value = this.alloNotEditArray;
          for (let i = 0; i < cols.length; i++) {
            let col = cols[i];
            //如果该列包含在“不可编辑列”定义中
            if (value.includes(col["dataIndex"])) {
              col["readOnly"] = true;
              col["editable"] = false;
            }
          }
          this.columns = cols;
        }
      }
    },
    /**
     * 设置必填列
     */
    requiredFieldSet () {
      console.log("动态改变可必填列:this.alloMustArray=", this.alloMustArray);
      let _this = this;
      let cols = [...this.columns];
      let value = this.alloMustArray;
      if (cols.length > 0 && this.disabled) {
        // 表被禁用的情况下
        if (this.$util.isType(value) === "Array" && value.length > 0) {
          for (let i = 0; i < cols.length; i++) {
            let col = cols[i];
            //如果该列包含在必填列定义中
            if (value.includes(col["dataIndex"])) {
              try {
                col["isMust"] = true;
                if (!_this.isMust.includes(col["dataIndex"])) {
                  _this.isMust.push(col["dataIndex"]);
                }
              } catch (err) {
                console.log("Etable添加动态必填报错：" + err);
              }
            }
          }
        }
      }
    },
    /**
     * 判断配置的自定义批量根据指定选人字段值增行配置是否有效
     * @param customBatchAdd
     */
    checkCanCustomBatchAdd (customBatchAdd) {
      if (
        customBatchAdd &&
        customBatchAdd.customBatchAddFiled &&
        this.option &&
        this.option.columns &&
        this.option.columns.length > 0
      ) {
        if (this.customBatchAddControl == null) {
          let thisColumn = this.option.columns.find(function (column) {
            return column.fieldName === customBatchAdd.customBatchAddFiled;
          });
          if (thisColumn) {
            if (thisColumn.controlType === "CxsGridTexBoxButtonColumn") {
              // 支持多选选择的控件类型
              customBatchAdd.editorFormType = thisColumn.editorFormType;
              if (!customBatchAdd.customBatchAddFiledName) {
                customBatchAdd.customBatchAddFiledName =
                  "多行" + thisColumn.headerText;
              }
              this.customBatchAddControl = Object.assign(
                {},
                thisColumn,
                customBatchAdd
              );
              console.log("customBatchAddControl", this.customBatchAddControl);
              return true;
            }
          }
        } else {
          return true;
        }
      }
      return false;
    },
    /**
     * 监听选择自定义导入字段对应的行
     */
    onSelectCustomBatchAdd (event, linkedClumns) {
      console.log("选中的明细字段值", event, linkedClumns);
      if (this.customBatchAddControl && event) {
        let fieldValues = event.split(",");
        let datas = [];
        for (let i = 0; i < fieldValues.length; i++) {
          let obj = {};
          obj[this.customBatchAddControl.fieldName] = fieldValues[i];
          if (linkedClumns && linkedClumns[i]) {
            //有联动数据
            obj = Object.assign(linkedClumns[i], obj);
          }
          datas.push(obj);
        }
        this.handleAdd(datas);
      }
    },

    // 获得数据
    getData () {
      var data = _lodash.cloneDeep(this.data);
      var columns;
      if (this.config.columns) {
        columns = [...this.config.columns];
      } else {
        columns = [...this.columns];
      }
      // 去除多余数据 指行定义里没有的字段
      for (var i = 0; i < data.length; i++) {
        for (var key in data[i]) {
          if (key != "ID") {
            var index = columns.findIndex(item => key === item["fieldName"]);
            if (index == -1) {
              delete data[i][key];
            }
          }
        }
        // 给没值得行定义内容赋null
        for (var j = 0; j < columns.length; j++) {
          if (data[i][columns[j]["fieldName"]]) {
            continue;
          } else {
            data[i][columns[j]["fieldName"]] = null;
          }
        }
        data[i]["ID"] = data[i]["ID"] ? data[i]["ID"] : null;
      }
      return data;
    },

    // 取得当前显示的列及状态
    getCol () {
      return _lodash.cloneDeep(this.columns).filter(item => !item.hideThis);
    },

    // 获得中文值
    getChineseValue () {
      var data = _lodash.cloneDeep(this.data);
      for (var i = 0; i < data.length; i++) {
        if (data[i]["detail_table_chinese_value"]) {
          // 如果有detail_table_chinese_value
          for (var key in data[i]["detail_table_chinese_value"]) {
            data[i][key] = data[i]["detail_table_chinese_value"][key];
          }
        }
        for (var keys in data[i]) {
          if (this.filterSave[keys]) {
            var index = this.filterSave[keys].findIndex(item => {
              try {
                if (
                  data[i][keys].toString().trim() ===
                  item.value.toString().trim()
                ) {
                  return true;
                }
              } catch {
                debugger;
              }
            });
            if (index != -1) {
              data[i][keys] = this.filterSave[keys][index].label;
            }
          }
        }
      }
      return data;
    },

    // 返回是否必填校验
    getValidateResult () {
      var tableName = this.option.tableName;
      var err = [];
      for (var i = 0; i < this.data.length; i++) {
        var errJson = {};
        // var errorObj = {};
        for (var key in this.isMust) {
          key = this.isMust[key];
          if (this.data[i][key] == "" || this.data[i][key] == null) {
            var nameIndex = this.columns.findIndex(
              item => key === item["dataIndex"]
            );
            errJson[key] = {
              message: "请输入" + this.columns[nameIndex]["title"]
            };
          }
        }
        if (!this.$util.objectIsNull(errJson)) {
          err.push({
            index: i,
            error: errJson
          });
        }
      }
      var returnData = {
        tableName: tableName,
        errors: err
      };
      return returnData;
    },

    // 追加插入数据
    insertData (data, notHH) {
      let _this = this;
      let newData = [];
      let count = Number(this.count);
      for (let i = 0; i < data.length; i++) {
        count++;
        data[i][this.rowKey] = count.toString();

        // 如果传入的是true，则会删除传入的HH
        if (notHH) {
          if (data[i][this.colHhKey]) {
            delete data[i][this.colHhKey];
          }
        }
        // 如果传入的数据对象没有某些对应的字段值，则会使用默认值替换掉
        let tempData = Object.assign({}, this.addNullJson, data[i]);
        // editFind[i]["isDelChangeHH"] = true;
        // this.editFind.push(editFind[i]);
        newData.push(tempData);
      }
      let newDataList = [].concat([], this.data, newData);
      this.data = newDataList;

      if (this.allFormulaConfigs) {
        // 再计算一次
        for (let j = 0; j < newData.length; j++) {
          let thisNewData = newDataList.find(item => {
            return item[_this.rowKey] === newData[j][_this.rowKey];
          });
          if (thisNewData) {
            /*for (let key in thisNewData) {
              if (_this.allFormulaArray.indexOf(key) != -1) {
                this.handleChange(
                  thisNewData[key],
                  thisNewData[this.rowKey],
                  key,
                  thisNewData
                );
              }
            }*/
            console.log(
              "计算第" + (j + 1) + "行数据-----------------------------"
            );
            this.changedManualTrigger(thisNewData);
            /*let loopArray = this.onlyFormulaVariable.length>0 ? this.onlyFormulaVariable :this.allFormulaArray;
            loopArray.forEach(function(fieldName){
                if(thisNewData[fieldName]) {
                  _this.handleChange(
                    thisNewData[fieldName],
                    thisNewData[_this.rowKey],
                    fieldName,
                    thisNewData
                  );
                }
            });*/
          }
        }
      }
      this.setLineNumber();
      console.log("插入数据：", this.data);
      this.$message.success("插入数据成功");
    },
    /**
     *手动触发一行数据的chenge事件的业务
     */
    changedManualTrigger (thisNewData) {
      let _this = this;
      let loopArray =
        this.onlyFormulaVariable.length > 0
          ? this.onlyFormulaVariable
          : this.allFormulaArray;
      loopArray.forEach(function (fieldName) {
        if (thisNewData[fieldName]) {
          _this.handleChange(
            thisNewData[fieldName],
            thisNewData[_this.rowKey],
            fieldName,
            thisNewData
          );
        }
      });
    },
    // 设置数据(覆盖)
    setData (data) {
      this.data = [];
      this.insertData(data);
    },
    /**
     * 修改行数据
     * @param rowObject
     * @param keyField
     */
    updateRowData (rowObject, keyField) {
      if (rowObject) {
        let keyName = this.rowKey;
        if (this.$util.isType(keyField) === "String" && keyField !== "") {
          keyName = keyField;
        }
        let key = rowObject[keyName];
        if (key) {
          let index = this.data.findIndex(function (row) {
            return row[keyName] === key;
          });
          if (index >= 0) {
            let upObject = Object.assign({}, this.data[index], rowObject);
            this.$set(this.data, index, upObject);
          }
        }
      }
    },

    // 设置初始的rowkey;
    setDataSource () {
      let i = 0;
      for (i = 0; i <= this.dataSource.length - 1; i++) {
        this.dataSource[i][this.rowKey] = i + "";
        if (this.changeValues.length != 0 && this.dataSource[i]["ID"]) {
          for (var j = 0; j < this.changeValues.length; j++) {
            if (
              this.changeValues[j].dataID &&
              this.$util.numberStringBoolean(
                this.dataSource[i]["ID"],
                this.changeValues[j].dataID
              ) &&
              this.changeValues[j].operateType != 1 &&
              this.changeValues[j].operateType != "1"
            ) {
              this.dataSource[i]["etable_changeValues"] = this.changeValues[j];
              this.dataSource[i]["readOnly"] = true;
            }
          }
        }
      }
      for (var o = 0; o < this.changeValues.length; o++) {
        if (
          this.changeValues[o].operateType == 1 ||
          this.changeValues[o].operateType == "1"
        ) {
          var newObj = this.changeValues[o]["editFind"];
          newObj["etable_changeValues"] = this.changeValues[o];
          this.dataSource.unshift(newObj);
        }
      }
      return this.dataSource;
    },

    // 获得上传文件的url
    getUploadUrl (data) {
      // var html = "";
      if (data == null || data == "") {
        return [];
      }

      var returnData = [];

      var isJson = true;
      if (typeof data != "object") {
        try {
          data = JSON.parse(data);
        } catch (err) {
          err;
          isJson = false;
        }
      }

      if (isJson) {
        for (var j = 0; j < data.length; j++) {
          returnData.push(window.UPLOAD_URL + data[j]["p"]);
        }
      } else {
        data = data.split(",");
        for (var i = 0; i < data.length; i++) {
          returnData[window.UPLOAD_URL + data[i]];
        }
      }

      return returnData;
    },

    // 页尾合计
    footerMethod ({ columns, data }) {
      var sums = [];
      sums.push("合计");
      var col = _lodash.cloneDeep(this.columns);

      for (var i = 0; i < columns.length; i++) {
        var sumCell = null;
        var colIndex = col.findIndex(
          item => item.fieldName === columns[i].property
        );
        if (colIndex != -1) {
          if (col[colIndex].isSum) {
            sumCell = this.$util.mathUtil.round3(
              XEUtils.sum(data, columns[i].property)
            );
            sums.push(this.$util.formatNumberRgx(sumCell, true));
          } else {
            sums.push("");
          }
        }
      }
      return [sums];
    },

    scrollCache (first) {
      var _this = this;
      var tableBodyDom;
      // console.log("fieldsAllowedEdit=",value);
      this.$nextTick(() => {
        setTimeout(function(){
          tableBodyDom = _this.$refs[_this.domId].$el.getElementsByClassName(
            "vxe-table--body-wrapper"
          )[0];
          tableBodyDom.scrollLeft = _this.tableBodyDomScrollLeft;
          tableBodyDom.scrollTop = _this.tableBodyDomScrollTop;
          console.log("设置滚动条位置",_this.tableBodyDomScrollTop);
          if (first) {
            _this.scrollCache();
          }
        },20);
      });
    },

    // 拿行数据填充filter search
    filterValueChange (str, data) {
      // 拿 %{{???}}%
      if (str != null && str != "") {
        // var reg = /\'\%\{\{(.*?)\}\}\%\'/g;
        var reg = /\{\{(.*?)\}\}/g;
        var filed = str.match(reg);
        if (filed) {
          var strSplit = str.split(reg);
          for (var i = 0; i < filed.length; i++) {
            filed[i] = filed[i].slice(2, filed[i].length - 2);
            for (var j = 0; j < strSplit.length; j++) {
              if (strSplit[j] == filed[i]) {
                strSplit[j] = data[filed[i]] ? data[filed[i]].trim() : "";
                // strSplit[j] = "'%" + strSplit[j] + "%'";
                continue;
              }
            }
          }

          var rStr = "";
          strSplit.forEach(strItem => {
            rStr = rStr + strItem;
          });

          return [rStr, true];
        } else {
          return [str, false];
        }
      } else {
        return "";
      }
    },

    // 动态条件是否必填
    conditionDynamicallyEditable (saveCondition, colData) {
      var result = true;
      var _this = this;
      try {
        if (saveCondition) {
          let obj = this.getSaveCondition(saveCondition);
          if (obj) {
            let saveCondition = obj.saveCondition;
            var tempConditions = "";
            for (var i = 0; i < obj.variableFields.length; i++) {
              let variable = obj.variableFields[i];
              let fValue = colData[variable.fieldName]
                ? getValidTypesValueString(colData[variable.fieldName])
                : "0";
              let thisCondition = saveCondition;
              thisCondition = thisCondition.replace(
                variable.oldArgName,
                fValue
              );
              tempConditions = thisCondition;
            }

            tempConditions = tempConditions.replace(/or/g, "||");
            tempConditions = tempConditions.replace(/and/g, "&&");
            //console.log("校验公式替换结果", tempConditions);
            result = eval(tempConditions);
            if (this.$util.isType(result) === "Boolean") {
              return result;
            }
          }
        }
        return false;
      } catch (err) {
        console.log("etable 动态条件必填出现问题：" + err);
        return false;
      }

      function getValidTypesValueString (v) {
        if (v) {
          return "'" + v + "'";
        }
        return "";
      }
    },

    checkBoxDisable (row) {
      if (this.formulaCannotBeFilled(this.data[row.rowIndex])) {
        return false;
      }
      return true;
    },
    /**
     * x轴滚动到指定位置
     * @param tableBodyDomScrollLeft
     */
    scrollX (tableBodyDomScrollLeft) {
      this.$nextTick(() => {
        this.$refs[this.domId].scrollTo(tableBodyDomScrollLeft);
        // tableBodyDom.scrollLeft = tableBodyDomScrollLeft;
      });
    },
    // 滚动滚动条事件
    moveScroll (type) {
      var scroll = this.$refs[this.domId].getScroll().scrollLeft;
      var newScroll = 0;
      if (type == "left") {
        newScroll = scroll - 300;
        if (newScroll < 0) {
          newScroll = 0;
        }
      }
      if (type == "right") {
        newScroll = scroll + 300;
      }
      this.$refs[this.domId].scrollTo(newScroll);
    },
    // 按住滚动滚动条
    moveScrollStart (type) {
      var _this = this;
      clearTimeout(this.moveScrollTimeOutEvent);
      this.moveScrollTimeOutEvent = setTimeout(() => {
        clearTimeout(_this.moveScrollTimeOutEvent);
        timeOut();
      }, 300);

      function timeOut () {
        _this.moveScroll(type);
        clearTimeout(_this.moveScrollTimeOutEvent);
        _this.moveScrollTimeOutEvent = setTimeout(() => {
          clearTimeout(_this.moveScrollTimeOutEvent);
          timeOut();
        }, 50);
      }
    },
    // 结束按住滚动条
    moveScrollEnd () {
      clearTimeout(this.moveScrollTimeOutEvent);
    },
    rowClassName (row) {
      var className = "e-table-row_style";
      className = className + " " + this.domId + "row" + row.rowid;
      if (
        row.row.etable_changeValues &&
        row.row.etable_changeValues.operateType
      ) {
        if (
          row.row.etable_changeValues.operateType == 1 ||
          row.row.etable_changeValues.operateType == "1"
        ) {
          className = className + " " + "new-row-change";
        } else if (
          row.row.etable_changeValues.operateType == 2 ||
          row.row.etable_changeValues.operateType == "2"
        ) {
          className = className + " " + "change-row-change";
        } else if (
          row.row.etable_changeValues.operateType == 3 ||
          row.row.etable_changeValues.operateType == "3"
        ) {
          className = className + " " + "del-row-change";
        }
      }
      if (this.formulaCannotBeFilled(row.row)) {
        className = className + " " + "disable-row-change";
      }
      return className;
    },
    /**
     * 检验行禁用编辑条件是否满足
     * @param data
     * @return {*}
     */
    formulaCannotBeFilled (data) {
      if (
        !this.nowCannotFormula ||
        this.nowCannotFormula == "" ||
        data == null
      ) {
        return false;
      }
      let obj = this.getSaveCondition(this.nowCannotFormula);
      if (obj) {
        let tempConditions = obj.saveCondition;
        for (let i = 0; i < obj.variableFields.length; i++) {
          let variable = obj.variableFields[i];
          if (variable.tableName) {
            // 从明细表获取数据
            try {
              var fValue = data[variable.fieldName]
                ? getValidTypesValueString(data[variable.fieldName])
                : "0";
            } catch (err) {
              err;
              debugger;
            }
            //console.log("明细表单数据：",editFind, fValue, tempConditions);
            tempConditions = tempConditions.replace(
              variable.oldArgName,
              fValue
            );
          } else {
            // 从主表单获取数据
            let mValue = this.mainTableData[variable.oldArgName]
              ? getValidTypesValueString(
                this.mainTableData[variable.oldArgName]
              )
              : "0";
            tempConditions = tempConditions.replace(
              variable.oldArgName,
              mValue
            );
          }
        }

        tempConditions = tempConditions.replace(/or/g, "||");
        tempConditions = tempConditions.replace(/and/g, "&&");
        //console.log("校验公式替换结果", tempConditions);
        try {
          let result = eval(tempConditions);
          if (this.$util.isType(result) === "Boolean") {
            return result;
          }
        } catch (e) {
          console.error("条件表单式错误", tempConditions, e);
          return false;
        }
      }
      // }
      function getValidTypesValueString (v) {
        if (v) {
          return "'" + v + "'";
        }
        return "";
      }
    },

    // 校验数据
    checkData (value, field) {
      if (value == "" || value == null) {
        return [true];
      }
      if (this.rule[field]) {
        var returnMessage = "";
        for (var i = 0; i < this.rule[field].length; i++) {
          var reg = this.rule[field][i].pattern;
          if (reg) {
            if (reg.test(value)) {
              continue;
            } else {
              returnMessage =
                returnMessage + this.rule[field][i].message + "  并且  ";
            }
          }
        }
        if (returnMessage == "") {
          return [true];
        } else {
          returnMessage = returnMessage.substr(0, returnMessage.length - 6);
          return [returnMessage];
        }
      } else {
        return [true];
      }
    },

    test () {
      this.conditionDynamicallyEditable(
        "[GXC_CKDMXB._HH]==='10'",
        this.data[0]
      );
    }
  },
  beforeDestroy () { },
  destroyed () {
    // debugger;
  }
};
</script>
<style scoped lang="less">
@import "../../../assets/style/base/controls/e-table.less";
</style>
