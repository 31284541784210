<!-- 从统一用户管理平台跳转过的页面中转 -->
<template>
  <div style="padding: 200px;">
    <a-spin
      :spinning="showFormSpin"
      tip="页面加载中..."
      size="large"
      class="form-spin"
    >
    </a-spin>
    <div v-show="showFormError">
      <a-empty :description="message" />
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import qs from "qs";
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法
export default {
  mixins: [requestControls],
  name: "TransferRedirect",
  data() {
    return {
      showFormSpin: true,
      showFormError: false,
      message: "页面打开失败"
    };
  },
  mounted: function() {
    console.log("跳转请求携带的路由属性：" + JSON.stringify(this.$route.query)); // 从菜单直接带过来
    let that = this;
    let token = this.$route.query.token;
    let redirectType = this.$route.query.redirectType;
    let redirectUri = this.$route.query.redirectUri;
    if (redirectType === "0" || redirectType === "1") {
      // 应用或待办事件
      let promise = new Promise(function(resolve, reject) {
        that.toLogin(token, resolve, reject);
      });
      if (redirectType === "0") {
        // 直接跳转到应用首页
        promise.then(
          function() {
            console.log("TransferRedirect执行登录成功");
            that.$router.push({
              path: "/",
              replace: true
            }); //跳转主页
            that.showFormSpin = false;
          },
          function(reason) {
            that.showFormSpin = false;
            that.showFormError = true;
            if (reason.message) {
              that.message = that.message + "," + reason.message;
            }
            console.log("失败执行回调抛出失败原因：", reason);
          }
        );
      } else if (redirectUri) {
        // 跳转到应用主页，并且打开指定页面
        promise.then(
          function() {
            console.log("TransferRedirect执行登录成功");
            that.$router.push({
              path: "/",
              query: { redirectUri: redirectUri },
              replace: true
            }); //跳转主页
            that.showFormSpin = false;
          },
          function(reason) {
            that.showFormSpin = false;
            that.showFormError = true;
            if (reason.message) {
              that.message = that.message + "," + reason.message;
            }
            console.log("失败执行回调抛出失败原因：", reason);
          }
        );
      }
    } else if (redirectType === "2") {
      // 新闻链接
    }
  },
  methods: {
    /**
     * 去校验登录
     */
    toLogin: function(token, resolve, reject) {
      let that = this;
      let params = {
        action: "LoginByThreeApps",
        Token: token
      };
      let url = this.BaseUrl + this.ServerPath + "System.ashx?" + params.action;
      Axios.post(url, qs.stringify(params), {
        headers: {
          "Content-Typ": "application/json; charset=utf-8"
        }
      })
        .then(resp => {
          if (resp && resp.status == 200) {
            if (resp.data && resp.data.success) {
              let req = resp.data;
              Axios.defaults.headers.common["Authorization"] =
                req.data.randomKey;
              that.$store.commit("login", {
                userName: req.data.userName,
                userDept: req.data.depart,
                userID: req.data.userId,
                userQrimg: "/static/qrtest.png",
                randomKey: req.data.randomKey,
                employee: req.data.employee,
                departId: req.data.departId,
                employeeId: req.data.employeeId,
                userHeadLogo:
                  "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png", //测试头像，需要根据需求设置
                companyId: req.data.companyId
              });
              resolve();
            } else {
              reject(resp.data);
            }
          } else {
            reject(resp);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    }
  }
};
</script>
