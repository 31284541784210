<template>
  <div>
    <!-- 菜单折叠展开按钮 -->
    <div
      class="folding-switch"
      :class="collapsed ? 'folding-switch-middle' : ''"
      :style="{ float: collapsedFloat }"
      @click.stop="switchCollapsed"
      @mouseover.stop="() => {}"
      @mouseout.stop="() => {}"
    >
      <e-icon iClass="trigger" :type="collapsedICon" class="e-menu-icon" />
    </div>
    <!-- 菜单树 $event-->
    <div class="leftSiderbar">
      <a-menu
        mode="inline"
        :inlineIndent="12"
        :selectedKeys="selectedKey"
        @click="onClickMenu"
        :openKeys="openKeys"
        @openChange="onOpenChange"
        forceSubMenuRender
        :overflowedIndicator="getIcon"
      >
        <template v-for="menu in menus">
          <!-- 第一层菜单 -->
          <a-sub-menu
            v-if="menu.submenu && menu.submenu.length > 0"
            :key="menu.key"
            :data-dhbm="menu.DHBM"
            :data-path="menu.path"
            :data-title="menu.menuName"
            :data-name="menu.name"
            class="erp-menu-top"
          >
            <span slot="title">
              <!-- 第一层菜单需要图标 -->
              <e-icon
                v-if="menu.iconCls"
                :key="menu.key + '_icon'"
                :type="menu.iconCls"
                class="e-menu-icon"
              /><span>{{ menu.menuName }}</span>
            </span>
            <template v-for="sMenu in menu.submenu">
              <!-- 第二层菜单 -->
              <a-sub-menu
                v-if="sMenu.submenu && sMenu.submenu.length > 0"
                :key="sMenu.key"
                :data-dhbm="sMenu.DHBM"
                :title="sMenu.menuName"
                :data-path="sMenu.path"
                :data-title="sMenu.menuName"
                :data-name="sMenu.name"
                class="left-sider-sub-menu"
              >
                <template v-for="ssMenu in sMenu.submenu"
                  ><!-- 第三层菜单 -->
                  <a-sub-menu
                    v-if="ssMenu.submenu && ssMenu.submenu.length > 0"
                    :key="ssMenu.key"
                    :data-dhbm="ssMenu.DHBM"
                    :data-path="ssMenu.path"
                    :data-title="ssMenu.menuName"
                    :data-name="ssMenu.name"
                  >
                    <template v-for="sssMenu in ssMenu.submenu">
                      <!-- 第四层菜单 -->
                      <a-menu-item
                        :key="sssMenu.key"
                        :data-dhbm="sssMenu.DHBM"
                        :title="sssMenu.menuName"
                        :data-path="sssMenu.path"
                        :data-title="sssMenu.menuName"
                        :data-name="sssMenu.name"
                      >
                        <template
                          v-if="collapsed || sssMenu.menuName.length <= 4"
                        >
                          {{ sssMenu.menuName }}
                        </template>
                        <template v-else>
                          <a-tooltip
                            :key="sssMenu.key + '_popover'"
                            :placement="popoverPlacement"
                            :title="sssMenu.menuName"
                          >
                            {{ sssMenu.menuName }}
                          </a-tooltip>
                        </template>
                        <a-badge
                          v-if="sssMenu.allCount"
                          :count="sssMenu.allCount"
                          :numberStyle="badgeNumberStyle"
                          :title="sssMenu.allCount + ''"
                          :overflowCount="overflowCount"
                          :offset="badgeNumberOffset"
                        />
                      </a-menu-item>
                    </template>
                    <template slot="title">
                      <template v-if="collapsed || ssMenu.menuName.length <= 5">
                        {{ ssMenu.menuName }}
                      </template>
                      <template v-else>
                        <a-tooltip
                          :key="ssMenu.key + '_popover'"
                          :placement="popoverPlacement"
                          :title="ssMenu.menuName"
                        >
                          {{ ssMenu.menuName }}
                        </a-tooltip>
                      </template>
                      <a-badge
                        v-if="ssMenu.allCount"
                        :count="ssMenu.allCount"
                        :numberStyle="badgeNumberStyle"
                        :title="ssMenu.allCount + ''"
                        :overflowCount="overflowCount"
                        :offset="badgeNumberOffset"
                      />
                    </template>
                  </a-sub-menu>
                  <a-menu-item
                    v-else
                    :key="ssMenu.key"
                    :data-dhbm="ssMenu.DHBM"
                    :data-path="ssMenu.path"
                    :data-title="ssMenu.menuName"
                    :data-name="ssMenu.name"
                  >
                    <template v-if="collapsed || ssMenu.menuName.length < 7">
                      {{ ssMenu.menuName }}
                    </template>
                    <template v-else>
                      <a-tooltip
                        :key="ssMenu.key + '_popover'"
                        :placement="popoverPlacement"
                        :title="ssMenu.menuName"
                      >
                        {{ ssMenu.menuName }}
                      </a-tooltip>
                    </template>
                    <a-badge
                      v-if="ssMenu.allCount"
                      :count="ssMenu.allCount"
                      :numberStyle="badgeNumberStyle"
                      :overflowCount="overflowCount"
                      :offset="badgeNumberOffset"
                      :title="ssMenu.allCount + ''"
                    />
                  </a-menu-item>
                </template>
              </a-sub-menu>
              <a-menu-item
                v-else
                :key="sMenu.key"
                :data-dhbm="sMenu.DHBM"
                :data-path="sMenu.path"
                :data-title="sMenu.menuName"
                :data-name="sMenu.name"
              >
                <template v-if="collapsed || sMenu.menuName.length < 7">
                  {{ sMenu.menuName }}
                </template>
                <template v-else>
                  <a-tooltip
                    :key="sMenu.key + '_popover'"
                    :placement="popoverPlacement"
                    :title="sMenu.menuName"
                  >
                    {{ sMenu.menuName }}
                  </a-tooltip>
                </template>
                <a-badge
                  v-if="sMenu.allCount"
                  :count="sMenu.allCount"
                  :numberStyle="badgeNumberStyle"
                  :overflowCount="overflowCount"
                  :offset="badgeNumberOffset"
                  :title="sMenu.allCount + ''"
                />
              </a-menu-item>
            </template>
          </a-sub-menu>

          <a-menu-item
            v-else
            :key="menu.key"
            :data-dhbm="menu.DHBM"
            :data-path="menu.path"
            :data-title="menu.menuName"
            :data-name="menu.name"
          >
            <e-icon
              v-if="menu.iconCls"
              :key="menu.key + '_icon'"
              :type="menu.iconCls"
              class="e-menu-icon"
            />
            <span>{{ menu.menuName }}</span>
          </a-menu-item>
        </template>
      </a-menu>
    </div>
  </div>
</template>

<script>
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
export default {
  mixins: [pageControls],
  name: "IndexLeftSidebar",
  data() {
    return {
      popoverPlacement: "right",
      selectedKey: [],
      openKeys: [],
      badgeNumberStyle: {
        // 数量图标的样式  backgroundColor: "#056cec",
        padding: "0px 3px 0px 2px"
      },
      badgeNumberOffset: [-3, -15],
      overflowCount: 99,
      JLCountsMap: {
        // 记录不同类型的数据的数量
        "274": 0,
        "276": 0,
        "251": 0,
        "255": 0
      }
    };
  },
  props: {
    menuList: {
      // 菜单列表
      type: Array,
      default() {
        return [];
      }
    },
    value: {
      // 监听传递菜单最小化状态
      type: Boolean,
      default: false
    },
    openKey: {
      // 当前展开的菜单
      type: Array,
      default() {
        return [];
      }
    },
    selected: {
      // 当前被打开的tab
      type: String,
      default: null
    }
  },
  computed: {
    collapsedFloat: function() {
      if (this.collapsed) {
        return "unset";
      }
      return "right";
    },
    collapsedICon: function() {
      if (this.collapsed) {
        return this.isNewTheme ? "icon-last" : "menu-unfold";
      }
      return this.isNewTheme ? "icon-first" : "menu-fold";
    },
    menus: function() {
      return this.menuList;
    },
    collapsed: {
      get: function() {
        return this.value;
      },
      set: function(v) {
        this.$emit("input", v);
      }
    },
    getIcon() {
      //let span = "<span class='icon iconfont icon-xia'></span>";
      let span = document.createElement("SPAN");
      span.className = "icon iconfont icon-xia";
      span.innerHTML = "...";
      return span; //this.getDom(span);
    }
  },
  watch: {
    openKey: function(n) {
      if (this.collapsed) {
        this.openKeys = [];
      } else {
        this.openKeys = n;
      }
    },
    selected: {
      immediate: true, // immediate选项可以开启首次赋值监听
      handler: function(n) {
        if (n && n !== "_0") {
          let mObj = this.$util.getObjByParamAndValue(this.menuList, "DHBM", n);
          if (mObj) this.selectedKey = [mObj.key];
        } else {
          this.selectedKey = [];
        }
      }
    }
  },
  created() {
    this.openKeys = this.openKey;
  },
  methods: {
    showBadgeTitle(v) {
      if (this.JLCountsMap[v]) {
        if (this.JLCountsMap[v] > this.overflowCount)
          return this.JLCountsMap[v] + "";
      }
      return "";
    },
    /**
     * 点击菜单最小化和最大化之间的切换
     */
    switchCollapsed() {
      this.collapsed = !this.collapsed;
      if (!this.collapsed) this.openKeys = [];
    },
    /**
     * 点击菜单项
     * @param tager
     */
    onClickMenu(tager) {
      let randomKey = new Date().getTime() + "_" + Math.random();
      let title = tager.item.$el.dataset.title;
      let path = tager.item.$el.dataset.path; //后面追加的是测试的dataId
      let name = tager.item.$el.dataset.name;
      let key = tager.item.$el.dataset.dhbm;
      this.$emit("clickMenu", {
        key: key + "_" + randomKey,
        title: title,
        path: path,
        name: name
      });
    },
    /**
     * 展开菜单事件,只展开一个顶级菜单
     * @param openKeys
     */
    onOpenChange(openKeys) {
      //console.log("打开的菜单",openKeys);
      const latestOpenKey = openKeys.find(
        key => this.openKeys.indexOf(key) === -1
      );
      let mObj = this.$util.getObjByParamAndValue(
        this.menuList,
        "key",
        latestOpenKey
      );
      if (mObj && latestOpenKey.includes(".", 1)) {
        this.openKeys = openKeys;
      } else if (mObj) {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      } else {
        this.openKeys = openKeys;
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "../../assets/style/base/index-left-sidebar.less";
</style>
