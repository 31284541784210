<template>
  <div>
    <a-modal
      title="选择"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @ok="handleSubmit"
      @cancel="handleCancel"
      cancelText="关闭"
      width="auto"
      wrapClassName="modal"
      :bodyStyle="{ 'min-width': '520px' }"
    >
      <template slot="footer">
        <a-button
          key="submit"
          type="primary"
          @click="handleSubmit"
          :size="$config.defaultControlSize"
        >
          确定
        </a-button>
      </template>
      <a-spin :spinning="showTreeSpin" tip="加载中..." class="tree-spin">
        <vxe-grid
          border
          resizable
          :data="data"
          :tree-config="isTree"
          :height="600"
          :size="tableSize_MiXin"
          :scroll-y="{ gt: 0 }"
          :loading="loading"
          show-overflow
          ref="grid"
          column-width="auto"
          column-min-width="auto"
          :auto-resize="true"
          :header-cell-style="{ 'font-size': '14px' }"
          :cell-style="{ 'font-size': '14px' }"
          @filter-change="onFilterChange"
        >
          <vxe-table-column type="radio" width="40"></vxe-table-column>
          <template v-for="(col, index) in columns">
            <vxe-table-column
              :tree-node="!index"
              :key="col.dataIndex"
              :field="col.dataIndex"
              :title="col.title"
              :filters="[{ data: '' }]"
              ><!--:filter-method="filterMethod"-->
              <template v-slot:filter="{ $panel, column }">
                <input
                  type="type"
                  v-for="(option, index) in column.filters"
                  :key="index"
                  v-model="option.data"
                  @input="$panel.changeOption($event, !!option.data, option)"
                />
              </template>
            </vxe-table-column>
          </template>
        </vxe-grid>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法
export default {
  name: "TableButtonControlsTabel",
  mixins: [pageControls, requestControls],
  data() {
    return {
      visible: false,
      confirmLoading: false,
      columns: [],
      data: this.dataSource == null ? [] : this.setDataSource(this.dataSource),
      addNullJson: {},
      editKey: null,
      selectedRowKeys: [],
      selectedRows: [],
      cacheData: [],
      count: -1,
      // multiSelect: "checkbox",
      controlsOption: {
        precision: 2
      },
      titleIndex: "",
      keyIndex: "",
      isTree: false,
      showTreeSpin: false,
      oldData: [],
      loading: false
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    defaultValue: {
      type: [String, null],
      default: null
    },
    value: {
      type: [String, null]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    option: {
      type: [Object, null],
      default: null
    },
    dataSource: {
      type: [Array, null],
      default: null
    },
    tempId: {
      type: [String, Number]
    },
    setting: {
      type: [Object, null],
      default: null
    },
    multiSelect: {
      type: String,
      default: "radio"
    },
    filterSave: {
      type: null
    },
    search: {
      type: String,
      default: ""
    },
    notSave: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.init();
  },
  computed: {
    //  单选多选Checkbox配置
    rowSelectionOption() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        type: this.multiSelect,
        onSelection: this.onSelection
      };
    },
    maxHeight: function() {
      return this.$root.NowWindowsSize.height * 0.7 + "px";
    },
    maxWidth: function() {
      return this.$root.NowWindowsSize.width + "px";
    }
  },
  methods: {
    init() {
      var _this = this;
      _this;
      var url = this.setting.serverName;
      var dataId =
        this.$util.isType(this.value) == "Object"
          ? this.value.value
          : this.value;
      if (this.filterSave) {
        _this.columns = this.filterSave.columns;
        _this.data = this.filterSave.data;
        _this.isTree = this.isTreeCheck(_this.data);
        _this.titleIndex = this.filterSave.titleIndex;
        _this.keyIndex = this.filterSave.keyIndex;
        _this.checkTheValue();
        return;
      }
      let params = {
        controlName: this.setting.controlName,
        action: this.setting.action,
        randomKey: this.$store.getters.getRandomKey,
        dataId: dataId,
        filter: this.search
      };
      this.showTreeSpin = true;
      this.get(url, params, {
        success: function(res) {
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? res.data:res;
          if (res.success) {
            _this.columns = reqData.data.ColsName;
            _this.data = _this.setDataSource(reqData.data.ColsValue);
            _this.isTree = _this.isTreeCheck(_this.data);
            _this.titleIndex = reqData.data.option.titleIndex;
            _this.keyIndex = reqData.data.option.keyIndex;
            _this.showTreeSpin = false;
            _this.$emit("filterSave", {
              columns: reqData.data.ColsName,
              data: _this.setDataSource(reqData.data.ColsValue),
              titleIndex: reqData.data.option.titleIndex,
              keyIndex: reqData.data.option.keyIndex
            });
            if (reqData.result && !_this.$util.objectIsNull(reqData.result)) {
              _this.filterResult(reqData.result);
            } else {
              _this.checkTheValue();
            }
          }
        },
        error: function() {
          _this.showTreeSpin = false;
        }
      });
    },
    /**
     * 响应过滤条件的变化
     * @param event
     */
    onFilterChange(event) {
      console.log("过滤事件", event);
      if (event.datas && event.datas.length > 0) {
        // 如果输入了过滤条件
        let property = event.property;
        let searchKey = event.datas[0];
        //this.oldData = [];
        let filteDatas = [];
        let that = this;
        this.data.forEach(function(da) {
          //that.oldData.push(Object.assign({}, da));
          let fdata = Object.assign({}, da);
          if (da[property].includes(searchKey)) {
            let fchild = [];
            if (da.children) {
              da.children.forEach(function(d1) {
                if (d1[property].includes(searchKey)) {
                  fchild.push(Object.assign({}, d1));
                }
              });
            }
            if (fchild.length > 0) {
              fdata.children = fchild;
            } else {
              delete fdata.children;
            }
            filteDatas.push(fdata);
          } else {
            let fchild1 = [];
            if (da.children) {
              da.children.forEach(function(d2) {
                if (d2[property].includes(searchKey)) {
                  fchild1.push(Object.assign({}, d2));
                }
              });
            }
            if (fchild1.length > 0) {
              fdata.children = fchild1;
              filteDatas.push(fdata);
            }
          }
        });
        this.$refs.grid.reloadData(filteDatas);
        if (filteDatas.length > 0)
          setTimeout(function() {
            that.$refs.grid.setAllTreeExpand(true);
          }, 60);
      } else {
        this.$refs.grid.reloadData(this.data);
      }
    },
    show() {
      this.visible = true;
    },
    handleCancel() {
      this.handleClear();
    },
    handleClear() {
      this.visible = false;
    },
    Empty() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    handleSubmit(e, selectedRows) {
      var check;
      if (!selectedRows) {
        try {
          var check = this.$refs.grid.getRadioRecord();
        } catch(err) {
          err
          return
        }
      } else {
        check = selectedRows;
      }
      var op = {
        type: "table",
        titleIndex: this.titleIndex,
        keyIndex: this.keyIndex
      };
      if (check == null) {
        this.$emit("ok", "", "", op);
      } else {
        this.$emit("ok", [check], null, op);
      }
      this.handleClear();
    },
    filterResult(res) {
      var _this = this;
      var rows = this.data.findIndex(item => {
        return item[_this.keyIndex].trim() === res["key"].trim();
      });
      if (rows != -1) {
        var rowArray = [];
        rowArray.push(this.data[rows]);
        var op = {
          type: "table",
          titleIndex: this.titleIndex,
          keyIndex: this.keyIndex
        };
        this.$emit("ok", rowArray, rows, op);
      }
    },
    handleAdd() {
      if (this.count == -1) {
        this.count = this.data.length;
      }
      var count = this.count + 1;
      var newData = JSON.parse(JSON.stringify(this.addNullJson));
      newData.key = count;
      this.data = [...this.data, newData];
      this.count = this.count + 1;
    },
    handleDel() {
      const key = [...this.selectedRowKeys];
      key.forEach(element => {
        this.onDelete(element);
      });
    },
    onDelete(key) {
      const data = [...this.data];
      this.data = data.filter(item => item.key !== key);
    },
    handleChange(value, key, column, type) {
      if (type == "time") {
        value = value == null ? "" : new moment(value).format("YYYY-MM-DD");
      }
      const newData = [...this.data];
      const target = newData.filter(item => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    //  定义点击表格的事件
    customRow(record) {
      return {
        on: {
          click: () => {
            console.log("click rowclick:");
            console.log(record);
          }
        }
      };
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter(item => key === item.key)[0];
      if (target) {
        target.editable = true;
        this.data = newData;
        //this.editKey = key;
      }
    },
    save(key) {
      const newData = [...this.data];
      const target = newData.filter(item => key === item.key)[0];
      if (target) {
        delete target.editable;
        this.data = newData;
        this.cacheData = newData.map(item => ({ ...item }));
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      console.log("selectedRows changed: ", selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    getData() {
      return this.data;
    },
    setDataSource(data) {
      var i = 0;
      for (i = 0; i <= data.length - 1; i++) {
        data[i]["key"] = (i + 1).toString();
      }
      return data;
    },
    checkTheValue() {
      var _this = this;
      checkValue(this.data);

      function checkValue(data) {
        for (var i = 0; i < data.length; i++) {
          if (
            data[i][_this.keyIndex] &&
            _this.value &&
            ((_this.$util.isType(data[i][_this.keyIndex]) === "String" &&
              _this.$util.isType(_this.value) === "String" &&
              _this.$util.isNumberString(data[i][_this.keyIndex]) &&
              _this.$util.isNumberString(_this.value) &&
              _this.$util.numberStringBoolean(
                data[i][_this.keyIndex],
                _this.value
              )) ||
              (_this.$util.isType(data[i][_this.keyIndex]) === "String" &&
                _this.$util.isType(_this.value) === "String" &&
                !_this.$util.isNumberString(data[i][_this.keyIndex]) &&
                !_this.$util.isNumberString(_this.value) &&
                _this.value.trim() === data[i][_this.keyIndex].trim()) ||
              (_this.$util.isType(data[i][_this.keyIndex]) === "val" &&
                _this.$util.isType(_this.value) === "val" &&
                data[i][_this.keyIndex] == _this.value))
          ) {
            // this.selectedRowKeys = [this.data[i].key];
            // this.selectedRows = [this.data[i]];
            // var row =
            // debugger
            _this.handleSubmit(null, data[i]);
            return;
          }
          if (data[i].children && data[i].children.length != 0) {
            checkValue(data[i].children);
          }
        }
      }
    },
    // 是否是树状
    isTreeCheck(data) {
      var flag = false;
      for (var i = 0; i < data.length; i++) {
        if (data[i].children && data[i].children.length != 0) {
          flag = true;
          break;
        }
      }
      if (flag) {
        return { children: "children" };
      }
      return flag;
    }
  },
  watch: {
    setvalue(value) {
      this.$emit("change", value);
    },
    data() {
      this.$emit("change", this.data);
    },
    disabled() {},
    search() {
      this.init();
    }
  }
};
</script>

<style lang="scss" scoped>
.editable-row-operations a {
  margin-right: 8px;
}
::v-deep .TableButtonControlsTabel td,
.TableButtonControlsTabel th {
  padding: 2px 2px !important;
}
::v-deep .modal {
  min-width: 520px !important;
  // max-width: 80% !important;
}
::v-deep .modal .ant-modal {
  max-width: 80% !important;
}
.tree-spin {
  width: 100%;
  height: 100%;
  // background-color: @tabs-bar-background-index;
}
</style>
