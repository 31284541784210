<!--
 * 登录组件
 * @create wangxin 2018/07/08
-->
<template>
  <div style="width:100%;height:100%;overflow:auto">
    <center1 v-if="layout == 'center1'"></center1>
    <left1 v-if="layout == 'left1'"></left1>
    <right1 v-if="layout == 'right1'"></right1>
    <loginQianSe v-if="layout == 'loginQianSe'"></loginQianSe>
    <loginShenSe v-if="layout == 'loginShenSe'"></loginShenSe>
  </div>
</template>
<script>
import { center1, left1, right1, loginQianSe, loginShenSe } from "./view/selectLayout.js";
import { LOGIN_CONFIG } from "./loginSet.js";

//页面初始化时执行方法
export default {
  mixins: [LOGIN_CONFIG],
  name: "Login",
  data() {
    return {
      layout: window.LOGIN_CONFIG.style,
      config: window.LOGIN_CONFIG
    };
  },
  components: {
    center1,
    left1,
    right1,
    loginQianSe,
    loginShenSe
  },
  created() {},
  mounted: function() {
    this.$notification.destroy();
    this.$destroyAll();
  },
  methods: {}
};
</script>
