<template>
    <a-modal
      title="系统打印"
      :visible="visible"
      cancelText="关闭"
      v-if="visible"
      :maskClosable="false"
      @cancel="
        () => {
          visible = false;
          editors = null;
        }
      "
      :destroyOnClose="true"
      :width="1330"
      :mask="false"
    >
      <template slot="footer">
        <a-button type="primary" @click="print">
          <a-icon type="check" ></a-icon>打印预览
        </a-button>
      </template>
      <template>
        <div>
          <textarea :id="id" style="min-height:600px;width: 1280px;"></textarea>
        </div>
      </template>
      <div
        :style="{
          'overflow-x': 'scroll',
          width: getWidth,
          maxwidth: '1800px',
          'font-size': fontSize
        }"
        v-show="false"
      >
        <div style="width:100%;">
          <div :style="{ width: '100%' }">
            <div class="relative">
              <div id="systemPrintTitle" v-if="header != ''">
                <h1 :style="{ 'text-align': 'center' }">{{ header }}</h1>
              </div>
              <div
                id="systemPrintMainForm"
                style="width:100%"
                v-if="printConfig && printConfig.formRows"
              >
                <table style="width: 100%; margin-bottom: 20px;" border="0">
                  <template v-for="(row, index) in printConfig.formRows">
                    <tr :key="`row${index}`">
                      <template v-for="(col, colIndex) in row">
                        <template
                          v-if="col && col.controlType &&
                            col.controlType != 'Text' &&
                              col.controlType != 'EUpload'
                          "
                        >
                          <td
                            :key="`row${index}col${colIndex}label`"
                            :style="{
                              'font-size': fontSize,
                              'text-align': 'right'
                            }"
                            class="mceNonEditable"
                          >
                            {{ col.label }}:
                          </td>
                          <td
                            :key="`row${index}col${colIndex}value`"
                            :style="{ 'font-size': fontSize }"
                            :colspan="
                              colIndex == row.length - 1
                                ? (maxCol - (colIndex + 1)) * 2 + 1
                                : ''
                            "
                            class="mceNonEditable"
                          >
                            <span
                              style="text-decoration: underline;"
                              v-if="col && col.controlType && col.controlType != 'EDatetimePicker'"
                              >{{ col.value }}</span
                            >
                            <span v-else-if="col">{{ timeFormat(col.value) }}</span>
                          </td>
                        </template>
                        <template v-else-if="col && col.controlType != 'EUpload'">
                          <td
                            :key="`row${index}col${colIndex}text`"
                            :style="{
                              'font-size': fontSize
                            }"
                            :colspan="
                              colIndex == row.length - 1
                                ? (maxCol - (colIndex + 1)) * 2 + 2
                                : ''
                            "
                            class="mceNonEditable"
                          >
                            {{ col.label }}
                          </td>
                        </template>
                      </template>
                    </tr>
                  </template>
                </table>
              </div>
              <div
                v-if="printConfig && (printConfig.mxForm || printConfig.flow)"
                id="systemPrintMxForm"
                style="width:100%"
              >
                <a-table
                  v-if="printConfig.mxForm && printConfig.mxForm.columns"
                  :bordered="true"
                  :columns="printConfig.mxForm.columns"
                  :dataSource="
                    printConfig.mxForm.data.length == 0
                      ? returnNullMxData
                      : printConfig.mxForm.data
                  "
                  :pagination="false"
                  :style="{ 'font-size': fontSize, width: '100%' }"
                >
                  <template
                    v-for="(col, index) in printConfig.mxForm.columns"
                    :slot="col.dataIndex"
                    slot-scope="text"
                  >
                    <span
                      :style="{ 'font-size': fontSize }"
                      :key="col.dataIndex + index"
                      v-if="col &&
                        col.controlType &&
                          col.controlType == 'CxsGridDateTimeColumn'
                      "
                      >{{ timeFormat(text) }}</span
                    >
                    <span
                      v-else-if="col"
                      :key="col.dataIndex + index"
                      :style="{ 'font-size': fontSize }"
                      >{{ checkTime(text, col) }}</span
                    >
                  </template>
                </a-table>
                <table v-if="printConfig.flow" style="width: 100%;margin-top:20px" border="0">
                  <template v-for="(row, index) in printConfig.flow">
                    <tr :key="`row${index}`">
                      <template v-for="(col, colIndex) in row">
                        <template
                          v-if="col && col.controlType &&
                            col.controlType != 'Text' &&
                              col.controlType != 'EUpload'
                          "
                        >
                          <td
                            :key="`row${index}col${colIndex}label`"
                            :style="{
                              'font-size': fontSize,
                              'text-align': 'right'
                            }"
                            class="mceNonEditable"
                          >
                            {{ col.label }}:
                          </td>
                          <td
                            :key="`row${index}col${colIndex}value`"
                            :style="{ 'font-size': fontSize }"
                            :colspan="
                              colIndex == row.length - 1
                                ? (maxCol - (colIndex + 1)) * 2 + 1
                                : ''
                            "
                            class="mceNonEditable"
                          >
                            <span
                              style="text-decoration: underline;"
                              v-if="col && col.controlType && col.controlType != 'EDatetimePicker'"
                              v-html="col.value"
                              >{{ col.value }}</span
                            >
                            <span v-else>{{ timeFormat(col.value) }}</span>
                          </td>
                        </template>
                        <template v-else-if="col && col.controlType != 'EUpload'">
                          <td
                            :key="`row${index}col${colIndex}text`"
                            :style="{
                              'font-size': fontSize
                            }"
                            :colspan="
                              colIndex == row.length - 1
                                ? (maxCol - (colIndex + 1)) * 2 + 2
                                : ''
                            "
                            class="mceNonEditable"
                          >
                            {{ col.label }}
                          </td>
                        </template>
                      </template>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
</template>
<script>
import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法
import { tableThResizeable } from "@/controls/Table/TabelThResizeable.js"; // 表格头可变列宽控件
import moment from "moment";

import tinymce from "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/print";
import "tinymce/plugins/table";
import "tinymce/plugins/code";
import "tinymce/plugins/noneditable";

export default {
  name: "systemPrint",
  mixins: [requestControls, tableThResizeable],
  data() {
    return {
      visible: false,
      printConfig: null, // 打印设置
      slectLine: [],
      printAllMxFromFlag: true,
      header: null,
      fontSize: "16px",
      hideField: null,
      id: "tiny",
      editors: null,
      maxCol: 0,

      flags: false,
      loading: false,
      position: { x: 0, y: 0 },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
      none: "unset"
    };
  },
  props: {},
  watch: {
    visible: function(v) {
      if(v) {
        let that = this;
        this.$nextTick(() => {
          setTimeout(function() {
            that.tinymceInit();
          }, 200);
        });
      }
    }
  },
  created() {},
  mounted() {},
  computed: {
    // 根据最后一行的高度，计算出流体的盒子高度
    getHeight: function() {
      // var index = this.printConfig.formRows.length - 1;
      var max = 0;
      for (var i = 0; i < this.printConfig.formRows.length; i++) {
        for (var j = 0; j < this.printConfig.formRows[i].length; j++) {
          if (this.printConfig.formRows[i][j].position.y > max) {
            max = this.printConfig.formRows[i][j].position.y;
          }
        }
      }
      var height = max + 50;

      return height + "px";
    },
    // 找出最大宽度，计算出流体的盒子宽度
    getWidth: function() {
      var maxWidth = 0;
      if (this.printConfig.formRows == "") {
        return "auto";
      }
      this.printConfig.formRows.forEach(element => {
        for (var i = 0; i < element.length; i++) {
          if (element[i].position.x > maxWidth) {
            maxWidth = element[i].position.x;
          }
        }
      });
      if (this.printConfig.mxForm) {
        return maxWidth + 400 + this.printConfig.mxForm.columns.length * 20;
      } else {
        return maxWidth + 400;
      }
    },
    // 当明细数据为0时调用，返回一条空数据时打印样式自然
    returnNullMxData: function() {
      var json = { key: 0 };
      this.printConfig.mxForm.columns.forEach(element => {
        json[element.dataIndex] = "";
      });
      var data = [json];
      return data;
    }
  },
  methods: {
    print() {
      this.editors.execCommand("print");
    },
    show(printConfig, header, fontSize) {
      this.loading = true;
        // 抬头标题
      this.header = header;
      // 字体的大小
      this.fontSize = fontSize;
      // 打印设置
      this.printConfig =this.$util.deepCopy(printConfig);
      // 隐藏字段
      this.hideField = printConfig.hideField;
      // 生成随机id
      this.id = "tiny" + Date.parse(new Date()).toString();
      // 获得主表最大列数，用来定义colspan
      this.maxCol = this.getMaxColNum();
      this.checkTheValue();
      console.log("printConfig", printConfig);
      // 设置可见 开始渲染
      this.visible = true;
    },
    tinymceInit() {
      var title = "",mainTableDom = "",mxTableDom = "",dom;
      try {
        title = document.getElementById("systemPrintTitle").innerHTML;
      } catch (err) {
        err;
      }
      try {
        mainTableDom = document.getElementById("systemPrintMainForm").innerHTML;
      } catch (err) {
        err;
      }
      dom = document.getElementById("systemPrintMxForm");
      if(dom) {
        try {
          var th = dom.getElementsByTagName("th");
          var td = dom.getElementsByTagName("td");
          for (var i = 0; i < th.length; i++) {
            th[i].setAttribute("class", "mceNonEditable");
          }
          for (var j = 0; j < td.length; j++) {
            td[j].setAttribute("class", "mceNonEditable");
          }
          mxTableDom = document.getElementById("systemPrintMxForm").innerHTML;

        } catch (err) {
          console.warn("明细表打印初始化失败", err);
        }
      }
      try {
        tinymce
          .init({
            selector: "#" + this.id,
            // plugins: ["paste", "link", "print", "table", "code", "noneditable"],
            plugins: ["print", "table", "noneditable"],
            language: "zh_CN",
            base_url: "/js/tinymce",
            branding: false, // 隐藏右下角技术支持
            elementpath: false, // 隐藏底栏的元素路径
            // menubar: "edit insert format table"
            menubar: "",
            // toolbar:
            //   "undo redo forecolor bold italic | alignleft aligncenter alignright | fontsizeselect",
            toolbar: "",
            // table_toolbar: "tabledelete | tabledeleterow | tabledeletecol", // 关闭table的快速栏
            table_toolbar: "",
            visual: false,
            autoresize_on_init: true,
            // table_toolbar: "",
            contextmenu: "" // 关闭右键菜单栏
            // table_appearance_options: false,
            // table_grid: false
            // readonly: 1
          })
          .then(editors => {
            editors[0].setContent(title + mainTableDom + mxTableDom);
            this.editors = editors[0];
            this.loading = false;
            // editors[0].execCommand("print");
          });
      } catch (err) {
        console.error("初始化页面编辑器失败", err);
      }
    },
    getMaxColNum() {
      var max = 0;
      var data = this.printConfig.formRows;
      for (var i = 0; i < data.length; i++) {
        max < data[i].length ? (max = data[i].length) : "";
      }
      return max;
    },
    handleCancel() {
      this.visible = false;
    },
    checkTheValue() {
      if (this.printConfig.mxForm) {
        this.printConfig.mxForm.columns.forEach((element, index) => {
          if (!this.printConfig.mxForm.columns[index]["dataIndex"]) {
            this.printConfig.mxForm.columns[index]["dataIndex"] =
              element["fieldName"];
          }
          if (!this.printConfig.mxForm.columns[index]["title"]) {
            this.printConfig.mxForm.columns[index]["title"] =
              element["headerText"];
          }
        });
      }
    },
    // 校验主表是否可见
    fieldNameCheck(value) {
      var flag = true;
      if (!this.hideField) {
        return flag;
      }
      if (!this.hideField.mainTableField) {
        return flag;
      }
      this.hideField.mainTableField.forEach(element => {
        if (element === value) {
          flag = false;
        }
      });
      return flag;
    },
    mceNonEditable() {
      return "mceNonEditable";
    },
    checkTime(text, col) {
      if (
        this.$util.isType(text) === "String" &&
        !this.$util.isNumberString(text)
      ) {
        let reg = moment(text, "YYYY-MM-DDTHH:mm:ss").isValid();
        try {
          if (reg) {
            if (col.title.indexOf("日期") > 0) return text.substring(0, 10);
            else return this.$util.changeTimeFormat(text);
          }
        } catch (err) {
          return text;
        }
      }
      return text;
    },
    timeFormat(value) {
      if (!value || value == "") {
        return "";
      }
      return new moment(value).format("YYYY-M-D");
    },
    down() {
      this.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = moveDiv.offsetLeft;
      this.dy = moveDiv.offsetTop;
    },
    move() {
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        moveDiv.style.left = this.xPum + "px";
        moveDiv.style.top = this.yPum + "px";
        moveDiv.style.right = this.none;
        moveDiv.style.bottom = this.none;
        //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
        document.addEventListener(
          "touchmove",
          function() {
            event.preventDefault();
          },
          false
        );
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
    }
  }
};
</script>
<style scoped lang="less">
.relative {
  position: relative;
  height: 100%;
  width: 100%;
}
.form-col {
  position: absolute;
}
::v-deep .ant-table > .ant-table-content > .ant-table-body {
  overflow-x: unset;
}
::v-deep .ant-tag {
  font-size: unset;
  border: 0px;
  background: unset;
  margin-right: 20px;
}
::v-deep .ant-tag:hover {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: #fafafa;
  width: 100%;
  height: 100%;
}
::v-deep .ant-tag .anticon-close {
  display: none;
}
::v-deep .ant-tag:hover .anticon-close {
  display: unset;
  width: 100%;
  height: 100%;
}
table {
  width: 100%;
}
</style>
