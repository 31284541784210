<!-- 图片查看器 -->
<template>
  <a-modal
    v-model="visible"
    :title="title"
    :bodyStyle="{ width: 'auto', maxHeight: maxHeight, maxWidth: allWidth }"
    :width="allWidth"
    :footer="null"
    :centered="true"
  >
    <div>
      <img
        v-show="imageLoaded"
        :src="imageSrc"
        @load="getImageOver()"
        :style="{ maxWidth: imageWidth }"
      />
      <a-spin
        tip="图片加载中..."
        v-show="!imageLoaded"
        style="width: 500px;height: 80px;"
      ></a-spin>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "ViewImageModal",
  data() {
    return {
      tempSrc: "",
      imageWidth: "50px",
      maxHeight: "1000px",
      imageLoaded: false
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    src: {
      type: String,
      default: ""
    }
  },
  computed: {
    visible: {
      get: function() {
        //console.log("弹窗显示",this.value );
        return this.value;
      },
      set: function(v) {
        this.$emit("input", v);
      }
    },
    imageSrc: function() {
      return this.tempSrc;
    },
    allWidth: function() {
      if (this.imageWidth) {
        return this.imageWidth + 48;
      } else {
        return 520;
      }
    }
  },
  watch: {
    src: function(v) {
      if (v) this.tempSrc = v;
      else this.tempSrc = "";
    }
  },
  created() {
    this.tempSrc = this.src;
    try {
      let windowSize = this.$util.findDimensions();
      if (windowSize) {
        let mHeight = windowSize.height - 55;
        let mWidth = windowSize.width;
        this.maxHeight = mHeight + "px"; // 减去底部按钮区域高度，获得内容区域最大高度
        this.imageWidth = mWidth + "px";
      }
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    getImageOver: function() {
      let that = this;
      setTimeout(function() {
        that.imageLoaded = true;
      }, 500);
      //this.imageLoaded = true;
    }
  }
};
</script>
