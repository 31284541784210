<!-- 上下两个表格并排布局的列表视图 -->
<template>
  <div style="height: 100%;overflow-y: auto;">
    <div class="form-headButton">
      <!-- 授权操作按钮 -->
      <e-toolbar
        :buttons="buttons"
        :operationDisabled="operationDisabled"
        :size="$config.defaultControlSize"
        @click="onButtonClick"
      ></e-toolbar>
    </div>
    <div class="tab-view-context" style="margin-bottom: 10px;">
      <!-- 视图表格 -->
      <div class="view-table" >
        <!-- 明细表title -->
        <e-head-title :title="title" :size="$config.defaultControlSize"></e-head-title>
        <!-- 上表 -->
        <div class="view-context">
          <a-row>
            <div class="view-context_headButtons">
              <a-popconfirm
                placement="bottom"
                :visible="delButtonConfirmVisible1"
                @cancel="delButtonConfirmVisible1 = false"
                @confirm="handleDel(1)"
                @visibleChange="delButtonConfirmVisibleChange(1)"
              >
                <e-icon
                  slot="icon"
                  type="question-circle-o"
                  style="color: red;"
                />
                <template slot="title">
                  <p>是否确定删除</p>
                </template>
                <a-button class="editable-del-btn" icon="delete">删除</a-button>
              </a-popconfirm>
            </div>
            <div class="view-context_screen">
              <e-table-content-filter
                ref="screenViewTable"
                @screen="
                  data => {
                    tableDatas = data;
                  }
                "
                :columns="thisColumns"
                :dataSource="tableDatas"
                :disabled="tableSpin"
              ></e-table-content-filter>
            </div>
          </a-row>
          <a-table
            bordered
            ref="tableRef"
            :class="$config.table_stripe ? 'ant-table-stripe':''"
            :rowKey="rowKey"
            :dataSource="tableDatas"
            :loading="tableSpin"
            :size="aTableSize_MiXin"
            :scroll="{ x: tableMaxWidth, y: 200 }"
            :columns="thisColumns"
            :components="thComponents"
            :rowSelection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange
            }"
          >
            <template
              v-for="col in thisColumns"
              :slot="col.dataIndex"
              slot-scope="text, record"
              :rowKey="rowKey"
            >
              <template v-if="col.dataIndex != 'operation'">
                <a-tooltip
                  :key="col.dataIndex + '_popover'"
                  :placement="popoverPlacement"
                >
                  <template slot="title">{{
                    columsCustomRender(col.title, text)
                  }}</template>
                  <span :key="col.dataIndex" class="view-table-colums__title">{{
                    columsCustomRender(col.title, text)
                  }}</span>
                </a-tooltip>
              </template>
              <template v-else-if="col.dataIndex == 'operation'">
                <span :key="col.dataIndex">
                  <a @click="() => show(record)">查看</a>
                </span>
              </template>
            </template>
          </a-table>
        </div>
        <!-- 下表 -->
        <div class="view-context view-context_borderTop">
          <a-row>
            <div class="view-context_headButtons">
              <a-popconfirm
                placement="bottom"
                :visible="delButtonConfirmVisible2"
                @cancel="delButtonConfirmVisible2 = false"
                @confirm="handleDel(2)"
                @visibleChange="delButtonConfirmVisibleChange(2)"
              >
                <e-icon
                  slot="icon"
                  type="question-circle-o"
                  style="color: red;"
                />
                <template slot="title">
                  <p>是否确定删除</p>
                </template>
                <a-button class="editable-del-btn" icon="delete">删除</a-button>
              </a-popconfirm>
            </div>
            <div class="view-context_screen">
              <e-table-content-filter
                ref="screenViewTable2"
                @screen="
                  data => {
                    tableDatas2 = data;
                  }
                "
                :columns="thisColumns2"
                :dataSource="tableDatas2"
                :disabled="tableSpin2"
              ></e-table-content-filter>
            </div>
          </a-row>
          <a-table
            bordered
            :class="$config.table_stripe ? 'ant-table-stripe':''"
            ref="tableRef2"
            :rowKey="rowKey"
            :size="aTableSize_MiXin"
            :dataSource="tableDatas2"
            :loading="tableSpin2"
            :columns="thisColumns2"
            :components="thComponents2"
            :scroll="{ x: tableMaxWidth2, y: 200 }"
            :rowSelection="{
              selectedRowKeys: selectedRowKeys2,
              onChange: onSelectChange2
            }"
          >
            <template
              v-for="col in thisColumns2"
              :slot="col.dataIndex"
              slot-scope="text, record"
              :rowKey="rowKey"
            >
              <template v-if="col.dataIndex != 'operation'">
                <a-tooltip
                  :key="col.dataIndex + '_popover'"
                  :placement="popoverPlacement"
                >
                  <template slot="title">{{
                    columsCustomRender(col.title, text)
                  }}</template>
                  <span :key="col.dataIndex" class="view-table-colums__title">{{
                    columsCustomRender(col.title, text)
                  }}</span>
                </a-tooltip>
              </template>
              <template v-else-if="col.dataIndex == 'operation'">
                <span :key="col.dataIndex">
                  <a @click="() => show(record)">查看</a>
                </span>
              </template>
            </template>
          </a-table>
        </div>
      </div>
    </div>

    <!-- 明细数据选人弹出框 -->
    <e-table-modal
      v-if="gridImportTableConfig && gridImportTableConfig.columns"
      v-model="gridImportModalShow"
      :tableConfig="gridImportTableConfig"
      :title="gridImportTitle"
      width="950"
      :tableDatas="gridImportTableDatas"
      :buttons="gridImportModalButtons"
      :loading="gridImportModalLading"
      :selectedKeys="gridImportTableSelectedKeys"
      @buttonClicked="onClickTableModal"
    ></e-table-modal>

    <!-- 明细数据选人弹出框"筛选"条件选择窗 -->
    <e-form-modal
      v-model="gridImportSelectModalShow"
      :tempId="gridImportSelectTempId"
      :showOk="true"
      @okClicked="onGridImportSelected"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">筛选条件</span>
        </div>
      </template>
    </e-form-modal>

    <systemPrintSet ref="systemPrintSet"></systemPrintSet>
  </div>
</template>

<script>
import { tableThResizeable } from "@/controls/Table/TabelThResizeable.js"; // 表格头可变列宽控件
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求
import { e1FormToolbarGridImport } from "@/views/form/mixins/e1FormToolbarGridImport.js"; // e1的明细选人操作方法

export default {
  mixins: [
    analyticalMenuAuth,
    pageControls,
    requestControls,
    e1FormToolbarGridImport
  ],
  name: "UpAndDownListView",
  data() {
    return {
      cacheData: null,
      tableDatas: [],
      thisColumns: [],
      thComponents : null,
      selectedRowKeys: [], // 表格选中的行
      tableSpin: false,
      delButtonConfirmVisible1: false,

      cacheData2: null,
      tableDatas2: [],
      thisColumns2: [],
      thComponents2 : null,
      selectedRowKeys2: [], // 表格选中的行
      tableSpin2: false,
      delButtonConfirmVisible2: false,

      thisConfig: null,
      buttons: [], // 用户可做的操作按钮
      tableConfig: {},

      tempId: null,
      rowKey: this.defaultTableKeyName,
      dhbm: null,
      formTitle: null,
      operationDisabled: false, // 操作按钮是否可用
      popoverPlacement: "right" // 表单内容提示框
    };
  },
  props: {
    pageConfig: {
      //包含视图结构、视图数据来源、操作权限配置
      type: Object,
      default: function() {
        return null;
      }
    },
    title: {
      type: String,
      default: ""
    }
  },
  computed: {
    showToolbar: function() {
      return this.checkToobleIsShow(this.buttons);
    },
    tableMaxWidth: function() {
      if (this.thisColumns.length > 0) {
        let tempWidth = 0;
        for (let i = 0; i < this.thisColumns.length; i++) {
          tempWidth += this.thisColumns[i].width;
        }
        return tempWidth;
      }
      return 0;
    },
    tableMaxWidth2: function() {
      if (this.thisColumns2.length > 0) {
        let tempWidth = 0;
        for (let i = 0; i < this.thisColumns2.length; i++) {
          tempWidth += this.thisColumns2[i].width;
        }
        return tempWidth;
      }
      return 0;
    },
  },
  watch: {
    pageConfig: function(nDatas) {
      this.thisConfig = nDatas;
    },
    tableSpin: function(v) {
      if(!v) { // 数据加载完成后，将滚动条拉到顶部
        let tableBody = this.$refs.tableRef.$el.querySelector('.ant-table-body');
        tableBody.scrollTop = 0;
      }
    },
    tableSpin2: function(v) {
      if(!v) { // 数据加载完成后，将滚动条拉到顶部
        let tableBody = this.$refs.tableRef2.$el.querySelector('.ant-table-body');
        tableBody.scrollTop = 0;
      }
    }
  },
  created() {
    this.thisConfig = this.pageConfig;
    console.log("pageConfig:", this.thisConfig);
    //分析出用户可做操作按钮
    this.buttons = this.getUserJurisdictionButtons(this.thisConfig);
    if (this.thisConfig && this.thisConfig.viewConfig) {
      // 表格配置
      this.tableConfig = this.thisConfig.viewConfig;
      if (this.tableConfig.topgridcolsset) {
        this.thisColumns = this.analyticalATableColsArray(
          this.tableConfig.topgridcolsset
        ); // 上表头配置
        this.thComponents = {
          header: {
            cell: tableThResizeable.getResizeableTitleFunction(this.thisColumns)
          }
        };
        this.thisColumns2 = this.analyticalATableColsArray(
          this.tableConfig.bottomgridcolsset
        ); // 下表头配置
        this.thComponents2 = {
          header: {
            cell: tableThResizeable.getResizeableTitleFunction(this.thisColumns2)
          }
        };
      }

      if (this.tableConfig.defaultDataId)
        // 如果视图指定了默认的唯一主键字段
        this.rowKey = this.tableConfig.defaultDataId;
      this.tempId = this.tableConfig.templateid;
      this.formTitle = this.tableConfig.title;

      this.dhbm = this.thisConfig.DHBM;
    } else {
      this.alertError("表格配置错误");
    }
    console.log("对应的表头", this.thisColumns);
  },
  methods: {
    /**
     * 根据表名设置对应表的数据
     * @param data
     */
    setUpOrDowmTableDataByName(data) {
      if (data && this.$util.isType(data) === "Array" && data.length > 0) {
        let that = this;
        let tableSelect = data[0];
        // 第一张表选人明细
        if (
          this.tableConfig.topconfigs &&
          this.tableConfig.topconfigs.newTable === tableSelect.tableName
        ) {
          this.tableSpin = true;
          this.tableDatas = tableSelect.datas;
          this.$nextTick(function() {
            that.tableSpin = false;
          });
        } else if (
          this.tableConfig.bottomconfigs &&
          this.tableConfig.bottomconfigs.newTable === tableSelect.tableName
        ) {
          // 第二张表选人明细
          this.tableSpin2 = true;
          this.tableDatas2 = tableSelect.datas;
          this.$nextTick(function() {
            that.tableSpin2 = false;
          });
        }
      }
    },
    /**
     * 对应操作按钮操作结束，需要改变按钮状态，和工具条的全局状态
     * @param button
     */
    finishedProcessing(button) {
      if (button) button.loading = false;
      this.operationDisabled = false;
    },
    /**
     * 对应操作按钮操作开始，需要改变按钮状态，和工具条的全局状态
     * @param button
     */
    startProcessing(button) {
      if (button) button.loading = true;
      this.operationDisabled = true;
    },
    /**
     * 点击“清账”按钮
     * @param button
     */
    tsbQingZhang(button) {
      if (this.tableDatas.length > 0 && this.tableDatas2.length > 0) {
        let that = this;
        let params = {
          action: "ClearingAccounts",
          topViewName: this.tableConfig.topviewname, //上表名
          bottomViewName: this.tableConfig.bottomviewname, // 下表名
          topData: JSON.stringify(this.tableDatas), // 上表数据
          bottomData: JSON.stringify(this.tableDatas2), // 下表数据
          qzlx: this.tableConfig.qzlx,
          datamap: this.tableConfig.datamap,
          randomKey: this.$store.getters.getRandomKey
        };
        this.startProcessing(button);
        this.post("Form.ashx", params, {
          success: function(req) {
            //console.log(req);
            let data = req.data;
            console.log("获取到清账数据:", data);
            if (data) {
              that.$store.commit("setCacheData", data);
              that.openNew();
            }
            that.tableDatas = [];
            that.tableDatas2 = [];
            that.finishedProcessing(button);
          },
          error: function(err) {
            console.log(err);
            that.finishedProcessing(button);
            that.alertError("请求失败，获取表数据失败");
          }
        });
      } else {
        this.tipWarning("请两张表都选人明细数据");
      }
    },
    /**
     * 删除指定表单的选中行
     * @param index
     */
    handleDel(index) {
      let that = this;
      if (index == 1) {
        if (this.selectedRowKeys.length > 0) {
          this.tableSpin = true;
          const data = [...this.tableDatas];
          this.tableDatas = data.filter(
            item => that.selectedRowKeys.indexOf(item[that.rowKey]) == -1
          );
          this.selectedRowKeys.length = 0;
          this.$nextTick(function() {
            that.delButtonConfirmVisible1 = false;
            that.tableSpin = false;
          });
          return;
        }
      } else if (index == 2) {
        if (this.selectedRowKeys2.length > 0) {
          this.tableSpin2 = true;
          const data = [...this.tableDatas2];
          this.tableDatas2 = data.filter(
            item => that.selectedRowKeys2.indexOf(item[that.rowKey]) == -1
          );
          this.selectedRowKeys2.length = 0;
          this.$nextTick(function() {
            that.delButtonConfirmVisible2 = false;
            that.tableSpin2 = false;
          });
          return;
        }
      }
      this.alertError("请选择需要删除的行");
    },
    /**
     * 删除确认框显示与否
     * @param index
     */
    delButtonConfirmVisibleChange(index) {
      if (index == 1) {
        const key = [...this.selectedRowKeys];
        if (key.length == 0) {
          this.delButtonConfirmVisible1 = false;
          this.tipWarning("请选择需要删除的数据");
        } else {
          this.delButtonConfirmVisible1 = true;
        }
      } else if (index == 2) {
        const key2 = [...this.selectedRowKeys2];
        if (key2.length == 0) {
          this.delButtonConfirmVisible2 = false;
          this.tipWarning("请选择需要删除的数据");
        } else {
          this.delButtonConfirmVisible2 = true;
        }
      }
    },
    /**
     *  打开新建表单数据页面
     */
    openNew() {
      if (this.tempId) {
        //this.alert("已选择模板ID:"+this.tempId);
        let query = {
          dhbm: this.dhbm,
          templetId: this.tempId,
          type: "dataFromCache"
        };
        this.openTab({
          title: "新增-" + this.formTitle,
          path: "/NewForm",
          query: query
        });
      } else {
        this.alertError("不存在的模板ID");
      }
    },
    /**
     * 刷新列表
     * @param button
     */
    tsbRefresh(button) {
      this.getTableData(button);
    },
    /**
     * 响应行的选中情况变化
     * @param selectedRowKeys
     */
    onSelectChange(selectedRowKeys) {
      console.log("1选中的行对象: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    /**
     * 响应行的选中情况变化
     * @param selectedRowKeys
     */
    onSelectChange2(selectedRowKeys) {
      console.log("2选中的行对象: ", selectedRowKeys);
      this.selectedRowKeys2 = selectedRowKeys;
    },
    // tsbExport() {
    //   this.tableDatas &&
    //     this.thisColumns &&
    //     this.$util.excelExport(this.tableDatas, this.thisColumns, this.title);
    // },
    tsbPrint() {
      var json = {
        formConfig: {
          position: { x: 0, y: 0 },
          size: { w: 0, h: 0 },
          title: this.title
        },
        formRows: [],
        title: this.title,
        mxForm: {
          columns: this.thisColumns,
          data: this.tableDatas,
          position: { x: 0, y: 0 },
          size: { w: 0, h: 0 },
          type: "mxForm"
        }
      };
      this.$refs.systemPrintSet.show(json);
    }
  }
};
</script>

<style lang="less" scoped>
  @import "../../assets/style/base/up-and-down-list-view.less";
</style>
