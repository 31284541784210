// 请求方法重新封装，根据sys-config内配置的SERVER_NAMES项目才能调取下列方法
import Axios from "axios";
import qs from "qs";
import { JSEncrypt } from "jsencrypt";

export const requestControls = {
  data() {
    return {
      showLog: false,
      defaultTimeOut: 180000
    };
  },
  created() {
    // console.log("混入requestControls的通用方法");
  },
  methods: {
    requestConoleLog(...vars) {
      if (this.showLog) {
        let len = vars.length;
        for (let i = 0; i < len; i++) {
          if (
            this.$util.isType(vars[i]) === "String" ||
            this.$util.isType(vars[i]) === "Boolean" ||
            this.$util.isType(vars[i]) === "val"
          )
            console.log(vars[i] + "\n");
          else console.log(JSON.stringify(vars[i]) + "\n");
        }
      }
    },
    /**
     * 数据加密方法
     * @param param
     * @returns {*|PromiseLike<ArrayBuffer>}
     */
    paramEncrypt(param) {
      if (this.C_RSAPublicKey && this.C_RSAPublicKey.length > 0) {
        let enCryptor = new JSEncrypt(); // 新建JSEncrypt对象
        enCryptor.setPublicKey(this.C_RSAPublicKey); // 设置公钥
        let encrypted = enCryptor.encrypt(param);
        if (encrypted) return encrypted;
        // 对密码进行加密
        else console.error("数据加密错误");
      }
      return param;
    },
    /**
     * 从请求参数中读取，表单默认字段值配置
     * @param defaultFiled
     * @returns {null}
     */
    getDefaultFiledForQuery(defaultFiled) {
      if (defaultFiled) {
        // 如果参数中携带了默认字段值，则给主表赋予对应字段对应默认值
        let obj = {};
        let defaultFiledLabel = this.$route.query[defaultFiled + "_Name"];
        let defaultFiledValue = this.$route.query[defaultFiled];
        if (defaultFiledLabel)
          defaultFiledValue = {
            label: defaultFiledLabel,
            value: defaultFiledValue
          };
        obj[defaultFiled] = defaultFiledValue;
        return obj;
      }
      return null;
    },
    /**
     * 根据 otherConfig.type确定是使用post还是get请求方式
     * @param serverName
     * @param params
     * @param otherConfig
     * @param resolve
     */
    request(serverName, params, otherConfig, resolve) {
      if (
        otherConfig.type &&
        (otherConfig.type === "POST" || otherConfig.type === "post")
      ) {
        this.post(serverName, params, otherConfig, resolve);
      } else {
        this.get(serverName, params, otherConfig, resolve);
      }
    },

    /**
     * get请求
     * @param serverName //对应sys-config内配置的SERVER_NAMES项目
     * @param params
     * @param otherConfig
     * @param resolve 用于支持Promise对象的异步数据确认
     */
    get(serverName, params, otherConfig, resolve) {
      if (this.ServerNames.indexOf(serverName) == -1) {
        if (
          otherConfig.error &&
          that.$util.isType(otherConfig.error) === "Function"
        )
          otherConfig.error({
            message: "错误的请求服务名称"
          });
        else {
          this.$error({
            title: "提示",
            content: "错误的请求服务名称"
          });
        }
        return;
      }
      let that = this;
      params = params ? params : {};
      otherConfig = otherConfig ? otherConfig : {};
      let header = otherConfig.headers
        ? otherConfig.headers
        : {
            "Content-Typ": "application/json; charset=utf-8"
          };
      // 请求超时时间
      let timeout = otherConfig.timeout || this.defaultTimeOut;
      let url =
        this.BaseUrl +
        this.ServerPath +
        serverName +
        "?" +
        this.$util.parseParam(params);
      Axios.get(url, {
        headers: header,
        timeout: timeout
      })
        .then(resp => {
          that.requestConoleLog(serverName+"?action="+params.action+"请求结果\n", resp);
          if (resolve) resolve();
          let result = that.responseConfirm(resp, otherConfig);
          if (result == 1) {
            if (
              otherConfig.success &&
              that.$util.isType(otherConfig.success) === "Function"
            )
              otherConfig.success(resp.data);
          } else if (result == -1) {
            if (
              otherConfig.error &&
              that.$util.isType(otherConfig.error) === "Function"
            )
              if (resp.data) otherConfig.error(resp.data);
              else otherConfig.error({ message: "请求失败，服务异常" });
          }
        })
        .catch(function(error) {
          if (resolve) resolve();
          that.requestConoleLog("请求失败：", error);
          if (
            otherConfig.error &&
            that.$util.isType(otherConfig.error) === "Function"
          )
            otherConfig.error(error);
          else that.$message.error("请求失败，" + JSON.stringify(error));
        });
    },
    /**
     * post请求
     * @param serverName
     * @param params
     * @param otherConfig
     * @param resolve 用于支持Promise对象的异步数据确认
     */
    post(serverName, params, otherConfig, resolve) {
      if (this.ServerNames.indexOf(serverName) == -1) {
        this.$error({
          title: "错误",
          content: "错误的请求服务名称"
        });
        return;
      }
      let that = this;
      params = params ? params : {};
      if (params.action && params.action === "GetXLdata") {
        // 特殊处理 ？？？？
        let paramKeys = Object.keys(params);
        for (let key = 0; key < paramKeys.length; key++) {
          let tempType = this.$util.isType(params[paramKeys[key]]);
          if (
            tempType === "String" &&
            params[paramKeys[key]].indexOf("+") >= 0
          ) {
            params[paramKeys[key]] = params[paramKeys[key]].replace(
              /\+/g,
              "$ADD$"
            ); // 特殊字符转义
          }
        }
      }
      otherConfig = otherConfig ? otherConfig : {};
      let header = otherConfig.headers
        ? otherConfig.headers
        : {
            "content-type": "application/x-www-form-urlencoded;charset=utf-8"
          };
      // 请求超时时间
      let timeout = otherConfig.timeout || this.defaultTimeOut;
      let url =
        this.BaseUrl + this.ServerPath + serverName + "?" + params.action;
      Axios.post(url, qs.stringify(params), {
        headers: header,
        timeout: timeout
      })
        .then(resp => {
          if (resolve) resolve();
          let result = that.responseConfirm(resp, otherConfig);
          if (result == 1) {
            if (
              otherConfig.success &&
              that.$util.isType(otherConfig.success) === "Function"
            )
              otherConfig.success(resp.data);
          } else if (result == -1) {
            if (
              otherConfig.error &&
              that.$util.isType(otherConfig.error) === "Function"
            ) {
              if (resp.data) otherConfig.error(resp.data);
              else otherConfig.error({ message: "请求失败，服务异常" });
            }
          }
        })
        .catch(function(error) {
          if (resolve) resolve();
          if (
            otherConfig.error &&
            that.$util.isType(otherConfig.error) === "Function"
          )
            otherConfig.error(error);
          else that.$message.error("请求失败，" + JSON.stringify(error));
        });
    },
    /**
     * post请求上传文件
     * @param serverName
     * @param params
     * @param file
     * @param otherConfig
     * @param resolve 用于支持Promise对象的异步数据确认
     */
    upLoad(serverName, file, params, otherConfig, resolve) {
      if (this.ServerNames.indexOf(serverName) == -1) {
        this.$error({
          title: "错误",
          content: "错误的请求服务名称"
        });
        return;
      }
      let that = this;
      params = params ? params : {};
      otherConfig = otherConfig ? otherConfig : {};
      let header = otherConfig.headers
        ? otherConfig.headers
        : {
            "content-type": "application/x-www-form-urlencoded;charset=utf-8"
          };
      let url =
        this.BaseUrl +
        this.ServerPath +
        serverName +
        "?action=" +
        params.action;
      Axios.post(url, file, {
        headers: header
      })
        .then(resp => {
          if (resolve) resolve();
          let result = that.responseConfirm(resp, otherConfig);
          if (result == 1) {
            if (
              otherConfig.success &&
              that.$util.isType(otherConfig.success) === "Function"
            )
              otherConfig.success(resp.data);
          } else if (result == -1) {
            if (
              otherConfig.error &&
              that.$util.isType(otherConfig.error) === "Function"
            ) {
              if (resp.data) otherConfig.error(resp.data);
              else otherConfig.error({ message: "请求失败，服务异常" });
            }
          }
        })
        .catch(function(error) {
          if (resolve) resolve();
          if (
            otherConfig.error &&
            that.$util.isType(otherConfig.error) === "Function"
          )
            otherConfig.error(error);
          else that.$message.error("请求失败，" + JSON.stringify(error));
        });
    },
    /**
     * 统一处理请求返回数据
     * @param res
     * @returns {*}
     */
    responseConfirm(res, config) {
      if (res && res.status == 200) {
        if (res.data && res.data.success) {
          return 1;
        } else if (res.data && (res.data.code === 1 || res.data.code === 2)) {
          if (!this.$store.state.isShowLoginConfirm) {
            this.$store.commit("setShowLoginConfirm");
            let that = this;
            if (!config.notTimeOut) {
              this.$confirm({
                title: "提示",
                content: "登录失效，请重新登录",
                okText: "确定",
                cancelText: "取消",
                onOk() {
                  return new Promise(resolve => {
                    resolve();
                    that.$store.commit("logout");
                    that.$router.replace("/login");
                    that.$store.commit("setHideLoginConfirm");
                    that.$destroyAll(); // 关闭其他的弹出框
                  }).catch(() => {
                    that.$store.commit("setHideLoginConfirm");
                    that.requestConoleLog("Oops errors");
                  });
                },
                onCancel() {
                  that.$store.commit("setHideLoginConfirm");
                  that.requestConoleLog("取消");
                }
              });
            } else {
              return new Promise(resolve => {
                resolve();
                that.$store.commit("logout");
                that.$router.replace("/login");
                that.$store.commit("setHideLoginConfirm");
                that.$destroyAll(); // 关闭其他的弹出框
              }).catch(() => {
                that.$store.commit("setHideLoginConfirm");
                that.requestConoleLog("Oops errors");
              });
            }
          }
          return 0;
        }
      }
      return -1;
    }
  }
};
