import Vue from "vue";
import XEUtils from "xe-utils";
import util from "../../utils/util.js";
import VXETablePluginVirtualTree from "vxe-table-plugin-virtual-tree";
import {
  VXETable,
  Table,
  Column,
  Header,
  Footer,
  Filter,
  Edit,
  Menu,
  Export,
  Keyboard,
  Validator,
  Grid,
  Toolbar,
  Pager,
  Checkbox,
  Radio,
  Input,
  Textarea,
  Button,
  Modal,
  Tooltip as VxeTooltip,
  Form,
  Select,
  Switch,
  List,
  Icon as VxeIcon
} from "vxe-table";
import zhCNLocat from "vxe-table/lib/locale/lang/zh-CN";

Vue.use(VXETable);
// 按需加载的方式默认是不带国际化的，需要自行导入
VXETable.setup({
  i18n: (key, value) => XEUtils.toFormatString(XEUtils.get(zhCNLocat, key),value)
});

// 创建一个空内容渲染
VXETable.renderer.add("NotData", {
  // 空内容模板
  renderEmpty(h, renderOpts) {
    return [
      <span style="panding">
        <img style="margin-top: 10px;" src="/img/noData.png" />
        <p style="font-size:14px; color:#00000040">暂无数据</p>
      </span>
    ];
  }
});

// 创建一个表格单元格内容el超链接渲染
VXETable.renderer.add("e1ColumnLink", {
  // 默认显示模板
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;
    //console.log("renderOpts=",renderOpts, params, rowIndex);
    let textCheckMethod = renderOpts.textCheckMethod;
    let textClickRule = null;
    let needCheckText = false;
    if (util.isType(textCheckMethod) === "Function") {
      // 设置了内容检测方法，满足方法则需要返回链接，否则只返回普通内容
      needCheckText = true;
      textClickRule = textCheckMethod(row[column.property]);
    }
    let text = util.defaultFormatText(row[column.property], column.title);
    let eParams = {
      record: row,
      text: text,
      column: column,
      linkMethod: renderOpts.clickMethod ? renderOpts.clickMethod : ""
    };
    if (renderOpts.splitSign && text !== "") {
      // 如果设置了数据分割符
      let allLinkText = util.stringSplitArray(text, renderOpts.splitSign);
      if (allLinkText.length > 1) {
        let ht = [];
        for (let i = 0; i < allLinkText.length; i++) {
          let aLink = (
            <a
              className="my-link"
              onClick={event => {
                eParams.event = event;
                eParams.index = i;
                events.click(eParams);
              }}
            >
              {allLinkText[i]}
            </a>
          );
          ht.push(aLink);
          if (i < allLinkText.length - 1) {
            ht.push("，");
          }
        }
        return ht;
      }
    }
    if ((!needCheckText || (needCheckText && textClickRule)) && text !== "") {
      return [
        <a
          class="my-link"
          onClick={event => {
            eParams.event = event;
            events.click(eParams);
          }}
        >
          {text}
        </a>
      ];
    } else {
      if (column.property === "ext_ZJX_option") {
        // 扩展方法，用于-工艺路线明细数据的质检项
        return [
          <a
            className="my-link"
            onClick={event => {
              eParams.event = event;
              events.click(eParams);
            }}
          >
            编辑质检项
          </a>
        ];
      } else {
        return text;
      }
    }
  }
});
// 创建一个默认的表格单元内容格式化
VXETable.renderer.add("e1ColumnDefault", {
  // 默认显示模板
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    if (row[column.property] !== undefined) {
      return util.defaultFormatText(row[column.property], column.title);
    } else {
      return "";
    }
  }
});
// 其他预定义内容格式化方法
VXETable.formats.mixin({
  formatSex({ cellValue }){
    return cellValue === '1' ? '男' : '女'
  },
  formatPercentage({ cellValue }){
    //console.log("列表数据格式化-formatPercentage",cellValue);
    return util.valToPercentStr(cellValue);
  },
  formatDate({ cellValue }){
    return XEUtils.toDateString(cellValue, 'YYYY-MM-DD')
  }
});
// 先安装依赖模块
Vue.use(VxeIcon);
Vue.use(Column);
Vue.use(Header);
Vue.use(Footer);
Vue.use(Filter);
Vue.use(Edit);
Vue.use(Menu);
Vue.use(Export);
Vue.use(Keyboard);
Vue.use(Validator);

Vue.use(VxeTooltip);
Vue.use(Grid);
Vue.use(Toolbar);
Vue.use(Pager);
Vue.use(Form);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(Switch);
Vue.use(Input);
Vue.use(Textarea);
Vue.use(Select);
Vue.use(Button);
Vue.use(Modal);
Vue.use(List);
console.log(Pager);
// 再安装核心库
Vue.use(Table);
VXETable.use(VXETablePluginVirtualTree);
/**
 * 修改分页控件的每页记录条数的渲染方法，支持“所有”选项
 * @param h
 * @return {*}

Pager.methods.renderSizes = function(h) {
  return h("vxe-select", {
    class: "vxe-pager--sizes",
    props: {
      value: this.pageSize,
      placement: "top",
      options: this.pageSizes.map(num => {
        return {
          value: num,
          label:
            num != Infinity
              ? XEUtils.template(XEUtils.get(zhCNLocat, "vxe.pager.pagesize"), [
                  num
                ])
              : "所有"
        };
      })
    },
    on: {
      change: ({ value }) => {
        this.pageSizeEvent(value);
      }
    }
  });
}; */
// 给 vue 实例挂载全局窗口对象
Vue.prototype.$XModal = VXETable.modal;
