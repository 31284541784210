// 明细表键盘操作相关
import { systemMethods } from "@/mixins/systemMethods.js"; // tab缓存数据处理方法

export const ETableDom = {
  mixins: [systemMethods],
  data() {
    return {};
  },
  inject: [], // 引用主页定义的页面操作方法
  computed: {},
  created() {},
  methods: {
    // 表内滚动条事件
    setTableScrollEvent({ type, scrollTop, scrollLeft, isX, isY, $event }) {
      var _this = this;
      // 这里是处理当主表内X轴滚动条滚动时,编辑列也跟着滚动
      // 底部虚拟滚动条跟着滚动
      var bottomDom = this.$refs[this.domId + "bottom"];
      if (bottomDom) {
        bottomDom.scrollLeft = scrollLeft;
      }
      /*console.log(
        "setTableScrollEvent:",
        type,
        scrollTop,
        scrollLeft,
        isX,
        isY,
        $event
      );*/
      if (_this.editIndex) {
        _this.setEditRowStyle(type, "tableScrollevent");
      }
    },

    editScrollEvent(e) {
      // return
      this.$refs[this.domId].scrollTo(e.target.scrollLeft);
    },
    // 滚动条对齐方法
    setTableScroll() {
      let dom = this.$refs[this.domId + "div"].querySelectorAll(
        ".editRowTable"
      )[0];
      if (dom) {
        // x轴移动时同步
        dom.scrollLeft = this.$refs[this.domId].getScroll().scrollLeft;
      }
    },
    // 设置全局的window事件，如表外滚动条，点击取消编辑事件,外部滚动条拉动隐藏底部滚动条
    initWindowEvent() {
      let _this = this;
      setWindow();
      function setWindow() {
        if (window.etable) {
          if (window.etable[_this.domId]) {
            window.etable[_this.domId]["scroll"] = _this.setEditRowStyle;
          } else {
            window.etable[_this.domId] = {};
            setWindow();
          }
        } else {
          window.etable = {};
          setWindow();
        }
      }
    },
    // 是否有Y轴
    haveY() {
      // // 这里的dom 对象是table 主表滚动条容器
      try {
        let tableBodyDom = this.$refs[this.domId].$el.querySelectorAll(
          ".vxe-table--body-wrapper"
        )[0];
        return tableBodyDom.clientWidth < tableBodyDom.offsetWidth;
      } catch {
        return true;
      }
    },
    // 是否有X轴
    haveX(heightAuto) {
      // 没合计
      var _this = this;
      this.$nextTick(() => {
        let refObj = _this.getThisRef(this.domId);
        if(refObj) {
          var XScrollDom = refObj.$el.querySelectorAll(
            ".vxe-table--body-wrapper"
          )[0];
          if (XScrollDom) {
            // 有X轴滚动条
            if (XScrollDom.scrollWidth > XScrollDom.clientWidth) {
              var DOMRect = XScrollDom.getBoundingClientRect();
              if (DOMRect.top + DOMRect.height > window.innerHeight) {
                // 如果视窗没有到明细表则不显示
                if (DOMRect.top - 20 > window.innerHeight) {
                  // bu显示
                  this.domScrollHide = true;
                } else {
                  // 显示
                  this.domScrollHide = false;
                }
              } else {
                // bu显示
                this.domScrollHide = true;
              }
            }
          }
        }
        if (heightAuto == true) {
          // 解决tab切换时的问题，有延迟因为计算渲染没这么快
          setTimeout(() => {
            _this.heightAuto();
            _this.haveX();
          }, 50);
        }
      });
    },
    // 高度自适应
    heightAuto() {
      var dom = this.$refs[this.domId + "relative"];
      var setDom = this.$refs[this.domId].$el.querySelectorAll(
        ".vxe-table--body-wrapper"
      )[0];

      // 用这个方法拿的是class内的css属性，而不是本身的style
      var height = document.defaultView.getComputedStyle(dom)["max-height"];

      if (height != "" && height != "none") {
        height = splitPx(height);
        if (this.config.sumRow) {
          height = height - 90;
        } else {
          height = height - 55;
        }
        if (height < 0) {
          height = 0;
        }
        setDom.style["height"] = height + "px";
      }

      function splitPx(num) {
        num = Number(num.split("px")[0]);
        return num;
      }
    },
    // 设置编辑行的随着滚动移动
    setEditRowStyle(type, origin) {
      //console.log("type", type, "scrollTop", scrollTop);
      let _this = this;
      this.$nextTick(() => {
        if (this.editIndex) {
          //console.log("触发来源:", origin);

          // 这里的dom 对象是编辑的具体的行
          let dom = this.$refs[this.domId].$el.querySelectorAll(
            "." + this.domId + "row" + _this.editIndex
          )[0];

          //console.log("domobject", this.editIndex, dom);

          // // 这里的dom 对象是table 主表滚动条容器
          let tableBodyDom = this.$refs[this.domId].$el.querySelectorAll(
            ".vxe-table--body-wrapper"
          )[0];

          if (dom) {
            let colHeight = dom.clientHeight;
            let editDom = _this.$refs[_this.domId + "div"].querySelectorAll(
              ".editRowTable"
            )[0];

            let editRowIndex = this.editFindIndex(this.editIndex);
            editRowIndex += 1;
            let top =
              editRowIndex * colHeight -
              this.$refs[this.domId].getScroll().scrollTop +
              1;
            // debugger
            // 10的预留
            let bottomBorder = tableBodyDom.offsetHeight + dom.offsetHeight / 3;

            //console.log("top:", top);

            if (this.config.sumRow) {
              bottomBorder =
                bottomBorder + dom.offsetHeight / 3 - dom.offsetHeight / 2;
            }
            /*console.log(
              "toptop:",
              top < 30 || top > bottomBorder,
              bottomBorder
            );*/
            if (top < 30 || top > bottomBorder) {
              this.editShow = false;
            } else {
              editDom.style.top = top + "px";
              this.editShow = true;
            }
            this.setTableScroll();
          } else {
            this.editShow = false;
          }
        }
        // 判断滚动条
        this.haveX(type);
      });
    },
    // 当table resize时的事件
    setTableResizable(info) {
      var columnsIndex = -1;
      var target = this.columns.find((item, index) => {
        if (item.dataIndex == info.column.property) {
          columnsIndex = index;
          return true;
        }
      });
      if (target) {
        target.width = info.column.resizeWidth;
      }
    },
    setEditColWidth(filed) {
      // debugger
      if (this.$refs[this.domId] && filed) {
        try {
          var width = this.$refs[this.domId].getColumnByField(filed)
            .renderWidth;
        } catch (err) {
          err;
        }
        if (width) {
          return width + "px !important";
        } else {
          return "auto !important";
        }
      }
    },
    // 设置最下X轴滚动条宽度
    setBottomScrollWidth(width) {
      if (width) {
        this.$refs[this.domId + "bottom-scroll"].style.width = width + "px";
        return;
      }
      if (this.bodyTableDom) {
        this.bottomScrollWidth = this.bodyTableDom.scrollWidth;
      } else {
        this.bottomScrollWidth = 0;
      }
    },
    // 最下面滚动条随着滚动
    bottomScroll(e) {
      this.$refs[this.domId].scrollTo(e.target.scrollLeft);
    }
  }
};
