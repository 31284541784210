<template>
  <a-radio-group
    v-model="setvalue"
    :defaultValue="defaultValue"
    :value="setvalue"
    :disabled="thisDisabled"
    :options="option"
    v-show="visible"
    v-if="!radio"
    ref="booleanCheck"
  ></a-radio-group>
</template>

<script>
export default {
  name: "EBooleanCheckbox",
  data() {
    return {
      setvalue: this.formatBool,
      thisDisabled: false,
      option: [
        { label: this.trueLabel, value: true, disabled: false },
        { label: this.falseLabel, value: false, disabled: false }
      ]
    };
  },
  model: {
    prop: "value",
    event: "change",
    type: Boolean
  },
  props: {
    defaultValue: {
      type: [Boolean, String]
    },
    value: {
      type: [Boolean, String]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    radio: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    trueLabel: {
      type: String,
      default: "是"
    },
    falseLabel: {
      type: String,
      default: "否"
    }
  },
  created() {
    this.thisDisabled = this.disabled;
    // 初始化组件时，如果没有值，则默认为false
    if (
      this.value === "true" ||
      this.value === "True" ||
      this.value === "TRUE" ||
      this.value == 1
    ) {
      this.setvalue = true;
    } else if (
      this.value === "false" ||
      this.value === "False" ||
      this.value === "FALSE" ||
      this.value === "" ||
      this.value == 0 ||
      this.value == undefined
    ) {
      this.setvalue = false;
    } else {
      this.setvalue = this.value;
    }
  },
  computed: {
    formatBool: function() {
      if (
        this.value === "true" ||
        this.value === "True" ||
        this.value === "TRUE" ||
        this.value == 1
      ) {
        return true;
      } else if (
        this.value === "false" ||
        this.value === "False" ||
        this.value === "FALSE" ||
        this.value === "" ||
        this.value == 0 ||
        this.value == undefined
      ) {
        return false;
      } else {
        return this.value;
      }
    }
  },
  methods: {
    /**
     * 设置禁用状态
     * @param v
     */
    setDisabled(v) {
      if(this.$util.isType(v) === "Boolean") {
        this.thisDisabled = v;
      }else {
        this.thisDisabled = true;
      }
    },
    /**
     * 切换当前控件的禁用状态
     */
    setDisabledSwitch() {
      this.thisDisabled = !this.thisDisabled;
    },
    tableFocus() {
      this.$refs.booleanCheck.focus();
    },
    tableBlur() {
      this.$refs.booleanCheck.blur();
    }
  },
  watch: {
    setvalue(value) {
      this.$emit("change", value);
    },
    value() {
      this.setvalue = this.formatBool;
    },
    disabled(v) {
      this.thisDisabled = v;
    },
  }
};
</script>
