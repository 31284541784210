import Vue from "vue";
import VueRouter from "vue-router";
import loginBackup from "../views/Login.vue";
import index from "../views/Index.vue";
import about from "../views/About.vue";
import home from "../views/notice/Home.vue";
import NotFound from "../views/NotFound.vue";
import NewForm from "../views/form/NewForm.vue";
import ApprovalForm from "../views/form/ApprovalForm.vue";
import DetailForm from "../views/form/DetailForm.vue";
import ChargeAgainstForm from "../views/form/ChargeAgainstForm.vue";
import KeepAccountsForm from "../views/form/KeepAccountsForm.vue";
import DataModifyApprovalForm from "../views/form/DataModifyApprovalForm.vue";
import EIFrame from "../views/EIFrame.vue";
import UserForms from "../views/listView/UserForms.vue";
import login from "../views/login/login.vue";

import Notice from "../views/notice/Notice.vue";
import test from "../views/test.vue";
import mobileList from "../views/builder/mobileBuilder/mobileList.vue";
import mobileBuilder from "../views/builder/mobileBuilder/mobileBuilder.vue";
// import AuthorityView from "../views/listView/AuthorityView.vue";
import RdlReportView from "../views/listView/RdlReportView.vue";
import transferRedirect from "../views/TransferRedirect.vue";
import BusinessFlowConfigView from "../views/listView/BusinessFlowConfigView.vue";
//对于replace方法做跳转路由异常过滤
const originalPush = VueRouter.prototype.replace;
VueRouter.prototype.replace = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: login
  },
  {
    path: "/transferRedirect",
    name: "transferRedirect",
    component: transferRedirect
  },
  {
    path: "/Ltest",
    name: "Ltest",
    component: loginBackup
  },
  {
    path: "/test",
    name: "test",
    component: test
  },
  {
    path: "/test2",
    name: "test2",
    component: BusinessFlowConfigView
  },
  {
    path: "/ca",
    name: "t",
    component: mobileList
  },
  {
    path: "/caa/:id",
    name: "t",
    component: mobileBuilder
  },
  {
    path: "/",
    name: "index",
    component: index,
    children: [
      {
        path: "/about",
        name: "about",
        component: about
      },
      {
        path: "/home",
        name: "home",
        component: home
      },
      {
        path: "/NewForm/:formName/:dhbm/:dataId",
        name: "NewForm",
        component: NewForm
      },
      {
        path: "/NewForm/:formName/:dhbm",
        name: "NewForm",
        component: NewForm
      },
      {
        path: "/NewForm",
        name: "NewForm",
        component: NewForm
      },
      {
        path: "/UserForms/:formName/:dhbm",
        name: "UserForms",
        component: UserForms
      },
      {
        path: "/UserForms",
        name: "UserForms",
        component: UserForms
      },
      {
        path: "/Notice/:title/:dhbm",
        name: "Notice",
        component: Notice
      },
      {
        path: "/ApprovalForm",
        name: "ApprovalForm",
        component: ApprovalForm
      },
      {
        path: "/KeepAccountsForm",
        name: "KeepAccountsForm",
        component: KeepAccountsForm
      },
      {
        path: "/DetailForm",
        name: "DetailForm",
        component: DetailForm
      },
      {
        path: "/ChargeAgainstForm",
        name: "ChargeAgainstForm",
        component: ChargeAgainstForm
      },
      {
        path: "/DataModifyApprovalForm",
        name: "DataModifyApprovalForm",
        component: DataModifyApprovalForm
      },
      {
        path: "/EIFrame",
        name: "EIFrame",
        component: EIFrame
      },
      {
        path: "/test",
        name: "test",
        component: test
      },
      {
        path: "/RdlReportView/:dataId/:rdlName",
        name: "RdlReportView",
        component: RdlReportView
      },
      {
        path: "/mobileList",
        name: "mobileList",
        component: mobileList
      },
      {
        path: "/mobileBuilder/:id",
        name: "mobileBuilder",
        component: mobileBuilder
      },
      {
        path: "*",
        component: NotFound
      }
    ]
  },
  {
    path: "*",
    component: NotFound
  }
];
const router = new VueRouter({
  routes
});
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    //如果未匹配到路由
    from.name ? next({ name: from.name }) : next("*"); //如果上级也未匹配到路由则跳转登录页面，如果上级能匹配到则转上级路由
  } else {
    next(); //如果匹配到正确跳转
  }
});
export default router;
