/*登陆页面配置*/
var loginConfig = {
  style: "right1",
  welcome: "欢迎登陆后台系统", // 欢迎语
  companyName: "上海嵌嵌信息科技有限公司", // 显示的公司名称
  systemName: "上海嵌嵌信息管理系统" // 显示的系统名称
};

export const LOGIN_CONFIG = {
  data() {
    return {};
  },
  created() {},
  methods: {
    loginConfig() {
      return loginConfig;
    }
  }
};
