<template>
  <div>
    <a-modal
      title="选择岗级"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @ok="handleSubmit"
      @cancel="handleCancel"
      cancelText="关闭"
      :centered="true"
      width="auto"
      wrapClassName="modal"
      :bodyStyle="{ 'min-width': '520px' }"
    >
      <template slot="footer">
        <a-button key="submit" type="primary" @click="handleSubmit">
          确定
        </a-button>
      </template>
      <a-spin :spinning="spinning">
        <a-tree
          :style="{ 'max-height': maxHeight, 'overflow-y': 'auto' }"
          ref="treeMod"
          checkable
          :treeData="treeData"
          :checkStrictly="true"
          @check="onCheck"
          @select="
            (key, info) => {
              info.node.checkable == false
                ? onExpand(info.node.eventKey, info, 'select')
                : onSelect(key, info);
            }
          "
          @expand="onExpand"
          :autoExpandParent="autoExpandParent"
          :expandedKeys="expandedKeys"
          :checkedKeys="checkedKeys"
        ></a-tree>
      </a-spin>
      <!-- :loadData="onLoadData" -->
    </a-modal>
  </div>
</template>
<script>
import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法
export default {
  name: "ECustomerTree",
  props: {
    mode: {
      type: String
    },
    defaultValue: {
      type: [String, Array],
      default: ""
    },
    value: {
      type: null,
      default: ""
    },
    setting: {
      type: [Object]
    }
  },
  mixins: [requestControls],
  data() {
    return {
      visible: false,
      confirmLoading: false,
      treeData: [],
      autoExpandParent: true,
      expandedKeys: [],
      checkedKeys: [],
      checkedRows: [],
      spinning: true,
      checkName: "",
      checkId: "",
      firstLoad: true
    };
  },
  created() {
    this.loadDepart();
  },
  mounted() {},
  computed: {
    maxHeight: function() {
      return this.$root.NowWindowsSize.height * 0.7 + "px";
    },
    maxWidth: function() {
      return this.$root.NowWindowsSize.width + "px";
    }
  },
  watch: {
    departId() {
      this.initDepartComponent();
    },
    // value(value) {
    //   console.log("value:", value);
    //   if (!this.firstLoad) {
    //     this.loadDepart(value);
    //   }
    // },
    visible: {
      handler() {
        if (this.departId) {
          this.checkedKeys = this.departId.split(",");
        } else {
          // this.checkedKeys = [];
        }
      }
    }
  },
  methods: {
    show() {
      this.visible = true;
      // this.checkedRows = [];
      // this.checkedKeys = [];
    },
    Empty() {
      this.checkedRows = [];
      this.checkedKeys = [];
    },
    loadDepart() {
      var _this = this;
      var url = this.setting.serverName;
      let params = {
        action: this.setting.treeAction
      };
      this.get(url, params, {
        success: function(res) {
          if (res.success) {
            let arr = res.data;
            if (_this.firstLoad == true) {
              _this.treeData = arr;
            }
            _this.firstLoad = false;
            console.log("value,d=" + _this.value + "," + _this.defaultValue);
            //  优先级value比defaultValue高
            if (
              (_this.value != "" && _this.value != null) ||
              JSON.stringify(_this.value) == "{}"
            ) {
              _this.checkValue(arr, _this.value, true);
            } else if (
              (_this.defaultValue != "" && _this.defaultValue) != null ||
              JSON.stringify(_this.defaultValue) == "{}"
            ) {
              _this.checkValue(arr, _this.defaultValue, true);
            }
          }
          _this.spinning = false;
        },
        error: function() {}
      });
    },
    onLoadData(treeNode) {
      var _this = this;
      return new Promise(resolve => {
        if (treeNode.dataRef.children == "" || !treeNode.dataRef.children) {
          var url = _this.setting.serverName;
          let params = {
            action: _this.setting.nodeAction,
            ID: treeNode.dataRef.key
          };
          this.get(url, params, {
            success: function(res) {
              if (res.success) {
                treeNode.dataRef.children = res.data;
                resolve();
              }
            },
            error: function() {}
          });
        } else {
          resolve();
          return;
        }
      });
    },
    checkValueOk(obj, element) {
      let currKey = obj.key;
      if (this.checkedKeys.indexOf(currKey) >= 0) {
        this.checkedKeys = this.checkedKeys.filter(item => {
          return item != currKey;
        });
        this.checkedRows = this.checkedRows.filter(item => {
          return item.key != currKey;
        });
      } else {
        var have = this.checkedRows.findIndex(function(objct) {
          return objct.key === element.key;
        });
        if (have == -1) {
          this.checkedRows.push(element);
          this.checkedKeys.push(element.key);
        }
      }
    },
    checkValue(obj, value, end) {
      if (value == null || value == "" || JSON.stringify(value) == "{}") {
        return;
      }
      // 如果是number处理为字符串
      if (value == "number") {
        value = value.toString();
      }
      obj.forEach(element => {
        if (typeof value == "string") {
          if (element.key == value || element.title == value) {
            this.checkValueOk(obj, element);
          }
        }
        if (typeof value == "object") {
          if (value.constructor === Object) {
            if (element.key == value.value && element.title == value.label) {
              this.checkValueOk(obj, element);
            }
          }
          if (value.constructor === Array) {
            for (var i = 0; i <= value.length - 1; i++) {
              if (value[i].constructor == Object) {
                if (
                  element.key == value[i].value &&
                  element.title == value[i].label
                ) {
                  this.checkValueOk(obj, element);
                }
              } else {
                if (element.key == value || element.title == value) {
                  this.checkValueOk(obj, element);
                }
              }
            }
          }
        }
        try {
          if (element.children.length != 0) {
            this.checkValue(element.children, value);
          }
        } catch (err) {
          err;
        }
      });
      if (end) {
        this.handleSubmit(true);
      }
      return false;
    },
    setCheckValue() {},
    onCheck(checkedKeys, info) {
      if (this.mode == "single") {
        let arr = checkedKeys.checked.filter(item => {
          return this.checkedKeys.indexOf(item) < 0;
        });
        this.checkedKeys = [...arr];
        this.checkedRows = [info.node.dataRef];
      } else if (this.mode == "multi") {
        let currKey = info.node.dataRef.key;
        if (this.checkedKeys.indexOf(currKey) >= 0) {
          this.checkedKeys = this.checkedKeys.filter(item => {
            return item != currKey;
          });
          this.checkedRows = this.checkedRows.filter(item => {
            return item.key != currKey;
          });
        } else {
          this.checkedRows.push(info.node.dataRef);
          this.checkedKeys = checkedKeys.checked;
        }
      }
    },
    onSelect(selectedKeys, info) {
      // if (info.node.checkable == false) {
      //   this.onExpand(selectedKeys, info);
      //   return;
      // }
      let keys = [];
      keys.push(selectedKeys[0]);
      if (
        !this.checkedKeys ||
        this.checkedKeys.length == 0 ||
        this.mode == "single"
      ) {
        // 单选时调用onCheck函数
        this.onCheck({ checked: [info.node.eventKey], halfChecked: [] }, info);
        // this.checkedKeys = [...keys];
        // this.checkedRows = [info.node.dataRef];
      } else if (this.mode == "multi") {
        let currKey = info.node.dataRef.key;
        if (this.checkedKeys.indexOf(currKey) >= 0) {
          this.checkedKeys = this.checkedKeys.filter(item => {
            return item != currKey;
          });
          this.checkedRows = this.checkedRows.filter(item => {
            return item.key != currKey;
          });
        } else {
          this.checkedRows.push(info.node.dataRef);
          this.checkedKeys.push(...keys);
        }
      }
    },
    onExpand(expandedKeys, info, type) {
      // 因为tree select 的机制，连续点击同一个select有问题，这里是做这个处理
      if (type == "select") {
        var isHave = this.expandedKeys.findIndex(item => {
          return item + "" === expandedKeys + "";
        });
        if (isHave == -1) {
          this.expandedKeys.push(expandedKeys);
        } else {
          this.expandedKeys.splice(isHave, 1);
        }
      } else {
        this.expandedKeys = expandedKeys;
      }
      // if (info.node.checkable == false) {
      //   if (expandedKeys[0] == null && this.expandedKeys[0] == null) {
      //     this.expandedKeys = [info.node.eventKey];
      //     return;
      //   }
      // }
      // if (expandedKeys[0] == this.expandedKeys[0]) {
      //   this.expandedKeys = [];
      //   return;
      // }
      // this.expandedKeys = expandedKeys;
      // this.autoExpandParent = false;
    },
    handleSubmit(first) {
      if (!this.checkedKeys || this.checkedKeys.length == 0) {
        if (first) {
          try {
            var arr = [];
            if (this.value.length != null && typeof this.value != "string") {
              this.value.forEach(element => {
                var obj = {
                  title: element.label,
                  key: element.value
                };
                arr.push(obj);
              });
            } else {
              var obj2 = {
                title: this.value.label,
                key: this.value.value
              };
              arr.push(obj2);
            }
            this.$emit("ok", arr, "1");
          } catch (err) {
            this.$emit("ok", "");
            console.log("控件出错了！", err);
          }
        } else {
          this.$emit("ok", "");
        }
      } else {
        this.$emit("ok", this.checkedRows, this.checkedKeys.join(","));
      }
      this.handleClear();
    },
    handleCancel() {
      this.handleClear();
    },
    handleClear() {
      this.visible = false;
      // this.checkedKeys = [];
      // this.checkedRows = [];
      //this.loadDepart()
      //this.$refs.treeMod.selectedKeys = [];
    }
  }
};
</script>
<style scoped lang="less">
@import "../../../assets/style/base/controls/e-pop-up-select-tree.less";
</style>
