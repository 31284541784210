<!-- 表单内容修改审批申请表 -->
<template>
  <div>
    <!-- 授权操作按钮-->
    <div v-if="showToolbar" class="form-headButton">
      <e-toolbar
        :buttons="buttons"
        :operationDisabled="operationDisabled"
        @click="onButtonClick"
        :size="$config.defaultControlSize"
        ref="eToolbar"
        :formConfigs="formConfig"
      ></e-toolbar>
    </div>
    <div :class="getTabFormContextClass" @scroll="formScroll">
      <!-- 表单标题-->
      <e-head-title
        v-if="showForm"
        title="表单修改申请表"
        :id="1"
        :size="$config.defaultControlSize"
        :tip-info="spState"
        rightIcon="true"
        @clickRightIcon="closeFormTable"
      >
      </e-head-title>
      <!-- 审批申请表表单区域 -->
      <div
        class="form-table form-table__inline-table"
        v-if="showForm"
        v-show="foldFormTable1"
      >
        <a-spin
          :spinning="showFormSpin"
          tip="表单加载中..."
          size="large"
          class="form-spin"
        >
        </a-spin>
        <a-form :form="thisForm" class="form_approval_context">
          <form-template
            v-if="!showFormSpin"
            :colSpan="thisColSpan"
            :formRows="formRows"
            :mainTableData="oldMainTableObject"
            :formDisable="formDisable"
            :formEditOver="formEditState"
            :tempId="tempId"
            :form="thisForm"
            ref="formApprovalTemplate"
            showModalType
          ></form-template>
        </a-form>
        <div v-show="showFormError"><p>表单加载失败</p></div>
      </div>
      <!-- 需要修改的表单显示区域 margin-top: 10px;-->
      <div v-show="foldFormTable2" class="form_approval_linkFrom">
        <a-form :form="form" :class="'form-data'">
          <e-form
            :form="form"
            :tempId="spFromTempId"
            :dataId="dataId"
            :disable="formDisable"
            :editOver="formEditState"
            :formConfigs="formConfigs"
            :title="title"
            :tableData="mainTableData"
            :mainDataChanged="mainDataChanged"
            :mxDataChanges="mxDataChanges"
            :mxOprButtons="mxOprButtons"
            :fieldsAllowedEdit="fieldsAllowedEdit"
            :mxAllowedEditField="mxAllowedEditField"
            :mxReadOnlyFields="mxReadOnlyFields"
            :hideFields="hideField"
            :mxHhAuto="mxChangeHh"
            ref="formTemplate"
            @zoom="zoomState => (isZoom = zoomState)"
            @publicUploadChange="publicUploadChange"
          ></e-form>
        </a-form>
      </div>
    </div>

    <!-- 其他功能区域 -->
    <!-- 审批意见确认窗 <a-icon type="form"></a-icon>-->
    <e-form-modal
      v-model="spModalShow"
      :buttons="spButtons"
      formName="审批意见表"
      @buttonClicked="doFormSp"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">表单审批</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 提交审批确认窗 -->
    <e-form-modal
      v-model="tjModalShow"
      formName="提交审批"
      :initFormData="tjDefaultData"
      :showOk="true"
      @okClicked="doFormTj"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">提交审批</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 审批记录弹出框 -->
    <e-table-modal
      v-model="spjlModalShow"
      :tableConfig="spTableConfig"
      title="审批记录"
      width="900"
      :tableDatas="spTableDatas"
      :loading="spTableSpin"
    ></e-table-modal>
    <!-- 查看审批流程图 -->
    <view-image-modal
      v-model="showWorkFlowImage"
      :src="workflowImageSrc"
      title="流程图"
    ></view-image-modal>
    <!-- 明细数据选人弹出框 -->
    <e-table-modal
      v-if="gridImportTableConfig && gridImportTableConfig.columns"
      v-model="gridImportModalShow"
      :tableConfig="gridImportTableConfig"
      title="明细选入搜索"
      width="950"
      :tableDatas="gridImportTableDatas"
      :buttons="gridImportModalButtons"
      :loading="gridImportModalLading"
      :selectedKeys="gridImportTableSelectedKeys"
      @buttonClicked="onClickTableModal"
    ></e-table-modal>

    <!-- 明细数据选人弹出框"筛选"条件选择窗 -->
    <e-form-modal
      v-model="gridImportSelectModalShow"
      :tempId="gridImportSelectTempId"
      :showOk="true"
      @okClicked="onGridImportSelected"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">筛选条件</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 提示错误信息明细 -->
    <e-table-modal
      v-model="errTableModalShow"
      :tableConfig="errTableConfig"
      title="错误提示信息"
      :tableDatas="errTableDatas"
      :loading="errTableSpin"
      :needCreateFrom="errTableInfoCreateFrom"
      :formTitle="errToCreateFormName"
      needExport
      needPrint
    ></e-table-modal>
    <!-- 导入明细表控件 -->
    <!-- <ImportExcelDataModal
                    @ExcelImportOk="ExcelImportOk"
                    ref="importExcel"
            ></ImportExcelDataModal> -->

    <!-- 系统打印控件 -->
    <systemPrintSet ref="systemPrintSet"></systemPrintSet>
    <!-- 公共上传控件 -->
    <EUpload
      ref="publicUpload"
      @change="publicUploadChangeValue"
      @fileListNumChange="publicUploadNumChange"
      @CanSealFile="CanSealFile"
      v-show="false"
      :auth="
        operaterFile
          ? operaterFile
          : { CanEditFile: true, CanRemoveFile: true, CanSealFile: true }
      "
    />
  </div>
</template>

<script>
import EForm from "@/components/EForm.vue";
import { e1Form } from "@/views/form/mixins/e1Form.js"; // 表单公共方法
import { e1FormToolbarWorkFlow } from "@/views/form/mixins/e1FormToolbarWorkFlow.js"; // e1的审批操作方法
import { e1FormToolbarGridImport } from "@/views/form/mixins/e1FormToolbarGridImport.js"; // e1的明细选人操作方法
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; // 解析菜单配置的权限字符串方法
import { Upload } from "@/views/form/mixins/Upload.js"; // 公共上传mixins

export default {
  mixins: [
    e1Form,
    analyticalMenuAuth,
    e1FormToolbarWorkFlow,
    e1FormToolbarGridImport,
    Upload
  ],
  name: "DataModifyApprovalForm",
  components: {
    EForm
  },
  data() {
    return {
      getMainTempAction: "GetFormTemplate",

      // 修改申请审批表单相关参数
      thisForm: null, // 审批申请表的表单
      approvalFormMainTableName: null, // 审批申请表的主表
      oldMainTableObject: {},
      modifiedDatas: null,
      mxDataChanges: null, //明细表修改的内容
      mainDataChanged: null, //主表单修改的内容
      showForm: false,
      showFormError: false,
      showFormSpin: false,
      thisFormName: null, // 对应的表单名称
      formRows: [],
      thisColSpan: 24,
      spFormDataId: null, // 审批表对应的id
      saveTempID: null, // 特殊表单，有时会指定保存使用的模板id
      is_temp_changed: false,// 特殊表单用于表示该表单模板变跟的状态

      // 修改的对应表单相关参数
      formName: null, // 对应的表单名称
      openType: 0, // 打开本表单的方式，0：使用通过params传参打开，1:使用query传参打开
      dataFromType: null, // 表单数据来源，默认根据dataId获取，默认为空，有效值：“dataFromCache”、“dateSearchView”
      foldFormTable1: true, // 是否折叠主表单
      foldFormTable2: true, // 是否折叠明细表单

      printConfig: null, // 系统打印的参数
      random: null, // 对于从非菜单栏打开的本页面可以使用random作为tab唯一标识符
      dhbm: null, // 当前开页面关联权限参数的id

      spFromTempId: null, // 当前待修改的表单模板id

      bdbh: null, // 保存后的表单编号
      tableData: null, // 表单原生数据
      dataId: 0, //对应的表单id
      bm: null,
      businessModel: null, // 用于模板加接口，获取表单模板默认数据的参数1
      defaultDataId: null, // 用于模板加接口，获取表单模板默认数据的参数2
      etName: null, // 当前表单的etName参数

      buttons: [], // 当前用户可用的操作按钮
      defaultFiled: "",
      thisConfig: null,

      formConfig: null, // 保存表单模板解析结果的容器配置数据
      mxOprButtons: [], // 明细表可做操作
      defaultMainTableFiled: null,
      fieldsAllowedEdit: null, // 可编辑的表单项，在formDisable=true时可用
      mxAllowedEditField: null, // 可编辑的明细表单项，在formDisable=true时可用
      mxReadOnlyFields: null, // 需要设置为只读的明细表字段
      requiredField: null, // 可编辑的表单项包含必填项，包含主表单和明细表
      hideField: null, // 需要隐藏的字段，包含主表单和明细表的设置
      logSource: 2, // 编辑表单日志记录来源类型，0 表单新建或审批过程中进行的数据修改， 1 表单查询页面中使用编辑功能进行的数据修改， 2 通过表单数据修改审批表进行的数据修改
      thisChildrenTablesDatas: null,

      // 错误信息是弹出表格
      errTableModalShow: false,
      errTableConfig: {
        rowKey: this.defaultTableKeyName,
        columns: []
      },
      errTableDatas: [],
      errTableSpin: true,
      errTableInfoCreateFrom: null, // 错误弹出信息，直接生成表单id
      errToCreateFormName: "", //  错误弹出信息，直接生成表单的表单名称

      isZoom: false // 明细表进入最大化状态
    };
  },
  created() {
    //console.log("路由属性：" + JSON.stringify(this.$route.params)); // 从菜单直接带过来
    console.log("newForm创建---------------------->");
    this.tempId = this.$route.query.templetId;
    this.spFromTempId = this.$route.query.spFromTempId;
    this.random = this.$route.query.random;
    this.dataId = this.$route.query.dataId;
    this.spFormDataId = this.$route.query.spFormDataId; // 审批表单的数据ID
    if (!this.tempId) {
      let that = this;
      // 从菜单点入，通过params传参
      this.alertError("错误的表单配置", function() {
        that.closeTab(that.random);
      });
      return;
    } else {
      // 页面内按钮点开、是通过query传参
      this.openType = 1;
      this.spMainTableDataID = 0;
      this.dhbm = this.$route.query.dhbm;
      this.dataFromType = this.$route.query.type;
      // 附加主表单默认数据的默认字段名称，有对应的this.defaultFiled+"_Name"参数将其值传入
      this.defaultFiled = this.$route.query.defaultFiled;
      // 如果参数中携带了默认字段值，则给主表赋予对应字段对应默认值
      this.defaultMainTableFiled = this.getDefaultFiledForQuery(
        this.defaultFiled
      );
      // 用于模板加接口，获取表单模板默认数据的参数
      this.businessModel = this.$route.query.businessModel;
      this.defaultDataId = this.$route.query.defaultDataId;
    }
    this.formName =
      "updateApproval-" +
      (this.dataFromType ? this.dataFromType + "_" : "") +
      this.random;
    this.thisForm = this.$form.createForm(this, {
      name: "apply_" + this.formName
    });
    this.form = this.$form.createForm(this, { name: this.formName });
  },
  mounted() {
    //console.log("请求参数：" + JSON.stringify(this.$route.query));
    //console.log("创建表单："+this.$route.params.dhbm);
    console.log("newForm mounted---------------------->", this.dataId);
    this.loading();
    if (this.tempId) {
      if (this.dataId || this.bdbh) {
        // 初始化如果有dataId,说明是审批单新增模式，已保存的行号不能修改
        this.mxChangeHh = false;
        this.getApprovalFormTemplate();
        this.getFormTemplate();
        return;
      } else if (this.spFormDataId) {
        // 是审批申请单，进入到流程通过我的E1点击进入
        this.getMainTempAction = "GetFormTemplateToWorkFlow";
        this.spMainTableDataID = this.spFormDataId;
        this.getApprovalFormTemplate(this.spFormDataId);
        return;
      }
    }
    this.alertError("错误的用户配置");
    this.loadingError();
  },
  methods: {
    /**
     * 获取表单模板
     */
    getFormTemplate() {
      let that = this;
      let tempMxData = null;
      let tempMainData = null;
      // 注意使用只有“GetFormTemplate”接口的d_id传的是参数dhbm值
      let selectID = this.dataId;
      let padata = {
        dmbh: this.dhbm,
        type: this.dataFromType,
        id: this.dataId,
        businessModel: this.businessModel,
        defalultDataId: this.defaultDataId
      };
      // 如果是是审批申请单，进入到流程通过我的E1点击进入
      if (this.getMainTempAction === "GetFormTemplateToWorkFlow") {
        padata.defalultDataId = this.spFormDataId;
        padata.spTempId = this.tempId;
      }
      padata.id = selectID;
      this.getForm(
        this.getMainTempAction,
        this.spFromTempId,
        padata,
        function(data) {
          // 获取表单对应的操作按钮
          that.buttons = data.buttons;
          that.mxOprButtons = data.rowButtons; // 明细表可做操作按钮
          if (data.tansferTemp) {
            // 如果有弹出报错明细，可以直接生成对应的表单
            that.errTableInfoCreateFrom = data.tansferTemp.tansferTemplateId;
            that.errToCreateFormName = data.tansferTemp.tansferTemplateName;
          }
          that.printConfig = data.template[0].MBNR;

          if (that.dataId) {
            // 如果需要获取数据，需要获得getData参数
            let saveParam = that.checkOperationAuth(
              that.buttons,
              "getData",
              () => {
                that.alertWarning("您没有查询本表单数据的权限");
              }
            );
            if (saveParam) {
              that.bm = saveParam.bm;
              that.etName = saveParam.et_name;
            }
          } else if (that.buttons && that.buttons.length > 0) {
            if (data.formData) {
              // 如果返回了，初始化表单数据
              that.dataFromType = "initFormData"; // 返回了初始化表单数据
              if (data.formData.mainTable) {
                tempMainData = data.formData.mainTable;
              }
              //console.log("从后台接口中获取数据后合并到表单数据：", that.mainTableData);
              if (data.formData.childrenTable) {
                tempMxData = data.formData.childrenTable;
              }
            }
          }
        },
        function() {
          if (that.dataId) {
            that.getFormData(
              that.spFromTempId,
              that.dataId,
              that.bm,
              that.etName,
              function(data) {
                if (that.dataFromType !== "dateSearchView") {
                  that.tableData = {};
                  that.tableData.mainTable = Object.assign(
                    {},
                    that.mainTableData
                  );
                  if (data.childrenDatas && data.childrenDatas.length > 0)
                    that.tableData.detailTable = JSON.parse(
                      JSON.stringify(data.childrenDatas)
                    );
                  if (that.modifiedDatas) {
                    console.log("修改后的数据：", that.modifiedDatas);
                    if (that.modifiedDatas.mainTable)
                      that.mainDataChanged = that.modifiedDatas.mainTable.data;
                    if (that.modifiedDatas.detailTable)
                      that.mxDataChanges = that.modifiedDatas.detailTable;
                  }
                  // 如果是从查询视图进来的编辑，这是特殊表单处理，所以不需要考虑流程状态
                  let workFlows = data.workFlow;
                  if (!that.isFormWorkflowEnd) {
                    // 流程未结束,去检测控件的可见范围是否有效
                    that.checkMainVisScopeTakeEffect(that.formConfigs.formRows);
                    that.checkMxVisScopeTakeEffect(that.formConfigs);
                  }
                  //如果设置了隐藏字段
                  if (workFlows.HideField && that.isFormWorkflowEnd == false) {
                    that.hideField = workFlows.HideField;
                  }
                  // 获取表单可编辑项目
                  if (workFlows.EditableField) {
                    let canEdit = false;
                    if (
                      workFlows.EditableField.mainTableField &&
                      workFlows.EditableField.mainTableField.length > 0
                    ) {
                      that.fieldsAllowedEdit =
                        workFlows.EditableField.mainTableField;
                      //console.log("明细有可以编辑的字段：",workFlows.field.chrenTableField);
                      canEdit = true;
                    }
                    console.log(
                      "明细有可以编辑的字段：",
                      workFlows.EditableField.chrenTableField
                    );
                    if (
                      workFlows.EditableField.chrenTableField &&
                      that.$util.isValidObject(
                        workFlows.EditableField.chrenTableField
                      )
                    ) {
                      that.mxAllowedEditField =
                        workFlows.EditableField.chrenTableField;
                      canEdit = true;
                    }
                    if (canEdit) that.formDisable = true; // 表单有限可编辑
                  }

                  // 如果返回流程设值了不可编辑项-workFlows.nonEditableFields
                  if (
                    workFlows.nonEditableFields &&
                    workFlows.nonEditableFields.length > 0
                  ) {
                    let formTagConfig = that.getFormTagConfig(
                      workFlows.nonEditableFields,
                      that.formConfig.hiddenButtons
                    );
                    // 明细表配置
                    if (that.mxTableName && that.mxTableName.length > 0) {
                      if (
                        that.mxOprButtons &&
                        that.mxOprButtons.length > 0 &&
                        formTagConfig.detailHiddenButtons
                      ) {
                        // 如果配置了明细表隐藏按钮
                        // 从明细表对应操作按钮中移除同名的按钮
                        for (let xx = 0; xx < that.mxTableName.length; xx++) {
                          if (
                            formTagConfig.detailHiddenButtons[
                              that.mxTableName[xx]
                            ]
                          ) {
                            let hiddenButtons =
                              formTagConfig.detailHiddenButtons[
                                that.mxTableName[xx]
                              ];
                            hiddenButtons.forEach(function(bName) {
                              let oprButtonIndex = that.mxOprButtons.findIndex(
                                function(b) {
                                  return b.name === bName;
                                }
                              );
                              if (oprButtonIndex >= 0)
                                that.mxOprButtons.splice(oprButtonIndex, 1);
                            });
                          }
                        }
                      }
                      if (formTagConfig.detailReadOnlyFields) {
                        // 如果配置了明细表不可编辑字段
                        that.mxReadOnlyFields =
                          formTagConfig.detailReadOnlyFields;
                      }
                    }
                    // 主表单设置
                    if (
                      formTagConfig.mainReadOnlyFields &&
                      formTagConfig.mainReadOnlyFields.length > 0
                    ) {
                      // 如果设置主表单只读字段
                      for (
                        let yy = 0;
                        yy < formTagConfig.mainReadOnlyFields.length;
                        yy++
                      ) {
                        let controlObject = that.$util.getObjByParamAndValue(
                          that.formConfigs.formRows,
                          "fieldName",
                          formTagConfig.mainReadOnlyFields[yy]
                        );
                        if (controlObject) controlObject.disabled = true;
                        //console.log("需要设置为不可编辑的字段",controlObject);
                      }
                    }
                  }
                }
                // 调用扩展功能，数据加载前调用的回调函数。
                if (
                  that.thisFromEvents &&
                  that.thisFromEvents.beforeRenderData
                ) {
                  that.thisFromEvents.beforeRenderData(
                    that,
                    that.mainTableData
                  );
                }
                that.loadingOk();
                if (that.getMainTempAction !== "GetFormTemplateToWorkFlow") {
                  that.updateEToolbarButtonDisplay(
                    ["tsbWorkFlowRecord", "tsbQRCode"],
                    false
                  );
                } else if (that.fromState == 2 || that.fromState == 4) {
                  // 需要审批操作
                  that.updateEToolbarButtonDisplay([
                    "tsbDoWorkFlow",
                    "tsbWorkFlowRecord"
                  ]);
                  that.updateEToolbarButtonDisplay(
                    ["tsbEdit", "tsbSave", "tsbGridImport"],
                    false
                  );
                } else if (that.fromState != 2) {
                  // 不能编辑或无可操作项 隐藏保存,编辑，明细选人按钮
                  that.updateEToolbarButtonDisplay(
                    ["tsbEdit", "tsbSave", "tsbGridImport"],
                    false
                  );
                  that.updateEToolbarButtonDisplay(["tsbWorkFlowRecord"], true);
                }
              }
            );
          } else that.loadingOk();
        }
      );
    },
    /**
     * 点击“新建”按钮，在tab打开一个新的本表单
     */
    tsbOpenNew() {
      let query = {
        dhbm: this.dhbm,
        templetId: this.tempId
      };
      // 附带信息
      if (this.defaultFiled) {
        query.defaultFiled = this.defaultFiled;
        let defaultFiledValue = this.defaultMainTableFiled[this.defaultFiled];
        if (this.$util.isType(defaultFiledValue) === "Object") {
          query[this.defaultFiled] = defaultFiledValue.value;
          query[this.defaultFiled + "_Name"] = defaultFiledValue.label;
        } else {
          query[this.defaultFiled] = defaultFiledValue;
        }
      }
      this.openTab({
        title: "新增-" + this.title,
        path: "/NewForm",
        query: query
      });
    },
    /**
     * 点击“保存”按钮,注：在复合按钮“保存”中也包含tsbNew按钮参数，操作等同于新建保存
     * @param button
     * @param param
     */
    tsbNew(button, param) {
      if (button && button.param) {
        // id作为扩展查询字段
        if (button.param.extendedField !== "ID") {
          if (this.spFormDataId) {
            this.alertWarning("修改数据请点击“保存”按钮");
            return;
          }
        }
      } else {
        this.alertWarning("修改数据请点击“保存”按钮");
        return;
      }
      let that = this;
      // 先校验申请表的内容是否通过
      this.thisForm.validateFields((err1, values1) => {
        if (!err1) {
          // 再校验修改的表单内容是否满足校验条件
          that.$refs.formTemplate.validateFields((err, values) => {
            //console.log("数据信息：" + JSON.stringify(values));
            if (!err) {
              let saveParam = param;
              if (!saveParam) {
                let tempsaveParam = that.checkOperationAuth(
                  that.buttons,
                  button.tsbName,
                  () => {
                    that.alertWarning("您没有新建本表单的权限");
                  }
                );
                saveParam = tempsaveParam.param;
              }
              if (saveParam) {
                that.allChildrenTablesDatas = that.getMxTableData(); // 保存明细缓存
                let modifyData = that.getFormChanged(
                  values,
                  that.getMxTableData()
                );
                console.log("修改的表单内容：", modifyData);
                if (!that.$util.isValidObject(modifyData)) {
                  that.alertWarning("您没有修改任何表单内容");
                  return;
                }
                modifyData.bm = saveParam.bm; // 需要当前表单操作使用的bm
                // 表单配置的全局校验条件
                if (
                  !that.saveConditionCheck(
                    that.formConfig.saveCondition,
                    values,
                    that.allChildrenTablesDatas
                  )
                ) {
                  that.alertError(that.formConfig.hintCotent);
                  return;
                }
                // 检测明细选人数据
                for (let r = 0; r < that.mxTableName.length; r++) {
                  if (that.judgeCheckCondition(that.mxTableName[r], [])) {
                    that.alertError(
                      "明细选入错误，" +
                        that.gridImportTableConfig.selectCheckCondition
                          .checkError
                    );
                    return;
                  }
                }
                // 保存前检测，明细是否满足非空
                let errMessage = that.checkDetailRequired(
                  that.formConfig.mxTableRequiredConfig,
                  that.allChildrenTablesDatas
                );
                if (errMessage) {
                  that.alertError(errMessage);
                  return;
                }
                that.startProcessing(button);

                values1["FormDataTemplateID"] = that.spFromTempId;
                values1["DataID"] = that.dataId;
                values1["TableName"] = that.mainTableName;
                values1["TableData"] = JSON.stringify(that.tableData);
                values1["ModifiedTableData"] = JSON.stringify(modifyData);
                let params = {
                  et_name: saveParam.et_name, //  表示操作类型  添加为0，修改为1，删除为2 等，从GJLCS中获取 {tsbNew,SYSTEM,1,980}
                  d_id: 0, // 插入之后返回的主键id
                  defalultDataId: that.defaultDataId // 特殊情况下，如果调用新建可能需要传递改参数，用于告诉后台此次d_id使用defalultDataId值
                };
                that.formSaveService(values1, params, button, function(data) {
                  that.spFormDataId = data.dataId;
                  if (that.spFormDataId) {
                    if (
                      data.mainTableData &&
                      that.$util.isType(data.mainTableData) === "Object" &&
                      that.$util.isValidObject(data.mainTableData)
                    ) {
                      values1 = Object.assign({}, values1, data.mainTableData);
                      that.bdbh = data.mainTableData._BDBH
                        ? data.mainTableData._BDBH
                        : data.mainTableData._DBDH;
                      that.oldMainTableObject = values1;
                      that.spMainTableDataID = that.spFormDataId; // 设置当前审批操作使用的dataID
                    }
                    // 显示出二维码打印按钮
                    that.updateEToolbarButtonDisplay("tsbQRCode");
                    // 是否有流程需要走，如果有则显示提交按钮
                    if (data.workFlow && data.workFlow.isWorkFlow) {
                      // 有流程则需要控制流程相关显示
                      let mc = that.oldMainTableObject._BDMC
                        ? that.oldMainTableObject._BDMC
                        : "表单修改申请表";
                      let zt = mc + "_" + that.bdbh;
                      that.updatePageData("tjDefaultData", "_zt", zt);
                      that.updateEToolbarButtonDisplay([
                        "tsbWorkFlowCreater",
                        "tsbForceSave"
                      ]);
                      that.fromState = 1;
                      that.spState = that.getStateName(that.fromState);
                    }
                  } else {
                    // 如果没有返回dataID，则需要隐藏保存相关按钮
                    that.updateEToolbarButtonDisplay(
                      ["tsbSave", "tsbNew", "tsbEdit", "tsbForceSave"],
                      false
                    );
                  }
                  //console.log("请求结果", that.mainTableData);
                  // 调用扩展功能，表单提交成功后调用的函数。
                  if (that.thisFromEvents && that.thisFromEvents.afterSave) {
                    that.thisFromEvents.afterSave(that, values1);
                  }
                });
              }
            } else {
              //console.log( JSON.stringify(err));
              let errMsg = that.getDecoratorErrInfo(err);
              that.tipWarning(errMsg);
            }
          });
        } else {
          //console.log( JSON.stringify(err));
          let errMsg1 = that.getDecoratorErrInfo(err1);
          that.tipWarning(errMsg1);
        }
      });
    },
    /**
     * 对请求错误信息做特殊处理，如果错误信息里包含，需要展示错误明细，则需要调取回调打开错误明细表
     * @param err
     */
    changeErrAlert(err) {
      if (err) {
        let that = this;
        if (err.errorData && err.cols) {
          // 包含错误信息表，在点击提示“知道”后需要弹窗查看
          that.alertError(err.message, function() {
            that.errTableModalShow = true;
            that.updatePageData(
              "errTableConfig",
              "columns",
              that.analyticalColsArray(err.cols, null, null, null, true)
            );
            console.log("错误表格配：", that.errTableConfig);
            setTimeout(function() {
              err.errorData.map(function(data, index) {
                // 给数组对象设置一个主键字段用于表格选择
                return (data[that.errTableConfig.rowKey] = index);
              });
              that.errTableDatas = err.errorData;
              that.errTableSpin = false;
            }, 500);
          });
        } else that.alertError(err.message);
      }
    },
    /**
     * 点击“保存”按钮,注：在复合按钮“保存”中包含tsbEdit按钮参数，操作等同于编辑保存
     */
    tsbEdit(button, param) {
      if (!this.dataId) {
        this.alertWarning("新建表单请点击“新建保存”按钮");
        return;
      }
      let that = this;
      this.thisForm.validateFields((err1, values1) => {
        if (!err1) {
          // 再校验修改的表单内容是否满足校验条件
          that.$refs.formTemplate.validateFields((err, values) => {
            if (!err) {
              //console.log("数据信息：" + JSON.stringify(values));
              let saveParam = param;
              if (!saveParam) {
                let tempsaveParam = that.checkOperationAuth(
                  that.buttons,
                  "tsbEdit",
                  () => {
                    that.alertWarning("您没有保存表单的权限");
                  }
                );
                saveParam = tempsaveParam.param;
              }
              if (saveParam) {
                let modifyData = that.getFormChanged(
                  values,
                  that.getMxTableData()
                );
                console.log("修改的表单内容：", modifyData);
                if (!that.$util.isValidObject(modifyData)) {
                  that.alertWarning("您没有修改任何表单内容");
                  return;
                }
                modifyData.bm = saveParam.bm; // 需要当前表单操作使用的bm
                // 表单配置的全局校验条件
                if (
                  !that.saveConditionCheck(
                    that.formConfig.saveCondition,
                    values,
                    that.getMxTableData()
                  )
                ) {
                  that.alertError(that.formConfig.hintCotent);
                  return;
                }
                // 检测明细选人数据
                for (let r = 0; r < that.mxTableName.length; r++) {
                  if (that.judgeCheckCondition(that.mxTableName[r], [])) {
                    that.alertError(
                      "明细选入错误，" +
                        that.gridImportTableConfig.selectCheckCondition
                          .checkError
                    );
                    return;
                  }
                }
                // 保存前检测，明细是否满足非空
                let errMessage = that.checkDetailRequired(
                  that.formConfig.mxTableRequiredConfig,
                  that.getMxTableData()
                );
                if (errMessage) {
                  that.alertError(errMessage);
                  return;
                }
                that.allChildrenTablesDatas = that.getMxTableData();
                values1["FormDataTemplateID"] = that.spFromTempId;
                values1["DataID"] = that.dataId;
                values1["TableName"] = that.mainTableName;
                values1["TableData"] = JSON.stringify(that.tableData);
                values1.ModifiedTableData = JSON.stringify(modifyData);

                that.startProcessing(button);
                let params = {
                  et_name: saveParam.et_name, //  表示操作类型  添加为0，修改为1，删除为2 等，从GJLCS中获取 {tsbNew,SYSTEM,1,980}
                  d_id: that.spFormDataId // 插入之后返回的主键id
                };

                that.formSaveService(values1, params, button, function(data) {
                  if (data.spFormDataId) {
                    that.oldMainTableObject = values;
                    let mc = that.oldMainTableObject._BDMC
                      ? that.oldMainTableObject._BDMC
                      : that.title;
                    let zt = mc + "_" + that.oldMainTableObject._BDBH;
                    that.updatePageData("tjDefaultData", "_zt", zt);
                  }
                });
              }
            } else {
              //console.log( JSON.stringify(err));
              let errMsg = that.getDecoratorErrInfo(err);
              that.tipWarning(errMsg);
            }
          });
        } else {
          //console.log( JSON.stringify(err));
          let errMsg1 = that.getDecoratorErrInfo(err1);
          that.tipWarning(errMsg1);
        }
      });
    },
    /**
     * 通用的保存数据接口调用方法,
     * 注意，保存的是tempId对应的表单数据
     */
    formSaveService(values, data, button, callBack) {
      let params = {
        action: "SaveFormData",
        logSource: this.logSource,
        tb_name: this.approvalFormMainTableName,
        data: JSON.stringify(values), // 主表数据
        bm: "SYSTEM",
        t_id: this.tempId, // 审批表的模板id
        m_id: this.defaultFiled ? this.defaultFiled : "", // 保存时标识关联字段名
        randomKey: this.$store.getters.getRandomKey
      };
      if (data) {
        params = Object.assign(params, data);
      }
      let that = this;
      // 保存数据的方法
      let doSave = function(params1, button1) {
        that.post("Form.ashx", params1, {
          success: function(req) {
            //console.log("请求结果", req.data.isWorkFlow);
            callBack(req.data);
            that.tipSuccess("保存成功");
            that.finishedProcessing(button1);
          },
          error: function(e) {
            console.log("请求失败", e);
            that.changeErrAlert(e);
            that.finishedProcessing(button1);
          }
        });
      };
      // 判断是否配置了提交前的扩展功能
      this.exeSaveFromEvents(values, params, button, doSave);
    },
    /**
     * 点击“删除”按钮，删除当前表单
     * @param button
     *  @param other
     */
    tsbDelete(button, other) {
      //console.log("删除", button);
      if (this.spFormDataId) {
        if (other && other.prioriCondition) {
          // 满足前置条件还需要确认是否操作
          this.$refs.eToolbar.showConfirm(button.tsbName);
        } else {
          let that = this;
          let params = {
            action: "DeleteData",
            tb_name: that.mainTableName, //主表名
            mxb_name: that.mxTableName.toString(), // 明细表名称
            bm: button.param.bm,
            t_id: this.tempId, // 模板id  从DHCS中获取
            d_id: this.spFormDataId, // 插入之后返回的主键id
            logSource: this.logSource,
            randomKey: this.$store.getters.getRandomKey
          };
          this.startProcessing(button);
          this.post("Form.ashx", params, {
            success: function() {
              //console.log("请求结果", req);
              // 把对应删除的数据从数组中移除
              that.closeTab(that.openType ? that.random : that.dhbm);
              that.tipSuccess("删除成功");
              that.finishedProcessing(button);
            },
            error: function(err) {
              that.alertError(err.message);
              that.finishedProcessing(button);
            }
          });
        }
      }
    },
    /**
     * 强制保存按钮
     * @param button
     */
    tsbForceSave(button, param) {
      this.tsbNew(button, param);
    },
    /**
     * 导出按钮
     */
    tsbExport() {
      // 如果存在对应明细表的映射对象
      this.$refs.formTemplate.tsbExport();
    },
    /**
     * 点击数据导入进入导入视图
     */
    tsbExcelImport() {
      this.$refs.formTemplate.tsbExcelImport();
    },
    /**
     * 确认导出
     */
    ExcelImportOk(tableData) {
      this.$refs.formTemplate.ExcelImportOk(tableData);
    },
    /**
     * 操作按钮点击响应，目前只有编辑视图有“复合功能按钮” 主要是保存场景问题
     * 覆盖了pageControls提供的默认方法
     * @param param
     * @constructor
     */
    onButtonClick(param) {
      let index = -1,
        other;
      if (this.$util.isType(param) === "Object") {
        index = param.index;
        other = param;
      } else {
        index = param;
      }
      if (this.buttons[index]) {
        if (this.$util.isType(this.buttons[index].tsbName) === "String") {
          // 单一功能按钮
          if (this[this.buttons[index].tsbName]) {
            this[this.buttons[index].tsbName](this.buttons[index], other);
          } else {
            this.tipWarning("尚没有" + this.buttons[index].name + "的功能");
          }
        } else if (
          this.$util.isType(this.buttons[index].tsbName) === "Array" &&
          this.$util.isType(this.buttons[index].param) === "Array"
        ) {
          // 复合功能按钮
          this.buttonExtend(this.buttons[index], other);
        } else {
          this.tipWarning("尚没有" + this.buttons[index].name + "的功能");
        }
      }
    },
    /**
     * 按钮功能扩展，特殊按钮
     */
    buttonExtend(button) {
      let tname = null;
      let param = null;
      let index = -1;
      if (
        button.name === "保存" &&
        this.$util.isType(button.tsbName) === "Array"
      ) {
        // 保存功能，复合按钮
        if (!this.spFormDataId) {
          index = button.tsbName.findIndex(function(obj) {
            return obj === "tsbNew";
          });
        } else {
          index = button.tsbName.findIndex(function(obj) {
            return obj === "tsbEdit";
          });
        }
      }
      tname = button.tsbName[index];
      if (tname) {
        param = button.param[index];
        if (this[tname]) {
          this[tname](button, param);
        } else {
          this.tipWarning("尚没有" + button.name + "的功能");
        }
      } else {
        this.tipWarning("尚没有" + button.name + "的功能配置");
      }
    },
    /**
     * 折叠或展开指定表单块
     */
    closeFormTable() {
      this.foldFormTable1 = !this.foldFormTable1;
    },

    /**
     *  获取指定模板
     *  @param sqId
     */
    getApprovalFormTemplate(sqId) {
      this.approvalFormLoading();
      //console.log("getFormTemplate->>>>>>>>",this.tempId);
      let that = this;
      // 根据模板id，从服务器获取模板
      let params = {
        action: "GetFormTemplate",
        t_id: this.tempId,
        randomKey: this.$store.getters.getRandomKey
      };
      this.get("Form.ashx", params, {
        success: function(req) {
          let tempXML = req.data.template[0].MBNR;
          that.approvalFormMainTableName = req.data.template[0].MBZBMC;
          that.spMainTableName = req.data.template[0].MBZBMC; // 设置审批操作使用的主表表名
          that.workflowTemplateInstance =
            req.data.template[0].WorkflowTemplateInstance; // 流程图id
          if (tempXML !== "") {
            let res = that.renderFormTemplate(tempXML, that.oldMainTableObject); // 解析表单模板
            //console.log("默认值",that.mainTableData);
            that.thisColSpan = res.colSpan;
            that.formRows = res.formRows;
            that.thisFormConfig = res.formDefine;
            if (sqId) {
              // 如果传入了申请单ID,则需要去获取申请单数据
              that.getApprovalFormData(sqId);
            } else {
              that.approvalFormLoadingOk();
            }
          }
        },
        error: function(err) {
          console.error(err);
          that.approvalFormLoadingError();
          that.alertError("请求失败，获取模板失败");
        }
      });
    },
    /**
     * 获取审批单ID对应的数据
     */
    getApprovalFormData(sqId) {
      let that = this;
      if (this.tempId && sqId) {
        let params = {
          action: "GetTemplateData",
          d_id: sqId,
          tb_name: this.approvalFormMainTableName, //主表名
          bm: "SYSTEM",
          t_id: this.tempId,
          et_name: "900",
          m_id: 0,
          randomKey: this.$store.getters.getRandomKey
        };
        this.get("Form.ashx", params, {
          success: function(req) {
            //console.log("获取到表单数据：" , req);
            // 初始化化表单数据
            if (req.data.mainTableData) {
              that.oldMainTableObject = Object.assign(
                {},
                that.oldMainTableObject,
                req.data.mainTableData
              );
            }
            if (req.data.workFlow) {
              if (req.data.workFlow.IsFormWorkflowEnd) {
                // 表单流程是否结束
                that.isFormWorkflowEnd = true;
              }
              let workFlows = req.data.workFlow;
              //console.log("获取到表单操作状态：" , workFlows);
              if (!that.isDelete && workFlows) {
                that.currentWorkflowName = workFlows.CurrentWorkflowName;
                that.spButtons = that.getSpButtons(workFlows.operation);
                that.bdbh = that.oldMainTableObject._BDBH;
                if (workFlows.workFlowState !== "") {
                  that.fromState = that.showStateNameFrom.indexOf(
                    workFlows.workFlowState
                  );
                  if (that.fromState >= 1)
                    that.spState = that.getStateName(that.fromState);
                }
                if (that.fromState == 1) {
                  // 根据表单流程状态-切换表格编辑状态
                  // 草稿状态
                  that.formEditState = false;
                  that.formDisable = false; // 表单可编辑
                  //that.updateEToolbarButtonDisplay(["tsbEdit", "tsbSave", "tsbGridImport"]);
                } else {
                  // 进入流程
                  // 非草稿状态
                  if (that.fromState == 2 || that.fromState == 4) {
                    that.formEditState = false; //表单可编辑
                    if (that.fromState == 2) {
                      that.formDisable = false; //未提交 表单可编辑
                      that.beforeSpOptNeedSave = true; // 如果直接审批，需要先保存
                    } else {
                      // 待处理 有限可编辑
                      that.formDisable = true;
                      that.formEditState = true;
                    }
                  } else if (that.fromState == 3 || that.fromState == 5) {
                    // 已提交和已处理
                    that.formEditState = true; //表单不可编辑
                  } else {
                    that.formEditState = true; //表单不可编辑
                  }
                }
              }
            }
            console.log("合并后的主表数据", that.oldMainTableObject);
            if (
              that.oldMainTableObject.FormDataTemplateID &&
              that.oldMainTableObject.DataID &&
              that.oldMainTableObject.TableName
            ) {
              that.spFromTempId = that.oldMainTableObject.FormDataTemplateID;
              that.dataId = that.oldMainTableObject.DataID;
              that.mainTableName = that.oldMainTableObject.TableName;
              if (that.oldMainTableObject.ModifiedTableData) {
                that.modifiedDatas = JSON.parse(
                  that.oldMainTableObject.ModifiedTableData
                );
              }
            }
            // 调用扩展功能，数据加载前调用的回调函数。
            if (that.thisFromEvents && that.thisFromEvents.beforeRenderData) {
              that.thisFromEvents.beforeRenderData(that, that.mainTableData);
            } else that.approvalFormLoadingOk();
            that.getFormTemplate(); // 获取that.dataId关联的数据
          },
          error: function(err) {
            console.log(err);
            that.approvalFormLoadingError();
            that.tipWarning("请求失败，获取表单数据失败");
          }
        });
      } else {
        this.approvalFormLoadingError();
      }
    },

    /**
     * 显示加载中
     */
    approvalFormLoading() {
      this.showFormSpin = true;
      this.showForm = false;
      this.showFormError = false;
    },
    /**
     * 加载成功
     */
    approvalFormLoadingOk() {
      this.showForm = true;
      this.showFormSpin = false;
    },
    /**
     * 加载失败
     */
    approvalFormLoadingError() {
      this.showFormSpin = false;
      this.showFormError = true;
    },
    /**
     * 监听表单滚动条，如果滚动做一些操作
     */
    formScroll() {
      this.$refs.formTemplate.filterSelectBlur();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/data-modify-approval-form.less";
</style>
