<!-- 新增数据表单页 -->
<template>
  <div>
    <!-- 授权操作按钮-->
    <div v-if="showToolbar" class="form-headButton">
      <e-toolbar
        :buttons="buttons"
        :operationDisabled="operationDisabled"
        @click="onButtonClick"
        :size="$config.defaultControlSize"
        ref="eToolbar"
        :formConfigs="formConfig"
      ></e-toolbar>
    </div>
    <!-- 表单区域 -->
    <div
      :class="getTabFormContextClass"
      @scroll="formScroll"
      :style="isZoom ? { 'padding-top': '0' } : {}"
    >
      <a-form
        :form="form"
        :class="showToolbar ? 'form-data' : 'form-data-noHead'"
      >
        <e-form
          :form="form"
          :tempId="tempId"
          :dataId="dataId"
          :disable="formDisable"
          :editOver="formEditState"
          :formConfigs="formConfigs"
          :title="title"
          :tableData="mainTableData"
          :mxOprButtons="mxOprButtons"
          :fieldsAllowedEdit="fieldsAllowedEdit"
          :mxAllowedEditField="mxAllowedEditField"
          :mxReadOnlyFields="mxReadOnlyFields"
          :hideFields="hideField"
          :spState="formTip"
          :mxHhAuto="mxChangeHh"
          ref="formTemplate"
          @formExtendEvent="onFormExtendEvent"
          @zoom="zoomState => (isZoom = zoomState)"
          @publicUploadChange="publicUploadChange"
        ></e-form>
      </a-form>
    </div>
    <!-- 其他功能区域 -->

    <!-- 审批意见确认窗 <a-icon type="form"></a-icon>-->
    <e-form-modal
      v-model="spModalShow"
      :buttons="spButtons"
      formName="审批意见表"
      @buttonClicked="doFormSp"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">表单审批</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 提交审批确认窗 -->
    <e-form-modal
      v-model="tjModalShow"
      formName="提交审批"
      :initFormData="tjDefaultData"
      :showOk="true"
      @okClicked="doFormTj($event, tsbNewData)"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">提交审批</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 审批记录弹出框 -->
    <e-table-modal
      v-model="spjlModalShow"
      :tableConfig="spTableConfig"
      title="审批记录"
      width="900"
      :tableDatas="spTableDatas"
      :loading="spTableSpin"
    ></e-table-modal>
    <!-- 查看审批流程图 -->
    <view-image-modal
      v-model="showWorkFlowImage"
      :src="workflowImageSrc"
      title="流程图"
    ></view-image-modal>
    <!-- 明细数据选人弹出框 :redraw="true"-->
    <e-table-modal
      v-if="
          gridImportTableConfig &&
          gridImportTableConfig.columns
      "
      v-model="gridImportModalShow"
      :tableConfig="gridImportTableConfig"
      title="明细选入搜索"
      width="950"
      :tableDatas="gridImportTableDatas"
      :buttons="gridImportModalButtons"
      :loading="gridImportModalLading"
      redraw
      :selectedKeys="gridImportTableSelectedKeys"
      @buttonClicked="onClickTableModal"
    ></e-table-modal>

    <!-- 明细数据选人弹出框"筛选"条件选择窗 v-if="gridImportSelectModalShow"-->
    <e-form-modal
      v-model="gridImportSelectModalShow"
      :tempId="gridImportSelectTempId"
      :showOk="true"
      @okClicked="onGridImportSelected"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">筛选条件</span>
        </div>
      </template>
    </e-form-modal>

    <!-- 提示错误信息明细 -->
    <e-table-modal
      v-model="errTableModalShow"
      :tableConfig="errTableConfig"
      title="错误提示信息"
      :tableDatas="errTableDatas"
      :loading="errTableSpin"
      :needCreateFrom="errTableInfoCreateFrom"
      :formTitle="errToCreateFormName"
      needExport
      needPrint
    ></e-table-modal>
    <!-- 导入明细表控件 -->
    <!-- <ImportExcelDataModal
                  @ExcelImportOk="ExcelImportOk"
                  ref="importExcel"
    ></ImportExcelDataModal>-->

    <!-- 系统打印控件 -->
    <systemPrintSet ref="systemPrintSet"></systemPrintSet>
    <!-- 小票打印预览 -->
    <n-p58-print
      v-model="showNpPrint"
      :dataSource="printDetailData"
    ></n-p58-print>

    <!-- 明细数据单元格-扩展点击弹出列表展示 对应的属性和方法在e1DetailColumnExtend.js-->
    <e-table-modal
      v-if="detailColumnExt_tableModalInit"
      v-model="detailColumnExt_tableModalShow"
      :tableConfig="detailColumnExt_tableConfig"
      :title="detailColumnExt_title"
      width="950"
      :tableDatas="detailColumnExt_tableDatas"
      :buttons="detailColumnExt_buttons"
      :loading="detailColumnExt_loading"
      @buttonClicked="onClickDetailColumnExtButton"
      @onSelectedChange="onSelectedChangeDetailColumnExtButton"
    ></e-table-modal>
    <!-- 明细数据单元格-扩展点击弹出表单展示 对应的属性和方法在e1DetailColumnExtend.js v-if="detailColumnExt_formModalInit" -->
    <e-form-modal
      v-model="detailColumnExt_formModalShow"
      :tempId="detailColumnExt_formTempId"
      :initFormData="detailColumnExt_formData"
      :showOk="true"
      @okClicked="onClickDetailColumnExtOk"
    >
      <template slot="title">
        <div class="e-model-head">
          <span class="e-model-title">{{ detailColumnExt_fromTitle }}</span>
        </div>
      </template>
    </e-form-modal>
    <!-- 公共上传控件 -->
    <e-upload
      ref="publicUpload"
      @change="publicUploadChangeValue"
      @fileListNumChange="publicUploadNumChange"
      @CanSealFile="CanSealFile"
      v-show="false"
      :auth="
        operaterFile
          ? operaterFile
          : { CanEditFile: true, CanRemoveFile: true, CanSealFile: true }
      "
    ></e-upload>
    <!-- 入库单-物资序列号配置管理弹窗 -->
    <e-configure-product-serial-modal
      v-model="serialConfigModal"
      redraw
      :products="serialConfigProducts"
      @okClicked="onSerialConfig"
    ></e-configure-product-serial-modal>
  </div>
</template>

<script>
import EForm from "@/components/EForm.vue";
import { e1Form } from "@/views/form/mixins/e1Form.js"; // 表单公共方法
import { e1FormToolbarWorkFlow } from "@/views/form/mixins/e1FormToolbarWorkFlow.js"; // e1的审批操作方法
import { e1FormToolbarGridImport } from "@/views/form/mixins/e1FormToolbarGridImport.js"; // e1的明细选人操作方法
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; // 解析菜单配置的权限字符串方法
import { analyticalPrint } from "@/mixins/analyticalPrint.js"; // 打印数据计算方法
import { npPrint } from "@/views/form/mixins/NpPrint.js"; // 下票打印方法
import { CustomPrintMixins } from "@/views/form/mixins/CustomPrintMixins.js"; // 按钮方法
import { e1DetailColumnExtend } from "@/views/form/mixins/e1DetailColumnExtend.js"; // 扩展明细表单元格内容相关功能
import { e1FormToolbarSCGL } from "@/views/form/mixins/e1FormToolbarSCGL.js"; // e1的业务-生产管理相关操作功能
import { e1FormSaveErrorHandle } from "@/views/form/mixins/e1FormSaveErrorHandle.js"; // e1的业务-表单保存返回错误信息，如果包含其他需要特殊处理的数据，则需要特殊处理
import { e1ConfigureProductSerial } from "@/views/form/mixins/e1ConfigureProductSerial.js"; // e1的业务-入库单序列号管理配置功能
import { Upload } from "@/views/form/mixins/Upload.js"; // 公共上传mixins

export default {
  mixins: [
    e1Form,
    analyticalMenuAuth,
    analyticalPrint,
    e1FormToolbarWorkFlow,
    e1FormToolbarGridImport,
    npPrint,
    CustomPrintMixins,
    e1DetailColumnExtend,
    e1FormToolbarSCGL,
    e1FormSaveErrorHandle,
    e1ConfigureProductSerial,
    Upload
  ],
  name: "NewForm",
  components: {
    EForm
  },
  data() {
    return {
      formName: null, // 对应的表单名称
      openType: 0, // 打开本表单的方式，0：使用通过params传参打开，1:使用query传参打开
      dataFromType: null, // 表单数据来源，默认根据dataId获取，默认为空，有效值：“dataFromCache”、“dateSearchView”、“dateManageView”、“initFormData”

      printConfig: null, // 系统打印的参数
      random: null, // 对于从非菜单栏打开的本页面可以使用random作为tab唯一标识符
      dhbm: null, // 当前开页面关联权限参数的id

      bdbh: null, // 保存后的表单编号
      dataId: 0, //对应的表单id
      saveTempID: null, // 特殊表单，有时会指定保存使用的模板id
      is_temp_changed: false,// 特殊表单用于表示该表单的模板变跟状态
      bm: null,
      businessModel: null, // 用于模板加接口，获取表单模板默认数据的参数1
      defaultDataId: null, // 用于模板加接口，获取表单模板默认数据的参数2
      etName: null, // 当前表单的etName参数

      buttons: [], // 当前用户可用的操作按钮
      defaultFiled: "",
      defaultMainTableFiled: null,
      thisConfig: null,

      formConfig: null, // 保存表单模板解析结果的容器配置数据
      mxOprButtons: [], // 明细表可做操作

      fieldsAllowedEdit: null, // 可编辑的表单项，在formDisable=true时可用
      mxAllowedEditField: null, // 可编辑的明细表单项，在formDisable=true时可用
      mxReadOnlyFields: null, // 需要设置为只读的明细表字段
      requiredField: null, // 可编辑的表单项包含必填项，包含主表单和明细表
      hideField: null, // 需要隐藏的字段，包含主表单和明细表的设置
      logSource: 0, // 编辑表单日志记录来源类型，0 表单新建或审批过程中进行的数据修改， 1 表单查询页面中使用编辑功能进行的数据修改， 2 通过表单数据修改审批表进行的数据修改
      thisChildrenTablesDatas: null,

      isZoom: false, // 明细表进入最大化状态
      formTip: null // 表单提示信息
    };
  },
  created() {
    //console.log("路由属性：" + JSON.stringify(this.$route.params)); // 从菜单直接带过来
    console.log("newForm创建---------------------->");
    this.tempId = this.$route.query.templetId;
    this.random = this.$route.query.random;
    if (!this.tempId) {
      // 从菜单点入，通过params传参
      this.openType = 0;
      this.formName = this.$route.params.formName;
      this.dhbm = this.$route.params.dhbm;
      this.dataId = this.$route.params.dataId;
      if (this.dhbm) {
        let viewConfig = this.getPageConfig(this.dhbm); // 根据菜单编号获取页面配置
        console.log("视图定义", viewConfig);
        if (viewConfig) {
          this.thisConfig = viewConfig;
          if (viewConfig.defaultDataId) {
            this.defaultDataId = viewConfig.defaultDataId;
            this.businessModel = viewConfig.defaultBm;
          }
          if (
            viewConfig.templatId &&
            (this.$util.isType(viewConfig.templatId) === "String" ||
              this.$util.isType(viewConfig.templatId) === "val")
          ) {
            // 如果包含模板ID
            this.tempId = viewConfig.templatId;
          }
        }
      }
    } else {
      // 页面内按钮点开、是通过query传参
      this.openType = 1;
      this.dataId = this.$route.query.dataId;
      this.dhbm = this.$route.query.dhbm;
      this.dataFromType = this.$route.query.type; //
      // 附加主表单默认数据的默认字段名称，有对应的this.defaultFiled+"_Name"参数将其值传入
      this.defaultFiled = this.$route.query.defaultFiled;
      // 如果参数中携带了默认字段值，则给主表赋予对应字段对应默认值
      this.defaultMainTableFiled = this.getDefaultFiledForQuery(
        this.defaultFiled
      );
      // 用于模板加接口，获取表单模板默认数据的参数
      this.businessModel = this.$route.query.businessModel;
      this.defaultDataId = this.$route.query.defaultDataId;
      if (this.dataFromType) {
        if (this.dataFromType === "dateSearchView") this.logSource = 1;
        else if (this.dataFromType === "dateManageView") {
          // 对应数据管理功能，特定方法功能
          let tempState = this.$route.query.state;
          if (tempState && tempState != "") {
            this.formTip = "（" + tempState + "）"; // 将传入的状态显示到标题后
            this.spState = tempState;
          }
        }
      }
    }
    if (!this.dataId) {
      this.formName = "add_" + this.formName + "_" + this.random;
    } else {
      this.formName =
        "update-" +
        (this.dataFromType ? this.dataFromType + "_" : "") +
        this.random;
    }
    this.form = this.$form.createForm(this, { name: this.formName });
  },
  mounted() {
    //console.log("请求参数：" + JSON.stringify(this.$route.query));
    //console.log("创建表单："+this.$route.params.dhbm);
    console.log("newForm mounted---------------------->", this.dataId);
    this.loading();
    if (this.tempId) {
      if (this.dataId || this.bdbh) {
        // 初始化如果有数据id,说明是编码模式，已保存的行号不能修改
        this.mxChangeHh = false;
      } else {
        // 新建页面可见范围不生效，编辑状态根据流程状态确定是否可见
        this.visScopeTakeEffectMiXin = false;
      }
      this.getFormTemplate();
    } else {
      this.alertError("错误的用户配置");
      this.loadingError();
    }
  },
  methods: {
    /**
     * 获取表单模板
     */
    getFormTemplate() {
      let that = this;
      let tempMxData = null;
      let tempMainData = null;
      // 注意使用只有“GetFormTemplate”接口的d_id传的是参数dhbm值
      this.getForm(
        "GetFormTemplate",
        this.tempId,
        {
          dmbh: this.dhbm,
          id: this.dataId,
          type: this.dataFromType,
          businessModel: this.businessModel,
          defalultDataId: this.defaultDataId
        },
        function(data) {
          // 获取表单对应的操作按钮
          that.buttons = data.buttons;
          that.mxOprButtons = data.rowButtons; // 明细表可做操作按钮
          if (data.tansferTemp) {
            // 如果有弹出报错明细，可以直接生成对应的表单
            that.errTableInfoCreateFrom = data.tansferTemp.tansferTemplateId;
            that.errToCreateFormName = data.tansferTemp.tansferTemplateName;
          }
          that.printConfig = data.template[0].MBNR;
          that.workflowTemplateInstance =
            data.template[0].WorkflowTemplateInstance; // 流程图id
          if (that.dataId) {
            // 如果需要获取数据，需要获得getData参数
            let saveParam = that.checkOperationAuth(
              that.buttons,
              "getData",
              () => {
                that.alertWarning("您没有查询本表单数据的权限");
              }
            );
            if (saveParam) {
              that.bm = saveParam.bm;
              that.etName = saveParam.et_name;
            }
          } else if (that.buttons && that.buttons.length > 0) {
            // 新建表单的情况下，如果包含已经显示的打印二维码按钮，则需要先隐藏起来
            let tsbQRCodeIndex = that.buttons.findIndex(function(b) {
              return b.tsbName === "tsbQRCode";
            });
            if (tsbQRCodeIndex >= 0) {
              that.buttons[tsbQRCodeIndex].isShow = false;
            }
            if (data.formData) {
              // 如果返回了，初始化表单数据
              that.dataFromType = "initFormData"; // 返回了初始化表单数据
              if (data.formData.mainTable) {
                tempMainData = data.formData.mainTable;
              }
              //console.log("从后台接口中获取数据后合并到表单数据：", that.mainTableData);
              if (data.formData.childrenTable) {
                tempMxData = data.formData.childrenTable;
              }
            }
          }
        },
        function() {
          // 调用扩展功能，数据加载前调用的回调函数。beforeRenderView
          if (that.thisFromEvents && that.thisFromEvents.afterInit) {
            that.thisFromEvents.afterInit(that);
          }
          if (that.dataId) {
            that.getFormData(
              that.tempId,
              that.dataId,
              that.bm,
              that.etName,
              function(data) {
                // 如果是从查询视图进来的编辑，这是特殊表单处理，所以不需要考虑流程状态
                if (that.dataFromType !== "dateSearchView") {
                  if (that.dataFromType === "dateManageView") {
                    // 是表单数据管理视图目前特定出现在（生产管理工作台、工单排产工作台）,不能出现的一些按钮要隐藏
                    that.updateEToolbarButtonDisplay(
                      ["tsbOpenNew", "tsbDelete"],
                      false
                    );
                  }
                  let workFlows = data.workFlow;
                  if (workFlows) {
                    if (workFlows.workFlowState !== "") {
                      that.fromState = that.showStateNameFrom.indexOf(
                        workFlows.workFlowState
                      );
                      if (!that.spState && that.fromState >= 1)
                        that.spState = that.getStateName(that.fromState);
                    }

                    //如果设置了隐藏字段
                    if (
                      workFlows.HideField &&
                      that.isFormWorkflowEnd == false
                    ) {
                      that.hideField = workFlows.HideField;
                    }
                    // 获取表单可编辑项目
                    if (workFlows.EditableField) {
                      let canEdit = false;
                      if (
                        workFlows.EditableField.mainTableField &&
                        workFlows.EditableField.mainTableField.length > 0
                      ) {
                        that.fieldsAllowedEdit =
                          workFlows.EditableField.mainTableField;
                        //console.log("明细有可以编辑的字段：",workFlows.field.chrenTableField);
                        canEdit = true;
                      }
                      console.log(
                        "明细有可以编辑的字段：",
                        workFlows.EditableField.chrenTableField
                      );
                      if (
                        workFlows.EditableField.chrenTableField &&
                        that.$util.isValidObject(
                          workFlows.EditableField.chrenTableField
                        )
                      ) {
                        that.mxAllowedEditField =
                          workFlows.EditableField.chrenTableField;
                        canEdit = true;
                      }
                      if (canEdit) that.formDisable = true; // 表单有限可编辑
                    }

                    // 如果返回流程设值了不可编辑项-workFlows.nonEditableFields
                    if (
                      workFlows.nonEditableFields &&
                      workFlows.nonEditableFields.length > 0
                    ) {
                      let formTagConfig = that.getFormTagConfig(
                        workFlows.nonEditableFields,
                        that.formConfig.hiddenButtons
                      );
                      // 明细表配置
                      if (that.mxTableName && that.mxTableName.length > 0) {
                        if (
                          that.mxOprButtons &&
                          that.mxOprButtons.length > 0 &&
                          formTagConfig.detailHiddenButtons
                        ) {
                          // 如果配置了明细表隐藏按钮
                          // 从明细表对应操作按钮中移除同名的按钮
                          for (let xx = 0; xx < that.mxTableName.length; xx++) {
                            if (
                              formTagConfig.detailHiddenButtons[
                                that.mxTableName[xx]
                              ]
                            ) {
                              let hiddenButtons =
                                formTagConfig.detailHiddenButtons[
                                  that.mxTableName[xx]
                                ];
                              hiddenButtons.forEach(function(bName) {
                                let oprButtonIndex = that.mxOprButtons.findIndex(
                                  function(b) {
                                    return b.name === bName;
                                  }
                                );
                                if (oprButtonIndex >= 0)
                                  that.mxOprButtons.splice(oprButtonIndex, 1);
                              });
                            }
                          }
                        }
                        if (formTagConfig.detailReadOnlyFields) {
                          // 如果配置了明细表不可编辑字段
                          that.mxReadOnlyFields =
                            formTagConfig.detailReadOnlyFields;
                        }
                      }
                      // 主表单设置
                      if (
                        formTagConfig.mainReadOnlyFields &&
                        formTagConfig.mainReadOnlyFields.length > 0
                      ) {
                        // 如果设置主表单只读字段
                        for (
                          let yy = 0;
                          yy < formTagConfig.mainReadOnlyFields.length;
                          yy++
                        ) {
                          let controlObject = that.$util.getObjByParamAndValue(
                            that.formConfigs.formRows,
                            "fieldName",
                            formTagConfig.mainReadOnlyFields[yy]
                          );
                          if (controlObject) controlObject.disabled = true;
                          //console.log("需要设置为不可编辑的字段",controlObject);
                        }
                      }
                    }
                  }
                }
                if (!that.bdbh) that.bdbh = that.mainTableData["_BDBH"];
                // 调用扩展功能，数据加载前调用的回调函数。beforeRenderView

                if (!that.isFormWorkflowEnd) {
                  // 流程未结束,去检测控件的可见范围是否有效
                  that.checkMainVisScopeTakeEffect(that.formConfigs.formRows);
                  that.checkMxVisScopeTakeEffect(that.formConfigs);
                } else {
                  // 流程结束
                  if (that.dataFromType === "dateManageView") {
                    // 是表单数据管理视图,不能出现的一些按钮要隐藏
                    that.formEditState = true;
                    // 不能编辑或无可操作项 隐藏保存,编辑，明细选人按钮
                    that.updateEToolbarButtonDisplay(
                      ["tsbEdit", "tsbSave", "tsbGridImport"],
                      false
                    );
                    that.mxOprButtons = []; // 明细表的操作按钮应该隐藏
                  }
                }

                if (
                  that.thisFromEvents &&
                  that.thisFromEvents.beforeRenderData
                ) {
                  that.thisFromEvents.beforeRenderData(
                    that,
                    that.mainTableData
                  );
                }
                that.loadingOk();
              }
            );
          } else if (that.dataFromType === "dataFromCache") {
            // 表单数据来源于本地缓存
            let cacheData = that.$store.state.cacheData;
            if (cacheData) {
              that.mainTableData = Object.assign(
                {},
                that.mainTableData,
                cacheData.mainTableData
              );
              console.log("从缓存中获取到后合并表单数据：", that.mainTableData);
              if (
                that.mxTableName.length > 0 &&
                that.$util.isType(cacheData.childrenDatas) === "Array" &&
                cacheData.childrenDatas.length > 0
              ) {
                that.$nextTick(function() {
                  // 初始化化明细表数据
                  let data = cacheData.childrenDatas;
                  if (data[0].tableName) {
                    // 如果已经是 [{tableName: "" , datas: [{data..}]}]结构
                    that.setMxTableData(cacheData.childrenDatas);
                  } else {
                    let detailTableName = that.mxTableName[0];
                    let tempData = { tableName: detailTableName };
                    tempData.datas = cacheData.childrenDatas;
                    that.setMxTableData([tempData]);
                  }
                });
              }
              // 调用扩展功能，数据加载前调用的回调函数。
              if (that.thisFromEvents && that.thisFromEvents.beforeRenderData) {
                that.thisFromEvents.beforeRenderData(that, that.mainTableData);
              }
              that.$store.commit("clearCacheData");
            }
            that.loadingOk();
          } else if (that.dataFromType === "initFormData") {
            // 有查询模板时返回的初始化表单数据
            if (tempMainData) {
              that.mainTableData = Object.assign(
                {},
                that.mainTableData,
                tempMainData
              );
            }
            if (
              that.mxTableName.length > 0 &&
              tempMxData &&
              tempMxData.length > 0
            ) {
              that.$nextTick(function() {
                // 初始化化明细表数据
                let detailTableName1 = that.mxTableName[0];
                let tempData = { tableName: detailTableName1 };
                tempData.datas = tempMxData;
                that.setMxTableData([tempData]);
              });
            }
            // 调用扩展功能，数据加载前调用的回调函数。
            if (that.thisFromEvents && that.thisFromEvents.beforeRenderData) {
              that.thisFromEvents.beforeRenderData(that, that.mainTableData);
            }
            that.loadingOk();
          } else {
            // 调用扩展功能，数据加载前调用的回调函数。
            if (that.thisFromEvents && that.thisFromEvents.beforeRenderData) {
              that.thisFromEvents.beforeRenderData(that, that.mainTableData);
            }
            that.loadingOk();
          }
        }
      );
    },
    /**
     * 点击“新建”按钮，在tab打开一个新的本表单
     */
    tsbOpenNew() {
      let query = {
        dhbm: this.dhbm,
        templetId: this.tempId
      };
      // 附带信息
      if (this.defaultFiled) {
        query.defaultFiled = this.defaultFiled;
        let defaultFiledValue = this.defaultMainTableFiled[this.defaultFiled];
        if (this.$util.isType(defaultFiledValue) === "Object") {
          query[this.defaultFiled] = defaultFiledValue.value;
          query[this.defaultFiled + "_Name"] = defaultFiledValue.label;
        } else {
          query[this.defaultFiled] = defaultFiledValue;
        }
      }
      this.openTab({
        title: "新增-" + this.title,
        path: "/NewForm",
        query: query
      });
    },
    /**
     * 点击“保存”按钮,注：在复合按钮“保存”中也包含tsbNew按钮参数，操作等同于新建保存
     * @param button
     * @param param
     */
    tsbNew(button, param) {
      //如果是保存和提交合并了，保存提交成功后需要跳转到原稿待处理的表单中
      //dhbm=276&templetId=134&dataId=15093&bm=SYSTEM&clzt=原稿&random=1600252880099_0.0182102996045741
      if(this.$config.fromApprovalMode === "merge_submit" && this.fromState<3) {
        let that = this;
        this.$refs.formTemplate.validateFields((err, values) => {
          //console.log("数据信息：" + JSON.stringify(values));
          if (!err) {
            let mc = values._BDMC
              ? values._BDMC
              : that.title;
            that.updatePageData("tjDefaultData", "_zt", mc);
            that.tjModalShow = true; // 弹出提交审批框
            that.fromState = 1;
            that.tsbNewData = {
              button: button,
              param: param,
              next: "newNextFun"
            };
          } else {
            //console.log( JSON.stringify(err));
            let errMsg = that.getDecoratorErrInfo(err);
            that.tipWarning(errMsg);
          }
        });
      } else { // 否则正常保存
        this.newNextFun(button, param);
      }
    },
    /**
     * 点击“保存”按钮, 之后的操作
     * @param button
     * @param param
     */
    newNextFun(button, param) {
      console.log(
        "拿到了明细设置的其他数据---------------》",
        button, param
      );
      if (button && button.param) {
        // id作为扩展查询字段
        if (button.param.extendedField !== "ID") {
          if (this.dataId) {
            this.alertWarning("修改数据请点击“保存”按钮");
            return;
          }
        }
      } else {
        this.alertWarning("修改数据请点击“保存”按钮");
        return;
      }
      let that = this;
      let needDoWorkProcess = false;
      this.$refs.formTemplate.validateFields((err, values) => {
        //console.log("数据信息：" + JSON.stringify(values));
        if (!err) {
          let saveParam = param;
          if (!saveParam) {
            let tempsaveParam = that.checkOperationAuth(
              that.buttons,
              button.tsbName,
              () => {
                that.alertWarning("您没有新建本表单的权限");
              }
            );
            saveParam = tempsaveParam.param;
          }
          if (saveParam) {
            that.allChildrenTablesDatas = that.getMxTableData(); // 保存明细缓存
            // 表单配置的全局校验条件
            if (
              !that.saveConditionCheck(
                that.formConfig.saveCondition,
                values,
                that.allChildrenTablesDatas
              )
            ) {
              that.alertError(that.formConfig.hintCotent);
              return;
            }
            // 检测明细选人数据
            for (let r = 0; r < that.mxTableName.length; r++) {
              if (that.judgeCheckCondition(that.mxTableName[r], [])) {
                that.alertError(
                  "明细选入错误，" +
                    that.gridImportTableConfig.selectCheckCondition.checkError
                );
                return;
              }
            }
            // 保存前检测，明细是否满足非空
            let errMessage = that.checkDetailRequired(
              that.formConfig.mxTableRequiredConfig,
              that.allChildrenTablesDatas
            );
            if (errMessage) {
              that.alertError(errMessage);
              return;
            }
            that.startProcessing(button);

            let params = {
              bm: saveParam.bm, // 类库名称（SYSTEM）、从GJLCS中获取 {tsbNew,SYSTEM,1,980}
              et_name: saveParam.et_name, //  表示操作类型  添加为0，修改为1，删除为2 等，从GJLCS中获取 {tsbNew,SYSTEM,1,980}
              d_id: 0, // 插入之后返回的主键id
              defalultDataId: this.defaultDataId // 特殊情况下，如果调用新建可能需要传递该参数，用于告诉后台此次d_id使用defalultDataId值
            };
            // 保存和提交合并的模式
            if(this.$config.fromApprovalMode === "merge_submit") {
              params.f_Subject = saveParam.f_Subject;
            }
            that.formSaveService(values, params, button, function(data) {
              that.dataId = data.dataId;
              if (that.dataId) {
                if (
                  data.mainTableData &&
                  that.$util.isType(data.mainTableData) === "Object" &&
                  that.$util.isValidObject(data.mainTableData)
                ) {
                  values = Object.assign({}, values, data.mainTableData);
                  that.bdbh = data.mainTableData._BDBH;
                  that.mainTableData = values;
                } else {
                  that.mainTableData.ID = that.dataId;
                  values.ID = that.dataId;
                }
                // 显示出二维码打印按钮
                /*that.updateEToolbarButtonDisplay(
                  "tsbQRCode"
                );*/
                // 是否有流程需要走，如果有则显示提交按钮
                if (data.workFlow && data.workFlow.isWorkFlow) {
                  needDoWorkProcess = true;
                  if(that.$config.fromApprovalMode !== "merge_submit") {
                    // 有流程则需要控制流程相关显示
                    let mc = that.mainTableData._BDMC
                      ? that.mainTableData._BDMC
                      : that.title;
                    let zt = mc + "_" + that.bdbh;
                    that.updatePageData("tjDefaultData", "_zt", zt);
                    that.updateEToolbarButtonDisplay([
                      "tsbWorkFlowCreater",
                      "tsbForceSave"
                    ]);
                    that.fromState = 1;
                    that.spState = that.getStateName(that.fromState);
                  } else { // 保存和提交一同执行了
                    that.fromState = 3;
                    that.spState = that.getStateName(that.fromState);
                  }
                }
              } else {
                // 如果没有返回dataID，则需要隐藏保存相关按钮
                that.updateEToolbarButtonDisplay(
                  ["tsbSave", "tsbNew", "tsbEdit", "tsbForceSave"],
                  false
                );
              }
              // 返回了明细数据,需要回填
              if (data.childrenDatas && data.childrenDatas.length > 0) {
                that.setMxTableData(data.childrenDatas);
                that.allChildrenTablesDatas = data.childrenDatas;
              }

              //console.log("请求结果", that.mainTableData);
              // 调用扩展功能，表单提交成功后调用的函数。
              if (that.thisFromEvents && that.thisFromEvents.afterSave) {
                that.thisFromEvents.afterSave(that, values, button);
              } else {
                that.tipSuccess("保存成功");
                // 保存成功页面重载 wangxin add 20200817
                that.createDataOkToReload(needDoWorkProcess);
                that.finishedProcessing(button);
              }
            });
          }
        } else {
          //console.log( JSON.stringify(err));
          let errMsg = that.getDecoratorErrInfo(err);
          that.tipWarning(errMsg);
        }
      });
    },
    /**
     * wangxin add 20200817
     * 新建表单成功后需要重载页面，根据情况页面重载的地址需要调整
     * @param {Boolean} needDoWorkProcess
     */
    createDataOkToReload(needDoWorkProcess) {
      // 如果保存成功后该表单不需要做事审批处理，则直接重载
      if (
        this.dataId &&
        !needDoWorkProcess
        //&& this.dataFromType !== "initFormData"
      ) {
        //带人数据id重载页面
        let query = Object.assign({}, this.$route.query);
        if(this.thisConfig && this.thisConfig.defaultDataId) {
          query.templetId = this.tempId;
          query.businessModel = this.businessModel;
          query.dhbm = this.dhbm;
          query.defaultDataId = this.thisConfig.defaultDataId;
        } else {
          query.dataId = this.dataId;
        }
        delete query.random;
        this.redirect("/NewForm", query);
      } else if (needDoWorkProcess) {
        // 需要审批操作,则直接跳转到表单操作审批页面
        let saveParam = this.checkOperationAuth(this.buttons, "getData", () => {
          this.alertWarning("您没有查询本表单数据的权限");
        });
        if (saveParam) {
          this.bm = saveParam.bm;
        }
        let query = {
          templetId: this.tempId, // 表单模板ID
          dataId: this.dataId, // 表单数据id
          bm: this.bm, // 表单对应BM表
          tempName: this.title // 表单名称
        };
        //hbm=276&templetId=134&dataId=15093&bm=SYSTEM&clzt=原稿&random=1600252880099_0.0182102996045741
        // 如果是提交和保存合并操作，则需要跳转到原稿待处理
        if(this.$config.fromApprovalMode === "merge_submit") {
          query.dhbm = 276;
          query.clzt = "原稿";
        } else {
          query.dhbm = 256;
          query.clzt = "草稿";
        }
        //带人数据id重载页面，跳转到审批页面
        this.redirect("/ApprovalForm", query);
      } else {
        this.reload();
      }
    },
    /**
     * 通用的保存数据接口调用方法
     */
    formSaveService(values, data, button, callBack) {
      let params = {
        action: "SaveFormData",
        logSource: this.logSource,
        data: JSON.stringify(values), // 主表数据
        children: this.getMxDataJSON(), // 子表数据
        tb_name: this.mainTableName, // 主表名称 从DHCS中获取
        mxb_name: this.mxTableName.toString(), // 明细表名称
        t_id: this.saveTempID ? this.saveTempID: this.tempId, // 模板id  从DHCS中获取
        m_id: this.defaultFiled ? this.defaultFiled : "", // 保存时标识关联字段名
        merge : this.$config.fromApprovalMode, // 审批模式如果值为“merge_submit”，则是保存和提交一起做
        randomKey: this.$store.getters.getRandomKey
      };
      if (data) {
        params = Object.assign(params, data);
      }
      let that = this;
      // 保存数据的方法
      let doSave = function(params1, button1) {
        that.post("Form.ashx", params1, {
          success: function(req) {
            //console.log("请求结果", req.data.isWorkFlow);
            if (callBack) {
              callBack(req.data);
            } else {
              that.tipSuccess("保存成功");
              that.finishedProcessing(button1);
            }
          },
          error: function(e) {
            console.log("请求失败", e);
            that.changeErrAlert(e);
            that.finishedProcessing(button1);
          }
        });
      };
      // 判断是否配置了提交前的扩展功能
      this.exeSaveFromEvents(values, params, button, doSave);
    },
    /**
     * 点击“保存”按钮,注：在复合按钮“保存”中包含tsbEdit按钮参数，操作等同于编辑保存
     */
    tsbEdit(button, param) {
      if (!this.dataId) {
        this.alertWarning("新建表单请点击“新建保存”按钮");
        return;
      }
      let that = this;
      this.$refs.formTemplate.validateFields((err, values) => {
        if (!err) {
          //console.log("数据信息：" + JSON.stringify(values));
          let saveParam = param;
          if (!saveParam) {
            let tempsaveParam = that.checkOperationAuth(
              that.buttons,
              "tsbEdit",
              () => {
                that.alertWarning("您没有保存表单的权限");
              }
            );
            saveParam = tempsaveParam.param;
          }
          if (saveParam) {
            // 表单配置的全局校验条件
            if (
              !that.saveConditionCheck(
                that.formConfig.saveCondition,
                values,
                that.getMxTableData()
              )
            ) {
              that.alertError(that.formConfig.hintCotent);
              return;
            }
            // 检测明细选人数据
            for (let r = 0; r < that.mxTableName.length; r++) {
              if (that.judgeCheckCondition(that.mxTableName[r], [])) {
                that.alertError(
                  "明细选入错误，" +
                    that.gridImportTableConfig.selectCheckCondition.checkError
                );
                return;
              }
            }
            // 保存前检测，明细是否满足非空
            let errMessage = that.checkDetailRequired(
              that.formConfig.mxTableRequiredConfig,
              that.getMxTableData()
            );
            if (errMessage) {
              that.alertError(errMessage);
              return;
            }
            that.startProcessing(button);
            that.allChildrenTablesDatas = that.getMxTableData(); // 保存明细缓存
            let params = {
              bm: saveParam.bm, // 类库名称（SYSTEM）、从GJLCS中获取 {tsbNew,SYSTEM,1,980}
              et_name: saveParam.et_name, //  表示操作类型  添加为0，修改为1，删除为2 等，从GJLCS中获取 {tsbNew,SYSTEM,1,980}
              d_id: that.dataId // 插入之后返回的主键id
            };
            that.formSaveService(values, params, button, function(data) {
              if (data.dataId) {
                that.mainTableData = values;
                let mc = that.mainTableData._BDMC
                  ? that.mainTableData._BDMC
                  : that.title;
                let zt = mc + "_" + that.mainTableData._BDBH;
                that.updatePageData("tjDefaultData", "_zt", zt);
              }
              if (that.thisFromEvents && that.thisFromEvents.afterSave) {
                that.thisFromEvents.afterSave(that, that.mainTableData, button);
              } else {
                that.tipSuccess("保存成功");
                that.updateDataOverReload(values);
                that.finishedProcessing(button);
              }
            });
          }
        } else {
          //console.log( JSON.stringify(err));
          let errMsg = that.getDecoratorErrInfo(err);
          that.tipWarning(errMsg);
        }
      });
    },
    /**
     * 保存后刷新页面
     */
    updateDataOverReload(changeMainData) {
      if(this.defaultFiled && this.defaultMainTableFiled) {
        if(changeMainData) {
          if (changeMainData[this.defaultFiled] !== undefined && changeMainData[this.defaultFiled] !== this.defaultMainTableFiled[this.defaultFiled].value) {
            let newQuery = this.$util.deepCopy(this.$route.query);
            let cnText = this.$refs.formTemplate.getChineseName();
            newQuery[this.defaultFiled + "_Name"] = cnText[this.defaultFiled];
            newQuery[this.defaultFiled] = changeMainData[this.defaultFiled];
            this.$router.replace({
              query: newQuery
            });
            return;
          }
        }
      }
      this.reload();
    },
    /**
     * 点击“删除”按钮，删除当前表单
     * @param button
     * @param other
     */
    tsbDelete(button, other) {
      //console.log("删除", button);
      if (this.dataId) {
        if (other && other.prioriCondition) {
          // 满足前置条件还需要确认是否操作
          this.$refs.eToolbar.showConfirm(button.tsbName);
        } else {
          let that = this;
          let params = {
            action: "DeleteData",
            tb_name: that.mainTableName, //主表名
            mxb_name: that.mxTableName.toString(), // 明细表名称
            bm: button.param.bm,
            t_id: this.saveTempID ? this.saveTempID: this.tempId, // 模板id  从DHCS中获取
            d_id: this.dataId, // 插入之后返回的主键id
            logSource: this.logSource,
            randomKey: this.$store.getters.getRandomKey
          };
          this.startProcessing(button);
          this.post("Form.ashx", params, {
            success: function() {
              //console.log("请求结果", req);
              // 把对应删除的数据从数组中移除
              that.closeTab(that.openType ? that.random : that.dhbm);
              that.tipSuccess("删除成功");
              that.finishedProcessing(button);
            },
            error: function(err) {
              that.alertError(err.message);
              that.finishedProcessing(button);
            }
          });
        }
      }
    },
    /**
     * 强制保存按钮
     * @param button
     */
    tsbForceSave(button, param) {
      this.tsbNew(button, param);
    },
    /**
     * 导出按钮
     */
    tsbExport() {
      // 如果存在对应明细表的映射对象
      this.$refs.formTemplate.tsbExport();
    },
    /**
     * 点击数据导入进入导入视图
     */
    tsbExcelImport() {
      this.$refs.formTemplate.tsbExcelImport();
    },
    /**
     * 确认导出
     */
    ExcelImportOk(tableData) {
      this.$refs.formTemplate.ExcelImportOk(tableData);
    },
    /**
     * 弹窗查看关联单据
     */
    tabRelated(button) {
      console.log("查看关联单据",button);
      if (button.param && button.param.tempId && button.param.dataId) {
        if(this.KeepAccountsFormTempIds.includes(button.param.tempId)) {
          this.openTab({
            title: "查看-" + button.param.tempCode,
            path: "/KeepAccountsForm",
            query: {
              dhbm: this.dmbh,
              templetId: button.param.tempId, // 表单模板ID
              dataId: button.param.dataId, // 表单数据id
              detailFiledName: "_BDBH", // 表单用于tab的title的表单字段名
              type: "dateSearchView",
              bm: button.param.bm // 表单对应BM表
            }
          });
        } else { //其他普通表单查看
          let rule = this.checkTextMeetASNGenerateRule(button.param.tempCode);
          if(rule) {
            let title = "查看-" + button.param.tempCode;
            this.openTab({
              title: title,
              path: "/DetailForm",
              query: {
                dataCode: button.param.tempCode, // 表单数据编号
                tableName: rule.tableName // 表单用于tab的title的表单字段名
              }
            });
          } else {
            this.alertError("关联数据表单编码未加入编码格式配置");
          }
        }
      } else {
        this.alertError("未获取到表单关联数据");
      }
    },
    /**
     * 操作按钮点击响应，目前只有编辑视图有“复合功能按钮” 主要是保存场景问题
     * 覆盖了pageControls提供的默认方法
     * @param param
     * @constructor
     */
    onButtonClick(param) {
      let index = -1,
        other;
      if (this.$util.isType(param) === "Object") {
        index = param.index;
        other = param;
      } else {
        index = param;
      }
      if (this.buttons[index]) {
        if (this.$util.isType(this.buttons[index].tsbName) === "String") {
          // 单一功能按钮
          if (this[this.buttons[index].tsbName]) {
            this[this.buttons[index].tsbName](this.buttons[index], other);
          } else {
            this.tipWarning("尚没有" + this.buttons[index].name + "的功能");
          }
        } else if (
          this.$util.isType(this.buttons[index].tsbName) === "Array" &&
          this.$util.isType(this.buttons[index].param) === "Array"
        ) {
          // 复合功能按钮
          this.buttonExtend(this.buttons[index], other);
        } else {
          this.tipWarning("尚没有" + this.buttons[index].name + "的功能");
        }
      }
    },
    /**
     * 按钮功能扩展，特殊按钮
     */
    buttonExtend(button) {
      let tname = null;
      let param = null;
      let index = -1;
      if (
        (button.name === "保存" || button.name === "执行") &&
        this.$util.isType(button.tsbName) === "Array"
      ) {
        // 保存功能，复合按钮
        if (!this.dataId) {
          index = button.tsbName.findIndex(function(obj) {
            return obj === "tsbNew";
          });
        } else {
          index = button.tsbName.findIndex(function(obj) {
            return obj === "tsbEdit";
          });
        }
      }
      tname = button.tsbName[index];
      if (tname) {
        param = button.param[index];
        if (param) {
          if (this[tname]) {
            this[tname](button, param);
          } else {
            this.tipWarning("尚没有" + button.name + "的功能");
          }
        } else {
          this.tipWarning(button.name + "未配置相应的操作参数");
        }
      } else {
        this.tipWarning("尚没有" + button.name + "的功能配置");
      }
    },
    /**
     * 监听表单滚动条，如果滚动做一些操作
     */
    formScroll() {
      this.$refs.formTemplate.filterSelectBlur();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/new-form.less";
</style>
