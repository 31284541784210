<template>
  <div class="rowbody">
    <template>
      <a-form :form="form">
        <!-- <a-row :style="{ border: '0px dashed #000' }"> -->
        <template v-for="(item, index) in option">
          <!-- {{index}}={{click}} -->
          <div :key="'row' + index">
            <div>
              <a-col span="8">
                <div>
                  <a-input
                    v-model="item.thisField"
                    placeholder="来源字段"
                  ></a-input>
                </div>
              </a-col>
              <a-col span="4" class="center">
                =>
              </a-col>
              <a-col span="8">
                <div class="input">
                  <a-input
                    v-model="item.toField"
                    placeholder="目标字段"
                  ></a-input>
                </div>
              </a-col>
              <a-col :style="{ 'text-align': 'center' }" span="4"
                ><div class="button">
                  <a-button
                    shape="circle"
                    icon="close"
                    :style="{ width: '30px', height: '30px' }"
                    @click.stop="delCol(index)"
                  ></a-button></div
              ></a-col>
            </div>
          </div>
        </template>
        <!-- </a-row> -->
      </a-form>
    </template>
    <a-button type="dashed" icon="plus" class="addbutton" @click="addCol"
      >添加一行联动字段</a-button
    >
  </div>
</template>

<script>
import moment from "moment";
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法
import { getOption, getAddData } from "../columnsSet.js";
export default {
  mixins: [analyticalMenuAuth, pageControls, requestControls],
  name: "linkageFields",
  data() {
    return {
      click: null,
      // data: this.pushArray(this.option),
      thisOptions: this.pushArray(this.option),
      form: this.$form.createForm(this),
      maxAdd: 2,
      addData: []
    };
  },
  computed: {
    // sum:function(){
    //     return this.Math+ this.English+this.chemistry;
    // },
    // average:function(){
    //     return Math.round(this.sum/3);
    // }
  },
  model: {
    prop: "option",
    event: "change"
  },
  // props:[
  //   'option',
  //   'row'
  // ],
  props: {
    option: {
      type: null,
      default: null
    },
    fieldsOption: {
      type: null,
      default: null
    },
    title: {
      type: String,
      default: "隐藏域"
    }
  },
  watch: {
    // option: {
    //   deep: true,
    //   handler: function (newVal,oldVal) {
    //     alert("1");
    //   }
    // },
    option: function(value) {
      value;
    },
    fieldsOption: function(value) {
      value;
    }
    // "option.0.columns": {
    //   deep: true,
    //   handler: function (newVal,oldVal) {
    //     debugger
    //   }
    // },
    // "option.0.columns" (value) {
    //   debugger
    // },
  },
  created() {
    this.addData = getAddData();
    this.init();
  },
  mounted() {},
  methods: {
    submit() {
      // this.thisOptions[0].columns = this.data;
      // var op = [...this.option];
      // op[0].columns = this.data;
      // this.$emit("ok", this.thisOptions, this.row);
      this.$emit("ok", this.data, this.row);
    },
    pushArray(data) {
      // if (data.length == null) {
      //   return [data];
      // }
      return data;
    },
    init() {
      this.option;
      this.row;
      this.data;
    },
    addCol() {
      var addData = {
        thisField: "",
        toField: ""
      };
      this.option.push(addData);
      // this.data[0].columns.push(addData);

      // this.submit();
      // }
    },
    delCol(index) {
      if (this.option[index]) {
        this.option.splice(index, 1);
        // this.$emit("del");
        // this.click = null;
        // this.submit();
      }
    },
    noClick() {
      this.click = null;
    },
    clickBox(index, type) {
      this.$emit("noClick");
      this.click = typeof index == "number" ? index : type;
      this.$emit("isClick", this.row, index, type);
    },
    button() {}
  }
};
</script>

<style lang="less" scoped>
.rowbody {
  // margin: 20px;
  // border: 0px;
  // padding: 20px;
  // background:cyan;
}
.clickRowBody {
  margin: 20px;
  border: 1px dashed blue;
  padding: 20px;
}
.label {
  font-size: 15px;
  float: left;
}
.input {
  width: 100%;
}
.button {
  float: right;
  font-size: 15px;
  z-index: 10001;
}
.clickbox {
  cursor: pointer;
  z-index: 10000;
  padding: 5px;
  margin: 5px;
}
.add-button {
  color: rgb(0, 0, 0);
}
.del-button {
  color: #fff;
  background: red;
}
.border {
  border: 1px dashed #000;
  cursor: pointer;
  z-index: 10000;
  padding: 5px;
  margin: 5px;
}
.border-click {
  border: 1px dashed #f2f2f2;
  cursor: pointer;
  z-index: 10000;
  padding: 5px;
  margin: 5px;
}
.addbutton {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.height {
  height: 60px;
  line-height: 60px;
}
h1 {
  text-align: center;
}
.labelCol {
  height: 40px;
  line-height: 40px;
}
.center {
  text-align: center;
  height: 30px;
  line-height: 30px;
}
</style>
