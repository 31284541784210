<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      cancelText="关闭"
      @cancel="handleCancel"
      v-if="visible"
    >
      <!-- @ok="ok"
      @cancel="handleCancel" -->
      <template slot="footer">
        <a-button key="submit" type="primary" @click="ok">
          确定
        </a-button>
      </template>
      <div>
        <a-form
          :form="form"
          :label-col="{ span: 10 }"
          :wrapper-col="{ span: 12 }"
        >
          <template v-if="type != 'table2'">
            <a-form-item :label="label1">
              <a-input
                v-decorator="[
                  'label1',
                  {
                    rules: [{ required: true, message: '此项为必填项' }],
                    initialValue: value.label1 ? value.label1 : ''
                  }
                ]"
              />
            </a-form-item>
            <a-form-item :label="label2">
              <a-input
                v-decorator="[
                  'label2',
                  {
                    rules: [{ required: true, message: '此项为必填项' }],
                    initialValue: value.label2 ? value.label2 : ''
                  }
                ]"
              />
            </a-form-item>
          </template>
          <template v-else>
            <a-form-item :label="label1">
              <a-select
                v-decorator="[
                  'select1',
                  {
                    rules: [{ required: true, message: '此项为必填项' }],
                    initialValue: value.select1 ? value.select1 : ''
                  }
                ]"
                :options="ywzdOption"
              />
              <a-input
                v-decorator="[
                  'label1',
                  {
                    rules: [{ required: true, message: '此项为必填项' }],
                    initialValue: value.label1 ? value.label1 : ''
                  }
                ]"
              />
            </a-form-item>
            <a-form-item :label="label2">
              <a-select
                v-decorator="[
                  'select2',
                  {
                    rules: [{ required: true, message: '此项为必填项' }],
                    initialValue: value.select2 ? value.select2 : ''
                  }
                ]"
                :options="jczdOption"
              />
              <a-input
                v-decorator="[
                  'label2',
                  {
                    rules: [{ required: true, message: '此项为必填项' }],
                    initialValue: value.label2 ? value.label2 : ''
                  }
                ]"
              />
            </a-form-item>
          </template>
          <template>
            <a-form-item label="是否生效">
              <EBooleanCheckbox
                v-decorator="[
                  'isOk',
                  {
                    rules: [{ required: true, message: '此项为必填项' }],
                    initialValue: value.isOk ? value.isOk : ''
                  }
                ]"
              />
            </a-form-item>
          </template>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法
export default {
  name: "CriticalDataProtectionViewChange",
  mixins: [requestControls],
  components: {},
  data() {
    return {
      visible: false,
      title: "",
      type: null,
      changeMode: null,
      ID: null,
      label1: "",
      label2: "",
      value: {},
      option: {
        tree: {
          add: "新增基础数据表信息",
          change: "修改基础数据表信息",
          label1: "基础数据表名称",
          label2: "基础数据表名"
        },
        table1: {
          add: "新增业务数据表信息",
          change: "修改业务数据表信息",
          label1: "业务数据表名称",
          label2: "业务数据表名"
        },
        table2: {
          add: "新增字段映射关系",
          change: "修改字段映射关系",
          label1: "业务数据字段",
          label2: "基础数据字段"
        }
      },
      actionObj: {
        tree: "AddZdbhJcsjbxx",
        table1: "AddZdbhYwsjbxx",
        table2: "AddZdbhZdys"
      },
      form: this.$form.createForm(this, { name: "changeForm" }),
      jczdOption: null,
      ywzdOption: null,
      table1Id: null
    };
  },
  props: {},
  created() {},
  computed: {},
  methods: {
    show(type, changeMode, id, valueObj, table1Id) {
      this.title = this.option[type][changeMode];
      this.type = type;
      this.changeMode = changeMode;
      this.ID = id;
      this.label1 = this.option[type].label1;
      this.label2 = this.option[type].label2;
      this.value = valueObj;
      this.table1Id = table1Id;
      if (type == "table2") {
        this.getSelectOption(table1Id, () => {
          this.visible = true;
        });
      } else {
        this.visible = true;
      }
    },
    init(valueObj) {
      if (valueObj) {
        for (var key in valueObj) {
          this.form.setFieldsValue({
            key: valueObj[key]
          });
        }
      }
    },
    handleCancel() {
      this.title = "";
      this.type = null;
      this.changeMode = null;
      this.ID = null;
      this.label1 = null;
      this.label2 = null;
      this.value = {};
      this.visible = false;
    },
    ok() {
      let that = this;
      let params = {
        action: this.actionObj[this.type],
        randomKey: this.$store.getters.getRandomKey,
        id: this.ID
      };
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.type == "tree") {
            params.jcsjbmc = values.label1;
            params.jcsjbm = values.label2;
            params.sfsx = values.isOk;
          }
          if (this.type == "table1") {
            params.ywsjbmc = values.label1;
            params.ywsjbm = values.label2;
            params.sfsx = values.isOk;
            params.jcid = this.table1Id;
          }
          if (this.type == "table2") {
            params.ywsjzdm = values.select1;
            params.ywsjzdmc = values.label1;
            params.jcsjzdm = values.select2;
            params.jcsjzdmc = values.label2;
            params.sfsx = values.isOk;
            params.ywid = this.table1Id;
          }
        }
      });

      this.get("System.ashx", params, {
        success: function(req) {
          if (req.success) {
            that.$emit("ok", that.type);
            that.visible = false;
          }
        },
        error: function(err) {
          this.$message.error("失败" + err);
          that.visible = false;
        }
      });
    },
    getSelectOption(table1Id, callBack) {
      let that = this;
      // let mx = this.viewConfig.treeFields;
      let params = {
        action: "GetZdbhZdys",
        randomKey: this.$store.getters.getRandomKey,
        id: table1Id
      };
      this.get("System.ashx", params, {
        success: function(req) {
          if (req.success) {
            that.jczdOption = req.data.jczd;
            that.ywzdOption = req.data.ywzd;
            callBack();
          }
        },
        error: function(err) {
          that.$message.error("获取字段信息失败." + err);
          that.visible = false;
        }
      });
    }
  },
  watch: {}
};
</script>
