var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.column)?_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":_vm.column.title}},[(_vm.column.type === 'EInput')?_c('e-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.column.fieldName,
        {
          initialValue: _vm.nowValue,
          rules: _vm.column.rules
        }
      ]),expression:"[\n        column.fieldName,\n        {\n          initialValue: nowValue,\n          rules: column.rules\n        }\n      ]"}],attrs:{"width":_vm.column.width}}):(_vm.column.type === 'EDatetimePicker')?_c('e-datetime-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.column.fieldName,
        {
          initialValue: _vm.nowValue,
          rules: _vm.column.rules
        }
      ]),expression:"[\n        column.fieldName,\n        {\n          initialValue: nowValue,\n          rules: column.rules\n        }\n      ]"}],attrs:{"width":_vm.column.width,"format":_vm.column.format,"mode":_vm.column.mode}}):(_vm.column.type === 'EDepartmentSelect')?_c('e-department-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.column.fieldName,
        {
          initialValue: _vm.nowValue,
          rules: _vm.column.rules
        }
      ]),expression:"[\n        column.fieldName,\n        {\n          initialValue: nowValue,\n          rules: column.rules\n        }\n      ]"}],attrs:{"disabled":_vm.column.disable,"width":_vm.column.width}}):(_vm.column.type === 'EEmployeeSelect')?_c('e-employee-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.column.fieldName,
        {
          initialValue: _vm.nowValue,
          rules: _vm.column.rules
        }
      ]),expression:"[\n        column.fieldName,\n        {\n          initialValue: nowValue,\n          rules: column.rules\n        }\n      ]"}],attrs:{"disabled":_vm.column.disable,"width":_vm.column.width}}):(_vm.column.type === 'ESupplierSelect')?_c('e-supplier-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.column.fieldName,
        {
          initialValue: _vm.nowValue,
          rules: _vm.column.rules
        }
      ]),expression:"[\n        column.fieldName,\n        {\n          initialValue: nowValue,\n          rules: column.rules\n        }\n      ]"}],attrs:{"disabled":_vm.column.disable,"width":_vm.column.width,"bdmbId":_vm.tempId}}):(_vm.column.type === 'ESelect')?_c('e-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.column.fieldName,
        {
          initialValue: _vm.nowValue,
          rules: _vm.column.rules
        }
      ]),expression:"[\n        column.fieldName,\n        {\n          initialValue: nowValue,\n          rules: column.rules\n        }\n      ]"}],attrs:{"filter":_vm.column.listFilter,"options":_vm.column.options,"disabled":_vm.column.disable,"setting":_vm.column.setting,"width":_vm.column.width}}):(_vm.column.type === 'ECheckbox')?_c('e-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.column.fieldName,
        {
          initialValue: _vm.nowValue,
          rules: _vm.column.rules
        }
      ]),expression:"[\n        column.fieldName,\n        {\n          initialValue: nowValue,\n          rules: column.rules\n        }\n      ]"}],attrs:{"options":_vm.column.options,"filter":_vm.column.listFilter,"setting":_vm.column.setting}}):(_vm.column.type === 'ERadio')?_c('e-radio',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.column.fieldName,
        {
          initialValue: _vm.nowValue,
          rules: _vm.column.rules
        }
      ]),expression:"[\n        column.fieldName,\n        {\n          initialValue: nowValue,\n          rules: column.rules\n        }\n      ]"}],attrs:{"options":_vm.column.options}}):(_vm.column.type === 'ENumericUpDown')?_c('e-numeric-up-down',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.column.fieldName,
        {
          initialValue: _vm.nowValue,
          rules: _vm.column.rules
        }
      ]),expression:"[\n        column.fieldName,\n        {\n          initialValue: nowValue,\n          rules: column.rules\n        }\n      ]"}],attrs:{"width":_vm.column.width,"max":_vm.column.max,"min":_vm.column.min}}):(_vm.column.type === 'EMaterials')?_c('e-materials',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.column.fieldName,
        {
          initialValue: _vm.nowValue,
          rules: _vm.column.rules
        }
      ]),expression:"[\n        column.fieldName,\n        {\n          initialValue: nowValue,\n          rules: column.rules\n        }\n      ]"}],attrs:{"bdmbId":_vm.tempId,"setting":_vm.column.setting,"width":_vm.column.width}}):(_vm.column.type === 'EBooleanCheckbox')?_c('e-boolean-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.column.fieldName,
        {
          initialValue: _vm.nowValue,
          rules: _vm.column.rules
        }
      ]),expression:"[\n        column.fieldName,\n        {\n          initialValue: nowValue,\n          rules: column.rules\n        }\n      ]"}],attrs:{"width":_vm.column.width}}):(_vm.column.type === 'ECustomer')?_c('e-customer',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.column.fieldName,
        {
          initialValue: _vm.nowValue,
          rules: _vm.column.rules
        }
      ]),expression:"[\n        column.fieldName,\n        {\n          initialValue: nowValue,\n          rules: column.rules\n        }\n      ]"}],attrs:{"setting":_vm.column.setting,"width":_vm.column.width}}):(_vm.column.type === 'EGyBdmb')?_c('e-gy-bdmb',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.column.fieldName,
        {
          initialValue: _vm.nowValue,
          rules: _vm.column.rules
        }
      ]),expression:"[\n        column.fieldName,\n        {\n          initialValue: nowValue,\n          rules: column.rules\n        }\n      ]"}],attrs:{"setting":_vm.column.setting,"width":_vm.column.width}}):(_vm.column.type === 'ERsGjdy')?_c('e-rs-gjdy',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.column.fieldName,
        {
          initialValue: _vm.nowValue,
          rules: _vm.column.rules
        }
      ]),expression:"[\n        column.fieldName,\n        {\n          initialValue: nowValue,\n          rules: column.rules\n        }\n      ]"}],attrs:{"setting":_vm.column.setting,"width":_vm.column.width}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }