<template>
  <div v-show="visible" :style="setwidth">
    <a-tooltip :title="departNames" arrow-point-at-center>
      <a-input
        @click="openModal"
        placeholder="请点击选择科目"
        :size="controlSize"
        v-model="departNames"
        readOnly
        :disabled="thisDisabled"
      >
        <e-icon slot="prefix" type="plus" title="选择控件" :disabled="thisDisabled"/>
        <a-icon
          v-if="departIds != '' && !thisDisabled && !readOnly"
          slot="suffix"
          type="close-circle"
          theme="filled"
          @click.stop="handleEmpty"
          title="清空"
          :style="{ color: 'rgba(0, 0, 0, 0.25)' }"
        />
      </a-input>
    </a-tooltip>
    <EAccountingSubjectTable
      :value="setvalue"
      ref="selectTable"
      @ok="handleOK"
      :setting="thisSetting"
      :filter="filter"
      :companyId="companyId"
      :keyType="keyType"
    ></EAccountingSubjectTable>
  </div>
</template>

<script>
import EAccountingSubjectTable from "./EAccountingSubjectTable.vue";
export default {
  name: "EAccountingSubject",
  data() {
    return {
      setvalue: this.value,
      controlSize: this.$config.defaultControlSize,
      departIds: "",
      departNames: "",
      setwidth: this.widthSet(),
      thisSetting: {
        serverName: "Form.ashx",
        action: "GetKJKMData"
      },
      set: {},
      thisDisabled: false
    };
  },
  components: {
    EAccountingSubjectTable
  },
  model: {
    prop: "value",
    event: "change",
    type: "String"
  },
  props: {
    defaultValue: {
      type: [String, Array, Number]
    },
    value: {
      type: null
    },
    type: {
      type: [String]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: [Array, null],
      default: null
    },
    mode: {
      type: String,
      default: "single"
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    formula: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: ""
    },
    bdmbId: {
      type: [String, Number]
    },
    setting: {
      type: [Object],
      default: () => ({
        action: "GetKJKMData"
      })
    },
    filter: {
      // 筛选条件
      type: String,
      default: ""
    },
    companyId: {
      // 公司ID
      type: String,
      default: ""
    },
    keyType: {
      // 关键字
      type: String,
      default: ""
    }
  },
  created() {
    //debugger
    this.thisDisabled = this.disabled;
    if (this.setting && !this.setting.serverName) {
      this.thisSetting = Object.assign(this.thisSetting, this.setting);
    } else {
      this.thisSetting = this.setting;
    }
  },
  computed: {},
  methods: {
    /**
     * 设置禁用状态
     * @param v
     */
    setDisabled(v) {
      if(this.$util.isType(v) === "Boolean") {
        this.thisDisabled = v;
      }else {
        this.thisDisabled = true;
      }
    },
    /**
     * 切换当前控件的禁用状态
     */
    setDisabledSwitch() {
      this.thisDisabled = !this.thisDisabled;
    },
    openModal() {
      if (!this.readOnly) {
        this.$refs.selectTable.show();
      }
    },
    handleOK(rows, idstr, option) {
      this.departNames = "";
      this.departIds = "";
      this.setvalue = "";
      var value;
      if (!rows) {
        this.departNames = "";
        this.departIds = "";
        this.setvalue = "";
        this.$emit("change", this.setvalue, this.departNames);
        return;
      }
      if (rows.length == 1) {
        let temp = "";
        for (let item of rows) {
          temp = item[option.titleIndex];
          value = item[option.keyIndex];
        }
        this.departNames = temp;
        this.departNames == "" || this.departNames == null
          ? (this.departIds = "")
          : (this.departIds = idstr);
      }
      if (rows.length > 1) {
        let temp = "";
        let values = "";

        for (let item of rows) {
          temp += "," + item[option.titleIndex];
          values += "," + item[option.keyIndex];
        }

        this.departNames = temp.substring(1);
        value = values.substring(1);
        this.departNames == "" || this.departNames == null
          ? (this.departIds = "")
          : (this.departIds = idstr);
      }
      this.$emit("change", value, this.departNames);
    },
    handleEmpty() {
      this.handleOK(null);
    },
    widthSet() {
      if (this.width == "") {
        this.setwidth = "";
        return "";
      }
      if (typeof this.width == "number") {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      if (this.width.indexOf("%") == -1 && this.width.indexOf("px") == -1) {
        this.setwidth = "width:" + this.width + "px";
        return "width:" + this.width + "px";
      }
      this.setwidth = "width:" + this.width;
      return "width:" + this.width;
    }
  },
  watch: {
    width() {
      this.widthSet();
    },
    disabled(v) {
      this.thisDisabled = v;
    },
    // value(value) {
    //     debugger
    //   this.departNames = value;
    // }
  }
};
</script>
