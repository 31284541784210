<template>
  <!-- 授权操作按钮 -->
  <a-row justify="space-between" :class="etoolbarRowHeight">
    <a-col :span="24">
      <div class="divider-block-min" style="width: 10px;"></div>
      <template v-for="(button, index) in thisButtons">
        <template
          v-if="
            button.name &&
              ((!$config.toolbarPopConfirm && button.needConfirm) ||
                !button.needConfirm ||
                button.needConfirm == false)
          "
        >
          <!-- 有下拉菜单的按钮-->
          <a-dropdown
            v-if="button.menuList && button.menuList.length > 0"
            v-show="button.isShow != false"
            :key="index"
            @visibleChange="onVisibleChange($event, index)"
          >
            <a
              class="ant-dropdown-link etoobar-button-link"
              @click="
                e => {
                  onButtonMenuClick(e, index, button.clickAutoOne);
                  e.preventDefault();
                }
              "
            >
              <e-icon-button
                :disabled="
                  operationDisabled ||
                    !!button.disabled ||
                    disabledButtonMap[button.tsbName]
                "
                :loading="button.loading"
                :size="thisSize"
                :type="button.type"
                :icon="button.icon"
                :keyID="index"
                :tip="button.title"
                :title="button.name"
                :haveMenu="true"
                :remind="remind[button.tsbName] ? remind[button.tsbName] : null"
              ></e-icon-button>
            </a>
            <a-menu slot="overlay" @click="onButtonMenuClick($event, index)">
              <a-menu-item
                v-for="(item, index1) in button.menuList"
                :key="index1"
                >{{ item }}</a-menu-item
              >
            </a-menu>
          </a-dropdown>
          <!-- 普通按钮 -->
          <e-icon-button
            v-else
            v-show="button.isShow != false"
            :disabled="
              operationDisabled ||
                !!button.disabled ||
                disabledButtonMap[button.tsbName]
            "
            :loading="button.loading"
            :size="thisSize"
            :type="button.type"
            :icon="button.icon"
            :key="index"
            :keyID="index"
            :tip="button.title ? button.title: button.name"
            :title="button.name"
            @click="onButtonClick"
            :remind="remind[button.tsbName] ? remind[button.tsbName] : null"
          ></e-icon-button>
          <div
            v-show="button.isShow != false"
            :key="'divider_div_' + index"
            class="divider-block-small"
          ></div>
        </template>
        <template
          v-else-if="
            button.name && button.needConfirm && $config.toolbarPopConfirm
          "
        >
          <!-- 操作需要确认 -->
          <a-popconfirm
            :visible="showConfirmButtonMap[button.tsbName]"
            :key="index"
            placement="bottom"
            :title="'您确定要执行' + button.name + '操作'"
            @confirm="onButtonClick(index)"
            @visibleChange="handleConfirmVisibleChange($event, button, index)"
            @cancel="hiddenConfirm(button.tsbName)"
          >
            <e-icon slot="icon" type="question-circle-o" style="color: red;" />
            <e-icon-button
              v-show="button.isShow != false"
              :disabled="
                operationDisabled ||
                  !!button.disabled ||
                  disabledButtonMap[button.tsbName]
              "
              :loading="button.loading"
              :tip="button.title ? button.title: button.name"
              :size="thisSize"
              :type="button.type"
              :icon="button.icon"
              :key="index"
              :keyID="index"
              :title="button.name"
              :remind="remind[button.tsbName] ? remind[button.tsbName] : null"
            ></e-icon-button>
            <div
              v-show="button.isShow != false"
              :key="'divider_div_' + index"
              class="divider-block-small"
            ></div>
          </a-popconfirm>
        </template>
      </template>
    </a-col>
  </a-row>
</template>

<script>
import { systemMethods } from "@/mixins/systemMethods.js"; // 页面控制方法
export default {
  mixins: [systemMethods],
  name: "EToolbar",
  data() {
    return {
      thisButtons: this.buttons,
      thisSize: "default", //this.size, //不应用外界传入得size参数
      thisId:
        "toolbar_" + new Date().getTime() + "_" + this.$util.getRandomString(3),
      remind: {},
      disabledButtonMap: {}, // 选择禁用按钮
      showConfirmButtonMap: {} // 需要弹出显示popconfirm的按钮
    };
  },
  props: {
    buttons: {
      type: Array,
      default: function() {
        return [];
      }
    },
    operationDisabled: {
      // 按钮全部禁用
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    }
  },
  watch: {
    buttons: {
      handle: function(n) {
        console.log("操作按钮变化，", n);
        this.initButtons(n);
        this.thisButtons = n;
      }
    }
  },
  created() {
    this.initButtons(this.buttons);
    this.thisButtons = this.buttons;
    console.log("初始化操作按钮，", this.buttons);
  },
  computed: {
    etoolbarRowHeight: function() {
      if (this.thisSize && this.thisSize === "small") {
        return ["etoolbar-row_height-small"];
      } else if (this.thisSize && this.thisSize === "large") {
        return ["form-block-title_large"];
      }
      return ["etoolbar-row_height"];
    }
  },
  methods: {
    /**
     * 初始化buttons中的menuList
     */
    initButtons(buttons) {
      if (this.$util.isType(buttons) === "Array" && buttons.length > 0) {
        let that = this;
        buttons.forEach(function(button) {
          if (
            that.$util.isType(button.menuList) === "Array" &&
            button.menuList.length > 0 &&
            !button.menuVisible
          ) {
            button.menuVisible = true;
            button.oldIcon = button.icon;
            button.clickAutoOne = true; // 点击工具栏按钮会默认触发第一个菜单的事件
          } else {
            delete button.menuList;
          }
          if (button.needConfirm) {
            that.showConfirmButtonMap[button.tsbName] = false;
          }
        });
      }
    },
    /**
     * 监听按钮点击事件
     */
    onButtonClick(key) {
      let button = this.thisButtons[key];
      if (button.needConfirm && !this.$config.toolbarPopConfirm) {
        // 没有开启气泡确认窗
        this.$emit("click", {
          index: key,
          prioriCondition: true
        });
        return;
      }
      this.$emit("click", key);
    },
    /**
     * 隐藏弹出窗
     */
    hiddenConfirm(tsbName) {
      let nowButtonIndex = this.thisButtons.findIndex(function(b) {
        return b.tsbName === tsbName && b.needConfirm;
      });
      if (nowButtonIndex >= 0) {
        this.updatePageData("showConfirmButtonMap", tsbName, false);
      }
    },
    /**
     * 显示确认框
     */
    showConfirm(tsbName) {
      if (!this.$config.toolbarPopConfirm) {
        // 没有开启气泡确认窗
        let that = this;
        let buttonIndex = this.thisButtons.findIndex(function(b) {
          return b.tsbName === tsbName && b.needConfirm;
        });
        this.$confirm({
          title: "提示",
          content: "您确定要执行" + this.thisButtons[buttonIndex].name + "操作",
          okText: "确定",
          cancelText: "取消",
          onOk() {
            that.$emit("click", buttonIndex);
          }
        });
      } else {
        let nowButtonIndex = this.thisButtons.findIndex(function(b) {
          return b.tsbName === tsbName && b.needConfirm;
        });
        if (nowButtonIndex >= 0) {
          this.updatePageData("showConfirmButtonMap", tsbName, true);
        }
      }
    },
    /**
     * 点击了包含确认需求的按钮
     * @param visible
     * @param button
     * @param key
     */
    handleConfirmVisibleChange(visible, button, key) {
      console.log("点击", visible, button, key);
      let buttonValue = this.showConfirmButtonMap[button.tsbName];
      if (buttonValue != undefined) {
        if (!visible) {
          // 隐藏确认弹窗
          this.updatePageData("showConfirmButtonMap", button.tsbName, false);
          return;
        }
        this.updatePageData("showConfirmButtonMap", button.tsbName, false);
        this.$emit("click", {
          index: key,
          prioriCondition: true
        });
      } else {
        this.$emit("click", key);
      }
    },
    /**
     * 点击有下拉选项的按钮选项
     * @param e
     * @param index
     * @param first
     */
    onButtonMenuClick(e, index, first) {
      //console.log("点击了菜单", e, index);
      if (first === false) {
        // 点击按钮不操作
      } else {
        this.$emit("click", {
          index: index,
          menuValue: first
            ? this.thisButtons[index].menuList[0]
            : this.thisButtons[index].menuList[e.key]
        });
        this.onVisibleChange(false, index);
      }
    },
    /**
     * 切换工具栏对应按钮的显示状态
     * @param tsbName
     * @param display
     */
    eToolbarButtonToggleDisplay(tsbName, display) {
      if (this.thisButtons && this.thisButtons.length > 0 && tsbName) {
        if (this.$util.isType(tsbName) === "Array") {
          for (let i = 0; i < tsbName.length; i++) {
            this.eToolbarOneButtonToggleDisplay(tsbName[i], display);
          }
        } else {
          this.eToolbarOneButtonToggleDisplay(tsbName, display);
        }
      }
    },
    /**
     * 切换工具栏对应按钮的显示状态
     * @param tsbName
     * @param display
     */
    eToolbarOneButtonToggleDisplay(tsbName, display) {
      if (this.thisButtons && this.thisButtons.length > 0 && tsbName) {
        let that = this;
        let haveTjButton = this.thisButtons.findIndex(function(button) {
          if (that.$util.isType(button.tsbName) === "Array") {
            return button.tsbName.indexOf(tsbName) >= 0;
          }
          return button.tsbName === tsbName;
        });
        if (haveTjButton >= 0) {
          if (
            display != undefined &&
            display != null &&
            this.$util.isType(display) === "Boolean"
          )
            this.thisButtons[haveTjButton].isShow = display;
          else if (this.thisButtons[haveTjButton].isShow === undefined)
            this.thisButtons[haveTjButton].isShow = false;
          else {
            this.thisButtons[haveTjButton].isShow = !this.thisButtons[
              haveTjButton
            ].isShow;
            //console.log("切换显示按钮",tsbName,this.thisButtons[haveTjButton].isShow)
          }
        }
      }
    },
    /**
     * 响应移入、移出按钮后菜单状态事件
     * @param v
     * @param index
     */
    onVisibleChange(v, index) {
      //console.log("菜单状态" + v, index);
      if (this.$util.isType(index) === "val") {
        let haveTjButton = this.thisButtons[index];
        if (v) {
          haveTjButton.icon = "down";
        } else {
          haveTjButton.icon = haveTjButton.oldIcon;
        }
      }
    },
    /**
     * 设置按钮组的红标
     * @param tsbName
     * @param value
     */
    remindSet(tsbName, value) {
      this.$set(this.remind, tsbName, value);
    },
    /**
     * 手动设置按钮组的不可用
     * @param tsbName
     * @param value
     */
    disabledButtonMapSet(tsbName, value) {
      this.$set(this.disabledButtonMap, tsbName, !value);
    }
  }
};
</script>

<style scoped lang="less">
@import "../assets/style/base/components/e-toolbar.less";
</style>
