<!-- 权限设置功能-树状表单编辑视图 -->
<template>
  <div>
    <div v-if="showToolbar" class="form-headButton">
      <!-- 授权操作按钮 -->
      <e-toolbar
        :buttons="buttons"
        :operationDisabled="operationDisabled"
        :size="$config.defaultControlSize"
        @click="onButtonClick"
      ></e-toolbar>
    </div>
    <a-layout class="treeView-layout" :class="expandTreeViewClass">
      <a-layout-header class="view-header">
        <!--表title -->
        <e-head-title
          title="基础数据保护"
          :size="$config.defaultControlSize"
        ></e-head-title>
      </a-layout-header>
      <a-layout class="view-content">
        <!-- 左侧树 -->
        <a-layout-sider class="view-sider" :class="expandViewSilderClass">
          <div class="button-div">
            <a-button size="small" @click="buttonClick('tree', 'add')"
              >新增</a-button
            ><a-button size="small" @click="buttonClick('tree', 'change')"
              >修改</a-button
            ><a-button size="small" @click="buttonClick('tree', 'del')"
              >删除</a-button
            >
          </div>
          <a-spin
            :spinning="showTreeSpin"
            tip="树加载中..."
            size="large"
            class="tree-spin"
          ></a-spin>
          <a-tree
            v-if="!showTreeSpin"
            showLine
            defaultExpandAll
            :treeData="treeData"
            @select="onTreeSelect"
          >
            <!--:loadData="onLoadTreeData" 异步加载数据  -->
          </a-tree>
        </a-layout-sider>
        <a-layout-sider
          class="view-sider  view-sider__leftTable"
          :class="expandViewSilderClass"
        >
          <div class="button-div">
            <a-button size="small" @click="buttonClick('table1', 'add')"
              >新增</a-button
            ><a-button size="small" @click="buttonClick('table1', 'change')"
              >修改</a-button
            ><a-button size="small" @click="buttonClick('table1', 'del')"
              >删除</a-button
            >
          </div>
          <a-table
            ref="table1"
            rowKey="RYID"
            :class="$config.table_stripe ? 'ant-table-stripe' : ''"
            :loading="showTableSpin1"
            :dataSource="table1Data"
            :columns="table1Col"
            :scroll="{ x: 200, y: 400 }"
            :size="aTableSize_MiXin"
            :pagination="pagination2"
            @change="tableChange2"
            :customRow="table1CustomRow"
            :rowSelection="{
              type: 'radio',
              onChange: table1Onchange,
              selectedRowKeys: table1RowKeys
            }"
          ></a-table>
        </a-layout-sider>
        <a-layout-sider
          class="view-sider view-sider__rightTable"
          :class="expandViewSilderClass"
        >
          <div class="button-div">
            <a-button size="small" @click="buttonClick('table2', 'add')"
              >新增</a-button
            ><a-button size="small" @click="buttonClick('table2', 'change')"
              >修改</a-button
            ><a-button size="small" @click="buttonClick('table2', 'del')"
              >删除</a-button
            >
          </div>
          <a-table
            ref="table2"
            bordered
            rowKey="id"
            :loading="showTableSpin2"
            :dataSource="table2Data"
            :columns="table2Col"
            :components="thComponents"
            :scroll="{ x: 200, y: 400 }"
            :size="aTableSize_MiXin"
            :pagination="pagination"
            @change="tableChange"
            :customRow="table2CustomRow"
            :rowSelection="{
              type: 'radio',
              onChange: table2Onchange,
              selectedRowKeys: table2RowKeys
            }"
          >
            <template v-for="col in table2Col" :slot="col.dataIndex">
              <div :key="col.dataIndex">
                {{ col }}
                <template>
                  <a-popover>
                    <template slot="content">
                      保存
                    </template>
                  </a-popover>
                </template>
              </div>
            </template>
          </a-table>
        </a-layout-sider>
      </a-layout>
    </a-layout>
    <CriticalDataProtectionViewChange
      ref="changeView"
      @ok="refresh"
    ></CriticalDataProtectionViewChange>
  </div>
</template>

<script>
import { tableThResizeable } from "@/controls/Table/TabelThResizeable.js"; // 表格头可变列宽控件
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { analyticalTemplate } from "@/mixins/analyticalTemplate.js"; // 解析xml模板的方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法
import CriticalDataProtectionViewChange from "@/views/listView/CriticalDataProtectionViewChange.vue";

export default {
  mixins: [
    requestControls,
    pageControls,
    analyticalMenuAuth,
    analyticalTemplate
  ],
  name: "CriticalDataProtectionView",
  data() {
    return {
      title: "被引用关键基础数据不可修改性保护",
      showTreeSpin: false,
      showTableSpin1: false,
      showTableSpin2: false,
      treeData: [],
      thisConfig: null,
      viewConfig: null,
      tempId: null,
      selectedNodeKeys: [], // 选中的树节点
      selectRowInfo: [], // 选中树的数据
      table1Col: [], // 表1行定义
      table2Col: [], // 表2行定义
      thComponents: null,
      table1Data: [], // 表1数据
      table2Data: [], // 表2数据
      buttons: [], // 当前用户可用的操作按钮
      operationDisabled: false, // 操作按钮可用
      selectedUserRowKeys: [],
      selectedUserRow: [],
      cacheRecord: "",
      cacheIndex: "",
      pagination2: {
        current: 1,
        pageSize: 20,
        showLessItems: true,
        showSizeChanger: true,
        operationDisabled: true, // 默认加载成功前工具栏不能操作
        pageSizeOptions: ["20", "25", "30", "40"]
      },
      table1SelectId: null,
      table2SelectId: null,
      table1Select: [],
      table2Select: [],
      table1RowKeys: [],
      table2RowKeys: [],
      delAction: {
        tree: "DeleteZdbhJcsjbxx",
        table1: "DeleteZdbhYwsjbxx",
        table2: "DeleteZdbhZdys"
      },
      table1Cache: [],
      table2Cache: []
    };
  },
  components: {
    CriticalDataProtectionViewChange
  },
  props: {
    pageConfig: {
      type: Object
    }
  },
  computed: {
    rowSelectionUser() {
      return {
        selectedRowKeys: this.selectedUserRowKeys,
        onChange: this.onSelectUserChange,
        hideDefaultSelections: true,
        type: "radio"
      };
    },
    // 根据当前设置的全局尺寸，改变界面样式
    expandTreeViewClass: function() {
      return this.getClassNameBySize("treeView-layout", this.showToolbar);
    },
    expandViewSilderClass: function() {
      return this.getClassNameBySize("view-sider", this.showToolbar);
    },
    showToolbar: function() {
      return this.checkToobleIsShow(this.buttons);
    }
  },
  watch: {},
  created() {
    this.thisConfig = this.pageConfig;
    //分析出用户可做操作按钮
    this.buttons = this.getUserJurisdictionButtons(this.thisConfig);
    this.viewConfig = this.thisConfig.viewConfig;
    if (this.viewConfig) {
      // 取得树配置
      if (this.viewConfig.rootName) {
        // 设置了根节点名称
        this.treeData.push({
          title: this.viewConfig.rootName,
          key: "ROOT",
          children: []
        });
      }
      this.pagination.pageSize = 20;
      this.tableStart(); // 初始化表
    } else {
      this.alertError("树形表单编辑视图配置错误");
    }
  },
  mounted() {
    if (this.viewConfig) {
      this.showTreeSpin = true;
      this.showTableSpin1 = true;
      this.showTableSpin2 = true;
      this.tempId = this.viewConfig.templateId;
      this.getTreeData(); // 加载树
    }
  },
  methods: {
    getTreeData() {
      this.showTreeSpin = true;
      let that = this;
      that.treeData[0].children = [];
      // let mx = this.viewConfig.treeFields;
      let params = {
        action: "GetBasicsTable",
        // tb_name: this.viewConfig.treeTable, //主表名
        // fieldNames: JSON.stringify(mx), //明细字段名
        // bm: this.viewConfig.treeBm,
        // t_id: this.viewConfig.treeTemplateId,
        randomKey: this.$store.getters.getRandomKey
      };
      this.get("System.ashx", params, {
        success: function(req) {
          let mData = req.data;
          for (let i = 0; i < mData.length; i++) {
            that.treeData[0].children.push(mData[i]);
          }
          that.showTreeSpin = false;
          that.showTableSpin1 = false;
          that.showTableSpin2 = false;
        },
        error: function(err) {
          console.log(err);
          that.showTreeSpin = false;
          that.showTableSpin1 = false;
          that.showTableSpin2 = false;
          that.alertError("请求失败，获取树数据失败");
        }
      });
    },
    tableStart() {
      this.table1Col = this.viewConfig.tableCols;
      this.table2Col = this.viewConfig.tableCols2;
      this.thComponents = {
        header: {
          cell: tableThResizeable.getResizeableTitleFunction(this.table2Col)
        }
      };
      this.showTableSpin1 = false;
      this.showTableSpin2 = false;
    },
    onTreeSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys;
      if (this.selectedKeys == "") {
        this.selectRowInfo = [];
        return;
      }
      this.selectRowInfo = info.node.dataRef;
      this.clickTree(info.node.dataRef.key);
    },
    clickTree(id) {
      let searchId = id;
      if (id === "ROOT") {
        let selectedIds = [];
        this.$util.getObjParamValueArrayByParam(
          this.treeData,
          "Id",
          selectedIds
        );
        searchId = selectedIds.toString();
      }
      let that = this;
      this.showTableSpin1 = true;
      // let tableSearch = this.viewConfig.treeLink;

      this.table1Data = [];
      this.table2Data = [];
      let params = {
        action: "GetBusinessBasicsTable",
        // tb_name: this.viewConfig.tableName, //主表名
        // bm: this.viewConfig.tableBm,
        // t_id: this.viewConfig.tableTemplateId,
        JCSJBXXBID: searchId,
        // search: tableSearch + " in (" + searchId + ")",
        randomKey: this.$store.getters.getRandomKey
      };
      this.post("System.ashx", params, {
        success: function(req) {
          that.table1Data = req.data;
          that.showTableSpin1 = false;
        },
        error: function(err) {
          console.log(err);
          that.showTableSpin1 = false;
          that.alertError("请求失败，获取表格数据失败");
        }
      });
    },
    clickTable(record, index) {
      this.cacheRecord = record;
      this.cacheIndex = index;
      var id = record.ID;
      this.selectedUserRowKeys = [index];
      this.selectedUserRow = record;
      let that = this;
      this.showTableSpin2 = true;
      // var tableSearch = this.viewConfig.tableLink[
      //   this.viewConfig.tableLink.length - 1
      // ];
      let params = {
        action: "GetBasicsTableColumn",
        // tb_name: this.viewConfig.table2Name, //主表名
        // bm: this.viewConfig.table2Bm,
        // t_id: 0,
        YWSJBXXBID: id,
        // search: tableSearch + " = " + id,
        randomKey: this.$store.getters.getRandomKey
      };
      this.post("System.ashx", params, {
        success: function(req) {
          that.table2Data = req.data;
          for (var i = 0; i < that.table2Data.length; i++) {
            if (that.table2Data[i]["_qxnr"]) {
              that.table2Data[i]["_QXNR"] = that.table2Data[i]["_qxnr"];
            }
            if (that.table2Data[i]["_sfyx"]) {
              that.table2Data[i]["_SFYX"] = that.table2Data[i]["_sfyx"];
            }
          }
          that.showTableSpin2 = false;
        },
        error: function(err) {
          console.log(err);
          that.showTableSpin2 = false;
          that.alertError("请求失败，获取表格数据失败");
        }
      });
    },
    table1CustomRow(record, key) {
      return {
        on: {
          click: object => {
            this.table1Onchange([key], [record]);
          }
        }
      };
    },
    table2CustomRow(record, key) {
      return {
        on: {
          click: object => {
            this.table2Onchange([key], [record]);
          }
        }
      };
    },
    buttonClick(type, changeMode) {
      var id = null;
      var obj = {};
      var table1Id = null;
      if (type == "tree") {
        id = this.selectRowInfo.key;
        if (changeMode == "change" || changeMode == "del") {
          obj = {
            label1: this.selectRowInfo.title,
            label2: this.selectRowInfo.tableName,
            isOk: this.selectRowInfo._SFSX
          };
          if (!id) {
            this.$message.error("请选中至少一条数据.");
            return;
          }
          if (changeMode == "del") {
            this.delteData(id, type);
            return;
          }
        }
      }
      if (type == "table1") {
        table1Id = this.selectRowInfo.key;
        if (changeMode == "change" || changeMode == "del") {
          id = this.table1SelectId.ID;
          obj = {
            label1: this.table1SelectId._YWSJBMC,
            label2: this.table1SelectId._YWSJBM,
            isOk: this.table1SelectId._SFSX
          };
          if (!id) {
            this.$message.error("请选中至少一条数据.");
            return;
          }
        }
        if (!table1Id) {
          this.$message.error("请选中至少一条数据.");
          return;
        }
        if (changeMode == "del") {
          this.delteData(id, type);
          return;
        }
      }
      if (type == "table2") {
        table1Id = this.table1SelectId.ID;
        if (changeMode == "change" || changeMode == "del") {
          id = this.table2SelectId.ID;
          obj = {
            select1: this.table2SelectId._YWSJZDM,
            label1: this.table2SelectId._YWSJZDMC,
            select2: this.table2SelectId._JCSJZDM,
            label2: this.table2SelectId._JCSJZDMC,
            isOk: this.table2SelectId._SFSX
          };
          if (!id) {
            this.$message.error("请选中至少一条数据.");
            return;
          }
        }
        if (!table1Id) {
          this.$message.error("请选中至少一条数据.");
          return;
        }
        if (changeMode == "del") {
          this.delteData(id, type);
          return;
        }
      }
      this.$refs.changeView.show(type, changeMode, id, obj, table1Id);
    },
    delteData(id, type) {
      let that = this;
      let params = {
        action: this.delAction[type],
        randomKey: this.$store.getters.getRandomKey,
        id: id
      };
      this.get("System.ashx", params, {
        success: function(req) {
          if (req.success) {
            that.refresh(type);
            that.$message.success("删除成功");
          }
        },
        error: function(err) {
          that.$message.error("删除失败" + err);
        }
      });
    },
    onSelectUserChange(selectedRowKeys, selectedRows) {
      this.selectedUserRowKeys = selectedRowKeys;
      this.selectedUserRow = selectedRows;
      console.log("选项变化", selectedRowKeys);
    },
    changeAuthCancel() {
      this.clickTable(this.cacheRecord, this.cacheIndex);
    },
    /**
     * 响应表格分页、排序、筛选事件
     * @param pagination
     */
    tableChange(pagination) {
      this.updatePageData("pagination", {
        current: pagination.current,
        pageSize: pagination.pageSize
      });
    },
    /**
     * 响应表格分页、排序、筛选事件
     * @param pagination
     */
    tableChange2(pagination) {
      this.updatePageData("pagination2", {
        current: pagination.current,
        pageSize: pagination.pageSize
      });
    },
    // 刷新
    refresh(type) {
      if (type == "tree") {
        this.selectedKeys = [];
        this.selectRowInfo = [];
        this.getTreeData();
      }
      if (type == "table1") {
        this.table2Data = [];
        this.table1Cache = [];
        this.table1SelectId = null;
        this.table1RowKeys = [];
        this.clickTree(this.selectRowInfo.key);
      }
      if (type == "table2") {
        this.table2SelectId = null;
        this.table2Cache = [];
        this.table2SelectId = [];
        this.table2RowKeys = [];
        this.clickTable(this.table1Cache[0], this.table1Cache[1]);
      }
    },
    table1Onchange(key, record) {
      this.table2Data = [];
      this.table1Cache = [record[0], key[0]];
      this.clickTable(record[0], key[0]);
      this.table1SelectId = record[0];
      this.table1RowKeys = key;
    },
    table2Onchange(key, record) {
      this.table2SelectId = record[0];
      this.table2Cache = [record[0]];
      this.table2RowKeys = key;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/critical-data-protection-view.less";
</style>
