<template>
  <div>
    <a-modal
      title="选择用户"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @ok="handleSubmit"
      @cancel="handleCancel"
      cancelText="关闭"
      :centered="true"
      width="auto"
      wrapClassName="modal"
      :bodyStyle="{ 'min-width': '520px' }"
    >
      <template slot="footer">
        <a-button key="submit" type="primary" @click="handleSubmit">
          确定
        </a-button>
      </template>
      <a-spin :spinning="spinning">
        <a-input-group compact>
          <a-input-search
            placeholder="查询"
            @search="onSearch"
            v-model="searchValue"
            allowClear
            style="width:90%"
            class="e-pop-up-select-tree-search-left"
          >
          </a-input-search>
          <a-button
            style="width:10%"
            @click="
              () => {
                searchValue = '';
                onSearch();
              }
            "
            class="e-pop-up-select-tree-search-right"
            ><a-icon type="close"
          /></a-button>
        </a-input-group>
        <!-- {{searchValue}} -->
        <a-tree
          :style="{ 'max-height': maxHeight, 'overflow-y': 'auto' }"
          ref="treeMod"
          checkable
          :treeData="searchCacheData != '' ? searchCacheData : treeData"
          :checkStrictly="true"
          @check="onCheck"
          @select="
            (key, info) => {
              info.node.checkable == false
                ? onExpand(info.node.eventKey, info, 'select')
                : onSelect(key, info);
            }
          "
          @expand="onExpand"
          :autoExpandParent="autoExpandParent"
          :expandedKeys="expandedKeys"
          :checkedKeys="checkedKeys"
          :loadData="onLoadData"
          :defaultExpandAll="true"
        >
          <template slot="title" slot-scope="{ title }">
            <span v-if="title.indexOf(searchValue) > -1 && searchValue != ''">
              {{ title.substr(0, title.indexOf(searchValue)) }}
              <span style="color: #f50">{{ searchValue }}</span>
              {{
                title.substr(title.indexOf(searchValue) + searchValue.length)
              }}
            </span>
            <span v-else>{{ title }}</span>
          </template>
        </a-tree>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import { requestControls } from "@/mixins/requestControls.js"; // 页面控制方法
export default {
  name: "EEmployeeSelectTree",
  props: {
    mode: {
      type: String
    },
    defaultValue: {
      type: [String, Array],
      default: ""
    },
    value: {
      type: null,
      default: ""
    },
    bdmbId: {
      type: [String, Number]
    },
    setting: {
      type: [Object],
      default: () => ({})
    },
    keyType: {
      type: String,
      default: ""
    },
    filterSave: {
      type: null
    },
    okValue: {
      type: Array,
      default: function() {
        return [];
      }
    },
    search: {
      type: String,
      default: ""
    }
  },
  mixins: [requestControls],
  data() {
    return {
      visible: false,
      confirmLoading: false,
      treeData: [],
      autoExpandParent: true,
      expandedKeys: [],
      checkedKeys: [],
      checkedRows: [],
      spinning: true,
      checkName: "",
      checkId: "",
      firstLoad: true,
      searchValue: "",
      searchCacheData: "",
      cacheExpandedKeys: [],

      finished: false,
      searchMaxDataNum: 70, // 筛选最大数据数，需要更多得下拉滚动条
      searchAllCacheData: "",
      localCalibration: false,
      okValueSave: this.okValue ? this.okValue : [],
      getValue: this.value
    };
  },
  created() {
    this.loadDepartValue(this.getValue);
  },
  mounted() {},
  computed: {
    maxHeight: function() {
      return this.$root.NowWindowsSize.height * 0.7 + "px";
    },
    maxWidth: function() {
      return this.$root.NowWindowsSize.width + "px";
    }
  },
  watch: {
    departId() {
      this.initDepartComponent();
    },
    visible: {
      handler() {
        if (this.departId) {
          this.checkedKeys = this.departId.split(",");
        } else {
          // this.checkedKeys = [];
        }
      }
    },
    searchValue(value) {
      if (value == "") {
        this.onSearch();
      }
    },
    okValue(v1) {
      if (!v1) {
        this.okValueSave = [];
      } else {
        this.okValueSave = v1;
      }
    }
  },
  methods: {
    show() {
      this.visible = true;
      // this.checkedRows = [];
      // this.checkedKeys = [];
    },
    Empty() {
      this.checkedRows = [];
      this.checkedKeys = [];
    },
    // 给data加 scopedSlots = { title: 'title' }
    treeDataPush() {
      var _this = this;
      for (var i = 0; i < this.treeData.length; i++) {
        this.treeData[i].scopedSlots = { title: "title" };
      }
      if (this.searchCacheData != "") {
        for (var j = 0; j < this.searchCacheData.length; j++) {
          setTitle(this.searchCacheData[j]);
          // this.searchCacheData[j].scopedSlots = { title: 'title' };
        }
      }
      function setTitle(node) {
        if (_this.$util.isType(node) === "Array") {
          for (var o = 0; o < node.length; o++) {
            node[o].scopedSlots = { title: "title" };
            if (node[o].children) {
              setTitle(node[o].children);
            }
          }
        } else {
          node.scopedSlots = { title: "title" };
          if (node.children) {
            setTitle(node.children);
          }
        }
      }
    },
    loadDepartValue(val, getChinense) {
      var _this = this;
      var url = this.setting.serverName;
      var dataId = this.$util.isType(val) == "Object" ? val.value : val;
      var gsbm = this.$store.getters.getCompanyId;
      let params = {
        action: this.setting.treeAction,
        gsbm: gsbm,
        keyType: this.keyType,
        searchStr: this.searchValue,
        dataId: dataId
      };
      if (getChinense) {
        getChinenseValue();
        return;
      }
      // 如果有缓存
      // 则说明之前已经拿过树结构了
      // 但是有异步加载的问题，如果没有拿到过异步的数据，则不匹配去调接口
      // if (this.filterSave && _this.firstLoad == true) {
      if (this.filterSave || this.treeData != "") {
        // 使用缓存
        if (this.filterSave) {
          _this.treeData = this.filterSave;
          // 给data加 scopedSlots = { title: 'title' }
          _this.treeDataPush();
        }

        // 是否第一次加载
        _this.firstLoad = false;
        _this.spinning = false;
        if (dataId != "" && dataId != null) {
          // 旗子，看是否在本地校对成功
          _this.localCalibration = false;

          if ((val != "" && val != null) || JSON.stringify(val) == "{}") {
            _this.localCalibration = _this.checkValue(
              _this.treeData,
              val,
              false,
              true
            );
          }
          // 如果本地校验没通过再调接口
          // 查出result等数据
          if (_this.localCalibration == false) {
            getData();
          } else {
            this.handleSubmit(true);
          }
        } else {
          // 如果传来为空
          this.Empty();
          this.handleSubmit(true);
        }
        return;
      }

      getData();

      function getData() {
        if (_this.okValueSave && _this.okValueSave.length != 0) {
          var okIndex = _this.okValueSave.findIndex(item => {
            if (item.key) {
              return _this.trimStr(item.key) === _this.trimStr(dataId);
            }
            return _this.trimStr(item) === _this.trimStr(dataId);
          });
          if (okIndex != -1) {
            _this.$emit("ok", [_this.okValueSave[okIndex]], "1");
            return true;
          }
        }

        _this.get(url, params, {
          success: function(req) {
            //兼容202102之前老版本接口
            let reqData = window.USE_NEW_API ? req.data:req;
            var data = reqData.data;
            if (req.success) {
              let arr = data;
              _this.columns = reqData.cols;

              // 如果第一次
              if (_this.firstLoad == true) {
                _this.treeData = arr;
                _this.treeDataPush();
                // 抛出缓存
                _this.$emit("filterSave", arr);
              }

              _this.firstLoad = false;
              //  优先级value比defaultValue高
              _this.spinning = false;

              if (reqData.result &&
                ((_this.$util.isType(reqData.result) ===  "Object" && !_this.$util.objectIsNull(reqData.result))
                || (_this.$util.isType(reqData.result) ===  "Array" && reqData.result.length>0))) {
                // 有result说明有相匹配的值
                let jsonA = [];
                if (_this.$util.isType(reqData.result) ===  "Object") {
                  let dataObject = {
                    title: reqData.result.label,
                    key: reqData.result.key
                  };
                  jsonA.push(dataObject);
                  _this.okValueSave.push(dataObject);
                  _this.$emit("okValue", _this.okValueSave);
                  _this.$emit("ok", jsonA, "1");
                  return true;
                } else if(_this.$util.isType(reqData.result) ===  "Array") { // 多个
                  reqData.result.forEach(function(vData){
                    if(vData && !_this.$util.objectIsNull(vData)) {
                      let tempData = {
                        title: vData.label,
                        key: vData.key
                      };
                      jsonA.push(tempData);
                      _this.okValueSave.push(tempData);
                    }
                  });
                  _this.$emit("okValue", _this.okValueSave);
                  _this.$emit("ok", jsonA, "1");
                }
              } else {
                // 拿到数据后如果没有result则2次本地校验，再没有只能返回空了
                _this.localCalibration = _this.checkValue(
                  _this.treeData,
                  val,
                  false,
                  true
                );
                if (_this.localCalibration == false) {
                  _this.$emit("ok", null, "1");
                } else {
                  _this.handleSubmit(true);
                }
              }
            }
            return false;
          },
          error: function() {}
        });
      }

      function getChinenseValue() {
        _this.get(url, params, {
          success: function(req) {
            //兼容202102之前老版本接口
            let reqData = window.USE_NEW_API ? req.data:req;
            if (req.success) {
              if (reqData.result) {
                // 有result说明有相匹配的值
                if (!_this.$util.objectIsNull(reqData.result)) {
                  _this.$emit("getChinese", reqData.result.label, reqData.result.key);
                  return;
                }
                _this.$emit("getChinese", "", "");
              } else {
                _this.$emit("getChinese", "", "");
              }
            }
            return false;
          },
          error: function() {}
        });
      }
    },
    /**
     * 加载节点数据
     * @param treeNode
     * @return {Promise<any>}
     */
    onLoadData(treeNode) {
      var _this = this;
      return new Promise(resolve => {
        if (!treeNode.dataRef.children || treeNode.dataRef.children.length==0) {
          var url = _this.setting.serverName;
          let params = {
            action: _this.setting.nodeAction,
            ID: treeNode.dataRef.key
          };
          this.get(url, params, {
            success: function(req) {
              if (req.success) {
                for (var i = 0; i < req.data.length; i++) {
                  req.data[i].scopedSlots = { title: "title" };
                }
                treeNode.dataRef.children = req.data;
                resolve();
              }
            },
            error: function() {}
          });
        } else {
          resolve();
          return;
        }
      });
    },
    checkValueOk(obj, element) {
      // let currKey = obj.key;
      // let currKey = element.key;
      // if (this.checkedKeys.indexOf(currKey) >= 0) {
      //   this.checkedKeys = this.checkedKeys.filter((item) => {
      //     return item != currKey;
      //   });
      //   this.checkedRows = this.checkedRows.filter((item) => {
      //     return item.key != currKey;
      //   });
      // } else {
      var have = this.checkedRows.findIndex(function(objct) {
        return objct.key === element.key;
      });
      if (have == -1) {
        if (this.mode == "single") {
          this.checkedRows = [];
          this.checkedKeys = [];
          this.checkedRows[0] = element;
          this.checkedKeys[0] = element.key;
        } else {
          this.checkedRows.push(element);
          this.checkedKeys.push(element.key);
        }
      }
      // }
    },
    trimStr(data) {
      if (data == null) {
        return data;
      }
      if (typeof data == "number") {
        data = data.toString();
      }
      return data.trim();
    },
    // end表示筛选完直接将值作为选中确定
    // flag 表示成功是否
    checkValue(obj, value, flag, end) {
      // 如果是number处理为字符串
      if (typeof value == "number") {
        value = value.toString();
      }
      for (var keys in obj) {
        if (typeof value == "string") {
          // 如果是null或者数字不trim
          // 逻辑就是如果key == value || title == label
          if (
            this.trimStr(obj[keys].key) === this.trimStr(value) ||
            this.trimStr(obj[keys].otherTitle) === this.trimStr(value)
          ) {
            flag = true;
            this.checkValueOk(obj, obj[keys]);
          }
        }

        if (typeof value == "object") {
          if (value.constructor === Object) {
            if (
              this.trimStr(obj[keys].key) === this.trimStr(value.value) &&
              this.trimStr(obj[keys].otherTitle) === this.trimStr(value.label)
            ) {
              flag = true;
              this.checkValueOk(obj, obj[keys]);
            }
          }

          if (value.constructor === Array) {
            for (var i = 0; i <= value.length - 1; i++) {
              if (value[i].constructor == Object) {
                if (
                  this.trimStr(obj[keys].key) ===
                    this.trimStr(value[i].value) &&
                  this.trimStr(obj[keys].otherTitle) ===
                    this.trimStr(value[i].label)
                ) {
                  flag = true;
                  this.checkValueOk(obj, obj[keys]);
                }
              } else {
                if (
                  this.trimStr(obj[keys].key) === this.trimStr(value) ||
                  this.trimStr(obj[keys].otherTitle) === this.trimStr(value)
                ) {
                  flag = true;
                  this.checkValueOk(obj, obj[keys]);
                }
              }
            }
          }
        }
        try {
          if (
            obj[keys].children &&
            obj[keys].children != "" &&
            obj[keys].children.length != 0
          ) {
            flag = this.checkValue(obj[keys].children, value, flag);
          }
        } catch (err) {
          err;
        }
      }
      if (end) {
        return flag;
      }
      return flag;
    },
    setCheckValue() {},
    onCheck(checkedKeys, info) {
      if (this.mode == "single") {
        let arr = checkedKeys.checked.filter(item => {
          return this.checkedKeys.indexOf(item) < 0;
        });
        this.checkedKeys = [...arr];
        this.checkedRows = [info.node.dataRef];
      } else if (this.mode == "multi") {
        let currKey = info.node.dataRef.key;
        if (this.checkedKeys.indexOf(currKey) >= 0) {
          this.checkedKeys = this.checkedKeys.filter(item => {
            return item != currKey;
          });
          this.checkedRows = this.checkedRows.filter(item => {
            return item.key != currKey;
          });
        } else {
          this.checkedRows.push(info.node.dataRef);
          this.checkedKeys = checkedKeys.checked;
        }
      }
    },
    onSelect(selectedKeys, info) {
      // if (info.node.checkable == false) {
      //   this.onExpand(selectedKeys, info);
      //   return;
      // }
      let keys = [];
      keys.push(selectedKeys[0]);
      if (
        !this.checkedKeys ||
        this.checkedKeys.length == 0 ||
        this.mode == "single"
      ) {
        // 单选时调用onCheck函数
        this.onCheck({ checked: [info.node.eventKey], halfChecked: [] }, info);
        // this.checkedKeys = [...keys];
        // this.checkedRows = [info.node.dataRef];
      } else if (this.mode == "multi") {
        let currKey = info.node.dataRef.key;
        if (this.checkedKeys.indexOf(currKey) >= 0) {
          this.checkedKeys = this.checkedKeys.filter(item => {
            return item != currKey;
          });
          this.checkedRows = this.checkedRows.filter(item => {
            return item.key != currKey;
          });
        } else {
          this.checkedRows.push(info.node.dataRef);
          this.checkedKeys.push(...keys);
        }
      }
    },
    onExpand(expandedKeys, info, type) {
      // 因为tree select 的机制，连续点击同一个select有问题，这里是做这个处理
      if (type == "select") {
        var isHave = this.expandedKeys.findIndex(item => {
          return item + "" === expandedKeys + "";
        });
        if (isHave == -1) {
          this.expandedKeys.push(expandedKeys);
        } else {
          this.expandedKeys.splice(isHave, 1);
        }
      } else {
        this.expandedKeys = expandedKeys;
      }
      // if (info.node.checkable == false) {
      //   if (expandedKeys[0] == null && this.expandedKeys[0] == null) {
      //     this.expandedKeys = [info.node.eventKey];
      //     return;
      //   }
      // }
      // if (expandedKeys[0] == this.expandedKeys[0]) {
      //   this.expandedKeys = [];
      //   return;
      // }
      // this.expandedKeys = expandedKeys;
      // this.autoExpandParent = false;
    },
    handleSubmit(first) {
      if (!this.checkedKeys || this.checkedKeys.length == 0) {
        if (first) {
          try {
            var arr = [];
            if (this.value.length != null && typeof this.value != "string") {
              this.value.forEach(element => {
                var obj = {
                  title: element.label,
                  key: element.value
                };
                arr.push(obj);
              });
            } else {
              var obj2 = {
                title: this.value.label,
                key: this.value.value
              };
              arr.push(obj2);
            }
            this.$emit("ok", arr, "1");
          } catch (err) {
            this.$emit("ok", "");
            console.log("控件出错了！", err);
          }
        } else {
          this.$emit("ok", "");
        }
      } else {
        this.$emit("ok", this.checkedRows, this.checkedKeys.join(","));
      }
      this.handleClear();
    },
    handleCancel() {
      this.handleClear();
    },
    handleClear() {
      this.visible = false;
      // this.checkedKeys = [];
      // this.checkedRows = [];
      //this.loadDepart()
      //this.$refs.treeMod.selectedKeys = [];
    },
    // getParentKey(title, tree) {
    //   let parentKey;
    //   for (let i = 0; i < tree.length; i++) {
    //     const node = tree[i];
    //     if (node.children) {
    //       if (node.children.some(item => item.title === title)) {
    //         parentKey = node.key;
    //       } else if (this.getParentKey(title, node.children)) {
    //         parentKey = this.getParentKey(title, node.children);
    //       }
    //     }
    //   }
    //   return parentKey;
    // },
    // 开始查询
    onSearch() {
      // this.searchValue = e.target.value;
      if (this.searchValue != "") {
        this.SearchData();
      } else {
        this.searchCacheData = "";
        this.expandedKeys = this.cacheExpandedKeys;
        this.cacheExpandedKeys = [];
      }
    },
    // 查询逻辑
    SearchData() {
      var _this = this;
      var url = this.setting.serverName;
      var gsbm = this.$store.getters.getCompanyId;
      let params = {
        action: this.setting.treeAction,
        gsbm: gsbm,
        keyType: this.keyType,
        searchStr: this.searchValue,
        dataId:
          this.$util.isType(this.value) == "Object"
            ? this.value.value
            : this.value
      };
      _this.spinning = true;
      this.get(url, params, {
        success: function(req) {
          //兼容202102之前老版本接口
          let reqData = window.USE_NEW_API ? req.data:req;
          var data = reqData.data;
          if (req.success) {
            let arr = data;
            _this.searchCacheData = arr;
            _this.searchExpanded();
            _this.treeDataPush();
            _this.spinning = false;
          }
        },
        error: function() {}
      });
    },
    // 用于打开查询后的父节点
    searchExpanded() {
      var _this = this;
      if (this.cacheExpandedKeys.length == 0) {
        this.cacheExpandedKeys = [...this.expandedKeys];
      }
      var newExpandedKeys = [];

      for (var i = 0; i < this.searchCacheData.length; i++) {
        getExpandedKey(this.searchCacheData[i]);
      }

      function getExpandedKey(node) {
        if (_this.$util.isType(node) === "Array") {
          for (var j = 0; j < node.length; j++) {
            if (node[j].children) {
              newExpandedKeys.push(node[j].key);
              getExpandedKey(node[j].children);
            }
          }
        } else {
          if (node.children) {
            newExpandedKeys.push(node.key);
            getExpandedKey(node.children);
          }
        }
      }
      this.expandedKeys = newExpandedKeys;
    }
  }
};
</script>
<style scoped lang="less">
@import "../../../assets/style/base/controls/e-pop-up-select-tree.less";
</style>
