export const dateFormat = {
  methods: {
    formatSex(cellValue){
      return cellValue === '1' ? '男' : '女'
    },
    formatPercentage(cellValue){
      //console.log("列表数据格式化-formatPercentage",cellValue);
      return this.$util.valToPercentStr(cellValue);
    },
    formatDate(cellValue){
      return this.$util.dateUtils.formatDateString(cellValue, 'YYYY-MM-DD');
    }
  }
};