/**
 * 支持蓝能ERP上下联系查看表单功能
 * @type {{data(): *, created(), methods: {}}}
 */
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求

export const e1FormToolbarToAssociated = {
  mixins: [pageControls, requestControls],
  data() {
    return {
      tempPreviousWorkflowFormNos_MiXin: null, // 多个上张业务表
      tempNextWorkflowFormNos_MiXin: null // 多个下张业务表
    };
  },
  created() {
    //console.log("混入e1FormToolbarToAssociated的通用方法");
  },
  methods: {
    /**
     * 获取本条记录关联的上一个或下一个业务表单
     * @param button
     * @param {Function} callback
     * @param {Boolean} type:表示下查,默认上查
     */
    getPreviousOrNextForm(button, callback, type) {
      let action = "GetPreviousFormWorkflowData";
      if (type) action = "GetNextFormWorkflowData";
      let that = this;
      let params = {
        action: action,
        formTemplateID: this.tempId,
        formDataID: this.dataId,
        randomKey: this.$store.getters.getRandomKey
      };
      this.get("System.ashx", params, {
        success: function(req) {
          if (req.data) {
            let forms = req.data;
            if (
              that.$util.isType(forms) !== "Array" ||
              (that.$util.isType(forms) === "Array" && forms.length == 0)
            ) {
              that.tipWarning("打开表单失败,未查询到关联表单");
              that.finishedProcessing(button);
              return;
            }
            if (forms.length == 1) {
              // 只有一个
              let formNo = forms[0]._BDBH;
              let rule = that.checkTextMeetASNGenerateRule(formNo);
              if (rule) {
                let data = {
                  rule: rule,
                  formNO: formNo
                };
                callback(data);
              } else {
                that.tipWarning("打开表单失败,未配置的表单编码");
                that.finishedProcessing(button);
              }
            } else {
              // 有多个
              let menuList = [];
              forms.forEach(function(m) {
                menuList.push(m._BDMC);
              });
              if (type) {
                //表示下查
                that.tempNextWorkflowFormNos_MiXin = forms;
              } else {
                that.tempPreviousWorkflowFormNos_MiXin = forms;
              }
              that.toolbarButtonAddMenu(button, menuList, false);
              that.finishedProcessing(button);
            }
          } else {
            that.tipWarning("请求失败," + req.message);
            that.finishedProcessing(button);
          }
        },
        error: function(err) {
          that.finishedProcessing(button);
          that.alertError("请求失败," + err.message);
        }
      });
    },

    /**
     * 查看上一张业务表
     * @param button
     * @param other
     */
    tsbPreviousWorkflow(button, other) {
      this.startProcessing(button);
      if (other) {
        // 点击有多个业务表单的下拉菜单中的一个
        this.openTempWorkflowFormNos(
          this.tempPreviousWorkflowFormNos_MiXin,
          other.menuValue
        );
        this.finishedProcessing(button);
      } else {
        // 第一次点击本按钮
        let that = this;
        this.getPreviousOrNextForm(
          button,
          function(data) {
            that.showFromByRule(data.formNO, data.rule);
            that.finishedProcessing(button);
          },
          false
        );
      }
    },
    /**
     * 查看下一张业务表
     * @param button
     * @param other
     */
    tsbNextWorkflow(button, other) {
      this.startProcessing(button);
      if (other) {
        // 点击有多个业务表单的下拉菜单中的一个
        this.openTempWorkflowFormNos(
          this.tempNextWorkflowFormNos_MiXin,
          other.menuValue
        );
        this.finishedProcessing(button);
      } else {
        // 第一次点击本按钮
        let that = this;
        this.getPreviousOrNextForm(
          button,
          function(data) {
            that.showFromByRule(data.formNO, data.rule);
            that.finishedProcessing(button);
          },
          true
        );
      }
    },
    /**
     * 打开指定名称的表单
     * @param formNos
     * @param menuValue
     */
    openTempWorkflowFormNos: function(formNos, menuValue) {
      if (formNos && menuValue) {
        let selectedMenu = formNos.find(function(d) {
          return d._BDMC === menuValue;
        });
        if (selectedMenu) {
          let formNo = selectedMenu._BDBH;
          let rule = this.checkTextMeetASNGenerateRule(formNo);
          if (rule) {
            this.showFromByRule(formNo, rule);
          } else {
            this.tipWarning("打开表单失败,未配置的表单编码");
          }
        } else {
          this.tipWarning("打开表单失败,错误的菜单参数");
        }
      }
    },
    /**
     * 查看行数据,通过编码rule配置（保存在systemConfigs.FormBHRules中）带了参数
     * @param row
     * @param rule
     */
    showFromByRule: function(row, rule) {
      if (row && rule) {
        let title = "查看-" + rule.tableChineseName + "（" + row + "）";
        this.openTab({
          title: title,
          path: "/DetailForm",
          query: {
            dataCode: row, // 表单数据编号
            tableName: rule.tableName // 表单用于tab的title的表单字段名
          }
        });
      }
    }
  }
};
