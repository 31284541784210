<template>
  <div>
    <div v-if="showToolbar" class="form-headButton">
      <!-- 授权操作按钮 -->
      <e-toolbar
        :buttons="buttons"
        :operationDisabled="operationDisabled"
        :size="$config.defaultControlSize"
      ></e-toolbar>
    </div>
    <a-layout class="treeView-layout" :class="expandTreeViewClass">
      <a-layout-header class="view-header">
        <!--表title -->
        <e-head-title
          title="业务流定义配置"
          :size="$config.defaultControlSize"
        ></e-head-title>
      </a-layout-header>
      <a-layout class="view-content">
        <a-layout-sider
          class="view-sider tree-blur"
          :class="expandViewSilderClass"
        >
          <div class="button-div">
            <a-button size="small" @click="buttonClick('add')">新增</a-button
            ><a-button size="small" @click="buttonClick('del')">删除</a-button>
          </div>
          <a-spin
            :spinning="showTreeSpin"
            tip="树加载中..."
            size="large"
            class="tree-spin"
          ></a-spin>
          <a-tree
            v-if="!showTreeSpin"
            showLine
            defaultExpandAll
            :treeData="treeData"
            @select="onTreeSelect"
          />
        </a-layout-sider>
        <!-- 右侧表单模板 -->
        <a-layout-content class="bodyBox">
          <!-- {{ filedSave }} -->
          <!-- {{ draggableDisplay }}
          {{draggableOption}} -->
          <div>
            <a-spin
              :spinning="showTreeSpin2"
              tip="表加载中..."
              size="large"
              class="tree-spin"
            >
            </a-spin>
            <a-form
              :form="form"
              :label-col="{ span: 4, offset: 0 }"
              :wrapper-col="{ span: 18, offset: 0 }"
              v-show="!showTreeSpin2"
            >
              <a-form-item label="基础信息" />
              <a-form-item label="业务流名称">
                <a-input
                  v-decorator="[
                    'name',
                    {
                      rules: [{ required: true, message: '请输入业务流名称' }]
                    }
                  ]"
                />
              </a-form-item>
              <a-form-item label="业务流编码">
                <a-input
                  v-decorator="[
                    'bm',
                    {
                      rules: [{ required: true, message: '请输入业务流编码' }]
                    }
                  ]"
                />
              </a-form-item>
              <a-form-item label="是否可用" style="text-align:left">
                <EBooleanCheckbox v-decorator="['isOk', {}]" />
                <!-- rules: [{ required: true, message: '此项为必填项' }], -->
                <!-- initialValue: value.isOk ? value.isOk : '' -->
              </a-form-item>

              <a-row style="display: flex;align-items: center;">
                <a-col span="6" offset="0">
                  业务流定义:
                </a-col>
                <a-col span="4" offset="12" style="text-align: right">
                  <a-button size="small" @click.stop="add"
                    ><a-icon type="plus"></a-icon>新增定义</a-button
                  >
                </a-col>
              </a-row>
              <br />
              <div>
                <draggable v-model="data" :options="draggableOption">
                  <transition-group>
                    <template v-for="(row, index) in data">
                      <a-col
                        :span="18"
                        :offset="4"
                        style="padding: 20px;margin-bottom: 20px"
                        class="box-class"
                        :key="index"
                      >
                        <div style="display: flex;align-items: center;">
                          <div>
                            {{ "业务流" + (index + 1) }}
                          </div>
                          <div>
                            <a-icon
                              type="menu"
                              style="font-size: 20px"
                              class="draggableHandle"
                            />
                            <a-icon
                              @click="deleteRow(index)"
                              type="close"
                              style="font-size: 20px"
                            />
                          </div>
                        </div>
                        <!-- {{data}} -->
                        <div>
                          <a-form
                            :label-col="{ span: 8, offset: 0 }"
                            :wrapper-col="{ span: 16, offset: 0 }"
                          >
                            <template v-for="(item, itemIndex) in row">
                              <div
                                :key="'item' + itemIndex"
                                class="relative"
                                style="display: flex;"
                              >
                                <div style="width: 95%">
                                  <div>
                                    <a-form-item label="业务表单">
                                      <a-select
                                        show-search
                                        placeholder="输入以查询"
                                        :options="templateOption"
                                        v-model="item.formTemplateCode"
                                        @change="
                                          (v, e) => {
                                            item.formName = e.data.props.MBDBT;
                                          }
                                        "
                                        :filter-option="filterOption"
                                      />
                                      <!-- {{ item.formName }} -->
                                    </a-form-item>
                                  </div>
                                  <div>
                                    <a-form-item label="上级业务表单关联字段">
                                      <a-input
                                        v-model="
                                          item.previousWorkflowFormRelativeField
                                        "
                                    /></a-form-item>
                                  </div>

                                  {{
                                    filedSave[item.formTemplateCode]
                                      ? undefined
                                      : getTemplateFiled(item.formTemplateCode)
                                  }}
                                  <div>
                                    <a-form-item label="本级业务表单关联字段">
                                      <a-select
                                        :options="
                                          filedSave[item.formTemplateCode]
                                        "
                                        v-model="
                                          item.previousWorkflowFormDataValueField
                                        "
                                      />
                                      <a-input
                                        v-model="
                                          item.previousWorkflowFormDataValueField
                                        "
                                      />
                                    </a-form-item>
                                  </div>
                                </div>
                                <div
                                  style="display: flex;flex-direction: column;width:5%;justify-content: space-around;"
                                >
                                  <a-icon
                                    type="close"
                                    style="font-size:20px; margin-top:20px;margin-bottom:20px"
                                    @click.stop="
                                      () => {
                                        data[index].splice(itemIndex, 1);
                                      }
                                    "
                                    v-if="row.length > 1"
                                  />
                                  <a-icon
                                    type="plus"
                                    style="font-size:20px;margin-top:20px;margin-bottom:20px"
                                    v-if="itemIndex == row.length - 1"
                                    @click.stop="addRow(index)"
                                  />
                                </div>
                              </div>

                              <div
                                class="splic-border"
                                v-if="itemIndex != row.length - 1"
                                :key="'border' + itemIndex"
                              ></div>
                            </template>
                          </a-form>
                        </div>
                      </a-col>
                    </template>
                  </transition-group>
                </draggable>
              </div>
              <a-button @click="save">保存</a-button>
            </a-form>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法
import _lodash from "lodash";
import draggable from "vuedraggable";
// import $ from "jquery";

export default {
  mixins: [pageControls, requestControls],
  components: { draggable },
  data() {
    return {
      form: this.$form.createForm(this, { name: "BusinessFlowConfigView" }),
      data: [],
      drag: false,
      addNewData: [
        {
          formName: "",
          formTemplateCode: "",
          previousWorkflowFormRelativeField: "",
          previousWorkflowFormDataValueField: ""
        }
      ],
      addNewObject: {
        formName: "",
        formTemplateCode: "",
        previousWorkflowFormRelativeField: "",
        previousWorkflowFormDataValueField: ""
      },
      showTreeSpin: false,
      showTreeSpin2: false,
      buttons: [],
      operationDisabled: true, // 操作按钮可用
      treeData: [],
      thisConfig: null,
      selectedNodeKeys: [],
      selectedKeys: [],
      selectRowInfo: [],
      formName: "",
      formCode: "",
      viewConfig: null,
      dataOk: true,
      id: "new",
      delKey: null,
      templateOption: [],
      filedSave: {},
      draggableDisplay: true
    };
  },
  props: {
    pageConfig: {
      type: Object
    }
  },
  computed: {
    rowSelectionUser() {
      return {
        selectedRowKeys: this.selectedUserRowKeys,
        onChange: this.onSelectUserChange,
        hideDefaultSelections: true,
        type: "radio"
      };
    },
    // 根据当前设置的全局尺寸，改变界面样式
    expandTreeViewClass: function() {
      return this.getClassNameBySize("treeView-layout", this.showToolbar);
    },
    expandViewSilderClass: function() {
      return this.getClassNameBySize("view-sider", this.showToolbar);
    },
    showToolbar: function() {
      return this.checkToobleIsShow(this.buttons);
    },
    draggableOption: function() {
      return {
        group: "title",
        animation: 150,
        handle: ".draggableHandle"
      };
    }
  },

  mounted() {
    // this.init();
    this.hideToolBar();
    this.getAllTemplate();
    if (this.viewConfig) {
      this.showTreeSpin = true;
      this.getTreeData(); // 加载树
    }
  },
  created() {
    this.thisConfig = this.pageConfig;
    //分析出用户可做操作按钮

    this.viewConfig = this.thisConfig.viewConfig;

    if (this.viewConfig) {
      // 取得树配置
      if (this.viewConfig.rootName) {
        // 设置了根节点名称
        this.treeData.push({
          title: this.viewConfig.rootName,
          key: "ROOT",
          children: []
        });
      }
      this.pagination.pageSize = 20;
    } else {
      this.alertError("树形表单编辑视图配置错误");
    }
  },
  methods: {
    // 获得所有表单下拉
    getAllTemplate() {
      let that = this;

      let params = {
        action: "GetAllTemplate",
        randomKey: this.$store.getters.getRandomKey
      };
      this.get("System.ashx", params, {
        success: function(req) {
          var data = req.data;
          for (var i = 0; i < data.length; i++) {
            data[i].label = data[i].MBDBT;
            data[i].key = data[i].MBBM;
          }
          that.templateOption = data;
        },
        error: function(err) {
          that.alertError("请求失败，获取下拉表数据失败");
        }
      });
    },
    // 更具表单获得字段
    getTemplateFiled(template) {
      let that = this;
      if (!template) {
        return;
      }

      var data = [];

      get().then(response => {
        //response
        that.$set(that.filedSave, template, response);
        return response;
      });

      // return data;

      function get() {
        let params = {
          action: "GetColsByTemplate",
          MBBM: template,
          randomKey: that.$store.getters.getRandomKey
        };
        return new Promise(function(resolve, reject) {
          that.get("System.ashx", params, {
            success: function(req) {
              var data = req.data;
              for (var i = 0; i < data.length; i++) {
                data[i].key = data[i].value;
              }
              resolve(data);
            },
            error: function(err) {
              that.$message.error("获取表单字段失败" + err);
              resolve([]);
            }
          });
        });
      }
    },
    getTreeData() {
      this.showTreeSpin = true;
      let that = this;
      that.treeData[0].children = [];
      // let mx = this.viewConfig.treeFields;
      let params = {
        action: "GetFormWorkflowDefine",
        randomKey: this.$store.getters.getRandomKey
      };
      this.get("System.ashx", params, {
        success: function(req) {
          let mData = req.data;
          var arr = [];
          for (let i = 0; i < mData.length; i++) {
            arr.push({ title: mData[i].WorkflowName, key: mData[i].ID });
          }
          that.treeData[0].children = arr;
          that.showTreeSpin = false;
        },
        error: function(err) {
          console.log(err);
          that.showTreeSpin = false;
          that.alertError("请求失败，获取树数据失败");
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    clean() {
      this.data = [];
      this.id = null;
      this.form.resetFields();
    },
    buttonClick(type) {
      if (type == "add") {
        this.clean();
        this.id = "new";
      }
      if (type == "del") {
        let searchId = this.selectRowInfo.key;
        if (searchId === null || searchId === "ROOT") {
          this.$message.warning("请选择子节点删除");
          return;
        }
        let that = this;

        this.showTreeSpin = true;

        let params = {
          action: "DeleteFormWorkflowDefine",
          id: searchId,
          randomKey: this.$store.getters.getRandomKey
        };
        this.post("System.ashx", params, {
          success: function(req) {
            if (req.success) {
              that.$message.success("删除成功");
              that.refesh();
              that.clean();
            }
          },
          error: function(err) {
            that.showTreeSpin = false;
            that.alertError("删除失败" + err);
          }
        });
      }
    },
    refesh() {
      this.getTreeData();
    },
    clickTree(id) {
      let searchId = id;
      if (id === "ROOT") {
        let selectedIds = [];
        this.$util.getObjParamValueArrayByParam(
          this.treeData,
          "Id",
          selectedIds
        );
        searchId = selectedIds.toString();
      }
      let that = this;

      this.showTreeSpin2 = true;
      that.dataOk = false;

      let params = {
        action: "GetFormWorkflowDefine",
        id: searchId,
        randomKey: this.$store.getters.getRandomKey
      };
      this.post("System.ashx", params, {
        success: function(req) {
          if (req.success) {
            that.data = req.data.WorkflowDefine
              ? JSON.parse(req.data.WorkflowDefine)
              : [];
            that.formName = req.data.WorkflowName;
            that.formCode = req.data.WorkflowCode;
            that.form.setFieldsValue({
              name: req.data.WorkflowName,
              bm: req.data.WorkflowCode,
              isOk: req.data.Enable
            });
            that.id = req.data.ID;
          }
          that.showTreeSpin2 = false;
          that.$nextTick(() => {
            that.dataOk = true;
          });
        },
        error: function(err) {
          console.log(err);
          that.id = null;
          that.showTreeSpin2 = false;
          that.alertError("请求失败，获取表格数据失败");
        }
      });
    },
    onTreeSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys;
      if (this.selectedKeys == "") {
        this.selectRowInfo = [];
        return;
      }
      this.selectRowInfo = info.node.dataRef;
      this.clickTree(info.node.dataRef.key);
    },
    save() {
      if (!this.id) {
        this.$message.warning("请选择一项或者新建再保存");
        return;
      }
      var that = this;
      var formData = this.form.validateFields((err, val) => {
        if (!err) {
          var data = {
            WorkflowName: val.name,
            WorkflowCode: val.bm,
            WorkflowDefine: this.data,
            Enable: val.isOk ? val.isOk : false
          };
          let params = {
            action: "SaveFormWorkflowDefine",
            randomKey: this.$store.getters.getRandomKey,
            data: JSON.stringify(data)
          };
          if (this.id != "new") {
            params.id = this.id;
          }
          this.post("System.ashx", params, {
            success: function(req) {
              if (req.success) {
                that.$message.success("保存成功");
                that.refesh();
                that.clean();
              }
            },
            error: function(err) {
              that.$message.error("保存失败" + err);
            }
          });
        }
      });
    },

    add() {
      this.data.push(_lodash.cloneDeep(this.addNewData));
    },
    addRow(index) {
      this.data[index].push(_lodash.cloneDeep(this.addNewObject));
    },
    deleteRow(index) {
      this.data.splice(index, 1);
    },
    start(evt) {
      this.drag = true;
      console.log(evt, "start...");
    },
    end(evt) {
      console.log(evt, "end....");
      this.drag = true;
      evt.item; //可以知道拖动的本身
      evt.to; // 可以知道拖动的目标列表
      evt.from; // 可以知道之前的列表
      evt.oldIndex; // 可以知道拖动前的位置
      evt.newIndex; // 可以知道拖动后的位置
    },
    move(evt, originalEvent) {
      console.log(evt, "move");
      console.log(originalEvent); //鼠标位置
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/style/base/business-flow-config-view.less";
</style>
