<!-- 表单弹出窗 :title="title"     v-show="visible"-->
<template>
  <a-modal
    v-model="visible"
    :bodyStyle="{ width: 'auto', maxHeight: maxHeight, maxWidth: maxWidth }"
    :width="allWidth"
    :centered="true"
    @keydown.enter.native="enter"
  >
    <slot name="title">
      <template slot="title">{{ title }}</template>
    </slot>
    <slot name="content">
      <a-spin :spinning="showFormSpin" tip="表单加载中..." size="large" class="form-spin"></a-spin>
      <a-form :form="thisForm" class="form_modal_context">
        <form-template
          v-if="showForm"
          :colSpan="thisColSpan"
          :formRows="formRows"
          :mainTableData="oldMainTableObject"
          :tempId="tempId"
          :form="thisForm"
          :formEditOver="formEditOver"
          ref="formTemplate"
          showModalType
        ></form-template>
      </a-form>
      <div v-show="showFormError">
        <p>表单加载失败</p>
      </div>
    </slot>
    <div slot="footer" class="e-form-modal-footer" :class="buttonAlignClass">
      <!-- 默认确定按钮 -->
      <a-button
        v-if="showOk"
        :disabled="buttonDisabled"
        class="headButton"
        type="primary"
        :ghost="okButton.ghost"
        :loading="okButton.loading"
        :data-config="okButton"
        @click="onButtonClick(okButton)"
        ref="okButton"
      >{{ okButton.title }}</a-button>
      <!--确定按钮图标和按钮尺寸 :icon="button.icon" :size="$config.defaultControlSize"-->
      <div v-if="showOk && showCancel" class="divider-block-min"></div>
      <template v-for="(button, bIndex) in thisButtons">
        <a-button
          :key="button.key"
          :disabled="buttonDisabled"
          class="headButton"
          type="primary"
          :ghost="button.ghost"
          :loading="button.loading"
          :data-config="button"
          @click="onButtonClick(button)"
          ref="okButton"
        >{{ button.title }}</a-button>
        <!--取消按钮图标和按钮尺寸 :icon="button.icon" :size="$config.defaultControlSize"-->
        <div
          v-if="
            thisButtons.length > 1 &&
              bIndex < thisButtons.length - 1 &&
              showCancel
          "
          :key="button.title"
          class="divider-block-min"
        ></div>
      </template>
      <!-- 默认取消按钮 -->
      <a-button
        v-if="showCancel"
        :disabled="buttonDisabled"
        class="headButton"
        type="primary"
        :ghost="cancelButton.ghost"
        :loading="cancelButton.loading"
        :data-config="cancelButton"
        @click="onButtonClick(cancelButton)"
      >{{ cancelButton.title }}</a-button>
    </div>
  </a-modal>
</template>

<script>
import Axios from "axios";
import util from "../utils/util.js";
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { analyticalTemplate } from "@/mixins/analyticalTemplate.js"; // 解析xml模板的方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法
export default {
  mixins: [pageControls, analyticalTemplate, requestControls],
  name: "EFormModal",
  data() {
    return {
      // 表单
      oldMainTableObject: {},
      tempData: {},
      showForm: false,
      showFormError: false,
      showFormSpin: false,
      thisFormName: null, // 对应的表单名称
      formRows: [],
      thisForm: null,
      thisColSpan: 24,
      buttonDisabled: false,
      thisFormConfig: null,
      thisCwpzId: null,
      thisTempBottons: null,
      okButton: {
        title: "确定",
        icon: "check",
        key: "ok",
        loading: false,
        system: true,
        returnData: true
      },
      cancelButton: {
        title: "取消",
        icon: "close",
        key: "cancel",
        system: true,
        loading: false
      },
      defaultButtons: [
        /*{
          title: "确定",
          icon: "check",
          key: "ok",
          loading: false,
          system: true,
          returnData: true
        }*/
        {
          title: "取消",
          icon: "close",
          key: "cancel",
          system: true,
          loading: false
        }
      ],
      isTableFocus: false, // 是否适配键盘导航
      fzhsSelectOk: false,
      formEditOver: false,//表单是否编辑结束
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    formName: {
      type: String,
      default: null
    },
    showOk: {
      type: Boolean,
      default: false
    },
    showCancel: {
      type: Boolean,
      default: false
    },
    buttons: {
      // 操作按钮
      type: Array,
      default: function() {
        return this.defaultButtons;
      }
    },
    buttonAlign: {
      type: String,
      default: "right"
    },
    colSpan: {
      // 定义表单项分布的参数
      type: Number,
      default: 24
    },
    formConfig: {
      // 定义表格表单项
      type: Array,
      default: function() {
        return [];
      }
    },
    tempId: {
      // 当前的模板id
      type: [Number, String],
      default: function() {
        return null;
      }
    },
    cwpzId: {
      // 当前配置的财务id
      type: [Number, String],
      default: function() {
        return null;
      }
    },
    initFormData: {
      //初始化表单数据
      type: Object,
      default: function() {
        return null;
      }
    },
    editOver: {
        type: Boolean,
        default: false
    },
    redraw: {
      // 显示时默认只初始化一次
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      validator: value => {
        if (value) {
          return (
            util.isType(value) === "val" ||
            util.isNumberString(value) ||
            util.isPixelString(value) ||
            util.isPercentageString(value)
          );
        }
        return true;
      }
    }
  },
  computed: {
    isRedraw: function() {
      return this.redraw;
    },
    visible: {
      get: function() {
        return this.value;
      },
      set: function(v) {
        this.$emit("input", v);
        if (this.fzhsSelectOk != false) {
          // 这里是填制凭证键盘相关兼容操作
          this.$nextTick(() => {
            this.$emit("fzhsSelectOk", this.fzhsSelectOk);
            this.fzhsSelectOk = false;
          });
        }
      }
    },
    buttonAlignClass: function() {
      if (this.buttonAlign) {
        if (this.buttonAlign === "right") {
          return "e-form-modal-footer__right";
        } else if (this.buttonAlign === "center") {
          return "e-form-modal-footer__center";
        } else if (this.buttonAlign === "space-between") {
          return "e-form-modal-footer__space-between";
        }
      }
      return "";
    },
    thisButtons: {
      get: function() {
        if (this.showOk) return [];
        if (this.thisTempBottons && this.thisTempBottons.length > 0)
          return this.thisTempBottons;
        return this.defaultButtons;
      },
      set: function(nButton) {
        this.$emit("onButtonState", nButton);
      }
    },
    allWidth: function() {
      // 如果设置了width默认宽度，则使用默认宽度计算，否则根据表单每行列数进行计算宽度
      if (this.$util.isType(this.width) === "String") {
        if (this.$util.isPixelString(this.width)) {
          let widthNumber = this.width.substring(0, this.width.indexOf("px"));
          return parseInt(widthNumber) + 48;
        } else if (this.$util.isNumberString(this.width)) {
          return parseInt(this.width) + 48;
        } else {
          let temp = this.$util.percentToPoint(this.width);
          let z = this.$util.mathUtil.accMul(
            temp,
            this.$root.NowWindowsSize.width
          );
          return z + 48;
        }
      } else if (this.$util.isType(this.width) === "val") {
        return this.width + 48;
      } else {
        let cols = 24 / this.thisColSpan;
        let minWidth = cols * 350 + 48;
        return minWidth > 550 ? minWidth : 550;
      }
    },
    maxHeight: function() {
      return this.$root.NowWindowsSize.height * 0.8 + "px";
    },
    maxWidth: function() {
      return this.$root.NowWindowsSize.width * 0.9 + "px";
    }
  },
  watch: {
    buttons: function(n) {
      //console.log("按钮改变了",n);
      this.thisTempBottons = n;
    },
    editOver: function(v) {
        this.formEditOver = v;
    },
    initFormData: function(newTable) {
      console.log("EFormModal初始化数据改变了", newTable);
      if(this.$util.isObjEqual(this.tempData, newTable)) {
        return;
      }
      this.loading();
      if (newTable) {
        /*let newObject = {};
        let paramKeys = Object.keys(this.oldMainTableObject);
        for (let key = 0; key < paramKeys.length; key++) {
          newObject[paramKeys[key]] = newTable[paramKeys[key]]
            ? newTable[paramKeys[key]]
            : null;
        }
        if (this.thisForm) this.thisForm.setFieldsValue(newObject);*/
        this.oldMainTableObject = Object.assign(
          this.oldMainTableObject,
          newTable
        );
      } else {
        this.oldMainTableObject = {};
      }
      let that = this;
      that.loadingOk();
    },
    value: function(v) {
      //console.log("value change->>>>>>>>");
      if (
        v &&
        this.formRows.length == 0 &&
        this.thisCwpzId !== "" &&
        this.thisCwpzId !== "0"
      ) {
        this.initForm();
      } else if(v){
        this.showForm = true;
      } else if(this.redraw) {
        let that = this;
        setTimeout(function(){
          that.showForm = false;
        },600)
      }
    },
    cwpzId: function(v) {
      this.thisCwpzId = v;
      if (this.thisCwpzId && this.thisCwpzId != "" && this.thisCwpzId != "0") {
        this.formRows = [];
        this.initForm();
      }
      /*this.showFormError = false;
      if (this.thisCwpzId && this.thisCwpzId!="" && this.thisCwpzId!="0") {
        this.initForm();
      }*/
    },
    formConfig: function(v) {
      if (v && v.length > 0) {
        this.loading();
        this.initForm();
      }
    },
    showFormSpin: function(v) {
      if (v == false && this.isTableFocus) {
        this.$nextTick(() => {
          this.tableFocus();
          this.isTableFocus = false;
        });
      }
    },
    oldMainTableObject: function(d) {
      this.tempData = d;
    }
  },
  created() {
    //console.log("this.oldMainTableObject",this.oldMainTableObject);
    this.thisTempBottons = this.buttons;
    this.formEditOver = this.editOver;
  },
  mounted() {
    this.thisCwpzId = this.cwpzId;
    if (
      this.formName ||
      this.tempId ||
      (this.thisCwpzId && this.thisCwpzId !== "" && this.thisCwpzId !== "0")
    ) {
      //console.log("mounted->>>>>>>>EFormModal");
      this.initForm();
    }
  },
  errorCaptured(err, vm, info) {
    console.error("弹出窗口表单异常:", err, vm, info);
    this.loadingError();
    return false;
  },
  methods: {
    initForm() {
      console.log("this.formConfig", this.formConfig);
      if (this.formName) {
        this.thisFormName = this.formName + "_" + new Date().getTime();
        this.formRows = this.formConfig;
      } else if (this.tempId) {
        this.thisFormName = "emodalForm_" + new Date().getTime();
        this.thisColSpan = this.colSpan;
      } else if (this.thisCwpzId) {
        this.thisFormName = "emodalCWPZForm_" + new Date().getTime();
        this.thisColSpan = this.colSpan;
      } else {
        if (this.formConfig.length > 0) {
          this.thisFormName = "emodalSearch_" + new Date().getTime();
          this.formRows = this.formConfig;
          this.thisForm = this.$form.createForm(this, {
            name: this.thisFormName
          });
          this.loadingOk();
        }
        return;
      }
      this.thisForm = this.$form.createForm(this, { name: this.thisFormName });
      this.getFormTemplate();
    },
    /**
     *  获取指定模板
     */
    getFormTemplate() {
      this.loading();
      //console.log("getFormTemplate->>>>>>>>",this.tempId);
      let that = this;
      if (this.formName) {
        // 获取本地配置模板
        let url = "/xml/" + this.formName + ".xml";
        Axios.get(url)
          .then(resp => {
            let tempXML = resp.data;
            if (tempXML !== "") {
              let res = that.renderFormTemplate(
                tempXML,
                that.oldMainTableObject
              );
              that.thisColSpan = res.colSpan;
              that.formRows = res.formRows;
              that.thisFormConfig = res.formDefine;
              that.mainTableName = res.formDefine.tableName;
              //that.mxTableName = res.formDefine.mxTableName;
              let mxForm = res.mxForm;
              if (mxForm) {
                //如果只有一张明细表格
                that.option = mxForm[0].controlDefine;
              } else {
                mxForm = res.mxForms;
                if (mxForm) {
                  //是多张明细表的tab ?
                  that.tabOptions = mxForm;
                }
              }
              if (that.initFormData) {
                // 有初始化数据需要注入
                that.oldMainTableObject = Object.assign(
                  {},
                  that.oldMainTableObject,
                  that.initFormData
                );
              }
              that.loadingOk();
              //console.log("明细表，传参", that.mxTableName, that.mainTableName);
            }
          })
          .catch(function(error) {
            console.error(error);
            that.loadingError();
          });
      } else if (this.tempId || this.thisCwpzId) {
        // 根据模板id，从服务器获取模板
        let params = {
          action: "GetFormTemplate",
          t_id: this.tempId,
          cwpzid: this.thisCwpzId,
          randomKey: this.$store.getters.getRandomKey
        };
        this.get("Form.ashx", params, {
          success: function(req) {
            let tempXML = req.data.template[0].MBNR;
            that.mainTableName = req.data.template[0].MBZBMC;
            if (tempXML !== "") {
              let res = that.renderFormTemplate(
                tempXML,
                that.oldMainTableObject
              ); // 解析表单模板
              //console.log("默认值",that.mainTableData);
              that.thisColSpan = res.colSpan;
              that.formRows = res.formRows;
              that.thisFormConfig = res.formDefine;
              console.log("formRows", JSON.stringify(that.formRows));
              //that.mxTableName = res.formDefine.mxTableName;
              let mxForm = res.mxForm;
              //debugger
              if (mxForm) {
                //如果只有一张明细表格
                //debugger
                that.option = mxForm[0].controlDefine;
              } else {
                mxForm = res.mxForms;
                if (mxForm) {
                  //是多张明细表的tab ?
                  that.tabOptions = mxForm;
                }
              }
              if (that.initFormData) {
                // 有初始化数据需要注入
                that.oldMainTableObject = Object.assign(
                  {},
                  that.oldMainTableObject,
                  that.initFormData
                );
              }
              //console.log("明细表，传参",that.mxTableName);
              that.loadingOk();
            }
          },
          error: function(err) {
            console.error(err);
            that.loadingError();
            that.alertError("请求失败，获取模板失败");
          }
        });
      }
    },
    /**
     * 显示加载中
     */
    loading() {
      this.showFormSpin = true;
      this.showForm = false;
      this.showFormError = false;
    },
    /**
     * 加载成功
     */
    loadingOk() {
      this.showForm = true;
      this.showFormSpin = false;
    },
    /**
     * 加载失败
     */
    loadingError() {
      this.showFormSpin = false;
      this.showFormError = true;
    },
    /**
     * 按钮事件 提交
     * @param button
     */
    onButtonClick(button) {
      if (button.system && button.key === "cancel") {
        this.visible = false;
      }
      //不可编辑表单，不需要返回数据
      if (this.formEditOver) {
        this.visible = false;
        this.$emit(button.key + "Clicked");
        this.$emit("buttonClicked", {
          key: button.key
        });
        return;
      }
      if (button.returnData) {
        let that = this;
        if (this.thisForm) {
          // 是模板表单
          this.thisForm.validateFields((err, values) => {
            if (!err) {
              this.tempData = values;
              let data = null;
              // 如果是通过财务配置id打开的模板，则需要返回模板控件对应的中文名
              if (this.thisCwpzId) {
                data = {};
                data.chineseName = Object.assign(
                  {},
                  this.$refs.formTemplate.chineseName
                );
                data.labelCnName = Object.assign(
                  {},
                  this.$refs.formTemplate.labelCnName
                );
                data.values = values;
              } else {
                data = values;
              }
              this.buttonDisabled = true;
              //console.log("点击了提交数据按钮");
              button.loading = true;
              let promise1 = new Promise(resolve => {
                that.$emit(button.key + "Clicked", {
                  resolve: resolve,
                  key: button.key,
                  data: data,
                  initFormData: that.initFormData
                });
                that.$emit("buttonClicked", {
                  resolve: resolve,
                  key: button.key,
                  data: data,
                  initFormData: that.initFormData
                });
              }).catch(() => console.error("弹出框按钮异常"));
              // 异步响应上方的Promise类提供的resolve的，执行结果
              promise1.then(function(v) {
                //console.log("确认按钮操作完成",value);
                if (v) {
                  // 如果带返回值，则不关闭页面
                  button.loading = false;
                  that.buttonDisabled = false;
                } else {
                  button.loading = false;
                  that.visible = false;
                  that.buttonDisabled = false;
                }
              });
            } else {
              let errMsg = that.getDecoratorErrInfo(err);
              that.alertError(errMsg);
            }
          });
        } else {
          // 非模板表单
          this.buttonDisabled = true;
          //console.log("点击了提交数据按钮");
          button.loading = true;
          let promise2 = new Promise(resolve => {
            that.$emit(button.key + "Clicked", {
              resolve: resolve,
              key: button.key
            });
            that.$emit("buttonClicked", {
              resolve: resolve,
              key: button.key
            });
          }).catch(() => console.error("弹出框按钮异常"));
          // 异步响应上方的Promise类提供的resolve的，执行结果
          promise2.then(function(v) {
            //console.log("确认按钮操作完成",value);
            if (v) {
              // 如果带返回值，则不关闭页面
              button.loading = false;
              that.buttonDisabled = false;
            } else {
              button.loading = false;
              that.visible = false;
              that.buttonDisabled = false;
            }
          });
        }
      } else {
        this.$emit(button.key + "Clicked");
        this.$emit("buttonClicked", {
          key: button.key
        });
      }
    },
    /**
     * 获取表单值对应的中文表示内容
     * @return {* | ({}) | ({} & chineseName)}
     */
    getFormChineseName() {
        return Object.assign(
          {},
          this.$refs.formTemplate.chineseName
        );
    },
    /**
     * 获取表单中对应字段标签的中文名称
     * @return {* | ({}) | ({} & labelCnName)}
     */
    getFormLabelCnName() {
      return Object.assign(
        {},
        this.$refs.formTemplate.labelCnName
      );
    },
    // 使用键盘
    tableFocus() {
      if (this.showFormSpin) {
        this.isTableFocus = true;
      }
      if (!this.showFormSpin) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.formTemplate.tableFocus();
          }, 300);
        });
      }
    },
    enter(e) {
      // e.preventDefault();
      // e.notEnter = true;
      // this.$emit("enter", e);
      // this.$emit("fzhsSelectOk", e);
      this.fzhsSelectOk = e;
      this.$refs.okButton.$el.click();
    },
    okButtonEventClick() {
      this.onButtonClick(this.okButton);
      // this.$refs.okButton.$el.click();
    }
  }
};
</script>

<style scoped lang="less">
@import "../assets/style/base/components/e-form-modal.less";
</style>
