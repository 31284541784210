<template>
  <div class="rowbody">
    <!-- {{data}} -->
    <template v-if="option">
      <a-form :form="form">
        <!-- <a-row :style="{ border: '0px dashed #000' }"> -->
        <template v-for="(item, index) in data">
          <a-col :span="24 / data.length" :key="'row' + index" class="cols">
            <div
              @click="clickBox(index)"
              class="clickbox"
              :style="
                click === index
                  ? { border: '1px dashed blue' }
                  : { border: '1px dashed #000' }
              "
            >
              <div>
                <div class="label">{{ item.label }}</div>
                <div class="button" @click.stop="">
                  <!-- {{ item.field }} -->
                  <a-input v-model="item.field"></a-input>
                  <a-button
                    shape="circle"
                    icon="plus"
                    @click.stop="addCol(index)"
                    v-if="index + 1 == data.length && index + 1 < maxAdd"
                    class="add-button"
                  ></a-button>
                  <a-button
                    shape="circle"
                    icon="close"
                    @click.stop="delCol(index)"
                    class="del-button"
                  ></a-button>
                </div>
              </div>
              <div class="input">
                <a-input
                  v-if="item.controlType == 'input'"
                  :defaultValue="item.defaultValue"
                  :disabled="item.disabled"
                  :readOnly="item.readonly"
                  :style="{ width: '100%' }"
                  v-model="item.defaultValue"
                ></a-input>
                <a-select
                  v-if="item.controlType == 'select'"
                  :defaultValue="item.defaultValue"
                  :disabled="item.disabled"
                  :readOnly="item.readonly"
                  :style="{ width: '100%' }"
                  :options="item.options"
                  v-model="item.defaultValue"
                ></a-select>
                <a-input
                  v-if="item.controlType == 'supplier-select'"
                  placeholder="请点击选择供应商"
                  v-model="item.defaultValue"
                  :disabled="item.disabled"
                >
                  <a-icon
                    slot="prefix"
                    type="plus"
                    style="color:blue"
                    title="供应商选择控件"
                  />
                </a-input>
                <a-input
                  v-if="item.controlType == 'material-select'"
                  placeholder="请点击选择物料"
                  v-model="item.defaultValue"
                  :disabled="item.disabled"
                >
                  <a-icon
                    slot="prefix"
                    type="plus"
                    style="color:blue"
                    title="物料选择控件"
                  />
                </a-input>
                <a-time-picker
                  v-if="item.controlType == 'datetime'"
                  :style="{ width: '100%' }"
                  inputReadOnly
                />
                <a-input
                  placeholder="拍照控件"
                  v-model="item.defaultValue"
                  :disabled="item.disabled"
                  v-if="item.controlType == 'photo'"
                >
                  <a-icon slot="prefix" type="camera" style="color:blue" />
                </a-input>
                <a-input
                  placeholder="当前人控件"
                  v-model="item.defaultValue"
                  :disabled="item.disabled"
                  v-if="item.controlType == 'current-user'"
                >
                  <a-icon slot="prefix" type="user" style="color:blue" />
                </a-input>
                <a-input
                  placeholder="当前部门控件"
                  v-model="item.defaultValue"
                  :disabled="item.disabled"
                  v-if="item.controlType == 'current-department'"
                >
                  <a-icon slot="prefix" type="team" style="color:blue" />
                </a-input>
              </div>
            </div>
          </a-col>
        </template>
        <!-- </a-row> -->
      </a-form>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import { analyticalMenuAuth } from "@/mixins/analyticalMenuAuth.js"; //解析菜单配置的权限字符串方法
import { pageControls } from "@/mixins/pageControls.js"; // 页面控制方法
import { requestControls } from "@/mixins/requestControls.js"; // 网络请求方法
import { getOption, getAddData } from "../columnsSet.js";
export default {
  mixins: [analyticalMenuAuth, pageControls, requestControls],
  name: "mobileBuilder",
  data() {
    return {
      click: null,
      data: this.pushArray(this.option),
      datas: {
        controlType: "input",
        field: "",
        defaultValue: "",
        label: "",
        readonly: false,
        disabled: false,
        validate: ""
      },
      form: this.$form.createForm(this),
      maxAdd: 2,
      addData: []
    };
  },
  props: {
    option: {
      type: null,
      default: null
    },
    row: {
      type: null,
      default: null
    }
  },
  watch: {
    // option: function() {

    // },
    row: function(value) {
      value;
    }
  },
  created() {
    this.addData = getAddData();
    this.init();
  },
  mounted() {},
  methods: {
    submit() {
      this.$emit("ok", this.data, this.row);
    },
    pushArray(data) {
      if (data.length == null) {
        return [data];
      }
      return data;
    },
    init() {
      this.option;
      this.row;
      this.data;
    },
    addCol(index) {
      // 是否超出最大
      if (index + 1 == this.data.length && index + 1 < this.maxAdd) {
        this.data.push(this.addData[0]);
        this.submit();
      }
    },
    delCol(index) {
      if (this.data[index]) {
        this.data.splice(index, 1);
        this.$emit("del");
        this.click = null;
        this.submit();
      }
    },
    noClick() {
      this.click = null;
    },
    clickBox(index) {
      this.$emit("noClick");
      this.click = index;
      this.$emit("isClick", this.row, index);
    },
    clickMe(index) {
      this.clickBox(index);
    }
  }
};
</script>

<style lang="less" scoped>
.rowbody {
  // height: 80px;
  // margin-top: 10px;
  // margin-bottom: 10px;
  // line-height: 60px;
  margin: 10px;
}
.label {
  font-size: 20px;
  float: left;
}
.cols {
  // margin-top: 10px;
  // margin-bottom: 10px;
  // height: 60px;
}
.input {
  width: 100%;
}
.button {
  float: right;
  font-size: 20px;
  z-index: 10001;
}
.clickbox {
  cursor: pointer;
  z-index: 10000;
  padding: 5px;
  margin: 5px;
}
.add-button {
  color: rgb(0, 0, 0);
}
.del-button {
  color: #fff;
  background: red;
}
.border {
  border: 1px dashed #000;
  cursor: pointer;
  z-index: 10000;
  padding: 5px;
  margin: 5px;
}
.border-click {
  border: 1px dashed #f2f2f2;
  cursor: pointer;
  z-index: 10000;
  padding: 5px;
  margin: 5px;
}
</style>
